import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  assignCollaboratorRole,
  deleteRole,
  hideUpdateRoleForm,
  removeCollaborator,
  showUpdateRoleForm,
  updateRole,
} from './actions';

const removeCollaboratorInProgress = createReducer(false)
  .handleAction(removeCollaborator.request, () => true)
  .handleAction([removeCollaborator.success, removeCollaborator.failure], () => false);

const updatedRoleCode = createReducer<null | string>(null)
  .handleAction(showUpdateRoleForm, (_state, { payload }) => payload)
  .handleAction(hideUpdateRoleForm, () => null);

const updateRoleInProgress = createReducer(false)
  .handleAction(updateRole.request, () => true)
  .handleAction([updateRole.success, updateRole.failure], () => false);

const assignCollaboratorRoleInProgress = createReducer(false)
  .handleAction(assignCollaboratorRole.request, () => true)
  .handleAction([assignCollaboratorRole.success, assignCollaboratorRole.failure], () => false);

const deleteRoleInProgress = createReducer(false)
  .handleAction(deleteRole.request, () => true)
  .handleAction([deleteRole.success, deleteRole.failure], () => false);

export default combineReducers({
  removeCollaboratorInProgress,
  updatedRoleCode,
  updateRoleInProgress,
  assignCollaboratorRoleInProgress,
  deleteRoleInProgress,
});
