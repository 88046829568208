import { RadioChangeEvent, Radio } from 'antd';
import cn from 'classnames';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchData, refreshData } from '@/modules/data/duck/actions';
import { createDataSel, createIsLoadingSel } from '@/modules/data/duck/selectors';
import {
  updateAttendeesAttributes,
  createFormAttribute,
} from '@/modules/entities/Attributes/duck/actions';
import {
  updateAttendeesAttributesInProgressSel,
  createFormAttributeInProgressSel,
} from '@/modules/entities/Attributes/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import TagSelector from '@/modules/shared/components/TagSelector/TagSelector';
import toastService from '@/modules/toasts/service';
import { useAbilities } from '@/modules/user/duck/abilities';

import styles from './AttendeesAttributesModal.less';
import { deselectAttribute, resetModuleState, selectAttribute } from './duck/actions';
import { selectedAttributesSel } from './duck/selectors';
import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const AttendeesAttributesModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const abilities = useAbilities();
  const { formCode } = useSelector(createDataSel('form'));
  const createFormAttributeInProgress = useSelector(createFormAttributeInProgressSel);
  const fetchingAttributes = useSelector(createIsLoadingSel('attributeList'));
  const updateInProgress = useSelector(updateAttendeesAttributesInProgressSel);
  const attributes = useSelector(createDataSel('attributeList'));
  const selectedAttributes = useSelector(selectedAttributesSel);

  const { selectedRows, dataType, dataId } = modalParams;

  const canCreateAttributes = abilities.can({
    action: 'edit',
    target: 'attendee.attributes',
  });

  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    dispatch(fetchData.request({ dataType: 'attributeList', queryObj: { formCode } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      dispatch(resetModuleState());
    },
    [dispatch],
  );

  const handleAddAttribute = (attributeCode: string, attributeName: string) => {
    if (!attributes.find(at => at.attributeCode === attributeCode)) {
      dispatch(createFormAttribute.request({ formCode, attributeCode, attributeName }));
    }

    handleSelectAttribute(attributeCode);
  };

  const handleSelectAttribute = (attributeCode: string) => {
    dispatch(selectAttribute(attributeCode));
  };

  const handleOnDelete = (attributeCode: string) => {
    dispatch(deselectAttribute(attributeCode));
  };

  const handleIsDeleteOnChange = (e: RadioChangeEvent) => {
    setIsDelete(e.target.value);
  };

  function handleAssignAttributes() {
    if (!selectedAttributes.size) {
      return toastService.warn('Select at least one attribute');
    }

    dispatch(
      updateAttendeesAttributes.request({
        formCode,
        isDelete,
        attendees: selectedRows,
        attributes: Array.from(selectedAttributes),
        finalActions: [refreshData({ dataType, dataId }), closeModal()],
      }),
    );
  }

  return (
    <ModalComponent
      title="Modify Attributes"
      description={`Modifying attributes for ${selectedRows.length} attendees:`}
      buttons={[
        {
          title: isDelete ? 'Remove Attribute(s)' : 'Add Attribute(s)',
          type: 'primary',
          onClick: handleAssignAttributes,
          loading: createFormAttributeInProgress,
        },
      ]}
      inProgress={updateInProgress || fetchingAttributes}
    >
      <Radio.Group
        className={cn(styles.rowWrap, styles.bottomMargin)}
        value={isDelete}
        onChange={handleIsDeleteOnChange}
      >
        <Radio value={false}>Add to list of attributes</Radio>
        <Radio value={true}>Find and remove from list</Radio>
      </Radio.Group>
      <div className={styles.description}>
        {isDelete ? 'Choose an existing attribute to remove' : 'Type a new attribute'}
      </div>
      <TagSelector
        dataSource={attributes || []}
        valueField="attributeCode"
        labelField="attributeName"
        value={Array.from(selectedAttributes)}
        onSelect={handleSelectAttribute}
        onDeselect={handleOnDelete}
        {...(!isDelete && canCreateAttributes
          ? {
              onAdd: handleAddAttribute,
              addInProgress: createFormAttributeInProgress,
            }
          : {})}
      />
    </ModalComponent>
  );
};

export default AttendeesAttributesModal;
