import { Button } from 'antd';
import { CollapsePanelProps } from 'antd/lib/collapse';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { useSelector } from 'react-redux';

import { Attendee } from '@/modules/data/dataTypes/attendeeList';
import { useGetData } from '@/modules/data/hooks';
import { updatePersonInProgressSel } from '@/modules/entities/Person/duck/selectors';
import AttendeeProfileInformation from '@/modules/shared/components/AttendeeProfileInformation';
import ReservationDetailsSection from '@/modules/shared/components/ReservationDetailsSection';

import Section from '../Section';

import { ButtonsWrapper } from './AdditionalRegistrantReservationDetails.styled';

type Props = CollapsePanelProps & {
  formRecord: Attendee;
};

const AdditionalRegistrantReservationDetails: React.FC<Props> = ({ formRecord, ...rest }) => {
  const { personGUID } = formRecord;
  const profileInformationRef = useRef<{ handleSubmit: () => void }>(null);
  const [isEditing, setIsEditing] = useState(false);
  const isUpdatingPerson = useSelector(updatePersonInProgressSel);
  const [person, personIsLoading] = useGetData({
    dataType: 'arnicaPerson',
    queryObj: { personGUID },
  });

  useEffect(() => {
    if (!isUpdatingPerson) {
      setIsEditing(false);
    }
  }, [isUpdatingPerson]);

  const handleSubmit = () => {
    profileInformationRef.current?.handleSubmit();
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  return (
    <Section isLoading={personIsLoading}>
      <ReservationDetailsSection
        {...rest}
        headerButtons={[
          {
            Icon: AiFillEdit,
            onClick: handleEdit,
            key: 'editActivities',
          },
        ]}
      >
        <AttendeeProfileInformation
          ref={profileInformationRef}
          isEditing={isEditing}
          person={person}
        />
        {isEditing && (
          <ButtonsWrapper>
            <Button onClick={handleCancel}>CANCEL</Button>
            <Button type="primary" onClick={handleSubmit}>
              SAVE
            </Button>
          </ButtonsWrapper>
        )}
      </ReservationDetailsSection>
    </Section>
  );
};

export default AdditionalRegistrantReservationDetails;
