import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { Session } from '@/modules/data/dataTypes/sessionList';
import { openModal } from '@/modules/modals/duck/actions';

import {
  cloneSession,
  deleteSession,
  updateSession,
  updateSessionClosed,
  updateSessionOpened,
  updateSessionsInBulk,
} from './actions';

const currentSession = createReducer<Session | null | Record<string, never>>(null)
  .handleAction(updateSessionOpened, (_state, { payload }) => payload || {})
  .handleAction(updateSessionClosed, () => null);

const deleteSessionInProgress = createReducer(false)
  .handleAction(deleteSession.request, () => true)
  .handleAction([deleteSession.failure, deleteSession.success], () => false);

const cloneSessionInProgress = createReducer(false)
  .handleAction(cloneSession.request, () => true)
  .handleAction([cloneSession.success, cloneSession.failure, openModal], () => false);

const updateSessionInProgress = createReducer(false)
  .handleAction([updateSession.request, updateSessionsInBulk.request], () => true)
  .handleAction(
    [
      updateSession.success,
      updateSession.failure,
      updateSessionsInBulk.success,
      updateSessionsInBulk.failure,
    ],
    () => false,
  );

export default combineReducers({
  currentSession,
  deleteSessionInProgress,
  cloneSessionInProgress,
  updateSessionInProgress,
});
