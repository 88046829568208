import { RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { useCallback, useMemo } from 'react';
import { MdAddCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'redux-first-router-link';

import { Attendee } from '@/modules/data/dataTypes/attendeeList';
import { refreshData, updatePageNumber } from '@/modules/data/duck/actions';
import { createPaginationSel } from '@/modules/data/duck/selectors';
import { AddSecondaryRegistrantModalParams } from '@/modules/entities/Registrations/components/AddSecondaryRegistrantModal/types';
import { ADD_SECONDARY_REGISTRANT_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import IconButton from '@/modules/shared/components/StylableIconButton';
import colors from '@/modules/styles/colors';

import { navigateToIndividualReservation } from '@/pages/reservation/duck/actions';
import { pageDataSel } from '@/pages/reservation/duck/selectors';
import { individualReservationTabDataSel } from '@/pages/reservation/tabs/OverviewIndividual/duck/selector';

import { TableInnerHeader, BlueBoldText } from './SecondaryRegistrantsTable.styled';

import ActionTable from 'SHARED/components/ActionTable';

const SecondaryRegistrantsTable: React.FC = () => {
  const dispatch = useDispatch();

  const {
    data: { attendeeList },
  } = useSelector(individualReservationTabDataSel);

  const {
    data: { form, attendeeDetails, session },
  } = useSelector(pageDataSel);

  const { formCode } = form;
  const pagination = useSelector(createPaginationSel('attendeeList'));

  const { pageNumber, pageSize, recordCount } = pagination || {};

  const createAddSecondaryRegistrantHandler = useCallback(
    (secondaryRegistrantFormRecordGUID?: string) => () => {
      const { formRecordGUID: primaryRegistrantFormRecordGUID } = attendeeDetails;

      const modalParams: AddSecondaryRegistrantModalParams = {
        formCode,
        primaryRegistrantFormRecordGUID,
        session,
        secondaryRegistrantFormRecordGUID,
        useCheckoutStep: true,
        finalActions: [
          refreshData({ dataType: 'attendeeList' }),
          refreshData({ dataType: 'attendeeDetails' }),
        ],
      };
      dispatch(openModal(ADD_SECONDARY_REGISTRANT_MODAL, modalParams));
    },
    [attendeeDetails, dispatch, session, formCode],
  );

  const columns: ColumnProps<Attendee>[] = useMemo(
    () => [
      {
        title: 'Name',
        key: 'name',
        // eslint-disable-next-line react/no-multi-comp
        render: ({ firstName, lastName }: Attendee) => (
          <BlueBoldText>{`${firstName} ${lastName}`}</BlueBoldText>
        ),
      },
      {
        title: 'Reservation Code',
        dataIndex: 'reservationCode',
      },
      {
        title: 'Attendee Type',
        dataIndex: 'typeName',
      },
      {
        title: '',
        key: 'action',
        // eslint-disable-next-line react/no-multi-comp
        render: ({ isRegistrationCompleted, formRecordGUID }: Attendee) => {
          if (isRegistrationCompleted) {
            return (
              <Link
                target="_blank"
                to={navigateToIndividualReservation({ formCode, formRecordGUID })}
              >
                <RightOutlined />
              </Link>
            );
          }
          return (
            <Button
              type="link"
              size="small"
              onClick={createAddSecondaryRegistrantHandler(formRecordGUID)}
            >
              Finish Registration
            </Button>
          );
        },
      },
    ],
    [formCode, createAddSecondaryRegistrantHandler],
  );

  const handlePageChange = (nextPageNumber: number) => {
    dispatch(
      updatePageNumber({
        dataType: 'attendeeList',
        pageNumber: nextPageNumber,
      }),
    );
  };

  const tableHeader = () => (
    <TableInnerHeader>
      <IconButton
        label="Add Person"
        disabled={!attendeeDetails.sessionCode}
        Icon={MdAddCircle}
        color={colors.white}
        iconSize={24}
        hoverColor={colors.primary_1}
        onClick={createAddSecondaryRegistrantHandler()}
      />
    </TableInnerHeader>
  );

  const renderTotal = (total: number) => `Total secondary registrants: ${total}`;

  return (
    <ActionTable
      title={tableHeader}
      id="secondaryRegistrantsTable"
      subject="Secondary Registrant"
      rowKey="key"
      columns={columns}
      dataSource={attendeeList}
      allowSearch={false}
      pagination={{
        pageSize,
        current: pageNumber,
        total: recordCount,
        position: ['bottomCenter'],
        showTotal: renderTotal,
        onChange: handlePageChange,
      }}
      emptyMessage="This reservation has no Secondary registrants, or you don't have the permissions to see them"
    />
  );
};

export default SecondaryRegistrantsTable;
