import styled from 'styled-components';

import SharedLabel from '@/modules/shared/components/Label';
import colors from '@/modules/styles/colors';

export const Label = styled(SharedLabel)`
  span:first-child {
    color: ${colors.scoutingBlue};
    font-size: 14px;
    font-weight: 700;
  }
`;
