/* eslint-disable react/no-multi-comp */
import { debounce } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { Attendee } from '@/modules/data/dataTypes/rosterAttendees';
import { RosterDetails } from '@/modules/data/dataTypes/rosterDetails';
import { Roster } from '@/modules/data/dataTypes/rosterList';
import { removeFilter, setFilter, updatePageNumber } from '@/modules/data/duck/actions';
import { createDataSel, createPaginationSel } from '@/modules/data/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import Desktop from '@/modules/responsiveSizes/Desktop';
import Mobile from '@/modules/responsiveSizes/Mobile';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { personGuidSel, isSystemAdministratorSel } from '@/modules/user/duck/selectors';

import { SearchInput } from '../../RosterPage.styled';
import { updateSelections } from '../../duck/actions';
import { groupAttendees } from '../../utils';
import AttendeesList from '../AttendeesList';

import { Pagination } from './CrewAttendees.styled';
import AttendeesCustomActionBar from './components/AttendeesCustomActionBar/AttendeesCustomActionBar';
import AttendeesTable from './components/AttendeesTable';

const FILTER_SEARCH = 'fullName';

type Props = {
  formSettings: FormSettings;
  reservationDetails: GroupReservation;
  roster: Roster & RosterDetails;
  selectedAttendees: Attendee[];
  selectedAttendeesPersonGuids: Set<string>;
  canEditParticipant: boolean;
  isLeadAdvisor: boolean;
};

const CrewAttendees: React.FC<Props> = ({
  formSettings,
  reservationDetails,
  roster,
  selectedAttendees,
  selectedAttendeesPersonGuids,
  canEditParticipant,
  isLeadAdvisor,
}) => {
  const { isCreator, isCollaborator } = formSettings;
  const { isCancelled, isReservationContact, initialPeopleCount, isAdditionalReservationContact } =
    reservationDetails;
  const { attendeeTypes, numberOfAttendees, maximumCapacity } = roster;
  const netMaxCapacity = Math.min(+initialPeopleCount, +maximumCapacity);
  const isRosterFull = +numberOfAttendees >= netMaxCapacity;

  const dispatch = useDispatch();

  const attendees = useSelector(createDataSel('rosterAttendees'));
  const pagination = useSelector(createPaginationSel('rosterAttendees'));
  const disclaimers = useSelector(createDataSel('formDisclaimers'));
  const personGuid = useSelector(personGuidSel);
  const isSystemAdministrator = useSelector(isSystemAdministratorSel);

  const { pageNumber, pageSize, recordCount } = pagination || {};

  const allowInviteAttendee = useMemo(() => {
    if (isCancelled) return false;
    if (isCreator || isCollaborator || isSystemAdministrator) return true;
    return isReservationContact || isAdditionalReservationContact || isLeadAdvisor;
  }, [
    isSystemAdministrator,
    isAdditionalReservationContact,
    isCancelled,
    isCollaborator,
    isCreator,
    isLeadAdvisor,
    isReservationContact,
  ]);

  const canUpdateGroupReservation = useSelector(
    createAbilitiesSelector('groupReservation', PermissionAction.Update, reservationDetails),
  );
  const canUpdateAttendeeDetails = useSelector(
    createAbilitiesSelector('attendeeDetails', PermissionAction.Update, null),
  );
  const groupedAttendees = useMemo(
    () => groupAttendees(attendees, roster, !isRosterFull && allowInviteAttendee, pageNumber),
    [attendees, allowInviteAttendee, isRosterFull, pageNumber, roster],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((typedSearch: string) => {
      if (typedSearch) {
        dispatch(
          setFilter({ dataType: 'rosterAttendees', key: FILTER_SEARCH, value: typedSearch }),
        );
        return;
      }

      dispatch(removeFilter({ dataType: 'rosterAttendees', key: FILTER_SEARCH }));
    }, 500),
    [],
  );

  const handleChangePage = (nextPageNumber: number) => {
    dispatch(updatePageNumber({ dataType: 'rosterAttendees', pageNumber: nextPageNumber }));
  };

  const handleSelect = (selectedGuids: Set<string>) => {
    dispatch(updateSelections(selectedGuids));
  };

  const renderTotal = (total: number) => `Total members ${total}`;

  if (!attendeeTypes) return null;

  return (
    <Fragment>
      <SearchInput onChange={handleSearch} />
      <AttendeesCustomActionBar
        canEditParticipant={canEditParticipant}
        netMaxCapacity={netMaxCapacity}
        selectedAttendees={selectedAttendees}
        roster={roster}
        canTransferAttendees={canUpdateGroupReservation && canUpdateAttendeeDetails}
      />
      <Desktop>
        <AttendeesTable
          groupedAttendees={groupedAttendees}
          onSelect={handleSelect}
          selectedAttendees={selectedAttendeesPersonGuids}
          eventHasDisclaimers={!!disclaimers.length}
        />
      </Desktop>
      <Mobile>
        <AttendeesList
          groupedAttendees={groupedAttendees}
          personGuid={personGuid as string}
          selectedAttendees={selectedAttendeesPersonGuids}
          reservationDetails={reservationDetails}
          eventHasDisclaimers={!!disclaimers.length}
          onSelect={handleSelect}
        />
      </Mobile>
      <Pagination
        pageSize={pageSize}
        current={pageNumber}
        total={recordCount}
        showTotal={renderTotal}
        onChange={handleChangePage}
      />
    </Fragment>
  );
};

export default CrewAttendees;
