import { createAsyncAction } from 'typesafe-actions';

import { Program } from '@/modules/data/dataTypes/programList';
import { createModuleNamespaceName } from '@/modules/utils/misc';

import { modulePath } from '../constants';

const moduleNamespace = createModuleNamespaceName(modulePath);

export const updateProgram = createAsyncAction(
  `${moduleNamespace}/UPDATE_PROGRAM_REQUEST`,
  `${moduleNamespace}/UPDATE_PROGRAM_SUCCESS`,
  `${moduleNamespace}/UPDATE_PROGRAM_FAILURE`,
)<
  Pick<Program, 'programName' | 'programPrefix' | 'accountCode' | 'accountName'> &
    Pick<Partial<Program>, 'programCode'>,
  undefined,
  Error
>();

export const deleteProgram = createAsyncAction(
  `${moduleNamespace}/DELETE_PROGRAM_REQUEST`,
  `${moduleNamespace}/DELETE_PROGRAM_SUCCESS`,
  `${moduleNamespace}/DELETE_PROGRAM_FAILURE`,
)<string, void, void>();
