import React from 'react';

import { Session } from '@/modules/data/dataTypes/sessionList';
import { CURRENCY_FORMAT } from '@/modules/utils/numberFormats';

import FooterItem from '../FooterItem';

type Props = {
  session: Session;
};

const AdditionalPersonFee: React.FC<Props> = ({ session }) => {
  const { additionalPersonFee, isFeePerPerson, baseFeePersonCount, maximumPeopleCount } = session;
  if (!additionalPersonFee) return null;

  const value = CURRENCY_FORMAT(additionalPersonFee);

  if (isFeePerPerson) {
    return <FooterItem text="Fee per Person" value={value} />;
  }

  if (maximumPeopleCount <= baseFeePersonCount) return null;

  return <FooterItem text="Extra Person Fee" value={value} />;
};

export default AdditionalPersonFee;
