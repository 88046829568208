import { sha256 } from 'js-sha256';

import apiService from '@/modules/utils/apiService';

const setFormRegistrationCompletedStatus$ = (payload: {
  sessionCode: string;
  token: string;
  formCode: string;
  formRecordGUID: string;
}) => {
  const { sessionCode, token, formCode, formRecordGUID } = payload;
  const key = sha256(sessionCode + token.slice(-4));

  const data = {
    formCode,
    key: key.slice(-9),
    formRecordGUID,
  };

  return apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=SetFormRegistrationCompletedStatus',
    data,
    {
      timeout: 120000,
    },
  );
};

export default {
  setFormRegistrationCompletedStatus$,
};
