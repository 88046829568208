import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { DISMISSED_MESSAGE_KEY, moduleName } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const broadcastMessageSel = createSelector(moduleSel, ({ broadcastMessage }) => {
  const dismissedMessage = localStorage.getItem(DISMISSED_MESSAGE_KEY);

  if (broadcastMessage === dismissedMessage) return '';

  return broadcastMessage;
});

export const updateBroadcastInProgressSel = createSelector(
  moduleSel,
  ({ updateBroadcastInProgress }) => updateBroadcastInProgress,
);
