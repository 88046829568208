import React from 'react';
import { FormattedPlural, FormattedMessage } from 'react-intl';

import styles from './EventsCount.less';

import S from 'SHARED/components/S';

interface Props {
  count?: number;
  id?: string;
}

const EventCount: React.FC<Props> = ({ id, count = 0 }) => (
  <span id={id} className={styles.spanWrapper}>
    <S bold inlineBlock className={styles.count} size="2">
      {count}
    </S>
    <S size="2" inlineBlock>
      <FormattedPlural
        value={count}
        other={<FormattedMessage id="events.EventsPage.EventCount.events" />}
        one={<FormattedMessage id="events.EventsPage.EventCount.event" />}
      />
    </S>
  </span>
);

export default EventCount;
