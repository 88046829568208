import { Col, Form, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { forwardRef, useImperativeHandle } from 'react';

import { AdditionalFieldsData } from '@/modules/payments/components/PaymentGateway/types';
import { US_DATE_FORMAT } from '@/modules/utils/dateFormats';

import { DatePicker, Input } from './CheckFields.styled';
import { CheckData } from './types';

const CheckFields = forwardRef<{ handleSubmit: () => Promise<AdditionalFieldsData> }>(
  (_props, ref) => {
    const [form] = Form.useForm<CheckData>();
    const handleSubmit = () => form.validateFields();

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    const disabledDate = (currentDate: Dayjs | null) => {
      if (currentDate === null) {
        return false;
      }
      return currentDate && currentDate.isAfter(dayjs(), 'day');
    };

    return (
      <Form form={form}>
        <Row gutter={50}>
          <Col md={12} sm={24}>
            <Form.Item
              name="postedDate"
              label="Posted Date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker format={US_DATE_FORMAT} disabledDate={disabledDate} size="large" />
            </Form.Item>
          </Col>
          <Col md={12} sm={24}>
            <Form.Item
              name="chequeNumber"
              label="Check Number"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[-0-9A-z]*$/,
                  message: 'Only alphanumeric characters are allowed',
                },
                {
                  max: 30,
                  type: 'string',
                  message: 'Maximum 30 characters allowed',
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  },
);

export default CheckFields;
