import { produce } from 'immer';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { resetOnAction } from '@/modules/utils/resetOnLogoutReducer';

import { TabKey } from '../constants';
import { moduleName as currentReportModuleName } from '../tabs/CurrentReport/constants';
import currentReportReducers from '../tabs/CurrentReport/duck/reducers';
import { ReportDataType } from '../types';

import { updateReportPermissions, openTab, resetReports, setFormCode } from './actions';

const formCode = createReducer('').handleAction(setFormCode, (_state, { payload }) => payload);

const activeTabKey = createReducer(
  {} as Record<ReportDataType, TabKey | string | void>,
).handleAction(openTab.success, (state, { payload: { tabKey, dataType } }) =>
  produce(state, draft => {
    draft[dataType] = tabKey;
  }),
);

const tabIsLoading = createReducer(false)
  .handleAction(openTab.request, () => true)
  .handleAction([openTab.success, openTab.failure], () => false);

const updateReportPermissionsInProgress = createReducer(false)
  .handleAction(updateReportPermissions.request, () => true)
  .handleAction([updateReportPermissions.success, updateReportPermissions.failure], () => false);

export default resetOnAction(
  combineReducers({
    [currentReportModuleName]: currentReportReducers,
    formCode,
    activeTabKey,
    tabIsLoading,
    updateReportPermissionsInProgress,
  }),
  resetReports,
);
