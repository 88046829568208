import React, { useMemo } from 'react';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import Banner from '@/modules/shared/components/Banner';
import { usDate } from '@/modules/utils/dateFormats';

type Props = {
  showCancelledByMessage: boolean;
} & (
  | { isGroup: true; groupReservation: GroupReservation }
  | { isGroup: false; attendeeDetails: AttendeeDetails }
);

const CancellationBanner: React.FC<Props> = props => {
  const { isGroup, showCancelledByMessage } = props;
  const isCancelled = isGroup
    ? props.groupReservation.isCancelled
    : props.attendeeDetails.isCancelled;

  const cancellationDate = isGroup
    ? props.groupReservation.cancellationDate
    : props.attendeeDetails.cancellationDate;

  const cancelledByMessage = useMemo(() => {
    if (showCancelledByMessage) return '';
    if (isGroup) {
      const { groupReservation } = props;
      const { cancelledByFirstName, cancelledByLastName } = groupReservation;
      if (!cancelledByFirstName || !cancelledByLastName) return '';
      return `by ${cancelledByFirstName} ${cancelledByLastName}`;
    }
    return '';
  }, [props, isGroup, showCancelledByMessage]);

  if (!isCancelled) return null;

  return (
    <Banner
      type="error"
      message={
        <span>
          This Reservation was cancelled
          {cancelledByMessage}
          on {usDate(cancellationDate)}
        </span>
      }
    />
  );
};

export default CancellationBanner;
