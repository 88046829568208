import React, { Fragment } from 'react';

import Dropdown from '@/modules/shared/components/Dropdown';

import { Container } from './ResendInvite.styled';

import { usDate } from 'UTILS/dateFormats';

type Props = {
  invitationSentDate: string;
  onResendInvite: () => void;
  onCancelInvite?: () => void;
};

const ResendInvite: React.FC<Props> = ({ invitationSentDate, onResendInvite, onCancelInvite }) => (
  <Container>
    {invitationSentDate && <Fragment>{`Invited on ${usDate(invitationSentDate)}`}</Fragment>}
    <Dropdown
      menuItems={[
        {
          key: 'resendInvite',
          label: 'Resend Invite',
          onClick: onResendInvite,
        },
        ...(onCancelInvite
          ? [
              {
                key: 'cancelInvite',
                label: 'Cancel Invite',
                onClick: onCancelInvite,
              },
            ]
          : []),
      ]}
    />
  </Container>
);

export default ResendInvite;
