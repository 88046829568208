import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { formStatus } from '@/modules/shared/constants';

import { approveOrDeclineAttendee } from '../../duck/actions';
import { isLoadingApprovalSel } from '../../duck/selectors';

import styles from './RevokeApprovalModal.less';

const RevokeApprovalModal: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingApprovalSel);
  const { formCode, formRecordGUID, firstName, lastName, personGUID } = useSelector(
    createDataSel('attendeeDetails'),
  );

  function handleRevokeApproval() {
    dispatch(
      approveOrDeclineAttendee.request({
        formCode,
        personGUID,
        formRecordGUID,
        statusCode: formStatus.council_approval_pending.code,
      }),
    );
  }

  return (
    <ModalComponent
      title="Revoke Approval"
      inProgress={isLoading}
      buttons={[
        {
          title: 'Revoke',
          type: 'primary',
          onClick: handleRevokeApproval,
        },
      ]}
    >
      <p>
        <b>{`Are you sure you want to REVOKE ${firstName} ${lastName}'s application?`}</b>
      </p>
      <div
        className={styles.bottomMargin}
      >{`${firstName} ${lastName}’s application form will be revoked and pending for approval.`}</div>
    </ModalComponent>
  );
};

RevokeApprovalModal.defaultProps = {
  isLoading: false,
};

export default RevokeApprovalModal;
