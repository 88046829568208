import { Upload } from 'antd';
import { MdInsertDriveFile } from 'react-icons/md';
import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

const dragActiveStyle = css`
  border-color: ${colors.lightBlue};
  box-shadow: 0 0 10px ${colors.lightBlue};
`;

export const Dragger = styled(Upload.Dragger)`
  .ant-upload-drag .ant-upload-btn {
    padding: 0;
  }
`;

export const Container = styled.div<{ $isDraggingOver: boolean }>`
  padding: 20px;
  border-radius: 4px;
  background-color: ${colors.white};
  transition: 'border .24s ease-in-out';
  border: 1px dashed ${colors.scoutingPaleGray};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;

  ${({ $isDraggingOver }) => $isDraggingOver && dragActiveStyle}
`;

export const DropZoneText = styled.div`
  color: ${colors.darkGrey};
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
`;

export const FileIcon = styled(MdInsertDriveFile)`
  color: ${colors.gray_6};
`;
