import DOMPurify from 'dompurify';
import React from 'react';

import { EVENT_LABEL, FOR_ATTENDEE_LABEL } from '../../constants';
import { Attendee, Disclaimer } from '../../types';

import { DisclaimerContent, Divider, Panel, Row } from './DisclaimerItem.styled';
import AcceptDisclaimer from './components/AcceptDisclaimer';
import AcceptanceDetails from './components/AcceptanceDetails';
import DownloadButton from './components/DownloadButton';

type Props = {
  disclaimer: Disclaimer;
  isAccepted: boolean;
  attendeeDetails: Attendee;
  onAccept?: (disclaimerCode: string, isAccepted: boolean) => void;
};

const DisclaimerItem: React.FC<Props> = ({
  disclaimer,
  attendeeDetails,
  isAccepted,
  onAccept,
  ...rest
}) => {
  const { disclaimerCode, disclaimerName, accepted } = disclaimer;
  const { firstName, lastName, formName } = attendeeDetails;

  return (
    <Panel
      {...rest}
      header={disclaimerName}
      key={disclaimerCode}
      extra={<DownloadButton attendeeDetails={attendeeDetails} disclaimer={disclaimer} />}
    >
      <Divider />
      <Row>
        <b>{FOR_ATTENDEE_LABEL}: </b>
        <span>{`${firstName} ${lastName}`}</span>
      </Row>
      <Row>
        <b>{EVENT_LABEL}: </b>
        <span>{formName}</span>
      </Row>
      {/* eslint-disable-next-line react/no-danger */}
      <DisclaimerContent
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(disclaimer.disclaimerContent) }}
      />
      {accepted && <AcceptanceDetails disclaimer={disclaimer} />}
      {!accepted && !!onAccept && (
        <AcceptDisclaimer
          disclaimerCode={disclaimerCode}
          isAccepted={isAccepted}
          onAccept={onAccept}
        />
      )}
    </Panel>
  );
};

export default DisclaimerItem;
