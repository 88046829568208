import { Row as RowBsa } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Text = styled.div`
  font-size: 16px;
  color: ${colors.darkGrey};
  width: 100%;
`;

export const Row = styled(RowBsa)`
  margin-bottom: 30px;
`;
