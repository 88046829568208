import cn from 'classnames';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'redux-first-router-link';

import { EventItem } from '@/modules/data/dataTypes/eventList';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import RegisterButton from '@/modules/shared/components/RegisterButton';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { navigateToEventPage } from '@/pages/event/duck/actions';
import { navigateToIndividualReservationList } from '@/pages/individualReservationsList/duck/actions';
import { navigateToGroupReservationList } from '@/pages/reservationList/duck/actions';

import styles from './EventCardFooter.less';
import { ViewEventButton, ViewReservationsButton } from './EventCardFooter.styled';

interface Props {
  isLoggedIn: boolean;
  allowRegister: boolean;
  eventItem: EventItem;
}

const EventCardFooter: React.FC<Props> = ({ isLoggedIn, allowRegister, eventItem }) => {
  const dispatch = useDispatch();

  const {
    isCreator,
    isRegistrationComplete,
    isPublished,
    isActive,
    isLeadAdvisor,
    isAttendee,
    isSecondaryRegistrant,
    allowGroupRegistration,
    isRegistered,
    formRecordGUID,
    formCode,
    lastInProgressFormRecordGUID,
    lastInProgressGroupReservationGUID,
  } = eventItem;

  const allowViewEvent = useSelector(
    createAbilitiesSelector('event', PermissionAction.Read, eventItem),
  );

  const canShowReservationList = useMemo(() => {
    if (allowGroupRegistration && isRegistered && !!formRecordGUID) return true;
    return isRegistrationComplete || isLeadAdvisor || isAttendee || isSecondaryRegistrant;
  }, [
    allowGroupRegistration,
    isAttendee,
    isLeadAdvisor,
    isRegistered,
    isRegistrationComplete,
    isSecondaryRegistrant,
    formRecordGUID,
  ]);

  const onViewEvent = () => dispatch(navigateToEventPage({ formCode }));

  return (
    <div
      className={cn(styles.eventFooter, {
        [styles.standarUser]: !isLoggedIn || !(isCreator || allowViewEvent),
      })}
    >
      <div
        className={cn('text-center', styles.actionButtonsContainer, {
          'mt-4': isRegistrationComplete,
          [styles.center]: !allowViewEvent,
        })}
      >
        {allowViewEvent && (
          <ViewEventButton onClick={onViewEvent} ghost>
            <FormattedMessage id="events.EventsPage.EventsCard.viewEvent" />
          </ViewEventButton>
        )}
        {isLoggedIn && canShowReservationList && (
          <Link
            id={`viewReservationLink-${formCode}`}
            to={
              allowGroupRegistration
                ? navigateToGroupReservationList({ formCode })
                : navigateToIndividualReservationList({ formCode })
            }
          >
            <ViewReservationsButton type="primary" disabled={!isActive || !isPublished}>
              {`View ${allowGroupRegistration ? 'Reservations' : 'Registrations'}`}
            </ViewReservationsButton>
          </Link>
        )}
        {allowRegister && !canShowReservationList && (
          <RegisterButton
            event={eventItem}
            {...(allowGroupRegistration
              ? { isGroup: true, lastInProgressGroupReservationGUID, numberOfReservations: 0 }
              : { isGroup: false, lastInProgressFormRecordGUID, numberOfRegistrations: 0 })}
          />
        )}
      </div>
    </div>
  );
};

export default EventCardFooter;
