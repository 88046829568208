import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { FoundPerson } from '@/modules/data/dataTypes/foundPersons';
import { AttendeeType } from '@/modules/data/dataTypes/rosterList';
import { createDataSel, createErrorSel } from '@/modules/data/duck/selectors';

import { modulesPath } from '../constants';

import reducers from './reducers';

import { ADULT_AGE_LOW } from 'SHARED/constants';
import { dateFromString } from 'UTILS/dateFormats';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulesPath, {});

export const updateSelectedSessionInProgressSel = createSelector(
  moduleSel,
  ({ updateFormRegistrationSessionInProgress, updateGroupReservationSessionInProgress }) =>
    updateFormRegistrationSessionInProgress || updateGroupReservationSessionInProgress,
);

export const updateReservationSizeInProgressSel = createSelector(
  moduleSel,
  ({ updateReservationSizeInProgress }) => updateReservationSizeInProgress,
);

export const hasUserNameSel = createSelector(
  [createDataSel('foundPeople')],
  foundPeople => !!foundPeople && !!foundPeople[0] && !!foundPeople[0].ULUserName,
);

const attendeeTypeSel = (_state: RootState, { attendeeType }: { attendeeType: AttendeeType }) =>
  attendeeType;

export const invitationEligibilityMessagesSel = createSelector(
  [
    attendeeTypeSel,
    createDataSel('groupReservation'),
    createDataSel('foundPeople'),
    createErrorSel('foundPeople'),
  ],
  (attendeeType, { sessionStartDate }, foundPeople?: FoundPerson[], foundPeopleError?: Error) => {
    const { typeName, restrictions } = attendeeType;

    const messages: string[] = [];

    if (foundPeopleError || !foundPeople || !foundPeople.length) {
      return messages;
    }

    const { firstName, lastName, gender, dateOfBirth, isBSAMember } = foundPeople[0];

    const ageOnReservation = dateFromString(sessionStartDate).diff(
      dateFromString(dateOfBirth),
      'years',
    );
    const fullName = `${firstName} ${lastName}`;

    const {
      adultOnly = false,
      minAge = 0,
      maxAge = 0,
      gender: restrictionGender,
      isBSAMember: restrictionBSAMember = false,
    } = restrictions;

    if (adultOnly && ageOnReservation < ADULT_AGE_LOW) {
      messages.push(
        `Attendee type "${typeName}" must be an adult. ${fullName} is registered as youth in the BSA`,
      );
    }

    if (restrictionBSAMember && !isBSAMember) {
      messages.push(
        `Attendee type "${typeName}" requires an active BSA membership. ${fullName} has a my.scouting account but does not have an active BSA membership.`,
      );
    }

    if (
      gender &&
      restrictionGender.length &&
      !(restrictionGender as string[]).includes(gender.toLowerCase())
    ) {
      const parsedGender = gender.toLowerCase() === 'm' ? 'Male' : 'Female';
      const parsedRestrictionGender = get(restrictionGender, [0], '').includes('m')
        ? 'Male'
        : 'Female';
      messages.push(
        `Attendee type "${typeName}" requires ${parsedRestrictionGender} but ${fullName} is ${parsedGender}`,
      );
    }

    if (ageOnReservation < minAge) {
      messages.push(
        `The minimum age for attendee type “${typeName}” is ${minAge}, however ${fullName} will be age ${ageOnReservation} on the first day of the session.`,
      );
    }

    if (ageOnReservation > maxAge) {
      messages.push(
        `The maximum age for attendee type “${typeName}” is ${maxAge}, however ${fullName} will be age ${ageOnReservation} on the first day of the session.`,
      );
    }

    return messages;
  },
);

export const invitationEligibilitySel = createSelector(
  [
    invitationEligibilityMessagesSel,
    createDataSel('foundPeople'),
    createErrorSel('foundPeople'),
    createErrorSel('attendeeTypeList'),
    hasUserNameSel,
  ],
  (eligibilityMessages = [], foundPeople = [], foundPeopleError, attendeeTypesError, hasUserName) =>
    eligibilityMessages.length === 0 &&
    hasUserName &&
    foundPeople.length > 0 &&
    !foundPeopleError &&
    !attendeeTypesError,
);
