import { createAction, createAsyncAction } from 'typesafe-actions';

import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName, modulesPath } from '../constants';

const moduleNamespace = modulesPath.join('/');

export const updateInstallmentOpened = createAction(`${moduleNamespace}/UPDATE_INSTALLMENT_OPENED`)<
  PaymentCategory | Record<string, never>
>();

export const updateInstallmentClosed = createAction(
  `${moduleNamespace}/UPDATE_INSTALLMENT_CLOSED`,
)();

export const updateInstallment = createAsyncAction(
  `${moduleName}/UPDATE_INSTALLMENT_REQUEST`,
  `${moduleName}/UPDATE_INSTALLMENT_SUCCESS`,
  `${moduleName}/UPDATE_INSTALLMENT_FAILURE`,
)<PaymentCategory, undefined, ApiError>();

export const deleteInstallment = createAsyncAction(
  `${moduleName}/DELETE_INSTALLMENT_REQUEST`,
  `${moduleName}/DELETE_INSTALLMENT_SUCCESS`,
  `${moduleName}/DELETE_INSTALLMENT_FAILURE`,
)<string, undefined, ApiError>();
