import { Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateEvent } from '@/modules/entities/Events/duck/actions';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import { CostCenterLogic } from '@/modules/payments/hooks/types';
import {
  allowValueSelectionConditions,
  visibilityLogicTestOptions,
} from '@/modules/questions/constants';
import InputMaterial from '@/modules/shared/components/InputMaterial';
import SelectConditionValue from '@/modules/shared/components/SelectConditionValue';
import SelectInputMaterial from '@/modules/shared/components/SelectInputMaterial';
import { PresentationType } from '@/modules/shared/constants';
import toastService from '@/modules/toasts/service';
import { parseBase64 } from '@/modules/utils/stringFunctions';

import { specialVisibilityOptions } from '@/pages/createEvent/constants';
import { pageDataSel } from '@/pages/createEvent/duck/selectors';

import { stepDataSel } from '../../duck/selectors';

import {
  ClearFieldsButton,
  Container,
  MuiInputWrapper,
} from './UpdateCostCenterVisibilityModal.styled';
import { getUpdatedFormParts, getUpdatedOptionSets } from './utils';

const { Option, OptGroup } = Select;

const UpdateCostCenterVisibilityModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    data: { form, attendeeTypeList },
    isLoading,
  } = useSelector(pageDataSel);
  const {
    data: { formPartsData, optionSets },
  } = useSelector(stepDataSel);

  const [condition, setCondition] = useState('');
  const [conditionFormItemCode, setConditionFormItemCode] = useState<string>('');
  const [conditionFormPartCode, setConditionFormPartCode] = useState<string>('');
  const [conditionValue, setConditionValue] = useState<string>('');

  const allowValueSelection = allowValueSelectionConditions.includes(condition);
  const { costCenterConfiguration = '' } = form;
  const { formParts } = formPartsData;
  const fieldToTestValue =
    conditionFormItemCode && conditionFormPartCode
      ? [conditionFormPartCode, conditionFormItemCode].join('-')
      : '';
  const updatedFormParts = getUpdatedFormParts(formParts);
  const updatedOptionSets = getUpdatedOptionSets(optionSets, attendeeTypeList);
  const hasAllFieldsFilled = !!condition && !!conditionFormItemCode && !!conditionFormPartCode;
  const hasAllEmptyFields = !condition && !conditionFormItemCode && !conditionFormPartCode;
  const hasMissingFields = !(hasAllEmptyFields || hasAllFieldsFilled);

  const isCheckboxPresentationType =
    updatedFormParts
      .find(fp => fp.formPartCode === conditionFormPartCode)
      ?.formItems.find(fi => fi.formItemCode === conditionFormItemCode)?.presentationType ===
    PresentationType.CHECKBOX;

  useEffect(() => {
    if (!costCenterConfiguration) return;

    const config = parseBase64<CostCenterLogic>(costCenterConfiguration);

    const { formItemCode, formPartCode } = config || {};

    setConditionFormItemCode(formItemCode as string);
    setConditionFormPartCode(formPartCode as string);
    setCondition(config?.condition as string);
    setConditionValue(config?.conditionValue as string);
  }, [costCenterConfiguration]);

  const selectedFormItem = useMemo(() => {
    if (!conditionFormItemCode || !conditionFormPartCode) return;

    const formPart = updatedFormParts.find(
      ({ formPartCode }) => formPartCode === conditionFormPartCode,
    );

    return formPart?.formItems.find(({ formItemCode }) => formItemCode === conditionFormItemCode);
  }, [conditionFormItemCode, conditionFormPartCode, updatedFormParts]);

  const optionSetCode = selectedFormItem?.optionSetCode || '';
  const presentationType = selectedFormItem?.presentationType || PresentationType.TEXT;

  const optionSetItems = useMemo(() => {
    if (!optionSetCode) return [];

    const optionSet = updatedOptionSets[optionSetCode];
    return optionSet?.optionItems || [];
  }, [optionSetCode, updatedOptionSets]);

  const visibilityOptions = useMemo<Array<{ key: string; text: string }>>(() => {
    const specialOptions = conditionFormItemCode && specialVisibilityOptions[conditionFormItemCode];

    if (specialOptions) return specialOptions;

    return visibilityLogicTestOptions.filter(opt =>
      opt.presentationType.includes(presentationType),
    );
  }, [presentationType, conditionFormItemCode]);

  const handleChangeConditionValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConditionValue(event.target.value);
  };

  const handleClickApplyButton = () => {
    if (hasMissingFields) {
      toastService.error('Please fill all fields or clear them before saving');
      return;
    }

    const logicSettings = {
      condition,
      conditionValue: conditionValue || '',
      formItemCode: conditionFormItemCode,
      formPartCode: conditionFormPartCode,
    };

    let parsedSettings = '';

    if (hasAllFieldsFilled) {
      parsedSettings = window.btoa(JSON.stringify(logicSettings));
    }

    const updatedFormData = { ...form, costCenterConfiguration: parsedSettings };
    const customSuccessMessage = `Cost Center visibility logic ${
      parsedSettings ? 'updated' : 'cleared'
    }`;

    dispatch(
      updateEvent.request({
        updatedFormData,
        isUpdatingSpecificFormField: true,
        customSuccessMessage,
      }),
    );

    dispatch(closeModal());
  };

  const handleClickClearButton = () => {
    setConditionFormItemCode('');
    setConditionFormPartCode('');
    setCondition('');
    setConditionValue('');
  };

  const handleSelectCondition = (newCondition: string) => {
    setCondition(newCondition);
    setConditionValue('');
  };

  const handleSelectFormItem = (item: string) => {
    const [newFormPart, newFormItem] = item.split('-');
    setConditionFormItemCode(newFormItem);
    setConditionFormPartCode(newFormPart);
    setConditionValue('');
    setCondition('');
  };

  return (
    <ModalComponent
      buttons={[
        {
          disabled: hasMissingFields,
          title: 'APPLY',
          type: 'primary',
          onClick: handleClickApplyButton,
        },
      ]}
      inProgress={isLoading.form}
      description="Hide Cost Center option based on attendee type or by question answers."
      title="Cost Center Visibility Logic"
    >
      <Container>
        <SelectInputMaterial
          labelContent="Select field to test"
          dropdownMatchSelectWidth={false}
          value={fieldToTestValue}
          onChange={handleSelectFormItem}
        >
          {updatedFormParts.map(({ formPartCode, formPartName, formItems }) => (
            <OptGroup key={formPartCode} label={formPartName}>
              {formItems.map(({ formItemCode, formItemName }) => (
                <Option key={formItemCode} value={`${formPartCode}-${formItemCode}`}>
                  {formItemName}
                </Option>
              ))}
            </OptGroup>
          ))}
        </SelectInputMaterial>
        <SelectInputMaterial
          labelContent="Select Condition"
          dropdownMatchSelectWidth={false}
          value={condition}
          onChange={handleSelectCondition}
        >
          {visibilityOptions.map(({ key, text }) => (
            <Option key={key} value={key}>
              {text}
            </Option>
          ))}
        </SelectInputMaterial>
        {optionSetItems.length ? (
          <SelectConditionValue
            conditionValue={conditionValue}
            isCheckboxPresentationType={isCheckboxPresentationType}
            isDisabled={!allowValueSelection}
            optionSetItems={optionSetItems}
            onChangeValue={setConditionValue}
          />
        ) : (
          <MuiInputWrapper>
            <InputMaterial
              disabled={!allowValueSelection}
              labelContent="Select Value"
              labelSize="1"
              value={conditionValue}
              onChange={handleChangeConditionValue}
            />
          </MuiInputWrapper>
        )}
      </Container>
      <ClearFieldsButton ghost onClick={handleClickClearButton}>
        Clear Fields
      </ClearFieldsButton>
    </ModalComponent>
  );
};

export default UpdateCostCenterVisibilityModal;
