import { Button, Input } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, MutableRefObject, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import { removeData, fetchData, clearError } from '@/modules/data/duck/actions';
import { createIsLoadingSel, createErrorSel } from '@/modules/data/duck/selectors';
import { useAbilities } from '@/modules/user/duck/abilities';
import { Abilities } from '@/modules/user/types';
import { reservationCodeRegex } from '@/modules/utils/regularExpressions';

import { Form, FormItem, FoundPersonLayout, ButtonRow } from './FindPersonForm.styled';
import FoundPersonContent from './components/FoundPersonContent';
import {
  DATE_OF_BIRTH_FIELD,
  LAST_NAME_FIELD,
  MEMBER_ID_OR_RESERVATION_CODE_FIELD,
} from './constants';
import { useFoundPerson, useDisabledFutureDates } from './hooks';
import { FindPersonFormValues, FoundPeopleQuery } from './types';
import { getFoundPeopleQuery } from './utils';

import DatePicker from 'SHARED/components/DatePicker';

interface Props {
  isEventOwner?: boolean;
  formLayout?: FormLayout;
  pivotDate?: string;
  personInfoTitle?: string;
  renderFoundPersonRef?: MutableRefObject<null>;
  showEligibilityContent?: boolean;
  isEligible?: boolean;
  eligibilityMessages?: string[];
  onResetForm?: () => void;
  onSearchChange?: (queryObj: FoundPeopleQuery) => void;
}
const FindPersonForm: React.FC<Props> = ({
  isEventOwner,
  pivotDate,
  personInfoTitle,
  renderFoundPersonRef,
  showEligibilityContent = false,
  isEligible = false,
  eligibilityMessages = [],
  formLayout = 'vertical',
  onResetForm,
  onSearchChange,
}) => {
  const dispatch = useDispatch();
  const abilities: Abilities = useAbilities();

  const foundPerson = useFoundPerson();
  const disabledFutureDates = useDisabledFutureDates();

  const searchInProgress = useSelector(createIsLoadingSel('foundPeople'));
  const error = useSelector(createErrorSel('foundPeople'));

  const canUnrestrictedFindPerson = abilities.can({
    action: 'unrestrictedFindPerson',
    target: 'event',
    options: { isEventOwner },
  });

  useEffect(() => {
    resetForm();
    dispatch(clearError({ dataType: 'foundPeople' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleSearch = useCallback((formValues: FindPersonFormValues) => {
    const queryObj = getFoundPeopleQuery(formValues);
    if (onSearchChange) onSearchChange(queryObj);
    else dispatch(fetchData.request({ dataType: 'foundPeople', queryObj }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetForm = () => {
    dispatch(removeData({ dataType: 'foundPeople' }));
    onResetForm && onResetForm();
  };

  const handleValuesChange = () => {
    if (error || foundPerson) {
      resetForm();
    }
  };

  return (
    <Form layout={formLayout} onFinish={handleSearch} onValuesChange={handleValuesChange}>
      <FormItem
        label="Member ID or Personal Reservation Code"
        name={MEMBER_ID_OR_RESERVATION_CODE_FIELD}
        rules={[
          {
            pattern: reservationCodeRegex,
            message:
              'Personal Reservation Code should be 5 or 6 characters long, with a combination of letters and numbers like "A1B2C3". Member ID is typically 8-9 characters long containing only numbers.',
          },
          {
            required: true,
            message: 'Member ID or reservation code is required',
          },
        ]}
      >
        <Input size="large" />
      </FormItem>
      <FormItem
        name={LAST_NAME_FIELD}
        rules={[{ required: true, message: 'Last Name is required.' }]}
        label="Last Name"
      >
        <Input size="large" />
      </FormItem>
      {!canUnrestrictedFindPerson && (
        <FormItem
          name={DATE_OF_BIRTH_FIELD}
          rules={[
            {
              required: true,
              message: 'Date of birth is required if you are not a collaborator or owner.',
            },
          ]}
        >
          <DatePicker labelContent="Date Of Birth" disabledDate={disabledFutureDates} />
        </FormItem>
      )}
      <ButtonRow>
        <Button type="primary" htmlType="submit" size="large" loading={searchInProgress}>
          SEARCH
        </Button>
      </ButtonRow>
      {renderFoundPersonRef && (
        <Form.Item shouldUpdate>
          {({ isFieldsTouched }) => {
            if (!renderFoundPersonRef.current) return null;

            if (!isFieldsTouched() || searchInProgress) return null;

            return createPortal(
              <FoundPersonLayout
                direction={formLayout === 'inline' ? 'row' : 'column'}
                centered={formLayout !== 'inline'}
              >
                <FoundPersonContent
                  foundPerson={foundPerson}
                  pivotDate={pivotDate}
                  error={error}
                  title={personInfoTitle}
                  showEligibilityContent={showEligibilityContent}
                  isEligible={isEligible}
                  eligibilityMessages={eligibilityMessages}
                />
              </FoundPersonLayout>,
              renderFoundPersonRef.current,
            );
          }}
        </Form.Item>
      )}
    </Form>
  );
};

export default FindPersonForm;
