import { Collapse as CollapseBsa } from 'antd';
import styled from 'styled-components';

export const Collapse = styled(CollapseBsa)`
  margin-top: 15px;
  border-left: none;
  border-right: none;
  border-top: none;
  width: 100%;
`;
