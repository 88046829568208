import dayjs from 'dayjs';
import { curry, flow } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import { PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { dateFromString } from '@/modules/utils/dateFormats';

import {
  PAYMENT_SCHEDULE_STATUS_OVERDUE,
  PAYMENT_SCHEDULE_STATUS_PAID,
  PAYMENT_SCHEDULE_STATUS_UNPAID,
} from '@/pages/reservation/constants';

import { moduleName } from '../constants';

import reducers from './reducers';

const PAYMENT_CATEGORY_DEPOSIT_NAME = 'Deposit';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const isCancelingGroupReservationSel = createSelector(
  moduleSel,
  ({ isCancelingGroupReservation }) => isCancelingGroupReservation,
);

export const cancelIndividualReservationInProgressSel = createSelector(
  moduleSel,
  ({ cancelIndividualReservationInProgress }) => cancelIndividualReservationInProgress,
);

const updatePaymentCategoryDueDate = (pc: PaymentCategory): PaymentCategory => {
  if (pc.paymentCategoryName === PAYMENT_CATEGORY_DEPOSIT_NAME) {
    return { ...pc, dueDate: 'With Initial Reservation' };
  }
  return pc;
};
const updatePaymentCategoryAmount = curry(
  (paymentSummary: PaymentSummary, pc: PaymentCategory): PaymentCategory => {
    const { totalDeposit = 0, selectedDepositAddonFee, totalFee } = paymentSummary;
    const { paymentCategoryPercent } = pc;

    if (pc.paymentCategoryName === PAYMENT_CATEGORY_DEPOSIT_NAME) {
      return { ...pc, paymentCategoryAmount: totalDeposit + selectedDepositAddonFee };
    }

    if (paymentCategoryPercent !== 0) {
      const totalFeeCalculated = totalFee - totalDeposit - selectedDepositAddonFee;

      return { ...pc, paymentCategoryAmount: (totalFeeCalculated * paymentCategoryPercent) / 100 };
    }

    return pc;
  },
);
const updatePaymentCategoryDueAmountAndStatus = (
  paymentSummary: PaymentSummary,
  totalDueAmount: number,
  pc: PaymentCategory,
) => {
  const { amountPaid } = paymentSummary;
  const { dueDate, paymentCategoryAmount, paymentCategoryName } = pc;
  if (totalDueAmount <= amountPaid) {
    return {
      ...pc,
      dueAmount: 0,
      status: PAYMENT_SCHEDULE_STATUS_PAID,
    };
  }

  return {
    ...pc,
    dueAmount: Math.min(totalDueAmount - amountPaid, paymentCategoryAmount),
    status:
      dateFromString(dueDate).isBefore(dayjs(), 'day') ||
      paymentCategoryName === PAYMENT_CATEGORY_DEPOSIT_NAME
        ? PAYMENT_SCHEDULE_STATUS_OVERDUE
        : PAYMENT_SCHEDULE_STATUS_UNPAID,
  };
};

const paymentCategoriesSel = (
  _state: RootState,
  { paymentCategories }: { paymentCategories: PaymentCategory[] },
) => paymentCategories;

const paymentSummarySel = (
  _state: RootState,
  { paymentSummary }: { paymentSummary: PaymentSummary },
) => paymentSummary;

export const reservationPaymentCategoriesSel = createSelector(
  [paymentCategoriesSel, paymentSummarySel],
  (paymentCategories, paymentSummary) =>
    paymentCategories
      .slice()
      .sort((a, b) => {
        if (a.paymentCategoryName === 'Deposit') return -1;
        if (b.paymentCategoryName === 'Deposit') return 1;
        if (a.dueDate < b.dueDate) return -1;
        return 1;
      })
      .map(pc =>
        flow([updatePaymentCategoryDueDate, updatePaymentCategoryAmount(paymentSummary)])(pc),
      )
      .map((pc, index, arr) => {
        const totalDueAmount = arr
          .slice(0, index + 1)
          .reduce((acc, { paymentCategoryAmount }) => acc + paymentCategoryAmount, 0);

        return updatePaymentCategoryDueAmountAndStatus(paymentSummary, totalDueAmount, pc);
      }),
);
