import { ReportDataType } from '../reports/types';

export const moduleName = 'data';

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 20;

export const recordKeys: Record<ReportDataType, string> = {
  attendeeListReport: 'formRecordGUID',
  reservationListReport: 'groupReservationGUID',
  formPaymentData: 'sessionId',
};
