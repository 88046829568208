import { createPageDataParams } from '@/modules/data/utils';

export const moduleName = 'forms';

export const ROUTE_FORMS = `${moduleName}/ROUTE_FORM`;
export const ROUTE_FORMS_OPTIONAL_SEGMENT = '(personal-information)?';

export const pageDataParams = createPageDataParams(
  { dataType: 'form' },
  { dataType: 'jobList' },
  { dataType: 'reservationListReport' },
  { dataType: 'attendeeDetails' },
  { dataType: 'groupReservation' },
);

export enum stepKeys {
  personalInformation,
  attendeeTypes,
  sessionPriorities,
  addons,
  activities,
  jobPriorities,
  formSubmission,
  addPerson,
  checkout,
  confirmation,
  waitlistConfirmation,
}

export const steps = [
  {
    key: stepKeys.personalInformation,
    title: 'Personal Information',
    isForGroup: true,
  },
  {
    key: stepKeys.attendeeTypes,
    title: 'Attendee Types',
  },
  {
    key: stepKeys.sessionPriorities,
    title: 'Sessions',
    isForGroup: true,
  },
  {
    key: stepKeys.addons,
    title: 'Add-ons',
    isForGroup: true,
  },
  {
    key: stepKeys.activities,
    title: 'Activities',
    isForGroup: true,
  },
  {
    key: stepKeys.jobPriorities,
    title: 'Job Priorities',
  },
  {
    key: stepKeys.formSubmission,
    title: 'Form',
  },
  {
    key: stepKeys.addPerson,
    title: 'Add Person',
  },
  {
    key: stepKeys.checkout,
    title: 'Checkout',
    isForGroup: true,
  },
  {
    key: stepKeys.confirmation,
    title: 'Confirmation',
    isForGroup: true,
  },
  {
    key: stepKeys.waitlistConfirmation,
    title: 'Waitlist Confirmation',
    isForGroupOnly: true,
  },
];
