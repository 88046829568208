import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { updateFormJob } from './actions';

const updateFormJobInProgress = createReducer(false)
  .handleAction(updateFormJob.request, () => true)
  .handleAction([updateFormJob.success, updateFormJob.failure], () => false);

export default combineReducers({
  updateFormJobInProgress,
});
