import { Col, Form, Row } from 'antd';
import React, { forwardRef, useImperativeHandle } from 'react';

import { Person } from '@/modules/data/dataTypes/person';
import { phoneValidationRule } from '@/modules/entities/FormItems/utils';
import { emailRegex } from '@/modules/utils/regularExpressions';

import { ParentData as ParentDataType } from '../../types';

import CardWithHeader from 'SHARED/components/CardWithHeader';
import InputMaterial from 'SHARED/components/InputMaterial';
import InputPhone from 'SHARED/components/InputPhone';
import SelectInputMaterial from 'SHARED/components/SelectInputMaterial';
import { nameSuffixes } from 'SHARED/constants';

type Props = {
  person?: Person;
};

const ParentData = forwardRef<{ handleSubmit: () => Promise<ParentDataType> }, Props>(
  ({ person }, ref) => {
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));
    const [form] = Form.useForm<ParentDataType>();

    function handleSubmit() {
      return form.validateFields();
    }

    return (
      <Form form={form}>
        <CardWithHeader
          header="Parent Data"
          description="Your age is under 18. Please enter your responsible parent/guardian's data."
        >
          <Row gutter={24}>
            <Col xs={24} sm={12} md={6} lg={6}>
              <Form.Item
                initialValue={person?.guardianFirstName}
                name="parent.firstName"
                rules={[{ required: true, message: 'First name is required' }]}
              >
                <InputMaterial labelContent="First name" type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
              <Form.Item
                name="parent.lastName"
                initialValue={person?.guardianLastName}
                rules={[{ required: true, message: 'Last name is required' }]}
              >
                <InputMaterial labelContent="Last name" type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={2}>
              <Form.Item name="parent.suffix">
                <SelectInputMaterial
                  labelContent="Suffix"
                  options={[
                    { value: '', label: 'None' },
                    ...nameSuffixes.map(suffix => ({
                      value: suffix,
                      label: suffix,
                    })),
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6} lg={5}>
              <Form.Item
                name="parent.email"
                initialValue={person?.guardianEmailAddress}
                rules={[
                  { required: true, message: 'Email is required' },
                  { pattern: emailRegex, message: 'Email is not valid' },
                ]}
              >
                <InputMaterial labelContent="Email" type="text" placeholder="email@scouting.org" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={3}>
              <Form.Item
                name="parent.phone"
                rules={[{ required: true, message: 'Phone is required' }, phoneValidationRule]}
              >
                <InputPhone labelContent="Phone" placeholder="XXX-XXX-XXXX" />
              </Form.Item>
            </Col>
          </Row>
        </CardWithHeader>
      </Form>
    );
  },
);

export default ParentData;
