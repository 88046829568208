import { Form } from 'antd';
import styled from 'styled-components';

export const RichTextEditorRow = styled.div`
  display: flex;
  height: 100%;
  padding: 0;
  width: 100%;

  > *:first-child {
    flex-grow: 1;
    margin-right: 30px;
  }
`;

export const ContentFormItem = styled(Form.Item)`
  .ant-form-item-row {
    height: 100%;
  }
  .ant-form-item-control-input {
    height: 100%;
  }
  .ant-form-item-control-input-content {
    display: flex;
    height: 100%;
  }
`;
