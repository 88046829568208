import { useCallback } from 'react';

export const useHandleEdit = (
  moduleName: string,
  editingSectionName: string | null,
  onEdit: (sectionName: string | null) => void,
): {
  isEditing: boolean;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
} => {
  const isEditing = editingSectionName === moduleName;

  const handleStartEdit = useCallback(() => {
    onEdit(moduleName);
  }, [onEdit, moduleName]);

  const handleStopEdit = useCallback(() => {
    onEdit(null);
  }, [onEdit]);

  return {
    isEditing,
    handleStartEdit,
    handleStopEdit,
  };
};
