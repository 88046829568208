import React, { PropsWithChildren, useEffect, useState } from 'react';

import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import { usePrevious } from '@/modules/utils/hooks';

import { Card } from './Section.styled';

type Props = {
  isLoading: boolean;
};

const Section: React.FC<PropsWithChildren<Props>> = ({ children, isLoading }) => {
  const [initialLoadingInProgress, setInitialLoadingInProgress] = useState(true);
  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (!isLoading && typeof prevIsLoading === 'boolean') {
      setInitialLoadingInProgress(false);
    }
  }, [isLoading, prevIsLoading]);

  return (
    <Card loading={initialLoadingInProgress} bordered={false}>
      <LoadingContainer isLoading={isLoading}>{children}</LoadingContainer>
    </Card>
  );
};

export default Section;
