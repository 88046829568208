import { combineEpics, Epic } from 'redux-observable';
import { of, concat, defer, merge, EMPTY } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { scheduleRefresh } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { LEAD_ADVISOR_INVITE_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';
import { memberIdSel } from '@/modules/user/duck/selectors';

import { navigateToAddAttendee } from '@/pages/addAttendee/duck/actions';
import { rosterListSel } from '@/pages/reservation/tabs/OverviewGroup/duck/selectors';

import { ModalParams } from '../../LeadAdvisorInviteModal/types';

import { inviteLeadAdvisor, showLeadAdvisorInviteModal } from './actions';
import { leadTypeSel, userLeadAdvisorRestrictionsSel } from './selectors';

const ROSTER_DETAILS_DATA_ID = 'addAttendee';

const showLeadAdvisorInviteModal$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(showLeadAdvisorInviteModal)),
    switchMap(() => {
      const state = state$.value;
      const rosters = rosterListSel(state);
      const currentUserMemberId = memberIdSel(state);
      const reservationDetails = createDataSel('groupReservation')(state);

      if (!currentUserMemberId || !rosters || !rosters.length) return EMPTY;

      const leadType = leadTypeSel(state, { roster: rosters[0] });
      const { restrictions, typeName } = leadType;
      const leadTypeRestrictionsPass = userLeadAdvisorRestrictionsSel(
        restrictions,
        typeName,
      )(state);
      const { isReservationContact, isAdditionalReservationContact } = reservationDetails;

      const rostersWithoutLeadAdvisor = rosters.filter(
        ({ leadAdvisorMemberID }) => !leadAdvisorMemberID,
      );

      if (!rostersWithoutLeadAdvisor.length) return EMPTY;

      const isContact = isReservationContact || isAdditionalReservationContact;

      const canShowInvitation =
        isContact &&
        leadTypeRestrictionsPass.passed &&
        !rosters.some(({ leadAdvisorMemberID }) => leadAdvisorMemberID === currentUserMemberId);

      if (canShowInvitation) {
        const modalParams: ModalParams = {
          rosterList: rostersWithoutLeadAdvisor,
        };
        return of(openModal(LEAD_ADVISOR_INVITE_MODAL, modalParams));
      }

      return EMPTY;
    }),
  );

const inviteLeadAdvisor$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(inviteLeadAdvisor.request)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { memberID, roster, foundPerson } = payload;
      const { rosterCode } = roster;
      const { formCode } = createDataSel('form')(state);
      const leadAdvisorAttendeeType = leadTypeSel(state, { roster });

      const rosterList = rosterListSel(state);

      if (!leadAdvisorAttendeeType || !rosterList) return of(inviteLeadAdvisor.failure(undefined));

      const rosterWithThisLeadAdvisor = rosterList.find(
        ({ leadAdvisorMemberID }) => leadAdvisorMemberID === memberID,
      );
      if (rosterWithThisLeadAdvisor) {
        const { leadAdvisorFirstName, leadAdvisorLastName, rosterExpeditionNumber } =
          rosterWithThisLeadAdvisor;

        throw new Error(
          `${leadAdvisorFirstName} ${leadAdvisorLastName} is already the Lead Advisor of crew ${rosterExpeditionNumber} in this same reservation.`,
        );
      }

      const currentUserMemberId = memberIdSel(state);
      const isSameUser = memberID === currentUserMemberId;
      if (isSameUser) {
        const { passed, message } = userLeadAdvisorRestrictionsSel(
          leadAdvisorAttendeeType.restrictions,
          leadAdvisorAttendeeType.typeName,
        )(state);

        if (!passed) {
          throw new Error(message);
        }
      }

      return concat(
        prefetchData$(action$, {
          dataType: 'rosterAttendees',
          dataId: ROSTER_DETAILS_DATA_ID,
          queryObj: {
            formCode,
            rosterCode,
          },
          fetchData: true,
        }),
        defer(() => {
          const rosterAttendees = createDataSel(
            'rosterAttendees',
            ROSTER_DETAILS_DATA_ID,
          )(state$.value);

          const alreadyAttendee = rosterAttendees.some(
            m => m.memberId === memberID && !m.isArchived,
          );
          if (alreadyAttendee) {
            throw new Error('Selected user is already an attendee.');
          }

          const groupReservation = createDataSel('groupReservation')(state);
          const { reservationContact, additionalReservationContact } = groupReservation;

          let formRecordGUID;

          if (reservationContact.memberID === memberID) {
            formRecordGUID = reservationContact.formRecordGUID;
          } else if (additionalReservationContact.memberID === memberID) {
            formRecordGUID = additionalReservationContact.formRecordGUID;
          }

          return of(
            closeModal(),
            scheduleRefresh({ dataType: 'groupReservationRosterList' }),
            inviteLeadAdvisor.success(),
            navigateToAddAttendee({
              personExists: true,
              personGUID: foundPerson.personGUID,
              formCode,
              rosterCode,
              attendeeType: leadAdvisorAttendeeType,
              reservationDetails: groupReservation,
              formRecordGUID,
              positionType: 'LeadAdvisor',
              isSameUser,
            }),
          );
        }),
      );
    }),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return merge(of(inviteLeadAdvisor.failure(error)), caught);
    }),
  );

export default combineEpics(inviteLeadAdvisor$, showLeadAdvisorInviteModal$);
