import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { moduleName as currentModuleName } from '../constants';

import reducers from './reducers';

const selectorOptions = {
  memoizeOptions: {
    maxSize: 2,
  },
};

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[currentModuleName];

const moduleNameSel = (_state: RootState, props: { moduleName: string }) => props.moduleName;

const stepIndexesSel = createSelector(
  [moduleSel, moduleNameSel],
  ({ stepIndex }, moduleName) => stepIndex[moduleName],
);

export const currentStepIndexSel = createSelector(
  stepIndexesSel,
  stepIndexes => stepIndexes?.current,
  selectorOptions,
);
export const futureStepIndexSel = createSelector(
  stepIndexesSel,
  stepIndexes => stepIndexes?.future || 0,
  selectorOptions,
);
