import { PlusCircleFilled } from '@ant-design/icons';
import { Button as BsaButton, Divider as AntDivider } from 'antd';
import React from 'react';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const FieldsWrapper = styled.div`
  position: relative;
  z-index: 0;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  background-color: white;
  border: solid 1px #cccccc;
  border-radius: 4px;
  padding: 20px 0 10px 10px;
  margin-bottom: 20px;
  position: relative;

  > button:last-child {
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    color: red;
  }

  :hover {
    > button:last-child {
      visibility: visible;
    }
  }
`;

export const AddFilterButton = styled(BsaButton).attrs(() => ({
  type: 'text',
  icon: <PlusCircleFilled />,
}))`
  background-color: transparent;
  height: fit-content;
  font-size: 16px;
  display: flex;
  align-items: center;
  border: none;

  :hover,
  :focus {
    color: unset;
    background-color: transparent;
  }

  .anticon {
    font-size: 20px;
  }
`;

export const AddSectionButton = styled(AddFilterButton)`
  margin-left: auto;
  margin-right: auto;
  color: ${colors.lightBlue};

  :hover,
  :focus {
    color: ${colors.lightBlue};
  }
`;

export const Divider = styled(AntDivider)`
  width: calc(100% - 35px);
  min-width: 0;
  margin: 12px 0 10px;
`;

export const Line = styled.div`
  position: absolute;
  top: 5px;
  left: 24px;
  width: 3px;
  height: calc(100% - 70px);
  background-color: #eeeeee;
  z-index: -1;
`;
