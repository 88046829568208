import styled from 'styled-components';

export const ButtonContainer = styled.div`
  height: 40px;
  width: 100%;

  & > div > div > svg {
    font-family:
      'applied-button-font-0',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif !important;

    & .appleButton > svg > g > svg > text {
      font-family:
        'applied-button-font-0',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Open Sans',
        'Helvetica Neue',
        sans-serif !important;
    }
  }
`;
