import { produce } from 'immer';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { Notice, Notification } from '../types';

import { addNotice, addNotification, clearNotice, markAsReadNotification } from './actions';

const notifications = createReducer<Map<string, Notification>>(new Map())
  .handleAction(addNotification, (state, { payload }) =>
    produce(state, draft => {
      draft.set(payload.id, payload);
    }),
  )
  .handleAction(markAsReadNotification, (state, { payload }) =>
    produce(state, draft => {
      const notification = draft.get(payload);
      if (notification) {
        notification.read = true;
      }
    }),
  );

const notice = createReducer<Notice | null>(null)
  .handleAction(addNotice, (_state, { payload }) => payload)
  .handleAction(clearNotice, () => null);

export default combineReducers({
  notifications,
  notice,
});
