import { Button as AddButton, DatePicker as DatePickerAntd } from 'antd';
import styled from 'styled-components';

export const Button = styled(AddButton)`
  border: none;
`;

export const DatePicker = styled(DatePickerAntd)`
  width: 100%;
`;
