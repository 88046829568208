import { Input, Button } from 'antd';
import React, { useState, useEffect } from 'react';

const DebugDelayTimeout: React.FC = () => {
  const [delay, setDelay] = useState(0);
  const [finished, setFinished] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  let timeout: NodeJS.Timeout | null = null;

  // clear timeout if component unmonts
  useEffect(() => {
    timeout && clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimeoutStart = () => {
    setInProgress(true);
    timeout = setTimeout(() => {
      setFinished(true);
      setInProgress(false);
      timeout = null;
    }, delay);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = Number(e.target.value);

    if (isNaN(time)) {
      return;
    }

    setDelay(time);
  };

  if (finished) {
    return <div>FINISHED DELAY</div>;
  }

  if (inProgress) {
    return null;
  }

  return (
    <Input
      id="debug-delay-input"
      onChange={handleInputChange}
      addonAfter={
        <Button id="debug-delay-button" type="primary" size="small" onClick={handleTimeoutStart}>
          Start delay
        </Button>
      }
    />
  );
};
export default DebugDelayTimeout;
