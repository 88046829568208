import { InfoCircleFilled } from '@ant-design/icons';
import { Button as BsaButton } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const BroadcastMessageWrapper = styled.div`
  background-color: rgba(6, 126, 235, 0.1);
  display: flex;
  align-items: center;
  padding: 5px 2.4rem 5px 18px;
  border-bottom: 4px solid ${colors.lightBlue};
`;

export const Icon = styled(InfoCircleFilled)`
  margin-right: 10px;
  font-size: 20px;
  color: ${colors.lightBlue};
`;

export const Button = styled(BsaButton).attrs(() => ({
  type: 'link',
}))`
  min-width: 0;
  padding-right: 0;
  margin-left: auto;
`;
