import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeData } from '@/modules/data/duck/actions';
import { saveActivitiesInProgressSel } from '@/modules/entities/Activities/duck/selectors';
import { saveOptionsInProgressSel } from '@/modules/entities/Addons/duck/selectors';
import { updateFormRecordInProgressSel } from '@/modules/entities/FormRecord/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import Stepper from '@/modules/shared/components/Stepper';
import { ValueOf } from '@/modules/utils/typeUtils';

import { secondaryRegistrantStepKeys, SECONDARY_REGISTRANT_DATA_ID } from './constants';
import { openPrevStep, openStep } from './duck/actions';
import {
  addAdditionalRegistrantInProgressSel,
  disabledStepsSel,
  stepIndexSel,
  stepIsLoadingSel,
  stepKeySel,
  stepsSel,
} from './duck/selectors';
import ActivitiesStep from './steps/ActivitiesStep';
import AddonsStep from './steps/AddonsStep';
import AttendeeTypesStep from './steps/AttendeeTypesStep';
import CheckoutStep from './steps/Checkout';
import FindPerson from './steps/FindPersonStep';
import QuestionsStep from './steps/QuestionsStep';
import { AddSecondaryRegistrantModalParams } from './types';

const stepComponents: Record<
  ValueOf<typeof secondaryRegistrantStepKeys>,
  React.ForwardRefExoticComponent<
    React.RefAttributes<{
      handleSubmit: () => void;
    }>
  >
> = {
  [secondaryRegistrantStepKeys.findPerson]: FindPerson,
  [secondaryRegistrantStepKeys.questions]: QuestionsStep,
  [secondaryRegistrantStepKeys.attendeeTypes]: AttendeeTypesStep,
  [secondaryRegistrantStepKeys.addons]: AddonsStep,
  [secondaryRegistrantStepKeys.activities]: ActivitiesStep,
  [secondaryRegistrantStepKeys.checkout]: CheckoutStep,
};

interface Props {
  modalParams: AddSecondaryRegistrantModalParams;
}

const AddSecondaryRegistrantModal: React.FC<Props> = ({ modalParams: { finalActions } }) => {
  const stepComponentRef = useRef<{ handleSubmit: () => void }>(null);
  const dispatch = useDispatch();
  const stepKey = useSelector(stepKeySel);
  const stepIndex = useSelector(stepIndexSel);

  const steps = useSelector(stepsSel);
  const addAdditionalRegistrantInProgress = useSelector(addAdditionalRegistrantInProgressSel);
  const updateFormRecordInProgress = useSelector(updateFormRecordInProgressSel);
  const saveAddonsInProgress = useSelector(saveOptionsInProgressSel);
  const saveActivitiesInProgress = useSelector(saveActivitiesInProgressSel);
  const stepIsLoading = useSelector(stepIsLoadingSel);
  const disabledSteps = useSelector(disabledStepsSel);

  function handleOpenPrevStep() {
    dispatch(openPrevStep());
  }

  function handleOpenNextStep() {
    stepComponentRef.current?.handleSubmit();
  }

  useEffect(
    () => {
      dispatch(removeData({ dataType: 'foundPeople' }));
      dispatch(removeData({ dataType: 'arnicaPerson' }));
      dispatch(openStep());
      return () => {
        // actually executing on modal close: due to the double arrow function, it
        // runs on component cleanup
        finalActions &&
          finalActions.forEach(action => {
            dispatch(action);
          });
        dispatch(removeData({ dataType: 'attendeeDetails', dataId: SECONDARY_REGISTRANT_DATA_ID }));
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderStepComponent = useCallback(() => {
    const StepComponent = stepComponents[stepKey];

    if (StepComponent) {
      return <StepComponent ref={stepComponentRef} />;
    }

    return null;
  }, [stepKey]);

  return (
    <ModalComponent
      title="Add Secondary Registrant"
      buttons={[
        { title: 'Back', onClick: handleOpenPrevStep, disabled: stepIndex === 0 },
        {
          title: 'Next',
          onClick: handleOpenNextStep,
          type: 'primary',
          style: { marginLeft: 'auto' },
        },
      ]}
    >
      <Stepper stepIndex={stepIndex} steps={steps} disabledSteps={disabledSteps} />
      <LoadingContainer
        isLoading={
          stepIsLoading ||
          addAdditionalRegistrantInProgress ||
          updateFormRecordInProgress ||
          saveAddonsInProgress ||
          saveActivitiesInProgress
        }
      >
        {renderStepComponent()}
      </LoadingContainer>
    </ModalComponent>
  );
};

export default AddSecondaryRegistrantModal;
