import React, { useMemo } from 'react';
import { RenderLeafProps } from 'slate-react';

import { leafTypesByTag, leafStyles } from '@/modules/richTextEditor/constants';

type OptionalProps = 'attributes' | 'text';

type Props = Omit<RenderLeafProps, OptionalProps> & Partial<Pick<RenderLeafProps, OptionalProps>>;

const Leaf: React.FC<Props> = ({ attributes, children, leaf }) => {
  const styles = useMemo(
    () =>
      leafStyles.reduce((acc, item) => {
        if (leaf[item]) {
          return { ...acc, [item]: leaf[item] };
        }
        return acc;
      }, {}),
    [leaf],
  );

  const renderedChildren = useMemo(
    () =>
      Object.entries(leafTypesByTag).reduce((acc, [TagName, leafType]) => {
        if (leaf[leafType]) {
          const Tag = TagName as React.ElementType;
          return <Tag>{acc}</Tag>;
        }
        return acc;
      }, children),
    [children, leaf],
  );

  return (
    <span {...attributes} style={styles}>
      {renderedChildren}
    </span>
  );
};

export default Leaf;
