import { Form, Input } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import { masquerade } from '@/modules/user/duck/actions';
import { masqueradeInProgressSel } from '@/modules/user/duck/selectors';

const MasqueradeModal: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const isLoading = useSelector(masqueradeInProgressSel);

  function handleSubmit() {
    form.validateFields().then((values: { memberId: string }) => {
      dispatch(masquerade.request(values.memberId));
    });
  }

  function handleClose() {
    dispatch(closeModal());
  }

  const buttons = [
    {
      title: 'Close',
      onClick: handleClose,
    },
    {
      title: 'Ok',
      onClick: handleSubmit,
    },
  ];

  return (
    <ModalComponent title="Masquerade" buttons={buttons} inProgress={isLoading}>
      <Form form={form} layout="vertical">
        <Form.Item
          name="memberId"
          label="Member Id"
          rules={[
            {
              required: true,
              message: 'Please input the member Id',
            },
            {
              pattern: /^\d*$/,
              message: 'Only numbers allowed',
            },
          ]}
        >
          <Input placeholder="Member Id" />
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default MasqueradeModal;
