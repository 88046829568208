import React from 'react';

import { ActiveColorIcon, ResetColorIcon } from './ColorIcon.styled';

type Props = {
  activeColor?: string;
  resetColor: string;
  color: string;
};

const ColorIcon: React.FC<Props> = ({ color, activeColor, resetColor }) => {
  switch (color) {
    case activeColor:
      return <ActiveColorIcon />;
    case resetColor:
      return <ResetColorIcon />;
  }

  return null;
};

export default ColorIcon;
