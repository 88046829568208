import { Row as AntRow } from 'antd';
import styled from 'styled-components';

export const Row = styled(AntRow)`
  > *:last-child {
    visibility: hidden;
  }

  :hover {
    > *:last-child {
      visibility: visible;
    }
  }
`;

export const HeadersRow = styled(AntRow)`
  font-weight: bold;
  margin-bottom: 20px;
`;
