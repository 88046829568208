import { List } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const ListItem = styled(List.Item)<{
  isHeader?: boolean;
  isSelected?: boolean;
}>`
  background-color: ${({ isHeader, isSelected }) => {
    if (isHeader) return '#e6e6e6';
    if (isSelected) return '#F4F7FA';
    return 'white';
  }};

  && {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const ListItemMeta = styled(List.Item.Meta)`
  &&& {
    margin-bottom: 0;

    .ant-list-item-meta-title {
      margin-bottom: 0;
    }
  }
`;

export const Title = styled.span`
  font-weight: bold;
  color: ${colors.lightBlue};
  flex-shrink: 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
