import { FormInstance, Form } from 'antd';
import { unset } from 'lodash';
import React, { useEffect } from 'react';

import { QueryFilterVariable } from '@/modules/data/dataTypes/queryFilterVariableList';
import { OperatorValues } from '@/modules/reports/types';
import { valueInputIsHidden } from '@/modules/reports/utils';
import { usePrevious } from '@/modules/utils/hooks';

import { FILTER_EXPRESSION_VALUE } from '../../../../../../constants';
import { Path } from '../../../../../../types';
import { FormItem } from '../../Expression.styled';

import ValueInput from './components/ValueInput';

type Props = {
  queryFilterVariable?: QueryFilterVariable;
  name: number;
  selectedOperator?: OperatorValues;
  fieldPath: Path;
  form: FormInstance;
};

const SelectValue: React.FC<Props> = ({
  queryFilterVariable,
  name,
  selectedOperator,
  form,
  fieldPath,
}) => {
  const prevSelectedOperator = usePrevious(selectedOperator);

  const fieldValue = Form.useWatch(fieldPath, form);

  useEffect(() => {
    if (prevSelectedOperator && prevSelectedOperator !== selectedOperator) {
      const allValues = form.getFieldsValue();
      unset(allValues, fieldPath);
      form.setFieldsValue(allValues);
    }

    // 'fieldPath' dependency causes 'Maximum update depth exceeded' error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperator, form, prevSelectedOperator]);

  if (valueInputIsHidden(fieldValue, queryFilterVariable?.presentationType, selectedOperator)) {
    return null;
  }

  return (
    <FormItem
      name={[name, FILTER_EXPRESSION_VALUE]}
      rules={[{ required: true, message: 'Value is required' }]}
      $showLine
    >
      <ValueInput selectedOperator={selectedOperator} queryFilterVariable={queryFilterVariable} />
    </FormItem>
  );
};

export default SelectValue;
