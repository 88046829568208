import dayjs from 'dayjs';
import React, { Fragment } from 'react';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { Attendee } from '@/modules/data/dataTypes/rosterAttendees';
import Desktop from '@/modules/responsiveSizes/Desktop';
import InfoBubble from '@/modules/shared/components/InfoBubble';
import { dateFromString, usDate } from '@/modules/utils/dateFormats';

type Props = {
  attendee: Attendee;
  reservationDetails: GroupReservation;
};

const AttendeeAge: React.FC<Props> = ({ attendee, reservationDetails }) => {
  const { sessionStartDate } = reservationDetails;
  const { dateOfBirth, fullName } = attendee;
  const currentAge = dayjs().diff(dateFromString(dateOfBirth), 'years');

  const ageOnReservationDate = dateFromString(sessionStartDate).diff(
    dateFromString(dateOfBirth),
    'years',
  );

  return (
    <span>
      {ageOnReservationDate}
      <Desktop>
        <InfoBubble>
          <Fragment>
            {fullName} is currently age {currentAge} and will be age {ageOnReservationDate} when the
            session starts on {usDate(sessionStartDate)}
          </Fragment>
        </InfoBubble>
      </Desktop>
    </span>
  );
};

export default AttendeeAge;
