import React, { createRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PersonalInfoForm from '@/modules/shared/components/PersonalInfoForm';
import { IndividualReservationPersonFormData } from '@/modules/shared/components/PersonalInfoForm/types';

import ButtonsRow from '../../components/ButtonRow';
import { collectFormParts, collectRegistrationData } from '../../duck/actions';
import { isLoadingSel } from '../../duck/selectors';

import { updatePersonalInformation } from './duck/actions';

const PersonalInformation: React.FC = () => {
  const dispatch = useDispatch();
  const formRef = createRef<{ handleSubmit: () => Promise<IndividualReservationPersonFormData> }>();
  const isLoading = useSelector(isLoadingSel);

  const triggerSubmit = () => {
    formRef.current?.handleSubmit().then(personFormValues => {
      const { formParts, individualRegistrationInitialSettings, personData } = personFormValues;
      formParts && dispatch(collectFormParts(formParts));
      dispatch(collectRegistrationData(individualRegistrationInitialSettings));
      dispatch(updatePersonalInformation.request(personData));
    });
  };

  return (
    <Fragment>
      <PersonalInfoForm ref={formRef} isGroup={false} />
      <ButtonsRow isLoading={isLoading} onNextClick={triggerSubmit} backButtonDisabled />
    </Fragment>
  );
};

export default PersonalInformation;
