import { combineEpics, Epic } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData } from '@/modules/data/duck/actions';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { updateFormJob } from './actions';
import services from './services';

const updateFormJob$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(updateFormJob.request)),
    switchMap(({ payload: { formCode, jobsToUpdate, successMessage, finalActions = [] } }) => {
      const jobsToUpdateWithFormCode = jobsToUpdate.map(job => ({ ...job, formCode }));

      return forkJoin(jobsToUpdateWithFormCode.map(j => services.updateFormJob$(j))).pipe(
        switchMap(() => {
          if (successMessage) toastService.success(successMessage);
          return of(
            refreshData({ dataType: 'formRecordJobs' }),
            updateFormJob.success(),
            ...finalActions,
          );
        }),
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(updateFormJob.failure(error));
        }),
      );
    }),
  );

export default combineEpics(updateFormJob$);
