import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React, { PropsWithChildren } from 'react';

import { Icon } from './InfoBubble.styled';

type Props = {
  noMargin?: boolean;
  placement?: TooltipPlacement;
  isDanger?: boolean;
  className?: string;
};

const InfoBubble: React.FC<PropsWithChildren<Props>> = ({
  children,
  noMargin,
  placement,
  isDanger,
  className,
}) => (
  <Tooltip className={className} title={children} placement={placement}>
    <Icon $noMargin={noMargin} $isDanger={isDanger} />
  </Tooltip>
);

InfoBubble.defaultProps = {
  noMargin: false,
  placement: 'top',
};

export default InfoBubble;
