import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { closeModal, openModal } from '@/modules/modals/duck/actions';
import { resetOnAction } from '@/modules/utils/resetOnLogoutReducer';

import { moduleName as AddPersonModalModuleName } from '../components/AddSecondaryRegistrantModal/constants';
import AddPersonModalReducers from '../components/AddSecondaryRegistrantModal/duck/reducers';

import { confirmRegistration } from './actions';

const confirmRegistrationInProgress = createReducer(false)
  .handleAction(confirmRegistration.request, () => true)
  .handleAction([confirmRegistration.success, confirmRegistration.failure], () => false);

const confirmRegistrationError = createReducer(false)
  .handleAction(confirmRegistration.failure, () => true)
  .handleAction([confirmRegistration.request, confirmRegistration.success], () => false);

export default combineReducers({
  [AddPersonModalModuleName]: resetOnAction(AddPersonModalReducers, openModal, closeModal),
  confirmRegistrationInProgress,
  confirmRegistrationError,
});
