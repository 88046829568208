import React from 'react';
import { useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { PaymentMethod } from '@/modules/payments/constants';

import ReservationPayments from '../../components/ReservationPayments';
import { pageDataSel } from '../../duck/selectors';

import { tabDataSel } from './duck/selector';

type GroupProps = {
  isGroup: true;
  groupReservation: GroupReservation;
};

type IndividualProps = {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};

type Props = (GroupProps | IndividualProps) & {
  form: FormSettings;
  enabledPaymentMethods: Set<PaymentMethod>;
};

const PaymentsTab: React.FC<Props> = props => {
  const {
    data: { payments },
  } = useSelector(tabDataSel);
  const {
    data: { paymentCategories, paymentSummary },
  } = useSelector(pageDataSel);

  return (
    <ReservationPayments
      {...props}
      paymentCategories={paymentCategories}
      payments={payments}
      paymentSummary={paymentSummary}
    />
  );
};

export default PaymentsTab;
