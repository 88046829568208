import { combineEpics, Epic } from 'redux-observable';
import { merge, of, race } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { scheduleRefresh } from '@/modules/data/duck/actions';
import { updatePerson } from '@/modules/entities/Person/duck/actions';
import toastService from '@/modules/toasts/service';

import { openNextStep } from '../../../duck/actions';

import { updatePersonalInformation } from './actions';

const updatePersonalInformation$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(updatePersonalInformation.request)),
    switchMap(({ payload }) =>
      merge(
        race(
          action$.pipe(
            filter(isActionOf(updatePerson.failure)),
            take(1),
            switchMap(({ payload: { message } }) => {
              throw new Error(message);
            }),
          ),
          action$.pipe(
            filter(isActionOf(updatePerson.success)),
            take(1),
            switchMap(({ payload: personGUID }) =>
              of(
                scheduleRefresh({ dataType: 'arnicaPerson' }),
                updatePersonalInformation.success(personGUID),
                openNextStep(),
              ),
            ),
          ),
        ),
        of(updatePerson.request({ ...payload, isLocal: !payload.personGUID || payload.isLocal })),
      ),
    ),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return merge(of(updatePersonalInformation.failure(error)), caught);
    }),
  );

export default combineEpics(updatePersonalInformation$);
