import { Avatar } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Container = styled.div`
  padding: 1em;
`;

export const StyledAvatar = styled(Avatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: ${colors.primaryColor};
`;

export const CommentText = styled.p`
  padding: 1em;
  background-color: ${colors.lighterGrey};
  border-radius: 4px;
`;
