import { Locale } from 'antd/lib/locale';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { fetchMessages, getBsaLocales } from './actions';

const messages = createReducer<null | Record<string, string>>(null).handleAction(
  fetchMessages.success,
  (_state, { payload }) => payload,
);

const bsaLocales = createReducer<null | Locale>(null).handleAction(
  getBsaLocales.success,
  (_state, { payload }) => payload,
);

export default combineReducers({
  messages,
  bsaLocales,
});
