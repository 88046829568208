import React from 'react';

import styles from './WarningTooltip.less';

type Props = {
  attendeeTypeNames: string[];
};

const WarningTooltip: React.FC<Props> = ({ attendeeTypeNames }) => (
  <ul className={styles.warningTooltip}>
    <li>Minimum capacity not met.</li>
    {attendeeTypeNames.map(typeName => (
      <li key={typeName}>{`Not Enough ${typeName}`}</li>
    ))}
  </ul>
);

export default WarningTooltip;
