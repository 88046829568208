import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createDataSel } from '@/modules/data/duck/selectors';

import { modulePath } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulePath);

export const currentFormSessionCodeSel = createSelector(
  [moduleSel],
  ({ selectedSessions }) => selectedSessions[0],
);

export const currentSessionSel = createSelector(
  [currentFormSessionCodeSel, createDataSel('sessionList')],
  (currentSessionCode, sessions) =>
    sessions.find(({ sessionCode }) => sessionCode === currentSessionCode),
);

export const updateSessionsInProgressSel = createSelector(
  moduleSel,
  ({ updateSessionsInProgress }) => updateSessionsInProgress,
);

export const joinWaitlistInProgressSel = createSelector(
  moduleSel,
  ({ joinWaitlistInProgress }) => joinWaitlistInProgress,
);

export const groupFeesSel = createSelector(moduleSel, ({ sessionsFees }) => sessionsFees);

export const loadingSessionFeesSel = createSelector(
  moduleSel,
  ({ getGroupSessionFeeInProgress }) => getGroupSessionFeeInProgress,
);
