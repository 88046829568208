import { Card as BsaCard, Button } from 'antd';
import styled from 'styled-components';

export const Centered = styled.div`
  margin: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const LargeText = styled.p`
  font-size: 2rem;
`;

export const Card = styled(BsaCard)`
  margin: 1rem;
`;

export const BigButton = styled(Button)`
  height: 40px;
  padding: 0 4rem;
`;
