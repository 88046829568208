import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Subtitle = styled.h2`
  font-size: 16px;
  color: ${colors.scoutingBlue};
  font-weight: bold;
  margin-top: 16px;
`;

export const CenteredText = styled.div`
  text-align: center;
`;
