import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { PermissionCode } from '@/modules/entities/Roles/constants';
import { ConditionalOperatorsTypes, logicValidators } from '@/modules/questions/constants';
import { hasAssignedPermissionWithCode } from '@/modules/user/duck/utils';
import { parseBase64 } from '@/modules/utils/stringFunctions';

import { PaymentMethod } from '../constants';

import { CostCenterLogic } from './types';

type Params = {
  form: FormSettings;
  availableMethods: PaymentMethod[];
  allowedMethods: PaymentMethod[];
} & ({ attendeeDetails: AttendeeDetails } | { groupReservation: GroupReservation });

export default ({ form, availableMethods, allowedMethods, ...params }: Params) => {
  const { assignedRoles, isCreator } = form;

  const hasPostPaymentsPermission = hasAssignedPermissionWithCode(
    assignedRoles,
    PermissionCode.PostPayments,
  );

  return new Set(
    availableMethods.filter(method => {
      if (hasPostPaymentsPermission || isCreator) return true;

      if (!allowedMethods.includes(method)) return false;

      if (method === PaymentMethod.costCenter) {
        const { costCenterConfiguration } = form;

        if (!costCenterConfiguration) return true;

        const { formItemCode, formPartCode, condition, conditionValue } =
          parseBase64<CostCenterLogic>(costCenterConfiguration) || {};

        const logicValidator = logicValidators[condition as ConditionalOperatorsTypes];

        if (typeof logicValidator !== 'function') return false;

        let isHidden = false;
        let userValue = '';

        if ('attendeeDetails' in params) {
          const { typeCode, formParts } = params.attendeeDetails;
          if (formPartCode === 'attendeeType') {
            userValue = typeCode;
          } else {
            const formItem = formParts
              .find(fp => fp.formPartCode === formPartCode)
              ?.formItems.find(fi => fi.formItemCode === formItemCode);
            userValue = formItem?.formItemValue?.toString() || '';
          }

          isHidden = logicValidator(userValue, conditionValue as string);
        }
        return !isHidden;
      }
      return true;
    }),
  );
};
