import { createPageDataParams } from '@/modules/data/utils';
import { PaymentMethod } from '@/modules/payments/constants';

export const moduleName = 'reservationPage';

export const ROUTE_INDIVIDUAL_RESERVATION_PREFIX = `${moduleName}/ROUTE_INDIVIDUAL_RESERVATION`;
export const ROUTE_GROUP_RESERVATION_PREFIX = `${moduleName}/ROUTE_GROUP_RESERVATION`;

export const ROUTE_RESERVATION_CONTACT_INVITE_VALIDATION =
  'route/ROUTE_RESERVATION_CONTACT_INVITE_VALIDATION';
export const ROUTE_RESERVATION_EDIT_GROUP_ADDONS = 'route/ROUTE_RESERVATION_EDIT_GROUP_ADDONS';
export const ROUTE_RESERVATION_EDIT_GROUP_ACTIVITIES =
  'route/ROUTE_RESERVATION_EDIT_GROUP_ACTIVITIES';
export const ROUTE_RESERVATION_EDIT_INDIVIDUAL_ACTIVITIES =
  'route/ROUTE_RESERVATION_EDIT_INDIVIDUAL_ACTIVITIES';
export const ROUTE_RESERVATION_EDIT_INDIVIDUAL_ADDONS =
  'route/ROUTE_RESERVATION_EDIT_INDIVIDUAL_ADDONS';

export const ROUTE_ATTENDEE_DETAILS = 'routes/ROUTE_ATTENDEE_DETAILS';

export const STARTED_PAYMENT_STATUS = 'Payment started';

export const PAYMENT_SCHEDULE_STATUS_OVERDUE = 'overdue';
export const PAYMENT_SCHEDULE_STATUS_UNPAID = 'unpaid';
export const PAYMENT_SCHEDULE_STATUS_PAID = 'paid';

export const VALIDATE_RESERVATION_CONTACT_INVITE_ACCEPT = 'accept';
export const VALIDATE_RESERVATION_CONTACT_INVITE_REJECT = 'decline';
export const VALIDATE_RESERVATION_CONTACT_INVITE_ERROR_STATUS = 'error';

export enum TabKey {
  Overview = 'overview',
  AttendeeDetails = 'attendeeDetails',
  Payment = 'payment',
  Disclaimers = 'disclaimers',
  Logs = 'logs',
}

export const nonFreeAgentJobsAmount = 3;

export const tabs: { key: TabKey; label: string }[] = [
  { key: TabKey.Overview, label: 'Reservation Overview' },
  { key: TabKey.AttendeeDetails, label: 'My record' },
  { key: TabKey.Payment, label: 'Payment' },
  { key: TabKey.Disclaimers, label: 'Disclaimers' },
  { key: TabKey.Logs, label: 'Logs' },
];

export const pageDataParams = createPageDataParams(
  { dataType: 'form' },
  { dataType: 'attendeeTypeList' },
  { dataType: 'jobList' },
  { dataType: 'ledgerAccounts' },
  { dataType: 'paymentSummary' },
  { dataType: 'paymentCategories' },
  { dataType: 'comments' },
  { dataType: 'groupReservation' },
  { dataType: 'attendeeDetails' },
  { dataType: 'formRecordJobs' },
  { dataType: 'sessionList' },
  { dataType: 'session' },
);

export const availablePaymentMethods = [
  PaymentMethod.check,
  PaymentMethod.costCenter,
  PaymentMethod.creditCard,
  PaymentMethod.eCheckOrbital,
];
