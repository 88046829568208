import { createAction, createAsyncAction } from 'typesafe-actions';

import { Addon, AddonOption } from '@/modules/data/dataTypes/addons';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';

export const updateAddonOpened = createAction(`${moduleName}/UPDATE_ADDON_OPENED`)<
  Addon | Record<string, never>
>();
export const updateAddonClosed = createAction(`${moduleName}/UPDATE_ADDON_CLOSED`)();

export const updateAddon = createAsyncAction(
  `${moduleName}/UPDATE_ADDON_REQUEST`,
  `${moduleName}/UPDATE_ADDON_SUCCESS`,
  `${moduleName}/UPDATE_ADDON_FAILURE`,
)<
  Pick<
    Addon,
    | 'addonName'
    | 'addonOptions'
    | 'description'
    | 'forbiddenStateList'
    | 'isAppliedToRoster'
    | 'isPaidWithDeposit'
  > &
    Partial<Pick<Addon, 'addonCode'>>,
  undefined,
  ApiError
>();

export const updateAddonObject = createAsyncAction(
  `${moduleName}/UPDATE_ADDON_OBJECT_REQUEST`,
  `${moduleName}/UPDATE_ADDON_OBJECT_SUCCESS`,
  `${moduleName}/UPDATE_ADDON_OBJECT_FAILURE`,
)<
  Omit<Addon, 'addonCode' | 'addonSessionGUID' | 'positionNumber' | 'isMandatory'> &
    Partial<Pick<Addon, 'addonCode'>>,
  string,
  ApiError
>();

export const updateAddonOptions = createAsyncAction(
  `${moduleName}/UPDATE_ADDON_OPTIONS_REQUEST`,
  `${moduleName}/UPDATE_ADDON_OPTIONS_SUCCESS`,
  `${moduleName}/UPDATE_ADDON_OPTIONS_FAILURE`,
)<Pick<Addon, 'addonCode' | 'addonOptions'>, AddonOption[] | undefined, ApiError>();

export const deleteAddonOptions = createAsyncAction(
  `${moduleName}/DELETE_ADDON_OPTIONS_REQUEST`,
  `${moduleName}/DELETE_ADDON_OPTIONS_SUCCESS`,
  `${moduleName}/DELETE_ADDON_OPTIONS_FAILURE`,
)<Pick<Addon, 'addonCode' | 'addonOptions'>, undefined, ApiError>();

export const deleteAddon = createAsyncAction(
  `${moduleName}/DELETE_ADDON_REQUEST`,
  `${moduleName}/DELETE_ADDON_SUCCESS`,
  `${moduleName}/DELETE_ADDON_FAILURE`,
)<string, undefined, Error>();
