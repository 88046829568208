import { CloseOutlined } from '@ant-design/icons';
import { Row as BsaRow, Form } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Description = styled.p`
  font-weight: bold;
`;

export const HeaderRow = styled(BsaRow)`
  display: flex;
  font-size: 16px;
  color: ${colors.primaryColor};
  font-weight: bold;
  align-items: center;
`;

export const Row = styled(BsaRow)`
  display: flex;
  align-items: center;

  .bsa-col:last-child {
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :hover {
    .bsa-col:last-child {
      visibility: visible;
    }
  }
`;

export const CloseIcon = styled(CloseOutlined)`
  color: #dd0000;
  cursor: pointer;
`;

export const FormItem = styled(Form.Item)`
  width: 100%;

  .ant-form-explain {
    float: right;
    margin-right: 20px;
  }
`;
