import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import {
  VisibilitySettingsParsed,
  VisibilitySettings,
} from '@/modules/data/dataTypes/visibilitySettings';
import { createDataSel } from '@/modules/data/duck/selectors';
import { FormPartCode } from '@/modules/entities/FormItems/constants';
import { PersonRoles } from '@/modules/entities/Roles/constants';
import { ADULT_AGE_HIGH } from '@/modules/shared/constants';
import { currentUserData } from '@/modules/user/constants';
import { parseBase64 } from '@/modules/utils/stringFunctions';

import { TrimmedIncomingFormParts } from '../types';
import { isQuestionVisible } from '../utils';

type BaseProps = {
  formParts: TrimmedIncomingFormParts;
  visibilitySettings: VisibilitySettings;
};

type AddAttendeeProps = BaseProps & {
  checkAttendeeTypeOnly?: true;
  providedAttendeeType: string;
};

type UpdateAttendeeProps = BaseProps & {
  attendeeDetails: AttendeeDetails;
  removeSystemFormParts: boolean;
};

type Props = AddAttendeeProps | UpdateAttendeeProps;

const propsSel = (_: RootState, props: Props) => props;

export const rolesSetForQuestionsSel = createSelector(
  [createDataSel('arnicaPerson', currentUserData), createDataSel('form')],
  ({ roles }, { assignedRoles }) =>
    new Set([
      ...roles
        .filter(({ roleCode }) => roleCode === PersonRoles.EventApprovalDesignee)
        .map(({ roleCode }) => roleCode),
      ...assignedRoles.map(({ roleCode }) => roleCode),
    ]),
);

export const formPartsSel = createSelector(
  [propsSel, createDataSel('form'), rolesSetForQuestionsSel],
  (props, form, rolesSet) => {
    const { formParts, visibilitySettings } = props;

    return formParts
      .reduce((acc, fp) => {
        const { formPartCode, formPartName, isSystem } = fp;

        if (isSystem && 'removeSystemFormParts' in props && props.removeSystemFormParts) {
          return acc;
        }

        switch (formPartCode) {
          case FormPartCode.parentData:
            if ('attendeeDetails' in props && Number(props.attendeeDetails.age) >= ADULT_AGE_HIGH) {
              return acc;
            }
            break;
          case FormPartCode.form:
            return acc;
        }

        const fpVisibilitySettings = visibilitySettings[fp.formPartCode];

        const fpVisibilitySettingsParsed = parseBase64<VisibilitySettingsParsed>(
          fpVisibilitySettings?.visibilitySettings,
        );

        const isFormPartVisibleByPrivileges = isQuestionVisible({
          ...props,
          form,
          isEditing: false,
          rolesSet,
          visibilitySettings: fpVisibilitySettingsParsed,
        });

        if (!isFormPartVisibleByPrivileges) return acc;

        const formItems: TrimmedIncomingFormParts[number]['formItems'] = fp.formItems;

        const updatedFormItems = formItems.reduce(
          (fiAcc, fi) => {
            const { isVisible } = fi;

            if (!isVisible) return fiAcc;

            const fiVisibilitySettingsParsed = parseBase64<VisibilitySettingsParsed>(
              fpVisibilitySettings?.formItems[fi.formItemCode],
            );
            const isFormItemVisibleByPrivileges = isQuestionVisible({
              ...props,
              form,
              isEditing: false,
              rolesSet,
              visibilitySettings: fiVisibilitySettingsParsed,
            });

            if (!isFormItemVisibleByPrivileges) return fiAcc;

            return [...fiAcc, fi];
          },
          [] as typeof formItems,
        );

        if (!updatedFormItems.length) return acc;

        let nextFormPartName = formPartName;

        if (formPartCode === FormPartCode.parentData) {
          nextFormPartName = 'Parent / Guardian Information';
        }

        return [...acc, { ...fp, formPartName: nextFormPartName, formItems: updatedFormItems }];
      }, [] as TrimmedIncomingFormParts)
      .sort((a, b) => {
        if (a.formPartCode === FormPartCode.form) return -1;
        if (b.formPartCode === FormPartCode.form) return 1;
        return 0;
      });
  },
);
