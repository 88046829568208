import React, { createRef, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { Person } from '@/modules/data/dataTypes/person';
import { fetchData } from '@/modules/data/duck/actions';
import { createDataSel, createIsLoadingSel } from '@/modules/data/duck/selectors';
import { LOGIN_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import PersonalInfoForm from '@/modules/shared/components/PersonalInfoForm';
import { PersonFormData } from '@/modules/shared/components/PersonalInfoForm/types';
import { personGuidSel } from '@/modules/user/duck/selectors';
import { usePrevious } from '@/modules/utils/hooks';

import ButtonsRow from '../../components/ButtonsRow';

import { MyScoutingLoginMessage } from './PersonalInformation.styled';
import { updatePersonalInformation } from './duck/actions';
import { updatePersonalInformationInProgressSel } from './duck/selectors';

const PersonalInformation: React.FC = () => {
  const dispatch = useDispatch();
  const formRef = createRef<{ handleSubmit: () => Promise<PersonFormData> }>();
  const updatePersonalInformationInProgress = useSelector(updatePersonalInformationInProgressSel);
  const formSettings = useSelector(createDataSel('form'));
  const attendeeDetails: AttendeeDetails | undefined = useSelector(
    createDataSel('attendeeDetails'),
  );
  const groupReservation: GroupReservation | undefined = useSelector(
    createDataSel('groupReservation'),
  );
  const person: Person | undefined = useSelector(createDataSel('arnicaPerson'));
  const personIsLoading = useSelector(createIsLoadingSel('arnicaPerson'));
  const personGUID = useSelector(personGuidSel);
  const prevPersonGUID = usePrevious(personGUID);

  useEffect(() => {
    if (!personGUID) {
      setTimeout(() => {
        dispatch(openModal(LOGIN_MODAL));
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (personGUID && !prevPersonGUID && !person) {
      dispatch(
        fetchData.request({
          dataType: 'arnicaPerson',
          queryObj: { personGUID, useAkelaData: true },
        }),
      );
    }
  }, [person, personGUID, prevPersonGUID, dispatch]);

  if (!formSettings) return null;

  const { allowGroupRegistration, disableLocalUsersRegistration } = formSettings;
  const disableLocalRegistration = disableLocalUsersRegistration && (!person || person?.isLocal);

  const triggerSubmit = () => {
    formRef.current?.handleSubmit().then(values => {
      dispatch(updatePersonalInformation(values));
    });
  };

  return (
    <Fragment>
      <LoadingContainer isLoading={personIsLoading || updatePersonalInformationInProgress}>
        {disableLocalRegistration ? (
          <MyScoutingLoginMessage>
            This is a My Scouting account only event. Please log in with a valid account
          </MyScoutingLoginMessage>
        ) : (
          <PersonalInfoForm
            key={person?.personGUID}
            ref={formRef}
            isGroup={allowGroupRegistration}
            initialUnitValue={
              formSettings.allowGroupRegistration
                ? groupReservation?.hostUnitGUID
                : attendeeDetails?.unit
            }
          />
        )}
      </LoadingContainer>
      {!disableLocalRegistration && <ButtonsRow rightButtons={[{ onClick: triggerSubmit }]} />}
    </Fragment>
  );
};

export default PersonalInformation;
