import { Input, RadioChangeEvent } from 'antd';
import { isString } from 'lodash';
import React, { Fragment, ReactElement, useMemo } from 'react';

import { OTHER_OPTION_VALUE, OTHER_VALUE_PREFIX } from '@/modules/entities/FormItems/constants';

import { Label } from './WithOtherOption.styled';
import { getOtherValue, isOtherValue } from './utils';

const getValueWithoutOther = (value: string) =>
  value.startsWith(OTHER_VALUE_PREFIX) ? OTHER_OPTION_VALUE : value;

const getValueWithOther = (nextOtherValue: string) => `${OTHER_VALUE_PREFIX}${nextOtherValue}`;

type Option = {
  value: string;
  label: string;
};

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  showOtherOption: boolean;
  options?: Option[];
  children: (params: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (value: any) => void;
    options: Option[];
  }) => ReactElement;
};

const WithOtherOption: React.FC<Props> = ({
  showOtherOption,
  options = [],
  value,
  onChange,
  children,
  ...rest
}) => {
  const showOtherValueInput = useMemo(() => {
    if (!showOtherOption) return false;
    if (Array.isArray(value)) {
      return value.some(isOtherValue);
    }
    return isOtherValue(value);
  }, [showOtherOption, value]);

  const optionsWithOther = useMemo(() => {
    if (!showOtherOption) return options;
    return [...options, { value: OTHER_OPTION_VALUE, label: 'Other' }];
  }, [options, showOtherOption]);

  const valueWithoutOther = useMemo(() => {
    if (!showOtherOption) return value;
    if (typeof value === 'string') {
      return getValueWithoutOther(value);
    }
    if (Array.isArray(value)) {
      return value.map(getValueWithoutOther);
    }
    return value;
  }, [showOtherOption, value]);

  const otherValue = useMemo(() => {
    if (typeof value === 'string') return getOtherValue(value);
    if (Array.isArray(value)) {
      const valueWithOther = value.filter(isString).find(v => v.startsWith(OTHER_VALUE_PREFIX));
      return valueWithOther ? getOtherValue(valueWithOther) : '';
    }
    return '';
  }, [value]);

  const handleChange = (val: RadioChangeEvent | string | string[]) => {
    const nextOptionsValue = typeof val === 'string' || Array.isArray(val) ? val : val.target.value;
    const valueWithOther = getValueWithOther(otherValue);
    if (nextOptionsValue === OTHER_OPTION_VALUE) {
      onChange && onChange(valueWithOther);
    } else if (Array.isArray(nextOptionsValue)) {
      onChange &&
        onChange(nextOptionsValue.map(v => (v === OTHER_OPTION_VALUE ? valueWithOther : v)));
    } else {
      onChange && onChange(nextOptionsValue);
    }
  };

  const handleOtherChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const nextOtherValue = e.target.value;
    const valueWithOther = getValueWithOther(nextOtherValue);
    if (typeof value === 'string') {
      onChange && onChange(valueWithOther);
    } else if (Array.isArray(value)) {
      onChange &&
        onChange(
          value.map(v => {
            if (isOtherValue(v)) return valueWithOther;
            return v;
          }),
        );
    }
  };

  return (
    <Fragment>
      {children({
        ...rest,
        options: optionsWithOther,
        value: valueWithoutOther,
        onChange: handleChange,
      })}
      {showOtherValueInput && (
        <Label label="Other" useFormStyles required>
          <Input value={otherValue} onChange={handleOtherChange} size="large" />
        </Label>
      )}
    </Fragment>
  );
};

export default WithOtherOption;
