import { ButtonType } from 'antd/lib/button';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isLoadingSel } from '@/modules/loading/duck/selectors';

import { openPrevStep } from '../../duck/actions';
import { nextStepNameSel, prevStepNameSel } from '../../duck/selectors';

import { Button, ButtonsRow as ButtonsRowComponent } from './ButtonsRow.styled';

interface StepButton {
  title?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: ButtonType;
  onClick?: () => void;
}

type Props = {
  leftButtons?: StepButton[];
  rightButtons?: StepButton[];
  alwaysVisible?: boolean;
};

const ButtonsRow: React.FC<Props> = ({ leftButtons, rightButtons, alwaysVisible }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSel);
  const prevStepName = useSelector(prevStepNameSel);
  const nextStepName = useSelector(nextStepNameSel);

  function handleBack() {
    dispatch(openPrevStep());
  }

  const defaultNextButtonTitle = useMemo(
    () => (nextStepName ? `Continue to ${nextStepName}` : 'Next'),
    [nextStepName],
  );

  if (!(leftButtons && leftButtons.length) && !(rightButtons && rightButtons.length)) {
    return null;
  }

  return (
    <ButtonsRowComponent $alwaysVisible={!!alwaysVisible}>
      {leftButtons &&
        leftButtons.map(
          ({
            title = `Back${prevStepName ? ` to: ${prevStepName}` : ''}`,
            type = 'default',
            onClick = handleBack,
            disabled,
            loading,
          }) => (
            <Button
              onClick={onClick}
              key={title}
              type={type}
              disabled={disabled}
              loading={loading}
              size="large"
            >
              {title}
            </Button>
          ),
        )}
      {rightButtons &&
        rightButtons.map(
          (
            { title = defaultNextButtonTitle, type = 'primary', onClick, disabled, loading },
            index,
          ) => (
            <Button
              onClick={onClick}
              key={title}
              type={type}
              disabled={disabled}
              loading={loading || (isLoading && index === rightButtons.length - 1)}
              size="large"
              $isFirstRight={index === 0}
            >
              {title}
            </Button>
          ),
        )}
    </ButtonsRowComponent>
  );
};

export default ButtonsRow;
