import { Form, Input, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { omit, sortBy, trimStart } from 'lodash';
import React, {
  useCallback,
  useState,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { Person } from '@/modules/data/dataTypes/person';
import { createDataSel } from '@/modules/data/duck/selectors';
import { phoneValidationRule } from '@/modules/entities/FormItems/utils';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { dateFromString, dateToString } from '@/modules/utils/dateFormats';
import { emailRegex } from '@/modules/utils/regularExpressions';

import InputPhone from '../InputPhone';

import { InputFieldsContainer } from './PersonData.styled';
import PersonDataFormItem from './components/PersonDataFormItem';
import { ADDRESS_COUNTRY_FIELD, USA_COUNTRY_SHORT_NAME, DATE_OF_BIRTH_FIELD } from './constants';

import DatePicker from 'SHARED/components/DatePicker';
import { nameSuffixes } from 'SHARED/constants';

type Props = {
  person?: Person;
  onDateOfBirthChange?: (dateOfBirth: Dayjs) => void;
};

const PersonData = forwardRef<{ handleSubmit: () => Promise<Partial<Person>> }, Props>(
  ({ person, onDateOfBirthChange }, ref) => {
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    const [form] = Form.useForm<Partial<Person>>();

    const countries = useSelector(createDataSel('countries'));
    const usaStates = useSelector(createDataSel('states'));
    const councilList = useSelector(createDataSel('councilList')) || [];
    const canUpdateAttendeeDetails = useSelector(
      createAbilitiesSelector('attendeeDetails', PermissionAction.Update, null),
    );

    const canUpdateMyScoutingAccount = !person || person.isLocal || canUpdateAttendeeDetails;

    const sortedCountries = useMemo(() => sortBy(countries || [], ({ name }) => name), [countries]);
    const sortedUsaStates = useMemo(() => sortBy(usaStates || [], ({ name }) => name), [usaStates]);

    const [currentCountry, setCurrentCountry] = useState(person?.country);

    function handleSubmit() {
      return form.validateFields().then(values => {
        const { dateOfBirth, phoneNumber } = values;

        const personData = {
          ...omit(person, ['roles', 'updateDate', 'yptExpirationDate']),
          ...values,
          dateOfBirth: dateToString(dateOfBirth),
          phoneCountryCode: '',
          phoneAreaCode: '',
          phoneExchange: '',
          phoneNumber,
        };

        return personData;
      });
    }

    const disableDate = useCallback((date: Dayjs | null) => {
      if (!date) return false;

      return !date.isBetween(dayjs().year(1900).startOf('year'), dayjs(), 'day');
    }, []);

    const handleCountryChange = useCallback((country: string) => {
      setCurrentCountry(country);
    }, []);

    const handleFormChange = ({
      [DATE_OF_BIRTH_FIELD]: dateOfBirth,
    }: Partial<Person> & { [DATE_OF_BIRTH_FIELD]: Dayjs }) => {
      if (dateOfBirth && onDateOfBirthChange) {
        onDateOfBirthChange(dateOfBirth);
      }
    };

    const initialValues = useMemo(() => {
      if (person) {
        const { phoneCountryCode, phoneNumber, phoneAreaCode, phoneExchange } = person;

        return {
          ...person,
          [DATE_OF_BIRTH_FIELD]: dateFromString(person.dateOfBirth),
          phoneNumber: trimStart(
            !phoneNumber || phoneNumber.length <= 4
              ? `${phoneCountryCode || '1'}${phoneAreaCode || ''}${phoneExchange || ''}${
                  phoneNumber || ''
                }`
              : phoneNumber,
            '0',
          ),
        };
      }

      return {
        dateOfBirth: null,
      };
    }, [person]);

    return (
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={handleFormChange}
        layout="vertical"
      >
        <InputFieldsContainer gutter={24}>
          <PersonDataFormItem
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'First name is required' }]}
          >
            <Input disabled={!canUpdateMyScoutingAccount} size="large" />
          </PersonDataFormItem>
          <PersonDataFormItem
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Last name is required' }]}
          >
            <Input disabled={!canUpdateMyScoutingAccount} size="large" />
          </PersonDataFormItem>
          <PersonDataFormItem name="nameSuffix" label="Suffix">
            <Select
              showSearch
              optionFilterProp="label"
              size="large"
              options={[
                { value: '', label: 'None' },
                ...nameSuffixes.map(suffix => ({
                  value: suffix,
                  label: suffix,
                })),
              ]}
            />
          </PersonDataFormItem>
          <PersonDataFormItem
            name={DATE_OF_BIRTH_FIELD}
            label="Date Of Birth"
            rules={[
              { type: 'date', message: 'Date is invalid' },
              { required: true, message: 'Date of birth is required' },
            ]}
          >
            <DatePicker disabled={!canUpdateMyScoutingAccount} disabledDate={disableDate} />
          </PersonDataFormItem>
          <PersonDataFormItem
            name="gender"
            label="Gender"
            rules={[{ required: true, message: 'Gender is required' }]}
          >
            <Select
              disabled={!canUpdateMyScoutingAccount}
              size="large"
              options={['M', 'F'].map(g => ({ value: g, label: g }))}
            />
          </PersonDataFormItem>
          <PersonDataFormItem
            name="phoneNumber"
            label="Phone"
            rules={[{ required: true, message: 'Phone number is required' }, phoneValidationRule]}
          >
            <InputPhone placeholder="XXX-XXX-XXXX" />
          </PersonDataFormItem>
          <PersonDataFormItem
            name="emailAddress"
            label="Email"
            rules={[
              { required: true, message: 'Email is required' },
              { pattern: emailRegex, message: 'Email is not valid' },
            ]}
          >
            <Input placeholder="email@scouting.org" size="large" />
          </PersonDataFormItem>
          {person && !person.isLocal ? (
            <Fragment>
              <PersonDataFormItem name="memberId" label="BSA Member ID">
                <Input disabled size="large" />
              </PersonDataFormItem>
              <PersonDataFormItem name="councilTerritory" label="Council Territory">
                <Input disabled size="large" />
              </PersonDataFormItem>
            </Fragment>
          ) : (
            <PersonDataFormItem name="councilNumber" label="Council">
              <Select
                optionFilterProp="label"
                showSearch
                size="large"
                options={councilList.map(({ councilLong, councilNumber }) => ({
                  value: councilNumber,
                  label: councilLong,
                }))}
              />
            </PersonDataFormItem>
          )}
          <PersonDataFormItem
            name="addressLine1"
            label="Home Address"
            rules={[{ required: true, message: 'Address is required' }]}
          >
            <Input size="large" />
          </PersonDataFormItem>
          <PersonDataFormItem
            name="city"
            label="City"
            rules={[{ required: true, message: 'City is required' }]}
          >
            <Input size="large" />
          </PersonDataFormItem>
          <PersonDataFormItem name="addressLine2" label="District/Province">
            <Input size="large" />
          </PersonDataFormItem>
          <PersonDataFormItem
            name={ADDRESS_COUNTRY_FIELD}
            label="Country"
            rules={[{ required: true, message: 'Country is required' }]}
          >
            <Select
              onChange={handleCountryChange}
              optionFilterProp="label"
              showSearch
              size="large"
              options={sortedCountries.map(({ name, short }) => ({
                value: short,
                label: name,
              }))}
            />
          </PersonDataFormItem>
          <Form.Item noStyle dependencies={[ADDRESS_COUNTRY_FIELD]}>
            {({ getFieldValue }) => {
              const country = getFieldValue(ADDRESS_COUNTRY_FIELD);
              if (country === USA_COUNTRY_SHORT_NAME) {
                return (
                  <PersonDataFormItem
                    name="state"
                    label="State"
                    rules={[
                      {
                        required: true,
                        message: 'State is required',
                      },
                    ]}
                  >
                    <Select
                      optionFilterProp="label"
                      showSearch
                      size="large"
                      options={sortedUsaStates.map(({ name, short }) => ({
                        value: short,
                        label: name,
                      }))}
                    />
                  </PersonDataFormItem>
                );
              }
            }}
          </Form.Item>
          <PersonDataFormItem
            name="postalCode"
            label="Postal Code"
            rules={[
              {
                required: currentCountry === USA_COUNTRY_SHORT_NAME,
                message: 'Postal Code is required for national registrants',
              },
            ]}
          >
            <Input size="large" />
          </PersonDataFormItem>
        </InputFieldsContainer>
      </Form>
    );
  },
);

export default PersonData;
