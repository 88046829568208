// UPDATE SAVE REPORT MODAL TO NEW ONE ONCE REPORTS TABLE IS FULLY AVAILABLE
import { ComponentType } from 'react';

import MasqueradeModal from '@/modules/core/components/MasqueradeModal';
import EditAddonsModal from '@/modules/entities/Addons/components/EditAttendeeAddonsModal';
import AddSecondaryRegistrant from '@/modules/entities/Registrations/components/AddSecondaryRegistrantModal';
import CancelIndividualReservationModal from '@/modules/entities/Reservations/components/CancelIndividualReservationModal';
import MakeRefundModal from '@/modules/payments/components/MakeRefundModal';
import SaveTableReportModal from '@/modules/reports/components/SaveReportModal';
import UpdateReportPermissionsModal from '@/modules/reports/components/UpdateReportPermissionsModal';
import AddColumnsModal from '@/modules/reports/tabs/CurrentReport/components/AddColumnsModal';
import UpdateReportFiltersModal from '@/modules/reports/tabs/CurrentReport/components/UpdateFiltersModal';
import ConfirmActionModal from '@/modules/shared/components/ConfirmModal';
import UploadQuestionsModal from '@/modules/uploadQuestionsModal';
import { moduleName as uploadQuestionsModalModuleName } from '@/modules/uploadQuestionsModal/constants';
import LoginModal from '@/modules/user/components/LoginModal';
import SessionExpiredModal from '@/modules/user/components/SessionExpiredModal';

import AssignCollaboratorRole from '@/pages/createEvent/steps/Collaborators/components/AssignCollaboratorRole';
import InviteCollaborator from '@/pages/createEvent/steps/Collaborators/components/InviteCollaborator';
import UpdateDisclaimerModal from '@/pages/createEvent/steps/Disclaimers/components/UpdateDisclaimerModal';
import { moduleName as updateDisclaimerModalModuleName } from '@/pages/createEvent/steps/Disclaimers/components/UpdateDisclaimerModal/constants';
import AddDataBlockModal from '@/pages/createEvent/steps/FormBuilder/components/AddDataBlockModal';
import EditOptionsInBulkModal from '@/pages/createEvent/steps/FormBuilder/components/EditOptionsInBulkModal';
import AddDatablockItemModal from '@/pages/createEvent/steps/FormBuilder/components/FormPart/AddDatablockItemModal';
import VisibilityLogicModal from '@/pages/createEvent/steps/FormBuilder/components/Sidebar/components/VisibilityLogicModal';
import TimezoneConfirmationModal from '@/pages/createEvent/steps/FormSettings/components/TimezoneConfirmationModal';
import UpdateCostCenterVisibilityModal from '@/pages/createEvent/steps/PaymentSchedule/components/UpdateCostCenterVisibilityModal';
import CreateEditProgramModal from '@/pages/createEvent/steps/Programs/components/CreateEditProgramModal';
import CloneSessionModal from '@/pages/createEvent/steps/Sessions/components/CloneSessionModal';
import ImportCsvFileModal from '@/pages/createEvent/steps/Sessions/components/ImportCsvFileModal';
import RegistrationTimeoutModal from '@/pages/createReservation/components/RegistrationTimeoutModal';
import EditAdditionalRegistrantActivitiesModal from '@/pages/createReservation/steps/AddPerson/components/RegistrantsList/components/Registrant/components/AdditionalRegistrantActivities/components/EditAdditionalRegistrantActivitiesModal';
import JoinWaitlistModal from '@/pages/createReservation/steps/SessionPriorities/components/JoinWaitlistModal';
import AttendeesAttributesModal from '@/pages/event/tabs/Attendees/components/AttendeesAttributesModal';
import MassResendEmailModal from '@/pages/event/tabs/Attendees/components/MassResendEmailModal';
import UpdateEmailTemplateModal from '@/pages/event/tabs/Emails/components/EmailTemplates/components/UpdateEmailTemplateModal';
import { moduleName as updateEmailTemplateModalModuleName } from '@/pages/event/tabs/Emails/components/EmailTemplates/components/UpdateEmailTemplateModal/constants';
import CloneEventModal from '@/pages/eventList/components/CloneEventModal';
import ApproveApplicantModal from '@/pages/reservation/components/ApproveApplicantModal';
import AttendeeJobAcceptModal from '@/pages/reservation/components/AttendeeJobAcceptModal';
import AttendeeJobOfferModal from '@/pages/reservation/components/AttendeeJobOfferModal';
import CancelGroupReservationModal from '@/pages/reservation/components/MyReservation/components/CancelGroupReservationModal';
import LeadAdvisorDesignateModal from '@/pages/reservation/components/MyReservation/components/LeadAdvisorDesignateModal';
import LeadAdvisorInviteModal from '@/pages/reservation/components/MyReservation/components/LeadAdvisorInviteModal';
import ChangeReservationSessionModal from '@/pages/reservation/components/MyReservation/components/ReservationDetails/components/ChangeReservationSessionModal';
import ReservationSizeEditModal from '@/pages/reservation/components/MyReservation/components/ReservationDetails/components/ReservationSizeEditModal';
import UpdateReservationContactModal from '@/pages/reservation/components/MyReservation/components/ReservationDetails/components/UpdateReservationContactModal';
import UpdatePaymentScheduleDueDateModal from '@/pages/reservation/components/MyReservation/components/UpdatePaymentScheduleDueDateModal';
import RevokeApprovalModal from '@/pages/reservation/components/RevokeApprovalModal';
import TransferRegistrationModal from '@/pages/reservation/components/TransferRegistrationModal';
import AttendeeDetailsJobPrioritiesModal from '@/pages/reservation/components/UpdatePrioritiesModal/UpdatePrioritiesModal';
import EditActivitiesModal from '@/pages/reservation/tabs/AttendeeDetails/components/Activities/components/EditActivitiesModal';
import AttendeeInviteModal from '@/pages/reservation/tabs/OverviewGroup/components/RosterPage/components/AddAttendeeModal';
import ConfirmAttendeesTransferModal from '@/pages/reservation/tabs/OverviewGroup/components/RosterPage/components/ConfirmAttendeesTransferModalRC';
import RemoveAttendeesModal from '@/pages/reservation/tabs/OverviewGroup/components/RosterPage/components/RemoveAttendeesModal';
import TransferAttendeesSelectTypeModal from '@/pages/reservation/tabs/OverviewGroup/components/RosterPage/components/TransferAttendeesSelectTypeModal';

import CreateBroadcastModal from '../broadcast/components/CreateBroadcastModal';
import MakeAdjustmentModal from '../payments/components/MakeAdjustmentModal';
import SendCustomEmailModal from '../reports/components/Emails/components/SendCustomEmailModal';

import {
  ADD_COLUMNS_MODAL,
  ADD_DATA_BLOCK_MODAL,
  ADD_SECONDARY_REGISTRANT_MODAL,
  UPDATE_ADDITIONAL_REGISTRANT_ACTIVITIES_MODAL,
  CONFIRM_ATTENDEES_TRANSFER_MODAL_RC,
  EDIT_ATTENDEE_ACTIVITIES_MODAL,
  EDIT_ATTENDEE_ADDONS_MODAL,
  INVITE_COLLABORATOR_MODAL,
  ADD_ATTENDEE_MODAL,
  LEAD_ADVISOR_INVITE_MODAL,
  LEAD_ADVISOR_DESIGNATE_MODAL,
  LOGIN_MODAL,
  MAKE_ADJUSTMENT_MODAL,
  MAKE_REFUND_MODAL,
  ASSIGN_COLLABORATOR_ROLE_MODAL,
  CLONE_SESSION_MODAL,
  CREATE_EDIT_PROGRAM_MODAL,
  MASQUERADE_MODAL,
  ATTENDEE_JOB_OFFER_MODAL,
  ATTENDEE_COUNCIL_APPROVAL_MODAL,
  ATTENDEE_DETAILS_UPDATE_JOB_PRIORITIES_MODAL,
  CANCEL_INDIVIDUAL_RESERVATION_MODAL,
  ATTENDEES_ATTRIBUTES_MODAL,
  VISIBILITY_LOGIC_MODAL,
  CLONE_EVENT_MODAL,
  REGISTRATION_TIME_OUT_MODAL,
  JOIN_WAIT_LIST_MODAL,
  CANCEL_GROUP_RESERVATION_MODAL,
  SESSION_EXPIRED_MODAL,
  TIMEZONE_CONFIRMATION_MODAL,
  TRANSFER_ATTENDEES_SELECT_TYPE_MODAL,
  TRANSFER_REGISTRATION_MODAL,
  UPDATE_RESERVATION_CONTACT_MODAL,
  CONFIRM_MODAL,
  RESERVATION_SIZE_EDIT_MODAL,
  ADD_DATABLOCK_ITEM_MODAL,
  SAVE_REPORT_MODAL,
  UPDATE_PAYMENT_SCHEDULE_DUE_DATE_MODAL,
  UPDATE_REPORT_FILTERS_MODAL,
  UPDATE_REPORT_PERMISSIONS_MODAL,
  CREATE_BROADCAST_MODAL,
  SEND_CUSTOM_EMAIL_MODAL,
  REMOVE_ATTENDEE_MODAL,
  IMPORT_CSV_FILE_MODAL,
  CHANGE_RESERVATION_SESSION_MODAL,
  UPDATE_COST_CENTER_VISIBILITY_MODAL,
  ATTENDEE_JOB_ACCEPT_MODAL,
  MASS_RESEND_EMAIL_MODAL,
  EDIT_OPTIONS_IN_BULK_MODAL,
  APPROVE_APPLICANT_MODAL,
} from './constants';

const modalsObj: Record<
  string,
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: ComponentType<{ modalParams: any }>;
    props?: Record<string, unknown>;
  }
> = {
  [ADD_COLUMNS_MODAL]: {
    Component: AddColumnsModal,
  },
  [ADD_DATA_BLOCK_MODAL]: {
    Component: AddDataBlockModal,
    props: {
      width: 1000,
    },
  },
  [ADD_SECONDARY_REGISTRANT_MODAL]: {
    Component: AddSecondaryRegistrant,
    props: {
      width: 1500,
    },
  },
  [UPDATE_ADDITIONAL_REGISTRANT_ACTIVITIES_MODAL]: {
    Component: EditAdditionalRegistrantActivitiesModal,
    props: {
      width: 1000,
    },
  },
  [CONFIRM_MODAL]: {
    Component: ConfirmActionModal,
  },
  [uploadQuestionsModalModuleName]: {
    Component: UploadQuestionsModal,
    props: {
      width: 1500,
    },
  },
  [CONFIRM_ATTENDEES_TRANSFER_MODAL_RC]: {
    Component: ConfirmAttendeesTransferModal,
  },
  [EDIT_ATTENDEE_ADDONS_MODAL]: {
    Component: EditAddonsModal,
  },
  [EDIT_ATTENDEE_ACTIVITIES_MODAL]: {
    Component: EditActivitiesModal,
  },
  [INVITE_COLLABORATOR_MODAL]: {
    Component: InviteCollaborator,
  },
  [ADD_ATTENDEE_MODAL]: {
    Component: AttendeeInviteModal,
  },
  [LEAD_ADVISOR_INVITE_MODAL]: {
    Component: LeadAdvisorInviteModal,
  },
  [LEAD_ADVISOR_DESIGNATE_MODAL]: {
    Component: LeadAdvisorDesignateModal,
  },
  [LOGIN_MODAL]: {
    Component: LoginModal,
    props: {
      width: 460,
      closable: false,
    },
  },
  [MAKE_ADJUSTMENT_MODAL]: {
    Component: MakeAdjustmentModal,
  },
  [MAKE_REFUND_MODAL]: {
    Component: MakeRefundModal,
  },
  [ASSIGN_COLLABORATOR_ROLE_MODAL]: {
    Component: AssignCollaboratorRole,
  },
  [CLONE_SESSION_MODAL]: {
    Component: CloneSessionModal,
    props: {
      width: 800,
    },
  },
  [IMPORT_CSV_FILE_MODAL]: {
    Component: ImportCsvFileModal,
    props: {
      width: 610,
      height: 520,
    },
  },
  [SEND_CUSTOM_EMAIL_MODAL]: {
    Component: SendCustomEmailModal,
    props: {
      width: 1500,
    },
  },
  [SESSION_EXPIRED_MODAL]: {
    Component: SessionExpiredModal,
  },
  [CREATE_EDIT_PROGRAM_MODAL]: {
    Component: CreateEditProgramModal,
  },
  [MASQUERADE_MODAL]: {
    Component: MasqueradeModal,
  },
  [ATTENDEE_JOB_OFFER_MODAL]: {
    Component: AttendeeJobOfferModal,
  },
  [APPROVE_APPLICANT_MODAL]: {
    Component: ApproveApplicantModal,
  },
  [ATTENDEE_JOB_ACCEPT_MODAL]: {
    Component: AttendeeJobAcceptModal,
  },
  [ATTENDEE_COUNCIL_APPROVAL_MODAL]: {
    Component: RevokeApprovalModal,
  },
  [ATTENDEE_DETAILS_UPDATE_JOB_PRIORITIES_MODAL]: {
    Component: AttendeeDetailsJobPrioritiesModal,
  },
  [CANCEL_INDIVIDUAL_RESERVATION_MODAL]: {
    Component: CancelIndividualReservationModal,
  },
  [ATTENDEES_ATTRIBUTES_MODAL]: {
    Component: AttendeesAttributesModal,
  },
  [MASS_RESEND_EMAIL_MODAL]: {
    Component: MassResendEmailModal,
  },
  [updateEmailTemplateModalModuleName]: {
    Component: UpdateEmailTemplateModal,
    props: {
      width: 1500,
    },
  },
  [VISIBILITY_LOGIC_MODAL]: {
    Component: VisibilityLogicModal,
  },
  [CLONE_EVENT_MODAL]: {
    Component: CloneEventModal,
  },
  [REGISTRATION_TIME_OUT_MODAL]: {
    Component: RegistrationTimeoutModal,
    props: { maskClosable: false, closable: false },
  },
  [RESERVATION_SIZE_EDIT_MODAL]: {
    Component: ReservationSizeEditModal,
  },
  [JOIN_WAIT_LIST_MODAL]: {
    Component: JoinWaitlistModal,
  },
  [CANCEL_GROUP_RESERVATION_MODAL]: {
    Component: CancelGroupReservationModal,
  },
  [TIMEZONE_CONFIRMATION_MODAL]: {
    Component: TimezoneConfirmationModal,
  },
  [TRANSFER_ATTENDEES_SELECT_TYPE_MODAL]: {
    Component: TransferAttendeesSelectTypeModal,
    props: {
      width: 700,
    },
  },
  [TRANSFER_REGISTRATION_MODAL]: {
    Component: TransferRegistrationModal,
    props: { width: 1500 },
  },
  [updateDisclaimerModalModuleName]: {
    Component: UpdateDisclaimerModal,
    props: { width: 1200 },
  },
  [REMOVE_ATTENDEE_MODAL]: {
    Component: RemoveAttendeesModal,
  },
  [UPDATE_RESERVATION_CONTACT_MODAL]: {
    Component: UpdateReservationContactModal,
  },
  [ADD_DATABLOCK_ITEM_MODAL]: {
    Component: AddDatablockItemModal,
  },
  [SAVE_REPORT_MODAL]: {
    Component: SaveTableReportModal,
  },
  [UPDATE_PAYMENT_SCHEDULE_DUE_DATE_MODAL]: {
    Component: UpdatePaymentScheduleDueDateModal,
  },
  [UPDATE_REPORT_PERMISSIONS_MODAL]: {
    Component: UpdateReportPermissionsModal,
  },
  [UPDATE_REPORT_FILTERS_MODAL]: {
    Component: UpdateReportFiltersModal,
    props: {
      width: 1000,
    },
  },
  [CREATE_BROADCAST_MODAL]: {
    Component: CreateBroadcastModal,
    props: {
      width: 600,
    },
  },
  [CHANGE_RESERVATION_SESSION_MODAL]: {
    Component: ChangeReservationSessionModal,
    props: {
      width: 672,
    },
  },
  [UPDATE_COST_CENTER_VISIBILITY_MODAL]: {
    Component: UpdateCostCenterVisibilityModal,
    props: {
      width: 648,
    },
  },
  [EDIT_OPTIONS_IN_BULK_MODAL]: {
    Component: EditOptionsInBulkModal,
    props: {
      width: 560,
    },
  },
};

export default modalsObj;
