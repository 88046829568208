import dayjs, { Dayjs, ConfigType } from 'dayjs';
import { trim } from 'lodash';

const TIME_24_FORMAT = 'HH:mm';

export const US_DATE_FORMAT = 'MM/DD/YYYY';
export const US_TIME_FORMAT = 'hh:mm A';
export const SERVER_DATE_FORMAT = 'YYYYMMDDHHmmss';
export const SHORT_DATE = 'MMM DD, YYYY';

export const dateFromString = (date?: ConfigType, format = SERVER_DATE_FORMAT): Dayjs =>
  dayjs(date, format);

export const dateToString = (date?: ConfigType, format = SERVER_DATE_FORMAT): string =>
  dayjs(date).format(format);

export const utcDateFromString = (date: ConfigType, format = SERVER_DATE_FORMAT): Dayjs =>
  dayjs(date, format).utc(true).local();

export const utcDateToString = (date?: ConfigType, format = SERVER_DATE_FORMAT): string =>
  dayjs(date).utc().format(format);

export const eventDate = (start: string, end: string): string =>
  `${dayjs(start, SERVER_DATE_FORMAT).format('MMM DD')} - ${dayjs(end, SERVER_DATE_FORMAT).format(
    'MMM DD',
  )}`;

export const eventDateWithYear = (start: string, end: string): string =>
  `${dayjs(start, SERVER_DATE_FORMAT).format(SHORT_DATE)} - ${dayjs(end, SERVER_DATE_FORMAT).format(
    SHORT_DATE,
  )}`;

export const usDate = (date?: ConfigType, format?: string): string =>
  dateFromString(date, format).format(US_DATE_FORMAT);
export const usDateTime = (date?: ConfigType): string =>
  dateFromString(date).format(`${US_DATE_FORMAT} ${US_TIME_FORMAT}`);

export const shortDate = (date: ConfigType): string => dateFromString(date).format(SHORT_DATE);

export const dayWithTime = (date: string): string => dateFromString(date).format('MMM DD, H:mm A');

export const daysBetween = (start: string, end: string): number =>
  Math.abs(dateFromString(start).diff(dayjs(end, SERVER_DATE_FORMAT), 'days')) + 1;

const cleanFormat = (format: string) => {
  let nextFormat = format;
  nextFormat = nextFormat.replaceAll('  ', ' ');
  nextFormat = nextFormat.trim();
  nextFormat = trim(nextFormat, ',');

  return nextFormat;
};

export const formattedDatesRange = (
  start: ConfigType,
  end: ConfigType,
  options?: { monthFormat?: string; hideYear?: boolean },
): string => {
  const startDate = dateFromString(start);
  const closeDate = dateFromString(end);

  if (startDate.isValid() && closeDate.isValid()) {
    const monthFormat = options?.monthFormat || 'MMM';
    let startFormat = `${monthFormat} DD${options?.hideYear ? '' : ', YYYY'}`;

    if (dayjs().year() === startDate.year()) {
      startFormat = startFormat.replaceAll('Y', '');
    }

    startFormat = cleanFormat(startFormat);

    let endFormat = startFormat;

    if (startDate.isSame(closeDate, 'year')) {
      endFormat = endFormat.replaceAll('Y', '');

      if (startDate.isSame(closeDate, 'month')) {
        endFormat = endFormat.replaceAll('M', '');
      }
    }

    endFormat = cleanFormat(endFormat);

    return `${startDate.format(startFormat)} - ${closeDate.format(endFormat)}`;
  }

  return '';
};

const parseTimezone = (tz = ''): string =>
  tz
    .split('')
    .map(item => `\\${item}`)
    .join('');

export const timeFormatWithTimeZone = (timeFormat = US_TIME_FORMAT, tz?: string) => {
  if (!tz) {
    return timeFormat;
  }
  return `${timeFormat} ${parseTimezone(tz)}`;
};

export const getDateWithTimeFormat = (timezone?: string): string =>
  `${US_DATE_FORMAT} ${TIME_24_FORMAT}${timezone ? ` ${parseTimezone(timezone)}` : ''}`;

export const areDateRangesOverlap = (...dateRanges: [Dayjs, Dayjs][]) =>
  dateRanges.some(([startDate, finishDate], i) =>
    dateRanges.some((range, j) => {
      if (i === j) return false;
      return (
        startDate.isBetween(...range, undefined, '[]') ||
        finishDate.isBetween(...range, undefined, '[]')
      );
    }),
  );
