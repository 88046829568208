import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { acceptDisclaimers } from './actions';

const disclaimersAccepted = createReducer(false)
  .handleAction([acceptDisclaimers.request, acceptDisclaimers.failure], () => false)
  .handleAction(acceptDisclaimers.success, () => true);

const acceptDisclaimersInProgress = createReducer(false)
  .handleAction([acceptDisclaimers.failure, acceptDisclaimers.success], () => false)
  .handleAction(acceptDisclaimers.request, () => true);

export default combineReducers({
  disclaimersAccepted,
  acceptDisclaimersInProgress,
});
