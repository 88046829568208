import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { openNextStep } from '@/pages/createReservation/duck/actions';

import { updatePersonalInformation } from './actions';

const updatePersonalInformationInProgress = createReducer(false)
  .handleAction(updatePersonalInformation, () => true)
  .handleAction(openNextStep, () => false);

export default combineReducers({
  updatePersonalInformationInProgress,
});
