import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { flow, get, omit } from 'lodash';
import { pick } from 'lodash/fp';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFilter } from '@/modules/data/duck/actions';
import { createIsLoadingSel } from '@/modules/data/duck/selectors';
import {
  FILTER_CHECKBOX,
  FILTER_CONTENT,
  RESERVATION_IN_PROGRESS,
  RESERVATION_IS_COMPLETE,
  RESERVATION_IS_CANCELLED,
  RESERVATION_NOT_CANCELLED,
  DEFAULT_RESERVATION_LIST_FILTER_CONTENT,
} from '@/modules/entities/Reservations/constants';
import type { FilterContent } from '@/modules/entities/Reservations/types';
import { getFilterContentString } from '@/modules/entities/Reservations/utils';

import { Card, Checkbox } from './ReservationFilterCheckGroup.styled';

const ReservationFilterCheckGroup: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(createIsLoadingSel('reservationListReport'));
  const [filterContent, setFilterContent] = useState<FilterContent>(
    DEFAULT_RESERVATION_LIST_FILTER_CONTENT,
  );

  const handleOnChange = (e: CheckboxChangeEvent): void => {
    const { name, checked } = e.target;
    if (!name) return;

    let filters = filterContent;

    if (checked) {
      if (name === RESERVATION_IS_CANCELLED) {
        filters = omit(filterContent, [RESERVATION_NOT_CANCELLED]);
      }
      handleSetFilterContent({ ...filters, [name]: true });
    } else {
      if (name === RESERVATION_IS_CANCELLED) {
        filters = { ...filterContent, [RESERVATION_NOT_CANCELLED]: true };
      }
      handleSetFilterContent(omit(filters, [name]));
    }
  };

  const handleSetFilterContent = (newFilterContent: FilterContent) => {
    setFilterContent(newFilterContent);
    const parsedFilterContent = getFilterContentString(newFilterContent);
    dispatch(
      setFilter({
        dataType: 'reservationListReport',
        key: FILTER_CONTENT,
        value: parsedFilterContent,
      }),
    );
  };

  const isOnlyOneSelected =
    flow(pick([RESERVATION_IN_PROGRESS, RESERVATION_IS_COMPLETE, RESERVATION_IS_CANCELLED]), obj =>
      Object.keys(obj),
    )(filterContent).length === 1;

  return (
    <Card title="Filter Reservations">
      {FILTER_CHECKBOX.map(({ value, label }) => {
        const isChecked = get(filterContent, value, false);
        const isDisabled = isLoading || (isChecked && isOnlyOneSelected);

        return (
          <Checkbox
            onChange={handleOnChange}
            key={value}
            name={value}
            checked={isChecked}
            disabled={isDisabled}
          >
            {label}
          </Checkbox>
        );
      })}
    </Card>
  );
};
export default ReservationFilterCheckGroup;
