import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { Addon } from '@/modules/data/dataTypes/addons';

import { deleteAddon, updateAddon, updateAddonClosed, updateAddonOpened } from './actions';

const updatedAddon = createReducer<Addon | Record<string, never> | null>(null)
  .handleAction(updateAddonOpened, (_, { payload }) => payload)
  .handleAction(updateAddonClosed, () => null);

const updateAddonInProgress = createReducer(false)
  .handleAction(updateAddon.request, () => true)
  .handleAction([updateAddon.success, updateAddon.failure], () => false);

const deleteAddonInProgress = createReducer(false)
  .handleAction(deleteAddon.request, () => true)
  .handleAction([deleteAddon.success, deleteAddon.failure], () => false);

export default combineReducers({
  updatedAddon,
  updateAddonInProgress,
  deleteAddonInProgress,
});
