import { StateObservable } from 'redux-observable';
import { concat, defer, EMPTY, merge, Observable, of, race } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { confirmRegistration } from '@/modules/entities/Registrations/duck/actions';
import { createGroupReservationRosters } from '@/modules/entities/Reservations/duck/actions';
import toastService from '@/modules/toasts/service';
import { personGuidSel as currentUserPersonGuidSel } from '@/modules/user/duck/selectors';

export const checkoutStepEpic$ = (
  action$: Observable<RootAction>,
  state$: StateObservable<RootState>,
  guids:
    | {
        formRecordGUID: string;
      }
    | { groupReservationGUID: string },
  options: {
    finalActions: RootAction[];
    sessionCode: string;
    paymentSummary: PaymentSummary;
  },
): Observable<RootAction> => {
  const { sessionCode, finalActions, paymentSummary } = options;
  const state = state$.value;
  const { formCode } = createDataSel('form')(state);

  const personGUID = currentUserPersonGuidSel(state);

  return concat(
    prefetchData$(
      action$,
      {
        dataType: 'paymentCategories',
        queryObj: {
          formCode,
          ...guids,
        },
      },
      'groupReservationGUID' in guids
        ? {
            dataType: 'payments',
            queryObj: { formCode, groupReservationGUID: guids.groupReservationGUID },
          }
        : {
            dataType: 'payments',
            queryObj: { formCode, personGUID, formRecordGUID: guids.formRecordGUID },
          },
    ),
    defer(() => {
      const { nextPaymentAmountDue } = paymentSummary;
      const canConfirmRegistration = nextPaymentAmountDue === 0;
      if (!canConfirmRegistration) return EMPTY;

      return merge(
        race(
          action$.pipe(
            filter(
              isActionOf([createGroupReservationRosters.failure, confirmRegistration.failure]),
            ),
            take(1),
            switchMap(({ payload }) => {
              throw new Error(payload.message);
            }),
          ),
          action$.pipe(
            filter(
              isActionOf([createGroupReservationRosters.success, confirmRegistration.success]),
            ),
            switchMap(() => of(...finalActions)),
            take(1),
          ),
        ),
        of(
          'groupReservationGUID' in guids
            ? createGroupReservationRosters.request({
                formCode,
                groupReservationGUID: guids.groupReservationGUID,
                sessionCode,
              })
            : confirmRegistration.request({
                formCode,
                formRecordGUID: guids.formRecordGUID,
                sessionCode,
              }),
        ),
      ).pipe(
        catchError((error: Error) => {
          toastService.error(error.message);
          return EMPTY;
        }),
      );
    }),
  );
};
