import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'redux-first-router-link';

import { SubHeaderContainer, SubHeaderTitle } from './SubHeader.styled';

const SubHeader: React.FC = () => (
  <SubHeaderContainer>
    <SubHeaderTitle level={2}>
      <Link to="/events">
        <FormattedMessage id="core.Header.SubHeader.bsaEventManagement" />
      </Link>
    </SubHeaderTitle>
  </SubHeaderContainer>
);

export default SubHeader;
