import { Button } from 'antd';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { createDataSel, createIsLoadingSel } from '@/modules/data/duck/selectors';
import { JobPeriodCode, jobStatus, JOB_PERIODS } from '@/modules/entities/Jobs/constants';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { ATTENDEE_JOB_OFFER_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';
import Banner from '@/modules/shared/components/Banner';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import toastService from '@/modules/toasts/service';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { nonFreeAgentJobsAmount } from '../../constants';
import {
  attendeeTypeIsStaffSel,
  isFreeAgentSel,
  managerAvailableJobsToOfferSel,
  userFirstPriorityJob,
  pageDataSel,
} from '../../duck/selectors';
import { AttendeeJobOfferModalParams } from '../AttendeeJobOfferModal/types';

import {
  BannerLayout,
  BannerRightSide,
  BannerLeftSide,
  OfferButton,
  Select,
} from './StaffOfferBanner.styled';
import RevokeJobButton from './components/RevokeJobButton';
import { JobActionType } from './constants';

interface StaffOfferBannerProps {
  isCancelled: boolean;
}

const StaffOfferBanner: React.FC<StaffOfferBannerProps> = ({ isCancelled }) => {
  // this makes the select show the placeholder when jobPeriodCode is an empty string
  const dispatch = useDispatch();
  const userJobDepartments = useSelector(createDataSel('personJobDepartmentList'));
  const isLoadingJobList = useSelector(createIsLoadingSel('formRecordJobs'));
  const isFreeAgent = useSelector(isFreeAgentSel);
  const isStaff = useSelector(attendeeTypeIsStaffSel);
  const screenSize = useGetScreenSize();
  const {
    data: { form, attendeeDetails },
  } = useSelector(pageDataSel);

  const { formCode } = form;

  const activeJobPosition = useSelector((state: RootState) =>
    userFirstPriorityJob(state, { formCode }),
  ); // job position selected by attendee that can be offered by qualified collaborators
  const jobList = useSelector((state: RootState) =>
    managerAvailableJobsToOfferSel(state, { formCode }),
  );
  const isMobile = screenSize === 'mobile';

  const [selectedJobCode, setSelectedJobCode] = useState<string | undefined>(
    activeJobPosition?.jobCode,
  );
  const [selectedJobPeriod, setSelectedJobPeriod] = useState<JobPeriodCode | undefined>(
    activeJobPosition?.jobPeriodCode || undefined,
  );

  useEffect(() => setSelectedJobCode(activeJobPosition?.jobCode), [activeJobPosition]);

  const canOfferJobPosition = useSelector(
    createAbilitiesSelector('jobOffer', PermissionAction.Create, {
      isCancelled,
      isFreeAgent,
      isStaff,
      attendeeDetails,
      jobPosition: activeJobPosition,
      userJobDepartments,
    }),
  );

  const showOfferButton = useMemo(() => {
    if (activeJobPosition) {
      return [jobStatus.job_pending.code, '', undefined].includes(activeJobPosition.status);
    } else if (isFreeAgent) {
      return true;
    }
    return false;
  }, [activeJobPosition, isFreeAgent]);

  // if no positions to offer, not render anything
  if (!canOfferJobPosition || (!activeJobPosition && !jobList.length)) return null;

  const { firstName = '', lastName = '' } = attendeeDetails;

  const fullName = `${firstName} ${lastName}`;

  const createOpenPositionModalHandler = (type: JobActionType) => () => {
    const offeredJobPosition = selectedJobCode && jobList.find(j => j.jobCode === selectedJobCode);

    if (!offeredJobPosition) {
      toastService.error('Empty job position');
      return;
    }

    if (type === JobActionType.APPROVE && !selectedJobPeriod) {
      toastService.error('Please select a Job Period before offer a job');
      return;
    }

    const params: AttendeeJobOfferModalParams = {
      formCode,
      type,
      offeredJob: offeredJobPosition,
      priority: isFreeAgent ? nonFreeAgentJobsAmount : activeJobPosition?.priority || 0,
      jobPeriodCode: selectedJobPeriod as JobPeriodCode,
    };

    dispatch(openModal(ATTENDEE_JOB_OFFER_MODAL, params));
  };

  const showConfirmedPositionText =
    activeJobPosition && activeJobPosition.status === jobStatus.job_attendee_accepted.code;

  const showOfferedPositionText =
    activeJobPosition && activeJobPosition.status === jobStatus.job_offered.code;

  const showDeclineButton = !isFreeAgent && showOfferButton;

  return (
    <LoadingContainer isLoading={isLoadingJobList}>
      <Banner
        type="info"
        message={
          <BannerLayout $isMobile={isMobile}>
            <BannerLeftSide $fullWidth={isMobile || !!showOfferButton}>
              {isFreeAgent ? (
                <Fragment>
                  <strong>{fullName}</strong> is a Free Agent
                </Fragment>
              ) : (
                <Fragment>
                  You are reviewing <strong>{fullName}</strong>'s application form for “
                  {activeJobPosition?.jobName}” position.
                </Fragment>
              )}
            </BannerLeftSide>
            <BannerRightSide $isMobile={isMobile}>
              {isFreeAgent && (
                <Select
                  placeholder="Position to be offered"
                  size="large"
                  value={selectedJobCode}
                  onChange={setSelectedJobCode}
                  options={jobList
                    .slice()
                    .sort((a, b) => (a.jobName > b.jobName ? 1 : -1))
                    .map(({ jobCode, jobName }) => ({ value: jobCode, label: jobName }))}
                />
              )}

              {showOfferButton && (
                <Select
                  placeholder="Job Period"
                  size="large"
                  value={selectedJobPeriod}
                  onChange={setSelectedJobPeriod}
                  options={JOB_PERIODS.map(({ code, name }) => ({ value: code, label: name }))}
                />
              )}

              {showDeclineButton && (
                <Button
                  type="link"
                  size="small"
                  onClick={createOpenPositionModalHandler(JobActionType.DECLINE)}
                >
                  DECLINE
                </Button>
              )}

              {showOfferButton && (
                <OfferButton
                  type="link"
                  size="small"
                  onClick={createOpenPositionModalHandler(JobActionType.APPROVE)}
                >
                  OFFER
                </OfferButton>
              )}

              {showOfferedPositionText && 'Waiting for attendee to accept'}

              {showConfirmedPositionText && <strong>Job has been offered and accepted.</strong>}

              {(showOfferedPositionText || showConfirmedPositionText) && (
                <RevokeJobButton
                  isFreeAgent={!!isFreeAgent}
                  onClick={createOpenPositionModalHandler(JobActionType.CANCEL)}
                />
              )}
            </BannerRightSide>
          </BannerLayout>
        }
      />
    </LoadingContainer>
  );
};

export default StaffOfferBanner;
