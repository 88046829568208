import { combineEpics, Epic } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, switchMap, switchMapTo } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import toastService from '@/modules/toasts/service';
import { sessionTokenSel } from '@/modules/user/duck/selectors';

import addPersonModalEpics$ from '../components/AddSecondaryRegistrantModal/duck/epics';

import { confirmRegistration } from './actions';
import services from './services';

const confirmRegistrationRequest$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(confirmRegistration.request)),
    switchMap(({ payload: { formCode, formRecordGUID, sessionCode, finalActions = [] } }) => {
      const state = state$.value;
      const token = sessionTokenSel(state);

      if (!token) return EMPTY;

      if (!sessionCode) {
        throw new Error('Session Code not found');
      }

      return services
        .setFormRegistrationCompletedStatus$({
          formCode,
          token,
          formRecordGUID,
          sessionCode,
        })
        .pipe(
          map(response => {
            if (!['1', '-10'].includes(response.responseCode)) {
              throw new Error(response.responseMessage);
            }

            return response;
          }),
          switchMapTo(of(confirmRegistration.success(), ...finalActions)),
          catchError((error: Error) => {
            toastService.error(error.message);
            return of(confirmRegistration.failure(error));
          }),
        );
    }),
  );

export default combineEpics(addPersonModalEpics$, confirmRegistrationRequest$);
