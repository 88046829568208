import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { moduleName } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const noticeSel = createSelector(moduleSel, ({ notice }) => notice);

export const notificationsSel = createSelector(moduleSel, ({ notifications }) =>
  Array.from(notifications)
    .map(([, v]) => v)
    .reverse(),
);

export const unreadNotificationsCountSel = createSelector(
  notificationsSel,
  notifications => notifications.filter(({ read }) => !read).length,
);
