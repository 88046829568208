import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { RootAction, RootState } from 'typesafe-actions';

import { prefetchData$ } from '@/modules/data/duck/epics';
import { urlFormCodeSel } from '@/modules/location/duck/selectors';
import { personGuidSel } from '@/modules/user/duck/selectors';

import IndividualReservations from './IndividualReservationsList';
import { ROUTE_INDIVIDUAL_RESERVATION_LIST, pageDataParams } from './constants';

export default {
  [ROUTE_INDIVIDUAL_RESERVATION_LIST]: {
    path: '/registrations/:formCode/list/',
    titleId: 'page.registrationList',
    component: IndividualReservations,
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const state = state$.value;
      const formCode = urlFormCodeSel(state);
      const personGUID = personGuidSel(state);

      return prefetchData$(
        action$,
        ...pageDataParams.map(params => {
          switch (params.dataType) {
            case 'form':
              return { ...params, queryObj: { formCode } };
            case 'attendeeList':
              return { ...params, queryObj: { formCode, personGUID } };
          }
        }),
      );
    },
  },
};
