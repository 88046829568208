import { Card, Row, Col } from 'antd';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import { Payment as PaymentDataType } from '@/modules/data/dataTypes/paymentList';
import { PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { createIsLoadingSel } from '@/modules/data/duck/selectors';
import { reservationPaymentCategoriesSel } from '@/modules/entities/Reservations/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { UPDATE_PAYMENT_SCHEDULE_DUE_DATE_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import Payment from '@/modules/payments';
import {
  PaymentMethod,
  AllowedMethods,
  paymentMethodsData,
  PaymentMode,
} from '@/modules/payments/constants';
import {
  changePaymentStatusInProgressSel,
  makePaymentInProgressSel,
  makeRefundInProgressSel,
} from '@/modules/payments/duck/selectors';
import { useIsMultipleInstallmentsPaymentAllowed } from '@/modules/payments/hooks/useIsMultipleInstallmentsPaymentAllowed';
import Banner from '@/modules/shared/components/Banner';
import ReservationPaymentsCard from '@/modules/shared/components/ReservationPaymentsCard';
import toastService from '@/modules/toasts/service';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { personGuidSel } from '@/modules/user/duck/selectors';

import PaymentAmountPicker from '@/pages/reservation/components/ReservationPayments/components/PaymentAmountPicker';
import PaymentPageSummary from '@/pages/reservation/components/ReservationPayments/components/PaymentPageSummary';

import {
  currentInstallmentSel,
  nextFutureDueFeeSel,
  pastDueFeesSel,
  sortedPaymentsSel,
} from '../../duck/selectors';
import { UpdatePaymentScheduleDueDateModalParams } from '../MyReservation/components/UpdatePaymentScheduleDueDateModal/types';

import { PaymentWrapper } from './ReservationPayments.styled';

import { formStatus, cancelledOrDeclinedStatus, FormStatusCode } from 'SHARED/constants';

type GroupProps = {
  isGroup: true;
  groupReservation: GroupReservation;
};

type IndividualProps = {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};

type Props = (GroupProps | IndividualProps) & {
  form: FormSettings;
  isLoading: boolean;
  payments: PaymentDataType[];
  paymentSummary: PaymentSummary;
  // eslint-disable-next-line react/no-unused-prop-types
  paymentCategories: PaymentCategory[];
  enabledPaymentMethods: Set<PaymentMethod>;
};

const ReservationPayments: React.FC<Props> = props => {
  const { form: formSettings, isLoading, paymentSummary, isGroup, enabledPaymentMethods } = props;
  const dispatch = useDispatch();

  const currentUserPersonGuid = useSelector(personGuidSel);
  const pastDueFees = useSelector((state: RootState) => pastDueFeesSel(state, props));
  const nextFutureDueFee = useSelector((state: RootState) => nextFutureDueFeeSel(state, props));
  const currentInstallment = useSelector((state: RootState) => currentInstallmentSel(state, props));

  const [paymentMode, setPaymentMode] = useState<PaymentMode>(PaymentMode.Next);
  const [paymentMethod, setPaymentMethod] = useState<null | PaymentMethod>(null);
  const [paymentButtonDisabled, setPaymentButtonDisabled] = useState(false);
  const [triggerPayment, setTriggerPayment] = useState(false);
  const [amountToPay, setAmountToPay] = useState<number>(0);
  const [customAmountToPay, setCustomAmountToPay] = useState<number>(0);
  const [paymentComments, setPaymentComments] = useState('');

  const isMultipleInstallmentsPaymentAllowed = useIsMultipleInstallmentsPaymentAllowed({
    ...props,
    amountToPay,
    paymentMethod,
  });

  const { formCode, requireCouncilApproval, isCollaborator, isCreator } = formSettings;
  const { totalFee, amountPaid } = paymentSummary;
  const totalAmountDue = totalFee - amountPaid;

  const reservationStatus: FormStatusCode = isGroup
    ? props.groupReservation.registrationStatusCode
    : props.attendeeDetails.status;

  const isCancelled = cancelledOrDeclinedStatus.has(reservationStatus);

  const cardTitle = 'Payment Checkout';

  const paymentCategoryCode = useMemo(() => {
    const record = paymentSummary.records.find(r => {
      if (isGroup) {
        return r.groupReservationGUID === props.groupReservation.groupReservationGUID;
      }
      return r.formRecordGUID === props.attendeeDetails.formRecordGUID;
    });

    return record?.paymentCategoryCode;
  }, [
    paymentSummary,
    isGroup,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    props.groupReservation,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    props.attendeeDetails,
  ]);

  useEffect(() => {
    if (triggerPayment) {
      setTriggerPayment(false);
    }
  }, [triggerPayment]);

  const handleTriggerPayment = () => {
    if (!isMultipleInstallmentsPaymentAllowed && currentInstallment) {
      const label = paymentMethodsData.get(paymentMethod as AllowedMethods)?.label || '';
      toastService.error(
        `Payment beyond $${currentInstallment.dueAmount} cannot be made via ${label}`,
      );

      return;
    }

    setTriggerPayment(true);
  };

  const handleOnPaymentStarted = () => {
    setPaymentButtonDisabled(true);
  };

  const handleOnPaymentCompleted = () => {
    setPaymentButtonDisabled(false);
    setPaymentComments('');
    setAmountToPay(0);
  };

  const handleOnPaymentCancelled = () => {
    setPaymentButtonDisabled(false);
  };

  const handleOnPaymentError = () => {
    setPaymentButtonDisabled(false);
  };

  const disabledPaymentsMessage = useMemo<string | undefined>(() => {
    if (isCancelled) {
      return 'Reservation is cancelled.';
    } else if (totalAmountDue <= 0) {
      return 'No payments due.';
    }

    if (!isGroup) {
      if (
        requireCouncilApproval &&
        reservationStatus !== formStatus.registration_council_approved.code
      ) {
        return 'Reservation is not council approved';
      }

      if (!requireCouncilApproval && reservationStatus !== formStatus.registration_completed.code) {
        const reason = formStatus[reservationStatus]?.name;
        return `Registration needs to be completed${reason ? `: ${reason}` : ''}.`;
      }
    }
  }, [isCancelled, totalAmountDue, isGroup, requireCouncilApproval, reservationStatus]);

  const changePaymentStatusInProgress = useSelector(changePaymentStatusInProgressSel);
  const loadingPayments = useSelector(createIsLoadingSel('payments'));
  const reservationPayments = useSelector((state: RootState) => sortedPaymentsSel(state, props));
  const isCreatorOrCollaborator = isCreator || isCollaborator;
  const reservationPaymentCategories = useSelector((state: RootState) =>
    reservationPaymentCategoriesSel(state, props),
  );

  const onUpdatePaymentScheduleDueDate = (paymentCategory: PaymentCategory) => {
    const modalParams: UpdatePaymentScheduleDueDateModalParams = isGroup
      ? {
          isGroup: true,
          reservation: props.groupReservation,
          paymentCategory,
        }
      : {
          isGroup: false,
          reservation: props.attendeeDetails,
          paymentCategory,
        };
    dispatch(openModal(UPDATE_PAYMENT_SCHEDULE_DUE_DATE_MODAL, modalParams));
  };

  const handleMakeCustomPayment = useCallback((amount: number) => {
    if (amount) {
      setCustomAmountToPay(amount);
      setPaymentMode(PaymentMode.Custom);
    }
  }, []);

  const canViewPayments =
    useSelector(createAbilitiesSelector('payment', PermissionAction.Read, props)) &&
    (reservationPayments.length > 0 || totalFee > 0);

  return (
    <React.Fragment>
      {!!disabledPaymentsMessage && (
        <Banner type={isCancelled ? 'error' : 'warning'} message={disabledPaymentsMessage} />
      )}
      {canViewPayments && (
        <ReservationPaymentsCard
          {...props}
          isLoading={changePaymentStatusInProgress || loadingPayments}
          onMakePayment={handleMakeCustomPayment}
          paymentCategories={reservationPaymentCategories}
          reservationPayments={reservationPayments}
          {...(isCreatorOrCollaborator
            ? {
                onUpdatePaymentScheduleDueDate,
              }
            : {})}
        />
      )}
      {!!enabledPaymentMethods.size && (
        <PaymentWrapper>
          <Payment
            allowOfflinePayments
            amount={amountToPay}
            comments={paymentComments}
            formCode={formCode}
            isGroup={isGroup}
            guid={
              isGroup
                ? props.groupReservation.groupReservationGUID
                : props.attendeeDetails.formRecordGUID
            }
            enabledPaymentMethods={enabledPaymentMethods}
            paymentCategoryCode={paymentCategoryCode as string}
            triggerPayment={triggerPayment}
            personGUID={currentUserPersonGuid}
            paymentMethod={paymentMethod}
            onPaymentMethodChange={setPaymentMethod}
            onPaymentStart={handleOnPaymentStarted}
            onPaymentCompleted={handleOnPaymentCompleted}
            onPaymentCancelled={handleOnPaymentCancelled}
            onPaymentError={handleOnPaymentError}
          >
            {(paymentMethodPicker, isIframeDisplayed) => (
              <Row gutter={24}>
                <Col sm={16} xs={24}>
                  <Card title={cardTitle}>
                    {!isIframeDisplayed && (
                      <PaymentAmountPicker
                        paymentMethod={paymentMethod}
                        paymentMode={paymentMode}
                        onPaymentModeChange={setPaymentMode}
                        onAmountChange={setAmountToPay}
                        customAmount={customAmountToPay}
                        onCustomAmountChange={setCustomAmountToPay}
                        nextFutureDueFee={nextFutureDueFee}
                        pastDueFees={pastDueFees}
                        paymentSummary={paymentSummary}
                      />
                    )}
                    {paymentMethodPicker}
                  </Card>
                </Col>
                <Col sm={8} xs={24}>
                  <PaymentPageSummary
                    amount={amountToPay}
                    paymentComments={paymentComments}
                    paymentMethod={paymentMethod}
                    onPaymentCommentsChange={setPaymentComments}
                    onMakePayment={handleTriggerPayment}
                    paymentMode={paymentMode}
                    isLoading={isLoading}
                    isDisabled={
                      !enabledPaymentMethods.size || paymentButtonDisabled || isIframeDisplayed
                    }
                    hideMakePaymentButton={isIframeDisplayed}
                  />
                </Col>
              </Row>
            )}
          </Payment>
        </PaymentWrapper>
      )}
    </React.Fragment>
  );
};

const mapState = (state: RootState) => ({
  isLoading: makePaymentInProgressSel(state) || makeRefundInProgressSel(state),
});

export default connect(mapState)(ReservationPayments);
