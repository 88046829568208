import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

type Props = {
  isFreeAgent: boolean;
  onClick: () => void;
};

const RevokeJobButton: React.FC<Props> = ({ isFreeAgent, onClick }) => {
  const formSettings = useSelector(createDataSel('form'));

  const canEditEvent = useSelector(
    createAbilitiesSelector('event', PermissionAction.Update, formSettings),
  );
  const canCancelOffer = useSelector(createAbilitiesSelector('jobOffer', PermissionAction.Delete));

  if (isFreeAgent) {
    if (!canEditEvent) return null;
  } else {
    if (!canCancelOffer) return null;
  }

  return (
    <Button type="link" onClick={onClick}>
      REVOKE JOB
    </Button>
  );
};

export default RevokeJobButton;
