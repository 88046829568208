import React, { Fragment, PropsWithChildren, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PermissionAction } from '@/modules/entities/Roles/constants';
import { ATTENDEE_JOB_ACCEPT_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import Desktop from '@/modules/responsiveSizes/Desktop';
import EventCover from '@/modules/shared/components/EventCover';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import NotFoundData from '@/modules/shared/components/NotFoundData';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { AttendeeJobAcceptModalParams } from '@/pages/reservation/components/AttendeeJobAcceptModal/types';
import {
  attendeeAcceptedJobSel,
  attendeeOfferedJobSel,
  pageDataSel,
  futureTabKeySel,
  isLoadingTabSel,
} from '@/pages/reservation/duck/selectors';

import { tabs as tabsUnfiltered, TabKey } from '../../constants';
import { openTab } from '../../duck/actions';

import { Tabs, GoBack } from './ReservationDashboard.styled';

type Props = {
  tabs: typeof tabsUnfiltered;
};

const ReservationDashboard: React.FC<PropsWithChildren<Props>> = ({ tabs, children }) => {
  const dispatch = useDispatch();

  const {
    data: { groupReservation, form, attendeeDetails },
  } = useSelector(pageDataSel);

  const handleTabChange = (tab: string) => {
    dispatch(openTab(tab as TabKey));
  };

  const { formCode, allowGroupRegistration } = form;

  const futureTabKey = useSelector(futureTabKeySel);
  const isLoadingTab = useSelector(isLoadingTabSel);

  const attendeeOfferedJob = useSelector(attendeeOfferedJobSel); // job position alredy offered to attendee
  const attendeeAcceptedJob = useSelector(attendeeAcceptedJobSel);
  const canAcceptJobPosition = useSelector(
    createAbilitiesSelector('attendeeDetails', 'acceptJobPosition', attendeeDetails),
  );

  const canViewTabs = useSelector(
    createAbilitiesSelector(
      'reservation',
      PermissionAction.Read,
      allowGroupRegistration ? { groupReservation } : { attendeeDetails },
    ),
  );

  useEffect(() => {
    if (!attendeeAcceptedJob && canAcceptJobPosition && attendeeOfferedJob) {
      const params: AttendeeJobAcceptModalParams = { offeredJob: attendeeOfferedJob, formCode };
      dispatch(openModal(ATTENDEE_JOB_ACCEPT_MODAL, params));
    }
  }, [attendeeOfferedJob, attendeeAcceptedJob, canAcceptJobPosition, dispatch, formCode]);

  if (
    !(allowGroupRegistration ? groupReservation.reservationCode : attendeeDetails.reservationCode)
  ) {
    return <NotFoundData content="Sorry, event record was not found." />;
  }

  return (
    <Fragment>
      <EventCover
        title={allowGroupRegistration ? groupReservation.sessionName : attendeeDetails.sessionName}
        dates={
          allowGroupRegistration
            ? [groupReservation.sessionStartDate, groupReservation.sessionCloseDate]
            : undefined
        }
      />
      <Tabs
        activeKey={futureTabKey}
        renderTabBar={(tabsProps, DefaultTabBar) => (
          <Fragment>
            {canViewTabs && <DefaultTabBar {...tabsProps} />}
            <Desktop>
              <GoBack />
            </Desktop>
          </Fragment>
        )}
        onChange={handleTabChange}
        items={tabs}
        centered
      />
      <LoadingContainer isLoading={isLoadingTab}>{children}</LoadingContainer>
    </Fragment>
  );
};

export default ReservationDashboard;
