import { Input } from 'antd';
import React, { useState } from 'react';

import ModalComponent from '@/modules/modals/components/ModalComponent';

import Description from './components';
import { EditOptionsInBulkModalParams } from './types';
import { parseInputStringToOptions, parseOptionsToString } from './utils';

interface Props {
  modalParams: EditOptionsInBulkModalParams;
}

const EditOptionsInBulkModal: React.FC<Props> = ({ modalParams }) => {
  const { optionItems = [], onUpdateOptionItems } = modalParams;
  const [inputString, setInputString] = useState(parseOptionsToString(optionItems));

  const handleChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setInputString(event.target.value);

  const handleClickSave = () => {
    const parsedOptionItems = parseInputStringToOptions(inputString);

    if (!parsedOptionItems) return;

    onUpdateOptionItems(parsedOptionItems);
  };

  return (
    <ModalComponent
      buttons={[
        {
          title: 'SAVE',
          type: 'primary',
          onClick: handleClickSave,
        },
      ]}
      description={<Description />}
      title="Edit Options in Bulk"
    >
      <Input.TextArea
        placeholder="[answer option]-[answer-capacity]"
        rows={16}
        value={inputString}
        onChange={handleChangeInput}
      />
    </ModalComponent>
  );
};

export default EditOptionsInBulkModal;
