import React, { Fragment } from 'react';

import { Activity } from '@/modules/data/dataTypes/paymentSummary';
import {
  ACTIVITY_FIELD_ACTIVITY_CODE,
  ACTIVITY_FIELD_ACTIVITY_NAME,
  ACTIVITY_FIELD_IS_MANDATORY,
} from '@/modules/entities/Activities/constants';
import Currency from '@/modules/shared/components/Currency';
import Divider from '@/modules/shared/components/Divider';

import Row from '../Row';

type Props = {
  activities: Pick<
    Activity,
    'activityCode' | 'activityName' | 'fee' | 'isMandatory' | 'quantity' | 'totalFee'
  >[];
  subtotal: number;
};

const Activities: React.FC<Props> = ({ activities, subtotal }) => {
  if (!activities || !activities.length) return null;

  return (
    <Fragment>
      <Row columns={['Activities']} isBlue />
      {activities.map((activity, index) => {
        const isRequired = activity[ACTIVITY_FIELD_IS_MANDATORY];

        return (
          <Row
            key={`${activity[ACTIVITY_FIELD_ACTIVITY_CODE]}_${index}`}
            columns={[
              `${activity[ACTIVITY_FIELD_ACTIVITY_NAME]}${isRequired ? ' (required)' : ''}`,
              activity.quantity,
              <Currency key="amount" value={activity.totalFee} />,
            ]}
          />
        );
      })}
      <Divider />
      <Row
        columns={['Activities Subtotal', <Currency key="amount" value={subtotal} />]}
        extraBottomPadding
      />
    </Fragment>
  );
};

export default Activities;
