import React from 'react';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservationListReport';

import { ReservationStatusComponent } from './ReservationStatus.styled';

enum Status {
  InProgress = 'In Progress',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

const successStatuses = [Status.Completed];
const infoStatuses = [Status.InProgress];
const dangerStatuses = [Status.Cancelled];

type Props = {
  reservation: GroupReservation;
  isMobile?: boolean;
};

const ReservationStatus: React.FC<Props> = ({ reservation, isMobile = false }) => {
  const { isCancelled, isComplete } = reservation;

  let status: Status = Status.InProgress;

  if (isCancelled) {
    status = Status.Cancelled;
  } else if (isComplete) {
    status = Status.Completed;
  }

  return (
    <ReservationStatusComponent
      status={status}
      successStatuses={successStatuses}
      infoStatuses={infoStatuses}
      dangerStatuses={dangerStatuses}
      $isMobile={isMobile}
    />
  );
};

export default ReservationStatus;
