import * as React from 'react';
import { useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';

import { createFormInProgressSel } from '@/pages/createEvent/duck/selectors';

import { Column } from './TimezoneConfirmationModal.styled';
import { ModalParams } from './types';

interface Props {
  modalParams: ModalParams;
}

const TimezoneConfirmationModal: React.FC<Props> = ({ modalParams }) => {
  const { currentTimezone, newTimezone, onClickConfirm } = modalParams;

  const loading = useSelector(createFormInProgressSel);

  return (
    <ModalComponent
      title="Time zone Confirmation"
      buttons={[
        {
          title: 'Confirm',
          onClick: onClickConfirm,
          type: 'primary',
          size: 'small',
        },
      ]}
      inProgress={loading}
    >
      <p>
        Changing your time zone after event creation will re-label all your currently entered times
        as the new timezone. Times you entered previously will NOT be altered to fit the new
        timezone. It is recommended that you double check all your event times after making this
        change.
      </p>
      <Column>
        <span>
          <b>Current:</b> {currentTimezone?.timezoneName}
        </span>
        <span>
          <b>New:</b> {newTimezone?.timezoneName}
        </span>
      </Column>
    </ModalComponent>
  );
};

export default TimezoneConfirmationModal;
