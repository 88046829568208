import { Row, Col, Form } from 'antd';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo } from 'react';

import { AttendeeTypeForPerson } from '@/modules/data/dataTypes/attendeeTypeForPersonList';
import toastService from '@/modules/toasts/service';

import Banner from '../Banner';
import CardWithHeader from '../CardWithHeader';

import { Select } from './AttendeeTypes.styled';

type Props = {
  cardHeader?: string;
  attendeeTypes: Pick<
    AttendeeTypeForPerson,
    'typeCode' | 'typeName' | 'restrictions' | 'registrationCompletedAttendeeCount' | 'maximumUnit'
  >[];
  isMembershipEvent: boolean;
  isAlumni: boolean;
  hasActiveMembership: boolean;
  selectedAttendeeType?: string;
};

const AttendeeTypes = forwardRef<{ handleSubmit: () => Promise<string> | undefined }, Props>(
  (
    {
      cardHeader,
      isAlumni,
      hasActiveMembership,
      selectedAttendeeType,
      attendeeTypes,
      isMembershipEvent,
    },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));
    const [form] = Form.useForm();

    const filteredAttendeeTypes = useMemo(() => {
      if (isAlumni && !hasActiveMembership) {
        return attendeeTypes.filter(({ restrictions }) => !restrictions.isBSAMember);
      }
      return attendeeTypes;
    }, [isAlumni, hasActiveMembership, attendeeTypes]);

    const attendeeTypeOptions = useMemo(
      () =>
        filteredAttendeeTypes
          .filter(at => {
            const isMaxedOut = at.registrationCompletedAttendeeCount >= at.maximumUnit;
            return !isMaxedOut;
          })
          .map(at => ({
            value: at.typeCode,
            label: at.typeName,
          })),
      [filteredAttendeeTypes],
    );

    const canRenderForm = !!attendeeTypeOptions.length;

    function handleSubmit() {
      if (canRenderForm) {
        return form.validateFields().then(values => values.typeCode);
      }
      toastService.error("You can't proceed without selecting attendee type");
    }

    useEffect(() => {
      if (!selectedAttendeeType) {
        return;
      }
      if (!attendeeTypeOptions.some(at => at.value === selectedAttendeeType)) {
        toastService.error("You don't qualify for this event");
      }
    }, [attendeeTypeOptions, selectedAttendeeType]);

    const hasAttendeeTypesMaxedOut = useMemo(() => {
      if (filteredAttendeeTypes.length > 0 && attendeeTypeOptions.length === 0) return true;
      return !(filteredAttendeeTypes.length === 0 || attendeeTypeOptions.length > 0);
    }, [attendeeTypeOptions, filteredAttendeeTypes]);

    return (
      <Fragment>
        {isMembershipEvent && !hasActiveMembership && (
          <Banner
            type="warning"
            message="Some attendee types in this event require a valid BSA membership. If you do not see the
          attendee type you are looking for, please sign in with an account that has a valid BSA
          membership."
          />
        )}
        <CardWithHeader header={cardHeader}>
          {canRenderForm ? (
            <Form form={form} layout="vertical">
              <Row>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 9 }}>
                  <Form.Item
                    name="typeCode"
                    label="Select your attendee type:"
                    initialValue={selectedAttendeeType}
                    rules={[{ required: true, message: 'Please select an option' }]}
                  >
                    <Select options={attendeeTypeOptions} size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            <p className="text-center mt-2 mb-2" id="notQualifiedText">
              {hasAttendeeTypesMaxedOut
                ? 'The attendee type you qualify for has reached max capacity.'
                : "You don't qualify for this event."}
            </p>
          )}
        </CardWithHeader>
      </Fragment>
    );
  },
);

export default AttendeeTypes;
