export const sections = {
  personalInformation: {
    key: 'personalInformation',
    title: 'Personal Information',
  },
  activities: {
    title: 'Activities',
    key: 'activities',
  },
  addons: {
    key: 'addons',
    title: 'Add-ons',
  },
};
