import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import { RenderElementProps } from 'slate-react';

import styles from './VariableCaption.less';

type Props = {
  slateAttributes: RenderElementProps['attributes'];
  isSelected: boolean;
  notFound: boolean;
};

const VariableCaption: React.FC<PropsWithChildren<Props>> = ({
  children,
  slateAttributes,
  isSelected = false,
  notFound = false,
}) => (
  <span
    {...slateAttributes}
    className={cn(styles.variableCaption, {
      [styles.isSelected]: isSelected,
      [styles.notFound]: notFound,
    })}
  >
    {children}
  </span>
);

export default VariableCaption;
