import { CommentOutlined } from '@ant-design/icons';
import { List } from 'antd';
import React from 'react';

import type { Comment } from '@/modules/data/dataTypes/commentList';

import { Container, StyledAvatar, CommentText } from './CommentItem.styled';

import { dayWithTime } from 'UTILS/dateFormats';

interface Props {
  commentLog: Comment;
}

const CommentItem: React.FC<Props> = ({ commentLog }) => {
  const { updateDate, firstName, lastName, comment } = commentLog;

  return (
    <Container>
      <List.Item>
        <List.Item.Meta
          avatar={<StyledAvatar icon={<CommentOutlined />} size="large" shape="circle" />}
          title={
            <span>
              By <strong>{`${firstName} ${lastName}`}</strong>,{' '}
              <span>{dayWithTime(updateDate)}</span>
            </span>
          }
        />
      </List.Item>
      <CommentText>{comment}</CommentText>
    </Container>
  );
};

export default CommentItem;
