import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { VisibilitySettingsParsed } from '@/modules/data/dataTypes/visibilitySettings';
import { parseBase64 } from '@/modules/utils/stringFunctions';

import { editingSectionCodesSel, stepDataSel } from '../duck/selectors';

export default () => {
  const {
    data: { visibilitySettings },
  } = useSelector(stepDataSel);

  const { formPartCode, formItemCode } = useSelector(editingSectionCodesSel);

  const parsedSettings = useMemo(() => {
    if (formPartCode || formItemCode) {
      return parseBase64<VisibilitySettingsParsed>(
        formItemCode
          ? visibilitySettings[formPartCode]?.formItems[formItemCode]
          : visibilitySettings[formPartCode]?.visibilitySettings,
      );
    }
  }, [visibilitySettings, formPartCode, formItemCode]);

  return parsedSettings;
};
