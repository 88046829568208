import apiService from '@/modules/utils/apiService';

import {
  UpdatePaymentScheduleDueDatePayload,
  ValidateReservationContactInviteAPI,
} from '@/pages/reservation/types';

interface BaseUpdateGroupReservationContactPayload {
  personGUID: string;
  formCode: string;
  groupReservationGUID: string;
  sessionCode: string;
  destination: 'ARC' | 'RC';
}

export interface ReplaceGroupReservationContactPayload
  extends BaseUpdateGroupReservationContactPayload {
  action: 'UPDATE';
}

export interface RemoveGroupReservationContactPayload
  extends BaseUpdateGroupReservationContactPayload {
  destination: 'ARC';
  action: 'REMOVE';
}

export interface SendGroupReservationRCInvitePayload
  extends BaseUpdateGroupReservationContactPayload {
  lastName: string;
  emailAddress?: string;
}

type UpdateGroupReservationContactPayload =
  | ReplaceGroupReservationContactPayload
  | RemoveGroupReservationContactPayload;

const sendGroupReservationRCInvite$ = (payload: SendGroupReservationRCInvitePayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=SendGroupReservationRCInvitation',
    payload,
  );

const updateGroupReservationContact$ = (payload: UpdateGroupReservationContactPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateGroupReservationContact',
    payload,
  );

const cancelGroupReservationRCInvitation$ = (payload: {
  formCode: string;
  sessionCode: string;
  groupReservationGUID: string;
  personGUID: string;
  memberID: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=CancelGroupReservationRCInvitation',
    payload,
  );

const updatePaymentScheduleDueDate$ = (payload: UpdatePaymentScheduleDueDatePayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdatePaymentSchedule',
    payload,
  );

const validateGroupReservationRCInvite$ = ({
  contextToken,
  action,
}: ValidateReservationContactInviteAPI) =>
  apiService.get$(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=ValidateGroupReservationRCInvitation&contextToken=${contextToken}&action=${action}`,
  );

const getFormRecordGuid$ = (formCode: string, reservationCode: string) =>
  apiService.get$<{ responseValue: string }>(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=GetFormRecordCodeByReservationCode&formCode=${formCode}&reservationCode=${reservationCode}`,
  );

const assignHiringManager$ = (payload: {
  formCode: string;
  personGUID: string;
  jobDepartmentCode: string;
  isGranted: boolean;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateJobDepartmentPerson',
    payload,
  );

const updateFormRecordStatus$ = (data: {
  formCode: string;
  personGUID: string;
  statusCode: string;
  formRecordGUID: string;
  declineText?: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateFormRecordStatus',
    { ...data, isGranted: true },
  );

const transferRegistration$ = (payload: {
  formCode: string;
  newPersonGUID: string;
  formRecordGUID: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=SwapRegistrationPerson',
    payload,
  );

export default {
  transferRegistration$,
  assignHiringManager$,
  updateFormRecordStatus$,
  getFormRecordGuid$,
  sendGroupReservationRCInvite$,
  updateGroupReservationContact$,
  cancelGroupReservationRCInvitation$,
  validateGroupReservationRCInvite$,
  updatePaymentScheduleDueDate$,
};
