import { Input, Checkbox, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import { range } from 'lodash';
import React, { useEffect, createRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { EmailTemplate as ActiveEmailTemplate } from '@/modules/data/dataTypes/emailTemplate';
import { createDataSel, createIsLoadingSel } from '@/modules/data/duck/selectors';
import TemplateEditor from '@/modules/entities/Emails/components/TemplateEditor';
import TemplatePicker from '@/modules/entities/Emails/components/TemplatePicker';
import { sendCustomEmail } from '@/modules/entities/Emails/duck/actions';
import { sendCustomEmailInProgressSel } from '@/modules/entities/Emails/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import DatePicker from '@/modules/shared/components/DatePicker';
import Divider from '@/modules/shared/components/Divider';
import { primaryEmailSel } from '@/modules/user/duck/selectors';
import { getDateWithTimeFormat } from '@/modules/utils/dateFormats';
import { emailRegex } from '@/modules/utils/regularExpressions';

import { emailTemplatesSel, queryFilterVariablesSel } from '../../../../duck/selectors';

import { ModalParams } from './types';

const FIELD_NAME_EMAIL_CC_ADDRESS = 'emailCCAddress';
const FIELD_NAME_PROCESSING_START_DATE = 'processingStartDate';

type Props = {
  modalParams: ModalParams;
};

const SendCustomEmailModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const [scheduleEmail, setScheduleEmail] = useState(false);
  const [form] = Form.useForm();
  const senderEmailAddress = useSelector(primaryEmailSel);
  const isSendingEmail = useSelector(sendCustomEmailInProgressSel);
  const activeTemplate = useSelector(createDataSel('emailTemplate')) as
    | ActiveEmailTemplate
    | undefined;
  const isLoadingActiveTemplate = useSelector(createIsLoadingSel('emailTemplate'));
  const queryFilterVariables = useSelector((state: RootState) =>
    queryFilterVariablesSel(state, modalParams),
  );
  const emailTemplates = useSelector((state: RootState) => emailTemplatesSel(state, modalParams));

  const { timezoneCode } = useSelector(createDataSel('form'));

  const { queryFilter, dataType } = modalParams;

  const dateFormat = getDateWithTimeFormat(timezoneCode);

  const { validateFields } = form;

  const templateEditorRef = createRef<{
    getTemplateValues: () => Promise<{
      content: string;
      description: string;
      name: string;
      subject: string;
    }>;
  }>();

  useEffect(() => {
    const { getFieldsValue, setFieldsValue } = form;
    if (activeTemplate) {
      const values = getFieldsValue();
      setFieldsValue({ ...values, ...activeTemplate });
    }
  }, [activeTemplate, form]);

  function handleSubmit() {
    if (!templateEditorRef.current) return null;
    Promise.all([validateFields(), templateEditorRef.current.getTemplateValues()]).then(
      ([values, templateValues]) => {
        const { subject, content } = templateValues;

        const emailCCAddress: string | undefined = values[FIELD_NAME_EMAIL_CC_ADDRESS];
        const processingStartDate: Dayjs | undefined = values[FIELD_NAME_PROCESSING_START_DATE];

        const templateTouched =
          activeTemplate?.emailSubject !== subject ||
          activeTemplate?.emailTemplateContent !== content;

        const template =
          !templateTouched && activeTemplate
            ? {
                emailTemplateCode: activeTemplate.emailTemplateCode,
                emailTemplateName: activeTemplate.emailTemplateName,
              }
            : {
                senderEmailAddress: senderEmailAddress as string,
                emailTemplateContent: content,
                emailSubject: subject,
                emailTemplateName: activeTemplate?.emailTemplateName,
              };

        const mailing = {
          emailCCAddress,
          processingStartDate,
          queryFilterCode: queryFilter.queryFilterCode,
        };

        dispatch(sendCustomEmail.request({ template, mailing, dataType }));
      },
    );
  }

  const handleScheduleChange = (e: CheckboxChangeEvent) => {
    setScheduleEmail(e.target.checked);
  };

  const disableDate = (date: Dayjs | null) => {
    if (!date) return false;
    return date.isBefore(dayjs(), 'day');
  };

  const disableTime = (date?: Dayjs | null) => {
    if (!date) return {};
    const disabledTime: {
      disabledHours?: () => number[];
      disabledMinutes?: () => number[];
    } = {};

    if (date.isSameOrBefore(dayjs(), 'day')) {
      disabledTime.disabledHours = () => range(date.hour());
    }

    if (date.isSameOrBefore(dayjs(), 'minutes')) {
      disabledTime.disabledMinutes = () => range(date.minute());
    }

    return disabledTime;
  };

  return (
    <ModalComponent
      title={`Send custom email to ${queryFilter.queryFilterName}`}
      inProgress={isLoadingActiveTemplate || isSendingEmail}
      buttons={[
        {
          type: 'primary',
          onClick: handleSubmit,
          title: 'SEND',
        },
      ]}
    >
      <Form form={form} labelCol={{ span: 4 }}>
        <TemplatePicker activeTemplate={activeTemplate} emailTemplates={emailTemplates} />
        <Form.Item label="Schedule Email" labelCol={{ span: 4 }}>
          <Checkbox checked={scheduleEmail} onChange={handleScheduleChange} />
        </Form.Item>
        {scheduleEmail && (
          <Form.Item
            name={FIELD_NAME_PROCESSING_START_DATE}
            label="Delivery Date"
            rules={[{ required: true, message: 'Please select date' }]}
          >
            <DatePicker
              disabledDate={disableDate}
              disabledTime={disableTime}
              format={dateFormat}
              showTime
            />
          </Form.Item>
        )}
        <Divider />
        <Form.Item
          name={FIELD_NAME_EMAIL_CC_ADDRESS}
          label="CC"
          rules={[
            {
              validator: (_rule: unknown, value?: string) => {
                if (!value) return Promise.resolve();
                const ccEmailsInvalid = value
                  .split(',')
                  .some(email => !emailRegex.test(email.trim().toLowerCase()));
                if (ccEmailsInvalid) {
                  return Promise.reject(new Error('CC email address is invalid'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Form>
      <Divider />
      <TemplateEditor
        ref={templateEditorRef}
        template={activeTemplate || {}}
        queryFilterVariables={queryFilterVariables}
      />
    </ModalComponent>
  );
};

export default SendCustomEmailModal;
