import { Select as BsaSelect } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Select = styled(BsaSelect)`
  &&& {
    width: 75px;
    position: absolute;
    top: -15px;
    left: 10px;

    .ant-select-selection-selected-value {
      color: ${colors.darkGrey};
    }
  }
`;
