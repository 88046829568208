import { createAction, createAsyncAction } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { AttendeeType } from '@/modules/data/dataTypes/rosterList';
import { FormattedFormParts } from '@/modules/questions/types';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName, ROUTE_ADD_ATTENDEE, stepKeys } from '../constants';

export const openNextStep = createAction(`${moduleName}/OPEN_NEXT_STEP`)();
export const openPrevStep = createAction(`${moduleName}/OPEN_PREV_STEP`)();

export const openStepCompleted = createAction(`${moduleName}/OPEN_STEP_COMPLETED`)();

export const disableStep = createAction(`${moduleName}/DISABLE_STEP`)<{
  stepKey: stepKeys;
  reason: string;
}>();
export const enableStep = createAction(`${moduleName}/ENABLE_STEP`)<stepKeys>();

export const resetAddAttendeeState = createAction(`${moduleName}/RESET_ADD_ATTENDEE_STATE`)();

type AddAttendeeBasePayload = {
  formCode: string;
  rosterCode: string;
  attendeeType: AttendeeType;
  positionType?: 'LeadAdvisor';
  reservationDetails: GroupReservation;
  formRecordGUID?: string;
};

type AddAttendeeExistingPersonPayload = AddAttendeeBasePayload & {
  personExists: true;
  personGUID: string;
  isSameUser: boolean;
};

type AddAttendeeNewPersonPayload = AddAttendeeBasePayload & {
  personExists: false;
};

export const navigateToAddAttendee = createAction(ROUTE_ADD_ATTENDEE)<
  AddAttendeeExistingPersonPayload | AddAttendeeNewPersonPayload
>();

export const collectFormParts = createAction(
  `${moduleName}/COLLECT_FORM_PARTS`,
)<FormattedFormParts>();
export const collectRegistrationData = createAction(`${moduleName}/COLLECT_REGISTRATION_DATA`)<
  Partial<AttendeeDetails>
>();

export const addAttendee = createAsyncAction(
  `${moduleName}/ADD_ATTENDEE_REQUEST`,
  `${moduleName}/ADD_ATTENDEE_SUCCESS`,
  `${moduleName}/ADD_ATTENDEE_FAILURE`,
)<
  { formRecordGUID: string; bypassValidation: true } | undefined,
  undefined,
  ApiError | undefined
>();
