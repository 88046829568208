/* eslint-disable react/no-multi-comp */
import { UserOutlined } from '@ant-design/icons';
import { List } from 'antd';
import React, { useMemo } from 'react';

import { EventLog } from '@/modules/data/dataTypes/eventLogs';

import { Container, StyledAvatar } from './LogItem.styled';
import DeleteDescription from './components/DeleteDescription';
import InsertDescription from './components/InsertDescription';
import UpdateDescription from './components/UpdateDescription';

import { dayWithTime } from 'UTILS/dateFormats';

interface Props {
  eventLog: EventLog;
}

const LogItem: React.FC<Props> = ({ eventLog }) => {
  const { firstName, lastName, actionDate, action, api } = eventLog;

  const description = useMemo(() => {
    const DESCRIPTION_BUILDERS = {
      Update: <UpdateDescription eventLog={eventLog} />,
      Delete: <DeleteDescription />,
      Insert: <InsertDescription />,
    };
    return DESCRIPTION_BUILDERS[action || 'Update'];
  }, [action, eventLog]);

  return (
    <Container>
      <List.Item>
        <List.Item.Meta
          avatar={<StyledAvatar icon={<UserOutlined />} size="large" shape="circle" />}
          title={
            <span>
              By <strong>{`${firstName} ${lastName}`}</strong>,{' '}
              <span>{dayWithTime(actionDate)}</span>
            </span>
          }
        />
      </List.Item>
      <div>
        {description}
        <div>
          <h3>{<code>{`${action} - ${api}`}</code>}</h3>
        </div>
      </div>
    </Container>
  );
};

export default LogItem;
