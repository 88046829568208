import { chunk } from 'lodash';
import { combineEpics, Epic } from 'redux-observable';
import { concat, defer, forkJoin, merge, of, race } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { isActionOf, RootAction } from 'typesafe-actions';

import { refreshData } from '@/modules/data/duck/actions';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import {
  createFormAttribute,
  updateAttendeesAttributes,
  createAndSelectAttendeeAttribute,
} from './actions';
import services from './services';

const createFormAttributeRequest$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(createFormAttribute.request)),
    switchMap(({ payload }) =>
      services.createFormAttribute$(payload).pipe(
        switchMap(({ responseValue: attributeCode }) => {
          toastService.success('Attribute Created');
          return of(
            createFormAttribute.success({ attributeCode: attributeCode as string }),
            refreshData({ dataType: 'attributeList' }),
          );
        }),
        catchError((error: ApiError) => {
          toastService.error(error?.message || 'Could not create attribute');
          return of(createFormAttribute.failure(error));
        }),
      ),
    ),
  );

const updateAttendeesAttributesRequest$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(updateAttendeesAttributes.request)),
    switchMap(({ payload }) => {
      const {
        formCode,
        isDelete,
        attributes: attributeCodeList = [],
        attendees = [],
        finalActions = [],
      } = payload;
      const attendeesGroups = chunk(attendees, 1000);
      const attributes = attributeCodeList.map(attributeCode => ({ attributeCode }));

      return forkJoin(
        attendeesGroups.map(formRecordGUIDList =>
          services.assignAttributesToMultipleAttendees$({
            formCode,
            isDelete,
            formRecordGUIDList,
            attributes,
          }),
        ),
      ).pipe(
        switchMap(() => {
          toastService.success(
            isDelete
              ? 'Successfully removed attributes from selected attendees'
              : 'Successfully added attributes to selected attendees',
          );
          return of(
            updateAttendeesAttributes.success(),
            refreshData({ dataType: 'formRecordAttributes' }),
            ...finalActions,
          );
        }),
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(updateAttendeesAttributes.failure(error));
        }),
      );
    }),
  );

const createAndSelectAttendeeAttribute$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(createAndSelectAttendeeAttribute.request)),
    switchMap(
      ({ payload: { formCode, attributeName, attendeeFormRecordGUID, assignedAttributes } }) =>
        concat(
          merge(
            race(
              action$.pipe(
                filter(isActionOf(createFormAttribute.success)),
                take(1),
                switchMap(({ payload: { attributeCode } }) =>
                  merge(
                    race(
                      action$.pipe(filter(isActionOf(updateAttendeesAttributes.success)), take(1)),
                      action$.pipe(
                        filter(isActionOf(updateAttendeesAttributes.failure)),
                        take(1),
                        map(({ payload }) => {
                          throw new Error(payload.message);
                        }),
                      ),
                    ),
                    of(
                      updateAttendeesAttributes.request({
                        formCode,
                        isDelete: false,
                        attendees: [attendeeFormRecordGUID],
                        attributes: [...assignedAttributes, attributeCode],
                      }),
                    ),
                  ),
                ),
              ),
              action$.pipe(
                filter(isActionOf(createFormAttribute.failure)),
                take(1),
                map(({ payload }) => {
                  throw new Error(payload.message);
                }),
              ),
            ),
            // leting the backend handle the attributeCode creation
            of(createFormAttribute.request({ formCode, attributeCode: '', attributeName })),
          ),
          defer(() => of(createAndSelectAttendeeAttribute.success())),
        ),
    ),
    catchError((error: Error) => {
      toastService.error(error.message);
      return of(createAndSelectAttendeeAttribute.failure(error));
    }),
  );

export default combineEpics(
  createFormAttributeRequest$,
  updateAttendeesAttributesRequest$,
  createAndSelectAttendeeAttribute$,
);
