import { Typography } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 3rem;
`;

export const FlexItem = styled.div`
  flex: 1;
`;

export const BlueTitle = styled(Typography.Title)`
  && {
    color: ${colors.primary_5};
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  &&& {
    > * {
      margin-top: 0;
    }
  }
`;

export const Comments = styled.div`
  margin-top: 1rem;
  word-break: break-all;
`;
