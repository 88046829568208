import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip, Col } from 'antd';
import cn from 'classnames';
import React from 'react';

import styles from './Row.less';
import { Row } from './Row.styled';

type Props = {
  columns: Array<string | React.ReactNode>;
  isIndented?: boolean;
  tooltipText?: string;
  isHeader?: boolean;
  isBlue?: true;
  extraBottomPadding?: boolean;
};

const PaymentSummaryRow: React.FC<Props> = ({
  columns,
  isHeader,
  isIndented,
  isBlue,
  extraBottomPadding,
  tooltipText,
}) => {
  const columnsSpans = columns.reduce((acc: number[], _col, index) => {
    const isFirstCol = index === 0;

    if (isFirstCol) {
      return [24];
    }

    let span;
    const nextSpans = [...acc];
    const isLastCol = index === columns.length - 1;

    if (isLastCol) {
      span = 6;
    } else {
      span = 5;
    }

    nextSpans[0] -= span;
    nextSpans[index] = span;

    return nextSpans;
  }, []);

  return (
    <Row
      className={cn(styles.row, {
        [styles.header]: isHeader,
        [styles.extraBottomPadding]: extraBottomPadding,
      })}
      $isBlue={isBlue}
    >
      {columns.map((text, index) => (
        <Col
          key={index}
          span={columnsSpans[index]}
          className={cn({
            [styles.isIndented]: isIndented && index === 0,
            [styles.alignRight]: index !== 0,
          })}
        >
          {text}
          {tooltipText && index === 0 && (
            <Tooltip title={tooltipText}>
              <InfoCircleFilled className={styles.tooltipIcon} />
            </Tooltip>
          )}
        </Col>
      ))}
    </Row>
  );
};

PaymentSummaryRow.defaultProps = {
  isIndented: false,
  isHeader: false,
  extraBottomPadding: false,
};

export default PaymentSummaryRow;
