import apiService, { arnicaApiService } from '@/modules/utils/apiService';

export interface CloneEventPayload {
  formCode: string;
  clonedEventName: string;
  description: string;
  eventCodeName: string;
}

const cloneEvent$ = ({
  formCode,
  eventCodeName,
  clonedEventName,
  description,
}: CloneEventPayload) =>
  apiService.post$<{ formCode: string }>(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=CloneForm',
    {
      sourceFormCode: formCode,
      formName: clonedEventName,
      description,
      eventCodeName,
    },
  );

const validateCollaboratorInvite$ = (formCode: string, contextToken: string) =>
  arnicaApiService('ValidateContextAndUpdateFormPerson', { formCode, contextToken });

export default {
  cloneEvent$,
  validateCollaboratorInvite$,
};
