import { Radio as RadioBsa } from 'antd';
import styled from 'styled-components';

export const RadioGroup = styled(RadioBsa.Group)`
  display: flex;
  flex-direction: column;
`;

export const Radio = styled(RadioBsa)`
  margin-bottom: 5px;
`;
