import { phoneFormats } from './constants';

const getPhoneFormat = (phoneNumber: string): string => {
  if (!phoneNumber) return '';
  const foundFormat = phoneFormats[phoneNumber];
  if (foundFormat) return foundFormat;
  return getPhoneFormat(phoneNumber.slice(0, phoneNumber.length - 1));
};

export const getPhoneLength = (phoneNumber: string): number => {
  const format = getPhoneFormat(phoneNumber);
  return format.replace(/[^.]/g, '').length;
};

export const formatPhone = (phoneNumber: string): string => {
  if (!phoneNumber) return phoneNumber;
  const format = getPhoneFormat(phoneNumber);
  const formatLength = getPhoneLength(phoneNumber);

  if (!format) return phoneNumber;

  let nextPhoneNumber = format;

  let i = 0;

  while (i < Math.min(phoneNumber.length, formatLength)) {
    nextPhoneNumber = nextPhoneNumber.replace('.', phoneNumber[i]);
    i += 1;
  }

  return `${nextPhoneNumber.replace(/\./g, '')}${phoneNumber.slice(i)}`;
};
