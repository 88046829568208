import { Space as AntSpace } from 'antd';
import styled from 'styled-components';

export const Space = styled(AntSpace)`
  width: 100%;

  > *:first-child {
    width: 100%;
  }
`;
