import { Select as AntSelect } from 'antd';
import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Select = styled(AntSelect)`
  width: 100%;
` as typeof AntSelect;
