import { combineEpics, Epic } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError, filter, map, switchMap, switchMapTo } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { locales, bsaLocales, locale } from '../constants';

import { fetchMessages, getBsaLocales } from './actions';
import services from './services';

const fetchMessages$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(fetchMessages.request)),
    switchMap(() => services.getMessages$(locales[locale])),
    map(({ response }) => fetchMessages.success(response)),
    catchError((error: Error, caught) => merge(of(fetchMessages.failure(error)), caught)),
  );

const getBsaLocales$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(getBsaLocales.request)),
    switchMapTo(bsaLocales[locale]()),
    map(bsaLocaleItem => getBsaLocales.success(bsaLocaleItem.default)),
    catchError((error: Error, caught) => merge(of(getBsaLocales.failure(error)), caught)),
  );

export default combineEpics(fetchMessages$, getBsaLocales$);
