import { Form } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const FormItem = styled(Form.Item)`
  &&& .ant-form-item-explain-error {
    color: ${colors.error};
    line-height: 1.5;
    margin-bottom: 0;
  }
`;
