import { Card, Button, Row, Col } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';

import { navigateToIndividualReservation } from '@/pages/reservation/duck/actions';

type Props = {
  formCode: string;
  formRecordGUID: string;
};

const RegisteredRenderer: React.FC<Props> = ({ formCode, formRecordGUID }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(redirect(navigateToIndividualReservation({ formCode, formRecordGUID })));
  };

  return (
    <Card className="mt-6 text-center">
      <h2 className="text-primary">Already Registered </h2>
      <Row>
        <Col span={14} offset={5}>
          <p className="mt-3">
            Whoops, it looks like you already have a reservation for this event. Click below to view
            your reservation dashboard or to make an additional payment.
          </p>
          <Button className="mt-2" type="default" onClick={handleClick}>
            My Reservation
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default RegisteredRenderer;
