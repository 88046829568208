import { Row, Col, Form } from 'antd';
import React, { useEffect } from 'react';

import { Session } from '@/modules/data/dataTypes/sessionList';
import { SessionTotalFee } from '@/modules/types/sessionTotalFee';

import { FormItem } from './EstimatedFee.styled';

import InputMaterial from 'SHARED/components/InputMaterial';

type Props = {
  session: Session;
  changeEstimatedSessions: (sessionCode: string, value: boolean) => void;
  registeredPeople: number;
  minCrews: number;
  maxCrews: number;
  initialRosterCount: number;
  setGroupInitialSettings: (value: number) => void;
  getGroupTotalFee: (payload: {
    sessionCode: string;
    numberOfPeople: number;
    numberOfCrews: number;
  }) => void;
  fees: SessionTotalFee;
  isLoadingFees: boolean;
};

const EstimatedFee: React.FC<Props> = ({
  session,
  changeEstimatedSessions,
  registeredPeople,
  setGroupInitialSettings,
  getGroupTotalFee,
  fees = {},
  isLoadingFees,
  minCrews,
  maxCrews,
  initialRosterCount,
}) => {
  const [form] = Form.useForm();
  const { validateFields, setFieldsValue } = form;
  const { sessionCode, isFeePerPerson, remainingRosters, maximumPeopleCount, minimumPeopleCount } =
    session;

  const {
    totalFeePerPerson,
    totalFeePerRoster,
    totalDepositPerPerson,
    totalDepositPerRoster,
    totalAdoonFeePerPerson,
    totalAddonFeePerRoster,
    totalActivityFeePerPerson,
    totalActivityFeePerRoster,
  } = fees;

  const sessionFee = isFeePerPerson ? totalFeePerPerson : totalFeePerRoster;
  const deposit = isFeePerPerson ? totalDepositPerPerson : totalDepositPerRoster;
  const addonFee = isFeePerPerson ? totalAdoonFeePerPerson : totalAddonFeePerRoster;
  const activityFee = isFeePerPerson ? totalActivityFeePerPerson : totalActivityFeePerRoster;

  useEffect(() => {
    changeEstimatedSessions(sessionCode, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFieldsValue({ numberOfCrews: minCrews });
    handleFieldsChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredPeople]);

  const handleFieldsChange = () => {
    validateFields()
      .then(({ numberOfCrews }) => {
        changeEstimatedSessions(sessionCode, true);
        if (numberOfCrews) {
          setGroupInitialSettings(Number(numberOfCrews));

          getGroupTotalFee({
            sessionCode,
            numberOfPeople: registeredPeople,
            numberOfCrews,
          });
        }
      })
      .catch(() => {
        changeEstimatedSessions(sessionCode, false);
      });
  };

  const estimatedFee =
    Number(sessionFee || 0) +
    Number(deposit || 0) +
    Number(addonFee || 0) +
    Number(activityFee || 0);

  return (
    <Form form={form} onChange={handleFieldsChange}>
      <Row gutter={24}>
        <Col sm={5} xs={24}>
          <FormItem
            name="numberOfCrews"
            initialValue={initialRosterCount || minCrews}
            rules={[
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                validator: async (_rule: any, value = 0) => {
                  if (value > remainingRosters) {
                    return Promise.reject(
                      new Error(`This session has only ${remainingRosters} crews available`),
                    );
                  }
                  if (value < minCrews || !value) {
                    return Promise.reject(
                      new Error(`Your number of crews must be at least ${minCrews}`),
                    );
                  }
                  if (value > maxCrews) {
                    return Promise.reject(
                      new Error(
                        `In order to select ${value} crews, please input a total number of Youth and Adults between ${minimumPeopleCount} and ${maximumPeopleCount}`,
                      ),
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputMaterial
              labelContent={<span># of Crews</span>}
              labelSize="1"
              labelColored
              labelInlineBlock
              name="numberOfCrews"
              type="number"
              placeholder="# of Crews"
            />
          </FormItem>
        </Col>
        <Col sm={5} xs={24}>
          <InputMaterial
            labelContent={<span># of People</span>}
            labelSize="1"
            labelColored
            labelInlineBlock
            name="registeredPeople"
            type="number"
            value={registeredPeople}
            disabled
          />
        </Col>
        <Col sm={5} xs={24}>
          <InputMaterial
            labelContent={<span>Estimated Amount for Crews</span>}
            labelSize="1"
            labelColored
            labelInlineBlock
            name="totalFee"
            contentAfter="USD"
            type="money"
            value={isNaN(estimatedFee) ? 0 : estimatedFee}
            loading={isLoadingFees}
            disabled
          />
        </Col>
      </Row>
    </Form>
  );
};

EstimatedFee.defaultProps = {
  registeredPeople: 0,
  isLoadingFees: false,
  initialRosterCount: 0,
};

export default EstimatedFee;
