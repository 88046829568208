import { Row } from 'antd';
import React, { Fragment } from 'react';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';

import GroupReservationSections from './components/GroupReservationSections';
import IndividualReservationSections from './components/IndividualReservationSections';

type GroupProps = {
  isGroup: true;
  groupReservation: GroupReservation;
};
type IndividualProps = {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};
type Props = GroupProps | IndividualProps;

const Details: React.FC<Props> = props => {
  const { isGroup } = props;

  return (
    <Fragment>
      <Row justify="start">
        <h1>Reservation Details</h1>
      </Row>
      {isGroup ? (
        <GroupReservationSections />
      ) : (
        <IndividualReservationSections reservationDetails={props.attendeeDetails} />
      )}
    </Fragment>
  );
};

export default Details;
