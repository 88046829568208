import { Pagination as AntPagination, Card as AntCard } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
`;

export const Pagination = styled(AntPagination)`
  text-align: center;
  background-color: ${colors.white};
  padding: 1.2rem;
`;

export const Card = styled(AntCard)`
  background: none;

  & > .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }
`;
