import { combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { RootAction, isActionOf } from 'typesafe-actions';

import { removeDataItem, replaceDataItem } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import toastService from '@/modules/toasts/service';

import { deleteMultiSessionRule, updateMultiSessionRule } from './actions';
import services from './services';

const ID_FIELD = 'multiSessionRuleCode';

const updateRule$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateMultiSessionRule.request)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);

      return services.updateMultiSessionCapacityRule$({ ...payload, formCode }).pipe(
        switchMap(({ responseValue }) =>
          of(
            updateMultiSessionRule.success(),
            replaceDataItem({
              dataType: 'multiSessionCapacityRuleList',
              idField: ID_FIELD,
              dataItem: {
                ...payload,
                [ID_FIELD]: responseValue,
              },
            }),
          ),
        ),
        catchError(error => {
          toastService.error(error.message);
          return of(updateMultiSessionRule.failure(error));
        }),
      );
    }),
  );

const deleteRule$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteMultiSessionRule.request)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);

      return services
        .deleteMultiSessionCapacityRule$({ formCode, multiSessionRuleCode: payload })
        .pipe(
          switchMap(() =>
            of(
              deleteMultiSessionRule.success(),
              removeDataItem({
                dataType: 'multiSessionCapacityRuleList',
                idField: ID_FIELD,
                dataItemId: payload,
              }),
            ),
          ),
          catchError(error => {
            toastService.error(error.message);
            return of(deleteMultiSessionRule.failure(error));
          }),
        );
    }),
  );

export default combineEpics(updateRule$, deleteRule$);
