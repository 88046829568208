import { Observable } from 'rxjs';

import apiService, { createParamsFromObject } from '@/modules/utils/apiService';

const deleteMailing$ = (payload: { formCode: string; mailingCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteMailing',
    payload,
  );

const downloadSessionsCSV$ = (formCode: string, recordCount: number, programCode?: string) => {
  const params = createParamsFromObject({
    formCode,
    ...(programCode ? { programCodeList: programCode } : {}),
    recordCount,
  });

  return apiService.get$(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=GetSessionList&&reportType=CSV${params}`,
    {
      responseType: 'text',
    },
  ) as Observable<string>;
};

const summaryReportCSV$ = ({ formCode, groupBy }: { formCode: string; groupBy: string }) => {
  let url = `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=GetEventSummary&formCode=${formCode}&groupBy=${groupBy}&reportType=CSV`;

  if (groupBy === 'combined') {
    url = `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=GetEventCombinedSummary&formCode=${formCode}&allCouncils=1&reportType=CSV`;
  }

  if (groupBy === 'staffPositions') {
    url = `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=GetEventStaffPositions&formCode=${formCode}&reportType=CSV`;
  }

  if (groupBy === 'jobAvailability') {
    url = `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=GetFormJobsStatistics&formCode=${formCode}&reportType=CSV`;
  }

  return apiService.get$(
    url,
    // Required to get the plain text response, content type didn't worked
    {
      responseType: 'text',
    },
  ) as Observable<string>;
};

const getPaymentsHistoryCSV$ = (formCode: string, startDate: string, finishDate: string) =>
  apiService.get$(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=GetFormPayments&reportType=CSV&FormCode=${formCode}&startDate=${startDate}&finishDate=${finishDate}`,
    {
      responseType: 'text',
    },
  ) as Observable<string>;

export default {
  deleteMailing$,
  downloadSessionsCSV$,
  getPaymentsHistoryCSV$,
  summaryReportCSV$,
};
