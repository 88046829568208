import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import React, { PropsWithChildren, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@/modules/loading';
import { locale } from '@/modules/localization/constants';
import { bsaLocalesSel, messagesSel } from '@/modules/localization/duck/selectors';
import colors from '@/modules/styles/colors';

import { appInit } from '../../duck/actions';
import { appLoadCompletedSel } from '../../duck/selectors';

const AppInit: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const messages = useSelector(messagesSel);
  const localeData = useSelector(bsaLocalesSel);
  const appLoadCompleted = useSelector(appLoadCompletedSel);

  useEffect(() => {
    dayjs.locale(locale);
    dispatch(appInit.request());
  }, [dispatch]);

  return (
    <ConfigProvider
      getPopupContainer={() => document.getElementById('app') as HTMLElement}
      theme={{
        components: {
          Table: {
            colorBgBase: colors.primary,
            colorTextHeading: colors.white,
          },
        },
        token: {
          colorBorder: colors.gray_4,
          colorError: colors.redStatus,
          colorInfo: colors.lightBlueStatus,
          colorPrimary: colors.primary,
          colorSuccess: colors.greenStatus,
          colorWarning: colors.yellowStatus,
          colorText: '#525455',
          borderRadius: 4,
        },
      }}
      {...(localeData ? { locale: localeData } : {})}
    >
      {appLoadCompleted && (
        <IntlProvider locale={locale} {...(messages ? { messages } : {})}>
          {children}
        </IntlProvider>
      )}
      <Loading />
    </ConfigProvider>
  );
};

export default AppInit;
