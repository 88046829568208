import colors from '@/modules/styles/colors';

const formStatusCodes = [
  'registration_in_progress',
  'disclaimer_pending_review',
  'council_approval_pending',
  'attendee_cancelled',
  'council_declined',
  'collaborator_cancelled',
  'registration_council_approved',
  'registration_completed',
] as const;
const formStatusNames = [
  'In Progress',
  'Disclaimer Pending',
  'Pending Council Review',
  'Attendee Cancelled',
  'Council Declined',
  'Collaborator Cancelled',
  'Registration Council Approved',
  'Registration Completed',
] as const;

export type FormStatusCode = (typeof formStatusCodes)[number];
type FormStatusName = (typeof formStatusNames)[number];

// Status based on EM-2005
export const formStatus = Object.freeze({
  registration_in_progress: {
    code: 'registration_in_progress' as FormStatusCode,
    name: 'In Progress' as FormStatusName,
    color: colors.yellowStatus,
  },
  disclaimer_pending_review: {
    code: 'disclaimer_pending_review' as FormStatusCode,
    name: 'Disclaimer Pending' as FormStatusName,
    color: colors.yellowStatus,
  },
  council_approval_pending: {
    code: 'council_approval_pending' as FormStatusCode,
    name: 'Pending Council Review' as FormStatusName,
    color: colors.yellowStatus,
  },
  attendee_cancelled: {
    code: 'attendee_cancelled' as FormStatusCode,
    name: 'Attendee Cancelled' as FormStatusName,
    color: colors.redStatus,
  },
  council_declined: {
    code: 'council_declined' as FormStatusCode,
    name: 'Council Declined' as FormStatusName,
    color: colors.redStatus,
  },
  collaborator_cancelled: {
    code: 'collaborator_cancelled' as FormStatusCode,
    name: 'Collaborator Cancelled' as FormStatusName,
    color: colors.redStatus,
  },
  registration_council_approved: {
    code: 'registration_council_approved' as FormStatusCode,
    name: 'Registration Council Approved' as FormStatusName,
    color: colors.greenStatus,
  },
  registration_completed: {
    code: 'registration_completed' as FormStatusCode,
    name: 'Registration Completed' as FormStatusName,
    color: colors.greenStatus,
  },
});

export const cancelledOrDeclinedStatus = new Set([
  formStatus.collaborator_cancelled.code,
  formStatus.attendee_cancelled.code,
  formStatus.council_declined.code,
]);

// const enums they are completely removed during compilation and its enum
// members are inlined at use sites. See:
// https://www.typescriptlang.org/docs/handbook/enums.html#const-enums
export const enum PresentationType {
  TEXT = 0,
  DROPDOWN = 1,
  CHECKBOX = 2,
  SWITCH = 3,
  DATE_PICKER = 4,
  TEXTAREA = 5,
  FILE_UPLOAD = 6,
  RADIO = 7,
  DESCRIPTION = 8,
  DATE_RANGE_PICKER = 9,
  EMAIL = 10,
  PHONE = 11,
  INTERNATIONAL_PHONE = 12,
  DROPDOWN_BULK = 13,
}

export enum FormItemDataType {
  String = 1,
  Number = 2,
  Datetime = 3,
  Boolean = 4,
  Text = 5,
}

export const PRESENTATION_TYPES_WITH_OPTIONS = [
  PresentationType.DROPDOWN,
  PresentationType.CHECKBOX,
  PresentationType.RADIO,
  PresentationType.DROPDOWN_BULK,
];

export const presentationTypeNames = [
  'text', // 0
  'dropdown', // 1
  'checkbox', // 2
  'switch', // 3
  'datepicker', // 4
  'textarea', // 5
  'file_upload', // 6
  'radio', // 7
  'description', // 8
  'rangepicker', // 9
  'email', // 10
  'phone', // 11
  'internationalphone', // 12
  'dropdown_bulk', // 13
] as const;

export const ADULT_AGE_HIGH = 21;

export const ADULT_AGE_LOW = 18;

export const MAX_FIELD_CHARS = 254;

export const nameSuffixes = ['Jr.', 'Sr.', 'II', 'III', 'IV', 'V', 'VI'];
