import { DatePicker as DatePickerAntd, Select as SelectAntd, Input as InputAntd } from 'antd';
import styled from 'styled-components';

export const DatePicker = styled(DatePickerAntd)`
  width: 100%;
`;

export const Select = styled(SelectAntd)`
  width: 100%;
` as typeof SelectAntd;

export const Input = styled(InputAntd)`
  width: 100%;
`;
