import { Dayjs } from 'dayjs';
import { Descendant } from 'slate';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';

export const updateBroadcast = createAsyncAction(
  `${moduleName}/UPDATE_BROADCAST_REQUEST`,
  `${moduleName}/UPDATE_BROADCAST_SUCCESS`,
  `${moduleName}/UPDATE_BROADCAST_FAILURE`,
)<
  {
    startDate: Dayjs;
    endDate: Dayjs;
    content: Descendant[];
  },
  undefined,
  ApiError
>();

export const saveBroadcastMessage = createAction(`${moduleName}/SAVE_BROADCAST_MESSAGE`)<string>();

export const startBroadcastListening = createAction(`${moduleName}/START_BROADCAST_LISTENING`)();

export const dismissMessage = createAction(`${moduleName}/DISMISS_MESSAGE`)<string>();

export const clearBroadcast = createAction(`${moduleName}/CLEAR_BROADCAST`)();
