import { arnicaApiService } from '@/modules/utils/apiService';

const formRecordsMassUpdate$ = (payload: {
  formCode: string;
  registrations: {
    reservationCode: string;
    formParts: {
      formPartCode: string;
      formItems: {
        formItemCode: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formItemValue: any;
      }[];
    }[];
  }[];
}) =>
  arnicaApiService<{
    registrations: { message: string; reservationCode: string; status: string }[];
  }>('FormRecordsMassUpdate', payload);

export default {
  formRecordsMassUpdate$,
};
