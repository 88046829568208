import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { stepKeys } from '@/pages/createReservation/constants';
import { openStepByKey } from '@/pages/createReservation/duck/actions';

import styles from './RegistrationTimeoutModal.less';

const RegistrationTimeoutModal: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(openStepByKey(stepKeys.sessionPriorities));
  });

  function handleSubmit() {
    dispatch(closeModal());
  }

  return (
    <ModalComponent
      buttons={[
        {
          title: 'Select Session',
          onClick: handleSubmit,
          type: 'primary',
          className: styles.button,
          size: 'small',
        },
      ]}
      inProgress={false}
      centerButtons
    >
      <div className={styles.container}>
        <ExclamationCircleOutlined />
        <h4>Registration Timeout</h4>
        <p>The time for completing the registration has expired</p>
        <p>Please select another session</p>
      </div>
    </ModalComponent>
  );
};
export default RegistrationTimeoutModal;
