import { Button, Select } from 'antd';
import get from 'lodash/get';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { VisibilityLogicAction } from '@/modules/data/dataTypes/visibilitySettings';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import {
  allowValueSelectionConditions,
  visibilityLogicTestOptions,
} from '@/modules/questions/constants';
import SelectConditionValue from '@/modules/shared/components/SelectConditionValue';
import toastService from '@/modules/toasts/service';

import { logicActions, specialVisibilityOptions } from '@/pages/createEvent/constants';

import { updateVisibilitySettings } from '../../../../duck/actions';
import {
  editingFormItemSel,
  editingFormPartSel,
  stepDataSel,
  updateVisibilitySettingsInProgressSel,
} from '../../../../duck/selectors';
import useGetVisibilitySettings from '../../../../hooks/useGetVisibilitySettings';

import { ButtonRow, LabelText, FormWrapper, MuiInputWrapper } from './VisibilityLogicModal.styled';

import InputMaterial from 'SHARED/components/InputMaterial';
import SelectInputMaterial from 'SHARED/components/SelectInputMaterial';
import { PresentationType } from 'SHARED/constants';

const { Option, OptGroup } = Select;

const VisibilityLogicModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    data: { formPartsData, optionSets },
  } = useSelector(stepDataSel);
  const visibilitySettingsParsed = useGetVisibilitySettings();

  const initialFormItem = useSelector(editingFormItemSel);
  const initialFormPart = useSelector(editingFormPartSel);

  const isUpdating = useSelector(updateVisibilitySettingsInProgressSel);

  const { formParts } = formPartsData;
  const itemName = initialFormItem?.formItemName || '';

  const [conditionFormItemCode, setConditionFormItemCode] = useState<string>('');
  const [conditionFormPartCode, setConditionFormPartCode] = useState<string>('');
  const [action, setAction] = useState<VisibilityLogicAction | undefined>();
  const [condition, setCondition] = useState('');
  const [conditionValue, setConditionValue] = useState('');

  const allowValueSelection = allowValueSelectionConditions.includes(condition);

  const hasAllFieldsEmpty = useMemo(() => {
    const isFieldToTestEmpty = !conditionFormItemCode || !conditionFormPartCode;
    const hasEmptyFields = !action && isFieldToTestEmpty && !condition;

    return allowValueSelection ? hasEmptyFields && !conditionValue : hasEmptyFields;
  }, [
    action,
    allowValueSelection,
    condition,
    conditionValue,
    conditionFormItemCode,
    conditionFormPartCode,
  ]);

  const hasAllFieldsFilled = useMemo(() => {
    const isFieldToTestFilled = !!conditionFormItemCode && !!conditionFormPartCode;
    const hasFieldsFilled = !!action && isFieldToTestFilled && !!condition;

    return allowValueSelection ? hasFieldsFilled && !!conditionValue : hasFieldsFilled;
  }, [
    action,
    allowValueSelection,
    condition,
    conditionValue,
    conditionFormItemCode,
    conditionFormPartCode,
  ]);

  const filteredFormParts = useMemo(
    () =>
      formParts.filter(({ datablockCode }) => {
        if (['form', 'parentdata'].includes(datablockCode)) return false;

        const isVisibilityForBlock: boolean = !!initialFormPart && !initialFormItem;

        return isVisibilityForBlock ? datablockCode !== initialFormPart?.datablockCode : true;
      }),
    [formParts, initialFormPart, initialFormItem],
  );

  const fieldToTestValue =
    conditionFormItemCode && conditionFormPartCode
      ? [conditionFormPartCode, conditionFormItemCode].join('-')
      : '';
  const isCheckboxPresentationType =
    filteredFormParts
      .find(fp => fp.formPartCode === conditionFormPartCode)
      ?.formItems.find(fi => fi.formItemCode === conditionFormItemCode)?.presentationType ===
    PresentationType.CHECKBOX;

  const selectedFormItem = useMemo(() => {
    if (conditionFormPartCode && conditionFormPartCode) {
      const formPart = formParts.find(({ formPartCode }) => formPartCode === conditionFormPartCode);
      return formPart?.formItems.find(({ formItemCode }) => formItemCode === conditionFormItemCode);
    }
  }, [conditionFormItemCode, conditionFormPartCode, formParts]);

  const optionSetCode = get(selectedFormItem, 'optionSetCode', '');
  const presentationType = get(selectedFormItem, 'presentationType', PresentationType.TEXT);

  const optionSetItems = useMemo(() => {
    if (!optionSetCode) return [];

    const optionSet = optionSets[optionSetCode];
    return optionSet?.optionItems || [];
  }, [optionSetCode, optionSets]);

  useEffect(() => {
    if (visibilitySettingsParsed) {
      const logic = get(visibilitySettingsParsed, 'visibilityLogic', {});

      setAction(get(logic, 'action'));
      setConditionFormItemCode(get(logic, 'formItemCode', ''));
      setConditionFormPartCode(get(logic, 'formPartCode', ''));
      setCondition(get(logic, 'testOption', ''));
      setConditionValue(get(logic, 'value', ''));
    }
  }, [visibilitySettingsParsed]);

  const visibilityOptions = useMemo<Array<{ key: string; text: string }>>(() => {
    const specialOptions = conditionFormItemCode && specialVisibilityOptions[conditionFormItemCode];
    if (specialOptions) return specialOptions;

    return visibilityLogicTestOptions.filter(opt =>
      opt.presentationType.includes(presentationType),
    );
  }, [presentationType, conditionFormItemCode]);

  const handleFormItemChange = (compoundItem: string) => {
    const [newFormPart, newFormItem] = compoundItem.split('-');
    setConditionFormItemCode(newFormItem);
    setConditionFormPartCode(newFormPart);
    setConditionValue('');
    setCondition('');
  };

  const handleTextConditionValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConditionValue(e.target.value);
  };

  const handleResetLogic = () => {
    setAction(undefined);
    setConditionFormItemCode('');
    setConditionFormPartCode('');
    setCondition('');
    setConditionValue('');
  };

  const handleConditionChange = (newCondition: string) => {
    setCondition(newCondition);
    setConditionValue('');
  };

  function handleSaveLogic() {
    if (!hasAllFieldsEmpty && !hasAllFieldsFilled) {
      toastService.error('Please fill all fields before saving');
      return;
    }

    dispatch(
      updateVisibilitySettings.request({
        ...visibilitySettingsParsed,
        visibilityLogic: hasAllFieldsEmpty
          ? undefined
          : action && {
              action,
              formItemCode: conditionFormItemCode,
              formPartCode: conditionFormPartCode,
              value: conditionValue,
              testOption: condition,
            },
        successMessage: `${itemName ? 'Field' : 'Section'} custom visibility logic ${
          hasAllFieldsEmpty ? 'cleared' : 'updated'
        }`,
      }),
    );
  }

  const buttons = [
    {
      title: 'Close',
      onClick: () => dispatch(closeModal()),
    },
    {
      title: 'Save',
      onClick: handleSaveLogic,
    },
  ];

  return (
    <ModalComponent
      title={`Custom visibility logic${itemName ? ' for ' + itemName : ''}`}
      buttons={buttons}
      inProgress={isUpdating}
    >
      <FormWrapper>
        <SelectInputMaterial labelContent="Select Action" value={action} onChange={setAction}>
          {Object.entries(logicActions).map(([key, value]) => (
            <Option key={key} value={value}>
              {value}
            </Option>
          ))}
        </SelectInputMaterial>
        <LabelText>When</LabelText>

        <SelectInputMaterial
          labelContent="Select field to test"
          popupMatchSelectWidth={false}
          onChange={handleFormItemChange}
          value={fieldToTestValue}
        >
          {filteredFormParts.map(({ formPartCode, formPartName, formItems }) => (
            <OptGroup key={formPartCode} label={formPartName}>
              {formItems
                .filter(fi => fi.formItemCode !== initialFormItem?.formItemCode)
                .map(({ formItemCode, formItemName }) => (
                  <Option key={formItemCode} value={`${formPartCode}-${formItemCode}`}>
                    {formItemName}
                  </Option>
                ))}
            </OptGroup>
          ))}
        </SelectInputMaterial>
        <LabelText>Has</LabelText>
        <SelectInputMaterial
          labelContent="Select Condition"
          popupMatchSelectWidth={false}
          onChange={handleConditionChange}
          value={condition}
        >
          {visibilityOptions.map(({ key, text }) => (
            <Option key={key} value={key}>
              {text}
            </Option>
          ))}
        </SelectInputMaterial>
        {allowValueSelection && !!optionSetItems.length && (
          <SelectConditionValue
            conditionValue={conditionValue}
            isCheckboxPresentationType={isCheckboxPresentationType}
            optionSetItems={optionSetItems}
            onChangeValue={setConditionValue}
          />
        )}
        {allowValueSelection && !optionSetItems.length && (
          <MuiInputWrapper>
            <InputMaterial
              labelContent="Select Value"
              value={conditionValue}
              onChange={handleTextConditionValue}
            />
          </MuiInputWrapper>
        )}
      </FormWrapper>
      <ButtonRow>
        <Button ghost size="small" onClick={handleResetLogic}>
          Clear Logic
        </Button>
      </ButtonRow>
    </ModalComponent>
  );
};

export default VisibilityLogicModal;
