import { MenuUnfoldOutlined } from '@ant-design/icons';
import React from 'react';
import { useSlate } from 'slate-react';

import { addIndentation } from '../../../../utils';
import ToolbarButton from '../ToolbarButton';

const AddIndentationButton: React.FC = () => {
  const editor = useSlate();

  const handleClick = () => {
    addIndentation(editor);
  };

  return <ToolbarButton Icon={MenuUnfoldOutlined} onClick={handleClick} />;
};

export default AddIndentationButton;
