import apiService from '@/modules/utils/apiService';

const createFormAttribute$ = (payload: {
  formCode: string;
  attributeCode: string;
  attributeName: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateAttribute',
    payload,
  );

const assignAttributesToMultipleAttendees$ = (payload: {
  formCode: string;
  isDelete: boolean;
  formRecordGUIDList: string[];
  attributes: { attributeCode: string }[];
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateAttributesForFormRecordList',
    payload,
  );

export default {
  createFormAttribute$,
  assignAttributesToMultipleAttendees$,
};
