import React, { Fragment, useMemo } from 'react';

import { Activity, Addon, PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { Session } from '@/modules/data/dataTypes/sessionList';
import Currency from '@/modules/shared/components/Currency';

import Activities from '../../../Activities';
import Addons from '../../../Addons';
import Row from '../../../Row';

import { RegistrantName, Panel } from './RegistrantSection.styled';

type Props = {
  registrant: PaymentSummary['records'][number];
  providedAddons: Omit<Addon, 'formRecordAddonGUID' | 'groupReservationAddonGUID'>[] | null;
  providedActivities:
    | Pick<
        Activity,
        'activityCode' | 'activityName' | 'fee' | 'isMandatory' | 'quantity' | 'totalFee'
      >[]
    | null;
  providedAddonsSubtotal: number | null;
  providedActivitiesSubtotal: number | null;
  session?: Pick<Session, 'sessionName'>;
};

const RegistrantSection: React.FC<Props> = ({
  registrant,
  session,
  providedActivities,
  providedAddons,
  providedActivitiesSubtotal,
  providedAddonsSubtotal,
  ...rest
}) => {
  const {
    formRecordGUID,
    firstName,
    lastName,
    totalFee,
    totalDeposit,
    formFee,
    addons,
    activities,
    selectedAddonFee,
    selectedActivitiesFee,
    isPrimaryRegistrant,
  } = registrant;

  const totalFeePerRegistrant = useMemo(
    () =>
      totalFee +
      (typeof providedActivitiesSubtotal === 'number' && isPrimaryRegistrant
        ? providedActivitiesSubtotal - selectedActivitiesFee
        : 0) +
      (typeof providedAddonsSubtotal === 'number' && isPrimaryRegistrant
        ? providedAddonsSubtotal - selectedAddonFee
        : 0),
    [
      providedActivitiesSubtotal,
      providedAddonsSubtotal,
      selectedActivitiesFee,
      selectedAddonFee,
      isPrimaryRegistrant,
      totalFee,
    ],
  );

  return (
    <Panel
      {...rest}
      key={formRecordGUID}
      header={
        <Fragment>
          <RegistrantName>{`${firstName} ${lastName}${
            isPrimaryRegistrant ? ' (Primary)' : ''
          }`}</RegistrantName>
          <b>
            <Currency value={totalFeePerRegistrant} />
          </b>
        </Fragment>
      }
    >
      <Row columns={['Installment', 'Quantity', 'Amount']} isHeader />
      <Row columns={[session?.sessionName]} isBlue />
      <Row columns={['Deposit', <Currency key="amount" value={totalDeposit} />]} />
      <Row columns={['Session Fee', <Currency key="amount" value={formFee} />]} />
      <Addons
        addons={isPrimaryRegistrant && providedAddons ? providedAddons : addons}
        subtotal={
          isPrimaryRegistrant && typeof providedAddonsSubtotal === 'number'
            ? providedAddonsSubtotal
            : selectedAddonFee
        }
      />
      <Activities
        activities={isPrimaryRegistrant && providedActivities ? providedActivities : activities}
        subtotal={
          isPrimaryRegistrant && typeof providedActivitiesSubtotal === 'number'
            ? providedActivitiesSubtotal
            : selectedActivitiesFee
        }
      />
    </Panel>
  );
};

export default RegistrantSection;
