import { Editable as SlateEditable } from 'slate-react';
import styled, { css } from 'styled-components';

const simpleTextStyles = css`
  & p {
    margin: 0;
  }
`;

const editableAreaStyles = css`
  border-radius: 4px;
  border: dashed 1px #969897;
  margin-top: 15px;
  padding: 15px;
  height: 100%;
  overflow: auto;
`;

export const Container = styled.div<{ $isSimpleText: boolean }>`
  width: 100%;
  max-height: 400px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ $isSimpleText }) => $isSimpleText && simpleTextStyles}
`;

export const Editable = styled(SlateEditable)<{ $isSimpleText: boolean }>`
  ${({ $isSimpleText }) => !$isSimpleText && editableAreaStyles}
`;
