import { createPageDataParams } from '@/modules/data/utils';

export const overviewGroupTabDataParams = createPageDataParams(
  { dataType: 'rosterDetails' },
  { dataType: 'rosterAttendees' },
  { dataType: 'groupReservationRosterList' },
  { dataType: 'groupReservationAddons' },
  { dataType: 'groupReservationActivities' },
  { dataType: 'formDisclaimers' },
);
