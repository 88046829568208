import { Card, Button, Row, Col } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';

import { navigateToGroupReservationList } from '@/pages/reservationList/duck/actions';

type Props = {
  formCode: string;
};

const PendingReservation: React.FC<Props> = ({ formCode }) => {
  const dispatch = useDispatch();

  const handleNavigateToList = () => {
    dispatch(redirect(navigateToGroupReservationList({ formCode })));
  };

  return (
    <Card className="mt-6 text-center">
      <h2 className="text-primary">Group Event</h2>
      <Row>
        <Col span={14} offset={5}>
          <p className="mt-3">
            You already have unfinished reservations, please complete them before registering a new
            one.
          </p>
          <Button className="mt-2" type="default" onClick={handleNavigateToList}>
            Reservations List
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default PendingReservation;
