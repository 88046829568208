import { Form } from 'antd';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { removeFilter } from '@/modules/data/duck/actions';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { queryFilterVariablesObjSel } from '../../../../duck/selectors';
import { FilterContentExpression, FilterContentSection } from '../../../../types';
import { decodeFilterContent, getReportDataId } from '../../../../utils';
import { FILTER_KEY_FILTER_CONTENT } from '../../constants';
import { updateFilterContent } from '../../duck/actions';
import { filterContentSel } from '../../duck/selectors';

import ExpressionList from './components/ExpressionList';
import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const UpdateFiltersModal: React.FC<Props> = ({ modalParams: { dataType } }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const dataId = getReportDataId(dataType);

  const filterContentStr = useSelector((state: RootState) => filterContentSel(state, { dataType }));
  const queryFilterVariablesObj = useSelector((state: RootState) =>
    queryFilterVariablesObjSel(state, { dataType }),
  );

  const initialValues: FilterContentSection = useMemo(() => {
    const emptyInitialValue = {
      expressionList: [{} as FilterContentExpression],
    };

    if (!filterContentStr) {
      return emptyInitialValue;
    }

    const decodedExpressionItems = decodeFilterContent(filterContentStr, queryFilterVariablesObj);

    if (!decodedExpressionItems || !decodedExpressionItems.length) {
      return emptyInitialValue;
    }

    return {
      expressionList: decodedExpressionItems,
    };
  }, [filterContentStr, queryFilterVariablesObj]);

  const handleFinish = (values: FilterContentSection) => {
    dispatch(updateFilterContent({ filterContent: values, dataType }));
    dispatch(closeModal());
  };

  function handleClear() {
    dispatch(removeFilter({ dataType, key: FILTER_KEY_FILTER_CONTENT, dataId }));
    dispatch(closeModal());
  }

  return (
    <ModalComponent
      title="Filter By"
      buttons={[
        { title: 'Clear Filters', type: 'default', onClick: handleClear },
        { title: 'Apply Filters', type: 'primary', onClick: form.submit },
      ]}
    >
      <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
        <ExpressionList form={form} dataType={dataType} />
      </Form>
    </ModalComponent>
  );
};

export default UpdateFiltersModal;
