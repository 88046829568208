import React, { Fragment } from 'react';

import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import InfoBubble from '@/modules/shared/components/InfoBubble';
import { usDate, dateFromString } from '@/modules/utils/dateFormats';

import { DAYS_BEFORE_RESERVATION } from '@/pages/createEvent/steps/PaymentSchedule/constants';

import { DueDateWrapper, EditIcon } from './DueDateColumn.styled';

type Props = {
  record: PaymentCategory;
  paymentsAllowed: boolean;
  onUpdatePaymentScheduleDueDate?: (paymentCategory: PaymentCategory) => void;
};

const DueDateColumn: React.FC<Props> = ({
  record,
  paymentsAllowed,
  onUpdatePaymentScheduleDueDate,
}) => {
  const { installmentType, isCustom, dueAmount, dayCount, dueDate } = record;
  const daysBefore =
    installmentType === DAYS_BEFORE_RESERVATION && `${dayCount} days before reservation starts`;

  if (!dueDate) return null;

  if (dateFromString(dueDate).isValid()) {
    return (
      <DueDateWrapper>
        {usDate(dueDate)}
        {onUpdatePaymentScheduleDueDate && paymentsAllowed && !!dueAmount && (
          <EditIcon
            size={18}
            onClick={() => {
              onUpdatePaymentScheduleDueDate(record);
            }}
          />
        )}
        {!!daysBefore && !isCustom && <InfoBubble>{daysBefore}</InfoBubble>}
      </DueDateWrapper>
    );
  }
  return <Fragment>{dueDate}</Fragment>;
};

export default DueDateColumn;
