import React, { Fragment, ReactElement, useMemo } from 'react';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { Addon, PaymentSummary, Activity } from '@/modules/data/dataTypes/paymentSummary';
import { Session } from '@/modules/data/dataTypes/sessionList';
import Currency from '@/modules/shared/components/Currency';
import Divider from '@/modules/shared/components/Divider';

import Activities from '../Activities';
import Addons from '../Addons';
import Row from '../Row';

type Props = {
  providedAddons: Omit<Addon, 'formRecordAddonGUID' | 'groupReservationAddonGUID'>[] | null;
  providedActivities:
    | Pick<
        Activity,
        'activityCode' | 'activityName' | 'fee' | 'isMandatory' | 'totalFee' | 'quantity'
      >[]
    | null;
  selectedSession?: Pick<
    Session,
    'sessionName' | 'isFeePerPerson' | 'baseFeePersonCount' | 'additionalPersonFee'
  >;
  paymentSummary: PaymentSummary;
  groupReservation: GroupReservation;
  children: (params: {
    providedActivitiesSubtotal: number | null;
    providedAddonsSubtotal: number | null;
    existingSelectedActivitiesFee: number;
    existingSelectedAddonsFee: number;
  }) => ReactElement;
};

const GroupReservationPaymentSummaryContent: React.FC<Props> = ({
  selectedSession,
  paymentSummary,
  groupReservation,
  providedActivities,
  providedAddons,
  children,
}) => {
  const {
    sessionFee,
    depositPerUnit,
    totalDeposit,
    records,
    amountPaid,
    selectedActivitiesFee,
    selectedAddonFee,
  } = paymentSummary;

  const { initialPeopleCount, initialRosterCount } = groupReservation;

  const providedActivitiesSubtotal = useMemo(() => {
    if (!providedActivities) return null;
    return providedActivities.reduce((acc, activity) => acc + activity.totalFee, 0);
  }, [providedActivities]);

  const providedAddonsSubtotal = useMemo(() => {
    if (!providedAddons) return null;
    return providedAddons.reduce(
      (acc, { selectedOptionFee, isAppliedToRoster }) =>
        acc +
        (isAppliedToRoster
          ? Number(selectedOptionFee) * Number(initialRosterCount)
          : Number(selectedOptionFee)),
      0,
    );
  }, [providedAddons, initialRosterCount]);

  if (!selectedSession) return null;

  const { sessionName, isFeePerPerson, baseFeePersonCount, additionalPersonFee } = selectedSession;

  const addonsSubtotal =
    typeof providedAddonsSubtotal === 'number' ? providedAddonsSubtotal : selectedAddonFee;
  const activitiesSubtotal =
    typeof providedActivitiesSubtotal === 'number'
      ? providedActivitiesSubtotal
      : selectedActivitiesFee;

  const addons = providedAddons || records[0].addons;
  const activities = providedActivities || records[0].activities;

  const remaining = Math.max(
    0,
    Number(initialPeopleCount) - Number(initialRosterCount) * Number(baseFeePersonCount),
  );

  return (
    <Fragment>
      <Row columns={['Installment', 'Quantity', 'Amount']} isHeader />
      <Row columns={[sessionName]} />
      {isFeePerPerson ? (
        <Fragment>
          <Row
            columns={[
              'Fee per person',
              initialPeopleCount,
              <Currency key="amount" value={sessionFee} />,
            ]}
            isIndented
          />
          <Row
            columns={[
              'Deposit per person',
              initialPeopleCount,
              <Currency key="amount" value={depositPerUnit} />,
            ]}
            isIndented
          />
          <Divider />
          <Row
            columns={[
              'Subtotal',
              <Currency
                key="amount"
                value={(Number(sessionFee) + Number(depositPerUnit)) * Number(initialPeopleCount)}
              />,
            ]}
            extraBottomPadding
          />
        </Fragment>
      ) : (
        <Fragment>
          <Row
            columns={[
              `Crew base fee (Up to ${baseFeePersonCount || ''} people)`,
              initialRosterCount,
              <Currency key="amount" value={sessionFee} />,
            ]}
            isIndented
          />
          <Row
            columns={[
              'Crew Deposit Fee',
              initialRosterCount,
              <Currency key="amount" value={depositPerUnit} />,
            ]}
            isIndented
          />
          <Divider />
          <Row
            columns={[
              'Subtotal',
              <Currency
                key="amount"
                value={Number(sessionFee) * Number(initialRosterCount) + Number(totalDeposit)}
              />,
            ]}
            extraBottomPadding
          />
          {remaining > 0 && (
            <Fragment>
              <Row
                columns={[
                  'Fee per extra person',
                  remaining,
                  <Currency key="amount" value={additionalPersonFee} />,
                ]}
              />
              <Divider />
              <Row
                columns={[
                  'Subtotal',
                  <Currency key="amount" value={Number(remaining) * Number(additionalPersonFee)} />,
                ]}
                extraBottomPadding
              />
            </Fragment>
          )}
        </Fragment>
      )}
      <Addons initialRosterCount={initialRosterCount} addons={addons} subtotal={addonsSubtotal} />
      <Activities activities={activities} subtotal={activitiesSubtotal} />
      <Row columns={['Paid', <Currency key="amount" value={amountPaid} />]} />
      {children({
        providedActivitiesSubtotal,
        providedAddonsSubtotal,
        existingSelectedActivitiesFee: selectedActivitiesFee || 0,
        existingSelectedAddonsFee: selectedAddonFee || 0,
      })}
    </Fragment>
  );
};

export default GroupReservationPaymentSummaryContent;
