import { combineEpics, Epic } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import {
  addDataItem,
  refreshData,
  removeDataItem,
  replaceDataItem,
} from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import { closeModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';

import { deleteProgram, updateProgram } from './actions';
import services from './services';

const ID_FIELD = 'programCode';

const updateProgram$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateProgram.request)),
    switchMap(({ payload: { accountName, ...program } }) => {
      const state = state$.value;
      const programs = createDataSel('programs')(state);
      const { formCode } = createDataSel('form')(state);

      return services.updateProgram$({ formCode, ...program }).pipe(
        switchMap(({ responseValue }) => {
          // Response value it's the program code
          const oldProgram = programs.find(item => item[ID_FIELD] === responseValue);

          return of(
            oldProgram
              ? replaceDataItem({
                  dataType: 'programs',
                  idField: ID_FIELD,
                  dataItem: {
                    ...oldProgram,
                    ...program,
                    accountName,
                  },
                })
              : addDataItem({
                  dataType: 'programs',
                  dataItem: {
                    ...program,
                    [ID_FIELD]: responseValue,
                    accountName,
                  },
                }),
            closeModal(),
            updateProgram.success(),
            refreshData({ dataType: 'ledgerAccounts' }),
          );
        }),
      );
    }),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return merge(caught, of(updateProgram.failure(error)));
    }),
  );

const deleteProgram$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteProgram.request)),
    switchMap(({ payload: programCode }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      const programs = createDataSel('programs')(state);

      const programHasSessions =
        +(programs.find(p => p.programCode === programCode)?.sessionCount || 0) > 0;

      if (programHasSessions) {
        throw new Error('Program have sessions assigned');
      }

      return services
        .deleteProgram$(formCode, programCode)
        .pipe(
          switchMap(() =>
            of(
              deleteProgram.success(),
              removeDataItem({ dataType: 'programs', idField: ID_FIELD, dataItemId: programCode }),
            ),
          ),
        );
    }),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return merge(of(deleteProgram.failure()), caught);
    }),
  );

export default combineEpics(updateProgram$, deleteProgram$);
