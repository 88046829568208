import { createAsyncAction } from 'typesafe-actions';

import { moduleName } from '../constants';

export const fetchMessages = createAsyncAction(
  `${moduleName}/FETCH_MESSAGES_REQUEST`,
  `${moduleName}/FETCH_MESSAGES_SUCCESS`,
  `${moduleName}/FETCH_MESSAGES_FAILURE`,
)<undefined, Record<string, string>, Error>();

export const getBsaLocales = createAsyncAction(
  `${moduleName}/GET_BSA_LOCALES_REQUEST`,
  `${moduleName}/GET_BSA_LOCALES_SUCCESS`,
  `${moduleName}/GET_BSA_LOCALES_FAILURE`,
)<undefined, unknown, Error>();
