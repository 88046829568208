/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-multi-comp */
import { flow, flatten } from 'lodash';
import { map as mapFP, uniqBy as uniqByFP } from 'lodash/fp';
import React, { useMemo } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { AttendeeType, Roster } from '@/modules/data/dataTypes/rosterList';
import { fetchData } from '@/modules/data/duck/actions';
import { createIsLoadingSel } from '@/modules/data/duck/selectors';
import { LEAD_ADVISOR_DESIGNATE_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import Desktop from '@/modules/responsiveSizes/Desktop';
import Mobile from '@/modules/responsiveSizes/Mobile';
import InvitationStatusCell from '@/modules/shared/components/InvitationStatusCell';
import List, { ListItem } from '@/modules/shared/components/List';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import RosterAttendeeTypeAmount from '@/modules/shared/components/RosterAttendeeTypeAmount';
import { isSystemAdministratorSel, memberIdSel } from '@/modules/user/duck/selectors';

import { rosterListSel } from '@/pages/reservation/tabs/OverviewGroup/duck/selectors';

import { dropSelectedAttendees } from '../../../../tabs/OverviewGroup/components/RosterPage/duck/actions';
import { ModalParams } from '../LeadAdvisorDesignateModal/types';

import styles from './RostersList.less';
import LeadAdvisor from './components/LeadAdvisor/LeadAdvisor';
import RostersTable from './components/RostersTable';

import Card from 'SHARED/components/Card';

type Props = ReturnType<typeof mapStateToProps> & {
  form: FormSettings;
  groupReservation: GroupReservation;
};

const RostersList: React.FC<Props> = ({
  form,
  groupReservation,
  rosterListIsLoading,
  rosterIsLoading,
  rosterAttendeesIsLoading,
}) => {
  const dispatch = useDispatch();
  const rosters = useSelector(rosterListSel);
  const selfMemberID = useSelector(memberIdSel);
  const isSystemAdministrator = useSelector(isSystemAdministratorSel);
  const { formCode, isCreator, isCollaborator } = form;
  const {
    isCancelled,
    isReservationContact,
    isLeadAdvisor,
    leadAdvisorAttendeeType,
    isAdditionalReservationContact,
    isRegistrantCurrentUser,
  } = groupReservation;

  const canAddLeadAdvisor = useMemo(() => {
    if (!leadAdvisorAttendeeType) return false;
    if (isCancelled) return false;
    if (isCreator || isCollaborator) return true;
    return true;
  }, [isCancelled, isCollaborator, isCreator, leadAdvisorAttendeeType]);

  const rostersAttendeeTypes = useMemo<AttendeeType[]>(
    () =>
      flow([mapFP(({ attendeeTypes }) => attendeeTypes), flatten, uniqByFP('typeCode')])(rosters),
    [rosters],
  );

  const handleAddLeadAdvisor = (roster: Roster) => {
    const modalParams: ModalParams = {
      roster,
      isRegistrantCurrentUser,
    };
    dispatch(openModal(LEAD_ADVISOR_DESIGNATE_MODAL, modalParams));
  };

  const handleOpenRoster = ({ rosterCode }: Roster) => {
    dispatch(dropSelectedAttendees());
    dispatch(fetchData.request({ dataType: 'rosterDetails', queryObj: { formCode, rosterCode } }));
    dispatch(
      fetchData.request({ dataType: 'rosterAttendees', queryObj: { formCode, rosterCode } }),
    );
  };

  const getCanOpenRoster = ({ leadAdvisorStatus }: Roster) => {
    if (
      isCreator ||
      isCollaborator ||
      isReservationContact ||
      isAdditionalReservationContact ||
      isSystemAdministrator
    )
      return true;
    return ['confirmed', 'accepted'].includes(leadAdvisorStatus.toLowerCase());
  };

  const filteredRosters = useMemo(() => {
    if (!rosters) return [];

    if (
      isReservationContact ||
      isAdditionalReservationContact ||
      isCreator ||
      isCollaborator ||
      isSystemAdministrator
    ) {
      return rosters;
    }
    if (isLeadAdvisor) {
      return rosters?.filter(({ leadAdvisorMemberID }) => leadAdvisorMemberID === selfMemberID);
    }

    return [];
  }, [
    rosters,
    isReservationContact,
    isAdditionalReservationContact,
    isLeadAdvisor,
    isCollaborator,
    isCreator,
    isSystemAdministrator,
    selfMemberID,
  ]);

  return (
    <Card className={styles.container}>
      <h1 className={styles.header}>Crews</h1>
      <LoadingContainer
        isLoading={rosterIsLoading || rosterListIsLoading || rosterAttendeesIsLoading}
      >
        <Desktop>
          <RostersTable
            rosters={filteredRosters}
            rostersAttendeeTypes={rostersAttendeeTypes}
            reservationDetails={groupReservation}
            canAddLeadAdvisor={canAddLeadAdvisor}
            getCanOpenRoster={getCanOpenRoster}
            onAddLeadAdvisor={handleAddLeadAdvisor}
            onOpenRoster={handleOpenRoster}
          />
        </Desktop>
        <Mobile>
          <List
            dataSource={filteredRosters}
            renderListItem={record => {
              const { rosterExpeditionNumber, leadAdvisorStatus, leadAdvisorInvitationSentDate } =
                record;
              return (
                <ListItem
                  title={rosterExpeditionNumber}
                  record={record}
                  rowKey="rosterCode"
                  headerAction={{ Icon: AiOutlineRight, onClick: handleOpenRoster }}
                  rows={[
                    {
                      title: 'Lead Advisor',
                      content: (
                        <LeadAdvisor
                          roster={record}
                          canAdd={canAddLeadAdvisor}
                          onAdd={handleAddLeadAdvisor}
                        />
                      ),
                    },
                    ...(leadAdvisorStatus
                      ? [
                          {
                            title: 'Invitation Status',
                            content: (
                              <InvitationStatusCell
                                statusName={leadAdvisorStatus}
                                invitationSentDate={leadAdvisorInvitationSentDate}
                              />
                            ),
                          },
                        ]
                      : []),
                    ...rostersAttendeeTypes.map(attendeeType => ({
                      title: attendeeType.typeName,
                      content: (
                        <RosterAttendeeTypeAmount
                          roster={record}
                          attendeeType={attendeeType}
                          reservationDetails={groupReservation}
                        />
                      ),
                    })),
                  ]}
                />
              );
            }}
          />
        </Mobile>
      </LoadingContainer>
    </Card>
  );
};

RostersList.defaultProps = {
  rosterIsLoading: false,
};

const mapStateToProps = (state: RootState) => ({
  rosterListIsLoading: createIsLoadingSel('groupReservationRosterList')(state),
  rosterIsLoading: createIsLoadingSel('rosterDetails')(state),
  rosterAttendeesIsLoading: createIsLoadingSel('rosterAttendees')(state),
});

export default connect(mapStateToProps)(RostersList);
