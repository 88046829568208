import React from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import SlidePanel from '@/modules/shared/components/SlidePanel';

import MyReservation from '../../components/MyReservation';
import { updateSelectedSessionInProgressSel } from '../../components/MyReservation/duck/selectors';
import { pageDataSel } from '../../duck/selectors';

import RosterPage from './components/RosterPage';
import { rosterSel } from './duck/selectors';

const GroupReservationOverview: React.FC = () => {
  const { data, isLoading: dataPageIsLoading } = useSelector(pageDataSel);
  const updateSessionInProgress = useSelector(updateSelectedSessionInProgressSel);

  const showRoster = !!useSelector(rosterSel);

  return (
    <LoadingContainer isLoading={updateSessionInProgress || dataPageIsLoading.groupReservation}>
      <SlidePanel collapsed={!showRoster} hideCloseButton content={<RosterPage />}>
        <MyReservation {...data} isGroup />
      </SlidePanel>
    </LoadingContainer>
  );
};

export default GroupReservationOverview;
