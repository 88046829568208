import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { moduleName as activitiesModuleName } from '../steps/Activities/constants';
import activitiesReducer from '../steps/Activities/duck/reducers';
import { moduleName as addonsModuleName } from '../steps/Addons/constants';
import addonsReducers from '../steps/Addons/duck/reducers';
import attendeeTypesReducers from '../steps/AttendeeTypes/duck/reducers';
import { moduleName as collaboratorsModuleName } from '../steps/Collaborators/constants';
import collaboratorsReducers from '../steps/Collaborators/duck/reducers';
import { moduleName as disclaimersModuleName } from '../steps/Disclaimers/constants';
import disclaimersReducers from '../steps/Disclaimers/duck/reducers';
import formBuilderReducers from '../steps/FormBuilder/duck/reducers';
import { moduleName as jobsModuleNme } from '../steps/JobsPositions/constants';
import jobsReducers from '../steps/JobsPositions/duck/reducers';
import { moduleName as ledgerAccountsModuleName } from '../steps/LedgerAccounts/constants';
import ledgerAccountsReducers from '../steps/LedgerAccounts/duck/reducers';
import { moduleName as multiSessionRulesModuleName } from '../steps/MultiSessionCapacityRules/constants';
import multiSessionRulesReducers from '../steps/MultiSessionCapacityRules/duck/reducers';
import installmentsReducers from '../steps/PaymentSchedule/duck/reducers';
import { moduleName as programsModuleName } from '../steps/Programs/constants';
import programsReducers from '../steps/Programs/duck/reducers';
import { moduleName as sessionsModuleName } from '../steps/Sessions/constants';
import sessionsReducers from '../steps/Sessions/duck/reducers';

import { createForm } from './actions';

const createFormInProgress = createReducer(false)
  .handleAction(createForm.request, () => true)
  .handleAction([createForm.success, createForm.failure], () => false);

export default combineReducers({
  [collaboratorsModuleName]: collaboratorsReducers,
  [jobsModuleNme]: jobsReducers,
  [programsModuleName]: programsReducers,
  [disclaimersModuleName]: disclaimersReducers,
  [activitiesModuleName]: activitiesReducer,
  [addonsModuleName]: addonsReducers,
  [ledgerAccountsModuleName]: ledgerAccountsReducers,
  [multiSessionRulesModuleName]: multiSessionRulesReducers,
  [sessionsModuleName]: sessionsReducers,
  formBuilder: formBuilderReducers,
  attendeeTypes: attendeeTypesReducers,
  paymentSchedule: installmentsReducers,

  createFormInProgress,
});
