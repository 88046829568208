import { createSelector, Selector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { EventItem } from '@/modules/data/dataTypes/eventList';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { GroupReservation as GroupReservationListItem } from '@/modules/data/dataTypes/groupReservationListReport';
import { FormJob } from '@/modules/data/dataTypes/jobList';
import { Person } from '@/modules/data/dataTypes/person';
import { JobDepartment } from '@/modules/data/dataTypes/personJobDepartmentList';
import { QueryFilter } from '@/modules/data/dataTypes/queryFilterList';
import { createDataSel } from '@/modules/data/duck/selectors';
import { PermissionAction, PersonRoles, PermissionCode } from '@/modules/entities/Roles/constants';
import { createIsQueryFilterAccessPermission } from '@/modules/entities/Roles/utils';
import { formStatus } from '@/modules/shared/constants';
import { currentUserData } from '@/modules/user/constants';

import { TabKey } from '@/pages/event/constants';
import { isTabAllowed } from '@/pages/event/utils';

import { isEventAdministratorSel, isSystemAdministratorSel } from './selectors';
import {
  getIsEAD,
  hasAssignedPermission,
  hasAssignedPermissionWithCode,
  hasAssignedRoleWithCode,
} from './utils';

// [target, action, options];
type ParamsCreateAbilities =
  | [
      'attendeeDetails',
      PermissionAction.Update | 'councilApprove',
      (
        | { attendeeDetails: AttendeeDetails; groupReservationDetails?: GroupReservation }
        | null
        | undefined
      ),
    ]
  | ['attendeeDetails', 'acceptJobPosition', AttendeeDetails]
  | [
      'attendeeDetails',
      PermissionAction.Read | 'updateAttendeeType' | 'editJobsUnrestricted',
      (
        | { attendeeDetails: AttendeeDetails }
        | {
            reservationContact:
              | GroupReservation['reservationContact']
              | GroupReservation['pendingContact'];
            groupReservation: GroupReservation;
          }
        | null
        | undefined
      ),
    ]
  | ['attendeeSelections', PermissionAction.Update, AttendeeDetails | null]
  | ['comment', PermissionAction.Create]
  | ['log', PermissionAction.Read]
  | [
      'reservation',
      PermissionAction.Read | 'cancel',
      { attendeeDetails: AttendeeDetails } | { groupReservation: GroupReservation },
    ]
  | ['event', PermissionAction.Read, EventItem]
  | ['event', PermissionAction.Update, EventItem | FormSettings]
  | [
      'groupReservation',
      PermissionAction.Read,
      Pick<
        GroupReservationListItem,
        | 'isLeadAdvisor'
        | 'isReservationContact'
        | 'isAdditionalReservationContact'
        | 'councilNumber'
      >,
    ]
  | ['groupReservation', PermissionAction.Update, GroupReservation]
  | ['groupReservation', 'updateReservationSize']
  | [
      'jobOffer',
      PermissionAction.Create,
      {
        attendeeDetails: AttendeeDetails;
        isStaff: boolean;
        isCancelled: boolean;
        isFreeAgent: boolean;
        jobPosition?: FormJob | null;
        userJobDepartments: JobDepartment[];
      },
    ]
  | ['jobOffer', PermissionAction.Delete]
  | [
      'payment',
      PermissionAction.Create,
      { attendeeDetails: AttendeeDetails } | { groupReservation: GroupReservation },
    ]
  | [
      'payment',
      PermissionAction.Read,
      { attendeeDetails: AttendeeDetails } | { groupReservation: GroupReservation } | null,
    ]
  | ['payment', PermissionAction.Update]
  | ['refund', PermissionAction.Create]
  | [
      'report',
      PermissionAction.Create | PermissionAction.Update | PermissionAction.Delete,
      QueryFilter | null | undefined,
    ]
  | ['summaryReport', 'export']
  | [TabKey, PermissionAction.Read];

export const createAbilitiesSelector = (
  ...params: ParamsCreateAbilities
): Selector<RootState, boolean> =>
  createSelector(
    [
      isSystemAdministratorSel,
      isEventAdministratorSel,
      createDataSel('arnicaPerson', currentUserData),
      createDataSel('form'),
    ],
    (isSystemAdministrator, isEventAdministrator, currentPerson?: Person, form?) => {
      if (isSystemAdministrator) return true;

      const [target, action, options] = params;

      const currentPersonIsEad = (
        councilNumber?: string | null,
        hostCouncilNumber?: string | null,
      ): boolean => {
        if (!form || !currentPerson) return false;

        return getIsEAD(form, currentPerson, councilNumber, hostCouncilNumber);
      };

      const hasPermission = (permissionCode: PermissionCode): boolean =>
        hasAssignedPermissionWithCode(
          [...(form?.assignedRoles || []), ...(currentPerson?.roles || [])],
          permissionCode,
        );

      switch (target) {
        case 'attendeeDetails':
          if (!form || !currentPerson) return false;
          switch (action) {
            case 'acceptJobPosition': {
              if (options && options.personGUID === currentPerson.personGUID) {
                return true;
              }

              return false;
            }
            case 'councilApprove': {
              const { isCreator, requireCouncilApproval } = form;

              if (!requireCouncilApproval) return false;

              if (
                isCreator ||
                isEventAdministrator ||
                hasPermission(PermissionCode.CouncilApproveAccess)
              )
                return true;

              if (options && 'attendeeDetails' in options) {
                const { attendeeDetails } = options;
                const { councilNumber, hostCouncil } = attendeeDetails;

                return currentPersonIsEad(councilNumber, hostCouncil);
              }

              return false;
            }
            case PermissionAction.Read: {
              const { isHiringManager, isCreator, isCollaborator } = form;

              if (isCreator || isCollaborator) return true;

              const { personGUID } = currentPerson;

              const viewDetailsPermissionGroup = [
                PermissionCode.AttendeeDetailsReadAccess,
                PermissionCode.AttendeeDetailsWriteAccess,
                PermissionCode.AttributesUpdateAccess,
                PermissionCode.SessionUpdateAccess,
                PermissionCode.PostPayments,
                PermissionCode.CouncilApproveAccess,
                PermissionCode.HiringAccess,
                PermissionCode.HiringCancelAccess,
                PermissionCode.AttendeeLogsAccess,
                PermissionCode.AttendeeCommentsAccess,
                PermissionCode.AttendeeEditJobPrioritiesAccess,
              ];

              const hasEADProps =
                options && 'attendeeDetails' in options
                  ? [options.attendeeDetails.councilNumber, options.attendeeDetails.hostCouncil]
                  : [options?.reservationContact.councilNumber ?? ''];

              const isEAD = currentPersonIsEad(...hasEADProps);

              if (isHiringManager || isEAD || viewDetailsPermissionGroup.some(hasPermission)) {
                return true;
              }

              if (!options) return false;

              if ('attendeeDetails' in options) {
                const { attendeeDetails } = options;
                const isPrimaryRegistrant =
                  personGUID === attendeeDetails.primaryRegistrantPersonGUID;
                const isCurrentUserSecondaryRegistrant =
                  attendeeDetails.isCurrentUserSecondaryRegistrant;
                const isCurrentUser = personGUID === attendeeDetails.personGUID;
                return isPrimaryRegistrant || isCurrentUser || isCurrentUserSecondaryRegistrant;
              }
              if ('reservationContact' in options) {
                const { reservationContact, groupReservation } = options;
                return (
                  reservationContact.personGUID === personGUID ||
                  groupReservation.isReservationContact ||
                  groupReservation.isAdditionalReservationContact
                );
              }
              return false;
            }
            case PermissionAction.Update: {
              const { isCreator } = form;
              const { personGUID } = currentPerson;

              if (
                isCreator ||
                isEventAdministrator ||
                hasPermission(PermissionCode.AttendeeDetailsWriteAccess)
              ) {
                return true;
              }

              if (!options) return false;

              const { attendeeDetails, groupReservationDetails } = options;

              const { isAdditionalReservationContact, isReservationContact } =
                groupReservationDetails ?? {};

              if (isAdditionalReservationContact || isReservationContact) return true;

              if (!attendeeDetails) return false;

              const { councilNumber, hostCouncil, primaryRegistrantPersonGUID } = attendeeDetails;
              const isPrimaryRegistrant = personGUID === primaryRegistrantPersonGUID;
              const isCurrentUser = attendeeDetails.personGUID === currentPerson.personGUID;

              return (
                isPrimaryRegistrant ||
                isCurrentUser ||
                currentPersonIsEad(councilNumber, hostCouncil)
              );
            }
            case 'updateAttendeeType': {
              const { isCreator } = form;
              if (isCreator || hasPermission(PermissionCode.AttendeeDetailsWriteAccess))
                return true;
              return false;
            }
            case 'editJobsUnrestricted': {
              if (!form) return false;

              const { isCreator } = form;

              return (
                isCreator ||
                hasPermission(PermissionCode.AttendeeEditJobPrioritiesAccess) ||
                isSystemAdministrator
              );
            }
          }
          return false;
        case 'attendeeSelections': {
          if (!form || !currentPerson) return false;

          const { allowAttendeesToEditActivities, isCreator } = form;
          const { personGUID: currentPersonGuid } = currentPerson;

          if (isCreator || hasPermission(PermissionCode.SessionUpdateAccess)) return true;

          if (!options) return false;

          const { primaryRegistrantPersonGUID, personGUID: attendeePersonGuid } = options;
          const isPrimaryRegistrant = currentPersonGuid === primaryRegistrantPersonGUID;
          const isCurrentUser = currentPersonGuid === attendeePersonGuid;

          return !!allowAttendeesToEditActivities && (isPrimaryRegistrant || isCurrentUser);
        }
        case 'comment':
          if (!form) return false;
          if (action === PermissionAction.Create) {
            const { isCreator } = form;
            return (
              isCreator ||
              isSystemAdministrator ||
              hasPermission(PermissionCode.AttendeeCommentsAccess)
            );
          }
          return false;
        case 'log':
          if (!form) return false;
          if (action === PermissionAction.Read) {
            const { isCreator } = form;
            return (
              isCreator || isSystemAdministrator || hasPermission(PermissionCode.AttendeeLogsAccess)
            );
          }
          return false;
        case 'reservation':
          if (!form) return false;
          switch (action) {
            case PermissionAction.Read: {
              const { isCreator, isCollaborator } = form;
              if ('groupReservation' in options) {
                const { groupReservation } = options;
                const { isReservationContact, isAdditionalReservationContact } = groupReservation;
                return (
                  isCreator ||
                  isCollaborator ||
                  isReservationContact ||
                  isAdditionalReservationContact
                );
              }
              return true;
            }
            case 'cancel': {
              const { isCreator, isCollaborator } = form;
              const { isCancelled } =
                'attendeeDetails' in options ? options.attendeeDetails : options.groupReservation;

              if (isCancelled) return false;
              if (isCreator || isSystemAdministrator) return true;
              return isCollaborator && hasPermission(PermissionCode.SessionUpdateAccess);
            }
          }
          break;
        case 'event':
          switch (action) {
            case PermissionAction.Read: {
              const { isCreator, isCollaborator, isHiringManager, requireCouncilApproval } =
                options;

              if (isCreator || isCollaborator || isHiringManager) return true;
              if (!currentPerson) return false;
              return (
                requireCouncilApproval &&
                hasAssignedRoleWithCode(currentPerson.roles, PersonRoles.EventApprovalDesignee)
              );
            }
            case PermissionAction.Update: {
              const { isCreator } = options;

              if (
                'roles' in options &&
                hasAssignedPermissionWithCode(options.roles, PermissionCode.EditEvent)
              ) {
                return true;
              }

              return isCreator || isSystemAdministrator || hasPermission(PermissionCode.EditEvent);
            }
          }
          break;
        case 'report': {
          if (!form || !currentPerson) return false;
          const { isCreator, assignedRoles } = form;
          const { personGUID, roles } = currentPerson;

          switch (action) {
            case PermissionAction.Create: {
              return isCreator || hasPermission(PermissionCode.QueryFilterCreateAccess);
            }
            case PermissionAction.Update: {
              if (!options) return false;
              const { queryFilterCode, isDefault } = options;
              if (isDefault) return false;
              if (isCreator || options.personGUID === personGUID) {
                return true;
              }

              const isQueryFilterUpdatePermission = createIsQueryFilterAccessPermission(
                queryFilterCode,
                PermissionAction.Update,
              );
              const hasUpdatePermission = hasAssignedPermission(
                [...assignedRoles, ...roles],
                isQueryFilterUpdatePermission,
              );
              return hasUpdatePermission;
            }
            case PermissionAction.Delete: {
              if (!options) return false;
              const { queryFilterCode, isDefault } = options;

              if (isDefault) return false;

              const isReportCreator = options.personGUID === personGUID;

              if (isCreator || isReportCreator) {
                return true;
              }

              const isQueryFilterUpdatePermission = createIsQueryFilterAccessPermission(
                queryFilterCode,
                PermissionAction.Update,
              );
              return hasAssignedPermission(
                [...assignedRoles, ...roles],
                isQueryFilterUpdatePermission,
              );
            }
          }
          return false;
        }
        case 'groupReservation': {
          if (!form || !form.allowGroupRegistration) return false;
          const { isCreator, isCollaborator } = form;

          if (isCreator || isEventAdministrator) {
            return true;
          }
          switch (action) {
            case PermissionAction.Read: {
              const {
                isLeadAdvisor,
                isReservationContact,
                isAdditionalReservationContact,
                councilNumber,
              } = options;
              return (
                isCollaborator ||
                isReservationContact ||
                isLeadAdvisor ||
                isAdditionalReservationContact ||
                currentPersonIsEad(councilNumber)
              );
            }
            case PermissionAction.Update: {
              const { isReservationContact } = options;
              return isReservationContact || hasPermission(PermissionCode.SessionUpdateAccess);
            }
            case 'updateReservationSize': {
              return hasPermission(PermissionCode.SessionUpdateAccess);
            }
          }
          return false;
        }
        case 'jobOffer': {
          if (!form) return false;
          const { allowGroupRegistration, isCreator, isHiringManager } = form;
          switch (action) {
            case PermissionAction.Delete: {
              return (
                isCreator ||
                isSystemAdministrator ||
                hasPermission(PermissionCode.HiringCancelAccess)
              );
            }
            case PermissionAction.Create: {
              const {
                isStaff,
                isFreeAgent,
                isCancelled,
                jobPosition,
                attendeeDetails,
                userJobDepartments,
              } = options;
              const { status } = attendeeDetails;

              if (!isStaff || isCancelled || allowGroupRegistration) {
                return false;
              }
              if (
                isCreator ||
                isSystemAdministrator ||
                hasPermission(PermissionCode.HiringAccess) ||
                [
                  formStatus.registration_council_approved.code,
                  formStatus.registration_completed.code,
                ].includes(status)
              ) {
                return true;
              }

              if (isHiringManager) {
                if (isFreeAgent) return true;

                return userJobDepartments.some(
                  ({ jobDepartmentCode }) => jobDepartmentCode === jobPosition?.jobDepartmentCode,
                );
              }

              return false;
            }
          }
        }
        case 'payment': {
          if (!form) return false;
          const { isCreator, allowGroupRegistration } = form;

          if (options && 'attendeeDetails' in options) {
            if (allowGroupRegistration || options.attendeeDetails.registrantType === 'Secondary') {
              return false;
            }
          }

          switch (action) {
            case PermissionAction.Create:
              if (
                isCreator ||
                isEventAdministrator ||
                isSystemAdministrator ||
                hasPermission(PermissionCode.PostPayments)
              )
                return true;

              if ('attendeeDetails' in options) {
                const { attendeeDetails } = options;
                const { isRegistrantCurrentUser, isCancelled } = attendeeDetails;

                if (isCancelled) return false;
                if (
                  isRegistrantCurrentUser ||
                  currentPersonIsEad(attendeeDetails.councilNumber, attendeeDetails.hostCouncil)
                )
                  return true;
              } else {
                const { groupReservation } = options;
                const {
                  reservationContact,
                  additionalReservationContact,
                  isReservationContact,
                  isAdditionalReservationContact,
                  isCancelled,
                } = groupReservation;

                if (isCancelled) return false;

                if (
                  isReservationContact ||
                  isAdditionalReservationContact ||
                  currentPersonIsEad(
                    reservationContact?.councilNumber ||
                      additionalReservationContact?.councilNumber,
                  )
                )
                  return true;
              }

              return false;
            case PermissionAction.Read:
              if (
                isCreator ||
                isEventAdministrator ||
                hasPermission(PermissionCode.TransactionsReadAccess)
              )
                return true;

              if (options && 'attendeeDetails' in options) {
                const { attendeeDetails } = options;
                const { isRegistrantCurrentUser, isCancelled } = attendeeDetails;

                if (isCancelled) return false;
                if (isRegistrantCurrentUser) return true;
              }

              if (options && 'groupReservation' in options) {
                return true;
              }

              return false;
            case PermissionAction.Update:
              return hasPermission(PermissionCode.PostPayments);
          }
          return false;
        }
        case 'refund': {
          if (!form) return false;

          const { isCreator } = form;

          if (isCreator || isEventAdministrator) return true;

          return hasPermission(PermissionCode.PostRefunds);
        }
        case 'summaryReport': {
          if (!form) return false;

          const { isCreator, isHiringManager } = form;

          if (action === 'export') {
            return (
              isCreator ||
              isSystemAdministrator ||
              isHiringManager ||
              currentPersonIsEad() ||
              hasPermission(PermissionCode.ExportAttendeeDataAccess)
            );
          }
          return false;
        }
        case TabKey.ACTIVITIES:
        case TabKey.ATTENDEES:
        case TabKey.EMAILS:
        case TabKey.OVERVIEW:
        case TabKey.RESERVATIONS:
        case TabKey.ROSTERS:
        case TabKey.SESSIONS:
        case TabKey.TRANSACTIONS:
          if (!currentPerson || !form) return false;
          return isTabAllowed(
            target,
            isSystemAdministrator,
            isEventAdministrator,
            currentPersonIsEad(),
            currentPerson,
            form,
          );
      }
    },
  );
