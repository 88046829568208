import styled, { css } from 'styled-components';

import StatusComponent from '@/modules/shared/components/StatusComponent';

const mobileStyles = css`
  padding: unset;
`;

export const ReservationStatusComponent = styled(StatusComponent)<{ $isMobile: boolean }>`
  ${({ $isMobile }) => $isMobile && mobileStyles}
`;
