import { get } from 'lodash';
import { combineEpics, Epic } from 'redux-observable';
import { concat, merge, of, race } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { updateFormRecord } from '@/modules/entities/FormRecord/duck/actions';
import reservationServices from '@/modules/entities/Reservations/duck/services';
import { urlFormCodeSel } from '@/modules/location/duck/selectors';
import { REGISTRATION_TIME_OUT_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';
import { personGuidSel } from '@/modules/user/duck/selectors';
import { ApiError } from '@/modules/utils/apiService';

import { stepKeys } from '@/pages/createReservation/constants';
import {
  setCurrentGroupReservationGUID,
  openNextStep,
  initRegistrationTimer,
  openStepByKey,
} from '@/pages/createReservation/duck/actions';
import {
  formRecordGuidSel,
  groupInitialSettingsSel,
} from '@/pages/createReservation/duck/selectors';

import {
  getGroupSessionFee,
  joinSessionWaitlist,
  updateGroupReservationSessions,
  updateIndividualReservationSessions,
  updateReservationSessions,
} from './actions';
import services from './services';

const updateGroupReservationSessions$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateGroupReservationSessions.request)),
    switchMap(({ payload: sessions }) => {
      const state = state$.value;
      const [sessionCode] = sessions;
      const formCode: string = urlFormCodeSel(state);
      const personGUID = personGuidSel(state) as string;
      const initialSettings = groupInitialSettingsSel(state);
      const groupReservation: GroupReservation | undefined =
        createDataSel('groupReservation')(state);
      const formRecordGUID = formRecordGuidSel(state) || undefined;

      return reservationServices
        .updateGroupReservation$({
          ...initialSettings,
          groupReservationGUID: get(groupReservation, 'groupReservationGUID') as string,
          formRecordGUID,
          sessionCode,
          formCode,
          personGUID,
        })
        .pipe(
          switchMap(({ responseValue }) =>
            concat(
              prefetchData$(action$, {
                dataType: 'groupReservation',
                queryObj: {
                  formCode,
                  groupReservationGUID: responseValue,
                },
                fetchData: true,
              }),
              of(
                setCurrentGroupReservationGUID(responseValue as string),
                updateGroupReservationSessions.success(),
              ),
            ),
          ),
          catchError((error: ApiError) => {
            if (error.responseCode === '-30') {
              return of(openModal(REGISTRATION_TIME_OUT_MODAL));
            }
            toastService.error(error.message);
            return of(updateGroupReservationSessions.failure(error));
          }),
        );
    }),
  );

const updateIndividualReservationSessions$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateIndividualReservationSessions.request)),
    switchMap(({ payload: sessions }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      const personGUID = personGuidSel(state);
      const formRecordGUID = formRecordGuidSel(state);

      return concat(
        merge(
          action$.pipe(
            filter(isActionOf(updateFormRecord.success)),
            take(1),
            takeUntil(action$.pipe(filter(isActionOf(updateFormRecord.failure)))),
          ),
          of(
            updateFormRecord.request({
              formCode,
              personGUID,
              formRecordGUID: formRecordGUID as string,
              sessionCode: sessions[0],
            }),
          ),
        ),
        of(updateIndividualReservationSessions.success()),
      );
    }),
    catchError((_error, caught) =>
      merge(caught, of(updateIndividualReservationSessions.failure())),
    ),
  );

const updateReservationSessions$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateReservationSessions)),
    switchMap(({ payload: sessions }) => {
      const state = state$.value;
      const { allowGroupRegistration } = createDataSel('form')(state);

      return concat(
        merge(
          race(
            action$.pipe(
              filter(
                isActionOf([
                  updateGroupReservationSessions.success,
                  updateIndividualReservationSessions.success,
                ]),
              ),
              take(1),
            ),
            action$.pipe(
              filter(
                isActionOf([
                  updateGroupReservationSessions.failure,
                  updateIndividualReservationSessions.failure,
                ]),
              ),
              take(1),
              switchMap(() => {
                throw new Error();
              }),
            ),
          ),
          of(
            allowGroupRegistration
              ? updateGroupReservationSessions.request(sessions)
              : updateIndividualReservationSessions.request(sessions),
          ),
        ),
        of(openNextStep(), initRegistrationTimer()),
      );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return caught;
    }),
  );

const joinSessionWaitlist$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(joinSessionWaitlist.request)),
    switchMap(({ payload }) => {
      const { formCode } = createDataSel('form')(state$.value);

      return services.updateSessionWaitingList$({ formCode, ...payload }).pipe(
        switchMap(() =>
          of(
            openStepByKey(stepKeys.waitlistConfirmation),
            joinSessionWaitlist.success(),
            closeModal(),
          ),
        ),
        catchError(error => {
          toastService.error(error.message);
          return of(joinSessionWaitlist.failure(error));
        }),
      );
    }),
  );

const getGroupTotalFeeRequest$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getGroupSessionFee.request)),
    switchMap(({ payload: { sessionCode, numberOfPeople, numberOfCrews } }) => {
      const { formCode } = createDataSel('form')(state$.value);

      return services
        .getGroupTotalFee$({
          formCode,
          sessionCode,
          numberOfPeople,
          numberOfCrews,
        })
        .pipe(
          switchMap(({ responseCode, responseMessage, responseValue, ...fees }) =>
            of(getGroupSessionFee.success({ sessionCode, ...fees })),
          ),
          catchError((error: ApiError) => {
            toastService.error('Could not get estimated cost');
            return of(getGroupSessionFee.failure(error));
          }),
        );
    }),
  );

export default combineEpics(
  joinSessionWaitlist$,
  getGroupTotalFeeRequest$,
  updateReservationSessions$,
  updateGroupReservationSessions$,
  updateIndividualReservationSessions$,
);
