import { combineReducers } from 'redux';
import { createReducer, getType, RootAction } from 'typesafe-actions';

import { logout } from '@/modules/user/duck/actions';

import routes from '../routes';

import { allowLeave, pageLoadCompleted, PAGE_LOAD_COMPLETED } from './actions';

const route = createReducer('').handleAction([pageLoadCompleted], (_state, { payload }) => payload);

const loading = (state: boolean | undefined, { type }: RootAction): boolean => {
  if (routes[type]) return true;
  if (type === PAGE_LOAD_COMPLETED) return false;
  return state || false;
};

const canLeave = (state: boolean | undefined, { type }: RootAction): boolean => {
  if (routes[type]) return false;
  if (type === getType(allowLeave)) return true;
  return state || false;
};

const initialPageLoadCompleted = createReducer(false)
  .handleAction(pageLoadCompleted, () => true)
  .handleAction(logout.request, () => false);

export default combineReducers({
  route,
  loading,
  canLeave,
  initialPageLoadCompleted,
});
