import {
  defaultSortSessionsListOption,
  SortSessionsListOptions,
  SortSessionsListParams,
} from './constants';

export const getSessionSortParams = (sessionsListSortType: string): SortSessionsListParams =>
  sessionsListSortType in SortSessionsListOptions
    ? SortSessionsListParams[sessionsListSortType as SortSessionsListOptions]
    : SortSessionsListParams[defaultSortSessionsListOption];
