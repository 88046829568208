import styled from 'styled-components';

export const ToolbarWrapper = styled.div`
  border-radius: 4px;
  overflow: none;
  width: fit-content;
  display: flex;
  flex-shrink: 0;
  width: 100%;
`;
