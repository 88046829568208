import { differenceBy, differenceWith, identity, isEqual, isNil, omitBy, pick } from 'lodash';
import { combineEpics, Epic } from 'redux-observable';
import { concat, forkJoin, merge, of, race, defer, Observable } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { RootAction, isActionOf, RootState } from 'typesafe-actions';

import { refreshData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import {
  ACTIVITY_FIELD_ACTIVITY_CODE,
  ACTIVITY_FIELD_ACTIVITY_SESSION_GUID,
  ACTIVITY_FIELD_IS_MANDATORY,
} from '@/modules/entities/Activities/constants';
import {
  ADDON_FIELD_ADDON_CODE,
  ADDON_FIELD_ADDON_SESSION_GUID,
  ADDON_FIELD_DEFAULT_ADDON_OPTION_CODE,
  ADDON_FIELD_IS_MANDATORY,
} from '@/modules/entities/Addons/constants';
import {
  ATTENDEE_TYPE_FIELD_DEPOSIT,
  ATTENDEE_TYPE_FIELD_EARLY_ARRIVAL_DAYS,
  ATTENDEE_TYPE_FIELD_EARLY_ARRIVAL_FEE,
  ATTENDEE_TYPE_FIELD_FEE,
  ATTENDEE_TYPE_FIELD_IS_ROSTER_LEAD,
  ATTENDEE_TYPE_FIELD_MAX_CAPACITY,
  ATTENDEE_TYPE_FIELD_MIN_CAPACITY,
  ATTENDEE_TYPE_FIELD_TYPE_CODE,
} from '@/modules/entities/AttendeeTypes/constants';
import { closeModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';
import { ApiError, ApiResponse } from '@/modules/utils/apiService';

import importCsvModalEpics$ from '../components/ImportCsvFileModal/duck/epics';
import { UPDATE_SESSION_ACCEPTED_FIELDS } from '../constants';
import { createToAddAndRemove } from '../utils';

import {
  cloneSession,
  deleteSession,
  updateSession,
  saveSessionAttendeeTypes,
  saveSessionActivities,
  saveSessionAddons,
  saveSessionMultiSessionRule,
  updateSessionClosed,
  updateSessionsInBulk,
} from './actions';
import { currentSessionSel } from './selectors';
import services from './services';

const deleteSessions$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteSession.request)),
    switchMap(({ payload }) => {
      const { formCode } = createDataSel('form')(state$.value);
      return services.deleteSession$({ formCode, sessionCode: payload }).pipe(
        mergeMap(() => of(deleteSession.success(), refreshData({ dataType: 'sessionList' }))),
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(deleteSession.failure(error));
        }),
      );
    }),
  );

const cloneSession$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(cloneSession.request)),
    switchMap(({ payload }) => {
      const { sessionCode, sessions } = payload;
      const { formCode } = createDataSel('form')(state$.value);

      return services.cloneSession$(formCode, sessionCode, sessions).pipe(
        switchMap(() => {
          toastService.success('Session was successfully cloned');
          return of(closeModal(), cloneSession.success(), refreshData({ dataType: 'sessionList' }));
        }),
        catchError((err: ApiError) => {
          toastService.error(err.message);
          return of(cloneSession.failure(err));
        }),
      );
    }),
  );

const updateSession$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateSession.request)),
    switchMap(({ payload }) => {
      const {
        session,
        activities = [],
        addons = [],
        attendeeTypes,
        multiSessionRuleCode = '',
        hasMultiSessionRule,
        previousRuleCode,
      } = payload;
      const state = state$.value;

      const { formCode } = createDataSel('form')(state);

      return services.updateCreateSession$({ formCode, sessions: [session] }).pipe(
        switchMap(({ responseValue }) => {
          const sessionCode = session.sessionCode || (responseValue as string);

          return concat(
            merge(
              race(
                action$.pipe(filter(isActionOf(saveSessionAttendeeTypes.success)), take(1)),
                action$.pipe(
                  filter(isActionOf(saveSessionAttendeeTypes.failure)),
                  take(1),
                  map(({ payload: { message } }) => {
                    throw new Error(message);
                  }),
                ),
              ),
              race(
                action$.pipe(filter(isActionOf(saveSessionActivities.success)), take(1)),
                action$.pipe(
                  filter(isActionOf(saveSessionActivities.failure)),
                  take(1),
                  map(({ payload: { message } }) => {
                    throw new Error(message);
                  }),
                ),
              ),
              race(
                action$.pipe(filter(isActionOf(saveSessionAddons.success)), take(1)),
                action$.pipe(
                  filter(isActionOf(saveSessionAddons.failure)),
                  take(1),
                  map(({ payload: { message } }) => {
                    throw new Error(message);
                  }),
                ),
              ),
              race(
                action$.pipe(filter(isActionOf(saveSessionMultiSessionRule.success)), take(1)),
                action$.pipe(
                  filter(isActionOf(saveSessionMultiSessionRule.failure)),
                  take(1),
                  map(({ payload: { message } }) => {
                    throw new Error(message);
                  }),
                ),
              ),
              of(
                saveSessionAttendeeTypes.request({ attendeeTypes, sessionCode }),
                saveSessionActivities.request({ sessionCode, activities }),
                saveSessionAddons.request({ sessionCode, addons }),
                saveSessionMultiSessionRule.request({
                  sessionCode,
                  multiSessionRuleCode,
                  hasMultiSessionRule,
                  previousRuleCode,
                }),
              ),
            ),
            defer(() => {
              toastService.info('Session was created / updated');
              return of(
                refreshData({ dataType: 'sessionList' }),
                refreshData({ dataType: 'programs' }),
                refreshData({ dataType: 'ledgerAccounts' }),
                updateSessionClosed(),
                updateSession.success(),
              );
            }),
          );
        }),
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(updateSession.failure(error));
        }),
      );
    }),
  );

const updateSessionsInBulk$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateSessionsInBulk.request)),
    switchMap(({ payload: { sessions, finalActions = [] } }) => {
      const state = state$.value;

      const { formCode } = createDataSel('form')(state);
      const currentSessions = createDataSel('sessionList')(state);
      const extraServiceRequests: Observable<ApiResponse>[] = [];

      const sessionAttendeeTypesPayload = sessions
        .map(session => {
          const currentAttendeeTypes =
            currentSessions.find(({ sessionCode }) => sessionCode === session.sessionCode)
              ?.attendeeTypes || [];

          const { toAdd, toRemove } = createToAddAndRemove(
            session.attendeeTypes,
            currentAttendeeTypes,
            ATTENDEE_TYPE_FIELD_TYPE_CODE,
            [
              ATTENDEE_TYPE_FIELD_IS_ROSTER_LEAD,
              ATTENDEE_TYPE_FIELD_MIN_CAPACITY,
              ATTENDEE_TYPE_FIELD_MAX_CAPACITY,
              ATTENDEE_TYPE_FIELD_DEPOSIT,
              ATTENDEE_TYPE_FIELD_FEE,
              ATTENDEE_TYPE_FIELD_EARLY_ARRIVAL_DAYS,
              ATTENDEE_TYPE_FIELD_EARLY_ARRIVAL_FEE,
            ],
          );

          const sessionCode = session.sessionCode;

          return [
            ...toAdd.map(attendeeType => ({
              ...attendeeType,
              isGranted: true,
              sessionCode,
            })),
            ...toRemove.map(attendeeType => ({
              ...attendeeType,
              isGranted: false,
              sessionCode,
            })),
          ];
        })
        .reduce((acc, val) => [...acc, ...val], []);

      if (sessionAttendeeTypesPayload.length) {
        extraServiceRequests.push(
          services.updateSessionAttendeeType$({
            attendeeTypes: sessionAttendeeTypesPayload,
            formCode,
          }),
        );
      }

      const sessionActivitiesPayload = sessions
        .map(session => {
          const currentActivities =
            currentSessions.find(({ sessionCode }) => sessionCode === session.sessionCode)
              ?.activities || [];

          const changedActivitiesWithSessionGUID = session.activities.map(activity => {
            const currentActivity = currentActivities.find(
              ({ activityCode }) => activityCode === activity.activityCode,
            );
            return { ...activity, activitySessionGUID: currentActivity?.activitySessionGUID || '' };
          });

          const { toAdd, toRemove } = createToAddAndRemove(
            changedActivitiesWithSessionGUID,
            currentActivities,
            ACTIVITY_FIELD_ACTIVITY_CODE,
            [ACTIVITY_FIELD_IS_MANDATORY],
          );

          const activitiesDiff = [
            ...toAdd.map(activity => ({ ...activity, isIncluded: true })),
            ...toRemove.map(activity => ({ ...activity, isIncluded: false })),
          ];

          return { sessionCode: session.sessionCode, activities: activitiesDiff };
        })
        .filter(session => session.activities.length > 0);

      if (sessionActivitiesPayload.length) {
        extraServiceRequests.push(
          services.updateActivitySession$({ formCode, sessions: sessionActivitiesPayload }),
        );
      }

      const sessionAddonsPayload = sessions
        .map(session => {
          const currentAddons =
            currentSessions.find(({ sessionCode }) => sessionCode === session.sessionCode)
              ?.addons || [];

          const changedAddonsWithSessionGUID = session.addons.map(addon => {
            const currentAddon = currentAddons.find(
              ({ addonCode }) => addonCode === addon.addonCode,
            );
            return { ...addon, addonSessionGUID: currentAddon?.addonSessionGUID || '' };
          });

          const { toAdd, toRemove } = createToAddAndRemove(
            changedAddonsWithSessionGUID,
            currentAddons,
            ADDON_FIELD_ADDON_CODE,
            [ADDON_FIELD_DEFAULT_ADDON_OPTION_CODE, ADDON_FIELD_IS_MANDATORY],
          );

          const addonsDiff = [
            ...toAdd.map(addon => ({ ...addon, isIncluded: true })),
            ...toRemove.map(addon => ({ ...addon, isIncluded: false, positionNumber: 0 })),
          ];

          return { sessionCode: session.sessionCode, addons: addonsDiff };
        })
        .filter(session => session.addons.length > 0);

      if (sessionAddonsPayload.length) {
        extraServiceRequests.push(
          services.updateAddonSession$({ formCode, sessions: sessionAddonsPayload }),
        );
      }

      const cleanSessions = sessions.map(session => {
        const onlyAcceptedFields = pick(session, UPDATE_SESSION_ACCEPTED_FIELDS);
        return omitBy(onlyAcceptedFields, isNil);
      });

      return services
        .updateCreateSession$({
          formCode,
          sessions: cleanSessions,
        })
        .pipe(
          switchMap(() =>
            forkJoin(extraServiceRequests).pipe(
              defaultIfEmpty([{ responseCode: '1', responseMessage: 'No extra requests needed' }]),
              switchMap(() => {
                toastService.success('Sessions were created / updated');
                return of(
                  refreshData({ dataType: 'sessionList' }),
                  updateSessionsInBulk.success(),
                  ...finalActions,
                );
              }),
            ),
          ),
          catchError((error: ApiError) => {
            toastService.error(error.message);
            return of(
              updateSessionsInBulk.failure(error),
              refreshData({ dataType: 'sessionList' }),
            );
          }),
        );
    }),
  );

const saveSessionAttendeeTypes$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(saveSessionAttendeeTypes.request)),
    switchMap(({ payload: { attendeeTypes: rawAttendeeTypes, sessionCode } }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);

      const attendeeTypes = rawAttendeeTypes.map(t => ({
        ...t,
        fee: t.fee || 0,
        sessionCode,
      }));

      return services
        .updateSessionAttendeeType$({
          attendeeTypes,
          formCode,
        })
        .pipe(
          map(() => saveSessionAttendeeTypes.success()),
          catchError((error: ApiError) => of(saveSessionAttendeeTypes.failure(error))),
        );
    }),
  );

const saveSessionAddons$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(saveSessionAddons.request)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { sessionCode, addons } = payload;
      const { formCode } = createDataSel('form')(state);

      const currentSession = currentSessionSel(state);
      const currentAddons = currentSession?.addons;
      const preparedAddons = addons.filter(identity);

      const addonsToAdd = differenceWith(
        preparedAddons,
        currentAddons || [],
        (nextAddon, prevAddon) => {
          const fields = [
            ADDON_FIELD_ADDON_SESSION_GUID,
            ADDON_FIELD_ADDON_CODE,
            ADDON_FIELD_DEFAULT_ADDON_OPTION_CODE,
            ADDON_FIELD_IS_MANDATORY,
          ];
          return isEqual(pick(nextAddon, fields), pick(prevAddon, fields));
        },
      );
      const addonsToRemove = differenceBy(
        currentAddons,
        preparedAddons,
        ADDON_FIELD_ADDON_SESSION_GUID,
      );

      const addonsToSave = [
        ...addonsToAdd.map(addon => ({ ...addon, isIncluded: true })),
        ...addonsToRemove.map(addon => ({ ...addon, isIncluded: false, positionNumber: 0 })),
      ];

      if (!addonsToSave.length) return of(saveSessionAddons.success());

      return services
        .updateAddonSession$({ formCode, sessions: [{ sessionCode, addons: addonsToSave }] })
        .pipe(
          map(() => saveSessionAddons.success()),
          catchError((error: ApiError) => of(saveSessionAddons.failure(error))),
        );
    }),
  );

const saveSessionActivities$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(saveSessionActivities.request)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { sessionCode, activities } = payload;
      const { formCode } = createDataSel('form')(state);
      const currentSession = currentSessionSel(state);
      const currentActivities = currentSession?.activities;

      const activitiesToAdd = differenceWith(
        activities,
        currentActivities || [],
        (nextActivity, prevActivity) => {
          const fields = [
            ACTIVITY_FIELD_ACTIVITY_SESSION_GUID,
            ACTIVITY_FIELD_ACTIVITY_CODE,
            ACTIVITY_FIELD_IS_MANDATORY,
          ];

          return isEqual(pick(nextActivity, fields), pick(prevActivity, fields));
        },
      );

      const activitiesToRemove = differenceBy(
        currentActivities,
        activities,
        ACTIVITY_FIELD_ACTIVITY_SESSION_GUID,
      );

      const activitiesToSave = [
        ...activitiesToAdd.map(addon => ({ ...addon, isIncluded: true })),
        ...activitiesToRemove.map(addon => ({ ...addon, isIncluded: false })),
      ];

      if (!activitiesToSave.length) return of(saveSessionActivities.success());

      return services
        .updateActivitySession$({
          formCode,
          sessions: [{ sessionCode, activities: activitiesToSave }],
        })
        .pipe(
          map(() => saveSessionActivities.success()),
          catchError((error: ApiError) => of(saveSessionActivities.failure(error))),
        );
    }),
  );

const saveSessionMultiSessionRule$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(saveSessionMultiSessionRule.request)),
    switchMap(({ payload }) => {
      const { sessionCode, multiSessionRuleCode, hasMultiSessionRule, previousRuleCode } = payload;

      const state = state$.value;
      const { formCode } = createDataSel('form')(state);

      if (!multiSessionRuleCode && !previousRuleCode)
        return of(saveSessionMultiSessionRule.success());

      const sessions = [];

      if (multiSessionRuleCode) {
        sessions.push({
          multiSessionRuleCode,
          isIncluded: !!hasMultiSessionRule,
        });
      }

      if (previousRuleCode && multiSessionRuleCode !== previousRuleCode) {
        sessions.push({ multiSessionRuleCode: previousRuleCode, isIncluded: false });
      }

      const data = {
        formCode,
        sessionCode,
        sessions,
      };

      return services.updateSessionMultiSessionRule$(data).pipe(
        switchMap(() => of(saveSessionMultiSessionRule.success())),
        catchError(err => of(saveSessionMultiSessionRule.failure(err.message))),
      );
    }),
  );

export default combineEpics(
  importCsvModalEpics$,
  deleteSessions$,
  cloneSession$,
  updateSession$,
  updateSessionsInBulk$,
  saveSessionAddons$,
  saveSessionActivities$,
  saveSessionAttendeeTypes$,
  saveSessionMultiSessionRule$,
);
