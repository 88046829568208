import { Button as BsaButton } from 'antd';
import styled from 'styled-components';

export const Button = styled(BsaButton)`
  box-shadow: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  margin-left: auto;

  && {
    color: unset;
  }
`;
