import { enableMapSet } from 'immer';

import { moduleName as broadcastModuleName } from '@/modules/broadcast/constants';
import broadcastReducers from '@/modules/broadcast/duck/reducers';
import { moduleName as coreModuleName } from '@/modules/core/constants';
import coreReducers from '@/modules/core/duck/reducers';
import { moduleName as dataModuleName } from '@/modules/data/constants';
import dataReducers from '@/modules/data/duck/reducers';
import { moduleName as activitiesModuleName } from '@/modules/entities/Activities/constants';
import activitiesReducer from '@/modules/entities/Activities/duck/reducers';
import { moduleName as addonsModuleName } from '@/modules/entities/Addons/constants';
import addonsReducers from '@/modules/entities/Addons/duck/reducers';
import { moduleName as attributesModuleName } from '@/modules/entities/Attributes/constants';
import attributesReducers from '@/modules/entities/Attributes/duck/reducers';
import { moduleName as emailsModuleName } from '@/modules/entities/Emails/constants';
import emailReducers from '@/modules/entities/Emails/duck/reducers';
import { moduleName as eventEntitiesModuleName } from '@/modules/entities/Events/constants';
import eventEntitiesReducers from '@/modules/entities/Events/duck/reducers';
import { moduleName as formRecordModuleName } from '@/modules/entities/FormRecord/constants';
import formRecordReducer from '@/modules/entities/FormRecord/duck/reducers';
import { moduleName as jobsModuleName } from '@/modules/entities/Jobs/constants';
import jobsReducer from '@/modules/entities/Jobs/duck/reducers';
import { moduleName as logsModuleName } from '@/modules/entities/Logs/constants';
import logsReducers from '@/modules/entities/Logs/duck/reducers';
import { moduleName as notificationsModuleName } from '@/modules/entities/Notifications/constants';
import notificationsReducers from '@/modules/entities/Notifications/duck/reducers';
import { moduleName as personEntitiesModuleName } from '@/modules/entities/Person/constants';
import personEntitiesReducers from '@/modules/entities/Person/duck/reducers';
import { moduleName as registrationsModuleName } from '@/modules/entities/Registrations/constants';
import registrationsReducers from '@/modules/entities/Registrations/duck/reducers';
import { moduleName as reservationsModuleName } from '@/modules/entities/Reservations/constants';
import reservationsReducers from '@/modules/entities/Reservations/duck/reducers';
import { moduleName as localizationModuleName } from '@/modules/localization/constants';
import localizationReducers from '@/modules/localization/duck/reducers';
import { moduleName as modalsModuleName } from '@/modules/modals/constants';
import modalsReducers from '@/modules/modals/duck/reducers';
import { moduleName as paymentsModuleName } from '@/modules/payments/constants';
import paymentsReducers from '@/modules/payments/duck/reducers';
import { moduleName as reportsModuleName } from '@/modules/reports/constants';
import reportsReducers from '@/modules/reports/duck/reducers';
import { moduleName as routingModuleName } from '@/modules/routing/constants';
import routingReducers from '@/modules/routing/duck/reducers';
import { moduleName as stepsModuleName } from '@/modules/steps/constants';
import stepsReducers from '@/modules/steps/duck/reducers';
import { moduleName as uploadQuestionsModalModuleName } from '@/modules/uploadQuestionsModal/constants';
import uploadQuestionsModalReducers from '@/modules/uploadQuestionsModal/duck/reducers';
import { moduleName as userModuleName } from '@/modules/user/constants';
import userReducers from '@/modules/user/duck/reducers';
import resetOnLogoutReducer from '@/modules/utils/resetOnLogoutReducer';

import { moduleName as addAttendeeModuleName } from '@/pages/addAttendee/constants';
import addAttendeeReducers from '@/pages/addAttendee/duck/reducers';
import { moduleName as createEventModuleName } from '@/pages/createEvent/constants';
import createEventPageReducers from '@/pages/createEvent/duck/reducers';
import { moduleName as formsModuleName } from '@/pages/createReservation/constants';
import formsReducers from '@/pages/createReservation/duck/reducers';
import { moduleName as eventPageModuleName } from '@/pages/event/constants';
import eventPageReducers from '@/pages/event/duck/reducers';
import { moduleName as eventListModuleName } from '@/pages/eventList/constants';
import eventListReducer from '@/pages/eventList/duck/reducers';
import { moduleName as parentConsentModuleName } from '@/pages/parentConsent/constants';
import parentConsentReducers from '@/pages/parentConsent/duck/reducers';
import { moduleName as reservationPageModuleName } from '@/pages/reservation/constants';
import reservationPageReducers from '@/pages/reservation/duck/reducers';

enableMapSet();

export default {
  [addonsModuleName]: resetOnLogoutReducer(addonsReducers),
  [broadcastModuleName]: broadcastReducers,
  [coreModuleName]: resetOnLogoutReducer(coreReducers),
  [addAttendeeModuleName]: resetOnLogoutReducer(addAttendeeReducers),
  [dataModuleName]: resetOnLogoutReducer(dataReducers),
  [emailsModuleName]: resetOnLogoutReducer(emailReducers),
  [jobsModuleName]: resetOnLogoutReducer(jobsReducer),
  [personEntitiesModuleName]: resetOnLogoutReducer(personEntitiesReducers),
  [eventEntitiesModuleName]: resetOnLogoutReducer(eventEntitiesReducers),
  [userModuleName]: resetOnLogoutReducer(userReducers),
  [createEventModuleName]: resetOnLogoutReducer(createEventPageReducers),
  [eventPageModuleName]: resetOnLogoutReducer(eventPageReducers),
  [eventListModuleName]: resetOnLogoutReducer(eventListReducer),
  [formsModuleName]: resetOnLogoutReducer(formsReducers),
  [localizationModuleName]: localizationReducers,
  [paymentsModuleName]: resetOnLogoutReducer(paymentsReducers),
  [reportsModuleName]: resetOnLogoutReducer(reportsReducers),
  [modalsModuleName]: resetOnLogoutReducer(modalsReducers),
  [parentConsentModuleName]: resetOnLogoutReducer(parentConsentReducers),
  [reservationPageModuleName]: resetOnLogoutReducer(reservationPageReducers),
  [routingModuleName]: routingReducers,
  [stepsModuleName]: resetOnLogoutReducer(stepsReducers),
  [uploadQuestionsModalModuleName]: resetOnLogoutReducer(uploadQuestionsModalReducers),
  [attributesModuleName]: resetOnLogoutReducer(attributesReducers),
  [registrationsModuleName]: resetOnLogoutReducer(registrationsReducers),
  [reservationsModuleName]: resetOnLogoutReducer(reservationsReducers),
  [logsModuleName]: resetOnLogoutReducer(logsReducers),
  [notificationsModuleName]: resetOnLogoutReducer(notificationsReducers),
  [formRecordModuleName]: resetOnLogoutReducer(formRecordReducer),
  [activitiesModuleName]: resetOnLogoutReducer(activitiesReducer),
};
