import { Button } from 'antd';
import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

import CardWithHeader from 'SHARED/components/CardWithHeader';

const titleFontSettings = css`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${colors.white};
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  ${titleFontSettings}
`;

export const CardWithWiderHeader = styled(CardWithHeader)`
  div[class^='CardWithHeader__header'] {
    height: 56px;
  }
`;

const flexWrap = css`
  flex-wrap: wrap;
`;

export const ButtonsRow = styled.div<{ justifyContent?: string; wrapButtons?: boolean }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  margin: 1rem 0;
  gap: 1rem;
  ${props => props.wrapButtons && flexWrap}
`;

export const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.lightBlue};
  border-color: ${colors.lightBlue};
  z-index: 1;
  margin-left: 15px;
`;

export const ActionButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  top: 18px;
  z-index: 1;
`;
