import apiService from '@/modules/utils/apiService';

import { UpdateFormJobPayload } from '../types';

const updateFormJob$ = (payload: UpdateFormJobPayload & { formCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateFormJob',
    payload,
  );

export default {
  updateFormJob$,
};
