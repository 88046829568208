import React from 'react';

import * as s from './CharsCounter.styled';

type Props = {
  text: string;
  maxLength?: number;
};

const CharsCounter: React.FC<Props> = ({ text, maxLength = 200 }) => (
  <s.CharsCounter>{`${text.length}/${maxLength}`}</s.CharsCounter>
);

export default CharsCounter;
