export const operatorsObj = {
  equalTo: { label: 'Equal to', value: 'EQ' },
  greaterOrEqualTo: { label: 'Greater or equal to', value: 'GE' },
  greaterThan: { label: 'Greater than', value: 'GT' },
  include: { label: 'Include', value: 'INCLUDE' },
  notinclude: { label: 'Does not include', value: 'NOTINCLUDE' },
  inlist: { label: 'Is One Of', value: 'INLIST' },
  notInList: { label: 'Is Not One Of', value: 'NOTINLIST' },
  lesserOrEqualTo: { label: 'Lesser or equal to', value: 'LE' },
  lesserThan: { label: 'Lesser than', value: 'LT' },
  notEqualTo: { label: 'Not equal to', value: 'NE' },
  isNotBlank: { label: 'Is Not Blank ', value: 'NOTNULL' },
  isBlank: { label: 'Is Blank', value: 'NULL' },
  startWith: { label: 'Start with', value: 'START' },
  before: { label: 'Before', value: 'LT' },
  beforeOrEquals: { label: 'Before or equals', value: 'LE' },
  after: { label: 'After', value: 'GT' },
  afterOrEquals: { label: 'After or equals', value: 'GE' },
  isSimilar: { label: 'Is similar', value: 'SIMILAR' },
  isNotSimilar: { label: 'Is not similar', value: 'NOTSIMILAR' },
} as const;

export const distanceBetweenInputs = 10;
