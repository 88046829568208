import React from 'react';
import { useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import Disclaimers from '@/modules/shared/components/Disclaimers';

import { tabDataSel } from './duck/selector';

type Props = {
  attendeeDetails: AttendeeDetails;
};
const DisclaimersTab: React.FC<Props> = props => {
  const {
    data: { formRecordDisclaimers },
  } = useSelector(tabDataSel);

  return <Disclaimers {...props} disclaimers={formRecordDisclaimers} />;
};

export default DisclaimersTab;
