import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createFiltersSel } from '@/modules/data/duck/selectors';
import { queryFiltersSel } from '@/modules/reports/duck/selectors';
import { ReportDataType } from '@/modules/reports/types';
import { getReportDataId } from '@/modules/reports/utils';

import {
  modulePath,
  FILTER_KEY_COLUMN_LIST,
  FILTER_KEY_FILTER_CONTENT,
  FILTER_KEY_GLOBAL_SEARCH_VALUE,
  FILTER_KEY_QUERY_FILTER_CODE,
  FILTER_KEY_SORT_ORDER,
} from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulePath);

type Props = {
  dataType: ReportDataType;
};

const dataTypeSel = (_state: RootState, { dataType }: Props) => dataType;

const updatedQueryFilterNameSel = createSelector(
  moduleSel,
  ({ updatedQueryFilterName }) => updatedQueryFilterName,
);

export const filtersSel = (
  state: RootState,
  props: Props,
): {
  [FILTER_KEY_QUERY_FILTER_CODE]?: string;
  [FILTER_KEY_FILTER_CONTENT]?: string;
  [FILTER_KEY_COLUMN_LIST]?: string;
  [FILTER_KEY_SORT_ORDER]?: string;
  [FILTER_KEY_GLOBAL_SEARCH_VALUE]?: string;
} => {
  const dataType = dataTypeSel(state, props);
  const dataId = getReportDataId(dataType);
  return createFiltersSel(dataType, dataId)(state) || {};
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createCurrentQueryFilterSel = (queryFilterCodeProvided?: string) =>
  createSelector([queryFiltersSel, filtersSel], (queryFilters, filters) => {
    if (!queryFilters || !queryFilters.length) return null;

    const queryFilterCode = queryFilterCodeProvided || filters[FILTER_KEY_QUERY_FILTER_CODE];

    if (queryFilterCode) {
      return queryFilters.find(qf => qf.queryFilterCode === queryFilterCode);
    }

    return queryFilters.find(({ isDefault }) => isDefault);
  });

export const queryFilterColumnMetadataSel = createSelector(
  createCurrentQueryFilterSel(),
  currentQueryFilter => {
    if (!currentQueryFilter) return [];

    return currentQueryFilter.columnMetadata;
  },
);

export const queryFilterNameSel = createSelector(
  [createCurrentQueryFilterSel(), updatedQueryFilterNameSel],
  (currentQueryFilter, updatedQueryFilterName) =>
    typeof updatedQueryFilterName === 'string'
      ? updatedQueryFilterName
      : currentQueryFilter?.queryFilterName,
);

export const updatedColumnMetadataSel = createSelector(
  moduleSel,
  ({ updatedColumnMetadata }) => updatedColumnMetadata,
);

export const columnMetadataSel = createSelector(
  [updatedColumnMetadataSel, queryFilterColumnMetadataSel],
  (updatedColumnMetadata, currentColumnMetadata) => updatedColumnMetadata || currentColumnMetadata,
);

export const filterContentSel = createSelector(
  [filtersSel, createCurrentQueryFilterSel()],
  (filters, currentQueryFilter) =>
    filters[FILTER_KEY_FILTER_CONTENT] || currentQueryFilter?.filterContent || '',
);

export const searchValueSel = createSelector(
  filtersSel,
  ({ globalSearchValue }) => globalSearchValue,
);

export const sortOrderSel = createSelector(
  [filtersSel, createCurrentQueryFilterSel()],
  (filters, queryFilter) => {
    if (FILTER_KEY_SORT_ORDER in filters) {
      const sortOrderFilter = filters[FILTER_KEY_SORT_ORDER];
      if (typeof sortOrderFilter === 'string') {
        return sortOrderFilter;
      }
    }
    return queryFilter?.sortOrder || '';
  },
);

export const sortOrderChangedSel = createSelector(
  [filtersSel, createCurrentQueryFilterSel()],
  (filters, queryFilter) =>
    FILTER_KEY_SORT_ORDER in filters && filters[FILTER_KEY_SORT_ORDER] !== queryFilter?.sortOrder,
);

export const saveReportInProgressSel = createSelector(
  moduleSel,
  ({ saveReportInProgress }) => saveReportInProgress,
);

export const selectedRowsSel = createSelector(moduleSel, ({ selectedRows }) => selectedRows);
