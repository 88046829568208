import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CDN_URL } from '@/modules/config/config';
import { refreshData } from '@/modules/data/duck/actions';
import { createDataSel, createIsLoadingSel } from '@/modules/data/duck/selectors';
import { jobStatus, JOB_PERIODS } from '@/modules/entities/Jobs/constants';
import { updateFormJob } from '@/modules/entities/Jobs/duck/actions';
import { updateFormJobInProgressSel } from '@/modules/entities/Jobs/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import { formattedDatesRange } from '@/modules/utils/dateFormats';

import { CenteredText, Subtitle } from './AttendeeJobAcceptModal.styled';
import { AttendeeJobAcceptModalParams } from './types';

const FLEUR_DE_LIS_IMG_URL = `${CDN_URL}FleurDeLis_90px.png`;

interface Props {
  modalParams: AttendeeJobAcceptModalParams;
}

const AttendeeJobAcceptModal: React.FC<Props> = ({ modalParams: { formCode, offeredJob } }) => {
  const dispatch = useDispatch();

  const { sessionName, sessionCode, formRecordGUID } = useSelector(
    createDataSel('attendeeDetails'),
  );
  const sessions = useSelector(createDataSel('sessionList'));
  const areFormJobsLoading = useSelector(createIsLoadingSel('formRecordJobs'));
  const isFormJobUpdateInProgress = useSelector(updateFormJobInProgressSel);

  const { jobName, jobDepartmentName, description, jobPeriodCode } = offeredJob;

  const sessionText = useMemo(() => {
    const currentSession = sessions.find(s => s.sessionCode === sessionCode);
    const sessionDateRange = currentSession
      ? formattedDatesRange(currentSession.startDate, currentSession.closeDate)
      : 'Unknown date';

    return `${sessionName} | ${sessionDateRange}`;
  }, [sessions, sessionCode, sessionName]);

  const jobPeriodText = useMemo(() => {
    const offeredJobPeriod = JOB_PERIODS.find(({ code }) => code === jobPeriodCode);
    return offeredJobPeriod ? `${offeredJobPeriod.name}` : 'Unknown period';
  }, [jobPeriodCode]);

  const createOfferDecisionHandler = (isAccepted: boolean) => () => {
    if (offeredJob) {
      dispatch(
        updateFormJob.request({
          formCode,
          jobsToUpdate: [
            {
              ...offeredJob,
              formRecordGUID,
              status: isAccepted
                ? jobStatus.job_attendee_accepted.code
                : jobStatus.job_attendee_declined.code,
            },
          ],
          successMessage: 'Thank you for your response. Your record has been updated accordingly.',
          finalActions: [refreshData({ dataType: 'attendeeDetails' }), closeModal()],
        }),
      );
    }
  };

  return (
    <ModalComponent
      title="Job Offer"
      inProgress={areFormJobsLoading || isFormJobUpdateInProgress}
      buttons={[
        {
          title: 'DECLINE OFFER',
          onClick: createOfferDecisionHandler(false),
        },
        {
          title: 'ACCEPT OFFER',
          onClick: createOfferDecisionHandler(true),
          type: 'primary',
        },
      ]}
      centerButtons
    >
      <CenteredText>
        <img src={FLEUR_DE_LIS_IMG_URL} alt="" />
        <Subtitle>Congratulations!</Subtitle>
        <p>
          You have been offered the "{jobName}" position and you can now be part of
          {` ${jobDepartmentName}`} staff team!
        </p>
        <p>
          <b>Description:</b> {description}
        </p>
        <p>
          <b>For Session: </b>
          {sessionText}
        </p>
        <p>
          <b>Job Period: </b>
          {jobPeriodText}
        </p>
      </CenteredText>
    </ModalComponent>
  );
};

export default AttendeeJobAcceptModal;
