import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import {
  APPROVE_APPLICANT_MODAL,
  CANCEL_INDIVIDUAL_RESERVATION_MODAL,
} from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import Banner from '@/modules/shared/components/Banner';

import { ModalParams as ApproveApplicantModalParams } from '../../components/ApproveApplicantModal/types';

import styles from './CouncilApproveBanner.less';

import { formStatus } from 'SHARED/constants';

type Props = {
  attendeeDetails: AttendeeDetails;
  isCancelled: boolean;
};

const CouncilApproveBanner: React.FC<Props> = ({ attendeeDetails, isCancelled }) => {
  const dispatch = useDispatch();

  const { formCode, formName, formRecordGUID, personGUID, firstName, lastName, status } =
    attendeeDetails;

  if (isCancelled || status !== formStatus.council_approval_pending.code) return null;

  const handleCancelReservation = () => {
    dispatch(
      openModal(CANCEL_INDIVIDUAL_RESERVATION_MODAL, {
        cancellationReasonStatus: formStatus.council_declined.code,
        ownReservation: false,
      }),
    );
  };

  const handleApprove = () => {
    const modalParams: ApproveApplicantModalParams = {
      formCode,
      formName,
      formRecordGUID,
      personGUID,
      firstName,
      lastName,
    };
    dispatch(openModal(APPROVE_APPLICANT_MODAL, modalParams));
  };

  return (
    <Banner
      type="info"
      message={
        <div className={styles.toastContent}>
          <span>
            You are reviewing <strong>{` ${firstName} ${lastName}'s `}</strong>application form.
          </span>

          <div>
            <Button type="link" size="small" onClick={handleCancelReservation}>
              DECLINE
            </Button>
            <Button type="link" size="small" onClick={handleApprove}>
              APPROVE APPLICANT
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default CouncilApproveBanner;
