import { DataTypes } from '@/modules/data/dataTypes';
import { arnicaServiceDefinitions } from '@/modules/data/duck/services';
import type { ArnicaServiceDefinition, QueryObj } from '@/modules/data/types';
import apiService, { arnicaApiPrefix } from '@/modules/utils/apiService';

const getDownloadStartService$ = (dataType: keyof DataTypes, queryObj: QueryObj) => {
  const {
    scriptCode,
    downloadScriptCode,
    scriptSetCode = 'EventFormMetadata',
  } = arnicaServiceDefinitions[dataType] as ArnicaServiceDefinition;

  const script = downloadScriptCode || scriptCode;

  return apiService.post$(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=${scriptSetCode}&ScriptCode=${script}`,
    {
      ...queryObj,
      reportType: 'csv',
    },
  );
};

const checkReportStatus$ = (contextToken: string) =>
  apiService.get$(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=CheckReportStatus&contextToken=${contextToken}`,
  );

// URL is opened on another tab directly, no observable process required.
const downloadReportFile$ = (contextToken: string): string =>
  `${arnicaApiPrefix}/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=DownloadReportFile&contextToken=${contextToken}`;

const deleteQueryFilter$ = (payload: { queryFilterCode: string; formCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteQueryFilter',
    payload,
  );

export default {
  getDownloadStartService$,
  checkReportStatus$,
  downloadReportFile$,
  deleteQueryFilter$,
};
