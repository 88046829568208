import { TypeOptions } from 'react-toastify';

import colors from '../styles/colors';

export const getColor = (type: TypeOptions) => {
  switch (type) {
    case 'error':
      return colors.redStatus;
    case 'info':
      return colors.lightBlueStatus;
    case 'success':
      return colors.greenStatus;
    case 'warning':
      return colors.yellowStatus;
  }
};
