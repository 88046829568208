import { Radio } from 'antd';
import type { RadioChangeEvent } from 'antd/lib/radio';
import React, { Fragment, useEffect } from 'react';

import { AddonOption } from '@/modules/data/dataTypes/addons';
import StatusComponent from '@/modules/shared/components/StatusComponent';

import { Title, Row } from './Addon.styled';
import Option from './components/Option';

type Props = {
  addonName: string;
  canSkipRequiredAddOns?: boolean;
  description?: string;
  addonOptions: AddonOption[];
  isMandatory: boolean;
  selectedOptionCode?: string;
  onChange: (optionCode: string) => void;
  isAppliedToRoster?: boolean;
};

const Addon: React.FC<Props> = ({
  addonName,
  canSkipRequiredAddOns,
  description,
  addonOptions,
  isMandatory,
  selectedOptionCode,
  onChange,
  isAppliedToRoster,
}) => {
  useEffect(() => {
    if (!selectedOptionCode) {
      const defaultOption = addonOptions.find(option => option.isDefaultOption);
      if (defaultOption) onChange(defaultOption.optionCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const longOptionExists = addonOptions.some(({ optionName }) => optionName.length > 50);

  const handleOptionChange = (e: RadioChangeEvent) => {
    onChange(e.target.value);
  };

  const displayNoneOption = !isMandatory || canSkipRequiredAddOns;

  return (
    <Fragment>
      <Row>
        <Title>{addonName}</Title>
        {isAppliedToRoster && (
          <StatusComponent status="info" statusLabel="PER CREW" infoStatuses={['info']} />
        )}
        {isMandatory && (
          <StatusComponent
            status="danger"
            statusLabel="REQUIRED"
            successStatuses={[]}
            dangerStatuses={['danger']}
            infoStatuses={[]}
          />
        )}
      </Row>
      <p>{description}</p>
      <Radio.Group onChange={handleOptionChange} value={selectedOptionCode}>
        {displayNoneOption && (
          <Option optionCode="" optionName="None" verticalAlignment={longOptionExists} />
        )}
        {addonOptions.map(({ optionName, optionCode, fee }) => (
          <Option
            key={optionCode}
            optionCode={optionCode}
            optionName={optionName}
            fee={fee}
            verticalAlignment={longOptionExists}
          />
        ))}
      </Radio.Group>
    </Fragment>
  );
};

export default Addon;
