import { InputNumber as AntInputNumber } from 'antd';
import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 300px;
  margin-left: auto;
  margin-right: auto;
`;

export const InputNumber = styled(AntInputNumber)`
  width: 100%;
` as typeof AntInputNumber;
