import React, { useEffect, useMemo, useRef } from 'react';
import { AiOutlineSwap } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { updatePersonInProgressSel } from '@/modules/entities/Person/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { TRANSFER_REGISTRATION_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import AttendeeProfileInformation from '@/modules/shared/components/AttendeeProfileInformation';
import EditableFormSection from '@/modules/shared/components/EditableFormSection';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { tabDataSel } from '@/pages/reservation/tabs/AttendeeDetails/duck/selector';

import { ModalParams as TransferRegistrationModalParams } from '../../../../components/TransferRegistrationModal/types';
import { useHandleEdit } from '../../hooks';

import { moduleName } from './constants';

type Props = {
  canEdit: boolean;
  onEdit: (sectionName: string | null) => void;
  editingSectionName: string | null;
  attendeeDetails: AttendeeDetails;
};

const ProfileInformation: React.FC<Props> = ({
  attendeeDetails,
  canEdit,
  onEdit,
  editingSectionName,
}) => {
  const dispatch = useDispatch();
  const {
    data: { arnicaPerson },
    isLoading: tabIsLoading,
  } = useSelector(tabDataSel);

  const attendeeProfileRef = useRef<{ handleSubmit: () => void }>(null);
  const person = arnicaPerson;
  const isUpdatingPerson = useSelector(updatePersonInProgressSel);
  const canTransferRegistration = useSelector(
    createAbilitiesSelector('attendeeDetails', PermissionAction.Update, null),
  );

  const { handleStartEdit, handleStopEdit, isEditing } = useHandleEdit(
    moduleName,
    editingSectionName,
    onEdit,
  );

  useEffect(() => {
    if (!isUpdatingPerson) {
      handleStopEdit();
    }
  }, [isUpdatingPerson, handleStopEdit]);

  const handleSubmit = () => {
    attendeeProfileRef.current?.handleSubmit();
  };

  const extraActions = useMemo(() => {
    const { isReservationContact, isAdditionalReservationContact } = attendeeDetails;

    function handleTransferRegistration() {
      const modalParams: TransferRegistrationModalParams = {
        attendeeDetails,
      };
      dispatch(openModal(TRANSFER_REGISTRATION_MODAL, modalParams));
    }

    const actions = [];

    if (canTransferRegistration && !isReservationContact && !isAdditionalReservationContact) {
      actions.push({
        key: 'transferRegistrations',
        icon: <AiOutlineSwap />,
        onClick: handleTransferRegistration,
      });
    }

    return actions;
  }, [canTransferRegistration, attendeeDetails, dispatch]);

  return (
    <EditableFormSection
      onEditClick={handleStartEdit}
      onCancel={handleStopEdit}
      canEdit={canEdit}
      title="Personal Information"
      isEditing={isEditing}
      onSave={handleSubmit}
      extraActions={extraActions}
      isLoading={tabIsLoading.arnicaPerson || isUpdatingPerson}
    >
      <AttendeeProfileInformation ref={attendeeProfileRef} isEditing={isEditing} person={person} />
    </EditableFormSection>
  );
};

export default ProfileInformation;
