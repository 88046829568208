import { Popover as BsaPopover, PopoverProps } from 'antd';
import cn from 'classnames';
import React from 'react';

import styles from './Popover.less';

type Props = {
  headerColor?: 'default' | 'scouting-red';
  noArrow?: boolean;
  noPadding: boolean;
  overlayClassName?: string;
} & PopoverProps;

const Popover: React.FC<Props> = ({
  headerColor = '',
  noArrow,
  noPadding,
  overlayClassName,
  ...rest
}) => {
  const overlayClassNames = cn(
    overlayClassName,
    [styles[headerColor]],
    { [styles.noArrow]: noArrow },
    { [styles.noPadding]: noPadding },
  );

  return <BsaPopover overlayClassName={overlayClassNames} {...rest} />;
};

Popover.defaultProps = {
  headerColor: 'default',
  noArrow: false,
  noPadding: false,
};

export default Popover;
