import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import DatePicker from '@/modules/shared/components/DatePicker';
import { dateToString, usDate } from '@/modules/utils/dateFormats';

import { updatePaymentScheduleDueDate } from '@/pages/reservation/duck/actions';
import { updatePaymentScheduleDueDateInProgressSel } from '@/pages/reservation/duck/selectors';

import { Form } from './UpdatePaymentScheduleDueDateModal.styled';
import { UpdatePaymentScheduleDueDateModalParams } from './types';

type Props = {
  modalParams: UpdatePaymentScheduleDueDateModalParams;
};

const DUE_DATE_FIELD_NAME = 'dueDate';

const UpdatePaymentScheduleDueDateModal: React.FC<Props> = ({ modalParams }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const updatePaymentScheduleDueDateInProgress = useSelector(
    updatePaymentScheduleDueDateInProgressSel,
  );
  const { dueDate, paymentCategoryCode } = modalParams.paymentCategory;

  function handleSubmit() {
    form.validateFields().then(values => {
      const nextDueDate: Dayjs = values[DUE_DATE_FIELD_NAME];
      dispatch(
        updatePaymentScheduleDueDate.request({
          dueDate: dateToString(nextDueDate),
          paymentCategoryCode,
          sessionCode: modalParams.reservation.sessionCode,
          ...(modalParams.isGroup
            ? { groupReservationGUID: modalParams.reservation.groupReservationGUID }
            : { formRecordGUID: modalParams.reservation.formRecordGUID }),
        }),
      );
    });
  }

  const disableDates = (date: Dayjs | null) => {
    if (!date) return false;
    return date.isBefore(dayjs(), 'days');
  };

  return (
    <ModalComponent
      title="New Payment Due Date"
      description={`Select the New Payment Due Date for Reservation ${
        modalParams.isGroup
          ? modalParams.reservation.groupReservationExpeditionNumber
          : modalParams.reservation.reservationCode
      }`}
      buttons={[{ title: 'Confirm Date', type: 'primary', onClick: handleSubmit }]}
      inProgress={updatePaymentScheduleDueDateInProgress}
    >
      <p>
        <b>Original Due Date: </b>
        {`${usDate(dueDate)}`}
      </p>
      <Form form={form}>
        <Form.Item
          name={DUE_DATE_FIELD_NAME}
          rules={[
            {
              required: true,
              message: 'Please select Next Due Date',
            },
          ]}
        >
          <DatePicker labelContent="New Due Date" disabledDate={disableDates} />
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default UpdatePaymentScheduleDueDateModal;
