import { operatorsObj } from '../../constants';

const {
  equalTo,
  notEqualTo,
  greaterOrEqualTo,
  greaterThan,
  include,
  notinclude,
  inlist,
  notInList,
  lesserOrEqualTo,
  lesserThan,
  isNotBlank,
  isBlank,
  startWith,
  before,
  beforeOrEquals,
  after,
  afterOrEquals,
  isNotSimilar,
  isSimilar,
} = operatorsObj;

export const DATES_OPERATORS = [
  equalTo,
  notEqualTo,
  before,
  after,
  beforeOrEquals,
  afterOrEquals,
  isBlank,
  isNotBlank,
];

export const TEXT_OPERATORS = [
  equalTo,
  notEqualTo,
  include,
  notinclude,
  startWith,
  inlist,
  notInList,
  isBlank,
  isNotBlank,
  isSimilar,
  isNotSimilar,
];

export const NUMBER_OPERATORS = [
  equalTo,
  notEqualTo,
  greaterThan,
  lesserThan,
  greaterOrEqualTo,
  lesserOrEqualTo,
  isBlank,
  isNotBlank,
];

export const BOOLEAN_OPERATORS = [equalTo];

export const FILE_OPERATORS = [isBlank, isNotBlank];
