import { createAction, createAsyncAction } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';
import { createModuleNamespaceName } from '@/modules/utils/misc';

import { modulePath } from '../constants';

const moduleNamespace = createModuleNamespaceName(modulePath);

export const findPersonAndInvite = createAction(`${moduleNamespace}/FIND_PERSON_AND_INVITE`)<{
  memberId: string;
  lastName: string;
  roleCodes: string[];
}>();

export const assignCollaboratorRole = createAsyncAction(
  `${moduleNamespace}/ASSIGN_COLLABORATOR_ROLE_REQUEST`,
  `${moduleNamespace}/ASSIGN_COLLABORATOR_ROLE_SUCCESS`,
  `${moduleNamespace}/ASSIGN_COLLABORATOR_ROLE_FAILURE`,
)<
  {
    roleCodes: string[];
    personGUID: string;
  },
  undefined,
  Error
>();

export const inviteCollaborator = createAction(`${moduleNamespace}/INVITE_COLLABORATOR`)<{
  personGUID: string;
  lastName: string;
}>();

export const removeCollaborator = createAsyncAction(
  `${moduleNamespace}/REMOVE_COLLABORATOR_REQUEST`,
  `${moduleNamespace}/REMOVE_COLLABORATOR_SUCCESS`,
  `${moduleNamespace}/REMOVE_COLLABORATOR_FAILURE`,
)<string, undefined, ApiError>();

export const showUpdateRoleForm = createAction(
  `${moduleNamespace}/SHOW_UPDATE_ROLE_FORM`,
)<string>();
export const hideUpdateRoleForm = createAction(`${moduleNamespace}/HIDE_UPDATE_ROLE_FORM`)();

export const updateRole = createAsyncAction(
  `${moduleNamespace}/UPDATE_ROLE_REQUEST`,
  `${moduleNamespace}/UPDATE_ROLE_SUCCESS`,
  `${moduleNamespace}/UPDATE_ROLE_FAILURE`,
)<
  {
    roleName: string;
    roleCode: string;
    description: string;
    permissions: Array<{ permissionCode: string; isIncluded: boolean }>;
  },
  undefined,
  Error
>();

export const deleteRole = createAsyncAction(
  `${moduleNamespace}/DELETE_ROLE_REQUEST`,
  `${moduleNamespace}/DELETE_ROLE_SUCCESS`,
  `${moduleNamespace}/DELETE_ROLE_FAILURE`,
)<{ roleCode: string }, undefined, ApiError>();
