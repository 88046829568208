import { Input } from 'antd';
import cn from 'classnames';
import React from 'react';

import styles from './TextAreaMaterial.less';

import L from 'SHARED/components/L';

type Props = {
  labelContent?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  wrapperClassName?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxLength?: number;
};

const TextAreaMaterial: React.FC<Props> = ({
  labelContent,
  placeholder,
  disabled,
  wrapperClassName,
  ...textAreaProps
}) => (
  <div className={cn(styles.textAreaWrapper, wrapperClassName)}>
    {labelContent && (
      <L size="1" colored inlineBlock>
        {labelContent}
      </L>
    )}
    <Input.TextArea
      {...textAreaProps}
      autoSize
      placeholder={placeholder}
      rows={4}
      cols={64}
      disabled={disabled}
    />
  </div>
);

TextAreaMaterial.defaultProps = {
  disabled: false,
};

export default TextAreaMaterial;
