import { Form } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Descendant } from 'slate';

import { Disclaimer } from '@/modules/data/dataTypes/disclaimers';
import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import RichTextEditor from '@/modules/richTextEditor';
import { fromHTML, isEmptyValue, toHTML } from '@/modules/richTextEditor/utils';
import { safeDecodeURIComponent } from '@/modules/utils/stringFunctions';

import { updateDisclaimer } from '../../duck/actions';
import { stepDataSel, updateDisclaimerInProgressSel } from '../../duck/selectors';

import { UpdateDisclaimerModalParams } from './types';

import InputMaterial from 'SHARED/components/InputMaterial';

const { useEffect } = React;

const CONTENT_FIELD: keyof Disclaimer = 'disclaimerContent';
const NAME_FIELD: keyof Disclaimer = 'disclaimerName';
interface Props {
  modalParams: UpdateDisclaimerModalParams;
}
const UpdateDisclaimerModal: React.FC<Props> = ({ modalParams }) => {
  const { disclaimer: selectedDisclaimer } = modalParams;
  const {
    data: { disclaimerList },
  } = useSelector(stepDataSel);
  const [form] = Form.useForm<{ [NAME_FIELD]: string; [CONTENT_FIELD]: Descendant[] }>();
  const dispatch = useDispatch();
  const { formCode } = useSelector(createDataSel('form'));

  const isLoading = useSelector(updateDisclaimerInProgressSel);

  useEffect(() => {
    form.resetFields();
  }, [selectedDisclaimer, form]);

  function handleModalClose() {
    dispatch(closeModal());
  }

  function handleSubmit() {
    form.validateFields().then(values => {
      const disclaimerInfo = {
        ...selectedDisclaimer,
        ...values,
        formCode,
        disclaimerContent: toHTML(values.disclaimerContent),
      };

      dispatch(updateDisclaimer.request(disclaimerInfo));
    });
  }

  return (
    <ModalComponent
      title={selectedDisclaimer?.disclaimerCode ? 'Update Disclaimer' : 'Create Disclaimer'}
      inProgress={isLoading}
      buttons={[
        {
          title: 'Cancel',
          onClick: handleModalClose,
        },
        {
          title: selectedDisclaimer?.disclaimerCode ? 'Update Disclaimer' : 'Add Disclaimer',
          type: 'primary',
          onClick: handleSubmit,
        },
      ]}
    >
      <Form form={form}>
        <Form.Item
          name={NAME_FIELD}
          initialValue={selectedDisclaimer?.disclaimerName}
          rules={[
            { required: true, message: 'Disclaimer Name is required' },
            {
              validator: (_rule, value) => {
                if (!value) return Promise.resolve();
                const disclaimerNameIsTaken = disclaimerList.some(d => {
                  if (d.disclaimerCode === selectedDisclaimer?.disclaimerCode) return false;
                  return d.disclaimerName.toLowerCase().trim() === value.toLocaleLowerCase().trim();
                });
                if (disclaimerNameIsTaken) return Promise.reject('Disclaimer name already in use.');
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputMaterial
            id="disclaimerName"
            type="text"
            name="disclaimerName"
            placeholder="Disclaimer Name"
          />
        </Form.Item>
        <Form.Item
          name={CONTENT_FIELD}
          initialValue={fromHTML(safeDecodeURIComponent(selectedDisclaimer?.disclaimerContent))}
          rules={[
            {
              validator: (_, value) => {
                if (isEmptyValue(value)) {
                  return Promise.reject('Content is required');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <RichTextEditor />
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default UpdateDisclaimerModal;
