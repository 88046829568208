import { Form, Select } from 'antd';
import { isEqual } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { updateProgram } from '../../duck/actions';
import { stepDataSel, updateProgramInProgressSel } from '../../duck/selectors';

import styles from './CreateEditProgramModal.less';
import { ModalParams } from './types';

import InputMaterial from 'SHARED/components/InputMaterial';
import SelectInputMaterial from 'SHARED/components/SelectInputMaterial';

type Props = {
  modalParams: ModalParams;
};

const PROGRAM_NAME_FIELD = 'programName';
const PROGRAM_PREFIX_FIELD = 'programPrefix';
const ACCOUNT_CODE_FIELD = 'accountCode';

const CreateEditProgramModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<{
    [PROGRAM_NAME_FIELD]: string;
    [PROGRAM_PREFIX_FIELD]: string;
    [ACCOUNT_CODE_FIELD]: string;
  }>();
  const {
    data: { ledgerAccounts },
  } = useSelector(stepDataSel);
  const inProgress = useSelector(updateProgramInProgressSel);
  const { program: { programName, programCode, programPrefix, accountCode } = {} } = modalParams;
  const title = `${programCode ? 'Edit' : 'Create'} Program`;

  function handleSubmit() {
    form.validateFields().then(nextProgram => {
      if (isEqual({ programName, programPrefix, accountCode }, nextProgram)) {
        dispatch(closeModal());
      } else {
        const ledgerAccount = ledgerAccounts.find(la => la.accountCode === nextProgram.accountCode);
        dispatch(
          updateProgram.request({
            ...nextProgram,
            accountName: ledgerAccount?.accountName as string,
            programCode,
          }),
        );
      }
    });
  }

  return (
    <ModalComponent
      title={title}
      description="Define the name of the program."
      inProgress={inProgress}
      buttons={[
        {
          title,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className={styles.formWrapper}>
        <Form form={form}>
          <Form.Item
            name={PROGRAM_NAME_FIELD}
            initialValue={programName}
            rules={[
              {
                required: true,
                message: 'Program Name should not be empty',
              },
              {
                min: 3,
                message: "Name can't have less than 3 letters",
              },
              {
                max: 50,
                message: "Name can't have more than 50 letters",
              },
            ]}
          >
            <InputMaterial labelContent="Program Name" />
          </Form.Item>
          <Form.Item
            name={PROGRAM_PREFIX_FIELD}
            initialValue={programPrefix}
            rules={[
              {
                required: true,
                message: 'Program Code should not be empty',
              },
              {
                max: 3,
                message: 'Code can not have more than 3 letters',
              },
              {
                min: 2,
                message: 'Code can not have less than 2 letters',
              },
              {
                pattern: /^[a-zA-Z]+$/,
                message: 'Code should contain only letters',
              },
            ]}
          >
            <InputMaterial labelContent="Program Code" />
          </Form.Item>
          <Form.Item name={ACCOUNT_CODE_FIELD} initialValue={accountCode}>
            <SelectInputMaterial labelContent="Ledger Account">
              {ledgerAccounts.map(la => (
                <Select.Option key={la.accountCode}>{la.accountName}</Select.Option>
              ))}
            </SelectInputMaterial>
          </Form.Item>
        </Form>
      </div>
    </ModalComponent>
  );
};

export default CreateEditProgramModal;
