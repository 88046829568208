import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { updateEvent } from '@/modules/entities/Events/duck/actions';

const updatingEvent = createReducer<string | null>(null)
  .handleAction(updateEvent.request, (_state, { payload }) => payload.updatedFormData.formCode)
  .handleAction([updateEvent.success, updateEvent.failure], () => null);

export default combineReducers({
  updatingEvent,
});
