import React from 'react';
import { useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';

import MyReservation from '../../components/MyReservation';
import { updateSelectedSessionInProgressSel } from '../../components/MyReservation/duck/selectors';
import { pageDataSel } from '../../duck/selectors';

const IndividualReservationOverview: React.FC = () => {
  const { data, isLoading: dataPageIsLoading } = useSelector(pageDataSel);
  const updateSessionInProgress = useSelector(updateSelectedSessionInProgressSel);

  const primaryAttendeeDetails = useSelector(createDataSel('attendeeDetails', 'primaryAttendee'));

  return (
    <LoadingContainer isLoading={updateSessionInProgress || dataPageIsLoading.attendeeDetails}>
      <MyReservation {...data} isGroup={false} attendeeDetails={primaryAttendeeDetails} />
    </LoadingContainer>
  );
};

export default IndividualReservationOverview;
