import dayjs from 'dayjs';
import Loadable from 'react-loadable';
import { Epic, StateObservable } from 'redux-observable';
import { of, merge, concat, EMPTY, Observable, defer } from 'rxjs';
import { RootAction, RootState } from 'typesafe-actions';
// eslint-disable-next-line import/no-named-as-default

import { resetPagination, resetFilters, setFilter, removeData } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { prevTypeSel, urlFormCodeSel } from '@/modules/location/duck/selectors';
import { prefetchReports$ } from '@/modules/reports/duck/epics';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { utcDateToString } from '@/modules/utils/dateFormats';

import {
  ROUTE_EVENT_PAGE,
  dashboardTabs,
  routeEventPagePathPrefix,
  ROUTE_EVENT_PAGE_SESSION_LIST,
  TabKey,
  pageDataParams,
} from './constants';
import { showQuickSearchTabSel } from './duck/selectors';
import { DEFAULT_ROSTER_LIST_FILTERS } from './tabs/Rosters/constants';

const createGetObservable =
  (tabKey: string): Epic<RootAction, RootAction, RootState> =>
  (action$, state$) => {
    const state = state$.value;
    const formCode = urlFormCodeSel(state);
    const prevRoute = prevTypeSel(state);
    const activeTemplate = createDataSel('emailTemplate')(state);

    const extraActions: RootAction[] = [];
    const isInitialLoad = !prevRoute || !prevRoute.includes(ROUTE_EVENT_PAGE);

    if (activeTemplate) {
      extraActions.push(removeData({ dataType: 'emailTemplate' }));
    }

    return concat(
      isInitialLoad
        ? of(
            resetPagination({ dataType: 'sessionList', fetchData: false }),
            resetPagination({ dataType: 'rosterList', fetchData: false }),
            resetFilters({ dataType: 'sessionList', fetchData: false }),
            resetFilters({ dataType: 'rosterList', fetchData: false }),
            ...extraActions,
          )
        : EMPTY,
      prefetchData$(
        action$,
        ...pageDataParams.map(params => {
          switch (params.dataType) {
            case 'form':
              return { ...params, queryObj: { formCode } };
          }
        }),
      ),
      defer(() => {
        switch (tabKey) {
          case TabKey.ATTENDEES: {
            const showQuickSearchTab = showQuickSearchTabSel(state$.value);

            if (showQuickSearchTab) return EMPTY;

            return prefetchReports$(action$, {
              dataType: 'attendeeListReport',
              formCode,
            });
          }
          case TabKey.RESERVATIONS: {
            const showQuickSearchTab = showQuickSearchTabSel(state$.value);

            if (showQuickSearchTab) return EMPTY;

            return prefetchReports$(action$, {
              dataType: 'reservationListReport',
              formCode,
            });
          }
          case TabKey.TRANSACTIONS:
            return prefetchReports$(action$, {
              dataType: 'formPaymentData',
              formCode,
            });
          case TabKey.SESSIONS: {
            return prefetchData$(
              action$,
              { dataType: 'sessionList', queryObj: { formCode } },
              { dataType: 'attendeeTypeList', queryObj: { formCode } },
              { dataType: 'programs', queryObj: { formCode } },
              { dataType: 'eventStatistics', queryObj: { formCode } },
              { dataType: 'multiSessionCapacityRuleList', queryObj: { formCode } },
              { dataType: 'disclaimerList', queryObj: { isDeleted: false } },
            );
          }
          case TabKey.ACTIVITIES: {
            return prefetchData$(action$, { dataType: 'activities', queryObj: { formCode } });
          }
          case TabKey.ROSTERS:
            return merge(
              ...Object.entries(DEFAULT_ROSTER_LIST_FILTERS).map(([key, value]) =>
                of(setFilter({ dataType: 'rosterList', key, value })),
              ),
              prefetchData$(
                action$,
                { dataType: 'rosterList', queryObj: { formCode } },
                { dataType: 'programs', queryObj: { formCode } },
              ),
            );
          case TabKey.EMAILS:
            return prefetchData$(
              action$,
              {
                dataType: 'mailingList',
                queryObj: {
                  formCode,
                  startDate: utcDateToString(dayjs()),
                },
              },
              {
                dataType: 'emailTemplateList',
                queryObj: {
                  isLocal: true,
                  formCode,
                },
              },
            );
          default:
            return EMPTY;
        }
      }),
    );
  };

const tabRoutes = dashboardTabs.reduce(
  (acc, { key }) => ({
    ...acc,
    [`${ROUTE_EVENT_PAGE}/${key}`]: {
      path: `${routeEventPagePathPrefix}/${key}`,
      component: Loadable({
        loader: () => import('./EventPage'),
        loading: LoadingContainer,
      }),
      titleId: 'page.event',
      getObservable: createGetObservable(key),
    },
  }),
  {},
);

const routes = {
  ...tabRoutes,
  [ROUTE_EVENT_PAGE_SESSION_LIST]: {
    path: '/events/:formCode/session-list',
    titleId: 'page.sessions',
    unAuthenticatedAccess: true,
    hideBreadcrumbs: true,
    component: Loadable({
      loader: () => import('./components/SessionListPage'),
      loading: LoadingContainer,
    }),
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const state = state$.value;
      const formCode = urlFormCodeSel(state);

      return concat(
        merge(
          of(
            resetPagination({ dataType: 'sessionList', fetchData: false }),
            resetFilters({ dataType: 'sessionList', fetchData: false }),
          ),
          prefetchData$(action$, { dataType: 'form', queryObj: { formCode }, fetchData: true }),
        ),
        defer(() => {
          const nextState = state$.value;
          const { hidePastSessions } = createDataSel('form')(nextState);
          const canSeeAllSessions = createAbilitiesSelector(
            'attendeeSelections',
            PermissionAction.Update,
            null,
          )(nextState);

          return prefetchData$(
            action$,
            {
              dataType: 'sessionList',
              queryObj: {
                formCode,
                includeAllFields: false,
                isIncludeAttendeeTypes: false,
                isIncludeAddons: false,
                isIncludeActivities: false,
                hideFullSessions: true,
                hidePastSessions: canSeeAllSessions ? false : hidePastSessions,
                ...(canSeeAllSessions ? {} : { includeHiddenSessions: false }),
              },
              fetchData: true,
            },
            { dataType: 'programs', queryObj: { formCode }, fetchData: true },
            {
              dataType: 'multiSessionCapacityRuleList',
              queryObj: { formCode },
              fetchData: true,
            },
          );
        }),
      );
    },
  },
};

export default routes;
