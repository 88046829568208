import get from 'lodash/get';
import React, { PropsWithChildren } from 'react';

import styles from './TBody.less';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  GroupHeaderComponent: React.FC<any>;
  colSpan: number;
  hasRowSelection: boolean;
};

const TBody: React.FC<PropsWithChildren<Props>> = ({
  children,
  GroupHeaderComponent,
  colSpan,
  hasRowSelection,
  ...rest
}) => (
  <tbody {...rest}>
    {React.Children.toArray(children).map(row => {
      const isGroupHeader = get(row, ['props', 'record', 'isGroupHeader']);
      if (isGroupHeader) {
        const record = get(row, ['props', 'record']);
        return (
          // eslint-disable-next-line react/jsx-key
          <tr className={styles.groupHeaderRow}>
            <td colSpan={hasRowSelection ? colSpan + 1 : colSpan}>
              <GroupHeaderComponent {...record} />
            </td>
          </tr>
        );
      }

      return row;
    })}
  </tbody>
);

export default TBody;
