import { Row, Col, Checkbox, Form, DatePicker, Input, InputNumber } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { isNumber } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import InfoBubble from '@/modules/shared/components/InfoBubble';
import { dateToString, dateFromString } from '@/modules/utils/dateFormats';

import { PaymentMethod } from '../../constants';
import { makeRefund } from '../../duck/actions';
import { makeRefundInProgressSel } from '../../duck/selectors';
import { paymentMethodsIncludes } from '../../utils';

import RefundInfo from './components/RefundInfo';
import { MakeRefundModalParams } from './types';

const IS_ONLINE_REFUND_FIELD = 'isOnlineRefund';

interface Props {
  modalParams: MakeRefundModalParams;
}

type DateType = Dayjs | null;

function getRefundDate(
  paymentDate: Dayjs,
  refundDate: Dayjs | null,
  isOnlineRefund: boolean,
): string {
  if (isOnlineRefund || !refundDate) {
    if (dayjs().isBefore(paymentDate)) {
      return dateToString(paymentDate);
    }
    return dateToString();
  }

  if (refundDate.isBefore(paymentDate) && refundDate.isSame(paymentDate, 'day')) {
    return dateToString(paymentDate);
  }

  return dateToString(refundDate);
}

function getPaymentGateway(isOnlineRefund: boolean, paymentMethod: string) {
  if (isOnlineRefund) {
    if (
      paymentMethodsIncludes([PaymentMethod.creditCard, PaymentMethod.eCheckOrbital], paymentMethod)
    ) {
      return '1'; //Chase
    }
  }
}

const MakeRefundModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<{
    amount: number;
    refundReason: string;
    refundDate: DateType;
    [IS_ONLINE_REFUND_FIELD]?: boolean;
  }>();
  const makeRefundInProgress = useSelector(makeRefundInProgressSel);
  const { expeditionNumber, formCode, isGroup, guid } = modalParams;

  const paymentRecord = 'paymentRecord' in modalParams ? modalParams.paymentRecord : null;

  function handleSubmit() {
    form.validateFields().then(values => {
      const { refundDate, isOnlineRefund = false, ...rest } = values;

      dispatch(
        makeRefund.request({
          formCode,
          isGroup,
          guid,
          ...rest,
          ...(paymentRecord
            ? {
                sessionId: paymentRecord.sessionId,
                paymentGateway: getPaymentGateway(isOnlineRefund, paymentRecord.paymentType),
                paymentDate: getRefundDate(
                  dateFromString(paymentRecord.creationDate),
                  refundDate,
                  isOnlineRefund,
                ),
              }
            : {
                paymentDate: dateToString(refundDate),
                skipOriginalPaymentCheck: true,
              }),
        }),
      );
    });
  }

  return (
    <ModalComponent
      title={`Make Refund${expeditionNumber ? ` For Expedition Number ${expeditionNumber}` : ''}`}
      inProgress={makeRefundInProgress}
      buttons={[
        {
          title: 'REFUND',
          type: 'primary',
          onClick: handleSubmit,
        },
      ]}
    >
      <Form form={form}>
        <Row justify="center">
          <Col span={12}>
            {paymentRecord && (
              <Form.Item name={IS_ONLINE_REFUND_FIELD} valuePropName="checked" initialValue={true}>
                <Checkbox>
                  Process Refund Online{' '}
                  <InfoBubble>
                    If unchecked, this refund will be recorded in the payment history but will not
                    actually be sent to the payment processor. This is useful if you already
                    refunded the payment outside of this system.
                  </InfoBubble>
                </Checkbox>
              </Form.Item>
            )}
            <Form.Item noStyle dependencies={[IS_ONLINE_REFUND_FIELD]}>
              {({ getFieldValue }) => {
                const isOnlineRefund = getFieldValue(IS_ONLINE_REFUND_FIELD);
                if (isOnlineRefund) return null;
                return (
                  <Form.Item
                    name="refundDate"
                    label="Posted Date"
                    rules={[
                      {
                        required: !isOnlineRefund,
                        message: 'Please select Date',
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={date => {
                        if (!date) return false;
                        if (date.isAfter(dayjs(), 'day')) return true;
                        if (!paymentRecord) return false;
                        const { creationDate } = paymentRecord;
                        const paymentDate = dateFromString(creationDate);
                        return date.isBefore(paymentDate, 'day');
                      }}
                      size="large"
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        {paymentRecord && (
          <Row justify="center">
            <Col span={12}>
              <RefundInfo payment={paymentRecord} />
            </Col>
          </Row>
        )}
        <Row justify="center">
          <Col span={12}>
            <Form.Item
              name="amount"
              initialValue={paymentRecord?.amount}
              label="Amount to Refund"
              rules={[
                {
                  required: true,
                  message: 'Amount is required',
                },
                {
                  type: 'number',
                  message: 'Amount can contain only numbers',
                },
                {
                  validator: (_rule, value) => {
                    if (isNumber(value)) {
                      if (value <= 0) {
                        return Promise.reject('Refund amount should be bigger than 0');
                      }
                      if ('paymentRecord' in modalParams) {
                        if (value > modalParams.paymentRecord.amount) {
                          return Promise.reject(
                            'Refund amount can not be bigger than payment amount',
                          );
                        }
                      } else {
                        const { amountPaid } = modalParams;
                        if (value > amountPaid) {
                          return Promise.reject('Refund amount can not be bigger than amount paid');
                        }
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber size="large" controls={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="refundReason"
              label="Reason for Refund"
              rules={[
                {
                  required: true,
                  message: 'Refund reason is required',
                },
                {
                  min: 5,
                  message: 'Reason for Refund must be at least 5 characters',
                },
                {
                  max: 200,
                  message: 'Reason for Refund can not be longer than 200 characters',
                },
              ]}
            >
              <Input.TextArea size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalComponent>
  );
};

export default MakeRefundModal;
