import { ButtonProps } from 'antd';
import React, { useMemo } from 'react';
import { AiFillEdit } from 'react-icons/ai';

import Button from '@/modules/shared/components/Button';

import {
  TitleWrapper,
  CardWithWiderHeader,
  ButtonsRow,
  ActionButtonsContainer,
  ActionButton,
} from './EditableFormSection.styled';

type Props = {
  canEdit: boolean;
  children: React.ReactNode;
  description?: string;
  extraActions?: Array<
    Pick<ButtonProps, 'onClick' | 'icon' | 'href' | 'rel' | 'target'> & { key: string }
  >;
  isEditing: boolean;
  isLoading?: boolean;
  hideButtons?: boolean;
  hideCancelButton?: boolean;
  hideSaveButton?: boolean;
  saveButtonDisabled?: boolean;
  title: string;
  wrapButtons?: boolean;
  onCancel?: () => void;
  onEditClick: () => void;
  onSave?: () => void;
};

const EditableFormSection: React.FC<Props> = ({
  canEdit,
  children,
  extraActions,
  isEditing,
  description,
  hideButtons,
  hideCancelButton,
  hideSaveButton,
  saveButtonDisabled,
  isLoading,
  title,
  wrapButtons,
  onCancel,
  onEditClick,
  onSave,
}) => {
  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSave = () => {
    onSave && onSave();
  };

  const headerButtons = useMemo(
    () => [
      ...(extraActions || []),
      ...(canEdit
        ? [
            {
              key: 'edit',
              icon: <AiFillEdit />,
              onClick: onEditClick,
            },
          ]
        : []),
    ],
    [canEdit, extraActions, onEditClick],
  );

  return (
    <CardWithWiderHeader
      description={description || undefined}
      loading={isLoading}
      header={
        <TitleWrapper>
          <span>{title}</span>
          <ActionButtonsContainer>
            {!isEditing &&
              headerButtons.map(({ key, ...buttonProps }) => (
                <ActionButton
                  key={key}
                  {...buttonProps}
                  shape="circle"
                  size="large"
                  type="primary"
                />
              ))}
          </ActionButtonsContainer>
        </TitleWrapper>
      }
    >
      {children}
      {isEditing && !hideButtons && (
        <ButtonsRow justifyContent="flex-end" wrapButtons={wrapButtons}>
          {!hideCancelButton && (
            <Button onClick={handleCancel} disabled={isLoading}>
              CLOSE
            </Button>
          )}
          {!hideSaveButton && (
            <Button type="primary" onClick={handleSave} disabled={isLoading || saveButtonDisabled}>
              SAVE
            </Button>
          )}
        </ButtonsRow>
      )}
    </CardWithWiderHeader>
  );
};

export default EditableFormSection;
