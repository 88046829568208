import { combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { deleteAttendeeType, updateAttendeeType, updateAttendeeTypeClosed } from './actions';
import services from './services';

const deleteAttendeeType$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteAttendeeType.request)),
    switchMap(({ payload }) => {
      const { formCode } = createDataSel('form')(state$.value);
      return services.deleteAttendeeType$({ formCode, ...payload }).pipe(
        switchMap(() => {
          toastService.success('Attendee Type deleted successfully');
          return of(deleteAttendeeType.success(), refreshData({ dataType: 'attendeeTypeList' }));
        }),
      );
    }),
    catchError((error: ApiError) => {
      toastService.error(error.message);
      return of(deleteAttendeeType.failure(error));
    }),
  );

const updateAttendeeType$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateAttendeeType.request)),
    switchMap(({ payload }) => {
      const { formCode } = createDataSel('form')(state$.value);

      return services
        .updateAttendeeType$({
          ...payload,
          formCode,
        })
        .pipe(
          switchMap(() => {
            toastService.success(
              `Attendee Type ${payload.typeCode ? 'created' : 'updated'} successfully`,
            );
            return of(
              updateAttendeeType.success(),
              refreshData({ dataType: 'attendeeTypeList' }),
              updateAttendeeTypeClosed(),
            );
          }),
        );
    }),
    catchError((error: ApiError) => {
      toastService.error(error.message);
      return of(updateAttendeeType.failure(error));
    }),
  );

export default combineEpics(deleteAttendeeType$, updateAttendeeType$);
