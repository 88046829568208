import { createAction, createCustomAction } from 'typesafe-actions';

import { moduleName } from '../constants';

export const openModal = createCustomAction(
  `${moduleName}/OPEN_MODAL`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (modalName: string, params = {}, props = {}) => ({
    modalName,
    params,
    props,
  }),
);

export const closeModal = createAction(`${moduleName}/CLOSE_MODAL`)();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateModalParams = createAction(`${moduleName}/UPDATE_MODAL_PARAMS`)<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, any>
>();
