import React, { forwardRef } from 'react';
import { IconType } from 'react-icons';
import { ImSpinner8 } from 'react-icons/im';

import { TransparentButton, Label, ButtonContent } from './StylableIconButton.styled';

interface Props {
  label?: string;
  Icon?: IconType;
  color?: string;
  hoverColor?: string;
  iconSize?: number;
  labelSize?: string;
  inline?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  disabled?: boolean;
  renderIcon?: () => React.ReactNode;
}

const IconButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      label,
      Icon,
      color,
      hoverColor,
      iconSize = 20,
      labelSize = '14px',
      inline,
      onClick,
      isLoading,
      disabled,
      renderIcon,
      // passed ...rest to allow styled-components to extend it using classname, and
      // to allow event bindings to the internal button, required by some wrappers
      // like Antd Tooltip
      ...rest
    },
    ref,
  ) => {
    const IconComponent = isLoading ? ImSpinner8 : Icon;

    return (
      <TransparentButton
        ref={ref}
        onClick={onClick}
        color={color}
        hoverColor={hoverColor}
        inline={inline}
        disabled={disabled || isLoading}
        {...rest}
      >
        <ButtonContent $isLoading={isLoading}>
          {renderIcon ? renderIcon() : IconComponent && <IconComponent size={iconSize} />}
          {label && <Label fontSize={labelSize}>{label}</Label>}
        </ButtonContent>
      </TransparentButton>
    );
  },
);

export default IconButton;
