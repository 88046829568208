import { Steps as AntSteps } from 'antd';
import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 20px;

  > * {
    :last-child {
      margin-left: auto;
    }
  }
`;

export const Steps = styled(AntSteps)`
  margin-bottom: 20px;
`;
