import { createAsyncAction, RootAction } from 'typesafe-actions';

import { moduleName } from '../constants';

export const acceptDisclaimers = createAsyncAction(
  `${moduleName}/ACCEPT_DISCLAIMERS_REQUEST`,
  `${moduleName}/ACCEPT_DISCLAIMERS_SUCCESS`,
  `${moduleName}/ACCEPT_DISCLAIMERS_FAILURE`,
)<
  {
    codes: string[];
    finalActions?: RootAction[];
    formCode: string;
    personGUID: string;
    formRecordGUID: string;
    firstName: string;
    lastName: string;
    relationship: string;
  },
  undefined,
  Error
>();
