import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FoundPerson } from '@/modules/data/dataTypes/foundPersons';
import { createDataSel } from '@/modules/data/duck/selectors';

export const useFoundPerson = (): FoundPerson | undefined => {
  const foundPeople: FoundPerson[] | undefined = useSelector(createDataSel('foundPeople'));
  const foundPerson = useMemo(() => foundPeople && foundPeople[0], [foundPeople]);
  return foundPerson;
};

export const useDisabledFutureDates = (): ((date: Dayjs | null) => boolean) => {
  const disabledFutureDates = useCallback((date: Dayjs | null) => {
    if (!date) return false;
    return date.isAfter(dayjs(), 'day');
  }, []);

  return disabledFutureDates;
};
