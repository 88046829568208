import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { moduleName as rostersListModuleName } from '@/pages/reservation/components/MyReservation/components/RostersList/constants';
import rostersListReducers from '@/pages/reservation/components/MyReservation/components/RostersList/duck/reducers';
import { moduleName as rosterPageModuleName } from '@/pages/reservation/tabs/OverviewGroup/components/RosterPage/constants';
import rosterPageReducers from '@/pages/reservation/tabs/OverviewGroup/components/RosterPage/duck/reducers';

import {
  updateFormRegistrationSession,
  updateGroupReservationSession,
  updateReservationSize,
} from './actions';

const updateFormRegistrationSessionInProgress = createReducer(false)
  .handleAction(updateFormRegistrationSession.request, () => true)
  .handleAction(
    [updateFormRegistrationSession.success, updateFormRegistrationSession.failure],
    () => false,
  );

const updateGroupReservationSessionInProgress = createReducer(false)
  .handleAction(updateGroupReservationSession.request, () => true)
  .handleAction(
    [updateGroupReservationSession.success, updateGroupReservationSession.failure],
    () => false,
  );

const updateReservationSizeInProgress = createReducer(false)
  .handleAction(updateReservationSize.request, () => true)
  .handleAction([updateReservationSize.success, updateReservationSize.failure], () => false);

export default combineReducers({
  [rosterPageModuleName]: rosterPageReducers,
  [rostersListModuleName]: rostersListReducers,
  updateFormRegistrationSessionInProgress,
  updateGroupReservationSessionInProgress,
  updateReservationSizeInProgress,
});
