import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createDataSel, createExtraDataSel } from '@/modules/data/duck/selectors';
import AttendeeTypes from '@/modules/shared/components/AttendeeTypes';

import { SECONDARY_REGISTRANT_DATA_ID } from '../../constants';
import { addAdditionalRegistrant } from '../../duck/actions';

const AttendeeTypesStep = forwardRef<{ handleSubmit: () => void }>((_, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const attendeeTypesRef = useRef<{ handleSubmit: () => Promise<string> | undefined }>(null);
  const dispatch = useDispatch();
  const attendeeDetails = useSelector(
    createDataSel('attendeeDetails', SECONDARY_REGISTRANT_DATA_ID),
  );
  const attendeeTypes = useSelector(createDataSel('attendeeTypesForPersonList'));
  const { isMembershipEvent } = useSelector(createExtraDataSel('attendeeTypesForPersonList'));

  function handleSubmit() {
    attendeeTypesRef.current?.handleSubmit()?.then(typeCode => {
      dispatch(addAdditionalRegistrant.request(typeCode));
    });
  }

  return (
    <AttendeeTypes
      ref={attendeeTypesRef}
      selectedAttendeeType={attendeeDetails?.typeCode}
      isMembershipEvent={isMembershipEvent}
      attendeeTypes={attendeeTypes}
      hasActiveMembership={false}
      isAlumni={false}
    />
  );
});

export default AttendeeTypesStep;
