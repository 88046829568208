import { Button } from 'antd';
import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

const buttonStyles = css`
  text-transform: uppercase;
`;

export const ViewEventButton = styled(Button)`
  && {
    ${buttonStyles};

    box-shadow: none;
    color: ${colors.scoutingBlue};
  }
`;

export const ViewReservationsButton = styled(Button)`
  ${buttonStyles};
`;
