import { Card as BsaCard, Steps as BsaSteps } from 'antd';
import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

const finishedStepColor = colors.scoutingBlue;
const activeStepColor = colors.lightBlue;
const waitingStepColor = colors.complementColor;

export const Card = styled(BsaCard)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
`;

export const Steps = styled(BsaSteps)`
  .ant-steps-item-tail::after {
    height: 3px;
    border-radius: 3px;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: ${finishedStepColor};
    border-color: ${finishedStepColor};

    .ant-steps-icon {
      color: #fff;
    }
  }

  .ant-steps-item-active .ant-steps-item-icon {
    background-color: ${activeStepColor};
    border-color: ${activeStepColor};

    .ant-steps-icon {
      color: #fff;
    }
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: ${waitingStepColor};
    border-color: ${waitingStepColor};

    .ant-steps-icon {
      color: #969897;
    }
  }
`;

const disabledStepColor = colors.darkGrey;
const disabledStepStyles = css`
  &&& {
    .ant-steps-item-icon {
      background-color: ${disabledStepColor};
      border-color: ${disabledStepColor};
    }
    .ant-steps-icon {
      color: #969897;
    }
  }
`;

export const Step = styled(Steps.Step)<{ $disabled: boolean }>`
  ${({ $disabled }) => ($disabled ? disabledStepStyles : '')};
`;
