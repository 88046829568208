import { Row, Col } from 'antd';
import styled, { css } from 'styled-components';

const collapsed = css`
  right: 0px;
  width: 0;
  transform: translate(100%, 0);
  position: absolute;
  transition: all 0.4s;
`;

export const SlidePanel = styled.div`
  overflow: hidden;
`;

export const Container = styled(Row)<{ $isHidden: boolean }>`
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: 1;
  visibility: ${({ $isHidden }) => ($isHidden ? 'hidden' : 'visible')};
`;

export const Panel = styled(Col)<{ $isCollapsed: boolean }>`
  height: 100%;
  float: right;
  transition: all 0.4s;
  overflow: auto;
  ${({ $isCollapsed }) => $isCollapsed && collapsed};
`;

export const CloseButtonWrapper = styled.div`
  padding: 0 0.8rem;
  text-align: right;
`;
