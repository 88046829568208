import apiService from '@/modules/utils/apiService';

import {
  ACCOUNT_CODE,
  ACCOUNT_COST_CENTER,
  ACCOUNT_DESCRIPTION,
  ACCOUNT_NAME,
  ACCOUNT_NUMBER,
  ACCOUNT_SESSIONS,
} from '../constants';

const updateCreateLedgerAccount$ = (payload: {
  formCode: string;
  [ACCOUNT_CODE]?: string;
  [ACCOUNT_NAME]: string;
  [ACCOUNT_DESCRIPTION]: string;
  [ACCOUNT_COST_CENTER]: string;
  [ACCOUNT_NUMBER]: string;
  [ACCOUNT_SESSIONS]: number;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateGeneralLedgerAccount',
    payload,
  );

const deleteLedgerAccount$ = (payload: { formCode: string; accountCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteGeneralLedgerAccount',
    payload,
  );

export default {
  updateCreateLedgerAccount$,
  deleteLedgerAccount$,
};
