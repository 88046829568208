import { combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, catchError, filter } from 'rxjs/operators';
import { isActionOf, RootAction } from 'typesafe-actions';

import toastService from '@/modules/toasts/service';

import { saveSelectedAddonOptions } from './actions';
import services from './services';

const saveSelectedAddonOptions$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(saveSelectedAddonOptions.request)),
    switchMap(({ payload }) => {
      const { formCode, isGroup, addons = [], finalActions = [] } = payload;

      const service$ = isGroup
        ? services.updateGroupReservationAddon$
        : services.updateFormRecordAddon$;

      const newPayload = {
        formCode,
        ...(payload.isGroup
          ? {
              groupReservationGUID: payload.groupReservationGUID,
            }
          : {
              formRecordGUID: payload.formRecordGUID,
            }),
        addons: addons.map(({ addonOptions, ...addon }) => ({
          ...addon,
          ...('selectedOptionCode' in addon && addon.selectedOptionCode
            ? {
                isIncluded: true,
                selectedOptionCode: addon.selectedOptionCode,
              }
            : {
                isIncluded: false,
              }),
        })),
      };

      return service$(newPayload).pipe(
        switchMap(() => {
          toastService.success(
            `${isGroup ? 'Reservation' : 'Registration'} addons were successfully updated`,
          );
          return of(saveSelectedAddonOptions.success(), ...finalActions);
        }),
        catchError(error => {
          toastService.error(error.message);
          return of(saveSelectedAddonOptions.failure(error.message));
        }),
      );
    }),
  );

export default combineEpics(saveSelectedAddonOptions$);
