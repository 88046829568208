import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Li = styled.li`
  max-height: 800px;
  overflow: auto;
`;

export const Strong = styled.strong`
  color: ${colors.primaryColor};
`;

export const Code = styled.code`
  color: ${colors.text};
`;
