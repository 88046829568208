import { createAsyncAction } from 'typesafe-actions';

import { Disclaimer } from '@/modules/data/dataTypes/disclaimers';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { createModuleNamespaceName } from '@/modules/utils/misc';

import { UpdateDisclaimerPayload } from '../components/UpdateDisclaimerModal/types';
import { modulePath } from '../constants';

const moduleNamespace = createModuleNamespaceName(modulePath);

export const updateDisclaimer = createAsyncAction(
  `${moduleNamespace}/UPDATE_DISCLAIMER_REQUEST`,
  `${moduleNamespace}/UPDATE_DISCLAIMER_SUCCESS`,
  `${moduleNamespace}/UPDATE_DISCLAIMER_FAILURE`,
)<UpdateDisclaimerPayload, void, Error>();

export const deleteDisclaimer = createAsyncAction(
  `${moduleNamespace}/DELETE_DISCLAIMER_REQUEST`,
  `${moduleNamespace}/DELETE_DISCLAIMER_SUCCESS`,
  `${moduleNamespace}/DELETE_DISCLAIMER_FAILURE`,
)<Pick<Disclaimer, 'disclaimerCode'> & Pick<FormSettings, 'formCode'>, void, Error>();

export const linkEventDisclaimer = createAsyncAction(
  `${moduleNamespace}/LINK_EVENT_DISCLAIMER_REQUEST`,
  `${moduleNamespace}/LINK_EVENT_DISCLAIMER_SUCCESS`,
  `${moduleNamespace}/LINK_EVENT_DISCLAIMER_FAILURE`,
)<Pick<Disclaimer, 'disclaimerCode'> & Pick<FormSettings, 'formCode'>, void, Error>();

export const unlinkEventDisclaimer = createAsyncAction(
  `${moduleNamespace}/UNLINK_EVENT_DISCLAIMER_REQUEST`,
  `${moduleNamespace}/UNLINK_EVENT_DISCLAIMER_SUCCESS`,
  `${moduleNamespace}/UNLINK_EVENT_DISCLAIMER_FAILURE`,
)<{ formCode: string; disclaimerCode: string }, void, Error>();
