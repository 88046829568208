import { PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { ChangeEventHandler, Fragment, useEffect, useState } from 'react';

import Divider from '../../../Divider';

import { Space } from './AddTag.styled';

type Props = {
  addInProgress: boolean;
  onAdd: (value: string, label: string) => void;
};

const AddTag: React.FC<Props> = ({ addInProgress, onAdd }) => {
  const [newTag, setNewTag] = useState('');

  useEffect(() => {
    if (!addInProgress) {
      setNewTag('');
    }
  }, [addInProgress]);

  const handleChangeNewTag: ChangeEventHandler<HTMLInputElement> = e => {
    setNewTag(e.target.value);
  };

  const handleAdd = () => {
    if (!newTag) return null;
    onAdd(newTag.replace(/\s+/g, '').toLowerCase(), newTag);
  };

  return (
    <Fragment>
      <Divider />
      <Space>
        <Input value={newTag} onChange={handleChangeNewTag} />
        <Button type="text" icon={<PlusOutlined />} loading={addInProgress} onClick={handleAdd}>
          Add
        </Button>
      </Space>
    </Fragment>
  );
};

export default AddTag;
