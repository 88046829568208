import { Button } from 'antd';
import styled from 'styled-components';

import { CDN_URL } from '@/modules/config/config';

export const MainContainer = styled.section`
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  height: 100%;
  padding: 30px;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
`;

export const StyledBSATent = styled.img.attrs({
  src: `${CDN_URL}bsa-tent.png`,
  srcset: `${CDN_URL}bsa-tent@2x.png 2x, ${CDN_URL}bsa-tent@3x.png 3x`,
})`
  width: auto;
  height: 50vh;
  object-fit: contain;
`;

export const ErrorTitle = styled.h1`
  font-size: 5vh;
  font-weight: bold;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.8px;
`;

export const ErrorMessage = styled.h2`
  font-size: 2.2vh;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: #666;
`;

export const RoundedBigButton = styled(Button).attrs({ type: 'primary' })`
  border-radius: 28px;
  height: 56px;
`;

export const RoundedBigButtonText = styled.span`
  font-size: 16px;
  margin: 16px 56px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 2px;
`;
