import dayjs from 'dayjs';
import React, { Fragment, useMemo } from 'react';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { AttendeeType, Roster } from '@/modules/data/dataTypes/rosterList';
import ActionTable from '@/modules/shared/components/ActionTable';
import RosterAttendeeTypeAmount from '@/modules/shared/components/RosterAttendeeTypeAmount';
import { useAbilities } from '@/modules/user/duck/abilities';
import { dateFromString, usDate } from '@/modules/utils/dateFormats';

import PrintRosterColumn from '@/pages/event/tabs/Rosters/PrintRosterColumn/PrintRosterColumn';

import LeadAdvisor from '../LeadAdvisor';
import OpenButton from '../OpenButton';

import styles from './RostersTable.less';
import { RostersTableDateReminder } from './RostersTable.styled';

import InvitationStatusCell from 'SHARED/components/InvitationStatusCell';

type Props = {
  reservationDetails: GroupReservation;
  rosters: Roster[];
  rostersAttendeeTypes: AttendeeType[];
  canAddLeadAdvisor: boolean;
  getCanOpenRoster: (roster: Roster) => boolean;
  onAddLeadAdvisor: (roster: Roster) => void;
  onOpenRoster: (roster: Roster) => void;
};

const RostersTable: React.FC<Props> = ({
  reservationDetails,
  rosters,
  rostersAttendeeTypes,
  canAddLeadAdvisor,
  getCanOpenRoster,
  onAddLeadAdvisor,
  onOpenRoster,
}) => {
  const abilities = useAbilities();

  const canPrintRosterReport = abilities.can({
    action: 'executePrint',
    target: 'dashboard.reports',
  });

  const columns = useMemo(() => {
    const attendeeColumns = rostersAttendeeTypes.map(attendeeType => ({
      title: attendeeType.typeName,
      key: attendeeType.typeCode,
      // eslint-disable-next-line react/no-multi-comp
      render: (roster: Roster) => (
        <RosterAttendeeTypeAmount
          roster={roster}
          attendeeType={attendeeType}
          reservationDetails={reservationDetails}
        />
      ),
    }));

    const initialColumns = [
      {
        title: 'Crew Number',
        dataIndex: 'rosterExpeditionNumber',
        className: styles.rosterCode,
      },
      {
        title: 'Lead Advisor',
        key: 'leadAdvisorName',
        // eslint-disable-next-line react/no-multi-comp
        render: (roster: Roster) => (
          <LeadAdvisor onAdd={onAddLeadAdvisor} canAdd={canAddLeadAdvisor} roster={roster} />
        ),
      },
      {
        title: 'Invitation Status',
        key: 'leadAdvisorStatus',
        // eslint-disable-next-line react/no-multi-comp
        render: (roster: Roster) => (
          <InvitationStatusCell
            statusName={roster.leadAdvisorStatus}
            invitationSentDate={roster.leadAdvisorInvitationSentDate}
          />
        ),
      },
      ...attendeeColumns,
    ];

    if (canPrintRosterReport) {
      initialColumns.push({
        key: 'printRoster',
        title: '',
        // eslint-disable-next-line react/no-multi-comp
        render: (roster: Roster) =>
          Number(roster.numberOfAttendees) > 0 ? (
            <PrintRosterColumn rosterCode={roster.rosterCode} />
          ) : (
            <Fragment />
          ),
      });
    }

    return [
      ...initialColumns,
      {
        // eslint-disable-next-line react/no-multi-comp
        render: (roster: Roster) => {
          const canOpenRoster = getCanOpenRoster(roster);

          if (canOpenRoster) {
            return <OpenButton onOpenRoster={onOpenRoster} roster={roster} />;
          }

          return null;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canPrintRosterReport, rosters]);

  const { sessionStartDate } = reservationDetails;

  const renderRosterDateReminder = () => {
    if (!sessionStartDate) return null;

    const registrationDate = dateFromString(sessionStartDate).subtract(90, 'days');
    const isAfterRequiredDate = dayjs().isSameOrAfter(registrationDate, 'day');
    const descriptionLegend = isAfterRequiredDate
      ? 'You can now provide your detailed list of attendees to each roster below.'
      : `Please provide your detailed list of attendees to each roster below by ${usDate(
          registrationDate,
        )}. It is not necessary to fill out your rosters prior to this date.`;

    return <RostersTableDateReminder>{descriptionLegend}</RostersTableDateReminder>;
  };

  return (
    <ActionTable
      id="rostersTable"
      columns={columns}
      dataSource={rosters}
      rowKey="rosterCode"
      customActionBarRender={renderRosterDateReminder}
      allowSearch={false}
    />
  );
};

export default RostersTable;
