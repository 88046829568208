import React from 'react';
import { useSelector } from 'react-redux';

import { memberIdSel } from '@/modules/user/duck/selectors';

import { StyledCard, ContentWrapper } from './InvalidMembership.styled';

const InvalidMembership: React.FC = () => {
  const memberId = useSelector(memberIdSel);

  return (
    <StyledCard title="Invalid Membership">
      <ContentWrapper>
        <p>
          The member ID <b>{memberId}</b> associated with your account is not valid. If you have
          received an updated member ID from your council, you can visit
          <a href="https://my.scouting.org/tools/manage-member-id" target="_blank">
            <b> My.Scouting - Manage Member Id </b>
          </a>
          to update your account. Simply enter your new Member ID and then select it to be your
          "Primary" Member ID.
        </p>
        <p>
          If you are not aware of any other Member ID, please contact your council for assistance.
        </p>
      </ContentWrapper>
    </StyledCard>
  );
};

export default InvalidMembership;
