export enum PermissionCode {
  EditEvent = 'editEvent',
  QueryFilterCreateAccess = 'createReport',
  QueryFilterExecuteReportsAccess = 'executeReportsEventDashboard',
  AttendeeDetailsReadAccess = 'viewAttendeeDetails',
  AttendeeDetailsWriteAccess = 'editAttendeeDetails',
  AttributesUpdateAccess = 'updateAttributes',
  SessionUpdateAccess = 'editAttendeeSession',
  ExportAttendeeDataAccess = 'exportAttendeeData',
  PostPayments = 'postPaymentsToAttendee',
  CouncilApproveAccess = 'councilApproveAttendees',
  HiringAccess = 'hireAttendee',
  HiringCancelAccess = 'cancelJobOffer',
  AttendeeLogsAccess = 'viewAttendeeRecordLogs',
  AttendeeCommentsAccess = 'commentAttendeeRecordLogs',
  AttendeeEditJobPrioritiesAccess = 'editAttendeeJobPriorities',
  AttendeesTabReadAccess = 'viewAttendeesTab',
  EmailsManageAccess = 'manageEmails',
  PostRefunds = 'postRefunds',
  TransactionsReadAccess = 'viewTransactions',
}

export const queryFilterPermissionTargetPrefix = 'queryFilter';

export enum PersonRoles {
  SystemAdministrator = 'systemAdministrator',
  EventAdministrator = 'eventAdministrator',
  EventApprovalDesignee = 'ead',
  Alumni = 'alumni',
}

export enum PermissionAction {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}
