import React, { Fragment } from 'react';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservationListReport';
import InfoBubble from '@/modules/shared/components/InfoBubble';

import styles from './PeopleColumn.less';

type Props = {
  record: GroupReservation;
};

const PeopleColumn: React.FC<Props> = ({ record }) => {
  const { numberOfAdults, numberOfYouth, registrationCount } = record;

  return (
    <Fragment>
      {registrationCount}
      <InfoBubble>
        <ul className={styles.tooltip}>
          <li>{numberOfAdults} Adults</li>
          <li>{numberOfYouth} Youth</li>
        </ul>
      </InfoBubble>
    </Fragment>
  );
};

export default PeopleColumn;
