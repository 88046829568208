import { Card, Form } from 'antd';
import React, { useMemo } from 'react';

import { PaymentMethod, PaymentMode } from '@/modules/payments/constants';
import TextAreaMaterial from '@/modules/shared/components/TextAreaMaterial';

import * as s from './PaymentPageSummary.styled';
import CharsCounter from './components/CharsCounter';
import MaxAmountInfo from './components/MaxAmountInfo';

interface Props {
  paymentMode: PaymentMode;
  amount: number;
  paymentComments: string;
  isLoading: boolean;
  isDisabled: boolean;
  hideMakePaymentButton: boolean;
  paymentMethod: PaymentMethod | null;
  onMakePayment: (e: React.MouseEvent<HTMLElement>) => void;
  onPaymentCommentsChange: (notes: string) => void;
}

const PaymentPageSummary: React.FC<Props> = ({
  amount,
  paymentComments,
  paymentMode,
  onPaymentCommentsChange,
  onMakePayment,
  isLoading,
  isDisabled,
  hideMakePaymentButton,
  paymentMethod,
}) => {
  const handlePaymentCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onPaymentCommentsChange(e.target.value);
  };

  const handleMakePayment = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (error) return;
    onMakePayment(e);
  };

  const submitButtonText = useMemo(() => {
    switch (paymentMethod) {
      case PaymentMethod.check:
        return 'Continue';
      case PaymentMethod.adjustment:
        return 'Make Adjustment';
      default:
        return 'Make Payment';
    }
  }, [paymentMethod]);

  const error = useMemo(() => {
    const noteFieldRequired = paymentMethod === PaymentMethod.adjustment;
    if (!noteFieldRequired) return false;
    if (!paymentComments) return 'Notes are required';
    if (paymentComments.length < 5) return 'Notes must be at least 5 characters';
  }, [paymentMethod, paymentComments]);

  return (
    <Card title="Payment Summary">
      <s.HeaderRow>
        <b>Installment</b>
        <b>Amount</b>
      </s.HeaderRow>
      <s.Row>
        <p>{paymentMode} Payment:</p>
        <p>${amount}</p>
      </s.Row>
      <MaxAmountInfo paymentMethod={paymentMethod} amount={amount} />
      <s.RowBordered>
        <b>TOTAL</b>
        <b>${amount}</b>
      </s.RowBordered>
      <Form.Item help={error} validateStatus={error ? 'error' : ''}>
        <TextAreaMaterial
          labelContent="Notes"
          value={paymentComments}
          onChange={handlePaymentCommentsChange}
          disabled={isDisabled}
        />
      </Form.Item>
      <CharsCounter text={paymentComments} />
      {!hideMakePaymentButton && (
        <s.SubmitButton
          type="primary"
          onClick={handleMakePayment}
          loading={isLoading}
          disabled={isDisabled || !amount}
        >
          {submitButtonText}
        </s.SubmitButton>
      )}
    </Card>
  );
};

export default PaymentPageSummary;
