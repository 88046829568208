import { combineEpics, Epic } from 'redux-observable';
import { merge, of, race } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData } from '@/modules/data/duck/actions';
import { updatePerson } from '@/modules/entities/Person/duck/actions';
import toastService from '@/modules/toasts/service';

import { updateProfileInformation } from './actions';

const updateProfileInformation$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(updateProfileInformation)),
    switchMap(({ payload }) =>
      merge(
        race(
          action$.pipe(
            filter(isActionOf(updatePerson.failure)),
            take(1),
            switchMap(({ payload: error }) => {
              throw new Error(error.message);
            }),
          ),
          action$.pipe(
            filter(isActionOf(updatePerson.success)),
            take(1),
            map(() => {
              toastService.success('Successfully updated profile information');
              return refreshData({ dataType: 'arnicaPerson' });
            }),
          ),
        ),
        of(updatePerson.request(payload)),
      ),
    ),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return caught;
    }),
  );

export default combineEpics(updateProfileInformation$);
