import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import SelectAddons from '@/modules/entities/Addons/components/SelectAddons';
import { saveOptionsInProgressSel } from '@/modules/entities/Addons/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';

import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const EditAttendeeAddonsModal: React.FC<Props> = ({ modalParams }) => {
  const { firstName, lastName } = modalParams;
  const inProgress = useSelector(saveOptionsInProgressSel);
  const selectAddonsRef = useRef<{ handleSubmit: () => void }>(null);

  function handleSubmit() {
    selectAddonsRef.current?.handleSubmit();
  }

  return (
    <ModalComponent
      title={`${firstName} ${lastName} Add-ons`}
      description={`Choose the add-ons you want to add to ${firstName} ${lastName}`}
      buttons={[
        {
          title: 'SAVE CHANGES',
          type: 'primary',
          onClick: handleSubmit,
        },
      ]}
      inProgress={inProgress}
    >
      <SelectAddons ref={selectAddonsRef} isGroup={false} {...modalParams} />
    </ModalComponent>
  );
};

export default EditAttendeeAddonsModal;
