import { DeleteOutlined } from '@ant-design/icons';
import { Spin, Tag } from 'antd';
import get from 'lodash/get';
import React, { Fragment, useCallback, useMemo } from 'react';
import { AiOutlineSwap } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import { MdAddCircleOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { UPDATE_RESERVATION_CONTACT_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import Dropdown from '@/modules/shared/components/Dropdown';
import InfoItem from '@/modules/shared/components/InfoItem';
import { formatPhone } from '@/modules/shared/components/InputPhone/utils';
import ResendInvite from '@/modules/shared/components/InvitationStatusCell/components/ResendInvite/ResendInvite';
import Section from '@/modules/shared/components/ReservationDetailsSection';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { isSystemAdministratorSel } from '@/modules/user/duck/selectors';

import { RCStatus } from '@/pages/reservation/components/MyReservation/components/types';
import {
  cancelGroupReservationContactInvitation,
  navigateToGroupReservationAttendeeDetails,
  removeAdditionalReservationContact,
  updateGroupReservationContact,
} from '@/pages/reservation/duck/actions';
import {
  cancelGroupReservationContactInvitationInProgressSel,
  pageDataSel,
  sendGroupReservationRCInviteInProgressSel,
} from '@/pages/reservation/duck/selectors';

import { ModalParams } from '../../../UpdateReservationContactModal/types';
import { sections } from '../GroupReservationSections/constants';

import {
  AdditionalRCActionButton,
  ReservationContactNameWrapper,
} from './ReservationContactInformation.styled';

const statusLabel = {
  [RCStatus.PENDING]: 'Pending',
  [RCStatus.ACTIVE]: '',
  [RCStatus.REPLACING]: 'Replacement Pending',
};

interface Props {
  key: string;
  sectionId: string;
  header: string;
  status: RCStatus;
  reservationContact: GroupReservation['reservationContact'] | GroupReservation['pendingContact'];
  canAddARC?: boolean;
  pendingRCPositionLabel?: string;
}

const ReservationContactInformation: React.FC<Props> = ({
  key,
  sectionId,
  header,
  status,
  reservationContact,
  canAddARC,
  pendingRCPositionLabel,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isSystemAdministrator = useSelector(isSystemAdministratorSel);
  const {
    data: {
      form: { formCode, isCreator, isCollaborator },
      groupReservation,
    },
  } = useSelector(pageDataSel);

  const {
    reservationCode,
    groupReservationGUID,
    sessionCode,
    isCancelled,
    isReservationContact,
    isAdditionalReservationContact,
    pendingContact,
    isRegistrantCurrentUser,
  } = groupReservation;

  const isARCSection = sectionId === sections.additionalReservationContact.key;
  const isForARContact =
    sectionId === sections.pendingReservationContact.key && pendingContact?.position === 'ARC';
  const rcReservationCode =
    'reservationCode' in reservationContact && reservationContact.reservationCode
      ? reservationContact.reservationCode
      : undefined;

  const canAccessAttendeeDetails = useSelector(
    createAbilitiesSelector('attendeeDetails', PermissionAction.Read, {
      reservationContact,
      groupReservation,
    }),
  );

  const allowRCEdit = useMemo(() => {
    if (isCreator || isCollaborator || isSystemAdministrator) return true;
    if (isCancelled) return false;
    if (isReservationContact || isAdditionalReservationContact) return true;
    return false;
  }, [
    isCreator,
    isCollaborator,
    isCancelled,
    isReservationContact,
    isAdditionalReservationContact,
    isSystemAdministrator,
  ]);

  const sendInvitationInProgress = useSelector(sendGroupReservationRCInviteInProgressSel);

  const cancelInvitationInProgress = useSelector(
    cancelGroupReservationContactInvitationInProgressSel,
  );

  const reservationContactPrimaryPhone = useMemo(() => {
    const { phones } = reservationContact;

    const phone = phones.find(({ source }) => source === 'EMS') ?? phones[0];

    if (phone) {
      const { phoneCountryCode, phoneAreaCode, phoneExchange, phoneLineNumber } = phone;

      const countryCode = phoneCountryCode ? `+${phoneCountryCode} ` : '';
      const areaCode = phoneAreaCode ? `${phoneAreaCode}-` : '';
      const exchange = phoneExchange ? `${phoneExchange}-` : '';

      return `${countryCode}${areaCode}${exchange}${phoneLineNumber}`;
    }
  }, [reservationContact]);

  const createOpenUpdateRCModalHandler = useCallback(
    (isInvitingAdditionalRC?: boolean) => () => {
      const modalParams: ModalParams = {
        groupReservationGUID,
        sessionCode,
        isAdditionalRC: isARCSection,
        isInvitingAdditionalRC,
        isRegistrantCurrentUser,
      };
      dispatch(openModal(UPDATE_RESERVATION_CONTACT_MODAL, modalParams));
    },
    [dispatch, groupReservationGUID, isARCSection, isRegistrantCurrentUser, sessionCode],
  );

  const handleResendInvitation = () => {
    dispatch(
      updateGroupReservationContact.request({
        sendInvitationEmail: true,
        data: {
          personGUID: get(reservationContact, ['personGUID']),
          lastName: get(reservationContact, ['lastName']),
          sessionCode,
          formCode,
          groupReservationGUID,
          destination: isForARContact ? 'ARC' : 'RC',
        },
      }),
    );
  };

  const handleCancelInvitation = () => {
    dispatch(
      cancelGroupReservationContactInvitation.request({
        groupReservationGUID,
        sessionCode,
        reservationContact,
      }),
    );
  };

  const isInvitationSent = get(reservationContact, ['isInvitationSent'], false);

  const renderDropdown = useCallback(() => {
    if (!allowRCEdit) return null;

    function handleViewReservationContact() {
      if (rcReservationCode) {
        const { formRecordGUID } = reservationContact;
        dispatch(
          navigateToGroupReservationAttendeeDetails({
            formCode,
            groupReservationGUID,
            formRecordGUID,
          }),
        );
      }
    }

    const dropDownMenuItems = [];

    if (status === RCStatus.ACTIVE && !pendingContact?.firstName) {
      dropDownMenuItems.push({
        key: 'changeReservationContact',
        label: 'Change Reservation Contact',
        icon: <AiOutlineSwap />,
        onClick: createOpenUpdateRCModalHandler(),
      });
    }

    if (rcReservationCode && canAccessAttendeeDetails) {
      dropDownMenuItems.push({
        key: 'viewAttendeeDetails',
        label: 'View Attendee Details',
        icon: <BsFillPersonFill />,
        onClick: handleViewReservationContact,
      });
    }

    if (!dropDownMenuItems.length) return null;

    return <Dropdown key="reservationContactMenu" menuItems={dropDownMenuItems} />;
  }, [
    allowRCEdit,
    status,
    pendingContact,
    rcReservationCode,
    canAccessAttendeeDetails,
    dispatch,
    formCode,
    groupReservationGUID,
    createOpenUpdateRCModalHandler,
    reservationContact,
  ]);

  const renderRemoveAdditionalRCButton = useCallback(() => {
    if (!isARCSection) return null;

    const handleRemoveAdditionalRC = () => {
      dispatch(
        removeAdditionalReservationContact({
          personGUID: get(reservationContact, ['personGUID']),
          formCode,
          groupReservationGUID,
          sessionCode,
          destination: 'ARC',
          action: 'REMOVE',
        }),
      );
    };

    return (
      <AdditionalRCActionButton
        icon={<DeleteOutlined />}
        type="text"
        onClick={handleRemoveAdditionalRC}
      />
    );
  }, [isARCSection, dispatch, formCode, groupReservationGUID, reservationContact, sessionCode]);

  return (
    <Section
      {...rest}
      key={key}
      header={header}
      headerButtons={[renderDropdown(), renderRemoveAdditionalRCButton()]}
      headerActions={
        <Fragment>
          {isInvitationSent && allowRCEdit && (
            <ResendInvite
              onResendInvite={handleResendInvitation}
              onCancelInvite={handleCancelInvitation}
              invitationSentDate={get(reservationContact, ['invitationSentDate'])}
            />
          )}
          {allowRCEdit && canAddARC && (
            <AdditionalRCActionButton onClick={createOpenUpdateRCModalHandler(true)}>
              <MdAddCircleOutline />
              Add Contact
            </AdditionalRCActionButton>
          )}
        </Fragment>
      }
    >
      <Spin spinning={sendInvitationInProgress || cancelInvitationInProgress}>
        <InfoItem
          header="Contact Name"
          info={
            <ReservationContactNameWrapper>
              <span>{`${reservationContact.firstName} ${reservationContact.lastName}`}</span>
              {status !== RCStatus.ACTIVE && (
                <Tag color="orange">{get(statusLabel, [status], '')}</Tag>
              )}
            </ReservationContactNameWrapper>
          }
        />
        {(isCreator || isCollaborator) && (
          <InfoItem header="Address" info={reservationContact.address.addressLine1} />
        )}
        <InfoItem header="Email" info={reservationContact.emailAddress} />
        <InfoItem
          header="Primary Phone"
          info={reservationContactPrimaryPhone && formatPhone(reservationContactPrimaryPhone)}
        />
        {pendingRCPositionLabel && <InfoItem header="Position" info={pendingRCPositionLabel} />}
        {(isCreator || isCollaborator) && rcReservationCode && (
          <React.Fragment>
            <InfoItem header="Personal Reservation Code" info={rcReservationCode} />
            <InfoItem header="Group Reservation Code" info={reservationCode} />
          </React.Fragment>
        )}
      </Spin>
    </Section>
  );
};
export default ReservationContactInformation;
