import { Collapse as AntCollapse } from 'antd';
import styled from 'styled-components';

import SearchInputShared from '@/modules/shared/components/SearchInput';
import colors from '@/modules/styles/colors';

import CardWithHeader from 'SHARED/components/CardWithHeader';

export const StyledCard = styled(CardWithHeader)`
  margin-top: 0;
  & .ant-card-body {
    padding: 0;
  }
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: ${colors.complementColor};
  padding: 0 1.5em;
`;

export const Collapse = styled(AntCollapse)`
  border-radius: 0;
`;

export const SearchInput = styled(SearchInputShared)`
  max-width: 300px;
  padding: 0;
  border-radius: 4px;
  display: flex;
  flex: 1;
  background: white;

  input {
    border: none;
  }
`;
