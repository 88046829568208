import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import { PermissionAction } from '@/modules/entities/Roles/constants';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { isStaffEventSel } from '@/pages/createEvent/duck/selectors';
import { pageDataSel } from '@/pages/reservation/duck/selectors';

import Activities from './components/Activities';
import Addons from './components/Addons';
import AssignHiringManager from './components/AssignHiringManager';
import AttendeeFormData from './components/AttendeeFormData';
import Attributes from './components/Attributes';
import JobPrioritiesList from './components/JobPrioritiesList';
import PrimaryRegistrant from './components/PrimaryRegistrant';
import ProfileInformation from './components/ProfileInformation';
import RegistrationInfo from './components/RegistrationInfo';

const AttendeeDetails: React.FC = () => {
  const {
    data: { form, groupReservation, attendeeDetails },
  } = useSelector(pageDataSel);

  const [editingSectionName, setEditingSectionName] = useState<string | null>(null);
  const isStaffEvent: boolean = useSelector(isStaffEventSel);
  const { allowGroupRegistration } = form;

  const { formRecordGUID } = attendeeDetails;

  const isAllowedToEditAttendee = useSelector(
    createAbilitiesSelector('attendeeDetails', PermissionAction.Update, {
      attendeeDetails,
      groupReservationDetails: groupReservation,
    }),
  );

  return (
    <Fragment>
      <ProfileInformation
        attendeeDetails={attendeeDetails}
        canEdit={isAllowedToEditAttendee}
        editingSectionName={editingSectionName}
        onEdit={setEditingSectionName}
      />
      {attendeeDetails.primaryRegistrantFormRecordGUID && (
        <PrimaryRegistrant attendeeDetails={attendeeDetails} />
      )}
      <RegistrationInfo
        canEditAttendeeDetails={isAllowedToEditAttendee}
        editingSectionName={editingSectionName}
        onEdit={setEditingSectionName}
      />
      <Attributes
        canEditAttendeeDetails={isAllowedToEditAttendee}
        editingSectionName={editingSectionName}
        onEdit={setEditingSectionName}
      />
      {!allowGroupRegistration && isStaffEvent && (
        <Fragment>
          <JobPrioritiesList />
          <AssignHiringManager
            editingSectionName={editingSectionName}
            onEdit={setEditingSectionName}
          />
        </Fragment>
      )}
      <AttendeeFormData onEdit={setEditingSectionName} editingSectionName={editingSectionName} />
      {!allowGroupRegistration && (
        <Fragment>
          <Addons />
          <Activities formRecordGUID={formRecordGUID} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default AttendeeDetails;
