import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { moduleName as attendeesAttributesModalModuleName } from '../components/AttendeesAttributesModal/constants';
import attendeeAttributesModalReducers from '../components/AttendeesAttributesModal/duck/reducers';

import { checkIn } from './actions';

const checkInInprogress = createReducer(false)
  .handleAction(checkIn.request, () => true)
  .handleAction([checkIn.success, checkIn.failure], () => false);

export default combineReducers({
  [attendeesAttributesModalModuleName]: attendeeAttributesModalReducers,
  checkInInprogress,
});
