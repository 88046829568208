import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { pageDataSel } from '../../duck/selectors';

type Props = {
  isCancelled: boolean;
  onCancelReservation: () => void;
};

const CancelButton: React.FC<Props> = ({ isCancelled, onCancelReservation }) => {
  const {
    data: { form },
  } = useSelector(pageDataSel);
  const { isCreator, isCollaborator } = form;

  if (!isCreator && !isCollaborator) return null;
  if (isCancelled) return null;

  return (
    <div className="text-center mb-6 mt-6">
      <Button type="primary" danger onClick={onCancelReservation}>
        Cancel Reservation
      </Button>
    </div>
  );
};

export default CancelButton;
