import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';

import LoginComponent from '../LoginComponent';

import { ContinueAsNewRegistrantButton, LoginWrapper } from './LoginModal.styled';

const LoginModal: React.FC = () => {
  const dispatch = useDispatch();
  const screenSize = useGetScreenSize();
  const { disableLocalUsersRegistration } = useSelector(createDataSel('form'));

  const handleClickNewRegistrantButton = () => {
    dispatch(closeModal());
  };

  return (
    <ModalComponent>
      <LoginWrapper>
        <LoginComponent hideSignInWithCode={disableLocalUsersRegistration} />
        {!disableLocalUsersRegistration && (
          <ContinueAsNewRegistrantButton
            ghost
            id="user.LoginPage.continueAsNewRegistrant"
            $isMobile={screenSize === 'mobile'}
            onClick={handleClickNewRegistrantButton}
          >
            Continue as New Registrant
          </ContinueAsNewRegistrantButton>
        )}
      </LoginWrapper>
    </ModalComponent>
  );
};

export default LoginModal;
