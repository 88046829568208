import { combineEpics, Epic } from 'redux-observable';
import { concat, defer, EMPTY } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { SESSION_SORT_ORDER_FIELD } from '@/modules/entities/Sessions/constants';
import { getSessionSortParams } from '@/modules/entities/Sessions/utils';
import toastService from '@/modules/toasts/service';
import csvDownload from '@/modules/utils/csvDownload';

import { SESSION_CSV_REPORT_DATA_ID } from '../constants';
import { generateSessionsCsv } from '../utils';

import { generateReimportableSessionCsv } from './actions';

const generateReimportableSessionCsv$: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(generateReimportableSessionCsv)),
    switchMap(() => {
      const {
        formCode,
        formName,
        sessionsListSortType = '',
        allowGroupRegistration,
      } = createDataSel('form')(state$.value);
      return concat(
        prefetchData$(action$, {
          dataType: 'sessionList',
          dataId: SESSION_CSV_REPORT_DATA_ID,
          queryObj: {
            formCode,
            [SESSION_SORT_ORDER_FIELD]: getSessionSortParams(sessionsListSortType),
            isShowAllSessions: true,
          },
          fetchData: true,
        }),
        defer(() => {
          const sessions =
            createDataSel('sessionList', SESSION_CSV_REPORT_DATA_ID)(state$.value) || [];
          const sessionReportCsvString = generateSessionsCsv(sessions, allowGroupRegistration);
          csvDownload(sessionReportCsvString, `${formName} re-importable sessions`);
          return EMPTY;
        }),
      );
    }),
    catchError((e: Error) => {
      toastService.error(e.message);
      return EMPTY;
    }),
  );

export default combineEpics(generateReimportableSessionCsv$);
