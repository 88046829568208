import { Form } from 'antd';
import styled from 'styled-components';

import DatePicker from '@/modules/shared/components/DatePicker';

export const DateTimePicker = styled(DatePicker)`
  &&&.bsa-date-picker {
    width: 100%;
  }
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-control {
    line-height: normal;
  }
  .ant-form-item-explain-error {
    color: red;
  }
`;
