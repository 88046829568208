import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { LoginData } from '../types';

import {
  logout,
  savePersonData,
  login,
  masquerade,
  saveLoginData,
  googleLogin,
  appleLogin,
} from './actions';

const loginInProgress = createReducer(false)
  .handleAction([login.request, googleLogin, appleLogin], () => true)
  .handleAction([login.success, login.failure], () => false);

const loginData = createReducer<LoginData | null>(null).handleAction(
  saveLoginData,
  (_state, { payload }) => payload,
);

const masqueradeInProgress = createReducer(false)
  .handleAction(masquerade.request, () => true)
  .handleAction([masquerade.success, masquerade.failure], () => false);

const isUpdatingPerson = createReducer(false)
  .handleAction([savePersonData.request], () => true)
  .handleAction([savePersonData.success, savePersonData.failure], () => false);

const logoutInProgress = createReducer(false)
  .handleAction(logout.request, () => true)
  .handleAction([logout.success, logout.failure], () => false);

export default combineReducers({
  loginInProgress,
  loginData,
  masqueradeInProgress,
  isUpdatingPerson,
  logoutInProgress,
});
