import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { AttendeeType } from '@/modules/data/dataTypes/attendeeTypeList';

import {
  deleteAttendeeType,
  updateAttendeeType,
  updateAttendeeTypeClosed,
  updateAttendeeTypeOpened,
} from './actions';

const updatedAttendeeType = createReducer<AttendeeType | Record<string, never> | null>(null)
  .handleAction(updateAttendeeTypeOpened, (_, { payload }) => payload)
  .handleAction(updateAttendeeTypeClosed, () => null);

const updateAttendeeTypeInProgress = createReducer(false)
  .handleAction(updateAttendeeType.request, () => true)
  .handleAction([updateAttendeeType.success, updateAttendeeType.failure], () => false);

const deleteAttendeeTypeInProgress = createReducer(false)
  .handleAction(deleteAttendeeType.request, () => true)
  .handleAction([deleteAttendeeType.success, deleteAttendeeType.failure], () => false);

export default combineReducers({
  updatedAttendeeType,
  updateAttendeeTypeInProgress,
  deleteAttendeeTypeInProgress,
});
