import DOMPurify from 'dompurify';
import { filter, flatten, flow, get, pickBy, zip } from 'lodash';
import { map as mapFP, pick as pickFP } from 'lodash/fp';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  Descendant,
  Editor,
  Element,
  LinkElement,
  Range,
  Transforms,
  Node,
  VariableElement,
  IndentationElement,
  ParagraphElement,
  Point,
} from 'slate';
import { jsx } from 'slate-hyperscript';

import RenderNodeList from './components/RenderNodeList';
import {
  ElementType,
  elementTypesByTag,
  LeafType,
  leafTypesByTag,
  leafStyles as leafStylesArr,
  initialValue,
  FontSize,
} from './constants';
import { TrimmedVariable } from './types';

export const getFormat = (editor: Editor, format: LeafType) => {
  const marks = Editor.marks(editor);
  let activeFormat = get(marks, format, null);

  if (activeFormat) return activeFormat;

  const variableNodes = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === ElementType.variable,
  });

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { value, done } = variableNodes.next();
    activeFormat = get(value, [0, format], null);
    if (activeFormat || done) break;
  }

  return activeFormat;
};

export const isBlockActive = (editor: Editor, format: LeafType | ElementType) => {
  const { selection } = editor;

  if (!selection) return false;
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
    }),
  );

  return !!match;
};

export const getLinkNodeEntry = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === ElementType.link,
  });
  return link;
};

export const isLinkActive = (editor: Editor) => !!getLinkNodeEntry(editor);

export const addMark = (editor: Editor, format: LeafType, value: FontSize | string | true) => {
  Editor.addMark(editor, format, value);
};

export const removeMark = (editor: Editor, format: LeafType) => {
  Editor.removeMark(editor, format);
};

export const toggleMark = (editor: Editor, format: LeafType) => {
  const isActive = getFormat(editor, format);
  if (isActive) {
    removeMark(editor, format);
  } else {
    addMark(editor, format, true);
  }
};

export const toggleBlock = (
  editor: Editor,
  format: ElementType.orderedList | ElementType.unorderedList,
) => {
  const listTypes = [ElementType.orderedList, ElementType.unorderedList];
  const isActive = isBlockActive(editor, format);
  const isList = listTypes.includes(format);
  const theFormat = isList ? ElementType.listItem : format;
  const nextFormat = isActive ? ElementType.paragraph : theFormat;

  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor && Element.isElement(n) && listTypes.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: nextFormat,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === ElementType.link,
  });
};

export const wrapLink = (editor: Editor, url: string, title?: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const link: LinkElement = {
    type: ElementType.link,
    url,
    children: [{ text: title || url }],
  };

  const { selection } = editor;

  if (!selection) return null;

  const isCollapsed = Range.isCollapsed(selection);

  if (!isCollapsed) {
    Transforms.delete(editor, { at: selection });
    Transforms.collapse(editor, { edge: 'start' });
  }
  Transforms.insertNodes(editor, link);
};

export const addIndentation = (editor: Editor) => {
  const block: IndentationElement = {
    type: ElementType.indentation,
    children: [],
  };

  Transforms.wrapNodes(editor, block);
};

export const removeIndentation = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === ElementType.indentation,
  });
};

export const createEditLink = (editor: Editor, url: string, title: string) => {
  const { selection } = editor;
  if (selection) {
    wrapLink(editor, url, title);
  }
};

export const insertVariable = (editor: Editor, variable: TrimmedVariable) => {
  const { caption, value } = variable;
  const element: VariableElement = {
    type: ElementType.variable,
    value,
    caption,
    children: [{ text: value }],
  };
  Transforms.insertNodes(editor, element);
  Transforms.move(editor);
};

export const setValue = (editor: Editor, value?: Node | Node[]): void => {
  const cachedSelection = editor.selection;
  const children = [...editor.children];

  children.forEach(node => {
    editor.apply({ type: 'remove_node', path: [0], node });
  });

  if (value) {
    const nodes = Node.isNode(value) ? [value] : value;

    nodes.forEach((node, i) => {
      editor.apply({ type: 'insert_node', path: [i], node });
    });
  }

  if (cachedSelection && Point.isBefore(cachedSelection.anchor, Editor.end(editor, []))) {
    Transforms.select(editor, cachedSelection);
    return;
  }
  Transforms.select(editor, Editor.end(editor, []));
};

const variablesToObject = (variables: TrimmedVariable[]) =>
  variables.reduce(
    (acc, { caption, value }) => ({
      ...acc,
      [value]: caption,
    }),
    {} as Record<string, string>,
  );

export const toString = (value: Descendant[]) => value.map(Node.string).join(' ');

export const fromString = (str: string, variables: TrimmedVariable[]) => {
  const children = deserializeString(str, variablesToObject(variables));
  return [
    {
      type: ElementType.paragraph,
      children: flatten([children]),
    },
  ];
};

export const toHTML = (value: Descendant[]) =>
  DOMPurify.sanitize(ReactDOMServer.renderToStaticMarkup(<RenderNodeList nodes={value} />));

const deserializeString = (
  str: string,
  variablesObj: Record<string, string>,
  params = {},
): Descendant => {
  const isVariable = /^\{\{[^{}]+\}\}$/g.test(str);
  if (isVariable) {
    return jsx(
      'element',
      {
        type: ElementType.variable,
        value: str,
        caption: variablesObj[str],
        ...params,
      },
      [{ text: str }],
    );
  }

  const varRegExp = /\{\{[^{}]+\}\}/g;
  const foundVariables = str.match(varRegExp);

  if (!foundVariables) {
    return jsx('text', params, str);
  }

  const withoutVariables = str.split(varRegExp);

  return flow([
    zip,
    flatten,
    filter,
    mapFP((item: string) => deserializeString(item, variablesObj, params)),
  ])(withoutVariables, foundVariables);
};

const deserialize = (
  el: HTMLElement | string,
  variablesObj: Record<string, string>,
  params = {},
): Descendant | Descendant[] => {
  if (typeof el === 'string') return deserializeString(el, variablesObj, params);
  if (el.nodeType === 3) return deserialize(el.textContent as string, variablesObj, params);
  if (el.nodeType !== 1) return null as unknown as Descendant;

  const leafType: LeafType | undefined =
    leafTypesByTag[el.nodeName.toLowerCase() as keyof typeof leafTypesByTag];
  const leafStyles =
    el.nodeName === 'SPAN' ? flow([pickFP(leafStylesArr), pickBy])(el.style) : null;
  const isLeafNode = leafType || leafStyles || el.nodeName === 'SPAN';

  const children = Array.from(el.childNodes).map(item =>
    deserialize(item as HTMLElement, variablesObj, {
      ...params,
      ...leafStyles,
      ...(leafType ? { [leafType]: true } : {}),
    }),
  );

  if (isLeafNode)
    return children && children.length ? (children as Descendant[]) : jsx('text', params, '');

  const elementType: ElementType | undefined =
    elementTypesByTag[el.nodeName.toLowerCase() as keyof typeof elementTypesByTag];
  if (elementType) {
    return jsx('element', { type: elementType, ...params }, children);
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return '\n' as unknown as Descendant;
    case 'A':
      return jsx(
        'element',
        { type: ElementType.link, url: el.getAttribute('href'), ...params },
        children,
      );
    default:
      return deserialize(el.textContent as string, variablesObj, params);
  }
};

export const fromHTML = (htmlStr: string, variables: TrimmedVariable[] = []): Descendant[] => {
  const doc = new DOMParser().parseFromString(htmlStr, 'text/html');
  const { body } = doc;
  if (!body.firstChild) return initialValue;
  if (!body.childElementCount) {
    return [
      {
        type: ElementType.paragraph,
        children: [{ text: body.firstChild.textContent as string }],
      },
    ];
  }

  const deserializedValue = deserialize(body, variablesToObject(variables));

  const notWrappedInElement = !!get(deserializedValue, [0, 'text']);
  if (notWrappedInElement) {
    return [
      {
        type: ElementType.paragraph,
        children: deserializedValue,
      } as ParagraphElement,
    ];
  }

  return deserializedValue as Descendant[];
};

export const isEmptyValue = (value?: Element[]): boolean => {
  if (!value) return true;
  if (value.length > 1) return false;
  if (value[0].children.length > 1) return false;
  const firstChild = value[0].children[0];
  if ('text' in firstChild) {
    return !firstChild.text;
  }
  return false;
};
