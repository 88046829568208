import { Tabs as AntTabs } from 'antd';
import styled from 'styled-components';

import GoBackLink from 'SHARED/components/GoBackLink';

export const Tabs = styled(AntTabs)`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
    background-color: white;
  }
  .ant-tabs-bar {
    margin: 0;
  }
` as typeof AntTabs;

export const GoBack = styled(GoBackLink)`
  max-width: fit-content;
  margin: 20px 0 0 0;
`;
