import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { forwardRef, Fragment, useCallback, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Person } from '@/modules/data/dataTypes/person';
import { removeData } from '@/modules/data/duck/actions';
import { createDataSel, createIsLoadingSel } from '@/modules/data/duck/selectors';
import CardWithHeader from '@/modules/shared/components/CardWithHeader';
import FindPersonForm from '@/modules/shared/components/FindPersonForm';
import { FoundPeopleQuery } from '@/modules/shared/components/FindPersonForm/types';
import Label from '@/modules/shared/components/Label';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import PersonalInfoForm from '@/modules/shared/components/PersonalInfoForm';
import { IndividualReservationPersonFormData } from '@/modules/shared/components/PersonalInfoForm/types';

import { collectFormParts, collectRegistrationData } from '../../duck/actions';

import {
  MyScoutingRequiredMessage,
  MyScoutingRequiredMessageContainer,
} from './FindPersonStep.styled';
import { findExistingRegistrant, updateFoundPerson, updatePersonExists } from './duck/actions';
import { isRegistrantCurrentUserSel, personExistsSel } from './duck/selectors';

const FindPersonStep = forwardRef<{ handleSubmit: () => void }>((_, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const personDataRef = useRef<{
    handleSubmit: () => Promise<IndividualReservationPersonFormData>;
  }>(null);
  const renderFoundPersonRef = useRef(null);

  const dispatch = useDispatch();
  const personExists = useSelector(personExistsSel);
  const person: Person | undefined = useSelector(createDataSel('arnicaPerson'));
  const isRegistrantCurrentUser = useSelector(isRegistrantCurrentUserSel);
  const { disableLocalUsersRegistration } = useSelector(createDataSel('form'));

  const findPersonInProgress = useSelector(createIsLoadingSel('foundPeople'));
  const fetchPersonInProgress = useSelector(createIsLoadingSel('arnicaPerson'));

  function handleSubmit() {
    personDataRef.current
      ?.handleSubmit()
      .then(({ formParts, individualRegistrationInitialSettings, personData }) => {
        formParts && dispatch(collectFormParts(formParts));
        dispatch(collectRegistrationData(individualRegistrationInitialSettings));
        dispatch(updateFoundPerson(personData));
      });
  }

  const handleSetPersonExists = (e: RadioChangeEvent) => {
    dispatch(updatePersonExists(e.target.value));
    handleRemoveData();
  };

  const handleRemoveData = () => {
    dispatch(removeData({ dataType: 'foundPeople' }));
    dispatch(removeData({ dataType: 'arnicaPerson' }));
  };

  const handleFindPersonSearch = (foundPeopleQuery: FoundPeopleQuery) => {
    dispatch(findExistingRegistrant(foundPeopleQuery));
  };

  const renderPersonalInformation = useCallback(() => {
    const personalInformation = (
      <PersonalInfoForm
        key={`${person?.personGUID}${personExists}`}
        isGroup={false}
        ref={personDataRef}
      />
    );

    if (!disableLocalUsersRegistration) return personalInformation;

    if (!person) {
      return (
        <MyScoutingRequiredMessageContainer>
          <MyScoutingRequiredMessage>
            Secondary registrant must have a valid my.scouting account
          </MyScoutingRequiredMessage>
        </MyScoutingRequiredMessageContainer>
      );
    }

    return personalInformation;
  }, [disableLocalUsersRegistration, person, personDataRef, personExists]);

  return (
    <LoadingContainer isLoading={findPersonInProgress || fetchPersonInProgress}>
      <CardWithHeader header="Registering Someone Else">
        {!disableLocalUsersRegistration && (
          <Label label="Does this person already have an account on Events.Scouting.org or My.Scouting.org?">
            <Radio.Group value={personExists} onChange={handleSetPersonExists}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Label>
        )}
        {personExists && (
          <Fragment>
            <FindPersonForm
              isEventOwner={isRegistrantCurrentUser}
              renderFoundPersonRef={renderFoundPersonRef}
              formLayout="inline"
              onResetForm={handleRemoveData}
              onSearchChange={handleFindPersonSearch}
            />
            <div ref={renderFoundPersonRef} />
          </Fragment>
        )}
      </CardWithHeader>
      {renderPersonalInformation()}
    </LoadingContainer>
  );
});

export default FindPersonStep;
