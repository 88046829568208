import { Collapse as AntCollapse } from 'antd';
import styled from 'styled-components';

export const Collapse = styled(AntCollapse)`
  border: none;
  background-color: transparent;

  .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
`;
