import { RegistrantTypes } from '@/modules/data/dataTypes/attendeeTypeList';

import { moduleName as parentModuleName } from '../../constants';

export const moduleName = 'attendeeTypes';
export const modulePath = [parentModuleName, moduleName];

export const registrantTypesOptions = [
  { label: 'Primary registrants', value: RegistrantTypes.Primary },
  { label: 'Secondary registrants', value: RegistrantTypes.Secondary },
];

export const defaultRegistrantTypes = [RegistrantTypes.Primary, RegistrantTypes.Secondary];

export const genderOptions = [
  { label: 'Male', value: 'm' },
  { label: 'Female', value: 'f' },
];

export const programOptions = [
  { value: '1', label: 'Cub Scouting' },
  { value: '2', label: 'Scouts BSA' },
  { value: '4', label: 'Venturing' },
  { value: '5', label: 'Sea Scouting' },
  { value: '6', label: 'Exploring Post' },
  { value: '10', label: 'STEM Scouts' },
];

enum AttendeeCountryLabels {
  Any = 'Any Country',
  International = 'International Only',
  USA = 'USA Only',
}

enum AttendeeCountryValues {
  Any = 'any',
  International = 'international',
  USA = 'usa',
}

export const attendeeCountryOptions = [
  { label: AttendeeCountryLabels.Any, value: AttendeeCountryValues.Any },
  { label: AttendeeCountryLabels.International, value: AttendeeCountryValues.International },
  { label: AttendeeCountryLabels.USA, value: AttendeeCountryValues.USA },
];

export type CountryValidation = keyof typeof AttendeeCountryValues;
