import { Epic, combineEpics } from 'redux-observable';
import { EMPTY, concat, filter, of, switchMap } from 'rxjs';
import { RootAction, RootState, isActionOf } from 'typesafe-actions';

import { openStep } from './actions';

const openStep$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(openStep.request)),
    switchMap(({ payload: { moduleName, getObservable } }) =>
      concat(
        getObservable ? getObservable(action$, state$) : EMPTY,
        of(openStep.success(moduleName)),
      ),
    ),
  );

export default combineEpics(openStep$);
