import { Pagination } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Attendee } from '@/modules/data/dataTypes/attendeeList';
import { refreshData, updatePageNumber } from '@/modules/data/duck/actions';
import { createPaginationSel } from '@/modules/data/duck/selectors';
import { cancelIndividualReservation } from '@/modules/entities/Reservations/duck/actions';
import { cancelIndividualReservationInProgressSel } from '@/modules/entities/Reservations/duck/selectors';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import { formStatus } from '@/modules/shared/constants';

import { RegistrantsWrapper } from './RegistrantsList.styled';
import Registrant from './components/Registrant';

type Props = {
  formRecords: Attendee[];
};

const RegistrantsList: React.FC<Props> = ({ formRecords }) => {
  const dispatch = useDispatch();
  const cancelIndividualReservationInProgress = useSelector(
    cancelIndividualReservationInProgressSel,
  );
  const { pageNumber, pageSize, recordCount } =
    useSelector(createPaginationSel('attendeeList')) || {};

  const handlePageChange = (nextPageNumber: number) => {
    dispatch(updatePageNumber({ dataType: 'attendeeList', pageNumber: nextPageNumber }));
  };

  const createCancelRegistrantHandler = (formRecord: Attendee) => () => {
    const { formRecordGUID, personGUID } = formRecord;
    dispatch(
      cancelIndividualReservation.request({
        personGUID,
        formRecordGUID,
        cancellationReason: formStatus.attendee_cancelled.name,
        cancellationStatusCode: formStatus.attendee_cancelled.code,
        sendNotification: false,
        finalActions: [refreshData({ dataType: 'attendeeList' })],
      }),
    );
  };

  return (
    <LoadingContainer isLoading={cancelIndividualReservationInProgress}>
      <RegistrantsWrapper expandIconPosition="end" destroyInactivePanel accordion>
        {formRecords.map(formRecord => {
          const { formRecordGUID } = formRecord;
          return (
            <Registrant
              key={formRecordGUID}
              formRecord={formRecord}
              onCancel={createCancelRegistrantHandler(formRecord)}
            />
          );
        })}
      </RegistrantsWrapper>
      <Pagination
        pageSize={pageSize}
        current={pageNumber}
        total={recordCount}
        onChange={handlePageChange}
      />
    </LoadingContainer>
  );
};

export default RegistrantsList;
