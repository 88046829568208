import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { GOOGLE_SIGN_IN_CLIENT_ID } from '@/modules/config/config';
import { googleLogin } from '@/modules/user/duck/actions';

import { ButtonContainer } from './GoogleSignIn.styled';

const GoogleSignIn = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: GOOGLE_SIGN_IN_CLIENT_ID as string,
      callback: ({ credential }) => {
        dispatch(googleLogin(credential));
      },
    });

    if (ref.current) {
      google.accounts.id.renderButton(ref.current, {
        type: 'standard',
        theme: 'outline',
        text: 'signin_with',
        shape: 'rectangular',
      });
    }
  }, [dispatch]);

  return <ButtonContainer ref={ref} />;
};

export default GoogleSignIn;
