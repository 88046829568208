import { LeftOutlined } from '@ant-design/icons';
import React, { PropsWithChildren } from 'react';

import styles from './BackButton.less';

type Props = {
  onClick: () => void;
};

const BackButton: React.FC<PropsWithChildren<Props>> = ({ onClick, children }) => (
  <div className={styles.backButton} onClick={onClick}>
    <LeftOutlined />
    {children}
  </div>
);

export default BackButton;
