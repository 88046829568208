import { Alert } from 'antd';
import React from 'react';

import {
  MAX_ORBITAL_PAYMENT_AMOUNT,
  PAYMENT_METHODS_WITH_LIMIT,
} from '@/modules/entities/Payments/constants';
import { PaymentMethod } from '@/modules/payments/constants';

type Props = {
  amount: number;
  paymentMethod: PaymentMethod | null;
};

const MaxAmountInfo: React.FC<Props> = ({ amount, paymentMethod }) => {
  if (
    !paymentMethod ||
    !PAYMENT_METHODS_WITH_LIMIT.has(paymentMethod) ||
    amount < MAX_ORBITAL_PAYMENT_AMOUNT
  )
    return null;

  return (
    <Alert
      showIcon
      closable
      type="info"
      message="Maximum allowed single-payment value for selected payment method is $25,000"
    />
  );
};

export default MaxAmountInfo;
