import { createAsyncAction } from 'typesafe-actions';

import { Job } from '@/modules/data/dataTypes/jobList';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName as builderModule } from '../../../constants';
import { moduleName } from '../constants';

const namespace = `${builderModule}/${moduleName}`;

export const addJob = createAsyncAction(
  `${namespace}/ADD_JOB_REQUEST`,
  `${namespace}/ADD_JOB_SUCCESS`,
  `${namespace}/ADD_JOB_FAILURE`,
)<Partial<Job>, undefined, ApiError>();

export const deleteJob = createAsyncAction(
  `${namespace}/DELETE_JOB_REQUEST`,
  `${namespace}/DELETE_JOB_SUCCESS`,
  `${namespace}/DELETE_JOB_FAILURE`,
)<string, undefined, ApiError>();
