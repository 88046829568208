import cn from 'classnames';
import React from 'react';

import styles from './InvitationStatusCell.less';
import ResendInvite from './components/ResendInvite';
import {
  INVITATION_STATUS_CONFIRMED,
  INVITATION_STATUS_ACCEPTED,
  INVITATION_STATUS_PENDING,
  INVITATION_STATUS_INVITED,
  ADDED_WITHOUT_INVITATION,
} from './constants';

type Props = {
  statusName: string;
  invitationSentDate?: string;
  onResendInvite?: () => void;
  showStatus?: boolean;
  allowInvite?: boolean;
};

const InvitationStatusCell: React.FC<Props> = ({
  statusName,
  invitationSentDate,
  onResendInvite,
  allowInvite,
  showStatus = true,
}) => {
  const status = statusName.toLowerCase();

  const invitationPending = [INVITATION_STATUS_PENDING, INVITATION_STATUS_INVITED].includes(status);

  return (
    <div className={styles.invitationStatusWrapper}>
      {showStatus && (
        <div
          className={cn({
            [styles.invitationStatusConfirmed]: [
              INVITATION_STATUS_CONFIRMED,
              INVITATION_STATUS_ACCEPTED,
              ADDED_WITHOUT_INVITATION,
            ].includes(status),
            [styles.invitationStatusPending]: invitationPending,
          })}
        >
          {status.toUpperCase()}
        </div>
      )}
      {invitationPending && invitationSentDate && onResendInvite && allowInvite && (
        <ResendInvite onResendInvite={onResendInvite} invitationSentDate={invitationSentDate} />
      )}
    </div>
  );
};

InvitationStatusCell.defaultProps = {
  allowInvite: true,
};

export default InvitationStatusCell;
