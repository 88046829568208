import { Col, Form } from 'antd';
import React, { forwardRef, useImperativeHandle } from 'react';

import { Person } from '@/modules/data/dataTypes/person';

import { Row, Text } from './UnitAndCouncil.styled';
import OrganizationSelector from './components/OrganizationSelector';
import { UNIT_FIELD } from './constants';
import { useGetFilteredPositions } from './hooks';

type Props = {
  person: Person;
  initialUnitValue?: string;
};

type Values = {
  [UNIT_FIELD]: string;
};

const UnitAndCouncil = forwardRef<{ handleSubmit: () => Promise<Values> }, Props>(
  ({ person, initialUnitValue }, ref) => {
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));
    const [form] = Form.useForm<Values>();
    const filteredPositions = useGetFilteredPositions(person);

    const { councilLong } = person;

    function handleSubmit() {
      return form.validateFields();
    }

    return (
      <Form form={form}>
        <Row>
          <Col xs={24} sm={12} lg={12}>
            <Text id="yourCouncil">
              Council - <b>{councilLong}</b>
            </Text>
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <OrganizationSelector positions={filteredPositions} initialValue={initialUnitValue} />
          </Col>
        </Row>
      </Form>
    );
  },
);

export default UnitAndCouncil;
