import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

import styles from './PrioritySelector.less';

const suffix = ['st', 'nd', 'rd'];

const numberToOrdinal = (number: number) => {
  if (number === 0) return number;

  const aux = suffix[number - 1];

  if (aux) {
    return `${number}${aux}`;
  }

  return `${number}th`;
};

type Props = {
  position: number;
  allowMax: boolean;
  allowMin: boolean;
  onPlus: (position: number) => void;
  onMinus: (position: number) => void;
};

const PrioritySelector: React.FC<Props> = ({ allowMin, allowMax, position, onMinus, onPlus }) => {
  const handlePlus = () => {
    onPlus(position);
  };
  const handleMinus = () => {
    onMinus(position);
  };

  return (
    <div className={styles.container}>
      <Button
        type="primary"
        size="small"
        shape="circle"
        className={styles.button}
        disabled={!allowMin}
        onClick={handleMinus}
      >
        <ArrowDownOutlined />
      </Button>
      <strong>{numberToOrdinal(position + 1)} Choice </strong>
      <Button
        type="primary"
        size="small"
        shape="circle"
        className={styles.button}
        disabled={!allowMax}
        onClick={handlePlus}
      >
        <ArrowUpOutlined />
      </Button>
    </div>
  );
};

export default PrioritySelector;
