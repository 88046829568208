import { Checkbox, Form } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { useFoundPerson } from '@/modules/shared/components/FindPersonForm/hooks';
import toastService from '@/modules/toasts/service';

import { updateGroupReservationContact } from '@/pages/reservation/duck/actions';
import { sendGroupReservationRCInviteInProgressSel } from '@/pages/reservation/duck/selectors';

import styles from './UpdateReservationContactModal.less';
import type { ModalParams } from './types';

import FindPersonForm from 'SHARED/components/FindPersonForm';
import InputMaterial from 'SHARED/components/InputMaterial';
import Label from 'SHARED/components/Label';

type Props = {
  modalParams: ModalParams;
};

const EMAIL_ADDRESS_FIELD = 'emailAddress';

// eslint-disable-next-line import/no-unused-modules
export const UpdateReservationContactModal: React.FC<Props> = ({ modalParams }) => {
  const renderFoundPersonRef = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [sendInvitationEmail, setSendInvitationEmail] = useState(true);
  const { formCode, eventStartDate } = useSelector(createDataSel('form'));

  const isSendingInvite = useSelector(sendGroupReservationRCInviteInProgressSel);
  const foundPerson = useFoundPerson();
  const foundPersonEmailAddress = foundPerson?.emailAddress || foundPerson?.EMSEmailAddress;
  const { isAdditionalRC, isInvitingAdditionalRC, isRegistrantCurrentUser } = modalParams;

  const handleUpdateEmailRequired = (e: CheckboxChangeEvent) => {
    setSendInvitationEmail(e.target.checked);
  };

  useEffect(() => {
    form.resetFields();
  }, [foundPerson, form]);

  function handleSubmit() {
    if (!foundPerson) {
      return;
    }

    if (!foundPerson.ULUserName) {
      toastService.error(
        <span>
          This user cannot be assigned as a Reservation Contact because they do not have a
          my.scouting account. Please have the user go to{' '}
          <a href="http://my.scouting.org/" target="_blank" rel="noopener noreferrer">
            My.Scouting.org
          </a>{' '}
          and create an account using the same MID you provided, then try again.
        </span>,
      );
      return;
    }

    form.validateFields().then(({ [EMAIL_ADDRESS_FIELD]: nextEmailAddress }) => {
      const { personGUID } = foundPerson;
      const { groupReservationGUID, sessionCode } = modalParams;

      const data = {
        personGUID,
        formCode,
        groupReservationGUID,
        sessionCode,
      };

      if (sendInvitationEmail) {
        dispatch(
          updateGroupReservationContact.request({
            sendInvitationEmail,
            nextEmailAddress,
            data: {
              ...data,
              lastName: foundPerson.lastName,
              emailAddress: nextEmailAddress || foundPersonEmailAddress,
              destination: isAdditionalRC || isInvitingAdditionalRC ? 'ARC' : 'RC',
            },
          }),
        );
      } else {
        dispatch(
          updateGroupReservationContact.request({
            sendInvitationEmail,
            nextEmailAddress,
            data: {
              ...data,
              destination: isAdditionalRC || isInvitingAdditionalRC ? 'ARC' : 'RC',
              action: 'UPDATE',
            },
          }),
        );
      }
    });
  }

  return (
    <ModalComponent
      title={`${isInvitingAdditionalRC ? 'Invite Additional' : 'Change'} Reservation Contact`}
      buttons={[
        {
          title: 'Invite',
          onClick: handleSubmit,
          disabled: !foundPerson || isSendingInvite,
          type: 'primary',
          className: styles.button,
          size: 'small',
        },
      ]}
      inProgress={isSendingInvite}
      centerButtons
    >
      <h2 className={styles.subtitle}>{`Who will be the ${
        isInvitingAdditionalRC ? 'additional' : 'new'
      } Reservation Contact?`}</h2>
      <Label
        orientation="horizontal"
        label={`Require new RC to accept email invite before ${
          isInvitingAdditionalRC ? 'accepting' : 'changing'
        } ownership?`}
      >
        <Checkbox onChange={handleUpdateEmailRequired} checked={sendInvitationEmail} />
      </Label>
      <div className={styles.wrapper}>
        <FindPersonForm
          isEventOwner={isRegistrantCurrentUser}
          pivotDate={eventStartDate}
          personInfoTitle={`${
            isInvitingAdditionalRC ? 'Additional' : 'New'
          } Reservation Contact Info`}
          renderFoundPersonRef={renderFoundPersonRef}
        />
        <div ref={renderFoundPersonRef} />
        <Form form={form}>
          {foundPerson && (
            <Form.Item
              name={EMAIL_ADDRESS_FIELD}
              rules={[
                { required: !foundPersonEmailAddress, message: 'Email Address is required' },
                { type: 'email', message: 'Invalid email' },
              ]}
            >
              <InputMaterial
                labelContent={`Email Address${!foundPersonEmailAddress ? '*' : ''}`}
                labelSize="1"
                labelInlineBlock
                wrapperClassName={styles.input}
              />
            </Form.Item>
          )}
        </Form>
        <div ref={renderFoundPersonRef} />
      </div>
    </ModalComponent>
  );
};

export default UpdateReservationContactModal;
