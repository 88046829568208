import { Addon, AddonOption } from '@/modules/data/dataTypes/addons';
import apiService from '@/modules/utils/apiService';

const updateAddon$ = (
  addon: Omit<
    Addon,
    'addonCode' | 'addonSessionGUID' | 'addonOptions' | 'isMandatory' | 'positionNumber'
  > &
    Partial<Pick<Addon, 'addonCode'>> & { formCode: string },
) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateAddon',
    addon,
  );

const updateOption$ = (option: AddonOption & { formCode: string; addonCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateAddonOption',
    option,
  );

const deleteOption$ = (option: { formCode: string; addonCode: string; optionCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteAddonOption',
    option,
  );

const deleteAddon$ = (formCode: string, addonCode: string) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteAddon',
    {
      formCode,
      addonCode,
    },
  );

export default {
  updateAddon$,
  updateOption$,
  deleteOption$,
  deleteAddon$,
};
