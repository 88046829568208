import { difference, get } from 'lodash';
import { combineEpics, Epic } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData } from '@/modules/data/duck/actions';
import { refreshDataAndWait$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import roleServices from '@/modules/entities/Roles/duck/services';
import { closeModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import {
  assignCollaboratorRole,
  deleteRole,
  findPersonAndInvite,
  hideUpdateRoleForm,
  inviteCollaborator,
  removeCollaborator,
  updateRole,
} from './actions';
import services, { RemoveCollaboratorPayload } from './services';

const findPersonAndInvite$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(findPersonAndInvite)),
    switchMap(({ payload: { lastName, memberId, roleCodes } }) => {
      toastService.success('Sending invite...');

      return services.findPerson$(memberId, lastName).pipe(
        switchMap(({ payload }) => {
          const { formCode } = createDataSel('form')(state$.value);
          const personGUID = get(payload, [0, 'personGUID'], '');
          return services.sendCollaboratorInvite$({ formCode, personGUID, lastName }).pipe(
            switchMap(({ responseMessage }) => {
              toastService.success(responseMessage);
              return merge(
                of(closeModal()),
                roleCodes && roleCodes.length
                  ? concat(
                      refreshDataAndWait$(action$, { dataType: 'collaboratorList' }),
                      of(assignCollaboratorRole.request({ roleCodes, personGUID })),
                    )
                  : of(refreshData({ dataType: 'collaboratorList' })),
              );
            }),
          );
        }),
      );
    }),
    catchError((err, caught) => {
      toastService.error(err.message);
      return caught;
    }),
  );

const inviteCollaborator$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(inviteCollaborator)),
    switchMap(({ payload }) => {
      const { formCode } = createDataSel('form')(state$.value);
      return services.sendCollaboratorInvite$({ ...payload, formCode });
    }),
    map(() => refreshData({ dataType: 'collaboratorList' })),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return caught;
    }),
  );

const assignRoleCollaborator$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(assignCollaboratorRole.request)),
    switchMap(({ payload }) => {
      const { roleCodes, personGUID } = payload;

      const collaborators = createDataSel('collaboratorList')(state$.value);
      const currentCollaborator = collaborators.find(c => c.personGUID === personGUID);
      const currentCollaboratorRoleCodes =
        currentCollaborator?.roles.map(({ roleCode }) => roleCode) || [];
      const toAdd = difference(roleCodes, currentCollaboratorRoleCodes);
      const toDelete = difference(currentCollaboratorRoleCodes, roleCodes);

      const requests = [];

      if (toAdd.length) {
        requests.push(
          services.updateRolePerson$({ isGranted: true, personGUID, roleCodeList: toAdd }),
        );
      }

      if (toDelete.length) {
        requests.push(
          services.updateRolePerson$({ isGranted: false, personGUID, roleCodeList: toDelete }),
        );
      }

      const finalActions = of(assignCollaboratorRole.success(), closeModal());

      if (!requests.length) {
        return finalActions;
      }

      return zip(...requests).pipe(
        switchMap(() => merge(of(refreshData({ dataType: 'collaboratorList' })), finalActions)),
      );
    }),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return merge(of(assignCollaboratorRole.failure(error)), caught);
    }),
  );

const removeCollaborator$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeCollaborator.request)),
    switchMap(({ payload: personGUID }) => {
      const { formCode } = createDataSel('form')(state$.value);
      const servicePayload: RemoveCollaboratorPayload = {
        formCode,
        isGranted: false,
        personGUID,
      };
      return services.removeCollaborator$(servicePayload).pipe(
        switchMap(() => {
          toastService.success('Collaborator removed successfully');
          return of(refreshData({ dataType: 'collaboratorList' }), removeCollaborator.success());
        }),
      );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(removeCollaborator.failure(error)), caught);
    }),
  );

const updateRole$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateRole.request)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { description, permissions, roleCode, roleName } = payload;

      const { formCode } = createDataSel('form')(state);

      return roleServices
        .updateRole$({
          roleCode,
          roleName,
          description,
          formCode,
          permissions,
        })
        .pipe(
          switchMap(() => {
            toastService.success(`Role was successfully ${roleCode ? 'updated' : 'created'}`);
            return of(
              updateRole.success(),
              hideUpdateRoleForm(),
              refreshData({ dataType: 'formRolesList' }),
            );
          }),
          catchError((error: ApiError) => {
            toastService.error(error.message);
            return of(updateRole.failure(error));
          }),
        );
    }),
  );

const deleteRole$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(deleteRole.request)),
    switchMap(({ payload }) =>
      services.deleteRole$(payload).pipe(
        switchMap(() => {
          toastService.success('Role deleted successfully');
          return of(
            refreshData({ dataType: 'formRolesList' }),
            refreshData({ dataType: 'collaboratorList' }),
          );
        }),
      ),
    ),
    catchError((error: ApiError) => {
      toastService.error(error.message);
      return of(updateRole.failure(error));
    }),
  );

export default combineEpics(
  inviteCollaborator$,
  removeCollaborator$,
  updateRole$,
  findPersonAndInvite$,
  assignRoleCollaborator$,
  deleteRole$,
);
