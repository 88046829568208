import { Card } from 'antd';
import React from 'react';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';

type Props = {
  form: FormSettings;
} & (
  | { isGroup: true; groupReservation: GroupReservation }
  | { isGroup: false; attendeeDetails: AttendeeDetails }
);

const CancellationReason: React.FC<Props> = props => {
  const { form, isGroup } = props;
  const { isCreator, isCollaborator } = form;
  const { isCancelled, cancellationReason } = isGroup
    ? props.groupReservation
    : props.attendeeDetails;

  if (!isCreator && !isCollaborator) return null;
  if (!isCancelled || !cancellationReason) return null;

  return <Card title="Reason for Cancelling">{cancellationReason}</Card>;
};

export default CancellationReason;
