import { ComponentType, Fragment } from 'react';
import { NOT_FOUND } from 'redux-first-router';
import { StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { RootAction, RootState } from 'typesafe-actions';

import addAttendeeRoutes from '@/pages/addAttendee/routes';
import createEventRoutes from '@/pages/createEvent/routes';
import formRoutes from '@/pages/createReservation/routes';
import eventPageRoutes from '@/pages/event/routes';
import { navigateToEvents } from '@/pages/eventList/duck/actions';
import eventRoutes from '@/pages/eventList/routes';
import individualReservationsList from '@/pages/individualReservationsList/routes';
import parentConsentRoutes from '@/pages/parentConsent/routes';
import reservationPageRoutes from '@/pages/reservation/routes';
import reservationListRoutes from '@/pages/reservationList/routes';

import Sandbox from '../../sandbox/Sandbox';
import userRoutes from '../user/routes';

import { ROUTE_HOME, ROUTE_SANDBOX } from './constants';

import NotFound from 'SHARED/components/NotFound';

type Route = {
  path: string;
  titleId?: string;
  isStandAlone?: boolean;
  unAuthenticatedAccess?: boolean;
  component: ComponentType;
  getObservable?: (
    action$: Observable<RootAction>,
    state$: StateObservable<RootState>,
  ) => Observable<RootAction>;
};

const routes: Record<string, Route> = {
  [ROUTE_HOME]: {
    path: '/',
    component: Fragment,
    isStandAlone: true,
    getObservable: (): Observable<RootAction> => of(navigateToEvents()),
  },
  ...addAttendeeRoutes,
  ...userRoutes,
  ...createEventRoutes,
  ...eventPageRoutes,
  ...eventRoutes,
  ...formRoutes,
  ...parentConsentRoutes,
  ...reservationListRoutes,
  ...individualReservationsList,
  ...reservationPageRoutes,
  [NOT_FOUND]: {
    path: '/not-found',
    component: NotFound,
    titleId: 'page.notFound',
    hideBreadcrumbs: true,
    unAuthenticatedAccess: true,
  },
  // @dev only
  [ROUTE_SANDBOX]: {
    path: '/sandbox',
    component: Sandbox,
    titleId: 'page.sandbox',
  },
};

const pluckParam = <T extends keyof Route>(routeField: T) =>
  Object.entries(routes).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value[routeField] }),
    {} as Record<string, Route[T]>,
  );

export const pathMap = pluckParam('path');
export const unAuthenticatedAccessMap = pluckParam('unAuthenticatedAccess');

export default routes;
