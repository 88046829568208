import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff8ea;
  border-radius: 4px;
  border-bottom: 4px solid ${colors.dangerColor};
  margin: 20px 0;
  padding: 5px;

  svg {
    font-size: 1.5em;
    color: ${colors.dangerColor};
    margin: 3px 5px;
    align-self: flex-start;
  }

  > b {
    display: contents;
  }
`;
