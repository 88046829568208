import { Spin as SpinBsa } from 'antd';
import styled from 'styled-components';

export const Spin = styled(SpinBsa)`
  position: fixed;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255);
`;
