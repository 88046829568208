import { Card } from 'antd';
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { createDataSel } from '@/modules/data/duck/selectors';
import EventLogs from '@/modules/entities/Logs/components/EventLogs';
import { formatLogItem } from '@/modules/entities/Logs/utils';
import { personGuidSel } from '@/modules/user/duck/selectors';

import { TitleAddon } from '../../components/ReservationComments/ReservationComments.styled';

import { tabDataSel } from './duck/selector';

type GroupProps = {
  isGroup: true;
  groupReservation: GroupReservation;
};

type IndividualProps = {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};

type Props = GroupProps | IndividualProps;

const LogsTab: React.FC<Props> = props => {
  const currentPersonGuid = useSelector(personGuidSel);

  const form = useSelector(createDataSel('form'));

  const {
    data: { comments, loggedActions, personEmails },
    isLoading,
  } = useSelector(tabDataSel);

  const { formCode } = form;

  const eventLogs = useMemo(
    () =>
      [
        ...loggedActions.map(item => formatLogItem('loggedAction', item)),
        ...personEmails.map(item => formatLogItem('email', item)),
        ...comments
          .map(item => formatLogItem('comment', item))
          .map(item => {
            const isSameUser = currentPersonGuid === item.baseData.personGUID;
            return {
              ...item,
              title: (
                <Fragment>
                  {item.title}
                  {isSameUser && <TitleAddon> (You)</TitleAddon>}
                </Fragment>
              ),
            };
          }),
      ].sort((a, b) => Number(b.date) - Number(a.date)),
    [comments, loggedActions, personEmails, currentPersonGuid],
  );

  return (
    <Card>
      <EventLogs
        {...props}
        logs={eventLogs}
        isLoading={Object.values(isLoading).some(loading => loading)}
        formCode={formCode}
      />
    </Card>
  );
};

export default LogsTab;
