import {
  BoldOutlined,
  FontColorsOutlined,
  FontSizeOutlined,
  HighlightOutlined,
  ItalicOutlined,
  LinkOutlined,
  OrderedListOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import React from 'react';
import { useSlate } from 'slate-react';

import { LeafType, ElementType } from '../../constants';
import { TrimmedVariable } from '../../types';
import { isLinkActive } from '../../utils';

import { ToolbarWrapper } from './Toolbar.styled';
import AddIndentationButton from './components/AddIndentationButton';
import BlockButton from './components/BlockButton';
import CreateEditLink from './components/CreateEditLink';
import FontColorPicker from './components/FontColorPicker';
import HighlightColorPicker from './components/HighlightColorPicker';
import MarkButton from './components/MarkButton';
import RemoveIndentationButton from './components/RemoveIndentationButton';
import Separator from './components/Separator';
import SizePicker from './components/SizePicker';
import ToolbarPopoverButton from './components/ToolbarPopoverButton';

type Props = {
  variables: TrimmedVariable[];
};

const Toolbar: React.FC<Props> = ({ variables }) => {
  const editor = useSlate();

  return (
    <ToolbarWrapper>
      <MarkButton format={LeafType.bold} Icon={BoldOutlined} />
      <MarkButton format={LeafType.italic} Icon={ItalicOutlined} />
      <MarkButton format={LeafType.underline} Icon={UnderlineOutlined} />
      <ToolbarPopoverButton
        Icon={FontSizeOutlined}
        caretDirection="down"
        popover={<SizePicker />}
      />
      <ToolbarPopoverButton
        Icon={FontColorsOutlined}
        caretDirection="down"
        popover={<FontColorPicker />}
      />
      <ToolbarPopoverButton
        Icon={HighlightOutlined}
        caretDirection="down"
        popover={<HighlightColorPicker />}
      />
      <Separator />
      <BlockButton format={ElementType.orderedList} Icon={OrderedListOutlined} />
      <BlockButton format={ElementType.unorderedList} Icon={UnorderedListOutlined} />
      <RemoveIndentationButton />
      <AddIndentationButton />
      <Separator />
      <ToolbarPopoverButton
        Icon={LinkOutlined}
        isActive={isLinkActive(editor)}
        popover={<CreateEditLink variables={variables} />}
      />
    </ToolbarWrapper>
  );
};

export default Toolbar;
