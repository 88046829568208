import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createPageDataSelector } from '@/modules/data/duck/selectors';

import { moduleName, pageDataParams } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const pageDataSel = createPageDataSelector(pageDataParams);

export const disclaimersAcceptedSel = createSelector(
  moduleSel,
  ({ disclaimersAccepted }) => disclaimersAccepted,
);

export const acceptDisclaimersInProgressSel = createSelector(
  moduleSel,
  ({ acceptDisclaimersInProgress }) => acceptDisclaimersInProgress,
);
