import { Switch } from 'antd';
import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { createIsLoadingSel } from '@/modules/data/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { queryFiltersSel } from '@/modules/reports/duck/selectors';
import Banner from '@/modules/shared/components/Banner';
import Label from '@/modules/shared/components/Label';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { saveReport } from '../../tabs/CurrentReport/duck/actions';
import {
  createCurrentQueryFilterSel,
  queryFilterNameSel,
  saveReportInProgressSel,
} from '../../tabs/CurrentReport/duck/selectors';

import { Row, Col } from './SaveReportModal.styled';
import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const SaveReportModal: React.FC<Props> = ({ modalParams }) => {
  const { queryFilterCode, dataType } = modalParams;

  const dispatch = useDispatch();
  const isLoadingQueryFilters = useSelector(createIsLoadingSel('queryFilters'));
  const isLoadingQueryFilterVariables = useSelector(createIsLoadingSel('queryFilterVariables'));
  const saveReportInProgress = useSelector(saveReportInProgressSel);
  const queryFilterList = useSelector((state: RootState) => queryFiltersSel(state, modalParams));
  const queryFilterName = useSelector((state: RootState) => queryFilterNameSel(state, modalParams));
  const currentQueryFilter = useSelector((state: RootState) =>
    createCurrentQueryFilterSel(queryFilterCode)(state, modalParams),
  );

  const [isNew, setIsNew] = useState(!!currentQueryFilter?.isDefault);

  const errorMessage = useMemo(() => {
    if (!isNew) return null;
    if (!queryFilterName) return 'Report name is required';
    if (queryFilterName.length < 5) return 'Report name must have at least 5 characters';

    const nameAlreadyExists = queryFilterList.some(
      qf => qf.queryFilterName.toLowerCase() === queryFilterName.toLowerCase(),
    );

    if (nameAlreadyExists) return `Report with name "${queryFilterName}" already exists`;
  }, [isNew, queryFilterList, queryFilterName]);

  const canEditReport = useSelector(
    createAbilitiesSelector('report', PermissionAction.Update, currentQueryFilter),
  );

  function handleSaveReport() {
    dispatch(
      saveReport.request({
        currentQueryFilterCode: queryFilterCode,
        isNew,
        dataType,
      }),
    );
  }

  return (
    <ModalComponent
      title="Save Report"
      buttons={[
        {
          title: `${isNew ? 'Create' : 'Update'} Report`,
          type: 'primary',
          disabled: !!errorMessage,
          onClick: handleSaveReport,
        },
      ]}
      inProgress={saveReportInProgress || isLoadingQueryFilters || isLoadingQueryFilterVariables}
      centerButtons
    >
      <Row>
        <Col>
          <Label label="Save as NEW Report">
            <Switch checked={isNew} onChange={setIsNew} disabled={!canEditReport} />
          </Label>
        </Col>
      </Row>
      {errorMessage && <Banner type="error" message={errorMessage} />}
    </ModalComponent>
  );
};

export default SaveReportModal;
