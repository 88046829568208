import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { createPageDataSelector } from '@/modules/data/duck/selectors';
import { RESERVATION_CODE_COLUMN_NAME } from '@/modules/uploadQuestionsModal/constants';
import { columnNamesSel, mappedColumnsSel } from '@/modules/uploadQuestionsModal/duck/selectors';
import { MappedColumn } from '@/modules/uploadQuestionsModal/types';
import { searchInObj } from '@/modules/utils/searchUtils';

import { stepDataParams } from '../constants';
import { flattenOptions } from '../utils';

const stepDataSel = createPageDataSelector(stepDataParams);

export const optionsSel = createSelector(
  stepDataSel,
  ({
    data: {
      formPartsData: { formParts },
    },
  }) =>
    formParts.map(({ formPartCode, formPartName, formItems }) => ({
      label: formPartName,
      options: formItems.map(({ formItemCode, formItemName }) => ({
        value: `${formPartCode}/${formItemCode}`,
        label: formItemName,
      })),
    })),
);

export const initialValuesSel = createSelector(
  [mappedColumnsSel, columnNamesSel, optionsSel],
  (existingMappedColumns, importedColumnNames, options) => {
    if (!isEmpty(existingMappedColumns)) {
      return Object.entries(existingMappedColumns).reduce((acc, [formPartCode, formItemsObj]) => {
        const mappedColumns: MappedColumn[] = Object.entries(formItemsObj).map(
          ([formItemCode, importedColumnName]) => ({
            importedColumnName,
            nextColumnName: `${formPartCode}/${formItemCode}`,
          }),
        );

        return [...acc, ...mappedColumns];
      }, [] as MappedColumn[]);
    }

    const [initialMappedColumns] = importedColumnNames
      .filter(col => col !== RESERVATION_CODE_COLUMN_NAME)
      .reduce(
        (acc, column) => {
          const [mappedColumns, restOptions] = acc;
          const foundOptionIndex = restOptions.findIndex(option =>
            searchInObj(option, column, true),
          );

          const nextMappedColumn: Pick<MappedColumn, 'importedColumnName'> &
            Partial<Pick<MappedColumn, 'nextColumnName'>> = {
            importedColumnName: column,
          };
          if (foundOptionIndex > -1) {
            nextMappedColumn.nextColumnName = restOptions[foundOptionIndex].value;
          }

          return [
            [...mappedColumns, nextMappedColumn],
            [...restOptions.slice(0, foundOptionIndex), ...restOptions.slice(foundOptionIndex + 1)],
          ] as typeof acc;
        },
        [[], flattenOptions(options)] as [MappedColumn[], ReturnType<typeof flattenOptions>],
      );

    return initialMappedColumns;
  },
);
