import { UserOutlined, MailFilled, CommentOutlined } from '@ant-design/icons';
import React, { Fragment, useMemo } from 'react';

import { FormattedLogItem } from '@/modules/entities/Logs/types';

import {
  StyledListItem,
  StyledListItemMeta,
  Title,
  Description,
  Date,
  StyledAvatar,
} from './LogListItem.styled';

import { usDateTime } from 'UTILS/dateFormats';

type Props = {
  onActionClick?: (id: string) => void;
  log: FormattedLogItem;
  isSelected?: boolean;
};

const LogListItem: React.FC<Props> = ({ log, onActionClick, isSelected }) => {
  const { itemId, title, description, date, type } = log;

  const itemIcon = useMemo(() => {
    switch (type) {
      case 'comment':
        return <CommentOutlined />;
      case 'loggedAction':
        return <UserOutlined />;
      case 'email':
        return <MailFilled />;
      default:
        return <UserOutlined />;
    }
  }, [type]);

  const descriptionField = useMemo(
    () => (
      <Fragment>
        <Description>{description}</Description>
        <Date>{usDateTime(date)}</Date>
      </Fragment>
    ),
    [date, description]
  );

  const handleOnClickLog = () => {
    if (!onActionClick) return;
    onActionClick(itemId);
  };

  return (
    <StyledListItem onClick={handleOnClickLog} $isSelected={!!isSelected}>
      <StyledListItemMeta
        avatar={<StyledAvatar icon={itemIcon} size="large" shape="circle" />}
        title={<Title>{title}</Title>}
        description={descriptionField}
      />
    </StyledListItem>
  );
};

export default LogListItem;
