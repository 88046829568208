import React, { Fragment } from 'react';

import Desktop from '@/modules/responsiveSizes/Desktop';
import Mobile from '@/modules/responsiveSizes/Mobile';

import { DesktopWrapper, MobileWrapper, Header, Content } from './InfoItem.styled';

interface Props {
  header: string;
  info?: string | number | React.ReactChild;
}

const InfoItem: React.FC<Props> = ({ header, info }) => {
  function renderInfo() {
    return (
      <Fragment>
        <Header>{header}</Header>
        <Content>{info}</Content>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Desktop>
        <DesktopWrapper>{renderInfo()}</DesktopWrapper>
      </Desktop>
      <Mobile>
        <MobileWrapper>{renderInfo()}</MobileWrapper>
      </Mobile>
    </Fragment>
  );
};

export default InfoItem;
