import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

type SharedProps = {
  isMobile: boolean;
};

const sharedStyles = css<SharedProps>`
  display: flex;
  font-family: Roboto;
  font-weight: 300;
  color: ${colors.darkGrey};

  :first-child {
    margin-bottom: 8px;
  }

  > span:first-child {
    margin-right: ${({ isMobile }) => (isMobile ? 'auto' : '5px')};
  }
`;

export const AmountPaid = styled.p`
  ${sharedStyles}

  font-size: 20px;
  font-style: normal;
  line-height: 1.2;
`;

export const NextPayment = styled.p`
  ${sharedStyles}

  font-size: 14px;
  line-height: 1.43;
`;
