import { DownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useMemo } from 'react';

import Desktop from '@/modules/responsiveSizes/Desktop';
import Mobile from '@/modules/responsiveSizes/Mobile';
import Dropdown from '@/modules/shared/components/Dropdown';

import {
  ActionBarContainer,
  Col,
  Divider,
  InfoContainer,
  DropdownButton,
} from './CustomActionBar.styled';
import CustomActionBarButton from './components/CustomActionBarButton';

interface Button {
  title: string;
  icon: React.ReactElement;
  disabled: boolean;
  onClick: () => void;
}

type Props = {
  buttons: Button[];
  contentRight?: React.ReactElement;
  selectedAttendeesAmount: number;
};

const CustomActionBar: React.FC<Props> = ({ buttons, selectedAttendeesAmount, contentRight }) => {
  const activeButtons = useMemo(() => buttons.filter(button => !button.disabled), [buttons]);

  return (
    <ActionBarContainer>
      <Col span={14}>
        <Desktop>
          {buttons.map(button => (
            <CustomActionBarButton key={button.title} {...button} color="white" />
          ))}
        </Desktop>
        <Mobile>
          <Dropdown
            disabled={!activeButtons.length}
            placement="bottomRight"
            menuItems={activeButtons.map(({ title, ...button }) => ({
              key: title,
              label: title,
              ...button,
            }))}
          >
            <DropdownButton size="large" ghost color="white">
              Actions
              <DownOutlined />
            </DropdownButton>
          </Dropdown>
        </Mobile>
      </Col>
      <Col span={10}>
        {!!selectedAttendeesAmount && (
          <InfoContainer>{`${selectedAttendeesAmount} Selected`}</InfoContainer>
        )}
        <Divider />
        {contentRight}
      </Col>
    </ActionBarContainer>
  );
};

export default CustomActionBar;
