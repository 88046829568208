import { Document, Image, Page, Text, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';

import colors from '@/modules/styles/colors';
import { dateFromString, US_DATE_FORMAT } from '@/modules/utils/dateFormats';

import {
  disclaimerAcceptedText,
  DATE_OF_CONSENT_LABEL,
  EVENT_LABEL,
  FOR_ATTENDEE_LABEL,
  PERSON_GIVING_CONSENT_LABEL,
  RELATIONSHIP_TO_ATTENDEE_LABEL,
} from '../../constants';
import { Attendee, Disclaimer } from '../../types';

import CheckedIcon from './components/CheckedIcon';
import DisclaimerContent from './components/DisclaimerContent';
import UncheckedIcon from './components/UncheckedIcon';

import FleurDeLisImg from '@/assets/images/FleurDeLis_90px.png';
import FleurDeLisImgLarge from '@/assets/images/FleurDeLis_90px@2x.png';

type Props = {
  disclaimer: Disclaimer;
  attendeeDetails: Attendee;
};

const styles = StyleSheet.create({
  label: {
    fontWeight: 'bold',
  },
  row: {
    marginBottom: 8,
  },
  thickRow: {
    marginTop: 12,
  },
});

const DisclaimerDocument: React.FC<Props> = ({ disclaimer, attendeeDetails }) => {
  const {
    disclaimerName,
    disclaimerContent,
    accepted,
    relationship,
    dateOfAcceptance,
    firstName: consentFirstName,
    lastName: consentLastName,
  } = disclaimer;
  const { firstName, lastName, formName } = attendeeDetails;
  return (
    <Document>
      <Page
        style={{
          padding: 30,
          fontSize: 12,
          fontWeight: 'normal',
          fontFamily: 'Roboto',
          textAlign: 'justify',
          color: colors.darkGrey,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 12,
          }}
        >
          <View>
            <Text
              style={{
                color: colors.scoutingBlue,
                marginBottom: 20,
                fontSize: 24,
                fontWeight: 'bold',
              }}
            >
              {disclaimerName}
            </Text>
            <Text style={styles.row}>
              <Text style={styles.label}>{FOR_ATTENDEE_LABEL}: </Text>
              <Text>{`${firstName} ${lastName}`}</Text>
            </Text>
            <Text style={styles.row}>
              <Text style={styles.label}>{EVENT_LABEL}: </Text>
              <Text>{formName}</Text>
            </Text>
          </View>
          <Image
            src={FleurDeLisImgLarge}
            style={{
              width: 60,
              height: 60,
            }}
          />
        </View>
        <DisclaimerContent content={disclaimerContent} />
        {dateOfAcceptance && (
          <Text style={styles.thickRow}>
            <Text style={styles.label}>{DATE_OF_CONSENT_LABEL}: </Text>
            <Text>{`${dateFromString(dateOfAcceptance).format(US_DATE_FORMAT)}`}</Text>
          </Text>
        )}
        {consentFirstName && consentLastName && (
          <Text style={styles.thickRow}>
            <Text style={styles.label}>{PERSON_GIVING_CONSENT_LABEL}: </Text>
            <Text>{`${consentFirstName} ${consentLastName}`}</Text>
          </Text>
        )}
        {relationship && (
          <Text style={styles.thickRow}>
            <Text style={styles.label}>{RELATIONSHIP_TO_ATTENDEE_LABEL}: </Text>
            <Text>{relationship}</Text>
          </Text>
        )}
        <View
          style={[
            styles.thickRow,
            {
              flexDirection: 'row',
              alignItems: 'center',
            },
          ]}
        >
          {accepted ? (
            <CheckedIcon size={20} color={colors.gray} />
          ) : (
            <UncheckedIcon size={20} color={colors.gray} />
          )}
          <Text
            style={{
              marginLeft: 5,
            }}
          >
            {disclaimerAcceptedText}
          </Text>
        </View>
        <View
          style={{
            marginTop: 'auto',
          }}
          fixed
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderTop: `1 solid ${colors.gray}`,
              paddingTop: 15,
              marginTop: 15,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Image
                src={FleurDeLisImg}
                style={{
                  width: 15,
                  height: 15,
                }}
              />
              <Text
                style={{
                  color: colors.lightBlue,
                  marginLeft: 5,
                  fontFamily: 'Trajan',
                }}
              >
                Boy Scouts of America
              </Text>
            </View>
            <Text
              style={{
                fontSize: 10,
                color: colors.gray,
              }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DisclaimerDocument;
