import React, { Fragment, useMemo } from 'react';

import { transactionTypes } from '@/modules/payments/constants';

import { ExplanationWrapper, InfoIcon } from './Explanation.styled';

type Props = {
  transactionTypeCode?: keyof typeof transactionTypes;
};

const Explanation: React.FC<Props> = ({ transactionTypeCode }) => {
  const transaction = transactionTypeCode && transactionTypes[transactionTypeCode];

  const direction = useMemo(() => {
    switch (transaction?.direction) {
      case '+':
        return 'increases';
      case '-':
        return 'decreases';
    }
    return null;
  }, [transaction]);

  const target = useMemo(() => {
    switch (transaction?.target) {
      case 'charge':
        return 'balance due';
      case 'payment':
        return 'amount paid';
    }
    return null;
  }, [transaction]);

  const explanation = useMemo(() => {
    if (!direction || !target) return null;

    return (
      <Fragment>
        <InfoIcon />
        This adjustment &nbsp;
        <b>{`${direction} total ${target}`}.</b>
      </Fragment>
    );
  }, [direction, target]);

  return <ExplanationWrapper>{explanation}</ExplanationWrapper>;
};

export default Explanation;
