import { Typography } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const SubHeaderContainer = styled.div`
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  transition: height 150ms linear;
`;

export const SubHeaderTitle = styled(Typography.Title)`
  && {
    font-family: Roboto;
    font-size: 24px;
    height: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.darkGrey};
    margin: 15px 32px;
    transition: all 150ms linear;
    & a:hover {
      color: ${colors.info};
    }
    & a:focus {
      text-decoration: none;
    }
  }
`;
