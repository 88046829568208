import { useRef } from 'react';

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<{ value: T; prev?: T }>({
    value,
  });

  const currentValue = ref.current.value;

  if (value !== currentValue) {
    ref.current = {
      value,
      prev: currentValue,
    };
  }

  return ref.current.prev;
}
