import { Button as BsaButton } from 'antd';
import styled, { css } from 'styled-components';

import { appContentPadding } from '@/modules/core/components/App/constants';

const stickyPositionStyles = css`
  position: sticky;
  bottom: 0;
  margin: 0 -${appContentPadding};
  padding: 0 ${appContentPadding};
`;

export const ButtonsRow = styled.div<{
  $alwaysVisible: boolean;
}>`
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #eee;
  z-index: 1;
  ${({ $alwaysVisible }) => $alwaysVisible && stickyPositionStyles};
`;

export const Button = styled(BsaButton)<{ $isFirstRight?: boolean }>`
  :not(:first-child) {
    margin-left: 10px;
  }

  &&& {
    ${({ $isFirstRight }) => ($isFirstRight ? 'margin-left: auto;' : '')}
  }
`;
