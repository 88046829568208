import { SearchOutlined } from '@ant-design/icons';
import React, { FC, useState } from 'react';

import { TrimmedVariable } from '../../types';

import {
  ListContainer,
  SearchContainer,
  SearchInput,
  Title,
  VariablesListContainer,
} from './VariablesList.styled';
import Variable from './components/Variable';

interface Props {
  variables: TrimmedVariable[];
  onClick: (variable: TrimmedVariable) => void;
}

const VariablesList: FC<Props> = ({ variables, onClick }) => {
  const [searchStr, setSearchStr] = useState('');

  if (!variables || !variables.length) return null;

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(event.target.value);
  };

  return (
    <VariablesListContainer>
      <Title>Variables List</Title>
      <ListContainer>
        <SearchContainer>
          <SearchInput
            placeholder="Search by variable name"
            prefix={<SearchOutlined />}
            value={searchStr}
            onChange={handleSearch}
          />
        </SearchContainer>
        {variables
          .filter(({ caption }) => caption.toLowerCase().includes(searchStr.toLowerCase()))
          .map((variable, index) => (
            <Variable key={variable.value + index} variable={variable} onClick={onClick} />
          ))}
      </ListContainer>
    </VariablesListContainer>
  );
};

export default VariablesList;
