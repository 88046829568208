import { createAsyncAction } from 'typesafe-actions';

import { EventItem } from '@/modules/data/dataTypes/eventList';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '@/pages/createEvent/constants';

export const updateEvent = createAsyncAction(
  `${moduleName}/UPDATE_EVENT_REQUEST`,
  `${moduleName}/UPDATE_EVENT_SUCCESS`,
  `${moduleName}/UPDATE_EVENT_FAILURE`,
)<
  {
    updatedFormData: FormSettings | EventItem;
    isUpdatingSpecificFormField?: boolean;
    customSuccessMessage?: string;
  },
  undefined,
  ApiError | undefined
>();
