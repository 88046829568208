import { createAction, createAsyncAction } from 'typesafe-actions';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { ApiError } from '@/modules/utils/apiService';

import { modulesPath } from '../constants';

const moduleNamespace = modulesPath.join('/');

export const openUpdateReservationSizeModal = createAction(
  `${moduleNamespace}/OPEN_UPDATE_RESERVATION_SIZE_MODAL`,
)<GroupReservation>();

export const updateFormRegistrationSession = createAsyncAction(
  `${moduleNamespace}/UPDATE_FORM_REGISTRATION_SESSION_REQUEST`,
  `${moduleNamespace}/UPDATE_FORM_REGISTRATION_SESSION_SUCCESS`,
  `${moduleNamespace}/UPDATE_FORM_REGISTRATION_SESSION_FAILURE`,
)<{ selectedSessionCode: string }, undefined, ApiError>();

export const updateGroupReservationSession = createAsyncAction(
  `${moduleNamespace}/UPDATE_GROUP_RESERVATION_SESSION_REQUEST`,
  `${moduleNamespace}/UPDATE_GROUP_RESERVATION_SESSION_SUCCESS`,
  `${moduleNamespace}/UPDATE_GROUP_RESERVATION_SESSION_FAILURE`,
)<{ selectedSessionCode: string }, undefined, ApiError>();

export const updateReservationSize = createAsyncAction(
  `${moduleNamespace}/UPDATE_RESERVATION_SIZE_MODAL_REQUEST`,
  `${moduleNamespace}/UPDATE_RESERVATION_SIZE_MODAL_SUCCESS`,
  `${moduleNamespace}/UPDATE_RESERVATION_SIZE_MODAL_FAILURE`,
)<
  {
    initialNumberOfYouth: number;
    initialNumberOfAdults: number;
    initialRosterCount: number;
  },
  undefined,
  ApiError
>();
