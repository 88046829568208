import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { modulePath, secondaryRegistrantStepKeys, steps } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulePath);

export const stepIndexSel = createSelector(moduleSel, ({ stepIndex }) => stepIndex.current);

const futureStepIndexSel = createSelector(moduleSel, ({ stepIndex }) => stepIndex.future);

export const stepIsLoadingSel = createSelector(moduleSel, ({ stepIsLoading }) => stepIsLoading);

export const disabledStepsSel = createSelector(moduleSel, ({ disabledSteps }) => disabledSteps);

const initialModalDataSel = createSelector(moduleSel, ({ initialModalData }) => initialModalData);

export const formCodeSel = createSelector(
  initialModalDataSel,
  initialData => initialData?.formCode,
);

export const secondaryRegistrantFormRecordGuidSel = createSelector(
  [moduleSel, initialModalDataSel],
  ({ secondaryRegistrantFormRecordGuid }, initialData) =>
    secondaryRegistrantFormRecordGuid || initialData?.secondaryRegistrantFormRecordGUID || null,
);

export const primaryRegistrantFormRecordGUIDSel = createSelector(
  initialModalDataSel,
  initialData => initialData?.primaryRegistrantFormRecordGUID,
);

export const sessionSel = createSelector(initialModalDataSel, initialData => initialData?.session);

const useCheckoutStepSel = createSelector(
  initialModalDataSel,
  initialData => initialData?.useCheckoutStep,
);

export const collectedFormPartsSel = createSelector(
  moduleSel,
  ({ collectedFormParts }) => collectedFormParts,
);

export const collectedRegistrationDataSel = createSelector(
  moduleSel,
  ({ collectedRegistrationData }) => collectedRegistrationData,
);

export const addAdditionalRegistrantInProgressSel = createSelector(
  moduleSel,
  ({ addAdditionalRegistrantInProgress }) => addAdditionalRegistrantInProgress,
);

export const stepsSel = createSelector(useCheckoutStepSel, useCheckoutStep => {
  if (useCheckoutStep) return steps;
  return steps.filter(({ key }) => key !== secondaryRegistrantStepKeys.checkout);
});

export const stepKeySel = createSelector([stepsSel, stepIndexSel], (filteredSteps, stepIndex) =>
  get(filteredSteps, [stepIndex, 'key']),
);

export const futureStepKeySel = createSelector(
  [stepsSel, futureStepIndexSel],
  (filteredSteps, stepIndex) => get(filteredSteps, [stepIndex, 'key']),
);
