import { Card, Row, Col } from 'antd';
import React from 'react';

const UnpublishedEvent: React.FC = () => (
  <Card className="mt-6 text-center">
    <h2 className="text-primary">Event unavailable</h2>
    <Row>
      <Col span={14} offset={5}>
        <p className="mt-3">This event is temporary unavailable</p>
      </Col>
    </Row>
  </Card>
);

export default UnpublishedEvent;
