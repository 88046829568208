import { TypeOptions } from 'react-toastify';
import styled from 'styled-components';

import { getColor } from '../../utils';

export const Content = styled.div`
  margin-top: 3px;
  margin-bottom: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
`;

export const Title = styled.div<{ $type: TypeOptions }>`
  margin-right: 16px;
  line-height: 1.43;
  font-weight: bold;
  color: ${({ $type }) => getColor($type)};
  flex-shrink: 0;
`;

export const Body = styled.div`
  line-height: 1.43;
  flex-grow: 1;
`;
