import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import Toast from './components/Toast';

// TODO: create constants for each type of toast and pull all the information from them

type Content = string | React.ReactElement;

function transformContent(content: Content) {
  let newContent = content;
  if (typeof content === 'object' && !React.isValidElement(content)) {
    newContent = 'Error';
  }

  return newContent;
}

const success = (content: Content, title = 'Success', options?: ToastOptions) =>
  genericToast(transformContent(content), title, {
    type: 'success',
    ...options,
  });

const info = (content: Content, title = 'Informative', options?: ToastOptions) =>
  genericToast(transformContent(content), title, {
    type: 'info',
    ...options,
  });

const error = (content: Content, title = 'Error', options?: ToastOptions) =>
  genericToast(transformContent(content), title, {
    type: 'error',
    ...options,
  });

const warn = (content: Content, title = 'Warning', options?: ToastOptions) =>
  genericToast(transformContent(content), title, {
    type: 'warning',
    ...options,
  });

const genericToast = (content: Content, title: string, options: ToastOptions) =>
  toast(
    <Toast title={title} type={options.type}>
      {content}
    </Toast>,
    {
      closeButton: false,
      autoClose: 10000,
      position: 'top-center',
      ...options,
    },
  );

export default {
  success,
  info,
  error,
  warn,
  dismiss: toast.dismiss,
  isActive: toast.isActive,
};
