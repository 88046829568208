import { View } from '@react-pdf/renderer';
import React from 'react';
import { Element as SlateElement } from 'slate';

import { fromHTML } from '@/modules/richTextEditor/utils';

import Element from './components/Element';
import Leaf from './components/Leaf';

type Props = {
  content: string;
};

const DisclaimerContent: React.FC<Props> = ({ content }) => {
  const descendantsArr = fromHTML(content);
  return (
    <View>
      {descendantsArr.map((descendant, index) => {
        if (SlateElement.isElement(descendant)) return <Element key={index} element={descendant} />;
        return <Leaf leaf={descendant} key={index} />;
      })}
    </View>
  );
};

export default DisclaimerContent;
