import { EmailTemplate } from '@/modules/data/dataTypes/emailTemplate';
import apiService from '@/modules/utils/apiService';

// https://confluence.scouting.org/display/EM/SendSingleEmail
const sendEmail$ = (query: string) =>
  apiService.get$(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=SendSingleEmail${query}`,
  );

// https://confluence.scouting.org/display/EM/UpdateEmailTemplate
const updateEmailTemplate$ = (
  templateInfo: Pick<
    EmailTemplate,
    'senderEmailAddress' | 'emailTemplateContent' | 'emailSubject' | 'formCode' | 'apiName'
  > & { isAdHoc?: true },
) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateEmailTemplate',
    templateInfo,
  );

const updateMailing$ = (payload: {
  formCode: string;
  emailTemplateCode: string;
  queryFilterCode: string;
  emailCCAddress?: string;
  processingStartDate?: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateMailing',
    payload,
  );

const startMailingProcessing$ = (formCode: string, mailingCode: string) =>
  apiService.get$(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=StartMailingProcessing&formCode=${formCode}&mailingCode=${mailingCode}`,
  );

const deleteEmailTemplate = (payload: { emailTemplateCode: string; formCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteEmailTemplate',
    payload,
  );

export default {
  sendEmail$,
  updateEmailTemplate$,
  updateMailing$,
  startMailingProcessing$,
  deleteEmailTemplate,
};
