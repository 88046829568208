import { CountryValidation } from '@/pages/createEvent/steps/AttendeeTypes/constants';

export enum RegistrantTypes {
  Primary = 'primary',
  Secondary = 'secondary',
}

type RegistrantType = RegistrantTypes.Primary | RegistrantTypes.Secondary;

export interface AttendeeType {
  deposit: number;
  description: string;
  earlyArrivalDays?: number;
  earlyArrivalFee?: number;
  fee: number;
  isDefault: boolean;
  isLeader: boolean;
  isOfflinePaymentsEnabled: boolean;
  isRosterLead: boolean;
  isStaff: boolean;
  maxCapacity: number;
  maximumUnit: number;
  minCapacity: number;
  offlinePaymentsDescription: string;
  registrationCompletedAttendeeCount: number;
  restrictions: {
    countryValidation: CountryValidation;
    programId: string[];
    gender: string[];
    adultOnly: boolean;
    isBSAMember: boolean;
    maxAge: number;
    minAge: number;
    registrantType: RegistrantType[];
  };
  typeCode: string;
  typeName: string;
}
