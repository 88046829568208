import { Button, Form as AntForm, Typography } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

import { CreateAccountButton } from './components/LoginOptions/components/LoginWithMyScouting/LoginWithMyScouting.styled';

export const Form = styled(AntForm)<{ $isMobile: boolean }>`
  margin: 0 auto;
  max-width: ${({ $isMobile }) => ($isMobile ? '280px' : '284px')};
  width: 100%;
`;

export const Logo = styled.img`
  fill: white;
  height: 90px;
  margin-bottom: -30px;
  transform: translateY(-76%);
`;

export const Title = styled(Typography.Title)`
  && {
    color: ${colors.scoutingBlue};
    font-family: Roboto, serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1;
    margin-bottom: 24px;
    margin-top: -20px;
  }
`;

export const ChangeSignInButton = styled(CreateAccountButton)`
  &&& {
    color: ${colors.darkGrey};
  }
`;

export const LoginButton = styled(Button)<{ $isMobile: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ $isMobile }) => ($isMobile ? '280px' : '284px')};
  width: 100%;
`;

export const Subtitle = styled(Typography.Title)`
  && {
    color: ${colors.darkGrey};
    margin: 0 0 36px 0;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
  }
`;
