import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { saveSelectedAddonOptions } from './actions';

const saveOptionsInProgress = createReducer(false)
  .handleAction(saveSelectedAddonOptions.request, () => true)
  .handleAction([saveSelectedAddonOptions.success, saveSelectedAddonOptions.failure], () => false);

export default combineReducers({
  saveOptionsInProgress,
});
