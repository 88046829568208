import { Button as BsaButton } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div<{ showRemoveButton: boolean }>`
  display: flex;

  > button:last-child {
    visibility: hidden;
  }

  :hover {
    > button:last-child {
      visibility: ${({ showRemoveButton }) => (showRemoveButton ? 'visible' : 'hidden')};
    }
  }
`;

export const RemoveButton = styled(BsaButton)`
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 5px 0 0;
  color: red;
`;
