import { Card, Form } from 'antd';
import React, { Fragment, memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import {
  Activity,
  Addon,
  PaymentSummary as PaymentSummaryType,
} from '@/modules/data/dataTypes/paymentSummary';
import { Session } from '@/modules/data/dataTypes/sessionList';
import { createDataSel } from '@/modules/data/duck/selectors';
import { loadingSel } from '@/modules/routing/duck/selectors';

import styles from './PaymentSummary.less';
import GroupReservationPaymentSummaryContent from './components/GroupReservationPaymentSummaryContent';
import MakePayment from './components/MakePayment';
import RegistrantsList from './components/RegistrantsList';

import TextAreaMaterial from 'SHARED/components/TextAreaMaterial';

type BaseProps = (
  | {
      isGroup: true;
      groupReservation: GroupReservation;
    }
  | {
      isGroup: false;
    }
) & {
  isLoading: boolean;
  hideMakePaymentButton: boolean;
  currentSession: Pick<
    Session,
    'sessionName' | 'isFeePerPerson' | 'baseFeePersonCount' | 'additionalPersonFee'
  >;
  paymentSummary: PaymentSummaryType;
};

type PropsForAddons = BaseProps & {
  addons: Omit<Addon, 'formRecordAddonGUID' | 'groupReservationAddonGUID'>[];
  paymentCategories: PaymentCategory[];
};

type PropsForActivities = BaseProps & {
  activities: Pick<
    Activity,
    'activityCode' | 'activityName' | 'fee' | 'totalFee' | 'isMandatory' | 'quantity'
  >[];
  paymentCategories: PaymentCategory[];
};

type PropsForCheckout = BaseProps & {
  showComments: boolean;
  commentsFieldRequired: boolean;
  paymentComments: string;
  onMakePayment: () => void;
  onPaymentCommentsChange: (notes: string) => void;
};

type OwnProps = PropsForAddons | PropsForActivities | PropsForCheckout;

type MapStateProps = ReturnType<typeof mapStateToProps>;

type Props = MapStateProps & OwnProps;

const PaymentSummary: React.FC<Props> = props => {
  const { currentSession, isLoading, isGroup, paymentSummary, hideMakePaymentButton, payments } =
    props;

  const showComments = 'showComments' in props && props.showComments;
  const commentsFieldRequired = 'commentsFieldRequired' in props && props.commentsFieldRequired;
  const paymentComments = 'paymentComments' in props ? props.paymentComments : undefined;
  const providedAddons = 'addons' in props ? props.addons : null;
  const providedActivities = 'activities' in props ? props.activities : null;
  const paymentCategoriesAmount =
    'paymentCategories' in props ? props.paymentCategories.length : null;
  const onPaymentCommentsChange =
    'onPaymentCommentsChange' in props ? props.onPaymentCommentsChange : null;
  const onMakePayment = 'onMakePayment' in props ? props.onMakePayment : null;

  const handlePaymentCommentsChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onPaymentCommentsChange && onPaymentCommentsChange(e.target.value);
    },
    [onPaymentCommentsChange],
  );

  const handleMakePayment = useCallback(() => {
    onMakePayment && onMakePayment();
  }, [onMakePayment]);

  const error = useMemo(() => {
    if (!commentsFieldRequired) return false;
    if (!paymentComments) return 'Notes are required';
    if (paymentComments.length < 5) return 'Notes must be at least 5 characters';
  }, [commentsFieldRequired, paymentComments]);

  const isFeePerPerson = currentSession?.isFeePerPerson;

  const makePaymentTooltipText = useMemo(() => {
    if (!isGroup) return 'Required deposit for selected session';
    if (isFeePerPerson) return 'Required deposit equal to registered people on this session';
    return 'Required deposit equal to one crew base price';
  }, [isGroup, isFeePerPerson]);

  const renderMakePayment = useCallback(
    (makePaymentParams: {
      providedActivitiesSubtotal: number | null;
      providedAddonsSubtotal: number | null;
      existingSelectedActivitiesFee: number;
      existingSelectedAddonsFee: number;
    }) => (
      <Fragment>
        {showComments && (
          <Form.Item help={error} validateStatus={error ? 'error' : ''}>
            <TextAreaMaterial
              labelContent="Notes"
              value={paymentComments}
              onChange={handlePaymentCommentsChange}
            />
          </Form.Item>
        )}
        <MakePayment
          {...makePaymentParams}
          paymentSummary={paymentSummary}
          paymentCategoriesAmount={paymentCategoriesAmount}
          providedAddons={providedAddons}
          onMakePayment={handleMakePayment}
          hideMakePaymentButton={hideMakePaymentButton}
          isLoading={isLoading}
          tooltipText={makePaymentTooltipText}
          payments={payments}
        />
      </Fragment>
    ),
    [
      error,
      hideMakePaymentButton,
      isLoading,
      makePaymentTooltipText,
      paymentCategoriesAmount,
      paymentComments,
      paymentSummary,
      payments,
      providedAddons,
      showComments,
      handleMakePayment,
      handlePaymentCommentsChange,
    ],
  );

  return (
    <Card title="Payment Summary" className={styles.container}>
      {isGroup ? (
        <GroupReservationPaymentSummaryContent
          providedAddons={providedAddons}
          providedActivities={providedActivities}
          selectedSession={currentSession}
          paymentSummary={paymentSummary}
          groupReservation={props.groupReservation}
        >
          {renderMakePayment}
        </GroupReservationPaymentSummaryContent>
      ) : (
        <RegistrantsList
          paymentSummary={paymentSummary}
          providedActivities={providedActivities}
          providedAddons={providedAddons}
          selectedSession={currentSession}
        >
          {renderMakePayment}
        </RegistrantsList>
      )}
    </Card>
  );
};

const mapStateToProps = (state: RootState) => {
  const { allowSplitPayments } = createDataSel('form')(state);

  return {
    // eslint-disable-next-line react/no-unused-prop-types
    nextPageIsLoading: loadingSel(state),
    payments: allowSplitPayments ? createDataSel('payments')(state) : undefined,
  };
};

export default connect<MapStateProps, null, OwnProps, RootState>(mapStateToProps)(
  // using memo here to prevent component from updating while we are fetching data for the next page
  memo(PaymentSummary, (_prevProps: Props, { nextPageIsLoading }: Props) => nextPageIsLoading),
);
