import { Button, Form } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useRef, useMemo } from 'react';
import { Editor } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';

import { getLinkNodeEntry, createEditLink } from '@/modules/richTextEditor/utils';
import InputMaterial from '@/modules/shared/components/InputMaterial';

import { TrimmedVariable } from '../../../../types';

import styles from './CreateEditLink.less';

type Props = {
  onHide?: () => void;
  isVisible?: boolean;
  variables: TrimmedVariable[];
};

const CreateEditLink: React.FC<Props> = ({ onHide, isVisible, variables }) => {
  const [form] = Form.useForm();
  const editor = useSlate();
  const editorSelection = useRef(editor.selection);

  const { validateFields, setFieldsValue, resetFields } = form;

  const variablesValues = useMemo(() => new Set(variables.map(({ value }) => value)), [variables]);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    validateFields().then(({ title, url }) => {
      editor.selection = editorSelection.current;
      createEditLink(editor, url, title);
      resetFields();
      onHide && onHide();
      ReactEditor.focus(editor);
    });
  };

  useEffect(() => {
    if (isVisible && editor.selection) {
      editorSelection.current = editor.selection;
      const linkNodeEntry = getLinkNodeEntry(editor);
      setFieldsValue({
        title: Editor.string(editor, editor.selection),
        url: get(linkNodeEntry, [0, 'url']),
      });
    }
  }, [isVisible, editor, setFieldsValue]);

  return (
    <div className={styles.container}>
      <Form form={form}>
        <Form.Item
          name="title"
          rules={[
            {
              validator: (_rule, value, callback) => {
                if (!value) return callback('Please, insert link text');

                const includesVariable = /\{|\}/.test(value);
                if (includesVariable) {
                  return callback('You can not pass variables here.');
                }

                return callback();
              },
            },
          ]}
        >
          <InputMaterial labelContent="Text" />
        </Form.Item>
        <Form.Item
          name="url"
          rules={[
            {
              validator: (_rule, value: string, callback) => {
                if (!value) return callback('Please, insert link url');

                const bracketsRE = /\{|\}/;

                const hasBrackets = bracketsRE.test(value);
                if (hasBrackets) {
                  const matchingBracketsRE = /\{\{[^{}]*\}\}/;

                  const stringWithoutBrackets = value.replace(matchingBracketsRE, '');
                  if (bracketsRE.test(stringWithoutBrackets)) {
                    return callback('Variable is incorrect');
                  }

                  const insertedVariables = value.match(matchingBracketsRE);
                  const allVariablesInList =
                    insertedVariables && insertedVariables.every(item => variablesValues.has(item));
                  if (!allVariablesInList) {
                    return callback('Variable not found');
                  }
                }
                return callback();
              },
            },
          ]}
        >
          <InputMaterial labelContent="Link" />
        </Form.Item>
        <Button onMouseDown={handleSubmit} size="small">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default CreateEditLink;
