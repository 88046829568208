import React, { PropsWithChildren } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

import { Button } from './THeadButton.styled';

type Props = {
  onClick: () => void;
};

const THeadButton: React.FC<PropsWithChildren<Props>> = ({ children, onClick }) => {
  if (!children) {
    return null;
  }

  return (
    <Button onClick={onClick} type="primary" icon={<AiOutlinePlus />}>
      {children}
    </Button>
  );
};

export default THeadButton;
