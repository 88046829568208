import { RcFile } from 'antd/es/upload';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import { parse } from 'papaparse';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import UploadFileShared from '@/modules/shared/components/UploadFile';
import toastService from '@/modules/toasts/service';

import { RESERVATION_CODE_COLUMN_NAME } from '../../constants';
import { updateFileData } from '../../duck/actions';
import { FileData } from '../../types';

type Props = { onOpenNextStep: () => void };

const UploadFile = forwardRef<{ handleSubmit: () => void }, Props>(({ onOpenNextStep }, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const dispatch = useDispatch();
  const [file, setFile] = useState<RcFile | null>(null);
  const [errors, setErrors] = useState<string[]>([]);

  const fileStatus = useMemo<UploadFileStatus>(() => (errors.length ? 'error' : 'done'), [errors]);

  const showErrors = () => {
    errors.forEach(errorMessage => toastService.error(errorMessage));
  };

  function handleSubmit() {
    if (errors.length) {
      showErrors();
      return;
    }
    if (!file) {
      toastService.error('File not selected');
      return;
    }
    onOpenNextStep();
  }

  const handleUploadFile = (nextFile: RcFile | null) => {
    setFile(nextFile);

    if (!nextFile) {
      dispatch(updateFileData({ columnNames: [], data: [] }));

      return null;
    }

    parse<FileData>(nextFile, {
      header: true,
      skipEmptyLines: true,
      transformHeader: header => {
        if (header.toLowerCase() === 'reservation code') return RESERVATION_CODE_COLUMN_NAME;
        return header;
      },
      complete: ({ data, errors: rowErrors, meta }) => {
        const { fields } = meta;

        setErrors([
          ...rowErrors.map(({ row, message }) => `Row ${row}. ${message}`),
          ...(data.length && fields && !fields.includes(RESERVATION_CODE_COLUMN_NAME)
            ? ['Reservation Code column is missing']
            : []),
        ]);

        if (errors.length) {
          showErrors();

          dispatch(updateFileData({ columnNames: [], data: [] }));
        } else {
          dispatch(updateFileData({ data, columnNames: (fields || []).filter(field => field) }));
        }
      },
    });
  };

  return <UploadFileShared onChange={handleUploadFile} file={file} fileStatus={fileStatus} />;
});

export default UploadFile;
