import { Dayjs } from 'dayjs';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { Payment } from '@/modules/data/dataTypes/paymentList';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';
import {
  AdjustmentPayment,
  OfflinePaymentPayload,
  InitOrbitalTransactionResponse,
  MakeRefundPayload,
} from '../types';

import services from './services';

export const makeRefund = createAsyncAction(
  `${moduleName}/MAKE_REFUND_REQUEST`,
  `${moduleName}/MAKE_REFUND_SUCCESS`,
  `${moduleName}/MAKE_REFUND_FAILURE`,
)<MakeRefundPayload, undefined, Error>();

export const initOrbitalPayment = createAsyncAction(
  `${moduleName}/INIT_ORBITAL_PAYMENT_REQUEST`,
  `${moduleName}/INIT_ORBITAL_PAYMENT_SUCCESS`,
  `${moduleName}/INIT_ORBITAL_PAYMENT_FAILURE`,
)<
  {
    amount: number;
    comments: string;
    formCode: string;
    isGroup: boolean;
    guid: string;
    paymentCategoryCode: string;
    paymentType: string;
    personGUID?: string;
  },
  InitOrbitalTransactionResponse,
  string
>();

export const completeOrbitalPayment = createAsyncAction(
  `${moduleName}/COMPLETE_ORBITAL_PAYMENT_REQUEST`,
  `${moduleName}/COMPLETE_ORBITAL_PAYMENT_SUCCESS`,
  `${moduleName}/COMPLETE_ORBITAL_PAYMENT_FAILURE`,
  `${moduleName}/COMPLETE_ORBITAL_PAYMENT_CANCEL`,
)<
  {
    uID: string;
    isGroup: boolean;
    guid: string;
    sessionId: string;
    responseCode: string;
    formCode: string;
    paymentCategoryCode: string;
    personGUID?: string;
    comments?: string;
    onPaymentSucceeded?: () => void;
  },
  { uID: string; amount: number; creationDate: Dayjs },
  string,
  void
>();

export const iFramePaymentStart = createAction(`${moduleName}/IFRAME_PAYMENT_START`)<void>();

export const makeAdjustmentPayment = createAsyncAction(
  'MAKE_ADJUSTMENT_PAYMENT_REQUEST',
  'MAKE_ADJUSTMENT_PAYMENT_SUCCESS',
  'MAKE_ADJUSTMENT_PAYMENT_FAILURE',
)<AdjustmentPayment, undefined, Error>();

export const changePaymentStatus = createAsyncAction(
  `${moduleName}/CHANGE_PAYMENT_STATUS_REQUEST`,
  `${moduleName}/CHANGE_PAYMENT_STATUS_SUCCESS`,
  `${moduleName}/CHANGE_PAYMENT_STATUS_FAILURE`,
)<
  {
    formCode: string;
    statusCode: Parameters<typeof services.changePaymentStatus$>['0']['statusCode'];
    paymentRecord: Payment;
  },
  undefined,
  ApiError
>();

export const makeOfflinePayment = createAsyncAction(
  `${moduleName}/MAKE_OFFLINE_PAYMENT_REQUEST`,
  `${moduleName}/MAKE_OFFLINE_PAYMENT_SUCCESS`,
  `${moduleName}/MAKE_OFFLINE_PAYMENT_FAILURE`,
)<OfflinePaymentPayload, undefined, ApiError>();
