import { createSelector } from 'reselect';

import { createDataSel, createPageDataSelector } from '@/modules/data/duck/selectors';

import { overviewGroupTabDataParams } from '../constants';

export const groupReservationTabDataSel = createPageDataSelector(overviewGroupTabDataParams);

export const rosterListSel = createSelector(
  [createDataSel('groupReservationRosterList'), createDataSel('roster')],
  (rosterList, roster) => {
    if (!roster || !rosterList) return rosterList;
    const rosterIndex = rosterList.findIndex(({ rosterCode }) => rosterCode === roster.rosterCode);
    if (rosterIndex === -1) return rosterList;
    const nextRoster = {
      ...rosterList[rosterIndex],
      ...roster,
    };
    const nextRosterList = [...rosterList];
    nextRosterList[rosterIndex] = nextRoster;
    return nextRosterList;
  },
);

export const rosterSel = createSelector(
  [createDataSel('rosterDetails'), rosterListSel],
  (rosterDetails, rosterList) => {
    if (!rosterDetails) return null;
    const roster = rosterList?.find(({ rosterCode }) => rosterCode === rosterDetails.rosterCode);
    if (!roster) return null;
    return {
      ...roster,
      ...rosterDetails,
    };
  },
);
