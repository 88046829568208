import { InfoCircleFilled } from '@ant-design/icons';
import { Form, Input, Switch } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cancelGroupReservation } from '@/modules/entities/Reservations/duck/actions';
import { isCancelingGroupReservationSel } from '@/modules/entities/Reservations/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { pageDataSel } from '@/pages/reservation/duck/selectors';

import styles from './CancelGroupReservationModal.less';

const { TextArea } = Input;

const MIN_CHARS = 5;
const MAX_CHARS = 200;

const CANCEL_REASON_FIELD = 'cancellationReason';

const CancelGroupReservationModal: React.FC = () => {
  const [formInstance] = Form.useForm<{
    sendNotification: boolean;
    [CANCEL_REASON_FIELD]: string;
  }>();
  const dispatch = useDispatch();
  const {
    data: { groupReservation },
  } = useSelector(pageDataSel);
  const isLoading = useSelector(isCancelingGroupReservationSel);

  const { groupReservationExpeditionNumber, reservationContact } = groupReservation;
  const { firstName, lastName } = reservationContact;

  const handleSubmit = () => {
    formInstance.validateFields().then(values => {
      dispatch(cancelGroupReservation.request(values));
    });
  };

  const handleGoBack = () => dispatch(closeModal());

  return (
    <ModalComponent
      buttons={[
        {
          title: 'No, go back',
          onClick: handleGoBack,
          size: 'small',
          className: styles.button,
        },
        {
          title: 'Yes, Cancel Now',
          onClick: handleSubmit,
          type: 'primary',
          size: 'small',
          className: styles.button,
        },
      ]}
      title="Cancel Group Reservation"
      inProgress={isLoading}
    >
      <span
        className={styles.subtitle}
      >{`Are you sure you want to cancel reservation ${groupReservationExpeditionNumber} for ${firstName} ${lastName}`}</span>
      <div className="mt-2 mb-2">
        <InfoCircleFilled className={styles.icon} /> Cancelling does not automatically initiate a
        refund.
      </div>
      <Form form={formInstance}>
        <Form.Item
          name="sendNotification"
          initialValue={true}
          valuePropName="checked"
          colon={false}
          label="Send Cancellation Email to reservation owner"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name={CANCEL_REASON_FIELD}
          rules={[
            { required: true, message: 'Cancel Reason is required' },
            {
              max: MAX_CHARS,
              message: `Cannot be longer than ${MAX_CHARS} chars`,
            },
            {
              min: MIN_CHARS,
              message: `Has to be at least ${MIN_CHARS} char long`,
            },
          ]}
        >
          <TextArea
            className={styles.textArea}
            placeholder="Reason for Cancelling"
            rows={5}
            maxLength={MAX_CHARS}
          />
        </Form.Item>
        <Form.Item noStyle dependencies={[CANCEL_REASON_FIELD]}>
          {({ getFieldValue }) => {
            const reason = getFieldValue(CANCEL_REASON_FIELD) || '';
            return (
              <span className={styles.charCount}>
                {reason.length}/{MAX_CHARS}
              </span>
            );
          }}
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default CancelGroupReservationModal;
