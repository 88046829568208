import { Col, Row, Select, Form } from 'antd';
import React, { forwardRef, useImperativeHandle } from 'react';

import { AdditionalFieldsData } from '@/modules/payments/components/PaymentGateway/types';
import { transactionTypes } from '@/modules/payments/constants';

import { AdjustmentTypeValues } from './types';

const { Option } = Select;

const allowedTransactionTypes: Array<keyof typeof transactionTypes> = ['payment', 'transferIn'];

const AdjustmentType = forwardRef<{ handleSubmit: () => Promise<AdditionalFieldsData> }>(
  (_props, ref) => {
    const [form] = Form.useForm<AdjustmentTypeValues>();
    const handleSubmit = () => form.validateFields();
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    return (
      <div className="mt-3">
        <Form form={form}>
          <Row>
            <Col span={10}>
              <Form.Item
                name="transactionTypeCode"
                label="Adjustment Type"
                rules={[{ required: true, message: 'Select an adjustment type' }]}
              >
                <Select placeholder="Adjustment Type" size="large">
                  {Object.entries(transactionTypes)
                    .filter(([code]) =>
                      allowedTransactionTypes.includes(code as keyof typeof transactionTypes),
                    )
                    .map(([code, { label }]) => (
                      <Option key={code}>{label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  },
);

export default AdjustmentType;
