export const sections = {
  reservationSize: {
    title: 'Reservation Size',
    key: 'reservationSize',
  },
  sessionSelected: {
    title: 'Session Selected',
    key: 'sessionSelected',
  },
  activities: {
    title: 'Activities',
    key: 'activities',
  },
  addOns: {
    title: 'Add-Ons',
    key: 'addOns',
  },
  reservationContact: {
    title: 'Reservation Contact',
    key: 'reservationContact',
  },
  pendingReservationContact: {
    title: 'Pending Reservation Contact',
    key: 'pendingReservationContact',
  },
  additionalReservationContact: {
    title: 'Additional Reservation Contact',
    key: 'additionalReservationContact',
  },
};
