import { createAsyncAction } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';

import { RecordCommentRequestPayload } from '../types';

export const updateRecordComment = createAsyncAction(
  'UPDATE_RECORD_COMMENT_REQUEST',
  'UPDATE_RECORD_COMMENT_SUCCESS',
  'UPDATE_RECORD_COMMENT_FAILED'
)<RecordCommentRequestPayload & { onSuccess?: () => void }, undefined, ApiError>();
