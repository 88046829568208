import { DatePicker } from 'antd';
import styled from 'styled-components';

export const DatePickerComponent = styled(DatePicker)`
  &&& {
    .ant-picker {
      width: 100%;
    }
  }
`;
