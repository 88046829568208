import { Checkbox as AntCheckbox, Card as AntCard } from 'antd';
import styled from 'styled-components';

export const Checkbox = styled(AntCheckbox)`
  &&& {
    margin: 0 5px 5px 0;
  }
`;

export const Card = styled(AntCard)`
  border-left: none;
  border-right: none;
`;
