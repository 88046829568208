import { Button } from 'antd';
import React from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import styled from 'styled-components';

import SharedCard from '@/modules/shared/components/Card';
import RegisterButtonShared from '@/modules/shared/components/RegisterButton';
import colors from '@/modules/styles/colors';

export const OpenReservationButton = styled(Button).attrs(() => ({
  children: <AiOutlineRight />,
  ghost: true,
}))`
  &&& {
    box-shadow: none;
    min-width: 0;
    color: ${colors.darkGrey};
    width: fit-content;
    height: fit-content;
  }
`;

export const Card = styled(SharedCard)`
  background: none;

  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const RegisterButton = styled(RegisterButtonShared)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
` as typeof RegisterButtonShared;
