import { Form as BsaForm } from 'antd';
import styled, { css } from 'styled-components';

const extraSpace = css`
  & .ant-col.ant-form-item-control {
    margin-bottom: 16px;
  }
`;

export const Form = styled(BsaForm)`
  ${props => props.layout === 'inline' && extraSpace}

  .ant-form-item {
    min-width: 300px;
  }
  .ant-input {
    width: 100%;
  }
` as typeof BsaForm;

export const FoundPersonLayout = styled.div<{
  direction: 'row' | 'column';
  centered: boolean;
}>`
  margin-top: 16px;
  min-width: '50px';
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => props.direction};
  /* main axis */
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};
  /* cross axis */
  align-items: ${props => (props.centered ? 'center' : 'flex-start')};
  column-gap: 2rem;
`;

export const ButtonRow = styled(BsaForm.Item)`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;

  & > * {
    flex-grow: 1;
  }

  .ant-btn {
    margin: 0;
    width: 100%;
  }
`;

export const FormItem = styled(Form.Item)`
  position: relative;

  .ant-form-item-control-input + div {
    display: inline !important;
  }

  .ant-form-item-label {
    position: absolute;
    z-index: 2;

    top: -24px;

    label {
      height: unset;
      :after {
        content: none;
      }
    }
  }
`;
