import { Button, Card, Row, Col } from 'antd';
import React, { useEffect, useState, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';
import Link from 'redux-first-router-link';
import { RootState } from 'typesafe-actions';

import { scheduleRefresh } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';

import {
  formRecordGuidSel,
  currentGroupReservationGUIDSel,
} from '@/pages/createReservation/duck/selectors';
import {
  navigateToIndividualReservation,
  navigateToGroupReservationOverview,
} from '@/pages/reservation/duck/actions';

type Props = ReturnType<typeof mapState>;

const Confirmation: React.FC<Props> = ({ reservationCode, isGroupEvent, formCode }) => {
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(8);

  useEffect(() => {
    if (!reservationCode) {
      return;
    }

    if (countdown <= 0) {
      dispatch(
        redirect(
          isGroupEvent
            ? navigateToGroupReservationOverview({
                formCode,
                groupReservationGUID: reservationCode,
              })
            : navigateToIndividualReservation({
                formCode,
                formRecordGUID: reservationCode,
              }),
        ),
      );
      dispatch(scheduleRefresh({ dataType: 'personFormList' }));
    } else {
      setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown, reservationCode]);

  return (
    <Card>
      <Row justify="center" align="middle">
        <Col>
          <h2>Thank you for completing your application for this event</h2>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col>
          <p>
            A confirmation email with additional information has been sent to the email address on
            your record.
          </p>
        </Col>
      </Row>
      {reservationCode ? (
        <Fragment>
          <Row justify="center" align="middle">
            <Col>
              <p>Going to your reservation details in:</p>
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col>
              <h3>{countdown}</h3>
            </Col>
          </Row>
        </Fragment>
      ) : (
        <Row justify="center" align="middle">
          <Col>
            <Link to="/events" id="returnToEventsLink">
              <Button type="primary">Return to Events List</Button>
            </Link>
          </Col>
        </Row>
      )}
    </Card>
  );
};

const mapState = (state: RootState) => {
  const { formCode, allowGroupRegistration } = createDataSel('form')(state);

  return {
    formCode,
    isGroupEvent: allowGroupRegistration,
    reservationCode: allowGroupRegistration
      ? (currentGroupReservationGUIDSel(state) as string)
      : (formRecordGuidSel(state) as string),
  };
};

export default connect(mapState)(Confirmation);
