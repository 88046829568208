import { createPageDataParams } from '@/modules/data/utils';

import { moduleName as parentModuleName } from '../../constants';

export const moduleName = 'collaborators';
export const modulePath = [parentModuleName, moduleName];

export const stepDataParams = createPageDataParams(
  { dataType: 'formRolesList' },
  { dataType: 'permissionsList' },
  { dataType: 'collaboratorList' },
);
