import { List } from 'antd';
import { uniq } from 'lodash';
import React from 'react';

import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';

import { EnhancedPaymentCategory } from '../../../../ReservationPaymentsCard';
import { ROW_KEY } from '../../constants';

import Footer from './components/Footer';
import ListItem from './components/ListItem';

type Props = {
  paymentCategories: EnhancedPaymentCategory[];
  allowPayments: boolean;
  totalDueAmount: number;
  selectedRowKeys: string[];
  onSetSelectedRowKeys: (keys: string[]) => void;
  onUpdatePaymentScheduleDueDate?: (paymentCategory: PaymentCategory) => void;
};

const PaymentScheduleList: React.FC<Props> = ({
  paymentCategories,
  onSetSelectedRowKeys,
  onUpdatePaymentScheduleDueDate,
  selectedRowKeys,
  allowPayments,
  totalDueAmount,
}) => {
  const handleSelect = (categoryCode: string, isSelected: boolean) => {
    const nextSelectedRowKeys = isSelected
      ? uniq([...selectedRowKeys, categoryCode])
      : selectedRowKeys.filter(key => key !== categoryCode);

    onSetSelectedRowKeys(nextSelectedRowKeys);
  };

  return (
    <List
      itemLayout="vertical"
      dataSource={paymentCategories}
      renderItem={(paymentCategory: EnhancedPaymentCategory) => (
        <ListItem
          paymentCategory={paymentCategory}
          allowPayments={allowPayments}
          onSelect={handleSelect}
          onUpdatePaymentScheduleDueDate={onUpdatePaymentScheduleDueDate}
          isSelected={selectedRowKeys.includes(paymentCategory[ROW_KEY])}
        />
      )}
      footer={<Footer totalDueAmount={totalDueAmount} />}
    />
  );
};

export default PaymentScheduleList;
