import React, { forwardRef } from 'react';

import { PaymentMethod } from '@/modules/payments/constants';
import { paymentMethodsIncludes } from '@/modules/payments/utils';

import { AdditionalFieldsData } from '../../../../types';

import AdjustmentType from './components/AdjustmentType';
import CheckFields from './components/CheckFields';
import CostCenter from './components/CostCenter';

type Props = {
  paymentMethod: PaymentMethod | null;
};

const AdditionalFields = forwardRef<{ handleSubmit: () => Promise<AdditionalFieldsData> }, Props>(
  ({ paymentMethod }, ref) => {
    if (paymentMethodsIncludes([PaymentMethod.check], paymentMethod)) {
      return <CheckFields ref={ref} />;
    }

    if (paymentMethodsIncludes([PaymentMethod.adjustment], paymentMethod)) {
      return <AdjustmentType ref={ref} />;
    }

    if (paymentMethodsIncludes([PaymentMethod.costCenter], paymentMethod)) {
      return <CostCenter ref={ref} />;
    }

    return null;
  },
);

export default AdditionalFields;
