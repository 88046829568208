import { createAsyncAction } from 'typesafe-actions';

import { Person } from '@/modules/data/dataTypes/person';

import { modulePath } from '../constants';

const moduleNamespace = modulePath.join('/');

export const updatePersonalInformation = createAsyncAction(
  `${moduleNamespace}/UPDATE_PERSONAL_INFORMATION_REQUEST`,
  `${moduleNamespace}/UPDATE_PERSONAL_INFORMATION_SUCCESS`,
  `${moduleNamespace}/UPDATE_PERSONAL_INFORMATION_FAILURE`,
)<Partial<Person>, string, Error>();
