import React, { Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Questions from '@/modules/questions';
import { FormattedFormParts } from '@/modules/questions/types';

import ButtonsRow from '../../components/ButtonRow';
import { addAttendee, collectFormParts } from '../../duck/actions';
import { addAttendeeInitialDataSel, isLoadingSel } from '../../duck/selectors';

import { stepDataSel } from './duck/selectors';

const AddAttendeeFormSubmission: React.FC = () => {
  const dispatch = useDispatch();
  const addAttendeeInitialData = useSelector(addAttendeeInitialDataSel);
  const {
    data: { attendeeDetails, formPartsData, optionSets, visibilitySettings },
  } = useSelector(stepDataSel);
  const formRef = useRef<{ handleSubmit: () => Promise<FormattedFormParts> }>(null);
  const isLoading = useSelector(isLoadingSel);

  if (!addAttendeeInitialData) return null;

  const { attendeeType, formRecordGUID } = addAttendeeInitialData;

  const handleSubmit = () => {
    formRef.current?.handleSubmit().then(values => {
      dispatch(collectFormParts(values));
      dispatch(addAttendee.request(undefined));
    });
  };

  return (
    <Fragment>
      <Questions
        ref={formRef}
        providedAttendeeType={attendeeType.typeCode}
        removeSystemFormParts
        isLoading={isLoading}
        isEditing
        optionSets={optionSets}
        visibilitySettings={visibilitySettings}
        {...(formRecordGUID
          ? {
              attendeeDetails,
            }
          : {
              formPartsData,
            })}
      />
      <ButtonsRow isLoading={isLoading} onNextClick={handleSubmit} nextButtonText="Add Attendee" />
    </Fragment>
  );
};

export default AddAttendeeFormSubmission;
