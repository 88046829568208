import { FormSettings } from '@/modules/data/dataTypes/form';

import { PermissionAction, queryFilterPermissionTargetPrefix } from './constants';

export const createQueryFilterPermissionTarget = (queryFilterCode: string): string =>
  `${queryFilterPermissionTargetPrefix}_${queryFilterCode}`;

export const createIsQueryFilterAccessPermission =
  (queryFilterCode: string, action: PermissionAction) =>
  (permission: FormSettings['assignedRoles'][number]['permissions'][number]): boolean =>
    permission.action === action &&
    permission.target === createQueryFilterPermissionTarget(queryFilterCode);
