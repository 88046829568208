/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ActionCreator, isActionOf } from 'typesafe-actions';

import { logout } from '../user/duck/actions';

export const resetOnAction =
  <R>(reducer: R, ...actions: ActionCreator[]): R =>
  //@ts-ignore
  (state, incomingAction) => {
    if (isActionOf(actions, incomingAction)) {
      //@ts-ignore
      return reducer(undefined, incomingAction);
    }

    //@ts-ignore
    return reducer(state, incomingAction);
  };

export default <R>(reducer: R): R => resetOnAction(reducer, logout.success);
