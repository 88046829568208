import { Spin } from 'antd';
import React, { useMemo } from 'react';
import {
  AiFillContainer,
  AiFillCopy,
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineEdit,
  AiOutlineUndo,
} from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { CDN_URL } from '@/modules/config/config';
import { EventItem } from '@/modules/data/dataTypes/eventList';
import { updateEvent } from '@/modules/entities/Events/duck/actions';
import {
  updatingEventSel,
  isOnRegistrationWindowSel,
} from '@/modules/entities/Events/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { CLONE_EVENT_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';
import Dropdown from '@/modules/shared/components/Dropdown';
import Warning from '@/modules/shared/components/Warning';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { eventDateWithYear } from '@/modules/utils/dateFormats';

import { navigateToCreateEvent } from '@/pages/createEvent/duck/actions';

import { CloneEventModalParams } from '../CloneEventModal/types';

import styles from './EventCard.less';
import { EventCardComponent } from './EventCard.styled';
import Cover from './components/Cover';
import EventCardFooter from './components/EventCardFooter';
import EventCardTitle from './components/EventCardTitle';
import EventDescription from './components/EventDescription';

interface Props {
  isLoggedIn: boolean;
  allowRegister: boolean;
  eventItem: EventItem;
}

const EventCard: React.FC<Props> = ({ isLoggedIn, allowRegister, eventItem }) => {
  const dispatch = useDispatch();
  const screenSize = useGetScreenSize();

  const {
    formCode,
    formName,
    description,
    eventStartDate,
    eventCloseDate,
    eventLocation,
    isActive,
    isPublished,
    isCancelled,
    eventRegistrationStartDate,
    eventRegistrationCloseDate,
    maxCapacity,
  } = eventItem;

  const updatingForm = useSelector(updatingEventSel);

  const isOnRegistrationWindow = useSelector((state: RootState) =>
    isOnRegistrationWindowSel(state, eventItem),
  );
  const canEditEvent = useSelector(
    createAbilitiesSelector('event', PermissionAction.Update, eventItem),
  );

  const getLabel = () => {
    if (!isActive) {
      return <span className={styles.archived}>ARCHIVED</span>;
    }
    if (!isPublished) {
      return <span className={styles.unpublished}>UNPUBLISHED</span>;
    }
    if (isCancelled) {
      return <span className={styles.cancelled}>CANCELLED</span>;
    }
  };

  const isUpdating = updatingForm === formCode;

  const menuItems = useMemo(() => {
    const handleArchiveClick = () => {
      dispatch(
        updateEvent.request({
          updatedFormData: {
            ...eventItem,
            isActive: !isActive,
            timezoneCode: '',
          },
        }),
      );
    };

    const handleEdit = () => {
      dispatch(navigateToCreateEvent({ formCode, isInitialLoad: true }));
    };
    const handleModalOpen = () => {
      const params: CloneEventModalParams = { formCode, eventName: formName, description };
      dispatch(openModal(CLONE_EVENT_MODAL, params));
    };
    const handleUnpublishClick = () => {
      dispatch(
        updateEvent.request({
          updatedFormData: {
            ...eventItem,
            isPublished: !isPublished,
            timezoneCode: '',
          },
        }),
      );
    };

    return [
      {
        key: 'edit',
        label: 'Edit',
        icon: <AiOutlineEdit />,
        onClick: handleEdit,
      },
      { key: 'duplicate', label: 'Duplicate', onClick: handleModalOpen, icon: <AiFillCopy /> },
      {
        key: 'publish',
        label: isPublished ? 'Unpublish' : 'Publish',
        icon: isPublished ? <AiFillEyeInvisible /> : <AiFillEye />,
        onClick: handleUnpublishClick,
      },
      {
        key: 'unarchive',
        label: isActive ? 'Archive' : 'Unarchive',
        icon: isActive ? <AiFillContainer /> : <AiOutlineUndo />,
        onClick: handleArchiveClick,
      },
    ];
  }, [description, dispatch, eventItem, formCode, formName, isActive, isPublished]);

  return (
    <EventCardComponent $isMobile={screenSize === 'mobile'} $isUpdating={isUpdating}>
      {isUpdating ? (
        <Spin tip="Updating Event" spinning />
      ) : (
        <React.Fragment>
          <div className={styles.labelsContainer}>{getLabel()}</div>
          <div>
            <Cover image={`${CDN_URL}fleur-de-lis-logo.png`} />
            <div className={styles.eventBody} id={`eventInfo-${formCode}`}>
              <div className={styles.titleContainer}>
                <EventCardTitle title={formName} published={isPublished} isActive={isActive} />
                {canEditEvent && (
                  <div className={styles.dropdown}>
                    <Dropdown menuItems={menuItems} />
                  </div>
                )}
              </div>
              <div className={styles.eventInfoWrapper}>
                <div className={styles.eventInfo}>
                  <div>{eventDateWithYear(eventStartDate, eventCloseDate)}</div>
                  {eventLocation && <div className={styles.infoSeparator} />}
                  <div>{eventLocation}</div>
                </div>
                {!isOnRegistrationWindow && (
                  <Warning
                    className={styles.noMargin}
                    showWarningText={false}
                    errorMessage={`Registration period is closed (${eventDateWithYear(
                      eventRegistrationStartDate,
                      eventRegistrationCloseDate,
                    )})`}
                  />
                )}
                <div className={styles.eventInfo}>
                  <b>Capacity:&nbsp;</b>
                  <div>{maxCapacity}</div>
                </div>
              </div>
              <EventDescription
                description={description || 'I am missing my description'}
                id={`eventDescription-${formCode}`}
              />
            </div>
          </div>
          <EventCardFooter
            isLoggedIn={isLoggedIn}
            allowRegister={allowRegister}
            eventItem={eventItem}
          />
        </React.Fragment>
      )}
    </EventCardComponent>
  );
};

export default EventCard;
