export const moduleName = 'addAttendee';

export const ROUTE_ADD_ATTENDEE = `${moduleName}/ROUTE_ADD_ATTENDEE`;

export enum stepKeys {
  personalInfo,
  forms,
}

export const steps = [
  {
    key: stepKeys.personalInfo,
    title: 'Personal Information',
  },
  {
    key: stepKeys.forms,
    title: 'Forms',
  },
];
