import apiService from '@/modules/utils/apiService';

import { UpdateDisclaimerPayload } from '../components/UpdateDisclaimerModal/types';

const updateDisclaimerFormBase$ = (data: {
  disclaimerCode: string;
  formCode: string;
  isGranted: boolean;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateDisclaimerForm',
    data,
  );

const updateDisclaimerForm$ = (payload: { formCode: string; disclaimerCode: string }) =>
  updateDisclaimerFormBase$({ ...payload, isGranted: true });

const unlinkEventDisclaimer$ = (payload: { disclaimerCode: string; formCode: string }) =>
  updateDisclaimerFormBase$({ ...payload, isGranted: false });

const updateDisclaimer$ = (payload: UpdateDisclaimerPayload) =>
  apiService.post$<{ responseDate: string }>(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateDisclaimer',
    {
      ...payload,
      disclaimerContent:
        payload.disclaimerContent &&
        encodeURIComponent(payload.disclaimerContent).replace(/[!'()*]/g, escape),
    },
  );

const deleteDisclaimer$ = (payload: { disclaimerCode: string; formCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteDisclaimer',
    payload,
  );

export default {
  updateDisclaimer$,
  deleteDisclaimer$,
  updateDisclaimerForm$,
  unlinkEventDisclaimer$,
};
