import React, { useRef } from 'react';

import { Collapse } from './Disclaimers.styled';
import DisclaimerItem from './components/DisclaimerItem';
import { Attendee, Disclaimer } from './types';

type Props = {
  attendeeDetails: Attendee;
  disclaimers: Disclaimer[];
  acceptedDisclaimerCodes?: Set<string>;
  onAccept?: (disclaimerCode: string, isAccepted: boolean) => void;
};

const Disclaimers: React.FC<Props> = ({
  attendeeDetails,
  disclaimers,
  acceptedDisclaimerCodes,
  onAccept,
}) => {
  const defaultActiveKeys = useRef(
    onAccept
      ? disclaimers.filter(({ accepted }) => !accepted).map(({ disclaimerCode }) => disclaimerCode)
      : [],
  );

  return (
    <Collapse expandIconPosition="end" defaultActiveKey={defaultActiveKeys.current}>
      {disclaimers.map(disclaimer => {
        const { disclaimerCode } = disclaimer;
        return (
          <DisclaimerItem
            key={disclaimerCode}
            attendeeDetails={attendeeDetails}
            disclaimer={disclaimer}
            isAccepted={!!acceptedDisclaimerCodes && acceptedDisclaimerCodes.has(disclaimerCode)}
            onAccept={onAccept}
          />
        );
      })}
    </Collapse>
  );
};

export default Disclaimers;
