import { createAction, createAsyncAction } from 'typesafe-actions';

import { AttendeeType } from '@/modules/data/dataTypes/attendeeTypeList';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';

export const updateAttendeeTypeOpened = createAction(`${moduleName}/UPDATE_ATTENDEE_TYPE_OPENED`)<
  AttendeeType | Record<string, never>
>();
export const updateAttendeeTypeClosed = createAction(`${moduleName}/UPDATE_ATTENDEE_TYPE_CLOSED`)();

export type UpdateAttendeeTypePayload = Pick<
  AttendeeType,
  'description' | 'isDefault' | 'isLeader' | 'isStaff' | 'maximumUnit' | 'restrictions' | 'typeName'
> &
  Partial<Pick<AttendeeType, 'typeCode'>>;

export const updateAttendeeType = createAsyncAction(
  `${moduleName}/UPDATE_ATTENDEE_TYPE_REQUEST`,
  `${moduleName}/UPDATE_ATTENDEE_TYPE_SUCCESS`,
  `${moduleName}/UPDATE_ATTENDEE_TYPE_FAILURE`,
)<UpdateAttendeeTypePayload, undefined, ApiError>();

export const deleteAttendeeType = createAsyncAction(
  `${moduleName}/DELETE_ATTENDEE_TYPE_REQUEST`,
  `${moduleName}/DELETE_ATTENDEE_TYPE_SUCCESS`,
  `${moduleName}/DELETE_ATTENDEE_TYPE_FAILURE`,
)<{ isDefault: boolean; typeCode: string }, undefined, ApiError>();
