import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import styles from './THeadWrapper.less';

type Props = {
  hasIcon: boolean;
  colsLength: number;
  className: string;
  SubHeaderComponent: React.ComponentType;
  colSpan: number;
};

const THeadWrapper: React.FC<PropsWithChildren<Props>> = ({
  SubHeaderComponent,
  hasIcon,
  colSpan,
  className,
  children,
  ...rest
}) => (
  <thead {...rest} className={cn(className, styles.thead)}>
    {children}
    {SubHeaderComponent && (
      <tr className={styles.subHeader}>
        <th colSpan={colSpan}>
          <SubHeaderComponent />
        </th>
      </tr>
    )}
  </thead>
);

export default THeadWrapper;
