import styled from 'styled-components';

const color = '#67696a';

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  width: 100%;

  :not(:last-child) {
    border-bottom: 1px solid #e9e9e9;
  }

  :hover {
    cursor: pointer;
  }
`;

export const Description = styled.label`
  font-weight: bold;
  color: ${color};
  margin-bottom: 10px;
`;

export const Note = styled.label`
  color: ${color};
  font-size: 14px;
  margin-bottom: 5px;
`;
