import { Button, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { useDispatch } from 'react-redux';

import { FormSettings } from '@/modules/data/dataTypes/form';
import { Payment } from '@/modules/data/dataTypes/paymentList';
import { PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { MAKE_ADJUSTMENT_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import { MakeAdjustmentModalParams } from '@/modules/payments/components/MakeAdjustmentModal/types';
import { transactionTypes } from '@/modules/payments/constants';
import { getPaymentMethodLabel } from '@/modules/payments/utils';

import MenuButton from '../MenuButton';
import PaymentStatus from '../PaymentStatus';

import ExpandedHistoryRow from './components/ExpandedHistoryRow';

import { usDate } from 'UTILS/dateFormats';
import { CURRENCY_FORMAT } from 'UTILS/numberFormats';

type Props = {
  canPostRefunds: boolean;
  canUpdatePaymentStatus: boolean;
  reservationPayments: Payment[];
  formSettings: FormSettings;
  isGroup: boolean;
  guid: string;
  errorMessage?: string;
  paymentSummary: PaymentSummary;
  onMakeRefund: (payment?: Payment) => void;
};

const PaymentHistoryTable: React.FC<Props> = ({
  canPostRefunds,
  canUpdatePaymentStatus,
  reservationPayments,
  formSettings,
  isGroup,
  guid,
  errorMessage,
  paymentSummary,
  onMakeRefund,
}) => {
  const dispatch = useDispatch();

  const { formCode } = formSettings;

  const handleMakeAdjustment = () => {
    const modalParams: MakeAdjustmentModalParams = {
      formCode,
      isGroup,
      guid,
      paymentSummary,
    };
    dispatch(openModal(MAKE_ADJUSTMENT_MODAL, modalParams));
  };

  const columns: ColumnProps<Payment>[] = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: CURRENCY_FORMAT,
    },
    {
      title: 'Payment Method',
      key: 'paymentType',
      render: getPaymentMethodLabel,
    },
    {
      title: 'Transaction Date',
      dataIndex: 'creationDate',
      render: creationDate => usDate(creationDate),
    },
    {
      title: 'Transaction Type',
      key: 'transactionType',
      render: ({ transactionTypeCode }: Payment) => transactionTypes[transactionTypeCode]?.label,
    },
    {
      title: 'Status',
      key: 'status',
      // eslint-disable-next-line react/no-multi-comp
      render: (payment: Payment) => <PaymentStatus payment={payment} />,
    },
  ];

  if (canPostRefunds) {
    columns.push({
      align: 'right',
      title: <Button onClick={handleMakeAdjustment}>Create Adjustment</Button>,
      key: 'makeRefund',
      // eslint-disable-next-line react/no-multi-comp
      render: (record: Payment) => (
        <MenuButton
          canUpdatePaymentStatus={canUpdatePaymentStatus}
          canPostRefunds={canPostRefunds}
          record={record}
          formSettings={formSettings}
          onMakeRefund={onMakeRefund}
        />
      ),
    });
  }

  return (
    <Table
      rowKey="orderId"
      columns={columns}
      dataSource={reservationPayments}
      expandedRowRender={ExpandedHistoryRow}
      locale={{
        emptyText: errorMessage,
      }}
      pagination={{
        position: ['bottomCenter'],
      }}
    />
  );
};

export default PaymentHistoryTable;
