import { Button, Card, Row, Col } from 'antd';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Link from 'redux-first-router-link';

import { sessionNameFromLocationSel } from '../../duck/selectors';

const WaitlistConfirmation: React.FC = () => {
  const sessionName = useSelector(sessionNameFromLocationSel) || '';

  return (
    <Fragment>
      <Card className="mt-6 mb-3 text-center">
        <h2 className="text-primary pt-3">
          Thank you for joining the waitlist for session {sessionName}
        </h2>
        <Row>
          <Col span={14} offset={5}>
            <p className="mt-3">
              An email with additional information has been sent to the email address on your
              record. A registrar will contact you when a spot is available
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={14} offset={5}>
            <Link to="/events" id="returnToEventsLink">
              <Button className="mt-2" type="primary">
                Return to Events List
              </Button>
            </Link>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default WaitlistConfirmation;
