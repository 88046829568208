import { createStore, applyMiddleware, combineReducers, compose, Store } from 'redux';
import { connectRoutes } from 'redux-first-router';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { createEpicMiddleware } from 'redux-observable';
import { RootAction, RootState } from 'typesafe-actions';

import { pathMap } from '@/modules/routing/routes';

import { moduleName as locationModuleName } from '../modules/location/constants';

import epics from './epics';
import initialState from './initialState';
import reducers from './reducers';
import options from './routerOptions';
import { sentryMiddleware } from './sentryMiddleware';

const configureStoreProd = () => {
  const { reducer, middleware, enhancer } = connectRoutes(pathMap, options);

  const rootReducer = combineReducers({ ...reducers, [locationModuleName]: reducer });
  const epicMiddleware = createEpicMiddleware();
  const middleWares = applyMiddleware(sentryMiddleware, middleware, epicMiddleware);
  const enhancers = compose(enhancer, middleWares);

  const store = createStore(rootReducer, initialState, enhancers);

  epicMiddleware.run(epics);

  return store;
};

const configureStoreDev = () => {
  const { reducer, middleware, enhancer } = connectRoutes(pathMap, options);

  const rootReducer = combineReducers({ ...reducers, [locationModuleName]: reducer });
  const epicMiddleware = createEpicMiddleware();
  const middleWares = applyMiddleware(reduxImmutableStateInvariant(), middleware, epicMiddleware);
  const enhancers = composeEnhancers(enhancer, middleWares);

  const store = createStore(rootReducer, initialState, enhancers);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextReducer = require('./reducers').default;
      store.replaceReducer(nextReducer);
    });
  }

  epicMiddleware.run(epics);

  return store;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore =
  process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

let reduxStore: Store<RootState, RootAction>;

// Export store as a singleton. If a service somehow needs it before index, it will return the already created store.
export function getStore(): typeof reduxStore {
  if (!reduxStore) {
    reduxStore = configureStore();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).reduxStore = reduxStore;
  }
  return reduxStore;
}
