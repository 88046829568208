import { Button as AntButton } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Button = styled(AntButton)`
  && {
    color: ${colors.darkGrey};
    box-shadow: none;

    :hover,
    :focus,
    :active {
      color: ${colors.scoutingPaleGray};
      border: none;
    }
  }
`;
