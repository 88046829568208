import { Button } from 'antd';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { EventItem } from '@/modules/data/dataTypes/eventList';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { isOnRegistrationWindowSel } from '@/modules/entities/Events/duck/selectors';
import toastService from '@/modules/toasts/service';
import {
  isLoggedInSel,
  isOlderAgeSel,
  isSystemAdministratorSel,
} from '@/modules/user/duck/selectors';

import { openForm } from '@/pages/createReservation/duck/actions';

type BaseProps = {
  event: EventItem | FormSettings;
  isDisabled?: boolean;
  className?: string;
};

type GroupProps = BaseProps & {
  isGroup: true;
  numberOfReservations: number;
  lastInProgressGroupReservationGUID?: string;
};

type IndividualProps = BaseProps & {
  isGroup: false;
  numberOfRegistrations: number;
  lastInProgressFormRecordGUID?: string;
};

type Props = GroupProps | IndividualProps;

const RegisterButton: React.FC<Props> = props => {
  const { isGroup, event, className } = props;
  const dispatch = useDispatch();
  const isSystemAdministator = useSelector(isSystemAdministratorSel);
  const isLoggedIn = useSelector(isLoggedInSel);
  const isOlder = useSelector(isOlderAgeSel);
  const isOnRegistrationWindow = useSelector((state: RootState) =>
    isOnRegistrationWindowSel(state, event),
  );

  const {
    formCode,
    formName,
    isActive,
    isCollaborator,
    isCreator,
    isPublished,
    allowMultipleRegistrations,
    allowMultipleReservations,
  } = event;

  const isDisabled = useMemo(() => {
    if (props.isDisabled) return true;

    if (isCreator || isCollaborator || isSystemAdministator) return false;

    if (!isOnRegistrationWindow) return true;

    if (props.isGroup) {
      if (props.numberOfReservations) return allowMultipleReservations;
      return false;
    } else {
      if (props.numberOfRegistrations) return allowMultipleRegistrations;
      return false;
    }
  }, [
    allowMultipleRegistrations,
    allowMultipleReservations,
    isCollaborator,
    isCreator,
    isSystemAdministator,
    isOnRegistrationWindow,
    props.isDisabled,
    props.isGroup,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props.numberOfReservations,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props.numberOfRegistrations,
  ]);

  if (!isPublished || !isActive) return null;
  if ('isSecondaryRegistrant' in event && event.isSecondaryRegistrant) return null;

  const handleRegister = () => {
    if (isGroup && isLoggedIn && !isOlder) {
      return toastService.error(
        `Check with your unit or council Reservation Contact about joining a ${formName} reservation.`,
      );
    }

    dispatch(
      openForm({
        formCode,
        ...(props.isGroup
          ? {
              groupReservationGUID: props.lastInProgressGroupReservationGUID,
            }
          : {
              formRecordGUID: props.lastInProgressFormRecordGUID,
            }),
      }),
    );
  };

  return (
    <Button className={className} type="primary" onClick={handleRegister} disabled={isDisabled}>
      {(props.isGroup && props.lastInProgressGroupReservationGUID) ||
      (!props.isGroup && props.lastInProgressFormRecordGUID)
        ? 'FINISH REGISTERING'
        : 'REGISTER'}
    </Button>
  );
};

export default RegisterButton;
