import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import Link from 'redux-first-router-link';

import { Attendee } from '@/modules/data/dataTypes/rosterAttendees';

import { navigateToGroupReservationAttendeeDetails } from '@/pages/reservation/duck/actions';
import { pageDataSel } from '@/pages/reservation/duck/selectors';

type Props = {
  attendee: Attendee;
};

const AttendeeDetailsLink: React.FC<PropsWithChildren<Props>> = ({
  attendee: { formRecordGUID },
  children,
}) => {
  const {
    data: {
      form: { formCode },
      groupReservation: { groupReservationGUID },
    },
  } = useSelector(pageDataSel);

  return (
    <Link
      to={navigateToGroupReservationAttendeeDetails({
        formCode,
        groupReservationGUID: groupReservationGUID,
        formRecordGUID: formRecordGUID,
      })}
    >
      {children}
    </Link>
  );
};

export default AttendeeDetailsLink;
