import { CollapsePanelProps } from 'antd/lib/collapse';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Attendee } from '@/modules/data/dataTypes/attendeeList';
import { createDataSel } from '@/modules/data/duck/selectors';
import { useGetData } from '@/modules/data/hooks';
import { UPDATE_ADDITIONAL_REGISTRANT_ACTIVITIES_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import ReservationActivities from '@/modules/shared/components/ReservationActivities';

import Section from '../Section';

import { ModalParams } from './components/EditAdditionalRegistrantActivitiesModal/types';

type Props = CollapsePanelProps & {
  formRecord: Attendee;
};

const AdditionalRegistrantActivities: React.FC<Props> = ({ formRecord, ...rest }) => {
  const dispatch = useDispatch();
  const { formRecordGUID } = formRecord;
  const { formCode } = useSelector(createDataSel('form'));
  const activities = useSelector(createDataSel('activities'));
  const [formRecordActivities, formRecordActivitiesLoading, refreshFormRecordActivitiesAction] =
    useGetData({
      dataType: 'formRecordActivities',
      queryObj: { formCode, formRecordGUID },
    });

  const handleEdit = () => {
    const modalParams: ModalParams = {
      formRecord,
      availableActivities: activities,
      selectedActivities: formRecordActivities,
      finalActions: [refreshFormRecordActivitiesAction],
    };
    dispatch(openModal(UPDATE_ADDITIONAL_REGISTRANT_ACTIVITIES_MODAL, modalParams));
  };

  return (
    <Section isLoading={formRecordActivitiesLoading}>
      <ReservationActivities
        {...rest}
        isGroup={false}
        activities={formRecordActivities}
        onEditActivities={handleEdit}
      />
    </Section>
  );
};

export default AdditionalRegistrantActivities;
