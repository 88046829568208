import React, { useMemo } from 'react';

import { Status } from './StatusComponent.styled';

type StatusTypes = number | string;

type Props = {
  status: StatusTypes;
  statusLabel?: string;
  successStatuses?: StatusTypes[];
  infoStatuses?: StatusTypes[];
  failureStatuses?: StatusTypes[];
  dangerStatuses?: StatusTypes[];
  successColor?: string;
  infoColor?: string;
  failureColor?: string;
  dangerColor?: string;
  successBackgroundColor?: string;
  infoBackgroundColor?: string;
  failureBackgroundColor?: string;
  dangerBackgroundColor?: string;
  className?: string;
};

const StatusComponent: React.FC<Props> = ({
  status,
  statusLabel,
  successStatuses = [],
  infoStatuses = [],
  failureStatuses = [],
  dangerStatuses = [],
  successColor = '#039b03',
  infoColor = '#067eeb',
  failureColor = '',
  dangerColor = '#ff6200',
  successBackgroundColor = '#e6f5e6',
  infoBackgroundColor = '#e6f2fd',
  failureBackgroundColor = '',
  dangerBackgroundColor = '#fce6e6',
  className,
}) => {
  const [color, backgroundColor] = useMemo(() => {
    if (successStatuses.includes(status)) return [successColor, successBackgroundColor];
    if (infoStatuses.includes(status)) return [infoColor, infoBackgroundColor];
    if (failureStatuses.includes(status)) return [failureColor, failureBackgroundColor];
    if (dangerStatuses.includes(status)) return [dangerColor, dangerBackgroundColor];
    return ['', ''];
  }, [
    status,
    dangerBackgroundColor,
    dangerColor,
    dangerStatuses,
    failureBackgroundColor,
    failureColor,
    failureStatuses,
    infoBackgroundColor,
    infoColor,
    infoStatuses,
    successBackgroundColor,
    successColor,
    successStatuses,
  ]);

  return (
    <Status color={color} backgroundColor={backgroundColor} className={className}>
      {statusLabel || status}
    </Status>
  );
};

export default StatusComponent;
