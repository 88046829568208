import { Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { ATTENDEE_COUNCIL_APPROVAL_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import Banner from '@/modules/shared/components/Banner';
import { useAbilities } from '@/modules/user/duck/abilities';
import { isEADSel } from '@/modules/user/duck/selectors';

import styles from './CouncilEditBanner.less';

type Props = {
  attendeeDetails: AttendeeDetails;
  isCancelled: boolean;
};

const CouncilEditBanner: React.FC<Props> = ({ attendeeDetails, isCancelled }) => {
  const dispatch = useDispatch();
  const abilities = useAbilities();

  const { firstName, lastName } = attendeeDetails;

  const isEad = useSelector((state: RootState) => isEADSel(state, attendeeDetails));

  const canCouncilUpdateStatus = abilities.can({
    target: 'dashboard.attendees.reservation',
    action: 'edit',
    options: { statusCode: attendeeDetails.status, isCancelled, isEADEnabled: isEad },
  });

  const handleRevokeApproval = () => {
    dispatch(openModal(ATTENDEE_COUNCIL_APPROVAL_MODAL));
  };

  return (
    <Banner
      type="info"
      message={
        <div className={styles.toastContent}>
          <span>
            You are reviewing <strong>{` ${firstName} ${lastName}'s `}</strong> application form.
            <strong> This application is already council approved.</strong>
          </span>
          <div>
            {canCouncilUpdateStatus && (
              <Button type="link" size="small" onClick={handleRevokeApproval}>
                REVOKE APPROVAL
              </Button>
            )}
          </div>
        </div>
      }
    />
  );
};

export default CouncilEditBanner;
