import { Epic, combineEpics } from 'redux-observable';
import { EMPTY, Observable, merge, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { RootAction, RootState, isActionOf } from 'typesafe-actions';

import { prefetchData$ } from '@/modules/data/duck/epics';
import { closeModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';
import { updateMissingRecords } from '@/modules/uploadQuestionsModal/duck/actions';
import {
  fileDataSel,
  mappedAnswersSel,
  mappedColumnsSel,
  missingRecordsSel,
} from '@/modules/uploadQuestionsModal/duck/selectors';
import { formatValue } from '@/modules/uploadQuestionsModal/utils';

import { stepDataParams } from '../constants';

import { importAnswers } from './actions';
import { filteredFormPartsSel, stepDataSel } from './selectors';
import services from './services';

export const createGetStepObservable = (formCode: string) => (action$: Observable<RootAction>) =>
  prefetchData$(
    action$,
    ...stepDataParams.map(params => {
      switch (params.dataType) {
        case 'form':
        case 'formPartsData':
        case 'optionSets':
          return { ...params, queryObj: { formCode } };
      }
    }),
  );

const importAnswers$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(importAnswers.request)),
    switchMap(() => {
      const state = state$.value;
      const {
        data: {
          form: { formCode },
        },
      } = stepDataSel(state);
      const mappedColumns = mappedColumnsSel(state);
      const mappedAnswers = mappedAnswersSel(state);
      const filteredFormParts = filteredFormPartsSel(state);
      const fileData = fileDataSel(state);
      const missingRecords = missingRecordsSel(state);

      const attendeesFormParts = fileData
        .filter(({ reservationCode }) => !missingRecords.has(reservationCode))
        .map(({ reservationCode, ...rest }) => ({
          reservationCode,
          formParts: filteredFormParts
            .map(formPart => {
              const { formPartCode, formItems } = formPart;
              return {
                formPartCode,
                formItems: formItems
                  .map(formItem => {
                    const { formItemCode } = formItem;
                    const importedColumnName = mappedColumns[formPartCode][formItemCode];
                    const existingAnswer = rest[importedColumnName];
                    const optionalAnswer =
                      mappedAnswers[formPartCode]?.[formItemCode]?.[existingAnswer];
                    const formItemValue = optionalAnswer || existingAnswer;
                    return {
                      formItemCode,
                      formItemValue: formatValue(
                        reservationCode,
                        formPart,
                        formItem,
                        formItemValue,
                      ),
                    };
                  })
                  .filter(
                    ({ formItemValue }) => typeof formItemValue !== 'string' || !!formItemValue,
                  ),
              };
            })
            .filter(({ formItems }) => formItems.length),
        }))
        .filter(({ formParts }) => formParts.length);

      if (!attendeesFormParts.length) {
        throw new Error('Imported file has no data');
      }

      return services.formRecordsMassUpdate$({ formCode, registrations: attendeesFormParts });
    }),
    switchMap(({ registrations }) => {
      const missingRecords = registrations
        .filter(({ status }) => status === '-4')
        .map(({ reservationCode }) => reservationCode);

      if (missingRecords.length) {
        toastService.warn(
          `Records with following reservation codes are missing and will be excluded: ${missingRecords.join(
            ', ',
          )}`,
        );
        return of(updateMissingRecords(new Set(missingRecords)), importAnswers.request());
      }

      const errors = registrations
        .filter(({ status }) => status !== '1')
        .map(({ reservationCode, message }) => `${reservationCode}: ${message}`);

      if (errors.length) {
        errors.forEach(err => toastService.error(err));
        return EMPTY;
      }
      return of(closeModal(), importAnswers.success());
    }),
    catchError((err: Error, caught) => {
      toastService.error(err.message);
      return merge(caught, of(importAnswers.failure()));
    }),
  );

export default combineEpics(importAnswers$);
