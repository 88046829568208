import { createAction, createAsyncAction } from 'typesafe-actions';

import { moduleName } from '../constants';
import { GetObservable } from '../types';

export const openPrevStep = createAction(`${moduleName}/OPEN_PREV_STEP`)<string>();
export const openNextStep = createAction(`${moduleName}/OPEN_NEXT_STEP`)<string>();

export const openStep = createAsyncAction(
  `${moduleName}/OPEN_STEP_REQUEST`,
  `${moduleName}/OPEN_STEP_SUCCESS`,
  `${moduleName}/OPEN_STEP_FAILURE`,
)<
  {
    moduleName: string;
    getObservable?: GetObservable;
  },
  string,
  Error
>();

export const resetModuleState = createAction(`${moduleName}/RESET_MODULE_STATE`)<string>();
