import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { refreshData } from '@/modules/data/duck/actions';
import { updateFormRecord } from '@/modules/entities/FormRecord/duck/actions';
import { updateFormRecordInProgressSel } from '@/modules/entities/FormRecord/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import Questions from '@/modules/questions';
import { FormattedFormParts } from '@/modules/questions/types';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { pageDataSel } from '@/pages/reservation/duck/selectors';
import { tabDataSel } from '@/pages/reservation/tabs/AttendeeDetails/duck/selector';

type Props = {
  onEdit: (sectionName: string | null) => void;
  editingSectionName: string | null;
};

const AttendeeFormData: React.FC<Props> = ({ onEdit, editingSectionName }) => {
  const dispatch = useDispatch();
  const questionsRef = useRef<{ handleSubmit: () => Promise<FormattedFormParts> }>(null);
  const {
    data: { form, attendeeDetails, groupReservation },
    isLoading: dataPageIsLoading,
  } = useSelector(pageDataSel);
  const {
    data: { optionSets, visibilitySettings },
  } = useSelector(tabDataSel);

  const { formCode } = form;
  const { formRecordGUID, sessionCode } = attendeeDetails;
  const { isAdditionalReservationContact, isLeadAdvisor, isReservationContact } =
    groupReservation || {};

  const isUpdatingRecord = useSelector(updateFormRecordInProgressSel);

  const isAllowedToEditAttendee =
    useSelector(
      createAbilitiesSelector('attendeeDetails', PermissionAction.Update, { attendeeDetails }),
    ) ||
    isAdditionalReservationContact ||
    isLeadAdvisor ||
    isReservationContact;

  const handleSubmit = () => {
    questionsRef.current?.handleSubmit().then(formParts => {
      dispatch(
        updateFormRecord.request({
          formCode,
          formRecordGUID,
          sessionCode,
          formParts,
          finalActions: [refreshData({ dataType: 'attendeeDetails' })],
        }),
      );
    });
  };

  return (
    <Questions
      ref={questionsRef}
      attendeeDetails={attendeeDetails}
      optionSets={optionSets}
      isLoading={dataPageIsLoading.attendeeDetails || isUpdatingRecord}
      showEditButton={isAllowedToEditAttendee}
      removeSystemFormParts={false}
      visibilitySettings={visibilitySettings}
      onSubmit={handleSubmit}
      onEdit={onEdit}
      editingSectionName={editingSectionName}
    />
  );
};

export default AttendeeFormData;
