/* eslint-disable @typescript-eslint/no-explicit-any */
import { iif, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import apiService, { ApiResponse, createParamsFromObject } from '@/modules/utils/apiService';

import { DataTypes } from '../dataTypes';
import type { QueryObj, ArnicaServiceDefinition, ESBServiceDefinition } from '../types';

export const arnicaServiceDefinitions: Partial<Record<keyof DataTypes, ArnicaServiceDefinition>> = {
  activities: {
    scriptCode: 'GetActivities',
    responseField: 'activities',
  },
  addons: {
    scriptCode: 'GetAddons',
    responseField: 'addons',
  },
  allDisclaimersAccepted: {
    scriptCode: 'AllDisclaimersAccepted',
    scriptSetCode: 'EventData',
    responseField: 'responseValue',
  },
  attendeeTypeList: {
    scriptCode: 'GetAttendeeTypeList',
    responseField: 'attendeeTypes',
  },
  attendeeDetails: {
    scriptCode: 'GetAttendeeDetails',
    scriptSetCode: 'EventData',
  },
  attendeeList: {
    scriptCode: 'GetAttendeeList',
    scriptSetCode: 'EventData',
    responseField: 'attendees',
    method: 'POST',
  },
  attendeeListReport: {
    scriptCode: 'GetFormData',
    scriptSetCode: 'EventData',
    downloadScriptCode: 'GetFormDataStart',
    responseField: 'formRecords',
    method: 'POST',
  },
  attendeeTypesForPersonList: {
    scriptCode: 'GetAttendeeTypeForPersonList',
    responseField: 'attendeeTypes',
  },
  attributeList: {
    scriptCode: 'GetAttributes',
    responseField: 'attributes',
  },
  collaboratorList: {
    scriptCode: 'GetCollaboratorList',
    responseField: 'collaborators',
  },
  configuration: {
    scriptCode: 'GetConfiguration',
  },
  councilList: {
    scriptCode: 'GetCouncilList',
    scriptSetCode: 'EventData',
    responseField: 'councils',
  },
  dataBlockItem: {
    scriptCode: 'GetDatablockItem',
    scriptSetCode: 'EventDatablocks',
  },
  dataBlockItemList: {
    scriptCode: 'GetDatablockItemList',
    scriptSetCode: 'EventDatablocks',
    responseField: 'datablockItems',
  },
  dataBlockList: {
    scriptCode: 'GetDatablocks',
    scriptSetCode: 'EventDatablocks',
    responseField: 'datablocks',
  },
  disclaimerList: {
    scriptCode: 'GetDisclaimers',
    responseField: 'disclaimers',
  },
  emailTemplate: {
    scriptCode: 'GetEmailTemplate',
  },
  emailTemplateList: {
    scriptCode: 'GetEmailTemplates',
    responseField: 'emailTemplates',
  },
  eventCategories: {
    scriptCode: 'GetEventCategories',
    responseField: 'eventCategories',
  },
  eventStatistics: {
    scriptCode: 'GetEventStatistics',
    scriptSetCode: 'EventData',
  },
  form: {
    scriptCode: 'GetForm',
  },
  formPartsData: {
    scriptCode: 'GetFormItemList',
  },
  formPaymentData: {
    scriptCode: 'GetFormPaymentData',
    scriptSetCode: 'EventData',
    responseField: 'payments',
    downloadScriptCode: 'GetFormPaymentDataStart',
    method: 'POST',
  },
  formRecordActivities: {
    scriptCode: 'GetFormRecordActivities',
    scriptSetCode: 'EventData',
    responseField: 'activities',
  },
  formRecordAddons: {
    scriptCode: 'GetFormRecordAddons',
    scriptSetCode: 'EventData',
    responseField: 'addons',
  },
  formRecordAttributes: {
    scriptCode: 'GetFormRecordAttributeList',
    scriptSetCode: 'EventData',
    responseField: 'attributes',
  },
  formRecordDisclaimers: {
    scriptCode: 'GetFormRecordDisclaimers',
    scriptSetCode: 'EventData',
    responseField: 'disclaimers',
  },
  formRecordJobs: {
    scriptCode: 'GetFormJobs',
    responseField: 'jobs',
  },
  foundPeople: {
    scriptCode: 'FindPerson',
    responseField: 'payload',
  },
  groupReservationAddons: {
    scriptCode: 'GetGroupReservationAddons',
    scriptSetCode: 'EventData',
    responseField: 'addons',
  },
  groupReservationActivities: {
    scriptCode: 'GetGroupReservationActivities',
    scriptSetCode: 'EventData',
    responseField: 'activities',
  },
  groupReservationRosterList: {
    scriptCode: 'GetGroupReservationRosterList',
    responseField: 'rosters',
  },
  groupReservation: {
    scriptCode: 'GetGroupReservation',
    responseField: 'groupReservation',
  },
  jobDepartmentList: {
    scriptCode: 'GetJobDepartmentList',
    responseField: 'jobDepartments',
  },
  jobList: {
    scriptCode: 'GetJobs',
    responseField: 'jobs',
  },
  loggedActions: {
    scriptCode: 'GetLoggedActions',
    responseField: 'loggedActions',
  },
  mailingList: {
    scriptCode: 'GetMailings',
    responseField: 'mailings',
  },
  multiSessionCapacityRuleList: {
    scriptCode: 'GetMultiSessionRuleList',
    responseField: 'multiSessionRules',
  },
  programs: {
    scriptCode: 'GetPrograms',
    responseField: 'programs',
  },
  queryFilters: {
    scriptCode: 'GetQueryFilters',
    responseField: 'queryFilters',
  },
  queryFilterVariables: {
    scriptCode: 'GetQueryFilterVariables',
    responseField: 'variables',
  },
  personJobDepartmentList: {
    scriptCode: 'GetPersonJobDepartmentList',
    responseField: 'jobDepartments',
  },
  reservationList: {
    scriptCode: 'GetReservationList',
    responseField: 'groups',
    method: 'POST',
  },
  reservationListReport: {
    scriptCode: 'GetReservationGroupList',
    responseField: 'groupReservations',
    downloadScriptCode: 'GetReservationGroupListStart',
    method: 'POST',
  },
  roster: {
    scriptCode: 'GetRoster',
  },
  rosterDetails: {
    scriptCode: 'GetRosterDetails',
  },
  rosterList: {
    scriptCode: 'GetRosterList',
    responseField: 'rosters',
  },
  rosterAttendees: {
    scriptCode: 'GetRosterAttendees',
    responseField: 'members',
  },
  optionSets: {
    scriptCode: 'GetOptionSets',
    scriptSetCode: 'EventFormMetadata',
  },
  paymentSummary: {
    scriptCode: 'GetPaymentsSummary',
    scriptSetCode: 'EventData',
  },
  paymentCategories: {
    scriptCode: 'GetPaymentCategoryList',
    responseField: 'paymentCategories',
  },
  payments: {
    scriptCode: 'GetPayments',
    scriptSetCode: 'EventData',
    responseField: 'payments',
  },
  permissionsList: {
    scriptCode: 'GetRolePermissions',
    responseField: 'rolePermissions',
  },
  formRolesList: {
    scriptCode: 'GetFormRoles',
    responseField: 'roles',
  },
  personRolesList: {
    scriptCode: 'GetPersonRoles',
    responseField: 'roles',
  },
  session: {
    scriptCode: 'GetSession',
  },
  sessionList: {
    scriptCode: 'GetSessionList',
    responseField: 'sessions',
  },
  ledgerAccounts: {
    scriptCode: 'GetGeneralLedgerAccounts',
    responseField: 'generalLedgerAccounts',
  },
  personFormRoles: {
    scriptCode: 'GetPersonFormRole',
    scriptSetCode: 'EventData',
  },
  comments: {
    scriptCode: 'GetFormRecordCommentList',
    scriptSetCode: 'EventData',
    responseField: 'comments',
  },
  timezones: {
    scriptCode: 'GetTimezoneList',
    responseField: 'timezones',
  },
  formDisclaimers: {
    scriptCode: 'GetFormDisclaimers',
    responseField: 'disclaimers',
  },
  disclaimer: {
    scriptCode: 'GetDisclaimer',
  },
  personFormList: {
    scriptCode: 'GetPersonFormList',
    scriptSetCode: 'EventData',
    responseField: 'forms',
  },
  arnicaPerson: {
    scriptCode: 'GetPerson',
    scriptSetCode: 'EventData',
  },
  personEmails: {
    scriptCode: 'GetPersonEmailList',
    scriptSetCode: 'EventData',
    responseField: 'emails',
  },
  visibilitySettings: {
    scriptCode: 'GetVisibilitySettings',
  },
};

const ESBServiceDefinitions: Partial<Record<keyof DataTypes, ESBServiceDefinition>> = {
  personProfile: {
    endpoint: ({ personGuid }) => `/persons/${personGuid}/profile`,
  },
  countries: {
    endpoint: () => '/lookups/address/countries',
  },
  states: {
    endpoint: () => '/lookups/address/states',
  },
};

const arnicaService = (serviceDefinition: ArnicaServiceDefinition, queryObj: QueryObj) => {
  const {
    responseField,
    scriptCode,
    scriptSetCode = 'EventFormMetadata',
    method = 'GET',
  } = serviceDefinition;

  const url = `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=${scriptSetCode}&ScriptCode=${scriptCode}`;

  return iif(
    () => method === 'GET',
    apiService.get$(`${url}${createParamsFromObject(queryObj)}`),
    apiService.post$(url, queryObj),
  ).pipe(
    map(({ responseCode, responseMessage, recordCount, ...data }) => ({
      responseCode,
      responseMessage,
      recordCount,
      data,
      responseField,
    })),
  );
};

const esbProvider = (serviceDefinition: ESBServiceDefinition, queryObj: QueryObj) => {
  const { endpoint } = serviceDefinition;

  const queryStr = endpoint(queryObj);
  return apiService
    .get$<{
      data: any;
    }>(queryStr)
    .pipe(
      map(data => {
        const { responseCode } = data;
        return {
          responseCode: responseCode || '1',
          responseMessage: 'Success',
          data,
        };
      }),
      catchError((error: Error) =>
        of({
          responseCode: '-1',
          responseMessage: error.message,
        }),
      ),
    );
};

export const getService =
  (dataType: keyof DataTypes) =>
  (
    queryObj: QueryObj,
  ): Observable<
    ApiResponse & {
      data?: any;
      recordCount?: number;
      responseField?: string;
    }
  > => {
    if (dataType in arnicaServiceDefinitions) {
      return arnicaService(arnicaServiceDefinitions[dataType] as ArnicaServiceDefinition, queryObj);
    }

    return esbProvider(ESBServiceDefinitions[dataType] as ESBServiceDefinition, queryObj);
  };
