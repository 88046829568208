import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Header = styled.span`
  font-family: Roboto;
  color: ${colors.primary_5} !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  margin-right: 4px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  font-family: Roboto;
`;

export const DesktopWrapper = styled.div`
  margin: 0 0.75rem 0.75rem 0;
  display: inline-flex;
  flex-direction: column;
  width: 150px;
`;

export const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  width: 100%;
`;
