import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { moduleName } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const createFormAttributeInProgressSel = createSelector(
  moduleSel,
  ({ createFormAttributeInProgress }) => createFormAttributeInProgress,
);

export const updateAttendeesAttributesInProgressSel = createSelector(
  moduleSel,
  ({ updateAttendeesAttributesInProgress }) => updateAttendeesAttributesInProgress,
);
