import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import apiService from '@/modules/utils/apiService';

interface UpdateInstallment {
  formCode: string;
  paymentCategories: PaymentCategory[];
}

interface DeleteInstallment {
  formCode: string;
  paymentCategoryCode: string;
}

const updateInstallments$ = (payload: UpdateInstallment) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdatePaymentCategory',
    payload,
  );

const deleteInstallment$ = ({ formCode, paymentCategoryCode }: DeleteInstallment) =>
  apiService.get$(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeletePaymentCategory&formCode=${formCode}&paymentCategoryCode=${paymentCategoryCode}`,
  );

export default {
  updateInstallments$,
  deleteInstallment$,
};
