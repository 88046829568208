import styled from 'styled-components';

type Props = {
  opened: boolean;
};

export const PaymentItemSummaryWrapper = styled.div<Props>`
  padding-top: 10px;
  max-height: ${({ opened }) => (opened ? 100 : 0)}px;
  transition: max-height 300ms;
  overflow: hidden;
`;

export const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
