import { boolValue } from '../config/utils';

export const moduleName = 'payments';

export enum PaymentMode {
  Past = 'past',
  Total = 'total',
  Next = 'next',
  Custom = 'custom',
}

export enum PaymentMethod {
  creditCard = 'credit_card',
  eCheckOrbital = 'ECP',
  wireTransfer = 'wire_transfer',
  check = 'check',
  cash = 'cash',
  adjustment = 'adjustment',
  costCenter = 'costCenter',
}

export const paymentMethodsData: Map<
  PaymentMethod,
  {
    paymentMethodPickerTitle: string;
    label: string;
    description: string;
    note?: string;
    enabled: boolean;
    isOffline?: boolean;
  }
> = new Map([
  [
    PaymentMethod.creditCard,
    {
      paymentMethodPickerTitle: 'Pay Via Credit Card',
      label: 'Credit Card',
      description: 'Payment bank information will be collected using Orbital.',
      note: 'Please allow your browser to display pop-up windows.',
      enabled: boolValue(process.env.REACT_APP_PAYMENT_CREDIT_CARD),
    },
  ],
  [
    PaymentMethod.eCheckOrbital,
    {
      paymentMethodPickerTitle: 'Pay Via E-Check via Orbital',
      label: 'E-Check',
      description: 'Payment bank information will be collected using Orbital.',
      note: 'Please allow your browser to display pop-up windows.',
      enabled: boolValue(process.env.PAYMENT_METHOD_E_CHECK_ORBITAL),
    },
  ],
  [
    PaymentMethod.wireTransfer,
    {
      paymentMethodPickerTitle: 'Pay Via Wire Transfer',
      label: 'Wire Transfer',
      description: 'Payment bank information will be collected using Orbital.',
      enabled: boolValue(process.env.REACT_APP_PAYMENT_WIRE_TRANSFER),
      isOffline: true,
    },
  ],
  [
    PaymentMethod.costCenter,
    {
      paymentMethodPickerTitle: 'Pay Via Cost Center',
      label: 'Cost Center',
      description: '',
      enabled: boolValue(process.env.PAYMENT_METHOD_COST_CENTER),
      isOffline: true,
    },
  ],
  [
    PaymentMethod.check,
    {
      paymentMethodPickerTitle: 'Pay Via Paper Check',
      label: 'Check',
      description: 'Offline Check',
      enabled: boolValue(process.env.REACT_APP_PAYMENT_CHECK),
      isOffline: true,
    },
  ],
  [
    PaymentMethod.adjustment,
    {
      paymentMethodPickerTitle: 'Make Adjustment',
      label: 'Adjustment',
      description: 'Miscellaneous adjustments to balance',
      enabled: boolValue(process.env.PAYMENT_METHOD_ADJUSTMENT),
      isOffline: true,
    },
  ],
  [
    PaymentMethod.cash,
    {
      paymentMethodPickerTitle: 'Pay with cash',
      label: 'Cash',
      description: '',
      enabled: false,
      isOffline: true,
    },
  ],
]);

export const transactionStatuses = {
  started: { code: 1, label: 'Started' },
  cancelled: { code: 2, label: 'Cancelled' },
  failed: { code: 3, label: 'Failed' },
  completed: { code: 4, label: 'Completed' },
} as const;

export type AllowedMethods =
  | PaymentMethod.creditCard
  | PaymentMethod.costCenter
  | PaymentMethod.eCheckOrbital;

export const installmentMethods = [
  PaymentMethod.creditCard,
  PaymentMethod.costCenter,
  PaymentMethod.eCheckOrbital,
];

export const transactionTypes = {
  registrationFee: {
    label: 'Registration Fee',
    direction: '+',
    category: 'fee',
    target: 'charge',
  },
  registrationFeeReversal: {
    label: 'Registration Fee Reversal',
    direction: '-',
    category: 'fee',
    target: 'charge',
  },
  cancellationFee: {
    label: 'Cancellation Fee',
    direction: '+',
    category: 'fee',
    target: 'charge',
  },
  cancellationFeeReversal: {
    label: 'Cancellation Fee Reversal',
    direction: '-',
    category: 'fee',
    target: 'charge',
  },
  refund: {
    label: 'Refund',
    direction: '-',
    category: 'refund',
    target: 'payment',
  },
  refundReversal: {
    label: 'Refund Reversal',
    direction: '+',
    category: 'refund',
    target: 'payment',
  },
  discount: {
    label: 'Discount',
    direction: '-',
    category: 'fee',
    target: 'charge',
  },
  discountReversal: {
    label: 'Discount Reversal',
    direction: '+',
    category: 'fee',
    target: 'charge',
  },
  scholarship: {
    label: 'Scholarship',
    direction: '-',
    category: 'scholarship',
    target: 'charge',
  },
  scholarshipReversal: {
    label: 'Scholarship Reversal',
    direction: '+',
    category: 'scholarship',
    target: 'charge',
  },
  transferIn: {
    label: 'Transfer In',
    direction: '+',
    category: 'transfer',
    target: 'payment',
  },
  transferInReversal: {
    label: 'Transfer In Reversal',
    direction: '-',
    category: 'transfer',
    target: 'payment',
  },
  transferOut: {
    label: 'Transfer Out',
    direction: '-',
    category: 'transfer',
    target: 'payment',
  },
  transferOutReversal: {
    label: 'Transfer Out Reversal',
    direction: '+',
    category: 'transfer',
    target: 'payment',
  },
  customChargeCredit: {
    label: 'Custom Charge Credit',
    direction: '-',
    category: 'fee',
    target: 'charge',
  },
  customChargeDebit: {
    label: 'Custom Charge Debit',
    direction: '+',
    category: 'fee',
    target: 'charge',
  },
  payment: {
    label: 'Payment',
    direction: '+',
    category: 'payment',
    target: 'payment',
  },
  paymentReversal: {
    label: 'Payment Reversal',
    direction: '-',
    category: 'payment',
    target: 'payment',
  },
} as const;
