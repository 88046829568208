import { FormSettings } from '@/modules/data/dataTypes/form';
import apiService from '@/modules/utils/apiService';

const createForm$ = ({ ...rest }: FormSettings) => {
  const formSettings = {
    ...rest,
    formCode: '',
    isActive: true,
  };
  return apiService.post$<{ formCode: string }>(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=CreateForm',
    formSettings,
  );
};

export default {
  createForm$,
};
