import dayjs from 'dayjs';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { FormSettings } from '@/modules/data/dataTypes/form';
import { createDataSel } from '@/modules/data/duck/selectors';
import { PersonRoles } from '@/modules/entities/Roles/constants';
import { dateFromString } from '@/modules/utils/dateFormats';

import { moduleName, currentUserData } from '../constants';

import reducers from './reducers';
import { getIsEAD, hasAssignedRoleWithCode } from './utils';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const loginInProgressSel = createSelector(
  moduleSel,
  ({ loginInProgress }) => loginInProgress,
);
export const loginDataSel = createSelector(moduleSel, ({ loginData }) => loginData);

export const masqueradeInProgressSel = createSelector(
  moduleSel,
  ({ masqueradeInProgress }) => masqueradeInProgress,
);

export const ageInYearsSel = createSelector(loginDataSel, loginData => {
  const dateOfBirth = get(loginData, ['profile', 'dateOfBirth']);
  if (dateOfBirth) {
    return dayjs().diff(dateOfBirth, 'years');
  }
  return 0;
});

export const isLoggedInSel = createSelector(loginDataSel, loginData => !!loginData);
export const personGuidSel = createSelector(loginDataSel, loginData => loginData?.personGuid);

export const tokenSel = createSelector(loginDataSel, loginData => loginData?.token);

export const sessionTokenSel = createSelector(
  loginDataSel,
  loginData => loginData && loginData.sessionToken,
);

const profileMembershipSel = createSelector(
  [loginDataSel],
  loginData => loginData && loginData.membership,
);

const profileSel = createSelector(
  [loginDataSel, createDataSel('personProfile', currentUserData)],
  (loginData, userData) => {
    if (userData) {
      return userData.profile;
    }
    if (loginData) {
      return loginData.profile;
    }
  },
);

export const fullNameSel = createSelector(
  [profileSel],
  profile => profile && `${profile.firstName || ''} ${profile.lastName || ''}`,
);

export const isProductOwnerSel = createSelector(
  [personGuidSel],
  GUID => GUID === process.env.REACT_APP_PRODUCT_OWNER_GUID,
);

export const genderSel = createSelector(profileSel, profile => profile && profile.gender);
export const memberIdSel = createSelector(profileSel, profile => {
  if (profile && 'memberId' in profile) return profile.memberId;
});

export const isOlderAgeSel = createSelector(
  [createDataSel('arnicaPerson', currentUserData)],
  person => {
    if (!person) return false;
    const { dateOfBirth } = person;
    const dateOfBirthMoment = dateFromString(dateOfBirth);
    return dayjs().diff(dateOfBirthMoment, 'years') >= 21;
  },
);

export const hasMasqueradeRoleSel = createSelector(
  loginDataSel,
  loginData => !!loginData && loginData.hasMasqueradeRole,
);

export const isMainMembershipActiveSel = createSelector(
  [profileMembershipSel],
  profileMembership => profileMembership && !profileMembership.isDelete,
);

export const logoutInProgressSel = createSelector(
  moduleSel,
  ({ logoutInProgress }) => logoutInProgress,
);

export const isSystemAdministratorSel = createSelector(
  [createDataSel('arnicaPerson', currentUserData)],
  person => !!person && hasAssignedRoleWithCode(person.roles, PersonRoles.SystemAdministrator),
);

export const isEventAdministratorSel = createSelector(
  [createDataSel('arnicaPerson', currentUserData)],
  person => !!person && hasAssignedRoleWithCode(person.roles, PersonRoles.EventAdministrator),
);

const formCodeSel = (_state: RootState, { formCode }: Pick<FormSettings, 'formCode'>) => formCode;

// Return list of available hiring departments for the attendee, support multiple departments per attendee per form
export const currentManagerHiringDepartmentListSel = createSelector(
  [formCodeSel, createDataSel('personJobDepartmentList')],
  (formCode, jobDepartments = []) => jobDepartments.filter(dept => dept.formCode === formCode),
);

export const isAlumniSel = createSelector(
  [createDataSel('arnicaPerson', currentUserData)],
  person => hasAssignedRoleWithCode(person.roles, PersonRoles.Alumni),
);
export const isEventApprovalDesigneeSel = createSelector(
  [createDataSel('arnicaPerson', currentUserData)],
  person => hasAssignedRoleWithCode(person?.roles, PersonRoles.EventApprovalDesignee),
);

const councilNumberSel = (
  _state: RootState,
  props: { councilNumber?: string | null; hostCouncilNumber?: string | null },
) => props;

export const isEADSel = createSelector(
  [createDataSel('form'), createDataSel('arnicaPerson', currentUserData), councilNumberSel],
  (form, currentPerson, { councilNumber, hostCouncilNumber }) =>
    getIsEAD(form, currentPerson, councilNumber, hostCouncilNumber),
);

const userOrganizationPositionsSel = createSelector(
  [createDataSel('personProfile', currentUserData)],
  userData => userData?.organizationPositions || [],
);

// if at least one of the positions still active
export const hasActiveMembershipSel = createSelector(
  userOrganizationPositionsSel,
  organizations => {
    const today = dayjs();
    return organizations
      .reduce((acc, { positions }) => {
        const dates = positions.map(({ expirationDate }) => expirationDate);
        return acc.concat(dates);
      }, [] as string[])
      .some(date => today.isSameOrBefore(date));
  },
);

export const defaultOrganizationGuidSel = createSelector(
  userOrganizationPositionsSel,
  organizationPositions => organizationPositions[0]?.organizationGuid,
);

export const primaryEmailSel = createSelector(
  [createDataSel('personProfile', currentUserData)],
  userData => {
    const emails = get(userData, 'emails', []);
    const primaryEmail = emails.find(email => email.isPrimary === 'True');
    return primaryEmail && primaryEmail.email;
  },
);
