import { combineEpics, Epic } from 'redux-observable';
import { concat, EMPTY, merge, defer, of } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { isActionOf, RootAction } from 'typesafe-actions';

import { refreshData, scheduleRefresh } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { updateFormRecord } from '@/modules/entities/FormRecord/duck/actions';
import {
  createGroupReservationRosters,
  updateGroupReservation,
} from '@/modules/entities/Reservations/duck/actions';
import { RESERVATION_SIZE_EDIT_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import rosterPageEpics$ from '../../../tabs/OverviewGroup/components/RosterPage/duck/epics';
import { ModalParams } from '../components/ReservationDetails/components/ReservationSizeEditModal/types';
import rostersListEpics$ from '../components/RostersList/duck/epics';

import {
  openUpdateReservationSizeModal,
  updateFormRegistrationSession,
  updateGroupReservationSession,
  updateReservationSize,
} from './actions';

const openUpdateReservationSizeModal$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(openUpdateReservationSizeModal)),
    switchMap(({ payload: reservation }) => {
      const state = state$.value;
      const modalParams: ModalParams = {
        reservation,
      };
      const { formCode } = createDataSel('form')(state);
      const { sessionCode } = reservation;
      if (!sessionCode) {
        toastService.error('Session Code is empty');
        return EMPTY;
      }
      return concat(
        prefetchData$(action$, { dataType: 'session', queryObj: { formCode, sessionCode } }),
        of(openModal(RESERVATION_SIZE_EDIT_MODAL, modalParams)),
      );
    }),
  );

const updateGroupReservationSession$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateGroupReservationSession.request)),
    switchMap(({ payload: { selectedSessionCode } }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      const {
        groupReservationGUID,
        initialNumberOfAdults,
        initialNumberOfYouth,
        initialPeopleCount,
        initialRosterCount,
      } = createDataSel('groupReservation')(state);

      const updatedReservation = {
        formCode,
        groupReservationGUID,
        initialNumberOfAdults,
        initialNumberOfYouth,
        initialPeopleCount,
        initialRosterCount,
        sessionCode: selectedSessionCode,
      };

      return concat(
        merge(
          action$.pipe(
            filter(isActionOf(updateGroupReservation.failure)),
            take(1),
            switchMap(action => {
              throw new ApiError(action.payload.message, action.payload.responseCode);
            }),
            takeUntil(action$.pipe(filter(isActionOf(updateGroupReservation.success)), take(1))),
          ),
          of(
            updateGroupReservation.request({
              reservation: updatedReservation,
            }),
          ),
        ),
        defer(() => {
          toastService.success('Reservation session updated correctly');

          return merge(
            prefetchData$(action$, {
              dataType: 'groupReservationRosterList',
              queryObj: { formCode, groupReservationGUID, sessionCode: selectedSessionCode },
            }),
            of(
              refreshData({ dataType: 'groupReservation' }),
              refreshData({ dataType: 'paymentSummary' }),
              updateGroupReservationSession.success(),
              closeModal(),
            ),
          );
        }),
      ).pipe(
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(updateGroupReservationSession.failure(error));
        }),
      );
    }),
  );

const updateFormRegistrationSession$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateFormRegistrationSession.request)),
    switchMap(({ payload: { selectedSessionCode } }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      const { formRecordGUID, personGUID } = createDataSel('attendeeDetails')(state);

      return concat(
        merge(
          action$.pipe(
            filter(isActionOf(updateFormRecord.failure)),
            take(1),
            switchMap(action => {
              throw new ApiError(action.payload.message, action.payload.responseCode);
            }),
            takeUntil(action$.pipe(filter(isActionOf(updateFormRecord.success)), take(1))),
          ),
          of(
            updateFormRecord.request({
              personGUID,
              formRecordGUID,
              formCode,
              sessionCode: selectedSessionCode,
              successMessage: 'Registration session updated correctly',
            }),
          ),
        ),
        defer(() =>
          of(
            refreshData({ dataType: 'attendeeDetails' }),
            refreshData({ dataType: 'paymentSummary' }),
            updateFormRegistrationSession.success(),
            closeModal(),
          ),
        ),
      ).pipe(
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(updateFormRegistrationSession.failure(error));
        }),
      );
    }),
  );

const updateReservationSize$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateReservationSize.request)),
    switchMap(
      ({ payload: { initialNumberOfAdults, initialNumberOfYouth, initialRosterCount } }) => {
        const state = state$.value;
        const { formCode } = createDataSel('form')(state);
        const groupReservation = createDataSel('groupReservation')(state);

        const { sessionCode, groupReservationGUID } = groupReservation;

        return concat(
          merge(
            action$.pipe(
              filter(isActionOf(updateGroupReservation.failure)),
              take(1),
              switchMap(action => {
                throw new ApiError(action.payload.message, action.payload.responseCode);
              }),
              takeUntil(action$.pipe(filter(isActionOf(updateGroupReservation.success)), take(1))),
            ),
            of(
              updateGroupReservation.request({
                reservation: {
                  formCode,
                  sessionCode,
                  groupReservationGUID,
                  initialNumberOfYouth,
                  initialNumberOfAdults,
                  initialPeopleCount: initialNumberOfAdults + initialNumberOfYouth,
                  initialRosterCount,
                },
              }),
            ),
          ),
          defer(() => {
            if (initialRosterCount === groupReservation.initialRosterCount) return EMPTY;

            return merge(
              action$.pipe(
                filter(isActionOf(createGroupReservationRosters.failure)),
                take(1),
                switchMap(action => {
                  throw new ApiError(action.payload.message, action.payload.responseCode);
                }),
                takeUntil(
                  action$.pipe(filter(isActionOf(createGroupReservationRosters.success)), take(1)),
                ),
              ),
              of(
                createGroupReservationRosters.request({
                  formCode,
                  groupReservationGUID,
                  sessionCode,
                }),
              ),
            );
          }),
          defer(() => {
            toastService.success('Reservation size updated correctly');
            return of(
              refreshData({ dataType: 'groupReservation' }),
              refreshData({ dataType: 'paymentSummary' }),
              refreshData({ dataType: 'groupReservationRosterList' }),
              scheduleRefresh({ dataType: 'rosterList' }),
              closeModal(),
              updateReservationSize.success(),
            );
          }),
        ).pipe(
          catchError((error: ApiError) => {
            toastService.error(error.message);
            return of(updateReservationSize.failure(error));
          }),
        );
      },
    ),
  );

export default combineEpics(
  openUpdateReservationSizeModal$,
  updateFormRegistrationSession$,
  updateGroupReservationSession$,
  updateReservationSize$,
  rostersListEpics$,
  rosterPageEpics$,
);
