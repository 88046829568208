import { InputNumber as AntInputNumber } from 'antd';
import styled from 'styled-components';

import LabelShared from '@/modules/shared/components/Label';
import colors from '@/modules/styles/colors';

export const Container = styled.div<{
  readonly isDisabled: boolean;
}>`
  margin: 25px 0;
  && * {
    color: ${props => (props.isDisabled ? colors.greyText : '')};
  }
`;

export const Content = styled.div<{ $hasOffset: boolean }>`
  display: inline-flex;
  flex-direction: column;
  margin-left: ${({ $hasOffset }) => ($hasOffset ? 20 : 0)}px;
  width: 100%;
`;

export const Name = styled.h5`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.primary};
`;

export const InputNumber = styled(AntInputNumber)`
  width: 100%;
` as typeof AntInputNumber;

export const Label = styled(LabelShared)`
  color: ${colors.scoutingBlue};
`;
