import { identity, keyBy } from 'lodash';
import React, { useMemo } from 'react';

import { OptionSets } from '@/modules/data/dataTypes/optionSets';
import {
  getOtherValue,
  isOtherValue,
} from '@/modules/entities/FormItems/components/InputSelector/components/WithOtherOption/utils';
import { TrimmedIncomingFormParts } from '@/modules/questions/types';
import FileLink from '@/modules/shared/components/FileLink';
import { formatPhone } from '@/modules/shared/components/InputPhone/utils';
import { PresentationType } from '@/modules/shared/constants';

import { Label } from './ViewOnlyFormItem.styled';

type Props = {
  formItem: TrimmedIncomingFormParts[number]['formItems'][number];
  optionSets: OptionSets;
};

const ViewOnlyFormItem: React.FC<Props> = ({ formItem, optionSets }) => {
  const { formItemName, formItemValue, presentationType, optionSetCode } = formItem;

  const value = useMemo(() => {
    const optionSet = optionSetCode && optionSets[optionSetCode];

    const optionItemsObj = keyBy(optionSet ? optionSet.optionItems : [], 'itemValue');

    switch (presentationType) {
      case PresentationType.SWITCH:
        return formItemValue ? 'True' : 'False';
      case PresentationType.DROPDOWN:
      case PresentationType.DROPDOWN_BULK:
      case PresentationType.RADIO:
        if (isOtherValue(formItemValue)) return getOtherValue(formItemValue);
        if (typeof formItemValue === 'string') {
          if (!formItemValue || !optionItemsObj[formItemValue]) return '-';
          return optionItemsObj[formItemValue].itemLabel;
        }
        break;
      case PresentationType.CHECKBOX:
        if (typeof formItemValue === 'string') {
          return (
            formItemValue
              .split(',')
              .map(v => {
                if (isOtherValue(v)) return v;
                if (optionItemsObj[v]) return optionItemsObj[v].itemLabel;
                return '';
              })
              .filter(identity)
              .join(', ') || '-'
          );
        }
        break;
      case PresentationType.FILE_UPLOAD:
        if (!formItemValue) return null;
        return <FileLink value={formItemValue} />;
      case PresentationType.PHONE:
      case PresentationType.INTERNATIONAL_PHONE:
        if (typeof formItemValue === 'string') {
          return formatPhone(formItemValue);
        }
    }

    return formItemValue;
  }, [formItemValue, optionSetCode, presentationType, optionSets]);

  return <Label label={formItemName}>{value}</Label>;
};

export default ViewOnlyFormItem;
