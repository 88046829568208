import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { addJob, deleteJob } from './actions';

const addJobInProgress = createReducer(false)
  .handleAction(addJob.request, () => true)
  .handleAction([addJob.success, addJob.failure], () => false);

const deleteJobInProgress = createReducer(false)
  .handleAction(deleteJob.request, () => true)
  .handleAction([deleteJob.success, deleteJob.failure], () => false);

export default combineReducers({
  addJobInProgress,
  deleteJobInProgress,
});
