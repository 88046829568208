import apiService from '@/modules/utils/apiService';

import {
  CancelReservationPayload,
  CreateGroupReservationRostersPayload,
  UpdateGroupReservationPayload,
} from '../types';

const cancelIndividualReservation$ = (payload: {
  personGUID: string;
  cancellationPersonGUID: string;
  formCode: string;
  cancelationReason: string;
  cancellationStatusCode: string;
  formRecordGUID: string;
  sendNotification: boolean;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=CancelReservation',
    payload,
  );

const cancelGroupReservation$ = (payload: CancelReservationPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=CancelGroupReservation',
    payload,
  );

const updateGroupReservation$ = (payload: UpdateGroupReservationPayload) =>
  apiService.post$<{
    formRecordGUID: string;
  }>(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateGroupReservation',
    payload,
  );

const createGroupReservationRosters$ = (payload: CreateGroupReservationRostersPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=CreateGroupReservationRosters',
    payload,
  );

export default {
  cancelIndividualReservation$,
  cancelGroupReservation$,
  updateGroupReservation$,
  createGroupReservationRosters$,
};
