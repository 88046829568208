import dayjs from 'dayjs';
import React, { useMemo, forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Person } from '@/modules/data/dataTypes/person';
import PersonData from '@/modules/shared/components/PersonData';
import { dateFromString, usDate } from '@/modules/utils/dateFormats';

import { formatPhone } from '../InputPhone/utils';
import ItemMapper from '../ItemMapper';
import { internationalCouncil, noCouncilAffiliationOption } from '../PersonData/constants';

import { updateProfileInformation } from './duck/actions';

interface Props {
  person: Person;
  isEditing: boolean;
}

const AttendeeProfileInformation = forwardRef<{ handleSubmit: () => void }, Props>(
  ({ person, isEditing }, ref) => {
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));
    const personDataRef = useRef<{
      handleSubmit: () => Promise<Partial<Person>>;
    }>(null);
    const dispatch = useDispatch();

    function handleSubmit() {
      const { personGUID } = person;
      personDataRef.current?.handleSubmit().then(values => {
        dispatch(
          updateProfileInformation({
            ...values,
            personGUID,
          }),
        );
      });
    }

    const items = useMemo(() => {
      const {
        firstName,
        lastName,
        dateOfBirth,
        gender,
        emailAddress,
        phoneAreaCode,
        phoneExchange,
        phoneNumber,
        memberId,
        councilLong,
        councilNumber,
        councilTerritory,
        position,
        addressLine1,
        addressLine2,
        city,
        state,
        country,
        postalCode,
        yptExpirationDate,
      } = person;

      const getYptStatus = (date: string) => {
        const expDate = dateFromString(date);
        if (!expDate.isValid()) return '-';
        const validation = expDate.isSameOrAfter(dayjs(), 'day');
        if (validation) return `Valid through ${usDate(date)}`;
        return `Expired ${usDate(date)}`;
      };

      const phone =
        phoneNumber?.length <= 4 ? phoneAreaCode + phoneExchange + phoneNumber : phoneNumber;
      let personCouncilNumber = councilNumber;

      if (councilNumber === noCouncilAffiliationOption.councilNumber) {
        personCouncilNumber = '-';
      }

      if (councilNumber === internationalCouncil.councilNumber) {
        personCouncilNumber = '-';
      }

      return [
        { value: memberId, label: 'Member Id' },
        { value: firstName, label: 'First Name' },
        { value: lastName, label: 'Last Name' },
        { value: usDate(dateOfBirth), label: 'Date of Birth' },
        { value: gender, label: 'Gender' },
        { value: emailAddress, label: 'Email' },
        { value: formatPhone(phone), label: 'Phone' },
        { value: addressLine1, label: 'Home Address' },
        { value: country, label: 'Country' },
        { value: state, label: 'State' },
        { value: city, label: 'City' },
        { value: addressLine2, label: 'Province/District' },
        { value: postalCode, label: 'Postal Code' },
        { value: councilLong, label: 'Council' },
        { value: position, label: 'Positions' },
        { value: personCouncilNumber, label: 'Council Number' },
        { value: councilTerritory, label: 'Council Territory' },
        { value: getYptStatus(yptExpirationDate), label: 'YPT Status' },
      ];
    }, [person]);

    if (isEditing) {
      return <PersonData ref={personDataRef} person={person} />;
    }

    return <ItemMapper items={items} />;
  },
);

export default AttendeeProfileInformation;
