import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

type Props = {
  title: string;
  subtitle?: string;
  buttonText?: string;
  onClick?: () => void;
};

const EmptyTable: React.FC<Props> = ({ title, subtitle, buttonText, onClick }) => (
  <div>
    <p>
      <strong>{title}</strong>
    </p>
    {!!subtitle && <p>{subtitle}</p>}
    {!!buttonText && (
      <Button icon={<PlusCircleOutlined />} onClick={onClick} type="dashed">
        {buttonText}
      </Button>
    )}
  </div>
);

export default EmptyTable;
