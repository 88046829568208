import { Row } from 'antd';
import React, { useState } from 'react';

import { FormSettings } from '@/modules/data/dataTypes/form';
import { Payment } from '@/modules/data/dataTypes/paymentList';
import { getPaymentMethodLabel } from '@/modules/payments/utils';
import Currency from '@/modules/shared/components/Currency';
import {
  ContentData,
  ContentTitle,
  ListItem,
} from '@/modules/shared/components/ReservationPaymentsCard/ReservationPaymentsCard.styled';
import { usDate } from '@/modules/utils/dateFormats';

import MenuButton from '../../../MenuButton';
import PaymentStatus from '../../../PaymentStatus';

import { IconsContainer, ShowSummaryIcon } from './PaymentHistoryListItem.styled';
import PaymentItemDetails from './components/PaymentItemDetails';

type Props = {
  payment: Payment;
  formSettings?: FormSettings;
  canPostRefunds: boolean;
  canUpdatePaymentStatus: boolean;
  onMakeRefund: (payment?: Payment) => void;
};

const PaymentHistoryListItem: React.FC<Props> = ({
  canPostRefunds,
  canUpdatePaymentStatus,
  payment,
  formSettings,
  onMakeRefund,
}) => {
  const { amount, creationDate } = payment;

  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const handleSummaryToggle = () => {
    setShowPaymentDetails(!showPaymentDetails);
  };

  return (
    <ListItem>
      <IconsContainer>
        {!!formSettings && (
          <MenuButton
            canUpdatePaymentStatus={canUpdatePaymentStatus}
            canPostRefunds={canPostRefunds}
            formSettings={formSettings}
            record={payment}
            onMakeRefund={onMakeRefund}
          />
        )}
        <ShowSummaryIcon $opened={showPaymentDetails} onClick={handleSummaryToggle} />
      </IconsContainer>
      <div>
        <Row>
          <ContentTitle>Amount: </ContentTitle>
          <ContentData>
            <Currency value={amount} />
          </ContentData>
        </Row>
        <Row>
          <ContentTitle>Payment Method: </ContentTitle>
          <ContentData>{getPaymentMethodLabel(payment)}</ContentData>
        </Row>
        <Row>
          <ContentTitle>Transaction Date: </ContentTitle>
          <ContentData>{usDate(creationDate)}</ContentData>
        </Row>
        <Row>
          <ContentTitle>Status: </ContentTitle>
          <ContentData>
            <PaymentStatus payment={payment} />
          </ContentData>
        </Row>
        <PaymentItemDetails payment={payment} opened={showPaymentDetails} />
      </div>
    </ListItem>
  );
};

export default PaymentHistoryListItem;
