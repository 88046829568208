import React from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from '@/modules/shared/components/LoadingContainer';

import { individualReservationTabDataSel } from '@/pages/reservation/tabs/OverviewIndividual/duck/selector';

import { CardNoPadding, CardHeader, Title } from './SecondaryRegistrantsCard.styled';
import SecondaryRegistrantsTable from './components/SecondaryRegistrantsTable';

const SecondaryRegistrantsCard: React.FC = () => {
  const { isLoading } = useSelector(individualReservationTabDataSel);

  return (
    <LoadingContainer isLoading={isLoading.attendeeList}>
      <CardNoPadding>
        <CardHeader>
          <Title>Secondary Registrants</Title>
        </CardHeader>
        <SecondaryRegistrantsTable />
      </CardNoPadding>
    </LoadingContainer>
  );
};
export default SecondaryRegistrantsCard;
