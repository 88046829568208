import { List } from 'antd';
import React from 'react';

import { FormSettings } from '@/modules/data/dataTypes/form';
import { Payment } from '@/modules/data/dataTypes/paymentList';

import PaymentHistoryListItem from './components/PaymentHistoryListItem';

type Props = {
  canPostRefunds: boolean;
  canUpdatePaymentStatus: boolean;
  reservationPayments: Payment[];
  formSettings?: FormSettings;
  errorMessage?: string;
  onMakeRefund: (payment?: Payment) => void;
};

const PaymentHistoryList: React.FC<Props> = ({
  canPostRefunds,
  reservationPayments,
  formSettings,
  errorMessage,
  canUpdatePaymentStatus,
  onMakeRefund,
}) => (
  <List
    locale={{
      emptyText: errorMessage,
    }}
    dataSource={reservationPayments}
    renderItem={(payment: Payment) => (
      <PaymentHistoryListItem
        canPostRefunds={canPostRefunds}
        canUpdatePaymentStatus={canUpdatePaymentStatus}
        payment={payment}
        formSettings={formSettings}
        onMakeRefund={onMakeRefund}
      />
    )}
  />
);

export default PaymentHistoryList;
