import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { saveActivities } from './actions';

const saveActivitiesInProgress = createReducer(false)
  .handleAction(saveActivities.request, () => true)
  .handleAction([saveActivities.success, saveActivities.failure], () => false);

export default combineReducers({
  saveActivitiesInProgress,
});
