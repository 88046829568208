import { Collapse as CollapseBsa, Button } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Panel = styled(CollapseBsa.Panel)`
  margin-bottom: 25px;
  border-radius: 4px;

  &&& > .ant-collapse-header {
    background-color: ${colors.scoutingBlue};
    border-bottom: none;
    color: white;
  }

  & .ant-collapse {
    margin-top: 15px;
    border-left: none;
    border-right: none;
    border-top: none;
  }
`;

export const CancelButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
