import { get } from 'lodash';
import { combineEpics, Epic } from 'redux-observable';
import { catchError, filter, of, switchMap } from 'rxjs';
import { isActionOf, RootAction } from 'typesafe-actions';

import { removeDataItem, replaceDataItem } from '@/modules/data/duck/actions';
import toastService from '@/modules/toasts/service';

import { ACCOUNT_CODE, ACCOUNT_SESSIONS } from '../constants';

import { closeUpdateLedgerAccount, deleteLedgerAccount, updateLedgerAccount } from './actions';
import services from './services';

const updateCreateLedgerAccount$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(updateLedgerAccount.request)),
    switchMap(({ payload }) =>
      services.updateCreateLedgerAccount$(payload).pipe(
        switchMap(({ responseValue }) => {
          const updatedAccount = {
            ...payload,
            [ACCOUNT_CODE]: responseValue,
            [ACCOUNT_SESSIONS]: get(payload, ACCOUNT_SESSIONS, 0),
          };

          return of(
            updateLedgerAccount.success(),
            closeUpdateLedgerAccount(),
            replaceDataItem({
              dataType: 'ledgerAccounts',
              idField: ACCOUNT_CODE,
              dataItem: updatedAccount,
            }),
          );
        }),
        catchError((error: Error) => {
          toastService.error(error.message);
          return of(updateLedgerAccount.failure());
        }),
      ),
    ),
  );

const deleteLedgerAccount$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(deleteLedgerAccount.request)),
    switchMap(({ payload }) => {
      const { formCode, accountCode } = payload;
      return services
        .deleteLedgerAccount$({
          formCode,
          accountCode,
        })
        .pipe(
          switchMap(() =>
            of(
              deleteLedgerAccount.success(),
              removeDataItem({
                dataType: 'ledgerAccounts',
                idField: ACCOUNT_CODE,
                dataItemId: accountCode,
              }),
            ),
          ),
          catchError((error: Error) => {
            toastService.error(error.message);
            return of(deleteLedgerAccount.failure());
          }),
        );
    }),
  );

export default combineEpics(updateCreateLedgerAccount$, deleteLedgerAccount$);
