import { Button } from 'antd';
import React from 'react';

import type { Comment } from '@/modules/data/dataTypes/commentList';
import type { EmailListItem } from '@/modules/data/dataTypes/emailList';
import type { EventLog } from '@/modules/data/dataTypes/eventLogs';
import { FormattedLogItem } from '@/modules/entities/Logs/types';

import { Container } from './LogDetails.styled';
import CommentItem from './components/CommentItem';
import EmailItem from './components/EmailItem';
import LogItem from './components/LogItem';

type Props = {
  onClose: () => void;
  isLoading: boolean;
  log: FormattedLogItem;
};

const LogDetails: React.FC<Props> = ({ onClose, isLoading, log }) => {
  const { type, baseData } = log;

  let details;

  switch (type) {
    case 'comment': {
      const data = baseData as Comment;
      details = <CommentItem commentLog={data} />;
      break;
    }
    case 'email': {
      const data = baseData as EmailListItem;
      details = <EmailItem emailLog={data} />;
      break;
    }
    case 'loggedAction': {
      const data = baseData as EventLog;
      details = <LogItem eventLog={data} />;
      break;
    }
    default:
      details = null;
      break;
  }

  return (
    <Container isLoading={isLoading}>
      <Button type="text" onClick={onClose}>
        Close
      </Button>
      {details}
    </Container>
  );
};

export default LogDetails;
