import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const FormWrapper = styled.section`
  align-content: space-between;
  display: flex;
  flex-direction: column;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ButtonRow = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;

  .bsa-button {
    padding: 0;
    margin: 0;
    min-width: 0;
  }
`;

export const LabelText = styled.p`
  color: ${colors.scoutingBlue};
  font-weight: bold;
  margin-bottom: 16px;
`;

export const MuiInputWrapper = styled.div`
  & label {
    font-size: 14px;
  }
`;
