import { Row, Button } from 'antd';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirect } from 'redux-first-router';

import { refreshData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import SelectAddons from '@/modules/entities/Addons/components/SelectAddons';
import { saveOptionsInProgressSel } from '@/modules/entities/Addons/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import EventCover from '@/modules/shared/components/EventCover';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { navigateToIndividualReservation } from '@/pages/reservation/duck/actions';

import { StyledCard, CardContent, Title } from './EditIndividualReservationAddonsPage.styled';

const { Fragment } = React;

const EditIndividualReservationAddonsPage: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(saveOptionsInProgressSel);
  const { formCode } = useSelector(createDataSel('form'));
  const selectAddonsRef = React.useRef<{ handleSubmit: () => void }>(null);
  const attendeeDetails = useSelector(createDataSel('attendeeDetails'));
  const { formRecordGUID } = attendeeDetails;
  const addonsSelected = useSelector(createDataSel('formRecordAddons'));
  const { addonsSummary } = useSelector(createDataSel('session')) || '';
  const addons = useSelector(createDataSel('addons'));
  const sessions = useSelector(createDataSel('sessionList'));

  const isAllowedToEditAttendee = useSelector(
    createAbilitiesSelector('attendeeDetails', PermissionAction.Update, {
      attendeeDetails: attendeeDetails,
    }),
  );

  const isAllowedToEditAddons = useSelector(
    createAbilitiesSelector('attendeeSelections', PermissionAction.Update, attendeeDetails),
  );

  const currentSession = useMemo(
    () => (sessions || []).find(session => session.sessionCode === attendeeDetails.sessionCode),
    [attendeeDetails.sessionCode, sessions],
  );

  const canEditAddons = isAllowedToEditAddons || (!!currentSession && isAllowedToEditAttendee);

  const handleSubmit = () => {
    selectAddonsRef.current?.handleSubmit();
  };

  const handleBack = () => {
    dispatch(redirect(navigateToIndividualReservation({ formCode, formRecordGUID })));
  };

  if (!canEditAddons) {
    return (
      <StyledCard header="Add-ons" description={addonsSummary}>
        <CardContent>You do not have access</CardContent>
      </StyledCard>
    );
  }

  return (
    <Fragment>
      <EventCover
        title={!!currentSession ? currentSession.sessionName : 'Cancelled Reservation'}
        {...(!!currentSession
          ? { dates: [currentSession.startDate, currentSession.closeDate] }
          : {})}
      />
      <Title>Editing add-ons for Reservation {attendeeDetails.reservationCode}</Title>
      <StyledCard header="Add-ons" description={addonsSummary}>
        <CardContent>
          <SelectAddons
            ref={selectAddonsRef}
            isGroup={false}
            availableAddons={addons}
            selectedAddons={addonsSelected}
            formRecordGUID={attendeeDetails.formRecordGUID}
            formCode={formCode}
            finalActions={[
              refreshData({ dataType: 'attendeeDetails' }),
              refreshData({ dataType: 'formRecordAddons' }),
              refreshData({ dataType: 'paymentSummary' }),
              redirect(navigateToIndividualReservation({ formCode, formRecordGUID })),
            ]}
          />
        </CardContent>
      </StyledCard>
      <Row justify="space-between">
        <Button disabled={isLoading} onClick={handleBack} type="default" id="onBackButton">
          CANCEL
        </Button>
        <Button disabled={isLoading} loading={isLoading} onClick={handleSubmit} type="primary">
          CONFIRM CHANGES
        </Button>
      </Row>
    </Fragment>
  );
};

export default EditIndividualReservationAddonsPage;
