import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { createFormAttribute, updateAttendeesAttributes } from './actions';

const createFormAttributeInProgress = createReducer(false)
  .handleAction(createFormAttribute.request, () => true)
  .handleAction([createFormAttribute.success, createFormAttribute.failure], () => false);

const updateAttendeesAttributesInProgress = createReducer(false)
  .handleAction([updateAttendeesAttributes.request], () => true)
  .handleAction(
    [updateAttendeesAttributes.success, updateAttendeesAttributes.failure],
    () => false,
  );

export default combineReducers({
  createFormAttributeInProgress,
  updateAttendeesAttributesInProgress,
});
