import { Card, Row, Col, Button } from 'antd';
import get from 'lodash/get';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import { contextTokenSel } from '@/modules/location/duck/selectors';
import Warning from '@/modules/shared/components/Warning/Warning';
import { personGuidSel, fullNameSel } from '@/modules/user/duck/selectors';
import { usDate } from '@/modules/utils/dateFormats';

import {
  VALIDATE_RESERVATION_CONTACT_INVITE_ERROR_STATUS,
  VALIDATE_RESERVATION_CONTACT_INVITE_ACCEPT,
  VALIDATE_RESERVATION_CONTACT_INVITE_REJECT,
} from '@/pages/reservation/constants';
import { validateGroupReservationRCInvite } from '@/pages/reservation/duck/actions';
import {
  validatingGroupReservationInviteStatusSel,
  validatingGroupReservationInviteInProgressSel,
} from '@/pages/reservation/duck/selectors';

import WrongLoggedUserCard from './components/WrongLoggedUserCard';

const ReservationContactInviteValidation: React.FC = () => {
  const dispatch = useDispatch();
  const personGUID = useSelector(personGuidSel);
  const status = useSelector(validatingGroupReservationInviteStatusSel);
  const contextToken = useSelector(contextTokenSel);
  const inProgress = useSelector(validatingGroupReservationInviteInProgressSel);
  const fullName = useSelector(fullNameSel);
  const form = useSelector(createDataSel('form')) || {};
  const reservationDetails = useSelector(createDataSel('groupReservation')) || {};

  const { formName, formCode } = form;
  const {
    pendingContact,
    groupReservationExpeditionNumber,
    sessionStartDate,
    groupReservationGUID,
  } = reservationDetails;

  const validateToken = (action: string) =>
    dispatch(
      validateGroupReservationRCInvite.request({
        contextToken,
        action,
        formCode,
        groupReservationGUID,
      }),
    );

  const handleAccept = () => validateToken(VALIDATE_RESERVATION_CONTACT_INVITE_ACCEPT);
  const handleReject = () => validateToken(VALIDATE_RESERVATION_CONTACT_INVITE_REJECT);

  if (personGUID !== get(pendingContact, ['personGUID'])) {
    return <WrongLoggedUserCard />;
  }

  return (
    <React.Fragment>
      {status === VALIDATE_RESERVATION_CONTACT_INVITE_ERROR_STATUS && (
        <Warning errorMessage="An error occurred, please contact Registrar or try again later" />
      )}
      <Card className="mt-6 mb-3 text-center">
        <h2 className="text-primary pt-3">
          {`${fullName}'s Reservation Contact Invitation for ${formName} - ${groupReservationExpeditionNumber}`}
        </h2>
        <Row>
          <Col span={14} offset={5}>
            <p className="mt-3">
              {`You have been invited to take over as the Reservation Contact for reservation ${groupReservationExpeditionNumber}
            arriving at ${formName} on ${usDate(
              sessionStartDate,
            )}. As Reservation Contact, you are the primary
            point of contact for this reservation, and are able to submit payments and invite
            attendees to the reservation`}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={4} offset={8}>
            <Button className="mt-2" danger onClick={handleReject} disabled={inProgress}>
              Reject
            </Button>
          </Col>
          <Col span={4}>
            <Button className="mt-2" type="primary" onClick={handleAccept} disabled={inProgress}>
              Accept
            </Button>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};
export default ReservationContactInviteValidation;
