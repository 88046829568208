import dayjs from 'dayjs';
import { includes, trimStart, uniqueId } from 'lodash';

import { dateFromString, dateToString } from '@/modules/utils/dateFormats';

import { QueryFilterVariable } from '../data/dataTypes/queryFilterVariableList';

import { queryFilterVariablesObjSel } from './duck/selectors';
import { operatorsObj } from './tabs/CurrentReport/components/UpdateFiltersModal/components/ExpressionList/components/Expression/constants';
import {
  Condition,
  FilterContentItem,
  FilterContent,
  ExpressionItem,
  FilterContentSection,
  ExpressionItemDecoded,
  FilterContentExpression,
  ExpressionItemEncoded,
  OperatorValues,
  ReportDataType,
} from './types';

import { FormItemDataType, PresentationType } from 'SHARED/constants';

const TEMPORARY_NAME_PREFIX = 'temporaryName_';

export const getReportDataId = (dataType: ReportDataType) => {
  const typesObj = {
    attendeeListReport: 'attendees',
    reservationListReport: 'reservations',
    formPaymentData: 'transactions',
  } as const;

  return typesObj[dataType];
};

export const getIsEmailsDisabled = (dataType: ReportDataType): dataType is 'formPaymentData' =>
  dataType === 'formPaymentData';

export const isMultipleSelection = (
  queryFilterVariable: QueryFilterVariable,
  operator?: OperatorValues,
) => {
  const { hasOtherOption, presentationType, dataType } = queryFilterVariable;

  const isMultipleOperator = includes(
    [
      operatorsObj.include.value,
      operatorsObj.notinclude.value,
      operatorsObj.isSimilar.value,
      operatorsObj.isNotSimilar.value,
      operatorsObj.inlist.value,
      operatorsObj.notInList.value,
    ],
    operator,
  );
  if (dataType !== FormItemDataType.String || presentationType === PresentationType.TEXT)
    return false;

  return PresentationType.CHECKBOX === presentationType || isMultipleOperator || hasOtherOption;
};

const getPresentationType = (
  columnName: string,
  queryFilterVariablesObj: ReturnType<typeof queryFilterVariablesObjSel>,
) => queryFilterVariablesObj[columnName]?.presentationType;

const decodeExpressionValue = (
  expression: ExpressionItem,
  queryFilterVariablesObj: ReturnType<typeof queryFilterVariablesObjSel>,
): ExpressionItemDecoded => {
  const { ColumnName, Value, Operator } = expression;
  const queryFilterVariable = queryFilterVariablesObj[ColumnName];

  if (isMultipleSelection(queryFilterVariable, Operator)) {
    return {
      ...expression,
      Value: Value.toString().split(','),
    };
  }

  if (getPresentationType(ColumnName, queryFilterVariablesObj) === PresentationType.DATE_PICKER) {
    return {
      ...expression,
      Value: dateFromString(Value),
    };
  }

  return expression;
};

const encodeExpression = ({
  condition,
  ...expression
}: FilterContentExpression): ExpressionItemEncoded => ({
  ...expression,
  Value: Array.isArray(expression.Value) ? expression.Value.join(',') : expression.Value,
});

function getFormulaSection(formula: string): null | [string, string, string] {
  const sectionRegexp = /^\s*(AND|OR)?\s*(\()?.*/;
  const sectionResults = sectionRegexp.exec(formula);

  if (!sectionResults) return null;

  const startIndex = formula.indexOf('(');
  let endIndex = startIndex;
  let counter = 1;

  while (counter) {
    endIndex++;
    switch (formula[endIndex]) {
      case ')':
        counter--;
        break;
      case '(':
        counter++;
        break;
    }
  }
  return [sectionResults[1], formula.slice(startIndex + 1, endIndex), formula.slice(endIndex + 1)];
}

function decodeFilter(
  formulaStr: string,
  expressionsObj: Record<string, ExpressionItem>,
  decodedFilter: FilterContentItem[] = [],
): FilterContentItem[] {
  if (!formulaStr) return decodedFilter;

  const expressionRegexp = /^\s*(AND|OR)?\s*@(exp\d+)?\s*/g;
  const expressionResults = expressionRegexp.exec(formulaStr);

  if (expressionResults) {
    const [, condition, expressionName] = expressionResults;
    const expression = expressionsObj[expressionName];
    return decodeFilter(formulaStr.slice(expressionRegexp.lastIndex), expressionsObj, [
      ...decodedFilter,
      { ...expression, condition: condition as Condition },
    ]);
  }

  const sectionResults = getFormulaSection(formulaStr);

  if (sectionResults) {
    const [condition, formulaInsideSection, restFormula] = sectionResults;
    return decodeFilter(restFormula, expressionsObj, [
      ...decodedFilter,
      {
        condition: condition as Condition,
        expressionList: decodeFilter(formulaInsideSection, expressionsObj),
      },
    ]);
  }

  return decodedFilter;
}

type EncodedFilter = {
  formula: string;
  expressions: ExpressionItem[];
};

interface EncodeFilterParams {
  decodedFilter: FilterContentItem[];
  encodedFilter?: EncodedFilter;
  queryFilterVariablesObj?: ReturnType<typeof queryFilterVariablesObjSel>;
}

const encodeFilter = ({
  decodedFilter,
  encodedFilter = { formula: '', expressions: [] },
  queryFilterVariablesObj,
}: EncodeFilterParams): EncodedFilter => {
  if (!decodedFilter || !decodedFilter.length) return encodedFilter;

  const [decodedFilterItem, ...restDecodedFilterItems] = decodedFilter;
  const { formula, expressions } = encodedFilter;

  const { condition } = decodedFilterItem;
  const conditionStr = condition ? `${condition} ` : '';
  let nextFormula = '';
  let nextExpressions: ExpressionItem[] = [];

  if ('expressionList' in decodedFilterItem) {
    const { expressionList } = decodedFilterItem;

    delete expressionList[0]['condition'];
    const encodedFilterItem = encodeFilter({
      decodedFilter: expressionList,
      queryFilterVariablesObj,
    });
    nextFormula = `(${encodedFilterItem.formula})`;
    nextExpressions = encodedFilterItem.expressions;
  } else {
    const { ExpressionName = uniqueId(TEMPORARY_NAME_PREFIX) } = decodedFilterItem;
    nextFormula = `@${ExpressionName}`;
    const formatedExpresion = queryFilterVariablesObj
      ? formatExpressionValueForPayload(decodedFilterItem, queryFilterVariablesObj)
      : decodedFilterItem;
    nextExpressions = [{ ...formatedExpresion, ExpressionName }];
  }

  return encodeFilter({
    decodedFilter: restDecodedFilterItems,
    encodedFilter: {
      formula: trimStart(`${formula} ${conditionStr}${nextFormula}`),
      expressions: [...expressions, ...nextExpressions],
    },
    queryFilterVariablesObj,
  });
};

export const valueInputIsHidden = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  presentationType?: PresentationType,
  selectedOperator?: OperatorValues,
) => {
  const booleanOperators: Array<OperatorValues> = [
    operatorsObj.isNotBlank.value,
    operatorsObj.isBlank.value,
  ];
  if (selectedOperator && booleanOperators.includes(selectedOperator)) {
    return true;
  }
  if (presentationType === PresentationType.DATE_PICKER) {
    if (value && !dayjs(value).isValid()) return true;
  }
  return false;
};

const formatExpressionValueForPayload = (
  expression: FilterContentExpression,
  queryFilterVariablesObj: ReturnType<typeof queryFilterVariablesObjSel>,
) => {
  const { ColumnName, Value } = expression;
  const presentationType = getPresentationType(ColumnName, queryFilterVariablesObj);

  if (valueInputIsHidden(Value, presentationType)) {
    return expression;
  }

  if (presentationType === PresentationType.DATE_PICKER) {
    return {
      ...expression,
      Value: dateToString(Value),
    };
  }

  return expression;
};

export const decodeFilterContent = (
  filterContentStr: string,
  queryFilterVariablesObj: ReturnType<typeof queryFilterVariablesObjSel>,
): FilterContentItem[] => {
  const filterContent: FilterContent = JSON.parse(decodeURI(filterContentStr));

  const { Formula, Expressions } = filterContent;

  const expressions = Expressions.reduce((acc, expression) => {
    const { ExpressionName } = expression;
    return { ...acc, [ExpressionName]: decodeExpressionValue(expression, queryFilterVariablesObj) };
  }, {});

  const expressionList = decodeFilter(Formula, expressions);
  return expressionList;
};

type FormulaWithExpressionsObj = {
  Formula: string;
  expressionsObj: Record<string, ExpressionItem>;
};

export const encodeFilterContent = (
  decodedFilterContent: FilterContentSection,
  queryFilterVariablesObj?: ReturnType<typeof queryFilterVariablesObjSel>, // queryFilterVariablesObj should be included to format values like dates
): string => {
  const { expressionList } = decodedFilterContent;

  delete expressionList[0]['condition'];
  const { formula: formulaWithTempNames, expressions: expressionsWithTempNames } = encodeFilter({
    decodedFilter: expressionList,
    queryFilterVariablesObj,
  });

  const { Formula, expressionsObj }: FormulaWithExpressionsObj = expressionsWithTempNames
    .slice()
    .sort((a, b) => {
      const aIsTemporary = a.ExpressionName.startsWith(TEMPORARY_NAME_PREFIX);
      const bIsTemporary = b.ExpressionName.startsWith(TEMPORARY_NAME_PREFIX);
      if (aIsTemporary && !bIsTemporary) return 1;
      if (!aIsTemporary && bIsTemporary) return -1;
      return 0;
    })
    .reduce(
      (acc: FormulaWithExpressionsObj, expression) => {
        const { ExpressionName } = expression;
        if (ExpressionName.startsWith(TEMPORARY_NAME_PREFIX)) {
          let uniqueExpressionName = '';

          for (let i = 0; ; i += 1) {
            uniqueExpressionName = `exp${i}`;
            if (!acc.expressionsObj[uniqueExpressionName]) break;
          }

          const nextExpression: ExpressionItem = {
            ...expression,
            ExpressionName: uniqueExpressionName,
          };

          return {
            Formula: acc.Formula.replace(ExpressionName, uniqueExpressionName),
            expressionsObj: {
              ...acc.expressionsObj,
              [uniqueExpressionName]: nextExpression,
            },
          };
        } else {
          return {
            ...acc,
            expressionsObj: {
              ...acc.expressionsObj,
              [ExpressionName]: expression,
            },
          };
        }
      },
      { Formula: formulaWithTempNames, expressionsObj: {} },
    );

  const Expressions = Object.values(expressionsObj).map(encodeExpression);

  const filterContent: FilterContent = { Formula, Expressions };
  return JSON.stringify(filterContent);
};
