import React from 'react';
import { FallbackProps } from 'react-error-boundary';

import {
  MainContainer,
  StyledBSATent,
  ErrorTitle,
  ErrorMessage,
  RoundedBigButton,
  RoundedBigButtonText,
} from './ErrorFallback.styled';

export const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => (
  <MainContainer>
    <StyledBSATent />
    <div>
      <ErrorTitle>Sorry, something went wrong</ErrorTitle>
      {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
    </div>
    <RoundedBigButton onClick={resetErrorBoundary}>
      <RoundedBigButtonText>Take me back to home</RoundedBigButtonText>
    </RoundedBigButton>
  </MainContainer>
);
