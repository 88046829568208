import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

import {
  updateSelections,
  dropSelectedAttendees,
  transferAttendees,
  deselectAttendee,
  removeAttendees,
} from './actions';

const selectedAttendees = createReducer<Set<string>>(new Set([]))
  .handleAction(updateSelections, (_state, { payload }) => payload)
  .handleAction(deselectAttendee, (state, { payload }) => {
    const nextSelections = new Set(state);
    nextSelections.delete(payload);
    return nextSelections;
  })
  .handleAction([dropSelectedAttendees, removeAttendees.success], () => new Set([]));

const removeAttendeesInProgress = createReducer(false, {
  [getType(removeAttendees.request)]: () => true,
  [getType(removeAttendees.success)]: () => false,
  [getType(removeAttendees.failure)]: () => false,
});

const transferAttendeesInProgress = createReducer(false)
  .handleAction(transferAttendees.request, () => true)
  .handleAction([transferAttendees.success, transferAttendees.failure], () => false);

export default combineReducers({
  selectedAttendees,
  removeAttendeesInProgress,
  transferAttendeesInProgress,
});
