import { Form } from 'antd';
import { startCase } from 'lodash';
import React, { useCallback } from 'react';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { FormPartsData } from '@/modules/data/dataTypes/formPartsData';
import { OptionSets } from '@/modules/data/dataTypes/optionSets';
import { VisibilitySettings } from '@/modules/data/dataTypes/visibilitySettings';
import EditableFormSection from '@/modules/shared/components/EditableFormSection';

import { CurrentValuesObj, InitialValuesObj, TrimmedIncomingFormParts } from '../../types';
import { isVisibleByCustomLogic } from '../../utils';

import { Col } from './FormPart.styled';
import EditableFormItem from './components/EditableFormItem';
import ViewOnlyFormItem from './components/ViewOnlyFormItem';

type BaseProps = {
  isEditingAllFormParts?: boolean;
  editingFormPartCode?: string | null;
  formPart: TrimmedIncomingFormParts[number];
  formPartVisibilitySettings?: VisibilitySettings[string];
  initialValues: InitialValuesObj;
  showEditButton: boolean;
  hideButtons: boolean;
  isLoading: boolean;
  optionSets: OptionSets;
  onEditFormPart?: (formPartCode: string | null) => void;
  onSave?: () => void;
};

type AddAttendeeProps = BaseProps & {
  formPartsData: FormPartsData;
  providedAttendeeType: string;
  checkAttendeeTypeOnly?: true;
};

type UpdateAttendeeProps = BaseProps & {
  attendeeDetails: AttendeeDetails;
};

type Props = AddAttendeeProps | UpdateAttendeeProps;

const FormPart: React.FC<Props> = props => {
  const {
    hideButtons,
    formPart,
    isEditingAllFormParts,
    editingFormPartCode,
    initialValues,
    showEditButton,
    isLoading,
    optionSets,
    formPartVisibilitySettings,
    onSave,
    onEditFormPart,
  } = props;

  const form = Form.useFormInstance();

  const { formPartCode, formPartName, formItems, description } = formPart;

  const handleStartEdit = useCallback(() => {
    onEditFormPart && onEditFormPart(formPartCode);
  }, [formPartCode, onEditFormPart]);

  const handleStopEdit = useCallback(() => {
    onEditFormPart && onEditFormPart(null);
  }, [onEditFormPart]);

  const isEditing = isEditingAllFormParts || editingFormPartCode === formPartCode;

  const saveButtonDisabled = !form.isFieldTouched([formPartCode]);

  return (
    <EditableFormSection
      title={startCase(formPartName)}
      canEdit={showEditButton}
      hideButtons={hideButtons}
      isEditing={isEditing}
      onEditClick={handleStartEdit}
      onSave={onSave}
      onCancel={handleStopEdit}
      saveButtonDisabled={saveButtonDisabled}
      isLoading={isLoading}
      description={description}
      wrapButtons
    >
      {formItems.map(fi => (
        <Form.Item key={fi.formItemCode} shouldUpdate noStyle>
          {({ getFieldsValue }) => {
            const currentValues: CurrentValuesObj = getFieldsValue();
            const isVisible = isVisibleByCustomLogic({
              isEditingAllFormParts,
              editingFormPartCode,
              formPartCode,
              formItemCode: fi.formItemCode,
              currentValues,
              initialValues,
            });

            if (!isVisible) return null;

            if (isEditing) {
              return (
                <EditableFormItem
                  {...props}
                  formPartCode={formPartCode}
                  optionSets={optionSets}
                  formItem={fi}
                  formItemVisibilitySettings={
                    formPartVisibilitySettings?.formItems[fi.formItemCode]
                  }
                />
              );
            }

            return (
              <Col>
                <ViewOnlyFormItem formItem={fi} optionSets={optionSets} />
              </Col>
            );
          }}
        </Form.Item>
      ))}
    </EditableFormSection>
  );
};

export default FormPart;
