import React, { Fragment } from 'react';
import { Descendant } from 'slate';

import RenderNode from '../RenderNode';

type Props = {
  nodes: Descendant[];
};

const RenderNodeList: React.FC<Props> = ({ nodes }) => (
  <Fragment>
    {nodes.map((node, index) => (
      <RenderNode node={node} key={index} />
    ))}
  </Fragment>
);

export default RenderNodeList;
