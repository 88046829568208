import { QueryFilter } from '@/modules/data/dataTypes/queryFilterList';
import apiService from '@/modules/utils/apiService';

type UpdateQueryFilterPayload = Omit<
  QueryFilter,
  | 'updateDate'
  | 'creationDate'
  | 'lastRecordCount'
  | 'personGUID'
  | 'firstName'
  | 'lastName'
  | 'queryFilterCode'
> & { queryFilterCode: string | null; apiName: string };

const updateQueryFilter$ = (data: UpdateQueryFilterPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateQueryFilter',
    data,
  );

export default {
  updateQueryFilter$,
};
