import { Button as AntButton } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 2px;
`;

export const ColorsRow = styled.div`
  display: flex;
`;

export const Button = styled(AntButton)`
  min-width: 0;
  width: 20px;
  height: 20px;
  border: solid 2px white;
  padding: 0;
  display: flex;

  :empty {
    width: 20px;
    visibility: visible;
  }
`;
