import { Col, Form, Input, Row, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import { dateToString } from '@/modules/utils/dateFormats';

import { PaymentMethod, transactionTypes } from '../../constants';
import { makeAdjustmentPayment } from '../../duck/actions';
import { makePaymentInProgressSel, makeRefundInProgressSel } from '../../duck/selectors';

import { DatePicker, InputNumber } from './MakeAdjustmentModal.styled';
import Explanation from './components/Explanation';
import { MakeAdjustmentModalParams } from './types';

const TRANSACTION_TYPE_CODE_FIELD = 'transactionTypeCode';
const AMOUNT_FIELD = 'amount';
const POSTED_DATE_FIELD = 'postedDate';
const COMMENTS_FIELD = 'comments';

const maxCommentLength = 200;

type Props = {
  modalParams: MakeAdjustmentModalParams;
};

const MakeAdjustmentModal: React.FC<Props> = ({ modalParams: { paymentSummary, ...params } }) => {
  const [form] = Form.useForm<{
    [TRANSACTION_TYPE_CODE_FIELD]: keyof typeof transactionTypes;
    [AMOUNT_FIELD]: number;
    [POSTED_DATE_FIELD]?: Dayjs;
    [COMMENTS_FIELD]?: string;
  }>();
  const dispatch = useDispatch();
  const makePaymentInProgress = useSelector(makePaymentInProgressSel);
  const makeRefundInProgress = useSelector(makeRefundInProgressSel);

  const handleSucceededPayment = () => {
    dispatch(closeModal());
  };

  const handleSubmit = () => {
    form.validateFields().then(({ postedDate, ...values }) => {
      dispatch(
        makeAdjustmentPayment.request({
          paymentType: PaymentMethod.adjustment,
          ...params,
          ...values,
          ...(postedDate
            ? {
                postedDate: dateToString(postedDate),
              }
            : {}),
          onPaymentSucceeded: handleSucceededPayment,
        }),
      );
    });
  };

  return (
    <ModalComponent
      title="Create Adjustment"
      buttons={[
        {
          title: 'Create Adjustment',
          onClick: handleSubmit,
        },
      ]}
      inProgress={makePaymentInProgress || makeRefundInProgress}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name={TRANSACTION_TYPE_CODE_FIELD}
              label="Adjustment Type"
              rules={[
                {
                  required: true,
                  message: 'Adjustment Type is required',
                },
              ]}
            >
              <Select
                size="large"
                optionFilterProp="label"
                showSearch
                options={Object.entries(transactionTypes).map(([value, { label }]) => ({
                  value,
                  label,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={AMOUNT_FIELD}
              label="Amount"
              dependencies={[TRANSACTION_TYPE_CODE_FIELD]}
              rules={[
                { required: true, message: 'Amount is required' },
                {
                  validator(_rule, value: number | null) {
                    const transactionTypeCode: keyof typeof transactionTypes = form.getFieldValue(
                      TRANSACTION_TYPE_CODE_FIELD,
                    );
                    if (!transactionTypeCode || !value) return Promise.resolve();

                    if (value < 0) return Promise.reject("Amount can't be negative");
                    if (value >= 999999999999999) {
                      return Promise.reject('Number is too big');
                    }

                    const { target, direction } = transactionTypes[transactionTypeCode];
                    const { totalFee, amountPaid } = paymentSummary;
                    const totalAmountDue = totalFee - amountPaid;

                    switch (target) {
                      case 'charge':
                        if (direction === '-' && value > totalAmountDue) {
                          return Promise.reject('Amount is greater than total amount due');
                        }
                        break;
                      case 'payment':
                        if (direction === '-') {
                          if (value > amountPaid) {
                            return Promise.reject('Amount is greater than total amount paid');
                          }
                        }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber prefix="$" size="large" controls={false} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle dependencies={[TRANSACTION_TYPE_CODE_FIELD]}>
          {({ getFieldValue }) => {
            const transactionTypeCode = getFieldValue(TRANSACTION_TYPE_CODE_FIELD);
            return <Explanation transactionTypeCode={transactionTypeCode} />;
          }}
        </Form.Item>
        <Form.Item noStyle dependencies={[TRANSACTION_TYPE_CODE_FIELD]}>
          {({ getFieldValue }) => {
            const transactionTypeCode: keyof typeof transactionTypes | undefined = getFieldValue(
              TRANSACTION_TYPE_CODE_FIELD,
            );

            if (!transactionTypeCode) return null;

            const { target, direction } = transactionTypes[transactionTypeCode];
            const isRefund = target === 'payment' && direction === '-';

            if (!isRefund) return null;

            return (
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    name={POSTED_DATE_FIELD}
                    label="Posted Date"
                    rules={[
                      {
                        required: true,
                        message: 'Please select Date',
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={date => {
                        if (!date) return false;
                        return date.isAfter(dayjs(), 'day');
                      }}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
            );
          }}
        </Form.Item>
        <Form.Item
          name={COMMENTS_FIELD}
          label="Notes"
          rules={[
            {
              max: maxCommentLength,
            },
          ]}
        >
          <Input.TextArea size="large" maxLength={maxCommentLength} showCount />
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default MakeAdjustmentModal;
