import apiService from '@/modules/utils/apiService';

export type RemoveAttendeePayload = {
  formCode: string;
  formRecordGUID: string;
  sessionCode: string;
  rosterCode: string;
};

const removeRosterAttendee$ = (payload: RemoveAttendeePayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=DeleteRosterPerson',
    payload,
  );

const transferAttendees$ = (payload: {
  formCode: string;
  destinationGroupReservationGUID: string;
  destinationSessionCode: string;
  destinationRosterCode: string;
  sourceGroupReservationGUID: string;
  sourceSessionCode: string;
  sourceRosterCode: string;
  members: Array<{
    memberID: string;
    personGUID: string;
    destinationTypeCode: string;
  }>;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=MoveMemberToAnotherRoster',
    payload,
  );

export default {
  transferAttendees$,
  removeRosterAttendee$,
};
