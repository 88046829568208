import { moduleName as parentModuleName } from '../../constants';

export const moduleName = 'addPersonModal';
export const modulePath = [parentModuleName, moduleName];

export const SECONDARY_REGISTRANT_DATA_ID = 'secondaryRegistrantDataId';

export const secondaryRegistrantStepKeys = {
  findPerson: 'findPersonStepKey',
  attendeeTypes: 'attendeeTypesStepKey',
  addons: 'addonsStepKey',
  activities: 'activitiesStepKey',
  questions: 'questionsStepKey',
  checkout: 'checkoutStepKey',
} as const;

export const steps = [
  { key: secondaryRegistrantStepKeys.findPerson, title: 'Personal Information' },
  { key: secondaryRegistrantStepKeys.attendeeTypes, title: 'Attendee Types' },
  { key: secondaryRegistrantStepKeys.addons, title: 'Add-ons' },
  { key: secondaryRegistrantStepKeys.activities, title: 'Activities' },
  { key: secondaryRegistrantStepKeys.questions, title: 'Form' },
  { key: secondaryRegistrantStepKeys.checkout, title: 'Checkout' },
];
