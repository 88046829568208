import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React from 'react';

import LoadingContainer from '@/modules/shared/components/LoadingContainer';

import { Content, Title, Description, Footer, ButtonsRow } from './ModalComponent.styled';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  inProgress?: boolean;
  children?: React.ReactNode;
  buttons?: ButtonProps[];
  centerButtons?: boolean;
  renderFooter?: () => React.ReactNode;
}

const ModalComponent: React.FC<Props> = ({
  title,
  description,
  buttons = [],
  children,
  inProgress = false,
  centerButtons,
  renderFooter,
}) => (
  <LoadingContainer isLoading={inProgress}>
    <Content>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {children}
    </Content>
    {!!(buttons.length || renderFooter) && (
      <Footer>
        {!!renderFooter && renderFooter()}
        {!!buttons.length && (
          <ButtonsRow centerButtons={centerButtons}>
            {buttons.map(({ title: buttonTitle, onClick, type = 'default', ...buttonProps }) => (
              <Button
                key={buttonTitle}
                type={type}
                onClick={onClick}
                disabled={inProgress}
                {...buttonProps}
              >
                {buttonTitle}
              </Button>
            ))}
          </ButtonsRow>
        )}
      </Footer>
    )}
  </LoadingContainer>
);

export default ModalComponent;
