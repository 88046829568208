import { FormSettings } from '@/modules/data/dataTypes/form';
import { JobDepartment } from '@/modules/data/dataTypes/jobDepartmentList';
import { Job } from '@/modules/data/dataTypes/jobList';
import apiService from '@/modules/utils/apiService';

// Observables should have a $ at the end to distinguish them from a promise

// Return list of jobs selected by the attendee per form
const addJob$ = (job: Partial<Job> & Pick<FormSettings, 'formCode'>) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateJob',
    job,
  );

const addDepartment$ = (department: Partial<JobDepartment> & Pick<FormSettings, 'formCode'>) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateJobDepartment',
    department,
  );

const deleteJob$ = (payload: { formCode: string; jobCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteJob',
    payload,
  );

export default {
  addJob$,
  deleteJob$,
  addDepartment$,
};
