import React, { Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Roster } from '@/modules/data/dataTypes/rosterList';
import ActionTable from '@/modules/shared/components/ActionTable';
import Button from '@/modules/shared/components/Button';

import { transferAttendees } from '../../duck/actions';
import { transferAttendeesInProgressSel } from '../../duck/selectors';
import BackButton from '../BackButton';

type Props = {
  onCancel: () => void;
  rosters: Roster[];
};

const RostersListPage: React.FC<Props> = ({ onCancel, rosters }) => {
  const dispatch = useDispatch();
  const transferAttendeesInProgress = useSelector(transferAttendeesInProgressSel);

  const columns = useMemo(() => {
    const createSelectRosterHandler = (roster: Roster) => () => {
      dispatch(transferAttendees.request({ roster }));
    };

    return [
      {
        dataIndex: 'rosterExpeditionNumber',
        title: 'Crew Name',
      },
      {
        dataIndex: 'groupReservationReservationCode',
        title: 'Reservation Code',
      },
      {
        dataIndex: 'sessionName',
        title: 'Session',
      },
      {
        key: 'leadAdvisor',
        title: 'Lead Advisor',
        render: ({ leadAdvisorFirstName, leadAdvisorLastName }: Roster) =>
          `${leadAdvisorFirstName} ${leadAdvisorLastName}`,
      },
      {
        key: 'selectRoster',
        // eslint-disable-next-line react/no-multi-comp
        render: (roster: Roster) => (
          <Button type="link" onClick={createSelectRosterHandler(roster)}>
            SELECT CREW
          </Button>
        ),
      },
    ];
  }, [dispatch]);

  return (
    <Fragment>
      <BackButton onClick={onCancel}>Back to: Attendees List</BackButton>
      <ActionTable
        subject="crew"
        dataSource={rosters}
        rowKey="rosterCode"
        columns={columns}
        isLoading={transferAttendeesInProgress}
      />
    </Fragment>
  );
};

export default RostersListPage;
