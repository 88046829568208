import { StateObservable } from 'redux-observable';
import { concat, defer, Observable } from 'rxjs';
import { RootAction, RootState } from 'typesafe-actions';

import { prefetchData$ } from '@/modules/data/duck/epics';
import { urlFormRecordGUIDSel } from '@/modules/location/duck/selectors';
import { sliceTuple } from '@/modules/utils/typeUtils';

import ParentConsent from './ParentConsent';
import { pageDataParams, ROUTE_ATTENDEE_PARENT_CONSENT } from './constants';
import { pageDataSel } from './duck/selectors';

export default {
  [ROUTE_ATTENDEE_PARENT_CONSENT]: {
    path: '/attendee/parent-consent/:formRecordGUID/',
    component: ParentConsent,
    titleId: 'page.attendeeReservationConsent',
    unAuthenticatedAccess: false,
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const formRecordGUID = urlFormRecordGUIDSel(state$.value);
      const pageDataParams1 = sliceTuple(pageDataParams, 0, 1);
      const pageDataParams2 = sliceTuple(pageDataParams, 1, pageDataParams.length);

      return concat(
        prefetchData$(
          action$,
          ...pageDataParams1.map(params => {
            switch (params.dataType) {
              case 'attendeeDetails':
                return { ...params, queryObj: { formRecordGUID, hideNonVisibleItems: true } };
            }
          }),
        ),
        defer(() => {
          const {
            data: { attendeeDetails },
          } = pageDataSel(state$.value);

          const { formCode } = attendeeDetails;

          return prefetchData$(
            action$,
            ...pageDataParams2.map(params => {
              switch (params.dataType) {
                case 'formRecordDisclaimers':
                case 'allDisclaimersAccepted':
                  return { ...params, queryObj: { formCode, formRecordGUID } };
              }
            }),
          );
        }),
      );
    },
  },
};
