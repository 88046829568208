import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const CharsCounter = styled.span`
  color: ${colors.darkGrey};
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  text-align: right;
  display: inline-block;
`;
