import { DataBlock } from '@/modules/data/dataTypes/dataBlockList';
import { createPageDataParams } from '@/modules/data/utils';

import { moduleName as parentModuleName } from '../../constants';

export const moduleName = 'formBuilder';
export const modulePath = [parentModuleName, moduleName];

export const stepDataParams = createPageDataParams(
  { dataType: 'formPartsData' },
  { dataType: 'formRolesList' },
  { dataType: 'dataBlockList' },
  { dataType: 'optionSets' },
  { dataType: 'visibilitySettings' },
);

export const defaultCustomDataBlock: DataBlock = {
  datablockCode: 'customblock',
  datablockID: 301,
  datablockName: 'Custom Questions',
  description: '',
  formCode: '',
  isActive: true,
  isDefault: false,
  isSystem: false,
  numberOfItems: '0',
};
