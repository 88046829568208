import type { Rule } from 'antd/lib/form';

import { FormPartsData } from '@/modules/data/dataTypes/formPartsData';
import { getPhoneLength } from '@/modules/shared/components/InputPhone/utils';
import { emailRegex } from '@/modules/utils/regularExpressions';

import { isOtherValue } from './components/InputSelector/components/WithOtherOption/utils';
import { OTHER_VALUE_PREFIX } from './constants';

import { PresentationType } from 'SHARED/constants';

export const phoneValidationRule: Rule = {
  validator: (_rule, value: string) => {
    if (!value) return Promise.resolve();

    const requiredLength = getPhoneLength(value);

    if (requiredLength && requiredLength !== value.length) {
      return Promise.reject('Phone is not valid');
    }
    return Promise.resolve();
  },
};

export function generateFormItemRules(
  {
    hasOtherOption,
    presentationType,
    isMandatory,
    matchExpression,
    maximumItemLength,
    maximumValue,
    minimumItemLength,
    minimumValue,
  }: Pick<
    FormPartsData['formParts'][number]['formItems'][number],
    | 'hasOtherOption'
    | 'presentationType'
    | 'isMandatory'
    | 'matchExpression'
    | 'maximumItemLength'
    | 'maximumValue'
    | 'minimumItemLength'
    | 'minimumValue'
  >,
  isDisabled: boolean,
): Rule[] {
  const rules: Rule[] = [
    {
      required: isMandatory && !isDisabled,
      message: 'This field is required',
    },
  ];

  switch (presentationType) {
    case PresentationType.EMAIL:
      rules.push({
        type: 'string',
        // eslint-disable-next-line no-useless-escape
        pattern: emailRegex,
        message: 'Must be a valid email',
      });
      break;
    case PresentationType.PHONE:
    case PresentationType.INTERNATIONAL_PHONE:
      rules.push(phoneValidationRule);
      break;
    case PresentationType.TEXT:
    case PresentationType.TEXTAREA:
      if (maximumItemLength && Number(maximumItemLength) > 0) {
        const maxL = Number(maximumItemLength);
        rules.push({
          max: maxL,
          message: `Cannot be longer than ${maxL} char`,
        });
      }
      break;
  }

  if (matchExpression) {
    const regex =
      typeof matchExpression === 'string' ? new RegExp(matchExpression) : matchExpression;

    rules.push({
      type: 'string',
      pattern: regex,
      message: `Must match the expression ${regex}`,
    });
  }

  // Only limit the fields where the user is free to type whatever he want

  const numberMinimumItemLength = Number(minimumItemLength);
  if (numberMinimumItemLength > 0) {
    rules.push({
      min: numberMinimumItemLength,
      message: `Cannot be shorter than ${numberMinimumItemLength} char`,
    });
  }

  const numberMinimumValue = Number(minimumValue);
  if (numberMinimumValue > 0) {
    rules.push({
      min: numberMinimumValue,
      message: `Cannot be less than ${numberMinimumValue}`,
    });
  }

  const numberMaximumValue = Number(maximumValue);
  if (numberMaximumValue > 0) {
    rules.push({
      max: numberMaximumValue,
      message: `Cannot be greater than ${numberMaximumValue}`,
    });
  }

  if (hasOtherOption) {
    rules.push({
      validator(_rule, value) {
        if (Array.isArray(value)) {
          const otherValue = value.find(isOtherValue);
          if (otherValue !== OTHER_VALUE_PREFIX) {
            return Promise.resolve();
          }
        } else if (value !== OTHER_VALUE_PREFIX) {
          return Promise.resolve();
        }
        return Promise.reject('This field is required');
      },
    });
  }

  return rules;
}
