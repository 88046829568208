import { createAction, createAsyncAction, RootAction } from 'typesafe-actions';

import { FormSettings } from '@/modules/data/dataTypes/form';
import { ApiError } from '@/modules/utils/apiService';

import { firstStepRoute, moduleName } from '../constants';

export const navigateToCreateEvent = createAction(firstStepRoute)<{
  formCode?: string;
  isInitialLoad: true;
}>();

export const createForm = createAsyncAction(
  `${moduleName}/CREATE_FORM_REQUEST`,
  `${moduleName}/CREATE_FORM_SUCCESS`,
  `${moduleName}/CREATE_FORM_FAILURE`,
)<{ formData: Partial<FormSettings>; finalActions: RootAction[] }, undefined, ApiError>();

export const openNextStep = createAction(`${moduleName}/OPEN_NEXT_STEP`)();
