import { Row as BsaRow, Form } from 'antd';
import styled from 'styled-components';

export const Row = styled(BsaRow)`
  & .ant-form-item {
    flex-grow: 1;
  }

  & .ant-checkbox-group {
    width: 100%;
    columns: 300px 3;

    & .ant-checkbox-wrapper {
      width: 300px;
    }
  }

  & .ant-spin-container,
  & textarea {
    max-width: 600px;
  }

  & .ant-select {
    max-width: 400px;
  }
`;

export const FormItem = styled(Form.Item)`
  width: 100%;
`;
