import React, { Fragment } from 'react';

import type { BasePayloadActivity } from '@/modules/entities/Activities/types';
import Divider from '@/modules/shared/components/Divider';

import { ListContainer } from './ActivitiesList.styled';
import Activity from './components/Activity';

type Props = {
  activities: (BasePayloadActivity & { overlapActivitiesList: string })[];
  canEditDisabledActivities?: boolean;
  onInclude: (activity: string, quantity: number) => void;
};

const ActivitiesList: React.FC<Props> = ({ activities, canEditDisabledActivities, onInclude }) => (
  <ListContainer>
    {activities.map(({ overlapActivitiesList, ...activity }, index) => (
      <Fragment key={activity.activityName}>
        {index !== 0 && <Divider />}
        <Activity
          activity={activity}
          overlapActivitiesList={overlapActivitiesList}
          canEditDisabledActivities={canEditDisabledActivities}
          onInclude={onInclude}
        />
      </Fragment>
    ))}
  </ListContainer>
);

export default ActivitiesList;
