import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { moduleName } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const routeSel = createSelector(moduleSel, ({ route }) => route);

export const loadingSel = createSelector(moduleSel, ({ loading }) => loading);

export const canLeaveSel = createSelector(moduleSel, ({ canLeave }) => canLeave);

export const initialPageLoadCompletedSel = createSelector(
  moduleSel,
  ({ initialPageLoadCompleted }) => initialPageLoadCompleted,
);
