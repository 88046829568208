import { Permission } from '@/modules/data/dataTypes/permissionsList';
import { Role } from '@/modules/data/dataTypes/roleList';
import apiService from '@/modules/utils/apiService';

const updateRole$ = (
  payload: Partial<Pick<Role, 'roleCode' | 'roleName' | 'description'>> & {
    permissions: {
      permissionCode: string;
      isIncluded: boolean;
    }[];
    formCode?: string;
  },
) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateRole',
    {
      ...payload,
      resourceCode: payload.formCode,
    },
  );

const updatePermission$ = (payload: Pick<Permission, 'action' | 'target'>) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateRolePermission',
    payload,
  );

export default {
  updateRole$,
  updatePermission$,
};
