import React, { PropsWithChildren } from 'react';
import { useSlate } from 'slate-react';

import { Button, CaretDown, CaretUp } from './ToolbarButton.styled';

type Props = {
  caretDirection?: 'up' | 'down';
  isActive?: boolean;
  Icon: React.ComponentType<{ className?: string }>;
  onClick?: (e: React.MouseEvent) => void;
};

const ToolbarButton: React.FC<PropsWithChildren<Props>> = ({
  isActive,
  caretDirection,
  Icon,
  onClick,
  children,
}) => {
  const editor = useSlate();

  const disabled = !editor.selection;

  return (
    <Button disabled={disabled} $isActive={!!isActive} size="small" onMouseDown={onClick}>
      {Icon && <Icon />}
      {caretDirection === 'up' && <CaretUp />}
      {caretDirection === 'down' && <CaretDown />}
      {children}
    </Button>
  );
};

export default ToolbarButton;
