import { DatePicker as DatePickerAntd, Input as InputAntd } from 'antd';
import styled from 'styled-components';

export const DatePicker = styled(DatePickerAntd)`
  width: 100%;
`;

export const Input = styled(InputAntd)`
  width: 100%;
`;
