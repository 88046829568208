import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { Fragment, useMemo, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { useFoundPerson } from '@/modules/shared/components/FindPersonForm/hooks';
import Label from '@/modules/shared/components/Label';
import toastService from '@/modules/toasts/service';

import { navigateToAddAttendee } from '@/pages/addAttendee/duck/actions';
import {
  invitationEligibilitySel,
  invitationEligibilityMessagesSel,
  hasUserNameSel,
} from '@/pages/reservation/components/MyReservation/duck/selectors';

import { rosterSel } from '../../../../duck/selectors';

import { ModalParams } from './types';

import FindPersonForm from 'SHARED/components/FindPersonForm';

type OwnProps = {
  modalParams: ModalParams;
};

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const AddAttendeeModal: React.FC<Props> = ({
  modalParams,
  isEligible,
  eligibilityMessages,
  hasUserName,
}) => {
  const renderFoundPersonRef = useRef(null);
  const dispatch = useDispatch();
  const foundPerson = useFoundPerson();
  const roster = useSelector(rosterSel);
  const rosterAttendees = useSelector(createDataSel('rosterAttendees'));

  const { disableLocalUsersRegistration, formCode, isCreator, isCollaborator } = useSelector(
    createDataSel('form'),
  );
  const [personExists, setPersonExists] = useState(true);

  const { attendeeType, reservationDetails } = modalParams;

  const { sessionStartDate } = reservationDetails;
  const { typeName } = attendeeType;

  const handleSetPersonExists = (e: RadioChangeEvent) => {
    setPersonExists(e.target.value);
  };

  function handleSubmit() {
    if (!roster) return null;

    const { rosterCode } = roster;
    const positionType = attendeeType.typeName === 'Lead Advisor' ? 'LeadAdvisor' : undefined;

    const basePayload = {
      formCode,
      reservationDetails,
      attendeeType,
      rosterCode,
      positionType,
    } as const;

    if (personExists) {
      if (!foundPerson) return null;
      const alreadyAttendee = rosterAttendees.some(
        m => m.memberId === foundPerson.memberID && !m.isArchived,
      );

      if (alreadyAttendee) {
        toastService.error('Selected user is already an attendee.');
        return null;
      }

      // inferring positionType using attendeeType.typeName

      dispatch(
        navigateToAddAttendee({
          ...basePayload,
          personExists,
          personGUID: foundPerson.personGUID,
          isSameUser: false,
        }),
      );
    } else {
      dispatch(
        navigateToAddAttendee({
          ...basePayload,
          personExists,
        }),
      );
    }
  }

  const submitButtonDisabled = useMemo(() => {
    if (!personExists) return false;
    return isCreator || isCollaborator ? !hasUserName : !isEligible;
  }, [personExists, isCreator, isCollaborator, hasUserName, isEligible]);

  const descriptionText = `Please fill in the following fields for adding a new ${typeName}${
    disableLocalUsersRegistration ? ' (attendee requires a valid My Scouting account)' : ''
  }`;

  return (
    <ModalComponent
      title={`Adding New ${typeName}`}
      description={descriptionText}
      buttons={[
        {
          title: personExists ? 'ADD ATTENDEE' : 'NEXT',
          onClick: handleSubmit,
          disabled: submitButtonDisabled,
        },
      ]}
    >
      {!disableLocalUsersRegistration && (
        <Label label="Does this person already have an account on Events.Scouting.org or My.Scouting.org?">
          <Radio.Group value={personExists} onChange={handleSetPersonExists}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Label>
      )}
      {personExists && (
        <Fragment>
          <FindPersonForm
            isEventOwner={reservationDetails.isRegistrantCurrentUser}
            renderFoundPersonRef={renderFoundPersonRef}
            pivotDate={sessionStartDate}
            showEligibilityContent
            isEligible={isEligible}
            eligibilityMessages={eligibilityMessages}
          />
          <div ref={renderFoundPersonRef} />
        </Fragment>
      )}
    </ModalComponent>
  );
};

AddAttendeeModal.propTypes = {};

const mapStateToProps = (
  state: RootState,
  { modalParams }: OwnProps,
): {
  isEligible: boolean;
  eligibilityMessages: string[];
  hasUserName: boolean;
} => ({
  isEligible: invitationEligibilitySel(state, modalParams),
  eligibilityMessages: invitationEligibilityMessagesSel(state, modalParams),
  hasUserName: hasUserNameSel(state),
});

export default connect(mapStateToProps)(AddAttendeeModal);
