import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

const centerText = css`
  display: flex;
  justify-content: center;
`;

export const NoPersonHeader = styled.h3`
  ${centerText};
  color: ${colors.scoutingBlue};
  font-size: 24px;
  font-weight: bold;
`;

export const NoPersonDescription = styled.p`
  ${centerText};
  color: ${colors.darkGrey};
  font-size: 16px;
`;
