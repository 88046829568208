import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { OptionSets } from '@/modules/data/dataTypes/optionSets';
import { createDataSel } from '@/modules/data/duck/selectors';
import InputSelector from '@/modules/entities/FormItems/components/InputSelector';
import { generateFormItemRules } from '@/modules/entities/FormItems/utils';
import { rolesSetForQuestionsSel } from '@/modules/questions/duck/selectors';
import { TrimmedIncomingFormParts } from '@/modules/questions/types';
import { isQuestionVisible } from '@/modules/questions/utils';
import DataBlockItemLabel from '@/modules/shared/components/DataBlockItemLabel';
import { PresentationType } from '@/modules/shared/constants';
import { optionsArray } from '@/modules/utils/bsaLookupsDropdownsSets';
import { dateFromString } from '@/modules/utils/dateFormats';
import { parseBase64 } from '@/modules/utils/stringFunctions';

import { FormItem, Row } from './EditableFormItem.styled';

type BaseProps = {
  formPartCode: string;
  formItem: TrimmedIncomingFormParts[number]['formItems'][number];
  optionSets: OptionSets;
  formItemVisibilitySettings?: string;
};

type AddAttendeeProps = BaseProps & {
  providedAttendeeType: string;
  checkAttendeeTypeOnly?: true;
};

type UpdateAttendeeProps = BaseProps & {
  attendeeDetails: AttendeeDetails;
};

type Props = AddAttendeeProps | UpdateAttendeeProps;

const EditableFormItem: React.FC<Props> = props => {
  const { formPartCode, formItem, optionSets, formItemVisibilitySettings } = props;
  const {
    formItemCode,
    formItemCaption,
    formItemName,
    formItemValue,
    presentationType,
    isReadonly,
    hasOtherOption,
    placeholderText,
    optionSetCode,
    description,
  } = formItem;
  const form = useSelector(createDataSel('form'));
  const rolesSet = useSelector(rolesSetForQuestionsSel);

  const isDisabled =
    isReadonly ||
    !isQuestionVisible({
      ...props,
      rolesSet,
      form,
      isEditing: true,
      visibilitySettings: parseBase64(formItemVisibilitySettings),
    });

  const rules = useMemo(() => generateFormItemRules(formItem, isDisabled), [formItem, isDisabled]);

  const initialValue = useMemo(() => {
    switch (presentationType) {
      case PresentationType.CHECKBOX:
        return typeof formItemValue === 'string' && formItemValue ? formItemValue.split(',') : [];
      case PresentationType.SWITCH:
        return !!formItemValue;
      case PresentationType.DATE_PICKER: {
        if (typeof formItemValue === 'string' && formItemValue && formItemValue !== 'false') {
          const date = dateFromString(formItemValue);
          if (date.isValid()) {
            return date;
          }
        }
        return dayjs();
      }
      case PresentationType.DATE_RANGE_PICKER:
        if (formItemValue && formItemValue !== 'false') {
          try {
            const parsedDate = JSON.parse(String(formItemValue));
            const startDate = dateFromString(parsedDate.startDate);
            const endDate = dateFromString(parsedDate.endDate);
            if (startDate.isValid() && endDate.isValid()) {
              return [startDate, endDate];
            }
          } catch (e) {
            return [dayjs(), dayjs()];
          }
        }
        return [dayjs(), dayjs()];
      case PresentationType.PHONE:
      case PresentationType.INTERNATIONAL_PHONE:
        if (formItemValue && typeof formItemValue === 'string') {
          const onlyNumbersInitialValue = formItemValue.replace(/\D/g, '');
          if (onlyNumbersInitialValue.length < 11) return `1${formItemValue}`;
        }
        return formItemValue;
      case PresentationType.DROPDOWN:
      case PresentationType.DROPDOWN_BULK:
      case PresentationType.RADIO:
      default:
        return formItemValue;
    }
  }, [formItemValue, presentationType]);

  const options = useMemo(() => {
    if (optionSetCode) {
      const optionSet = optionSets[optionSetCode];

      if (optionSet) {
        return optionSet.optionItems.map(
          ({ itemLabel, itemValue, isDisabled: optionIsDisabled }) => ({
            value: itemValue,
            label: itemLabel,
            disabled: !!optionIsDisabled,
          }),
        );
      }
    }
    return optionsArray(formItemCode) || [];
  }, [optionSetCode, optionSets, formItemCode]);

  return (
    <Row gutter={24}>
      <FormItem
        name={[formPartCode, formItemCode]}
        label={
          <DataBlockItemLabel label={formItemCaption || formItemName} description={description} />
        }
        initialValue={initialValue}
        rules={rules}
        hasFeedback={
          ![PresentationType.CHECKBOX, PresentationType.SWITCH].includes(presentationType)
        }
      >
        <InputSelector
          presentationType={presentationType}
          disabled={isDisabled}
          placeholder={placeholderText}
          options={options}
          formSettings={form}
          formPartCode={formPartCode}
          formItemCode={formItemCode}
          showOtherOption={hasOtherOption}
          size="large"
        />
      </FormItem>
    </Row>
  );
};

export default EditableFormItem;
