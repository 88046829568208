import { Card, Table as AntTable } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

import Input from 'SHARED/components/SearchInput';

export const Table = styled(AntTable)`
  .ant-table-title {
    background-color: ${colors.darkGrey};
  }

  .ant-table-container table > thead > tr:first-child {
    > *:first-child {
      border-start-start-radius: 0;
    }
    > *:last-child {
      border-start-end-radius: 0;
    }
  }

  .ant-table-body {
    overflow: auto;
  }
` as typeof AntTable;

export const SearchInput = styled(Input)`
  display: flex;
  flex: 1;
  background: white;
  padding: 0.5em 1em;

  input {
    border: none;
  }
`;

export const WrapperCard = styled(Card)`
  border-radius: 0;
  &.ant-card {
    box-shadow: none;
  }
  .ant-card-body {
    padding: 0;
  }
`;
