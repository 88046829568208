import { Button } from 'antd';
import React from 'react';

import { Notice } from '@/modules/entities/Notifications/types';

import styles from './NoticeContent.less';
import { Title } from './NoticeContent.styled';

type Props = {
  notice: Notice;
  onClick: () => void;
};

const NoticeContent: React.FC<Props> = ({ notice: { title, content }, onClick }) => (
  <div className={styles.notice}>
    <div className={styles.body}>
      <Title level={4}>{title}</Title>
      <p>{content}</p>
    </div>
    <div className={styles.footer}>
      <Button onClick={onClick} type="primary">
        Got It
      </Button>
    </div>
  </div>
);

export default NoticeContent;
