import { FormSettings } from '@/modules/data/dataTypes/form';
import { createPageDataParams } from '@/modules/data/utils';

export const stepDataParams = createPageDataParams(
  { dataType: 'eventCategories' },
  { dataType: 'timezones' },
);

export const EVENT_DATES_FIELD = 'eventDates';
export const EVENT_REG_DATES_FIELD = 'eventRegistrationDates';
export const EVENT_IS_VISIBLE_FIELD = 'isVisibleOnEventList';
export const REQUIRE_COUNCIL_APPROVAL: keyof FormSettings = 'requireCouncilApproval';
export const ALLOW_GROUP_REGISTRATION: keyof FormSettings = 'allowGroupRegistration';
export const ALLOW_SECONDARY_REGISTRATION: keyof FormSettings = 'allowSecondaryRegistration';
