import { produce } from 'immer';
import { combineReducers } from 'redux';
import { createReducer, getType, RootAction } from 'typesafe-actions';

import { resetOnAction } from '@/modules/utils/resetOnLogoutReducer';

import { moduleName as personalInformationModuleName } from '../steps/PersonalInformation/constants';
import personalInformationReducers from '../steps/PersonalInformation/duck/reducers';
import { moduleName as sessionModuleName } from '../steps/SessionPriorities/constants';
import {
  updateReservationSessions,
  updateGroupReservationSessions,
  updateIndividualReservationSessions,
  setGroupInitialSettings,
  HostInitialSettings,
  PeopleInitialSettings,
} from '../steps/SessionPriorities/duck/actions';
import sessionReducers from '../steps/SessionPriorities/duck/reducers';

import {
  disableStep,
  enableStep,
  openForm,
  openNextStep,
  openPrevStep,
  setCurrentGroupReservationGUID,
  setFormRecordGuid,
  openStepCompleted,
  openStepByIndex,
  repeatOpenStep,
  setRegistrationTimeLeft,
  stopRegistrationTimer,
} from './actions';

const stepIndex = createReducer({ future: 0, current: 0 })
  .handleAction(openNextStep, state =>
    produce(state, draft => {
      draft.future += 1;
    }),
  )
  .handleAction(openPrevStep, state =>
    produce(state, draft => {
      draft.future -= 1;
    }),
  )
  .handleAction(openStepByIndex, (state, { payload }) =>
    produce(state, draft => {
      draft.future = payload;
    }),
  )
  .handleAction(repeatOpenStep, state =>
    produce(state, draft => {
      if (state.future > state.current) {
        draft.future += 1;
      } else if (state.future < state.current) {
        draft.future -= 1;
      }
    }),
  )
  .handleAction(openStepCompleted, state =>
    produce(state, draft => {
      draft.current = draft.future;
    }),
  );

const stepIsLoading = createReducer(false)
  .handleAction([openNextStep, openPrevStep, openStepByIndex], () => true)
  .handleAction(openStepCompleted, () => false);

const isLoadingFetchingForm = (state = false, action: RootAction) => {
  switch (action.type) {
    case getType(updateReservationSessions):
    case getType(setCurrentGroupReservationGUID):
    case getType(updateGroupReservationSessions.failure):
    case getType(updateGroupReservationSessions.success):
    case getType(updateIndividualReservationSessions.failure):
    case getType(updateIndividualReservationSessions.success):
      return false;
    default:
      return state;
  }
};

const currentGroupReservationGUID = createReducer<null | string>(null)
  .handleAction(setCurrentGroupReservationGUID, (_, { payload }) => payload)
  .handleAction(openForm, (_state, { payload }) =>
    'groupReservationGUID' in payload && payload.groupReservationGUID
      ? payload.groupReservationGUID
      : null,
  );

const formRecordGUID = createReducer<null | string>(null)
  .handleAction(setFormRecordGuid, (_state, { payload }) => payload)
  .handleAction(openForm, (_state, { payload }) =>
    'formRecordGUID' in payload && payload.formRecordGUID ? payload.formRecordGUID : null,
  );

type InitialSettings = HostInitialSettings & PeopleInitialSettings;

const groupReservationInitialSettings = createReducer({} as InitialSettings).handleAction(
  setGroupInitialSettings,
  (state, { payload }) => ({ ...state, ...payload }),
);

const disabledSteps = createReducer<Record<string, string>>({})
  .handleAction(disableStep, (state, { payload }) =>
    produce(state, draft => {
      draft[payload.stepKey] = payload.reason;
    }),
  )
  .handleAction(enableStep, (state, { payload }) =>
    produce(state, draft => {
      delete draft[payload];
    }),
  );

const registrationTimeLeft = createReducer<null | number>(null)
  .handleAction(setRegistrationTimeLeft, (_state, { payload }) => payload)
  .handleAction(stopRegistrationTimer, () => null);

export default resetOnAction(
  combineReducers({
    [personalInformationModuleName]: personalInformationReducers,
    [sessionModuleName]: sessionReducers,
    stepIndex,
    stepIsLoading,
    formRecordGUID,
    currentGroupReservationGUID,
    groupReservationInitialSettings,
    isLoadingFetchingForm,
    disabledSteps,
    registrationTimeLeft,
  }),
  openForm,
);
