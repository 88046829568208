import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { QueryFilterVariable } from '@/modules/data/dataTypes/queryFilterVariableList';
import { queryFilterVariablesForReportSel } from '@/modules/reports/duck/selectors';
import { ReportDataType } from '@/modules/reports/types';

import { FILTER_EXPRESSION_COLUMN_NAME } from '../../../../../../constants';

import { Select, FormItem } from './SelectColumn.styled';

type Props = {
  name: number;
  dataType: ReportDataType;
};

const SelectColumn: React.FC<Props> = ({ name, dataType }) => {
  const queryFilterVariables: QueryFilterVariable[] = useSelector((state: RootState) =>
    queryFilterVariablesForReportSel(state, { dataType }),
  );

  return (
    <FormItem
      name={[name, FILTER_EXPRESSION_COLUMN_NAME]}
      rules={[{ required: true, message: 'Filter Column is required' }]}
    >
      <Select placeholder="Colum Name" optionFilterProp="children" showSearch>
        {queryFilterVariables.map(({ caption, value }) => (
          <Select.Option key={value} value={value}>
            {caption}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
};

export default SelectColumn;
