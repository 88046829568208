import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { moduleName } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const sendEmailInProgressSel = createSelector(
  moduleSel,
  ({ sendEmailInProgress }) => sendEmailInProgress,
);

export const updateEmailTemplateInProgressSel = createSelector(
  moduleSel,
  ({ updateEmailTemplateInProgress }) => updateEmailTemplateInProgress,
);

export const sendCustomEmailInProgressSel = createSelector(
  moduleSel,
  ({ sendCustomEmailInProgress }) => sendCustomEmailInProgress,
);
