import { Pagination as BsaPagination } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Pagination = styled(BsaPagination)`
  background-color: ${colors.complementColor};
  padding: 1.2rem;
  display: flex;
  justify-content: center;

  .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-total-text {
    position: absolute;
    left: 0;
    margin-left: 20px;
  }
`;
