import { Button, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import React, { useMemo, useState } from 'react';

import { FormSettings } from '@/modules/data/dataTypes/form';
import toastService from '@/modules/toasts/service';
import { getApiPrefix } from '@/modules/utils/apiService';

import { ButtonContainer, InsertPhotoIcon, Upload } from './FileUpload.styled';

const MAX_FILE_SIZE = 10;

type Props = {
  value?: string;
  onChange?: (value: string | void) => void;
  options?: { value: string; label: string; isAllowed?: boolean }[];
  formPartCode: string;
  formItemCode: string;
  formSettings: FormSettings;
};

const FileUpload: React.FC<Props> = ({
  onChange,
  options,
  formSettings,
  formPartCode,
  formItemCode,
  value,
  ...rest
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>(() => {
    if (!value) return [];
    const [code, name] = value.split('/');

    return [
      {
        uid: code,
        name: name || code,
        status: 'done',
      },
    ];
  });

  const beforeUpload = (uploadedFile: UploadFile) => {
    const fileExtension = uploadedFile.name.split('.').pop()?.toUpperCase();
    const allowedExtensions = options?.filter(({ isAllowed }) => isAllowed) || [];

    const isRightExtension =
      !options?.length ||
      allowedExtensions.some(({ value: extension }) =>
        [extension.toUpperCase(), 'ANY'].includes(fileExtension as string),
      );

    if (!isRightExtension) {
      const extensionsString = allowedExtensions.map(({ label }) => label).join(', ');
      toastService.error(`You can only upload one of these types: ${extensionsString}!`);
    }
    const hasValidSize = !!uploadedFile.size && uploadedFile.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!hasValidSize) {
      toastService.error(`Image must be smaller than ${MAX_FILE_SIZE} MB!`);
    }
    return isRightExtension && hasValidSize;
  };

  const url = useMemo(() => {
    const { formCode } = formSettings;

    const data = {
      isPublic: true,
      formCode,
      formPartCode,
      formItemCode,
      Options: '@FormData',
    };
    const queryString = Object.entries(data).reduce((acc, [k, v]) => `${acc}&${k}=${v}`, '');
    const mainUrl =
      '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=AWSUploadFile';
    const apiPrefix = getApiPrefix(mainUrl);
    return `${apiPrefix}${mainUrl}${queryString}`;
  }, [formItemCode, formPartCode, formSettings]);

  const handleChange = (params: UploadChangeParam<UploadFile>) => {
    const {
      file: { response },
      fileList: nextFileList,
    } = params;

    setFileList(nextFileList.filter(({ status }) => !!status).slice(-1));

    if (response) {
      const fileName = fileList[0]?.name;

      onChange && onChange(`${response.responseValue}/${fileName}`);
    }
  };

  const handleRemoveFile = () => {
    onChange && onChange();
  };

  return (
    <Upload
      {...rest}
      fileList={fileList}
      multiple={false}
      beforeUpload={beforeUpload}
      action={url}
      onChange={handleChange}
      onRemove={handleRemoveFile}
    >
      <ButtonContainer>
        <InsertPhotoIcon />
        Drag a file or &nbsp;
        <Button type="primary">Select from Device</Button>
      </ButtonContainer>
    </Upload>
  );
};

export default FileUpload;
