import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { updatePerson } from '@/modules/entities/Person/duck/actions';

import { updatePersonExists } from './actions';

const personExists = createReducer(true).handleAction(
  updatePersonExists,
  (_state, { payload }) => payload,
);

const personGuid = createReducer<string | null>(null).handleAction(
  updatePerson.success,
  (_state, { payload }) => payload,
);

export default combineReducers({
  personExists,
  personGuid,
});
