import { CollapsePanelProps } from 'antd/lib/collapse';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Attendee } from '@/modules/data/dataTypes/attendeeList';
import { createDataSel } from '@/modules/data/duck/selectors';
import { useGetData } from '@/modules/data/hooks';
import { ModalParams } from '@/modules/entities/Addons/components/EditAttendeeAddonsModal/types';
import { EDIT_ATTENDEE_ADDONS_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal } from '@/modules/modals/duck/actions';
import ReservationAddons from '@/modules/shared/components/ReservationAddons';

import Section from '../Section';

type Props = CollapsePanelProps & {
  formRecord: Attendee;
};

const AdditionalRegistrantAddons: React.FC<Props> = ({ formRecord, ...rest }) => {
  const dispatch = useDispatch();
  const { formRecordGUID, firstName, lastName, state } = formRecord;
  const { formCode } = useSelector(createDataSel('form'));
  const addons = useSelector(createDataSel('addons'));
  const [formRecordAddons, formRecordAddonsLoading, refreshFormRecordAddonsAction] = useGetData({
    dataType: 'formRecordAddons',
    queryObj: { formCode, formRecordGUID },
  });

  const handleEdit = () => {
    const modalParams: ModalParams = {
      finalActions: [refreshFormRecordAddonsAction, closeModal()],
      formCode,
      firstName,
      lastName,
      formRecordGUID,
      availableAddons: addons,
      selectedAddons: formRecordAddons,
      state,
    };
    dispatch(openModal(EDIT_ATTENDEE_ADDONS_MODAL, modalParams));
  };

  return (
    <Section isLoading={formRecordAddonsLoading}>
      <ReservationAddons {...rest} addons={formRecordAddons} onEditAddons={handleEdit} />
    </Section>
  );
};

export default AdditionalRegistrantAddons;
