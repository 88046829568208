import { createPageDataParams } from '@/modules/data/utils';
import { createModuleNamespaceName } from '@/modules/utils/misc';

import { moduleName as parentModuleName } from '../../constants';

const moduleName = 'selectAnswers';

const modulePath = [parentModuleName, moduleName];

export const moduleNamespace = createModuleNamespaceName(modulePath);

export const stepDataParams = createPageDataParams(
  { dataType: 'form' },
  { dataType: 'formPartsData' },
  { dataType: 'optionSets' },
);
