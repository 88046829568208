import React, { useMemo } from 'react';
import { AiFillPrinter } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';

import { saveActivitiesInProgressSel } from '@/modules/entities/Activities/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { EDIT_ATTENDEE_ACTIVITIES_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import EditableFormSection from '@/modules/shared/components/EditableFormSection';
import ItemMapper from '@/modules/shared/components/ItemMapper';
import toastService from '@/modules/toasts/service';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { CURRENCY_FORMAT } from '@/modules/utils/numberFormats';

import { pageDataSel } from '@/pages/reservation/duck/selectors';
import { tabDataSel } from '@/pages/reservation/tabs/AttendeeDetails/duck/selector';

import { ModalParams } from './components/EditActivitiesModal/types';

interface Props {
  formRecordGUID: string;
}

const Activities: React.FC<Props> = ({ formRecordGUID }) => {
  const dispatch = useDispatch();
  const {
    data: { attendeeDetails },
    isLoading: dataPageIsLoading,
  } = useSelector(pageDataSel);
  const {
    data: { activities, formRecordActivities },
    isLoading: tabIsLoading,
  } = useSelector(tabDataSel);

  const isSavingActivities = useSelector(saveActivitiesInProgressSel);
  const isAllowedToEditAttendee = useSelector(
    createAbilitiesSelector('attendeeDetails', PermissionAction.Update, { attendeeDetails }),
  );
  const isAllowedToEditActivities = useSelector(
    createAbilitiesSelector('attendeeSelections', PermissionAction.Update, attendeeDetails),
  );

  const { firstName, formCode, lastName, personGUID, sessionCode } = attendeeDetails;
  const reportUrl = `https://my${process.env.REACT_APP_ENV}.scouting.org/mystrpt/webreport/output/proxy.aspx?Method=LoadReportPage&ReportCode=EMSIndividualizedActivitySchedules&person_guid=${personGUID}&form_code=${formCode}&session_code=${sessionCode}&OutputFormat=@PDF`;

  const isLoading =
    tabIsLoading.activities ||
    tabIsLoading.formRecordActivities ||
    dataPageIsLoading.paymentSummary ||
    isSavingActivities;

  const missingActivities = useMemo(() => {
    const activitiesCodes = activities.map(({ activityCode }) => activityCode);
    if (!activitiesCodes.length) return formRecordActivities;
    if (!formRecordActivities?.length) return [];
    return formRecordActivities.filter(
      ({ activityCode }) => !activitiesCodes.includes(activityCode),
    );
  }, [activities, formRecordActivities]);

  const items = useMemo(
    () =>
      formRecordActivities.map(({ activityName, fee }) => ({
        label: activityName,
        value: CURRENCY_FORMAT(fee),
      })),
    [formRecordActivities],
  );

  if (!formRecordActivities.length && !activities.length) {
    return null;
  }

  const handleEditActivities = () => {
    if (!activities.length) {
      return toastService.warn('This session does not have any activities');
    }

    const modalParams: ModalParams = {
      availableActivities: [...activities, ...missingActivities],
      attendeeName: `${firstName} ${lastName}`,
      formRecordGUID,
    };

    dispatch(openModal(EDIT_ATTENDEE_ACTIVITIES_MODAL, modalParams, { width: 1000 }));
  };

  return (
    <EditableFormSection
      canEdit={isAllowedToEditAttendee || isAllowedToEditActivities}
      extraActions={[
        {
          key: 'printActivities',
          href: reportUrl,
          icon: <AiFillPrinter />,
          rel: 'noopener noreferrer',
          target: '_blank',
        },
      ]}
      isEditing={false}
      isLoading={isLoading}
      title="Activities"
      onEditClick={handleEditActivities}
    >
      {formRecordActivities.length ? (
        <ItemMapper items={items} />
      ) : (
        <div>No activities were selected</div>
      )}
    </EditableFormSection>
  );
};

export default Activities;
