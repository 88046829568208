import { SearchOutlined } from '@ant-design/icons';
import { InputProps } from 'antd';
import React from 'react';

import { Input } from './SearchInput.styled';

type Props = Omit<InputProps, 'onChange'> & {
  onChange: (value: string) => void;
};

const SearchInput: React.FC<Props> = ({ onChange, ...rest }) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    onChange(e.target.value || '');
  };

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = e => {
    e.target.select();
  };

  return (
    <Input
      addonBefore={<SearchOutlined />}
      size="large"
      onChange={handleChange}
      onFocus={handleFocus}
      {...rest}
    />
  );
};

export default SearchInput;
