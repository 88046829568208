import { Button, Popconfirm } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import { sendEmail } from '@/modules/entities/Emails/duck/actions';
import { sendEmailInProgressSel } from '@/modules/entities/Emails/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { Footer, Select } from './MassResendEmailModal.styled';
import { ModalParams } from './types';

const { Option } = Select;

type Props = {
  modalParams: ModalParams;
};

const MassResendEmailModal: React.FC<Props> = ({ modalParams }) => {
  const { selectedRows, dataId, formCode } = modalParams;

  const dispatch = useDispatch();
  const emailTemplateList = useSelector(createDataSel('emailTemplateList', dataId));
  const isSendingEmail = useSelector(sendEmailInProgressSel);

  const [selectedEmailTemplateCode, setSelectedEmailTemplateCode] = useState<string | undefined>();

  const handleSendEmail = () => {
    if (!selectedEmailTemplateCode) return;
    dispatch(
      sendEmail.request({
        formCode,
        emailTemplateCode: selectedEmailTemplateCode,
        formRecordGUID: selectedRows,
        finalActions: [closeModal()],
      }),
    );
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const renderFooter = () => (
    <Footer>
      <Button onClick={handleCancel}>CANCEL</Button>
      <Popconfirm
        title={`Are you sure to send an email to ${selectedRows.length} selected attendees?`}
        onConfirm={handleSendEmail}
        placement="topRight"
      >
        <Button type="primary" disabled={!selectedEmailTemplateCode}>
          SEND
        </Button>
      </Popconfirm>
    </Footer>
  );

  return (
    <ModalComponent
      title="Mass Re-send Email"
      description="Which email do you want to re-send?"
      renderFooter={renderFooter}
      inProgress={isSendingEmail}
    >
      <Select
        placeholder="Select an email"
        value={selectedEmailTemplateCode}
        onChange={setSelectedEmailTemplateCode}
        size="large"
      >
        {emailTemplateList.map(({ emailTemplateName, emailTemplateCode }) => (
          <Option key={emailTemplateCode} value={emailTemplateCode}>
            {emailTemplateName}
          </Option>
        ))}
      </Select>
    </ModalComponent>
  );
};

export default MassResendEmailModal;
