import React from 'react';

import { Payment } from '@/modules/data/dataTypes/paymentList';
import {
  ContentData,
  ContentTitle,
} from '@/modules/shared/components/ReservationPaymentsCard/ReservationPaymentsCard.styled';

import { DataRow, PaymentItemSummaryWrapper } from './PaymentItemDetails.styled';

type Props = {
  payment: Payment;
  opened: boolean;
};

const PaymentItemDetails: React.FC<Props> = ({ payment: { orderId }, opened }) => (
  <PaymentItemSummaryWrapper opened={opened}>
    <ContentTitle>Details</ContentTitle>
    <DataRow>
      <ContentData>Order ID</ContentData>
      <ContentData>{orderId}</ContentData>
    </DataRow>
  </PaymentItemSummaryWrapper>
);

export default PaymentItemDetails;
