import { MdOpenInBrowser } from 'react-icons/md';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

interface JobStatusProps {
  color: string;
}

export const JobStatus = styled.span<JobStatusProps>`
  font-weight: bold;
  color: ${props => props.color};
`;

export const OpenJobOfferIcon = styled(MdOpenInBrowser)`
  font-size: 20px;
  cursor: pointer;
  color: ${colors.lightBlue};
  margin-left: 8px;
`;
