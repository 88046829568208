import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { refreshData } from '@/modules/data/duck/actions';
import { ModalParams } from '@/modules/entities/Addons/components/EditAttendeeAddonsModal/types';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { EDIT_ATTENDEE_ADDONS_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal } from '@/modules/modals/duck/actions';
import EditableFormSection from '@/modules/shared/components/EditableFormSection';
import ItemMapper from '@/modules/shared/components/ItemMapper';
import toastService from '@/modules/toasts/service';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { isSystemAdministratorSel } from '@/modules/user/duck/selectors';
import { CURRENCY_FORMAT } from '@/modules/utils/numberFormats';

import { pageDataSel } from '@/pages/reservation/duck/selectors';
import { tabDataSel } from '@/pages/reservation/tabs/AttendeeDetails/duck/selector';

const Addons: React.FC = () => {
  const dispatch = useDispatch();

  const { data: pageData, isLoading: dataPageIsLoading } = useSelector(pageDataSel);
  const {
    data: { arnicaPerson, addons, formRecordAddons },
    isLoading: tabIsLoading,
  } = useSelector(tabDataSel);
  const isSystemAdmin = useSelector(isSystemAdministratorSel);

  const { form, attendeeDetails, sessionList } = pageData;
  const groupReservation: GroupReservation | undefined = pageData.groupReservation;

  const { isCreator, isCollaborator, formCode } = form;
  const { formRecordGUID, sessionCode, firstName, lastName } = attendeeDetails;

  const { state } = arnicaPerson;

  const isAllowedToEditAttendee = useSelector(
    createAbilitiesSelector('attendeeDetails', PermissionAction.Update, {
      attendeeDetails,
      groupReservationDetails: groupReservation,
    }),
  );

  const isAllowedToEditAddons = useSelector(
    createAbilitiesSelector('attendeeSelections', PermissionAction.Update, attendeeDetails),
  );

  const currentSession = useMemo(
    () => sessionList.find(s => s.sessionCode === sessionCode),
    [sessionList, sessionCode],
  );

  const items = useMemo(
    () =>
      formRecordAddons.map(({ addonName, selectedOptionName, selectedOptionFee }) => {
        const value = `${selectedOptionName} (${CURRENCY_FORMAT(selectedOptionFee)})`;
        return { label: addonName, value };
      }),
    [formRecordAddons],
  );

  if (!formRecordAddons.length && !addons.length) {
    return null;
  }

  const handleEditAddons = () => {
    if (!addons.length) {
      return toastService.warn('This session does not have any addons');
    }

    if (!currentSession) return null;

    const modalParams: ModalParams = {
      formRecordGUID,
      formCode,
      firstName,
      lastName,
      selectedAddons: formRecordAddons,
      ...(isCreator || isCollaborator || isSystemAdmin
        ? {
            availableAddons: addons,
          }
        : {
            availableAddons: currentSession.addons,
            state,
          }),
      finalActions: [
        refreshData({ dataType: 'attendeeDetails' }),
        refreshData({ dataType: 'paymentSummary' }),
        refreshData({ dataType: 'formRecordAddons' }),
        closeModal(),
      ],
    };

    dispatch(openModal(EDIT_ATTENDEE_ADDONS_MODAL, modalParams, { width: 1000 }));
  };

  return (
    <EditableFormSection
      onEditClick={handleEditAddons}
      canEdit={isAllowedToEditAddons || (!!currentSession && isAllowedToEditAttendee)}
      title="Addons"
      isEditing={false}
      isLoading={tabIsLoading.addons || dataPageIsLoading.paymentSummary}
    >
      {formRecordAddons.length ? <ItemMapper items={items} /> : <div>No addons were selected</div>}
    </EditableFormSection>
  );
};

export default Addons;
