import { combineEpics, Epic } from 'redux-observable';
import { concat, defer, EMPTY, merge, of, race } from 'rxjs';
import { catchError, filter, mapTo, switchMap, take } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { FoundPerson } from '@/modules/data/dataTypes/foundPersons';
import { Person } from '@/modules/data/dataTypes/person';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { updatePerson } from '@/modules/entities/Person/duck/actions';
import toastService from '@/modules/toasts/service';

import { openNextStep } from '../../../duck/actions';

import { findExistingRegistrant, updateFoundPerson } from './actions';

const personNotFoundError = 'Person Not Found';

const findExistingRegistrant$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(findExistingRegistrant)),
    switchMap(({ payload: queryObj }) =>
      concat(
        prefetchData$(action$, { dataType: 'foundPeople', queryObj }),
        defer(() => {
          const foundPeople: FoundPerson[] | undefined = createDataSel('foundPeople')(state$.value);

          if (!foundPeople || !foundPeople?.length) {
            throw new Error(personNotFoundError);
          }

          const { personGUID } = foundPeople[0];

          return prefetchData$(action$, {
            dataType: 'arnicaPerson',
            queryObj: { personGUID, useAkelaData: true },
          });
        }),
        defer(() => {
          const person: Person | undefined = createDataSel('arnicaPerson')(state$.value);

          if (!person) throw new Error(personNotFoundError);

          return EMPTY;
        }),
      ),
    ),
    catchError((_error: Error, caught) => caught),
  );

const updateFoundPerson$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(updateFoundPerson)),
    switchMap(({ payload }) =>
      merge(
        race(
          action$.pipe(
            filter(isActionOf(updatePerson.failure)),
            take(1),
            switchMap(({ payload: { message } }) => {
              throw new Error(message);
            }),
          ),
          action$.pipe(filter(isActionOf(updatePerson.success)), take(1), mapTo(openNextStep())),
        ),
        of(
          updatePerson.request({
            ...payload,
            isLocal: !payload.personGUID || payload.isLocal,
          }),
        ),
      ),
    ),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return caught;
    }),
  );

export default combineEpics(findExistingRegistrant$, updateFoundPerson$);
