import { createSelector } from 'reselect';

import { createPageDataSelector } from '@/modules/data/duck/selectors';
import { mappedColumnsSel } from '@/modules/uploadQuestionsModal/duck/selectors';

import { stepDataParams } from '../constants';

export const stepDataSel = createPageDataSelector(stepDataParams);

export const filteredFormPartsSel = createSelector(
  [stepDataSel, mappedColumnsSel],
  (
    {
      data: {
        formPartsData: { formParts },
      },
    },
    mappedColumns,
  ) =>
    formParts.reduce(
      (acc, formPart) => {
        const { formPartCode, formItems } = formPart;
        const filteredFormItems = formItems.filter(
          ({ formItemCode }) => !!mappedColumns[formPartCode]?.[formItemCode],
        );
        if (!filteredFormItems.length) return acc;
        return [...acc, { ...formPart, formItems: filteredFormItems }];
      },
      [] as typeof formParts,
    ),
);

export const formItemsWithOptionsSel = createSelector(filteredFormPartsSel, filteredFormParts =>
  filteredFormParts.reduce(
    (acc, formPart) => {
      const { formPartCode, formItems } = formPart;
      const filteredFormItems = formItems
        .filter(({ optionSetCode }) => optionSetCode)
        .map(fi => ({ ...fi, formPartCode }));
      return [...acc, ...filteredFormItems];
    },
    [] as Array<(typeof filteredFormParts)[number]['formItems'][number] & { formPartCode: string }>,
  ),
);
