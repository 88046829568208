import React, { Fragment } from 'react';

import { Payment } from '@/modules/data/dataTypes/paymentList';
import Currency from '@/modules/shared/components/Currency';
import Divider from '@/modules/shared/components/Divider';

import Row from '../../../Row';

interface Props {
  payments: Payment[];
}

const CheckoutPayments: React.FC<Props> = ({ payments }) => {
  if (!payments.length) return null;

  return (
    <Fragment>
      <Row columns={['Payments']} isHeader />
      {payments.map((payment, index) => (
        <Row
          columns={[
            `Payment ${index + 1}`,
            <Currency key={payment.paymentId} value={payment.amount} />,
          ]}
          key={payment.paymentId}
        />
      ))}
      <Divider />
    </Fragment>
  );
};

export default CheckoutPayments;
