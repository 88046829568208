import { pickBy } from 'lodash';
import { of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { Payment } from '@/modules/data/dataTypes/paymentList';
import toastService from '@/modules/toasts/service';
import apiService from '@/modules/utils/apiService';
import { dateToString } from '@/modules/utils/dateFormats';

import {
  OfflinePaymentPayload,
  OfflinePaymentResponse,
  InitOrbitalTransactionResponse,
  MakeRefundPayload,
} from '../types';

const makeRefund$ = ({ isGroup, guid, ...data }: MakeRefundPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=CompleteRefund',
    { ...data, [isGroup ? 'groupReservationGUID' : 'formRecordGUID']: guid },
  );

const changePaymentStatus$ = (
  data: Pick<Payment, 'sessionId'> & {
    formCode: string;
    statusCode: 'Initiated' | 'Cancelled' | 'Failed' | 'Completed';
  },
) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=ChangePaymentStatus',
    data,
  );

const initOrbitalTransaction$ = ({
  isGroup,
  guid,
  ...rest
}: {
  amount: number;
  formCode: string;
  orderDesc: string;
  paymentCategoryCode: string;
  paymentType: string;
  address1?: string;
  adjustmentType?: string;
  appealName?: string;
  callbackMapid?: string;
  chequeNumber?: string;
  city?: string;
  comments?: string;
  country?: string;
  discountCategoryCode?: string;
  discountType?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  payerPersonGUID?: string;
  personGUID?: string;
  phone?: string;
  state?: string;
  zip?: string;
  isGroup: boolean;
  guid: string;
}) =>
  apiService.post$<InitOrbitalTransactionResponse>(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=ChaseInitPayment',
    { ...pickBy(rest), [isGroup ? 'groupReservationGUID' : 'formRecordGUID']: guid },
  );

const completeOrbitalPayment$ = ({
  isGroup,
  guid,
  ...data
}: {
  payerPersonGUID: string;
  formCode: string;
  isGroup: boolean;
  guid: string;
  uID: string;
  responseCode: string;
  sessionId: string;
  comments?: string;
}) =>
  apiService
    .post$<{ uID: string; amount: number }>(
      '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=ChaseCompletePayment',
      { ...pickBy(data), [isGroup ? 'groupReservationGUID' : 'formRecordGUID']: guid },
      {
        timeout: 120000,
      },
    )
    .pipe(
      map(response => {
        const { responseCode, responseMessage } = response;
        if (responseCode === '-14') {
          toastService.warn(responseMessage);
          return {
            ...response,
            responseCode: '1',
          };
        }
        return response;
      }),
    );

const processOfflinePayment$ = ({
  formCode,
  isGroup,
  guid,
  personGUID,
  payerPersonGUID,
  amount,
  paymentType,
  paymentCategoryCode = '',
  comments,
  chequeNumber,
  postedDate,
  orderDesc = 'Event Payment offline',
  transactionTypeCode,
  costCenterToCharge = '',
}: OfflinePaymentPayload) =>
  apiService
    .post$<OfflinePaymentResponse>(
      '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=CompletePayment',
      {
        formCode,
        [isGroup ? 'groupReservationGUID' : 'formRecordGUID']: guid,
        personGUID,
        payerPersonGUID,
        amount,
        paymentType,
        orderDesc,
        paymentCategoryCode,
        comments,
        chequeNumber,
        paymentDate: postedDate || dateToString(),
        paymentGateway: '',
        checkoutType: '',
        transactionTypeCode,
        costCenterToCharge,
      },
    )
    .pipe(
      switchMap(({ orderId, sessionId, responseCode, responseMessage }) => {
        if (!responseCode || responseCode !== '1') {
          throw new Error(responseMessage);
        }

        return of({ orderId, sessionId });
      }),
    );
export default {
  makeRefund$,
  changePaymentStatus$,
  initOrbitalTransaction$,
  completeOrbitalPayment$,
  processOfflinePayment$,
};
