import { Row, Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirect } from 'redux-first-router';

import { refreshData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import SelectAddons from '@/modules/entities/Addons/components/SelectAddons';
import { saveOptionsInProgressSel } from '@/modules/entities/Addons/duck/selectors';
import { urlGroupReservationGUIDSel, urlFormCodeSel } from '@/modules/location/duck/selectors';
import EventCover from '@/modules/shared/components/EventCover';
import { isSystemAdministratorSel } from '@/modules/user/duck/selectors';

import { navigateToGroupReservationOverview } from '@/pages/reservation/duck/actions';

import { StyledCard, CardContent, Title } from './EditAddonsPage.styled';

const { Fragment } = React;

const EditAddonsPage: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(saveOptionsInProgressSel);
  const formCode = useSelector(urlFormCodeSel);
  const groupReservationGUID = useSelector(urlGroupReservationGUIDSel);
  const { isCreator, isCollaborator } = useSelector(createDataSel('form'));
  const isSystemAdministrator = useSelector(isSystemAdministratorSel);
  const { sessionName, sessionStartDate, sessionCloseDate, groupReservationExpeditionNumber } =
    useSelector(createDataSel('groupReservation'));
  const groupReservationAddons = useSelector(createDataSel('groupReservationAddons'));
  const { addonsSummary } = useSelector(createDataSel('session')) || '';
  const addons = useSelector(createDataSel('addons'));
  const selectAddonsRef = React.useRef<{ handleSubmit: () => void }>(null);

  const handleSubmit = () => {
    selectAddonsRef.current?.handleSubmit();
  };

  const handleBack = () => {
    dispatch(redirect(navigateToGroupReservationOverview({ formCode, groupReservationGUID })));
  };

  if (!isCreator && !isCollaborator && !isSystemAdministrator) {
    return (
      <StyledCard header="Add-ons" description={addonsSummary}>
        <CardContent>You do not have access</CardContent>
      </StyledCard>
    );
  }

  return (
    <Fragment>
      <EventCover title={sessionName} dates={[sessionStartDate, sessionCloseDate]} />
      <Title>Editing add-ons for Reservation {groupReservationExpeditionNumber}</Title>
      <StyledCard header="Add-ons" description={addonsSummary}>
        <CardContent>
          <SelectAddons
            ref={selectAddonsRef}
            isGroup
            availableAddons={addons}
            selectedAddons={groupReservationAddons}
            groupReservationGUID={groupReservationGUID}
            formCode={formCode}
            finalActions={[
              refreshData({ dataType: 'groupReservation' }),
              refreshData({ dataType: 'groupReservationAddons' }),
              refreshData({ dataType: 'paymentSummary' }),
              redirect(navigateToGroupReservationOverview({ formCode, groupReservationGUID })),
            ]}
          />
        </CardContent>
      </StyledCard>
      <Row justify="space-between">
        <Button disabled={isLoading} onClick={handleBack} type="default" id="onBackButton">
          CANCEL
        </Button>
        <Button disabled={isLoading} loading={isLoading} onClick={handleSubmit} type="primary">
          CONFIRM CHANGES
        </Button>
      </Row>
    </Fragment>
  );
};

export default EditAddonsPage;
