import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { updateFormRecord } from './actions';

const updateFormRecordInProgress = createReducer(false)
  .handleAction(updateFormRecord.request, () => true)
  .handleAction([updateFormRecord.success, updateFormRecord.failure], () => false);

export default combineReducers({
  updateFormRecordInProgress,
});
