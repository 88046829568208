import cn from 'classnames';
import React from 'react';

import styles from './Divider.less';

type Props = {
  noMargin?: boolean;
  className?: string;
};

const Divider: React.FC<Props> = ({ className, noMargin = false }) => (
  <hr
    className={cn(styles.divider, className, {
      [styles.noMargin]: noMargin,
    })}
  />
);

export default Divider;
