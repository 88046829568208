/* eslint-disable import/default */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { Font } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { init as analyticsInit } from '@/modules/utils/analyticsService';
import { init as monitoringInit } from '@/modules/utils/monitoringService';

import App from './modules/core/components/App';
import { getStore } from './root/store';

import RobotoBold from '@/assets/fonts/Roboto-Bold.ttf';
import RobotoBoldItalic from '@/assets/fonts/Roboto-BoldItalic.ttf';
import RobotoItalic from '@/assets/fonts/Roboto-Italic.ttf';
import RobotoRegular from '@/assets/fonts/Roboto-Regular.ttf';
import TrajanPro3Regular from '@/assets/fonts/TrajanPro3Regular.ttf';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-phone-input-2/lib/style.css';

import './modules/styles/base.less';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      src: RobotoRegular,
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      src: RobotoItalic,
    },
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      src: RobotoBold,
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      src: RobotoBoldItalic,
    },
  ],
});

Font.register({
  family: 'Trajan',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: TrajanPro3Regular,
});

require('./favicon.ico');

dayjs.extend(dayOfYear);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(relativeTime);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (!window.Cypress) {
  analyticsInit('UA-2638006-51'); // Might fill from ENV file and inside the function itself

  if (process.env.REACT_APP_ENV === '') {
    // only for PD
    monitoringInit();
  }
}

const store = getStore();

const container = document.getElementById('app');
const root = createRoot(container as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
