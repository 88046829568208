import { CloseOutlined } from '@ant-design/icons';
import React, { MouseEvent, PropsWithChildren } from 'react';

import { RemoveButton, Wrapper } from './ExpressionListItemWrapper.styled';

type Props = {
  name: number;
  showRemoveButton: boolean;
  onRemove: (name: number) => void;
};

const ExpressionListItemWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  name,
  onRemove,
  showRemoveButton,
}) => {
  const handleDelete = (e: MouseEvent) => {
    e.stopPropagation();
    onRemove(name);
  };

  return (
    <Wrapper showRemoveButton={showRemoveButton}>
      {children}
      <RemoveButton type="link" onClick={handleDelete}>
        <CloseOutlined />
      </RemoveButton>
    </Wrapper>
  );
};

export default ExpressionListItemWrapper;
