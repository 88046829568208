import { createAsyncAction } from 'typesafe-actions';

import { Person } from '@/modules/data/dataTypes/person';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';

export const updatePerson = createAsyncAction(
  `${moduleName}/UPDATE_PERSON_REQUEST`,
  `${moduleName}/UPDATE_PERSON_SUCCESS`,
  `${moduleName}/UPDATE_PERSON_FAILURE`,
)<Partial<Person>, string, ApiError>();
