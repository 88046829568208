import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { removeAttendees, deselectAttendee } from '../../duck/actions';
import {
  selectedAttendeesByPersonGuidSel,
  removeAttendeesInProgressSel,
} from '../../duck/selectors';

import { StyledTag, TagContainer } from './RemoveAttendeesModal.styled';

const RemoveAttendeesModal: React.FC = () => {
  const dispatch = useDispatch();
  const attendees = useSelector(selectedAttendeesByPersonGuidSel);
  const isLoading: boolean = useSelector(removeAttendeesInProgressSel);

  function handleModalClose() {
    dispatch(closeModal());
  }

  function handleUnselectAttendee(personGuid: string) {
    dispatch(deselectAttendee(personGuid));
  }

  function handleOnSubmitAttendees() {
    dispatch(removeAttendees.request());
  }

  return (
    <ModalComponent
      title="Remove Attendees"
      buttons={[
        {
          title: 'No, go back',
          onClick: handleModalClose,
        },
        {
          title: 'Yes, Remove',
          disabled: !attendees.length,
          onClick: handleOnSubmitAttendees,
          type: 'primary',
        },
      ]}
      description="Are you sure you want to remove these attendees?"
      inProgress={isLoading}
    >
      <TagContainer>
        {attendees.map(({ fullName, personGUID = '' }) => (
          <StyledTag key={personGUID} closable onClose={() => handleUnselectAttendee(personGUID)}>
            {fullName}
          </StyledTag>
        ))}
      </TagContainer>
    </ModalComponent>
  );
};

export default RemoveAttendeesModal;
