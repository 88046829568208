import { MultiSessionCapacityRule } from '@/modules/data/dataTypes/multiSessionCapacityRules';

import apiService from 'UTILS/apiService';

const updateMultiSessionCapacityRule$ = (
  data: Partial<MultiSessionCapacityRule> & { formCode: string },
) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateMultiSessionRule',
    data,
  );

const deleteMultiSessionCapacityRule$ = (data: {
  formCode: string;
  multiSessionRuleCode: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteMultiSessionRule',
    data,
  );

export default {
  updateMultiSessionCapacityRule$,
  deleteMultiSessionCapacityRule$,
};
