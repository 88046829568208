import React, { PropsWithChildren } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';

import { ui } from '@/modules/utils/monitoringService';

import { navigateToEvents } from '@/pages/eventList/duck/actions';

import { ErrorFallback } from './components/ErrorFallback';

const ErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const recoverFromError = () => dispatch(navigateToEvents());

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={ui} onReset={recoverFromError}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
