import { DatePicker as DatePickerAntd, InputNumber as InputNumberAntd } from 'antd';
import styled from 'styled-components';

export const DatePicker = styled(DatePickerAntd)`
  width: 100%;
`;

export const InputNumber = styled(InputNumberAntd)`
  width: 100%;
`;
