import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchData } from '@/modules/data/duck/actions';
import { createDataSel, createIsLoadingSel } from '@/modules/data/duck/selectors';
import CardWithHeader from '@/modules/shared/components/CardWithHeader';

interface Props {
  children: React.ReactNode;
}

const PersonalDataCard = ({ children }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const isLoadingCountries = useSelector(createIsLoadingSel('countries'));
  const isLoadingStates = useSelector(createIsLoadingSel('states'));
  const isPersonFromMyScouting = !!useSelector(createDataSel('arnicaPerson'));

  useEffect(() => {
    dispatch(fetchData.request({ dataType: 'countries' }));
    dispatch(fetchData.request({ dataType: 'states' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardWithHeader
      header="Personal Data"
      loading={isLoadingCountries || isLoadingStates}
      description={
        isPersonFromMyScouting && (
          <span id="infoText">
            Please confirm info below, which has been copied from{' '}
            <b>
              <a href="https://my.scouting.org/" target="_blank">
                my.scouting.org
              </a>
            </b>{' '}
            profile. Please note that editing info below will not change my.scouting.org account,
            only account here on Events.scouting.org.
          </span>
        )
      }
    >
      {children}
    </CardWithHeader>
  );
};

export default PersonalDataCard;
