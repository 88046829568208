import { List as BsaList } from 'antd';
import React from 'react';

type Props<T> = {
  dataSource: T[];
  renderListItem: (record: T) => React.ReactNode;
};

const List = <T,>({ dataSource, renderListItem }: Props<T>): JSX.Element => (
  <BsaList itemLayout="vertical" dataSource={dataSource} renderItem={renderListItem} />
);

export default List;
