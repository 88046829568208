import React, { Fragment } from 'react';

import Banner from '@/modules/shared/components/Banner';

type Props = { cancellationReason: string };

const CancelledReservationBanner: React.FC<Props> = ({ cancellationReason }) => (
  <Banner
    type="error"
    message={
      <Fragment>
        This Reservation is <b>canceled / declined</b> <br />
        {!!cancellationReason && (
          <div>
            <b>Reason:</b> {cancellationReason}
          </div>
        )}
      </Fragment>
    }
  />
);

export default CancelledReservationBanner;
