import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { Roster } from '@/modules/data/dataTypes/rosterList';
import {
  ageInYearsSel,
  genderSel,
  hasActiveMembershipSel,
  isOlderAgeSel,
} from '@/modules/user/duck/selectors';

import { modulesPath } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulesPath);

export const leadAdvisorDesignateInProgressSel = createSelector(
  moduleSel,
  ({ leadAdvisorDesignateInProgress }) => leadAdvisorDesignateInProgress,
);

const rosterSel = (_state: RootState, { roster }: { roster: Roster }) => roster;

export const leadTypeSel = createSelector(
  rosterSel,
  ({ attendeeTypes }) => attendeeTypes.find(({ isRosterLead }) => isRosterLead) || attendeeTypes[0],
);

export const userLeadAdvisorRestrictionsSel = (
  restrictions: Roster['attendeeTypes'][number]['restrictions'],
  typeName = 'Lead Advisor',
) =>
  createSelector(
    [ageInYearsSel, genderSel, hasActiveMembershipSel, isOlderAgeSel],
    (userAge, userGender, isActiveMember, isOlderAge) => {
      const {
        adultOnly = false,
        minAge = 0,
        maxAge = 0,
        gender = [],
        isBSAMember = false,
      } = restrictions;

      let passed = true;
      let message = '';

      if (adultOnly && !isOlderAge) {
        passed = false;
        message = `Attendee type ${typeName} must be an adult`;
      }

      if ((minAge !== 0 && minAge > userAge) || (maxAge !== 0 && userAge > maxAge)) {
        passed = false;
        message = `Attendee type ${typeName} must be at least age ${minAge} but not yet age ${
          +maxAge + 1
        }`;
      }

      if (userGender && !(gender as string[]).includes(userGender.toLowerCase())) {
        const parsedGender = gender.includes('m') ? 'Male' : 'Female';
        passed = false;
        message = `Attendee type ${typeName} must be ${parsedGender}`;
      }

      if (isBSAMember && !isActiveMember) {
        passed = false;
        message = `Attendee type ${typeName} requires a BSA membership`;
      }

      return {
        passed,
        message,
      };
    },
  );
