import { createAction } from 'typesafe-actions';

import { FoundPeopleQuery } from '@/modules/shared/components/FindPersonForm/types';
import { IndividualReservationPersonFormData } from '@/modules/shared/components/PersonalInfoForm/types';

import { modulePath } from '../constants';

const moduleNamespace = modulePath.join('/');

export const findExistingRegistrant = createAction(
  `${moduleNamespace}/FIND_EXISTING_REGISTRANT`,
)<FoundPeopleQuery>();

export const updatePersonExists = createAction(
  `${moduleNamespace}/UPDATE_PERSON_EXISTS`,
)<boolean>();

export const updateFoundPerson = createAction(`${moduleNamespace}/UPDATE_FOUND_PERSON`)<
  IndividualReservationPersonFormData['personData']
>();
