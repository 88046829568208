import { AlertProps } from 'antd';
import React from 'react';

import { Alert } from './Banner.styled';

type Props = AlertProps;

const Banner: React.FC<Props> = props => <Alert {...props} showIcon />;

export default Banner;
