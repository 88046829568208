import { createAsyncAction, RootAction } from 'typesafe-actions';

import { moduleName } from '@/modules/entities/Reservations/constants';
import { ApiError } from '@/modules/utils/apiService';

import { CreateGroupReservationRostersPayload, UpdateGroupReservationPayload } from '../types';

export const cancelIndividualReservation = createAsyncAction(
  `${moduleName}/CANCEL_INDIVIDUAL_RESERVATION_REQUEST`,
  `${moduleName}/CANCEL_INDIVIDUAL_RESERVATION_SUCCESS`,
  `${moduleName}/CANCEL_INDIVIDUAL_RESERVATION_FAILURE`,
)<
  {
    personGUID: string;
    formRecordGUID: string;
    cancellationReason: string;
    cancellationStatusCode: string;
    sendNotification: boolean;
    finalActions: RootAction[];
  },
  undefined,
  ApiError
>();

export const cancelGroupReservation = createAsyncAction(
  `${moduleName}/CANCEL_GROUP_RESERVATION_REQUEST`,
  `${moduleName}/CANCEL_GROUP_RESERVATION_RESPONSE`,
  `${moduleName}/CANCEL_GROUP_RESERVATION_ERROR`,
)<
  {
    cancellationReason: string;
    sendNotification: boolean;
  },
  undefined,
  Error
>();

type Action = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [ptopName: string]: any;
};

export const updateGroupReservation = createAsyncAction(
  `${moduleName}/UPDATE_RESERVATION_SIZE_REQUEST`,
  `${moduleName}/UPDATE_RESERVATION_SIZE_SUCCESS`,
  `${moduleName}/UPDATE_RESERVATION_SIZE_ERROR`,
)<
  {
    reservation: UpdateGroupReservationPayload;
    successActions?: Action[];
    successMessage?: string;
    failureActions?: Action[];
  },
  undefined,
  ApiError
>();

export const createGroupReservationRosters = createAsyncAction(
  `${moduleName}/CREATE_GROUP_RESERVATION_ROSTERS_REQUEST`,
  `${moduleName}/CREATE_GROUP_RESERVATION_ROSTERS_SUCCESS`,
  `${moduleName}/CREATE_GROUP_RESERVATION_ROSTERS_FAILURE`,
)<CreateGroupReservationRostersPayload, undefined, ApiError>();
