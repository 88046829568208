import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const EventCoverWrapper = styled.div<{ isSlim: boolean }>`
  height: ${({ isSlim }) => (isSlim ? 290 : 448)}px;
  background-color: ${colors.primaryColor};
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;

  img {
    max-width: 100%;
    width: 256px;
    height: 256px;
    margin-top: 30px;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  height: 195px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  flex-direction: column;
`;

export const Title = styled.h1<{ isSlim: boolean }>`
  :only-child {
    color: ${colors.white} !important;
    text-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
    font-size: 64px;
    line-height: 1.13;
    font-family: 'Roboto Slab', sans-serif;
    bottom: 0;
    margin-bottom: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
  }

  ${({ isSlim }) =>
    isSlim
      ? `
        color: ${colors.white} !important;
        text-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
        font-size: 32px;
        font-weight: bold;
        line-height: 1.25;
        font-family: 'Roboto Slab', sans-serif;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        margin-left: 8px;
  `
      : ''}
`;

export const Dates = styled.h3`
  color: ${colors.white} !important;
  font-size: 24px;
  line-height: 1;
  font-family: 'Roboto Slab', sans-serif;
  margin-bottom: 40px;
  margin-left: 8px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;
