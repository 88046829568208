import { Row as BsaRow } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

const Row = styled(BsaRow)`
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  display: flex;
  justify-content: space-between;
  color: ${colors.darkGrey};

  :before,
  :after {
    content: none;
  }
`;

export const TotalDueRow = styled(Row)`
  font-weight: bold;
`;
