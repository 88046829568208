import styled from 'styled-components';

export const AuthHeaderWrapper = styled.div`
  display: flex;
  color: white;
  align-items: center;
`;

export const NameWrapper = styled.span`
  margin-right: 5px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;
