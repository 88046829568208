import { Council } from '@/modules/data/dataTypes/councilList';

export const DATE_OF_BIRTH_FIELD = 'dateOfBirth';

export const ADDRESS_COUNTRY_FIELD = 'country';

export const USA_COUNTRY_SHORT_NAME = 'US';

export const noCouncilAffiliationOption: Council = {
  councilLong: 'No Council Affiliation',
  councilNumber: '-002',
};

export const internationalCouncil: Council = {
  councilLong: 'International',
  councilNumber: '-003',
};
