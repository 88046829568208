import { EditFilled } from '@ant-design/icons';
import { Row } from 'antd';
import { CollapsePanelProps } from 'antd/lib/collapse';
import dayjs from 'dayjs';
import React from 'react';

import { FormRecordActivity } from '@/modules/data/dataTypes/formRecordActivities';
import { GroupReservationActivity } from '@/modules/data/dataTypes/groupReservationActivities';
import { dateFromString, formattedDatesRange, shortDate } from '@/modules/utils/dateFormats';
import { CURRENCY_FORMAT } from '@/modules/utils/numberFormats';

import InfoItem from '../InfoItem';
import Section from '../ReservationDetailsSection';

type BaseProps = CollapsePanelProps & {
  onEditActivities?: () => void;
};

type GroupProps = BaseProps & {
  isGroup: true;
  activities?: GroupReservationActivity[];
};

type IndividualProps = BaseProps & {
  isGroup: false;
  activities?: FormRecordActivity[];
};

type Props = GroupProps | IndividualProps;

const ReservationActivities: React.FC<Props> = ({
  activities,
  onEditActivities,
  isGroup,
  ...rest
}) => (
  <Section
    {...rest}
    headerButtons={[
      ...(onEditActivities
        ? [
            {
              Icon: EditFilled,
              onClick: onEditActivities,
              key: 'editActivities',
            },
          ]
        : []),
    ]}
  >
    {activities && !!activities.length ? (
      activities.map(
        ({ activityName, activityCode, finishDate, totalFee, quantity, startDate }) => {
          const start = dateFromString(startDate);
          const end = dateFromString(finishDate);
          const activityDuration = dayjs.duration(end.diff(start, 'minutes'), 'minutes');
          return (
            <Row key={activityCode}>
              <InfoItem
                header="Activity Name"
                info={`${activityName} (${CURRENCY_FORMAT(totalFee)})`}
              />
              <InfoItem
                header="Activity Date"
                info={`${
                  formattedDatesRange(start, end) || shortDate(start)
                } (${activityDuration.humanize()})`}
              />
              <InfoItem header="Quantity" info={quantity} />
            </Row>
          );
        },
      )
    ) : (
      <Row>No activities selected</Row>
    )}
  </Section>
);

export default ReservationActivities;
