import { LeftOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { back } from 'redux-first-router';

import { prevTypeSel } from '@/modules/location/duck/selectors';

import { GoBackWrapper } from './GoBackLink.styled';

type Props = {
  onBack?: () => void;
};

const GoBackLink: React.FC<Props> = ({ onBack }) => {
  const prevRoute = useSelector(prevTypeSel);

  if (!onBack && !prevRoute) return null;

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      back();
    }
  };

  return (
    <GoBackWrapper onClick={handleBack}>
      <LeftOutlined />
      GO BACK
    </GoBackWrapper>
  );
};

export default GoBackLink;
