import React from 'react';

import { HeaderAction } from '../../types';

import { Button } from './HeaderActionButton.styled';

type Props<T> = {
  record: T;
  action: HeaderAction<T>;
};

const OpenButton = <T,>({ record, action }: Props<T>): JSX.Element => {
  const { onClick } = action;

  const handleClick = () => {
    onClick(record);
  };

  return (
    <Button
      ghost
      size="small"
      onClick={handleClick}
      {...('title' in action
        ? {}
        : {
            color: 'scouting-warm-gray',
          })}
    >
      {'title' in action ? action.title : <action.Icon />}
    </Button>
  );
};

export default OpenButton;
