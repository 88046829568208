import { Row, Col } from 'antd';
import React from 'react';

import ValueWithLabel from 'SHARED/components/ValueWithLabel';

type Props = {
  items: Array<{
    value?: React.ReactElement | string | number | boolean | null;
    label: string;
    extraContent?: React.ReactNode;
  }>;
};

const ItemMapper: React.FC<Props> = ({ items = [] }) => (
  <Row gutter={24}>
    {items.map(({ value, label, extraContent }, i) => (
      <Col lg={4} md={6} sm={8} xs={12} key={value + label + i}>
        <ValueWithLabel label={label} value={value || undefined} />
        {extraContent}
      </Col>
    ))}
  </Row>
);

export default ItemMapper;
