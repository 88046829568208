import React from 'react';

import colors from '@/modules/styles/colors';

import { Button } from './CustomActionBarButton.styled';

type Props = {
  title: string;
  icon: React.ReactElement;
  disabled: boolean;
  onClick: () => void;
  color?: string;
};

const CustomActionBarButton: React.FC<Props> = ({
  title,
  icon,
  onClick,
  disabled,
  color = colors.darkGrey,
}) => (
  <Button onClick={onClick} disabled={disabled} ghost size="large" color={color}>
    {icon}
    {title}
  </Button>
);

export default CustomActionBarButton;
