import { createPageDataParams } from '@/modules/data/utils';

export const tabDataParams = createPageDataParams(
  { dataType: 'arnicaPerson' },
  { dataType: 'jobList' },
  { dataType: 'formRecordAttributes' },
  { dataType: 'optionSets' },
  { dataType: 'addons' },
  { dataType: 'attributeList' },
  { dataType: 'formDisclaimers' },
  { dataType: 'jobDepartmentList' },
  { dataType: 'visibilitySettings' },
  { dataType: 'activities' },
  { dataType: 'countries' },
  { dataType: 'states' },
  { dataType: 'councilList' },
  { dataType: 'formRecordAddons' },
  { dataType: 'formRecordActivities' },
);
