import { Form, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { updateDatablockItem } from '@/pages/createEvent/steps/FormBuilder/duck/actions';
import { isLoadingDatablockItemSel } from '@/pages/createEvent/steps/FormBuilder/duck/selectors';

import {
  DATABLOCK_ITEM_NAME_KEY,
  PLACEHOLDER_TEXT_KEY,
  PRESENTATION_TYPE_KEY,
  defaultDataBlockItem,
  fieldTypeOptions,
} from './constants';
import { AddDatablockItemModalParams } from './types';

import InputMaterial from 'SHARED/components/InputMaterial';
import SelectInputMaterial from 'SHARED/components/SelectInputMaterial';
import { FormItemDataType, MAX_FIELD_CHARS, PresentationType } from 'SHARED/constants';

const { Option } = Select;

interface Props {
  modalParams: AddDatablockItemModalParams;
}

const AddDatablockItemModal: React.FC<Props> = ({ modalParams }) => {
  const { formPartCode, datablockCode } = modalParams;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading = useSelector(isLoadingDatablockItemSel);

  function handleModalClose() {
    dispatch(closeModal());
  }

  function handleSubmit() {
    form.validateFields().then(values => {
      dispatch(
        updateDatablockItem.request({
          dataBlockItem: {
            ...defaultDataBlockItem,
            ...values,
            datablockCode,
            dataType:
              fieldTypeOptions.find(option => option.value === values[PRESENTATION_TYPE_KEY])
                ?.dataType || FormItemDataType.String,
          },
          formPartCode,
        }),
      );
    });
  }

  return (
    <ModalComponent
      title="Add form item"
      buttons={[
        {
          type: 'default',
          onClick: handleModalClose,
          title: 'Cancel',
        },
        {
          type: 'primary',
          onClick: handleSubmit,
          title: 'Add Item',
        },
      ]}
      inProgress={isLoading}
    >
      <Form form={form}>
        <Form.Item
          name={PRESENTATION_TYPE_KEY}
          rules={[{ required: true, message: 'Input Type is required' }]}
        >
          <SelectInputMaterial labelContent="Input type" placeholder="Input type">
            {fieldTypeOptions.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </SelectInputMaterial>
        </Form.Item>
        <Form.Item
          name={DATABLOCK_ITEM_NAME_KEY}
          rules={[
            { required: true, message: 'Label is required' },
            { max: MAX_FIELD_CHARS, message: `Cannot be longer than ${MAX_FIELD_CHARS}` },
          ]}
        >
          <InputMaterial
            labelContent="Label"
            labelSize="1"
            labelInlineBlock
            placeholder="Label"
            type="text"
          />
        </Form.Item>
        <Form.Item noStyle dependencies={[PRESENTATION_TYPE_KEY]}>
          {({ getFieldValue }) => {
            const presentationType = getFieldValue(PRESENTATION_TYPE_KEY);

            if (
              [
                PresentationType.RADIO,
                PresentationType.CHECKBOX,
                PresentationType.SWITCH,
                PresentationType.FILE_UPLOAD,
              ].includes(presentationType)
            ) {
              return null;
            }

            return (
              <Form.Item
                name={PLACEHOLDER_TEXT_KEY}
                rules={[
                  { required: true, message: 'Placeholder is required' },
                  { max: MAX_FIELD_CHARS, message: `Cannot be longer than ${MAX_FIELD_CHARS}` },
                ]}
              >
                <InputMaterial
                  labelContent="Placeholder"
                  labelSize="1"
                  labelInlineBlock
                  placeholder="Placeholder"
                  type="text"
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default AddDatablockItemModal;
