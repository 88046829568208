import { Button, Skeleton } from 'antd';
import React, {
  ForwardRefExoticComponent,
  Fragment,
  RefAttributes,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'typesafe-actions';

import LoadingContainer from '../shared/components/LoadingContainer/LoadingContainer';

import { ButtonsWrapper, Steps as AntSteps } from './Steps.styled';
import { openNextStep, openPrevStep, openStep, resetModuleState } from './duck/actions';
import { currentStepIndexSel, futureStepIndexSel } from './duck/selectors';
import { GetObservable } from './types';

type OwnProps = {
  moduleName: string;
  steps: {
    title: string;
    icon?: React.ReactNode;
    Component: ForwardRefExoticComponent<
      { onOpenNextStep: () => void } & RefAttributes<{ handleSubmit: () => void }>
    >;
    getObservable?: GetObservable;
  }[];
  isLoading?: boolean;
};

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const Steps: React.FC<Props> = ({
  moduleName,
  steps,
  currentStepIndex,
  futureStepIndex,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const ref = useRef<{ handleSubmit: () => void }>(null);

  useEffect(
    () => () => {
      dispatch(resetModuleState(moduleName));
    },
    [dispatch, moduleName],
  );

  useEffect(() => {
    if (currentStepIndex !== futureStepIndex) {
      const futureStep = steps[futureStepIndex];

      if (!futureStep) return;

      dispatch(
        openStep.request({
          moduleName,
          getObservable: futureStep.getObservable,
        }),
      );
    }
  }, [dispatch, currentStepIndex, futureStepIndex, steps, moduleName]);

  const handleOpenPrevStep = () => {
    dispatch(openPrevStep(moduleName));
  };

  const handleOpenNextStep = useCallback(() => {
    dispatch(openNextStep(moduleName));
  }, [dispatch, moduleName]);

  const handleSubmit = () => {
    ref.current?.handleSubmit();
  };

  const renderStepComponent = useCallback(() => {
    if (typeof currentStepIndex !== 'number') {
      return <Skeleton />;
    }
    const StepComponent = steps[currentStepIndex].Component;
    return <StepComponent ref={ref} onOpenNextStep={handleOpenNextStep} />;
  }, [currentStepIndex, steps, handleOpenNextStep]);

  return (
    <Fragment>
      <AntSteps
        current={currentStepIndex}
        items={steps.map(({ Component, getObservable, ...rest }) => rest)}
      />
      <LoadingContainer isLoading={currentStepIndex !== futureStepIndex}>
        <LoadingContainer isLoading={!!isLoading}>{renderStepComponent()}</LoadingContainer>
        <ButtonsWrapper>
          <Button onClick={handleOpenPrevStep} disabled={currentStepIndex === 0}>
            Back
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            Next
          </Button>
        </ButtonsWrapper>
      </LoadingContainer>
    </Fragment>
  );
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  currentStepIndex: currentStepIndexSel(state, props),
  futureStepIndex: futureStepIndexSel(state, props),
});

export default connect(mapStateToProps)(Steps);
