import { createAsyncAction, RootAction } from 'typesafe-actions';

import { moduleName } from '../constants';
import { BasePayloadActivity } from '../types';

interface BaseSaveActivitiesPayload {
  formCode: string;
  activities: BasePayloadActivity[];
  finalActions?: RootAction[];
}

interface GroupSaveActivitiesPayload extends BaseSaveActivitiesPayload {
  isGroup: true;
  groupReservationGUID: string;
}

interface IndividualSaveActivitiesPayload extends BaseSaveActivitiesPayload {
  isGroup: false;
  formRecordGUID: string;
}

type SaveActivitiesPayload = GroupSaveActivitiesPayload | IndividualSaveActivitiesPayload;

export const saveActivities = createAsyncAction(
  `${moduleName}/SAVE_ACTIVITIES_REQUEST`,
  `${moduleName}/SAVE_ACTIVITIES_SUCCESS`,
  `${moduleName}/SAVE_ACTIVITIES_FAILURE`,
)<SaveActivitiesPayload, undefined, Error>();
