import React from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';

import { Props as InputWrapperProps } from '../InputWrapper/types';

import { InputWrapper } from './InputPhone.styled';

type Props = InputWrapperProps & PhoneInputProps;

const InputPhone: React.FC<Props> = ({
  labelContent,
  labelSize,
  wrapperClassName,
  labelColored,
  labelInlineBlock,
  disabled,
  placeholder,
  value,
  loading,
  onChange,
  id,
  ...rest
}) => (
  <InputWrapper
    id={id}
    wrapperClassName={wrapperClassName}
    labelContent={labelContent}
    labelSize={labelSize}
    labelColored={labelColored}
    labelInlineBlock={labelInlineBlock}
    loading={loading}
  >
    <PhoneInput
      {...rest}
      country="us"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  </InputWrapper>
);

export default InputPhone;
