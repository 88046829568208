import dayjs from 'dayjs';
import { combineEpics, Epic } from 'redux-observable';
import { concat, of, defer, EMPTY, merge, race, zip, forkJoin, Observable } from 'rxjs';
import { filter, switchMap, catchError, map, delay, take, takeUntil } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import {
  fetchData,
  removeDataItem,
  replaceDataItem,
  scheduleRefresh,
  setFilter,
} from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { arnicaServiceDefinitions } from '@/modules/data/duck/services';
import { addNotification, addNotice } from '@/modules/entities/Notifications/duck/actions';
import rolesServices from '@/modules/entities/Roles/duck/services';
import { createQueryFilterPermissionTarget } from '@/modules/entities/Roles/utils';
import { CONFIRM_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal, updateModalParams } from '@/modules/modals/duck/actions';
import { cancelAction, confirmAction } from '@/modules/shared/components/ConfirmModal/duck/actions';
import { ModalParams as ConfirmModalParams } from '@/modules/shared/components/ConfirmModal/types';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';
import { usDateTime } from '@/modules/utils/dateFormats';

import { TabKey } from '../constants';
import { FILTER_KEY_QUERY_FILTER_CODE } from '../tabs/CurrentReport/constants';
import { resetCurrentReport } from '../tabs/CurrentReport/duck/actions';
import currentReportEpics from '../tabs/CurrentReport/duck/epics';
import { createCurrentQueryFilterSel, filtersSel } from '../tabs/CurrentReport/duck/selectors';
import currentReportServices from '../tabs/CurrentReport/duck/services';
import { ReportDataType } from '../types';
import { getIsEmailsDisabled, getReportDataId } from '../utils';

import {
  updateReportPermissions,
  downloadDataRequest,
  downloadDataHeartbeat,
  downloadDataError,
  deleteReport,
  selectReport,
  openTab,
} from './actions';
import services from './services';

import { PermissionAction } from 'ROLES/constants';

class FetchReportError extends Error {
  dataType: ReportDataType;
  formCode: string;

  constructor(dataType: ReportDataType, formCode: string) {
    super('fetch Report failure');

    this.dataType = dataType;
    this.formCode = formCode;
  }
}

const openTab$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(openTab.request)),
    switchMap(({ payload: { tabKey, formCode, dataType } }) => {
      const dataId = getReportDataId(dataType);
      const apiName = arnicaServiceDefinitions[dataType]?.scriptCode;

      return concat(
        merge(
          prefetchData$(
            action$,
            { dataType: 'queryFilterVariables', dataId, queryObj: { formCode, apiName } },
            getIsEmailsDisabled(dataType)
              ? null
              : {
                  dataType: 'emailTemplateList',
                  dataId,
                  queryObj: {
                    apiName,
                    formCode,
                  },
                },
          ),
          defer(() => {
            switch (tabKey) {
              case TabKey.SavedReports:
                return prefetchData$(action$, {
                  dataType: 'queryFilters',
                  dataId,
                  queryObj: { formCode, apiName },
                });
              case TabKey.CurrentReport:
                return merge(
                  prefetchData$(
                    action$,
                    { dataType, dataId, queryObj: { formCode, getAllGuids: true } },
                    { dataType: 'form', queryObj: { formCode } },
                    { dataType: 'optionSets', queryObj: { formCode } },
                  ),
                  action$.pipe(
                    filter(isActionOf(fetchData.failure)),
                    filter(
                      ({ payload }) => payload.dataType === dataType && payload.dataId === dataId,
                    ),
                    switchMap(() => {
                      throw new FetchReportError(dataType, formCode);
                    }),
                    takeUntil(
                      action$.pipe(
                        filter(isActionOf([fetchData.success, fetchData.cancel])),
                        filter(
                          ({ payload }) =>
                            payload.dataType === dataType && payload.dataId === dataId,
                        ),
                      ),
                    ),
                  ),
                );
              default:
                return EMPTY;
            }
          }),
        ),
        of(openTab.success({ tabKey, dataType })),
      );
    }),
    catchError((error: Error | FetchReportError, caught) => {
      const modalParams: ConfirmModalParams = {
        title: 'Report Failed to Fetch',
        description: 'Would you like to download CSV?',
        confirmButton: { title: 'Yes' },
        cancelButton: { title: 'No' },
      };

      return merge(
        caught,
        of(openTab.failure(error)),
        'dataType' in error
          ? merge(
              race(
                action$.pipe(
                  filter(isActionOf(confirmAction)),
                  switchMap(() => {
                    const { formCode, dataType } = error;
                    const queryObj = filtersSel(state$.value, { dataType });
                    return of(closeModal(), downloadDataRequest({ formCode, dataType, queryObj }));
                  }),
                ),
                action$.pipe(
                  filter(isActionOf(cancelAction)),
                  map(() => closeModal()),
                ),
              ),
              of(openModal(CONFIRM_MODAL, modalParams)),
            )
          : EMPTY,
      );
    }),
  );

export const prefetchReports$ = (
  action$: Observable<RootAction>,
  params: {
    dataType: ReportDataType;
    formCode: string;
  },
): Observable<RootAction> =>
  merge(
    action$.pipe(
      filter(isActionOf(openTab.success)),
      take(1),
      takeUntil(action$.pipe(filter(isActionOf(openTab.failure)), take(1))),
    ),
    of(openTab.request({ ...params, tabKey: TabKey.SavedReports })),
  );

const selectReport$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(selectReport)),
    switchMap(({ payload: { queryFilterCode, dataType } }) => {
      const dataId = getReportDataId(dataType);
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      const { [FILTER_KEY_QUERY_FILTER_CODE]: prevQueryFilterCode } = filtersSel(state, {
        dataType,
      });

      const openTabAction = openTab.request({ dataType, formCode, tabKey: TabKey.CurrentReport });

      if (queryFilterCode === prevQueryFilterCode) {
        return of(openTabAction);
      }

      return concat(
        merge(
          action$.pipe(
            filter(isActionOf(resetCurrentReport.success)),
            take(1),
            takeUntil(action$.pipe(filter(isActionOf(resetCurrentReport.failure)), take(1))),
          ),
          of(resetCurrentReport.request(dataType)),
        ),
        of(
          setFilter({
            dataType,
            dataId,
            key: FILTER_KEY_QUERY_FILTER_CODE,
            value: queryFilterCode,
          }),
          openTabAction,
        ),
      );
    }),
  );

const createRoleUpdates = (roles: string[], isIncluded: boolean, permissionCode?: string) => {
  if (!permissionCode || !roles.length) return [];
  return roles.map(roleCode => ({
    roleCode,
    permissions: [
      {
        permissionCode,
        isIncluded,
      },
    ],
  }));
};

const createUpdatePermissionObservable = (queryFilterCode: string, action: PermissionAction) => {
  const queryFilterPermissionTarget = createQueryFilterPermissionTarget(queryFilterCode);
  return rolesServices
    .updatePermission$({
      action,
      target: queryFilterPermissionTarget,
    })
    .pipe(map(({ responseValue }) => responseValue));
};

const updateReportPermissions$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateReportPermissions.request)),
    switchMap(
      ({
        payload: {
          dataType,
          queryFilterCode,
          isPublic,
          readPermissionCode,
          writePermissionCode,
          rolesWithRead,
          rolesWithWrite,
          rolesWithoutRead,
          rolesWithoutWrite,
        },
      }) => {
        const state = state$.value;
        const dataId = getReportDataId(dataType);
        const currentQueryFilter = createCurrentQueryFilterSel(queryFilterCode)(state, {
          dataType,
        });

        const apiName = arnicaServiceDefinitions[dataType]?.scriptCode as string;

        const updatedQueryFilter = currentQueryFilter &&
          isPublic !== currentQueryFilter.isPublic && { ...currentQueryFilter, isPublic, apiName };

        return zip(
          readPermissionCode || (!rolesWithRead.length && !rolesWithoutRead.length)
            ? of(readPermissionCode)
            : createUpdatePermissionObservable(queryFilterCode, PermissionAction.Read),
          writePermissionCode || (!rolesWithWrite.length && !rolesWithoutWrite.length)
            ? of(writePermissionCode)
            : createUpdatePermissionObservable(queryFilterCode, PermissionAction.Update),
        ).pipe(
          switchMap(([readCode, writeCode]) => {
            const updates = [
              ...createRoleUpdates(rolesWithRead, true, readCode),
              ...createRoleUpdates(rolesWithoutRead, false, readCode),
              ...createRoleUpdates(rolesWithWrite, true, writeCode),
              ...createRoleUpdates(rolesWithoutWrite, false, writeCode),
            ];
            const permissionsByRoleCode = updates.reduce(
              (
                acc: Record<string, (typeof updates)[number]['permissions']>,
                { roleCode, permissions },
              ) => ({
                ...acc,
                [roleCode]: [...(acc[roleCode] || []), ...permissions],
              }),
              {},
            );
            const groupedUpdates = Object.entries(permissionsByRoleCode).map(
              ([roleCode, permissions]) => ({
                roleCode,
                permissions,
              }),
            );

            return forkJoin([
              ...groupedUpdates.map(update => rolesServices.updateRole$(update)),
              ...(updatedQueryFilter
                ? [currentReportServices.updateQueryFilter$(updatedQueryFilter)]
                : []),
            ]);
          }),
          switchMap(() =>
            merge(
              updatedQueryFilter
                ? of(
                    replaceDataItem({
                      dataType: 'queryFilters',
                      dataId,
                      idField: 'queryFilterCode',
                      dataItem: updatedQueryFilter,
                    }),
                  )
                : EMPTY,
              of(
                updateReportPermissions.success(),
                closeModal(),
                scheduleRefresh({ dataType: 'personRolesList' }),
                scheduleRefresh({ dataType: 'formRolesList' }),
                scheduleRefresh({ dataType: 'permissionsList' }),
              ),
            ),
          ),
        );
      },
    ),
    catchError((err: Error, caught) => {
      toastService.error(err.message);
      return merge(of(updateReportPermissions.failure(err)), caught);
    }),
  );

const deleteReport$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteReport)),
    switchMap(({ payload: { dataType, queryFilterCode } }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      const dataId = getReportDataId(dataType);
      const currentQueryFilter = createCurrentQueryFilterSel()(state, { dataType });

      const confirmModalParams: ConfirmModalParams = {
        confirmButton: {
          title: 'Yes, Delete',
        },
        description: 'Are you sure you want to delete this report?',
        title: 'Delete Report?',
      };

      return merge(
        race(
          action$.pipe(
            filter(isActionOf(confirmAction)),
            take(1),
            switchMap(() => {
              const nextConfirmModalParams: ConfirmModalParams = {
                ...confirmModalParams,
                inProgress: true,
              };
              return merge(
                of(updateModalParams(nextConfirmModalParams)),
                services.deleteQueryFilter$({ queryFilterCode, formCode }).pipe(
                  switchMap(() => {
                    toastService.success('Report deleted');
                    return merge(
                      queryFilterCode === currentQueryFilter?.queryFilterCode
                        ? of(resetCurrentReport.request(dataType))
                        : EMPTY,
                      of(
                        removeDataItem({
                          dataType: 'queryFilters',
                          dataId,
                          idField: 'queryFilterCode',
                          dataItemId: queryFilterCode,
                        }),
                        closeModal(),
                      ),
                    );
                  }),
                ),
              );
            }),
          ),
          action$.pipe(
            filter(isActionOf(cancelAction)),
            take(1),
            map(() => closeModal()),
          ),
        ),
        of(openModal(CONFIRM_MODAL, confirmModalParams)),
      );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(closeModal()), caught);
    }),
  );

const downloadDataStart$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(downloadDataRequest)),
    switchMap(({ payload: { dataType, queryObj, formCode } }) => {
      const reportName = `${formCode}_${dataType}`;

      return merge(
        of(
          addNotice({
            title: 'Your CSV download is in process',
            content: 'Once your file is read, you will see a notification here to download it',
          }),
        ),
        services.getDownloadStartService$(dataType, { ...queryObj, formCode }).pipe(
          switchMap(({ responseValue: contextToken = '' }) =>
            of(
              addNotification({
                type: 'generic',
                title: `Report ${reportName}`,
                content: 'Your CSV download is in process',
                id: contextToken,
                read: false,
              }),
              downloadDataHeartbeat({ dataType, contextToken, reportName }),
            ),
          ),
          catchError(error => {
            toastService.error(error.message);
            return of(downloadDataError({ dataType, error }));
          }),
        ),
      );
    }),
  );

const downloadDataHeartbeat$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(downloadDataHeartbeat)),
    delay(2000),
    switchMap(({ payload: { dataType, contextToken, reportName } }) =>
      services.checkReportStatus$(contextToken).pipe(
        switchMap(({ responseValue }) => {
          if (responseValue !== '1') {
            return of(downloadDataHeartbeat({ dataType, contextToken, reportName }));
          }

          const reportDownloadName = `${reportName}_${usDateTime(dayjs())}`;
          const downloadUrl = services.downloadReportFile$(contextToken);
          return of(
            addNotification({
              type: 'reportDownloadReady',
              id: contextToken,
              reportName: reportDownloadName,
              downloadUrl,
              read: false,
            }),
          );
        }),
        catchError(error => {
          toastService.error(error.message);
          return of(downloadDataError({ dataType, error }));
        }),
      ),
    ),
  );

export default combineEpics(
  currentReportEpics,
  openTab$,
  updateReportPermissions$,
  deleteReport$,
  downloadDataStart$,
  downloadDataHeartbeat$,
  selectReport$,
);
