import { Dayjs } from 'dayjs';
import { createAction, createAsyncAction, RootAction } from 'typesafe-actions';

import { DataTypes } from '@/modules/data/dataTypes';
import { EmailTemplate } from '@/modules/data/dataTypes/emailTemplate';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';

type SendCustomEmailRequestPayload = {
  dataType: keyof DataTypes;
  mailing: {
    queryFilterCode: string;
    emailCCAddress?: string;
    processingStartDate?: Dayjs;
  };
  template:
    | Pick<EmailTemplate, 'emailTemplateCode' | 'emailTemplateName'>
    | (Pick<EmailTemplate, 'senderEmailAddress' | 'emailTemplateContent' | 'emailSubject'> &
        Partial<Pick<EmailTemplate, 'emailTemplateName'>>);
};

export const sendCustomEmail = createAsyncAction(
  `${moduleName}/SEND_CUSTOM_EMAIL_REQUEST`,
  `${moduleName}/SEND_CUSTOM_EMAIL_SUCCESS`,
  `${moduleName}/SEND_CUSTOM_EMAIL_FAILURE`,
)<SendCustomEmailRequestPayload, undefined, ApiError>();

export const sendEmail = createAsyncAction(
  `${moduleName}/SEND_EMAIL_REQUEST`,
  `${moduleName}/SEND_EMAIL_SUCCESS`,
  `${moduleName}/SEND_EMAIL_FAILURE`,
)<
  {
    formCode: string;
    emailTemplateCode: string;
    formRecordGUID: string | string[];
    finalActions?: RootAction[];
  },
  undefined,
  ApiError
>();

export const updateEmailTemplate = createAsyncAction(
  `${moduleName}/UPDATE_EMAIL_TEMPLATE_REQUEST`,
  `${moduleName}/UPDATE_EMAIL_TEMPLATE_SUCCESS`,
  `${moduleName}/UPDATE_EMAIL_TEMPLATE_FAILURE`,
)<
  Pick<
    EmailTemplate,
    | 'description'
    | 'emailTemplateName'
    | 'emailSubject'
    | 'emailTemplateContent'
    | 'apiName'
    | 'senderEmailAddress'
    | 'senderName'
  > & { formCode: string },
  undefined,
  ApiError
>();

export const deleteEmailTemplate = createAction(`${moduleName}/DELETE_EMAIL_TEMPLATE`)<{
  emailTemplateCode: string;
  emailTemplateApiName: string;
  formCode: string;
}>();
