import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Button = styled(AntdButton)`
  box-shadow: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.ant-btn.ant-btn-background-ghost {
    color: ${colors.darkGrey};
    border: none;
  }
`;
