import { produce } from 'immer';
import { get, isBoolean, isNumber, isNaN, omit, set } from 'lodash';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { DataTypes, PluralDataTypes } from '../dataTypes';
import { getKey } from '../utils';

import {
  addDataItem,
  appendDataResponse,
  fetchData,
  refreshData,
  removeData,
  removeDataItem,
  removeFilter,
  replaceData,
  replaceDataItem,
  scheduleRefresh,
  resetFilters,
  resetPagination,
  setFilter,
  updatePageNumber,
  updatePageSize,
  setFilters,
  clearError,
} from './actions';

export const dataReducer = createReducer<DataTypes>({} as DataTypes)
  .handleAction(fetchData.success, (state, { payload }) =>
    produce(state, draft => {
      const { responseField, data } = payload;
      const key = getKey(payload) as keyof DataTypes;
      draft[key] = responseField ? get(data, responseField) : data;
    }),
  )
  .handleAction(appendDataResponse, (state, { payload }) =>
    produce(state, draft => {
      const { responseField, data } = payload;
      const key = getKey(payload) as keyof PluralDataTypes;
      const newData = responseField ? get(data, responseField, []) : data;
      const updatedData = [...draft[key], ...newData];
      draft[key] = updatedData;
    }),
  )
  .handleAction(addDataItem, (state, { payload }) =>
    produce(state, draft => {
      const key = getKey(payload) as keyof PluralDataTypes;
      const { dataItem } = payload;
      const draftData = draft[key];
      if (Array.isArray(draftData)) {
        draftData.push(dataItem);
      } else {
        draft[key] = [dataItem];
      }
    }),
  )
  .handleAction(replaceData, (state, { payload }) =>
    produce(state, draft => {
      const key = getKey(payload) as keyof DataTypes;
      draft[key] = payload.data;
    }),
  )
  .handleAction(replaceDataItem, (state, { payload }) =>
    produce(state, draft => {
      const key = getKey(payload) as keyof PluralDataTypes;
      const { dataItem, idField } = payload;
      const id = dataItem[idField];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const draftData: any[] = Array.isArray(draft[key]) ? draft[key] : [];
      const dataItemIndex = draftData.findIndex(item => item[idField] === id);
      draftData[dataItemIndex > -1 ? dataItemIndex : draftData.length] = dataItem;
    }),
  )
  .handleAction(removeDataItem, (state, { payload }) =>
    produce(state, draft => {
      const { dataItemId, idField } = payload;
      const key = getKey(payload) as keyof PluralDataTypes;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const draftData: any[] = draft[key];
      const dataItemIndex = draftData.findIndex(item => item[idField] === dataItemId);
      draftData.splice(dataItemIndex, 1);
    }),
  )
  .handleAction(removeData, (state, { payload }) =>
    produce(state, draft => {
      const key = getKey(payload) as keyof DataTypes;
      delete (draft as Partial<typeof draft>)[key];
    }),
  );

type IsLoading = Record<keyof DataTypes, boolean>;

const isLoading = createReducer<IsLoading>({} as IsLoading)
  .handleAction(
    [fetchData.request, updatePageNumber, updatePageSize, refreshData],
    (state, { payload }) => ({ ...state, [getKey(payload)]: true }),
  )
  .handleAction(
    [setFilter, setFilters, resetFilters, resetPagination, removeFilter],
    (state, { payload }) => ({
      ...state,
      [getKey(payload)]: !isBoolean(payload.fetchData) || payload.fetchData,
    }),
  )
  .handleAction([fetchData.success, fetchData.failure, fetchData.cancel], (state, { payload }) => ({
    ...state,
    [getKey(payload)]: false,
  }))
  .handleAction(removeData, (state, { payload }) => omit(state, getKey(payload)) as IsLoading);

type QueryObjects = Record<
  keyof DataTypes,
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prev?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next?: any;
  }
>;

const queryObjects = createReducer<QueryObjects>({} as QueryObjects)
  .handleAction(fetchData.request, (state, { payload }) =>
    produce(state, draft => {
      const key = getKey(payload) as keyof DataTypes;
      const { queryObj = {} } = payload;
      set(draft, [key, 'next'], queryObj);
    }),
  )
  .handleAction([fetchData.success, fetchData.cancel, fetchData.failure], (state, { payload }) =>
    produce(state, draft => {
      const key = getKey(payload) as keyof DataTypes;
      const dataState = state[key] || {};
      const { prev, next } = dataState;
      set(draft, [key, 'prev'], next || prev);
      delete draft[key].next;
    }),
  )
  .handleAction(removeData, (state, { payload }) =>
    produce(state, draft => {
      const key = getKey(payload) as keyof DataTypes;
      delete draft[key];
    }),
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ErrorData = Record<keyof DataTypes, any>;

const error = createReducer<ErrorData>({} as ErrorData)
  .handleAction(
    [fetchData.request, fetchData.success, appendDataResponse, clearError],
    (state, { payload }) => omit(state, getKey(payload)) as ErrorData,
  )
  .handleAction(fetchData.failure, (state, { payload }) => {
    const {
      error: { message, responseCode },
    } = payload;
    const key = getKey(payload);
    return {
      ...state,
      [key]: { message, responseCode },
    };
  });

type Filters = Record<
  keyof DataTypes,
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prev?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next?: any;
  }
>;

const filters = createReducer<Filters>({} as Filters)
  .handleAction(setFilter, (state, { payload }) =>
    produce(state, draft => {
      const dataKey = getKey(payload) as keyof PluralDataTypes;
      const { key, value } = payload;
      const savedFilters = draft[dataKey] || {};
      const nextFilters = 'next' in savedFilters ? savedFilters.next : savedFilters.prev;
      set(draft, [dataKey, 'next'], {
        ...nextFilters,
        [key]: value,
      });
    }),
  )
  .handleAction(setFilters, (state, { payload }) =>
    produce(state, draft => {
      const dataKey = getKey(payload) as keyof PluralDataTypes;
      const savedFilters = draft[dataKey] || {};
      const nextFilters = 'next' in savedFilters ? savedFilters.next : savedFilters.prev;
      set(draft, [dataKey, 'next'], {
        ...nextFilters,
        ...payload.filters,
      });
    }),
  )
  .handleAction(removeFilter, (state, { payload }) => {
    const dataKey = getKey(payload) as keyof PluralDataTypes;
    const { key } = payload;
    const savedFilters = state[dataKey] || {};
    const nextFilters = 'next' in savedFilters ? savedFilters.next : savedFilters.prev;
    return {
      ...state,
      [dataKey]: {
        ...savedFilters,
        next: nextFilters && omit(nextFilters, key),
      },
    };
  })
  .handleAction(resetFilters, (state, { payload }) => {
    const dataKey = getKey(payload) as keyof PluralDataTypes;
    const savedFilters = state[dataKey];
    return {
      ...state,
      [dataKey]: {
        ...savedFilters,
        next: null,
      },
    };
  })
  .handleAction([fetchData.success, fetchData.cancel], (state, { payload }) => {
    const dataKey = getKey(payload) as keyof PluralDataTypes;
    const savedFilters = state[dataKey];

    if (!savedFilters || !('next' in savedFilters)) return state;
    if (savedFilters.next === null) return omit(state, dataKey) as Filters;

    return {
      ...state,
      [dataKey]: {
        prev: savedFilters.next,
      },
    };
  })
  .handleAction([fetchData.failure, removeData], (state, { payload }) =>
    produce(state, draft => {
      const key = getKey(payload) as keyof DataTypes;
      delete draft[key];
    }),
  );

type PageSize = Record<
  keyof DataTypes,
  {
    prev?: number | null;
    next?: number | null;
  }
>;
const pageSizeReducer = createReducer<PageSize>({} as PageSize)
  .handleAction(updatePageSize, (state, { payload }) => {
    const key = getKey(payload) as keyof PluralDataTypes;
    const { pageSize } = payload;
    const pageSizes = state[key];
    return { ...state, [key]: { ...pageSizes, next: pageSize } };
  })
  .handleAction(resetPagination, (state, { payload }) => {
    const key = getKey(payload) as keyof PluralDataTypes;
    const pageSizes = state[key];
    return { ...state, [key]: { ...pageSizes, next: null } };
  })
  .handleAction([fetchData.success, fetchData.cancel], (state, { payload }) => {
    const key = getKey(payload) as keyof PluralDataTypes;
    const pageSizes = state[key];

    if (!pageSizes || !('next' in pageSizes)) return state;
    if (pageSizes.next === null) return omit(state, key) as PageSize;

    return { ...state, [key]: { prev: pageSizes.next } };
  })
  .handleAction(fetchData.failure, (state, { payload }) => {
    const key = getKey(payload) as keyof PluralDataTypes;
    return omit(state, key) as PageSize;
  });

type PageNumber = Record<
  keyof DataTypes,
  {
    prev?: number | null;
    next?: number | null;
  }
>;

const pageNumberReducer = createReducer<PageNumber>({} as PageNumber)
  .handleAction(updatePageNumber, (state, { payload }) => {
    const key = getKey(payload) as keyof PluralDataTypes;
    const { pageNumber } = payload;
    const pageNumbers = state[key];
    return { ...state, [key]: { ...pageNumbers, next: pageNumber } };
  })
  .handleAction(
    [setFilter, setFilters, removeFilter, resetFilters, resetPagination],
    (state, { payload }) => {
      const key = getKey(payload) as keyof PluralDataTypes;
      const pageNumbers = state[key];
      return { ...state, [key]: { ...pageNumbers, next: null } };
    },
  )
  .handleAction([fetchData.success, fetchData.cancel], (state, { payload }) => {
    const key = getKey(payload) as keyof PluralDataTypes;
    const pageNumbers = state[key];

    if (!pageNumbers || !('next' in pageNumbers)) return state;
    if (pageNumbers.next === null) return omit(state, key) as PageNumber;

    return { ...state, [key]: { prev: pageNumbers.next } };
  })
  .handleAction(
    fetchData.failure,
    (state, { payload }) => omit(state, getKey(payload)) as PageNumber,
  );

type RecordCount = Record<keyof DataTypes, number>;

const recordCountReducer = createReducer<RecordCount>({} as RecordCount)
  .handleAction([fetchData.success, appendDataResponse], (state, { payload }) => {
    const { recordCount } = payload;
    const key = getKey(payload);
    const count = Number(recordCount);
    if (isNumber(count) && !isNaN(count)) {
      return { ...state, [key]: count };
    }
    return state;
  })
  .handleAction(
    fetchData.failure,
    (state, { payload }) => omit(state, getKey(payload)) as RecordCount,
  );

type RefreshScheduled = Record<keyof DataTypes, boolean>;

const refreshScheduled = createReducer<RefreshScheduled>({} as RefreshScheduled)
  .handleAction(
    [fetchData.request, resetPagination, setFilter, removeFilter, resetFilters, setFilters],
    (state, { payload }) =>
      produce(state, draft => {
        if ('fetchData' in payload && payload.fetchData) {
          const key = getKey(payload) as keyof DataTypes;
          draft[key] = true;
        }
      }),
  )
  .handleAction([fetchData.success, fetchData.cancel], (state, { payload }) =>
    produce(state, draft => {
      const key = getKey(payload) as keyof DataTypes;
      delete draft[key];
    }),
  )
  .handleAction(
    [
      scheduleRefresh,
      refreshData,
      addDataItem,
      replaceData,
      replaceDataItem,
      removeDataItem,
      fetchData.failure,
    ],
    (state, { payload }) =>
      produce(state, draft => {
        const key = getKey(payload) as keyof DataTypes;
        draft[key] = true;
      }),
  );

type FetchSkipped = Record<keyof DataTypes, boolean>;

const fetchSkipped = createReducer<FetchSkipped>({} as FetchSkipped)
  .handleAction(
    [
      fetchData.request,
      updatePageNumber,
      updatePageSize,
      setFilter,
      setFilters,
      removeFilter,
      resetFilters,
      resetPagination,
    ],
    (state, { payload }) =>
      produce(state, draft => {
        const key = getKey(payload) as keyof DataTypes;
        if ('fetchData' in payload && !payload.fetchData) {
          draft[key] = true;
        } else {
          delete draft[key];
        }
      }),
  )
  .handleAction([fetchData.success, fetchData.failure], (state, { payload }) =>
    produce(state, draft => {
      const key = getKey(payload) as keyof DataTypes;
      delete draft[key];
    }),
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extraData = createReducer<DataTypes>({} as DataTypes)
  .handleAction(fetchData.success, (state, { payload }) => {
    const { responseField, data } = payload;
    return {
      ...state,
      [getKey(payload)]: responseField ? omit(data, responseField) : {},
    };
  })
  .handleAction(removeData, (state, { payload }) => omit(state, getKey(payload)) as DataTypes);

export default combineReducers({
  data: dataReducer,
  isLoading,
  queryObjects,
  error,
  filters,
  pageSize: pageSizeReducer,
  pageNumber: pageNumberReducer,
  recordCount: recordCountReducer,
  refreshScheduled,
  fetchSkipped,
  extraData,
});
