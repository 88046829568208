import { PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { PaymentMethod } from '@/modules/payments/constants';

import { MAX_ORBITAL_PAYMENT_AMOUNT, PAYMENT_METHODS_WITH_LIMIT } from './constants';

export const getTotalBalanceDue = ({ totalFee, amountPaid }: PaymentSummary) =>
  Math.max(0, totalFee - amountPaid);

export const getPaymentAmount = (
  amount: number,
  maxAmount: number,
  paymentMethod: PaymentMethod | null,
) => {
  const maxPaymentAmount =
    paymentMethod &&
    PAYMENT_METHODS_WITH_LIMIT.has(paymentMethod) &&
    maxAmount > MAX_ORBITAL_PAYMENT_AMOUNT
      ? MAX_ORBITAL_PAYMENT_AMOUNT
      : maxAmount;

  return Math.min(amount, maxPaymentAmount);
};
