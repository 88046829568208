import dayjs from 'dayjs';
import { pick } from 'lodash';
import { unparse } from 'papaparse';

import { Session } from '@/modules/data/dataTypes/sessionList';
import { SERVER_DATE_FORMAT } from '@/modules/utils/dateFormats';

import {
  CSV_DATE_TIME_FORMAT,
  INTERNAL_COLUMN_DELIMITERS,
  sessionCsvColumnsConfig,
} from './constants';
import { SessionCSVColumnKey } from './types';

export function isEmptyValue(value: string | number | boolean | undefined): boolean {
  if (!value) {
    if (typeof value === 'number' && value === 0) return false;
    if (typeof value === 'boolean') return false;

    return true;
  }

  if (typeof value === 'string' && value.trim().length === 0) return true;

  return false;
}

export const getColumnKeys = (allowGroupRegistration: boolean): SessionCSVColumnKey[] =>
  Object.entries(sessionCsvColumnsConfig).reduce(
    (acc, [key, { isForGroupOnly }]) =>
      !allowGroupRegistration && isForGroupOnly ? acc : [...acc, key as SessionCSVColumnKey],
    [] as SessionCSVColumnKey[],
  );

export const createGetInternalColumnKeys = (
  allowGroupRegistration: boolean,
): ((columnKey: SessionCSVColumnKey) => string[]) => {
  const cache = {} as Record<SessionCSVColumnKey, string[]>;

  return (columnKey: SessionCSVColumnKey) => {
    if (!cache[columnKey]) {
      const internalColumnsConfig = sessionCsvColumnsConfig[columnKey]?.internalColumnsConfig || [];
      cache[columnKey] = internalColumnsConfig.reduce(
        (acc, { key, isForIndividualOnly }) =>
          allowGroupRegistration && isForIndividualOnly ? acc : [...acc, key],
        [] as string[],
      );
    }
    return cache[columnKey];
  };
};

const getUserFriendlyValue = (
  columnKey: SessionCSVColumnKey,
  columnValue: Session[SessionCSVColumnKey],
  getInternalColumnKeys: (columnKey: SessionCSVColumnKey) => string[],
) => {
  if (columnValue) {
    const columnConfig = sessionCsvColumnsConfig[columnKey];
    if (columnConfig?.isDate) {
      return dayjs(columnValue as string, SERVER_DATE_FORMAT).format(CSV_DATE_TIME_FORMAT);
    }

    if (columnKey === 'description') {
      return decodeURIComponent(columnValue as string);
    }

    if (columnConfig?.internalColumnsConfig) {
      const internalColumnKeys = getInternalColumnKeys(columnKey);
      const pickedKeys = (columnValue as unknown as Record<string, unknown>[]).map(record =>
        pick(record, internalColumnKeys),
      );

      const csvVersion = unparse(pickedKeys, {
        ...INTERNAL_COLUMN_DELIMITERS,
        header: false,
      });
      return csvVersion;
    }
  }

  return columnValue;
};

export const getUserFriendlyKey = (
  columnKey: SessionCSVColumnKey,
  getInternalColumnKeys: (columnKey: SessionCSVColumnKey) => string[],
): string => {
  const columnConfig = sessionCsvColumnsConfig[columnKey];

  let friendlyKey = columnConfig?.name || columnKey;

  if (columnConfig?.required) {
    friendlyKey = `${friendlyKey} *(req.)`;
  }

  if (columnConfig?.isDate) {
    friendlyKey = `${friendlyKey} (format: ${CSV_DATE_TIME_FORMAT})`;
  }

  if (columnConfig?.internalColumnsConfig) {
    const internalColumnKeys = getInternalColumnKeys(columnKey);
    const dummyData = [internalColumnKeys, internalColumnKeys].map((row, index) =>
      row.map(internalColumnKey => `${internalColumnKey}${index + 1}`),
    );
    const parsedDummyData = unparse(dummyData, INTERNAL_COLUMN_DELIMITERS);
    friendlyKey = `${friendlyKey} (Structure: ${parsedDummyData})`;
  }

  return friendlyKey;
};

const getColumnHeadersForCsv = (
  columnKeys: SessionCSVColumnKey[],
  getInternalColumnKeys: (columnKey: SessionCSVColumnKey) => string[],
) => [
  columnKeys.reduce(
    (acc, key) => ({
      ...acc,
      [getUserFriendlyKey(key, getInternalColumnKeys)]: '',
    }),
    {},
  ),
];

export const generateSessionsCsv = (
  sessions: Session[],
  allowGroupRegistration: boolean,
): string => {
  const columnKeys = getColumnKeys(allowGroupRegistration);
  const getInternalColumnKeys = createGetInternalColumnKeys(allowGroupRegistration);

  const userFriendlyKeysMap = columnKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: getUserFriendlyKey(key, getInternalColumnKeys),
    }),
    {},
  ) as Record<SessionCSVColumnKey, string>;

  const sortedSessionsByColumnIndex = sessions.map(session =>
    columnKeys.reduce(
      (acc, key) => ({
        ...acc,
        [userFriendlyKeysMap[key]]: getUserFriendlyValue(key, session[key], getInternalColumnKeys),
      }),
      {},
    ),
  );

  return unparse(
    sortedSessionsByColumnIndex.length
      ? sortedSessionsByColumnIndex
      : getColumnHeadersForCsv(columnKeys, getInternalColumnKeys),
  );
};
