import React from 'react';

import { ElementProps } from '../../types';

const Indentation: React.FC<ElementProps> = ({ attributes, children }) => {
  const styles = { margin: '0 0 0 4rem', padding: '0', border: 'none' };

  return (
    <blockquote
      {...attributes}
      style={styles}
      ref={element => {
        // there is some global style which overrides blockquote styles. I didn't find it so needed to use important.
        // here is the reason for this hack https://github.com/facebook/react/issues/1881#issuecomment-262257503
        if (element) {
          Object.entries(styles).forEach(([key, value]) => {
            element.style.setProperty(key, value, 'important');
          });
        }
      }}
    >
      {children}
    </blockquote>
  );
};

export default Indentation;
