import { Radio } from 'antd';
import cn from 'classnames';
import { isUndefined } from 'lodash';
import React from 'react';

import Currency from '@/modules/shared/components/Currency';

import styles from './Option.less';

type Props = {
  optionCode: string;
  optionName: string;
  fee?: number;
  verticalAlignment: boolean;
};

const Option: React.FC<Props> = ({ optionCode, optionName, fee, verticalAlignment }) => (
  <Radio value={optionCode} className={cn({ [styles.verticalAlignment]: verticalAlignment })}>
    <div className={styles.optionContent}>
      <div className={styles.optionName}>{optionName}</div>
      {!isUndefined(fee) && (
        <div className={styles.optionFee}>
          <Currency value={fee} />
          {' USD'}
        </div>
      )}
    </div>
  </Radio>
);

export default Option;
