import { last, orderBy, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState, StateType } from 'typesafe-actions';

import { Payment } from '@/modules/data/dataTypes/paymentList';
import { createDataSel, createPageDataSelector } from '@/modules/data/duck/selectors';
import { jobStatus } from '@/modules/entities/Jobs/constants';
import { reservationPaymentCategoriesSel } from '@/modules/entities/Reservations/duck/selectors';
import { typeSel } from '@/modules/location/duck/selectors';
import { routeSel } from '@/modules/routing/duck/selectors';
import {
  currentManagerHiringDepartmentListSel,
  isSystemAdministratorSel,
} from '@/modules/user/duck/selectors';
import { dateFromString } from '@/modules/utils/dateFormats';

import {
  moduleName,
  nonFreeAgentJobsAmount,
  PAYMENT_SCHEDULE_STATUS_OVERDUE,
  PAYMENT_SCHEDULE_STATUS_UNPAID,
  pageDataParams,
  STARTED_PAYMENT_STATUS,
} from '../constants';

import moduleReducers from './reducers';

const moduleSel = (state: RootState): StateType<typeof moduleReducers> => state[moduleName];

export const pageDataSel = createPageDataSelector(pageDataParams);

export const sendGroupReservationRCInviteInProgressSel = createSelector(
  moduleSel,
  ({ sendGroupReservationRCInviteInProgress }) => sendGroupReservationRCInviteInProgress,
);

export const cancelGroupReservationContactInvitationInProgressSel = createSelector(
  moduleSel,
  ({ cancelGroupReservationContactInvitationInProgress }) =>
    cancelGroupReservationContactInvitationInProgress,
);

export const validatingGroupReservationInviteInProgressSel = createSelector(
  moduleSel,
  ({ validatingGroupReservationInviteInProgress }) => validatingGroupReservationInviteInProgress,
);

export const validatingGroupReservationInviteStatusSel = createSelector(
  moduleSel,
  ({ validatingGroupReservationInviteStatus }) => validatingGroupReservationInviteStatus,
);

export const updatePaymentScheduleDueDateInProgressSel = createSelector(
  moduleSel,
  ({ updatePaymentScheduleDueDateInProgress }) => updatePaymentScheduleDueDateInProgress,
);

const paymentsSel = (_state: RootState, { payments = [] }: { payments: Payment[] }) =>
  payments.filter(({ status }) => status !== STARTED_PAYMENT_STATUS);

export const sortedPaymentsSel = createSelector(paymentsSel, payments =>
  orderBy(payments, ({ creationDate }) => creationDate, ['desc']),
);

export const pastDueFeesSel = createSelector(
  [reservationPaymentCategoriesSel],
  (paymentCategories = []) =>
    paymentCategories.reduce(
      (acc, { status, dueAmount }) =>
        status === PAYMENT_SCHEDULE_STATUS_OVERDUE ? acc + dueAmount : acc,
      0,
    ),
);

export const nextFutureDueFeeSel = createSelector(
  [reservationPaymentCategoriesSel],
  paymentCategories => {
    let dueAmount = 0;
    const unpaidCategories = paymentCategories.filter(
      ({ status }) => status === PAYMENT_SCHEDULE_STATUS_UNPAID,
    );

    if (unpaidCategories.length === 0) return dueAmount;

    // Considering that the payment categories are sorted, the first element is the closest next payment by date
    const nextPaymentDate = dateFromString(unpaidCategories[0].dueDate);

    // There are cases when two or more payments could happen on the same date. Those could happen when
    // we have an static date category and dynamic date category. We must add them
    dueAmount = unpaidCategories.reduce((amount, category, index) => {
      if (index === 0) return category.dueAmount;

      const categoryDate = dateFromString(category.dueDate);

      if (categoryDate.isSame(nextPaymentDate, 'day')) {
        return amount + category.dueAmount;
      }

      return amount;
    }, 0);

    return dueAmount;
  },
);

export const currentInstallmentSel = createSelector(
  reservationPaymentCategoriesSel,
  paymentCategories => paymentCategories.find(pc => pc.dueAmount),
);

export const attendeeTypeNameSel = createSelector(
  [createDataSel('attendeeDetails'), createDataSel('attendeeTypeList')],
  ({ typeCode }, attendeeTypes) => attendeeTypes.find(at => at.typeCode === typeCode)?.typeName,
);

export const currentTabKeySel = createSelector(routeSel, route => last(route.split('/')));
export const futureTabKeySel = createSelector(typeSel, route => last(route.split('/')));

export const isLoadingTabSel = createSelector(
  [currentTabKeySel, futureTabKeySel],
  (currentTab, futureTab) => currentTab !== futureTab,
);

export const assignHiringManagerInProgressSel = createSelector(
  moduleSel,
  ({ assignHiringManagerInProgress }) => assignHiringManagerInProgress,
);

export const isLoadingApprovalSel = createSelector(
  moduleSel,
  ({ isLoadingApproval }) => isLoadingApproval,
);

export const transferRegistrationInProgressSel = createSelector(
  moduleSel,
  ({ transferRegistrationInProgress }) => transferRegistrationInProgress,
);

// Is specific attendee type a staff attendee type
export const attendeeTypeIsStaffSel = createSelector(
  [createDataSel('attendeeDetails'), createDataSel('attendeeTypeList')],
  (attendeeDetails, attendeeTypes) =>
    !!attendeeTypes &&
    !!attendeeTypes.find(({ typeCode }) => typeCode === attendeeDetails?.typeCode)?.isStaff,
);

export const sessionSel = createSelector(
  [createDataSel('attendeeDetails'), createDataSel('sessionList')],
  ({ sessionCode }, sessions = []) => sessions.find(s => s.sessionCode === sessionCode),
);

export const userFirstPriorityJob = createSelector(
  [
    createDataSel('formRecordJobs'),
    currentManagerHiringDepartmentListSel,
    createDataSel('form'),
    isSystemAdministratorSel,
  ],
  (formJobs = [], currentManagerHiringDepartment, { isCreator, isCollaborator }, isSystemAdmin) => {
    const orderedFormJobs = sortBy(formJobs, formJob => Number(formJob.priority));

    // Find the first non canceled job in priority order.
    const job = orderedFormJobs.find(
      ({ status }) =>
        ![
          jobStatus.job_collaborator_declined.code,
          jobStatus.job_attendee_declined.code,
          jobStatus.job_offer_timed_out.code,
        ].includes(status),
    );

    // If there is no job, return null
    if (!job) return null;

    // If you are the form creator, you are a hiring manager of every department.
    if (isCreator || isCollaborator || isSystemAdmin) return job;

    // If you are a hiring manager, return the job only if its on your department.
    return currentManagerHiringDepartment.some(
      ({ jobDepartmentCode }) => job?.jobDepartmentCode === jobDepartmentCode,
    )
      ? job
      : null;
  },
);

export const isFreeAgentSel = createSelector(
  [createDataSel('formRecordJobs')],
  (attendeeJobs = []) =>
    attendeeJobs
      .filter(({ priority }) => priority < nonFreeAgentJobsAmount)
      .every(({ status }) =>
        [
          jobStatus.job_collaborator_declined.code,
          jobStatus.job_attendee_declined.code,
          jobStatus.job_offer_timed_out.code,
        ].includes(status),
      ),
);

export const managerAvailableJobsToOfferSel = createSelector(
  [
    createDataSel('form'),
    currentManagerHiringDepartmentListSel,
    createDataSel('jobList'),
    isSystemAdministratorSel,
  ],
  ({ isCreator, isCollaborator }, departments, jobs = [], isSystemAdmin) => {
    if (isCreator || isCollaborator || isSystemAdmin) return jobs;

    return jobs.filter(t => departments.some(d => d.jobDepartmentCode === t.jobDepartmentCode));
  },
);

export const attendeeOfferedJobSel = createSelector([createDataSel('formRecordJobs')], jobs =>
  jobs.find(job => job.status === jobStatus.job_offered.code),
);

export const attendeeAcceptedJobSel = createSelector([createDataSel('formRecordJobs')], jobs =>
  jobs.some(job => job.status === jobStatus.job_attendee_accepted.code),
);
