import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import JobPrioritySelector from '@/modules/entities/Jobs/components/JobPrioritySelector';
import { jobStatus, JobStatusCode } from '@/modules/entities/Jobs/constants';
import { updateFormJob } from '@/modules/entities/Jobs/duck/actions';
import { updateFormJobInProgressSel } from '@/modules/entities/Jobs/duck/selectors';
import { UpdateFormJobPayload } from '@/modules/entities/Jobs/types';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { StatusMessage } from './UpdatePrioritiesModal.styled';
import { UpdatePrioritiesModalParams } from './types';

const DISABLED_STATUS = new Set([jobStatus.job_offered.code, jobStatus.job_attendee_accepted.code]);

interface Props {
  modalParams: UpdatePrioritiesModalParams;
}

const UpdatePrioritiesModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const jobList = useSelector(createDataSel('jobList'));
  const selectedJobs = useSelector(createDataSel('formRecordJobs'));
  const isLoading = useSelector(updateFormJobInProgressSel);
  const canEditJobPositonsUnrestricted = useSelector(
    createAbilitiesSelector('attendeeDetails', 'editJobsUnrestricted', null),
  );

  const { formCode, formRecordGUID } = modalParams;

  const sortedSelectedJobs = useMemo(
    () => selectedJobs.slice().sort((a, b) => (a.priority || 0) - (b.priority || 0)),
    [selectedJobs],
  );
  const [newPositions, setNewPositions] = useState<(string | null)[]>([
    sortedSelectedJobs[0]?.jobCode || null,
    sortedSelectedJobs[1]?.jobCode || null,
    sortedSelectedJobs[2]?.jobCode || null,
  ]);

  const hasAcceptedJob = sortedSelectedJobs.some(
    ({ status }) => status === JobStatusCode.JobAttendeeAccepted,
  );
  const hasJobOffered = sortedSelectedJobs.some(
    ({ status }) => status === JobStatusCode.JobOffered,
  );
  const havePrioritiesChanged = newPositions.some(
    (position, index: number) => position && position !== sortedSelectedJobs[index]?.jobCode,
  );

  function handleSaveEditJobs() {
    const jobsToUpdate = newPositions
      .map((jobCode, index): UpdateFormJobPayload | null => {
        if (!jobCode) return null;

        return {
          formRecordGUID,
          jobCode,
          status:
            sortedSelectedJobs[index]?.jobCode === jobCode
              ? sortedSelectedJobs[index].status
              : jobStatus.job_pending.code,
          priority: index,
        };
      })
      .filter((j): j is UpdateFormJobPayload => !!j);

    dispatch(
      updateFormJob.request({
        formCode,
        jobsToUpdate: jobsToUpdate,
        successMessage: 'Job priorities updated',
        finalActions: [closeModal()],
      }),
    );
  }

  function handleClose() {
    dispatch(closeModal());
  }

  const disabledPriorities = useMemo(
    () =>
      canEditJobPositonsUnrestricted
        ? undefined
        : new Set(
            selectedJobs
              .filter(({ status }) => DISABLED_STATUS.has(status))
              .map(({ priority }) => priority),
          ),
    [selectedJobs, canEditJobPositonsUnrestricted],
  );

  return (
    <ModalComponent
      title="Update Attendee Priorities"
      description=""
      buttons={[
        {
          title: 'Close',
          onClick: handleClose,
        },
        {
          disabled: hasAcceptedJob || hasJobOffered || !havePrioritiesChanged,
          title: 'Save',
          type: 'primary',
          onClick: handleSaveEditJobs,
        },
      ]}
      inProgress={isLoading}
    >
      <JobPrioritySelector
        jobsList={jobList}
        value={newPositions}
        disabledPriorities={disabledPriorities}
        onChange={setNewPositions}
      />
      {hasAcceptedJob && (
        <StatusMessage>
          {`A job has already been accepted. Please revoke accepted job first before changing job
          priorities.`}
        </StatusMessage>
      )}
      {hasJobOffered && (
        <StatusMessage>
          {`A job has already been offered. Please revoke offered job first before changing job
          priorities.`}
        </StatusMessage>
      )}
    </ModalComponent>
  );
};

export default UpdatePrioritiesModal;
