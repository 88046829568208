import { Input, Select, Switch } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { refreshData, scheduleRefresh } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import { cancelIndividualReservation } from '@/modules/entities/Reservations/duck/actions';
import { cancelIndividualReservationInProgressSel } from '@/modules/entities/Reservations/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import Label from '@/modules/shared/components/Label';

import { ModalParams } from './types';

import { formStatus } from 'SHARED/constants';

type Props = {
  modalParams: ModalParams;
};

const CancelIndividualReservationModal: React.FC<Props> = ({ modalParams }) => {
  const { cancellationReasonStatus, ownReservation } = modalParams;
  const dispatch = useDispatch();
  const [cancellationStatusCode, setCancellationStatusCode] = useState(
    cancellationReasonStatus ?? formStatus.attendee_cancelled.code,
  );
  const [cancellationReason, setCancellationReason] = useState('');
  const [sendNotification, setSendNotification] = useState(true);
  const cancelIndividualReservationInProgress = useSelector(
    cancelIndividualReservationInProgressSel,
  );
  const { personGUID, formRecordGUID } = useSelector(createDataSel('attendeeDetails'));
  const { formName } = useSelector(createDataSel('form'));

  const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setCancellationReason(e.target.value);

  const handleNotificationChange = () => {
    setSendNotification(!sendNotification);
  };

  const handleConfirmCancellation = () => {
    dispatch(
      cancelIndividualReservation.request({
        personGUID,
        formRecordGUID,
        cancellationReason,
        cancellationStatusCode,
        sendNotification,
        finalActions: [
          closeModal(),
          // sessionList has "cancelledRosters" which will become outdated after this operation
          scheduleRefresh({ dataType: 'sessionList' }),
          // reservationList  will become outdated after this operation
          scheduleRefresh({ dataType: 'reservationListReport' }),
          refreshData({ dataType: 'attendeeDetails' }),
        ],
      }),
    );
  };

  return (
    <ModalComponent
      title="Cancel Individual Reservation"
      buttons={[
        {
          title: 'No, go back',
          onClick: () => {
            dispatch(closeModal());
          },
          type: 'default',
        },
        {
          title: 'Yes, Cancel Now',
          onClick: handleConfirmCancellation,
          type: 'primary',
        },
      ]}
      inProgress={cancelIndividualReservationInProgress}
    >
      <div>
        <p>
          <b>
            {ownReservation
              ? `Are you sure you want to cancel your ${formName} application?`
              : `Are you sure you want to cancel this reservation for ${formName}?`}
          </b>
        </p>
        {!ownReservation && (
          <p>
            After referring to the {formName} refund policy, the Registrar will process any
            applicable refund back to the credit card on which the payment(s) were submitted.
            Notification will go to the email address on file.
          </p>
        )}

        {!ownReservation && (
          <div className="mb-4">
            <Select value={cancellationStatusCode} onChange={setCancellationStatusCode}>
              <Select.Option
                disabled={
                  cancellationReasonStatus
                    ? cancellationReasonStatus !== formStatus.collaborator_cancelled.code
                    : false
                }
                value={formStatus.collaborator_cancelled.code}
              >
                {formStatus.collaborator_cancelled.name}
              </Select.Option>
              <Select.Option
                disabled={
                  cancellationReasonStatus
                    ? cancellationReasonStatus !== formStatus.council_declined.code
                    : false
                }
                value={formStatus.council_declined.code}
              >
                {formStatus.council_declined.name}
              </Select.Option>
              <Select.Option
                disabled={
                  cancellationReasonStatus
                    ? cancellationReasonStatus !== formStatus.attendee_cancelled.code
                    : false
                }
                value={formStatus.attendee_cancelled.code}
              >
                {formStatus.attendee_cancelled.name}
              </Select.Option>
            </Select>
          </div>
        )}
        <Input.TextArea
          id="reason"
          onChange={handleReasonChange}
          autoSize={{ minRows: 2, maxRows: 6 }}
          placeholder="Reason for cancelling"
        />
      </div>
      <Label label="Send cancellation email to reservation owner">
        <Switch checked={sendNotification} onChange={handleNotificationChange} />
      </Label>
    </ModalComponent>
  );
};

export default CancelIndividualReservationModal;
