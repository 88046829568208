import { combineEpics, Epic } from 'redux-observable';
import { EMPTY, fromEvent, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { closeModal } from '@/modules/modals/duck/actions';
import { toHTML } from '@/modules/richTextEditor/utils';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';
import { utcDateToString } from '@/modules/utils/dateFormats';

import { DISMISSED_MESSAGE_KEY } from '../constants';

import {
  clearBroadcast,
  dismissMessage,
  saveBroadcastMessage,
  startBroadcastListening,
  updateBroadcast,
} from './actions';
import { broadcastMessageSel } from './selectors';
import services from './services';

const updateBroadcast$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(updateBroadcast.request)),
    switchMap(({ payload: { startDate, endDate, content } }) =>
      services
        .updateBroadcast$({
          broadcastStartDate: utcDateToString(startDate),
          broadcastEndDate: utcDateToString(endDate),
          broadcastContent: toHTML(content),
        })
        .pipe(
          switchMap(() => {
            toastService.success('Broadcast message was successfully created');
            return of(closeModal(), updateBroadcast.success());
          }),
          catchError((err: ApiError) => {
            toastService.error(err.message);
            return of(updateBroadcast.failure(err));
          }),
        ),
    ),
  );

const clearBroadcast$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(clearBroadcast)),
    switchMap(() =>
      services.updateBroadcast$({ broadcastContent: '' }).pipe(
        switchMap(() => {
          toastService.success('Broadcast message was successfully cleared');
          const broadcastMessage = broadcastMessageSel(state$.value);
          return of(dismissMessage(broadcastMessage));
        }),
      ),
    ),
  );

const dismissMessage$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(dismissMessage)),
    switchMap(({ payload }) => {
      if (payload) {
        localStorage.setItem(DISMISSED_MESSAGE_KEY, payload);
      }
      return EMPTY;
    }),
  );

const startBroadcastListening$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(startBroadcastListening)),
    switchMap(() => {
      const env = process.env.REACT_APP_ENV as 'dv' | 'qa' | 'pp' | '' | undefined;

      if (env === 'pp') return EMPTY;

      const evtSource = new EventSource('/vim2/ServerSentEvents/EventForms.aspx');

      return fromEvent<MessageEvent>(evtSource, 'message').pipe(
        filter(event => {
          const currentMessage = broadcastMessageSel(state$.value);
          const dismissedMessage = localStorage.getItem(DISMISSED_MESSAGE_KEY);
          return ![currentMessage, dismissedMessage].includes(event.data);
        }),
        map(event => saveBroadcastMessage(event.data)),
        catchError((_err, caught) => caught),
      );
    }),
  );

export default combineEpics(
  updateBroadcast$,
  clearBroadcast$,
  startBroadcastListening$,
  dismissMessage$,
);
