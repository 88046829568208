import { Button, Collapse } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Panel = styled(Collapse.Panel)<{
  $isDesktop: boolean;
}>`
  &&& .ant-collapse-header {
    color: ${colors.scoutingBlue};
    background-color: white;
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    align-items: center;

    > i {
      right: 0 !important;
    }

    > button {
      margin-left: 5px;
    }

    ${({ $isDesktop: isDesktop }) => (isDesktop ? 'cursor: unset !important;' : '')}
  }

  .ant-collapse-content-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  :first-child {
    border-top: 0 !important;
  }
  :last-child {
    border-bottom: 0 !important;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

export const HeaderButton = styled(Button)`
  color: ${colors.scoutingBlue};
`;
