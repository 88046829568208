import { FetchDataParams } from './types';

export const getKey = (payload: { dataType: string; dataId?: string }): string => {
  const { dataType, dataId } = payload;
  if (dataId) return `${dataType}/${dataId}`;
  return dataType;
};

export const createPageDataParams = <T extends Pick<FetchDataParams, 'dataType' | 'dataId'>[]>(
  ...params: T
): T => params;
