import { Program } from '@/modules/data/dataTypes/programList';
import apiService from '@/modules/utils/apiService';

const updateProgram$ = ({
  formCode,
  programCode,
  programName,
  programPrefix,
  accountCode,
}: Pick<Program, 'programName' | 'programPrefix' | 'accountCode'> &
  Pick<Partial<Program>, 'programCode'> & {
    formCode: string;
  }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateProgram',
    {
      formCode,
      programCode,
      programName,
      accountCode,
      programPrefix,
    },
  );

const deleteProgram$ = (formCode: string, programCode: string) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteProgram',
    {
      formCode,
      programCode,
    },
  );

export default {
  updateProgram$,
  deleteProgram$,
};
