import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'redux-first-router-link';

import { navigateToCreateEvent } from '@/pages/createEvent/duck/actions';

import styles from './NoEvents.less';

type Props = {
  isAdmin: boolean;
};

const NoEvents: React.FC<Props> = ({ isAdmin }) => (
  <div className={styles.container}>
    <FormattedMessage id="events.EventsPage.noEvents" />
    {isAdmin && (
      <React.Fragment>
        <br />
        <br />
        <Link to={navigateToCreateEvent({ isInitialLoad: true })} id="createEventButton">
          <Button type="primary">
            <FormattedMessage id="events.EventsPage.createNewEvent" />
          </Button>
        </Link>
      </React.Fragment>
    )}
  </div>
);

export default NoEvents;
