import React from 'react';
import { useSelector } from 'react-redux';

import { routeSel } from '../../duck/selectors';
import routes from '../../routes';
import Layout from '../Layout';

const RouteSwitcher: React.FC = () => {
  const route = useSelector(routeSel);
  const Component = routes[route].component;

  return routes[route].isStandAlone ? (
    <Component />
  ) : (
    <Layout>
      <Component />
    </Layout>
  );
};

export default RouteSwitcher;
