import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Container = styled.div`
  position: relative;
`;

export const Loader = styled.div<{ greyOut?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ greyOut }) => (greyOut ? colors.greyUnderline : 'white')};
  opacity: ${({ greyOut }) => (greyOut ? 1 : 0.5)};
  z-index: 999;
`;

export const Spinner = styled.div<{ containerHeight: number }>`
  position: absolute;
  top: ${({ containerHeight }) => (containerHeight <= 400 ? 0 : '10%')};
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: ${({ containerHeight }) => (containerHeight <= 400 ? 'center' : 'unset')};
  justify-content: center;
  z-index: 999;
`;
