import styled from 'styled-components';

import colors from '@/modules/styles/colors';

import CardWithHeader from 'SHARED/components/CardWithHeader';

export const StyledCard = styled(CardWithHeader)`
  & .ant-card-body {
    padding: 0;
  }
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  color: ${colors.primaryColor};
  height: 50px;
  padding: 1.5em 0;
`;
