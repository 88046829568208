import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { moduleName } from '../constants';

const moduleSel = (state: RootState) => state[moduleName];

const prevRouteSel = createSelector(moduleSel, ({ prev }) => prev);
export const prevTypeSel = createSelector(prevRouteSel, previousRoute => previousRoute?.type);

export const payloadSel = createSelector(moduleSel, ({ payload }) => payload);

export const urlFormCodeSel = createSelector(payloadSel, ({ formCode }) => formCode);

export const pathnameSel = createSelector(moduleSel, ({ pathname }) => pathname);

export const typeSel = createSelector(moduleSel, ({ type }) => type);

export const currentRouteSel = createSelector([typeSel, payloadSel], (type, payload) => ({
  type,
  payload,
}));

const routeKindSel = createSelector(moduleSel, ({ kind }) => kind);

// if the user refresh the browser or just typed the route directly
// kind will always be "load"
export const isPageLoadSel = createSelector(routeKindSel, kind => kind === 'load');

export const urlFormRecordGUIDSel = createSelector(payloadSel, payload => payload.formRecordGUID);
export const urlReservationCodeSel = createSelector(payloadSel, payload => payload.reservationCode);
export const urlGroupReservationGUIDSel = createSelector(
  payloadSel,
  payload => payload.groupReservationGUID,
);
export const contextTokenSel = createSelector(payloadSel, ({ contextToken }) => contextToken);
