import React from 'react';
import { GrDocumentCsv } from 'react-icons/gr';
import { RiCheckboxCircleFill, RiErrorWarningFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import IconButton from '@/modules/shared/components/StylableIconButton';
import UploadFile from '@/modules/shared/components/UploadFile';
import colors from '@/modules/styles/colors';

import { updateSessionsInBulk } from '../../duck/actions';
import { updateSessionInProgressSel } from '../../duck/selectors';

import {
  BorderedContainer,
  ErrorsTitle,
  ErrorsList,
  FilenameText,
  IconRow,
  GrayIconWrapper,
  ProgressLabel,
  ErrorIconWrapper,
  SuccessIcon,
  ProgressBar,
  FileStatusRow,
} from './ImportCsvFileModal.styled';
import { useSessionCsvParser } from './hooks';

const ImportCsvFileModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const updateSessionInProgress = useSelector(updateSessionInProgressSel);

  const {
    filename,
    processFile,
    processingStatus,
    errors,
    parsedSessions,
    progressPercent,
    reset,
  } = useSessionCsvParser();

  const handleUpload = () => {
    dispatch(
      updateSessionsInBulk.request({ sessions: parsedSessions, finalActions: [closeModal()] }),
    );
  };

  const getResetButtonLabel = () => {
    switch (processingStatus) {
      case 'success':
        return 'REMOVE';
      case 'error':
        return 'RETRY';
      default:
        return 'CANCEL';
    }
  };

  const renderStatusIcon = () => {
    switch (processingStatus) {
      case 'success':
        return (
          <SuccessIcon>
            <RiCheckboxCircleFill size={20} />
          </SuccessIcon>
        );
      case 'error':
        return (
          <ErrorIconWrapper>
            <RiErrorWarningFill size={20} />
          </ErrorIconWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <ModalComponent
      title="Import CSV File"
      buttons={[
        {
          title: 'UPLOAD FILES',
          type: 'primary',
          onClick: handleUpload,
          loading: updateSessionInProgress,
          disabled: processingStatus !== 'success',
        },
      ]}
    >
      {filename ? (
        <BorderedContainer>
          <IconRow>
            <GrayIconWrapper>
              <GrDocumentCsv size={36} />
            </GrayIconWrapper>
            <div>
              <FileStatusRow>
                <FilenameText>{filename}</FilenameText>
                {renderStatusIcon()}
              </FileStatusRow>
              <ProgressLabel>{processingStatus} Processing CSV File.</ProgressLabel>
              {processingStatus !== 'error' && (
                <ProgressBar
                  strokeColor={
                    processingStatus === 'success' ? colors.greenStatus : colors.yellowStatus
                  }
                  percent={progressPercent}
                  showInfo={false}
                  strokeWidth={4}
                />
              )}
            </div>
          </IconRow>
          {processingStatus === 'error' && (
            <ErrorsList>
              <ErrorsTitle>Your CSV file had {errors.length} errors:</ErrorsTitle>
              {errors.map((errorMessage, index) => (
                <p key={`error-${index}`}>{errorMessage}</p>
              ))}
            </ErrorsList>
          )}
          <IconButton label={getResetButtonLabel()} color={colors.lightBlue} onClick={reset} />
        </BorderedContainer>
      ) : (
        <UploadFile onChange={processFile} />
      )}
    </ModalComponent>
  );
};

export default ImportCsvFileModal;
