import { Row } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { updatePageNumber } from '@/modules/data/duck/actions';
import { createDataSel, createPaginationSel } from '@/modules/data/duck/selectors';
import { useAbilities } from '@/modules/user/duck/abilities';
import { isEventAdministratorSel, isLoggedInSel } from '@/modules/user/duck/selectors';

import { navigateToCreateEvent } from '@/pages/createEvent/duck/actions';

import styles from './EventsList.less';
import { CreateEventButton, EventsCard, LinkToCreateEvent, Pagination } from './EventsList.styled';
import EventCard from './components/EventCard';
import EventsCount from './components/EventsCount';
import EventsFilter from './components/EventsFilter';
import NoEvents from './components/NoEvents';
import { tabs } from './constants';
import { changeTab } from './duck/actions';
import { tabKeySel, isAnyCollaboratorSel } from './duck/selectors';

const EventsList: React.FC = () => {
  const dispatch = useDispatch();
  const abilities = useAbilities();

  const eventsList = useSelector(createDataSel('personFormList')) || [];
  const pagination = useSelector(createPaginationSel('personFormList'));
  const isAdmin = useSelector(isEventAdministratorSel);
  const isCollaborator = useSelector(isAnyCollaboratorSel);
  const tabKey = useSelector(tabKeySel);
  const isLoggedIn = useSelector(isLoggedInSel);

  const { pageSize = 0, pageNumber = 0, recordCount = 0 } = pagination || {};

  const canCreateEvents = abilities.can({ action: 'create', target: 'events' });

  const onChangeTab = (nextTabKey: string) => dispatch(changeTab(nextTabKey));

  const onChangePageNumber = (nextPage: number) =>
    dispatch(updatePageNumber({ dataType: 'personFormList', pageNumber: nextPage }));

  function getTabs() {
    if (isLoggedIn) {
      return isAdmin || isCollaborator
        ? tabs
        : tabs.filter(({ forCollaboratorOnly }) => !forCollaboratorOnly);
    }

    return tabs.slice(0, 1);
  }

  const currentTab = tabs.find(({ key }) => key === tabKey);

  const handleShowTotal = (total: number, range: [number, number]) =>
    `${range[0]}-${range[1]} of ${total} items`;

  return (
    <React.Fragment>
      <EventsFilter events={eventsList} key={tabKey} />
      <div className={styles.eventsContainer}>
        <EventsCard
          bodyStyle={{ paddingTop: '0' }}
          tabList={getTabs()}
          activeTabKey={tabKey}
          onTabChange={onChangeTab}
          id="cardsContainer"
          tabBarExtraContent={
            canCreateEvents && (
              <LinkToCreateEvent to={navigateToCreateEvent({ isInitialLoad: true })}>
                <CreateEventButton type="primary" color="info">
                  Add
                </CreateEventButton>
              </LinkToCreateEvent>
            )
          }
        >
          {!eventsList.length && <NoEvents isAdmin={isAdmin && tabKey === 'future'} />}

          <Pagination
            current={pageNumber}
            pageSize={pageSize}
            onChange={onChangePageNumber}
            showTotal={handleShowTotal}
            total={recordCount}
            className={styles.pagination}
            showSizeChanger={false}
          />
          <EventsCount count={recordCount} id="eventsCounter" />
          <Row align="middle" justify="center">
            {eventsList.map(event => (
              <EventCard
                key={event.formCode}
                isLoggedIn={isLoggedIn}
                allowRegister={currentTab?.allowRegister || false}
                eventItem={event}
              />
            ))}
          </Row>
        </EventsCard>
      </div>
    </React.Fragment>
  );
};

export default EventsList;
