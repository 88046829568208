import { createSelector } from 'reselect';

import { appInitInProgressSel } from '@/modules/core/duck/selectors';
import { createIsLoadingSel } from '@/modules/data/duck/selectors';
import { loadingSel as routeLoadingSel } from '@/modules/routing/duck/selectors';
import { logoutInProgressSel, loginInProgressSel } from '@/modules/user/duck/selectors';

import { isLoadingFetchingFormSel } from '@/pages/createReservation/duck/selectors';

export const isLoadingSel = createSelector(
  [
    isLoadingFetchingFormSel,
    appInitInProgressSel,
    routeLoadingSel,
    loginInProgressSel,
    logoutInProgressSel,
    createIsLoadingSel('personFormList'),
  ],
  (...loaders) => loaders.some(loading => loading),
);
