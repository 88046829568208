import { Tooltip } from 'antd';
import React from 'react';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { Roster, AttendeeType } from '@/modules/data/dataTypes/rosterList';

import { Wrapper, Icon } from './RosterAttendeeTypeAmount.styled';

type Props = {
  roster: Roster;
  attendeeType: AttendeeType;
  reservationDetails?: GroupReservation;
};

const RosterAttendeeTypeAmount: React.FC<Props> = ({
  roster,
  attendeeType,
  reservationDetails,
}) => {
  const type = roster.attendeeTypes.find(item => item.typeCode === attendeeType.typeCode);

  if (!type) return null;

  const { minCapacity, maxCapacity, numberOfAttendees, typeName } = type;

  const netMaxCapacity = reservationDetails
    ? Math.min(reservationDetails.initialPeopleCount, maxCapacity)
    : maxCapacity;

  const showWarning = numberOfAttendees < minCapacity;

  return (
    <Wrapper>
      {`${numberOfAttendees}/${netMaxCapacity}`}
      {showWarning && (
        <Tooltip placement="right" title={`Not enough ${typeName}(s)`}>
          <Icon />
        </Tooltip>
      )}
    </Wrapper>
  );
};

export default RosterAttendeeTypeAmount;
