import { Typography } from 'antd';
import * as React from 'react';

import { Payment } from '@/modules/data/dataTypes/paymentList';

import { FlexRow, FlexItem, BlueTitle, DetailsRow, Comments } from './ExpandedHistoryRow.styled';

const ExpandedHistoryRow: React.FC<Payment> = ({ orderId, comments }) => (
  <FlexRow>
    <FlexItem>
      <BlueTitle level={4}>
        <b>Details</b>
      </BlueTitle>
      <DetailsRow>
        <Typography.Title level={5}>Order ID</Typography.Title>
        <Typography.Title level={5}>{orderId}</Typography.Title>
      </DetailsRow>
    </FlexItem>
    <FlexItem>
      <BlueTitle level={4}>
        <b>Comments</b>
      </BlueTitle>
      <Comments>{comments ? comments : 'No Comments'}</Comments>
    </FlexItem>
  </FlexRow>
);
export default ExpandedHistoryRow;
