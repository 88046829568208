import { Row as BsaRow } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const RowTitle = styled.span`
  color: ${colors.scoutingBlue};
  font-weight: bold;
  margin-right: 5px;
`;

export const Row = styled(BsaRow)`
  display: flex;
  align-items: center;
`;
