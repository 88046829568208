import React, { Fragment, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTotalBalanceDue } from '@/modules/entities/Payments/utils';
import { ModalParams as CancelIndividualReservationModalParams } from '@/modules/entities/Reservations/components/CancelIndividualReservationModal/types';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { CANCEL_INDIVIDUAL_RESERVATION_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import { PaymentMethod } from '@/modules/payments/constants';
import useGetPaymentMethods from '@/modules/payments/hooks/useGetPaymentMethods';
import { formStatus } from '@/modules/shared/constants';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import CancelButton from './components/CancelButton';
import CancelledReservationBanner from './components/CancelledReservationBanner';
import CouncilApproveBanner from './components/CouncilApproveBanner';
import CouncilEditBanner from './components/CouncilEditBanner';
import ReservationDashboard from './components/ReservationDashboard';
import StaffOfferBanner from './components/StaffOfferBanner';
import { TabKey, availablePaymentMethods, tabs } from './constants';
import { currentTabKeySel, pageDataSel } from './duck/selectors';
import AttendeeDetailsTab from './tabs/AttendeeDetails';
import DisclaimersTab from './tabs/Disclaimers';
import LogsTab from './tabs/Logs';
import OverviewTab from './tabs/OverviewIndividual';
import PaymentsTab from './tabs/Payments';

const ReservationPage: React.FC = () => {
  const dispatch = useDispatch();

  const {
    data: { form, attendeeDetails, paymentSummary, paymentCategories },
  } = useSelector(pageDataSel);

  const currentTabKey = useSelector(currentTabKeySel);

  const totalAmountDue = getTotalBalanceDue(paymentSummary);

  const canCancelReservation = useSelector(
    createAbilitiesSelector('reservation', 'cancel', { attendeeDetails }),
  );
  const canAccessAttendeeDetails = useSelector(
    createAbilitiesSelector('attendeeDetails', PermissionAction.Read, { attendeeDetails }),
  );
  const canViewPaymentsTab = useSelector(
    createAbilitiesSelector('payment', PermissionAction.Read, { attendeeDetails }),
  );
  const isAllowedToPostComments = useSelector(
    createAbilitiesSelector('comment', PermissionAction.Create),
  );
  const isAllowedToCouncilApprove = useSelector(
    createAbilitiesSelector('attendeeDetails', 'councilApprove', {
      attendeeDetails,
    }),
  );
  const canMakePayments = useSelector(
    createAbilitiesSelector('payment', PermissionAction.Create, { attendeeDetails }),
  );

  const isAllowedToViewLogs = useSelector(createAbilitiesSelector('log', PermissionAction.Read));

  const isCancelled = useMemo(() => {
    const { attendee_cancelled, collaborator_cancelled } = formStatus;

    return (
      attendeeDetails.isCancelled ||
      [attendee_cancelled.code, collaborator_cancelled.code].includes(attendeeDetails.status)
    );
  }, [attendeeDetails]);

  const currentInstallment = useMemo(() => {
    const record = paymentSummary.records.find(
      r => r.formRecordGUID === attendeeDetails.formRecordGUID,
    );

    if (record?.paymentCategoryCode) {
      return paymentCategories.find(p => p.paymentCategoryCode === record.paymentCategoryCode);
    }
  }, [attendeeDetails, paymentCategories, paymentSummary]);

  const enabledPaymentMethods = useGetPaymentMethods({
    form,
    attendeeDetails,
    availableMethods: [
      PaymentMethod.adjustment,
      ...(totalAmountDue > 0 ? availablePaymentMethods : []),
    ],
    allowedMethods: currentInstallment?.allowedMethods ?? [],
  });

  const filteredTabs = useMemo(
    () =>
      tabs.filter(({ key }) => {
        switch (key) {
          case TabKey.AttendeeDetails:
            return canAccessAttendeeDetails;
          case TabKey.Payment:
            return canViewPaymentsTab || (canMakePayments && enabledPaymentMethods.size);
          case TabKey.Logs:
            return isAllowedToPostComments || isAllowedToViewLogs;
          default:
            return true;
        }
      }),
    [
      canAccessAttendeeDetails,
      canMakePayments,
      canViewPaymentsTab,
      enabledPaymentMethods.size,
      isAllowedToPostComments,
      isAllowedToViewLogs,
    ],
  );

  const isDeclined = [
    formStatus.council_declined.code,
    formStatus.attendee_cancelled.code,
  ].includes(attendeeDetails.status);

  const isCouncilApproved =
    !isCancelled &&
    [
      formStatus.registration_council_approved.code,
      formStatus.registration_completed.code,
    ].includes(attendeeDetails.status);

  const handleCancelReservation = () => {
    const modalParams: CancelIndividualReservationModalParams = {
      ownReservation: true,
    };
    dispatch(openModal(CANCEL_INDIVIDUAL_RESERVATION_MODAL, modalParams));
  };

  const renderTab = useCallback(() => {
    switch (currentTabKey) {
      case TabKey.Overview:
        return <OverviewTab />;
      case TabKey.AttendeeDetails:
        return <AttendeeDetailsTab />;
      case TabKey.Payment:
        return (
          <PaymentsTab
            form={form}
            isGroup={false}
            attendeeDetails={attendeeDetails}
            enabledPaymentMethods={enabledPaymentMethods}
          />
        );
      case TabKey.Disclaimers:
        return <DisclaimersTab attendeeDetails={attendeeDetails} />;
      case TabKey.Logs:
        return <LogsTab isGroup={false} attendeeDetails={attendeeDetails} />;
    }
  }, [attendeeDetails, currentTabKey, enabledPaymentMethods, form]);

  return (
    <Fragment>
      {(isCancelled || isDeclined) && (
        <CancelledReservationBanner cancellationReason={attendeeDetails.cancellationReason} />
      )}
      {isAllowedToCouncilApprove && (
        <CouncilApproveBanner attendeeDetails={attendeeDetails} isCancelled={isCancelled} />
      )}
      {isAllowedToCouncilApprove && isCouncilApproved && (
        <CouncilEditBanner attendeeDetails={attendeeDetails} isCancelled={isCancelled} />
      )}
      {isCouncilApproved && <StaffOfferBanner isCancelled={isCancelled} />}
      <ReservationDashboard tabs={filteredTabs}>{renderTab()}</ReservationDashboard>
      {canCancelReservation && (
        <CancelButton isCancelled={isCancelled} onCancelReservation={handleCancelReservation} />
      )}
    </Fragment>
  );
};

export default ReservationPage;
