import { flow, identity, toString } from 'lodash';
import filterFP from 'lodash/fp/filter';
import flattenFP from 'lodash/fp/flatten';
import mapFP from 'lodash/fp/map';
import Loadable from 'react-loadable';
import { redirect } from 'redux-first-router';
import { StateObservable } from 'redux-observable';
import { of, EMPTY, concat, Observable, defer } from 'rxjs';
import shortid from 'shortid';
import { RootAction, RootState } from 'typesafe-actions';

import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import {
  removeData,
  resetPagination,
  resetFilters,
  updatePageSize,
  setFilter,
} from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel, createErrorSel } from '@/modules/data/duck/selectors';
import { SESSION_SORT_ORDER_FIELD } from '@/modules/entities/Sessions/constants';
import { getSessionSortParams } from '@/modules/entities/Sessions/utils';
import {
  typeSel,
  isPageLoadSel,
  prevTypeSel,
  urlFormCodeSel,
} from '@/modules/location/duck/selectors';
import { PaymentMethod } from '@/modules/payments/constants';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import { isSystemAdministratorSel } from '@/modules/user/duck/selectors';

import {
  ROUTE_CREATE_EVENT_FORM_BUILDER,
  ROUTE_CREATE_EVENT_FORM_PREVIEW,
  ROUTE_CREATE_EVENT_PUBLISH,
  ROUTE_CREATE_EVENT_SETTINGS_PROGRAMS,
  ROUTE_CREATE_EVENT_SETTINGS_ATTENDEE_TYPES,
  ROUTE_CREATE_EVENT_SETTINGS_BASIC_INFO,
  ROUTE_CREATE_EVENT_SETTINGS_COLLABORATORS,
  ROUTE_CREATE_EVENT_SETTINGS_DISCLAIMERS,
  ROUTE_CREATE_EVENT_SETTINGS_ACTIVITIES,
  ROUTE_CREATE_EVENT_SETTINGS_ADDONS,
  ROUTE_CREATE_EVENT_SETTINGS_MULTI_SESSION_CAPACITY_RULES,
  ROUTE_CREATE_EVENT_SETTINGS_JOBS,
  ROUTE_CREATE_EVENT_SETTINGS_PAYMENT_SCHEDULE,
  ROUTE_CREATE_EVENT_SETTINGS_SESSIONS,
  ROUTE_CREATE_EVENT_SETTINGS_LEDGER_ACCOUNTS,
  UNAUTHORIZED,
  steps,
  firstStepRoute,
  pageDataParams,
} from './constants';
import { navigateToCreateEvent } from './duck/actions';
import { stepDataParams as collaboratorsStepDataParams } from './steps/Collaborators/constants';
import { stepDataParams as disclaimersStepDataParams } from './steps/Disclaimers/constants';
import { stepDataParams as formBuilderStepDataParams } from './steps/FormBuilder/constants';
import { setEditingSectionCodes } from './steps/FormBuilder/duck/actions';
import { stepDataParams as formSettingsStepDataParams } from './steps/FormSettings/constants';
import { stepDataParams as paymentScheduleStepDataParams } from './steps/PaymentSchedule/constants';
import { updateInstallment } from './steps/PaymentSchedule/duck/actions';
import { stepDataParams as previewStepDataParams } from './steps/PreviewStep/constants';
import { stepDataParams as programsStepDataParams } from './steps/Programs/constants';
import { updateSessionClosed } from './steps/Sessions/duck/actions';

const stepComponents: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [PropName: string]: any;
} = {
  [ROUTE_CREATE_EVENT_SETTINGS_BASIC_INFO]: () => import('@/pages/createEvent/steps/FormSettings'),
  [ROUTE_CREATE_EVENT_SETTINGS_LEDGER_ACCOUNTS]: () =>
    import('@/pages/createEvent/steps/LedgerAccounts'),
  [ROUTE_CREATE_EVENT_SETTINGS_ATTENDEE_TYPES]: () =>
    import('@/pages/createEvent/steps/AttendeeTypes'),
  [ROUTE_CREATE_EVENT_SETTINGS_PROGRAMS]: () => import('@/pages/createEvent/steps/Programs'),
  [ROUTE_CREATE_EVENT_SETTINGS_COLLABORATORS]: () =>
    import('@/pages/createEvent/steps/Collaborators'),
  [ROUTE_CREATE_EVENT_SETTINGS_PAYMENT_SCHEDULE]: () =>
    import('@/pages/createEvent/steps/PaymentSchedule'),
  [ROUTE_CREATE_EVENT_SETTINGS_DISCLAIMERS]: () => import('@/pages/createEvent/steps/Disclaimers'),
  [ROUTE_CREATE_EVENT_SETTINGS_ACTIVITIES]: () => import('@/pages/createEvent/steps/Activities'),
  [ROUTE_CREATE_EVENT_SETTINGS_ADDONS]: () => import('@/pages/createEvent/steps/Addons'),
  [ROUTE_CREATE_EVENT_SETTINGS_MULTI_SESSION_CAPACITY_RULES]: () =>
    import('@/pages/createEvent/steps/MultiSessionCapacityRules'),
  [ROUTE_CREATE_EVENT_SETTINGS_SESSIONS]: () => import('@/pages/createEvent/steps/Sessions'),
  [ROUTE_CREATE_EVENT_SETTINGS_JOBS]: () => import('@/pages/createEvent/steps/JobsPositions'),
  [ROUTE_CREATE_EVENT_FORM_BUILDER]: () => import('@/pages/createEvent/steps/FormBuilder'),
  [ROUTE_CREATE_EVENT_FORM_PREVIEW]: () => import('@/pages/createEvent/steps/PreviewStep'),
  [ROUTE_CREATE_EVENT_PUBLISH]: () => import('@/pages/createEvent/steps/Publish'),
};

const subStepsOnly = flow(
  mapFP(({ subSteps }) => subSteps),
  filterFP(identity),
  flattenFP,
)(steps);

const stepKeys = flow(filterFP('key'))(steps);

const routeKeys = [...subStepsOnly, ...stepKeys].map(({ key }) => key);

const pageLoadObservable = (
  action$: Observable<RootAction>,
  state$: StateObservable<RootState>,
) => {
  const state = state$.value;
  const formCode = urlFormCodeSel(state);
  const isPageLoad = isPageLoadSel(state);
  const prevRoute = prevTypeSel(state);
  const isSystemAdministrator = isSystemAdministratorSel(state);

  const isInitialLoad = !prevRoute || !routeKeys.includes(prevRoute);

  return concat(
    isPageLoad || isInitialLoad
      ? of(
          removeData({ dataType: 'form' }),
          resetPagination({ dataType: 'sessionList', fetchData: false }),
          resetFilters({ dataType: 'sessionList', fetchData: false }),
        )
      : EMPTY,
    formCode
      ? concat(
          prefetchData$(
            action$,
            ...pageDataParams.map(params => {
              switch (params.dataType) {
                case 'form':
                case 'attendeeTypeList':
                  return { ...params, queryObj: { formCode } };
                case 'eventCategories':
                  return params;
              }
            }),
          ),
          defer(() => {
            const { isCreator, isCollaborator } = createDataSel('form')(state$.value);
            if (!isCreator && !isCollaborator && !isSystemAdministrator) {
              return of(redirect({ type: UNAUTHORIZED }));
            }
            return EMPTY;
          }),
        )
      : EMPTY,
  );
};

const stepGetObservables: {
  [PropName: string]: (
    action$: Observable<RootAction>,
    state$: StateObservable<RootState>,
  ) => Observable<RootAction>;
} = {
  [ROUTE_CREATE_EVENT_SETTINGS_BASIC_INFO]: action$ =>
    prefetchData$(
      action$,
      ...formSettingsStepDataParams.map(params => {
        switch (params.dataType) {
          case 'eventCategories':
          case 'timezones':
            return params;
        }
      }),
    ),
  [ROUTE_CREATE_EVENT_SETTINGS_LEDGER_ACCOUNTS]: (action$, state$) => {
    const state = state$.value;
    const formCode = urlFormCodeSel(state);
    return prefetchData$(action$, { dataType: 'ledgerAccounts', queryObj: { formCode } });
  },
  [ROUTE_CREATE_EVENT_SETTINGS_PROGRAMS]: (action$, state$) => {
    const state = state$.value;
    const formCode = urlFormCodeSel(state);

    return prefetchData$(
      action$,
      ...programsStepDataParams.map(dataParam => {
        switch (dataParam.dataType) {
          case 'programs':
          case 'ledgerAccounts':
            return { ...dataParam, queryObj: { formCode } };
        }
      }),
    );
  },
  [ROUTE_CREATE_EVENT_SETTINGS_COLLABORATORS]: (action$, state$) => {
    const state = state$.value;
    const formCode = urlFormCodeSel(state);
    return prefetchData$(
      action$,
      ...collaboratorsStepDataParams.map(dataParams => {
        switch (dataParams.dataType) {
          case 'collaboratorList':
          case 'formRolesList':
            return { ...dataParams, queryObj: { formCode } };
          case 'permissionsList':
            return dataParams;
        }
      }),
    );
  },
  [ROUTE_CREATE_EVENT_SETTINGS_PAYMENT_SCHEDULE]: (action$, state$) => {
    const state = state$.value;
    const formCode = urlFormCodeSel(state);

    return concat(
      prefetchData$(
        action$,
        ...paymentScheduleStepDataParams.map(params => {
          switch (params.dataType) {
            case 'paymentCategories':
            case 'formPartsData':
            case 'optionSets':
              return { ...params, queryObj: { formCode } };
          }
        }),
      ),
      defer(() => {
        const newState = state$.value;
        const paymentCategories = createDataSel('paymentCategories')(newState) || [];
        const hasError = createErrorSel('paymentCategories')(newState);
        const { eventStartDate } = createDataSel('form')(newState);

        const deposit: PaymentCategory = {
          allowedMethods: [PaymentMethod.creditCard, PaymentMethod.eCheckOrbital],
          paymentCategoryCode: shortid.generate(),
          paymentCategoryName: 'Deposit',
          dueAmount: 0,
          dayCount: 0,
          description: 'Deposit',
          paymentCategoryAmount: 0,
          paymentCategoryPercent: 0,
          dueDate: toString(eventStartDate),
          isMandatory: true,
          installmentType: 1,
          isCustom: false,
        };

        if (paymentCategories.length === 0 && !hasError) {
          const nextAction$ = updateInstallment.request(deposit);

          return of(nextAction$);
        }

        return EMPTY;
      }),
    );
  },
  [ROUTE_CREATE_EVENT_SETTINGS_DISCLAIMERS]: (action$, state$) => {
    const state = state$.value;
    const formCode = urlFormCodeSel(state);
    return prefetchData$(
      action$,
      ...disclaimersStepDataParams.map(param => {
        switch (param.dataType) {
          case 'disclaimerList':
          case 'formDisclaimers':
            return { ...param, queryObj: { formCode } };
        }
      }),
    );
  },
  [ROUTE_CREATE_EVENT_SETTINGS_ADDONS]: (action$, state$) => {
    const state = state$.value;
    const formCode = urlFormCodeSel(state);
    return prefetchData$(
      action$,
      { dataType: 'addons', queryObj: { formCode } },
      { dataType: 'states' },
    );
  },
  [ROUTE_CREATE_EVENT_SETTINGS_ACTIVITIES]: (action$, state$) => {
    const state = state$.value;
    const formCode = urlFormCodeSel(state);
    return prefetchData$(action$, { dataType: 'activities', queryObj: { formCode } });
  },
  [ROUTE_CREATE_EVENT_SETTINGS_MULTI_SESSION_CAPACITY_RULES]: (action$, state$) => {
    const state = state$.value;
    const formCode = urlFormCodeSel(state);
    return concat(
      of(updatePageSize({ dataType: 'multiSessionCapacityRuleList', pageSize: 10 })),
      prefetchData$(action$, { dataType: 'multiSessionCapacityRuleList', queryObj: { formCode } }),
    );
  },
  [ROUTE_CREATE_EVENT_SETTINGS_SESSIONS]: (action$, state$) => {
    const state = state$.value;
    const { formCode, sessionsListSortType = '' } = createDataSel('form')(state);
    const sortParams = getSessionSortParams(sessionsListSortType);

    return concat(
      of(
        updatePageSize({ dataType: 'multiSessionCapacityRuleList', pageSize: 1000 }),
        updateSessionClosed(),
      ),
      prefetchData$(
        action$,
        { dataType: 'activities', queryObj: { formCode } },
        { dataType: 'addons', queryObj: { formCode } },
        { dataType: 'multiSessionCapacityRuleList', queryObj: { formCode } },
        { dataType: 'programs', queryObj: { formCode } },
        { dataType: 'disclaimerList', queryObj: { isDeleted: false } },
        { dataType: 'ledgerAccounts', queryObj: { formCode } },
        {
          dataType: 'sessionList',
          queryObj: {
            formCode,
            hidePastSessions: false,
            [SESSION_SORT_ORDER_FIELD]: sortParams,
          },
        },
      ),
      of(
        setFilter({
          dataType: 'sessionList',
          key: SESSION_SORT_ORDER_FIELD,
          value: sortParams,
          fetchData: false,
        }),
      ),
    );
  },
  [ROUTE_CREATE_EVENT_SETTINGS_JOBS]: (action$, state$) => {
    const state = state$.value;
    const { formCode, sessionsListSortType = '' } = createDataSel('form')(state);
    const sortParams = getSessionSortParams(sessionsListSortType);

    return prefetchData$(
      action$,
      { dataType: 'jobList', queryObj: { formCode } },
      { dataType: 'jobDepartmentList', queryObj: { formCode } },
      {
        dataType: 'sessionList',
        queryObj: {
          formCode,
          hidePastSessions: false,
          includeJobsTotals: true,
          [SESSION_SORT_ORDER_FIELD]: sortParams,
        },
      },
    );
  },
  [ROUTE_CREATE_EVENT_FORM_BUILDER]: (action$, state$) => {
    const state = state$.value;
    const formCode = urlFormCodeSel(state);
    return concat(
      of(setEditingSectionCodes()),
      prefetchData$(
        action$,
        ...formBuilderStepDataParams.map(params => {
          switch (params.dataType) {
            case 'dataBlockList':
            case 'formPartsData':
            case 'formRolesList':
            case 'optionSets':
            case 'visibilitySettings':
              return { ...params, queryObj: { formCode } };
          }
        }),
      ),
    );
  },
  [ROUTE_CREATE_EVENT_FORM_PREVIEW]: (action$, state$) => {
    const state = state$.value;
    const formCode = urlFormCodeSel(state);
    return prefetchData$(
      action$,
      ...previewStepDataParams.map(params => {
        switch (params.dataType) {
          case 'attendeeTypeList':
          case 'formDisclaimers':
          case 'formPartsData':
          case 'optionSets':
          case 'visibilitySettings':
            return { ...params, queryObj: { formCode } };
        }
      }),
    );
  },
};

const createGetObservable =
  (key: string) => (action$: Observable<RootAction>, state$: StateObservable<RootState>) => {
    const state = state$.value;
    const location = typeSel(state);

    if (location !== firstStepRoute) {
      const formCode = urlFormCodeSel(state);
      if (!formCode) {
        return of(navigateToCreateEvent({ isInitialLoad: true }));
      }
    }

    const stepGetObservable = stepGetObservables[key];

    return concat(
      pageLoadObservable(action$, state$),
      defer(() => (stepGetObservable ? stepGetObservable(action$, state$) : EMPTY)),
    );
  };

export default [...steps, ...subStepsOnly].reduce(
  (acc, { key, path, ...rest }) => {
    if (!key) return acc;

    return {
      ...acc,
      [key]: {
        ...rest,
        path: `/events/create/:formCode?/${path}`,
        titleId: 'page.create_event',
        getObservable: createGetObservable(key),
        component: Loadable({
          loader: stepComponents[key],
          loading: LoadingContainer,
        }),
      },
    };
  },
  {
    [UNAUTHORIZED]: {
      path: '/unauthorized',
      titleId: 'page.create_event',
      component: LoadingContainer,
    },
  },
);
