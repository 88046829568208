import { DataBlockItem } from '@/modules/data/dataTypes/dataBlockItem';

import { FormItemDataType, MAX_FIELD_CHARS, PresentationType } from 'SHARED/constants';

export const PRESENTATION_TYPE_KEY: keyof DataBlockItem = 'presentationType';
export const DATABLOCK_ITEM_NAME_KEY: keyof DataBlockItem = 'datablockItemName';
export const PLACEHOLDER_TEXT_KEY: keyof DataBlockItem = 'placeholderText';

export const defaultDataBlockItem: Omit<DataBlockItem, 'datablockItemCode'> = {
  datablockCode: '',
  datablockItemName: '',
  dataLength: 254, // database column definition
  description: '',
  itemCaption: '',
  placeholderText: '',
  presentationType: PresentationType.TEXT,
  positionNumber: '0',
  maximumItemLength: MAX_FIELD_CHARS.toString(), // for strings
  minimumItemLength: '0', // for strings
  isVisible: true,
  isMandatory: false,
  isReadonly: false,
  maximumValue: '0', // for numbers
  minimumValue: '0', // for numbers
  matchExpression: '',
  optionSetCode: '',
  isSystem: false,
  designTimeValue: '',
  isDeleted: false,
  hasOtherOption: false,
};

export const fieldTypeOptions = [
  { value: PresentationType.TEXT, label: 'Text Input', dataType: FormItemDataType.String },
  { value: PresentationType.DROPDOWN, label: 'Dropdown List', dataType: FormItemDataType.String },
  { value: PresentationType.CHECKBOX, label: 'Check Box', dataType: FormItemDataType.String },
  { value: PresentationType.SWITCH, label: 'Toggle', dataType: FormItemDataType.Boolean },
  { value: PresentationType.RADIO, label: 'Radio', dataType: FormItemDataType.String },
  {
    value: PresentationType.DROPDOWN_BULK,
    label: 'Large Selection with inventory',
    dataType: FormItemDataType.String,
  },
  { value: PresentationType.FILE_UPLOAD, label: 'File Upload', dataType: FormItemDataType.String },
];
