import { Col, Row } from 'antd';
import React, { Fragment, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { scheduleRefresh } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import AddonsWrapper from '@/modules/entities/Addons/components/AddonsWrapper';
import SelectAddons from '@/modules/entities/Addons/components/SelectAddons';
import { saveOptionsInProgressSel } from '@/modules/entities/Addons/duck/selectors';
import { FullAddon } from '@/modules/entities/Addons/types';
import PaymentSummary from '@/modules/entities/Registrations/components/PaymentSummary';

import { currentSessionSel } from '@/pages/createReservation/steps/SessionPriorities/duck/selectors';

import ButtonsRow from '../../components/ButtonsRow';
import { openNextStep } from '../../duck/actions';
import { pageDataSel } from '../../duck/selectors';

const AddonsStep: React.FC = () => {
  const ref = useRef<{ handleSubmit: () => void }>(null);
  const saveOptionsInProgress = useSelector(saveOptionsInProgressSel);
  const {
    data: { form, attendeeDetails, groupReservation },
  } = useSelector(pageDataSel);
  const paymentCategories = useSelector(createDataSel('paymentCategories'));
  const paymentSummary = useSelector(createDataSel('paymentSummary'));
  const currentSession = useSelector(currentSessionSel);
  const formRecordAddons = useSelector(createDataSel('formRecordAddons'));
  const groupReservationAddons = useSelector(createDataSel('groupReservationAddons'));
  const { state } = useSelector(createDataSel('arnicaPerson'));
  const addonList = useSelector(createDataSel('addons'));

  const { formCode, allowGroupRegistration } = form;

  const [addons, setAddons] = useState<FullAddon[]>([]);

  const addonsForPaymentSummary = useMemo(
    () =>
      addons
        .filter(
          (addon): addon is FullAddon =>
            'selectedOptionCode' in addon && !!addon.selectedOptionCode,
        )
        .sort((a, b) => a.positionNumber - b.positionNumber),
    [addons],
  );

  function handleSubmit() {
    ref.current?.handleSubmit();
  }

  if (!currentSession) return null;

  return (
    <Fragment>
      <Row gutter={20}>
        <Col span={16}>
          <AddonsWrapper addonsSummary={currentSession.addonsSummary}>
            <SelectAddons
              ref={ref}
              formCode={formCode}
              checkMandatoryAddons
              availableAddons={addonList}
              onChange={setAddons}
              finalActions={[openNextStep(), scheduleRefresh({ dataType: 'formRecordAddons' })]}
              state={state}
              {...(allowGroupRegistration
                ? {
                    isGroup: allowGroupRegistration,
                    groupReservationGUID: groupReservation.groupReservationGUID,
                    selectedAddons: groupReservationAddons,
                  }
                : {
                    isGroup: allowGroupRegistration,
                    formRecordGUID: attendeeDetails.formRecordGUID,
                    selectedAddons: formRecordAddons,
                  })}
            />
          </AddonsWrapper>
        </Col>
        <Col span={8}>
          <PaymentSummary
            hideMakePaymentButton
            currentSession={currentSession}
            paymentSummary={paymentSummary}
            isLoading={saveOptionsInProgress}
            addons={addonsForPaymentSummary}
            paymentCategories={paymentCategories}
            {...(allowGroupRegistration
              ? {
                  isGroup: allowGroupRegistration,
                  groupReservation,
                }
              : {
                  isGroup: allowGroupRegistration,
                })}
          />
        </Col>
      </Row>
      <ButtonsRow
        leftButtons={[
          {
            disabled: saveOptionsInProgress,
          },
        ]}
        rightButtons={[
          {
            onClick: handleSubmit,
            loading: saveOptionsInProgress,
          },
        ]}
      />
    </Fragment>
  );
};

export default AddonsStep;
