import { redirect } from 'redux-first-router';
import { combineEpics, Epic } from 'redux-observable';
import { of, merge, concat, EMPTY, race } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import { refreshData, replaceDataItem, scheduleRefresh } from '@/modules/data/duck/actions';
import { prefetchData$, refreshDataAndWait$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { updatePerson } from '@/modules/entities/Person/duck/actions';
import { payloadSel, typeSel } from '@/modules/location/duck/selectors';
import { CONFIRM_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal, updateModalParams } from '@/modules/modals/duck/actions';
import {
  changePaymentStatus,
  completeOrbitalPayment,
  makeOfflinePayment,
  makeRefund,
} from '@/modules/payments/duck/actions';
import { routeSel } from '@/modules/routing/duck/selectors';
import { cancelAction, confirmAction } from '@/modules/shared/components/ConfirmModal/duck/actions';
import { ModalParams as ConfirmModalParams } from '@/modules/shared/components/ConfirmModal/types';
import toastService from '@/modules/toasts/service';
import { savePersonData, updatePersonData } from '@/modules/user/duck/actions';
import { ApiError } from '@/modules/utils/apiService';

import { navigateToEvents } from '@/pages/eventList/duck/actions';
import {
  ROUTE_GROUP_RESERVATION_PREFIX,
  ROUTE_INDIVIDUAL_RESERVATION_PREFIX,
  VALIDATE_RESERVATION_CONTACT_INVITE_ACCEPT,
} from '@/pages/reservation/constants';

import myReservationEpics$ from '../components/MyReservation/duck/epics';

import {
  updateGroupReservationContact,
  validateGroupReservationRCInvite,
  updatePaymentScheduleDueDate,
  cancelGroupReservationContactInvitation,
  removeAdditionalReservationContact,
  assignHiringManager,
  approveOrDeclineAttendee,
  transferRegistration,
  navigateToGroupReservationOverview,
  openTab,
} from './actions';
import services from './services';

const openTabEpic$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(openTab)),
    switchMap(({ payload: tabKey }) => {
      const currentRoute = routeSel(state$.value);
      const payload = payloadSel(state$.value);
      const prefix = currentRoute.split('/').slice(0, -1).join('/');

      return of({ type: `${prefix}/${tabKey}`, payload });
    }),
  );

const scheduleRefreshGroupReservation$: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(savePersonData.success)),
    filter(() => {
      const currentRoute = typeSel(state$.value);
      return !currentRoute.startsWith(ROUTE_GROUP_RESERVATION_PREFIX);
    }),
    map(() => scheduleRefresh({ dataType: 'groupReservation' })),
  );

const updateGroupReservationContact$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(updateGroupReservationContact.request)),
    switchMap(({ payload }) => {
      const apiCall$ = payload.sendInvitationEmail
        ? services.sendGroupReservationRCInvite$(payload.data)
        : services.updateGroupReservationContact$(payload.data);

      const { nextEmailAddress, data } = payload;
      const { personGUID } = data;

      return apiCall$.pipe(
        switchMap(() =>
          concat(
            nextEmailAddress
              ? merge(
                  action$.pipe(
                    filter(isActionOf([updatePersonData.success, updatePersonData.failure])),
                    take(1),
                  ),
                  of(
                    updatePersonData.request({
                      personGUID,
                      emailAddress: nextEmailAddress,
                    }),
                  ),
                )
              : EMPTY,
            of(
              closeModal(),
              refreshData({ dataType: 'groupReservation' }),
              updateGroupReservationContact.success(),
            ),
          ),
        ),
        catchError((e: Error) => {
          toastService.error(e.message);
          return of(updateGroupReservationContact.failure(e));
        }),
      );
    }),
  );

const cancelGroupReservationContactInvitation$: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(cancelGroupReservationContactInvitation.request)),
    switchMap(({ payload }) => {
      const { formCode } = createDataSel('form')(state$.value);
      const { groupReservationGUID, sessionCode, reservationContact } = payload;
      const { memberID, personGUID } = reservationContact;
      return services
        .cancelGroupReservationRCInvitation$({
          formCode,
          groupReservationGUID,
          memberID,
          personGUID,
          sessionCode,
        })
        .pipe(
          switchMap(() => {
            toastService.success('Reservation Contact invitation cancelled successfully');

            return of(
              cancelGroupReservationContactInvitation.success(),
              refreshData({ dataType: 'groupReservation' }),
            );
          }),
          catchError((error: ApiError) => {
            toastService.error(error.message);
            return of(cancelGroupReservationContactInvitation.failure(error));
          }),
        );
    }),
  );

const updatePaymentScheduleDueDate$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updatePaymentScheduleDueDate.request)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { dueDate, paymentCategoryCode, sessionCode } = payload;
      const { formCode } = createDataSel('form')(state);

      return services
        .updatePaymentScheduleDueDate$({
          formCode,
          sessionCode,
          ...('formRecordGUID' in payload
            ? { formRecordGUID: payload.formRecordGUID }
            : { groupReservationGUID: payload.groupReservationGUID }),
          paymentCategories: [{ dueDate, paymentCategoryCode }],
        })
        .pipe(
          switchMap(() => {
            const paymentCategories = createDataSel('paymentCategories')(state);
            const prevPaymentCategory = paymentCategories.find(
              pc => pc.paymentCategoryCode === paymentCategoryCode,
            );
            const nextPaymentCategory: PaymentCategory = {
              ...(prevPaymentCategory as PaymentCategory),
              dueDate,
              isCustom: true,
            };

            toastService.success('Payment schedule updated');

            return of(
              updatePaymentScheduleDueDate.success(),
              replaceDataItem({
                dataType: 'paymentCategories',
                idField: 'paymentCategoryCode',
                dataItem: nextPaymentCategory,
              }),
              refreshData({ dataType: 'payments' }),
              closeModal(),
            );
          }),
          catchError((error: ApiError) => {
            toastService.error(error.message);
            return of(updatePaymentScheduleDueDate.failure(error));
          }),
        );
    }),
  );

const validateGroupReservationRCInvite$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(validateGroupReservationRCInvite.request)),
    switchMap(({ payload: { contextToken, action, formCode, groupReservationGUID } }) =>
      services.validateGroupReservationRCInvite$({ contextToken, action }).pipe(
        switchMap(() => {
          const route =
            action === VALIDATE_RESERVATION_CONTACT_INVITE_ACCEPT
              ? navigateToGroupReservationOverview({
                  formCode: formCode,
                  groupReservationGUID: groupReservationGUID,
                })
              : navigateToEvents();
          return merge(
            of(
              refreshData({ dataType: 'groupReservation' }),
              validateGroupReservationRCInvite.success(action),
              redirect(route),
            ),
          );
        }),
        catchError((e: Error) => {
          toastService.error(e.message);
          return of(validateGroupReservationRCInvite.failure(e));
        }),
      ),
    ),
  );

const removeAdditionalReservationContact$: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(removeAdditionalReservationContact)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { additionalReservationContact } = createDataSel('groupReservation')(state);

      const contactFullName = `${additionalReservationContact.firstName} ${additionalReservationContact.lastName}`;

      const confirmModalParams: ConfirmModalParams = {
        confirmButton: {
          title: 'Yes, Remove',
        },
        description: `Are you sure you want to remove ${contactFullName} as Additional Reservation Contact?`,
        title: 'Remove Additional Reservation Contact?',
      };

      return merge(
        race(
          action$.pipe(
            filter(isActionOf(confirmAction)),
            take(1),
            switchMap(() => {
              const nextConfirmModalParams: ConfirmModalParams = {
                ...confirmModalParams,
                inProgress: true,
              };
              return merge(
                of(updateModalParams(nextConfirmModalParams)),
                services.updateGroupReservationContact$(payload).pipe(
                  switchMap(() => {
                    toastService.success('Additional Reservation Contact Removed Correctly');
                    return of(closeModal(), refreshData({ dataType: 'groupReservation' }));
                  }),
                ),
              );
            }),
          ),
          action$.pipe(
            filter(isActionOf(cancelAction)),
            take(1),
            map(() => closeModal()),
          ),
        ),
        of(openModal(CONFIRM_MODAL, confirmModalParams)),
      );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(closeModal()), caught);
    }),
  );

const updatePaymentSummary$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        makeOfflinePayment.success,
        completeOrbitalPayment.success,
        makeRefund.success,
        changePaymentStatus.success,
      ]),
    ),
    filter(() => {
      const state = state$.value;
      const currentRoute = routeSel(state);
      return [ROUTE_GROUP_RESERVATION_PREFIX, ROUTE_INDIVIDUAL_RESERVATION_PREFIX].some(prefix =>
        currentRoute.startsWith(prefix),
      );
    }),
    switchMap(() =>
      of(refreshData({ dataType: 'paymentSummary' }), refreshData({ dataType: 'payments' })),
    ),
  );

const assignHiringManager$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(assignHiringManager.request)),
    mergeMap(({ payload }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      return services.assignHiringManager$({ ...payload, formCode });
    }),
    switchMap(() =>
      of(assignHiringManager.success(), refreshData({ dataType: 'attendeeDetails' })),
    ),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(assignHiringManager.failure(error)), caught);
    }),
  );

const approveAttendee$: Epic<RootAction, RootAction> = actions$ =>
  actions$.pipe(
    filter(isActionOf(approveOrDeclineAttendee.request)),
    switchMap(({ payload }) => {
      const { declineText, ...statusUpdatePayload } = payload;

      return services.updateFormRecordStatus$(statusUpdatePayload).pipe(
        switchMap(() =>
          of(
            refreshData({ dataType: 'attendeeDetails' }),
            approveOrDeclineAttendee.success({
              isCancelled: false,
              statusCode: payload.statusCode,
            }),
            closeModal(),
          ),
        ),
        catchError((err: Error) => {
          toastService.error(err.message);
          return of(approveOrDeclineAttendee.failure(err));
        }),
      );
    }),
  );

const transferRegistration$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(transferRegistration.request)),
    switchMap(({ payload }) => {
      const { formCode, personExists, formRecordGUID } = payload;

      if (personExists) {
        return services.transferRegistration$(payload).pipe(
          switchMap(() =>
            concat(
              merge(
                prefetchData$(action$, {
                  dataType: 'arnicaPerson',
                  queryObj: { personGUID: payload.newPersonGUID },
                }),
                refreshDataAndWait$(action$, { dataType: 'attendeeDetails' }),
              ),
              of(transferRegistration.success(), closeModal()),
            ),
          ),
        );
      }

      return merge(
        race(
          action$.pipe(
            filter(isActionOf(updatePerson.success)),
            take(1),
            switchMap(({ payload: newPersonGUID }) =>
              of(
                transferRegistration.request({
                  formCode,
                  formRecordGUID,
                  personExists: true,
                  newPersonGUID,
                }),
              ),
            ),
          ),
          action$.pipe(
            filter(isActionOf(updatePerson.failure)),
            take(1),
            switchMap(({ payload: { message } }) => {
              throw new Error(message);
            }),
          ),
        ),
        of(updatePerson.request({ ...payload.personData, isLocal: true })),
      );
    }),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return merge(of(transferRegistration.failure(error)), caught);
    }),
  );

const refetchPaymentData$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf([makeOfflinePayment.success, completeOrbitalPayment.success])),
    switchMap(() =>
      of(refreshData({ dataType: 'paymentSummary' }), refreshData({ dataType: 'payments' })),
    ),
  );

export default combineEpics(
  openTabEpic$,
  approveAttendee$,
  assignHiringManager$,
  transferRegistration$,
  refetchPaymentData$,
  scheduleRefreshGroupReservation$,
  updateGroupReservationContact$,
  cancelGroupReservationContactInvitation$,
  updatePaymentScheduleDueDate$,
  validateGroupReservationRCInvite$,
  removeAdditionalReservationContact$,
  myReservationEpics$,
  updatePaymentSummary$,
);
