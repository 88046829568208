import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const HeaderWrapper = styled.div`
  height: 56px;
  background-color: ${colors.scoutingBlue};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2.4rem;
`;
