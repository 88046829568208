import { createAction, createAsyncAction } from 'typesafe-actions';

import { DataTypes } from '@/modules/data/dataTypes';
import { Attendee } from '@/modules/data/dataTypes/attendeeList';
import { createModuleNamespaceName } from '@/modules/utils/misc';

import { modulePath } from '../constants';

const moduleNamespace = createModuleNamespaceName(modulePath);

export const councilApproveSelected = createAction(`${moduleNamespace}/COUNCIL_APPROVE_SELECTED`)<{
  formRecordGUIDList: string[];
  dataType: keyof DataTypes;
  dataId: string;
}>();

export const checkIn = createAsyncAction(
  `${moduleNamespace}/CHECK_IN_REQUEST`,
  `${moduleNamespace}/CHECK_IN_SUCCESS`,
  `${moduleNamespace}/CHECK_IN_FAILURE`,
)<{ attendee: Attendee; checkedIn: boolean }, void, Error>();
