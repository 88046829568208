import React from 'react';
import { useSlate } from 'slate-react';

import { LeafType } from '../../../../constants';
import { getFormat, toggleMark } from '../../../../utils';
import ToolbarButton from '../ToolbarButton';

type Props = {
  format: LeafType;
  Icon: React.ComponentType;
};

const FormatButton: React.FC<Props> = ({ format, Icon }) => {
  const editor = useSlate();
  const activeFormat = getFormat(editor, format);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleMark(editor, format);
  };

  return <ToolbarButton isActive={!!activeFormat} Icon={Icon} onClick={handleClick} />;
};

export default FormatButton;
