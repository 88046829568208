import React, { cloneElement, useState } from 'react';

import ToolbarButton from '@/modules/richTextEditor/components/Toolbar/components/ToolbarButton';
import Popover from '@/modules/shared/components/Popover';

type Props = {
  popover: React.ReactElement;
  isActive?: boolean;
  Icon: React.ComponentType;
  caretDirection?: 'up' | 'down';
};

const ToolbarPopoverButton: React.FC<Props> = ({
  popover,
  isActive = false,
  Icon,
  caretDirection,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  function handleHidePopover() {
    setPopoverVisible(false);
  }

  return (
    <Popover
      noPadding
      content={cloneElement(popover, { onHide: handleHidePopover, isVisible: popoverVisible })}
      trigger="click"
      placement="bottom"
      open={popoverVisible}
      onOpenChange={setPopoverVisible}
    >
      <ToolbarButton Icon={Icon} caretDirection={caretDirection} isActive={isActive} />
    </Popover>
  );
};

ToolbarPopoverButton.propTypes = {};

ToolbarPopoverButton.defaultProps = {
  isActive: false,
};

export default ToolbarPopoverButton;
