import { LeftOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';
import { login } from '@/modules/user/duck/actions';
import { loginInProgressSel } from '@/modules/user/duck/selectors';

import { Form, LoginButton, ChangeSignInButton, Subtitle } from '../../../../LoginComponent.styled';

type Props = {
  onSelectLoginOption: (loginOption: null) => void;
};

const LoginWithReservationCode: React.FC<Props> = ({ onSelectLoginOption }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<{ reservationCode: string; lastName: string }>();
  const screenSize = useGetScreenSize();
  const isLoginInProgress = useSelector(loginInProgressSel);

  const isMobileScreen = screenSize === 'mobile';

  const handleSubmit = () => {
    form.validateFields().then(values => {
      dispatch(login.request(values));
    });
  };

  const handleClickSignInOptions = () => {
    onSelectLoginOption(null);
  };

  return (
    <Fragment>
      <Subtitle level={2}>
        <FormattedMessage id="user.LoginPage.signInWithReservationCode" />
      </Subtitle>
      <Form form={form} $isMobile={isMobileScreen} layout="vertical">
        <Form.Item
          name="reservationCode"
          label={<FormattedMessage id="user.LoginPage.reservationCode" />}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={isLoginInProgress} size="large" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={<FormattedMessage id="user.LoginPage.email" />}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={isLoginInProgress} size="large" />
        </Form.Item>
        <LoginButton
          disabled={isLoginInProgress}
          loading={isLoginInProgress}
          size="large"
          type="primary"
          $isMobile={isMobileScreen}
          onClick={handleSubmit}
        >
          <FormattedMessage id="user.LoginPage.login" />
        </LoginButton>
      </Form>
      <ChangeSignInButton
        ghost
        id="change.signIn.option"
        $isMobile={isMobileScreen}
        onClick={handleClickSignInOptions}
      >
        <LeftOutlined />
        {'Change Sign In Option'}
      </ChangeSignInButton>
    </Fragment>
  );
};

export default LoginWithReservationCode;
