import { Col, Row } from 'antd';
import React, { Fragment, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Activity } from '@/modules/data/dataTypes/activities';
import { scheduleRefresh } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import ActivitiesSelector from '@/modules/entities/Activities/components/ActivitiesSelector';
import { saveActivitiesInProgressSel } from '@/modules/entities/Activities/duck/selectors';
import PaymentSummary from '@/modules/entities/Registrations/components/PaymentSummary';

import { openNextStep } from '@/pages/createReservation/duck/actions';

import ButtonsRow from '../../components/ButtonsRow';
import { pageDataSel } from '../../duck/selectors';
import { currentSessionSel } from '../SessionPriorities/duck/selectors';

const ActivitiesStep: React.FC = () => {
  const ref = useRef<{ handleSubmit: () => void }>(null);
  const saveActivitiesInProgress = useSelector(saveActivitiesInProgressSel);
  const {
    data: { form, attendeeDetails, groupReservation },
  } = useSelector(pageDataSel);
  const currentSession = useSelector(currentSessionSel);
  const paymentSummary = useSelector(createDataSel('paymentSummary'));
  const availableActivities = useSelector(createDataSel('activities'));
  const formRecordActivities = useSelector(createDataSel('formRecordActivities'));
  const groupReservationActivities = useSelector(createDataSel('groupReservationActivities'));
  const paymentCategories = useSelector(createDataSel('paymentCategories'));

  const { allowGroupRegistration } = form;

  const [activities, setActivities] = useState<Array<Activity & { quantity: number }>>([]);

  const includedActivities = useMemo(
    () =>
      activities
        .filter(({ quantity }) => quantity > 0)
        .map(activity => ({ ...activity, totalFee: activity.fee * activity.quantity })),
    [activities],
  );

  function handleSubmit() {
    ref.current?.handleSubmit();
  }

  if (!currentSession) return null;

  return (
    <Fragment>
      <Row gutter={20}>
        <Col span={16}>
          <ActivitiesSelector
            ref={ref}
            finalActions={[openNextStep(), scheduleRefresh({ dataType: 'formRecordActivities' })]}
            onChange={setActivities}
            availableActivities={availableActivities}
            {...(allowGroupRegistration
              ? {
                  isGroup: allowGroupRegistration,
                  groupReservationGUID: groupReservation.groupReservationGUID,
                  selectedActivities: groupReservationActivities,
                }
              : {
                  isGroup: allowGroupRegistration,
                  formRecordGUID: attendeeDetails.formRecordGUID,
                  selectedActivities: formRecordActivities,
                })}
          />
        </Col>
        <Col span={8}>
          <PaymentSummary
            currentSession={currentSession}
            paymentSummary={paymentSummary}
            paymentCategories={paymentCategories}
            activities={includedActivities}
            hideMakePaymentButton
            isLoading={saveActivitiesInProgress}
            {...(allowGroupRegistration
              ? {
                  isGroup: allowGroupRegistration,
                  groupReservation,
                }
              : {
                  isGroup: allowGroupRegistration,
                })}
          />
        </Col>
      </Row>
      <ButtonsRow
        leftButtons={[
          {
            disabled: saveActivitiesInProgress,
          },
        ]}
        rightButtons={[
          {
            onClick: handleSubmit,
            loading: saveActivitiesInProgress,
          },
        ]}
      />
    </Fragment>
  );
};

export default ActivitiesStep;
