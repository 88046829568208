import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const WarningMessage = styled.span`
  color: ${colors.error};
  font-size: 14px;
  padding: 0 1rem;
  font-style: italic;
  > a {
    color: #003f87;
  }
`;
