import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  changePaymentStatus,
  makeRefund,
  iFramePaymentStart,
  completeOrbitalPayment,
  initOrbitalPayment,
  makeOfflinePayment,
} from './actions';

const paymentErrorMessage = createReducer('')
  .handleAction(
    [initOrbitalPayment.failure, completeOrbitalPayment.failure],
    (_state, { payload }) => payload,
  )
  .handleAction(
    [initOrbitalPayment.request, initOrbitalPayment.success, iFramePaymentStart],
    () => '',
  );

const orbitalPaymentSessionIdentifiers = createReducer({ sessionId: '', uID: '' })
  .handleAction(initOrbitalPayment.success, (_state, { payload: { sessionId, uID } }) => ({
    sessionId,
    uID,
  }))
  .handleAction(
    [
      initOrbitalPayment.request,
      initOrbitalPayment.failure,
      completeOrbitalPayment.cancel,
      completeOrbitalPayment.success,
    ],
    () => ({ sessionId: '', uID: '' }),
  );

const makePaymentInProgress = createReducer(false)
  .handleAction(
    [
      completeOrbitalPayment.request,
      initOrbitalPayment.request,
      iFramePaymentStart,
      makeOfflinePayment.request,
    ],
    () => true,
  )
  .handleAction(
    [
      initOrbitalPayment.failure,
      initOrbitalPayment.success,
      completeOrbitalPayment.cancel,
      completeOrbitalPayment.failure,
      completeOrbitalPayment.success,
      makeOfflinePayment.success,
      makeOfflinePayment.failure,
    ],
    () => false,
  );

const makeRefundInProgress = createReducer(false)
  .handleAction(makeRefund.request, () => true)
  .handleAction([makeRefund.success, makeRefund.failure], () => false);

const changePaymentStatusInProgress = createReducer(false)
  .handleAction(changePaymentStatus.request, () => true)
  .handleAction([changePaymentStatus.success, changePaymentStatus.failure], () => false);

const isErrorMakeRefund = createReducer(false)
  .handleAction(makeRefund.failure, () => true)
  .handleAction([makeRefund.request, makeRefund.success], () => false);

export default combineReducers({
  paymentErrorMessage,
  orbitalPaymentSessionIdentifiers,
  makeRefundInProgress,
  makePaymentInProgress,
  changePaymentStatusInProgress,
  isErrorMakeRefund,
});
