import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateFormRecord } from '@/modules/entities/FormRecord/duck/actions';
import { updateFormRecordInProgressSel } from '@/modules/entities/FormRecord/duck/selectors';
import Questions from '@/modules/questions';
import { FormattedFormParts } from '@/modules/questions/types';

import { openNextStep } from '../../duck/actions';
import {
  primaryRegistrantFormRecordGUIDSel,
  secondaryRegistrantFormRecordGuidSel,
  sessionSel,
} from '../../duck/selectors';

import { stepDataSel } from './duck/selectors';

const QuestionsStep = forwardRef<{ handleSubmit: () => void }>((_props, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const formRef = useRef<{ handleSubmit: () => Promise<FormattedFormParts> }>(null);
  const dispatch = useDispatch();

  const {
    data: { attendeeDetails, optionSets, form, visibilitySettings },
  } = useSelector(stepDataSel);

  const updateFormRecordInProgress = useSelector(updateFormRecordInProgressSel);
  const primaryRegistrantFormRecordGUID = useSelector(primaryRegistrantFormRecordGUIDSel);
  const secondaryRegistrantFormRecordGUID = useSelector(secondaryRegistrantFormRecordGuidSel);
  const session = useSelector(sessionSel);

  const { formCode } = form;

  function handleSubmit() {
    formRef.current?.handleSubmit().then(formParts => {
      if (Array.isArray(formParts) && formParts.length) {
        dispatch(
          updateFormRecord.request({
            formCode,
            formParts,
            formRecordGUID: secondaryRegistrantFormRecordGUID as string,
            primaryRegistrantFormRecordGUID,
            sessionCode: session?.sessionCode,
            finalActions: [openNextStep()],
          }),
        );
      } else {
        dispatch(openNextStep());
      }
    });
  }
  return (
    <Questions
      ref={formRef}
      attendeeDetails={attendeeDetails}
      optionSets={optionSets}
      visibilitySettings={visibilitySettings}
      isLoading={updateFormRecordInProgress}
      removeSystemFormParts
      isEditing
    />
  );
});

export default QuestionsStep;
