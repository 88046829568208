import { FormInstance } from 'antd';
import { set, unset } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { QueryFilterVariable } from '@/modules/data/dataTypes/queryFilterVariableList';
import { usePrevious } from '@/modules/utils/hooks';

import { FILTER_EXPRESSION_OPERATOR } from '../../../../../../constants';
import { FormItem } from '../../Expression.styled';
import { operatorsObj } from '../../constants';

import { Select } from './SelectOperator.styled';
import {
  BOOLEAN_OPERATORS,
  DATES_OPERATORS,
  FILE_OPERATORS,
  NUMBER_OPERATORS,
  TEXT_OPERATORS,
} from './constants';

import { FormItemDataType, PresentationType } from 'SHARED/constants';

type Props = {
  queryFilterVariable?: QueryFilterVariable;
  name: number;
  form: FormInstance;
  fieldPath: (string | number)[];
};

const SelectOperator: React.FC<Props> = ({ queryFilterVariable, name, form, fieldPath }) => {
  const variable = queryFilterVariable?.value;

  const prevVariable = usePrevious(variable);

  useEffect(() => {
    if (prevVariable && prevVariable !== variable) {
      const allValues = form.getFieldsValue();
      unset(allValues, fieldPath);
      form.setFieldsValue(allValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variable]);

  useEffect(() => {
    if (queryFilterVariable?.dataType === FormItemDataType.Boolean) {
      const allValues = form.getFieldsValue();
      set(allValues, fieldPath, operatorsObj.equalTo.value);
      form.setFieldsValue(allValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilterVariable, form]);

  const options: Array<{ label: string; value: string }> = useMemo(() => {
    if (!queryFilterVariable) return [];
    if (queryFilterVariable.presentationType === PresentationType.FILE_UPLOAD)
      return FILE_OPERATORS;
    switch (queryFilterVariable.dataType) {
      case FormItemDataType.Boolean:
        return BOOLEAN_OPERATORS;
      case FormItemDataType.Datetime:
        return DATES_OPERATORS;
      case FormItemDataType.Number:
        return NUMBER_OPERATORS;
      case FormItemDataType.String:
      case FormItemDataType.Text:
      default:
        return TEXT_OPERATORS;
    }
  }, [queryFilterVariable]);

  return (
    <FormItem
      name={[name, FILTER_EXPRESSION_OPERATOR]}
      rules={[{ required: true, message: 'Operator is required' }]}
      $showLine
    >
      <Select
        disabled={!queryFilterVariable || queryFilterVariable.dataType === FormItemDataType.Boolean}
        placeholder="Operator"
      >
        {options.map(({ label, value }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
};

export default SelectOperator;
