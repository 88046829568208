import { get, isEqual } from 'lodash';
import { selectLocationState } from 'redux-first-router';
import { combineEpics, Epic } from 'redux-observable';
import { of, interval, concat, defer, merge, Observable, EMPTY } from 'rxjs';
import { filter, mergeMap, switchMap, takeUntil, map, catchError } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { startBroadcastListening } from '@/modules/broadcast/duck/actions';
import { Person } from '@/modules/data/dataTypes/person';
import { fetchData, replaceData } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import personServices from '@/modules/entities/Person/duck/services';
import { SESSION_EXPIRED_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal } from '@/modules/modals/duck/actions';
import { getNeedsAuth } from '@/modules/routing/utils';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { navigateToEvents } from '@/pages/eventList/duck/actions';

import { currentRouteSel, typeSel } from '../../location/duck/selectors';
import { currentUserData, IS_MASQUERADED, SESSION_CHECK_TIME } from '../constants';
import { LoginData } from '../types';

import {
  showSessionExpiredModal,
  startSessionCheck,
  logout,
  savePersonData,
  navigateToLogin,
  updatePersonData,
  login,
  masquerade,
  singleSignOnRequest,
  ROUTE_LOGIN,
  saveLoginData,
  googleLogin,
  appleLogin,
} from './actions';
import { hasMasqueradeRoleSel, isLoggedInSel } from './selectors';
import services from './services';

const SESSION_CHECK_INTERVAL = 1000 * 60 * SESSION_CHECK_TIME;

const handleSsoError$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(fetchData.failure)),
    filter(({ payload }) => payload.dataType === 'personProfile'),
    map(() => {
      toastService.error("Couldn't retrieve user profile");
      return logout.request(undefined);
    }),
  );

const ssoValidation$: Epic<RootAction, RootAction, RootState> = actions$ =>
  actions$.pipe(
    filter(isActionOf(singleSignOnRequest)),
    switchMap(() =>
      services
        .selfSessionValidation$()
        .pipe(
          switchMap(response =>
            concat(prefetchLoginData$(actions$, response), of(login.success())),
          ),
        ),
    ),
    catchError(() => of(logout.request(undefined))),
  );

const showSessionExpiredModal$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(showSessionExpiredModal)),
    filter(() => isLoggedInSel(state$.value)),
    map(() => openModal(SESSION_EXPIRED_MODAL)),
  );

export const sessionCheck$: Epic<RootAction, RootAction> = actions$ =>
  actions$.pipe(
    filter(isActionOf(startSessionCheck)),
    switchMap(() =>
      interval(SESSION_CHECK_INTERVAL).pipe(
        switchMap(() => services.renewSession$(true)),
        switchMap(() => EMPTY),
        takeUntil(actions$.pipe(filter(isActionOf(logout.request)))),
      ),
    ),
  );

const savePersonData$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(savePersonData.request)),
    switchMap(({ payload }) => {
      const currentValues = createDataSel('arnicaPerson')(state$.value);
      const { finalActions = [], ...rest } = payload;

      const dataChanged = Object.entries(rest).some(
        ([key, value]) => !isEqual(value, currentValues[key as keyof Person]),
      );

      if (!dataChanged) {
        return of(savePersonData.success(), ...finalActions);
      }

      return personServices.updatePerson$(rest).pipe(
        switchMap(() =>
          of(
            savePersonData.success(),
            replaceData({ dataType: 'arnicaPerson', data: { ...currentValues, ...rest } }),
            ...finalActions,
          ),
        ),
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(savePersonData.failure(error));
        }),
      );
    }),
  );

const updatePersonData$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updatePersonData.request)),
    switchMap(({ payload }) => {
      const { personGUID } = payload;
      return concat(
        prefetchData$(action$, { dataType: 'arnicaPerson', queryObj: { personGUID } }),
        defer(() => {
          const personData = createDataSel('arnicaPerson')(state$.value);
          return personServices
            .updatePerson$({ ...personData, ...payload })
            .pipe(map(() => updatePersonData.success()));
        }),
      );
    }),
    catchError((error: ApiError, caught) => merge(of(updatePersonData.failure(error)), caught)),
  );

export const login$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(login.request)),
    switchMap(({ payload }) => {
      const serviceCall =
        'reservationCode' in payload
          ? services.loginWithReservationCode$(payload)
          : services.login$(payload);
      return serviceCall.pipe(
        switchMap(loginData =>
          concat(
            prefetchLoginData$(action$, {
              ...loginData,
              isLocalUser: 'reservationCode' in payload,
            }),
            of(login.success(payload.preventPostLoginRedirection), closeModal()),
          ),
        ),
      );
    }),
    catchError((err: ApiError, caught) => {
      toastService.error(err.message);
      return merge(of(login.failure(err)), caught);
    }),
  );

const prefetchLoginData$ = (
  action$: Observable<RootAction>,
  loginData: LoginData,
  isMasqueraded?: boolean,
): Observable<RootAction> => {
  const { isLocalUser = false, personGuid } = loginData;

  localStorage.setItem(IS_MASQUERADED, (isMasqueraded || false).toString());

  return concat(
    of(saveLoginData(loginData)),
    prefetchData$(
      action$,
      { dataType: 'configuration' },
      { dataType: 'personJobDepartmentList' },
      { dataType: 'personFormRoles' },
      { dataType: 'arnicaPerson', dataId: currentUserData, queryObj: { personGUID: personGuid } },
      isLocalUser
        ? null
        : {
            dataType: 'personProfile',
            dataId: currentUserData,
            queryObj: { personGuid },
          },
    ),
    of(startSessionCheck(), startBroadcastListening()),
  );
};

export const logout$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(logout.request)),
    map(action => {
      google.accounts.id.disableAutoSelect();

      return action;
    }),
    mergeMap(({ payload }) => {
      services.removeUserRole();
      localStorage.removeItem(IS_MASQUERADED);

      return services.logout$().pipe(switchMap(() => of(logout.success(!!payload))));
    }),
    catchError((err: ApiError, caught) => merge(caught, of(logout.failure(err)))),
  );

const masquerade$: Epic<RootAction, RootAction, RootState> = (actions$, state$) =>
  actions$.pipe(
    filter(isActionOf(masquerade.request)),
    switchMap(({ payload: memberId }) => {
      const hasMasqueradeRole = hasMasqueradeRoleSel(state$.value);
      if (!hasMasqueradeRole) {
        toastService.error("You don't have permission to masquerade");
        return of(closeModal());
      }
      return services.masqueradeUser$(memberId).pipe(
        mergeMap(response => {
          if (!get(response, 'account.username')) {
            const msg = "This Member id it's not the primary for this account";
            toastService.error(msg);
            throw new Error(msg);
          }

          return concat(
            prefetchLoginData$(actions$, response, true),
            of(login.success(), masquerade.success(), closeModal()),
          );
        }),
        catchError((error: Error) => of(masquerade.failure(error))),
      );
    }),
  );

const appleLoginEpic$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appleLogin)),
    switchMap(({ payload: token }) => {
      const header = token ? { Authorization: `bearer ${token}` } : undefined;

      return services.selfSessionValidation$(header).pipe(
        switchMap(loginData => concat(prefetchLoginData$(action$, loginData), of(login.success()))),
        catchError(err => {
          if (err.status === 401) {
            toastService.error('Your Apple account is not associated with a bsa profile');
          }
          return of(logout.request());
        }),
      );
    }),
  );

const googleLoginEpic$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(googleLogin)),
    switchMap(({ payload }) => {
      const header = { Authorization: `Bearer ${payload}` };
      return services.selfSessionValidation$(header).pipe(
        switchMap(loginData => concat(prefetchLoginData$(action$, loginData), of(login.success()))),
        catchError(err => {
          if (err.status === 401) {
            toastService.error(get(err, ['response', 'message'], 'User not found'));
          }
          return of(logout.request());
        }),
      );
    }),
  );

const navigateOnLogin$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(login.success)),
    switchMap(({ payload: preventPostLoginNavigation }) => {
      const state = state$.value;
      const routeFromAddressBar = currentRouteSel(state);
      const { prev: routeBeforeLogin } = selectLocationState(state);

      if (preventPostLoginNavigation) return EMPTY;

      if (routeFromAddressBar?.type === ROUTE_LOGIN) {
        if (routeBeforeLogin?.type) {
          return of(routeBeforeLogin);
        }
        return of(navigateToEvents());
      }

      return of(routeFromAddressBar);
    }),
  );

const navigateOnLogout$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(logout.success)),
    switchMap(({ payload: openLogin }) => {
      const state = state$.value;
      const currentRoute = currentRouteSel(state);
      const routeType = typeSel(state);
      const needsAuth = getNeedsAuth(routeType);

      return of(needsAuth || openLogin ? navigateToLogin() : currentRoute);
    }),
  );

export default combineEpics(
  showSessionExpiredModal$,
  sessionCheck$,
  login$,
  logout$,
  masquerade$,
  appleLoginEpic$,
  googleLoginEpic$,
  savePersonData$,
  handleSsoError$,
  ssoValidation$,
  updatePersonData$,
  navigateOnLogin$,
  navigateOnLogout$,
);
