import { Switch } from 'antd';
import { difference } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { Permission } from '@/modules/data/dataTypes/permissionsList';
import { Role } from '@/modules/data/dataTypes/roleList';
import { fetchData } from '@/modules/data/duck/actions';
import { createDataSel, createIsLoadingSel } from '@/modules/data/duck/selectors';
import { PermissionAction, PersonRoles } from '@/modules/entities/Roles/constants';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import Divider from '@/modules/shared/components/Divider';
import Label from '@/modules/shared/components/Label';
import S from '@/modules/shared/components/S';
import TagSelector from '@/modules/shared/components/TagSelector';

import { updateReportPermissions } from '../../duck/actions';
import { updateReportPermissionsInProgressSel } from '../../duck/selectors';
import { createCurrentQueryFilterSel } from '../../tabs/CurrentReport/duck/selectors';

import { ModalParams } from './types';

import { createQueryFilterPermissionTarget } from 'ROLES/utils';

const getRolesWithPermission = (roles?: Role[], permission?: Permission) =>
  permission && roles
    ? roles
        .filter(r => r.permissions.find(p => p.permissionCode === permission.permissionCode))
        .map(r => r.roleCode)
    : [];

const getPermission = (
  queryFilterCode: string,
  action: PermissionAction,
  permissions?: Permission[],
) => {
  if (permissions) {
    const queryFilterPermissionTarget = createQueryFilterPermissionTarget(queryFilterCode);
    return permissions.find(p => p.target === queryFilterPermissionTarget && p.action === action);
  }
};

type Props = { modalParams: ModalParams };

const UpdateReportPermissionsModal: React.FC<Props> = ({ modalParams }) => {
  const { queryFilterCode, dataType } = modalParams;

  const dispatch = useDispatch();
  const formRoles: Role[] | undefined = useSelector(createDataSel('formRolesList'));
  const personRoles: Role[] | undefined = useSelector(createDataSel('personRolesList'));
  const permissions: Permission[] | undefined = useSelector(createDataSel('permissionsList'));
  const { formCode, requireCouncilApproval } = useSelector(createDataSel('form'));
  const formRolesIsLoading = useSelector(createIsLoadingSel('formRolesList'));
  const personRolesIsLoading = useSelector(createIsLoadingSel('personRolesList'));
  const permissionsIsLoading = useSelector(createIsLoadingSel('permissionsList'));
  const updateReportPermissionsInProgress = useSelector(updateReportPermissionsInProgressSel);
  const currentQueryFilter = useSelector((state: RootState) =>
    createCurrentQueryFilterSel(queryFilterCode)(state, { dataType }),
  );

  const filteredRoles = useMemo(() => {
    if (!requireCouncilApproval) return formRoles || [];

    const filteredPersonRoles =
      personRoles && personRoles.filter(r => r.roleCode === PersonRoles.EventApprovalDesignee);

    return [...(filteredPersonRoles || []), ...(formRoles || [])];
  }, [requireCouncilApproval, personRoles, formRoles]);

  useEffect(() => {
    dispatch(fetchData.request({ dataType: 'personRolesList' }));
    dispatch(fetchData.request({ dataType: 'formRolesList', queryObj: { formCode } }));
    dispatch(fetchData.request({ dataType: 'permissionsList' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const readPermission = useMemo(
    () => getPermission(queryFilterCode, PermissionAction.Read, permissions),
    [permissions, queryFilterCode],
  );
  const writePermission = useMemo(
    () => getPermission(queryFilterCode, PermissionAction.Update, permissions),
    [permissions, queryFilterCode],
  );

  const initialRolesWithRead = useMemo(
    () => getRolesWithPermission(filteredRoles, readPermission),
    [filteredRoles, readPermission],
  );
  const initialRolesWithWrite = useMemo(
    () => getRolesWithPermission(filteredRoles, writePermission),
    [filteredRoles, writePermission],
  );

  const [rolesWithRead, setRolesWithRead] = useState(initialRolesWithRead);
  const [rolesWithWrite, setRolesWithWrite] = useState(initialRolesWithWrite);
  const [isPublic, setIsPublic] = useState(!!currentQueryFilter?.isPublic);

  useEffect(() => {
    setRolesWithRead(initialRolesWithRead);
  }, [initialRolesWithRead]);

  useEffect(() => {
    setRolesWithWrite(initialRolesWithWrite);
  }, [initialRolesWithWrite]);

  function handleSubmit() {
    dispatch(
      updateReportPermissions.request({
        queryFilterCode,
        isPublic,
        readPermissionCode: readPermission?.permissionCode,
        writePermissionCode: writePermission?.permissionCode,
        rolesWithRead: difference(rolesWithRead, initialRolesWithRead),
        rolesWithWrite: difference(rolesWithWrite, initialRolesWithWrite),
        rolesWithoutRead: difference(initialRolesWithRead, rolesWithRead),
        rolesWithoutWrite: difference(initialRolesWithWrite, rolesWithWrite),
        dataType,
      }),
    );
  }

  return (
    <ModalComponent
      title="Edit Permissions"
      buttons={[
        {
          title: 'Save',
          type: 'primary',
          onClick: handleSubmit,
        },
      ]}
      inProgress={
        updateReportPermissionsInProgress ||
        personRolesIsLoading ||
        formRolesIsLoading ||
        permissionsIsLoading
      }
    >
      <Label label="Make Report Public">
        <Switch checked={isPublic} onChange={setIsPublic} />
      </Label>
      <S size="4" semibold>
        Specify which roles will have access to this report
      </S>
      <TagSelector
        dataSource={filteredRoles}
        valueField="roleCode"
        labelField="roleName"
        onChange={setRolesWithRead}
        value={rolesWithRead}
      />
      <Divider />
      <S size="4" semibold>
        Specify which roles can edit this report
      </S>
      <TagSelector
        dataSource={filteredRoles}
        valueField="roleCode"
        labelField="roleName"
        value={rolesWithWrite}
        onChange={setRolesWithWrite}
      />
      <Divider />
    </ModalComponent>
  );
};

export default UpdateReportPermissionsModal;
