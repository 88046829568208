import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``;

type Props = {
  className: string;
};

const ClockIcon: React.FC<Props> = ({ className }) => (
  <Svg viewBox="0 0 24 24" width="24px" height="24px" className={className}>
    <title>timer-24px</title>
    <g strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-138.000000, -594.000000)">
        <g transform="translate(122.000000, 580.000000)">
          <g transform="translate(16.000000, 14.000000)">
            <polygon points="0 0 24 0 24 24 0 24" />
            <path
              d="M15,1 L9,1 L9,3 L15,3 L15,1 Z M11,14 L13,14 L13,8 L11,8 L11,14 Z M19.03,7.39 L20.45,5.97 C20.02,5.46 19.55,4.98 19.04,4.56 L17.62,5.98 C16.07,4.74 14.12,4 12,4 C7.03,4 3,8.03 3,13 C3,17.97 7.02,22 12,22 C16.98,22 21,17.97 21,13 C21,10.88 20.26,8.93 19.03,7.39 Z M12,20 C8.13,20 5,16.87 5,13 C5,9.13 8.13,6 12,6 C15.87,6 19,9.13 19,13 C19,16.87 15.87,20 12,20 Z"
              fill="currentColor"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default ClockIcon;
