import { createAction, createAsyncAction } from 'typesafe-actions';

import { DataTypes } from '@/modules/data/dataTypes';
import { QueryObj } from '@/modules/data/types';
import { ApiError } from '@/modules/utils/apiService';
import { createModuleNamespaceName } from '@/modules/utils/misc';

import { moduleName, TabKey } from '../constants';
import { ReportDataType } from '../types';

const moduleNameSpace = createModuleNamespaceName(moduleName);

export const setFormCode = createAction(`${moduleNameSpace}/SET_FORM_CODE`)<string>();

export const openTab = createAsyncAction(
  `${moduleNameSpace}/OPEN_TAB_REQUEST`,
  `${moduleNameSpace}/OPEN_TAB_SUCCESS`,
  `${moduleNameSpace}/OPEN_TAB_FAILURE`,
)<
  {
    tabKey: TabKey | string;
    formCode: string;
    dataType: ReportDataType;
  },
  {
    tabKey: TabKey | string;
    dataType: ReportDataType;
  },
  Error
>();

export const selectReport = createAction(`${moduleNameSpace}/SELECT_REPORT`)<{
  queryFilterCode: string;
  dataType: ReportDataType;
}>();

export const deleteReport = createAction(`${moduleNameSpace}/DELETE_REPORT_REQUEST`)<{
  dataType: ReportDataType;
  queryFilterCode: string;
}>();

export const updateReportPermissions = createAsyncAction(
  `${moduleNameSpace}/UPDATE_REPORT_ROLES_REQUEST`,
  `${moduleNameSpace}/UPDATE_REPORT_ROLES_SUCCESS`,
  `${moduleNameSpace}/UPDATE_REPORT_ROLES_FAILURE`,
)<
  {
    dataType: ReportDataType;
    queryFilterCode: string;
    isPublic: boolean;
    readPermissionCode?: string;
    writePermissionCode?: string;
    rolesWithRead: string[];
    rolesWithWrite: string[];
    rolesWithoutRead: string[];
    rolesWithoutWrite: string[];
  },
  undefined,
  Error
>();

export const downloadDataRequest = createAction(`${moduleNameSpace}/DOWNLOAD_DATA_REQUEST`)<{
  dataType: keyof DataTypes;
  queryObj: QueryObj;
  formCode: string;
}>();

export const downloadDataHeartbeat = createAction(`${moduleNameSpace}/DOWNLOAD_DATA_HEARTBEAT`)<{
  dataType: keyof DataTypes;
  contextToken: string;
  reportName: string;
}>();

export const downloadDataError = createAction(`${moduleNameSpace}/DOWNLOAD_DATA_ERROR`)<{
  dataType: keyof DataTypes;
  error: ApiError;
}>();

export const resetReports = createAction(`${moduleName}/RESET_REPORTS`)<ReportDataType>();
