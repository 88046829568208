import { createAsyncAction, RootAction } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';
import { UpdateFormJobPayload } from '../types';

export const updateFormJob = createAsyncAction(
  `${moduleName}/UPDATE_FORM_JOB_REQUEST`,
  `${moduleName}/UPDATE_FORM_JOB_SUCCESS`,
  `${moduleName}/UPDATE_FORM_JOB_FAILURE`,
)<
  {
    formCode: string;
    jobsToUpdate: UpdateFormJobPayload[];
    successMessage?: string;
    finalActions?: RootAction[];
  },
  undefined,
  ApiError
>();
