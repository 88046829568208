import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';

import { approveOrDeclineAttendee } from '../../duck/actions';
import { isLoadingApprovalSel } from '../../duck/selectors';

import { ModalParams } from './types';

import { formStatus } from 'SHARED/constants';

type Props = {
  modalParams: ModalParams;
};

const ApproveApplicantModal: React.FC<Props> = ({
  modalParams: { formCode, formName, formRecordGUID, personGUID, firstName, lastName },
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingApprovalSel);

  const handleConfirm = () => {
    dispatch(
      approveOrDeclineAttendee.request({
        formCode,
        personGUID,
        formRecordGUID,
        statusCode: formStatus.registration_council_approved.code,
      }),
    );
  };

  return (
    <ModalComponent
      title={'Accept Applicant'}
      inProgress={isLoading}
      buttons={[
        {
          title: 'ACCEPT APPLICANT',
          onClick: handleConfirm,
          type: 'primary',
        },
      ]}
    >
      <p>
        <b>{`You are about to ACCEPT ${firstName} ${lastName} for ${formName}`}</b>
      </p>
    </ModalComponent>
  );
};

export default ApproveApplicantModal;
