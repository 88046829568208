import React from 'react';
import { AiFillWarning } from 'react-icons/ai';

import { WarningContainer } from './Warning.styled';

type Props = {
  errorMessage: string;
  className?: string;
  showWarningText?: boolean;
};

const Warning: React.FC<Props> = ({ errorMessage, showWarningText, className }) => (
  <WarningContainer className={className}>
    <AiFillWarning />
    {showWarningText && <b>WARNING:</b>} {errorMessage}
  </WarningContainer>
);

Warning.defaultProps = {
  errorMessage: '',
  showWarningText: true,
};

export default Warning;
