import React, { Fragment, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import Modal from '@/modules/modals';
import RouteSwitcher from '@/modules/routing/components/RouteSwitcher';
import ToastContainer from '@/modules/toasts/components/ToastContainer';
import AbilityContext, { defineAbilities } from '@/modules/user/duck/abilities';
import {
  isEventAdministratorSel,
  isSystemAdministratorSel,
  isLoggedInSel,
  loginDataSel,
} from '@/modules/user/duck/selectors';

import AppInit from '../AppInit';
import ErrorBoundary from '../ErrorBoundary';
import PageTitle from '../PageTitle';

import { GlobalStyle } from './App.styled';

const App: React.FC = () => {
  const isLoggedIn = useSelector(isLoggedInSel);
  const login = useSelector(loginDataSel);
  const formSettings = useSelector(createDataSel('form'));
  const isSystemAdministrator = useSelector(isSystemAdministratorSel);
  const isEventAdministrator = useSelector(isEventAdministratorSel);

  const ability = useMemo(
    () =>
      defineAbilities({
        isLoggedIn,
        personGuid: login?.personGuid,
        formSettings,
        isSystemAdministrator,
        isEventAdministrator,
      }),
    [isLoggedIn, login, formSettings, isSystemAdministrator, isEventAdministrator],
  );

  return (
    <Fragment>
      <GlobalStyle />
      <AppInit>
        <ErrorBoundary>
          <DndProvider backend={HTML5Backend}>
            <AbilityContext.Provider value={ability}>
              <Modal />
              <PageTitle />
              <ToastContainer />
              <RouteSwitcher />
            </AbilityContext.Provider>
          </DndProvider>
        </ErrorBoundary>
      </AppInit>
    </Fragment>
  );
};

export default App;
