import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  & div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const InfoLabelContainer = styled.div`
  display: flex;
`;

export const SessionLabel = styled.span`
  color: ${colors.darkGrey};
  font-size: 14px;
  font-weight: bold;
  margin-right: 4px;
`;

export const SessionLabelValue = styled(SessionLabel)`
  font-weight: normal;
  margin-right: 0;
`;
