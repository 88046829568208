/* eslint-disable */
export const moduleName = 'reservations';

export const RESERVATION_IN_PROGRESS = 'in_progress';
export const RESERVATION_IS_COMPLETE = 'is_complete';
export const RESERVATION_IS_CANCELLED = 'is_cancelled';
export const RESERVATION_NOT_CANCELLED = 'not_cancelled';

export const FILTER_CONTENT = 'filterContent';
const COLUMN_LIST = 'columnList';

const columnList = [
  'group_reservation_guid',
  'expedition_number',
  'reservation_contact_full_name',
  'start_date',
  'initial_roster_count',
  'amount_due',
  'amount_paid',
  'registration_count',
  'is_cancelled',
  'is_reservation_contact',
  'is_complete',
  'is_lead_advisor',
  'pending_reservation_list',
  'is_additional_reservation_contact',
];

export const DEFAULT_RESERVATION_LIST_FILTERS = {
  [COLUMN_LIST]: columnList.join(','),
};

export const DEFAULT_RESERVATION_LIST_FILTER_CONTENT = {
  [RESERVATION_IN_PROGRESS]: true,
  [RESERVATION_IS_COMPLETE]: true,
  [RESERVATION_NOT_CANCELLED]: true,
};

export const RESERVATION_LIST_FILTER_CONTENT_EXPRESSIONS = {
  [RESERVATION_IN_PROGRESS]: {
    ColumnName: RESERVATION_IS_COMPLETE, // NEGATIVE TO IS_COMPLETE, there is no "in_progress" status
    Operator: 'EQ',
    Value: 'FALSE',
  },
  [RESERVATION_IS_COMPLETE]: {
    ColumnName: RESERVATION_IS_COMPLETE,
    Operator: 'EQ',
    Value: 'TRUE',
  },
  [RESERVATION_IS_CANCELLED]: {
    ColumnName: RESERVATION_IS_CANCELLED,
    Operator: 'EQ',
    Value: 'TRUE',
  },
  [RESERVATION_NOT_CANCELLED]: {
    ColumnName: RESERVATION_IS_CANCELLED,
    Operator: 'EQ',
    Value: 'FALSE',
  },
};

export const FILTER_CHECKBOX = [
  {
    value: RESERVATION_IN_PROGRESS,
    label: 'In Progress',
  },
  {
    value: RESERVATION_IS_COMPLETE,
    label: 'Completed',
  },
  {
    value: RESERVATION_IS_CANCELLED,
    label: 'Cancelled',
  },
];
