import { createAsyncAction, RootAction } from 'typesafe-actions';

import { createModuleNamespaceName } from '@/modules/utils/misc';

import { moduleName } from '../constants';

const moduleNamespace = createModuleNamespaceName(moduleName);

export const confirmRegistration = createAsyncAction(
  `${moduleNamespace}/CONFIRM_REGISTRATION_REQUEST`,
  `${moduleNamespace}/CONFIRM_REGISTRATION_SUCCESS`,
  `${moduleNamespace}/CONFIRM_REGISTRATION_FAILURE`,
)<
  { formCode: string; formRecordGUID: string; sessionCode: string; finalActions?: RootAction[] },
  undefined,
  Error
>();
