import React from 'react';

import { usDate } from '@/modules/utils/dateFormats';

import { activitiesGroupDateFormat } from '../../constants';

import { Container } from './CollapseHeader.styled';

type Props = {
  startDate: string;
};

const CollapseHeader: React.FC<Props> = ({ startDate }) => {
  const formatDate = usDate(startDate, activitiesGroupDateFormat);

  return <Container>{formatDate}</Container>;
};

export default CollapseHeader;
