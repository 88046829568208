import React from 'react';
import { useSlate } from 'slate-react';

import { ElementType } from '../../../../constants';
import { isBlockActive, toggleBlock } from '../../../../utils';
import ToolbarButton from '../ToolbarButton';

type Props = {
  format: ElementType.orderedList | ElementType.unorderedList;
  Icon: React.ComponentType;
};

const BlockButton: React.FC<Props> = ({ format, Icon }) => {
  const editor = useSlate();
  const isActive = isBlockActive(editor, format);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleBlock(editor, format);
  };

  return <ToolbarButton isActive={isActive} Icon={Icon} onClick={handleClick} />;
};

export default BlockButton;
