import { WarningFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

import styles from './TotalAttendees.less';
import WarningTooltip from './components/WarningTooltip';

type Props = {
  numberOfAttendees: number;
  maximumCapacity: number;
  minimumCapacity: number;
  notEnoughAttendeeTypeNames: string[];
};

const TotalAttendees: React.FC<Props> = ({
  numberOfAttendees,
  maximumCapacity,
  minimumCapacity,
  notEnoughAttendeeTypeNames,
}) => {
  // eslint-disable-next-line react/no-multi-comp
  const tooltipContent = () => <WarningTooltip attendeeTypeNames={notEnoughAttendeeTypeNames} />;

  return (
    <div className={styles.actionBar}>
      {` Total: ${numberOfAttendees}/${maximumCapacity}`}
      {numberOfAttendees < minimumCapacity && (
        <Tooltip placement="right" title={tooltipContent}>
          <WarningFilled />
        </Tooltip>
      )}
    </div>
  );
};

export default TotalAttendees;
