import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { appInit } from './actions';

const appInitInProgress = createReducer(false)
  .handleAction(appInit.request, () => true)
  .handleAction([appInit.success, appInit.failure], () => false);

export default combineReducers({
  appInitInProgress,
});
