import dayjs from 'dayjs';
import { Route, redirect } from 'redux-first-router';
import { StateObservable } from 'redux-observable';
import { of, concat, Observable, EMPTY, merge, defer } from 'rxjs';
import { take, filter, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { removeData } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { SESSION_SORT_ORDER_FIELD } from '@/modules/entities/Sessions/constants';
import { getSessionSortParams } from '@/modules/entities/Sessions/utils';
import {
  urlFormRecordGUIDSel,
  urlGroupReservationGUIDSel,
  payloadSel,
  urlFormCodeSel,
  urlReservationCodeSel,
} from '@/modules/location/duck/selectors';
import { pageLoadCompleted } from '@/modules/routing/duck/actions';
import { isEADSel, isSystemAdministratorSel, personGuidSel } from '@/modules/user/duck/selectors';
import { dateFromString } from '@/modules/utils/dateFormats';
import { sliceTuple } from '@/modules/utils/typeUtils';

import { showLeadAdvisorInviteModal } from '@/pages/reservation/components/MyReservation/components/RostersList/duck/actions';
import { navigateToGroupReservationList } from '@/pages/reservationList/duck/actions';

import GroupReservationPage from './GroupReservationPage';
import IndividualReservationPage from './IndividualReservationPage';
import EditAddonsPage from './components/MyReservation/components/EditAddonsPage';
import EditActivitiesPage from './components/MyReservation/components/EditGroupReservationActivitiesPage';
import EditIndividualReservationActivitiesPage from './components/MyReservation/components/EditIndividualReservationActivitiesPage';
import EditIndividualReservationAddonsPage from './components/MyReservation/components/EditIndividualReservationAddonsPage';
import ReservationContactInviteValidation from './components/ReservationContactInviteValidation';
import {
  ROUTE_INDIVIDUAL_RESERVATION_PREFIX,
  ROUTE_GROUP_RESERVATION_PREFIX,
  ROUTE_RESERVATION_CONTACT_INVITE_VALIDATION,
  ROUTE_RESERVATION_EDIT_GROUP_ADDONS,
  ROUTE_RESERVATION_EDIT_GROUP_ACTIVITIES,
  ROUTE_RESERVATION_EDIT_INDIVIDUAL_ACTIVITIES,
  ROUTE_RESERVATION_EDIT_INDIVIDUAL_ADDONS,
  tabs,
  pageDataParams,
  ROUTE_ATTENDEE_DETAILS,
  TabKey,
} from './constants';
import {
  navigateToGroupReservationOverview,
  navigateToIndividualReservation,
} from './duck/actions';
import { pageDataSel } from './duck/selectors';
import services from './duck/services';
import { tabDataParams as attendeeDetailsTabDataParams } from './tabs/AttendeeDetails/constants';
import { tabDataParams as disclaimersTabDataParams } from './tabs/Disclaimers/constants';
import { tabDataParams as logsTabDataParams } from './tabs/Logs/constants';
import { ROSTERS_DATA_ID_TRANSFER_ATTENDEES } from './tabs/OverviewGroup/components/RosterPage/constants';
import { dropSelectedAttendees } from './tabs/OverviewGroup/components/RosterPage/duck/actions';
import { overviewGroupTabDataParams } from './tabs/OverviewGroup/constants';
import { overviewIndividualTabDataParams } from './tabs/OverviewIndividual/constants';
import { tabDataParams as paymentsTabDataParams } from './tabs/Payments/constants';

const INDIVIDUAL_RESERVATION_PATH_PREFIX = '/reservations/:formCode/individual/:formRecordGUID';
const GROUP_RESERVATION_PATH_PREFIX =
  '/reservations/:formCode/group/:groupReservationGUID/:formRecordGUID*';

const getFetchTabDataObservable = (
  action$: Observable<RootAction>,
  state$: StateObservable<RootState>,
  {
    key,
    isGroup,
    sessionCode,
  }: {
    key: TabKey;
    isGroup: boolean;
    sessionCode: string;
  },
): Observable<RootAction> => {
  const { data } = pageDataSel(state$.value);

  const {
    form: { formCode },
    attendeeDetails: { formRecordGUID, primaryRegistrantFormRecordGUID, personGUID },
  } = data;

  switch (key) {
    case TabKey.AttendeeDetails:
      return prefetchData$(
        action$,
        ...attendeeDetailsTabDataParams.map(params => {
          switch (params.dataType) {
            case 'arnicaPerson':
              return { ...params, queryObj: { personGUID } };
            case 'jobList':
              return { ...params, queryObj: { formCode } };
            case 'formRecordAttributes':
            case 'optionSets':
              return { ...params, queryObj: { formCode, formRecordGUID } };
            case 'formRecordActivities':
            case 'formRecordAddons':
              if (isGroup) return null;
              return { ...params, queryObj: { formCode, formRecordGUID } };
            case 'addons':
              return { ...params, queryObj: { formCode, sessionCode, checkPermissions: true } };
            case 'attributeList':
            case 'formDisclaimers':
            case 'jobDepartmentList':
            case 'visibilitySettings':
              return { ...params, queryObj: { formCode } };
            case 'activities':
              return { ...params, queryObj: { formCode, sessionCode } };
            case 'countries':
            case 'states':
            case 'councilList':
              return params;
          }
        }),
      );
    case TabKey.Disclaimers:
      if (isGroup) {
        const {
          groupReservation: { groupReservationGUID },
        } = data;

        return of(redirect(navigateToGroupReservationOverview({ formCode, groupReservationGUID })));
      }

      return prefetchData$(
        action$,
        ...disclaimersTabDataParams.map(params => {
          switch (params.dataType) {
            case 'formRecordDisclaimers':
              return {
                ...params,
                queryObj: {
                  formCode,
                  formRecordGUID,
                },
              };
          }
        }),
      );
    case TabKey.Logs:
      return prefetchData$(
        action$,
        ...logsTabDataParams.map(params => {
          switch (params.dataType) {
            case 'comments':
            case 'loggedActions':
              return {
                ...params,
                queryObj: {
                  formCode,
                  ...(isGroup
                    ? { groupReservationGUID: data.groupReservation.groupReservationGUID }
                    : { formRecordGUID }),
                },
              };
            case 'personEmails':
              return { ...params, queryObj: { formCode, personGUID } };
          }
        }),
      );
    case TabKey.Payment:
      return prefetchData$(
        action$,
        ...paymentsTabDataParams.map(params => {
          switch (params.dataType) {
            case 'paymentSummary':
            case 'paymentCategories':
              return {
                ...params,
                queryObj: {
                  formCode,
                  ...(isGroup
                    ? {
                        groupReservationGUID: data.groupReservation.groupReservationGUID,
                      }
                    : {
                        formRecordGUID,
                      }),
                },
              };
            case 'payments':
              return {
                ...params,
                queryObj: {
                  formCode,
                  ...(isGroup
                    ? {
                        groupReservationGUID: data.groupReservation.groupReservationGUID,
                      }
                    : {
                        formRecordGUID: primaryRegistrantFormRecordGUID || formRecordGUID,
                      }),
                },
              };
          }
        }),
      );
    case TabKey.Overview: {
      const formRecordGUIDUrl = urlFormRecordGUIDSel(state$.value);

      if (isGroup) {
        const {
          groupReservation: { groupReservationGUID },
        } = data;
        const { rosterCodeDisplayed } = payloadSel(state$.value) as ReturnType<
          typeof navigateToGroupReservationOverview
        >['payload'];

        return prefetchData$(
          action$,
          ...overviewGroupTabDataParams.map(params => {
            switch (params.dataType) {
              case 'formDisclaimers':
                return { ...params, queryObj: { formCode } };
              case 'groupReservationRosterList':
                return { ...params, queryObj: { formCode, groupReservationGUID, sessionCode } };
              case 'groupReservationAddons':
                return { ...params, queryObj: { formCode, groupReservationGUID } };
              case 'groupReservationActivities':
                return { ...params, queryObj: { formCode, groupReservationGUID }, fetchData: true };
              case 'rosterAttendees':
              case 'rosterDetails':
                if (!rosterCodeDisplayed) return null;
                return { ...params, queryObj: { formCode, rosterCode: rosterCodeDisplayed } };
            }
          }),
        );
      }

      const individualFormRecordGuid = primaryRegistrantFormRecordGUID || formRecordGUIDUrl;

      return concat(
        prefetchData$(action$, {
          dataType: 'attendeeDetails',
          dataId: 'primaryAttendee',
          queryObj: {
            formRecordGUID: individualFormRecordGuid,
            hideNonVisibleItems: true,
          },
        }),
        prefetchData$(
          action$,
          ...overviewIndividualTabDataParams.map(params => {
            switch (params.dataType) {
              case 'allDisclaimersAccepted':
              case 'formRecordAddons':
              case 'formRecordActivities':
                return {
                  ...params,
                  queryObj: { formCode, formRecordGUID: individualFormRecordGuid },
                };
              case 'attendeeList':
                return {
                  ...params,
                  queryObj: {
                    formCode,
                    primaryRegistrantFormRecordGUID: individualFormRecordGuid,
                    isPrimaryRegistrant: false,
                  },
                };
            }
          }),
        ),
      );
    }
  }
};

const individualReservationRoutes = tabs.reduce(
  (acc, { key }) => ({
    ...acc,
    [`${ROUTE_INDIVIDUAL_RESERVATION_PREFIX}/${key}`]: {
      path: `${INDIVIDUAL_RESERVATION_PATH_PREFIX}/${key}`,
      component: IndividualReservationPage,
      titleId: 'page.reservation',
      getObservable: (
        action$: Observable<RootAction>,
        state$: StateObservable<RootState>,
      ): Observable<RootAction> => {
        const formCode = urlFormCodeSel(state$.value);
        const formRecordGUID = urlFormRecordGUIDSel(state$.value);
        const personGUID = personGuidSel(state$.value);
        const pageDataParams1 = sliceTuple(pageDataParams, 0, 9);
        const pageDataParams2 = sliceTuple(pageDataParams, 9, pageDataParams.length);
        return concat(
          prefetchData$(
            action$,
            ...pageDataParams1.map(params => {
              switch (params.dataType) {
                case 'form':
                case 'attendeeTypeList':
                case 'jobList':
                case 'ledgerAccounts':
                  return { ...params, queryObj: { formCode } };
                case 'paymentSummary':
                case 'paymentCategories':
                case 'comments':
                  return { ...params, queryObj: { formCode, formRecordGUID } };
                case 'attendeeDetails':
                  return { ...params, queryObj: { formRecordGUID, hideNonVisibleItems: true } };
                case 'groupReservation':
                  return null;
              }
            }),
          ),
          defer(() => {
            const {
              data: {
                form: { sessionsListSortType = '' },
                attendeeDetails: { sessionCode },
              },
            } = pageDataSel(state$.value);

            return concat(
              prefetchData$(
                action$,
                ...pageDataParams2.map(params => {
                  switch (params.dataType) {
                    case 'formRecordJobs':
                      return {
                        ...params,
                        queryObj: { formCode, formRecordGUID, personGUID },
                      };
                    case 'session':
                      return { ...params, queryObj: { formCode, sessionCode } };
                    case 'sessionList':
                      return {
                        ...params,
                        queryObj: {
                          formCode,
                          hidePastSessions: false,
                          hideFullSessions: true,
                          [SESSION_SORT_ORDER_FIELD]: getSessionSortParams(sessionsListSortType),
                        },
                      };
                  }
                }),
              ),
              defer(() =>
                getFetchTabDataObservable(action$, state$, { isGroup: false, key, sessionCode }),
              ),
            );
          }),
        );
      },
    },
  }),
  {} as Record<string, Route>,
);

const groupReservationRoutes = tabs.reduce(
  (acc, { key }) => ({
    ...acc,
    [`${ROUTE_GROUP_RESERVATION_PREFIX}/${key}`]: {
      path: `${GROUP_RESERVATION_PATH_PREFIX}/${key}`,
      component: GroupReservationPage,
      titleId: 'page.groupDetails',
      getObservable: (
        action$: Observable<RootAction>,
        state$: StateObservable<RootState>,
      ): Observable<RootAction> => {
        const state = state$.value;
        const formCode = urlFormCodeSel(state);
        const personGUID = personGuidSel(state);
        const groupReservationGUID = urlGroupReservationGUIDSel(state);
        const { keepRosterOpened } = payloadSel(state) as ReturnType<
          typeof navigateToGroupReservationOverview
        >['payload'];

        const pageDataParams1 = sliceTuple(pageDataParams, 0, 8);
        const pageDataParams2 = sliceTuple(pageDataParams, 8, pageDataParams.length);

        return concat(
          of(
            dropSelectedAttendees(),
            removeData({ dataType: 'rosterList', dataId: ROSTERS_DATA_ID_TRANSFER_ATTENDEES }),
            removeData({ dataType: 'groupReservationRosterList' }),
          ),
          keepRosterOpened
            ? EMPTY
            : of(
                removeData({ dataType: 'rosterDetails' }),
                removeData({ dataType: 'rosterAttendees' }),
              ),
          prefetchData$(
            action$,
            ...pageDataParams1.map(params => {
              switch (params.dataType) {
                case 'form':
                case 'attendeeTypeList':
                case 'jobList':
                case 'ledgerAccounts':
                  return { ...params, queryObj: { formCode } };
                case 'paymentCategories':
                case 'paymentSummary':
                case 'groupReservation':
                case 'comments':
                  return { ...params, queryObj: { formCode, groupReservationGUID } };
              }
            }),
          ),
          defer(() => {
            const {
              data: {
                form: { isCreator, isCollaborator, sessionsListSortType = '' },
                groupReservation: {
                  isReservationContact,
                  sessionCode,
                  reservationContact,
                  additionalReservationContact,
                  isLeadAdvisor,
                  isCancelled,
                  sessionStartDate = '',
                  isAdditionalReservationContact,
                  councilNumber,
                },
              },
            } = pageDataSel(state$.value);

            const isEAD = isEADSel(state$.value, { councilNumber });
            const isSystemAdministrator = isSystemAdministratorSel(state$.value);
            const formRecordGUIDUrl = urlFormRecordGUIDSel(state$.value);

            const currentFormRecordGUID =
              formRecordGUIDUrl ||
              reservationContact.formRecordGUID ||
              additionalReservationContact.formRecordGUID;

            if (
              !isCreator &&
              !isCollaborator &&
              !isReservationContact &&
              !isLeadAdvisor &&
              !isSystemAdministrator &&
              !isEAD &&
              !isAdditionalReservationContact
            ) {
              return merge(
                of(redirect(navigateToGroupReservationList({ formCode }))),
                action$.pipe(filter(isActionOf(pageLoadCompleted)), take(1)),
              );
            }

            const finalActions = [];
            let isAfterRequiredDate = false;

            if (sessionStartDate) {
              const registrationDate = dateFromString(sessionStartDate).subtract(120, 'days');
              isAfterRequiredDate = dayjs().isSameOrAfter(registrationDate, 'day');
            }

            if (!isCancelled && isAfterRequiredDate) {
              finalActions.push(of(showLeadAdvisorInviteModal()));
            }

            return concat(
              prefetchData$(
                action$,
                ...pageDataParams2.map(params => {
                  switch (params.dataType) {
                    case 'attendeeDetails':
                      return {
                        ...params,
                        queryObj: {
                          formRecordGUID: currentFormRecordGUID,
                          hideNonVisibleItems: true,
                        },
                      };
                    case 'formRecordJobs':
                      return {
                        ...params,
                        queryObj: {
                          formCode,
                          formRecordGUID: currentFormRecordGUID,
                          personGUID,
                        },
                      };
                    case 'session':
                      return {
                        ...params,
                        queryObj: {
                          formCode,
                          sessionCode,
                        },
                      };
                    case 'sessionList':
                      return {
                        ...params,
                        queryObj: {
                          formCode,
                          hidePastSessions: false,
                          hideFullSessions: true,
                          [SESSION_SORT_ORDER_FIELD]: getSessionSortParams(sessionsListSortType),
                        },
                      };
                  }
                }),
              ),
              defer(() =>
                getFetchTabDataObservable(action$, state$, { key, isGroup: true, sessionCode }),
              ),
              ...finalActions,
            );
          }),
        );
      },
    },
  }),
  {} as Record<string, Route>,
);

export default {
  ...individualReservationRoutes,
  ...groupReservationRoutes,

  [ROUTE_RESERVATION_CONTACT_INVITE_VALIDATION]: {
    path: '/reservations/:formCode/group/:groupReservationGUID/rc-invite/:contextToken',
    component: ReservationContactInviteValidation,
    titleId: 'page.edit',
    disableBreadcrumbs: true,
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const state = state$.value;
      const formCode = urlFormCodeSel(state);
      const groupReservationGUID = urlGroupReservationGUIDSel(state);

      return prefetchData$(
        action$,
        { dataType: 'groupReservation', queryObj: { formCode, groupReservationGUID } },
        { dataType: 'form', queryObj: { formCode } },
      );
    },
  },

  [ROUTE_RESERVATION_EDIT_INDIVIDUAL_ACTIVITIES]: {
    path: '/reservations/:formCode/individual/:formRecordGUID/activities',
    component: EditIndividualReservationActivitiesPage,
    titleId: 'page.edit',
    disableBreadcrumbs: true,
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const state = state$.value;
      const formCode = urlFormCodeSel(state);
      const formRecordGUID = urlFormRecordGUIDSel(state);

      return concat(
        prefetchData$(
          action$,
          { dataType: 'formRecordActivities', queryObj: { formCode, formRecordGUID } },
          { dataType: 'activities', queryObj: { formCode, formRecordGUID } },
          { dataType: 'attendeeDetails', queryObj: { formCode, formRecordGUID } },
          { dataType: 'form', queryObj: { formCode } },
        ),
        defer(() => {
          const nextState = state$.value;
          const { sessionCode } = createDataSel('attendeeDetails')(nextState);

          return prefetchData$(
            action$,
            { dataType: 'activities', queryObj: { formCode, sessionCode } },
            {
              dataType: 'sessionList',
              queryObj: {
                formCode,
                sessionCode,
                hideFullSessions: true,
                hidePastSessions: false,
              },
            },
          );
        }),
      );
    },
  },
  [ROUTE_RESERVATION_EDIT_INDIVIDUAL_ADDONS]: {
    path: '/reservations/:formCode/individual/:formRecordGUID/addons',
    component: EditIndividualReservationAddonsPage,
    titleId: 'page.edit',
    disableBreadcrumbs: true,
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const state = state$.value;
      const formCode = urlFormCodeSel(state);
      const formRecordGUID = urlFormRecordGUIDSel(state);

      return concat(
        prefetchData$(
          action$,
          { dataType: 'formRecordAddons', queryObj: { formCode, formRecordGUID } },
          { dataType: 'attendeeDetails', queryObj: { formCode, formRecordGUID } },
          { dataType: 'form', queryObj: { formCode } },
          { dataType: 'addons', queryObj: { formCode } },
        ),
        defer(() => {
          const { sessionCode } = createDataSel('attendeeDetails')(state$.value);

          return prefetchData$(
            action$,
            {
              dataType: 'session',
              queryObj: { formCode, sessionCode },
            },
            { dataType: 'addons', queryObj: { formCode, sessionCode } },
          );
        }),
      );
    },
  },
  [ROUTE_RESERVATION_EDIT_GROUP_ADDONS]: {
    path: '/reservations/:formCode/group/:groupReservationGUID/addons',
    component: EditAddonsPage,
    titleId: 'page.edit',
    disableBreadcrumbs: true,
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const state = state$.value;
      const formCode = urlFormCodeSel(state);
      const groupReservationGUID = urlGroupReservationGUIDSel(state);

      return concat(
        prefetchData$(
          action$,
          { dataType: 'groupReservation', queryObj: { formCode, groupReservationGUID } },
          { dataType: 'groupReservationAddons', queryObj: { formCode, groupReservationGUID } },
          { dataType: 'addons', queryObj: { formCode } },
          { dataType: 'paymentSummary', queryObj: { formCode, groupReservationGUID } },
          { dataType: 'form', queryObj: { formCode } },
        ),
        defer(() => {
          const { sessionCode } = createDataSel('groupReservation')(state$.value);

          return prefetchData$(action$, {
            dataType: 'session',
            queryObj: { formCode, sessionCode },
          });
        }),
      );
    },
  },
  [ROUTE_RESERVATION_EDIT_GROUP_ACTIVITIES]: {
    path: '/reservations/:formCode/group/:groupReservationGUID/activities',
    component: EditActivitiesPage,
    titleId: 'page.edit',
    disableBreadcrumbs: true,
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const state = state$.value;
      const formCode = urlFormCodeSel(state);
      const groupReservationGUID = urlGroupReservationGUIDSel(state);

      return concat(
        prefetchData$(
          action$,
          { dataType: 'groupReservation', queryObj: { formCode, groupReservationGUID } },
          { dataType: 'groupReservationActivities', queryObj: { formCode, groupReservationGUID } },
          { dataType: 'paymentSummary', queryObj: { formCode, groupReservationGUID } },
          { dataType: 'form', queryObj: { formCode } },
        ),
        defer(() => {
          const nextState = state$.value;
          const { sessionCode } = createDataSel('groupReservation')(nextState);

          return prefetchData$(
            action$,
            { dataType: 'activities', queryObj: { formCode, sessionCode } },
            {
              dataType: 'sessionList',
              queryObj: {
                formCode,
                sessionCode,
                isIncludeAttendeeTypes: false,
                includeAllFields: false,
              },
            },
          );
        }),
      );
    },
  },

  // deprecated
  [ROUTE_GROUP_RESERVATION_PREFIX]: {
    path: GROUP_RESERVATION_PATH_PREFIX,
    titleId: 'page.groupDetails',
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const payload = payloadSel(state$.value);
      return merge(
        action$.pipe(filter(isActionOf(pageLoadCompleted)), take(1)),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        of(redirect(navigateToGroupReservationOverview(payload as any))),
      );
    },
  },

  // deprecated
  [ROUTE_INDIVIDUAL_RESERVATION_PREFIX]: {
    path: INDIVIDUAL_RESERVATION_PATH_PREFIX,
    titleId: 'page.reservation',
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const payload = payloadSel(state$.value);
      return merge(
        action$.pipe(filter(isActionOf(pageLoadCompleted)), take(1)),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        of(redirect(navigateToIndividualReservation(payload as any))),
      );
    },
  },

  //Deprecated
  [ROUTE_ATTENDEE_DETAILS]: {
    path: '/events/:formCode/attendees/:reservationCode',
    component: IndividualReservationPage,
    titleId: 'page.event',
    disableBreadcrumbs: true,
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const formCode = urlFormCodeSel(state$.value);
      const reservationCode = urlReservationCodeSel(state$.value);

      return services.getFormRecordGuid$(formCode, reservationCode).pipe(
        switchMap(({ responseValue: formRecordGUID }) =>
          concat(
            prefetchData$(
              action$,
              { dataType: 'form', queryObj: { formCode } },
              {
                dataType: 'attendeeDetails',
                queryObj: { formRecordGUID, hideNonVisibleItems: true, fetchData: true },
              },
            ),
            defer(() => {
              const nextState = state$.value;
              const { groupReservationGUID } = createDataSel('attendeeDetails')(nextState);
              const { allowGroupRegistration } = createDataSel('form')(nextState);

              if (allowGroupRegistration) {
                return merge(
                  action$.pipe(filter(isActionOf(pageLoadCompleted)), take(1)),
                  of(
                    redirect(
                      navigateToGroupReservationOverview({
                        formCode,
                        groupReservationGUID,
                        formRecordGUID,
                      }),
                    ),
                  ),
                );
              }

              return merge(
                action$.pipe(filter(isActionOf(pageLoadCompleted)), take(1)),
                of(
                  redirect(
                    navigateToIndividualReservation({
                      formCode,
                      formRecordGUID,
                    }),
                  ),
                ),
              );
            }),
          ),
        ),
      );
    },
  },
};
