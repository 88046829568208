import { Select as AntdSelect, Typography } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  width: 100%;

  & div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const OptionItemLabel = styled(Typography)`
  white-space: normal;
`;

export const Select = styled(AntdSelect)`
  width: 288px;

  & article {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & span.ant-select-arrow {
    height: 8px;
  }
` as typeof AntdSelect;

export const SelectContainer = styled.div`
  & > label {
    margin-right: 16px;
  }
`;
