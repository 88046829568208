import { Popover } from 'antd';
import React, { useEffect, PropsWithChildren } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from './NoticePopover.less';
import NoticeContent from './components/NoticeContent';

import { clearNotice } from 'NOTIFICATIONS/duck/actions';
import { noticeSel } from 'NOTIFICATIONS/duck/selectors';

const NoticePopover: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();

  const notice = useSelector(noticeSel);

  useEffect(() => {
    if (notice) {
      const element = document.getElementById('app');
      element?.scroll({ top: 0, behavior: 'smooth' });
    }
  }, [notice]);

  const handleNoticeAck = () => {
    dispatch(clearNotice());
  };

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      content={notice && <NoticeContent notice={notice} onClick={handleNoticeAck} />}
      open={!!notice}
      overlayClassName={styles.popover}
      arrow
    >
      {children}
    </Popover>
  );
};

export default NoticePopover;
