import { StateObservable } from 'redux-observable';
import { of, concat, Observable } from 'rxjs';
import { RootAction, RootState } from 'typesafe-actions';

import { resetFilters, resetPagination, setFilter } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import {
  DEFAULT_RESERVATION_LIST_FILTERS,
  FILTER_CONTENT,
  DEFAULT_RESERVATION_LIST_FILTER_CONTENT,
} from '@/modules/entities/Reservations/constants';
import { getFilterContentString } from '@/modules/entities/Reservations/utils';
import { urlFormCodeSel } from '@/modules/location/duck/selectors';

import ReservationList from './ReservationList';
import { ROUTE_GROUP_RESERVATION_LIST, pageDataParams } from './constants';

export default {
  [ROUTE_GROUP_RESERVATION_LIST]: {
    path: '/reservations/:formCode/list/',
    component: ReservationList,
    titleId: 'page.reservationList',
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const state = state$.value;
      const formCode = urlFormCodeSel(state);
      return concat(
        of(
          resetFilters({ dataType: 'reservationListReport', fetchData: false }),
          resetPagination({ dataType: 'reservationListReport', fetchData: false }),
          ...(Object.entries(DEFAULT_RESERVATION_LIST_FILTERS).map(([key, value]) =>
            setFilter({ dataType: 'reservationListReport', key, value }),
          ) as RootAction[]),
          setFilter({
            dataType: 'reservationListReport',
            key: FILTER_CONTENT,
            value: getFilterContentString(DEFAULT_RESERVATION_LIST_FILTER_CONTENT),
          }),
        ),
        prefetchData$(
          action$,
          ...pageDataParams.map(params => {
            switch (params.dataType) {
              case 'form':
                return { ...params, queryObj: { formCode } };
              case 'reservationListReport':
                return { ...params, queryObj: { formCode }, fetchData: true };
            }
          }),
        ),
      );
    },
  },
};
