import { FormSettings } from '@/modules/data/dataTypes/form';
import { Person } from '@/modules/data/dataTypes/person';
import { PermissionCode } from '@/modules/entities/Roles/constants';
import { hasAssignedPermissionWithCode } from '@/modules/user/duck/utils';

import { TabKey } from './constants';

export const isTabAllowed = (
  tabKey: TabKey,
  isSystemAdministrator: boolean,
  isEventAdministrator: boolean,
  isEAD: boolean,
  currentPerson: Person,
  form: FormSettings,
): boolean => {
  const { allowGroupRegistration, isCreator, isCollaborator, isHiringManager } = form;
  const hasPermission = (permissionCode: string) =>
    hasAssignedPermissionWithCode([...currentPerson.roles, ...form.assignedRoles], permissionCode);

  switch (tabKey) {
    case TabKey.OVERVIEW:
      return isCreator || isCollaborator || isSystemAdministrator || isHiringManager || isEAD;
    case TabKey.RESERVATIONS:
      return (
        allowGroupRegistration &&
        (isCreator ||
          isHiringManager ||
          isSystemAdministrator ||
          isEAD ||
          (isCollaborator && hasPermission(PermissionCode.QueryFilterExecuteReportsAccess)))
      );
    case TabKey.ATTENDEES:
      return (
        isCreator ||
        isSystemAdministrator ||
        isEAD ||
        isHiringManager ||
        (isCollaborator && hasPermission(PermissionCode.AttendeesTabReadAccess))
      );
    case TabKey.ACTIVITIES:
      return isCreator || isCollaborator || isSystemAdministrator;
    case TabKey.TRANSACTIONS:
      return (
        isSystemAdministrator ||
        isEventAdministrator ||
        isCreator ||
        (isCollaborator && hasPermission(PermissionCode.TransactionsReadAccess))
      );
    case TabKey.SESSIONS:
      return isCreator || isCollaborator || isSystemAdministrator;
    case TabKey.EMAILS:
      return isCreator || isSystemAdministrator || hasPermission(PermissionCode.EmailsManageAccess);
    case TabKey.ROSTERS:
      return (
        (isCreator || isCollaborator || isSystemAdministrator || isHiringManager || isEAD) &&
        allowGroupRegistration
      );
  }
};
