import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

import InputWrapperShared from '../InputWrapper';

const borderStyles = css`
  border-color: ${colors.gray_4};
  transition: border-color 0.5s;
`;

export const InputWrapper = styled(InputWrapperShared)`
  /* height: 45px; */

  &&& {
    .flag-dropdown {
      ${borderStyles}
    }
    input {
      height: 40px;
      width: 100%;
      ${borderStyles}
    }

    :hover {
      .flag-dropdown {
        border-color: ${colors.scoutingBlue};
      }

      input {
        border-color: ${colors.scoutingBlue};
      }
    }
  }
`;
