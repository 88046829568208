import { map, truncate as _truncate } from 'lodash';
import React from 'react';

import type { EventLog } from '@/modules/data/dataTypes/eventLogs';

import { Li, Strong, Code } from './UpdateDescription.styled';

const MAX_CHARS = 50;
const truncate = (str: string) => _truncate(str, { length: MAX_CHARS });

type Props = {
  eventLog: EventLog;
};

const UpdateDescription: React.FC<Props> = ({ eventLog }) => {
  const changesLst = eventLog.compareResult;
  const changedItems = map(changesLst, (changes, attribute) => {
    const from = changes.old || "''";
    const to = changes.new || "''";

    return (
      <Li key={`change-${attribute}`}>
        <Strong>{attribute}</Strong> from <Code>{truncate(from)}</Code> to{' '}
        <code>{truncate(to)}</code>.
      </Li>
    );
  });

  if (changedItems.length === 1)
    return (
      <p>
        <Strong>Updated</Strong> {changedItems[0].props.children}
      </p>
    );

  return (
    <div>
      <Strong>Updated:</Strong>
      <ul>{changedItems}</ul>
    </div>
  );
};

export default UpdateDescription;
