import dayjs from 'dayjs';
import get from 'lodash/get';
import { Epic, combineEpics } from 'redux-observable';
import { of, concat, merge, TimeoutError, iif, race, defer } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { isActionOf, RootAction } from 'typesafe-actions';

import { addDataItem } from '@/modules/data/duck/actions';
import { closeModal } from '@/modules/modals/duck/actions';
import { OfflinePaymentResponse, OfflinePaymentPayload } from '@/modules/payments/types';
import toastService from '@/modules/toasts/service';
import { personGuidSel } from '@/modules/user/duck/selectors';
import { ApiError } from '@/modules/utils/apiService';

import { openNextStep, stopRegistrationTimer } from '@/pages/createReservation/duck/actions';

import { transactionTypes } from '../constants';

import {
  makeRefund,
  makeAdjustmentPayment,
  changePaymentStatus,
  makeOfflinePayment,
  initOrbitalPayment,
  completeOrbitalPayment,
} from './actions';
import services from './services';

import { SERVER_DATE_FORMAT } from 'UTILS/dateFormats';

const makeRefund$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(makeRefund.request)),
    switchMap(({ payload }) =>
      services.makeRefund$(payload).pipe(
        switchMap(() => {
          toastService.success('Refund posted successfully');
          return of(closeModal(), makeRefund.success());
        }),
        catchError((e: Error) => {
          toastService.error(e.message);
          return of(makeRefund.failure(e));
        }),
      ),
    ),
  );

const processInitOrbitalPayment$: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(initOrbitalPayment.request)),
    switchMap(({ payload }) => {
      const state = store$.value;
      const payerPersonGUID = personGuidSel(state);

      return services
        .initOrbitalTransaction$({ ...payload, payerPersonGUID, orderDesc: 'Event Payment' })
        .pipe(
          switchMap(uIDAndSessionId => of(initOrbitalPayment.success(uIDAndSessionId))),
          catchError(err => {
            toastService.error(get(err, ['message'], err));
            return of(initOrbitalPayment.failure(get(err, ['message'], err)));
          }),
        );
    }),
  );

// this will be called AFTER the iframe return the complete callback
const processOrbitalContinued$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(completeOrbitalPayment.request)),
    switchMap(({ payload }) => {
      const { onPaymentSucceeded, ...completeTransInfo } = payload;
      const state = state$.value;
      const payerPersonGUID = personGuidSel(state) as string;
      return merge(
        of(stopRegistrationTimer()),
        services
          .completeOrbitalPayment$({
            ...completeTransInfo,
            payerPersonGUID,
          })
          .pipe(
            switchMap(({ uID, amount }) => {
              const creationDate = dayjs();

              onPaymentSucceeded && onPaymentSucceeded();
              toastService.success('Payment Completed');

              return of(
                completeOrbitalPayment.success({
                  uID,
                  amount,
                  creationDate,
                }),
              );
            }),
            catchError((err: ApiError) => {
              if (err instanceof TimeoutError) {
                return of(completeOrbitalPayment.failure('TIMEOUT'), openNextStep());
              }

              toastService.error(err.message);
              return of(
                completeOrbitalPayment.failure(
                  get(err, ['message'], 'An unexpected API error just happened'),
                ),
              );
            }),
          ),
      );
    }),
  );

const processAdjustmentPayment$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(makeAdjustmentPayment.request)),
    switchMap(({ payload }) => {
      const {
        onPaymentSucceeded,
        transactionTypeCode,
        paymentType,
        comments,
        postedDate,
        ...rest
      } = payload;

      const { label, direction } = transactionTypes[transactionTypeCode];

      const commentsWithAdjustmentLabel = `${label} - ${comments}`;
      const now = dayjs().format(SERVER_DATE_FORMAT);

      return concat(
        merge(
          race(
            action$.pipe(
              filter(isActionOf([makeOfflinePayment.success, makeRefund.success])),
              take(1),
            ),
            action$.pipe(
              filter(isActionOf([makeOfflinePayment.failure, makeRefund.failure])),
              take(1),
              switchMap(({ payload: error }) => {
                throw error;
              }),
            ),
          ),
          iif(
            () => direction === '+',
            of(
              makeOfflinePayment.request({
                ...rest,
                paymentType,
                comments: commentsWithAdjustmentLabel,
                postedDate: postedDate || now,
                transactionTypeCode,
              }),
            ),
            of(
              makeRefund.request({
                ...rest,
                refundReason: commentsWithAdjustmentLabel,
                paymentDate: postedDate || now,
                transactionTypeCode,
                sessionId: '',
              }),
            ),
          ),
        ),
        defer(() => {
          onPaymentSucceeded && onPaymentSucceeded();
          return of(makeAdjustmentPayment.success());
        }),
      );
    }),
    catchError((e: Error, caught) => {
      toastService.error('Failed to adjust payments');
      return merge(of(makeAdjustmentPayment.failure(e)), caught);
    }),
  );

const changePaymentStatus$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(changePaymentStatus.request)),
    switchMap(({ payload: { formCode, paymentRecord, statusCode } }) => {
      const { sessionId } = paymentRecord;

      return services
        .changePaymentStatus$({
          formCode,
          sessionId,
          statusCode,
        })
        .pipe(
          map(() => changePaymentStatus.success()),
          catchError((err: ApiError) => {
            toastService.error(err.message);
            return of(changePaymentStatus.failure(err));
          }),
        );
    }),
  );

const processOfflinePayment$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(makeOfflinePayment.request)),
    switchMap(
      ({ payload: { onPaymentSucceeded, ...payload } }: { payload: OfflinePaymentPayload }) => {
        const payerPersonGUID = personGuidSel(state$.value);
        return services.processOfflinePayment$({ ...payload, payerPersonGUID }).pipe(
          switchMap(({ orderId, sessionId }: OfflinePaymentResponse) => {
            const payment = {
              orderID: orderId,
              sessionId,
              payerPersonGUID,
              creationDate: payload.postedDate,
              orderDesc: 'Event Payment Offline',
              status: 'Completed',
              personGUID: payload.personGUID || '',
              ...payload,
            };
            toastService.success('Payment posted Successfully');
            onPaymentSucceeded && onPaymentSucceeded();
            return of(
              addDataItem({ dataType: 'formPaymentData', dataItem: payment }),
              makeOfflinePayment.success(),
            );
          }),
          catchError(e => {
            toastService.error(e.message);
            return of(makeOfflinePayment.failure(e.message));
          }),
        );
      },
    ),
  );

export default combineEpics(
  makeRefund$,
  changePaymentStatus$,
  processInitOrbitalPayment$,
  processOrbitalContinued$,
  processAdjustmentPayment$,
  processOfflinePayment$,
);
