import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { QueryFilterVariable } from '@/modules/data/dataTypes/queryFilterVariableList';
import { createDataSel } from '@/modules/data/duck/selectors';

import { moduleName } from '../constants';
import { ReportDataType } from '../types';
import { getReportDataId } from '../utils';

const moduleSel = (state: RootState) => state[moduleName];

type Props = {
  dataType: ReportDataType;
};

const dataTypeSel = (_state: RootState, { dataType }: Props) => dataType;

export const activeTabKeySel = createSelector(
  [moduleSel, dataTypeSel],
  ({ activeTabKey }, dataType) => activeTabKey[dataType],
);

export const formCodeSel = createSelector(moduleSel, ({ formCode }) => formCode);

export const tabIsLoadingSel = createSelector(moduleSel, ({ tabIsLoading }) => tabIsLoading);

export const queryFilterVariablesSel = (state: RootState, props: Props) => {
  const dataType = dataTypeSel(state, props);
  const dataId = getReportDataId(dataType);
  const variables = createDataSel('queryFilterVariables', dataId)(state);
  return sortBy(variables, 'caption');
};

export const queryFilterVariablesForReportSel = createSelector(queryFilterVariablesSel, variables =>
  variables.filter(({ isAvailableForReport }) => isAvailableForReport),
);

export const queryFiltersSel = (state: RootState, props: Props) => {
  const dataType = dataTypeSel(state, props);
  const dataId = getReportDataId(dataType);
  const queryFilters = createDataSel('queryFilters', dataId)(state);
  return queryFilters;
};

export const optionSetsSel = (state: RootState) => {
  const optionSets = createDataSel('optionSets')(state);
  return optionSets;
};

export const emailTemplatesSel = (state: RootState, props: Props) => {
  const dataType = dataTypeSel(state, props);
  const dataId = getReportDataId(dataType);
  return createDataSel('emailTemplateList', dataId)(state);
};

export const queryFilterVariablesObjSel = createSelector(
  queryFilterVariablesSel,
  (queryFilterVariables = []): Record<string, QueryFilterVariable> =>
    queryFilterVariables.reduce((acc, qfv) => ({ ...acc, [qfv.value]: qfv }), {}),
);

export const updateReportPermissionsInProgressSel = createSelector(
  moduleSel,
  ({ updateReportPermissionsInProgress }) => updateReportPermissionsInProgress,
);
