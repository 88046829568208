import React from 'react';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { ADDED_WITHOUT_INVITATION } from '@/modules/shared/components/InvitationStatusCell/constants';
import List, { ListItem } from '@/modules/shared/components/List';

import DisclaimerStatus from '@/pages/reservation/tabs/OverviewGroup/components/RosterPage/components/DisclaimerStatus';

import { GroupedAttendees } from '../../types';
import AttendeeAge from '../AttendeeAge';
import GroupHeader from '../GroupHeader';

import InvitationStatusCell from 'SHARED/components/InvitationStatusCell';

type Props = {
  groupedAttendees: GroupedAttendees;
  reservationDetails: GroupReservation;
  personGuid: string;
  selectedAttendees: Set<string>;
  eventHasDisclaimers: boolean;
  onSelect: (selectedIds: Set<string>) => void;
};

const AttendeesList: React.FC<Props> = ({
  groupedAttendees,
  reservationDetails,
  personGuid,
  eventHasDisclaimers,
  onSelect,
  selectedAttendees,
}) => (
  <List
    dataSource={groupedAttendees}
    renderListItem={record => {
      if ('isGroupHeader' in record) {
        return <ListItem isHeader content={<GroupHeader {...record} />} />;
      }

      const { invitationStatus, isInvitationSent } = record;

      return (
        <ListItem
          title={record.fullName}
          record={record}
          rowKey="memberId"
          rows={[
            {
              title: 'Age',
              content: <AttendeeAge reservationDetails={reservationDetails} attendee={record} />,
            },
            {
              title: 'Invitation Status',
              content: (
                <InvitationStatusCell
                  statusName={isInvitationSent ? invitationStatus : ADDED_WITHOUT_INVITATION}
                />
              ),
            },
            <InvitationStatusCell
              key="invitationStatusCell"
              statusName={invitationStatus}
              showStatus={false}
            />,
            eventHasDisclaimers
              ? {
                  title: 'Disclaimer Status',
                  content: <DisclaimerStatus attendeeRecord={record} />,
                }
              : null,
          ]}
          {...(personGuid === record.personGUID
            ? {}
            : {
                onSelect,
                selectedIds: selectedAttendees,
              })}
        />
      );
    }}
  />
);

export default AttendeesList;
