import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { isUndefined } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { getPaymentAmount, getTotalBalanceDue } from '@/modules/entities/Payments/utils';
import { PaymentMethod, PaymentMode } from '@/modules/payments/constants';
import InputMoney from '@/modules/shared/components/InputMoney';

import MaxAmountInfoBubble from '../../../../../../modules/entities/Payments/components/MaxAmountInfoBubble';

import CSS from './PaymentAmountPicker.less';

const RadioGroup = Radio.Group;

type Props = {
  paymentSummary: PaymentSummary;
  nextFutureDueFee: number;
  pastDueFees: number;
  paymentMode: PaymentMode;
  paymentMethod: PaymentMethod | null;
  customAmount: number;
  onAmountChange: (amount: number) => void;
  onCustomAmountChange: (amount: number) => void;
  onPaymentModeChange: (paymentMode: PaymentMode) => void;
};

type PaymentModes = Record<
  PaymentMode,
  {
    title: string;
    amount?: number;
    disabled: boolean;
  }
>;

const PaymentAmountPicker: React.FC<Props> = ({
  paymentSummary,
  nextFutureDueFee = 0,
  customAmount = 0,
  pastDueFees = 0,
  paymentMode,
  paymentMethod,
  onAmountChange,
  onCustomAmountChange,
  onPaymentModeChange,
}) => {
  const totalBalanceDue = getTotalBalanceDue(paymentSummary);

  const paymentModes: PaymentModes = useMemo(
    () => ({
      [PaymentMode.Past]: {
        title: 'Past due amount',
        amount: pastDueFees,
        disabled: totalBalanceDue === 0 || pastDueFees === 0,
      },
      [PaymentMode.Next]: {
        title: `Next installment${Number(pastDueFees) > 0 ? ' + past due amounts' : ''}`,
        amount: Number(nextFutureDueFee) + Number(pastDueFees),
        disabled: totalBalanceDue === 0 || nextFutureDueFee === 0,
      },
      [PaymentMode.Total]: {
        title: 'Total remaining balance',
        amount: totalBalanceDue,
        disabled: totalBalanceDue === 0,
      },
      [PaymentMode.Custom]: {
        title: 'Custom amount',
        disabled: totalBalanceDue === 0,
      },
    }),
    [nextFutureDueFee, pastDueFees, totalBalanceDue],
  );

  useEffect(() => {
    if (paymentModes[paymentMode]?.disabled) {
      onPaymentModeChange(PaymentMode.Custom);
    }
  }, [paymentMode, paymentModes, onPaymentModeChange]);

  useEffect(() => {
    const nextAmount = getPaymentAmount(
      paymentMode === PaymentMode.Custom ? customAmount : paymentModes[paymentMode].amount || 0,
      totalBalanceDue,
      paymentMethod,
    );
    onAmountChange(nextAmount);
  }, [paymentMode, paymentModes, customAmount, totalBalanceDue, paymentMethod, onAmountChange]);

  const handleChangeMode = (e: RadioChangeEvent) => {
    const mode = e.target.value;
    onPaymentModeChange(mode);
  };

  const handleCustomAmountChange = (amount?: number) => {
    if (isUndefined(amount)) {
      onCustomAmountChange(0);
    } else if (amount >= 0) {
      const nextCustomAmount = getPaymentAmount(amount, totalBalanceDue, paymentMethod);
      onCustomAmountChange(nextCustomAmount);
    }
  };

  return (
    <RadioGroup onChange={handleChangeMode} value={paymentMode} className={CSS.optionsWrapper}>
      {Object.entries(paymentModes).map(([mode, { title, amount, disabled }]) => (
        <div key={mode} className={CSS.option}>
          <Radio value={mode} disabled={disabled}>
            {title}
            {mode === paymentMode && <MaxAmountInfoBubble paymentMethod={paymentMethod} />}
          </Radio>
          {mode === PaymentMode.Custom ? (
            <InputMoney
              labelSize="1"
              labelColored
              labelInlineBlock
              placeholder="0.00"
              addonAfter="USD"
              value={customAmount}
              onChange={handleCustomAmountChange}
              disabled={disabled}
            />
          ) : (
            <p className={CSS.optionDescription}>{`$${(amount || 0).toFixed(2)} USD`}</p>
          )}
        </div>
      ))}
    </RadioGroup>
  );
};

export default PaymentAmountPicker;
