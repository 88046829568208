import { EditFilled } from '@ant-design/icons';
import { CollapsePanelProps } from 'antd/lib/collapse';
import React, { Fragment } from 'react';
import { FormattedPlural } from 'react-intl';

import InfoItem from '@/modules/shared/components/InfoItem';
import Section from '@/modules/shared/components/ReservationDetailsSection';

interface Props extends CollapsePanelProps {
  dates: string;
  length: number;
  name: string;
  onEdit?: () => void;
}

const ReservationSession: React.FC<Props> = ({ dates, length, name, onEdit, ...rest }) => (
  <Section
    {...rest}
    headerButtons={[
      ...(onEdit
        ? [
            {
              Icon: EditFilled,
              onClick: onEdit,
              key: 'editSession',
            },
          ]
        : []),
    ]}
  >
    <InfoItem
      header="Session Selected"
      info={
        <Fragment>
          {name}
          {!!length && (
            <Fragment>
              {' ('}
              {length} <FormattedPlural value={length} one="day" other="days" />
              {')'}
            </Fragment>
          )}
        </Fragment>
      }
    />
    <InfoItem header="Session Dates" info={dates} />
  </Section>
);

export default ReservationSession;
