import { Button as BsaButton } from 'antd';
import styled from 'styled-components';

export const ToPay = styled.div`
  display: inline-flex;
  font-size: 20px;
`;

type AmountProps = {
  isMobile: boolean;
};

export const Amount = styled.span<AmountProps>`
  color: #003f87;
  font-weight: bold;
  margin-left: ${({ isMobile }) => (isMobile ? 'auto' : '5px')};
`;

type MakePaymentButtonProps = {
  isMobile: boolean;
};

export const MakePaymentButton = styled(BsaButton).attrs({
  type: 'primary',
})<MakePaymentButtonProps>`
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin: ${({ isMobile }) => (isMobile ? '20px auto' : '0 0 0 15px')};

  :not(:disabled) {
    background-color: #067eeb;
  }
`;
