import apiService from '@/modules/utils/apiService';

const massApproveFormRecords$ = (payload: { formCode: string; formRecordGUIDList: string[] }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=MassApproveFormRecords',
    payload,
  );

export default {
  massApproveFormRecords$,
};
