import styled from 'styled-components';

type Props = {
  color: string;
  backgroundColor: string;
};

export const Status = styled.div<Props>`
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: fit-content;
  min-width: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 3px 10px;
  text-transform: uppercase;
`;
