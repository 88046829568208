import { produce } from 'immer';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { resetOnAction } from '@/modules/utils/resetOnLogoutReducer';

import { deselectAttribute, resetModuleState, selectAttribute } from './actions';

const selectedAttributes = createReducer<Set<string>>(new Set())
  .handleAction(selectAttribute, (state, { payload }) =>
    produce(state, draft => {
      draft.add(payload);
    }),
  )
  .handleAction(deselectAttribute, (state, { payload }) =>
    produce(state, draft => {
      draft.delete(payload);
    }),
  );

export default resetOnAction(
  combineReducers({
    selectedAttributes,
  }),
  resetModuleState,
);
