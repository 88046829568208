import { Button } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const AdditionalRCActionButton = styled(Button)`
  color: ${colors.scoutingBlue};
  :hover {
    background-color: ${colors.white};
  }
`;

export const ReservationContactNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
