export const responseStatus: {
  [PropName: string]: string;
} = {
  '000': 'Successful request.',
  100: 'Merchant Identifier left blank or not valid. The transaction was not processed.',
  110: 'Session Identifier left blank. The transaction was not processed.',
  200: 'Name not present',
  300: 'Amount is invalid or Credit Card is invalid',
  310: 'Credit Card field was left blank',
  315: 'Credit card number is invalid.',
  320: 'Credit card type left blank or invalid.',
  330: 'Expiration month is required',
  340: 'Expiration year is required.',
  350: 'CVC does not match the card.',
  355: 'CVC is required but not present.',
  357: 'An invalid character was entered, such as a letter in a numeric field.',
  360: 'Payment declined by financial institution, Please contact your bank',
  370: 'Expiration date is invalid.',
  400: 'The Address is invalid or missing',
  500: 'Billing Address is missing',
  510: 'City field is required.',
  520: 'State field is required',
  530: 'ZIP/postal is required',
  531: 'The ZIP/postal is invalid',
  550: 'Country field is required',
  600: 'The Bank name was blank',
  610: 'The Routing Number is blank',
  620: 'The Checking Account number was blank',
  630: 'The Routing Number is invalid',
  640: 'The Routing Number is invalid',
};

export const whatsThisDescriptions = {
  cvv: 'This is the CVC Number ("Card Verification Code") on your credit card or debit card is a 3 digit number on VISA®, MasterCard® and Discover® branded credit and debit cards. On your American Express® branded credit or debit card it is a 4 digit numeric code.',
  routing: 'A routing number is a nine-digit code used to identify your bank',
  account: 'A 10-12 digit number specific to your account',
};
