import { createGlobalStyle } from 'styled-components';

import colors from '@/modules/styles/colors';

import RobotoBlack from '@/assets/fonts/Roboto-Black.ttf';
import RobotoBold from '@/assets/fonts/Roboto-Bold.ttf';
import RobotoItalic from '@/assets/fonts/Roboto-Italic.ttf';
import RobotoLight from '@/assets/fonts/Roboto-Light.ttf';
import RobotoMedium from '@/assets/fonts/Roboto-Medium.ttf';
import RobotoRegular from '@/assets/fonts/Roboto-Regular.ttf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoRegular}) format('truetype');
    font-weight: normal; //400
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoItalic}) format('truetype');
    font-weight: normal; //400
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBold}) format('truetype');
    font-weight: bold; // 700
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  

  body {
    margin: 0;
    font-family: Roboto, sans-serif;
  }
  #app {
    background-color: #eee;
    position: relative;
    overflow-y: auto; 
    height: 100vh; 
  }
  .ant-form-item .ant-form-item-label > label {
    color: ${colors.scoutingBlue}
  }
  .ant-btn-primary {
    box-shadow: none;
  }
  .ant-modal .ant-modal-content {
    padding: 0;
  }
    .ant-table-wrapper {
      .ant-table-cell {
      }
      .ant-table-thead > tr > th, .ant-table-thead > tr > td {
        background: ${colors.primary};
        
        ::before {
          background-color: transparent !important;
        }
      }
  }
  .ant-typography {
    a {
      color: ${colors.scoutingBlue}
    }
  }
`;
