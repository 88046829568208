import { Row as BsaRow, Col as BsaCol, Button as BsaButton } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const ActionBarContainer = styled(BsaRow)`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.darkGrey};
  padding-right: 24px;
`;

export const InfoContainer = styled.span`
  color: white;
`;

export const Col = styled(BsaCol)`
  position: relative;
  display: flex;
  align-items: center;
  color: white;

  :last-child {
    justify-content: flex-end;
  }
`;

export const Divider = styled.span.attrs(() => ({
  children: '|',
}))`
  color: white;
  margin: 0 5px;

  :first-child,
  :last-child {
    display: none;
  }
`;

export const DropdownButton = styled(BsaButton)`
  padding-left: 24px;
  display: flex;
  align-items: center;
  min-width: 0;
`;
