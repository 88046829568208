const scoutingBlue = '#003f87';
const scoutingRed = '#ce1126';
const scoutingTan = '#d6cebd';
const scoutingDarkBlue = '#003366';
const scoutingPaleBlue = '#9ab3d5';

export default {
  white: '#fff',

  // ******** Corporate colors ************
  scoutingBlue,
  scoutingRed,
  scoutingTan,

  // primaryColorVariations
  scoutingDarkBlue,
  scoutingPaleBlue,

  scoutingDarkTan: '#ad9d7b',
  scoutingLightTan: '#e9e9e4',

  scoutingDarkGray: '#232528',
  scoutingPaleGray: '#969897',

  scoutingDarkRed: '#860d1a',
  scoutingPaleRed: '#c54250',

  // ******  BSA program colors ********

  //Cub scout
  cubScoutYellow: '#fdc019',

  // boyScouts:
  boyScoutBlue: scoutingBlue,
  boyScoutRed: scoutingRed,
  boyScoutTan: scoutingTan,
  boyScoutOlive: '#243e26',

  //Venturing
  venturingYellow: '#fbd536',
  venturingGreen: '#0D7549',

  // Sea scouts
  seaScoutBlue: scoutingDarkBlue,
  seaScoutPaleBlue: scoutingPaleBlue,
  seaScoutYellow: '#fed12f',

  // STEAM Scouts
  stemRed: '#f26c52',
  stemYellow: '#fcb53b',
  stemBlue: '#97c6d5',
  stemWarmBlack: '#4e4540',

  // Exploring
  exploringDeepBlue: '#221850',
  exploringMidBlue: '#0c7cff',
  exploringLightBlue: '#aee6ff',
  exploringGreen: '#00c77c',

  // ****** Interface colors  ***********

  //  status level
  darkRedStatus: '#9A0000',
  redStatus: '#DD0000',
  orangeStatus: '#FF6200',
  yellowStatus: '#FFB225',
  grayStatus: ' #858787',
  greenStatus: '#039B03',
  blackStatus: '#000000',
  lightBlueStatus: '#067eeb',

  // Grays
  gray_1: '#f2f2f2',
  gray_2: '#e6e6e6',
  gray_3: '#cccccc',
  gray_4: '#b3b3b3',
  gray_5: '#999999',
  gray_6: '#808080',
  gray_7: '#666666',
  gray_8: '#4d4d4d',
  gray_9: '#333333',
  gray_10: '#1a1a1a',

  // states
  primary: scoutingBlue,
  info: '#4990e2',
  success: '#039B03',
  error: '#DD0000',
  highlight: '#DD0000',
  warning: '#FFB225',
  normal: '#d9d9d9',

  black: '#000000',
  text: '#4a4a4a',
  gray: '#979797',
  greyUnderline: '#cccccc',
  successHover: 'lighten(desaturate(@success, 40%), 15%)',
  infoHover: 'lighten(desaturate(@info, 40%), 15%)',
  errorHover: 'lighten(desaturate(@error, 40%), 15%)',
  warningHover: 'lighten(desaturate(@warning, 40%), 15%)',
  dangerColor: '#ffb225',
  greyText: '#9b9b9b',
  lightGrey: '#eee',
  lighterGrey: '#fafafa',

  maskColor: 'rgba(0, 0, 0, 0.65)',
  maskFallbackColor: '#373737',
  darkGrey: '#515354',

  lightBlue: '#067eeb',
  primary_5: '#185694',
  darkBlue: 'rgb(0, 63, 135)',

  ceruleanBlue: '#067eeb',

  primaryColor: '#185694',
  complementColor: '#f2f2f2',

  primary_1: '#b3c0c7',
  primary_2: '#779fba',
  primary_4: '#336ea1',

  infoBackground: '#e6f2fd',
};
