import React, { useState, useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { FormattedLogItem } from '@/modules/entities/Logs/types';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { StyledList, Row, Column, SearchInput } from './EventLogs.styled';
import CommentsBlock from './components/CommentsBlock';
import LogDetails from './components/LogDetails';
import LogListItem from './components/LogListItem';

import { search } from 'UTILS/searchUtils';

type BaseProps = {
  formCode: string;
  isLoading: boolean;
  logs: FormattedLogItem[];
};

type GroupProps = BaseProps & {
  isGroup: true;
  groupReservation: GroupReservation;
};

type IndividualProps = BaseProps & {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};

type Props = GroupProps | IndividualProps;

const EventLogs: React.FC<Props> = props => {
  const { formCode, isLoading, logs, ...rest } = props;
  const isAllowedToPostComments = useSelector(
    createAbilitiesSelector('comment', PermissionAction.Create),
  );
  const [currentLogId, setCurrentLogId] = useState<string>();
  const [searchStr, setSearchStr] = useState('');

  const filteredLogs = useMemo(() => {
    if (!searchStr) return logs;
    return search(logs, searchStr, true);
  }, [logs, searchStr]);

  const handleActionClick = (logId: string) => {
    setCurrentLogId(logId);
  };

  const handleCloseDrawer = () => {
    setCurrentLogId('');
  };

  const currentLog = useMemo(
    () => logs.find(log => log.itemId === currentLogId),
    [currentLogId, logs],
  );

  return (
    <Fragment>
      <Row>
        <Column>
          {isAllowedToPostComments && (
            <CommentsBlock formCode={formCode} isLoading={isLoading} {...rest} />
          )}
        </Column>
      </Row>
      <Row>
        <Column flex={currentLog ? 1 : 2}>
          <SearchInput placeholder="Search" onChange={setSearchStr} />
          <StyledList
            loading={isLoading}
            itemLayout="vertical"
            dataSource={filteredLogs}
            rowKey="itemId"
            renderItem={log => (
              <LogListItem
                key={log.itemId}
                onActionClick={handleActionClick}
                log={log}
                isSelected={currentLogId === log.itemId}
              />
            )}
          />
        </Column>
        <Column flex={2}>
          {!!currentLog && (
            <LogDetails onClose={handleCloseDrawer} isLoading={isLoading} log={currentLog} />
          )}
        </Column>
      </Row>
    </Fragment>
  );
};

export default EventLogs;
