import React from 'react';

import styles from './EventDescription.less';

interface Props {
  id?: string;
  description: string;
}

const EventDescription: React.FC<Props> = ({ id, description = '' }) => (
  <div id={id} className={styles.description}>
    {description.length >= 216 ? description.substring(0, 215) + '...' : description}
  </div>
);

export default EventDescription;
