import styled from 'styled-components';

import ValueWithLabel from 'SHARED/components/ValueWithLabel';

interface DynamicValueWithLabelProps {
  isOverdue?: boolean;
}

export const DynamicValueWithLabel = styled(ValueWithLabel)<DynamicValueWithLabelProps>`
  color: ${({ isOverdue }) => (isOverdue ? 'red' : '')};
`;
