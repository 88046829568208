import React, { PropsWithChildren } from 'react';

import Footer from '../Footer';
import Header from '../Header';

import { ContentWrapper, LayoutComponent } from './Layout.styled';
import BroadcastMessage from './components/BroadcastMessage';
import SubHeader from './components/SubHeader';

const Layout: React.FC<PropsWithChildren> = ({ children }) => (
  <LayoutComponent>
    <Header />
    <BroadcastMessage />
    <SubHeader />
    <ContentWrapper>{children}</ContentWrapper>
    <Footer />
  </LayoutComponent>
);

export default Layout;
