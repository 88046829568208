import { Button } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const LoginWrapper = styled.div`
  text-align: center;
`;

export const ContinueAsNewRegistrantButton = styled(Button)<{ $isMobile: boolean }>`
  align-items: center;
  box-shadow: none;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  height: 48px;
  justify-content: center;
  margin: 0 auto 16px auto;
  max-width: ${({ $isMobile }) => ($isMobile ? '280px' : '284px')};
  text-align: center;
  width: 100%;

  &&& {
    color: ${colors.lightBlue};
  }
`;
