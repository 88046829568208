import { Input, InputNumber, Spin } from 'antd';
import { InputProps, InputRef } from 'antd/lib/input';
import { InputNumberProps } from 'antd/lib/input-number';
import cn from 'classnames';
import React, { useState, forwardRef } from 'react';

import L from '../L';

import styles from './InputMaterial.less';

type ExtraProps = {
  labelSize?: '1' | '2' | '3' | '4' | '5' | '6';
  size?: 'large';
  labelColored?: boolean;
  wrapperClassName?: string;
  type?: 'password' | 'money' | 'number' | 'text';
  className?: string;
  id?: string;
  labelInlineBlock?: boolean;
  loading?: boolean;
  hideSpinner?: boolean;
  flexWrapper?: boolean;
  required?: boolean;
  labelContent?: React.ReactNode;
  contentAfter?: React.ReactNode;
  contentBefore?: React.ReactNode;
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  prefix?: React.ReactNode;
  value?: string | number | boolean;
  defaultValue?: string | number | boolean;
  name?: string;
  children?: React.ReactNode;
};

type InputMaterialProps = ExtraProps & InputProps;
type InputNumberMaterialProps = ExtraProps & InputNumberProps;

type Props = InputMaterialProps | InputNumberMaterialProps;

const InputMaterial = forwardRef<InputRef, Props>(
  (
    {
      labelContent,
      labelSize,
      labelColored,
      labelInlineBlock,
      wrapperClassName = '',
      type,
      contentAfter,
      contentBefore,
      loading,
      hideSpinner,
      className,
      flexWrapper,
      addonBefore,
      addonAfter,
      required,
      ...rest
    },
    ref,
  ) => {
    const [show, setShow] = useState(false);

    const inputProps: Pick<InputNumberProps, 'formatter' | 'parser'> & { type?: string } = {
      ...rest,
      type,
    };

    const toggleShow = () => {
      setShow(!show);
    };

    let TheInput: typeof Input | typeof InputNumber = Input;

    switch (type) {
      case 'password':
        inputProps.type = show ? 'text' : 'password';
        break;
      case 'money':
        TheInput = InputNumber;
        inputProps.formatter = value => {
          if (!value) return '';
          const parts = value.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return parts.join('.');
        };
        inputProps.parser = value => {
          if (!value) return '';
          return value.toString().replace(/(,*)/g, '');
        };
        break;
    }

    return (
      <div
        className={cn(styles.inputMaterial, {
          [wrapperClassName]: wrapperClassName,
          [styles.flexWrapper]: flexWrapper,
          [styles.leftPadding]: !flexWrapper && (contentBefore || type === 'money'),
          [styles.rightPadding]: !flexWrapper && (contentAfter || type === 'password'),
        })}
      >
        {addonBefore}
        <Spin spinning={loading} size="small">
          {(type === 'money' || contentBefore) && (
            <span className={cn(styles.contentBefore, { [styles.moneySign]: type === 'money' })}>
              {type === 'money' ? '$' : contentBefore}
            </span>
          )}
          {labelContent && (
            <L size={labelSize} colored={labelColored} inlineBlock={labelInlineBlock}>
              {labelContent}
            </L>
          )}
          <TheInput
            {...inputProps}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
            required={required}
            className={cn(className, {
              [styles.hideSpinner]: hideSpinner,
              [styles.leftPadding]: flexWrapper && (contentBefore || type === 'money'),
              [styles.rightPadding]: flexWrapper && (contentAfter || type === 'password'),
            })}
          />
          {type === 'password' ? (
            <i
              className={cn(styles.inputMaterialIconPass, show ? styles.hide : styles.show)}
              onClick={toggleShow}
            />
          ) : (
            contentAfter && <span className={styles.contentAfter}>{contentAfter}</span>
          )}
        </Spin>
        {addonAfter}
      </div>
    );
  },
);

InputMaterial.defaultProps = {
  loading: false,
  required: true,
};

export default InputMaterial;
