import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  deleteDisclaimer,
  linkEventDisclaimer,
  unlinkEventDisclaimer,
  updateDisclaimer,
} from './actions';

const updateDisclaimerInProgress = createReducer(false)
  .handleAction(updateDisclaimer.request, () => true)
  .handleAction([updateDisclaimer.success, updateDisclaimer.failure], () => false);

const deleteDisclaimerInProgress = createReducer(false)
  .handleAction(deleteDisclaimer.request, () => true)
  .handleAction([deleteDisclaimer.success, deleteDisclaimer.failure], () => false);

const linkEventDisclaimerInProgress = createReducer(false)
  .handleAction(linkEventDisclaimer.request, () => true)
  .handleAction([linkEventDisclaimer.success, linkEventDisclaimer.failure], () => false);

const unlinkEventDisclaimerInProgress = createReducer(false)
  .handleAction(unlinkEventDisclaimer.request, () => true)
  .handleAction([unlinkEventDisclaimer.success, unlinkEventDisclaimer.failure], () => false);

export default combineReducers({
  updateDisclaimerInProgress,
  deleteDisclaimerInProgress,
  linkEventDisclaimerInProgress,
  unlinkEventDisclaimerInProgress,
});
