import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const DisclaimerAcceptedStatusIcon = styled(CheckCircleFilled)`
  color: ${colors.greenStatus};
`;

export const DisclaimerNotAcceptedStatusIcon = styled(CloseCircleFilled)`
  color: ${colors.redStatus};
`;

export const DisclaimerStatusContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;
