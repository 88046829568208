import qs from 'qs';
import { Dispatch } from 'redux';
import { Bag, Meta, redirect, StateGetter } from 'redux-first-router';
import restoreScroll from 'redux-first-router-restore-scroll';
import { RootAction, RootState } from 'typesafe-actions';

import { ROUTE_SANDBOX } from '@/modules/routing/constants';
import { ROUTE_INVALID_MEMBERSHIP, ROUTE_LOGIN } from '@/modules/user/duck/actions';
import { isLoggedInSel, isMainMembershipActiveSel } from '@/modules/user/duck/selectors';

import { navigateToEvents } from '@/pages/eventList/duck/actions';

import { set, pageView } from 'UTILS/analyticsService';

const checkAccess = (dispatch: Dispatch<RootAction>, state: RootState, action: RootAction) => {
  const isLoggedIn = isLoggedInSel(state);
  const hasActiveMembership = isMainMembershipActiveSel(state);
  const actionType = action.type;

  if (action.type === ROUTE_SANDBOX) return action;

  if (actionType !== ROUTE_INVALID_MEMBERSHIP && !hasActiveMembership && isLoggedIn) {
    return dispatch(redirect({ type: ROUTE_INVALID_MEMBERSHIP }));
  }

  if (actionType === ROUTE_LOGIN && isLoggedIn) {
    return dispatch(navigateToEvents());
  }
};

export default {
  onBeforeChange: (
    dispatch: Dispatch<RootAction>,
    getState: StateGetter<RootState>,
    { action /*, extra*/ }: Bag,
  ) => {
    const page = (action.meta as Meta)?.location?.current.pathname;
    set({ page });
    pageView(page);

    const state = getState();
    checkAccess(dispatch, state, action);
  },
  querySerializer: qs,
  restoreScroll: restoreScroll(),
};
