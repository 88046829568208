import React, { Fragment, ReactElement, useMemo } from 'react';

import { Activity, Addon, PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { Session } from '@/modules/data/dataTypes/sessionList';

import { Collapse } from './RegistrantsList.styled';
import RegistrantSection from './components/RegistrantSection';

type Props = {
  paymentSummary: PaymentSummary;
  providedAddons: Omit<Addon, 'formRecordAddonGUID' | 'groupReservationAddonGUID'>[] | null;
  providedActivities:
    | Pick<
        Activity,
        'activityCode' | 'activityName' | 'fee' | 'isMandatory' | 'quantity' | 'totalFee'
      >[]
    | null;
  selectedSession?: Pick<Session, 'sessionName'>;
  children: (params: {
    providedActivitiesSubtotal: number | null;
    providedAddonsSubtotal: number | null;
    existingSelectedActivitiesFee: number;
    existingSelectedAddonsFee: number;
  }) => ReactElement;
};

const RegistrantsList: React.FC<Props> = ({
  paymentSummary,
  providedActivities,
  providedAddons,
  selectedSession,
  children,
}) => {
  const { records } = paymentSummary;

  const providedActivitiesSubtotal = useMemo(() => {
    if (!providedActivities) return null;
    return providedActivities.reduce((acc, activity) => acc + activity.totalFee, 0);
  }, [providedActivities]);

  const providedAddonsSubtotal = useMemo(() => {
    if (!providedAddons) return null;
    return providedAddons.reduce(
      (acc, { selectedOptionFee }) => acc + Number(selectedOptionFee),
      0,
    );
  }, [providedAddons]);

  const existingSelectedAddonsFee = useMemo(() => {
    const primaryRegistrant = records.find(({ isPrimaryRegistrant }) => isPrimaryRegistrant);
    return primaryRegistrant?.selectedAddonFee || 0;
  }, [records]);

  const existingSelectedActivitiesFee = useMemo(() => {
    const primaryRegistrant = records.find(({ isPrimaryRegistrant }) => isPrimaryRegistrant);
    return primaryRegistrant?.selectedActivitiesFee || 0;
  }, [records]);

  const sortedRegistrants = useMemo(
    () =>
      records.slice().sort((a, b) => {
        if (a.isPrimaryRegistrant) return -1;
        if (b.isPrimaryRegistrant) return 1;
        return 0;
      }),
    [records],
  );

  const primaryRegistrantFormRecordGuid = useMemo(
    () => sortedRegistrants.find(({ isPrimaryRegistrant }) => isPrimaryRegistrant)?.formRecordGUID,
    [sortedRegistrants],
  );

  return (
    <Fragment>
      <Collapse expandIconPosition="end" defaultActiveKey={primaryRegistrantFormRecordGuid}>
        {sortedRegistrants.map(registrant => (
          <RegistrantSection
            key={registrant.formRecordGUID}
            registrant={registrant}
            providedActivities={providedActivities}
            providedAddons={providedAddons}
            providedActivitiesSubtotal={providedActivitiesSubtotal}
            providedAddonsSubtotal={providedAddonsSubtotal}
            session={selectedSession}
          />
        ))}
      </Collapse>
      {children({
        existingSelectedActivitiesFee,
        existingSelectedAddonsFee,
        providedActivitiesSubtotal,
        providedAddonsSubtotal,
      })}
    </Fragment>
  );
};

export default RegistrantsList;
