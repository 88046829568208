import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { moduleName as attendeesModuleName } from '../tabs/Attendees/constants';
import attendeesReducers from '../tabs/Attendees/duck/reducers';

import { adminReportCSV, deleteMailing, summaryReport } from './actions';

const deleteMailingInProgress = createReducer(false)
  .handleAction(deleteMailing.request, () => true)
  .handleAction([deleteMailing.failure, deleteMailing.success], () => false);

const isLoadingCSVRequest = createReducer(false)
  .handleAction(summaryReport.request, () => true)
  .handleAction([summaryReport.request, summaryReport.failure], () => false);

const isDownloadingPaymentsReport = createReducer(false)
  .handleAction(adminReportCSV.request, () => true)
  .handleAction([adminReportCSV.success, adminReportCSV.failure], () => false);

export default combineReducers({
  deleteMailingInProgress,
  isLoadingCSVRequest,
  isDownloadingPaymentsReport,
  [attendeesModuleName]: attendeesReducers,
});
