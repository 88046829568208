import { Form, Input } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { mapValues } from 'lodash';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';

import { updateReservationSize } from '../../../../duck/actions';
import { updateReservationSizeInProgressSel } from '../../../../duck/selectors';

import { FormContainer, InputNumber } from './ReservationEditModal.styled';
import { ModalParams } from './types';

const NUMBER_OF_YOUTH_FIELD_NAME: keyof GroupReservation = 'initialNumberOfYouth';
const NUMBER_OF_ADULTS_FIELD_NAME: keyof GroupReservation = 'initialNumberOfAdults';
const NUMBER_OF_CREWS_FIELD_NAME: keyof GroupReservation = 'initialRosterCount';

type Props = {
  modalParams: ModalParams;
};

const ReservationSizeEditModal: React.FC<Props> = ({ modalParams }) => {
  const [form] = Form.useForm<{
    [NUMBER_OF_YOUTH_FIELD_NAME]: number;
    [NUMBER_OF_ADULTS_FIELD_NAME]: number;
    [NUMBER_OF_CREWS_FIELD_NAME]: number;
  }>();
  const dispatch = useDispatch();
  const session = useSelector(createDataSel('session'));
  const updateReservationSizeInProgress = useSelector(updateReservationSizeInProgressSel);
  const { groupTotalMemberCount } = useSelector(createDataSel('groupReservation'));
  const { reservation } = modalParams;
  const { maximumPeopleCount, minimumPeopleCount } = session;

  function handleCancel() {
    dispatch(closeModal());
  }

  function handleSubmit() {
    form.validateFields().then(values => {
      const { initialRosterCount, initialNumberOfAdults, initialNumberOfYouth } = mapValues(
        values,
        value => Number(value || 0),
      );
      const totalExpectedAttendees = initialNumberOfAdults + initialNumberOfYouth;

      if (totalExpectedAttendees < groupTotalMemberCount) {
        return toastService.error(
          `Adults + Youth expected total must be equal or greater than people in reservation (${groupTotalMemberCount}).`,
        );
      }

      dispatch(
        updateReservationSize.request({
          initialNumberOfAdults,
          initialNumberOfYouth,
          initialRosterCount,
        }),
      );
    });
  }

  return (
    <ModalComponent
      title="Edit Reservation Size"
      description={`Are you sure you want to edit the reservation size for Reservation ${reservation.groupReservationExpeditionNumber}? When editing the number of participants in the reservation, fees would be updated in the payment info.`}
      buttons={[
        { title: 'No, go back', onClick: handleCancel },
        { title: 'Yes, Edit it', onClick: handleSubmit, type: 'primary' },
      ]}
      inProgress={updateReservationSizeInProgress}
    >
      <FormContainer>
        <Form form={form} initialValues={reservation} layout="vertical">
          <Form.Item
            name={NUMBER_OF_YOUTH_FIELD_NAME}
            rules={[{ required: true, message: 'Please enter number of youth.' }]}
            label="Youth Expected"
          >
            <InputNumber size="large" precision={0} />
          </Form.Item>
          <Form.Item
            name={NUMBER_OF_ADULTS_FIELD_NAME}
            rules={[{ required: true, message: 'Please enter number of adults.' }]}
            label="Adults Expected"
          >
            <InputNumber size="large" precision={0} />
          </Form.Item>
          <Form.Item
            noStyle
            dependencies={[NUMBER_OF_YOUTH_FIELD_NAME, NUMBER_OF_ADULTS_FIELD_NAME]}
          >
            {({ getFieldValue }) => {
              const numberOfYouth = Number(getFieldValue(NUMBER_OF_YOUTH_FIELD_NAME) || 0);
              const numberOfAdults = Number(getFieldValue(NUMBER_OF_ADULTS_FIELD_NAME) || 0);

              const peopleCount = numberOfYouth + numberOfAdults;

              const minCrews = Math.ceil(peopleCount / maximumPeopleCount) || 1;
              const maxCrews = Math.floor(peopleCount / minimumPeopleCount) || 1;

              return (
                <Fragment>
                  <Form.Item
                    name={NUMBER_OF_CREWS_FIELD_NAME}
                    dependencies={[NUMBER_OF_YOUTH_FIELD_NAME, NUMBER_OF_ADULTS_FIELD_NAME]}
                    label="Crews in Reservation"
                    rules={[
                      {
                        validator: (_rule: RuleObject, value = 0) => {
                          if (value < reservation.rosterWithMemberCount) {
                            return Promise.reject(
                              `Your number of crews must be at least ${reservation.rosterWithMemberCount}`,
                            );
                          }
                          if (value < minCrews || !value) {
                            return Promise.reject(
                              `Your number of crews must be at least ${minCrews}`,
                            );
                          }
                          if (value > maxCrews) {
                            return Promise.reject(
                              `In order to select ${value} crews, please input a total number of Youth and Adults between ${
                                minimumPeopleCount * value
                              } and ${maximumPeopleCount * value}`,
                            );
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber precision={0} size="large" />
                  </Form.Item>
                  <Form.Item label="People in Reservation">
                    <Input value={peopleCount} disabled size="large" />
                  </Form.Item>
                </Fragment>
              );
            }}
          </Form.Item>
        </Form>
      </FormContainer>
    </ModalComponent>
  );
};

export default ReservationSizeEditModal;
