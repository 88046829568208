import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';

import { scheduleRefresh } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import AddonsWrapper from '@/modules/entities/Addons/components/AddonsWrapper';
import SelectAddons from '@/modules/entities/Addons/components/SelectAddons';
import { secondaryRegistrantFormRecordGuidSel } from '@/modules/entities/Registrations/components/AddSecondaryRegistrantModal/duck/selectors';

import { SECONDARY_REGISTRANT_DATA_ID } from '../../constants';
import { openNextStep } from '../../duck/actions';

const AddonsStep = forwardRef<{ handleSubmit: () => void }>((_, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const addonsRef = useRef<{ handleSubmit: () => void }>(null);
  const { formCode } = useSelector(createDataSel('form'));
  const { state } = useSelector(createDataSel('arnicaPerson'));
  const addons = useSelector(createDataSel('addons'));
  const currentSession = useSelector(createDataSel('session'));
  const formRecordGUID = useSelector(secondaryRegistrantFormRecordGuidSel);
  const formRecordAddons = useSelector(createDataSel('formRecordAddons'));

  function handleSubmit() {
    addonsRef.current?.handleSubmit();
  }

  return (
    <AddonsWrapper addonsSummary={currentSession.addonsSummary}>
      <SelectAddons
        checkMandatoryAddons
        ref={addonsRef}
        formCode={formCode}
        finalActions={[
          openNextStep(),
          scheduleRefresh({ dataType: 'paymentSummary', dataId: SECONDARY_REGISTRANT_DATA_ID }),
          scheduleRefresh({ dataType: 'formRecordAddons' }),
        ]}
        formRecordGUID={formRecordGUID as string}
        isGroup={false}
        availableAddons={addons}
        state={state}
        selectedAddons={formRecordAddons}
      />
    </AddonsWrapper>
  );
});

export default AddonsStep;
