import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const FooterContent = styled.div`
  background-color: ${colors.gray_2};
  height: 68px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  color: ${colors.gray_7};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
`;

export const Link = styled.a`
  font-weight: 500;
  color: ${colors.lightBlue};
`;
