import { PlusCircleFilled } from '@ant-design/icons';
import React from 'react';

import { Button } from './AddButton.styled';

type Props = {
  onClick: () => void;
};

const AddButton: React.FC<Props> = ({ onClick }) => (
  <Button onClick={onClick} ghost>
    <PlusCircleFilled />
    Add
  </Button>
);

export default AddButton;
