import { combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { deleteInstallment, updateInstallment, updateInstallmentClosed } from './actions';
import services from './services';

const deleteInstallment$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteInstallment.request)),
    switchMap(({ payload: paymentCategoryCode }) => {
      const { formCode } = createDataSel('form')(state$.value);

      return services.deleteInstallment$({ formCode, paymentCategoryCode }).pipe(
        switchMap(() => {
          toastService.success('Payment Installment deleted successfully!');
          return of(deleteInstallment.success(), refreshData({ dataType: 'paymentCategories' }));
        }),
      );
    }),
    catchError((error: ApiError) => {
      toastService.error(error.message);
      return of(deleteInstallment.failure(error));
    }),
  );

const updateInstallment$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateInstallment.request)),
    switchMap(({ payload }) => {
      const { formCode } = createDataSel('form')(state$.value);

      return services.updateInstallments$({ formCode, paymentCategories: [payload] }).pipe(
        switchMap(() => {
          toastService.success('Payment installment saved successfully!');
          return of(
            updateInstallment.success(),
            refreshData({ dataType: 'paymentCategories' }),
            updateInstallmentClosed(),
          );
        }),
      );
    }),
    catchError((error: ApiError) => {
      toastService.error(error.message);
      return of(updateInstallment.failure(error));
    }),
  );

export default combineEpics(deleteInstallment$, updateInstallment$);
