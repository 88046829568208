import { Card, Button, Row, Col } from 'antd';
import React from 'react';
import { back } from 'redux-first-router';

const AgeRequirement: React.FC = () => (
  <Card className="mt-6 text-center">
    <h2 className="text-primary">Group Event</h2>
    <Row>
      <Col span={14} offset={5}>
        <p className="mt-3">
          This is a group event. You must be at least 18 years old to register a group.&nbsp; If you
          want to register speak with your council to be invited to a group.
        </p>
        <Button className="mt-2" type="default" onClick={back}>
          My Reservation
        </Button>
      </Col>
    </Row>
  </Card>
);

export default AgeRequirement;
