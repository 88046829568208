const states = [
  {
    label: 'AA-APO AA',
    positionNumber: 1,
    short: 'AA',
    value: '1',
  },
  {
    label: 'AE-APO AE',
    positionNumber: 2,
    short: 'AE',
    value: '2',
  },
  {
    label: 'AK-ALASKA',
    positionNumber: 3,
    short: 'AK',
    value: '3',
  },
  {
    label: 'AL-ALABAMA',
    positionNumber: 4,
    short: 'AL',
    value: '4',
  },
  {
    label: 'AP-APO AP',
    positionNumber: 5,
    short: 'AP',
    value: '5',
  },
  {
    label: 'AR-ARKANSAS',
    positionNumber: 6,
    short: 'AR',
    value: '6',
  },
  {
    label: 'AS-AMERICAN SOMOA',
    positionNumber: 6,
    short: 'AS',
    value: '7',
  },
  {
    label: 'AZ-ARIZONA',
    positionNumber: 7,
    short: 'AZ',
    value: '8',
  },
  {
    label: 'CA-CALIFORNIA',
    positionNumber: 8,
    short: 'CA',
    value: '9',
  },
  {
    label: 'CO-COLORADO',
    positionNumber: 9,
    short: 'CO',
    value: '10',
  },
  {
    label: 'CT-CONNECTICUT',
    positionNumber: 10,
    short: 'CT',
    value: '11',
  },
  {
    label: 'DC-DISTRICT OF COLUMBIA',
    positionNumber: 11,
    short: 'DC',
    value: '12',
  },
  {
    label: 'DE-DELAWARE',
    positionNumber: 11,
    short: 'DE',
    value: '13',
  },
  {
    label: 'FL-FLORidA',
    positionNumber: 12,
    short: 'FL',
    value: '14',
  },
  {
    label: 'FM-FED ST MICRONESIA',
    positionNumber: 13,
    short: 'FM',
    value: '15',
  },
  {
    label: 'GA-GEORGIA',
    positionNumber: 14,
    short: 'GA',
    value: '16',
  },
  {
    label: 'GU-GUAM',
    positionNumber: 15,
    short: 'GU',
    value: '17',
  },
  {
    label: 'HI-HAWAII',
    positionNumber: 16,
    short: 'HI',
    value: '18',
  },
  {
    label: 'IA-IOWA',
    positionNumber: 17,
    short: 'IA',
    value: '19',
  },
  {
    label: 'id-idAHO',
    positionNumber: 18,
    short: 'id',
    value: '20',
  },
  {
    label: 'IL-ILLINOIS',
    positionNumber: 19,
    short: 'IL',
    value: '21',
  },
  {
    label: 'IN-INDIANA',
    positionNumber: 20,
    short: 'IN',
    value: '22',
  },
  {
    label: 'KS-KANSAS',
    positionNumber: 21,
    short: 'KS',
    value: '23',
  },
  {
    label: 'KY-KENTUCKY',
    positionNumber: 22,
    short: 'KY',
    value: '24',
  },
  {
    label: 'LA-LOUISIANA',
    positionNumber: 23,
    short: 'LA',
    value: '25',
  },
  {
    label: 'MA-MASSACHUSETTS',
    positionNumber: 24,
    short: 'MA',
    value: '26',
  },
  {
    label: 'MD-MARYLAND',
    positionNumber: 25,
    short: 'MD',
    value: '27',
  },
  {
    label: 'ME-MAINE',
    positionNumber: 26,
    short: 'ME',
    value: '28',
  },
  {
    label: 'MH-MARSHALL ISLANDS',
    positionNumber: 27,
    short: 'MH',
    value: '29',
  },
  {
    label: 'MI-MICHIGAN',
    positionNumber: 28,
    short: 'MI',
    value: '30',
  },
  {
    label: 'MN-MINNESOTA',
    positionNumber: 29,
    short: 'MN',
    value: '31',
  },
  {
    label: 'MO-MISSOURI',
    positionNumber: 30,
    short: 'MO',
    value: '32',
  },
  {
    label: 'MP-NORTHERN MARIANA IS',
    positionNumber: 31,
    short: 'MP',
    value: '33',
  },
  {
    label: 'MS-MISSISSIPPI',
    positionNumber: 32,
    short: 'MS',
    value: '34',
  },
  {
    label: 'MT-MONTANA',
    positionNumber: 33,
    short: 'MT',
    value: '35',
  },
  {
    label: 'NC-NORTH CAROLINA',
    positionNumber: 34,
    short: 'NC',
    value: '36',
  },
  {
    label: 'ND-NORTH DAKOTA',
    positionNumber: 35,
    short: 'ND',
    value: '37',
  },
  {
    label: 'NE-NEBRASKA',
    positionNumber: 36,
    short: 'NE',
    value: '38',
  },
  {
    label: 'NH-NEW HAMPSHIRE',
    positionNumber: 37,
    short: 'NH',
    value: '39',
  },
  {
    label: 'NJ-NEW JERSEY',
    positionNumber: 38,
    short: 'NJ',
    value: '40',
  },
  {
    label: 'NM-NEW MEXICO',
    positionNumber: 39,
    short: 'NM',
    value: '41',
  },
  {
    label: 'NV-NEVADA',
    positionNumber: 40,
    short: 'NV',
    value: '42',
  },
  {
    label: 'NY-NEW YORK',
    positionNumber: 41,
    short: 'NY',
    value: '43',
  },
  {
    label: 'OH-OHIO',
    positionNumber: 42,
    short: 'OH',
    value: '44',
  },
  {
    label: 'OK-OKLAHOMA',
    positionNumber: 43,
    short: 'OK',
    value: '45',
  },
  {
    label: 'OR-OREGON',
    positionNumber: 44,
    short: 'OR',
    value: '46',
  },
  {
    label: 'PA-PENNSYLVANIA',
    positionNumber: 45,
    short: 'PA',
    value: '47',
  },
  {
    label: 'PR-PUERTO RICO',
    positionNumber: 46,
    short: 'PR',
    value: '48',
  },
  {
    label: 'PW-PALAU ISLAND',
    positionNumber: 47,
    short: 'PW',
    value: '49',
  },
  {
    label: 'RI-RHODE ISLAND',
    positionNumber: 48,
    short: 'RI',
    value: '50',
  },
  {
    label: 'SC-SOUTH CAROLINA',
    positionNumber: 49,
    short: 'SC',
    value: '51',
  },
  {
    label: 'SD-SOUTH DAKOTA',
    positionNumber: 50,
    short: 'SD',
    value: '52',
  },
  {
    label: 'TN-TENNESSEE',
    positionNumber: 51,
    short: 'TN',
    value: '53',
  },
  {
    label: 'TT-TRUST TERRITORIES',
    positionNumber: 52,
    short: 'TT',
    value: '54',
  },
  {
    label: 'TX-TEXAS',
    positionNumber: 53,
    short: 'TX',
    value: '55',
  },
  {
    label: 'UT-UTAH',
    positionNumber: 54,
    short: 'UT',
    value: '56',
  },
  {
    label: 'VA-VIRGINIA',
    positionNumber: 55,
    short: 'VA',
    value: '57',
  },
  {
    label: 'VI-VIRGIN ISLANDS',
    positionNumber: 56,
    short: 'VI',
    value: '58',
  },
  {
    label: 'VT-VERMONT',
    positionNumber: 57,
    short: 'VT',
    value: '59',
  },
  {
    label: 'WA-WASHINGTON',
    positionNumber: 58,
    short: 'WA',
    value: '60',
  },
  {
    label: 'WI-WISCONSIN',
    positionNumber: 59,
    short: 'WI',
    value: '61',
  },
  {
    label: 'WV-WEST VIRGINIA',
    positionNumber: 60,
    short: 'WV',
    value: '62',
  },
  {
    label: 'WY-WYOMING',
    positionNumber: 61,
    short: 'WY',
    value: '63',
  },
];
const ethnicities = [
  {
    positionNumber: 0,
    value: '1740',
    label: 'Black/African American',
    short: 'AA',
  },
  {
    positionNumber: 1,
    value: '1741',
    label: 'Alaska Native',
    short: 'AN',
  },
  {
    positionNumber: 2,
    value: '1742',
    label: 'Asian',
    short: 'AS',
  },
  {
    positionNumber: 3,
    value: '1743',
    label: 'Caucasian/White',
    short: 'CA',
  },
  {
    positionNumber: 4,
    value: '1744',
    label: 'Hispanic/Latino',
    short: 'HI',
  },
  {
    positionNumber: 5,
    value: '1745',
    label: 'Native American',
    short: 'NA',
  },
  {
    positionNumber: 6,
    value: '1746',
    label: 'Not Provided',
    short: 'NP',
  },
  {
    positionNumber: 7,
    value: '1747',
    label: 'Other',
    short: 'OT',
  },
  {
    positionNumber: 8,
    value: '1748',
    label: 'Pacific Islander',
    short: 'PC',
  },
];
const religion = [
  {
    positionNumber: 0,
    value: '71',
    short: '1',
    label: 'African Methodist Episcopal',
  },
  {
    positionNumber: 1,
    value: '72',
    short: '2',
    label: 'African Methodist Episcopal Zion',
  },
  {
    positionNumber: 2,
    value: '73',
    short: '5',
    label: 'Eastern Orthodox Churches (Greek)',
  },
  {
    positionNumber: 3,
    value: '74',
    short: '6',
    label: 'Assemblies of God',
  },
  {
    positionNumber: 4,
    value: '75',
    short: '7',
    label: 'Presbyterian Church',
  },
  {
    positionNumber: 5,
    value: '76',
    short: '8',
    label: 'Lutheran Churches',
  },
  {
    positionNumber: 6,
    value: '77',
    short: '10',
    label: 'Buddist Churches of America',
  },
  {
    positionNumber: 7,
    value: '78',
    short: '11',
    label: 'Community Churches',
  },
  {
    positionNumber: 8,
    value: '79',
    short: '12',
    label: 'Christian Church (Disciples of Christ)',
  },
  {
    positionNumber: 9,
    value: '80',
    short: '15',
    label: 'Christian Methodist Episcopal Church',
  },
  {
    positionNumber: 10,
    value: '81',
    short: '17',
    label: 'Church of Christ',
  },
  {
    positionNumber: 11,
    value: '82',
    short: '21',
    label: 'Latter-day Saints (Mormon)',
  },
  {
    positionNumber: 12,
    value: '83',
    short: '22',
    label: 'Church of the Brethren',
  },
  {
    positionNumber: 13,
    value: '84',
    short: '23',
    label: 'Church of Nazarene',
  },
  {
    positionNumber: 14,
    value: '85',
    short: '24',
    label: 'Episcopal Church',
  },
  {
    positionNumber: 15,
    value: '86',
    short: '25',
    label: 'Evangelical/Independent Churches',
  },
  {
    positionNumber: 16,
    value: '87',
    short: '26',
    label: 'United Methodist Church',
  },
  {
    positionNumber: 17,
    value: '88',
    short: '27',
    label: 'Friends or Quakers',
  },
  {
    positionNumber: 18,
    value: '89',
    short: '28',
    label: 'Church of God',
  },
  {
    positionNumber: 19,
    value: '90',
    short: '31',
    label: 'Pentecostal Churches',
  },
  {
    positionNumber: 20,
    value: '91',
    short: '32',
    label: 'Jewish Synagogues',
  },
  {
    positionNumber: 21,
    value: '92',
    short: '35',
    label: 'Moravian Church in America',
  },
  {
    positionNumber: 22,
    value: '93',
    short: '36',
    label: 'Islam, Muslim, Massjid',
  },
  {
    positionNumber: 23,
    value: '94',
    short: '39',
    label: 'Roman Catholic Church, Knights of Columbus',
  },
  {
    positionNumber: 24,
    value: '95',
    short: '40',
    label: 'Reformed Church in America',
  },
  {
    positionNumber: 25,
    value: '96',
    short: '41',
    label: 'Reorganized Latter Day Saints',
  },
  {
    positionNumber: 26,
    value: '97',
    short: '42',
    label: 'Salvation Army',
  },
  {
    positionNumber: 27,
    value: '98',
    short: '44',
    label: 'Unitarian Universalist Association',
  },
  {
    positionNumber: 28,
    value: '99',
    short: '45',
    label: 'United Church of Christ',
  },
  {
    positionNumber: 29,
    value: '100',
    short: '175',
    label: 'Baptist Churches',
  },
  {
    positionNumber: 30,
    value: '101',
    short: '214',
    label: 'Other Churches',
  },
  {
    positionNumber: 31,
    value: '102',
    short: '231',
    label: 'Armenian Church of America',
  },
];

const councils2 = [
  {
    positionNumber: 0,
    value: '1',
    label: 'Greater Alabama',
  },
  {
    positionNumber: 1,
    value: '3',
    label: 'Alabama-Florida',
  },
  {
    positionNumber: 2,
    value: '4',
    label: 'Mobile Area',
  },
  {
    positionNumber: 3,
    value: '5',
    label: 'Tukabatchee Area',
  },
  {
    positionNumber: 4,
    value: '6',
    label: 'Black Warrior',
  },
  {
    positionNumber: 5,
    value: '10',
    label: 'Grand Canyon',
  },
  {
    positionNumber: 6,
    value: '11',
    label: 'Catalina',
  },
  {
    positionNumber: 7,
    value: '13',
    label: 'De Soto Area',
  },
  {
    positionNumber: 8,
    value: '16',
    label: 'Westark Area',
  },
  {
    positionNumber: 9,
    value: '18',
    label: 'Quapaw Area',
  },
  {
    positionNumber: 10,
    value: '22',
    label: 'Alameda',
  },
  {
    positionNumber: 11,
    value: '23',
    label: 'Mount Diablo Silverado',
  },
  {
    positionNumber: 12,
    value: '27',
    label: 'Sequoia',
  },
  {
    positionNumber: 13,
    value: '28',
    label: 'San Francisco Bay Area',
  },
  {
    positionNumber: 14,
    value: '30',
    label: 'Southern Sierra',
  },
  {
    positionNumber: 15,
    value: '31',
    label: 'Pacific Skyline',
  },
  {
    positionNumber: 16,
    value: '32',
    label: 'Long Beach Area',
  },
  {
    positionNumber: 17,
    value: '33',
    label: 'Greater Los Angeles Area',
  },
  {
    positionNumber: 18,
    value: '35',
    label: 'Marin',
  },
  {
    positionNumber: 19,
    value: '39',
    label: 'Orange County',
  },
  {
    positionNumber: 20,
    value: '41',
    label: 'Redwood Empire',
  },
  {
    positionNumber: 21,
    value: '42',
    label: 'Piedmont',
  },
  {
    positionNumber: 22,
    value: '45',
    label: 'California Inland Empire',
  },
  {
    positionNumber: 23,
    value: '47',
    label: 'Golden Empire',
  },
  {
    positionNumber: 24,
    value: '49',
    label: 'San Diego-Imperial',
  },
  {
    positionNumber: 25,
    value: '51',
    label: 'Western Los Angeles County',
  },
  {
    positionNumber: 26,
    value: '53',
    label: 'Los Padres',
  },
  {
    positionNumber: 27,
    value: '55',
    label: 'Silicon Valley Monterey Bay',
  },
  {
    positionNumber: 28,
    value: '57',
    label: 'Ventura County',
  },
  {
    positionNumber: 29,
    value: '58',
    label: 'Verdugo Hills',
  },
  {
    positionNumber: 30,
    value: '59',
    label: 'Greater Yosemite',
  },
  {
    positionNumber: 31,
    value: '60',
    label: 'Pikes Peak',
  },
  {
    positionNumber: 32,
    value: '61',
    label: 'Denver Area',
  },
  {
    positionNumber: 33,
    value: '62',
    label: 'Longs Peak',
  },
  {
    positionNumber: 34,
    value: '63',
    label: 'Rocky Mountain',
  },
  {
    positionNumber: 35,
    value: '64',
    label: 'Western Colorado',
  },
  {
    positionNumber: 36,
    value: '66',
    label: 'Connecticut Rivers',
  },
  {
    positionNumber: 37,
    value: '67',
    label: 'Greenwich',
  },
  {
    positionNumber: 38,
    value: '69',
    label: 'Housatonic',
  },
  {
    positionNumber: 39,
    value: '70',
    label: 'Old North State',
  },
  {
    positionNumber: 40,
    value: '72',
    label: 'Connecticut Yankee',
  },
  {
    positionNumber: 41,
    value: '81',
    label: 'Del-Mar-Va',
  },
  {
    positionNumber: 42,
    value: '82',
    label: 'National Capital Area',
  },
  {
    positionNumber: 43,
    value: '83',
    label: 'Central Florida',
  },
  {
    positionNumber: 44,
    value: '84',
    label: 'South Florida',
  },
  {
    positionNumber: 45,
    value: '85',
    label: 'Gulf Stream',
  },
  {
    positionNumber: 46,
    value: '87',
    label: 'North Florida',
  },
  {
    positionNumber: 47,
    value: '88',
    label: 'Southwest Florida',
  },
  {
    positionNumber: 48,
    value: '89',
    label: 'Greater Tampa Bay Area',
  },
  {
    positionNumber: 49,
    value: '91',
    label: 'Chattahoochee',
  },
  {
    positionNumber: 50,
    value: '92',
    label: 'Atlanta Area',
  },
  {
    positionNumber: 51,
    value: '93',
    label: 'Georgia-Carolina',
  },
  {
    positionNumber: 52,
    value: '95',
    label: 'Flint River',
  },
  {
    positionNumber: 53,
    value: '96',
    label: 'Central Georgia',
  },
  {
    positionNumber: 54,
    value: '98',
    label: 'South Georgia',
  },
  {
    positionNumber: 55,
    value: '99',
    label: 'Coastal Georgia',
  },
  {
    positionNumber: 56,
    value: '100',
    label: 'Northwest Georgia',
  },
  {
    positionNumber: 57,
    value: '101',
    label: 'Northeast Georgia',
  },
  {
    positionNumber: 58,
    value: '102',
    label: 'Maui County',
  },
  {
    positionNumber: 59,
    value: '104',
    label: 'Aloha',
  },
  {
    positionNumber: 60,
    value: '106',
    label: 'Ore-ida',
  },
  {
    positionNumber: 61,
    value: '107',
    label: 'Grand Teton',
  },
  {
    positionNumber: 62,
    value: '111',
    label: 'Snake River',
  },
  {
    positionNumber: 63,
    value: '114',
    label: 'Lewis & Clark',
  },
  {
    positionNumber: 64,
    value: '117',
    label: 'Prairielands',
  },
  {
    positionNumber: 65,
    value: '121',
    label: 'Lincoln Trails',
  },
  {
    positionNumber: 66,
    value: '127',
    label: 'Three Fires',
  },
  {
    positionNumber: 67,
    value: '129',
    label: 'Northeast Illinois',
  },
  {
    positionNumber: 68,
    value: '133',
    label: 'Illowa',
  },
  {
    positionNumber: 69,
    value: '138',
    label: 'W.D. Boyce',
  },
  {
    positionNumber: 70,
    value: '141',
    label: 'Mississippi Valley',
  },
  {
    positionNumber: 71,
    value: '144',
    label: 'Abraham Lincoln',
  },
  {
    positionNumber: 72,
    value: '145',
    label: 'Hoosier Trails',
  },
  {
    positionNumber: 73,
    value: '152',
    label: 'Calumet',
  },
  {
    positionNumber: 74,
    value: '156',
    label: 'Buffalo Trace',
  },
  {
    positionNumber: 75,
    value: '157',
    label: 'Anthony Wayne Area',
  },
  {
    positionNumber: 76,
    value: '160',
    label: 'Crossroads of America',
  },
  {
    positionNumber: 77,
    value: '162',
    label: 'Sagamore',
  },
  {
    positionNumber: 78,
    value: '165',
    label: 'La Salle',
  },
  {
    positionNumber: 79,
    value: '172',
    label: 'Hawide Area',
  },
  {
    positionNumber: 80,
    value: '173',
    label: 'Winnebago',
  },
  {
    positionNumber: 81,
    value: '177',
    label: 'Mid-Iowa',
  },
  {
    positionNumber: 82,
    value: '178',
    label: 'Northeast Iowa',
  },
  {
    positionNumber: 83,
    value: '192',
    label: 'Coronado Area',
  },
  {
    positionNumber: 84,
    value: '194',
    label: 'Santa Fe Trail',
  },
  {
    positionNumber: 85,
    value: '197',
    label: 'Jayhawk Area',
  },
  {
    positionNumber: 86,
    value: '198',
    label: 'Quivira',
  },
  {
    positionNumber: 87,
    value: '204',
    label: 'Blue Grass',
  },
  {
    positionNumber: 88,
    value: '205',
    label: 'Lincoln Heritage',
  },
  {
    positionNumber: 89,
    value: '209',
    label: 'Calcasieu Area',
  },
  {
    positionNumber: 90,
    value: '211',
    label: 'Istrouma Area',
  },
  {
    positionNumber: 91,
    value: '212',
    label: 'Evangeline Area',
  },
  {
    positionNumber: 92,
    value: '213',
    label: 'Louisiana Purchase',
  },
  {
    positionNumber: 93,
    value: '214',
    label: 'Southeast Louisiana',
  },
  {
    positionNumber: 94,
    value: '215',
    label: 'Norwela',
  },
  {
    positionNumber: 95,
    value: '216',
    label: 'Katahdin Area',
  },
  {
    positionNumber: 96,
    value: '218',
    label: 'Pine Tree',
  },
  {
    positionNumber: 97,
    value: '220',
    label: 'Baltimore Area',
  },
  {
    positionNumber: 98,
    value: '221',
    label: 'Mason-Dixon',
  },
  {
    positionNumber: 99,
    value: '224',
    label: 'Cape Cod and Islands',
  },
  {
    positionNumber: 100,
    value: '227',
    label: 'Spirit of Adventure',
  },
  {
    positionNumber: 101,
    value: '230',
    label: 'Nashua Valley',
  },
  {
    positionNumber: 102,
    value: '234',
    label: 'Western Massachusetts',
  },
  {
    positionNumber: 103,
    value: '244',
    label: 'Knox Trail',
  },
  {
    positionNumber: 104,
    value: '249',
    label: 'Old Colony',
  },
  {
    positionNumber: 105,
    value: '250',
    label: 'Northern Star',
  },
  {
    positionNumber: 106,
    value: '254',
    label: 'Mohegan',
  },
  {
    positionNumber: 107,
    value: '283',
    label: 'Twin Valley',
  },
  {
    positionNumber: 108,
    value: '286',
    label: 'Voyageurs Area',
  },
  {
    positionNumber: 109,
    value: '296',
    label: 'Central Minnesota',
  },
  {
    positionNumber: 110,
    value: '299',
    label: 'Gamehaven',
  },
  {
    positionNumber: 111,
    value: '302',
    label: 'Choctaw Area',
  },
  {
    positionNumber: 112,
    value: '303',
    label: 'Andrew Jackson',
  },
  {
    positionNumber: 113,
    value: '304',
    label: 'Pine Burr Area',
  },
  {
    positionNumber: 114,
    value: '306',
    label: 'Ozark Trails',
  },
].sort(function (a, b) {
  const nameA = a.label.toUpperCase();
  const nameB = b.label.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
});
const advancementsUnitTypes = [
  {
    positionNumber: 0,
    value: '1',
    label: 'Cub Scout Pack',
    short: 'Pack',
    minAge: '4',
    maxAge: '11',
    subUnitType: 'Den',
  },
  {
    positionNumber: 1,
    value: '2',
    label: 'Boy Scout Troop',
    short: 'Troop',
    minAge: '10',
    maxAge: '40',
    subUnitType: 'Patrol',
  },
  {
    positionNumber: 2,
    value: '3',
    label: 'Varsity Scout Team',
    short: 'Team',
    minAge: '14',
    maxAge: '40',
    subUnitType: 'Squad',
  },
  {
    positionNumber: 3,
    value: '4',
    label: 'Venturing Crew',
    short: 'Crew',
    minAge: '13',
    maxAge: '40',
    subUnitType: '',
  },
  {
    positionNumber: 4,
    value: '5',
    label: 'Sea Scout Ship',
    short: 'Ship',
    minAge: '13',
    maxAge: '40',
    subUnitType: '',
  },
];
const numberOf = [
  { positionNumber: 0, value: '0', label: '0' },
  { positionNumber: 1, value: '1', label: '1' },
  { positionNumber: 2, value: '2', label: '2' },
  { positionNumber: 3, value: '3', label: '3' },
  { positionNumber: 4, value: '4', label: '4' },
  { positionNumber: 5, value: '5', label: '5' },
];
const height = [
  { positionNumber: 0, value: '0', label: '50 in' },
  { positionNumber: 1, value: '1', label: '52 in' },
  { positionNumber: 2, value: '2', label: '54 in' },
  { positionNumber: 3, value: '3', label: '56 in' },
  { positionNumber: 4, value: '4', label: '58 in' },
  { positionNumber: 5, value: '5', label: '60 in' },
  { positionNumber: 6, value: '6', label: '62 in' },
  { positionNumber: 7, value: '7', label: '64 in' },
  { positionNumber: 8, value: '8', label: '66 in' },
  { positionNumber: 9, value: '9', label: '68 in' },
  { positionNumber: 10, value: '10', label: '70 in' },
  { positionNumber: 11, value: '11', label: '72 in' },
];

const defaultOptions = [
  { positionNumber: 0, value: '0', label: 'Option 1' },
  { positionNumber: 1, value: '1', label: 'Option 2' },
  { positionNumber: 2, value: '2', label: 'Option 3' },
  { positionNumber: 3, value: '3', label: 'Option 4' },
];

const fileTypeOptions = [
  { positionNumber: 0, label: 'PNG', value: 'png', isAllowed: true },
  { positionNumber: 1, label: 'JPG', value: 'jpg', isAllowed: true },
  { positionNumber: 2, label: 'PDF', value: 'pdf', isAllowed: false },
  { positionNumber: 3, label: 'DOC', value: 'doc', isAllowed: false },
  { positionNumber: 4, label: 'ANY', value: 'Any', isAllowed: false },
];
const region = [
  { positionNumber: 0, value: '0', label: 'Northeast' },
  { positionNumber: 1, value: '1', label: 'Midwest' },
  { positionNumber: 2, value: '2', label: 'South' },
  { positionNumber: 3, value: '3', label: 'West' },
];
const gender = [
  { positionNumber: 0, value: '0', label: 'Male' },
  { positionNumber: 1, value: '1', label: 'Female' },
  { positionNumber: 2, value: '2', label: 'Other' },
];
const arrivalMethod = [
  { positionNumber: 0, value: '0', label: 'Plane' },
  { positionNumber: 1, value: '1', label: 'Train' },
  { positionNumber: 2, value: '2', label: 'Bus' },
  { positionNumber: 3, value: '3', label: 'Car' },
];

const dropdownSetsObj = {
  ethnic_background: ethnicities,
  religious_preference: religion,
  gender: gender,
  region: region,
  council_state: states,
  state: states,
  council_name_text: councils2,
  height: height,
  unit_type: advancementsUnitTypes,
  arrival_jamboree_method: arrivalMethod,
  profilepicture: fileTypeOptions,
  defaultOptions: defaultOptions,
};

type DropdownSetsKeys = keyof typeof dropdownSetsObj;
type DropdownSetsValues = (typeof dropdownSetsObj)[DropdownSetsKeys] | typeof numberOf;

export const optionsArray = (formItemCode: string): DropdownSetsValues | undefined => {
  if (formItemCode in dropdownSetsObj) {
    return dropdownSetsObj[formItemCode as DropdownSetsKeys];
  }

  switch (formItemCode) {
    case 'years_scouting':
    case 'times_attending_jamboree':
    case 'years_high_adventure':
    case 'times_staff_jamboree':
    case 'family_size':
    case 'number_incomes_household':
    case 'number_parents_household':
      return numberOf;
  }
};
