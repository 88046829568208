import { combineEpics, Epic } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData } from '@/modules/data/duck/actions';
import { closeModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';

import {
  deleteDisclaimer,
  linkEventDisclaimer,
  unlinkEventDisclaimer,
  updateDisclaimer,
} from './actions';
import services from './services';

const updateDisclaimer$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(updateDisclaimer.request)),
    switchMap(({ payload }) =>
      services.updateDisclaimer$(payload).pipe(
        switchMap(() => {
          const { disclaimerCode } = payload;
          toastService.success(
            `Disclaimer ${disclaimerCode ? 'updated' : 'created'} successfully.`,
          );

          return of(
            refreshData({ dataType: 'formDisclaimers' }),
            refreshData({ dataType: 'disclaimerList' }),
            updateDisclaimer.success(),
            closeModal(),
          );
        }),
      ),
    ),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return merge(of(updateDisclaimer.failure(error)), caught);
    }),
  );

const deleteDisclaimer$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(deleteDisclaimer.request)),
    switchMap(({ payload }) => services.deleteDisclaimer$(payload)),
    switchMap(() =>
      of(
        deleteDisclaimer.success(),
        refreshData({ dataType: 'disclaimerList' }),
        refreshData({ dataType: 'formDisclaimers' }),
      ),
    ),
    catchError((err: Error, caught) => {
      toastService.error(err.message);
      return merge(of(deleteDisclaimer.failure(err)), caught);
    }),
  );

const linkEventDisclaimer$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(linkEventDisclaimer.request)),
    switchMap(({ payload }) => services.updateDisclaimerForm$(payload)),
    switchMap(() => {
      toastService.success('Disclaimer added to the event.');
      return of(
        linkEventDisclaimer.success(),
        refreshData({ dataType: 'formDisclaimers' }),
        refreshData({ dataType: 'disclaimerList' }),
      );
    }),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return merge(of(linkEventDisclaimer.failure(error)), caught);
    }),
  );

const unlinkEventDisclaimer$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(unlinkEventDisclaimer.request)),
    switchMap(({ payload }) => services.unlinkEventDisclaimer$(payload)),
    switchMap(() => {
      toastService.success('Disclaimer removed from event.');
      return of(
        unlinkEventDisclaimer.success(),
        refreshData({ dataType: 'formDisclaimers' }),
        refreshData({ dataType: 'disclaimerList' }),
      );
    }),
    catchError((error: Error, caught) => {
      toastService.error('Failed to remove disclaimer');
      return merge(of(unlinkEventDisclaimer.failure(error)), caught);
    }),
  );

export default combineEpics(
  updateDisclaimer$,
  deleteDisclaimer$,
  linkEventDisclaimer$,
  unlinkEventDisclaimer$,
);
