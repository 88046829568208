import React, { useCallback, useMemo } from 'react';
import { VariableElement, Text } from 'slate';
import { useSelected, useFocused } from 'slate-react';

import Leaf from '../../../Leaf';
import VariableCaption from '../../../VariableCaption';
import { ElementProps } from '../../types';

const Variable: React.FC<ElementProps> = props => {
  const { element, children } = props;
  const { value, caption } = element as VariableElement;
  const serializing = 'serializing' in props && !!props.serializing;
  const selected = useSelected();
  const focused = useFocused();

  const content = useMemo(() => {
    if (serializing) return value;
    return caption || value;
  }, [serializing, caption, value]);

  const theChildren = useMemo(() => {
    if (serializing) return null;
    return children;
  }, [children, serializing]);

  const renderLeaf = useCallback(
    () => (
      <Leaf leaf={element as unknown as Text}>
        {content}
        {theChildren}
      </Leaf>
    ),
    [content, element, theChildren],
  );

  if ('serializing' in props) {
    return renderLeaf();
  }

  return (
    <VariableCaption
      slateAttributes={props.attributes}
      isSelected={selected && focused}
      notFound={!caption}
    >
      {renderLeaf()}
    </VariableCaption>
  );
};

export default Variable;
