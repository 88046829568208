import React from 'react';
import { useDispatch } from 'react-redux';

import { logout } from '@/modules/user/duck/actions';

import { BigButton, Card, Centered, LargeText } from './WrongLoggedUserCard.styled';

const WrongLoggedUserCard = (): JSX.Element => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout.request(undefined));
  };

  return (
    <Card title="Unauthorized">
      <Centered>
        <LargeText>
          This invitation was sent to a different user than the one currently logged in. <br /> If
          it belongs to you, please log out and back in as the correct user.
        </LargeText>
        <BigButton type="primary" onClick={handleLogout}>
          Logout
        </BigButton>
      </Centered>
    </Card>
  );
};

export default WrongLoggedUserCard;
