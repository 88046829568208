import React from 'react';
import { FormattedNumber } from 'react-intl';

type Props = {
  value: number;
  currencyCode?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const Currency: React.FC<Props> = ({
  value,
  currencyCode = 'USD',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
}) => (
  <FormattedNumber
    style="currency"
    minimumFractionDigits={minimumFractionDigits}
    maximumFractionDigits={maximumFractionDigits}
    value={value}
    currency={currencyCode}
    currencyDisplay="symbol"
  />
);

export default Currency;
