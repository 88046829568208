import { Input as AntInput } from 'antd';
import styled from 'styled-components';

export const Input = styled(AntInput)`
  .ant-input-group-addon {
    border: 0;
  }
  input {
    border: 0;
  }
  .ant-input {
    :focus {
      box-shadow: none;
    }
  }
`;
