import { Form, Input } from 'antd';
import { sortBy } from 'lodash';
import React, { useState, useMemo, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Descendant, Element } from 'slate';

import { EmailTemplate } from '@/modules/data/dataTypes/emailTemplate';
import { QueryFilterVariable } from '@/modules/data/dataTypes/queryFilterVariableList';
import RichTextEditor from '@/modules/richTextEditor';
import {
  isEmptyValue,
  fromHTML,
  fromString,
  toHTML,
  toString,
} from '@/modules/richTextEditor/utils';
import Divider from '@/modules/shared/components/Divider';

import { makeVariableCanBeUsedInTemplate } from '../../utils';

import { ContentFormItem, RichTextEditorRow } from './TemplateEditor.styled';
import VariablesList from './components/VariablesList';
import { TrimmedVariable } from './types';

const FIELD_NAME = 'name';
const FIELD_DESCRIPTION = 'description';
const FIELD_SUBJECT = 'subject';
const FIELD_CONTENT = 'content';

type Props = {
  template: EmailTemplate | Record<string, never>;
  queryFilterVariables: QueryFilterVariable[];
};

const TemplateEditor = forwardRef<
  {
    getTemplateValues: () => Promise<{
      [FIELD_CONTENT]: string;
      [FIELD_DESCRIPTION]: string;
      [FIELD_NAME]: string;
      [FIELD_SUBJECT]: string;
    }>;
  },
  Props
>(({ template, queryFilterVariables }, ref) => {
  useImperativeHandle(ref, () => ({
    getTemplateValues: handleSubmit,
  }));
  const [form] = Form.useForm<{
    [FIELD_CONTENT]: Descendant[];
    [FIELD_SUBJECT]: Descendant[];
    [FIELD_NAME]: string;
    [FIELD_DESCRIPTION]: string;
  }>();

  const [variableToInsert, setVariableToInsert] = useState<TrimmedVariable | undefined>();

  const variables: TrimmedVariable[] = useMemo(() => {
    const variableCanBeUsedInTemplate = makeVariableCanBeUsedInTemplate(template);

    const convertedVariables = queryFilterVariables
      .filter(variableCanBeUsedInTemplate)
      .map(({ caption, value }) => ({
        value: `{{${value}}}`,
        caption,
      }));

    return sortBy(convertedVariables, 'caption');
  }, [queryFilterVariables, template]);

  const templateSubjectInitialValue = useMemo(() => {
    if (template) {
      return fromString(template.emailSubject || '', variables);
    }
  }, [template, variables]);

  const templateContentInitialValue = useMemo(() => {
    if (template) {
      return fromHTML(template.emailTemplateContent || '', variables);
    }
  }, [template, variables]);

  useEffect(() => {
    const subjectValue = form.getFieldValue(FIELD_SUBJECT);
    if (subjectValue) {
      form.resetFields([FIELD_SUBJECT]);
    }
  }, [templateSubjectInitialValue, form]);

  useEffect(() => {
    const contentValue = form.getFieldValue(FIELD_CONTENT);
    if (contentValue) {
      form.resetFields([FIELD_CONTENT]);
    }
  }, [templateContentInitialValue, form]);

  const handleSubmit = () =>
    form.validateFields().then(({ name, description, ...values }) => {
      const content = toHTML(values.content);
      const subject = toString(values.subject);

      return {
        description,
        content,
        name,
        subject,
      };
    });

  const handleInsertVariable = (variable: TrimmedVariable) => {
    setVariableToInsert({ ...variable });
  };

  useEffect(() => form.resetFields(), [form, template]);

  return (
    <Form form={form} labelCol={{ span: 4 }}>
      <Form.Item
        name={FIELD_NAME}
        label="Template Name"
        initialValue={template?.emailTemplateName}
        rules={[
          {
            required: true,
            message: 'Template Name is required',
          },
        ]}
      >
        <Input size="large" disabled={!!template.emailTemplateCode} />
      </Form.Item>
      <Form.Item
        name={FIELD_DESCRIPTION}
        label="Template Description"
        initialValue={template?.description}
      >
        <Input.TextArea size="large" />
      </Form.Item>
      <Divider />
      <Form.Item
        name={FIELD_SUBJECT}
        label="Subject"
        initialValue={templateSubjectInitialValue}
        required
        rules={[
          {
            validator: (_rule: unknown, value: Element[]) => {
              if (isEmptyValue(value)) {
                return Promise.reject(new Error('Email Subject is required'));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <RichTextEditor isSimpleText variableToInsert={variableToInsert} variables={variables} />
      </Form.Item>
      <Divider />
      <RichTextEditorRow>
        <ContentFormItem
          name={FIELD_CONTENT}
          initialValue={templateContentInitialValue}
          rules={[
            {
              validator: (_rule: unknown, value: Element[]) => {
                if (isEmptyValue(value)) {
                  return Promise.reject(new Error('Email Content is required'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <RichTextEditor variables={variables} variableToInsert={variableToInsert} />
        </ContentFormItem>
        <VariablesList variables={variables} onClick={handleInsertVariable} />
      </RichTextEditorRow>
    </Form>
  );
});

export default TemplateEditor;
