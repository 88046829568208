import { Tooltip } from 'antd';
import React from 'react';

import { Card, Step, Steps } from './Stepper.styled';

interface StepType {
  title: string;
  key?: string | number;
  path?: string;
}

type Props = {
  stepIndex: number;
  steps: StepType[];
  disabledSteps?: Record<string, string>;
};

const Stepper: React.FC<Props> = ({ stepIndex, steps, disabledSteps }) => (
  <Card>
    <Steps current={stepIndex} labelPlacement="vertical">
      {steps &&
        steps.map(({ title, key, path }) => {
          const stepKey = key === undefined ? path || title : key;
          const disabledMessage = disabledSteps && disabledSteps[stepKey];
          return (
            <Step
              key={stepKey}
              progressDot={dot => <Tooltip title={disabledMessage}>{dot}</Tooltip>}
              title={title}
              $disabled={!!disabledMessage}
            />
          );
        })}
    </Steps>
  </Card>
);

export default Stepper;
