import { Form } from 'antd';
import React from 'react';

import { FILTER_EXPRESSION_CONDITION } from '../../../../../../constants';

import { Plug, Select } from './SelectCondition.styled';

type Props = {
  name: number;
};

const SelectCondition: React.FC<Props> = ({ name }) => {
  if (name === 0) {
    return <Plug>SCOPE</Plug>;
  }

  return (
    <Form.Item name={[name, FILTER_EXPRESSION_CONDITION]} initialValue="AND">
      <Select>
        <Select.Option value="AND">AND</Select.Option>
        <Select.Option value="OR">OR</Select.Option>
      </Select>
    </Form.Item>
  );
};

export default SelectCondition;
