import { createAsyncAction } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';

export const createFormAttribute = createAsyncAction(
  `${moduleName}/CREATE_FORM_ATTRIBUTE_REQUEST`,
  `${moduleName}/CREATE_FORM_ATTRIBUTE_SUCCESS`,
  `${moduleName}/CREATE_FORM_ATTRIBUTE_FAILURE`,
)<
  {
    formCode: string;
    attributeCode: string;
    attributeName: string;
  },
  {
    attributeCode: string;
  },
  ApiError
>();

export const updateAttendeesAttributes = createAsyncAction(
  `${moduleName}/UPDATE_ATTENDEES_ATTRIBUTES_REQUEST`,
  `${moduleName}/UPDATE_ATTENDEES_ATTRIBUTES_SUCCESS`,
  `${moduleName}/UPDATE_ATTENDEES_ATTRIBUTES_FAILURE`,
)<
  {
    formCode: string;
    attributes: string[];
    isDelete: boolean;
    attendees: string[];
    finalActions?: { type: string }[];
  },
  void,
  Error
>();

export const createAndSelectAttendeeAttribute = createAsyncAction(
  `${moduleName}/CREATE_AND_SELECT_ATTENDEES_ATTRIBUTES_REQUEST`,
  `${moduleName}/CREATE_AND_SELECT_ATTENDEES_ATTRIBUTES_SUCCESS`,
  `${moduleName}/CREATE_AND_SELECT_ATTENDEES_ATTRIBUTES_FAILURE`,
)<
  {
    formCode: string;
    attributeName: string;
    attendeeFormRecordGUID: string;
    assignedAttributes: string[];
  },
  undefined,
  Error
>();
