export enum Tags {
  Attendee = 'ATTENDEE',
  Disclaimer = 'DISCLAIMER',
  Group = 'GROUP',
  Invitation = 'INVITATION',
  Job = 'JOB',
  Payment = 'PAYMENT',
  PaymentSummary = 'PAYMENTSUMMARY',
}

export const tagLabels = {
  [Tags.Attendee]: 'Attendee',
  [Tags.Disclaimer]: 'Disclaimer',
  [Tags.Group]: 'Group',
  [Tags.Invitation]: 'Invitation',
  [Tags.Job]: 'Job',
  [Tags.Payment]: 'Payment',
  [Tags.PaymentSummary]: 'Payment Summary',
};

export enum CustomTemplateApiNames {
  Attendee = 'GetFormData',
  Group = 'GetReservationGroupList',
}
