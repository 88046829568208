class Ability {
  constructor(defaultPerformer) {
    this.abilities = [];
    this.defaultPerformer = defaultPerformer;
  }

  allow({ actions, targets = 'all', model = this.defaultPerformer, condition }) {
    (Array.isArray(actions) ? [...actions] : [actions]).forEach(action => {
      (Array.isArray(targets) ? [...targets] : [targets]).forEach(target => {
        this.abilities.push({ model, action, target, condition });
      });
    });
  }

  can({ action, target, performer = this.defaultPerformer, options = {} }) {
    return (
      this.abilities
        .filter(ability => performer === ability.model || performer instanceof ability.model)
        .filter(
          ability =>
            ability.target === 'all' ||
            target === ability.target ||
            (typeof ability.target === 'object' && target instanceof ability.target),
        )
        .filter(ability => action === ability.action)
        .filter(ability => {
          if (ability.condition) {
            return ability.condition(performer, target, options);
          }
          return true;
        }).length > 0
    );
  }

  cannot() {
    return !this.can.apply(this, arguments);
  }

  authorize() {
    if (this.cannot.apply(this, arguments)) {
      throw new Error('Authorization Error');
    }
  }
}

export default Ability;
