import React from 'react';

import { PaymentMethod } from '@/modules/payments/constants';
import Currency from '@/modules/shared/components/Currency';
import InfoBubble from '@/modules/shared/components/InfoBubble';

import { MAX_ORBITAL_PAYMENT_AMOUNT } from '../../constants';

type Props = {
  paymentMethod: PaymentMethod | null;
};

const MaxAmountInfoBubble: React.FC<Props> = ({ paymentMethod }) => {
  if (paymentMethod !== PaymentMethod.eCheckOrbital) return null;

  return (
    <InfoBubble>
      The maximum allowed single-payment value is{' '}
      <Currency
        value={MAX_ORBITAL_PAYMENT_AMOUNT}
        maximumFractionDigits={0}
        minimumFractionDigits={0}
      />
      . To submit payments over $25,000 please split your payments into multiple smaller amounts.
    </InfoBubble>
  );
};

export default MaxAmountInfoBubble;
