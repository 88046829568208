import { Radio as AntRadio } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Radio = styled(AntRadio)`
  margin-bottom: 25px;
`;

export const DisclaimersMessageWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    color: ${colors.lightBlue};
  }
`;
