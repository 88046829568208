import { SearchOutlined } from '@ant-design/icons';
import { Row, Col, Input } from 'antd';
import { Dayjs } from 'dayjs';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { EventItem } from '@/modules/data/dataTypes/eventList';
import { removeFilter, setFilter } from '@/modules/data/duck/actions';
import { createFiltersSel } from '@/modules/data/duck/selectors';
import Label from '@/modules/shared/components/Label';

import { FiltersCard, DatePicker, Select } from './EventsFilter.styled';

import { dateFromString, dateToString } from 'UTILS/dateFormats';

interface Props {
  events: EventItem[];
}

type SortOrderAttributes = '@CreationDate' | '@EventStartDate' | '@RegistrationStartDate';

const EVENT_NAME_FILTER = 'eventName';
const EVENT_DATE_FILTER = 'eventDate';
const SORT_ORDER = 'sortOrder';

interface Filters {
  [EVENT_NAME_FILTER]?: string;
  [EVENT_DATE_FILTER]?: string;
  [SORT_ORDER]?: SortOrderAttributes;
}

const EventsFilter: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const filters: Filters = useSelector(createFiltersSel('personFormList'));

  const handleChangeFilter = (key: keyof Filters, value: string | null) => {
    dispatch(
      value
        ? setFilter({
            dataType: 'personFormList',
            key,
            value,
          })
        : removeFilter({ dataType: 'personFormList', key }),
    );
  };

  const handleSortOrderChange = (sortOrder: SortOrderAttributes) => {
    handleChangeFilter(SORT_ORDER, sortOrder);
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeFilter(EVENT_NAME_FILTER, e.target.value);
  };

  const handleDateChange = (nextDate: Dayjs | null) => {
    handleChangeFilter(EVENT_DATE_FILTER, nextDate && dateToString(nextDate));
  };

  return (
    <FiltersCard>
      <Row gutter={10}>
        <Col md={12}>
          <Label label="Search by Event Name">
            <Input
              value={filters[EVENT_NAME_FILTER]}
              onChange={handleNameChange}
              defaultValue={filters.eventName}
              size="large"
              suffix={<SearchOutlined />}
            />
          </Label>
        </Col>
        <Col md={6}>
          <Label label="Sort By">
            <Select
              allowClear
              onChange={handleSortOrderChange}
              value={filters[SORT_ORDER]}
              size="large"
              options={[
                {
                  label: 'Date created',
                  value: '@CreationDate',
                },
                {
                  label: 'Date event starts',
                  value: '@EventStartDate',
                },
                {
                  label: 'Date event registration begins',
                  value: '@RegistrationStartDate',
                },
              ]}
            />
          </Label>
        </Col>
        <Col md={6}>
          <Label label="Select Date">
            <DatePicker
              allowClear
              id="dateFilter"
              value={filters[EVENT_DATE_FILTER] ? dateFromString(filters[EVENT_DATE_FILTER]) : null}
              onChange={handleDateChange}
              size="large"
            />
          </Label>
        </Col>
      </Row>
    </FiltersCard>
  );
};

export default EventsFilter;
