import { get } from 'lodash';
import { combineEpics, Epic } from 'redux-observable';
import { concat, EMPTY, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { FormSettings } from '@/modules/data/dataTypes/form';
import { scheduleRefresh } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import eventEntitiesServices from '@/modules/entities/Events/duck/services';
import { routeSel } from '@/modules/routing/duck/selectors';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { ROUTE_CREATE_EVENT_SETTINGS_JOBS, steps } from '../constants';
import activitiesEpics$ from '../steps/Activities/duck/epics';
import addonEpics$ from '../steps/Addons/duck/epics';
import attendeeTypesEpics$ from '../steps/AttendeeTypes/duck/epics';
import collaboratorsEpics$ from '../steps/Collaborators/duck/epics';
import disclaimersEpics$ from '../steps/Disclaimers/duck/epics';
import formBuilderEpics$ from '../steps/FormBuilder/duck/epics';
import JobsEpics$ from '../steps/JobsPositions/duck/epics';
import ledgerAccountEpics$ from '../steps/LedgerAccounts/duck/epics';
import multiSessionRulesEpics$ from '../steps/MultiSessionCapacityRules/duck/epics';
import installmentsEpics$ from '../steps/PaymentSchedule/duck/epics';
import programsEpics$ from '../steps/Programs/duck/epics';
import sessionEpics$ from '../steps/Sessions/duck/epics';

import { createForm, openNextStep } from './actions';
import { currentStepIndexSel, isStaffEventSel } from './selectors';
import services from './services';

const openNextStep$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(openNextStep)),
    switchMap(() => {
      const state = state$.value;
      const currentStepIndex = currentStepIndexSel(state);
      const subSteps = get(steps, [currentStepIndex, 'subSteps']);
      const { formCode } = createDataSel('form')(state) || {};
      const isStaff = isStaffEventSel(state);
      if (subSteps) {
        const currentRoute = routeSel(state);
        const currentSubStepIndex = subSteps.findIndex(({ key }) => key === currentRoute);
        let nextSubStepRoute = get(subSteps, [currentSubStepIndex + 1, 'key']);

        if (!isStaff && nextSubStepRoute === ROUTE_CREATE_EVENT_SETTINGS_JOBS) {
          nextSubStepRoute = get(subSteps, [currentSubStepIndex + 2, 'key']);
        }

        if (nextSubStepRoute) {
          return of({
            type: nextSubStepRoute,
            payload: {
              formCode,
            },
          });
        }
      }

      const nextStepRoute = get(steps, [currentStepIndex + 1, 'key']);

      if (nextStepRoute)
        return of({
          type: nextStepRoute,
          payload: {
            formCode,
          },
        });

      return EMPTY;
    }),
  );

const updateForm$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(createForm.request)),
    switchMap(({ payload }) => {
      const { formData, finalActions = [] } = payload;
      const { formCode } = formData;
      const shouldUpdate = !!formCode;

      const createUpdateForm = shouldUpdate
        ? eventEntitiesServices.updateEvent$
        : services.createForm$;

      return createUpdateForm({ ...formData, isCreator: true } as FormSettings).pipe(
        switchMap(({ formCode: responseFormCode }) =>
          concat(
            formCode
              ? of(scheduleRefresh({ dataType: 'form' }))
              : prefetchData$(action$, {
                  dataType: 'form',
                  queryObj: { formCode: responseFormCode },
                }),
            of(createForm.success(), ...finalActions),
          ),
        ),
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(createForm.failure(error));
        }),
      );
    }),
  );

export default combineEpics(
  activitiesEpics$,
  addonEpics$,
  multiSessionRulesEpics$,
  openNextStep$,
  JobsEpics$,
  ledgerAccountEpics$,
  collaboratorsEpics$,
  attendeeTypesEpics$,
  programsEpics$,
  sessionEpics$,
  disclaimersEpics$,
  installmentsEpics$,
  formBuilderEpics$,
  updateForm$,
);
