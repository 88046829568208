import { Form, Select } from 'antd';
import React, { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchData } from '@/modules/data/duck/actions';
import { createDataSel, createIsLoadingSel } from '@/modules/data/duck/selectors';
import TemplateEditor from '@/modules/entities/Emails/components/TemplateEditor';
import { updateEmailTemplate } from '@/modules/entities/Emails/duck/actions';
import ModalComponent from '@/modules/modals/components/ModalComponent/ModalComponent';
import LoadingContainer from '@/modules/shared/components/LoadingContainer/LoadingContainer';
import toastService from '@/modules/toasts/service';

import { CustomTemplateApiNames } from '../../constants';

import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const API_NAME_FIELD = 'apiName';

const UpdateEmailTemplateModal: React.FC<Props> = ({
  modalParams: { template, templateList, formSettings },
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<{ [API_NAME_FIELD]: CustomTemplateApiNames | null }>();
  const apiName = Form.useWatch(API_NAME_FIELD, form);

  const variables = useSelector(createDataSel('queryFilterVariables'));
  const variablesIsLoading = useSelector(createIsLoadingSel('queryFilterVariables'));

  const { formCode } = formSettings;
  const {
    senderEmailAddress = 'noreply-EM@scouting.org',
    senderName = 'Event Management Platform',
  } = template;
  const { emailTemplateCode } = template;
  const isNewTemplate = !emailTemplateCode;
  const templateEditorRef = createRef<{
    getTemplateValues: () => Promise<{
      content: string;
      description: string;
      name: string;
      subject: string;
    }>;
  }>();

  useEffect(() => {
    if (!apiName) return;

    dispatch(
      fetchData.request({
        dataType: 'queryFilterVariables',
        queryObj: {
          formCode,
          apiName,
        },
      }),
    );
  }, [dispatch, apiName, formCode]);

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    form.validateFields().then(() => {
      templateEditorRef.current
        ?.getTemplateValues()
        .then(data => {
          const { content, description, name, subject } = data;

          if (!content || !name || !subject || !apiName) return;

          const isNameAlreadyInUse = templateList.some(
            t => t.emailTemplateName.toLocaleLowerCase() === name.toLocaleLowerCase(),
          );

          if (isNewTemplate && isNameAlreadyInUse) {
            toastService.error('Template Name is already in use');
            return;
          }

          dispatch(
            updateEmailTemplate.request({
              ...template,
              formCode,
              description,
              emailTemplateName: name,
              emailSubject: subject,
              emailTemplateContent: content,
              apiName,
              senderEmailAddress,
              senderName,
            }),
          );
        })
        .catch(reason => {
          toastService.error(reason);
        });
    });
  };

  return (
    <ModalComponent
      title={`${emailTemplateCode ? 'Update' : 'Create'} Email Template`}
      buttons={[
        { onClick: handleSubmit, title: emailTemplateCode ? 'Update' : 'Create', type: 'primary' },
      ]}
    >
      <Form form={form} labelCol={{ span: 4 }}>
        <Form.Item
          name={API_NAME_FIELD}
          initialValue={template.apiName}
          label="Select a Category"
          rules={[
            {
              required: true,
              message: 'Please select a category',
            },
          ]}
        >
          <Select
            options={[
              { value: CustomTemplateApiNames.Attendee, label: 'Attendee' },
              { value: CustomTemplateApiNames.Group, label: 'Group' },
            ]}
            size="large"
          />
        </Form.Item>
      </Form>
      <LoadingContainer isLoading={variablesIsLoading}>
        {variables && (
          <TemplateEditor
            ref={templateEditorRef}
            template={template}
            queryFilterVariables={variables}
          />
        )}
      </LoadingContainer>
    </ModalComponent>
  );
};

export default UpdateEmailTemplateModal;
