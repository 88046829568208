import React from 'react';

import { Payment } from '@/modules/data/dataTypes/paymentList';
import { getPaymentMethodLabel } from '@/modules/payments/utils';
import { dateFromString, US_DATE_FORMAT } from '@/modules/utils/dateFormats';
import { CURRENCY_FORMAT } from '@/modules/utils/numberFormats';

import { RefundInformationRow } from './RefundInfo.styled';

type Props = {
  payment: Payment;
};

const RefundInfo: React.FC<Props> = ({ payment }) => {
  const { creationDate, amount } = payment;
  const paymentDate = dateFromString(creationDate);
  return (
    <RefundInformationRow>
      <span>
        <b>Refund Information</b>
      </span>
      <span>
        <b>Date: </b>
        {paymentDate.format(US_DATE_FORMAT)}
      </span>
      <span>
        <b>Payment Method: </b>
        {getPaymentMethodLabel(payment)}
      </span>
      <span>
        <b>Amount Paid: </b>
        {CURRENCY_FORMAT(amount)}
      </span>
    </RefundInformationRow>
  );
};

export default RefundInfo;
