import { Button, Typography } from 'antd';
import Reaptcha from 'reaptcha';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

// TODO check types
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const Captcha = styled(Reaptcha)`
  margin-bottom: 25px;
`;

export const CreateAccountButton = styled(Button)<{ $isMobile: boolean }>`
  align-items: center;
  box-shadow: none;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  height: 48px;
  justify-content: center;
  margin: 0 auto 24px auto;
  max-width: ${({ $isMobile }) => ($isMobile ? '280px' : '284px')};
  text-align: center;
  width: 100%;

  &&& {
    color: ${colors.lightBlue};
  }
`;

export const NoAccountMessage = styled(Typography.Title)`
  && {
    color: ${colors.gray};
    margin: 0 auto 8px auto;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 1.14;
  }
`;

export const PasswordReminder = styled.a`
  color: ${colors.scoutingRed};
  font-size: 14px;
  line-height: 1.4;
`;

export const PasswordReminderContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 24px;
  margin-top: -8px;
`;

export const SocialMediaContainer = styled.div<{ $isMobile: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 96px;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ $isMobile }) => ($isMobile ? '280px' : '284px')};
  width: 100%;
`;

export const Version = styled.div`
  bottom: 10px;
  color: ${colors.scoutingBlue};
  position: absolute;
  right: 10px;
`;
