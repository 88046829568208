import styled from 'styled-components';

export const DeclineReasonCounter = styled.div`
  margin-top: 4px;
  font-size: 12px;
  float: right;
`;

export const Body = styled.div`
  margin-bottom: 18px;
`;
