import React, { Fragment } from 'react';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { Comment } from '@/modules/data/dataTypes/commentList';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import { PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { useAbilities } from '@/modules/user/duck/abilities';
import { Abilities } from '@/modules/user/types';

import ReservationComments from '../ReservationComments';

import CSS from './MyReservation.less';
import CancellationBanner from './components/CancellationBanner';
import CancellationReason from './components/CancellationReason';
import ReservationDetails from './components/ReservationDetails';
import RostersList from './components/RostersList';
import SecondaryRegistrantsCard from './components/SecondaryRegistrantsCard';

type BaseProps = {
  paymentCategories: PaymentCategory[];
  paymentSummary: PaymentSummary;
  form: FormSettings;
  comments: Comment[];
};

type GroupProps = BaseProps & {
  isGroup: true;
  groupReservation: GroupReservation;
};

type IndividualProps = BaseProps & {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};

type Props = GroupProps | IndividualProps;

const MyReservation: React.FC<Props> = props => {
  const abilities: Abilities = useAbilities();

  const { isGroup, paymentSummary, form } = props;

  const { isCreator, isCollaborator, allowSecondaryRegistration } = form;

  const isCreatorOrCollaborator = isCreator || isCollaborator;

  const canViewRosters =
    isGroup &&
    abilities.can({
      action: 'view',
      target: 'reservationPage.rosters',
      options: props.groupReservation,
    });

  return (
    <Fragment>
      <CancellationBanner {...props} showCancelledByMessage={isCreatorOrCollaborator} />
      <CancellationReason {...props} />
      <div className={CSS.cardsWrapper}>
        <ReservationDetails {...props} paymentSummary={paymentSummary} />
        {canViewRosters && <RostersList {...props} />}
        {!props.isGroup && allowSecondaryRegistration && <SecondaryRegistrantsCard />}
      </div>
      {isCreator && isCollaborator && <ReservationComments {...props} />}{' '}
    </Fragment>
  );
};

export default MyReservation;
