import React from 'react';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';
import { RootState } from 'typesafe-actions';

import { VERSION } from '@/modules/config/config';
import { fullNameSel, isLoggedInSel } from '@/modules/user/duck/selectors';

import styles from './Header.less';
import { HeaderWrapper } from './Header.styled';
import AuthHeader from './components/AuthHeader';

type Props = ReturnType<typeof mapState>;

const Header: React.FC<Props> = ({ isLoggedIn, fullName }) => (
  <HeaderWrapper>
    {isLoggedIn ? (
      <AuthHeader fullName={fullName} version={VERSION} />
    ) : (
      <Link className={styles.welcome} to="/login">
        Login
      </Link>
    )}
  </HeaderWrapper>
);

const mapState = (
  state: RootState,
): {
  fullName?: string;
  isLoggedIn: boolean;
} => ({
  fullName: fullNameSel(state),
  isLoggedIn: isLoggedInSel(state),
});

export default connect(mapState)(Header);
