import { CardProps } from 'antd/lib/card';
import cn from 'classnames';
import React from 'react';

import styles from './Card.less';
import { TheCard } from './Card.styled';

interface Props extends CardProps {
  margin?: boolean;
  bordered?: boolean;
  hoverable?: boolean;
  noShadow?: boolean;
  className?: string;
  loading?: boolean;
}

const Card: React.FC<Props> = ({ margin, bordered, hoverable, className, noShadow, ...props }) => {
  const classNames = cn(
    styles.card,
    {
      [styles.margin]: margin,
      [styles.noShadow]: noShadow,
    },
    className,
  );

  return <TheCard className={classNames} bordered={bordered} hoverable={hoverable} {...props} />;
};

Card.defaultProps = {
  margin: false,
  bordered: false,
  hoverable: false,
  noShadow: false,
};

export default Card;
