import { Button as BsaButton } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

import DropdownWithClassName from './components/DropdownWithClassName';

export const Button = styled(BsaButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  height: auto;
  color: ${colors.scoutingBlue};
  background-color: ${colors.white};

  :hover {
    background-color: ${colors.white};
  }
`;

export const StyledDropdown = styled(DropdownWithClassName)`
  .ant-dropdown-menu-item {
    :not(:last-child) {
      border-bottom: 1px solid #d9d9d9;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    :not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;

export const FakeIcon = styled.span<{ $size: number }>`
  width: ${({ $size }) => $size}px;
`;
