import { truncate } from 'lodash';
import React, { Fragment } from 'react';
import shortid from 'shortid';

import {
  MAX_DESCRIPTION_CHARS,
  manualEmailMethods,
} from '@/modules/entities/Logs/components/EventLogs/constants';

import { FormattedLogItem, LogParams } from './types';

export const formatLogItem = <T extends LogParams>(...params: T): FormattedLogItem<T> => {
  const [type, item] = params;

  switch (type) {
    case 'email': {
      const { sentDate, scriptCode, usedEmailAddress, emailTemplateName, emailType, methodName } =
        item;
      const automaticDescription = manualEmailMethods.includes(scriptCode)
        ? 'Collaborator'
        : 'Automated System';

      let title = emailTemplateName;

      if (!title && emailType === 'Automated' && methodName === 'ProcessNextMailing') {
        title = 'Report: Custom Mail - Upon Custom Report Mailing';
      }

      return {
        type,
        baseData: { ...item, emailTemplateName: title },
        date: sentDate,
        description: (
          <Fragment>
            <div>From: {automaticDescription}</div>
            <div>To: {usedEmailAddress}</div>
          </Fragment>
        ),
        itemId: shortid.generate(),
        title,
      };
    }
    case 'comment': {
      const { comment, updateDate, commentCode, firstName, lastName } = item;
      return {
        type,
        baseData: item,
        date: updateDate,
        description: truncate(comment, { length: MAX_DESCRIPTION_CHARS }) || '',
        itemId: commentCode,
        title: `${firstName} ${lastName}`,
      };
    }
    case 'loggedAction': {
      const { actionDate, action, actionId, firstName, lastName } = item;

      const descriptionsObj: Record<Exclude<typeof action, undefined>, string> = {
        Delete: 'Deleted a record',
        Insert: 'Created a record',
        Update: 'Updated a Record',
      };

      return {
        type,
        baseData: item,
        date: actionDate,
        description: descriptionsObj[action || 'Update'],
        itemId: actionId,
        title: `By ${firstName} ${lastName}`,
      };
    }
  }
};
