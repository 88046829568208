import dayjs from 'dayjs';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import type { PaymentSummary as PaymentSummaryType } from '@/modules/data/dataTypes/paymentSummary';
import { useAbilities } from '@/modules/user/duck/abilities';
import { isEADSel } from '@/modules/user/duck/selectors';
import { Abilities } from '@/modules/user/types';
import { dateFromString, SHORT_DATE } from '@/modules/utils/dateFormats';

import styles from './PaymentSummary.less';
import { DynamicValueWithLabel } from './PaymentSummary.styled';

import ValueWithLabel from 'SHARED/components/ValueWithLabel';
import { CURRENCY_FORMAT } from 'UTILS/numberFormats';

type BaseOwnProps = {
  paymentSummary: PaymentSummaryType;
};

type GroupProps = BaseOwnProps & {
  isGroup: true;
  groupReservation: GroupReservation;
};

type IndividualProps = BaseOwnProps & {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};

type Props = GroupProps | IndividualProps;

const PaymentSummary: React.FC<Props> = props => {
  const abilities: Abilities = useAbilities();

  const { isGroup, paymentSummary } = props;
  const { totalFee, amountPaid, nextPaymentAmountDue, nextPaymentDueDate } = paymentSummary;
  const reservationDetails = isGroup ? props.groupReservation : props.attendeeDetails;

  const isEad = useSelector((state: RootState) => isEADSel(state, reservationDetails));

  const totalAmountDue = totalFee - amountPaid;

  const nextDueDate = dateFromString(nextPaymentDueDate);
  const isOverdue = nextDueDate.isBefore(dayjs(), 'day');

  const canViewEventCostDetails = abilities.can({
    action: 'view',
    target: 'reservationPage.eventCostDetails',
    options: {
      isEADPaymentEnabled: isEad,
      reservationDetails: reservationDetails,
    },
  });

  return (
    <Fragment>
      <ValueWithLabel
        label={isGroup ? 'Reservation Expedition Number' : 'Reservation Number'}
        value={
          isGroup
            ? props.groupReservation.groupReservationExpeditionNumber
            : props.attendeeDetails.reservationCode
        }
        className={styles.headerValue}
        wrapperClassName={styles.headerLabel}
      />
      {canViewEventCostDetails && (
        <Fragment>
          <ValueWithLabel
            label="Total Event Cost"
            value={CURRENCY_FORMAT(totalFee)}
            className={styles.headerValue}
            wrapperClassName={styles.headerLabel}
          />
          <ValueWithLabel
            label="Total Amount Paid:"
            value={CURRENCY_FORMAT(amountPaid)}
            wrapperClassName={styles.paymentRow}
          />
          <ValueWithLabel
            label="Total Amount Due:"
            value={CURRENCY_FORMAT(totalAmountDue)}
            wrapperClassName={styles.paymentRow}
          />
          {!!nextPaymentDueDate && nextDueDate.isValid() && (
            <Fragment>
              <DynamicValueWithLabel
                label={isOverdue ? 'Payment Due Date' : 'Next Payment Due Date:'}
                value={nextDueDate.format(SHORT_DATE)}
                wrapperClassName={styles.paymentRow}
                isOverdue={isOverdue}
              />
              <DynamicValueWithLabel
                label={isOverdue ? 'Payment Amount Past Due' : 'Next Payment Amount Due:'}
                value={CURRENCY_FORMAT(nextPaymentAmountDue)}
                wrapperClassName={styles.paymentRow}
                isOverdue={isOverdue}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default PaymentSummary;
