import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

import styles from './DataBlockItemLabel.less';

type Props = {
  label: string;
  description?: string;
};

const DataBlockItemLabel: React.FC<Props> = ({ label, description }) => (
  <span>
    {label}
    {!!description?.length && (
      <Tooltip title={description} trigger="hover">
        <InfoCircleOutlined className={styles.info} />
      </Tooltip>
    )}
  </span>
);

export default DataBlockItemLabel;
