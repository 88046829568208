import dayjs from 'dayjs';

import { FormPartsData } from '../data/dataTypes/formPartsData';
import { FormItemDataType } from '../shared/constants';
import { dateToString } from '../utils/dateFormats';

export const formatValue = (
  reservationCode: string,
  formPart: FormPartsData['formParts'][number],
  formItem: FormPartsData['formParts'][number]['formItems'][number],
  value: string | string[],
) => {
  if (Array.isArray(value)) return value.join(',');

  const { formPartName } = formPart;
  const { dataType, formItemName } = formItem;

  const errorMessage = `Record with reservation code ${reservationCode} has wrong value for question ${formPartName}/${formItemName}`;

  switch (dataType) {
    case FormItemDataType.Number: {
      const nextValue = Number(value);
      if (Number.isNaN(nextValue)) {
        throw new Error(errorMessage);
      }
      return nextValue;
    }
    case FormItemDataType.Datetime: {
      const nextValue = dayjs(value);
      if (!nextValue.isValid()) {
        throw new Error(errorMessage);
      }
      return dateToString(nextValue);
    }
  }
  return value;
};
