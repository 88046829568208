import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { FormSettings } from '@/modules/data/dataTypes/form';
import { Payment } from '@/modules/data/dataTypes/paymentList';
import { PaymentMethod, transactionStatuses, transactionTypes } from '@/modules/payments/constants';
import { changePaymentStatus } from '@/modules/payments/duck/actions';
import { paymentMethodsIncludes } from '@/modules/payments/utils';
import Dropdown from '@/modules/shared/components/Dropdown';

type Props = {
  record: Payment;
  formSettings: FormSettings;
  canPostRefunds: boolean;
  canUpdatePaymentStatus: boolean;
  onMakeRefund: (payment: Payment) => void;
};

const MenuButton: React.FC<Props> = ({
  record,
  formSettings,
  canPostRefunds,
  canUpdatePaymentStatus,
  onMakeRefund,
}) => {
  const dispatch = useDispatch();

  const { formCode } = formSettings;
  const { transactionTypeCode, statusCode } = record;
  const paymentType = record.paymentType || '';

  const { category, direction } = transactionTypes[transactionTypeCode];

  const handleMakeRefund = useCallback(() => {
    onMakeRefund(record);
  }, [onMakeRefund, record]);

  const createChangeStatusHandler = useCallback(
    (
      status: Extract<
        ReturnType<typeof changePaymentStatus.request>['payload']['statusCode'],
        'Failed' | 'Completed' | 'Cancelled'
      >,
    ) =>
      () => {
        dispatch(
          changePaymentStatus.request({
            formCode,
            statusCode: status,
            paymentRecord: record,
          }),
        );
      },
    [dispatch, formCode, record],
  );
  const isAdjustmentRefund = !paymentType && direction === '-';

  const showMakeRefundButton = useMemo(() => {
    if (!canPostRefunds || statusCode !== transactionStatuses.completed.code) return false;

    return paymentMethodsIncludes(
      [
        PaymentMethod.check,
        PaymentMethod.creditCard,
        PaymentMethod.eCheckOrbital,
        PaymentMethod.adjustment,
      ],
      paymentType,
    );
  }, [canPostRefunds, paymentType, statusCode]);

  const menuItemsArr = useMemo(() => {
    const paymentTypeLowerCase = paymentType.toLowerCase();

    const isOrbitalCheck = paymentTypeLowerCase === PaymentMethod.eCheckOrbital.toLowerCase();
    const isPaperCheck = paymentTypeLowerCase === PaymentMethod.check.toLowerCase();
    const isAdjustment = paymentTypeLowerCase === PaymentMethod.adjustment.toLowerCase();
    const isAdjustmentOrPaperCheck = isPaperCheck || isAdjustment || isAdjustmentRefund;

    const paymentFailStatus =
      isAdjustment || isPaperCheck
        ? transactionStatuses.cancelled.code
        : transactionStatuses.failed.code;

    const showChangeStatusOptions =
      (isOrbitalCheck || isPaperCheck || isAdjustment) && category === 'payment';

    const menuItems = [];

    if (showMakeRefundButton && !isAdjustment && !isAdjustmentRefund) {
      menuItems.push({ key: 'makeRefund', label: 'Make Refund', onClick: handleMakeRefund });
    }

    const completedStatusCheck =
      showChangeStatusOptions && statusCode !== transactionStatuses.completed.code;
    const failedStatusCheck = showChangeStatusOptions && statusCode !== paymentFailStatus;
    const adjustmentRefundCompletedCheck =
      isAdjustmentRefund && statusCode !== transactionStatuses.completed.code;
    const adjustmentRefundFailedCheck =
      isAdjustmentRefund && statusCode !== transactionStatuses.cancelled.code;

    if (canUpdatePaymentStatus) {
      if (completedStatusCheck || adjustmentRefundCompletedCheck) {
        menuItems.push({
          key: 'markAsCompleted',
          label: 'Mark as Completed',
          onClick: createChangeStatusHandler('Completed'),
        });
      }

      if (failedStatusCheck || adjustmentRefundFailedCheck) {
        menuItems.push({
          key: 'markAsCancelledFailed',
          label: `Mark as ${isAdjustmentOrPaperCheck ? 'Cancelled' : 'Failed'}`,
          onClick: createChangeStatusHandler(isAdjustmentOrPaperCheck ? 'Cancelled' : 'Failed'),
        });
      }
    }

    return menuItems;
  }, [
    paymentType,
    isAdjustmentRefund,
    category,
    showMakeRefundButton,
    statusCode,
    canUpdatePaymentStatus,
    handleMakeRefund,
    createChangeStatusHandler,
  ]);

  if (!menuItemsArr.length) return null;

  return <Dropdown menuItems={menuItemsArr} />;
};

export default MenuButton;
