import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';

import { DataBlock } from '@/modules/data/dataTypes/dataBlockList';

import styles from './PanelHeader.less';

type Props = {
  dataBlock: DataBlock;
  checked: boolean;
  onCheckboxClick: (e: CheckboxChangeEvent, code: string) => void;
};

const PanelHeader: React.FC<Props> = ({ onCheckboxClick, checked, dataBlock }) => {
  const { datablockCode, datablockName } = dataBlock;

  const handleCheckboxClick = (e: CheckboxChangeEvent) => {
    onCheckboxClick(e, datablockCode);
  };

  return (
    <Checkbox onChange={handleCheckboxClick} checked={checked} className={styles.checkbox}>
      <span>{datablockName}</span>
    </Checkbox>
  );
};

export default PanelHeader;
