import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import { ADD_ATTENDEE_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import InfoBubble from '@/modules/shared/components/InfoBubble';

import { GroupHeaderProps } from '../../types';
import { EXTRA_ATTENDEE_TYPE } from '../../utils';
import { ModalParams as AttendeeInviteModalParams } from '../AddAttendeeModal/types';

import { Container, Name, Count } from './GroupHeader.styled';
import AddButton from './components/AddButton';

type Props = GroupHeaderProps;

const GroupHeader: React.FC<Props> = ({ attendeeType, showAddButton = false }) => {
  const dispatch = useDispatch();
  const { typeCode, typeName, maxCapacity, numberOfAttendees } = attendeeType;
  const reservationDetails = useSelector(createDataSel('groupReservation'));

  const handleOnAddClick = () => {
    const modalParams: AttendeeInviteModalParams = { attendeeType, reservationDetails };
    dispatch(openModal(ADD_ATTENDEE_MODAL, modalParams));
  };

  return (
    <Container>
      <div>
        <Name>{typeName}</Name>
        &nbsp;
        {maxCapacity > 0 ? <Count>{numberOfAttendees}</Count> : null}
        {typeCode === EXTRA_ATTENDEE_TYPE && (
          <InfoBubble>
            These attendees doesn't have a valid attendee type for the selected session.
          </InfoBubble>
        )}
      </div>

      {showAddButton && numberOfAttendees < maxCapacity && <AddButton onClick={handleOnAddClick} />}
    </Container>
  );
};

export default GroupHeader;
