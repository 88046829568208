import { produce } from 'immer';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { GroupSessionFees } from '../types';

import {
  getGroupSessionFee,
  joinSessionWaitlist,
  updateGroupReservationSessions,
  updateIndividualReservationSessions,
  updateReservationSessions,
} from './actions';

const selectedSessions = createReducer<string[]>([]).handleAction(
  updateReservationSessions,
  (_state, { payload }) => payload,
);

const updateSessionsInProgress = createReducer(false)
  .handleAction(
    [updateGroupReservationSessions.request, updateIndividualReservationSessions.request],
    () => true,
  )
  .handleAction(
    [
      updateGroupReservationSessions.success,
      updateGroupReservationSessions.failure,
      updateIndividualReservationSessions.success,
      updateIndividualReservationSessions.failure,
    ],
    () => false,
  );

const joinWaitlistInProgress = createReducer(false)
  .handleAction(joinSessionWaitlist.request, () => true)
  .handleAction([joinSessionWaitlist.success, joinSessionWaitlist.failure], () => false);

const getGroupSessionFeeInProgress = createReducer(false)
  .handleAction(getGroupSessionFee.request, () => true)
  .handleAction([getGroupSessionFee.success, getGroupSessionFee.failure], () => false);

const sessionsFees = createReducer<Record<string, GroupSessionFees>>({}).handleAction(
  getGroupSessionFee.success,
  (state, { payload: { sessionCode, ...fees } }) =>
    produce(state, draft => {
      draft[sessionCode] = fees;
    }),
);

export default combineReducers({
  joinWaitlistInProgress,
  getGroupSessionFeeInProgress,
  sessionsFees,
  selectedSessions,
  updateSessionsInProgress,
});
