import { combineEpics, Epic } from 'redux-observable';
import { merge, Observable, of, EMPTY, interval } from 'rxjs';
import {
  catchError,
  delayWhen,
  filter,
  groupBy,
  map,
  mergeMap,
  switchMap,
  take,
  throttle,
} from 'rxjs/operators';
import { getType, isActionOf, RootAction, RootState } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';

import { FetchDataParams, RefreshDataParams } from '../types';
import { getKey } from '../utils';

import {
  appendDataResponse,
  fetchData,
  refreshData,
  removeFilter,
  resetFilters,
  resetPagination,
  setFilter,
  setFilters,
  updatePageNumber,
  updatePageSize,
} from './actions';
import { createFetchCancelledSel, createQueryParamsSel } from './selectors';
import { getService } from './services';

const filterActionTypes: Set<string> = new Set(
  [setFilter, setFilters, removeFilter, resetFilters].map(getType),
);

const isNotNull = <T>(paramsObj: T | null): paramsObj is T => !!paramsObj;

export const prefetchData$ = (
  action$: Observable<RootAction>,
  ...params: (FetchDataParams | null)[]
): Observable<RootAction> =>
  merge(
    ...params.filter(isNotNull).map(paramsObj =>
      merge(
        action$.pipe(
          filter(isActionOf([fetchData.success, fetchData.cancel, fetchData.failure])),
          filter(action => getKey(paramsObj) === getKey(action.payload)),
          take(1),
        ),
        of(fetchData.request(paramsObj)),
      ),
    ),
  );

export const refreshDataAndWait$ = (
  action$: Observable<RootAction>,
  ...params: RefreshDataParams[]
): Observable<RootAction> =>
  merge(
    ...params.map(paramsObj =>
      merge(
        action$.pipe(
          filter(isActionOf([fetchData.success, fetchData.cancel, fetchData.failure])),
          filter(action => getKey(paramsObj) === getKey(action.payload)),
          take(1),
          switchMap(() => EMPTY),
        ),
        of(refreshData(paramsObj)),
      ),
    ),
  );

const fetchData$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        fetchData.request,
        refreshData,
        updatePageNumber,
        updatePageSize,
        setFilter,
        setFilters,
        removeFilter,
        resetFilters,
        resetPagination,
      ]),
    ),
    groupBy(({ payload }) => getKey(payload)),
    mergeMap(group$ =>
      group$.pipe(
        throttle(({ type }) => (filterActionTypes.has(type) ? interval(0) : interval(1000)), {
          leading: true,
          trailing: true,
        }),
        switchMap(action =>
          of(action).pipe(
            delayWhen(({ type }) => (filterActionTypes.has(type) ? interval(1000) : interval(0))),
            switchMap(({ payload }) => {
              const { dataType, dataId } = payload;
              const state = state$.value;

              const fetchCancelled = createFetchCancelledSel(dataType, dataId)(state);

              if (fetchCancelled) {
                return of(fetchData.cancel({ dataType, dataId }));
              }

              const queryParams = createQueryParamsSel(dataType, dataId)(state);
              const service$ = getService(dataType);

              return service$(queryParams).pipe(
                map(({ data, responseField, recordCount }) => {
                  if ('preserveData' in payload && payload.preserveData) {
                    return appendDataResponse({
                      dataType,
                      data,
                      responseField: responseField as string,
                      recordCount: recordCount as number,
                      dataId,
                    });
                  }

                  return fetchData.success({
                    dataType,
                    data,
                    responseField: responseField as string,
                    recordCount: recordCount as number,
                    dataId,
                  });
                }),
                catchError((error: ApiError) => of(fetchData.failure({ dataType, error, dataId }))),
              );
            }),
          ),
        ),
      ),
    ),
  );

export default combineEpics(fetchData$);
