import { RightOutlined } from '@ant-design/icons';
import React from 'react';

import { Roster } from '@/modules/data/dataTypes/rosterList';

import { Button } from './OpenButton.styled';

type Props = {
  roster: Roster;
  onOpenRoster: (roster: Roster) => void;
};

const OpenButton: React.FC<Props> = ({ onOpenRoster, roster }) => {
  const handleOpenRoster = () => {
    onOpenRoster(roster);
  };

  return (
    <Button ghost size="small" onClick={handleOpenRoster}>
      <RightOutlined />
    </Button>
  );
};

export default OpenButton;
