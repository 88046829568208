import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { closeModal } from '@/modules/modals/duck/actions';
import { resetOnAction } from '@/modules/utils/resetOnLogoutReducer';

import { importAnswers } from '../steps/SelectAnswers/duck/actions';
import { FileData, MappedAnswers } from '../types';

import {
  updateFileData,
  updateMappedAnswers,
  updateMappedColumns,
  updateMissingRecords,
} from './actions';

const fileData = createReducer<FileData[]>([]).handleAction(
  updateFileData,
  (_state, { payload }) => payload.data,
);

const columnNames = createReducer<string[]>([]).handleAction(
  updateFileData,
  (_state, { payload }) => payload.columnNames,
);

const mappedColumns = createReducer({} as Record<string, Record<string, string>>).handleAction(
  updateMappedColumns,
  (_state, { payload }) => payload,
);

const mappedAnswers = createReducer({} as MappedAnswers).handleAction(
  updateMappedAnswers,
  (_state, { payload }) => payload,
);

const missingRecords = createReducer<Set<string>>(new Set()).handleAction(
  updateMissingRecords,
  (_state, { payload }) => payload,
);

const importAnswersInProgress = createReducer(false)
  .handleAction(importAnswers.request, () => true)
  .handleAction([importAnswers.success, importAnswers.failure], () => false);

export default resetOnAction(
  combineReducers({
    fileData,
    columnNames,
    importAnswersInProgress,
    mappedColumns: resetOnAction(mappedColumns, updateFileData),
    mappedAnswers: resetOnAction(mappedAnswers, updateFileData),
    missingRecords: resetOnAction(missingRecords, updateFileData),
  }),
  closeModal,
);
