import * as Sentry from '@sentry/browser';

import { VERSION } from '../config/config';

// Initialize monitoring providers, can be more than one at a time.
export const init = () => {
  Sentry.init({
    dsn: 'https://41f4333cf37049f99823eaaa828647a4@sentry.io/1508519',
    release: VERSION,
    environment: process.env.REACT_APP_ENV || 'pd',
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        sentry: true,
        xhr: true,
      }),
    ],
  });
};

export const ui = (error: Error): void => {
  const { message } = error;

  Sentry.captureEvent({
    message: `[UI] ${message}`,
    logger: 'ui',
    extra: {
      error,
    },
  });
};

export const api = (error: {
  status?: string;
  request: { url: string; body: Record<string, unknown>; options: Record<string, unknown> };
}) => {
  const { request, status = '' } = error;
  const { url = '' } = request;
  const message = `[API] ${status} ${url}`;

  Sentry.captureEvent({
    message,
    logger: 'api',
    extra: {
      error,
    },
  });
};
