import { identity, sortBy } from 'lodash';
import React, { useState, useMemo, Fragment } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { createDataSel } from '@/modules/data/duck/selectors';
import JobPrioritySelector from '@/modules/entities/Jobs/components/JobPrioritySelector';
import { updateFormJob } from '@/modules/entities/Jobs/duck/actions';
import { updateFormJobInProgressSel } from '@/modules/entities/Jobs/duck/selectors';
import toastService from '@/modules/toasts/service';
import { personGuidSel } from '@/modules/user/duck/selectors';

import { openNextStep } from '@/pages/createReservation/duck/actions';

import ButtonsRow from '../../components/ButtonsRow';
import { formRecordGuidSel, isLoadingFetchingFormSel, pageDataSel } from '../../duck/selectors';

import CardWithHeader from 'SHARED/components/CardWithHeader';

type Props = ReturnType<typeof mapState>;

const JobPriorities: React.FC<Props> = ({ isLoading, personGUID, jobsList, selectedJobs }) => {
  const dispatch = useDispatch();
  const {
    data: { form },
  } = useSelector(pageDataSel);
  const formRecordGUID = useSelector(formRecordGuidSel);
  const [positions, setPositions] = useState<(string | null)[]>(
    selectedJobs.length === 3 ? selectedJobs : [null, null, null],
  );

  const allowNext = useMemo(() => positions.every(identity), [positions]);

  function handleNext() {
    if (isLoading) {
      return false;
    }

    if (!allowNext) {
      toastService.error('You need to select 3 staff positions');
      return false;
    }

    const jobsToUpdate = positions.map((position, i) => ({
      personGUID,
      formRecordGUID: formRecordGUID as string,
      jobCode: position as string,
      priority: i,
    }));

    if (jobsToUpdate.length) {
      const { formCode } = form;
      dispatch(updateFormJob.request({ formCode, jobsToUpdate, finalActions: [openNextStep()] }));
    } else {
      dispatch(openNextStep());
    }
  }

  return (
    <Fragment>
      <CardWithHeader header="Job Priorities" loading={isLoading}>
        <JobPrioritySelector jobsList={jobsList} value={positions} onChange={setPositions} />
      </CardWithHeader>
      <ButtonsRow
        leftButtons={[{ disabled: isLoading }]}
        rightButtons={[{ onClick: handleNext, disabled: !allowNext, loading: isLoading }]}
      />
    </Fragment>
  );
};

const mapState = (state: RootState) => {
  const selectedJobsFull = createDataSel('formRecordJobs')(state) || [];
  const selectedJobs = sortBy(selectedJobsFull, 'priority').map(({ jobCode }) => jobCode);
  return {
    jobsList: createDataSel('jobList')(state),
    isLoading: isLoadingFetchingFormSel(state) || updateFormJobInProgressSel(state),
    personGUID: personGuidSel(state) as string,
    selectedJobs,
  };
};

export default connect(mapState)(JobPriorities);
