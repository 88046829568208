import { Form, Input } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { cloneEvent } from '../../duck/actions';
import { isCloningEventSel } from '../../duck/selectors';

import { CloneEventModalParams } from './types';

const EVENT_NAME_KEY = 'clonedEventName';
const EVENT_CODE_KEY = 'clonedEventCode';

interface FormValues {
  [EVENT_NAME_KEY]: string;
  [EVENT_CODE_KEY]: string;
}

interface Props {
  modalParams: CloneEventModalParams;
}

const CloneEventModal = ({ modalParams }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const inProgress = useSelector(isCloningEventSel);

  const { formCode, eventName, description } = modalParams;

  const handleFinish = ({ clonedEventName, clonedEventCode }: FormValues) => {
    dispatch(
      cloneEvent.request({
        formCode,
        clonedEventName,
        description,
        eventCodeName: clonedEventCode,
      }),
    );
  };

  function handleDuplicateEvent() {
    form.submit();
  }

  function handleModalClose() {
    dispatch(closeModal());
  }

  return (
    <ModalComponent
      title="Duplicate Event"
      description={`You are about to duplicate ${eventName}`}
      inProgress={inProgress}
      buttons={[
        {
          title: 'Cancel',
          onClick: handleModalClose,
        },
        {
          title: 'Duplicate Event',
          onClick: handleDuplicateEvent,
          type: 'primary',
        },
      ]}
    >
      <p>Before you continue, please name the new event.</p>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name={EVENT_NAME_KEY}
          label="Event Name"
          initialValue={`${eventName} Copy`}
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input size="large" placeholder="Event Name" />
        </Form.Item>
        <Form.Item
          label="Event Code"
          name={EVENT_CODE_KEY}
          rules={[
            { max: 10, message: 'Length cannot be more than 10 characters' },
            {
              validator: (_, value = '') => {
                if (value.includes(' ')) {
                  return Promise.reject(new Error('No spaces allowed'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input size="large" placeholder="Event Code Name" />
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default CloneEventModal;
