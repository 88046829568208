import { combineEpics, Epic } from 'redux-observable';
import { concat, defer, EMPTY, merge, of, race } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { fetchMessages, getBsaLocales } from '@/modules/localization/duck/actions';
import { typeSel } from '@/modules/location/duck/selectors';
import { login, logout, ROUTE_LOGIN, singleSignOnRequest } from '@/modules/user/duck/actions';

import { appInit } from './actions';

const appInit$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appInit.request)),
    switchMap(() =>
      concat(
        merge(
          merge(
            merge(
              action$.pipe(
                filter(isActionOf(fetchMessages.success)),
                take(1),
                takeUntil(action$.pipe(filter(isActionOf(fetchMessages.failure)))),
              ),
              of(fetchMessages.request()),
            ),
            merge(
              action$.pipe(
                filter(isActionOf(getBsaLocales.success)),
                take(1),
                takeUntil(action$.pipe(filter(isActionOf(getBsaLocales.failure)))),
              ),
              of(getBsaLocales.request()),
            ),
          ),
          defer(() => {
            const currentRoute = typeSel(state$.value);

            if (currentRoute === ROUTE_LOGIN) return EMPTY;

            return merge(
              race(
                action$.pipe(filter(isActionOf(login.success)), take(1)),
                action$.pipe(filter(isActionOf(logout.success)), take(1)),
              ),
              of(singleSignOnRequest()),
            );
          }),
        ),
        of(appInit.success()),
      ),
    ),
  );

export default combineEpics(appInit$);
