import { createAction, createAsyncAction } from 'typesafe-actions';

import { LedgerAccount } from '@/modules/data/dataTypes/ledgerAccountLIst';
import { createModuleNamespaceName } from '@/modules/utils/misc';

import {
  ACCOUNT_CODE,
  ACCOUNT_COST_CENTER,
  ACCOUNT_DESCRIPTION,
  ACCOUNT_NAME,
  ACCOUNT_NUMBER,
  ACCOUNT_SESSIONS,
  modulePath,
} from '../constants';

const moduleNamespace = createModuleNamespaceName(modulePath);

export const openUpdateLedgerAccount = createAction(
  `${moduleNamespace}/OPEN_UPDATE_LEDGER_ACCOUNT`,
)<Partial<LedgerAccount>>();
export const closeUpdateLedgerAccount = createAction(
  `${moduleNamespace}/CLOSE_UPDATE_LEDGER_ACCOUNT`,
)();

export const updateLedgerAccount = createAsyncAction(
  `${moduleNamespace}/UPDATE_LEDGER_ACCOUNT_REQUEST`,
  `${moduleNamespace}/UPDATE_LEDGER_ACCOUNT_SUCCESS`,
  `${moduleNamespace}/UPDATE_LEDGER_ACCOUNT_FAILURE`,
)<
  {
    formCode: string;
    [ACCOUNT_CODE]?: string;
    [ACCOUNT_NAME]: string;
    [ACCOUNT_DESCRIPTION]: string;
    [ACCOUNT_COST_CENTER]: string;
    [ACCOUNT_NUMBER]: string;
    [ACCOUNT_SESSIONS]: number;
  },
  void,
  void
>();

export const deleteLedgerAccount = createAsyncAction(
  `${moduleNamespace}/DELETE_LEDGER_ACCOUNT_REQUEST`,
  `${moduleNamespace}/DELETE_LEDGER_ACCOUNT_SUCCESS`,
  `${moduleNamespace}/DELETE_LEDGER_ACCOUNT_FAILURE`,
)<{ formCode: string; accountCode: string }, void, void>();
