import { dateToString } from '@/modules/utils/dateFormats';
import { memberIdRegex } from '@/modules/utils/regularExpressions';

import { FindPersonFormValues, FoundPeopleQuery } from './types';

export const getFoundPeopleQuery = ({
  memberIdOrReservationCode,
  lastName,
  dateOfBirth,
}: FindPersonFormValues): FoundPeopleQuery => ({
  ...(memberIdRegex.test(memberIdOrReservationCode)
    ? { memberId: memberIdOrReservationCode }
    : { reservationCode: memberIdOrReservationCode }),
  lastName,
  ...(dateOfBirth ? { dateOfBirth: dateToString(dateOfBirth) } : {}),
});
