import { Collapse } from 'antd';
import styled from 'styled-components';

import DividerShared from '@/modules/shared/components/Divider';
import colors from '@/modules/styles/colors';

const { Panel: AntPanel } = Collapse;

export const Panel = styled(AntPanel)`
  && {
    border: none;
  }

  .ant-collapse-header-text {
    color: ${colors.primary};
    font-weight: bold;
    font-size: 16px;
  }

  .ant-collapse-content {
    border: none;

    .ant-collapse-content-box {
      padding: 12px;
      padding-top: 0;
    }
  }

  background-color: ${colors.white};
  margin-bottom: 10px;
`;

export const Row = styled.div`
  margin-bottom: 15px;
`;

export const Divider = styled(DividerShared)`
  margin-bottom: 15px;
  margin-top: 0;
`;

export const DisclaimerContent = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 15px;
`;
