import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { moduleName } from '../constants';

const moduleSel = (state: RootState) => state[moduleName];

export const modalNameSel = createSelector(moduleSel, ({ name }) => name);

export const modalParamsSel = createSelector(moduleSel, ({ params }) => params);

export const modalPropsSel = createSelector(moduleSel, ({ props }) => props);
