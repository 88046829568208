import { createAction } from 'typesafe-actions';

import { moduleName } from '../constants';
import { FileData, MappedAnswers } from '../types';

export const updateFileData = createAction(`${moduleName}/UPDATE_FILE_DATA`)<{
  data: FileData[];
  columnNames: string[];
}>();

export const updateMappedColumns = createAction(`${moduleName}/UPDATE_MAPPED_COLUMNS`)<
  Record<string, Record<string, string>>
>();

export const updateMappedAnswers = createAction(
  `${moduleName}/UPDATE_MAPPED_ANSWERS`,
)<MappedAnswers>();

export const updateMissingRecords = createAction(`${moduleName}/UPDATE_MISSING_RECORDS`)<
  Set<string>
>();
