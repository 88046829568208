import React, { Fragment } from 'react';

import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';

import LoginComponent from '../LoginComponent';

import { BlueBackground, Card } from './LoginPage.styled';

const LoginPage: React.FC = () => {
  const screenSize = useGetScreenSize();

  return (
    <Fragment>
      <BlueBackground />
      <Card $isMobile={screenSize === 'mobile'}>
        <LoginComponent />
      </Card>
    </Fragment>
  );
};

export default LoginPage;
