import React from 'react';
import { useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import type { PaymentSummary as PaymentSummaryType } from '@/modules/data/dataTypes/paymentSummary';
import { createIsLoadingSel } from '@/modules/data/duck/selectors';
import Desktop from '@/modules/responsiveSizes/Desktop';
import Mobile from '@/modules/responsiveSizes/Mobile';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';

import {
  DetailsCol,
  DetailsRow,
  PaymentSummaryCol,
  PaymentSummaryRow,
  ReservationDetailsCard,
  ReservationDetailsDesktopCard,
} from './ReservationDetails.styled';
import Details from './components/Details';
import PaymentSummary from './components/PaymentSummary';

type BaseOwnProps = {
  paymentSummary: PaymentSummaryType;
};

type GroupProps = BaseOwnProps & {
  isGroup: true;
  groupReservation: GroupReservation;
};

type IndividualProps = BaseOwnProps & {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};

type Props = GroupProps | IndividualProps;

const ReservationDetails: React.FC<Props> = props => {
  const { isGroup } = props;
  const { isCancelled } = isGroup ? props.groupReservation : props.attendeeDetails;

  const sessionIsLoading = useSelector(createIsLoadingSel('session'));

  function renderDetails() {
    return <Details {...props} />;
  }

  function renderPaymentSummary() {
    return <PaymentSummary {...props} />;
  }

  function renderDesktop() {
    return (
      <ReservationDetailsDesktopCard $isCancelled={isCancelled}>
        <DetailsCol span={16}>{renderDetails()}</DetailsCol>
        <PaymentSummaryCol span={8}>{renderPaymentSummary()}</PaymentSummaryCol>
      </ReservationDetailsDesktopCard>
    );
  }

  function renderMobile() {
    return (
      <ReservationDetailsCard $isCancelled={isCancelled}>
        <DetailsRow>{renderDetails()}</DetailsRow>
        <PaymentSummaryRow>{renderPaymentSummary()}</PaymentSummaryRow>
      </ReservationDetailsCard>
    );
  }

  return (
    <LoadingContainer isLoading={sessionIsLoading}>
      <Desktop>{renderDesktop()}</Desktop>
      <Mobile>{renderMobile()}</Mobile>
    </LoadingContainer>
  );
};

export default ReservationDetails;
