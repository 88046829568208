import { boolValue } from './utils';

// all values read from .env are strings, so we need to apply conversions to non-string values
export const VERSION = 'v1.21.18';

export const API_ENV = process.env.REACT_APP_API_ENV;
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

export const payments = {
  env: process.env.REACT_APP_PAYMENT_ENV || '',
  wePay: {
    clientId: process.env.REACT_APP_WE_PAY_CLIENT_ID,
    endpoint_env: process.env.REACT_APP_WE_PAY_ENDPOINT_ENV,
  },
  chase: {
    merchantId: process.env.REACT_APP_CHASE_MERCHANT_ID,
    host: process.env.REACT_APP_CHASE_IFRAME_HOST,
  },
};

export const ENABLE_RECAPTCHA = boolValue(process.env.REACT_APP_ENABLE_RECAPTCHA);

export const GOOGLE_SIGN_IN_CLIENT_ID = process.env.GOOGLE_SIGN_IN_CLIENT_ID;

export const GOOGLE_SIGN_ENABLED = boolValue(process.env.GOOGLE_SIGN_ENABLED);

export const APPLE_SIGN_IN_ENABLED = boolValue(process.env.APPLE_SIGN_IN_ENABLED);

export const REACT_APP_LOCAL = boolValue(process.env.REACT_APP_LOCAL);

// Enable debug field in login page for load test
export const DEBUG_DELAY_INPUT_ENABLED = boolValue(process.env.DEBUG_DELAY_INPUT_ENABLED);

export const CDN_URL = 'https://dptte84ooufix.cloudfront.net/events/';
