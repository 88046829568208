import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const ShowSummaryIcon = styled(DownOutlined)<{
  $opened: boolean;
}>`
  transform: ${({ $opened }) => ($opened ? 'rotate(180deg);' : 'none')};
  transition: transform 150ms ease;
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;

  > * {
    padding: 15px 10px;
  }
`;
