import { Input } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const ListContainer = styled.div`
  border-radius: 4px;
  border: solid 1px ${colors.scoutingPaleGray};
  flex: 1;
  overflow: scroll;
  padding: 0 0 15px 15px;
`;

export const SearchContainer = styled.div`
  align-items: flex-end;
  background-color: white;
  display: flex;
  height: 50px;
  left: 0;
  padding-right: 15px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const SearchInput = styled(Input)`
  margin-bottom: 10px;

  & input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${colors.scoutingPaleGray};

    &:focus {
      box-shadow: none;
    }
  }

  & .ant-input-prefix {
    left: 0;
  }

  & .bsa-icon {
    color: ${colors.scoutingPaleGray};
    font-size: 17px;
  }
`;

export const Title = styled.h3`
  color: ${colors.scoutingBlue};
  font-size: 14px;
  font-weight: bold;
`;

export const VariablesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  width: 300px;
`;
