import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { initialPageLoadCompletedSel, routeSel } from '@/modules/routing/duck/selectors';
import { logoutInProgressSel } from '@/modules/user/duck/selectors';

import { moduleName } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const appInitInProgressSel = createSelector(
  moduleSel,
  ({ appInitInProgress }) => appInitInProgress,
);

export const appLoadCompletedSel = createSelector(
  [logoutInProgressSel, appInitInProgressSel, initialPageLoadCompletedSel, routeSel],
  (logoutInProgress, appInitInProgress, initialPageLoadCompleted, route) =>
    !logoutInProgress && !appInitInProgress && initialPageLoadCompleted && !!route,
);
