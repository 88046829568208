import styled from 'styled-components';

export const ButtonsRow = styled.div`
  margin-top: 56px;
  display: flex;

  > :last-child {
    margin-left: auto;
  }
`;
