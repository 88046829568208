import { Card, Row, Col } from 'antd';
import styled, { css } from 'styled-components';

const detailsContainerStyles = css`
  padding: 24px;
`;

const paymentSummaryContainerStyles = css`
  background-color: #fafafa;
  padding: 3rem;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const ReservationDetailsCard = styled(Card)<{
  $isCancelled: boolean;
}>`
  > .ant-card-body {
    padding: 0;
  }

  filter: grayscale(${({ $isCancelled }) => ($isCancelled ? 1 : 0)});
  opacity: ${({ $isCancelled }) => ($isCancelled ? 0.7 : 1)};
`;

export const ReservationDetailsDesktopCard = styled(ReservationDetailsCard)`
  > .ant-card-body {
    display: flex;
  }
`;

export const DetailsCol = styled(Col)`
  ${detailsContainerStyles}
`;

export const DetailsRow = styled(Row)`
  ${detailsContainerStyles}
`;

export const PaymentSummaryCol = styled(Col)`
  ${paymentSummaryContainerStyles}
  border-left: 1px solid #cccccc;
`;

export const PaymentSummaryRow = styled(Row)`
  ${paymentSummaryContainerStyles}
  border-top: 1px solid #cccccc;
`;
