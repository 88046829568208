import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { Person } from '@/modules/data/dataTypes/person';
import { ApiError } from '@/modules/utils/apiService';
import { createModuleNamespaceName } from '@/modules/utils/misc';

import { UpdatePaymentScheduleDueDateActionRequest } from '@/pages/reservation/types';

import {
  moduleName,
  ROUTE_INDIVIDUAL_RESERVATION_PREFIX,
  ROUTE_GROUP_RESERVATION_PREFIX,
  ROUTE_RESERVATION_EDIT_GROUP_ADDONS,
  ROUTE_RESERVATION_EDIT_GROUP_ACTIVITIES,
  ROUTE_RESERVATION_EDIT_INDIVIDUAL_ACTIVITIES,
  ROUTE_RESERVATION_EDIT_INDIVIDUAL_ADDONS,
  TabKey,
} from '../constants';

import {
  RemoveGroupReservationContactPayload,
  ReplaceGroupReservationContactPayload,
  SendGroupReservationRCInvitePayload,
} from './services';

const moduleNamespace = createModuleNamespaceName(moduleName);

export const openTab = createAction(`${moduleNamespace}/OPEN_TAB`)<TabKey>();

export const updateGroupReservationContact = createAsyncAction(
  `${moduleName}/UPDATE_GROUP_RESERVATION_CONTACT_REQUEST`,
  `${moduleName}/UPDATE_GROUP_RESERVATION_CONTACT_SUCCESS`,
  `${moduleName}/UPDATE_GROUP_RESERVATION_CONTACT_FAILURE`,
)<
  | {
      sendInvitationEmail: true;
      nextEmailAddress?: string;
      data: SendGroupReservationRCInvitePayload;
    }
  | {
      sendInvitationEmail: false;
      nextEmailAddress?: string;
      data: ReplaceGroupReservationContactPayload;
    },
  undefined,
  Error
>();

export const cancelGroupReservationContactInvitation = createAsyncAction(
  `${moduleName}/CANCEL_GROUP_RESERVATION_CONTACT_INVITATION_REQUEST`,
  `${moduleName}/CANCEL_GROUP_RESERVATION_CONTACT_INVITATION_SUCCESS`,
  `${moduleName}/CANCEL_GROUP_RESERVATION_CONTACT_INVITATION_FAILURE`,
)<
  {
    groupReservationGUID: string;
    sessionCode: string;
    reservationContact: GroupReservation['reservationContact'] | GroupReservation['pendingContact'];
  },
  undefined,
  ApiError
>();

export const updatePaymentScheduleDueDate = createAsyncAction(
  `${moduleName}/UPDATE_PAYMENT_SCHEDULE_DUE_DATE_REQUEST`,
  `${moduleName}/UPDATE_PAYMENT_SCHEDULE_DUE_DATE_SUCCESS`,
  `${moduleName}/UPDATE_PAYMENT_SCHEDULE_DUE_DATE_FAILURE`,
)<UpdatePaymentScheduleDueDateActionRequest, undefined, ApiError>();

const VALIDATE_RC_INVITE_REQUEST = `${moduleName}/VALIDATE_RC_INVITE_REQUEST`;
const VALIDATE_RC_INVITE_SUCCESS = `${moduleName}/VALIDATE_RC_INVITE_SUCCESS`;
const VALIDATE_RC_INVITE_FAILURE = `${moduleName}/VALIDATE_RC_INVITE_FAILURE`;

export const validateGroupReservationRCInvite = createAsyncAction(
  VALIDATE_RC_INVITE_REQUEST,
  VALIDATE_RC_INVITE_SUCCESS,
  VALIDATE_RC_INVITE_FAILURE,
)<
  {
    contextToken: string;
    action: string;
    groupReservationGUID: string;
    formCode: string;
  },
  string,
  Error
>();

export const navigateToIndividualReservation = createAction(
  `${ROUTE_INDIVIDUAL_RESERVATION_PREFIX}/${TabKey.Overview}`,
)<{
  formCode: string;
  formRecordGUID: string;
}>();

export const navigateToEditIndividualActivities = createCustomAction(
  ROUTE_RESERVATION_EDIT_INDIVIDUAL_ACTIVITIES,
  (formCode: string, formRecordGUID: string) => ({
    payload: {
      formCode,
      formRecordGUID,
    },
  }),
);

export const navigateToEditIndividualAddons = createCustomAction(
  ROUTE_RESERVATION_EDIT_INDIVIDUAL_ADDONS,
  (formCode: string, formRecordGUID: string) => ({
    payload: {
      formCode,
      formRecordGUID,
    },
  }),
);

export const navigateToGroupReservationOverview = createAction(
  `${ROUTE_GROUP_RESERVATION_PREFIX}/${TabKey.Overview}`,
)<{
  formCode: string;
  groupReservationGUID: string;
  formRecordGUID?: string;
  keepRosterOpened?: boolean;
  rosterCodeDisplayed?: string;
}>();

export const navigateToGroupReservationAttendeeDetails = createAction(
  `${ROUTE_GROUP_RESERVATION_PREFIX}/${TabKey.AttendeeDetails}`,
)<{
  formCode: string;
  groupReservationGUID: string;
  formRecordGUID?: string;
}>();

export const navigateToEditGroupAddons = createCustomAction(
  ROUTE_RESERVATION_EDIT_GROUP_ADDONS,
  (formCode: string, groupReservationGUID: string) => ({
    payload: {
      formCode,
      groupReservationGUID,
    },
  }),
);

export const navigateToEditGroupActivities = createCustomAction(
  ROUTE_RESERVATION_EDIT_GROUP_ACTIVITIES,
  (formCode: string, groupReservationGUID: string) => ({
    payload: {
      formCode,
      groupReservationGUID,
    },
  }),
);

export const removeAdditionalReservationContact = createAction(
  `${moduleName}/REMOVE_ADDITIONAL_RESERVATION_CONTACT_REQUEST`,
)<RemoveGroupReservationContactPayload>();

export const assignHiringManager = createAsyncAction(
  `${moduleName}/ASSIGN_HIRING_MANAGER_REQUEST`,
  `${moduleName}/ASSIGN_HIRING_MANAGER_SUCCESS`,
  `${moduleName}/ASSIGN_HIRING_MANAGER_FAILURE`,
)<{ jobDepartmentCode: string; isGranted: boolean; personGUID: string }, undefined, Error>();

export const approveOrDeclineAttendee = createAsyncAction(
  `${moduleName}/APPROVE_OR_DECLINE_ATTENDEE_REQUEST`,
  `${moduleName}/APPROVE_OR_DECLINE_ATTENDEE_SUCCESS`,
  `${moduleName}/APPROVE_OR_DECLINE_ATTENDEE_FAILURE`,
)<
  {
    formCode: string;
    personGUID: string;
    statusCode: string;
    formRecordGUID: string;
    declineText?: string;
  },
  { isCancelled: boolean; statusCode: string },
  Error
>();

type TransferRegistrationBasePayload = {
  formCode: string;
  formRecordGUID: string;
};

type TransferRegistrationWithPersonPayload = TransferRegistrationBasePayload & {
  personExists: true;
  newPersonGUID: string;
};
type TransferRegistrationWithoutPersonPayload = TransferRegistrationBasePayload & {
  personExists: false;
  personData: Partial<Person>;
};

export const transferRegistration = createAsyncAction(
  `${moduleName}/TRANSFER_REGISTRATION_REQUEST`,
  `${moduleName}/TRANSFER_REGISTRATION_SUCCESS`,
  `${moduleName}/TRANSFER_REGISTRATION_FAILURE`,
)<
  TransferRegistrationWithPersonPayload | TransferRegistrationWithoutPersonPayload,
  undefined,
  Error
>();
