import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { APPLE_SIGN_IN_ENABLED } from '@/modules/config/config';
import toastService from '@/modules/toasts/service';
import { appleLogin } from '@/modules/user/duck/actions';
import AppleId from '@/modules/utils/appleId';

import { ButtonContainer } from './AppleSignIn.styled';

const AppleSignInButton = () => {
  const dispatch = useDispatch();

  const onAppleIdSuccess = useCallback(
    (data: { detail: { authorization: { id_token: string } } }) => {
      dispatch(appleLogin(data.detail.authorization.id_token));
    },
    [dispatch],
  );

  const onAppleIdFailure = useCallback(() => {
    toastService.error('Unable to login with Apple account');
  }, []);

  useEffect(() => {
    if (APPLE_SIGN_IN_ENABLED) {
      const { protocol, hostname } = window.location;
      const appleId = new AppleId();
      appleId.loadSDK().then(() => {
        appleId.init(
          {
            clientId: 'org.scouting.my',
            scope: 'name email',
            redirectURI: `${protocol}//${hostname}/login`,
            usePopup: true,
          },
          onAppleIdSuccess,
          onAppleIdFailure,
        );
        return () => {
          appleId.cleanUp();
        };
      });
    }
  }, [onAppleIdSuccess, onAppleIdFailure]);

  return APPLE_SIGN_IN_ENABLED ? (
    <ButtonContainer
      id="appleid-signin"
      data-color="light"
      data-border="true"
      data-type="sign in"
    />
  ) : null;
};

export default AppleSignInButton;
