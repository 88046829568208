import { WarningFilled } from '@ant-design/icons';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled(WarningFilled)`
  svg {
    height: 10px;
    color: #ffb225;
  }
`;
