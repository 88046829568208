import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';

import { registrationTimeLeftSel } from '../../duck/selectors';

import { Timer } from './SessionTimer.styled';
import ClockIcon from './components/ClockIcon';

const TOOLTIP_TEXT = "If your timer expires you'll be returned to the session selection step";

type Props = {
  warningThreshold?: number;
  errorThreshold?: number;
  infoText?: string;
};

const SessionTimer: React.FC<Props> = ({
  warningThreshold = 0.5,
  errorThreshold = 0.2,
  infoText = TOOLTIP_TEXT,
}) => {
  const { registrationCompletionTimeLimitSeconds: duration } = useSelector(createDataSel('form'));
  const timeLeft = useSelector(registrationTimeLeftSel) as number;
  const warningLimit = duration * warningThreshold;
  const errorLimit = duration * errorThreshold;

  const percentLeft = (timeLeft * 100) / duration;

  const timerClass = cn({
    info: timeLeft >= warningLimit,
    warning: timeLeft < warningLimit && timeLeft > errorLimit,
    error: timeLeft <= errorLimit,
  });

  if (timeLeft < 0 || timeLeft === null) {
    return null;
  }

  return (
    <Timer $percent={percentLeft} className={timerClass}>
      <ClockIcon className="icon" />
      Your session will remain on hold for you for&nbsp;
      <b>{dayjs.duration(timeLeft, 'seconds').format('HH:mm:ss')}</b>
      &nbsp;seconds while you complete your reservation
      <Tooltip placement="right" title={infoText}>
        <InfoCircleFilled className="tooltip-icon" />
      </Tooltip>
    </Timer>
  );
};

export default SessionTimer;
