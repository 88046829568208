/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncAction } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';
import { DataTypes, PluralDataTypes } from '../dataTypes';
import { FilterType } from '../types';

export const ADD_DATA_ITEM = `${moduleName}/ADD_DATA_ITEM`;
export const REPLACE_DATA = `${moduleName}/REPLACE_DATA`;
export const REPLACE_DATA_ITEM = `${moduleName}/REPLACE_DATA_ITEM`;
export const REMOVE_DATA_ITEM = `${moduleName}/REMOVE_DATA_ITEM`;

export type FetchDataRequestParams<T = keyof DataTypes> = {
  dataType: T;
  dataId?: string;
  queryObj?: Record<string, any>;
  fetchData?: boolean;
};

export const fetchData = createAsyncAction(
  `${moduleName}/FETCH_DATA_REQUEST`,
  `${moduleName}/FETCH_DATA_SUCCESS`,
  `${moduleName}/FETCH_DATA_FAILURE`,
  `${moduleName}/FETCH_DATA_CANCEL`,
)<
  FetchDataRequestParams,
  {
    dataType: keyof DataTypes;
    data: any;
    responseField?: string;
    recordCount?: number;
    dataId?: string;
  },
  {
    dataType: keyof DataTypes;
    error: ApiError;
    dataId?: string;
  },
  {
    dataType: keyof DataTypes;
    dataId?: string;
  }
>();

// for lack of better name, suggestions are welcome
export const appendDataResponse = createAction(`${moduleName}/APPEND_DATA_RESPONSE`)<{
  dataType: keyof DataTypes;
  data: any;
  responseField?: string;
  recordCount?: number;
  dataId?: string;
}>();

export const refreshData = createAction(`${moduleName}/REFRESH_DATA`)<{
  dataType: keyof DataTypes;
  dataId?: string;
}>();

export const addDataItem = createAction(ADD_DATA_ITEM)<{
  dataType: keyof DataTypes;
  dataItem: any;
  dataId?: string;
}>();

export const replaceDataItem = createAction(REPLACE_DATA_ITEM)<{
  dataType: keyof DataTypes;
  idField: string;
  dataItem: any;
  dataId?: string;
}>();

export const replaceData = createAction(REPLACE_DATA)<{
  dataType: keyof DataTypes;
  data: any;
  dataId?: string;
}>();

export const removeDataItem = createAction(REMOVE_DATA_ITEM)<{
  dataType: keyof DataTypes;
  idField: string;
  dataItemId: string;
  dataId?: string;
}>();

export const removeData = createAction(`${moduleName}/REMOVE_DATA`)<{
  dataType: keyof DataTypes;
  dataId?: string;
}>();

export const clearError = createAction(`${moduleName}/CLEAR_ERROR`)<{
  dataType: keyof DataTypes;
  dataId?: string;
}>();

export const updatePageSize = createAction(`${moduleName}/UPDATE_PAGE_SIZE`)<{
  dataType: keyof PluralDataTypes;
  pageSize: number;
  dataId?: string;
}>();

export const updatePageNumber = createAction(`${moduleName}/UPDATE_PAGE_NUMBER`)<{
  dataType: keyof PluralDataTypes;
  pageNumber: number;
  preserveData?: true;
  dataId?: string;
}>();

export const resetPagination = createAction(`${moduleName}/RESET_PAGINATION`)<{
  dataType: keyof PluralDataTypes;
  fetchData?: boolean;
  dataId?: string;
}>();

export const setFilter = createAction(`${moduleName}/SET_FILTER`)<{
  dataType: keyof DataTypes;
  key: string;
  value: any;
  dataId?: string;
  fetchData?: boolean;
}>();

export const removeFilter = createAction(`${moduleName}/REMOVE_FILTER`)<{
  dataType: keyof DataTypes;
  key: string;
  dataId?: string;
  fetchData?: boolean;
}>();

export const resetFilters = createAction(`${moduleName}/RESET_FILTERS`)<{
  dataType: keyof DataTypes;
  fetchData?: boolean;
  dataId?: string;
}>();

export const setFilters = createAction(`${moduleName}/SET_FILTERS`)<{
  dataType: keyof DataTypes;
  filters: FilterType;
  dataId?: string;
  fetchData?: boolean;
}>();

export const scheduleRefresh = createAction(`${moduleName}/SCHEDULE_REFRESH`)<{
  dataType: keyof DataTypes;
  dataId?: string;
}>();
