import React from 'react';

import { ElementType, elementTagsByType } from '../../constants';

import Indentation from './elements/Indentation';
import Link from './elements/Link';
import Variable from './elements/Variable';
import { ElementProps } from './types';

const Element: React.FC<ElementProps> = props => {
  const {
    element: { type },
    attributes,
    children,
  } = props;

  switch (type) {
    case ElementType.link:
      return <Link {...props} />;
    case ElementType.indentation:
      return <Indentation {...props} />;
    case ElementType.variable:
      return <Variable {...props} />;
  }

  const TheTag = elementTagsByType[type];

  if (TheTag) {
    return <TheTag {...attributes}>{children}</TheTag>;
  }

  return <p {...attributes}>{children}</p>;
};

export default Element;
