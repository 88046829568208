import { Card } from 'antd';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Session } from '@/modules/data/dataTypes/sessionList';
import { refreshData, scheduleRefresh } from '@/modules/data/duck/actions';
import { AddSecondaryRegistrantModalParams } from '@/modules/entities/Registrations/components/AddSecondaryRegistrantModal/types';
import { ADD_SECONDARY_REGISTRANT_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';

import { formRecordGuidSel, pageDataSel } from '@/pages/createReservation/duck/selectors';
import { currentSessionSel } from '@/pages/createReservation/steps/SessionPriorities/duck/selectors';

import ButtonsRow from '../../components/ButtonsRow';
import { openNextStep } from '../../duck/actions';

import { NoPersonDescription, NoPersonHeader } from './AddPerson.styled';
import RegistrantsList from './components/RegistrantsList';
import { stepDataSel } from './duck/selectors';

const AddPerson: React.FC = () => {
  const dispatch = useDispatch();
  const {
    data: {
      form: { formCode },
    },
  } = useSelector(pageDataSel);
  const {
    data: { attendeeList: secondaryRegistrantList },
    isLoading,
  } = useSelector(stepDataSel);
  const primaryRegistrantFormRecordGUID = useSelector(formRecordGuidSel) as string;
  const currentSession = useSelector(currentSessionSel) as Session;

  useEffect(() => () => {
    dispatch(scheduleRefresh({ dataType: 'attendeeList' }));
  });

  function handleAddPerson() {
    const modalParams: AddSecondaryRegistrantModalParams = {
      formCode,
      primaryRegistrantFormRecordGUID,
      session: currentSession,
      useCheckoutStep: false,
      finalActions: [refreshData({ dataType: 'attendeeList' })],
    };
    dispatch(openModal(ADD_SECONDARY_REGISTRANT_MODAL, modalParams));
  }
  function handleNext() {
    dispatch(openNextStep());
  }

  return (
    <Fragment>
      <LoadingContainer isLoading={isLoading.attendeeList}>
        {secondaryRegistrantList.length ? (
          <RegistrantsList formRecords={secondaryRegistrantList} />
        ) : (
          <Card>
            <NoPersonHeader>No Extra Person Added Yet</NoPersonHeader>
            <NoPersonDescription>
              You can add secondary registrants to match your current selection. Would you like to
              add an extra person?
            </NoPersonDescription>
          </Card>
        )}
      </LoadingContainer>
      <ButtonsRow
        leftButtons={[{}]}
        rightButtons={[
          {
            onClick: handleAddPerson,
            title: 'Add Person',
            type: 'default',
          },
          { onClick: handleNext },
        ]}
      />
    </Fragment>
  );
};

export default AddPerson;
