import { createPageDataParams } from '@/modules/data/utils';

import { moduleName as parentModuleName } from '../../constants';

export const moduleName = 'disclaimers';
export const modulePath = [parentModuleName, moduleName];

export const stepDataParams = createPageDataParams(
  { dataType: 'disclaimerList' },
  { dataType: 'formDisclaimers' },
);
