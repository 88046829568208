import { Text } from '@react-pdf/renderer';
import React, { useMemo } from 'react';

import { FontSize, leafStyles, LeafType } from '@/modules/richTextEditor/constants';
import { CustomText } from '@/modules/richTextEditor/types';

const stylesObj = {
  bold: { fontWeight: 700 },
  italic: { fontStyle: 'italic' },
  underline: { textDecoration: 'underline' },
} as const;

const pdfFontSizes = {
  [FontSize.large]: 18,
  [FontSize.xLarge]: 20,
};

type Props = {
  leaf: CustomText;
};

const Leaf: React.FC<Props> = ({ leaf }) => {
  const { text, bold, italic, underline } = leaf;

  const leafStyle = useMemo(
    () =>
      leafStyles.reduce(
        (acc, style) => {
          if (!leaf[style]) return acc;
          if (style === LeafType.fontSize) {
            return { ...acc, [style]: pdfFontSizes[leaf[style] as FontSize] };
          }
          return { ...acc, [style]: leaf[style] };
        },
        {
          ...(bold ? stylesObj.bold : {}),
          ...(italic ? stylesObj.italic : {}),
          ...(underline ? stylesObj.underline : {}),
        },
      ),
    [leaf, bold, italic, underline],
  );

  return <Text style={leafStyle}>{text}</Text>;
};

export default Leaf;
