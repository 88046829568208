import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { useFoundPerson } from '@/modules/shared/components/FindPersonForm/hooks';
import TagSelector from '@/modules/shared/components/TagSelector';
import toastService from '@/modules/toasts/service';

import { findPersonAndInvite } from '../../duck/actions';

import styles from './InviteCollaborator.less';

import FindPersonForm from 'SHARED/components/FindPersonForm';

const InviteCollaborator = (): JSX.Element => {
  const dispatch = useDispatch();
  const renderFoundPersonRef = useRef(null);
  const roles = useSelector(createDataSel('formRolesList'));
  const foundPerson = useFoundPerson();

  const [assignedRoles, setAssignedRoles] = useState<string[]>([]);

  function handleSubmit() {
    if (!foundPerson) {
      toastService.error('No person was found');
      return;
    }
    dispatch(
      findPersonAndInvite({
        lastName: foundPerson.lastName,
        memberId: foundPerson.memberID,
        roleCodes: assignedRoles,
      }),
    );
  }

  const handleSelect = (roleCode: string) => {
    setAssignedRoles([...assignedRoles, roleCode]);
  };

  const handleOnDelete = (roleCode: string) => {
    setAssignedRoles(assignedRoles.filter(p => p !== roleCode));
  };

  const buttons = [
    {
      title: 'Invite',
      onClick: handleSubmit,
    },
  ];

  return (
    <ModalComponent title="Invite Collaborator" buttons={buttons}>
      <div className={styles.wrapper}>
        <FindPersonForm renderFoundPersonRef={renderFoundPersonRef} />
        <div ref={renderFoundPersonRef} />
        <TagSelector
          dataSource={roles}
          valueField="roleCode"
          label="Roles to assign"
          labelField="roleName"
          value={assignedRoles}
          onSelect={handleSelect}
          onDeselect={handleOnDelete}
        />
      </div>
    </ModalComponent>
  );
};

export default InviteCollaborator;
