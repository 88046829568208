import { OTHER_VALUE_PREFIX } from '@/modules/entities/FormItems/constants';

export const isOtherValue = (value: unknown): value is string =>
  typeof value === 'string' && value.startsWith(OTHER_VALUE_PREFIX);

export const getOtherValue = (value: string) => {
  if (value.startsWith(OTHER_VALUE_PREFIX)) {
    return value.replace(OTHER_VALUE_PREFIX, '');
  }
  return '';
};
