import { Select } from 'antd';
import React, { Fragment, useCallback } from 'react';

import { OptionSetItem } from '@/modules/data/dataTypes/optionSets';

import SelectInputMaterial from '../SelectInputMaterial';

const { Option } = Select;

interface Props {
  conditionValue: string;
  isCheckboxPresentationType?: boolean;
  isDisabled?: boolean;
  optionSetItems: Omit<OptionSetItem, 'positionNumber' | 'capacity' | 'isDisabled'>[];
  onChangeValue: (value: string) => void;
}

const SelectConditionValue: React.FC<Props> = ({
  conditionValue,
  isCheckboxPresentationType,
  isDisabled,
  optionSetItems,
  onChangeValue,
}) => {
  const handleSetConditionValues = (values: unknown) => {
    const selectedValues = values as string[];
    onChangeValue(selectedValues.join(','));
  };

  const renderOptions = useCallback(
    () =>
      optionSetItems.map(({ itemLabel, itemValue }) => (
        <Option key={itemValue} value={itemValue}>
          {itemLabel}
        </Option>
      )),
    [optionSetItems],
  );

  if (isCheckboxPresentationType) {
    const selectedValues = !conditionValue ? [] : conditionValue.split(',');

    return (
      <Fragment>
        <SelectInputMaterial
          autoClearSearchValue={false}
          disabled={isDisabled}
          dropdownMatchSelectWidth={false}
          labelContent="Select Value"
          mode="multiple"
          optionFilterProp="children"
          value={selectedValues}
          onChange={handleSetConditionValues}
        >
          {renderOptions()}
        </SelectInputMaterial>
      </Fragment>
    );
  }

  return (
    <SelectInputMaterial
      disabled={isDisabled}
      dropdownMatchSelectWidth={false}
      labelContent="Select Value"
      value={conditionValue}
      onChange={onChangeValue}
    >
      {renderOptions()}
    </SelectInputMaterial>
  );
};

export default SelectConditionValue;
