import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { dismissMessage, saveBroadcastMessage, updateBroadcast } from './actions';

const broadcastMessage = createReducer('')
  .handleAction(saveBroadcastMessage, (_state, { payload }) => payload)
  .handleAction(dismissMessage, () => '');

const updateBroadcastInProgress = createReducer(false)
  .handleAction(updateBroadcast.request, () => true)
  .handleAction([updateBroadcast.failure, updateBroadcast.success], () => false);

export default combineReducers({
  broadcastMessage,
  updateBroadcastInProgress,
});
