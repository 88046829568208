import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

interface LabelProps {
  fontSize?: string;
}

interface ButtonProps {
  color?: string;
  hoverColor?: string;
  inline?: boolean;
}

export const Label = styled.span<LabelProps>`
  ${props => props.fontSize && `font-size: ${props.fontSize}`};
  font-family: Roboto;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-left: 0.5rem;
`;

const inlineStyle = css`
  display: inline-block;
`;

export const TransparentButton = styled.button<ButtonProps>`
  ${props => props.color && `color: ${props.color};`}
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  padding: 0;

  :hover {
    ${props => (props.hoverColor ? `color: ${props.hoverColor};` : 'filter: brightness(0.7);')}
  }

  :disabled {
    color: ${colors.gray};
  }

  ${props => props.inline && inlineStyle}
`;

const spinningIconStyle = css`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    animation: spin infinite 2s linear;
  }
`;

export const ButtonContent = styled.div<{ $isLoading?: boolean }>`
  display: flex;
  align-items: center;
  ${props => props.$isLoading && spinningIconStyle}
`;
