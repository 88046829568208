import React from 'react';

import * as s from './WarningMessage.styled';

type Props = {
  children?: React.ReactNode;
};

const WarningMessage: React.FC<Props> = ({ children }) => {
  if (!children) return null;

  return <s.WarningMessage>{children}</s.WarningMessage>;
};

export default WarningMessage;
