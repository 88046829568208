import { Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';

import { DataBlock } from '@/modules/data/dataTypes/dataBlockList';
import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';

import PanelHeader from '../PanelHeader';

import { CustomPanel, CustomCollapse } from './DataBlocksSet.styled';

type Props = {
  selectedDataBlocks: Record<string, boolean>;
  dataBlocks: DataBlock[];
  onSelectDataBlock: (e: CheckboxChangeEvent, blockCode: string) => void;
};

const DataBlocksSet: React.FC<Props> = ({ dataBlocks, selectedDataBlocks, onSelectDataBlock }) => {
  const screenSize = useGetScreenSize();

  if (!dataBlocks) return <Spin size="large" />;

  return (
    <CustomCollapse>
      {dataBlocks.map((block, index) => {
        const isBlockSelected = !!selectedDataBlocks[block.datablockCode];
        return (
          <CustomPanel
            isBlockSelected={isBlockSelected}
            screenSize={screenSize}
            header={
              <PanelHeader
                dataBlock={block}
                checked={isBlockSelected}
                onCheckboxClick={onSelectDataBlock}
              />
            }
            key={`${block.datablockCode}${index}`}
          >
            <p>{block.description || 'No description'}</p>
          </CustomPanel>
        );
      })}
    </CustomCollapse>
  );
};

export default DataBlocksSet;
