import { createAction, createAsyncAction } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';
import { createModuleNamespaceName } from '@/modules/utils/misc';

import { modulePath } from '../constants';
import { GroupSessionFees } from '../types';

const moduleNamespace = createModuleNamespaceName(modulePath);

export const updateReservationSessions = createAction(
  `${moduleNamespace}/UPDATE_RESERVATION_SESSIONS`,
)<string[]>();

export const updateGroupReservationSessions = createAsyncAction(
  `${moduleNamespace}/UPDATE_GROUP_RESERVATION_SESSIONS_REQUEST`,
  `${moduleNamespace}/UPDATE_GROUP_RESERVATION_SESSIONS_SUCCESS`,
  `${moduleNamespace}/UPDATE_GROUP_RESERVATION_SESSIONS_FAILURE`,
)<string[], undefined, ApiError>();

export const updateIndividualReservationSessions = createAsyncAction(
  `${moduleNamespace}/UPDATE_INDIVIDUAL_RESERVATION_SESSIONS_REQUEST`,
  `${moduleNamespace}/UPDATE_INDIVIDUAL_RESERVATION_SESSIONS_SUCCESS`,
  `${moduleNamespace}/UPDATE_INDIVIDUAL_RESERVATION_SESSIONS_FAILURE`,
)<string[], undefined, undefined>();

export interface HostInitialSettings {
  hostUnit: string;
  hostCouncil: string;
}

export interface PeopleInitialSettings {
  initialNumberOfYouth: number;
  initialNumberOfAdults: number;
  initialRosterCount: number;
  initialPeopleCount: number;
}

export const setGroupInitialSettings = createAction(
  `${moduleNamespace}/SET_GROUP_INITIAL_SETTINGS`,
)<HostInitialSettings | PeopleInitialSettings>();

export const joinSessionWaitlist = createAsyncAction(
  `${moduleNamespace}/JOIN_SESSION_WAITLIST_REQUEST`,
  `${moduleNamespace}/JOIN_SESSION_WAITLIST_SUCCESS`,
  `${moduleNamespace}/JOIN_SESSION_WAITLIST_FAILURE`,
)<
  {
    sessionCode: string;
    initialRosterCount: number;
    initialPeopleCount: number;
    initialAdultCount?: number;
    initialYouthCount?: number;
  },
  void,
  Error
>();

export const getGroupSessionFee = createAsyncAction(
  `${moduleNamespace}/GROUP_SESSION_FEE_REQUEST`,
  `${moduleNamespace}/GROUP_SESSION_FEE_SUCCESS`,
  `${moduleNamespace}/GROUP_SESSION_FEE_FAILURE`,
)<
  {
    sessionCode: string;
    numberOfPeople: number;
    numberOfCrews: number;
  },
  {
    sessionCode: string;
  } & GroupSessionFees,
  Error
>();
