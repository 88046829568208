import { PresentationType } from '../shared/constants';

// conditional operators
const EQUAL_TO = 'EQ';
const NOT_EQUAL_TO = 'NE';
const NOT_NULL = 'NOTNULL';
const NULL = 'NULL';
export const INCLUDE = 'INCLUDE';
export const NOTINCLUDE = 'NOTINCLUDE';

export const visibilityLogicTestOptions = [
  {
    key: EQUAL_TO,
    text: 'Is Equal To',
    presentationType: [
      PresentationType.TEXT,
      PresentationType.TEXTAREA,
      PresentationType.DROPDOWN,
      PresentationType.DROPDOWN_BULK,
      PresentationType.RADIO,
      PresentationType.EMAIL,
      PresentationType.PHONE,
    ],
  },
  {
    key: NOT_EQUAL_TO,
    text: 'Is Not Equal To',
    presentationType: [
      PresentationType.TEXT,
      PresentationType.TEXTAREA,
      PresentationType.DROPDOWN,
      PresentationType.DROPDOWN_BULK,
      PresentationType.RADIO,
      PresentationType.EMAIL,
      PresentationType.PHONE,
    ],
  },
  {
    key: NOT_NULL,
    text: 'Is Not Empty',
    presentationType: [
      PresentationType.TEXT,
      PresentationType.TEXTAREA,
      PresentationType.DROPDOWN,
      PresentationType.DROPDOWN_BULK,
      PresentationType.EMAIL,
      PresentationType.PHONE,
    ],
  },
  {
    key: NULL,
    text: 'Is Empty',
    presentationType: [
      PresentationType.TEXT,
      PresentationType.TEXTAREA,
      PresentationType.DROPDOWN,
      PresentationType.DROPDOWN_BULK,
      PresentationType.EMAIL,
      PresentationType.PHONE,
    ],
  },
  { key: INCLUDE, text: 'Matches Any', presentationType: [PresentationType.CHECKBOX] },
  {
    key: NOTINCLUDE,
    text: 'Does Not Match Any',
    presentationType: [PresentationType.CHECKBOX],
  },
];

export const allowValueSelectionConditions = [EQUAL_TO, NOT_EQUAL_TO, INCLUDE, NOTINCLUDE];

export type ConditionalOperatorsTypes =
  | typeof EQUAL_TO
  | typeof NOT_EQUAL_TO
  | typeof NOT_NULL
  | typeof NULL
  | typeof INCLUDE
  | typeof NOTINCLUDE;

// Dependency value its what user typed
// test value is stored in visibility logic
export const logicValidators = {
  [EQUAL_TO]: (userValue: string, conditionValue: string): boolean => conditionValue === userValue,
  [NOT_EQUAL_TO]: (userValue: string, conditionValue: string): boolean =>
    conditionValue !== userValue,
  [NOT_NULL]: (userValue: string): boolean => !!userValue,
  [NULL]: (userValue: string): boolean => !userValue,
  [INCLUDE]: (userValue: string | string[] | undefined, conditionValue = ''): boolean => {
    if (typeof conditionValue !== 'string' || typeof userValue !== 'string') {
      return false;
    }

    if (conditionValue.split(',').length > 1) {
      return conditionValue.split(',').some(cv => userValue.includes(cv));
    }

    return userValue.includes(conditionValue);
  },
  [NOTINCLUDE]: (userValue?: string | string[], conditionValue = ''): boolean => {
    if (typeof conditionValue !== 'string') {
      return false;
    }

    if (typeof userValue === 'undefined') {
      return false;
    }

    if (conditionValue.split(',').length > 1) {
      return !conditionValue.split(',').some(cv => userValue.includes(cv));
    }

    return !userValue.includes(conditionValue);
  },
};
