import { combineEpics, Epic } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { switchMap, filter, map, catchError } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { removeDataItem } from '@/modules/data/duck/actions';
import { createDataSel, createPaginationSel } from '@/modules/data/duck/selectors';
import { closeModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';
import csvDownload from '@/modules/utils/csvDownload';

import attendeesTabEpics$ from '../tabs/Attendees/duck/epics';

import { adminReportCSV, deleteMailing, downloadSessionsReport, summaryReport } from './actions';
import services from './services';

const deleteMailing$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteMailing.request)),
    switchMap(({ payload: mailingCode }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);

      return services.deleteMailing$({ formCode, mailingCode }).pipe(
        switchMap(() =>
          of(
            deleteMailing.success(),
            removeDataItem({
              dataType: 'mailingList',
              dataItemId: mailingCode,
              idField: 'mailingCode',
            }),
          ),
        ),
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(deleteMailing.failure(error));
        }),
      );
    }),
  );

// TODO CREATE OPTION FOR GENERIC DOWNLOADS IN DATA MODULE
const downloadSessionsReport$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(downloadSessionsReport)),
    switchMap(({ payload: programCode }) => {
      const state = state$.value;

      const { formCode, formName } = createDataSel('form')(state);
      const paginationData = createPaginationSel('sessionList')(state$.value);

      return services
        .downloadSessionsCSV$(formCode, paginationData?.recordCount as number, programCode)
        .pipe(
          switchMap(response => {
            csvDownload(response, formName);
            return EMPTY;
          }),
          catchError((e: ApiError) => {
            toastService.error(e.message);
            return EMPTY;
          }),
        );
    }),
  );

const summaryReport$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(summaryReport.request)),
    switchMap(({ payload }) =>
      services.summaryReportCSV$(payload).pipe(
        map(res => {
          const { eventName, groupBy } = payload;
          const fileName = `Summary${groupBy.charAt(0).toUpperCase()}${groupBy.slice(1)}${eventName
            .trim()
            .replace(/ /gi, '')}`;
          csvDownload(res, fileName);
          return summaryReport.success();
        }),
        catchError((error: Error) => of(summaryReport.failure(error))),
      ),
    ),
  );

const getPaymentCSV$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(adminReportCSV.request)),
    switchMap(({ payload }) => {
      const { eventName, formCode, startDate, finishDate } = payload;
      return services.getPaymentsHistoryCSV$(formCode, startDate, finishDate).pipe(
        switchMap(res => {
          csvDownload(res, eventName || 'Payment Report');
          return of(closeModal(), adminReportCSV.success());
        }),
        catchError(() => of(adminReportCSV.failure())),
      );
    }),
  );

export default combineEpics(
  deleteMailing$,
  downloadSessionsReport$,
  summaryReport$,
  getPaymentCSV$,
  attendeesTabEpics$,
);
