import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { Spin } from './Loading.styled';
import { isLoadingSel } from './duck/selectors';

type Props = ReturnType<typeof mapStateToProps>;

const Loading: React.FC<Props> = ({ isLoading }) => {
  if (!isLoading) return null;

  return <Spin size="large" />;
};

const mapStateToProps = (state: RootState) => ({
  isLoading: isLoadingSel(state),
});

export default connect(mapStateToProps)(Loading);
