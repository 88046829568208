import {
  ACTIVITY_FIELD_ACTIVITY_NAME,
  ACTIVITY_FIELD_IS_MANDATORY,
} from '@/modules/entities/Activities/constants';
import {
  ADDON_FIELD_ADDON_NAME,
  ADDON_FIELD_DEFAULT_ADDON_OPTION_CODE,
  ADDON_FIELD_IS_MANDATORY,
} from '@/modules/entities/Addons/constants';
import {
  ATTENDEE_TYPE_FIELD_DEPOSIT,
  ATTENDEE_TYPE_FIELD_EARLY_ARRIVAL_DAYS,
  ATTENDEE_TYPE_FIELD_EARLY_ARRIVAL_FEE,
  ATTENDEE_TYPE_FIELD_FEE,
  ATTENDEE_TYPE_FIELD_IS_ROSTER_LEAD,
  ATTENDEE_TYPE_FIELD_MAX_CAPACITY,
  ATTENDEE_TYPE_FIELD_MIN_CAPACITY,
  ATTENDEE_TYPE_FIELD_TYPE_NAME,
} from '@/modules/entities/AttendeeTypes/constants';

import { RawSessionFromCSV } from '../../types';

import { SessionCsvColumnsConfig } from './types';

export const moduleName = 'importCsvFileModal';

export const SESSION_CSV_REPORT_DATA_ID = 'sessionCsvReport';

export const INTERNAL_COLUMN_DELIMITERS = Object.freeze({
  delimiter: ';',
  newline: '%',
});

// this date format allows the leading zeros removed by ms spreadsheet program
export const CSV_DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm';

const requiredIfIsGroup = (
  _session: RawSessionFromCSV,
  columnName: string,
  isGroup: boolean,
): string => (isGroup ? `${columnName} required on group events` : '');

const requiredIfAllowEarlyBirdRegistration = (
  { allowEarlyBirdRegistration }: RawSessionFromCSV,
  columnName: string,
): string =>
  allowEarlyBirdRegistration ? `${columnName} required if early bird registration is allowed` : '';

export const sessionCsvColumnsConfig: Readonly<SessionCsvColumnsConfig> = {
  // basic session data
  sessionCode: {
    name: 'Session Code (identifier)',
    expectedType: 'string',
  },
  sessionName: {
    name: 'Session Name',
    required: true,
    expectedType: 'string',
  },
  description: {
    name: 'Description',
    expectedType: 'string',
  },
  addonsSummary: {
    name: 'Help text for Addons Summary',
    expectedType: 'string',
  },
  startDate: {
    name: 'Start Date',
    required: true,
    isDate: true,
    expectedType: 'string',
  },
  closeDate: {
    name: 'Close Date',
    required: true,
    isDate: true,
    expectedType: 'string',
  },
  programCode: {
    name: 'Program Code',
    required: true,
    expectedType: 'string',
  },
  accountCode: {
    name: 'Ledger Account Code or Name',
    required: true,
    expectedType: 'string',
  },
  // session capacity fields (no multi session)
  attendeeCapacity: {
    name: 'Session Attendee Capacity',
    required: true,
    expectedType: 'number',
  },
  maximumRosterCount: {
    name: 'Session Maximum Roster Count',
    requiredIf: requiredIfIsGroup,
    expectedType: 'number',
    isForGroupOnly: true,
  },
  // roster capacity fields
  minimumPeopleCount: {
    name: 'Per Crew Min Capacity',
    requiredIf: requiredIfIsGroup,
    expectedType: 'number',
    isForGroupOnly: true,
  },
  maximumPeopleCount: {
    name: 'Per Crew Max Capacity',
    requiredIf: requiredIfIsGroup,
    expectedType: 'number',
    isForGroupOnly: true,
  },
  // roster fee fields
  isFeePerPerson: {
    name: 'Is Fee Per Person',
    requiredIf: requiredIfIsGroup,
    expectedType: 'boolean',
    isForGroupOnly: true,
  },
  deposit: {
    name: 'Deposit Per Person Or Crew',
    requiredIf: requiredIfIsGroup,
    expectedType: 'number',
    isForGroupOnly: true,
  },
  additionalPersonFee: {
    name: 'Fee Per Person or Additional Person Charge',
    requiredIf: ({ isFeePerPerson }, _, isGroup) => {
      if (!isGroup) return '';
      return isFeePerPerson
        ? 'Fee per Person required if crew fee is per person'
        : 'Additional Person Charge required if fee is per crew';
    },
    expectedType: 'number',
    isForGroupOnly: true,
  },
  baseRosterFee: {
    name: 'Crew Base Fee',
    requiredIf: ({ isFeePerPerson }, columnName, isGroup) => {
      if (!isGroup) return '';
      return !isFeePerPerson ? `${columnName} required if fee is per crew` : '';
    },
    expectedType: 'number',
    isForGroupOnly: true,
  },
  baseFeePersonCount: {
    name: 'Crew Base Fee For Up To How Many Persons', // validate dynamically
    requiredIf: ({ isFeePerPerson }, columnName, isGroup) => {
      if (!isGroup) return '';
      return !isFeePerPerson ? `${columnName} required if fee is per crew` : '';
    },
    expectedType: 'number',
    isForGroupOnly: true,
  },
  // early bird fee fields
  allowEarlyBirdRegistration: {
    name: 'Allow Early Bird Registration',
    required: true,
    expectedType: 'boolean',
  },
  earlyBirdDeposit: {
    name: 'Early Bird Deposit',
    requiredIf: requiredIfAllowEarlyBirdRegistration,
    expectedType: 'number',
  },
  earlyBirdSessionFee: {
    name: 'Early Bird Session Fee',
    requiredIf: requiredIfAllowEarlyBirdRegistration,
    expectedType: 'number',
  },
  earlyBirdDayCount: {
    name: 'Early Bird Day Count',
    requiredIf: requiredIfAllowEarlyBirdRegistration,
    expectedType: 'number',
  },
  // extra fee fields
  earlyStartDays: {
    name: 'Early Start Days',
    requiredIf: requiredIfAllowEarlyBirdRegistration,
    expectedType: 'number',
    isForGroupOnly: true,
  },
  earlyStartPricePerDay: {
    name: 'Early Arrival Fee',
    expectedType: 'number',
    isForGroupOnly: true,
  },
  leaveLateDays: {
    name: 'Leave Late Days',
    expectedType: 'number',
    isForGroupOnly: true,
  },
  leaveLatePricePerDay: {
    name: 'Leave Late Fee',
    expectedType: 'number',
    isForGroupOnly: true,
  },
  // allowed attendee types fields
  attendeeTypes: {
    name: 'Session Attendee Types',
    required: true,
    expectedType: 'string',
    internalColumnsConfig: [
      { key: ATTENDEE_TYPE_FIELD_TYPE_NAME },
      { key: ATTENDEE_TYPE_FIELD_MIN_CAPACITY },
      { key: ATTENDEE_TYPE_FIELD_MAX_CAPACITY },
      { key: ATTENDEE_TYPE_FIELD_IS_ROSTER_LEAD },
      { key: ATTENDEE_TYPE_FIELD_DEPOSIT, isForIndividualOnly: true },
      { key: ATTENDEE_TYPE_FIELD_FEE, isForIndividualOnly: true },
      { key: ATTENDEE_TYPE_FIELD_EARLY_ARRIVAL_DAYS, isForIndividualOnly: true },
      { key: ATTENDEE_TYPE_FIELD_EARLY_ARRIVAL_FEE, isForIndividualOnly: true },
    ],
  },
  activities: {
    name: 'Activities',
    expectedType: 'string',
    internalColumnsConfig: [
      { key: ACTIVITY_FIELD_ACTIVITY_NAME },
      { key: ACTIVITY_FIELD_IS_MANDATORY },
    ],
  },
  addons: {
    name: 'Addons',
    expectedType: 'string',
    internalColumnsConfig: [
      { key: ADDON_FIELD_ADDON_NAME },
      { key: ADDON_FIELD_IS_MANDATORY },
      { key: ADDON_FIELD_DEFAULT_ADDON_OPTION_CODE },
    ],
  },
  isHidden: {
    name: 'Hide Session on registration form',
    expectedType: 'boolean',
  },
};
