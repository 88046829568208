import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import TagSelector from '@/modules/shared/components/TagSelector';

import { queryFilterVariablesForReportSel } from '../../../../duck/selectors';
import { addColumns } from '../../duck/actions';
import { columnMetadataSel } from '../../duck/selectors';

import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const AddColumnsModal: React.FC<Props> = ({ modalParams: { dataType } }) => {
  const dispatch = useDispatch();
  const existingColumns = useSelector((state: RootState) => columnMetadataSel(state, { dataType }));
  const queryFilterVariables = useSelector((state: RootState) =>
    queryFilterVariablesForReportSel(state, { dataType }),
  );

  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  function handleSubmit() {
    dispatch(
      addColumns({
        columns: selectedColumns.map(name => ({ name })),
        dataType,
      }),
    );
  }

  const disabledItems = useMemo(
    () => new Set(existingColumns.map(({ name }) => name)),
    [existingColumns],
  );

  return (
    <ModalComponent
      title="Add Columns"
      buttons={[
        {
          title: 'Add Columns',
          onClick: handleSubmit,
          type: 'primary',
          disabled: !selectedColumns.length,
        },
      ]}
    >
      <TagSelector
        dataSource={queryFilterVariables}
        disabledItems={disabledItems}
        valueField="value"
        labelField="caption"
        onChange={setSelectedColumns}
        value={selectedColumns}
      />
    </ModalComponent>
  );
};

export default AddColumnsModal;
