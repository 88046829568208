import dayjs from 'dayjs';
import React, { Fragment, useMemo } from 'react';
import { MdPersonOff, MdInfo } from 'react-icons/md';

import { FoundPerson } from '@/modules/data/dataTypes/foundPersons';
import { ApiError } from '@/modules/utils/apiService';
import { dateFromString } from '@/modules/utils/dateFormats';

import { Error, NotFound, UserInfo } from './FoundPersonContent.styled';
import EligibilityMessages from './components/EligibilityMessages';
import NoUsernameContent from './components/NoUsernameContent';

interface Props {
  error?: ApiError;
  foundPerson?: FoundPerson;
  pivotDate?: string;
  title?: string;
  showEligibilityContent?: boolean;
  isEligible?: boolean;
  eligibilityMessages?: string[];
}

const FoundPersonContent: React.FC<Props> = ({
  foundPerson,
  pivotDate,
  error,
  title,
  showEligibilityContent,
  isEligible = false,
  eligibilityMessages = [],
}) => {
  const memberId = foundPerson?.memberID || '';

  const ageOnDate = useMemo(() => {
    if (!foundPerson) return null;

    const { dateOfBirth } = foundPerson;

    if (!dateOfBirth || !pivotDate) {
      return null;
    }

    const mPivotDate = dateFromString(pivotDate);
    const mDateOfBirth = dateFromString(dateOfBirth);

    if (mPivotDate.isValid() && mDateOfBirth.isValid()) {
      return dayjs(mPivotDate).diff(mDateOfBirth, 'years');
    }
  }, [foundPerson, pivotDate]);

  if (showEligibilityContent) {
    if (foundPerson && !foundPerson.ULUserName && foundPerson.lastName) {
      return (
        <NoUsernameContent foundPerson={foundPerson} memberID={memberId} isEligible={isEligible} />
      );
    }

    if (eligibilityMessages.length) {
      return <EligibilityMessages messages={eligibilityMessages} />;
    }
  }

  if (error) {
    if (error.responseCode === '-6') {
      return (
        <NotFound>
          <MdPersonOff />
          <span>Person not found</span>
        </NotFound>
      );
    }

    return (
      <Error>
        <MdInfo />
        {error.responseCode === '-5' ? (
          <div>
            The member ID {memberId} associated with this account is not valid. If user has received
            an updated member ID from your council, they can visit
            <a href="https://my.scouting.org/tools/manage-member-id" target="_blank">
              <b> My.Scouting - Manage Member Id </b>
            </a>
            to update the account. Simply enter their new Member ID and then select it to be the
            "Primary" Member ID. If they are not aware of any other Member ID, please contact their
            council for assistance.
          </div>
        ) : (
          <div>{error.message}</div>
        )}
      </Error>
    );
  }

  if (!foundPerson) {
    return <NotFound />;
  }

  const { firstName, lastName, age, emailAddress, EMSEmailAddress } = foundPerson;

  return (
    <Fragment>
      {title && <h3 className="mb-2">{title}</h3>}
      <UserInfo>
        <b>Name:</b> {firstName} {lastName}
      </UserInfo>
      <UserInfo>
        <b>Email Address:</b> {emailAddress || EMSEmailAddress}
      </UserInfo>
      <UserInfo>
        <b>Member ID:</b> {memberId}
      </UserInfo>
      <UserInfo>
        {ageOnDate ? (
          <Fragment>
            <b>Age when event starts:</b> {ageOnDate}
          </Fragment>
        ) : (
          <Fragment>
            <b>Current age:</b> {age}
          </Fragment>
        )}
      </UserInfo>
    </Fragment>
  );
};

export default FoundPersonContent;
