import { get } from 'lodash';
import { TimeoutError, timer } from 'rxjs';
import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, map, retry } from 'rxjs/operators';

import toastService from '@/modules/toasts/service';
import { api as reportApiError } from '@/modules/utils/monitoringService';

import { API_ENV, REACT_APP_LOCAL } from '../config/config';
import { pageLoadCompleted } from '../routing/duck/actions';
import { WindowType } from '../types/misc';
import { IS_MASQUERADED } from '../user/constants';
import { logout, ROUTE_LOGIN, showSessionExpiredModal } from '../user/duck/actions';
import { tokenSel } from '../user/duck/selectors';

import { startPerformanceMeasure } from 'UTILS/analyticsService';

export class ApiError extends Error {
  responseCode: string;

  constructor(message: string, responseCode: string) {
    super(message);
    this.responseCode = responseCode;
  }
}

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type ApiResponse = {
  responseCode: string;
  responseMessage: string;
  responseValue?: string;
  recordCount?: number;
};

export const arnicaApiPrefix = '/vim2';
const coreApiPrefix = '/api';
const esbApiPrefix = REACT_APP_LOCAL ? '/esb' : `https://api${API_ENV}.scouting.org`;
const paymentsApiPrefix = '/pymt';

const dLockError = 'DEADLOCK_ERROR';

export const createParamsFromObject = (data: Record<string, unknown>) =>
  Object.entries(data).reduce((acc, [key, value]) => `${acc}&${key}=${value}`, '');

export const getApiPrefix = (endpoint: string) => {
  if (/\/users\/.*/.test(endpoint)) {
    return coreApiPrefix;
  } else if (/\/webscript\/output\//i.test(endpoint)) {
    return arnicaApiPrefix;
  } else if (/\/payment\//i.test(endpoint)) {
    return paymentsApiPrefix;
  }

  return esbApiPrefix;
};

const createEsbRequest = (options = {}) => {
  let token = '';

  if ((window as WindowType).reduxStore) {
    const state = (window as WindowType).reduxStore.getState();
    token = tokenSel(state) as string;
  }

  return {
    ...options,
    headers: {
      ...get(options, 'headers', {}),
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
    crossDomain: true,
  };
};
const createDefaultOptions = (options = {}) => ({
  ...options,
  headers: {
    ...get(options, 'headers', {}),
    'Content-Type': 'application/json',
  },
});

const getBaseOptions = () => {
  // validating masquerade
  const isMasqueraded = localStorage.getItem(IS_MASQUERADED);
  let headers = {};
  if (isMasqueraded === 'true' && (window as WindowType).reduxStore) {
    const state = (window as WindowType).reduxStore.getState();
    const token = tokenSel(state);
    headers = {
      Authorization: token ? `Bearer ${token}` : '',
    };
  }

  return {
    credentials: 'include',
    headers,
  };
};

const apiCall$ = <T>(
  method: RequestMethod,
  endpoint: string,
  body = {},
  options = {},
  validator?: <R extends ApiResponse>(response: R) => R,
) => {
  const prefix = getApiPrefix(endpoint);
  // Need to add this flag to remove unsupported header on esb API.
  const esbCompliantOptions =
    prefix === esbApiPrefix ? createEsbRequest(options) : createDefaultOptions(options);
  const markName =
    prefix === arnicaApiPrefix ? endpoint.split('ScriptCode=')[1].split('&')[0] : endpoint;

  const performanceMeasureObject = startPerformanceMeasure(markName);

  return ajax<T & ApiResponse>({
    method,
    url: `${prefix}${endpoint}`,
    body: JSON.stringify(body),
    ...getBaseOptions(),
    ...esbCompliantOptions,
  }).pipe(
    map(({ response, responseType, status }) => {
      if (responseType === 'text') {
        return response;
      }

      try {
        // Finish api timing, track and clear.
        performanceMeasureObject.finishPerformanceMeasureAndClear(prefix);
      } catch (e) {
        // Ignore warning
      }

      if (!response) {
        if (status === 204) {
          return {
            responseCode: '1',
            responseMessage: 'Success',
          } as T & ApiResponse;
        }
        throw new ApiError('Response is null', '-1');
      }

      const { responseCode, responseMessage } = response;

      if (responseCode === '1') return response;

      // Log if status 200 and Arnica request and statusCode different than 1
      if (prefix === arnicaApiPrefix && status === 200) {
        reportApiError({
          status: responseCode,
          request: {
            url: prefix + endpoint,
            body,
            options,
          },
        });

        switch (responseCode) {
          // Handle -100 & -120 error globally and logout the user.
          // case '-100': // THIS NEEDS ANOTHER HANDLER, IT IS NOT DEADLOCK_ERROR, IT IS SESSION TOKEN EXPIRED SENT
          case '-120':
            throw new Error(dLockError);
          case '-102':
            (window as WindowType).reduxStore.dispatch(showSessionExpiredModal());
            break;
          default:
            if (validator) return validator(response);
            throw new ApiError(responseMessage, responseCode);
        }
      }

      return response;
    }),
    retry({
      count: 2,
      delay(error) {
        if (error instanceof ApiError || get(error, ['message'], error) !== dLockError) {
          throw error;
        }
        return timer(1000);
      },
      resetOnSuccess: true,
    }),
    catchError((err: Error | ApiError | AjaxError) => {
      if (err instanceof ApiError) {
        throw err;
      }
      const message = get(err, ['message'], err);

      if (message === dLockError) {
        toastService.error('We could not complete the request, logging out now');
        setTimeout(() => {
          (window as WindowType).reduxStore.dispatch(logout.request());
          (window as WindowType).reduxStore.dispatch(pageLoadCompleted(ROUTE_LOGIN));
        }, 1000);
      }

      try {
        // Clear api timing.
        performanceMeasureObject.clearFailedPerformanceMeasure();
      } catch (e) {
        // Ignore warning
      }

      if ('status' in err) {
        const { status } = err;
        if (status >= 400) {
          throw new ApiError(get(err, ['response', 'message'], 'Error'), '-1');
        }

        if ([0, 504].includes(status)) {
          throw new TimeoutError();
        }
      }

      throw new Error(get(err, ['response', 'message'], 'Detailed error not available'));
    }),
  );
};

const apiService = {
  get$: <T>(endpoint: string, options = {}) => apiCall$<T>('GET', endpoint, undefined, options),
  post$: <T>(
    endpoint: string,
    body = {},
    options = {},
    validator?: <R extends ApiResponse>(response: R) => R,
  ) => apiCall$<T>('POST', endpoint, body, options, validator),
  put$: <T>(endpoint: string, body = {}, options = {}) =>
    apiCall$<T>('PUT', endpoint, body, options),
  patch$: <T>(endpoint: string, body = {}, options = {}) =>
    apiCall$<T>('PATCH', endpoint, body, options),
  delete$: <T>(endpoint: string, body = {}, options = {}) =>
    apiCall$<T>('DELETE', endpoint, body, options),
};

const arnicaApis = {
  FormRecordsMassUpdate: {
    method: 'POST',
    container: 'EventData',
  },
  ValidateContextAndUpdateFormPerson: {
    method: 'GET',
    container: 'EventData',
  },
} as const;

export const arnicaApiService = <T>(
  apiName: keyof typeof arnicaApis,
  body = {},
  options = {},
  validator?: <R extends ApiResponse>(response: R) => R,
) => {
  const { method, container } = arnicaApis[apiName];
  const endpoint = `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=${container}&ScriptCode=${apiName}${
    method === 'GET' ? createParamsFromObject(body) : ''
  }`;

  return apiCall$<T>(method, endpoint, method === 'GET' ? undefined : body, options, validator);
};

export default apiService;
