import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button as AntButton } from 'antd';
import styled, { css } from 'styled-components';

const caretStyles = css`
  font-size: 10px;
`;

export const CaretUp = styled(CaretUpOutlined)`
  ${caretStyles}
`;

export const CaretDown = styled(CaretDownOutlined)`
  ${caretStyles}
`;

export const Button = styled(AntButton)<{ $isActive: boolean }>`
  box-shadow: none;

  &.ant-btn {
    min-width: fit-content;
    color: #515354;
    width: 50px;
    height: 45px;
    border-radius: 0;
  }

  :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  :not(:first-child) {
    border-left: none;
  }
  :not(:last-child) {
    border-right: none;
  }

  > *:first-child {
    font-size: 20px;
  }

  :hover {
    color: unset;
    background: unset;
  }

  ${({ $isActive }) =>
    $isActive
      ? `
          background-color: #ccc;
          :hover {
            background-color: #ccc;
          }
        `
      : ''}
`;
