import { Button } from 'antd';
import React from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const OpenReservationButton = styled(Button).attrs(() => ({
  children: <AiOutlineRight />,
  ghost: true,
}))`
  &&& {
    box-shadow: none;
    min-width: 0;
    color: ${colors.darkGrey};
    width: fit-content;
    height: fit-content;
  }
`;
