import { Person } from '@/modules/data/dataTypes/person';
import apiService from '@/modules/utils/apiService';

const updatePerson$ = (data: Partial<Person> & { isLocal?: boolean }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdatePerson',
    data,
  );

export default {
  updatePerson$,
};
