import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { closeModal, openModal, updateModalParams } from './actions';

const name = createReducer('')
  .handleAction(openModal, (_state, { modalName }) => modalName)
  .handleAction(closeModal, () => '');

const params = createReducer({})
  .handleAction(openModal, (_state, action) => action.params)
  .handleAction(updateModalParams, (state, { payload }) => ({ ...state, ...payload }))
  .handleAction(closeModal, () => ({}));

const props = createReducer({})
  .handleAction(openModal, (_state, action) => action.props)
  .handleAction(closeModal, () => ({}));

export default combineReducers({
  name,
  params,
  props,
});
