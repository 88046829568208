import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import { primaryEmailSel } from '@/modules/user/duck/selectors';

import { joinSessionWaitlist } from '../../duck/actions';
import { joinWaitlistInProgressSel } from '../../duck/selectors';

import styles from './JoinWaitlistModal.less';
import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const WaitlistModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const sessionName = modalParams.sessionName || 'Session';
  const isLoading = useSelector(joinWaitlistInProgressSel);
  const email = useSelector(primaryEmailSel);

  const handleOk = () => {
    const { sessionName: name, ...params } = modalParams;
    dispatch(joinSessionWaitlist.request(params));
  };

  return (
    <ModalComponent
      title={`Join Waitlist for ${sessionName}`}
      buttons={[
        {
          title: 'Join Waitlist',
          onClick: handleOk,
          type: 'primary',
          className: styles.button,
          size: 'small',
        },
      ]}
      inProgress={isLoading}
      centerButtons
    >
      <p className={styles.header}>
        <strong>
          <span>SESSION FULL </span>
          {`${sessionName} has a waitlist`}
        </strong>
      </p>
      <p>
        Would you like to join the {sessionName} waitlist? you will be notified at{' '}
        <strong>{email}</strong> email address if you reach the top of the waitlist
      </p>
    </ModalComponent>
  );
};

export default WaitlistModal;
