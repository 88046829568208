import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { BaseSelectRef } from 'rc-select';
import React from 'react';

import L from '../L';

import { SelectWrapper } from './SelectInputMaterial.styled';

type Props<T> = SelectProps<T> & {
  labelContent?: React.ReactNode;
  forInput?: string;
  value?: string | string[];
  children?: React.ReactNode;
};

const SelectInputMaterial = <T extends SelectValue>(
  { labelContent, forInput, ...props }: Props<T>,
  ref: React.Ref<BaseSelectRef>,
) => (
  <SelectWrapper>
    {labelContent && (
      <L size="1" forInput={forInput}>
        {labelContent}
      </L>
    )}
    <Select {...props} size="large" ref={ref} />
  </SelectWrapper>
);

export default React.forwardRef(SelectInputMaterial) as <T extends SelectValue>(
  p: Props<T> & { ref?: React.Ref<typeof Select> },
) => React.ReactElement;
