import { Row } from 'antd';
import { CollapsePanelProps } from 'antd/lib/collapse';
import React, { ComponentType, Fragment } from 'react';

import Desktop from '@/modules/responsiveSizes/Desktop';
import Mobile from '@/modules/responsiveSizes/Mobile';
import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';

import { Panel, Title, HeaderButton } from './ReservationDetailsSection.styled';

type Props = CollapsePanelProps & {
  headerActions?: React.ReactNode | null;
  headerButtons?: Array<
    | {
        Icon: ComponentType;
        onClick: () => void;
        key: string;
      }
    | React.ReactElement
    | null
  >;
};

const Section: React.FC<Props> = ({
  header,
  children,
  headerActions,
  headerButtons = [],
  ...rest
}) => {
  const screenSize = useGetScreenSize();

  const createOnClickHandler = (onClick: () => void) => (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <Panel
      {...rest}
      {...(screenSize === 'desktop'
        ? {
            expandIcon: null,
          }
        : {})}
      $isDesktop={screenSize === 'desktop'}
      header={
        <Fragment>
          <Title>{header}</Title>
          {headerButtons.map(hb => {
            if (!hb) return null;
            if ('onClick' in hb) {
              const { Icon, onClick, key } = hb;
              return (
                <HeaderButton
                  type="text"
                  icon={<Icon />}
                  key={key}
                  onClick={createOnClickHandler(onClick)}
                />
              );
            }
            return hb;
          })}
          {headerActions}
        </Fragment>
      }
    >
      <Desktop>
        <Row>{children}</Row>
      </Desktop>
      <Mobile>{children}</Mobile>
    </Panel>
  );
};

export default Section;
