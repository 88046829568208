import { createAction } from 'typesafe-actions';

import { moduleName, ROUTE_FORMS, stepKeys } from '../constants';
import type { GroupReservationPayload, IndividualReservationPayload } from '../types';

export const setCurrentGroupReservationGUID = createAction(
  'SET_CURRENT_GROUP_RESERVATION_GUID',
)<string>();

export const setFormRecordGuid = createAction(`${moduleName}/SET_FORM_RECORD_GUID`)<string>();

export const openForm = createAction(ROUTE_FORMS)<
  IndividualReservationPayload | GroupReservationPayload
>();

export const openPrevStep = createAction(`${moduleName}/OPEN_PREV_STEP`)();
export const openNextStep = createAction(`${moduleName}/OPEN_NEXT_STEP`)();

export const openStepByIndex = createAction(`${moduleName}/OPEN_STEP_BY_INDEX`)<number>();
export const openStepByKey = createAction(`${moduleName}/OPEN_STEP_BY_KEY`)<stepKeys>();

export const repeatOpenStep = createAction(`${moduleName}/REPEAT_OPEN_STEP`)();

export const openStepCompleted = createAction(`${moduleName}/OPEN_STEP_COMPLETED`)();

export const disableStep = createAction(`${moduleName}/DISABLE_STEP`)<{
  stepKey: stepKeys;
  reason: string;
}>();
export const enableStep = createAction(`${moduleName}/ENABLE_STEP`)<stepKeys>();

export const initRegistrationTimer = createAction(`${moduleName}/INIT_REGISTRATION_TIMER`)();

export const setRegistrationTimeLeft = createAction(
  `${moduleName}/SET_REGISTRATION_TIME_LEFT`,
)<number>();

export const stopRegistrationTimer = createAction(`${moduleName}/STOP_REGISTRATION_TIMER`)();
