import { createAction, createAsyncAction } from 'typesafe-actions';

import { ColumnMetadataItem } from '@/modules/data/dataTypes/queryFilterList';
import { FilterContentSection, ReportDataType } from '@/modules/reports/types';
import { ApiError } from '@/modules/utils/apiService';
import { createModuleNamespaceName } from '@/modules/utils/misc';

import { modulePath } from '../constants';

const moduleNameSpace = createModuleNamespaceName(modulePath);

export const selectRows = createAction(`${moduleNameSpace}/SELECT_ROWS`)<string[]>();

export const resetRowsSelection = createAction(`${moduleNameSpace}/RESET_ROWS_SELECTION`)();

export const updateColumnMetadata = createAsyncAction(
  `${moduleNameSpace}/UPDATE_COLUMN_METADATA_REQUEST`,
  `${moduleNameSpace}/UPDATE_COLUMN_METADATA_SUCCESS`,
  `${moduleNameSpace}/UPDATE_COLUMN_METADATA_FAILURE`,
)<
  {
    dataType: ReportDataType;
    nextColumnMetadata: ColumnMetadataItem[];
  },
  ColumnMetadataItem[],
  Error
>();

export const resetColumns = createAction(`${moduleNameSpace}/RESET_COLUMNS`)();

export const updateQueryFilterName = createAction(`${moduleNameSpace}/UPDATE_QUERY_FILTER_NAME`)<
  string | null
>();

export const saveReport = createAsyncAction(
  `${moduleNameSpace}/SAVE_REPORT_REQUEST`,
  `${moduleNameSpace}/SAVE_REPORT_SUCCESS`,
  `${moduleNameSpace}/SAVE_REPORT_FAILURE`,
)<
  {
    dataType: ReportDataType;
    currentQueryFilterCode: string;
    isNew: boolean;
  },
  undefined,
  ApiError | undefined
>();

export const addColumns = createAction(`${moduleNameSpace}/ADD_COLUMN`)<{
  dataType: ReportDataType;
  columns: { name: string }[];
}>();

export const deleteColumn = createAction(`${moduleNameSpace}/DELETE_COLUMN`)<{
  dataType: ReportDataType;
  columnName: string;
}>();

export const moveColumn = createAction(`${moduleNameSpace}/MOVE_COLUMN`)<{
  dataType: ReportDataType;
  sourceColumnName: string;
  targetColumnName: string;
}>();

export const resizeColumn = createAction(`${moduleNameSpace}/RESIZE_COLUMN`)<{
  dataType: ReportDataType;
  columnName: string;
  columnWidth: number;
}>();

export const updateFilterContent = createAction(`${moduleNameSpace}/UPDATE_FILTER_CONTENT`)<{
  dataType: ReportDataType;
  filterContent: FilterContentSection;
}>();

export const updateSortOrder = createAction(`${moduleNameSpace}/UPDATE_SORT_ORDER`)<{
  dataType: ReportDataType;
  columnName: string;
  order?: 'ASC' | 'DESC';
}>();

export const resetCurrentReport = createAsyncAction(
  `${moduleNameSpace}/RESET_TABLE_DATA_REQUEST`,
  `${moduleNameSpace}/RESET_TABLE_DATA_SUCCESS`,
  `${moduleNameSpace}/RESET_TABLE_DATA_FAILURE`,
)<ReportDataType, undefined, Error>();
