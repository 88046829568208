import apiService from '@/modules/utils/apiService';

import { updateFormRecord } from './actions';

const updateFormRecord$ = (payload: ReturnType<typeof updateFormRecord.request>['payload']) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateFormRecord',
    payload,
  );

export default {
  updateFormRecord$,
};
