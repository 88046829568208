import { Select as AntSelect, Tag as AntTag } from 'antd';
import styled from 'styled-components';

import InputWrapperShared from '../InputWrapper';

export const TagSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 100%;
`;

export const SelectWrapper = styled(InputWrapperShared)`
  .ant-select-selection__rendered > ul > li:not(:last-child) {
    display: none;
  }

  margin-bottom: 20px;
`;

export const Tag = styled(AntTag)`
  border: none;
  background-color: #f2f2f2;
  border-radius: 18px;
  display: inline-flex;
  align-items: center;
  height: 30px;
  margin-bottom: 5px;
`;

export const Select = styled(AntSelect)`
  width: 100%;
`;
