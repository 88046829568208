export const MULTI_SESSION_KEY = 'multiSessionRuleCode';
export const MULTI_SESSION_INCLUDE_CODE = 'includeMultiSession';
export const MIN_PEOPLE_COUNT_KEY = 'minimumPeopleCount';
export const MAX_PEOPLE_COUNT_KEY = 'maximumPeopleCount';
export const MAX_ROSTER_COUNT_KEY = 'maximumRosterCount';
export const ATTENDEE_CAPACITY_KEY = 'attendeeCapacity';

export const ATTENDEE_TYPES_KEY = 'attendeeTypes';
export const LEAD_ADVISOR_KEY = 'leadAdvisor';
export const START_DATE_KEY = 'startDate';
export const CLOSE_DATE_KEY = 'closeDate';
