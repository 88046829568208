import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { routeSel } from '@/modules/routing/duck/selectors';
import routes from '@/modules/routing/routes';

import { appName } from '../../constants';

const PageTitle = () => {
  const intl = useIntl();
  const route = useSelector(routeSel);
  const titleId: string | undefined = routes[route]?.titleId;
  let title = appName;
  if (titleId) {
    title = `${intl.formatMessage({ id: titleId })} | ${title}`;
  }
  document.title = title;

  return null;
};

export default PageTitle;
