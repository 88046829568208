import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PermissionAction } from '@/modules/entities/Roles/constants';
import { CHANGE_RESERVATION_SESSION_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';
import ReservationActivities from '@/modules/shared/components/ReservationActivities';
import ReservationAddons from '@/modules/shared/components/ReservationAddons';
import ReservationSession from '@/modules/shared/components/ReservationSession';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { RCStatus } from '@/pages/reservation/components/MyReservation/components/types';
import {
  navigateToEditGroupActivities,
  navigateToEditGroupAddons,
} from '@/pages/reservation/duck/actions';
import { pageDataSel } from '@/pages/reservation/duck/selectors';
import { groupReservationTabDataSel } from '@/pages/reservation/tabs/OverviewGroup/duck/selectors';

import { ChangeSessionModalParams } from '../../../ChangeReservationSessionModal/types';
import { Collapse } from '../../Details.styled';
import ReservationContactInformation from '../ReservationContactInformation';

import ReservationSizeSection from './components/ReservationSizeSection';
import { sections } from './constants';

import { daysBetween, formattedDatesRange } from 'UTILS/dateFormats';

const GroupReservationSections: React.FC = () => {
  const dispatch = useDispatch();
  const screenSize = useGetScreenSize();
  const {
    data: { groupReservationActivities, groupReservationAddons },
  } = useSelector(groupReservationTabDataSel);

  const {
    data: { form, groupReservation },
  } = useSelector(pageDataSel);

  const { formCode } = form;
  const {
    groupReservationGUID,
    initialPeopleCount,
    reservationContact,
    pendingContact,
    sessionName,
    sessionStartDate,
    sessionCloseDate,
    additionalReservationContact,
    sessionCode,
    initialRosterCount,
  } = groupReservation;

  const canEditReservationSession = useSelector(
    createAbilitiesSelector('groupReservation', PermissionAction.Update, groupReservation),
  );

  const canUpdateSelections = useSelector(
    createAbilitiesSelector('attendeeSelections', PermissionAction.Update, null),
  );

  const handleEditAddons = useCallback(() => {
    if (canUpdateSelections) {
      dispatch(navigateToEditGroupAddons(formCode, groupReservationGUID));
    }
  }, [canUpdateSelections, formCode, groupReservationGUID, dispatch]);

  const handleEditActivities = useCallback(() => {
    if (canUpdateSelections) {
      dispatch(navigateToEditGroupActivities(formCode, groupReservationGUID));
    }
  }, [canUpdateSelections, formCode, groupReservationGUID, dispatch]);

  const handleClickEditSession = useCallback(() => {
    const reservationOwnerName = `${reservationContact.firstName} ${reservationContact.lastName}`;
    const modalParams: ChangeSessionModalParams = {
      currentSessionCode: sessionCode,
      initialPeopleCount,
      initialRosterCount,
      reservationOwnerName,
    };

    return canEditReservationSession
      ? dispatch(openModal(CHANGE_RESERVATION_SESSION_MODAL, modalParams))
      : undefined;
  }, [
    canEditReservationSession,
    initialRosterCount,
    reservationContact,
    sessionCode,
    initialPeopleCount,
    dispatch,
  ]);

  const sessionLength =
    sessionStartDate && sessionCloseDate ? daysBetween(sessionStartDate, sessionCloseDate) : 0;
  const sessionDates =
    sessionStartDate && sessionCloseDate && formattedDatesRange(sessionStartDate, sessionCloseDate);
  const hasMainRCOnly = !pendingContact?.firstName && !additionalReservationContact?.firstName;
  const mainRCStatus = pendingContact?.position === 'RC' ? RCStatus.REPLACING : RCStatus.ACTIVE;
  const additionalRCStatus =
    pendingContact?.position === 'ARC' ? RCStatus.REPLACING : RCStatus.ACTIVE;
  const pendingRCPositionLabel =
    pendingContact?.position === 'RC'
      ? sections.reservationContact.title
      : sections.additionalReservationContact.title;

  const sectionKeys = useMemo(() => Object.values(sections).map(({ key }) => key), []);

  return (
    <Collapse
      expandIconPosition="end"
      {...(screenSize === 'desktop' ? { activeKey: sectionKeys } : {})}
    >
      <ReservationSizeSection
        key={sections.reservationSize.key}
        header={sections.reservationSize.title}
      />
      {reservationContact && (
        <ReservationContactInformation
          key={sections.reservationContact.key}
          sectionId={sections.reservationContact.key}
          header={sections.reservationContact.title}
          status={mainRCStatus}
          reservationContact={reservationContact}
          canAddARC={hasMainRCOnly}
        />
      )}
      {pendingContact?.firstName && (
        <ReservationContactInformation
          key={sections.pendingReservationContact.key}
          sectionId={sections.pendingReservationContact.key}
          header={sections.pendingReservationContact.title}
          status={RCStatus.PENDING}
          reservationContact={pendingContact}
          pendingRCPositionLabel={pendingRCPositionLabel}
        />
      )}
      {additionalReservationContact?.firstName && (
        <ReservationContactInformation
          key={sections.additionalReservationContact.key}
          sectionId={sections.additionalReservationContact.key}
          header={sections.additionalReservationContact.title}
          status={additionalRCStatus}
          reservationContact={additionalReservationContact}
        />
      )}
      <ReservationSession
        dates={sessionDates}
        key={sections.sessionSelected.key}
        header={sections.sessionSelected.title}
        length={sessionLength}
        name={sessionName}
        onEdit={canEditReservationSession ? handleClickEditSession : undefined}
      />
      <ReservationActivities
        isGroup
        activities={groupReservationActivities}
        key={sections.activities.key}
        header={sections.activities.title}
        onEditActivities={canUpdateSelections ? handleEditActivities : undefined}
      />
      <ReservationAddons
        addons={groupReservationAddons}
        key={sections.addOns.key}
        header={sections.addOns.title}
        onEditAddons={canUpdateSelections ? handleEditAddons : undefined}
      />
    </Collapse>
  );
};

export default GroupReservationSections;
