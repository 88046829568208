import apiService from '@/modules/utils/apiService';

const updateBroadcast$ = (payload: {
  broadcastContent: string;
  broadcastStartDate?: string;
  broadcastEndDate?: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateBroadcastData',
    payload,
  );

export default {
  updateBroadcast$,
};
