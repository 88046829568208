import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createDataSel, createPageDataSelector } from '@/modules/data/duck/selectors';
import { isPercentage } from '@/modules/entities/PaymentCategories/utils';

import { modulesPath, stepDataParams } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulesPath);

export const stepDataSel = createPageDataSelector(stepDataParams);

export const updateInstallmentInProgressSel = createSelector(
  moduleSel,
  ({ updateInstallmentInProgress }) => updateInstallmentInProgress,
);

export const currentInstallmentSel = createSelector(
  [moduleSel],
  ({ currentInstallment }) => currentInstallment,
);

export const totalPercentageSel = createSelector(
  [createDataSel('paymentCategories'), currentInstallmentSel],
  (installments, currentInstallment) =>
    installments
      .filter(i => {
        if (!isPercentage(i)) return false;
        if (!currentInstallment) return true;
        return i.paymentCategoryCode !== currentInstallment.paymentCategoryCode;
      })
      .reduce((acc, { paymentCategoryPercent }) => acc + Number(paymentCategoryPercent), 0),
);
