import { createAsyncAction, ActionType } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';

export const updateFormRecord = createAsyncAction(
  `${moduleName}/UPDATE_FORM_RECORD_REQUEST`,
  `${moduleName}/UPDATE_FORM_RECORD_SUCCESS`,
  `${moduleName}/UPDATE_FORM_RECORD_FAILURE`,
)<
  {
    formCode: string;
    typeCode?: string;
    personGUID?: string;
    unit?: string;
    formParts?: Array<{
      formPartCode: string;
      formItems: Array<{
        formItemCode: string;
        formItemValue?: string | number | boolean;
      }>;
    }>;
    sessionCode?: string;
    formRecordGUID?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    finalActions?: ActionType<any>[];
    primaryRegistrantFormRecordGUID?: string;
    successMessage?: string;
  },
  string,
  ApiError
>();
