import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const RostersTableDateReminder = styled.div`
  background-color: ${colors.complementColor};
  padding: 16px 24px;
  margin: 0;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
`;
