import { LeftOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  VERSION,
  API_ENV,
  RECAPTCHA_KEY,
  ENABLE_RECAPTCHA,
  GOOGLE_SIGN_ENABLED,
  DEBUG_DELAY_INPUT_ENABLED,
} from '@/modules/config/config';
import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';
import { login } from '@/modules/user/duck/actions';
import { loginInProgressSel } from '@/modules/user/duck/selectors';

import { Form, ChangeSignInButton, LoginButton, Subtitle } from '../../../../LoginComponent.styled';

import AppleSignIn from './AppleSignIn';
import DebugDelayTimeout from './DebugDelayTimeout';
import GoogleSignIn from './GoogleSignIn';
import {
  Captcha,
  CreateAccountButton,
  NoAccountMessage,
  PasswordReminder,
  PasswordReminderContainer,
  SocialMediaContainer,
  Version,
} from './LoginWithMyScouting.styled';

const createAccountUrl = `https://my${API_ENV}.scouting.org/create-account`;
const resetPasswordUrl = `https://my${API_ENV}.scouting.org/recover-credentials`;

type Props = {
  hideChangeSignInOption?: boolean;
  onSelectLoginOption: (loginOption: null) => void;
};

const LoginWithMyScouting: React.FC<Props> = ({ hideChangeSignInOption, onSelectLoginOption }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<{ login: string; password: string }>();
  const loginInProgress = useSelector(loginInProgressSel);
  const screenSize = useGetScreenSize();
  const [verified, setVerified] = useState(!ENABLE_RECAPTCHA);

  const disabled = loginInProgress || !verified;
  const isMobileScreen = screenSize === 'mobile';

  const handleLogin = () => {
    form.validateFields().then(values => {
      dispatch(login.request(values));
    });
  };

  const onVerify = () => {
    setVerified(true);
  };

  const handleClickSignInOptions = () => {
    onSelectLoginOption(null);
  };

  return (
    <Fragment>
      <Subtitle level={2}>
        <FormattedMessage id="user.LoginPage.signInWithMyscouting" />
      </Subtitle>
      <Form form={form} layout="vertical" $isMobile={isMobileScreen}>
        <Form.Item
          name="login"
          label={<FormattedMessage id="user.LoginPage.username" />}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" disabled={loginInProgress} />
        </Form.Item>
        <Form.Item
          name="password"
          label={<FormattedMessage id="user.LoginPage.password" />}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password size="large" disabled={loginInProgress} />
        </Form.Item>
        {ENABLE_RECAPTCHA && <Captcha sitekey={RECAPTCHA_KEY as string} onVerify={onVerify} />}
        <PasswordReminderContainer>
          <PasswordReminder href={resetPasswordUrl} id="recover" target="_blank">
            <FormattedMessage id="user.LoginPage.forgotUsernamePassword" />
          </PasswordReminder>
        </PasswordReminderContainer>
        <LoginButton
          type="primary"
          size="large"
          disabled={disabled}
          loading={loginInProgress}
          $isMobile={isMobileScreen}
          onClick={handleLogin}
        >
          <FormattedMessage id="user.LoginPage.login" />
        </LoginButton>
      </Form>
      <SocialMediaContainer $isMobile={isMobileScreen}>
        {GOOGLE_SIGN_ENABLED && <GoogleSignIn />}
        <AppleSignIn />
      </SocialMediaContainer>
      <NoAccountMessage level={3}>
        <FormattedMessage id="user.LoginPage.bsaEventManagement.noAccount" />
      </NoAccountMessage>
      <CreateAccountButton
        ghost
        href={createAccountUrl}
        id="register"
        $isMobile={isMobileScreen}
        target="_blank"
      >
        Create my.scouting account
      </CreateAccountButton>
      {!hideChangeSignInOption && (
        <ChangeSignInButton
          ghost
          id="change.signIn.option"
          $isMobile={isMobileScreen}
          onClick={handleClickSignInOptions}
        >
          <LeftOutlined />
          {'Change Sign In Option'}
        </ChangeSignInButton>
      )}
      {DEBUG_DELAY_INPUT_ENABLED && (
        <React.Fragment>
          <br />
          <DebugDelayTimeout />
        </React.Fragment>
      )}
      <Version>{VERSION}</Version>
    </Fragment>
  );
};

export default LoginWithMyScouting;
