import { Button as BsaButton } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

type Props = {
  color: string;
};

export const Button = styled(BsaButton)<Props>`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};

  :first-child {
    padding-left: 24px;
  }

  &.ant-btn.ant-btn-background-ghost {
    border: none;
  }

  &.ant-btn.ant-btn-background-ghost:disabled {
    color: ${colors.gray};
  }
`;
