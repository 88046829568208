import { Collapse } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const RegistrantName = styled.span`
  font-weight: bold;
  color: ${colors.scoutingBlue};
`;

export const Panel = styled(Collapse.Panel)`
  &&& {
    border: none;
    margin-bottom: 10px;
  }

  &&& .ant-collapse-content-active {
    padding-top: 10px;
  }

  &&& .ant-collapse-header {
    padding: 0;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;

    > i {
      right: 0;
    }
  }

  .ant-collapse-content-box {
    padding: 0;
  }
`;
