import React from 'react';

import styles from './PeopleAmount.less';

import CardWithHeader from 'SHARED/components/CardWithHeader';
import InputMaterial from 'SHARED/components/InputMaterial';

type Props = {
  numberOfAdults?: number;
  numberOfYouth?: number;
  onYouthChange: (amount?: number) => void;
  onAdultsChange: (amount?: number) => void;
};

const PeopleAmount: React.FC<Props> = ({
  numberOfYouth,
  numberOfAdults,
  onYouthChange,
  onAdultsChange,
}) => {
  const createAmountChangeHandler =
    (handler: typeof onYouthChange | typeof onAdultsChange) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (value) {
        const amount = Number(value);
        const roundedAmount = Math.round(amount);
        const positiveAmount = Math.abs(roundedAmount);

        handler(positiveAmount);
      } else {
        handler();
      }
    };

  return (
    <CardWithHeader header="Registration Information">
      Specify the number of youth and adults for the group
      <br />
      <br />
      <div className={styles.container}>
        <InputMaterial
          labelColored
          labelInlineBlock
          labelSize="1"
          labelContent="Number of Youth"
          type="number"
          value={numberOfYouth}
          onChange={createAmountChangeHandler(onYouthChange)}
        />
        <InputMaterial
          labelColored
          labelInlineBlock
          labelSize="1"
          labelContent="Number of Adults"
          type="number"
          value={numberOfAdults}
          onChange={createAmountChangeHandler(onAdultsChange)}
        />
      </div>
    </CardWithHeader>
  );
};

export default PeopleAmount;
