import React from 'react';
import { useSelector } from 'react-redux';

import { FormSettings } from '@/modules/data/dataTypes/form';
import { createDataSel } from '@/modules/data/duck/selectors';

import { ROUTE_FORMS } from '@/pages/createReservation/constants';

import { routeSel } from '../../duck/selectors';

import { FooterContent, Link } from './Footer.styled';

const Footer: React.FC = () => {
  const formSettings: FormSettings | undefined = useSelector(createDataSel('form'));
  const currentRoute = useSelector(routeSel);

  if (!formSettings?.contactEmail || currentRoute !== ROUTE_FORMS) return null;

  const { contactEmail, formName } = formSettings;

  return (
    <FooterContent>
      <div>
        For registration assistance contact{' '}
        <Link href={`mailto:${contactEmail}?subject=Event registration assistance for ${formName}`}>
          {contactEmail}
        </Link>
      </div>
    </FooterContent>
  );
};

export default Footer;
