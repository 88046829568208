import { Button } from 'antd';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import {
  SlidePanel as TheSlidePanel,
  Container,
  Panel,
  CloseButtonWrapper,
} from './SlidePanel.styled';

type Props = {
  wrapperClass?: string;
  className?: string;
  collapsed?: boolean;
  maskClosable?: boolean;
  hideCloseButton?: boolean;
  id?: string;
  layout?: {
    xs: { span: number; offset: number };
    sm: { span: number; offset: number };
    md: { span: number; offset: number };
  };
  handleClose?: () => void;
  content?: React.ReactNode;
  children?: React.ReactNode;
};

const SlidePanel = ({
  className,
  layout = {
    xs: { span: 24, offset: 100 },
    sm: { span: 24, offset: 100 },
    md: { span: 24, offset: 100 },
  },
  collapsed,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleClose = () => {},
  hideCloseButton = false,
  wrapperClass,
  content,
  children,
}: Props): JSX.Element => {
  const preventClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <TheSlidePanel className={wrapperClass}>
      <Container $isHidden={!!collapsed} onClick={handleClose}>
        <Panel {...layout} className={className} $isCollapsed={!!collapsed} onClick={preventClick}>
          {!hideCloseButton && (
            <CloseButtonWrapper>
              <Button type="text" icon={<AiOutlineClose />} onClick={handleClose} />
            </CloseButtonWrapper>
          )}
          {content}
        </Panel>
      </Container>
      {collapsed && children}
    </TheSlidePanel>
  );
};

export default SlidePanel;
