import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createDataSel, createPageDataSelector } from '@/modules/data/duck/selectors';
import { PermissionCode } from '@/modules/entities/Roles/constants';
import { currentUserData } from '@/modules/user/constants';
import {
  isEventAdministratorSel,
  isEventApprovalDesigneeSel,
  isSystemAdministratorSel,
} from '@/modules/user/duck/selectors';
import { hasAssignedPermissionWithCode } from '@/modules/user/duck/utils';

import { moduleName, dashboardTabs, pageDataParams } from '../constants';
import { isTabAllowed } from '../utils';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const pageDataSel = createPageDataSelector(pageDataParams);

export const deleteMailingInProgressSel = createSelector(
  moduleSel,
  ({ deleteMailingInProgress }) => deleteMailingInProgress,
);

export const dashboardTabsSel = createSelector(
  [
    isSystemAdministratorSel,
    isEventAdministratorSel,
    isEventApprovalDesigneeSel,
    createDataSel('arnicaPerson', currentUserData),
    createDataSel('form'),
  ],
  (isSystemAdministrator, isEventAdministrator, isEAD, currentPerson, form) =>
    dashboardTabs.filter(({ key }) =>
      isTabAllowed(
        key,
        isSystemAdministrator,
        isEventAdministrator,
        isEAD && form.requireCouncilApproval,
        currentPerson,
        form,
      ),
    ),
);

export const isLoadingCSVRequestSel = createSelector(
  moduleSel,
  ({ isLoadingCSVRequest }) => isLoadingCSVRequest,
);

export const showQuickSearchTabSel = createSelector(
  [pageDataSel, isSystemAdministratorSel, isEventAdministratorSel],
  (
    {
      data: {
        form: { isCreator, assignedRoles },
      },
    },
    isSystemAdministrator,
    isEventAdministrator,
  ) =>
    isSystemAdministrator ||
    isEventAdministrator ||
    isCreator ||
    hasAssignedPermissionWithCode(assignedRoles, PermissionCode.AttendeeDetailsReadAccess),
);
