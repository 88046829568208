import { MoreOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { FaTheaterMasks, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { clearBroadcast } from '@/modules/broadcast/duck/actions';
import { CREATE_BROADCAST_MODAL, MASQUERADE_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import Dropdown from '@/modules/shared/components/Dropdown';
import { logout } from '@/modules/user/duck/actions';
import { hasMasqueradeRoleSel, isProductOwnerSel } from '@/modules/user/duck/selectors';

import { IconContainer } from './Settings.styled';

const Settings: React.FC = () => {
  const dispatch = useDispatch();

  const hasMasqueradeRole: boolean = useSelector(hasMasqueradeRoleSel);
  const isProductOwner: boolean = useSelector(isProductOwnerSel);

  const menuItems = useMemo(() => {
    function handleLogout() {
      dispatch(logout.request(undefined));
    }

    function handleMasquerade() {
      dispatch(openModal(MASQUERADE_MODAL));
    }

    function handleCreateBroadcast() {
      dispatch(openModal(CREATE_BROADCAST_MODAL));
    }

    function handleClearBroadcast() {
      dispatch(clearBroadcast());
    }

    return [
      ...(hasMasqueradeRole
        ? [
            {
              key: 'masquerade',
              label: 'Masquerade',
              icon: <FaTheaterMasks />,
              onClick: handleMasquerade,
            },
          ]
        : []),
      ...(isProductOwner
        ? [
            {
              key: 'newBroadcast',
              label: 'New Broadcast',
              icon: <FaVolumeUp />,
              onClick: handleCreateBroadcast,
            },
            {
              key: 'clearBroadcast',
              label: 'Clear Broadcast',
              icon: <FaVolumeMute />,
              onClick: handleClearBroadcast,
            },
          ]
        : []),
      {
        key: 'logout',
        label: <FormattedMessage id="logout" />,
        icon: <AiOutlineLogout />,
        onClick: handleLogout,
      },
    ];
  }, [dispatch, hasMasqueradeRole, isProductOwner]);

  return (
    <Dropdown menuItems={menuItems}>
      <IconContainer>
        <MoreOutlined />
      </IconContainer>
    </Dropdown>
  );
};

export default Settings;
