import { Empty } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import styles from './NotificationList.less';
import NotificationItem from './components/NotificationItem';

import { notificationsSel } from 'NOTIFICATIONS/duck/selectors';

const NotificationList = () => {
  const notifications = useSelector(notificationsSel);

  return (
    <div className={styles.listContainer}>
      {notifications.length === 0 && (
        <Empty className={styles.emptyContainer} description="No new notifications" />
      )}
      {notifications.map(n => (
        <NotificationItem key={n.id} notification={n} />
      ))}
    </div>
  );
};
export default NotificationList;
