import { invert } from 'lodash';
import { Descendant } from 'slate';

export enum FontSize {
  large = 'large',
  xLarge = 'x-large',
}

export enum LeafType {
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  fontSize = 'fontSize',
  color = 'color',
  backgroundColor = 'backgroundColor',
}

export const leafTypesByTag = {
  strong: LeafType.bold,
  b: LeafType.bold,
  em: LeafType.italic,
  u: LeafType.underline,
};

export const leafStyles = [LeafType.fontSize, LeafType.color, LeafType.backgroundColor] as const;

export enum ElementType {
  paragraph = 'paragraph',
  link = 'link',
  variable = 'variable',
  orderedList = 'orderedList',
  unorderedList = 'unorderedList',
  listItem = 'listItem',
  indentation = 'indentation',
}

export const elementTypesByTag = {
  ol: ElementType.orderedList,
  ul: ElementType.unorderedList,
  li: ElementType.listItem,
  p: ElementType.paragraph,
  blockquote: ElementType.indentation,
};

export const elementTagsByType = invert(elementTypesByTag);

export const initialValue: Descendant[] = [
  {
    type: ElementType.paragraph,
    children: [{ text: '' }],
  },
];
