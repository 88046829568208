import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootAction } from 'typesafe-actions';

import { DataTypes } from './dataTypes';
import { fetchData, FetchDataRequestParams, refreshData } from './duck/actions';
import { createDataSel, createIsLoadingSel } from './duck/selectors';

export const useGetData = <T extends keyof DataTypes>(
  params: FetchDataRequestParams<T>,
): [DataTypes[T], boolean, RootAction] => {
  const { dataType, dataId } = params;

  const dispatch = useDispatch();
  const data = useSelector(createDataSel(dataType));
  const isLoading = useSelector(createIsLoadingSel(dataType));

  useEffect(() => {
    dispatch(fetchData.request(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return [data, isLoading, refreshData({ dataType, dataId })];
};
