import { moduleName as parentModuleName } from '../../constants';

export const moduleName = 'sessions';
export const modulePath = [parentModuleName, moduleName];

export const SESSIONS_SUMMARY = 'sessionsSummary';

export const UPDATE_SESSION_ACCEPTED_FIELDS = [
  'accountCode',
  'additionalPersonFee',
  'addonsSummary',
  'allowEarlyBirdRegistration',
  'attendeeCapacity',
  'baseFeePersonCount',
  'baseRosterFee',
  'closeDate',
  'deposit',
  'description',
  'earlyBirdDayCount',
  'earlyBirdDeposit',
  'earlyBirdSessionFee',
  'earlyStartDays',
  'earlyStartPricePerDay',
  'isFeePerPerson',
  'leaveLateDays',
  'leaveLatePricePerDay',
  'maximumPeopleCount',
  'maximumRosterCount',
  'minimumPeopleCount',
  'programCode',
  'sessionCode',
  'sessionName',
  'startDate',
];
