import { combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import toastService from '@/modules/toasts/service';

import { acceptDisclaimers } from './actions';
import services from './services';

const disclaimersAcceptRequest$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(acceptDisclaimers.request)),
    switchMap(({ payload }) => {
      const {
        codes,
        firstName,
        lastName,
        relationship,
        formCode,
        personGUID,
        formRecordGUID,
        ...rest
      } = payload;

      const disclaimers = codes.map(disclaimerCode => ({
        isAccepted: true,
        disclaimerCode,
        firstName,
        lastName,
        relationship,
        ...rest,
      }));

      return services
        .disclaimerAcceptRequest$({
          disclaimers,
          formCode,
          personGUID,
          formRecordGUID,
        })
        .pipe(
          map(() => acceptDisclaimers.success()), // TODO check if we should navigate to events
          catchError((error: Error) => {
            toastService.error("Couldn't update the disclaimers, please try again");
            return of(acceptDisclaimers.failure(error));
          }),
        );
    }),
  );

export default combineEpics(disclaimersAcceptRequest$);
