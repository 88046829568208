import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createDataSel } from '@/modules/data/duck/selectors';

import { modulePath } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulePath);

export const isRegistrantCurrentUserSel = createSelector(
  [createDataSel('attendeeDetails')],
  attendeeDetails => attendeeDetails.isRegistrantCurrentUser,
);

export const personExistsSel = createSelector(moduleSel, ({ personExists }) => personExists);

export const personGuidSel = createSelector(moduleSel, ({ personGuid }) => personGuid);
