import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const UserInfo = styled.p`
  font-style: italic;
`;

export const NotFound = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 32px;
  }

  span {
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  svg {
    font-size: 20px;
    color: ${colors.info};
    flex-shrink: 0;
  }
`;
