import { combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction } from 'typesafe-actions';

import { refreshData } from '@/modules/data/duck/actions';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { updateEvent } from './actions';
import services from './services';

const updateEvent$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(updateEvent.request)),
    switchMap(({ payload }) => {
      const {
        updatedFormData,
        isUpdatingSpecificFormField,
        customSuccessMessage = 'Event Updated',
      } = payload;
      return services.updateEvent$(updatedFormData).pipe(
        switchMap(() => {
          toastService.success(customSuccessMessage);
          return of(
            updateEvent.success(),
            refreshData({ dataType: isUpdatingSpecificFormField ? 'form' : 'personFormList' }),
          );
        }),
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(updateEvent.failure(error));
        }),
      );
    }),
  );

export default combineEpics(updateEvent$);
