import { Input, Form } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { refreshData } from '@/modules/data/duck/actions';
import { updateRecordComment } from '@/modules/entities/Logs/duck/actions';
import { addCommentInProgressSel } from '@/modules/entities/Logs/duck/selectors';
import Button from '@/modules/shared/components/Button';

import { Container, Title, ButtonWrapper } from './CommentsBlock.styled';

const FormItem = Form.Item;

const COMMENT_FIELD = 'comment';
const MAX_COMMENT_LENGTH = 400;

type BaseProps = {
  isLoading: boolean;
  formCode: string;
};

type GroupProps = BaseProps & {
  isGroup: true;
  groupReservation: GroupReservation;
};

type IndividualProps = BaseProps & {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};

type Props = GroupProps | IndividualProps;

const CommentsBlock: React.FC<Props> = ({ isLoading, formCode, ...props }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addCommentInProgress = useSelector(addCommentInProgressSel);

  const { resetFields, validateFields } = form;

  const handleRefreshComments = () => {
    resetFields([COMMENT_FIELD]);
    dispatch(refreshData({ dataType: 'comments' }));
  };

  const handleSubmit: React.MouseEventHandler<HTMLElement> = e => {
    e.preventDefault();
    validateFields().then(({ comment }) => {
      dispatch(
        updateRecordComment.request({
          formCode,
          comment,
          ...(props.isGroup
            ? { groupReservationGUID: props.groupReservation.groupReservationGUID }
            : { formRecordGUID: props.attendeeDetails.formRecordGUID }),
          onSuccess: handleRefreshComments,
        }),
      );
    });
  };

  return (
    <Container>
      <Title>Comments</Title>
      <Form form={form}>
        <FormItem
          name={COMMENT_FIELD}
          colon={false}
          hasFeedback={true}
          initialValue=""
          rules={[
            { required: true },
            {
              max: MAX_COMMENT_LENGTH,
              message: `Cannot be longer than ${MAX_COMMENT_LENGTH} char`,
            },
          ]}
        >
          <Input.TextArea
            placeholder="Add new comment"
            rows={5}
            showCount
            maxLength={MAX_COMMENT_LENGTH}
          />
        </FormItem>
      </Form>
      <ButtonWrapper>
        <Button
          type="primary"
          size="large"
          shadow
          loading={isLoading || addCommentInProgress}
          onClick={handleSubmit}
        >
          Add Comment
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default CommentsBlock;
