import { combineEpics, Epic } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';

import { updatePerson } from './actions';
import services from './services';

const updatePerson$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(updatePerson.request)),
    switchMap(({ payload }) =>
      services
        .updatePerson$(payload)
        .pipe(map(({ responseValue }) => updatePerson.success(responseValue as string))),
    ),
    catchError((error: ApiError, caught) => merge(of(updatePerson.failure(error)), caught)),
  );

export default combineEpics(updatePerson$);
