import { Row, Col, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Descendant } from 'slate';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import RichTextEditor from '@/modules/richTextEditor';
import { getDateWithTimeFormat } from '@/modules/utils/dateFormats';

import { updateBroadcast } from '../../duck/actions';
import { updateBroadcastInProgressSel } from '../../duck/selectors';

import { DateTimePicker, FormItem } from './CreateBroadcastModal.styled';

const CreateBroadcastModal: React.FC = () => {
  const dispatch = useDispatch();
  const inProgress = useSelector(updateBroadcastInProgressSel);
  const [form] = Form.useForm();

  const dateFormat = getDateWithTimeFormat();

  function handleSubmit() {
    form
      .validateFields()
      .then((values: { startDate: Dayjs; endDate: Dayjs; content: Descendant[] }) => {
        dispatch(updateBroadcast.request(values));
      });
  }

  const disableStartDate = (date: Dayjs | null) => {
    if (!date) return false;
    if (dayjs().subtract(1, 'day').isSameOrAfter(date)) return true;

    const endDate: Dayjs | undefined = form.getFieldValue('endDate');
    if (endDate && endDate.isBefore(date)) return true;

    return false;
  };

  const disableEndDate = (date: Dayjs | null) => {
    if (!date) return false;

    const startDate: Dayjs | undefined = form.getFieldValue('startDate');
    if (startDate && startDate.isAfter(date)) return true;

    if (dayjs().subtract(1, 'day').isSameOrAfter(date)) return true;
    return false;
  };

  return (
    <ModalComponent
      title="New Broadcast"
      buttons={[{ title: 'Submit Broadcast', onClick: handleSubmit, type: 'primary' }]}
      inProgress={inProgress}
    >
      <Form form={form}>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <FormItem
              name="startDate"
              rules={[{ required: true, message: 'Start Date is required' }]}
            >
              <DateTimePicker
                disabledDate={disableStartDate}
                format={dateFormat}
                placeholder="Broadcast Start Date"
                showTime
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="endDate" rules={[{ required: true, message: 'End Date is required' }]}>
              <DateTimePicker
                disabledDate={disableEndDate}
                format={dateFormat}
                placeholder="Broadcast End Date"
                showTime
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <FormItem name="content" rules={[{ required: true, message: 'Message is required' }]}>
            <RichTextEditor />
          </FormItem>
        </Row>
      </Form>
    </ModalComponent>
  );
};

export default CreateBroadcastModal;
