import { Col, Form, Select } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { refreshData } from '@/modules/data/duck/actions';
import { updateFormRecord } from '@/modules/entities/FormRecord/duck/actions';
import { updateFormRecordInProgressSel } from '@/modules/entities/FormRecord/duck/selectors';
import {
  NO_HOST_COUNCIL_KEY,
  NO_HOST_COUNCIL_LABEL,
  NO_UNIT_SELECTED_KEY,
  NO_UNIT_SELECTED_LABEL,
} from '@/modules/entities/Registrations/constants';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import EditableFormSection from '@/modules/shared/components/EditableFormSection';
import ItemMapper from '@/modules/shared/components/ItemMapper';
import { getPositionName } from '@/modules/shared/components/PersonData/utils';
import { useGetFilteredPositions } from '@/modules/shared/components/PersonalInfoForm/components/UnitAndCouncil/hooks';
import { formStatus } from '@/modules/shared/constants';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { usePrevious } from '@/modules/utils/hooks';

import { pageDataSel } from '@/pages/reservation/duck/selectors';
import { tabDataSel } from '@/pages/reservation/tabs/AttendeeDetails/duck/selector';

import { useHandleEdit } from '../../hooks';

import { moduleName } from './constants';

type Props = {
  canEditAttendeeDetails: boolean;
  editingSectionName: string | null;
  onEdit: (sectionName: string | null) => void;
};

const RegistrationInfo: React.FC<Props> = ({
  canEditAttendeeDetails,
  editingSectionName,
  onEdit,
}) => {
  const [formInstance] = Form.useForm();
  const dispatch = useDispatch();
  const { handleStartEdit, handleStopEdit, isEditing } = useHandleEdit(
    moduleName,
    editingSectionName,
    onEdit,
  );
  const {
    data: { form, attendeeDetails, attendeeTypeList },
  } = useSelector(pageDataSel);
  const {
    data: { arnicaPerson, councilList, formDisclaimers },
  } = useSelector(tabDataSel);
  const updateFormRecordInProgress = useSelector(updateFormRecordInProgressSel);

  const canUpdateHostCouncil = useSelector(
    // updating host council should not be available to attendee itself, so intentionally not providing attendeeDetails
    createAbilitiesSelector('attendeeDetails', PermissionAction.Update, null),
  );
  const canUpdateAttendeeType = useSelector(
    createAbilitiesSelector('attendeeDetails', 'updateAttendeeType', null),
  );

  const positions = useGetFilteredPositions(arnicaPerson);

  const stopEditHandler = useCallback(() => {
    formInstance.resetFields();
    handleStopEdit();
  }, [formInstance, handleStopEdit]);

  const {
    isAllDisclaimersAccepted,
    formRecordGUID,
    sessionName,
    reservationCode,
    status,
    typeName,
    hostCouncil,
    unit,
  } = attendeeDetails;

  const prevUpdateFormRecordInProgress = usePrevious(updateFormRecordInProgress);

  useEffect(() => {
    if (isEditing && prevUpdateFormRecordInProgress && !updateFormRecordInProgress) {
      handleStopEdit();
    }
  }, [isEditing, prevUpdateFormRecordInProgress, updateFormRecordInProgress, handleStopEdit]);

  const councilName = useMemo(() => {
    const noHostCouncilText = NO_HOST_COUNCIL_LABEL;
    if (!hostCouncil || hostCouncil === NO_HOST_COUNCIL_KEY) return noHostCouncilText;
    return councilList.find(c => c.councilNumber === hostCouncil)?.councilLong || noHostCouncilText;
  }, [hostCouncil, councilList]);

  const unitName = useMemo(() => {
    if (unit) {
      if (unit === NO_UNIT_SELECTED_KEY) return NO_UNIT_SELECTED_LABEL;

      // previously unit was saved as unitNumber
      // currently it's changed
      // correct unit should be organizationGUID
      // for now temporarily checking both
      const foundPosition = positions.find(({ unitNumber, organizationGUID }) =>
        [unitNumber, organizationGUID].includes(unit),
      );
      return (foundPosition && getPositionName(foundPosition)) || unit;
    }
  }, [positions, unit]);

  const handleSubmit = () => {
    formInstance.validateFields().then(values => {
      const { formCode } = form;

      dispatch(
        updateFormRecord.request({
          ...values,
          formCode,
          formRecordGUID,
          finalActions: [
            refreshData({ dataType: 'attendeeDetails' }),
            refreshData({ dataType: 'paymentSummary' }),
          ],
        }),
      );
    });
  };

  return (
    <EditableFormSection
      onEditClick={handleStartEdit}
      onCancel={stopEditHandler}
      onSave={handleSubmit}
      isEditing={isEditing}
      title="Registration Information"
      canEdit={canUpdateAttendeeType || canUpdateHostCouncil || canEditAttendeeDetails}
      isLoading={updateFormRecordInProgress}
    >
      {isEditing ? (
        <Form layout="vertical" form={formInstance}>
          <Col span={10}>
            {canUpdateAttendeeType && (
              <Form.Item
                name="typeCode"
                initialValue={attendeeDetails.typeCode}
                label="Attendee Type"
              >
                <Select
                  size="large"
                  options={attendeeTypeList.map(at => ({
                    value: at.typeCode,
                    label: at.typeName,
                  }))}
                />
              </Form.Item>
            )}
            {canUpdateHostCouncil && (
              <Form.Item
                name="hostCouncil"
                initialValue={hostCouncil || NO_HOST_COUNCIL_KEY}
                label="Host Council"
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  size="large"
                  options={[
                    { value: NO_HOST_COUNCIL_KEY, label: NO_HOST_COUNCIL_LABEL },
                    ...councilList.map(c => ({
                      value: c.councilNumber,
                      label: c.councilLong,
                    })),
                  ]}
                />
              </Form.Item>
            )}
            {canEditAttendeeDetails && (
              <Form.Item
                initialValue={unit || NO_UNIT_SELECTED_KEY}
                label="Unit Selected"
                name="unit"
              >
                <Select
                  optionFilterProp="label"
                  showSearch
                  size="large"
                  options={[
                    { value: NO_UNIT_SELECTED_KEY, label: NO_UNIT_SELECTED_LABEL },
                    ...positions.map(p => ({
                      value: p.organizationGUID,
                      label: getPositionName(p),
                    })),
                  ]}
                />
              </Form.Item>
            )}
          </Col>
        </Form>
      ) : (
        <ItemMapper
          items={[
            { label: 'Personal Reservation Code', value: reservationCode },
            { label: 'Registration Status', value: formStatus[status]?.name },
            { label: 'Host Council', value: councilName },
            { label: 'Unit Selected', value: unitName },
            { label: 'Session', value: sessionName },
            { label: 'Attendee Type', value: typeName },
            ...(formDisclaimers.length
              ? [
                  {
                    label: 'Disclaimer Accepted',
                    value: isAllDisclaimersAccepted ? 'Accepted' : 'Pending',
                  },
                ]
              : []),
          ]}
        />
      )}
    </EditableFormSection>
  );
};

export default RegistrationInfo;
