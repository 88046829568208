import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { MULTI_SESSION_KEY } from '../components/AddSession/constants';
import { modulePath } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulePath);

export const currentSessionSel = createSelector(moduleSel, ({ currentSession }) => currentSession);

export const deleteSessionInProgressSel = createSelector(
  moduleSel,
  ({ deleteSessionInProgress }) => deleteSessionInProgress,
);

export const cloneSessionInProgressSel = createSelector(
  moduleSel,
  ({ cloneSessionInProgress }) => cloneSessionInProgress,
);

export const updateSessionInProgressSel = createSelector(
  moduleSel,
  ({ updateSessionInProgress }) => updateSessionInProgress,
);

export const previousRuleCodeSel = createSelector([currentSessionSel], session =>
  get(session, [MULTI_SESSION_KEY]),
);
