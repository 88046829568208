import React, { Fragment } from 'react';

import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';

import { AmountPaid as AmountPaidComponent, NextPayment } from './AmountPaid.styled';

import { CURRENCY_FORMAT } from 'UTILS/numberFormats';

type Props = {
  totalAmountPaid: number;
  nextPaymentAmountDue: number;
};

const AmountPaid: React.FC<Props> = ({ totalAmountPaid, nextPaymentAmountDue }) => {
  const screenSize = useGetScreenSize();
  const isMobile = screenSize === 'mobile';

  return (
    <Fragment>
      <AmountPaidComponent isMobile={isMobile}>
        <span>Amount Paid: </span>
        <span>{CURRENCY_FORMAT(totalAmountPaid)}</span>
      </AmountPaidComponent>
      {nextPaymentAmountDue !== 0 && (
        <NextPayment isMobile={isMobile}>
          <span>Next Payment Due: </span>
          <span>{CURRENCY_FORMAT(nextPaymentAmountDue)}</span>
        </NextPayment>
      )}
    </Fragment>
  );
};

export default AmountPaid;
