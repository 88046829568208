import { EventItem } from '@/modules/data/dataTypes/eventList';
import { FormSettings } from '@/modules/data/dataTypes/form';

import apiService from 'UTILS/apiService';

const updateEvent$ = ({ isActive = true, ...rest }: FormSettings | EventItem) =>
  apiService.post$<{ formCode: string }>(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateForm',
    {
      ...rest,
      isActive,
    },
  );

export default {
  updateEvent$,
};
