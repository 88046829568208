import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootAction, RootState } from 'typesafe-actions';

import { ModalComponent } from './Modal.styled';
import { closeModal } from './duck/actions';
import { modalNameSel, modalParamsSel, modalPropsSel } from './duck/selectors';
import modals from './modals';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Modal: React.FC<Props> = ({ modalName, modalParams, modalProps, onCloseModal }) => {
  const { Component, props = {} } = modals[modalName] || {};
  return (
    <ModalComponent
      {...props}
      {...modalProps}
      destroyOnClose
      open={!!Component}
      onCancel={onCloseModal}
      bodyStyle={{
        padding: '0',
      }}
      footer={null}
    >
      {!!Component && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <Component modalParams={modalParams} />
      )}
    </ModalComponent>
  );
};

Modal.defaultProps = {
  modalProps: {},
};

const mapStateToProps = (
  state: RootState,
): {
  modalName: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  modalParams?: object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  modalProps?: object;
} => ({
  modalName: modalNameSel(state),
  modalParams: modalParamsSel(state),
  modalProps: modalPropsSel(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onCloseModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
