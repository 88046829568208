import cn from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import { markAsReadNotification } from '@/modules/entities/Notifications/duck/actions';

import { Notification } from '../../../../types';
import GenericNotification from '../../../GenericNotification';
import ReportReadyNotification from '../../../ReportReadyNotification';

import styles from './NotificationItem.less';

type Props = {
  notification: Notification;
};

const NotificationItem: React.FC<Props> = ({ notification }) => {
  const { read, type, id } = notification;
  const dispatch = useDispatch();

  const handleMarkAsRead = () => {
    if (!read) {
      dispatch(markAsReadNotification(id));
    }
  };

  return (
    <div
      onClick={handleMarkAsRead}
      className={cn(styles.item, {
        [styles.read]: read,
      })}
    >
      {type === 'reportDownloadReady' ? (
        <ReportReadyNotification {...notification} />
      ) : (
        <GenericNotification {...notification} />
      )}
    </div>
  );
};

export default NotificationItem;
