import { chunk, cloneDeep, differenceBy, differenceWith, isEqual, set } from 'lodash';
import { combineEpics, Epic } from 'redux-observable';
import { of, zip, merge, EMPTY, concat, defer, race } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData, replaceData, scheduleRefresh } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { CONFIRM_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal, updateModalParams } from '@/modules/modals/duck/actions';
import { cancelAction, confirmAction } from '@/modules/shared/components/ConfirmModal/duck/actions';
import { ModalParams as ConfirmModalParams } from '@/modules/shared/components/ConfirmModal/types';
import { PresentationType } from '@/modules/shared/constants';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { pageDataSel } from '@/pages/createEvent/duck/selectors';

import {
  updateFormPart,
  updateDatablockItem,
  connectDatablockItem,
  reorderFormParts,
  updateFormItem,
  updateFormItemOptions,
  deleteFormItemOptions,
  updateVisibilitySettings,
  addDataBlock,
  removeDataBlock,
  removeDatablockItem,
  reorderFormItems,
} from './actions';
import { editingFormItemSel, editingSectionCodesSel, stepDataSel } from './selectors';
import services from './services';

const createDataBlockItem$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateDatablockItem.request)),
    switchMap(({ payload }) => {
      const { dataBlockItem, formPartCode } = payload;
      const { datablockCode } = dataBlockItem;
      const { formCode } = createDataSel('form')(state$.value);
      return services.updateOrCreateDataBlockItem$({ ...dataBlockItem, formCode }).pipe(
        switchMap(({ responseValue }) =>
          concat(
            merge(
              race(
                action$.pipe(filter(isActionOf(connectDatablockItem.success)), take(1)),
                action$.pipe(
                  filter(isActionOf(connectDatablockItem.failure)),
                  take(1),
                  switchMap(({ payload: { message } }) => {
                    throw new Error(message);
                  }),
                ),
              ),
              of(
                connectDatablockItem.request({
                  formPartCode,
                  datablockCode,
                  datablockItemCode: responseValue as string,
                }),
              ),
            ),
            of(updateDatablockItem.success(), refreshData({ dataType: 'formPartsData' })),
          ),
        ),
        catchError((err: ApiError) => {
          toastService.error(err.message);
          return of(updateDatablockItem.failure(err));
        }),
      );
    }),
  );

const removeDataBlockItem$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeDatablockItem)),
    switchMap(({ payload }) => {
      const { datablockCode, datablockItemCode, formPartCode } = payload;
      const confirmModalParams: ConfirmModalParams = {
        confirmButton: {
          title: 'Yes, Delete',
        },
        description: 'Are you sure you want to delete this question?',
        title: 'Delete Custom Question?',
      };

      return merge(
        race(
          action$.pipe(
            filter(isActionOf(confirmAction)),
            take(1),
            switchMap(() => {
              const nextConfirmModalParams: ConfirmModalParams = {
                ...confirmModalParams,
                inProgress: true,
              };
              return concat(
                of(updateModalParams(nextConfirmModalParams)),
                prefetchData$(action$, {
                  dataType: 'dataBlockItem',
                  queryObj: { datablockCode, datablockItemCode },
                }),
                defer(() => {
                  const dataBlockItem = createDataSel('dataBlockItem')(state$.value);

                  return concat(
                    services.updateDataBlockItem$({ ...dataBlockItem, isDeleted: true }).pipe(
                      switchMap(() => {
                        toastService.success('Question deleted correctly');
                        return concat(
                          of(
                            connectDatablockItem.request({
                              action: 'disconnect',
                              formPartCode,
                              datablockCode,
                              datablockItemCode,
                            }),
                          ),
                          of(closeModal()),
                        );
                      }),
                    ),
                  );
                }),
              );
            }),
          ),
          action$.pipe(
            filter(isActionOf(cancelAction)),
            take(1),
            map(() => closeModal()),
          ),
        ),
        of(openModal(CONFIRM_MODAL, confirmModalParams)),
      );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(closeModal()), caught);
    }),
  );

const connectDataBlockItem$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(connectDatablockItem.request)),
    switchMap(({ payload }) => {
      const { formCode } = createDataSel('form')(state$.value);
      return services.connectDataBlockItem$({ ...payload, formCode }).pipe(
        switchMap(() => {
          if (payload.action !== 'disconnect') {
            toastService.success('Field was created');
          }

          return of(
            refreshData({ dataType: 'formPartsData' }),
            connectDatablockItem.success(),
            closeModal(),
          );
        }),
        catchError((err: ApiError) => {
          toastService.error(err.message);
          return of(connectDatablockItem.failure(err));
        }),
      );
    }),
  );

const reorderFormParts$: Epic<RootAction, RootAction> = (actions$, state$) =>
  actions$.pipe(
    filter(isActionOf(reorderFormParts.request)),
    switchMap(({ payload: { currentFormPartCode, otherFormPartCode } }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      const formPartsData = createDataSel('formPartsData')(state);
      const { formParts } = formPartsData;

      const currentFormPartIndex = formParts.findIndex(
        fp => fp.formPartCode === currentFormPartCode,
      );
      const otherFormPartIndex = formParts.findIndex(fp => fp.formPartCode === otherFormPartCode);

      const nextFormParts = cloneDeep(formParts);
      set(
        nextFormParts,
        [currentFormPartIndex, 'positionNumber'],
        formParts[otherFormPartIndex].positionNumber,
      );
      set(
        nextFormParts,
        [otherFormPartIndex, 'positionNumber'],
        formParts[currentFormPartIndex].positionNumber,
      );

      return zip(
        ...[nextFormParts[currentFormPartIndex], nextFormParts[otherFormPartIndex]].map(fp =>
          services.updateOrCreateFormPart$({ ...fp, formCode }),
        ),
      ).pipe(
        switchMap(() => {
          const nextFormPartsData = {
            ...formPartsData,
            formParts: nextFormParts,
          };
          return of(
            reorderFormParts.success(),
            replaceData({ dataType: 'formPartsData', data: nextFormPartsData }),
          );
        }),
        catchError((e: ApiError) => {
          toastService.error(e.message);
          return of(reorderFormParts.failure(e));
        }),
      );
    }),
  );

const reorderFormItems$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(reorderFormItems)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { positionNumbers, formPartCode } = payload;
      const formPartsData = createDataSel('formPartsData')(state);
      const { formCode } = createDataSel('form')(state);

      const formPartIndex = formPartsData.formParts.findIndex(
        fp => fp.formPartCode === formPartCode,
      );
      const formPart = formPartsData.formParts[formPartIndex];
      const nextFormItems = formPart.formItems.map(fi => ({
        ...fi,
        positionNumber:
          fi.formItemCode in positionNumbers ? positionNumbers[fi.formItemCode] : fi.positionNumber,
      }));
      const formItemsToSave = nextFormItems.filter(fi => fi.formItemCode in positionNumbers);

      const nextFormPartsData = cloneDeep(formPartsData);
      nextFormPartsData.formParts[formPartIndex].formItems = nextFormItems;

      return merge(
        of(replaceData({ dataType: 'formPartsData', data: nextFormPartsData })),
        ...formItemsToSave.map(fi =>
          services.updateFormItem$({ ...fi, formCode, formPartCode }).pipe(switchMap(() => EMPTY)),
        ),
      );
    }),
    catchError((err: ApiError, caught) => {
      toastService.error(err.message);
      return caught;
    }),
  );

const updateFormItemOptions$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateFormItemOptions.request)),
    switchMap(({ payload }) => {
      const { formItem, options } = payload;
      const { formItemCode, optionSetCode } = formItem;

      if (!options || !options.length) {
        return of(updateFormItemOptions.success(undefined));
      }

      const {
        data: { form },
      } = pageDataSel(state$.value);
      const {
        data: { optionSets },
      } = stepDataSel(state$.value);
      const { formCode } = form;
      const optionSet = optionSetCode && optionSets[optionSetCode];

      // if the option set was previously created
      if (optionSetCode && optionSetCode.includes(formItemCode)) {
        if (!optionSet) {
          return of(updateFormItemOptions.success(undefined));
        }
        const optionsToEdit = differenceWith(options, optionSet.optionItems, isEqual);

        if (!optionsToEdit.length) {
          return of(updateFormItemOptions.success(undefined));
        }

        return merge(
          ...chunk(optionsToEdit, 100).map(optionSetItems =>
            services.updateOptionSetData$({
              formCode,
              optionSetCode,
              optionSetItems,
            }),
          ),
        ).pipe(map(() => updateFormItemOptions.success(undefined)));
      }
      // if option set not previously created, create it
      const nextOptionSetCode = `${formItemCode}_set`;

      return services
        .createOptionSet$({
          formCode,
          optionSetCode: nextOptionSetCode,
          optionSetName: `${formItemCode} clone of ${optionSetCode}`,
          // description: `Clone version of ${optionSetCode} for ${formItemCode} from item`,
        })
        .pipe(
          switchMap(() =>
            services.updateOptionSetData$({
              formCode,
              optionSetCode: nextOptionSetCode,
              optionSetItems: options,
            }),
          ),
          map(() => updateFormItemOptions.success(nextOptionSetCode)),
        );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(updateFormItemOptions.failure(error)), caught);
    }),
  );

const deleteFormItemOptions$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteFormItemOptions.request)),
    switchMap(({ payload }) => {
      const { formItem, options } = payload;
      const { formItemCode, optionSetCode } = formItem;

      if (!options || !(optionSetCode && optionSetCode.includes(formItemCode))) {
        return of(deleteFormItemOptions.success());
      }
      const {
        data: { form },
      } = pageDataSel(state$.value);
      const {
        data: { optionSets },
      } = stepDataSel(state$.value);

      const { formCode } = form;
      const optionSet = optionSets[optionSetCode];

      if (!optionSet) return of(deleteFormItemOptions.success());

      const optionsToDelete = differenceBy(optionSet.optionItems, options, 'itemName').map(
        ({ itemValue }) => itemValue,
      );

      if (!optionsToDelete.length) return of(deleteFormItemOptions.success());

      return services
        .deleteOptionSetItemList$({
          formCode,
          optionSetCode,
          optionSetItems: optionsToDelete,
        })
        .pipe(
          map(() => deleteFormItemOptions.success()),
          catchError((error: ApiError) => {
            toastService.error(error.message);
            return of(deleteFormItemOptions.failure(error));
          }),
        );
    }),
  );

const updateDataLength$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateFormItem.request)),
    switchMap(({ payload }) => {
      const { options, formItem, formPart } = payload;
      const { datablockCode } = formPart;
      const { presentationType, datablockItemCode } = formItem;

      if (presentationType !== PresentationType.CHECKBOX || !options || !options.length) {
        return EMPTY;
      }

      return concat(
        prefetchData$(action$, {
          dataType: 'dataBlockItem',
          queryObj: { datablockCode, datablockItemCode },
        }),
        defer(() => {
          const dataBlockItem = createDataSel('dataBlockItem')(state$.value);
          return services
            .updateDataBlockItem$({ ...dataBlockItem, dataLength: options.length * 50 })
            .pipe(
              switchMap(() =>
                of(
                  scheduleRefresh({ dataType: 'dataBlockItemList' }),
                  scheduleRefresh({ dataType: 'dataBlockItem' }),
                ),
              ),
            );
        }),
      );
    }),
  );

const updateFormItem$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateFormItem.request)),
    switchMap(({ payload }) =>
      merge(
        zip(
          action$.pipe(filter(isActionOf(updateFormItemOptions.success)), take(1)),
          action$.pipe(filter(isActionOf(deleteFormItemOptions.success)), take(1)),
        ).pipe(
          switchMap(([{ payload: nextOptionSetCode }]) => {
            const { formPart, formItem } = payload;
            const { formPartCode } = formPart;
            const { formCode } = createDataSel('form')(state$.value);
            const nextFormItem = {
              ...formItem,
              optionSetCode: nextOptionSetCode || formItem.optionSetCode,
            };
            const originalFormItem = editingFormItemSel(state$.value);

            if (isEqual(originalFormItem, nextFormItem)) {
              toastService.info('Field was updated');
              return of(updateFormItem.success(), refreshData({ dataType: 'formPartsData' }));
            }

            return services
              .updateFormItem$({
                ...nextFormItem,
                formCode,
                formPartCode,
              })
              .pipe(
                switchMap(() => {
                  toastService.info('Field was updated');
                  return of(
                    updateFormItem.success(),
                    refreshData({ dataType: 'formPartsData' }),
                    refreshData({ dataType: 'optionSets' }),
                  );
                }),
                catchError((error: ApiError) => {
                  toastService.error(error.message);
                  return of(updateFormItem.failure(error));
                }),
              );
          }),
        ),
        of(updateFormItemOptions.request(payload)),
        of(deleteFormItemOptions.request(payload)),
      ),
    ),
  );

const updateFormPart$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateFormPart.request)),
    switchMap(({ payload }) => {
      const { formCode } = createDataSel('form')(state$.value);
      return services.updateOrCreateFormPart$({ ...payload, formCode }).pipe(
        map(() => refreshData({ dataType: 'formPartsData' })),
        catchError(err => of(updateFormPart.failure(err.message))),
      );
    }),
  );

const updateVisibilitySettings$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateVisibilitySettings.request)),
    switchMap(({ payload }) => {
      const { successMessage, ...visibilitySettings } = payload;
      const { formCode } = createDataSel('form')(state$.value);
      const { formItemCode, formPartCode } = editingSectionCodesSel(state$.value);

      const service = formItemCode
        ? services.updateFormItemVisibility$
        : services.updateFormPartVisibility$;

      return service({
        formCode,
        formItemCode,
        formPartCode,
        visibilitySettings: window.btoa(JSON.stringify(visibilitySettings)),
      }).pipe(
        mergeMap(() => {
          const message = successMessage
            ? successMessage
            : `${formItemCode ? 'Field' : 'Section'} visibility was updated`;
          toastService.info(message);
          return of(
            updateVisibilitySettings.success(),
            refreshData({ dataType: 'formPartsData' }),
            refreshData({ dataType: 'visibilitySettings' }),
            closeModal(),
          );
        }),
        catchError((error: ApiError) => {
          toastService.error(error.message);

          return of(updateVisibilitySettings.failure(error));
        }),
      );
    }),
  );

// TODO: SPLIT CREATE AND UPDATE APIS TO PROCESS SEPARATEDLY
const addDataBlocks$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addDataBlock.request)),
    switchMap(({ payload }) => {
      const { formCode } = createDataSel('form')(state$.value);
      return zip(
        ...payload.map(db => {
          if (db.datablockCode === 'customblock') {
            const unix_timestamp = new Date().getTime();
            const newBlockCode = `${db.datablockCode}_${formCode}_${unix_timestamp}`.toLowerCase();
            return services
              .updateOrCreateDataBlock$({
                datablockCode: newBlockCode,
                datablockName: 'Custom Block',
                formCode,
                description: '',
                isActive: true,
                isDefault: false,
                isSystem: false,
              })
              .pipe(switchMap(() => of({ ...db, datablockCode: newBlockCode })));
          }
          return of(db);
        }),
      );
    }),
    switchMap(dataBlocks => {
      const { formCode } = createDataSel('form')(state$.value);
      return zip(
        ...dataBlocks.map(db =>
          services.updateOrCreateFormPart$({ ...db, formCode }).pipe(
            switchMap(({ responseValue }) =>
              services.connectDataBlock$({
                ...db,
                formPartCode: responseValue as string,
                formCode,
              }),
            ),
          ),
        ),
      );
    }),
    switchMap(() => {
      toastService.info('Form was updated');
      return of(refreshData({ dataType: 'formPartsData' }), addDataBlock.success(), closeModal());
    }),
    catchError((error: ApiError, caught) => {
      toastService.error('Unable to update form, try again later');
      return merge(of(addDataBlock.failure(error)), caught);
    }),
  );

const removeDataBlock$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeDataBlock.request)),
    switchMap(({ payload: formPartCode }) => {
      const { formCode } = createDataSel('form')(state$.value);
      return services.disconnectDataBlock$({ formCode, formPartCode }).pipe(
        switchMap(() => {
          toastService.info('Form was updated');
          return of(refreshData({ dataType: 'formPartsData' }), removeDataBlock.success());
        }),
        catchError((err: ApiError) => of(removeDataBlock.failure(err))),
      );
    }),
  );

export default combineEpics(
  createDataBlockItem$,
  connectDataBlockItem$,
  reorderFormParts$,
  reorderFormItems$,
  updateFormItem$,
  updateFormItemOptions$,
  deleteFormItemOptions$,
  updateFormPart$,
  updateVisibilitySettings$,
  addDataBlocks$,
  removeDataBlock$,
  updateDataLength$,
  removeDataBlockItem$,
);
