import { get, isEqual } from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import {
  createEditor,
  Transforms,
  Range,
  Node,
  Descendant,
  Editor,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';

import { Container, Editable } from './RichTextEditor.styled';
import Element from './components/Element';
import Leaf from './components/Leaf';
import Toolbar from './components/Toolbar';
import { ElementType, initialValue as richTextInitialValue } from './constants';
import { withLinks, withVariables } from './plugins';
import { TrimmedVariable } from './types';
import { insertVariable, setValue } from './utils';

type Props = {
  variables?: TrimmedVariable[];
  variableToInsert?: TrimmedVariable;
  value?: Descendant[];
  isSimpleText?: boolean;
  onChange?: (value: Descendant[]) => void;
};

const RichTextEditor: React.FC<Props> = ({
  variables = [],
  variableToInsert,
  value = richTextInitialValue,
  onChange,
  isSimpleText = false,
}) => {
  const [editor] = useState(withVariables(withLinks(withHistory(withReact(createEditor())))));

  useEffect(() => {
    if (variableToInsert && ReactEditor.isFocused(editor)) {
      insertVariable(editor, variableToInsert);
    }
  }, [variableToInsert, editor]);

  useEffect(() => {
    if (!isEqual(value, editor.children)) {
      setValue(editor, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (nextValue: Descendant[]) => {
    onChange && onChange(nextValue);
  };

  const handleKeyDown: React.KeyboardEventHandler = e => {
    // needed this hack for deleting selected variables
    // more details here: https://github.com/ianstormtaylor/slate/issues/3456
    if (['Backspace', 'Delete'].includes(e.key)) {
      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        const currentNode = Node.parent(editor, selection.anchor.path);
        const currentNodeType = get(currentNode, 'type');
        if (currentNodeType === ElementType.variable) {
          Transforms.removeNodes(editor, {
            match: n =>
              !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === ElementType.variable,
          });
        }
      }
    }
  };

  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

  return (
    <Container $isSimpleText={isSimpleText}>
      <Slate editor={editor} initialValue={value} onChange={handleChange}>
        {!isSimpleText && <Toolbar variables={variables} />}
        <Editable
          $isSimpleText={isSimpleText}
          onKeyDown={handleKeyDown}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
    </Container>
  );
};

export default RichTextEditor;
