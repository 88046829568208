import React, { PropsWithChildren } from 'react';

import { Row, RowTitle } from './ListItemContentRow.styled';

type Props = {
  title: string;
};

const ListItemContentRow: React.FC<PropsWithChildren<Props>> = ({ title, children }) => (
  <Row>
    <RowTitle>{title}:</RowTitle>
    {children}
  </Row>
);

export default ListItemContentRow;
