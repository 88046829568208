import dayjs from 'dayjs';
import { mapValues } from 'lodash';
import { parse } from 'papaparse';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import { SERVER_DATE_FORMAT } from '@/modules/utils/dateFormats';

import {
  ParsedSessionFromCSV,
  RawSessionFromCSV,
  SessionActivityFromCSV,
  SessionAddonFromCSV,
  SessionAttendeeTypeFromCSV,
} from '../../types';
import { encodeSessionDescription } from '../../utils';

import {
  sessionCsvColumnsConfig,
  INTERNAL_COLUMN_DELIMITERS,
  CSV_DATE_TIME_FORMAT,
} from './constants';
import { SessionCSVColumnKey } from './types';
import {
  createGetInternalColumnKeys,
  getColumnKeys,
  getUserFriendlyKey,
  isEmptyValue,
} from './utils';

type ProcessingStatus = 'success' | 'error' | '';

export const useSessionCsvParser = (): {
  processFile: (files: File | null) => void;
  filename: string;
  processingStatus: ProcessingStatus;
  errors: string[];
  parsedSessions: ParsedSessionFromCSV[];
  reset: () => void;
  progressPercent: number;
} => {
  const { allowGroupRegistration } = useSelector(createDataSel('form'));
  const eventAttendeeTypes = useSelector(createDataSel('attendeeTypeList')) || [];
  const eventActivities = useSelector(createDataSel('activities')) || [];
  const eventAddons = useSelector(createDataSel('addons')) || [];
  const eventLedgerAccounts = useSelector(createDataSel('ledgerAccounts')) || [];

  const [filename, setFilename] = useState('');
  const [processingStatus, setProcessingStatus] = useState<ProcessingStatus>('');
  const [errors, setErrors] = useState<string[]>([]);
  const [parsedSessions, setParsedSessions] = useState<ParsedSessionFromCSV[]>([]);
  const [progressPercent, setProgressPercent] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getInternalColumnKeys = useCallback(createGetInternalColumnKeys(allowGroupRegistration), [
    allowGroupRegistration,
  ]);

  const processFile = (csv: File | null) => {
    if (!csv) return null;

    setFilename(csv.name);

    const columnKeys = getColumnKeys(allowGroupRegistration);
    const expectedHeaders = columnKeys.map(columnKey =>
      getUserFriendlyKey(columnKey, getInternalColumnKeys),
    );
    const headerErrors: string[] = [];

    parse<RawSessionFromCSV>(csv, {
      download: true,
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (fileHeader, index) => {
        const expectedHeader = expectedHeaders[index];
        if (fileHeader.trim() !== expectedHeader.trim()) {
          headerErrors.push(
            `Expected column name on position ${index + 1} should be "${expectedHeader}"`,
          );
        }

        return columnKeys[index];
      },
      error: error => setErrors([...errors, error.message]),
      complete: results => {
        const currentErrors = [
          ...errors,
          ...headerErrors,
          ...results.errors.map(({ message, row }) => `Line: ${row}: ${message}`),
        ];

        if (!currentErrors.length) {
          const { currentParsedSessions, fieldsValidationErrors } = parseSessions(results.data);
          currentErrors.push(...fieldsValidationErrors);
          if (!fieldsValidationErrors.length) setParsedSessions(currentParsedSessions);
        }

        setErrors(currentErrors);
        setProcessingStatus(currentErrors.length ? 'error' : 'success');
      },
    });
  };

  const parseSessions = (sessions: RawSessionFromCSV[]) => {
    const fieldsValidationErrors: string[] = [];

    const currentParsedSessions = sessions.map((session, index) => {
      const line = index + 1;
      const addValidationError = (errorMessage: string): string => {
        fieldsValidationErrors.push(`Line ${line}: ${errorMessage}`);
        return errorMessage;
      };
      setProgressPercent(Math.round((line / sessions.length) * 100));

      return mapValues(session, (value, columnKey) => {
        const { name, required, requiredIf, isDate, expectedType, internalColumnsConfig } =
          sessionCsvColumnsConfig[columnKey as SessionCSVColumnKey];
        const autoDetectedType = typeof value;
        const isEmptyCurrentValue = isEmptyValue(value);
        const requiredMessage = requiredIf && requiredIf(session, name, allowGroupRegistration);

        if (!isEmptyCurrentValue && expectedType !== autoDetectedType) {
          return addValidationError(
            `Expected ${name} to be ${expectedType}, but detected as ${autoDetectedType}`,
          );
        }

        if ((required || requiredMessage) && isEmptyCurrentValue) {
          return addValidationError(requiredMessage || `Missing required ${name}`);
        }

        if (!isEmptyCurrentValue && isDate) {
          const momentDate = dayjs(value as string, CSV_DATE_TIME_FORMAT, true);
          if (momentDate.isValid()) {
            return momentDate.format(SERVER_DATE_FORMAT);
          }
          return addValidationError(`Can't parse as a date ${name}: ${value}`);
        }

        if (internalColumnsConfig) {
          if (isEmptyCurrentValue || typeof value !== 'string') return [];

          const valueCsvLike =
            // adding internal column keys as header
            getInternalColumnKeys(columnKey as SessionCSVColumnKey).join(
              INTERNAL_COLUMN_DELIMITERS.delimiter,
            ) +
            '\n' +
            // replacing delimiter with an \n, since papaparse only support \n and \r combinations as newline delimiters
            value.replace(new RegExp(`${INTERNAL_COLUMN_DELIMITERS.newline}`, 'g'), '\n');

          const parseResults = parse<
            SessionAttendeeTypeFromCSV | SessionActivityFromCSV | SessionAddonFromCSV
          >(valueCsvLike, {
            header: true,
            dynamicTyping: true,
            delimiter: INTERNAL_COLUMN_DELIMITERS.delimiter,
          });

          if (parseResults.errors.length) {
            fieldsValidationErrors.push(
              ...parseResults.errors.map(
                ({ message }) => `Line ${line}, Column: ${name}: ${message}`,
              ),
            );
            return '';
          }

          if (columnKey === 'attendeeTypes') {
            const attendeeTypeWithFullData = (
              parseResults.data as SessionAttendeeTypeFromCSV[]
            ).map(attendeeTypeFromCSV => {
              const existingAttendeeType = eventAttendeeTypes.find(
                ({ typeName }) => typeName.trim() === attendeeTypeFromCSV.typeName.trim(),
              );

              if (!existingAttendeeType) {
                addValidationError(
                  `Not found Attendee Type "${attendeeTypeFromCSV.typeName}" in ${name} column`,
                );
              }

              return { ...existingAttendeeType, ...attendeeTypeFromCSV };
            });

            if (allowGroupRegistration) {
              const leadAdvisorAttendeeTypeSelected = attendeeTypeWithFullData.some(
                ({ isRosterLead }) => isRosterLead,
              );
              if (!leadAdvisorAttendeeTypeSelected) {
                addValidationError(
                  'Please, enter at least 1 attendee type that can be lead advisor',
                );
              }
            }

            return attendeeTypeWithFullData;
          }

          if (columnKey === 'activities') {
            const activitiesWithFullData = (parseResults.data as SessionActivityFromCSV[]).map(
              activityFromCSV => {
                const existingActivity = eventActivities.find(
                  ({ activityName }) => activityName.trim() === activityFromCSV.activityName.trim(),
                );

                if (!existingActivity) {
                  addValidationError(`Not found activity "${activityFromCSV.activityName}"`);
                }

                return { ...existingActivity, ...activityFromCSV };
              },
            );

            return activitiesWithFullData;
          }

          if (columnKey === 'addons') {
            const addonsWithFullData = (parseResults.data as SessionAddonFromCSV[]).map(
              (addonFromCSV, positionNumber) => {
                const existingAddon = eventAddons.find(
                  ({ addonName }) => addonName.trim() === addonFromCSV.addonName.trim(),
                );

                if (!existingAddon) {
                  addValidationError(`Not found addon "${addonFromCSV.addonName}"`);
                }

                return { ...existingAddon, ...addonFromCSV, positionNumber };
              },
            );

            return addonsWithFullData;
          }
        }

        if (columnKey === 'description' && value && !isEmptyCurrentValue)
          return encodeSessionDescription(value.toString());

        if (columnKey === 'sessionCode') {
          return ((value as string) || session.sessionCode).toUpperCase();
        }

        if (columnKey === 'accountCode') {
          const foundAccount = eventLedgerAccounts.find(
            ({ accountName, accountCode }) =>
              accountCode === value || accountName.trim() === (value as string).trim(),
          );
          if (!foundAccount) {
            return addValidationError(
              `Not found any ledger account with code or name "${value}" on this event`,
            );
          }

          return foundAccount.accountCode;
        }

        return value;
      }) as unknown as ParsedSessionFromCSV;
    });

    return { currentParsedSessions, fieldsValidationErrors };
  };

  const reset = () => {
    setFilename('');
    setProcessingStatus('');
    setErrors([]);
    setParsedSessions([]);
    setProgressPercent(0);
  };

  return {
    processFile,
    filename,
    processingStatus,
    errors,
    parsedSessions,
    reset,
    progressPercent,
  };
};
