import { Tag } from 'antd';
import styled from 'styled-components';

export const StyledTag = styled(Tag)`
  border-radius: 1rem;
  border: 0px;
`;

export const TagContainer = styled.div`
  padding: 1rem 0;
`;
