import { createAction, createAsyncAction } from 'typesafe-actions';

import { MultiSessionCapacityRule } from '@/modules/data/dataTypes/multiSessionCapacityRules';

import { moduleNameSpace } from '../constants';

export const updateMultiSessionRule = createAsyncAction(
  `${moduleNameSpace}/UPDATE_MULTI_SESSION_CAPACITY_RULE_REQUEST`,
  `${moduleNameSpace}/UPDATE_MULTI_SESSION_CAPACITY_RULE_SUCCESS`,
  `${moduleNameSpace}/UPDATE_MULTI_SESSION_CAPACITY_RULE_FAILURE`,
)<Partial<MultiSessionCapacityRule>, undefined, Error>();

export const createEditMultiSessionRuleOpened = createAction(
  `${moduleNameSpace}/CREATE_EDIT_MULTI_SESSION_RULE_OPENED`,
  (rule: Partial<MultiSessionCapacityRule>) => rule,
)();

export const createEditMultiSessionRuleClosed = createAction(
  `${moduleNameSpace}/CREATE_EDIT_MULTI_SESSION_RULE_CLOSED`,
)();

export const deleteMultiSessionRule = createAsyncAction(
  `${moduleNameSpace}/DELETE_MULTI_SESSION_CAPACITY_RULE_REQUEST`,
  `${moduleNameSpace}/DELETE_MULTI_SESSION_CAPACITY_RULE_SUCCESS`,
  `${moduleNameSpace}/DELETE_MULTI_SESSION_CAPACITY_RULE_FAILURE`,
)<string, undefined, Error>();
