import apiService from '@/modules/utils/apiService';

import { GroupSessionFees } from '../types';

const updateSessionWaitingList$ = (payload: {
  formCode: string;
  sessionCode: string;
  initialRosterCount: number;
  initialPeopleCount: number;
  initialAdultCount?: number;
  initialYouthCount?: number;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateSessionWaitingList',
    {
      isAllowedToRegister: false,
      waitingListRecordGUID: '',
      comments: '',
      ...payload,
    },
  );

const getGroupTotalFee$ = ({
  formCode,
  sessionCode,
  numberOfPeople,
  numberOfCrews,
}: {
  formCode: string;
  sessionCode: string;
  numberOfPeople: number;
  numberOfCrews: number;
}) =>
  apiService.get$<GroupSessionFees>(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=GetSessionTotalFee&sessionCode=${sessionCode}&formCode=${formCode}&numberOfCrews=${numberOfCrews}&numberOfPeople=${numberOfPeople}`,
  );

export default {
  getGroupTotalFee$,
  updateSessionWaitingList$,
};
