import { Card, Row, Col } from 'antd';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import { Payment } from '@/modules/data/dataTypes/paymentList';
import { PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import Desktop from '@/modules/responsiveSizes/Desktop';
import Mobile from '@/modules/responsiveSizes/Mobile';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import CSS from './ReservationPaymentsCard.less';
import { Tabs, TotalAmountRow } from './ReservationPaymentsCard.styled';
import AmountPaid from './components/AmountPaid';
import AmountToPay from './components/AmountToPay';
import PaymentHistoryTab from './components/PaymentHistoryTab';
import PaymentScheduleTab from './components/PaymentScheduleTab';

const TAB_KEY_HISTORY = 'history';
const TAB_KEY_SCHEDULE = 'schedule';

export type EnhancedPaymentCategory = PaymentCategory & {
  dueAmount: number;
  status?: string;
};

type BaseProps = {
  isLoading?: boolean;
  paymentCategories: EnhancedPaymentCategory[];
  reservationPayments: Payment[];
  paymentSummary: PaymentSummary;
  form: FormSettings;
  paymentHistoryErrorMessage?: string;
  onMakePayment?: (amount: number) => void;
  onUpdatePaymentScheduleDueDate?: (paymentCategory: PaymentCategory) => void;
};

type GroupProps = BaseProps & {
  isGroup: true;
  groupReservation: GroupReservation;
};

type IndividualProps = BaseProps & {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};

const ReservationPaymentCard: React.FC<GroupProps | IndividualProps> = props => {
  const {
    isGroup,
    isLoading = false,
    paymentCategories,
    reservationPayments,
    paymentSummary,
    onMakePayment,
    onUpdatePaymentScheduleDueDate,
    form: formSettings,
    paymentHistoryErrorMessage,
  } = props;
  const { amountPaid, nextPaymentAmountDue } = paymentSummary;

  const [activeTabKey, setActiveTabKey] = useState(TAB_KEY_HISTORY);
  const [amountToPay, setAmountToPay] = useState(0);
  const [selectedScheduleRowKeys, setSelectedScheduleRowKeys] = useState<string[]>([]);

  const { isCancelled } = isGroup ? props.groupReservation : props.attendeeDetails;

  const canPostPayments = useSelector(
    createAbilitiesSelector('payment', PermissionAction.Create, props),
  );

  const canUpdatePaymentStatus = useSelector(
    createAbilitiesSelector('payment', PermissionAction.Update),
  );

  const canPostRefunds = useSelector(createAbilitiesSelector('refund', PermissionAction.Create));

  const renderTotalAmount = useCallback(() => {
    const handleMakePayment = () => {
      onMakePayment && onMakePayment(amountToPay);
    };

    switch (activeTabKey) {
      case TAB_KEY_SCHEDULE:
        return canPostPayments ? (
          <AmountToPay
            amountToPay={amountToPay}
            onMakePayment={handleMakePayment}
            displayAmount={selectedScheduleRowKeys.length > 0}
          />
        ) : null;
      case TAB_KEY_HISTORY:
        return (
          <AmountPaid nextPaymentAmountDue={nextPaymentAmountDue} totalAmountPaid={amountPaid} />
        );
      default:
        return null;
    }
  }, [
    activeTabKey,
    amountToPay,
    nextPaymentAmountDue,
    amountPaid,
    selectedScheduleRowKeys,
    canPostPayments,
    onMakePayment,
  ]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <Card className={CSS.reservationPaymentsCard}>
        <header className={CSS.header}>
          <Row>
            <Col span={14}>
              <h2 className={CSS.title}>Payment Info</h2>
            </Col>
            <Desktop>
              <Col span={10}>
                <Row justify="end">
                  <div className={CSS.headerSection}>{renderTotalAmount()}</div>
                </Row>
              </Col>
            </Desktop>
          </Row>
        </header>
        <Row>
          <Tabs
            className={CSS.table}
            activeKey={activeTabKey}
            onChange={setActiveTabKey}
            size="large"
            animated
            items={[
              {
                key: TAB_KEY_SCHEDULE,
                label: 'Payment Schedule',
                children: (
                  <PaymentScheduleTab
                    paymentCategories={paymentCategories}
                    onSetAmountToPay={setAmountToPay}
                    isReservationCancelled={isCancelled}
                    selectedRowKeys={selectedScheduleRowKeys}
                    isAllowedToPay={canPostPayments}
                    onSetSelectedRowKeys={setSelectedScheduleRowKeys}
                    onUpdatePaymentScheduleDueDate={onUpdatePaymentScheduleDueDate}
                  />
                ),
              },
              {
                key: TAB_KEY_HISTORY,
                label: 'Payment History',
                children: (
                  <PaymentHistoryTab
                    {...props}
                    reservationPayments={reservationPayments}
                    paymentSummary={paymentSummary}
                    formSettings={formSettings}
                    errorMessage={paymentHistoryErrorMessage}
                    canPostRefunds={canPostRefunds}
                    canUpdatePaymentStatus={canUpdatePaymentStatus}
                  />
                ),
              },
            ]}
          />
        </Row>
        <Mobile>
          <TotalAmountRow>{renderTotalAmount()}</TotalAmountRow>
        </Mobile>
      </Card>
    </LoadingContainer>
  );
};

export default ReservationPaymentCard;
