import { Col, Form, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import Warning from '@/modules/shared/components/Warning';

import { rosterSel } from '@/pages/reservation/tabs/OverviewGroup/duck/selectors';

import { transferAttendees, deselectAttendee } from '../../duck/actions';
import { selectedAttendeesByPersonGuidSel } from '../../duck/selectors';

import {
  Description,
  HeaderRow,
  Row,
  CloseIcon,
  FormItem,
} from './TransferAttendeesSelectTypeModal.styled';
import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const TransferAttendeesSelectTypeModal: React.FC<Props> = ({ modalParams }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const selectedAttendees = useSelector(selectedAttendeesByPersonGuidSel);
  const sourceRoster = useSelector(rosterSel);
  const { destinationRoster } = modalParams;

  function handleCancel() {
    dispatch(closeModal());
  }

  function handleTransfer() {
    form.validateFields().then(selectedAttendeeTypes => {
      dispatch(transferAttendees.request({ roster: destinationRoster, selectedAttendeeTypes }));
    });
  }

  const createRemoveAttendeeHandler = (memberId: string) => () => {
    dispatch(deselectAttendee(memberId));
  };

  return (
    <ModalComponent
      title="Transfer Confirmation"
      buttons={[
        { title: 'No, go back', onClick: handleCancel },
        { title: 'Yes, Transfer', onClick: handleTransfer, type: 'primary' },
      ]}
    >
      <Warning
        showWarningText={false}
        errorMessage="The attendees you are transferring do not meet the requirements of this roster. Please select different attendees or a different destination roster"
      />
      <Description>
        {`Are you sure you want to transfer these attendees from “${sourceRoster?.rosterExpeditionNumber}” to “${destinationRoster.rosterExpeditionNumber}”?`}
        <br />
        Please confirm the attendeee types for the people you selected.
      </Description>
      <HeaderRow gutter={[10, 10]}>
        <Col span={7}>
          <b>Attendee</b>
        </Col>
        <Col span={7}>
          <b>Actual Attendee Type</b>
        </Col>
        <Col span={8}>
          <b>New Attendee Type</b>
        </Col>
      </HeaderRow>
      <Form form={form}>
        {selectedAttendees.map(({ memberId, fullName, attendeeTypeCode }) => {
          const attendeeType = sourceRoster?.attendeeTypes.find(
            ({ typeCode }) => typeCode === attendeeTypeCode,
          );
          return (
            <Row key={memberId} gutter={[10, 0]} align="middle">
              <FormItem>
                <Col span={7}>
                  <b>{fullName}</b>
                </Col>
                <Col span={7}>
                  <b>{attendeeType ? attendeeType.typeName : ''}</b>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={memberId}
                    rules={[
                      {
                        required: true,
                        message: 'Please select attendee type or remove this attendee',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Attendee Type"
                      options={destinationRoster.attendeeTypes.map(({ typeCode, typeName }) => ({
                        value: typeCode,
                        label: typeName,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <CloseIcon onClick={createRemoveAttendeeHandler(memberId)} />
                </Col>
              </FormItem>
            </Row>
          );
        })}
      </Form>
    </ModalComponent>
  );
};

export default TransferAttendeesSelectTypeModal;
