import { SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select';
import React, { createRef, Fragment, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { Select, SelectWrapper, Tag, TagSelectorWrapper } from './TagSelector.styled';
import AddTag from './components/AddTag';

type BaseProps<T> = Pick<SelectProps, 'placeholder'> & {
  dataSource: T[];
  disabledItems?: Set<string>;
  value?: string[];
  valueField: keyof T;
  labelField: keyof T;
  autoClearSearchValue?: boolean;
  allowEdit?: boolean;
  closeOnSelect?: boolean;
  className?: string;
  label?: string;
  portalNodeRef?: React.RefObject<HTMLElement>;
  addInProgress?: boolean;
  onChange?: (value: string[]) => void;
  onSelect?: (valueItem: string) => void;
  onDeselect?: (valueItem: string) => void;
  onAdd?: (value: string, label: string) => void;
};

const TagSelector = <T,>({
  autoClearSearchValue = false,
  dataSource,
  disabledItems,
  value = [],
  valueField,
  labelField,
  allowEdit = true,
  closeOnSelect = false,
  className,
  label,
  portalNodeRef,
  addInProgress,
  onChange,
  onSelect,
  onDeselect,
  onAdd,
  ...rest
}: BaseProps<T>): JSX.Element => {
  const [dropdownIsOpened, setDropdownIsOpened] = useState(false);
  const selectRef = createRef<BaseSelectRef>();

  const labelsObj = useMemo(
    () =>
      dataSource.reduce(
        (acc, o) => ({
          ...acc,
          [o[valueField] as unknown as string]: o[labelField] as unknown as string,
        }),
        {} as Record<string, string>,
      ),
    [dataSource, valueField, labelField],
  );

  const handleSelect = (valueItem: unknown) => {
    onSelect && onSelect(valueItem as string);
    if (closeOnSelect) {
      setDropdownIsOpened(false);
      selectRef.current?.blur();
    }
  };

  const handleDeselect = useCallback(
    (valueItem: unknown) => {
      onDeselect && onDeselect(valueItem as string);
      if (closeOnSelect) {
        setDropdownIsOpened(false);
        selectRef.current?.blur();
      }
    },
    [closeOnSelect, onDeselect, selectRef],
  );

  const handleChange = (nextValue: unknown) => {
    onChange && onChange(nextValue as string[]);
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    setDropdownIsOpened(open);
  };

  const renderTags = useCallback(() => {
    const createDeselectHandler = (valueItem: string) => () => {
      handleDeselect(valueItem);
      if (onChange) {
        const nextValue = value.filter(i => i !== valueItem);
        onChange(nextValue);
      }
    };

    return (
      <Fragment>
        {value.map(valueItem => (
          <Tag key={valueItem} closable={allowEdit} onClose={createDeselectHandler(valueItem)}>
            {labelsObj[valueItem]}
          </Tag>
        ))}
      </Fragment>
    );
  }, [allowEdit, handleDeselect, labelsObj, onChange, value]);

  return (
    <TagSelectorWrapper className={className}>
      {allowEdit && (
        <SelectWrapper labelContent={label}>
          <Select
            {...rest}
            ref={selectRef}
            onChange={handleChange}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            optionFilterProp="label"
            mode="multiple"
            size="large"
            value={value}
            autoClearSearchValue={autoClearSearchValue}
            open={dropdownIsOpened}
            tagRender={() => <Fragment>{null}</Fragment>}
            options={dataSource.map(o => ({
              value: o[valueField],
              label: o[labelField],
              disabled: disabledItems?.has(o[valueField] as string),
            }))}
            dropdownRender={menu => (
              <Fragment>
                {menu}
                {onAdd && <AddTag onAdd={onAdd} addInProgress={!!addInProgress} />}
              </Fragment>
            )}
          />
        </SelectWrapper>
      )}
      {portalNodeRef?.current ? (
        createPortal(renderTags(), portalNodeRef.current)
      ) : (
        <div>{renderTags()}</div>
      )}
    </TagSelectorWrapper>
  );
};

export default TagSelector;
