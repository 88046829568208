import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { tabs } from '../constants';

import { changeTab, cloneEvent } from './actions';

const tabKey = createReducer(tabs[0].key).handleAction(changeTab, (_, { payload }) => payload);

const isCloningEvent = createReducer(false)
  .handleAction(cloneEvent.request, () => true)
  .handleAction([cloneEvent.success, cloneEvent.failure], () => false);

export default combineReducers({
  tabKey,
  isCloningEvent,
});
