import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { Activity } from '@/modules/data/dataTypes/activities';
import { resetOnAction } from '@/modules/utils/resetOnLogoutReducer';

import {
  deleteActivity,
  resetModuleState,
  updateActivity,
  updateActivityClosed,
  updateActivityOpened,
} from './actions';

const currentActivity = createReducer<Activity | Record<string, never> | null>(null)
  .handleAction(updateActivityOpened, (_, { payload }) => payload || {})
  .handleAction(updateActivityClosed, () => null);

const updateActivityInProgress = createReducer(false)
  .handleAction(updateActivity.request, () => true)
  .handleAction([updateActivity.success, updateActivity.failure], () => false);

const deleteActivityInProgress = createReducer(false)
  .handleAction(deleteActivity.request, () => true)
  .handleAction([deleteActivity.success, deleteActivity.failure], () => false);

export default resetOnAction(
  combineReducers({
    currentActivity,
    updateActivityInProgress,
    deleteActivityInProgress,
  }),
  resetModuleState,
);
