import dayjs, { Dayjs } from 'dayjs';
import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootAction, RootState } from 'typesafe-actions';

import { payments } from '@/modules/config/config';
import {
  PaymentMethod,
  paymentMethodsData as paymentMethodsMap,
} from '@/modules/payments/constants';
import {
  makeAdjustmentPayment,
  makeOfflinePayment,
  initOrbitalPayment,
  completeOrbitalPayment,
  iFramePaymentStart,
} from '@/modules/payments/duck/actions';
import {
  sessionIdSel,
  uIDSel,
  paymentErrorMessageSel,
  makePaymentInProgressSel,
  makeRefundInProgressSel,
} from '@/modules/payments/duck/selectors';
import { AdjustmentPayment, OfflinePaymentPayload } from '@/modules/payments/types';
import toastService from '@/modules/toasts/service';
import { dateToString } from '@/modules/utils/dateFormats';

import IframeCCForm from './components/IframeCCForm';
import PaymentMethodPicker from './components/PaymentMethodPicker';
import { AdjustmentTypeValues } from './components/PaymentMethodPicker/components/AdditionalFields/components/AdjustmentType/types';
import { AdditionalFieldsData } from './types';

const { chase } = payments;
const { merchantId, host } = chase;

export interface PaymentGatewayProps {
  amount: number;
  comments?: string;
  enabledPaymentMethods: Set<PaymentMethod>;
  formCode: string;
  isGroup: boolean;
  guid: string;
  triggerPayment: boolean;
  paymentCategoryCode: string;
  allowOfflinePayments?: boolean;
  personGUID?: string;
  paymentMethod: PaymentMethod | null;
  onPaymentMethodChange?: (paymentMethod: PaymentMethod) => void;
  onPaymentCompleted?: () => void;
  onPaymentCancelled?: () => void;
  onPaymentError?: () => void;
  onPaymentStart?: () => void;
}

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & PaymentGatewayProps;

class PaymentGateway extends React.Component<Props> {
  static defaultProps = {
    uID: '',
    formCode: '',
    formRecordGUID: '',
    sessionId: '',
    customPaymentAmount: 0,
    triggerPayment: false,
    allowOfflinePayments: false,
  };

  componentDidUpdate(prevProps: Props) {
    const { paymentErrorMessage, triggerPayment, isLoading, onPaymentError } = this.props;
    if (triggerPayment && !prevProps.triggerPayment) {
      this.handleInitPayment();
    }

    if (paymentErrorMessage && !prevProps.paymentErrorMessage) {
      onPaymentError && onPaymentError();
    } else if (!isLoading && prevProps.isLoading) {
      this.resetPaymentMethod();
    }
  }

  componentWillUnmount() {
    const { uID, completeOrbitalPaymentCancel } = this.props;
    if (uID) {
      completeOrbitalPaymentCancel();
    }
  }

  additionalFieldsRef = createRef<{
    handleSubmit: () => Promise<AdditionalFieldsData>;
  }>();

  resetPaymentMethod = () => {
    this.setState({ paymentMethod: null });
  };

  getIsOffline = () => {
    const { paymentMethod } = this.props;

    if (!paymentMethod) return false;

    const selectedPaymentMethod = paymentMethodsMap.get(paymentMethod);

    if (selectedPaymentMethod) {
      return selectedPaymentMethod.isOffline;
    }
    return false;
  };

  handleCompleteOnlinePayment = (uID: string, responseCode: string) => {
    const {
      dispatch,
      formCode,
      isGroup,
      guid,
      paymentCategoryCode,
      personGUID,
      onPaymentCompleted,
      sessionId,
      comments,
    } = this.props;

    dispatch(
      completeOrbitalPayment.request({
        uID,
        isGroup,
        guid,
        sessionId,
        responseCode,
        formCode,
        paymentCategoryCode,
        personGUID,
        comments,
        onPaymentSucceeded: onPaymentCompleted,
      }),
    );

    this.resetPaymentMethod();
    onPaymentCompleted && onPaymentCompleted();
  };

  handlePaymentMethodChange = (paymentMethod: PaymentMethod) => {
    const { onPaymentMethodChange } = this.props;
    this.setState(
      { paymentMethod },
      () => typeof onPaymentMethodChange === 'function' && onPaymentMethodChange(paymentMethod),
    );
  };

  handleInitPayment = async () => {
    const {
      amount,
      comments = '',
      formCode,
      isGroup,
      guid,
      personGUID,
      isLoading,
      paymentCategoryCode,
      paymentMethod,
      onInitOrbitalPayment,
      makeOfflinePaymentRequest,
      onPaymentStart,
      onPaymentCompleted,
      makeAdjustmentPaymentRequest,
    } = this.props;

    if (isLoading) return null;

    const isOffline = this.getIsOffline();

    if (!paymentMethod) return toastService.error('Please select a payment method');

    if (!amount) return toastService.error('The amount cannot be 0');

    const values = await this.additionalFieldsRef.current?.handleSubmit();

    onPaymentStart && onPaymentStart();

    const baseData = {
      amount,
      comments,
      formCode,
      isGroup,
      guid,
      paymentCategoryCode,
      paymentType: paymentMethod,
      personGUID,
    };

    if (paymentMethod === PaymentMethod.adjustment) {
      return makeAdjustmentPaymentRequest({
        onPaymentSucceeded: onPaymentCompleted,
        ...(values as AdjustmentTypeValues),
        ...baseData,
      });
    }

    if (isOffline) {
      if (paymentMethod === PaymentMethod.costCenter) {
        const { costCenter } = values as { costCenter: string };

        return makeOfflinePaymentRequest({
          onPaymentSucceeded: onPaymentCompleted,
          ...values,
          ...baseData,
          costCenterToCharge: costCenter,
          postedDate: dateToString(dayjs()),
        });
      } else {
        const date = values as { postedDate: Dayjs };
        const postedDate = date.postedDate && dateToString(date.postedDate);

        return makeOfflinePaymentRequest({
          onPaymentSucceeded: onPaymentCompleted,
          ...values,
          ...baseData,
          postedDate,
        });
      }
    }

    onInitOrbitalPayment(baseData);
  };

  handleCancelPayment = () => {
    const { completeOrbitalPaymentCancel, onPaymentCancelled } = this.props;
    completeOrbitalPaymentCancel();
    if (typeof onPaymentCancelled === 'function') {
      onPaymentCancelled();
    }
  };

  handlePaymentStart = () => {
    const { onIframePaymentStart, onPaymentStart } = this.props;
    if (typeof onPaymentStart === 'function') {
      onPaymentStart();
    }

    onIframePaymentStart();
  };

  render() {
    const { enabledPaymentMethods, paymentMethod, uID, completeOrbitalPaymentFailure, formCode } =
      this.props;

    if (!merchantId) {
      return <div>Missing Merchant Id</div>;
    }
    if (!host) {
      return <div>Unknown Host</div>;
    }
    if (!formCode) {
      return <div>Unknown Event</div>;
    }

    if (!!paymentMethod && !paymentMethodsMap.get(paymentMethod)) {
      return <div>Unknown Payment Type</div>;
    }

    if (uID) {
      return (
        <IframeCCForm
          uID={uID}
          onPaymentStart={this.handlePaymentStart}
          onPaymentError={completeOrbitalPaymentFailure}
          onPaymentCompleted={this.handleCompleteOnlinePayment}
          onPaymentCanceled={this.handleCancelPayment}
        />
      );
    }

    return (
      <PaymentMethodPicker
        ref={this.additionalFieldsRef}
        enabledPaymentMethods={enabledPaymentMethods}
        paymentMethod={paymentMethod}
        onPaymentMethodChange={this.handlePaymentMethodChange}
        allowOfflinePayments={this.props.allowOfflinePayments}
      />
    );
  }
}

const mapState = (
  state: RootState,
): {
  isLoading: boolean;
  uID: string;
  sessionId: string;
  paymentErrorMessage: string;
} => ({
  isLoading: makePaymentInProgressSel(state) || makeRefundInProgressSel(state),
  uID: uIDSel(state),
  sessionId: sessionIdSel(state),
  paymentErrorMessage: paymentErrorMessageSel(state),
});

const mapDispatch = (dispatch: Dispatch<RootAction>) => ({
  dispatch,
  onInitOrbitalPayment: (payload: ReturnType<typeof initOrbitalPayment.request>['payload']) =>
    dispatch(initOrbitalPayment.request(payload)),
  onIframePaymentStart: () => dispatch(iFramePaymentStart()),
  completeOrbitalPaymentFailure: (msg: string) => dispatch(completeOrbitalPayment.failure(msg)),
  completeOrbitalPaymentCancel: () => dispatch(completeOrbitalPayment.cancel()),
  makeOfflinePaymentRequest: (paymentData: OfflinePaymentPayload) =>
    dispatch(makeOfflinePayment.request(paymentData)),
  makeAdjustmentPaymentRequest: (data: AdjustmentPayment) =>
    dispatch(makeAdjustmentPayment.request(data)),
});

export default connect(mapState, mapDispatch)(PaymentGateway);
