import { Input, Select, Card, Button, Form, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CardWithHeader from '@/modules/shared/components/CardWithHeader';
import Disclaimers from '@/modules/shared/components/Disclaimers/Disclaimers';
import { ADULT_AGE_LOW } from '@/modules/shared/constants';
import toastService from '@/modules/toasts/service';
import { dateFromString } from '@/modules/utils/dateFormats';

import { navigateToEvents } from '../eventList/duck/actions';

import { acceptDisclaimers } from './duck/actions';
import {
  acceptDisclaimersInProgressSel,
  disclaimersAcceptedSel,
  pageDataSel,
} from './duck/selectors';

const FIRST_NAME_FIELD = 'firstName';
const LAST_NAME_FIELD = 'lastName';
const RELATIONSHIP_FIELD = 'relationship';
const CONSENT_FIELD = 'consent';

const ParentConsent: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<{
    [FIRST_NAME_FIELD]: string;
    [LAST_NAME_FIELD]: string;
    [RELATIONSHIP_FIELD]: string;
    [CONSENT_FIELD]: 'yes' | 'no';
  }>();
  const {
    data: { attendeeDetails, formRecordDisclaimers, allDisclaimersAccepted },
  } = useSelector(pageDataSel);
  const acceptDisclaimersInProgress = useSelector(acceptDisclaimersInProgressSel);
  const disclaimersAccepted = useSelector(disclaimersAcceptedSel);
  const [acceptedDisclaimerCodes, setAcceptedDisclaimerCodes] = useState<Set<string>>(new Set());

  const { formCode, formName, personGUID, formRecordGUID, firstName, lastName, dateOfBirth } =
    attendeeDetails;

  const handleSubmit = () => {
    const hasNotAcceptedDisclaimers = formRecordDisclaimers.some(
      ({ disclaimerCode, accepted }) => !accepted && !acceptedDisclaimerCodes.has(disclaimerCode),
    );

    if (hasNotAcceptedDisclaimers) {
      toastService.error('You must accept all of the disclaimers');
      return;
    }

    form.validateFields().then(values => {
      dispatch(
        acceptDisclaimers.request({
          ...values,
          codes: [...acceptedDisclaimerCodes],
          formCode,
          personGUID,
          formRecordGUID,
        }),
      );
    });
  };

  const handleAcceptDisclaimer = (disclaimerCode: string, isAccepted: boolean) => {
    setAcceptedDisclaimerCodes(
      isAccepted
        ? new Set([...acceptedDisclaimerCodes, disclaimerCode])
        : new Set(Array.from(acceptedDisclaimerCodes).filter(code => code !== disclaimerCode)),
    );
  };

  const handleNavigateToEvents = () => {
    dispatch(navigateToEvents());
  };

  const isYouth = useMemo(
    () => dayjs().diff(dateFromString(dateOfBirth), 'years') < ADULT_AGE_LOW,
    [dateOfBirth],
  );

  if (acceptDisclaimersInProgress) {
    return (
      <div className="text-center">
        <Spin tip="Loading Content..." spinning />
      </div>
    );
  }

  if (disclaimersAccepted) {
    return (
      <Card className="mt-6 text-center">
        <h2 className="text-primary">Thank you for accepting the disclaimers for {formName}</h2>
        <Button className="mt-2" type="default" onClick={handleNavigateToEvents}>
          Return to Events List
        </Button>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <h1 className="mt-1">{`For Attendee: ${firstName} ${lastName}`}</h1>
      <h3>
        <strong>Event:</strong> {formName}
      </h3>
      <Form layout="horizontal" form={form} labelCol={{ span: 6 }}>
        {!allDisclaimersAccepted && (
          <CardWithHeader header="Name and relationship">
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: 'First Name is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: 'Last Name is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="relationship"
              label="Relationship to the Applicant"
              rules={[{ required: true, message: 'The relationship is required' }]}
            >
              <Select
                options={
                  isYouth
                    ? ['Parent', 'Legal Guardian'].map(o => ({ value: o, label: o }))
                    : ['Myself'].map(o => ({ value: o, label: o }))
                }
              />
            </Form.Item>
          </CardWithHeader>
        )}
        <Disclaimers
          attendeeDetails={attendeeDetails}
          disclaimers={formRecordDisclaimers}
          acceptedDisclaimerCodes={acceptedDisclaimerCodes}
          onAccept={handleAcceptDisclaimer}
        />
        {isYouth && (
          <Card className="mt-5">
            <p>By selecting "YES" in the dropdown below, I am confirming the following:</p>

            <ul>
              <li>I am the parent / legal guardian for the above named youth.</li>
              <li>
                I understand an application for attendance to the Event {formName} has been
                submitted for the above named youth.
              </li>
              <li>
                I give my permission for the above named youth to attend the Event {formName} for
                this application to be processed.
              </li>
            </ul>

            <Form.Item
              name="consent"
              label="Parental Consent"
              initialValue="yes"
              rules={[
                { required: true, message: 'Consent is required' },
                { pattern: /^yes$/, message: 'Invalid response' },
              ]}
            >
              <Select
                options={[
                  { value: 'yes', label: 'YES I give parental consent' },
                  { value: 'no', label: "NO I don't give parental consent" },
                ]}
              />
            </Form.Item>
          </Card>
        )}
        {!allDisclaimersAccepted && (
          <Button
            className="mt-5 mb-5"
            onClick={handleSubmit}
            disabled={acceptDisclaimersInProgress}
          >
            Continue
          </Button>
        )}
      </Form>
    </React.Fragment>
  );
};

export default ParentConsent;
