import isEmpty from 'lodash/isEmpty';

// OBJECTS

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const searchInObj = <T extends Record<string, any>>(
  obj: T,
  searchTerm: string,
  allowDeepSearch?: boolean,
  searchInKeys?: Array<string>,
): boolean =>
  !searchTerm ||
  Object.entries(obj).some(([key, val]) => {
    if (!val) return false;

    if (searchInKeys) {
      return searchInKeys?.includes(key) && val.toLowerCase().includes(searchTerm.toLowerCase());
    }

    switch (typeof val) {
      case 'string':
        return val.toLowerCase().includes(searchTerm.toLowerCase());
      case 'number':
        return (val + '').toLowerCase().includes(searchTerm.toLowerCase());
      case 'object':
        if (!allowDeepSearch) {
          return false;
        }
        return Array.isArray(val)
          ? !isEmpty(search(val, searchTerm, allowDeepSearch))
          : searchInObj(val, searchTerm);
      default:
        return false;
    }
  });

// ARRAYS

// Search in Array of Objects

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function search<T extends Record<string, any>>(
  fullItems: readonly T[],
  searchTerm: string,
  allowDeepSearch?: boolean,
  searchInKeys?: Array<string>,
): T[] {
  if (!searchTerm) {
    return fullItems as T[];
  }

  return fullItems.filter(item => searchInObj(item, searchTerm, allowDeepSearch, searchInKeys));
}
