import React from 'react';

import GenericNotification from '../GenericNotification';

import styles from './ReportReadyNotification.less';

import S from 'SHARED/components/S';

type Props = {
  reportName: string;
  downloadUrl: string;
};

const ReportReadyNotification: React.FC<Props> = ({ reportName, downloadUrl }) => (
  <a target="_blank" href={downloadUrl}>
    <GenericNotification
      title={'Report is Ready'}
      content={
        <S size="5" className={styles.content}>
          {`Download ${reportName}`}
        </S>
      }
    />
  </a>
);

export default ReportReadyNotification;
