import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createIsLoadingSel } from '@/modules/data/duck/selectors';

import { moduleName, steps as basicSteps, stepKeys } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const addAttendeeInitialDataSel = createSelector(
  moduleSel,
  ({ addAttendeeInitialData }) => addAttendeeInitialData,
);

export const addAttendeeInProgressSel = createSelector(
  moduleSel,
  ({ addAttendeeInProgress }) => addAttendeeInProgress,
);

export const personGuidSel = createSelector(moduleSel, ({ personGuid }) => personGuid);

export const collectedFormPartsSel = createSelector(
  moduleSel,
  ({ collectedFormParts }) => collectedFormParts,
);

export const collectedRegistrationDataSel = createSelector(
  moduleSel,
  ({ collectedRegistrationData }) => collectedRegistrationData,
);

export const stepsSel = createSelector(addAttendeeInitialDataSel, addAttendeeInitialData => {
  if (addAttendeeInitialData?.personExists && addAttendeeInitialData?.isSameUser) {
    return basicSteps.filter(({ key }) => key === stepKeys.forms);
  }
  return basicSteps;
});
export const currentStepIndexSel = createSelector(moduleSel, ({ stepIndex }) => stepIndex.current);
const futureStepIndexSel = createSelector(moduleSel, ({ stepIndex }) => stepIndex.future);

export const futureStepKeySel = createSelector(
  [futureStepIndexSel, stepsSel],
  (stepIndex, steps) => steps[stepIndex].key,
);

export const stepIsLoadingSel = createSelector(moduleSel, ({ stepIsLoading }) => stepIsLoading);

export const disabledStepsSel = createSelector(moduleSel, ({ disabledSteps }) => disabledSteps);

export const currentStepIsDisabledSel = createSelector(
  [stepsSel, currentStepIndexSel, disabledStepsSel],
  (steps, stepIndex, disabledSteps) => {
    const step = steps[stepIndex];

    return !!disabledSteps[step.key];
  },
);

const isLoadingProfileSel = createSelector(
  [createIsLoadingSel('personProfile')],
  isLoadingPerson => isLoadingPerson,
);

const isLoadingDetailsSel = createSelector(
  [createIsLoadingSel('groupReservation')],
  loadingReservation => loadingReservation,
);

export const isLoadingSel = createSelector(
  [isLoadingDetailsSel, isLoadingProfileSel],
  (loadingDetails, isLoadingProfile) => loadingDetails || isLoadingProfile,
);
