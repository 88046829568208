import { createAction, createAsyncAction, RootAction } from 'typesafe-actions';

import { AttendeeType } from '@/modules/data/dataTypes/attendeeTypeList';
import { Session } from '@/modules/data/dataTypes/sessionList';
import { ApiError } from '@/modules/utils/apiService';

import { modulePath } from '../constants';
import {
  ParsedSessionFromCSV,
  SessionAddon,
  SessionMultiSessionRule,
  UpdateSessionPayload,
} from '../types';

const moduleNamespace = modulePath.join('/');

export const updateSessionOpened = createAction(
  `${moduleNamespace}/UPDATE_SESSION_OPENED`,
)<Session | null>();
export const updateSessionClosed = createAction(`${moduleNamespace}/UPDATE_SESSION_CLOSED`)();

export const deleteSession = createAsyncAction(
  `${moduleNamespace}/DELETE_SESSION_REQUEST`,
  `${moduleNamespace}/DELETE_SESSION_SUCCESS`,
  `${moduleNamespace}/DELETE_SESSION_FAILURE`,
)<string, undefined, ApiError>();

export const cloneSession = createAsyncAction(
  `${moduleNamespace}/CLONE_SESSION_REQUEST`,
  `${moduleNamespace}/CLONE_SESSION_SUCCESS`,
  `${moduleNamespace}/CLONE_SESSION_FAILURE`,
)<
  {
    sessionCode: string;
    sessions: {
      sessionName: string;
      startDate: string;
      closeDate: string;
    }[];
  },
  undefined,
  ApiError
>();

export const updateSession = createAsyncAction(
  `${moduleNamespace}/UPDATE_SESSION_REQUEST`,
  `${moduleNamespace}/UPDATE_SESSION_SUCCESS`,
  `${moduleNamespace}/UPDATE_SESSION_FAILURE`,
)<
  {
    session: UpdateSessionPayload;
    addons: SessionAddon[];
    activities: {
      activityCode: string;
      isMandatory: boolean;
    }[];
    attendeeTypes: Array<AttendeeType & { isGranted: boolean }>;
    multiSessionRuleCode?: string;
    hasMultiSessionRule: boolean;
    previousRuleCode?: string;
  },
  undefined,
  ApiError
>();

export const updateSessionsInBulk = createAsyncAction(
  `${moduleNamespace}/UPDATE_SESSIONS_IN_BULK_REQUEST`,
  `${moduleNamespace}/UPDATE_SESSIONS_IN_BULK_SUCCESS`,
  `${moduleNamespace}/UPDATE_SESSIONS_IN_BULK_FAILURE`,
)<{ sessions: ParsedSessionFromCSV[]; finalActions: RootAction[] }, undefined, ApiError>();

export const saveSessionAttendeeTypes = createAsyncAction(
  `${moduleNamespace}/SAVE_SESSION_ATTENDEE_TYPES_REQUEST`,
  `${moduleNamespace}/SAVE_SESSION_ATTENDEE_TYPES_SUCCESS`,
  `${moduleNamespace}/SAVE_SESSION_ATTENDEE_TYPES_FAILURE`,
)<
  { attendeeTypes: Array<AttendeeType & { isGranted: boolean }>; sessionCode: string },
  undefined,
  ApiError
>();

export const saveSessionActivities = createAsyncAction(
  `${moduleNamespace}/SAVE_SESSION_ACTIVITIES_REQUEST`,
  `${moduleNamespace}/SAVE_SESSION_ACTIVITIES_SUCCESS`,
  `${moduleNamespace}/SAVE_SESSION_ACTIVITIES_FAILURE`,
)<
  {
    sessionCode: string;
    activities: Omit<
      ReturnType<typeof updateSession.request>['payload']['activities'],
      'date' | 'time'
    >;
  },
  undefined,
  ApiError
>();

export const saveSessionAddons = createAsyncAction(
  `${moduleNamespace}/SAVE_SESSION_ADDONS_REQUEST`,
  `${moduleNamespace}/SAVE_SESSION_ADDONS_SUCCESS`,
  `${moduleNamespace}/SAVE_SESSION_ADDONS_FAILURE`,
)<{ sessionCode: string; addons: SessionAddon[] }, undefined, ApiError>();

export const saveSessionMultiSessionRule = createAsyncAction(
  `${moduleNamespace}/SAVE_SESSION_MULTISESSION_RULE_REQUEST`,
  `${moduleNamespace}/SAVE_SESSION_MULTISESSION_RULE_SUCCEEDED`,
  `${moduleNamespace}/SAVE_SESSION_MULTISESSION_RULE_FAILED`,
)<SessionMultiSessionRule, undefined, ApiError>();
