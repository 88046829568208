import { Collapse, Popconfirm } from 'antd';
import React from 'react';

import { Attendee } from '@/modules/data/dataTypes/attendeeList';

import { CancelButton, Panel } from './Registrant.styled';
import AdditionalRegistrantActivities from './components/AdditionalRegistrantActivities';
import AdditionalRegistrantAddons from './components/AdditionalRegistrantAddons';
import AdditionalRegistrantReservationDetails from './components/AdditionalRegistrantReservationDetails';
import { sections } from './constants';

type Props = {
  formRecord: Attendee;
  onCancel: () => void;
};

const activeSections = Object.values(sections).map(({ key }) => key);

const Registrant: React.FC<Props> = ({ formRecord, onCancel, ...rest }) => {
  const { formRecordGUID, firstName, lastName } = formRecord;

  return (
    <Panel {...rest} header={`${firstName} ${lastName} Details`} key={formRecordGUID}>
      <Collapse expandIconPosition="end" activeKey={activeSections}>
        <AdditionalRegistrantReservationDetails
          formRecord={formRecord}
          key={sections.personalInformation.key}
          header={sections.personalInformation.title}
        />
        <AdditionalRegistrantActivities
          formRecord={formRecord}
          key={sections.activities.key}
          header={sections.activities.title}
        />
        <AdditionalRegistrantAddons
          formRecord={formRecord}
          key={sections.addons.key}
          header={sections.addons.title}
        />
      </Collapse>
      <Popconfirm title="Are you sure?" onConfirm={onCancel}>
        <CancelButton>CANCEL RESERVATION</CancelButton>
      </Popconfirm>
    </Panel>
  );
};

export default Registrant;
