import { produce } from 'immer';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { FormattedFormParts } from '@/modules/questions/types';
import { resetOnAction } from '@/modules/utils/resetOnLogoutReducer';

import { updatePersonalInformation } from '../steps/PersonalInformation/duck/actions';

import {
  addAttendee,
  collectFormParts,
  collectRegistrationData,
  disableStep,
  enableStep,
  navigateToAddAttendee,
  openNextStep,
  openPrevStep,
  openStepCompleted,
  resetAddAttendeeState,
} from './actions';

const stepIndex = createReducer({ future: 0, current: 0 })
  .handleAction(openNextStep, state =>
    produce(state, draft => {
      draft.future += 1;
    }),
  )
  .handleAction(openPrevStep, state =>
    produce(state, draft => {
      draft.future -= 1;
    }),
  )
  .handleAction(openStepCompleted, state =>
    produce(state, draft => {
      draft.current = draft.future;
    }),
  );

const stepIsLoading = createReducer(false)
  .handleAction([openNextStep, openPrevStep], () => true)
  .handleAction(openStepCompleted, () => false);

const addAttendeeInitialData = createReducer<
  ReturnType<typeof navigateToAddAttendee>['payload'] | null
>(null).handleAction(navigateToAddAttendee, (_state, { payload }) => payload);

const personGuid = createReducer<string | null>(null)
  .handleAction(navigateToAddAttendee, (_state, { payload }) =>
    'personGUID' in payload ? payload.personGUID : null,
  )
  .handleAction(updatePersonalInformation.success, (_state, { payload }) => payload);

const addAttendeeInProgress = createReducer(false)
  .handleAction(addAttendee.request, () => true)
  .handleAction([addAttendee.success, addAttendee.failure], () => false);

const collectedFormParts = createReducer<FormattedFormParts>([])
  .handleAction(collectFormParts, (state, { payload }) =>
    produce(state, draft => {
      payload.forEach(fp => {
        const existingFormPart = draft.find(({ formPartCode }) => formPartCode === fp.formPartCode);
        if (existingFormPart) {
          fp.formItems.forEach(fi => {
            const existingFormItemIndex = existingFormPart.formItems.findIndex(
              ({ formItemCode }) => formItemCode === fi.formItemCode,
            );
            if (existingFormItemIndex !== -1) {
              existingFormPart.formItems.splice(existingFormItemIndex, 1);
            }
            existingFormPart.formItems.push(fi);
          });
        } else {
          draft.push(fp);
        }
      });
    }),
  )
  .handleAction(addAttendee.success, () => []);

const collectedRegistrationData = createReducer({} as Partial<AttendeeDetails>).handleAction(
  collectRegistrationData,
  (state, { payload }) =>
    produce(state, draft => {
      Object.assign(draft, payload);
    }),
);

const disabledSteps = createReducer<Record<string, string>>({})
  .handleAction(disableStep, (state, { payload }) =>
    produce(state, draft => {
      draft[payload.stepKey] = payload.reason;
    }),
  )
  .handleAction(enableStep, (state, { payload }) =>
    produce(state, draft => {
      delete draft[payload];
    }),
  );

export default resetOnAction(
  combineReducers({
    stepIndex,
    stepIsLoading,
    personGuid,
    addAttendeeInitialData,
    addAttendeeInProgress,
    collectedFormParts,
    collectedRegistrationData,
    disabledSteps,
  }),
  resetAddAttendeeState,
);
