import { Upload as AntUpload } from 'antd';
import { MdInsertPhoto } from 'react-icons/md';
import styled from 'styled-components';

export const Upload = styled(AntUpload)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px dashed grey;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
`;

export const ButtonContainer = styled.div`
  font-size: 14px;
  display: flex;
  color: #979797;
  justify-content: center;
  align-items: center;
`;

export const InsertPhotoIcon = styled(MdInsertPhoto)`
  width: 66px !important;
  height: 66px !important;
  fill: #979797;
`;
