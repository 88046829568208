import styled, { css } from 'styled-components';

const formStyles = css`
  color: rgb(0, 63, 135);
  font-weight: normal;
`;

const requiredStyles = css`
  :before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #dd0000;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;

export const LabelWrapper = styled.div<{
  $orientation: 'horizontal' | 'vertical';
}>`
  width: 100%;
  display: flex;
  align-items: ${({ $orientation }) => ($orientation === 'horizontal' ? 'center' : 'flex-start')};
  flex-direction: ${({ $orientation }) =>
    $orientation === 'horizontal' ? 'row-reverse' : 'column'};
  justify-content: ${({ $orientation }) =>
    `flex-${$orientation === 'horizontal' ? 'end' : 'start'}`};
  margin-bottom: 30px;

  > :first-child {
    ${({ $orientation: orientation }) =>
      `margin-${orientation === 'horizontal' ? 'left' : 'bottom'}: 5px`};
  }
`;

export const LabelText = styled.span<{ $useFormStyles: boolean; $required: boolean }>`
  font-size: 14px;
  font-weight: 500;
  display: block;

  ${({ $useFormStyles }) => $useFormStyles && formStyles}
  ${({ $required }) => $required && requiredStyles}
`;
