import { createPageDataParams } from '@/modules/data/utils';

export const moduleName = 'parentConsent';

export const ROUTE_ATTENDEE_PARENT_CONSENT = 'routes/ROUTE_ATTENDEE_PARENT_CONSENT';

export const pageDataParams = createPageDataParams(
  { dataType: 'attendeeDetails' },
  { dataType: 'formRecordDisclaimers' },
  { dataType: 'allDisclaimersAccepted' },
);
