import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

export const Content = styled.section`
  padding: 24px;
`;

export const Title = styled.h1`
  font-size: 18px;
  color: ${colors.scoutingBlue};
  font-weight: bold;
  margin-bottom: 25px;
`;

export const Description = styled.div`
  font-weight: bold;
  margin-bottom: 25px;
`;

const centerButtons = css`
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  background-color: ${colors.gray_1};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 16px 24px;
`;

export const ButtonsRow = styled.div<{ centerButtons?: boolean }>`
  display: flex;
  justify-content: flex-end;
  & > button {
    margin-left: 10px;
  }
  ${props => props.centerButtons && centerButtons}
`;
