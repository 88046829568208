import pick from 'lodash/pick';
import { combineEpics, Epic } from 'redux-observable';
import { of, concat, merge } from 'rxjs';
import { catchError, switchMap, filter } from 'rxjs/operators';
import { RootAction, isActionOf, RootState } from 'typesafe-actions';

import { resetFilters, resetPagination, setFilters } from '@/modules/data/duck/actions';
import { createFiltersSel } from '@/modules/data/duck/selectors';
import { closeModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { navigateToCreateEvent } from '@/pages/createEvent/duck/actions';
import { tabFiltersSel } from '@/pages/eventList/duck/selectors';

import { changeTab, cloneEvent } from './actions';
import services from './services';

const fetchEventList$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(changeTab)),
    switchMap(() => {
      const state = state$.value;
      const usedFilters = createFiltersSel('personFormList')(state);
      const filters = tabFiltersSel(state);
      // Save non tab related filters when changing tab
      const toPreserve = pick(usedFilters, 'eventName', 'eventDate', 'sortOrderAttribute');

      return concat(
        of(
          resetPagination({ dataType: 'personFormList', fetchData: false }),
          resetFilters({ dataType: 'personFormList', fetchData: false }),
          setFilters({ dataType: 'personFormList', filters: { ...filters, ...toPreserve } }),
        ),
      );
    }),
  );

const cloneEvent$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(cloneEvent.request)),
    switchMap(({ payload }) => services.cloneEvent$(payload)),
    switchMap(({ formCode }) => {
      toastService.success('Event cloned successfully');
      return of(
        cloneEvent.success({ formCode }),
        closeModal(),
        navigateToCreateEvent({ formCode, isInitialLoad: true }),
      );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(cloneEvent.failure(error)), caught);
    }),
  );

export default combineEpics(fetchEventList$, cloneEvent$);
