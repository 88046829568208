import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';

import * as s from './Payment.styled';
import PaymentGateway from './components/PaymentGateway';
import { PaymentGatewayProps } from './components/PaymentGateway/PaymentGateway';
import { uIDSel, makePaymentInProgressSel, makeRefundInProgressSel } from './duck/selectors';

type Props = ReturnType<typeof mapState> &
  PaymentGatewayProps & {
    children?: (
      paymentProvider: React.ReactElement,
      isIframeDisplayed: boolean,
    ) => React.ReactElement;
  };

class Payment extends Component<Props> {
  renderPaymentProvider = () => {
    const { children, isIframeDisplayed, ...rest } = this.props;

    return <PaymentGateway {...rest} />;
  };

  renderContent = () => {
    const { children, isIframeDisplayed } = this.props;
    if (typeof children !== 'function') {
      return this.renderPaymentProvider();
    }
    return children(this.renderPaymentProvider(), isIframeDisplayed);
  };

  render() {
    const { isLoading } = this.props;

    return (
      <s.LoadingWrapper
        size="large"
        spinning={isLoading}
        tip="Please don't close this window, this could take several minutes to process"
      >
        {this.renderContent()}
      </s.LoadingWrapper>
    );
  }
}

const mapState = (
  state: RootState,
): {
  isLoading: boolean;
  isIframeDisplayed: boolean;
} => ({
  isLoading: makePaymentInProgressSel(state) || makeRefundInProgressSel(state),
  isIframeDisplayed: !!uIDSel(state),
});

export default connect(mapState)(Payment);
