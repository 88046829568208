import { set, groupBy, sortBy } from 'lodash';
import React, { useState, forwardRef, useImperativeHandle, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootAction } from 'typesafe-actions';

import { Activity } from '@/modules/data/dataTypes/activities';
import { FormRecordActivity } from '@/modules/data/dataTypes/formRecordActivities';
import { GroupReservationActivity } from '@/modules/data/dataTypes/groupReservationActivities';
import { createDataSel } from '@/modules/data/duck/selectors';
import ActivitiesList from '@/modules/entities/Activities/components/ActivitiesList';
import { saveActivities } from '@/modules/entities/Activities/duck/actions';
import type { BasePayloadActivity } from '@/modules/entities/Activities/types';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { dateFromString, areDateRangesOverlap } from '@/modules/utils/dateFormats';

import { StyledCard, SearchBarWrapper, Collapse, SearchInput } from './ActivitiesSelector.styled';
import CollapseHeader from './components/CollapseHeader/CollapseHeader';
import { activitiesGroupDateFormat } from './constants';

import { searchInObj } from 'UTILS/searchUtils';

interface BaseProps {
  availableActivities: Activity[];
  isEditingSelectedActivities?: boolean;
  finalActions: RootAction[];
  onChange?: (activities: BasePayloadActivity[]) => void;
}

type GroupProps = BaseProps & {
  isGroup: true;
  groupReservationGUID: string;
  selectedActivities: GroupReservationActivity[];
};

type IndividualProps = BaseProps & {
  isGroup: false;
  formRecordGUID: string;
  selectedActivities: FormRecordActivity[];
};

type Props = GroupProps | IndividualProps;

const ActivitiesSelector = forwardRef<{ handleSubmit: () => void }, Props>((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const { finalActions, onChange, availableActivities, isEditingSelectedActivities } = props;

  const dispatch = useDispatch();
  const { formCode } = useSelector(createDataSel('form'));
  const canEditActivities = useSelector(
    createAbilitiesSelector('attendeeSelections', PermissionAction.Update, null),
  );

  const [searchString, setSearchString] = useState<string>('');

  const [includedActivities, setIncludedActivities] = useState<BasePayloadActivity[]>(
    sortBy(availableActivities, 'startDate').map((activity: Activity) => {
      let quantity = activity.isMandatory ? 1 : 0;

      if (props.isGroup) {
        if (quantity === 0) {
          const selectedActivity = props.selectedActivities.find(
            ({ activityCode }) => activityCode === activity.activityCode,
          );
          if (selectedActivity) {
            quantity = 1;
          }
        }
      } else {
        const selectedActivity = props.selectedActivities.find(
          ({ activityCode }) => activityCode === activity.activityCode,
        );
        quantity = selectedActivity?.quantity || quantity;
      }

      return {
        ...activity,
        quantity,
      };
    }),
  );

  const groupedActivities = useMemo(
    () =>
      groupBy(
        includedActivities
          .filter(activity => searchInObj(activity, searchString))
          .map(activity => ({
            ...activity,
            overlapActivitiesList: includedActivities
              .filter(({ startDate, finishDate, quantity, activitySessionGUID }) => {
                if (quantity === 0 || activitySessionGUID === activity.activitySessionGUID)
                  return false;
                return areDateRangesOverlap(
                  [dateFromString(activity.startDate), dateFromString(activity.finishDate)],
                  [dateFromString(startDate), dateFromString(finishDate)],
                );
              })
              .map(({ activityName }) => activityName)
              .join(', '),
          })),
        ({ startDate }) => startDate.slice(0, activitiesGroupDateFormat.length),
      ),
    [includedActivities, searchString],
  );

  useEffect(() => {
    onChange && onChange(includedActivities);
  }, [includedActivities, onChange]);

  function handleSubmit() {
    dispatch(
      saveActivities.request({
        formCode,
        activities: includedActivities,
        finalActions: finalActions,
        ...(props.isGroup
          ? {
              isGroup: props.isGroup,
              groupReservationGUID: props.groupReservationGUID,
            }
          : {
              isGroup: props.isGroup,
              formRecordGUID: props.formRecordGUID,
            }),
      }),
    );
  }

  const handleInclude = (activitySessionGUID: string, quantity: number) => {
    const activityIndex = includedActivities.findIndex(
      item => item.activitySessionGUID === activitySessionGUID,
    );
    const nextIncludedActivities = [...includedActivities];
    set(nextIncludedActivities, [activityIndex, 'quantity'], quantity);
    setIncludedActivities(nextIncludedActivities);
  };

  return (
    <StyledCard header="Activities">
      <SearchBarWrapper>
        Select the activities you want to join per day.
        <SearchInput placeholder="Search by activity" onChange={setSearchString} />
      </SearchBarWrapper>
      <Collapse defaultActiveKey={Object.keys(groupedActivities)} expandIconPosition="end">
        {Object.entries(groupedActivities).map(([date, activitiesGroup]) => (
          <Collapse.Panel header={<CollapseHeader startDate={date} />} key={date}>
            <ActivitiesList
              activities={activitiesGroup}
              canEditDisabledActivities={isEditingSelectedActivities && canEditActivities}
              onInclude={handleInclude}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </StyledCard>
  );
});

export default ActivitiesSelector;
