import { IndividualReservationPersonFormData } from '@/modules/shared/components/PersonalInfoForm/types';
import apiService, { ApiResponse } from '@/modules/utils/apiService';

const updateFormRecord$ = (
  payload: Pick<IndividualReservationPersonFormData, 'formParts'> & {
    personGUID: string;
    formCode: string;
    formRecordGUID?: string;
    sessionCode: string;
  },
) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateFormRecord',
    payload,
  );

type AddAttendeePayload = {
  formCode: string;
  formRecordGUID: string;
  rosterCode: string;
  sessionCode: string;
  typeCode: string;
  personGUID: string;
  bypassValidation?: boolean;
};

type AddLeadAdvisorPayload = AddAttendeePayload & {
  positionType: 'LeadAdvisor';
  status: 'Accept';
};

const addAttendeeToRoster$ = (
  payload: AddAttendeePayload | AddLeadAdvisorPayload,
  validator: <R extends ApiResponse>(response: R) => R,
) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateRosterPerson',
    payload,
    undefined,
    validator,
  );

export default {
  updateFormRecord$,
  addAttendeeToRoster$,
};
