import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import { useFoundPerson } from '@/modules/shared/components/FindPersonForm/hooks';

import {
  invitationEligibilitySel,
  invitationEligibilityMessagesSel,
  hasUserNameSel,
} from '@/pages/reservation/components/MyReservation/duck/selectors';

import { inviteLeadAdvisor } from '../RostersList/duck/actions';
import { leadAdvisorDesignateInProgressSel, leadTypeSel } from '../RostersList/duck/selectors';

import { ModalParams } from './types';

import FindPersonForm from 'SHARED/components/FindPersonForm';

type Props = {
  modalParams: ModalParams;
};

const LeadAdvisorDesignateModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const renderFoundContentRef = useRef(null);
  const { roster, isRegistrantCurrentUser } = modalParams;

  const foundPerson = useFoundPerson();
  const { sessionStartDate } = useSelector(createDataSel('groupReservation'));
  const hasUserName = useSelector(hasUserNameSel);
  const { isCreator, isCollaborator } = useSelector(createDataSel('form'));
  const leadAdvisorAttendeeType = useSelector((state: RootState) => leadTypeSel(state, { roster }));
  const isEligible = useSelector((state: RootState) =>
    invitationEligibilitySel(state, { attendeeType: leadAdvisorAttendeeType }),
  );
  const eligibilityMessages = useSelector((state: RootState) =>
    invitationEligibilityMessagesSel(state, { attendeeType: leadAdvisorAttendeeType }),
  );
  const inProgress = useSelector(leadAdvisorDesignateInProgressSel);

  const { rosterExpeditionNumber } = roster;

  function handleSubmit() {
    if (!foundPerson) return;
    dispatch(
      inviteLeadAdvisor.request({
        roster,
        memberID: foundPerson.memberID,
        foundPerson,
      }),
    );
  }

  function handleCancel() {
    dispatch(closeModal());
  }

  const buttons = [
    {
      title: 'Cancel',
      onClick: handleCancel,
    },
    {
      title: 'Confirm',
      onClick: handleSubmit,
      disabled: isCreator || isCollaborator ? !hasUserName : !isEligible,
    },
  ];

  return (
    <ModalComponent
      title={`Designating Lead Advisor for ${rosterExpeditionNumber}`}
      description={`Who will be the Lead Advisor for ${rosterExpeditionNumber}?`}
      buttons={buttons}
      inProgress={inProgress}
    >
      <FindPersonForm
        isEventOwner={isRegistrantCurrentUser}
        pivotDate={sessionStartDate}
        showEligibilityContent
        isEligible={isEligible}
        eligibilityMessages={eligibilityMessages}
        renderFoundPersonRef={renderFoundContentRef}
      />
      <div ref={renderFoundContentRef} />
    </ModalComponent>
  );
};

export default LeadAdvisorDesignateModal;
