import { DatePicker as DatePickerAntd, Select as SelectAntd } from 'antd';
import styled from 'styled-components';

import Card from 'SHARED/components/Card';

export const FiltersCard = styled(Card)`
  margin-bottom: 0.8rem;
`;

export const DatePicker = styled(DatePickerAntd)`
  width: 100%;
`;

export const Select = styled(SelectAntd)`
  width: 100%;
` as typeof SelectAntd;
