import { Button } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const ClearFieldsButton = styled(Button)`
  margin-bottom: 5px;
  padding: 0;

  & > span {
    color: ${colors.ceruleanBlue};
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 36px;
  }

  & > div:last-child {
    margin-bottom: 24px;
  }
`;

export const MuiInputWrapper = styled.div`
  & label {
    font-size: 14px;
  }
`;
