import { combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, catchError, filter } from 'rxjs/operators';
import { isActionOf, RootAction } from 'typesafe-actions';

import { updateRecordComment } from './actions';
import services from './services';

const updateRecordComment$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(updateRecordComment.request)),
    switchMap(({ payload: { onSuccess, ...rest } }) =>
      services.updateRecordComment$(rest).pipe(
        switchMap(() => {
          onSuccess && onSuccess();
          return of(updateRecordComment.success());
        }),
        catchError(err => of(updateRecordComment.failure(err)))
      )
    )
  );

export default combineEpics(updateRecordComment$);
