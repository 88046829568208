import { createAction } from 'typesafe-actions';

import { PersonFormData } from '@/modules/shared/components/PersonalInfoForm/types';

import { modulePath } from '../constants';

const moduleNamespace = modulePath.join('/');

export const updatePersonalInformation = createAction(
  `${moduleNamespace}/UPDATE_PERSONAL_INFORMATION`,
)<PersonFormData>();
