import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1em 0 2em 0;
`;

export const Title = styled.h3`
  color: ${colors.primaryColor};
  font-size: 18px;
  font-family: Roboto, serif;
  font-weight: bold;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
