import { differenceBy, differenceWith, isEqual, pick } from 'lodash';

export const encodeSessionDescription = (description: string): string =>
  encodeURIComponent(description).replace(/[!'()*]/g, escape);

type createToAddAndRemoveReturnType<T> = {
  toAdd: T[];
  toRemove: T[];
};

export function createToAddAndRemove<T>(
  newObjects: T[],
  currentObjects: T[],
  objectKey: keyof T,
  modifiedFields: Array<keyof T>,
): createToAddAndRemoveReturnType<T> {
  const toAdd = differenceWith(newObjects, currentObjects, (nextObject, prevObject) => {
    const fields = [objectKey, ...modifiedFields];
    return isEqual(pick(nextObject, fields), pick(prevObject, fields));
  });

  const toRemove = differenceBy(currentObjects, newObjects, objectKey);

  return {
    toAdd,
    toRemove,
  };
}
