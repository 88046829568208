export const moduleName = 'modals';

// these are the names of modals
export const ADD_COLUMNS_MODAL = `${moduleName}/ADD_COLUMNS_MODAL`;
export const ADD_DATA_BLOCK_MODAL = `${moduleName}/ADD_DATA_BLOCK_MODAL`;
export const ADD_SECONDARY_REGISTRANT_MODAL = `${moduleName}/ADD_SECONDARY_REGISTRANT_MODAL`;
export const APPROVE_APPLICANT_MODAL = `${moduleName}/APPROVE_APPLICANT_MODAL`;
export const UPDATE_ADDITIONAL_REGISTRANT_ACTIVITIES_MODAL = `${moduleName}/UPDATE_ADDITIONAL_REGISTRANT_ACTIVITIES_MODAL`;
export const CONFIRM_MODAL = `${moduleName}/CONFIRM_MODAL`;
export const CONFIRM_ATTENDEES_TRANSFER_MODAL_RC = `${moduleName}/CONFIRM_ATTENDEES_TRANSFER_MODAL_RC`;
export const EDIT_ATTENDEE_ACTIVITIES_MODAL = `${moduleName}/EDIT_ATTENDEE_ACTIVITIES_MODAL`;
export const EDIT_ATTENDEE_ADDONS_MODAL = `${moduleName}/EDIT_ATTENDEE_ADDONS_MODAL`;
export const INVITE_COLLABORATOR_MODAL = `${moduleName}/INVITE_COLLABORATOR_MODAL`;
export const ADD_ATTENDEE_MODAL = `${moduleName}/ADD_ATTENDEE_MODAL`;
export const LEAD_ADVISOR_INVITE_MODAL = `${moduleName}/LEAD_ADVISOR_INVITE_MODAL`;
export const LEAD_ADVISOR_DESIGNATE_MODAL = `${moduleName}/LEAD_ADVISOR_DESIGNATE_MODAL`;
export const LOGIN_MODAL = `${moduleName}/LOGIN_MODAL`;
export const MAKE_ADJUSTMENT_MODAL = `${moduleName}/MAKE_ADJUSTMENT_MODAL`;
export const MAKE_REFUND_MODAL = `${moduleName}/MAKE_REFUND_MODAL`;
export const ASSIGN_COLLABORATOR_ROLE_MODAL = `${moduleName}/ASSIGN_COLLABORATOR_ROLE_MODAL`;
export const CLONE_SESSION_MODAL = `${moduleName}/CLONE_SESSION_MODAL`;
export const IMPORT_CSV_FILE_MODAL = `${moduleName}/IMPORT_CSV_FILE_MODAL`;
export const SEND_CUSTOM_EMAIL_MODAL = `${moduleName}/SEND_CUSTOM_EMAIL_MODAL`;
export const CREATE_EDIT_PROGRAM_MODAL = `${moduleName}/CREATE_EDIT_PROGRAM_MODAL`;
export const MASQUERADE_MODAL = `${moduleName}/MASQUERADE_MODAL`;
export const ATTENDEE_JOB_OFFER_MODAL = `${moduleName}/ATTENDEE_JOB_OFFER_MODAL`;
export const ATTENDEE_JOB_ACCEPT_MODAL = `${moduleName}/ATTENDEE_JOB_ACCEPT_MODAL`;
export const ATTENDEE_COUNCIL_APPROVAL_MODAL = `${moduleName}/ATTENDEE_COUNCIL_APPROVAL_MODAL`;
export const ATTENDEE_DETAILS_UPDATE_JOB_PRIORITIES_MODAL = `${moduleName}/ATTENDEE_DETAILS_UPDATE_JOB_PRIORITIES_MODAL`;
export const CANCEL_INDIVIDUAL_RESERVATION_MODAL = `${moduleName}/CANCEL_INDIVIDUAL_RESERVATION_MODAL`;
export const ATTENDEES_ATTRIBUTES_MODAL = `${moduleName}/ATTENDEES_ATTRIBUTES_MODAL`;
export const MASS_RESEND_EMAIL_MODAL = `${moduleName}/MASS_RESEND_EMAIL_MODAL`;
export const VISIBILITY_LOGIC_MODAL = `${moduleName}/VISIBILITY_LOGIC_MODAL`;
export const CLONE_EVENT_MODAL = `${moduleName}/CLONE_EVENT_MODAL`;
export const REGISTRATION_TIME_OUT_MODAL = `${moduleName}/REGISTRATION_TIME_OUT_MODAL`;
export const RESERVATION_SIZE_EDIT_MODAL = `${moduleName}/RESERVATION_SIZE_EDIT_MODAL`;
export const JOIN_WAIT_LIST_MODAL = `${moduleName}/JOIN_WAIT_LIST_MODAL`;
export const CANCEL_GROUP_RESERVATION_MODAL = `${moduleName}/CANCEL_GROUP_RESERVATION_MODAL`;
export const SESSION_EXPIRED_MODAL = `${moduleName}/SESSION_EXPIRED_MODAL`;
export const TIMEZONE_CONFIRMATION_MODAL = `${moduleName}/TIMEZONE_CONFIRMATION_MODAL`;
export const TRANSFER_ATTENDEES_SELECT_TYPE_MODAL = `${moduleName}/TRANSFER_ATTENDEES_SELECT_TYPE_MODAL`;
export const TRANSFER_REGISTRATION_MODAL = `${moduleName}/TRANSFER_REGISTRATION_MODAL`;
export const REMOVE_ATTENDEE_MODAL = `${moduleName}/REMOVE_ATTENDEE_MODAL`;
export const UPDATE_RESERVATION_CONTACT_MODAL = `${moduleName}/UPDATE_RESERVATION_CONTACT_MODAL`;
export const ADD_DATABLOCK_ITEM_MODAL = `${moduleName}/ADD_DATABLOCK_ITEM_MODAL`;
export const SAVE_REPORT_MODAL = `${moduleName}/SAVE_REPORT_MODAL`;
export const UPDATE_PAYMENT_SCHEDULE_DUE_DATE_MODAL = `${moduleName}/UPDATE_PAYMENT_SCHEDULE_DUE_DATE_MODAL`;
export const UPDATE_REPORT_PERMISSIONS_MODAL = `${moduleName}/UPDATE_REPORT_PERMISSIONS_MODAL`;
export const UPDATE_REPORT_FILTERS_MODAL = `${moduleName}/UPDATE_REPORT_FILTERS_MODAL`;
export const CREATE_BROADCAST_MODAL = `${moduleName}/CREATE_BROADCAST_MODAL`;
export const CHANGE_RESERVATION_SESSION_MODAL = `${moduleName}/CHANGE_RESERVATION_SESSION_MODAL`;
export const UPDATE_COST_CENTER_VISIBILITY_MODAL = `${moduleName}/COST_CENTER_VISIBILITY_MODAL`;
export const EDIT_OPTIONS_IN_BULK_MODAL = `${moduleName}/EDIT_OPTIONS_IN_BULK_MODAL`;
