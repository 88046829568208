export const sections = {
  sessionSelected: {
    title: 'Session Selected',
    key: 'sessionSelected',
  },
  activities: {
    title: 'Activities',
    key: 'activities',
  },
  addOns: {
    title: 'Add-Ons',
    key: 'addOns',
  },
};
