import { createAsyncAction, ActionType } from 'typesafe-actions';

import { moduleName } from '../constants';
import { JoinedAddons } from '../types';

interface BaseSaveAddonsPayload {
  addons: JoinedAddons;
  formCode: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  finalActions?: ActionType<any>[];
}

interface IndividualSaveAddonsPayload extends BaseSaveAddonsPayload {
  isGroup: false;
  formRecordGUID: string;
}

interface GroupSaveAddonsPayload extends BaseSaveAddonsPayload {
  isGroup: true;
  groupReservationGUID: string;
}

export const saveSelectedAddonOptions = createAsyncAction(
  `${moduleName}/SAVE_SELECTED_ADDON_OPTIONS_REQUEST`,
  `${moduleName}/SAVE_SELECTED_ADDON_OPTIONS_SUCCESS`,
  `${moduleName}/SAVE_SELECTED_ADDON_OPTIONS_FAILURE`,
)<IndividualSaveAddonsPayload | GroupSaveAddonsPayload, undefined, Error>();
