import React, { Fragment } from 'react';

import { Title } from './GenericNotification.styled';

type Props = {
  title: string;
  content?: React.ReactNode;
};

const GenericNotification: React.FC<Props> = ({ title, content }) => (
  <Fragment>
    <Title level={5}>{title}</Title>
    {content}
  </Fragment>
);

export default GenericNotification;
