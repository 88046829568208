import { Card, Button } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { replace } from 'redux-first-router';
import Link from 'redux-first-router-link';

import { pathnameSel } from '@/modules/location/duck/selectors';

import styles from './NotFound.less';

const NotFound = () => {
  const pathname = useSelector(pathnameSel);

  useEffect(() => {
    if (pathname.includes('//')) {
      const newPathname = pathname.replace(/\/\//g, '/');
      replace(newPathname);
    }
  }, [pathname]);

  return (
    <Card title="404 - Page not found" className={styles.cardMargin}>
      <div className={styles.centerText}>
        <p className={styles.largeText}>We are sorry, the page you are looking for is not here.</p>
        <Link to="/events">
          <Button>Home</Button>
        </Link>
      </div>
    </Card>
  );
};

export default NotFound;
