import styled from 'styled-components';

import { appContentPadding } from '@/modules/core/components/App/constants';

export const LayoutComponent = styled.div`
  height: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  padding: ${appContentPadding};
  flex-grow: 1;
`;
