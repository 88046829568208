import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createPageDataSelector } from '@/modules/data/duck/selectors';

import { modulePath, stepDataParams } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulePath);

export const stepDataSel = createPageDataSelector(stepDataParams);

export const removeCollaboratorInProgressSel = createSelector(
  moduleSel,
  ({ removeCollaboratorInProgress }) => removeCollaboratorInProgress,
);

export const updatedRoleCodeSel = createSelector(
  moduleSel,
  ({ updatedRoleCode }) => updatedRoleCode,
);

export const updateRoleInProgressSel = createSelector(
  moduleSel,
  ({ updateRoleInProgress }) => updateRoleInProgress,
);

export const assignCollaboratorRoleInProgressSel = createSelector(
  moduleSel,
  ({ assignCollaboratorRoleInProgress }) => assignCollaboratorRoleInProgress,
);
