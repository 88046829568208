/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncAction, ActionType } from 'typesafe-actions';

import type { Person } from '@/modules/data/dataTypes/person';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';
import { LoginData } from '../types';

export const ROUTE_LOGIN = `${moduleName}/ROUTE_LOGIN`;
export const ROUTE_INVALID_MEMBERSHIP = `${moduleName}/ROUTE_INVALID_MEMBERSHIP`;

interface LoginBasePayload {
  preventPostLoginRedirection?: boolean;
}

export interface LoginWithMyScoutingPayload extends LoginBasePayload {
  login: string;
  password: string;
}

export interface LoginWithReservationCodePayload extends LoginBasePayload {
  lastName: string;
  reservationCode: string;
}

export const showSessionExpiredModal = createAction(`${moduleName}/SHOW_SESSION_EXPIRED_MODAL`)();

export const startSessionCheck = createAction(`${moduleName}/START_SESSION_CHECK`)();

export const navigateToLogin = createAction(ROUTE_LOGIN)();

export const singleSignOnRequest = createAction(`${moduleName}/SSO_REQUEST`)();

export const login = createAsyncAction(
  `${moduleName}/LOGIN_REQUEST`,
  `${moduleName}/LOGIN_SUCCESS`,
  `${moduleName}/LOGIN_FAILURE`,
)<LoginWithMyScoutingPayload | LoginWithReservationCodePayload, boolean | void, Error>();

export const saveLoginData = createAction(`${moduleName}/SAVE_LOGIN_DATA`)<LoginData>();

export const logout = createAsyncAction(
  `${moduleName}/LOGOUT_REQUEST`,
  `${moduleName}/LOGOUT_SUCCESS`,
  `${moduleName}/LOGOUT_FAILURE`,
)<boolean | void, boolean, ApiError>();

export const masquerade = createAsyncAction(
  `${moduleName}/MASQUERADE_REQUEST`,
  `${moduleName}/MASQUERADE_SUCCESS`,
  `${moduleName}/MASQUERADE_FAILURE`,
)<string, undefined, Error>();

export const appleLogin = createAction(`${moduleName}/APPLE_LOGIN`)<string>();

export const googleLogin = createAction(`${moduleName}/GOOGLE_LOGIN`)<string>();

export const savePersonData = createAsyncAction(
  `${moduleName}/SAVE_PERSON_DATA_REQUEST`,
  `${moduleName}/SAVE_PERSON_DATA_RESPONSE`,
  `${moduleName}/SAVE_PERSON_DATA_FAILURE`,
)<
  Partial<Person> & {
    finalActions?: ActionType<any>[];
  },
  undefined,
  ApiError
>();

export const updatePersonData = createAsyncAction(
  `${moduleName}/UPDATE_PERSON_DATA_REQUEST`,
  `${moduleName}/UPDATE_PERSON_DATA_SUCCESS`,
  `${moduleName}/UPDATE_PERSON_DATA_FAILURE`,
)<Partial<Person> & Pick<Person, 'personGUID'>, undefined, ApiError>();
