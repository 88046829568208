import React, { PropsWithChildren } from 'react';

import { LabelText, LabelWrapper } from './Label.styled';

type Props = {
  label: React.ReactNode;
  orientation?: 'horizontal' | 'vertical';
  className?: string;
  required?: boolean;
  useFormStyles?: boolean;
};

const Label: React.FC<PropsWithChildren<Props>> = ({
  children,
  label,
  className,
  orientation = 'vertical',
  required = false,
  useFormStyles = false,
}) => (
  <LabelWrapper $orientation={orientation} className={className}>
    <LabelText $required={required} $useFormStyles={useFormStyles}>
      {label}
    </LabelText>
    {children}
  </LabelWrapper>
);

export default Label;
