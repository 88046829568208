import { MailFilled } from '@ant-design/icons';
import { List } from 'antd';
import React from 'react';

import type { EmailListItem } from '@/modules/data/dataTypes/emailList';

import { manualEmailMethods } from '../../../../constants';

import { Container, EmailAvatar, EmailContent, TitleContainer } from './EmailItem.styled';

import { dayWithTime } from 'UTILS/dateFormats';

interface Props {
  emailLog: EmailListItem;
}

const EmailItem: React.FC<Props> = ({ emailLog }) => {
  const { emailTemplateName, sentDate, usedEmailAddress, scriptCode } = emailLog;

  return (
    <Container>
      <List.Item>
        <List.Item.Meta
          avatar={<EmailAvatar icon={<MailFilled />} size="large" shape="circle" />}
          title={
            <TitleContainer>
              <strong>{emailTemplateName}</strong>
              <span>
                From:{' '}
                {manualEmailMethods.includes(scriptCode) ? 'Collaborator' : 'Automated System'}
              </span>
              <span>Sent To: {usedEmailAddress}</span>
              <span>{dayWithTime(sentDate)}</span>
            </TitleContainer>
          }
        />
      </List.Item>
      <EmailContent>Email preview not available.</EmailContent>
    </Container>
  );
};

export default EmailItem;
