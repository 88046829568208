/* eslint-disable react/no-multi-comp */
import { Button } from 'antd';
import type { ColumnProps } from 'antd/lib/table';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservationListReport';
import ActionTable from '@/modules/shared/components/ActionTable';
import { usDate } from '@/modules/utils/dateFormats';

import { pageDataSel } from '../../duck/selectors';
import PeopleColumn from '../PeopleColumn';
import ReservationStatus from '../ReservationStatus';

import styles from './ReservationsTable.less';
import { OpenReservationButton } from './ReservationsTable.styled';

type Props = {
  allowRegistration: boolean;
  onFinishReservation: (reservation: GroupReservation) => void;
  onOpenReservation: (reservation: GroupReservation) => void;
};

const Reservations: React.FC<Props> = ({
  onFinishReservation,
  onOpenReservation,
  allowRegistration,
}) => {
  const {
    data: { form, reservationListReport: reservations },
  } = useSelector(pageDataSel);
  const { isCreator, isCollaborator } = form;

  const columns: ColumnProps<GroupReservation>[] = [
    {
      title: 'Expedition Number',
      dataIndex: 'expeditionNumber',
    },
    {
      title: 'Reservation Contact',
      key: 'reservationContactFullName',
      render: (_index, { reservationContactFullName }) => reservationContactFullName,
    },
    ...(isCreator || isCollaborator
      ? [
          {
            title: 'Session Date',
            dataIndex: 'startDate',
            render: (value: string) => value && usDate(value),
          },
        ]
      : []),
    {
      title: 'Crews',
      dataIndex: 'initialRosterCount',
    },
    {
      title: 'People',
      key: 'registrationCount',
      render: (_index, record) => <PeopleColumn record={record} />,
    },
    ...(isCollaborator || isCreator
      ? []
      : [
          {
            title: 'Total Amount Due',
            dataIndex: 'amountDue',
            render: (value: string) => <span>${value}</span>,
          },
          {
            title: 'Total Amount Paid',
            dataIndex: 'amountPaid',
            render: (value: string) => <span>${value}</span>,
          },
        ]),
    {
      title: 'Status',
      key: 'status',
      render: (_index, record) => <ReservationStatus reservation={record} />,
    },
    {
      key: 'openButton',
      title: '',
      // eslint-disable-next-line react/no-multi-comp
      render: (record: GroupReservation) => {
        const {
          isComplete,
          expeditionNumber,
          isReservationContact,
          isLeadAdvisor,
          isCancelled,
          isAdditionalReservationContact,
        } = record;
        if (
          !isCancelled &&
          (isReservationContact || isAdditionalReservationContact) &&
          (!isComplete || !expeditionNumber)
        ) {
          if (!allowRegistration) {
            return (
              <span className={styles.incompleteLabel}>This reservation was not finished.</span>
            );
          }

          return (
            <Button type="link" size="small" onClick={() => onFinishReservation(record)}>
              Finish Registration
            </Button>
          );
        }

        if (
          isCreator ||
          isCollaborator ||
          isLeadAdvisor ||
          isReservationContact ||
          isAdditionalReservationContact
        ) {
          return <OpenReservationButton onClick={() => onOpenReservation(record)} />;
        }
      },
    },
  ];

  const handleSelectRowClass = ({ isCancelled }: GroupReservation) => {
    if (isCancelled) {
      return cn(styles.row, styles.cancelled);
    }
    return cn(styles.row, styles.active);
  };

  return (
    <ActionTable
      allowSearch={false}
      pagination={false}
      bordered={false}
      id="reservationsTable"
      rowKey="groupReservationGUID"
      columns={columns}
      dataSource={reservations}
      subject="reservation"
      rowClassName={handleSelectRowClass}
    />
  );
};

export default Reservations;
