/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Modal } from 'antd';
import React from 'react';

import { payments } from '@/modules/config/config';
import toastService from '@/modules/toasts/service';

import { responseStatus, whatsThisDescriptions } from '../../constants';
const { chase } = payments;
const { host } = chase;

type Props = {
  onPaymentStart: () => void;
  onPaymentError: (error: string) => void;
  onPaymentCompleted: (uID: string, code: string) => void;
  onPaymentCanceled: () => void;
  uID: string;
};

class IframeCCForm extends React.Component<Props> {
  componentDidMount(): void {
    // The iframe requires this global functions as callbacks
    //@ts-ignore
    window.top.whatsThis = this.handleWhatsThis;
    //@ts-ignore
    window.top.handlePaymentErrors = this.handlePaymentErrors;
    //@ts-ignore
    window.top.completePayment = this.handleCompletePayment;
    //@ts-ignore
    window.top.startPayment = this.handleStartPayment;
    //@ts-ignore
    window.top.cancelPayment = this.handleCancelPayment;
  }

  handleWhatsThis = (target: 'cvv' | 'routing' | 'account'): void => {
    const content = whatsThisDescriptions[target];

    if (!content) {
      return;
    }

    Modal.info({
      title: 'What is this?',
      content,
    });
  };

  handlePaymentErrors = ({ errorCode }: { errorCode: string[] }): void => {
    const errorMessages = errorCode.map(code => responseStatus[code]).filter(message => message);

    errorMessages.forEach(message => {
      toastService.error(message);
    });

    if (errorMessages.length < errorCode.length) {
      toastService.error('Something went wrong');
    }

    this.props.onPaymentError('');
  };

  handleCancelPayment = (): void => {
    toastService.error('User canceled the payment');
    this.props.onPaymentCanceled();
  };

  handleCompletePayment = ({ code }: { code: string }): void => {
    const { uID, onPaymentCompleted } = this.props;
    onPaymentCompleted(uID, code);
  };

  handleStartPayment = (): void => {
    this.props.onPaymentStart();
  };

  render(): React.ReactElement {
    const { uID } = this.props;
    return (
      <iframe
        title="Chase CC Payment"
        src={`${host}${uID}`}
        style={{ display: 'block', position: 'initial', height: '70rem', width: '100%' }}
        allowFullScreen={false}
        frameBorder="0"
        height="70rem"
        width="100%"
      >
        Loading, Please DO NOT close this window
      </iframe>
    );
  }
}

export default IframeCCForm;
