import { Select, Form } from 'antd';
import React from 'react';

import { Person } from '@/modules/data/dataTypes/person';

import { getPositionName } from '../../../../../PersonData/utils';
import { Text } from '../../UnitAndCouncil.styled';
import { UNIT_FIELD } from '../../constants';

const { Option } = Select;

type Props = {
  positions: Person['positions'];
  initialValue?: string;
};

const OrganizationSelector: React.FC<Props> = ({ positions, initialValue }) => {
  switch (positions.length) {
    case 0: {
      return <Text id="noUnitText">No organization registration found</Text>;
    }
    case 1:
      return (
        <Form.Item name={UNIT_FIELD} initialValue={initialValue || positions[0].organizationGUID}>
          <Text id="yourUnitText">
            Unit Registration - <b>{getPositionName(positions[0])}</b>
          </Text>
        </Form.Item>
      );
    default:
      return (
        <Form.Item
          name={UNIT_FIELD}
          initialValue={initialValue}
          label="Multiple unit registrations found, please choose one"
          rules={[{ required: true, message: 'Please select a unit' }]}
        >
          <Select placeholder="Select Unit" showSearch optionFilterProp="children" size="large">
            {positions.map(p => (
              <Option key={p.organizationGUID}>{getPositionName(p)}</Option>
            ))}
          </Select>
        </Form.Item>
      );
  }
};

export default OrganizationSelector;
