import { Select as BsaSelect } from 'antd';
import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

const sharedStyles = css`
  width: 75px;
`;

export const Plug = styled.div`
  &&& {
    ${sharedStyles}
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 32px;
    padding-left: 11px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: ${colors.darkGrey};
    display: flex;
    align-items: center;
  }
`;

export const Select = styled(BsaSelect)`
  &.ant-select {
    .ant-select-selector {
      color: white;
      background-color: ${colors.lightBlue};
      border-color: ${colors.lightBlue};
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .ant-select-arrow {
      color: white;
    }
  }
`;
