import styled from 'styled-components';

import WarningComponent from '@/modules/shared/components/Warning';

export const Warning = styled(WarningComponent)`
  align-items: center;
  display: flex;
  font-size: 12px;
  height: 56px;
  margin: 0 0 8px 0;
  min-height: 24px;
  width: 100%;

  & .bsa-icon {
    align-self: center;
    margin: 8px;
  }
`;

export const WarningsContainer = styled.div`
  margin-bottom: 32px;
`;
