import { EditFilled } from '@ant-design/icons';
import React, { Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Desktop from '@/modules/responsiveSizes/Desktop';
import InfoBubble from '@/modules/shared/components/InfoBubble';
import InfoItem from '@/modules/shared/components/InfoItem';
import Section from '@/modules/shared/components/ReservationDetailsSection';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { openUpdateReservationSizeModal } from '@/pages/reservation/components/MyReservation/duck/actions';
import { pageDataSel } from '@/pages/reservation/duck/selectors';

interface Props {
  key: string;
  header: string;
}

const ReservationSizeSection: React.FC<Props> = ({ key, header, ...rest }) => {
  const dispatch = useDispatch();

  const {
    data: { groupReservation },
  } = useSelector(pageDataSel);

  const canEdit = useSelector(createAbilitiesSelector('groupReservation', 'updateReservationSize'));

  const {
    initialNumberOfAdults,
    initialNumberOfYouth,
    initialPeopleCount,
    groupTotalMemberCount,
    rosterCount,
  } = groupReservation;

  const headerButtons = useMemo(() => {
    if (canEdit) {
      const handleEdit = () => {
        dispatch(openUpdateReservationSizeModal(groupReservation));
      };
      return [
        {
          onClick: handleEdit,
          Icon: EditFilled,
          key: 'editReservationSize',
        },
      ];
    }
  }, [dispatch, canEdit, groupReservation]);

  return (
    <Section {...rest} key={key} header={header} headerButtons={headerButtons}>
      <InfoItem
        header="People in Reservation"
        info={
          <Fragment>
            {groupTotalMemberCount}/{initialPeopleCount}
            <Desktop>
              <InfoBubble>Actual People / Initial People Reserved</InfoBubble>
            </Desktop>
          </Fragment>
        }
      />
      <InfoItem
        header="Youth Expected"
        info={
          <Fragment>
            {initialNumberOfYouth}
            <Desktop>
              <InfoBubble>
                This is the number of youth provided during initial reservation, as an estimate
                prior to adding the actual roster attendees.
              </InfoBubble>
            </Desktop>
          </Fragment>
        }
      />
      <InfoItem
        header="Adults Expected"
        info={
          <Fragment>
            {initialNumberOfAdults}
            <Desktop>
              <InfoBubble>
                This is the number of adults provided during initial reservation, as an estimate
                prior to adding the actual roster attendees.
              </InfoBubble>
            </Desktop>
          </Fragment>
        }
      />
      <InfoItem header="Crews in Reservation" info={rosterCount} />
    </Section>
  );
};

export default ReservationSizeSection;
