import { Table } from 'antd';
import { Key } from 'antd/lib/table/interface';
import cn from 'classnames';
import React, { useMemo } from 'react';

import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import Currency from '@/modules/shared/components/Currency';

import { EnhancedPaymentCategory } from '../../../../ReservationPaymentsCard';
import styles from '../../../../ReservationPaymentsCard.less';
import { ROW_KEY } from '../../constants';
import DueDateColumn from '../DueDateColumn';
import StatusColumn from '../StatusColumn';

import NameColumn from './components/NameColumn';

const IS_FOOTER = 'isFooter';

type Props = {
  paymentCategories: EnhancedPaymentCategory[];
  totalDueAmount: number;
  selectedRowKeys: string[];
  onSetSelectedRowKeys: (keys: string[]) => void;
  onUpdatePaymentScheduleDueDate?: (paymentCategory: PaymentCategory) => void;
  allowPayments: boolean;
};

const PaymentScheduleTable: React.FC<Props> = ({
  paymentCategories,
  totalDueAmount,
  selectedRowKeys,
  onSetSelectedRowKeys,
  onUpdatePaymentScheduleDueDate,
  allowPayments,
}) => {
  const dataSource = useMemo(
    () => [
      ...paymentCategories,
      {
        paymentCategoryName: 'TOTAL DUE',
        dueAmount: totalDueAmount,
        [IS_FOOTER]: true,
      },
    ],
    [paymentCategories, totalDueAmount],
  );

  const columns = [
    {
      title: 'Installments',
      key: 'paymentCategoryName',
      render: NameColumn,
    },
    {
      title: 'Due Amount',
      key: 'dueAmount',
      render: (record: EnhancedPaymentCategory) => <Currency value={record.dueAmount} />,
    },
    {
      title: 'Due Date',
      key: 'dueDate',
      // eslint-disable-next-line react/no-multi-comp
      render: (record: EnhancedPaymentCategory) => (
        <DueDateColumn
          record={record}
          paymentsAllowed={allowPayments}
          onUpdatePaymentScheduleDueDate={onUpdatePaymentScheduleDueDate}
        />
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: StatusColumn,
    },
  ];

  const rowSelection = {
    onChange(nextSelectedRowKeys: Key[]) {
      onSetSelectedRowKeys(nextSelectedRowKeys as string[]);
    },
    getCheckboxProps(record: (typeof dataSource)[number]) {
      if (IS_FOOTER in record) {
        return {
          disabled: true,
          style: { display: 'none' },
        };
      }
      if (!allowPayments) {
        return {
          disabled: true,
        };
      }
      return {};
    },
    selectedRowKeys,
  };

  return (
    <Table
      rowSelection={allowPayments ? rowSelection : undefined}
      rowKey={ROW_KEY}
      columns={columns}
      dataSource={dataSource}
      rowClassName={record => cn({ [styles.tableFooterRow]: IS_FOOTER in record })}
      pagination={{
        position: ['bottomCenter'],
      }}
    />
  );
};

export default PaymentScheduleTable;
