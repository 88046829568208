import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { CDN_URL } from '@/modules/config/config';
import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';
import Divider from '@/modules/shared/components/Divider';

import {
  BSALogo,
  DividerContainer,
  MyScoutingButton,
  ReservationCodeButton,
  Subtitle,
} from './LoginOptions.styled';
import LoginWithMyScouting from './components/LoginWithMyScouting';
import LoginWithReservationCode from './components/LoginWithReservationCode';
import { LoginOptions as LoginOptionsEnum } from './constants';

type Props = {
  hideSignInWithCode: boolean;
};

const LoginOptions: React.FC<Props> = ({ hideSignInWithCode }) => {
  const screenSize = useGetScreenSize();
  const [selectedLoginOption, setSelectedLoginOption] = useState<LoginOptionsEnum | null>(
    hideSignInWithCode ? LoginOptionsEnum.myScouting : null,
  );
  const createSelectLoginOptionHandler = (loginOption: LoginOptionsEnum) => () => {
    setSelectedLoginOption(loginOption);
  };

  switch (selectedLoginOption) {
    case LoginOptionsEnum.myScouting:
      return (
        <LoginWithMyScouting
          hideChangeSignInOption={hideSignInWithCode}
          onSelectLoginOption={setSelectedLoginOption}
        />
      );
    case LoginOptionsEnum.reservationCode:
      return <LoginWithReservationCode onSelectLoginOption={setSelectedLoginOption} />;
  }

  return (
    <Fragment>
      <Subtitle level={2}>
        <FormattedMessage id="user.LoginPage.signInOptions" />
      </Subtitle>
      <MyScoutingButton
        size="large"
        type="primary"
        onClick={createSelectLoginOptionHandler(LoginOptionsEnum.myScouting)}
        $isMobile={screenSize === 'mobile'}
      >
        <BSALogo alt="" src={`${CDN_URL}FleurDeLis_90px.png`} />
        <FormattedMessage id="user.LoginPage.myScoutingOption" />
      </MyScoutingButton>
      <DividerContainer>
        <Divider />
        <span>or</span>
        <Divider />
      </DividerContainer>
      <ReservationCodeButton
        size="large"
        $isMobile={screenSize === 'mobile'}
        onClick={createSelectLoginOptionHandler(LoginOptionsEnum.reservationCode)}
      >
        <FormattedMessage id="user.LoginPage.reservationCodeOption" />
      </ReservationCodeButton>
    </Fragment>
  );
};

export default LoginOptions;
