import { createSelector } from 'reselect';
import { RootState, StateType } from 'typesafe-actions';

import { moduleName } from '../constants';

import moduleReducers from './reducers';

const moduleSel = (state: RootState): StateType<typeof moduleReducers> => state[moduleName];

export const makePaymentInProgressSel = createSelector(
  moduleSel,
  ({ makePaymentInProgress }) => makePaymentInProgress,
);

export const makeRefundInProgressSel = createSelector(
  moduleSel,
  ({ makeRefundInProgress }) => makeRefundInProgress,
);

const isErrorMakeRefundSel = createSelector(
  moduleSel,
  ({ isErrorMakeRefund }) => isErrorMakeRefund,
);

export const changePaymentStatusInProgressSel = createSelector(
  moduleSel,
  ({ changePaymentStatusInProgress }) => changePaymentStatusInProgress,
);

export const paymentErrorMessageSel = createSelector(
  [moduleSel, isErrorMakeRefundSel],
  ({ paymentErrorMessage }, isErrorMakeRefund) =>
    paymentErrorMessage || (isErrorMakeRefund ? 'Error making refund' : ''),
);

export const sessionIdSel = createSelector(
  moduleSel,
  ({ orbitalPaymentSessionIdentifiers }) => orbitalPaymentSessionIdentifiers.sessionId,
);

export const uIDSel = createSelector(
  moduleSel,
  ({ orbitalPaymentSessionIdentifiers }) => orbitalPaymentSessionIdentifiers.uID,
);
