import styled from 'styled-components';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  font-size: 25px;
  &:hover {
    cursor: pointer;
  }
`;
