import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { typeSel } from '@/modules/location/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { logout, ROUTE_LOGIN } from '../../duck/actions';

const SessionExpiredModal: React.FC = () => {
  const dispatch = useDispatch();
  const currentRoute = useSelector(typeSel);

  useEffect(
    // actually running on component cleanup due to the double arrow function
    () => () => {
      dispatch(logout.request(currentRoute !== ROUTE_LOGIN));
    },
  );

  function handleCloseModal() {
    dispatch(closeModal());
  }

  return (
    <ModalComponent
      title="Session Expired"
      buttons={[{ title: 'Close', onClick: handleCloseModal }]}
      description="Your session has been expired. Please Login again."
    />
  );
};

export default SessionExpiredModal;
