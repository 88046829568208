import { Button } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 0;
`;

export const Row = styled(HeaderRow)`
  font-size: 14px;
  color: #979797;
  text-transform: capitalize;
`;

export const RowBordered = styled(HeaderRow)`
  padding: 1.5em 0;
  border-top: 1px solid ${colors.lightGrey};
  border-bottom: 1px solid ${colors.lightGrey};
`;

export const SubmitButton = styled(Button)`
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;
