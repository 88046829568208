import { OptionSetItem } from '@/modules/data/dataTypes/optionSets';
import toastService from '@/modules/toasts/service';

const MAX_CAPACITY = 999999;

export const parseOptionsToString = (optionItems: OptionSetItem[]): string => {
  let inputString = '';

  optionItems.forEach(
    (item, index) =>
      (inputString +=
        `${item.itemLabel}-${item.capacity}` + (index + 1 === optionItems.length ? '' : '\n')),
  );

  return inputString;
};

export const parseInputStringToOptions = (
  inputString = '',
): Array<Partial<OptionSetItem>> | null => {
  const parsedRows: Partial<OptionSetItem>[] = [];

  if (!inputString.length) {
    return parsedRows;
  }

  const rows = inputString.split('\n');

  if (rows.length > 1000) {
    toastService.error('A maximum of 1000 rows is allowed');
    return null;
  }

  for (let i = 0; i < rows.length; i++) {
    const parsedRow = parseRow(rows[i]);

    if (!parsedRow) {
      toastService.error(`Invalid option format at row ${i + 1}`);
      return null;
    }

    const { capacity, label } = parsedRow;

    if (!label) {
      toastService.error(`Invalid label at row ${i + 1}`);
      return null;
    }

    if (capacity > MAX_CAPACITY) {
      toastService.error(`Max capacity is ${MAX_CAPACITY} (row ${i + 1})`);
      return null;
    }

    parsedRows.push({
      itemLabel: label,
      capacity,
    });
  }

  return parsedRows;
};

const parseRow = (rowString: string) => {
  const splittedString = rowString.split('-');

  if (splittedString.length !== 2) return null;

  const [label, capacity] = splittedString;
  const parsedCapacity = parseInt(capacity, 10);

  if (!/^\d+$/.test(capacity.trimEnd()) || isNaN(parsedCapacity)) return null;

  return {
    capacity: parsedCapacity,
    label,
  };
};
