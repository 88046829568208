import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { Job } from '@/modules/data/dataTypes/jobList';
import { Session } from '@/modules/data/dataTypes/sessionList';
import { createDataSel, createPageDataSelector } from '@/modules/data/duck/selectors';
import { payloadSel } from '@/modules/location/duck/selectors';

import { moduleName, pageDataParams, stepKeys, steps } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const pageDataSel = createPageDataSelector(pageDataParams);

export const isLoadingFetchingFormSel = createSelector(
  moduleSel,
  ({ isLoadingFetchingForm }) => isLoadingFetchingForm,
);

export const formRecordGuidSel = createSelector(moduleSel, ({ formRecordGUID }) => formRecordGUID);

export const currentGroupReservationGUIDSel = createSelector(
  moduleSel,
  ({ currentGroupReservationGUID }) => currentGroupReservationGUID,
);

export const groupInitialSettingsSel = createSelector(
  moduleSel,
  ({ groupReservationInitialSettings }) => groupReservationInitialSettings,
);
export const disabledStepsSel = createSelector(moduleSel, ({ disabledSteps }) => disabledSteps);

export const stepsSel = createSelector(
  [createDataSel('form'), createDataSel('jobList')],
  ({ allowGroupRegistration, allowSecondaryRegistration }, jobsList?: Job[]) =>
    steps.filter(({ key, isForGroup, isForGroupOnly }) => {
      if (isForGroupOnly && !allowGroupRegistration) return false;

      switch (key) {
        case stepKeys.jobPriorities:
          if (!jobsList?.length) return false;
          break;
        case stepKeys.addPerson:
          if (!allowSecondaryRegistration) return false;
      }

      if (allowGroupRegistration) {
        return isForGroup;
      }

      return true;
    }),
);

export const currentStepIndexSel = createSelector(moduleSel, ({ stepIndex }) => stepIndex.current);
const futureStepIndexSel = createSelector(moduleSel, ({ stepIndex }) => stepIndex.future);

export const futureStepKeySel = createSelector(
  [stepsSel, futureStepIndexSel],
  (stepsList, stepIndex) => stepsList[stepIndex].key,
);

export const currentStepKeySel = createSelector(
  [stepsSel, currentStepIndexSel],
  (stepsList, stepIndex) => stepsList[stepIndex].key,
);

export const prevStepNameSel = createSelector(
  [stepsSel, currentStepIndexSel],
  (stepList, stepIndex) => stepList[stepIndex - 1]?.title,
);

export const nextStepNameSel = createSelector(
  [stepsSel, currentStepIndexSel],
  (stepList, stepIndex) => stepList[stepIndex + 1]?.title,
);

export const stepIsLoadingSel = createSelector(moduleSel, ({ stepIsLoading }) => stepIsLoading);

export const registrationTimeLeftSel = createSelector(
  moduleSel,
  ({ registrationTimeLeft }) => registrationTimeLeft,
);

export const sessionNameFromLocationSel = createSelector(
  [createDataSel('sessionList'), payloadSel],
  (sessionList: Session[] | undefined, locationPayload) => {
    const sessionCode: string = get(locationPayload, 'sessionCode', '');

    return sessionList?.find(s => s.sessionCode === sessionCode)?.sessionName || '';
  },
);
