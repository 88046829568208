import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const GoBackWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.darkGrey};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  i {
    margin-right: 10px;
  }
`;
