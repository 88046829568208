import React, { Fragment } from 'react';

import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';

import Currency from '../../../Currency';

import { MakePaymentButton, ToPay, Amount } from './AmountToPay.styled';

type Props = {
  amountToPay: number;
  displayAmount: boolean;
  onMakePayment: () => void;
};

const AmountToPay: React.FC<Props> = ({ amountToPay, displayAmount, onMakePayment }) => {
  const screenSize = useGetScreenSize();
  const isMobile = screenSize === 'mobile';

  return (
    <Fragment>
      <ToPay>
        {displayAmount ? (
          <Fragment>
            To Pay:
            <Amount isMobile={isMobile}>
              <Currency value={amountToPay} />
            </Amount>
          </Fragment>
        ) : (
          'Please select installments to pay'
        )}
      </ToPay>
      {displayAmount && (
        <MakePaymentButton isMobile={isMobile} disabled={!amountToPay} onClick={onMakePayment}>
          SET CUSTOM AMOUNT
        </MakePaymentButton>
      )}
    </Fragment>
  );
};

export default AmountToPay;
