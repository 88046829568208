import apiService from '@/modules/utils/apiService';

import { LoginData } from '../types';

const userRoleStorageKey = 'TEMP_USER_ROLE';

const removeUserRole = (): void => localStorage.removeItem(userRoleStorageKey);

const loginWithMyScouting$ = (payload: { login: string; password: string }) =>
  apiService.post$<LoginData>(
    `/users/${payload.login}/authenticate`,
    { password: payload.password },
    {
      headers: {
        Accept: 'application/json; version = 2',
      },
    },
  );

const loginWithReservationCode$ = (payload: { reservationCode: string; lastName: string }) =>
  apiService.post$<LoginData>(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=CreateUserSessionByReservationCode',
    payload,
  );

const logout$ = () => apiService.get$('/users/logout');

const masqueradeUser$ = (memberId: string) =>
  apiService.get$<LoginData>(`/users/${memberId}/sessions/masquerade`, {
    headers: {
      Accept: 'application/json; version = 1',
    },
  });

const renewSession$ = (validateOnly: boolean) =>
  apiService.get$(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=RenewSession&validateOnly=${validateOnly}`,
  );

const selfSessionValidation$ = (headers = {}) =>
  apiService.get$<LoginData>('/users/self_eac774ca-6566-4439-8304-f3f137cda8b9/sessions/current', {
    headers: {
      Accept: 'application/json; version = 1',
      ...headers,
    },
  });

export default {
  login$: loginWithMyScouting$,
  loginWithReservationCode$,
  logout$,
  masqueradeUser$,
  selfSessionValidation$,
  removeUserRole,
  renewSession$,
};
