import React from 'react';

import EditableFormSection from '@/modules/shared/components/EditableFormSection';
import { useAbilities } from '@/modules/user/duck/abilities';
import { Abilities } from '@/modules/user/types';

import { useHandleEdit } from '../../hooks';

import AttributesPicker from './components/AttributesPicker';
import { moduleName } from './constants';

type Props = {
  editingSectionName: string | null;
  canEditAttendeeDetails: boolean;
  onEdit: (sectionName: string | null) => void;
};

const Attributes: React.FC<Props> = ({ canEditAttendeeDetails, editingSectionName, onEdit }) => {
  const abilities: Abilities = useAbilities();
  const { handleStartEdit, handleStopEdit, isEditing } = useHandleEdit(
    moduleName,
    editingSectionName,
    onEdit,
  );

  const isAllowedToUpdateAttributes = abilities.can({
    action: 'edit',
    target: 'attendee.attributes',
  });

  return (
    <EditableFormSection
      title="Attributes"
      isEditing={isEditing}
      onEditClick={handleStartEdit}
      onCancel={handleStopEdit}
      hideSaveButton
      canEdit={isAllowedToUpdateAttributes}
    >
      <AttributesPicker
        canEditAttributes={isAllowedToUpdateAttributes}
        canEditAttendeeDetails={canEditAttendeeDetails}
        isEditing={isEditing}
      />
    </EditableFormSection>
  );
};

export default Attributes;
