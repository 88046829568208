import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createDataSel } from '@/modules/data/duck/selectors';

import { modulesPath } from '../constants';

import moduleReducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof moduleReducers> => get(state, modulesPath);

export const selectedAttendeesIdsSel = createSelector(
  moduleSel,
  ({ selectedAttendees }) => selectedAttendees,
);

const attendeesByPersonGuidSel = createSelector(
  [createDataSel('rosterAttendees')],
  (rosterAttendees = []) =>
    rosterAttendees.reduce(
      (acc, attendee) => ({ ...acc, [attendee.personGUID]: attendee }),
      {} as Record<string, (typeof rosterAttendees)[number]>,
    ),
);

export const selectedAttendeesByPersonGuidSel = createSelector(
  [selectedAttendeesIdsSel, attendeesByPersonGuidSel],
  (personGUIDs, attendeesObj = {}) => [...personGUIDs].map(personGUID => attendeesObj[personGUID]),
);

export const removeAttendeesInProgressSel = createSelector(
  [moduleSel],
  ({ removeAttendeesInProgress }) => removeAttendeesInProgress,
);

export const transferAttendeesInProgressSel = createSelector(
  moduleSel,
  ({ transferAttendeesInProgress }) => transferAttendeesInProgress,
);
