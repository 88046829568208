import { createPageDataParams } from '@/modules/data/utils';
import { PaymentMethod, paymentMethodsData } from '@/modules/payments/constants';

import { moduleName as parentModuleName } from '../../constants';
export const moduleName = 'paymentSchedule';

export const modulesPath = [parentModuleName, moduleName];

export const stepDataParams = createPageDataParams(
  { dataType: 'paymentCategories' },
  { dataType: 'formPartsData' },
  { dataType: 'optionSets' },
);

export const WITH_RESERVATION = 1;
export const DAYS_BEFORE_RESERVATION = 2;
export const SPECIFIC_DATE = 3;

export const installmentTypes = [
  {
    value: WITH_RESERVATION,
    text: 'With Reservation',
    depositOnly: true,
  },
  {
    value: DAYS_BEFORE_RESERVATION,
    text: 'Days before reservation starts',
    depositOnly: false,
  },
  {
    value: SPECIFIC_DATE,
    text: 'Specific Date',
    depositOnly: false,
  },
];

export const dayCountValues = [1, 7, 15, 30, 45, 60, 90];

export const paymentOptions = [
  {
    label: paymentMethodsData.get(PaymentMethod.creditCard)?.label,
    value: PaymentMethod.creditCard,
  },
  {
    label: paymentMethodsData.get(PaymentMethod.eCheckOrbital)?.label,
    value: PaymentMethod.eCheckOrbital,
  },
  {
    label: paymentMethodsData.get(PaymentMethod.costCenter)?.label,
    description:
      'When enabled, registrants will have the option to input a cost center to be charged, instead of submitting payment.',
    value: PaymentMethod.costCenter,
  },
];

export const defaultMethods = [PaymentMethod.creditCard, PaymentMethod.eCheckOrbital];
