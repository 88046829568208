/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isEqual, isNumber } from 'lodash';
import { createSelector, createStructuredSelector, Selector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, moduleName } from '../constants';
import { DataTypes, ExtraData, PluralDataTypes } from '../dataTypes';
import { createPageDataParams, getKey } from '../utils';

import moduleReducers from './reducers';

const paginationEnabledDataTypes = new Set<keyof PluralDataTypes>([
  'attendeeList',
  'attendeeListReport',
  'disclaimerList',
  'formDisclaimers',
  'sessionList',
  'formPaymentData',
  'comments',
  'multiSessionCapacityRuleList',
  'personFormList',
  'queryFilters',
  'reservationList',
  'reservationListReport',
  'rosterAttendees',
  'rosterList',
]);

type ModuleData = ReturnType<typeof moduleReducers>;

type Fields =
  | 'data'
  | 'isLoading'
  | 'queryObjects'
  | 'error'
  | 'extraData'
  | 'pageNumber'
  | 'pageSize'
  | 'recordCount'
  | 'filters'
  | 'refreshScheduled'
  | 'fetchSkipped';

const moduleSel = (state: RootState): ModuleData => state[moduleName];

const makeCreateSel = <F extends Fields, V>(field: F, defaultValue?: V) => {
  const rootSelector = createSelector(moduleSel, moduleState => moduleState[field]);
  const selectors = {};

  return <D extends keyof DataTypes>(
    dataType: D,
    dataId = '',
  ): Selector<RootState, ModuleData[F][D]> => {
    type selectorsType = Record<string, typeof selector>;

    const key = getKey({ dataType, dataId }) as D;
    if ((selectors as selectorsType)[key]) return (selectors as selectorsType)[key];

    const selector = createSelector(rootSelector, data => {
      const value = data && data[key];
      if (defaultValue === undefined) return value;
      return value || defaultValue;
    });
    (selectors as selectorsType)[key] = selector;
    return selector;
  };
};

export const createDataSel = makeCreateSel('data');
export const createIsLoadingSel = makeCreateSel('isLoading', false);
export const createErrorSel = makeCreateSel('error', false);
export const createExtraDataSel = makeCreateSel('extraData') as <D extends keyof ExtraData>(
  dataType: D,
  dataId?: string,
) => Selector<RootState, ExtraData[D]>;

const createQueryObjSel = makeCreateSel('queryObjects');
const createNeedsRefreshSel = makeCreateSel('refreshScheduled', false);
const createFetchSkippedSel = makeCreateSel('fetchSkipped', false);
const createPageNumberSel = makeCreateSel('pageNumber');
const createPageSizeSel = makeCreateSel('pageSize');
const createPrevNextFiltersSel = makeCreateSel('filters');
export const createRecordCountSel = makeCreateSel('recordCount');

const makeCreatePaginationSel = () => {
  const selectors = {};

  return (dataType: keyof PluralDataTypes, dataId = '') => {
    type selectorsType = Record<string, typeof selector>;

    const key = getKey({ dataType, dataId });
    if ((selectors as selectorsType)[key]) return (selectors as selectorsType)[key];

    const selector = createSelector(
      [
        createRecordCountSel(dataType, dataId),
        createPageNumberSel(dataType, dataId),
        createPageSizeSel(dataType, dataId),
      ],
      (recordCount, pageNumbers = {}, pageSizes = {}) => {
        if (!paginationEnabledDataTypes.has(dataType)) return null;
        const pageNumber = 'next' in pageNumbers ? pageNumbers.next : pageNumbers.prev;
        const pageSize = 'next' in pageSizes ? pageSizes.next : pageSizes.prev;
        return {
          pageNumber: isNumber(pageNumber) ? pageNumber : DEFAULT_PAGE_NUMBER,
          pageSize: isNumber(pageSize) ? pageSize : DEFAULT_PAGE_SIZE,
          recordCount,
        };
      },
    );
    (selectors as selectorsType)[key] = selector;
    return selector;
  };
};

const makeCreateFiltersSel = () => {
  const selectors = {};

  return (dataType: keyof DataTypes, dataId = '') => {
    type selectorsType = Record<string, typeof selector>;

    const key = getKey({ dataType, dataId });
    if ((selectors as selectorsType)[key]) return (selectors as selectorsType)[key];

    const prevNextFiltersSel = createPrevNextFiltersSel(dataType, dataId);
    const selector = createSelector(prevNextFiltersSel, filters => {
      if (!filters) return {};
      if ('next' in filters) return filters.next || {};
      return filters.prev || {};
    });
    (selectors as selectorsType)[key] = selector;
    return selector;
  };
};

const createQueryObjChangedSel = (dataType: keyof DataTypes, dataId = '') =>
  createSelector([createQueryObjSel(dataType, dataId)], queryObjects => {
    if (queryObjects && 'next' in queryObjects) {
      // we need these conversions to escape false comparisons of null and undefined
      const prevQueryObjects = queryObjects.prev || null;
      const nextQueryObjects = queryObjects.next || {};

      if (!isEqual(prevQueryObjects, nextQueryObjects)) return true;
    }
    return false;
  });

const createPageNumberChangedSel = (dataType: keyof DataTypes, dataId = '') =>
  createSelector([createPageNumberSel(dataType, dataId)], pageNumbers => {
    if (pageNumbers && 'next' in pageNumbers) {
      const prevPageNumber = pageNumbers.prev || 0;
      const nextPageNumber = pageNumbers.next || 0;

      if (prevPageNumber !== nextPageNumber) return true;
    }

    return false;
  });

const createPageSizeChangedSel = (dataType: keyof DataTypes, dataId = '') =>
  createSelector([createPageSizeSel(dataType, dataId)], pageSizes => {
    if (pageSizes && 'next' in pageSizes) {
      const prevPageSize = pageSizes.prev;
      const nextPageSize = pageSizes.next;

      if (prevPageSize !== nextPageSize) return true;
    }
    return false;
  });

const createFiltersChangedSel = (dataType: keyof DataTypes, dataId = '') =>
  createSelector([createPrevNextFiltersSel(dataType, dataId)], filters => {
    if (filters && 'next' in filters) {
      const prevFilters = filters.prev || null;
      const nextFilters = filters.next || null;

      if (!isEqual(prevFilters, nextFilters)) return true;
    }

    return false;
  });

const makeCreateQueryParamsChangedSel = () => {
  const selectors = {};

  return (dataType: keyof DataTypes, dataId = '') => {
    type selectorsType = Record<string, typeof selector>;

    const key = getKey({ dataType, dataId });
    if ((selectors as selectorsType)[key]) return (selectors as selectorsType)[key];

    const selector = createSelector(
      [
        createQueryObjChangedSel(dataType, dataId),
        createPageNumberChangedSel(dataType, dataId),
        createPageSizeChangedSel(dataType, dataId),
        createFiltersChangedSel(dataType, dataId),
      ],
      (queryObjChanged, pageNumberChanged, pageSizeChanged, filtersChanged) =>
        queryObjChanged || pageNumberChanged || pageSizeChanged || filtersChanged,
    );
    (selectors as selectorsType)[key] = selector;
    return selector;
  };
};

const makeCreateQueryParamsSel = () => {
  const selectors = {};

  return (dataType: keyof DataTypes, dataId = '') => {
    type selectorsType = Record<string, typeof selector>;

    const key = getKey({ dataType, dataId });
    if ((selectors as selectorsType)[key]) return (selectors as selectorsType)[key];

    const selector = createSelector(
      [
        createPaginationSel(dataType as keyof PluralDataTypes, dataId),
        createFiltersSel(dataType, dataId),
        createQueryObjSel(dataType, dataId),
      ],
      (pagination, filters, queryObjects = {}) => ({
        ...('next' in queryObjects ? queryObjects.next : queryObjects.prev),
        ...filters,
        ...(!pagination
          ? {}
          : {
              pageNumber: pagination.pageNumber,
              recordCount: pagination.pageSize,
            }),
      }),
    );

    (selectors as selectorsType)[key] = selector;
    return selector;
  };
};

export const createPaginationSel = makeCreatePaginationSel();
export const createFiltersSel = makeCreateFiltersSel();
export const createQueryParamsSel = makeCreateQueryParamsSel();
const createQueryParamsChangedSel = makeCreateQueryParamsChangedSel();

type DataSelectorsObj<T extends keyof DataTypes> = {
  [D in keyof Pick<DataTypes, T>]: Selector<RootState, DataTypes[D]>;
};
type ExtraDataSelectorsObj<T extends keyof DataTypes> = {
  [D in keyof Pick<DataTypes, T>]: Selector<
    RootState,
    D extends keyof ExtraData ? ExtraData[D] : null
  >;
};
type IsLoadingSelectorsObj<T extends keyof DataTypes> = {
  [D in keyof Pick<DataTypes, T>]: Selector<RootState, boolean>;
};
type PaginationSelectorsObj<T extends keyof DataTypes> = {
  [D in keyof Pick<DataTypes, T>]: Selector<
    RootState,
    { pageNumber: number; pageSize: number; recordCount: number } | undefined
  >;
};

export const createPageDataSelector = <T extends ReturnType<typeof createPageDataParams>>(
  params: T,
) =>
  createStructuredSelector({
    data: createStructuredSelector(
      params.reduce(
        (acc, { dataType, dataId }) => ({ ...acc, [dataType]: createDataSel(dataType, dataId) }),
        {} as DataSelectorsObj<T[number]['dataType']>,
      ),
    ),
    extraData: createStructuredSelector(
      params.reduce(
        (acc, { dataType, dataId }) => ({
          ...acc,
          [dataType]: createExtraDataSel(dataType as keyof ExtraData, dataId),
        }),
        {} as ExtraDataSelectorsObj<T[number]['dataType']>,
      ),
    ),
    isLoading: createStructuredSelector(
      params.reduce(
        (acc, { dataType, dataId }) => ({
          ...acc,
          [dataType]: createIsLoadingSel(dataType, dataId),
        }),
        {} as IsLoadingSelectorsObj<T[number]['dataType']>,
      ),
    ),
    pagination: createStructuredSelector(
      params.reduce(
        (acc, { dataType, dataId }) => {
          if (!paginationEnabledDataTypes.has(dataType as keyof PluralDataTypes)) return acc;
          return {
            ...acc,
            [dataType]: createPaginationSel(dataType as keyof PluralDataTypes, dataId),
          };
        },
        {} as PaginationSelectorsObj<T[number]['dataType']>,
      ),
    ),
  });

const makeCreateFetchCancelledSel = () => {
  const selectors = {};

  return (dataType: keyof DataTypes, dataId?: string) => {
    type Selectors = Record<string, typeof selector>;
    const key = getKey({ dataType, dataId });
    if ((selectors as Selectors)[key]) return (selectors as Selectors)[key];

    const selector = createSelector(
      [
        createQueryObjSel(dataType, dataId),
        createNeedsRefreshSel(dataType, dataId),
        createFetchSkippedSel(dataType, dataId),
        createQueryParamsChangedSel(dataType, dataId),
      ],
      (queryObj, refreshNeeded, fetchSkipped, queryParamsChanged) => {
        if (!queryObj) return true;
        if (refreshNeeded) return false;
        return fetchSkipped || !queryParamsChanged;
      },
    );

    (selectors as Selectors)[key] = selector;
    return selector;
  };
};

export const createFetchCancelledSel = makeCreateFetchCancelledSel();
