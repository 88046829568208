import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createDataSel, createPageDataSelector } from '@/modules/data/duck/selectors';
import { routeSel } from '@/modules/routing/duck/selectors';

import { moduleName, pageDataParams, steps } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const pageDataSel = createPageDataSelector(pageDataParams);

export const createFormInProgressSel = createSelector(
  moduleSel,
  ({ createFormInProgress }) => createFormInProgress,
);

export const currentStepIndexSel = createSelector(routeSel, currentRoute =>
  steps.findIndex(({ key, subSteps }) => {
    if (key === currentRoute) return true;
    if (!subSteps) return false;
    return subSteps.some(({ key: subStepKey }) => subStepKey === currentRoute);
  }),
);

// ANY of the attendee types is staff
export const isStaffEventSel = createSelector(
  [createDataSel('attendeeTypeList')],
  (attendeeTypes = []) => attendeeTypes.some(({ isStaff }) => isStaff),
);
