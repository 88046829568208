import { List, Row, Tabs as AntTabs } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const ListItem = styled(List.Item)`
  position: relative;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
`;

export const ListItemMeta = styled(List.Item.Meta)`
  .ant-list-item-meta-title {
    display: flex;
    align-items: center;
    color: ${colors.darkGrey};
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
  }
`;

export const ContentTitle = styled.span`
  color: ${colors.primaryColor};
  font-weight: bold;
`;

export const ContentData = styled.span`
  color: ${colors.darkGrey};
`;

export const TotalAmountRow = styled(Row)`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

export const Tabs = styled(AntTabs)`
  width: 100%;
  background-color: ${colors.lighterGrey};

  .ant-tabs-nav {
    margin: 0;
  }
`;
