import styled from 'styled-components';

export const SelectWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  label {
    z-index: 1;
  }

  .ant-select {
    flex: 1;
  }

  & label,
  & .ant-form .selectInputMaterial label {
    font-size: 14px;
    font-weight: normal;
    position: absolute !important;
    pointer-events: none;
    top: -7px;
    left: 5px;
    transition: 0.2s ease all;
    background: #ffffff;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    color: #003f87;
  }
`;
