import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import { PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { reservationPaymentCategoriesSel } from '@/modules/entities/Reservations/duck/selectors';
import {
  isEADSel,
  isEventAdministratorSel,
  isSystemAdministratorSel,
} from '@/modules/user/duck/selectors';

import { AllowedMethods, installmentMethods, PaymentMethod } from '../constants';

export const useIsMultipleInstallmentsPaymentAllowed = (
  params: {
    amountToPay: number;
    paymentMethod: PaymentMethod | null;
    form: FormSettings;
    paymentCategories: PaymentCategory[];
    paymentSummary: PaymentSummary;
  } & (
    | { isGroup: true; groupReservation: GroupReservation }
    | { isGroup: false; attendeeDetails: AttendeeDetails }
  ),
): boolean => {
  const { form, isGroup, amountToPay, paymentMethod } = params;
  const reservationDetails = isGroup ? params.groupReservation : params.attendeeDetails;
  const isEADEnabled = useSelector((state: RootState) => isEADSel(state, reservationDetails));
  const isEventAdministrator = useSelector(isEventAdministratorSel);
  const isSystemAdministrator = useSelector(isSystemAdministratorSel);
  const installments = useSelector((state: RootState) =>
    reservationPaymentCategoriesSel(state, params),
  );

  const { isCreator } = form;

  const isAdmin = isCreator || isEADEnabled || isEventAdministrator || isSystemAdministrator;
  const currentInstallmentIndex = installments.findIndex(installment => installment.dueAmount);
  const currentInstallment =
    currentInstallmentIndex > -1 ? installments[currentInstallmentIndex] : undefined;
  const nextInstallment = installments[currentInstallmentIndex + 1];

  if (
    isAdmin ||
    !currentInstallment ||
    amountToPay <= currentInstallment.dueAmount ||
    !nextInstallment ||
    !paymentMethod ||
    !installmentMethods.includes(paymentMethod)
  )
    return true;

  if (!nextInstallment.allowedMethods.includes(paymentMethod as AllowedMethods)) return false;

  return true;
};
