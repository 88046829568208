import { Spin } from 'antd';
import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import L from '../L';

import styles from './InputWrapper.less';
import { Props } from './types';

const InputMaterial: React.FC<PropsWithChildren<Props>> = ({
  children,
  labelContent,
  labelSize,
  labelColored,
  labelInlineBlock,
  labelClassName,
  wrapperClassName = '',
  // type,
  contentAfter,
  contentBefore,
  loading = false,
  // hideSpinner,
  className,
  flexWrapper,
  leftPadding,
  rightPadding,
  id,
  // addonBefore,
  // addonAfter,
  // required,
  // ...rest
}) => (
  <div
    id={id}
    className={cn(styles.inputMaterial, className, {
      [wrapperClassName]: wrapperClassName,
      [styles.flexWrapper]: flexWrapper,
      [styles.leftPadding]: leftPadding,
      [styles.rightPadding]: rightPadding,
    })}
  >
    {/* {addonBefore} */}
    <Spin spinning={loading} size="small" className={styles.spinner}>
      {contentBefore && <span className={styles.contentBefore}>{contentBefore}</span>}
      {children}
      {labelContent && (
        <L
          className={cn(labelClassName)}
          size={labelSize}
          colored={labelColored}
          inlineBlock={labelInlineBlock}
        >
          {labelContent}
        </L>
      )}
      {contentAfter && <span className={styles.contentAfter}>{contentAfter}</span>}
    </Spin>
  </div>
);

export default InputMaterial;
