import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import Label from '@/modules/shared/components/Label';
import TagSelector from '@/modules/shared/components/TagSelector';

import { assignCollaboratorRole } from '../../duck/actions';
import { assignCollaboratorRoleInProgressSel } from '../../duck/selectors';

import { AssignRolesWrapper } from './AssignCollaboratorRole.styled';
import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const AssignCollaboratorRole: React.FC<Props> = ({ modalParams }) => {
  const { personGUID, memberID, lastName } = modalParams;
  const dispatch = useDispatch();
  const collaborators = useSelector(createDataSel('collaboratorList'));
  const roles = useSelector(createDataSel('formRolesList'));
  const assignRolesInProgress = useSelector(assignCollaboratorRoleInProgressSel);

  const currentCollaboratorRoleCodes = useMemo(() => {
    const currentCollaborator = collaborators.find(c => c.personGUID === personGUID);
    return currentCollaborator?.roles.map(({ roleCode }) => roleCode) || [];
  }, [collaborators, personGUID]);

  const [assigned, setAssigned] = useState<string[]>(currentCollaboratorRoleCodes);

  function handleSave() {
    dispatch(assignCollaboratorRole.request({ personGUID, roleCodes: assigned }));
  }

  const buttons = [
    {
      title: 'Save',
      onClick: handleSave,
    },
  ];

  return (
    <ModalComponent title="Edit Collaborator" buttons={buttons} inProgress={assignRolesInProgress}>
      <AssignRolesWrapper>
        <Label label="Member Id" useFormStyles>
          <b>{memberID}</b>
        </Label>
        <Label label="Last Name" useFormStyles>
          <b>{lastName}</b>
        </Label>
        <Label label="Assigned Roles" useFormStyles>
          <TagSelector
            dataSource={roles}
            valueField="roleCode"
            labelField="roleName"
            value={assigned}
            onChange={setAssigned}
          />
        </Label>
      </AssignRolesWrapper>
    </ModalComponent>
  );
};

export default AssignCollaboratorRole;
