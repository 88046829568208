import apiService from '@/modules/utils/apiService';

interface SendCollaboratorInvitePayload {
  formCode: string;
  lastName: string;
  personGUID: string;
}

export interface RemoveCollaboratorPayload {
  formCode: string;
  isGranted: false;
  personGUID: string;
}

const findPerson$ = (memberId: string, lastName: string) =>
  apiService.get$<{ payload: { personGUID: string }[] }>(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=FindPerson&memberId=${memberId}&lastName=${lastName}`,
  );

const sendCollaboratorInvite$ = (payload: SendCollaboratorInvitePayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=CreateUserContext',
    payload,
  );

const removeCollaborator$ = (payload: RemoveCollaboratorPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateFormPerson',
    payload,
  );

const updateRolePerson$ = (payload: {
  roleCodeList: string[];
  personGUID: string;
  isGranted: boolean;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateRolePerson',
    payload,
  );

const deleteRole$ = (payload: { roleCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteRole',
    payload,
  );

export default {
  findPerson$,
  sendCollaboratorInvite$,
  removeCollaborator$,
  updateRolePerson$,
  deleteRole$,
};
