import { MdEdit } from 'react-icons/md';
import styled from 'styled-components';

export const DueDateWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const EditIcon = styled(MdEdit)`
  margin-left: 3px;
  cursor: pointer;
`;
