import styled from 'styled-components';

export const EventCardComponent = styled.div<{ $isMobile: boolean; $isUpdating: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in;
  margin: ${({ $isMobile }) => ($isMobile ? '15px 0 0 0' : '8px 8px 15px 8px')};
  position: relative;
  display: inline-flex;
  height: 512px;
  width: 375px;

  :hover {
    transform: scale(1.005);
    box-shadow: 4px 4px 6px 1px rgba(0, 0, 0, 0.3);
  }

  ${({ $isUpdating }) => ($isUpdating ? 'justify-content: center;' : '')}
`;
