import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Title = styled.span`
  font-size: 16px;
  color: ${colors.scoutingBlue};
  font-weight: bold;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;
