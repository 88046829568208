import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import EventCover from '@/modules/shared/components/EventCover';
import Stepper from '@/modules/shared/components/Stepper';

import { currentStepIndexSel, disabledStepsSel, stepsSel } from '../../duck/selectors';

const FormHeader: React.FC = () => {
  const { formName, eventLocation, eventStartDate, eventCloseDate } = useSelector(
    createDataSel('form'),
  );
  const steps = useSelector(stepsSel);
  const stepIndex = useSelector(currentStepIndexSel);
  const disabledSteps = useSelector(disabledStepsSel);
  return (
    <Fragment>
      <EventCover
        title={formName}
        location={eventLocation}
        dates={[eventStartDate, eventCloseDate]}
      />
      <Stepper steps={steps} stepIndex={stepIndex} disabledSteps={disabledSteps} />
    </Fragment>
  );
};

export default FormHeader;
