import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { moduleName } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const fileDataSel = createSelector(moduleSel, ({ fileData }) => fileData);

export const columnNamesSel = createSelector(moduleSel, ({ columnNames }) => columnNames);

export const mappedColumnsSel = createSelector(moduleSel, ({ mappedColumns }) => mappedColumns);

export const mappedAnswersSel = createSelector(moduleSel, ({ mappedAnswers }) => mappedAnswers);

export const missingRecordsSel = createSelector(moduleSel, ({ missingRecords }) => missingRecords);

export const importAnswersInProgressSel = createSelector(
  moduleSel,
  ({ importAnswersInProgress }) => importAnswersInProgress,
);
