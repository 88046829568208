/* eslint-disable react/no-danger */
import { CardProps } from 'antd/lib/card';
import DOMPurify from 'dompurify';
import React, { useMemo } from 'react';

import { Card, Container, Description, Header } from './CardWithHeader.styled';

interface Props extends CardProps {
  header?: React.ReactNode;
  description?: React.ReactNode;
  contentClassName?: string;
  className?: string;
  headerClassName?: string;
  descriptionClassName?: string;
  loading?: boolean;
}

const CardWithHeader: React.FC<Props> = ({
  header,
  children,
  contentClassName,
  className,
  headerClassName,
  description,
  descriptionClassName,
  ...rest
}) => {
  const descriptionObj = useMemo(() => {
    switch (typeof description) {
      case 'string':
        return (
          <Description
            className={descriptionClassName}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
          />
        );
      case 'undefined':
        return null;
      default:
        return <Description className={descriptionClassName}>{description}</Description>;
    }
  }, [description, descriptionClassName]);

  return (
    <Container className={className}>
      {header && <Header className={headerClassName}>{header}</Header>}
      {descriptionObj}
      <Card className={contentClassName} {...rest}>
        {children}
      </Card>
    </Container>
  );
};

export default CardWithHeader;
