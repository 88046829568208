import { RadioChangeEvent } from 'antd';
import React, { Fragment, useRef, useState } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { Person } from '@/modules/data/dataTypes/person';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import FindPersonForm from '@/modules/shared/components/FindPersonForm';
import { useFoundPerson } from '@/modules/shared/components/FindPersonForm/hooks';
import PersonData from '@/modules/shared/components/PersonData';
import toastService from '@/modules/toasts/service';

import { transferRegistration } from '../../duck/actions';
import { transferRegistrationInProgressSel } from '../../duck/selectors';

import { DisclaimersMessageWrapper, Radio } from './TransferRegistrationModal.styled';
import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const TransferRegistrationModal: React.FC<Props> = ({ modalParams: { attendeeDetails } }) => {
  const { firstName, lastName, formRecordGUID, formCode } = attendeeDetails;
  const dispatch = useDispatch();
  const renderFountPersonRef = useRef(null);
  const personDataRef = useRef<{ handleSubmit: () => Promise<Partial<Person>> }>(null);
  const transferRegistrationInProgress = useSelector(transferRegistrationInProgressSel);
  const foundPerson = useFoundPerson();
  const [personExists, setPersonExists] = useState(true);

  const handleSetPersonExists = (e: RadioChangeEvent) => {
    setPersonExists(e.target.value);
  };

  function handleSubmit() {
    if (personExists) {
      if (!foundPerson) {
        toastService.error('No person was found');
        return null;
      }
      if (foundPerson.personGUID === attendeeDetails.personGUID) {
        toastService.error('Current registration already belongs to selected person');
        return null;
      }
      dispatch(
        transferRegistration.request({
          personExists,
          formCode,
          formRecordGUID,
          newPersonGUID: foundPerson.personGUID,
        }),
      );
    } else {
      personDataRef.current?.handleSubmit().then(personData => {
        dispatch(
          transferRegistration.request({
            personExists,
            formCode,
            formRecordGUID,
            personData,
          }),
        );
      });
    }
  }

  return (
    <ModalComponent
      title="Transfer Registration"
      description={`Choose a member to transfer ${firstName} ${lastName}'s reservation`}
      inProgress={transferRegistrationInProgress}
      buttons={[
        {
          title: 'Transfer Registration',
          type: 'primary',
          onClick: handleSubmit,
        },
      ]}
    >
      <Radio.Group value={personExists} onChange={handleSetPersonExists}>
        <Radio value={true}>Search for Registrant</Radio>
        <Radio value={false}>Create Registrant</Radio>
      </Radio.Group>
      {personExists ? (
        <Fragment>
          <FindPersonForm formLayout="inline" renderFoundPersonRef={renderFountPersonRef} />
          <div ref={renderFountPersonRef} />
        </Fragment>
      ) : (
        <PersonData ref={personDataRef} />
      )}
      <DisclaimersMessageWrapper>
        <AiFillInfoCircle />
        Disclaimers will be re-sent to the new registrant
      </DisclaimersMessageWrapper>
    </ModalComponent>
  );
};

export default TransferRegistrationModal;
