import colors from '@/modules/styles/colors';

export const moduleName = 'jobs';

export enum JobStatusCode {
  JobPending = 'job_pending',
  JobOffered = 'job_offered',
  JobAttendeeDeclined = 'job_attendee_declined',
  JobCollaboratorDeclined = 'job_collaborator_declined',
  JobAttendeeAccepted = 'job_attendee_accepted',
  JobOfferTimedOut = 'job_offer_timed_out',
}

export enum JobPeriodCode {
  FirstHalf = 'first_half',
  SecondHalf = 'second_half',
  FullEvent = 'full_event',
}

export const JOB_PERIODS: {
  code: JobPeriodCode;
  name: string;
}[] = [
  { code: JobPeriodCode.FirstHalf, name: 'First Half' },
  { code: JobPeriodCode.SecondHalf, name: 'Second Half' },
  { code: JobPeriodCode.FullEvent, name: 'Full Event' },
];

// rename object with status code

export const jobStatus = Object.freeze({
  job_pending: {
    code: JobStatusCode.JobPending,
    name: 'Job Pending',
    color: colors.yellowStatus,
  },
  job_offered: {
    code: JobStatusCode.JobOffered,
    name: 'Job Offered',
    color: colors.yellowStatus,
  },
  job_attendee_declined: {
    code: JobStatusCode.JobAttendeeDeclined,
    name: 'Job Attendee Declined',
    color: colors.redStatus,
  },
  job_collaborator_declined: {
    code: JobStatusCode.JobCollaboratorDeclined,
    name: 'Job Collaborator Declined',
    color: colors.redStatus,
  },
  job_attendee_accepted: {
    code: JobStatusCode.JobAttendeeAccepted,
    name: 'Job Attendee Accepted',
    color: colors.greenStatus,
  },
  job_offer_timed_out: {
    code: JobStatusCode.JobOfferTimedOut,
    name: 'Job Timed Out',
    color: colors.redStatus,
  },
});
