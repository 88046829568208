import { ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Attendee } from '@/modules/data/dataTypes/rosterAttendees';
import { RosterDetails } from '@/modules/data/dataTypes/rosterDetails';
import { Roster } from '@/modules/data/dataTypes/rosterList';
import { REMOVE_ATTENDEE_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';

import { initiateAttendeesTransfer } from '../../../../duck/actions';
import CustomActionBar from '../../../CustomActionBar';

import TotalAttendees from './components/TotalAttendees';

type Props = {
  roster: Roster & RosterDetails;
  selectedAttendees: Attendee[];
  canEditParticipant: boolean;
  netMaxCapacity: number;
  canTransferAttendees: boolean;
};

const AttendeesCustomActionBar: React.FC<Props> = ({
  roster,
  selectedAttendees = [],
  canEditParticipant,
  netMaxCapacity,
  canTransferAttendees,
}) => {
  const dispatch = useDispatch();

  const { attendeeCount, attendeeTypes, minimumCapacity } = roster;
  const selectedAttendeesAmount = selectedAttendees.length;

  const notEnoughAttendeeTypeNames = attendeeTypes
    .filter(({ numberOfAttendees, minCapacity }) => numberOfAttendees < minCapacity)
    .map(({ typeName }) => typeName);

  const buttons = useMemo(() => {
    function handleTransferAttendees() {
      dispatch(initiateAttendeesTransfer());
    }
    function removeAttendee() {
      dispatch(openModal(REMOVE_ATTENDEE_MODAL));
    }
    return [
      ...(canTransferAttendees
        ? [
            {
              title: 'Transfer Participant(s)',
              onClick: handleTransferAttendees,
              disabled: !selectedAttendeesAmount,
              icon: <ArrowRightOutlined />,
            },
          ]
        : []),
      ...(canEditParticipant
        ? [
            {
              title: 'Remove Participant(s)',
              disabled: !selectedAttendeesAmount,
              onClick: removeAttendee,
              icon: <DeleteOutlined />,
            },
          ]
        : []),
    ];
  }, [selectedAttendeesAmount, canEditParticipant, canTransferAttendees, dispatch]);

  return (
    <CustomActionBar
      buttons={buttons}
      selectedAttendeesAmount={selectedAttendeesAmount}
      contentRight={
        <TotalAttendees
          maximumCapacity={netMaxCapacity}
          minimumCapacity={+minimumCapacity}
          numberOfAttendees={attendeeCount}
          notEnoughAttendeeTypeNames={notEnoughAttendeeTypeNames}
        />
      }
    />
  );
};

export default AttendeesCustomActionBar;
