import React, { Fragment } from 'react';

import type { Addon } from '@/modules/data/dataTypes/paymentSummary';
import Currency from '@/modules/shared/components/Currency';
import Divider from '@/modules/shared/components/Divider';

import Row from '../Row';

type Props = {
  addons: Omit<Addon, 'formRecordAddonGUID' | 'groupReservationAddonGUID'>[];
  subtotal: number;
  initialRosterCount?: number;
};

const Addons: React.FC<Props> = ({ addons, subtotal, initialRosterCount = 1 }) => {
  if (!addons.length) return null;

  return (
    <Fragment>
      <Row columns={['Add-ons']} isBlue />
      {addons.map(
        (
          { addonCode, addonName, selectedOptionFee = 0, selectedOptionName, isAppliedToRoster },
          index,
        ) =>
          isAppliedToRoster ? (
            <Row
              key={`${addonCode}_${index}`}
              columns={[
                `${selectedOptionName} ${addonName} per crew`,
                initialRosterCount,
                <Currency key="amount" value={selectedOptionFee} />,
              ]}
            />
          ) : (
            <Row
              key={`${addonCode}_${index}`}
              columns={[
                `${selectedOptionName} ${addonName}`,
                <Currency key="amount" value={selectedOptionFee} />,
              ]}
            />
          ),
      )}
      <Divider />
      <Row
        columns={['Add-ons Subtotal', <Currency key="amount" value={subtotal} />]}
        extraBottomPadding
      />
    </Fragment>
  );
};

Addons.defaultProps = {
  addons: [],
};

export default Addons;
