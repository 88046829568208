import { EditFilled } from '@ant-design/icons';
import { CollapsePanelProps } from 'antd/lib/collapse';
import React, { Fragment, useMemo } from 'react';

import { FormRecordAddon } from '@/modules/data/dataTypes/formRecordAddons';
import { CURRENCY_FORMAT } from '@/modules/utils/numberFormats';

import InfoItem from '../InfoItem';
import Section from '../ReservationDetailsSection';

type Props = CollapsePanelProps & {
  addons?: Pick<
    FormRecordAddon,
    'addonCode' | 'addonName' | 'selectedOptionName' | 'selectedOptionFee' | 'positionNumber'
  >[];
  onEditAddons?: () => void;
};

const ReservationAddons: React.FC<Props> = ({ addons = [], onEditAddons, ...rest }) => {
  const sortedAddons = useMemo(
    () => addons.slice().sort((a, b) => a.positionNumber - b.positionNumber),
    [addons],
  );

  return (
    <Section
      {...rest}
      headerButtons={[
        ...(onEditAddons
          ? [
              {
                Icon: EditFilled,
                onClick: onEditAddons,
                key: 'editAddons',
              },
            ]
          : []),
      ]}
    >
      {sortedAddons.length ? (
        <Fragment>
          {sortedAddons.map(({ addonCode, addonName, selectedOptionName, selectedOptionFee }) => (
            <InfoItem
              key={addonCode}
              header={addonName}
              info={`${selectedOptionName} (${CURRENCY_FORMAT(selectedOptionFee)})`}
            />
          ))}
        </Fragment>
      ) : (
        'No addons selected'
      )}
    </Section>
  );
};

export default ReservationAddons;
