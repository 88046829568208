import { combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, filter, catchError } from 'rxjs/operators';
import { isActionOf, RootAction } from 'typesafe-actions';

import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { updateFormRecord } from './actions';
import services from './services';

const updateFormRecord$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(updateFormRecord.request)),
    switchMap(({ payload: { finalActions = [], successMessage, ...rest } }) =>
      services.updateFormRecord$(rest).pipe(
        switchMap(({ responseValue }) => {
          const message = successMessage
            ? successMessage
            : `Registration ${rest.formRecordGUID ? 'updated' : 'created'} successfully`;
          toastService.success(message);
          return of(updateFormRecord.success(responseValue as string), ...finalActions);
        }),
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(updateFormRecord.failure(error));
        }),
      ),
    ),
  );

export default combineEpics(updateFormRecord$);
