import { combineEpics, Epic } from 'redux-observable';
import { concat, defer, EMPTY, of, zip, merge } from 'rxjs';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { isActionOf, RootAction } from 'typesafe-actions';

import { refreshData, removeData, scheduleRefresh } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { urlFormCodeSel } from '@/modules/location/duck/selectors';
import {
  CONFIRM_ATTENDEES_TRANSFER_MODAL_RC,
  TRANSFER_ATTENDEES_SELECT_TYPE_MODAL,
} from '@/modules/modals/constants';
import { closeModal, openModal } from '@/modules/modals/duck/actions';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { rosterSel } from '../../../duck/selectors';
import { ModalParams as TransferAttendeesSelectTypeModalParams } from '../components/TransferAttendeesSelectTypeModal/types';
import { ROSTERS_DATA_ID_TRANSFER_ATTENDEES } from '../constants';

import {
  removeAttendees,
  dropSelectedAttendees,
  initiateAttendeesTransfer,
  transferAttendees,
} from './actions';
import { selectedAttendeesByPersonGuidSel, selectedAttendeesIdsSel } from './selectors';
import services, { RemoveAttendeePayload } from './services';

const removeRosterAttendees$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeAttendees.request)),
    switchMap(() => {
      const state = state$.value;
      const formCode = urlFormCodeSel(state);
      const attendeesPersonGuids = selectedAttendeesIdsSel(state) || [];
      const roster = rosterSel(state);
      const { rosterCode, sessionCode } = roster || {};
      const rosterAttendees = createDataSel('rosterAttendees')(state) || [];

      const attendeesToRemove: RemoveAttendeePayload[] = [...attendeesPersonGuids].reduce(
        (acc: RemoveAttendeePayload[], attendeePersonGuid: string) => {
          const { formRecordGUID } =
            rosterAttendees.find(member => attendeePersonGuid === member.personGUID) || {};

          if (!formRecordGUID || !rosterCode || !sessionCode) return acc;

          return [
            ...acc,
            {
              formCode,
              formRecordGUID,
              rosterCode,
              sessionCode,
            },
          ];
        },
        [],
      );

      if (!attendeesToRemove.length) {
        toastService.error("Can't remove selected attendee(s)");
        return of(removeAttendees.failure(undefined), closeModal());
      }

      return zip(
        ...attendeesToRemove.map(attendeePayload =>
          services.removeRosterAttendee$(attendeePayload),
        ),
      ).pipe(
        switchMap(() => {
          toastService.success('Attendee(s) removed successfully');
          return of(
            removeAttendees.success(),
            dropSelectedAttendees(),
            refreshData({ dataType: 'rosterDetails' }),
            refreshData({ dataType: 'rosterAttendees' }),
            refreshData({ dataType: 'groupReservation' }),
            refreshData({ dataType: 'groupReservationRosterList' }),
            closeModal(),
          );
        }),
      );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(removeAttendees.failure(error), closeModal()), caught);
    }),
  );

const initiateTransferAttendees$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(initiateAttendeesTransfer)),
    switchMap(() => {
      const state = state$.value;
      const { isCreator, isCollaborator, formCode } = createDataSel('form')(state);
      const currentRoster = rosterSel(state);
      const { groupReservationGUID } = createDataSel('groupReservation')(state);

      return concat(
        prefetchData$(action$, {
          dataType: 'rosterList',
          dataId: ROSTERS_DATA_ID_TRANSFER_ATTENDEES,
          queryObj: {
            formCode,
            isCancelled: false,
            ...(isCreator || isCollaborator ? {} : { groupReservationGUID }),
          },
        }),
        defer(() => {
          const nextState = state$.value;
          const rosters = createDataSel(
            'rosterList',
            ROSTERS_DATA_ID_TRANSFER_ATTENDEES,
          )(nextState);
          const rostersWithoutCurrent = rosters.filter(
            ({ rosterCode }) => rosterCode !== currentRoster?.rosterCode,
          );

          if (!rostersWithoutCurrent || !rostersWithoutCurrent.length) {
            toastService.error('Current reservation has no other rosters');
            return of(
              removeData({ dataType: 'rosterList', dataId: ROSTERS_DATA_ID_TRANSFER_ATTENDEES }),
            );
          }

          if (isCreator || isCollaborator) return EMPTY;
          return of(openModal(CONFIRM_ATTENDEES_TRANSFER_MODAL_RC));
        }),
      ).pipe(catchError(() => EMPTY));
    }),
  );

const transferAttendees$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(transferAttendees.request)),
    switchMap(({ payload: { roster: destinationRoster, selectedAttendeeTypes } }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      const selectedAttendees = selectedAttendeesByPersonGuidSel(state);
      const currentReservation = createDataSel('groupReservation')(state);
      const sourceRoster = rosterSel(state);
      return services
        .transferAttendees$({
          formCode,
          sourceGroupReservationGUID: currentReservation.groupReservationGUID,
          sourceSessionCode: sourceRoster?.sessionCode as string,
          sourceRosterCode: sourceRoster?.rosterCode as string,
          destinationGroupReservationGUID: destinationRoster.groupReservationGUID,
          destinationRosterCode: destinationRoster.rosterCode,
          destinationSessionCode: destinationRoster.sessionCode,
          members: selectedAttendees.map(({ memberId, personGUID, attendeeTypeCode }) => ({
            personGUID,
            memberID: memberId,
            destinationTypeCode:
              (selectedAttendeeTypes && selectedAttendeeTypes[memberId]) || attendeeTypeCode,
          })),
        })
        .pipe(
          switchMap(() =>
            of(
              transferAttendees.success(),
              dropSelectedAttendees(),
              removeData({ dataType: 'rosterList', dataId: ROSTERS_DATA_ID_TRANSFER_ATTENDEES }),
              refreshData({ dataType: 'groupReservationRosterList' }),
              refreshData({ dataType: 'rosterDetails' }),
              refreshData({ dataType: 'rosterAttendees' }),
              scheduleRefresh({ dataType: 'groupReservation' }),
              closeModal(),
            ),
          ),
          catchError((error: ApiError) =>
            concat(
              of(transferAttendees.failure(error)),
              defer(() => {
                if (error.responseCode === '-16') {
                  const modalParams: TransferAttendeesSelectTypeModalParams = { destinationRoster };
                  return of(openModal(TRANSFER_ATTENDEES_SELECT_TYPE_MODAL, modalParams));
                }

                toastService.error(error.message);
                return EMPTY;
              }),
            ),
          ),
        );
    }),
  );

export default combineEpics(initiateTransferAttendees$, transferAttendees$, removeRosterAttendees$);
