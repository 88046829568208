import React from 'react';

import { CDN_URL } from '@/modules/config/config';

import { Dates, EventCoverWrapper, Title, TitleWrapper } from './EventCover.styled';

import fleurDeLis from '@/assets/images/fleur-de-lis-logo.png';
import { formattedDatesRange } from 'UTILS/dateFormats';

type Props = {
  title: string;
  dates?: [string, string];
  location?: string;
};

const EventCover: React.FC<Props> = ({ title, location, dates }) => {
  const slim = !!location || dates?.length === 2;

  return (
    <EventCoverWrapper isSlim={slim}>
      <picture>
        <source media="(min-width: 650px)" srcSet={fleurDeLis} />
        <source media="(min-width: 465px)" srcSet={fleurDeLis} />
        <img src={`${CDN_URL}fleur-de-lis-logo.png`} alt="title" />
      </picture>
      <TitleWrapper>
        <Title isSlim={slim}>{title}</Title>
        {slim && dates && (
          <Dates>
            {formattedDatesRange(...dates)} {location && `| ${location}`}
          </Dates>
        )}
      </TitleWrapper>
    </EventCoverWrapper>
  );
};

EventCover.defaultProps = {
  title: '',
  location: '',
};

export default EventCover;
