import { Typography } from 'antd';
import styled from 'styled-components';

export const MyScoutingRequiredMessage = styled(Typography.Text)`
  font-size: 1.5rem;
`;

export const MyScoutingRequiredMessageContainer = styled.div`
  margin: 32px 0 16px;
  text-align: center;
  width: 100%;
`;
