import React, { PropsWithChildren } from 'react';

import { useGetScreenSize } from './hooks';

const Mobile: React.FC<PropsWithChildren> = ({ children }) => {
  const screenSize = useGetScreenSize();

  if (screenSize === 'mobile' && children) return children as React.ReactElement;
  return null;
};

export default Mobile;
