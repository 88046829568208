import cn from 'classnames';
import React from 'react';

import styles from './S.less';

type Props = {
  id?: string;
  size: '1' | '2' | '3' | '4' | '5' | '6';
  hr?: boolean;
  semibold?: boolean;
  bold?: boolean;
  colored?: boolean;
  colon?: boolean;
  inline?: boolean;
  ellipsis?: boolean;
  inlineBlock?: boolean;
  serif?: boolean;
  className?: string;
  children: React.ReactNode;
};

const S: React.FC<Props> = ({
  size,
  hr,
  semibold,
  bold,
  colored,
  colon,
  inline,
  inlineBlock,
  serif,
  className,
  children,
  ellipsis,
  ...rest
}) => {
  const sizeClassName = `subtitle-${size}`;
  const classNames = cn(
    sizeClassName,
    styles.block,
    {
      'text-primary': colored,
      [styles.hr]: hr,
      [styles.semibold]: semibold,
      [styles.bold]: bold,
      [styles.inline]: inline,
      [styles.inlineBlock]: inlineBlock,
      [styles.serif]: serif,
      [styles.ellipsis]: ellipsis,
    },
    className,
  );

  return (
    <span className={classNames} {...rest}>
      {children}
      {colon && ':'}
    </span>
  );
};

S.defaultProps = {
  hr: false,
  semibold: false,
  bold: false,
  colored: false,
  colon: false,
  inline: false,
  inlineBlock: false,
  serif: false,
};

export default S;
