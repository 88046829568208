import { Col, FormInstance, Form } from 'antd';
import { get } from 'lodash';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { queryFilterVariablesObjSel } from '@/modules/reports/duck/selectors';
import { OperatorValues, ReportDataType } from '@/modules/reports/types';

import {
  FILTER_EXPRESSION_COLUMN_NAME,
  FILTER_EXPRESSION_OPERATOR,
  FILTER_EXPRESSION_VALUE,
} from '../../../../constants';
import { Path } from '../../../../types';

import { Row } from './Expression.styled';
import SelectColumn from './components/SelectColumn';
import SelectCondition from './components/SelectCondition';
import SelectOperator from './components/SelectOperator';
import SelectValue from './components/SelectValue';
import { distanceBetweenInputs } from './constants';

type Props = {
  name: number;
  form: FormInstance;
  path: Path;
  dataType: ReportDataType;
};

const Expression: React.FC<Props> = ({ name, path, form, dataType }) => {
  const queryFilterVariablesObj = useSelector((state: RootState) =>
    queryFilterVariablesObjSel(state, { dataType }),
  );

  const columnFieldPath = [...path, FILTER_EXPRESSION_COLUMN_NAME];
  const operatorFieldPath = [...path, FILTER_EXPRESSION_OPERATOR];
  const valueFieldPath = [...path, FILTER_EXPRESSION_VALUE];

  return (
    <Row gutter={[distanceBetweenInputs, 0]} align="top">
      <Col span={8}>
        <Row>
          <Col span={6}>
            <SelectCondition name={name} />
          </Col>
          <Col span={18}>
            <SelectColumn name={name} dataType={dataType} />
          </Col>
        </Row>
      </Col>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, nextValues) => {
          const prevColumn: string | undefined = get(prevValues, columnFieldPath);
          const nextColumn: string | undefined = get(nextValues, columnFieldPath);

          const prevOperator: string | undefined = get(prevValues, operatorFieldPath);
          const nextOperator: string | undefined = get(nextValues, operatorFieldPath);

          const columnChanged = prevColumn !== nextColumn;
          const operatorChanged = prevOperator !== nextOperator;
          return columnChanged || operatorChanged;
        }}
      >
        {({ getFieldValue }) => {
          const selectedColumn: string | undefined = getFieldValue(columnFieldPath);
          const selectedOperator: OperatorValues | undefined = getFieldValue(operatorFieldPath);

          const queryFilterVariable =
            typeof selectedColumn === 'string'
              ? queryFilterVariablesObj[selectedColumn]
              : undefined;

          return (
            <Fragment>
              <Col span={8}>
                <SelectOperator
                  name={name}
                  queryFilterVariable={queryFilterVariable}
                  form={form}
                  fieldPath={operatorFieldPath}
                />
              </Col>
              <Col span={8}>
                <SelectValue
                  name={name}
                  queryFilterVariable={queryFilterVariable}
                  selectedOperator={selectedOperator}
                  form={form}
                  fieldPath={valueFieldPath}
                />
              </Col>
            </Fragment>
          );
        }}
      </Form.Item>
    </Row>
  );
};

export default Expression;
