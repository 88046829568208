import { Col, Row, Button, Select } from 'antd';
import { Dayjs } from 'dayjs';
import { omit, uniq, without, sortBy, isString } from 'lodash';
import React, { useState, useMemo, Fragment, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootAction, RootState } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import {
  removeFilter,
  resetFilters,
  setFilter,
  updatePageNumber,
} from '@/modules/data/duck/actions';
import {
  createDataSel,
  createExtraDataSel,
  createFiltersSel,
  createIsLoadingSel,
  createPaginationSel,
} from '@/modules/data/duck/selectors';
import { JOIN_WAIT_LIST_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import Divider from '@/modules/shared/components/Divider';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import toastService from '@/modules/toasts/service';
import { dateFromString, dateToString } from '@/modules/utils/dateFormats';
import { safeDecodeURIComponent } from '@/modules/utils/stringFunctions';

import ButtonsRow from '../../components/ButtonsRow';

import {
  ProgramsSelectedTitle,
  CenteredContainer,
  ResetButton,
  SearchIcon,
  ArrowIcon,
  ProgramSelect,
  ProgramTag,
  Input,
  SessionsDatePicker,
  SelectedProgramsRow,
  Pagination,
} from './SessionPriorities.styled';
import { ModalParams as JoinWaitlistModalParams } from './components/JoinWaitlistModal/types';
import PeopleAmount from './components/PeopleAmount';
import Priority from './components/Priority';
import {
  FILTER_FIELD_EARLIEST_START_DATE,
  FILTER_FIELD_LATEST_START_DATE,
  FILTER_FIELD_PROGRAM_CODE_LIST,
  FILTER_FIELD_SESSION_NAME,
} from './constants';
import {
  updateReservationSessions,
  setGroupInitialSettings,
  getGroupSessionFee,
} from './duck/actions';
import { groupFeesSel, loadingSessionFeesSel, updateSessionsInProgressSel } from './duck/selectors';

import CardWithHeader from 'SHARED/components/CardWithHeader';

function swapArray<T>(arr: T[], a: number, b: number): T[] {
  const tmp = arr.slice();
  const aux = tmp[a];

  tmp[a] = tmp[b];
  tmp[b] = aux;

  return tmp;
}

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SessionPriorities: React.FC<Props> = ({
  onPageChange,
  onUpdateReservationSessions,
  onFilterSet,
  onFilterRemove,
  onFiltersReset,
  setGroupSettings,
  getGroupTotalFee,
  isLoadingFees,
  programs,
  openJoinWaitlistModal,
  sessionsSummary,
}) => {
  const { allowGroupRegistration, isSessionPrioritizationEnabled, isWaitlistEnabled } = useSelector(
    createDataSel('form'),
  );
  const attendeeDetails: AttendeeDetails | undefined = useSelector(
    createDataSel('attendeeDetails'),
  );
  const groupReservation: GroupReservation | undefined = useSelector(
    createDataSel('groupReservation'),
  );
  const multiSessionRuleList = useSelector(createDataSel('multiSessionCapacityRuleList'));
  const updateSessionsInProgress = useSelector(updateSessionsInProgressSel);
  const groupFees = useSelector(groupFeesSel);
  const sessionList = useSelector(createDataSel('sessionList'));
  const sessionListLoading = useSelector(createIsLoadingSel('sessionList'));
  const pagination = useSelector(createPaginationSel('sessionList'));

  const sessionListExtraData = useSelector(createExtraDataSel('sessionList'));

  const sessionListFilters: {
    [FILTER_FIELD_EARLIEST_START_DATE]: string | null;
    [FILTER_FIELD_LATEST_START_DATE]: string | null;
    [FILTER_FIELD_SESSION_NAME]: string;
    [FILTER_FIELD_PROGRAM_CODE_LIST]: string;
  } = useSelector(createFiltersSel('sessionList'));

  const {
    sessionCode: originalSessionCode,
    initialRosterCount,
    initialNumberOfAdults: initAdults = 0,
    initialNumberOfYouth: initYouth = 0,
  } = groupReservation || {};
  const { pageNumber, pageSize, recordCount } = pagination || {};
  const initialShowSessions =
    !allowGroupRegistration ||
    (originalSessionCode && Number(initYouth || 0) + Number(initAdults || 0) > 0);

  const [numberOfYouth, setNumberOfYouth] = useState<number | undefined>(initYouth || 0);
  const [numberOfAdults, setNumberOfAdults] = useState<number | undefined>(initAdults || 0);
  const [showSessions, setShowSessions] = useState(initialShowSessions);
  const registeredPeople = Number(numberOfYouth || 0) + Number(numberOfAdults || 0);

  const {
    [FILTER_FIELD_EARLIEST_START_DATE]: earliestStartDateFilter,
    [FILTER_FIELD_LATEST_START_DATE]: latestStartDateFilter,
    [FILTER_FIELD_PROGRAM_CODE_LIST]: programCodeListFilter,
  } = sessionListFilters;

  const earliestStartDate = useMemo(() => {
    const date = dateFromString(earliestStartDateFilter || sessionListExtraData.earliestStartDate);
    if (date.isValid()) return date;
  }, [earliestStartDateFilter, sessionListExtraData]);

  const latestStartDate = useMemo(() => {
    const date = dateFromString(latestStartDateFilter || sessionListExtraData.latestStartDate);
    if (date.isValid()) return date;
  }, [latestStartDateFilter, sessionListExtraData]);

  const selectedProgramsArr = useMemo(() => {
    if (isString(programCodeListFilter)) return programCodeListFilter.split(',');
    return [];
  }, [programCodeListFilter]);

  const [priorities, setPriorities] = useState(() => {
    const selectedSessionCode = allowGroupRegistration
      ? groupReservation?.sessionCode
      : attendeeDetails?.sessionCode;
    const sessionInList =
      !!selectedSessionCode && !!sessionList.find(s => s.sessionCode === selectedSessionCode);
    if (sessionInList) return [selectedSessionCode];
    return [];
  });
  const [hasEstimated, setHasEstimated] = useState<{ [sessionCode: string]: boolean }>({});

  const handleMoveUp = (position: number) => {
    if (!isSessionPrioritizationEnabled || priorities.length - 1 === position) {
      return;
    }
    const tPriorities = swapArray(priorities, position, position + 1);
    setPriorities(tPriorities);
  };

  const handleMoveDown = (position: number) => {
    if (!isSessionPrioritizationEnabled || position === 0) {
      return;
    }
    const tPriorities = swapArray(priorities, position, position - 1);
    setPriorities(tPriorities);
  };

  const handleSelect = useCallback(
    (sessionCode: string) => {
      if (!isSessionPrioritizationEnabled) {
        return setPriorities([sessionCode]);
      }

      setPriorities(uniq([...priorities, sessionCode]));
      setHasEstimated({
        ...hasEstimated,
        [sessionCode]: true,
      });
    },
    [isSessionPrioritizationEnabled, hasEstimated, priorities],
  );

  const handleDeselect = useCallback(
    (sessionCode: string) => {
      if (!priorities.includes(sessionCode)) {
        return null;
      }
      setPriorities(without(priorities, sessionCode));

      if (isSessionPrioritizationEnabled) {
        setHasEstimated(omit(hasEstimated, sessionCode));
      }
    },
    [hasEstimated, isSessionPrioritizationEnabled, priorities],
  );

  const changeEstimatedSessions = (sessionCode: string, value: boolean) => {
    setHasEstimated({
      ...hasEstimated,
      [sessionCode]: value,
    });
  };

  const handlePageChange = (nextPageNumber: number) => {
    onPageChange(nextPageNumber);
  };

  function handleNext() {
    if (!priorities.length) {
      return;
    }

    if (!allowGroupRegistration) {
      return onUpdateReservationSessions(priorities);
    }

    const selectedSession = sessionList.find(({ sessionCode }) => priorities[0] === sessionCode);

    if (!selectedSession) return;

    // will just work with one priority selected
    const {
      isPersonInWaitingList,
      remainingRosters,
      waitingListRecordCount,
      sessionCode,
      maximumPeopleCount,
      sessionName,
    } = selectedSession;

    if (isPersonInWaitingList) {
      // Future logic for registration from wait list here
      return toastService.error('You are already in the waitlist for this session');
    }

    if (!isWaitlistEnabled || (remainingRosters > 0 && waitingListRecordCount <= 0)) {
      return onUpdateReservationSessions(priorities);
    }
    openJoinWaitlistModal({
      sessionName,
      sessionCode,
      initialRosterCount: Math.ceil(registeredPeople / maximumPeopleCount),
      initialPeopleCount: registeredPeople,
      initialAdultCount: numberOfAdults,
      initialYouthCount: numberOfYouth,
    });
  }

  const handleSetGroupInitialSettings = (rosterCount: number) => {
    const initialNumberOfYouth = numberOfYouth || 0;
    const initialNumberOfAdults = numberOfAdults || 0;
    const initialPeopleCount = initialNumberOfAdults + initialNumberOfYouth;

    setGroupSettings({
      initialRosterCount: rosterCount,
      initialPeopleCount,
      initialNumberOfYouth,
      initialNumberOfAdults,
    });
  };

  const { Option } = Select;

  const handleSelectProgram = (programCodes: string[]) => {
    handleFilterSet(FILTER_FIELD_PROGRAM_CODE_LIST, programCodes.join(','));
  };

  const createRemoveProgramHandler = (code: string) => () => {
    const programsArr = without(selectedProgramsArr, code);
    handleFilterSet(FILTER_FIELD_PROGRAM_CODE_LIST, programsArr.join(','));
  };

  const handleResetFilters = () => {
    onFiltersReset();
  };

  function handleFilterSet(key: string, value: string | null) {
    if (value) {
      onFilterSet(key, value);
    } else {
      onFilterRemove(key);
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFilterSet(FILTER_FIELD_SESSION_NAME, e.target.value);
  };

  const disableEarliestDates = (currentDate: Dayjs | null) => {
    if (!latestStartDate || !currentDate) return false;
    return currentDate.isAfter(latestStartDate, 'day');
  };

  const disableLatestDates = (currentDate: Dayjs | null) => {
    if (!earliestStartDate || !currentDate) return false;
    return currentDate.isBefore(earliestStartDate, 'day');
  };

  const handleEarliestDateChange = (value: Dayjs | null) => {
    handleFilterSet(FILTER_FIELD_EARLIEST_START_DATE, value && dateToString(value.startOf('day')));
  };

  const handleLatestStartDateChange = (value: Dayjs | null) => {
    handleFilterSet(FILTER_FIELD_LATEST_START_DATE, value && dateToString(value.endOf('day')));
  };

  const handleOnShowSessions = () => {
    setShowSessions(true);
  };

  return (
    <Fragment>
      {allowGroupRegistration && (
        <PeopleAmount
          numberOfYouth={numberOfYouth}
          numberOfAdults={numberOfAdults}
          onYouthChange={setNumberOfYouth}
          onAdultsChange={setNumberOfAdults}
        />
      )}
      {(allowGroupRegistration ? Number(registeredPeople) > 0 : true) && showSessions ? (
        <CardWithHeader header="Sessions" description={sessionsSummary}>
          <Row gutter={[16, 16]}>
            <Col xs={12} md={4}>
              <SessionsDatePicker
                labelContent="Filter by Date"
                value={earliestStartDate}
                onChange={handleEarliestDateChange}
                disabledDate={disableEarliestDates}
                format="MM/DD/YYYY"
              />
            </Col>
            <Col xs={12} md={4}>
              <SessionsDatePicker
                value={latestStartDate}
                onChange={handleLatestStartDateChange}
                disabledDate={disableLatestDates}
                format="MM/DD/YYYY"
              />
            </Col>
            <Col xs={24} md={8}>
              <Input
                placeholder="Search sessions"
                prefix={<SearchIcon />}
                value={sessionListFilters[FILTER_FIELD_SESSION_NAME]}
                onChange={handleSearchChange}
              />
            </Col>
            <Col xs={24} md={8}>
              <ProgramSelect
                autoClearSearchValue={false}
                mode="multiple"
                placeholder={
                  <>
                    <SearchIcon />
                    Filter by Program
                  </>
                }
                showArrow={true}
                suffixIcon={<ArrowIcon />}
                value={selectedProgramsArr}
                onChange={handleSelectProgram}
                optionFilterProp="children"
              >
                {programs.map(program => (
                  <Option key={program.programCode}>{program.programName}</Option>
                ))}
              </ProgramSelect>
            </Col>
          </Row>
          {selectedProgramsArr.length > 0 && (
            <SelectedProgramsRow align="middle" gutter={16}>
              <Col>
                <ProgramsSelectedTitle>Programs Selected:</ProgramsSelectedTitle>
              </Col>
              <Col>
                <Row gutter={[4, 8]}>
                  {selectedProgramsArr.map((code: string) => (
                    <Col key={code}>
                      <ProgramTag closable onClose={createRemoveProgramHandler(code)}>
                        {programs.find(program => program.programCode === code)?.programName}
                      </ProgramTag>
                    </Col>
                  ))}
                </Row>
              </Col>
            </SelectedProgramsRow>
          )}
          <Divider />
          {!sessionList.length && (
            <p id="errorMessage" className="text-center mt-2 mb-2">
              There are no sessions to show.
            </p>
          )}
          {!sessionList.length && selectedProgramsArr.length > 0 && (
            <p id="errorMessage" className="text-center mt-2 mb-2">
              Click
              <ResetButton onClick={handleResetFilters}> here </ResetButton>
              to reset your search.
            </p>
          )}
          <LoadingContainer isLoading={sessionListLoading}>
            {sessionList.map(session => {
              const { sessionCode } = session;
              return (
                <Priority
                  session={session}
                  key={sessionCode}
                  index={priorities.indexOf(sessionCode)}
                  onSelect={handleSelect}
                  onDeselect={handleDeselect}
                  onMoveUp={handleMoveUp}
                  onMoveDown={handleMoveDown}
                  selectedCount={priorities.length}
                  showPrioritySelector={isSessionPrioritizationEnabled}
                  isGroupRegistration={allowGroupRegistration}
                  changeEstimatedSessions={changeEstimatedSessions}
                  registeredPeople={registeredPeople}
                  setGroupInitialSettings={handleSetGroupInitialSettings}
                  getGroupTotalFee={getGroupTotalFee}
                  fees={groupFees[sessionCode]}
                  isLoadingFees={isLoadingFees}
                  isWaitlistEnabled={isWaitlistEnabled}
                  initialRosterCount={initialRosterCount}
                  multiSessionRuleList={multiSessionRuleList}
                  selectedAttendeeTypeCode={
                    allowGroupRegistration ? undefined : attendeeDetails?.typeCode
                  }
                />
              );
            })}
          </LoadingContainer>
          <Divider />
          <Pagination
            current={pageNumber}
            pageSize={pageSize}
            total={recordCount}
            onChange={handlePageChange}
          />
        </CardWithHeader>
      ) : (
        <CenteredContainer>
          {registeredPeople ? (
            <Button type="primary" onClick={handleOnShowSessions}>
              Show Sessions
            </Button>
          ) : (
            <span>Please specify amount of adults and youth in your group</span>
          )}
        </CenteredContainer>
      )}
      <ButtonsRow
        leftButtons={[{}]}
        rightButtons={[
          {
            onClick: handleNext,
            loading: updateSessionsInProgress,
            disabled:
              !priorities.length ||
              !!(
                allowGroupRegistration &&
                (Object.keys(hasEstimated).filter(key => !!hasEstimated[key]).length <
                  priorities.length ||
                  !registeredPeople)
              ),
          },
        ]}
        alwaysVisible={!!priorities.length}
      />
    </Fragment>
  );
};

SessionPriorities.defaultProps = {
  programs: [],
  sessionsSummary: '',
};

const mapState = (state: RootState) => {
  const { sessionsSummary } = createDataSel('form')(state);
  const programs = createDataSel('programs')(state);
  const sortedPrograms = sortBy(programs, ['programName']);

  return {
    programs: sortedPrograms,
    isLoadingFees: loadingSessionFeesSel(state),
    sessionsSummary: safeDecodeURIComponent(sessionsSummary),
  };
};

const mapDispatch = (dispatch: Dispatch<RootAction>) => ({
  onUpdateReservationSessions: (sessions: string[]) =>
    dispatch(updateReservationSessions(sessions)),
  setGroupSettings: (settings: {
    initialRosterCount: number;
    initialPeopleCount: number;
    initialNumberOfYouth: number;
    initialNumberOfAdults: number;
  }) => dispatch(setGroupInitialSettings(settings)),
  getGroupTotalFee: (payload: {
    sessionCode: string;
    numberOfPeople: number;
    numberOfCrews: number;
  }) => dispatch(getGroupSessionFee.request(payload)),
  openJoinWaitlistModal: (params: JoinWaitlistModalParams) =>
    dispatch(openModal(JOIN_WAIT_LIST_MODAL, params)),
  onPageChange: (pageNumber: number) =>
    dispatch(updatePageNumber({ dataType: 'sessionList', pageNumber })),
  onFilterSet: (key: string, value: string) =>
    dispatch(setFilter({ dataType: 'sessionList', key, value })),
  onFilterRemove: (key: string) => dispatch(removeFilter({ dataType: 'sessionList', key })),
  onFiltersReset: () => dispatch(resetFilters({ dataType: 'sessionList' })),
});

export default connect(mapState, mapDispatch)(SessionPriorities);
