import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import { isNumber, isUndefined } from 'lodash';
import React, { forwardRef } from 'react';

import InputWrapper from '../InputWrapper';
import { Props as InputWrapperProps } from '../InputWrapper/types';

type Props = InputWrapperProps &
  Omit<InputNumberProps, 'onChange'> & {
    onChange?: (value?: number) => void;
  };

const InputMoney: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      labelContent,
      labelSize,
      wrapperClassName,
      labelColored,
      labelInlineBlock,
      value,
      loading,
      onChange,
      ...rest
    },
    ref,
  ) => {
    function formatValue(val?: number | string | null) {
      if (!val) return '';
      const parts = val.toString().split('.');

      const baseString = parts[0];
      let temp = '';
      let i = baseString.length - 1;
      let count = 1;

      while (i >= 0) {
        temp = baseString[i] + temp;

        if (count === 3 && i > 0) {
          temp = ',' + temp;
          count = 0;
        }
        count++;
        i--;
      }

      parts[0] = temp;

      // NOTE: RegExp lookAround (behind and ahead) does not work for Safari

      return parts.join('.');
    }

    const handleChange = (val: string | number | null) => {
      if (!onChange) return;

      if (val === '') {
        onChange(undefined);
      } else if (isNumber(val) || isUndefined(val)) {
        onChange(val);
      } else {
        const numVal = parseFloat(val as string);
        if (!isNaN(numVal)) {
          onChange(numVal);
        }
      }
    };

    return (
      <InputWrapper
        wrapperClassName={wrapperClassName}
        labelContent={labelContent}
        labelSize={labelSize}
        labelColored={labelColored}
        labelInlineBlock={labelInlineBlock}
        loading={loading}
      >
        <InputNumber
          {...rest}
          ref={ref}
          value={value}
          addonBefore="$"
          onChange={handleChange}
          formatter={val => {
            const numVal = Number(val);
            if (numVal !== value && isNumber(value)) {
              return formatValue(value);
            }
            return formatValue(val);
          }}
          parser={val => {
            if (val === undefined) return '';
            return val
              .toString()
              .replace(/[^\d.]/g, '') // remove all non decimals and non .
              .replace(/(\..{2}).*/g, '$1'); // allow only 2 decimals
          }}
        />
      </InputWrapper>
    );
  },
);

export default InputMoney;
