import React, { Fragment } from 'react';

import Warning from '@/modules/shared/components/Warning';

type Props = {
  messages: string[];
};

const EligibilityMessages: React.FC<Props> = ({ messages }) => (
  <Fragment>
    {messages.map(m => (
      <Warning key={m} showWarningText={false} errorMessage={m} />
    ))}
  </Fragment>
);

export default EligibilityMessages;
