import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { updateRecordComment } from './actions';

const addCommentInProgress = createReducer(false)
  .handleAction(updateRecordComment.request, () => true)
  .handleAction([updateRecordComment.success, updateRecordComment.failure], () => false);

export default combineReducers({
  addCommentInProgress,
});
