import React from 'react';

import CSS from './NotFoundData.less';

type Props = {
  content?: string;
};

const NotFoundData: React.FC<Props> = ({ content }) => (
  <div className={CSS.content}>{content || 'Sorry, content was not found.'}</div>
);

export default NotFoundData;
