import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { modulePath } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulePath);

export const updatePersonalInformationInProgressSel = createSelector(
  moduleSel,
  ({ updatePersonalInformationInProgress }) => updatePersonalInformationInProgress,
);
