import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { refreshData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import ActivitiesSelector from '@/modules/entities/Activities/components/ActivitiesSelector';
import { saveActivitiesInProgressSel } from '@/modules/entities/Activities/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const EditActivitiesModal: React.FC<Props> = ({ modalParams }) => {
  const { attendeeName, formRecordGUID, availableActivities } = modalParams;

  const dispatch = useDispatch();
  const ref = useRef<{ handleSubmit: () => void }>(null);
  const isLoading = useSelector(saveActivitiesInProgressSel);
  const formRecordActivities = useSelector(createDataSel('formRecordActivities'));

  const handleClickCancel = () => {
    dispatch(closeModal());
  };

  const handleClickSave = () => {
    ref.current?.handleSubmit();
  };

  return (
    <ModalComponent
      title={`Edit selected activities for ${attendeeName}`}
      buttons={[
        { title: 'CANCEL', onClick: handleClickCancel },
        { title: 'SAVE', onClick: handleClickSave },
      ]}
      inProgress={isLoading}
    >
      <ActivitiesSelector
        availableActivities={availableActivities}
        selectedActivities={formRecordActivities}
        finalActions={[
          refreshData({ dataType: 'formRecordActivities' }),
          refreshData({ dataType: 'paymentSummary' }),
          closeModal(),
        ]}
        formRecordGUID={formRecordGUID}
        isEditingSelectedActivities
        isGroup={false}
        ref={ref}
      />
    </ModalComponent>
  );
};

export default EditActivitiesModal;
