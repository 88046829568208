import dayjs from 'dayjs';

import { CurrentValuesObj, InitialValuesObj } from '@/modules/questions/types';

import { nonCollaboratorRole } from '@/pages/createEvent/constants';

import { AttendeeDetails } from '../data/dataTypes/attendeeDetails';
import { FormSettings } from '../data/dataTypes/form';
import {
  VisibilitySettingsParsed,
  VisibilityLogicItem,
} from '../data/dataTypes/visibilitySettings';
import { dateToString } from '../utils/dateFormats';

import { ConditionalOperatorsTypes, logicValidators } from './constants';

export const formatValue = (
  formPartCode: string,
  formItemCode: string,
  valuesObj: CurrentValuesObj,
): string => {
  let formItemValue = valuesObj?.[formPartCode]?.[formItemCode];
  if (Array.isArray(formItemValue)) {
    formItemValue = formItemValue.join(',');
  } else if (dayjs.isDayjs(formItemValue)) {
    formItemValue = dateToString(formItemValue);
  }
  return formItemValue;
};

const hasPrivilege = (isEditing: boolean, settings?: VisibilityLogicItem[]) => {
  if (!settings || !settings.length) return true;
  if (isEditing) {
    return settings.some(({ privileges: { edit, update } }) => edit || update);
  }

  return settings.some(({ privileges: { edit, update, view } }) => edit || update || view);
};

const isVisibleToAttendeeType = (
  settings: VisibilitySettingsParsed,
  isEditing: boolean,
  typeCode: string,
): boolean => hasPrivilege(isEditing, settings.attendeeTypes?.filter(({ id }) => id === typeCode));

const isVisibleToCollaboratorRoles = (
  settings: VisibilitySettingsParsed,
  isEditing: boolean,
  rolesSet: Set<string>,
): boolean => {
  if (!settings.roles || !settings.roles.length) {
    return true;
  }

  if (!rolesSet.size) {
    return false;
  }

  const settingsRoleCodes = new Set(settings.roles.map(({ id }) => id));

  for (const role of rolesSet) {
    // collaborator has a role whish wasn't defined in visibility settings, that's why considered as granted
    if (!settingsRoleCodes.has(role)) return true;
  }

  return hasPrivilege(
    isEditing,
    settings.roles.filter(({ id }) => rolesSet.has(id)),
  );
};

const isVisibleToReservationContact = (
  settings: VisibilitySettingsParsed,
  isEditing: boolean,
): boolean => hasPrivilege(isEditing, settings.reservationContact);

export const isVisibleByCustomLogic = ({
  isEditingAllFormParts,
  editingFormPartCode,
  formPartCode: currentFormPartCode,
  formItemCode: currentFormItemCode,
  currentValues: currentValuesObj,
  initialValues: initialValuesObj,
}: {
  isEditingAllFormParts?: boolean;
  editingFormPartCode?: string | null;
  formPartCode: string;
  formItemCode?: string;
  currentValues: CurrentValuesObj;
  initialValues: InitialValuesObj;
}): boolean => {
  const settings = currentFormItemCode
    ? initialValuesObj[currentFormPartCode]?.formItems[currentFormItemCode]
        ?.customVisibilitySettings
    : initialValuesObj[currentFormPartCode]?.customVisibilitySettings;

  if (!settings) return true;

  const { action, formItemCode, formPartCode, testOption, value: testValue } = settings;

  if (!formPartCode || !formItemCode) return true;

  const isTestFormItemVisible = isVisibleByCustomLogic({
    isEditingAllFormParts,
    editingFormPartCode,
    formPartCode,
    formItemCode,
    currentValues: currentValuesObj,
    initialValues: initialValuesObj,
  });

  if (!isTestFormItemVisible) return false;

  let conditionMet = false;

  const logicValidator = logicValidators[testOption as ConditionalOperatorsTypes];

  const value =
    isEditingAllFormParts || editingFormPartCode === formPartCode
      ? formatValue(formPartCode, formItemCode, currentValuesObj)
      : initialValuesObj?.[formPartCode]?.formItems[formItemCode]?.formItemValue;

  if (typeof logicValidator === 'function') {
    conditionMet = logicValidator(value as string, testValue);
  }

  const shouldHide = action === 'Hide';
  const shouldShow = action === 'Show';

  if ((conditionMet && shouldHide) || (!conditionMet && shouldShow)) {
    return false;
  }

  if ((conditionMet && shouldShow) || (!conditionMet && shouldHide)) {
    return true;
  }

  return false;
};

type BaseParams = {
  visibilitySettings?: VisibilitySettingsParsed;
  isEditing: boolean;
  form: FormSettings;
  rolesSet: Set<string>;
};

type AddAttendeeParams = BaseParams & {
  providedAttendeeType: string;
  checkAttendeeTypeOnly?: true;
};

type UpdateAttendeeParams = BaseParams & {
  attendeeDetails: AttendeeDetails;
};

export const isQuestionVisible = (params: AddAttendeeParams | UpdateAttendeeParams): boolean => {
  const { visibilitySettings, isEditing } = params;

  if (!visibilitySettings) return true;

  if ('checkAttendeeTypeOnly' in params) {
    return isVisibleToAttendeeType(visibilitySettings, isEditing, params.providedAttendeeType);
  }

  const { form, rolesSet } = params;
  const { isCollaborator, isCreator } = form;

  if (isCreator) return true;

  let isVisibleByObject = false;

  if ('attendeeDetails' in params && params.attendeeDetails.isReservationContact) {
    isVisibleByObject = isVisibleToReservationContact(visibilitySettings, isEditing);
  } else {
    const attendeeTypeCode =
      'providedAttendeeType' in params
        ? params.providedAttendeeType
        : params.attendeeDetails.typeCode;
    isVisibleByObject = isVisibleToAttendeeType(visibilitySettings, isEditing, attendeeTypeCode);
  }

  if (!isVisibleByObject) return false;

  let isVisibleToSubject = false;

  if (rolesSet.size) {
    isVisibleToSubject = isVisibleToCollaboratorRoles(visibilitySettings, isEditing, rolesSet);
  } else if (!isCollaborator) {
    isVisibleToSubject = hasPrivilege(
      isEditing,
      visibilitySettings.roles?.filter(({ id }) => id === nonCollaboratorRole),
    );
  }

  return isVisibleToSubject;
};
