import apiService from '@/modules/utils/apiService';

const initiateFormRecord$ = (payload: { formCode: string; personGUID: string }) =>
  apiService.post$<{ reservationCode: string }>(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=InitiateFormRecord',
    payload,
  );

export default {
  initiateFormRecord$,
};
