import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { VALIDATE_RESERVATION_CONTACT_INVITE_ERROR_STATUS } from '@/pages/reservation/constants';

import { moduleName as myReservationModuleName } from '../components/MyReservation/constants';
import myReservationReducers from '../components/MyReservation/duck/reducers';

import {
  validateGroupReservationRCInvite,
  updateGroupReservationContact,
  updatePaymentScheduleDueDate,
  cancelGroupReservationContactInvitation,
  assignHiringManager,
  approveOrDeclineAttendee,
  transferRegistration,
} from './actions';

const sendGroupReservationRCInviteInProgress = createReducer(false)
  .handleAction(updateGroupReservationContact.request, () => true)
  .handleAction(
    [updateGroupReservationContact.success, updateGroupReservationContact.failure],
    () => false,
  );

const cancelGroupReservationContactInvitationInProgress = createReducer(false)
  .handleAction(cancelGroupReservationContactInvitation.request, () => true)
  .handleAction(
    [
      cancelGroupReservationContactInvitation.success,
      cancelGroupReservationContactInvitation.failure,
    ],
    () => false,
  );

const validatingGroupReservationInviteInProgress = createReducer(false)
  .handleAction(validateGroupReservationRCInvite.request, () => true)
  .handleAction(
    [validateGroupReservationRCInvite.success, validateGroupReservationRCInvite.failure],
    () => false,
  );
const validatingGroupReservationInviteStatus = createReducer('')
  .handleAction(validateGroupReservationRCInvite.request, () => '')
  .handleAction(validateGroupReservationRCInvite.success, (_, { payload }) => payload)
  .handleAction(
    validateGroupReservationRCInvite.failure,
    () => VALIDATE_RESERVATION_CONTACT_INVITE_ERROR_STATUS,
  );

const updatePaymentScheduleDueDateInProgress = createReducer(false)
  .handleAction(updatePaymentScheduleDueDate.request, () => true)
  .handleAction(
    [updatePaymentScheduleDueDate.success, updatePaymentScheduleDueDate.failure],
    () => false,
  );

const assignHiringManagerInProgress = createReducer(false)
  .handleAction(assignHiringManager.request, () => true)
  .handleAction([assignHiringManager.success, assignHiringManager.failure], () => false);

const isLoadingApproval = createReducer(false)
  .handleAction(approveOrDeclineAttendee.request, () => true)
  .handleAction([approveOrDeclineAttendee.success, approveOrDeclineAttendee.failure], () => false);

const transferRegistrationInProgress = createReducer(false)
  .handleAction(transferRegistration.request, () => true)
  .handleAction([transferRegistration.success, transferRegistration.failure], () => false);

export default combineReducers({
  [myReservationModuleName]: myReservationReducers,
  transferRegistrationInProgress,
  isLoadingApproval,
  sendGroupReservationRCInviteInProgress,
  cancelGroupReservationContactInvitationInProgress,
  validatingGroupReservationInviteInProgress,
  validatingGroupReservationInviteStatus,
  updatePaymentScheduleDueDateInProgress,
  assignHiringManagerInProgress,
});
