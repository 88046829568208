import { usePDF } from '@react-pdf/renderer';
import React, { forwardRef } from 'react';

import { Attendee, Disclaimer } from '@/modules/shared/components/Disclaimers/types';

import DisclaimerDocument from '../../../../../DisclaimerDocument';

type Props = {
  disclaimer: Disclaimer;
  attendeeDetails: Attendee;
};

const Link = forwardRef<HTMLAnchorElement, Props>(({ disclaimer, attendeeDetails }, ref) => {
  const [instance] = usePDF({
    document: <DisclaimerDocument disclaimer={disclaimer} attendeeDetails={attendeeDetails} />,
  });

  if (!instance.url) return null;

  const { disclaimerName } = disclaimer;
  const { firstName, lastName, reservationCode } = attendeeDetails;

  return (
    <a
      ref={ref}
      href={instance.url}
      download={`${lastName}_${firstName}_${reservationCode}_${disclaimerName.replaceAll(
        ' ',
        '_',
      )}.pdf`}
    />
  );
});

export default Link;
