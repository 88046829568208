import { Svg, Path, G } from '@react-pdf/renderer';
import React from 'react';

type Props = {
  size: number;
  color: string;
};

const UnCheckedIcon: React.FC<Props> = ({ size, color }) => (
  <Svg viewBox="0 0 24 24" width={size} height={size}>
    <G strokeWidth={0} />
    <G strokeLineCap="round" stroke="round" />
    <G>
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G fill={color} fillRule="nonzero">
          <Path d="M5.75,3 L18.25,3 C19.7687831,3 21,4.23121694 21,5.75 L21,18.25 C21,19.7687831 19.7687831,21 18.25,21 L5.75,21 C4.23121694,21 3,19.7687831 3,18.25 L3,5.75 C3,4.23121694 4.23121694,3 5.75,3 Z M5.75,4.5 C5.05964406,4.5 4.5,5.05964406 4.5,5.75 L4.5,18.25 C4.5,18.9403559 5.05964406,19.5 5.75,19.5 L18.25,19.5 C18.9403559,19.5 19.5,18.9403559 19.5,18.25 L19.5,5.75 C19.5,5.05964406 18.9403559,4.5 18.25,4.5 L5.75,4.5 Z" />
        </G>
      </G>
    </G>
  </Svg>
);

export default UnCheckedIcon;
