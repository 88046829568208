import isUrl from 'is-url';
import { Editor } from 'slate';

import { ElementType } from './constants';
import { wrapLink } from './utils';

export const withLinks = <T extends Editor>(editor: T): T => {
  const { insertData, insertText, isInline } = editor;
  editor.isInline = element => element.type === ElementType.link || isInline(element);

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const withVariables = <T extends Editor>(editor: T): T => {
  const { isInline, isVoid } = editor;
  editor.isInline = element => element.type === ElementType.variable || isInline(element);
  editor.isVoid = element => element.type === ElementType.variable || isVoid(element);
  return editor;
};
