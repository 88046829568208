import { Row } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const RefundInformationRow = styled(Row)`
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  > * {
    margin-bottom: 8px;

    :first-child {
      font-size: 16px;
      color: ${colors.scoutingBlue};
      margin-bottom: 16px;
    }
  }
`;
