import { combineEpics, Epic } from 'redux-observable';
import { of, concat } from 'rxjs';
import { switchMap, catchError, filter } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData, removeDataItem } from '@/modules/data/duck/actions';
import toastService from '@/modules/toasts/service';

import { pageDataSel } from '@/pages/createEvent/duck/selectors';

import { addJob, deleteJob } from './actions';
import services from './services';

const addUpdateJob$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addJob.request)),
    switchMap(({ payload: { jobDepartmentCode, jobDepartmentName, ...rest } }) => {
      const {
        data: {
          form: { formCode },
        },
      } = pageDataSel(state$.value);
      return services.addDepartment$({ jobDepartmentCode, jobDepartmentName, formCode }).pipe(
        switchMap(() =>
          concat(
            of(refreshData({ dataType: 'jobDepartmentList' })),
            services.addJob$({ ...rest, jobDepartmentCode, formCode }).pipe(
              switchMap(() => {
                toastService.success('Job was added/updated.');
                return of(addJob.success(), refreshData({ dataType: 'jobList' }));
              }),
              catchError(error => {
                toastService.error(error?.message || 'Failed to add/update job.');
                return of(addJob.failure(error));
              }),
            ),
          ),
        ),
        catchError(error => {
          toastService.error(error?.message || 'Failed to add/update department.');
          return of(addJob.failure(error));
        }),
      );
    }),
  );

const deleteJob$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteJob.request)),
    switchMap(({ payload: jobCode }) => {
      const {
        data: {
          form: { formCode },
        },
      } = pageDataSel(state$.value);
      return services.deleteJob$({ jobCode, formCode }).pipe(
        switchMap(() => {
          toastService.success('Job was deleted.');
          return of(
            removeDataItem({ dataType: 'jobList', idField: 'jobCode', dataItemId: jobCode }),
            deleteJob.success(),
          );
        }),
        catchError(error => {
          toastService.error(error?.message || 'Failed to delete job.');
          return of(deleteJob.failure(error));
        }),
      );
    }),
  );

export default combineEpics(addUpdateJob$, deleteJob$);
