import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';

import { disclaimerAcceptedText } from '../../../../constants';

type Props = {
  disclaimerCode: string;
  isAccepted: boolean;
  onAccept: (disclaimerCode: string, isAccepted: boolean) => void;
};

const AcceptDisclaimer: React.FC<Props> = ({ disclaimerCode, isAccepted, onAccept }) => {
  const handleAccept = (e: CheckboxChangeEvent) => {
    onAccept(disclaimerCode, e.target.checked);
  };
  return (
    <Checkbox checked={isAccepted} onChange={handleAccept}>
      {disclaimerAcceptedText}
    </Checkbox>
  );
};

export default AcceptDisclaimer;
