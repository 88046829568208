import styled from 'styled-components';
// eslint-disable-next-line import/named

const INFO = '#067eeb';
const WARNING = '#ffb225';
const ERROR = '#dd0000';

export const Timer = styled.div<{ $percent: number }>`
  min-height: 56px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.2px;
  font-family: Roboto;
  margin-bottom: 12px;
  overflow: hidden;

  &:before {
    content: '';
    width: 100%;
    background-color: ${INFO};
    height: 4px;
    opacity: 0.3;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &:after {
    content: '';
    width: ${props => props.$percent}%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 300ms ease;
  }

  &.info:before,
  &.info:after {
    background-color: ${INFO};
  }

  &.warning:before,
  &.warning:after {
    background-color: ${WARNING};
  }
  &.error:before,
  &.error:after {
    background-color: ${ERROR};
  }

  & .tooltip-icon {
    color: ${INFO};
    margin: 0 6px;
  }

  & .icon {
    margin: 0 10px 0 20px;
  }

  & .icon,
  & .tooltip-icon {
    transition: all 300ms ease;
  }

  &.info .icon {
    color: ${INFO};
  }

  &.warning .icon {
    color: ${WARNING};
  }

  &.error .icon {
    color: ${ERROR};
  }
`;
