/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */

import { redirect } from 'redux-first-router';
import { StateObservable } from 'redux-observable';
import { concat, defer, merge, Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { RootState, RootAction, isActionOf } from 'typesafe-actions';

import { resetFilters, resetPagination, scheduleRefresh } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { isOnRegistrationWindowSel } from '@/modules/entities/Events/duck/selectors';
import { urlFormCodeSel } from '@/modules/location/duck/selectors';
import { allowLeave, pageLoadCompleted } from '@/modules/routing/duck/actions';
import { canLeaveSel } from '@/modules/routing/duck/selectors';
import toastService from '@/modules/toasts/service';
import { ROUTE_LOGIN } from '@/modules/user/duck/actions';
import { eventDateWithYear } from '@/modules/utils/dateFormats';

import { navigateToIndividualReservationList } from '@/pages/individualReservationsList/duck/actions';
import {
  ROUTE_GROUP_RESERVATION_PREFIX,
  ROUTE_INDIVIDUAL_RESERVATION_PREFIX,
} from '@/pages/reservation/constants';
import { navigateToGroupReservationList } from '@/pages/reservationList/duck/actions';

import { navigateToEvents } from '../eventList/duck/actions';

import CreateReservationPage from './CreateReservationPage';
import { ROUTE_FORMS, ROUTE_FORMS_OPTIONAL_SEGMENT } from './constants';
import { openStepByIndex, openStepCompleted } from './duck/actions';

const routes = {
  [ROUTE_FORMS]: {
    path: `/form/:formCode/${ROUTE_FORMS_OPTIONAL_SEGMENT}`,
    confirmLeave: (state: RootState, { type }: RootAction): string | undefined => {
      const canLeave = canLeaveSel(state);

      if (canLeave) return undefined;

      const { allowGroupRegistration } = createDataSel('form')(state) || {};
      if (
        ![ROUTE_INDIVIDUAL_RESERVATION_PREFIX, ROUTE_GROUP_RESERVATION_PREFIX].some(prefix =>
          type.startsWith(prefix),
        ) ||
        ![ROUTE_LOGIN, ROUTE_FORMS].includes(type)
      ) {
        return `Are you sure you want to leave without completing ${
          allowGroupRegistration ? 'reservation' : 'registration'
        }?`;
      }
    },
    unAuthenticatedAccess: true,
    titleId: 'page.form',
    component: CreateReservationPage,
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const formCode = urlFormCodeSel(state$.value);

      return concat(
        of(
          scheduleRefresh({ dataType: 'reservationListReport' }),
          resetPagination({ dataType: 'sessionList', fetchData: false }),
          resetFilters({ dataType: 'sessionList', fetchData: false }),
        ),
        prefetchData$(action$, { dataType: 'form', queryObj: { formCode }, fetchData: true }),
        defer(() => {
          const state = state$.value;
          const formSettings = createDataSel('form')(state);

          const {
            formName,
            allowMultipleRegistrations,
            allowMultipleReservations,
            allowGroupRegistration,
            eventRegistrationStartDate,
            eventRegistrationCloseDate,
            isCreator,
            isCollaborator,
            personCounters: { activeCompletedRegistrations },
          } = formSettings;

          const onRegistrationWindow = isOnRegistrationWindowSel(state, formSettings);

          const allowMultiple = allowGroupRegistration
            ? allowMultipleReservations
            : allowMultipleRegistrations;

          let canRegisterMultipleTimes = true;

          if (!allowMultiple) {
            if (activeCompletedRegistrations > 0) {
              canRegisterMultipleTimes = false;
            }
          }

          const canRegister =
            (onRegistrationWindow && canRegisterMultipleTimes) || isCreator || isCollaborator;

          if (canRegister) {
            return merge(
              action$.pipe(filter(isActionOf(openStepCompleted)), take(1)),
              of(openStepByIndex(0)),
            );
          }

          if (!onRegistrationWindow) {
            toastService.error(
              `Registration period for ${formName} is closed (${eventDateWithYear(
                eventRegistrationStartDate,
                eventRegistrationCloseDate,
              )})`,
            );

            return merge(
              action$.pipe(filter(isActionOf(pageLoadCompleted)), take(1)),
              of(allowLeave(), redirect(navigateToEvents())),
            );
          } else {
            toastService.error(
              `You already have a ${
                allowGroupRegistration ? 'reservation' : 'registration'
              } for this event.`,
            );

            return merge(
              action$.pipe(filter(isActionOf(pageLoadCompleted)), take(1)),
              of(
                allowLeave(),
                redirect(
                  allowGroupRegistration
                    ? navigateToGroupReservationList({ formCode })
                    : navigateToIndividualReservationList({ formCode }),
                ),
              ),
            );
          }
        }),
      );
    },
  },
};

export default routes;
