import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import ActivitiesSelector from '@/modules/entities/Activities/components/ActivitiesSelector';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const EditAdditionalRegistrantActivitiesModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const activitiesRef = useRef<{ handleSubmit: () => void }>(null);
  const { formCode } = useSelector(createDataSel('form'));
  const {
    formRecord: { formRecordGUID, firstName, lastName },
    availableActivities,
    selectedActivities,
    finalActions,
  } = modalParams;

  useEffect(() => {
    dispatch(
      fetchData.request({
        dataType: 'formRecordActivities',
        queryObj: { formCode, formRecordGUID },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmit() {
    activitiesRef.current?.handleSubmit();
  }

  function handleCancel() {
    dispatch(closeModal());
  }

  return (
    <ModalComponent
      title={`${firstName} ${lastName} Activities`}
      buttons={[
        { title: 'CANCEL', onClick: handleCancel },
        { title: 'SAVE', onClick: handleSubmit },
      ]}
    >
      <ActivitiesSelector
        ref={activitiesRef}
        isGroup={false}
        formRecordGUID={formRecordGUID}
        availableActivities={availableActivities}
        selectedActivities={selectedActivities}
        finalActions={[...finalActions, closeModal()]}
      />
    </ModalComponent>
  );
};

export default EditAdditionalRegistrantActivitiesModal;
