import DOMPurify from 'dompurify';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dismissMessage } from '@/modules/broadcast/duck/actions';
import { broadcastMessageSel } from '@/modules/broadcast/duck/selectors';

import { BroadcastMessageWrapper, Icon, Button } from './BroadcastMessage.styled';

const BroadcastMessage: React.FC = () => {
  const dispatch = useDispatch();
  const broadcastMessage = useSelector(broadcastMessageSel);

  const handleDismissMessage = () => {
    dispatch(dismissMessage(broadcastMessage));
  };

  if (!broadcastMessage) return null;

  return (
    <BroadcastMessageWrapper>
      <Icon />
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(broadcastMessage) }} />
      <Button onClick={handleDismissMessage}>DISMISS</Button>
    </BroadcastMessageWrapper>
  );
};

export default BroadcastMessage;
