import { Observable } from 'rxjs';
import { RootAction } from 'typesafe-actions';

import { prefetchData$ } from '@/modules/data/duck/epics';

import { stepDataParams } from '../constants';

export const createGetStepObservable = (formCode: string) => (action$: Observable<RootAction>) =>
  prefetchData$(
    action$,
    ...stepDataParams.map(params => {
      switch (params.dataType) {
        case 'formPartsData':
          return { ...params, queryObj: { formCode } };
      }
    }),
  );
