import { Card, Pagination as BsaPagination } from 'antd';
import Link from 'redux-first-router-link';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

import Button from 'SHARED/components/Button';

export const EventsCard = styled(Card)`
  &&& {
    width: 100%;

    .ant-card-head {
      background: none;
    }
    .ant-card-head-wrapper {
      border-bottom: none;
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab {
      color: ${colors.text};
      font-size: 1em;

      :hover {
        color: ${colors.text};
      }
    }
    .ant-tabs-tab-active {
      background: ${colors.white};
      color: ${colors.primary_5};
      box-shadow: 0 -8px 0px ${colors.primary_5} inset;

      :hover {
        color: ${colors.primary_5};
      }
    }
  }
`;

export const Pagination = styled(BsaPagination)`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

export const LinkToCreateEvent = styled(Link)`
  transition: transform 150ms linear;
  top: 0;
  right: 0;
`;

export const CreateEventButton = styled(Button)`
  background-color: ${colors.lightBlueStatus};
  border-color: ${colors.lightBlueStatus};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
`;
