import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { moduleName } from '../constants';

const moduleSel = (state: RootState) => state[moduleName];

export const addCommentInProgressSel = createSelector(
  moduleSel,
  ({ addCommentInProgress }) => addCommentInProgress
);
