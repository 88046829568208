import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormJob } from '@/modules/data/dataTypes/jobList';
import { jobStatus, JOB_PERIODS } from '@/modules/entities/Jobs/constants';
import {
  ATTENDEE_DETAILS_UPDATE_JOB_PRIORITIES_MODAL,
  ATTENDEE_JOB_ACCEPT_MODAL,
} from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import EditableFormSection from '@/modules/shared/components/EditableFormSection';
import ItemMapper from '@/modules/shared/components/ItemMapper';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { AttendeeJobAcceptModalParams } from '@/pages/reservation/components/AttendeeJobAcceptModal/types';
import { UpdatePrioritiesModalParams } from '@/pages/reservation/components/UpdatePrioritiesModal/types';
import { nonFreeAgentJobsAmount } from '@/pages/reservation/constants';
import { attendeeAcceptedJobSel, pageDataSel } from '@/pages/reservation/duck/selectors';

import { JobStatus, OpenJobOfferIcon } from './JobPrioritiesList.styled';

const JobPrioritiesList: React.FC = () => {
  const dispatch = useDispatch();

  const {
    data: { form, attendeeDetails, formRecordJobs },
    isLoading: dataPageIsLoading,
  } = useSelector(pageDataSel);

  const { formCode } = form;
  const { formRecordGUID } = attendeeDetails;

  const selectedJobs = useMemo(
    () => sortBy(formRecordJobs, ({ priority }) => Number(priority)),
    [formRecordJobs],
  );

  const attendeeAcceptedJob = useSelector(attendeeAcceptedJobSel);

  const isAllowedToEditJobPositions = useSelector(
    createAbilitiesSelector('attendeeDetails', 'editJobsUnrestricted', null),
  );

  const canAcceptJobPosition = useSelector(
    createAbilitiesSelector('attendeeDetails', 'acceptJobPosition', attendeeDetails),
  );

  const handleEditJobs = () => {
    const modalParams: UpdatePrioritiesModalParams = { formCode, formRecordGUID };
    dispatch(openModal(ATTENDEE_DETAILS_UPDATE_JOB_PRIORITIES_MODAL, modalParams));
  };

  const items = useMemo(() => {
    const createOpenJobOfferHandler = (offeredJob: FormJob) => () => {
      const params: AttendeeJobAcceptModalParams = { formCode, offeredJob };
      dispatch(openModal(ATTENDEE_JOB_ACCEPT_MODAL, params));
    };

    return selectedJobs.map(formJob => {
      const { priority, status, jobName, jobPeriodCode } = formJob;

      const label =
        priority >= nonFreeAgentJobsAmount ? `FA${priority + 1}` : `Priority ${priority + 1}`;

      const offeredJobPeriod = JOB_PERIODS.find(({ code }) => code === jobPeriodCode);
      const jobPeriodText = offeredJobPeriod ? ` (${offeredJobPeriod.name})` : '';
      const value = `${jobName}${jobPeriodText}`;

      const jobStatusConfig = jobStatus[status];
      const extraContent = (
        <JobStatus color={jobStatusConfig?.color || jobStatus.job_pending.color}>
          {jobStatusConfig?.name || status}
          {!attendeeAcceptedJob &&
            canAcceptJobPosition &&
            status === jobStatus.job_offered.code && (
              <OpenJobOfferIcon onClick={createOpenJobOfferHandler(formJob)} />
            )}
        </JobStatus>
      );
      return { label, value, extraContent };
    });
  }, [selectedJobs, canAcceptJobPosition, attendeeAcceptedJob, formCode, dispatch]);

  return (
    <EditableFormSection
      title="Job Priorities"
      canEdit={isAllowedToEditJobPositions}
      onEditClick={handleEditJobs}
      isEditing={false}
      isLoading={dataPageIsLoading.formRecordJobs}
      hideButtons
    >
      <ItemMapper items={items} />
    </EditableFormSection>
  );
};

export default JobPrioritiesList;
