import get from 'lodash/get';
import { combineEpics, Epic } from 'redux-observable';
import { EMPTY, of, merge } from 'rxjs';
import { catchError, switchMap, filter } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import { closeModal } from '@/modules/modals/duck/actions';
import { formStatus } from '@/modules/shared/constants';
import toastService from '@/modules/toasts/service';
import { personGuidSel } from '@/modules/user/duck/selectors';
import { ApiError } from '@/modules/utils/apiService';

import { navigateToEvents } from '@/pages/eventList/duck/actions';

import { CancelReservationPayload } from '../types';

import {
  cancelGroupReservation,
  cancelIndividualReservation,
  createGroupReservationRosters,
  updateGroupReservation,
} from './actions';
import services from './services';

const cancelIndividualReservation$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(cancelIndividualReservation.request)),
    switchMap(({ payload }) => {
      const currentState = state$.value;
      const cancellationPersonGUID = personGuidSel(currentState) as string;
      const { formCode } = createDataSel('form')(currentState);
      const {
        cancellationReason: cancelationReason,
        cancellationStatusCode,
        personGUID,
        formRecordGUID,
        sendNotification,
        finalActions,
      } = payload;

      return services
        .cancelIndividualReservation$({
          personGUID,
          cancellationPersonGUID,
          formCode,
          cancelationReason,
          cancellationStatusCode,
          formRecordGUID,
          sendNotification,
        })
        .pipe(
          switchMap(() => {
            toastService.info('Reservation was canceled.');
            return merge(
              of(
                cancelIndividualReservation.success(),
                refreshData({ dataType: 'formRecordAddons' }),
                refreshData({ dataType: 'formRecordActivities' }),
                refreshData({ dataType: 'paymentSummary' }),
                ...finalActions,
              ),
              // If personGUID === cancellationPersonGUID, the user is a regular user, otherwise is an admin.
              personGUID === cancellationPersonGUID ? of(navigateToEvents()) : EMPTY,
            );
          }),
          catchError((err: ApiError) => {
            toastService.error(err.message);
            return of(cancelIndividualReservation.failure(err));
          }),
        );
    }),
  );

const cancelGroupReservation$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(cancelGroupReservation.request)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { cancellationReason, sendNotification } = payload;
      const { formCode } = createDataSel('form')(state);
      const { groupReservationGUID } = createDataSel('groupReservation')(state);

      const cancelPayload: CancelReservationPayload = {
        formCode,
        groupReservationGUID,
        cancellationReason,
        cancellationStatusCode: formStatus.collaborator_cancelled.code,
        sendNotification,
      };

      return services.cancelGroupReservation$(cancelPayload).pipe(
        switchMap(() =>
          of(
            cancelGroupReservation.success(),
            refreshData({ dataType: 'groupReservation' }),
            refreshData({ dataType: 'groupReservationAddons' }),
            refreshData({ dataType: 'groupReservationActivities' }),
            refreshData({ dataType: 'paymentSummary' }),
            closeModal(),
          ),
        ),
        catchError(error => {
          toastService.error(get(error, 'message', error));
          return of(cancelGroupReservation.failure(error));
        }),
      );
    }),
  );

const updateGroupReservation$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(updateGroupReservation.request)),
    switchMap(
      ({ payload: { reservation, successActions = [], successMessage, failureActions = [] } }) =>
        services.updateGroupReservation$(reservation).pipe(
          switchMap(() => {
            if (successMessage) {
              toastService.success(successMessage);
            }

            return of(updateGroupReservation.success(), ...successActions);
          }),
          catchError((error: ApiError) =>
            of(updateGroupReservation.failure(error), ...failureActions),
          ),
        ),
    ),
  );

const createGroupReservationRosters$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(createGroupReservationRosters.request)),
    switchMap(({ payload }) => {
      const { finalActions = [], ...rest } = payload;
      return services.createGroupReservationRosters$(rest).pipe(
        switchMap(() => of(createGroupReservationRosters.success(), ...finalActions)),
        catchError((error: ApiError) => of(createGroupReservationRosters.failure(error))),
      );
    }),
  );

export default combineEpics(
  cancelIndividualReservation$,
  cancelGroupReservation$,
  updateGroupReservation$,
  createGroupReservationRosters$,
);
