import { createAction } from 'typesafe-actions';

import { createModuleNamespaceName } from '@/modules/utils/misc';

import { modulePath } from '../constants';

const moduleNameSpace = createModuleNamespaceName(modulePath);

export const resetModuleState = createAction(`${moduleNameSpace}/RESET_MODULE_STATE`)();

export const selectAttribute = createAction(`${moduleNameSpace}/SELECT_ATTRIBUTE`)<string>();
export const deselectAttribute = createAction(`${moduleNameSpace}/DESELECT_ATTRIBUTE`)<string>();
