import { Row } from 'antd';
import React, { Fragment } from 'react';

import { US_DATE_FORMAT, dateFromString } from '@/modules/utils/dateFormats';

import {
  DATE_OF_CONSENT_LABEL,
  PERSON_GIVING_CONSENT_LABEL,
  RELATIONSHIP_TO_ATTENDEE_LABEL,
} from '../../../../constants';
import { Disclaimer } from '../../../../types';

type Props = {
  disclaimer: Disclaimer;
};

const AcceptanceDetails: React.FC<Props> = ({ disclaimer }) => {
  const {
    dateOfAcceptance,
    relationship,
    firstName: consentFirstName,
    lastName: consentLastName,
  } = disclaimer;
  return (
    <Fragment>
      {dateOfAcceptance && (
        <Row>
          <b>{DATE_OF_CONSENT_LABEL}: </b>
          <span>{dateFromString(dateOfAcceptance).format(US_DATE_FORMAT)}</span>
        </Row>
      )}
      {consentFirstName && consentLastName && (
        <Row>
          <b>{PERSON_GIVING_CONSENT_LABEL}: </b>
          <span>{`${consentFirstName} ${consentLastName}`}</span>
        </Row>
      )}
      {relationship && (
        <Row>
          <b>{RELATIONSHIP_TO_ATTENDEE_LABEL}: </b>
          <span>{relationship}</span>
        </Row>
      )}
    </Fragment>
  );
};

export default AcceptanceDetails;
