import React from 'react';

import InfoBubble from '@/modules/shared/components/InfoBubble';

import { InfoContainer } from './Description.styled';

const Description: React.FC = () => (
  <div>
    Add new answer options below, one option per row. Row format [answer option]-[answer-capacity]
    <InfoBubble placement="bottom">
      <InfoContainer>
        <span>Row Format [Answer option] - [Count]</span>
        <span>Example:</span>
        <strong>Apple-2</strong>
        <strong>Orange-1</strong>
        <span>Apple can be selected two times and</span>
        <span>Orange just one before they get hidden</span>
      </InfoContainer>
    </InfoBubble>
  </div>
);

export default Description;
