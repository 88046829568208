export enum SortSessionsListOptions {
  ByProgramType = 'ByProgramType',
  ByStartDate = 'ByStartDate',
}

export enum SortSessionsListParams {
  ByProgramType = 'program_name ASC, start_date ASC',
  ByStartDate = 'start_date ASC, program_name ASC',
}

export enum SortSessionsListLabels {
  ByProgramType = 'Program Type',
  ByStartDate = 'Start Date',
}

export const defaultSortSessionsListOption = SortSessionsListOptions.ByProgramType;

export const SESSION_SORT_ORDER_FIELD = 'sortOrder';
