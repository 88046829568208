import { Typography } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Title = styled(Typography.Title)`
  && {
    color: ${colors.primary};
  }
`;
