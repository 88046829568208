import { Form, Row as RowAntd } from 'antd';
import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

import { distanceBetweenInputs } from './constants';

const lineStyles = css`
  position: relative;

  ::before {
    content: '';
    position: absolute;
    width: ${distanceBetweenInputs}px;
    height: 3px;
    top: 16px;
    right: 100%;
    background-color: ${colors.lightGrey};
    z-index: -1;
  }
`;

export const Row = styled(RowAntd)`
  width: 100%;
`;

export const FormItem = styled(Form.Item)<{
  $showLine?: boolean;
}>`
  &&& .ant-form-item {
    margin-bottom: none;
  }

  &&& .ant-form-item-control {
    line-height: normal;
  }

  &&& .ant-form-item-explain-error {
    color: red;
  }

  .ant-calendar-picker {
    width: 100%;
  }

  &&&.ant-form-item-hidden {
    visibility: hidden;
  }

  &&& .ant-select {
    height: auto;
    top: 0;
  }

  ${({ $showLine }) => ($showLine ? lineStyles : '')}
`;
