import { PrinterOutlined } from '@ant-design/icons';
import React from 'react';

import { CellContainer, PrintButton } from './PrintRosterColumn.styled';

interface Props {
  rosterCode: string;
}

const PrintRosterColumn: React.FC<Props> = ({ rosterCode }) => {
  const reportURL = `https://my${process.env.REACT_APP_ENV}.scouting.org/mystrpt/WebReport/Output/proxy.aspx?Method=LoadReportPage&ReportCode=EMSRosterReport&RosterCode=${rosterCode}&OutputFormat=@PDF`;

  return (
    <CellContainer>
      <PrintButton ghost href={reportURL} rel="noopener noreferrer" target="_blank">
        <PrinterOutlined />
        Print Roster
      </PrintButton>
    </CellContainer>
  );
};

export default PrintRosterColumn;
