import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
import { RootState } from 'typesafe-actions';

import { defaultOrganizationGuidSel, loginDataSel } from '@/modules/user/duck/selectors';

export const sentryMiddleware = createSentryMiddleware<RootState>(Sentry, {
  getUserContext: state => {
    const loginData = loginDataSel(state);
    if (!loginData) return {};
    const organizationGuid = defaultOrganizationGuidSel(state);
    const { account, membership } = loginData;
    const { username, userId } = account;
    const { memberId } = membership;
    const { personGuid } = loginData;

    return {
      username,
      userId,
      memberId,
      personGuid,
      organizationGuid,
    };
  },
  actionTransformer: ({ type }) => ({ type }),
});
