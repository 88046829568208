import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { moduleName } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const confirmRegistrationErrorSel = createSelector(
  moduleSel,
  ({ confirmRegistrationError }) => confirmRegistrationError,
);

export const confirmRegistrationInProgressSel = createSelector(
  moduleSel,
  ({ confirmRegistrationInProgress }) => confirmRegistrationInProgress,
);
