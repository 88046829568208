import dayjs from 'dayjs';
import { redirect } from 'redux-first-router';
import { combineEpics, Epic, StateObservable } from 'redux-observable';
import { concat, EMPTY, iif, merge, of, race, defer, Observable } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { RootAction, RootState, isActionOf } from 'typesafe-actions';

import { refreshData, removeData, scheduleRefresh } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { urlFormCodeSel } from '@/modules/location/duck/selectors';
import { CONFIRM_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal, updateModalParams } from '@/modules/modals/duck/actions';
import { formPartsSel } from '@/modules/questions/duck/selectors';
import { pageLoadCompleted } from '@/modules/routing/duck/actions';
import { cancelAction, confirmAction } from '@/modules/shared/components/ConfirmModal/duck/actions';
import { ModalParams as ConfirmModalParams } from '@/modules/shared/components/ConfirmModal/types';
import toastService from '@/modules/toasts/service';
import { personGuidSel as currentUserPersonGUIDSel } from '@/modules/user/duck/selectors';
import { ApiError } from '@/modules/utils/apiService';
import { dateFromString } from '@/modules/utils/dateFormats';

import { navigateToEvents } from '@/pages/eventList/duck/actions';
import { navigateToGroupReservationOverview } from '@/pages/reservation/duck/actions';

import { stepKeys } from '../constants';
import { stepDataParams as formsStepDataParams } from '../steps/AddAttendeeFormSubmission/constants';
import { stepDataSel as formsStepDataSel } from '../steps/AddAttendeeFormSubmission/duck/selectors';
import personalInformationEpics$ from '../steps/PersonalInformation/duck/epics';

import {
  addAttendee,
  disableStep,
  enableStep,
  openNextStep,
  openPrevStep,
  openStepCompleted,
} from './actions';
import {
  addAttendeeInitialDataSel,
  collectedFormPartsSel,
  currentStepIsDisabledSel,
  personGuidSel,
  futureStepKeySel,
  collectedRegistrationDataSel,
} from './selectors';
import services from './services';

export const stepGetObservable: Record<
  stepKeys,
  (action: Observable<RootAction>, state: StateObservable<RootState>) => Observable<RootAction>
> = {
  [stepKeys.personalInfo]: (action$, state$) => {
    const state = state$.value;

    const initialData = addAttendeeInitialDataSel(state);
    if (!initialData) return of(redirect(navigateToEvents()));

    return concat(
      merge(
        initialData.personExists
          ? prefetchData$(action$, {
              dataType: 'arnicaPerson',
              queryObj: { personGUID: initialData.personGUID },
            })
          : of(removeData({ dataType: 'arnicaPerson' })),
        prefetchData$(
          action$,
          { dataType: 'countries' },
          { dataType: 'states' },
          { dataType: 'councilList' },
        ),
      ),
      of(closeModal()),
    );
  },
  [stepKeys.forms]: (action$, state$) => {
    const state = state$.value;

    const initialData = addAttendeeInitialDataSel(state);

    if (!initialData) return of(redirect(navigateToEvents()));

    const formCode = urlFormCodeSel(state);
    const personGUID = personGuidSel(state);
    const currentUserPersonGUID = currentUserPersonGUIDSel(state);
    const { formRecordGUID } = initialData;

    return concat(
      prefetchData$(
        action$,
        ...formsStepDataParams.map(params => {
          switch (params.dataType) {
            case 'form':
            case 'visibilitySettings':
              return { ...params, queryObj: { formCode } };
            case 'attendeeDetails':
              if (!formRecordGUID) return null;
              return { ...params, queryObj: { formCode, formRecordGUID } };
            case 'formPartsData':
              if (formRecordGUID) return null;
              return {
                ...params,
                fetchData: true,
                queryObj: { formCode, applyCapacityFilter: true },
              };
            case 'optionSets':
              return {
                ...params,
                queryObj: { formCode, ...(formRecordGUID ? { formRecordGUID } : {}) },
              };
          }
        }),
      ),
      defer(() => {
        const { attendeeType } = initialData;
        const {
          data: { attendeeDetails, formPartsData, visibilitySettings },
        } = formsStepDataSel(state$.value);
        const formParts = formPartsSel(state$.value, {
          providedAttendeeType: attendeeType.typeCode,
          removeSystemFormParts: true,
          visibilitySettings,
          ...(formRecordGUID
            ? {
                attendeeDetails,
                formParts: attendeeDetails.formParts,
              }
            : {
                formParts: formPartsData.formParts,
              }),
        });

        if (!formParts.length) {
          return merge(
            action$.pipe(filter(isActionOf(pageLoadCompleted)), take(1)),
            of(
              disableStep({
                stepKey: stepKeys.forms,
                reason: 'No form required to fill',
              }),
              addAttendee.request(undefined),
            ),
          );
        }

        return merge(
          of(enableStep(stepKeys.forms)),
          prefetchData$(action$, {
            dataType: 'arnicaPerson',
            queryObj: { personGUID: personGUID || currentUserPersonGUID, useAkelaData: true },
          }),
        );
      }),
    );
  },
};

const openNextStep$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([openNextStep, openPrevStep])),
    switchMap(() => {
      const state = state$.value;
      const futureStepKey = futureStepKeySel(state);

      const stepObservable = stepGetObservable[futureStepKey];

      if (!stepObservable) {
        return of(openStepCompleted());
      }

      return concat(
        of(enableStep(futureStepKey)),
        stepObservable(action$, state$),
        of(openStepCompleted()),
      );
    }),
  );

const addAttendee$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addAttendee.request)),
    switchMap(({ payload }) => {
      const personGUID = personGuidSel(state$.value) as string;

      return concat(
        prefetchData$(action$, {
          dataType: 'arnicaPerson',
          queryObj: { personGUID, useAkelaData: true },
        }),
        defer(() => {
          const state = state$.value;
          const addAttendeeInitialData = addAttendeeInitialDataSel(state);
          const formParts = collectedFormPartsSel(state);
          const registrationData = collectedRegistrationDataSel(state);
          const person = createDataSel('arnicaPerson')(state);

          if (!addAttendeeInitialData) return EMPTY;

          const {
            formCode,
            rosterCode,
            attendeeType,
            reservationDetails,
            positionType,
            formRecordGUID,
          } = addAttendeeInitialData;

          const { sessionCode, sessionStartDate, groupReservationGUID, programName } =
            reservationDetails;
          const { typeCode, typeName, restrictions } = attendeeType;
          const { dateOfBirth, firstName, lastName, gender, memberId } = person;

          const mDateOfBirth = dateFromString(dateOfBirth);
          const mSessionStartDate = dateFromString(sessionStartDate);

          const age = dayjs().diff(mDateOfBirth, 'year');
          const ageOnSessionStart =
            mDateOfBirth.isValid() && mSessionStartDate.isValid()
              ? mSessionStartDate.diff(mDateOfBirth, 'years')
              : age;

          return iif(
            () => !!payload?.formRecordGUID,
            of({ responseValue: payload?.formRecordGUID }),
            services.updateFormRecord$({
              ...registrationData,
              formParts,
              formCode,
              personGUID,
              sessionCode,
              ...(formRecordGUID ? { formRecordGUID } : {}),
            }),
          ).pipe(
            switchMap(({ responseValue }) =>
              services
                .addAttendeeToRoster$(
                  {
                    formCode,
                    formRecordGUID: responseValue as string,
                    personGUID,
                    sessionCode,
                    rosterCode,
                    typeCode,
                    bypassValidation: payload?.bypassValidation,
                    ...(positionType === 'LeadAdvisor'
                      ? {
                          positionType,
                          status: 'Accept',
                        }
                      : {}),
                  },
                  response => response,
                )
                .pipe(
                  switchMap(({ responseCode, responseMessage }) => {
                    const nextState = state$.value;
                    const { isCreator, isCollaborator } = createDataSel('form')(nextState);

                    const successObservable = of(
                      closeModal(),
                      addAttendee.success(),
                      refreshData({ dataType: 'rosterDetails' }),
                      refreshData({ dataType: 'rosterAttendees' }),
                      scheduleRefresh({ dataType: 'groupReservation' }),
                      redirect(
                        navigateToGroupReservationOverview({
                          formCode,
                          groupReservationGUID,
                          keepRosterOpened: true,
                        }),
                      ),
                    );

                    if (['1', '2', '-10'].includes(responseCode)) {
                      const successMessage =
                        responseCode === '1' || formRecordGUID
                          ? 'Attendee succesfully added'
                          : 'Invitee was previously archived in this roster. Proceeding to restore.';

                      toastService.success(successMessage);

                      return successObservable;
                    }

                    const { minAge, maxAge } = restrictions;
                    const fullName = `${firstName} ${lastName}`;

                    const bypassValidationErrors: Record<string, string> = {
                      '-14': 'Failed to load person program data',
                      '-15': `Attendee type "${typeName}" must be an adult. ${fullName} is registered as youth in the BSA`,
                      '-16': `Attendee type "${typeName}" requires an active BSA membership. ${fullName} has a my.scouting account but does not have an active BSA membership.`,
                      '-17': `Attendee type “${typeName}” requires ${
                        gender === 'F' ? 'male' : 'female'
                      } attendees but ${fullName} is a ${gender === 'F' ? 'female' : 'male'}.`,
                      '-18': `The minimum age for attendee type “${typeName}” is ${minAge}, however ${fullName} will be age ${ageOnSessionStart} on the first day of the session.`,
                      '-19': `The maximum age for attendee type “${typeName}” is ${maxAge}, however ${fullName} will be age ${ageOnSessionStart} on the first day of the session.`,
                      '-20': `Attendee type “${typeName}” requires attendees be registered in the ${programName} program, however ${fullName} does not have an active BSA registration with member ID ${memberId} in this program.`,
                    };

                    const bypassValidationErrorMessage = bypassValidationErrors[responseCode];

                    if (bypassValidationErrorMessage && (isCreator || isCollaborator)) {
                      const modalParams: ConfirmModalParams = {
                        title: 'Add attendee anyway?',
                        warning: bypassValidationErrorMessage,
                        confirmButton: {
                          title: 'Override validation and add attendee',
                          type: 'default',
                          isDanger: true,
                        },
                      };

                      return merge(
                        race(
                          action$.pipe(
                            filter(isActionOf(confirmAction)),
                            take(1),
                            switchMap(() =>
                              of(
                                addAttendee.request({
                                  bypassValidation: true,
                                  formRecordGUID: responseValue as string,
                                }),
                                updateModalParams({ ...modalParams, inProgress: true }),
                              ),
                            ),
                          ),
                          action$.pipe(
                            filter(isActionOf(cancelAction)),
                            take(1),
                            switchMap(() => {
                              toastService.success(
                                'Validation overrided, attendee succesfully added',
                              );
                              return successObservable;
                            }),
                          ),
                        ),
                        of(openModal(CONFIRM_MODAL, modalParams), addAttendee.failure(undefined)),
                      );
                    }

                    throw new Error(responseMessage);
                  }),
                ),
            ),
          );
        }),
      );
    }),
    catchError((err: ApiError, caught) => {
      toastService.error(err.message);
      const currentStepIsDisabled = currentStepIsDisabledSel(state$.value);
      return merge(
        of(addAttendee.failure(err)),
        caught,
        currentStepIsDisabled ? of(openPrevStep()) : EMPTY,
      );
    }),
  );

export default combineEpics(personalInformationEpics$, addAttendee$, openNextStep$);
