import { RecordCommentRequestPayload } from '../types';

import apiService from 'UTILS/apiService';

const updateRecordComment$ = (data: RecordCommentRequestPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateFormRecordComment',
    data
  );

export default {
  updateRecordComment$,
};
