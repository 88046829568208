import { includes } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { QueryFilterVariable } from '@/modules/data/dataTypes/queryFilterVariableList';
import { optionSetsSel } from '@/modules/reports/duck/selectors';
import { OperatorValues } from '@/modules/reports/types';
import { isMultipleSelection } from '@/modules/reports/utils';
import { PresentationType } from '@/modules/shared/constants';
import { US_DATE_FORMAT } from '@/modules/utils/dateFormats';

import { operatorsObj } from '../../../../constants';

import { Select, DatePicker, Input } from './ValueInput.styled';

type Props = {
  selectedOperator?: OperatorValues;
  queryFilterVariable?: QueryFilterVariable;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
};

const ValueInput: React.FC<Props> = ({ selectedOperator, queryFilterVariable, ...rest }) => {
  const optionSets = useSelector(optionSetsSel);
  const presentationType = queryFilterVariable?.presentationType;
  const placeholder = 'Value';

  const hasOptions = includes(
    [
      PresentationType.DROPDOWN,
      PresentationType.RADIO,
      PresentationType.CHECKBOX,
      PresentationType.DROPDOWN_BULK,
    ],
    presentationType,
  );

  const isTextInput = selectedOperator == operatorsObj.startWith.value;

  const optionSetMatch =
    optionSets &&
    queryFilterVariable?.optionSetCode &&
    optionSets[queryFilterVariable.optionSetCode];

  if (
    queryFilterVariable &&
    hasOptions &&
    optionSetMatch &&
    optionSetMatch.optionItems.length > 0 &&
    !isTextInput
  ) {
    return (
      <Select
        {...rest}
        mode={isMultipleSelection(queryFilterVariable, selectedOperator) ? 'tags' : undefined}
        placeholder="Value"
      >
        {optionSetMatch.optionItems.map(({ itemLabel, itemValue }) => (
          <Select.Option key={itemValue} value={itemValue}>
            {itemLabel}
          </Select.Option>
        ))}
      </Select>
    );
  }

  switch (presentationType) {
    case PresentationType.DATE_PICKER:
      return <DatePicker {...rest} placeholder={placeholder} format={US_DATE_FORMAT} />;
    case PresentationType.SWITCH:
      return (
        <Select {...rest} placeholder={placeholder}>
          <Select.Option value="true">TRUE</Select.Option>
          <Select.Option value="false">FALSE</Select.Option>
        </Select>
      );
    default:
      return (
        <Input
          {...rest}
          disabled={!(presentationType || presentationType === 0)}
          placeholder={placeholder}
        />
      );
  }
};

export default ValueInput;
