import React from 'react';
import { useDispatch } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import Warning from '@/modules/shared/components/Warning';

import { cancelAction, confirmAction } from './duck/actions';
import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const ConfirmModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();

  const {
    title,
    description,
    warning,
    cancelButton,
    confirmButton,
    inProgress = false,
    confirmActions: confirmActionsArr,
    cancelActions: cancelActionsArr,
  } = modalParams;

  function handleConfirm() {
    dispatch(confirmAction());
    confirmActionsArr?.forEach(action => {
      dispatch(action);
    });
  }

  function handleCancel() {
    dispatch(cancelAction());
    cancelActionsArr?.forEach(action => {
      dispatch(action);
    });
  }

  return (
    <ModalComponent
      title={title}
      description={description}
      buttons={[
        {
          title: cancelButton?.title || 'Cancel',
          type: cancelButton?.type || 'default',
          danger: cancelButton?.isDanger,
          onClick: handleCancel,
        },
        {
          title: confirmButton?.title || 'Confirm',
          type: confirmButton?.type || 'primary',
          danger: confirmButton?.isDanger,
          onClick: handleConfirm,
        },
      ]}
      inProgress={inProgress}
    >
      {warning && <Warning errorMessage={warning} />}
    </ModalComponent>
  );
};

export default ConfirmModal;
