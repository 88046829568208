import { Button, Select as AntdSelect } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const OfferButton = styled(Button)`
  color: ${colors.lightBlueStatus};
  font-size: 14px;
  font-weight: bold;
`;

export const BannerLayout = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ $isMobile }) => ($isMobile ? 'flex-direction: column;' : '')}
`;

export const BannerLeftSide = styled.div<{ $fullWidth?: boolean }>`
  margin-right: 16px;
  max-width: ${({ $fullWidth }) => ($fullWidth ? 100 : 60)}%;
  flex-shrink: 0;
`;

export const BannerRightSide = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : 'row')};
  justify-content: ${({ $isMobile }) => ($isMobile ? 'flex-start' : 'flex-end')};
  align-items: center;
  gap: 24px;
  flex-grow: 1;
`;

export const Select = styled(AntdSelect)`
  width: 100%;
  max-width: 400px;

  & span.ant-select-arrow {
    & > span {
      margin-right: 0;
    }
  }

  & article {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
` as typeof AntdSelect;
