import dayjs from 'dayjs';

export default (csvContent: string, name = '', dateFormat = 'MM-DD-YYYY') => {
  // prevents downloads when running tests
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.Cypress) return;

  const csvName = `${name} ${dayjs().format(dateFormat)}.csv`.trim();
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvContent));
  element.setAttribute('download', csvName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
