import { Col, Form, Input, Row } from 'antd';
import React, { forwardRef, useImperativeHandle } from 'react';

import { AdditionalFieldsData } from '@/modules/payments/components/PaymentGateway/types';

import { CostCenterData } from './types';

const CostCenter = forwardRef<{ handleSubmit: () => Promise<AdditionalFieldsData> }>(
  (_props, ref) => {
    const [form] = Form.useForm<CostCenterData>();
    const handleSubmit = () => form.validateFields();
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    return (
      <Form form={form}>
        <Row>
          <Col md={12} sm={24}>
            <Form.Item
              name="costCenter"
              label="Cost Center to be Charged"
              rules={[
                {
                  required: true,
                  message: 'Cost Center is required',
                },
                {
                  max: 100,
                  type: 'string',
                  message: 'Maximum 100 characters allowed',
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  },
);

export default CostCenter;
