import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import React from 'react';

import { ReportDataType } from '@/modules/reports/types';

import { Path } from '../../types';

import {
  SectionWrapper,
  AddFilterButton,
  Divider,
  AddSectionButton,
  Line,
  FieldsWrapper,
} from './ExpressionList.styled';
import Expression from './components/Expression';
import ExpressionListItemWrapper from './components/ExpressionListItemWrapper';
import SelectSectionCondition from './components/SelectSectionCondition';

const EXPRESSION_LIST_KEY = 'expressionList';

type Props = {
  path?: Path;
  name?: (number | string)[];
  form: FormInstance;
  dataType: ReportDataType;
};

const ExpressionList: React.FC<Props> = ({
  name = EXPRESSION_LIST_KEY,
  path = [EXPRESSION_LIST_KEY],
  form,
  dataType,
}) => (
  <Form.List
    name={name}
    rules={[
      {
        validator: async (_, values) => {
          if (!values || !values.length) {
            throw Error("Filters section can't be empty");
          }
        },
      },
    ]}
  >
    {(fields, { add, remove }) => (
      <FieldsWrapper>
        {fields.map(field => {
          const fieldPath = [...path, field.name];
          const expression = form.getFieldValue(fieldPath);

          return (
            <ExpressionListItemWrapper
              key={field.key}
              name={field.name}
              showRemoveButton={fields.length > 1}
              onRemove={remove}
            >
              {expression && EXPRESSION_LIST_KEY in expression ? (
                <SectionWrapper>
                  <SelectSectionCondition name={field.name} />
                  <ExpressionList
                    name={[field.name, EXPRESSION_LIST_KEY]}
                    path={[...fieldPath, EXPRESSION_LIST_KEY]}
                    form={form}
                    dataType={dataType}
                  />
                </SectionWrapper>
              ) : (
                <Expression name={field.name} path={fieldPath} form={form} dataType={dataType} />
              )}
            </ExpressionListItemWrapper>
          );
        })}
        <AddFilterButton
          onClick={() => {
            add();
          }}
        >
          Add Filter
        </AddFilterButton>
        <Divider />
        <AddSectionButton
          onClick={() => {
            add({ [EXPRESSION_LIST_KEY]: [{}] });
          }}
        >
          Add Filters Section
        </AddSectionButton>
        <Line />
      </FieldsWrapper>
    )}
  </Form.List>
);
export default ExpressionList;
