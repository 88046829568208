import { moduleName as parentModuleName } from '../../constants';

export const moduleName = 'ledgerAccounts';
export const modulePath = [parentModuleName, moduleName];

export const ACCOUNT_CODE = 'accountCode';
export const ACCOUNT_NAME = 'accountName';
export const ACCOUNT_DESCRIPTION = 'description';
export const ACCOUNT_NUMBER = 'accountNumber';
export const ACCOUNT_COST_CENTER = 'costCenter';
export const ACCOUNT_SESSIONS = 'sessionCount';

export const ledgerColumns = [
  {
    dataIndex: ACCOUNT_NAME,
    title: 'General Ledger Account Name',
  },
  {
    dataIndex: ACCOUNT_COST_CENTER,
    title: 'Cost Center',
  },
  {
    dataIndex: ACCOUNT_NUMBER,
    title: 'Account Number',
  },
  {
    dataIndex: ACCOUNT_SESSIONS,
    title: 'Session Count',
  },
];
