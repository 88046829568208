import { Button } from 'antd';
import React, { Fragment, useMemo } from 'react';

import { Payment } from '@/modules/data/dataTypes/paymentList';
import { Addon, PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import Currency from '@/modules/shared/components/Currency';
import Divider from '@/modules/shared/components/Divider';

import Row from '../Row';

import styles from './MakePayment.less';
import CheckoutPayments from './components/CheckoutPayments';

type Props = {
  paymentSummary: PaymentSummary;
  providedActivitiesSubtotal: number | null;
  providedAddonsSubtotal: number | null;
  existingSelectedActivitiesFee: number;
  existingSelectedAddonsFee: number;
  paymentCategoriesAmount: number | null;
  providedAddons: Omit<Addon, 'formRecordAddonGUID' | 'groupReservationAddonGUID'>[] | null;
  tooltipText: string;
  isLoading: boolean;
  hideMakePaymentButton: boolean;
  payments?: Payment[];
  onMakePayment: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const MakePayment: React.FC<Props> = ({
  paymentSummary,
  providedActivitiesSubtotal,
  providedAddonsSubtotal,
  existingSelectedActivitiesFee,
  existingSelectedAddonsFee,
  paymentCategoriesAmount,
  providedAddons,
  tooltipText,
  onMakePayment,
  isLoading,
  hideMakePaymentButton,
  payments,
}) => {
  const dueTodayAmount = useMemo(() => {
    const { nextPaymentAmountDue, selectedDepositAddonFee } = paymentSummary;
    if (paymentCategoriesAmount === null || paymentCategoriesAmount > 1) {
      if (!providedAddons) return nextPaymentAmountDue;

      const providedAddonsPaidWithDepositFee = providedAddons
        .filter(({ isPaidWithDeposit }) => isPaidWithDeposit)
        .reduce((acc, { selectedOptionFee }) => acc + (selectedOptionFee || 0), 0);

      return nextPaymentAmountDue + providedAddonsPaidWithDepositFee - selectedDepositAddonFee;
    }

    return (
      nextPaymentAmountDue +
      (typeof providedActivitiesSubtotal === 'number'
        ? providedActivitiesSubtotal - existingSelectedActivitiesFee
        : 0) +
      (typeof providedAddonsSubtotal === 'number'
        ? providedAddonsSubtotal - existingSelectedAddonsFee
        : 0)
    );
  }, [
    paymentSummary,
    existingSelectedActivitiesFee,
    existingSelectedAddonsFee,
    paymentCategoriesAmount,
    providedActivitiesSubtotal,
    providedAddons,
    providedAddonsSubtotal,
  ]);

  const totalEventFee = useMemo(() => {
    const { totalFee } = paymentSummary;
    return (
      totalFee +
      (typeof providedActivitiesSubtotal === 'number'
        ? providedActivitiesSubtotal - existingSelectedActivitiesFee
        : 0) +
      (typeof providedAddonsSubtotal === 'number'
        ? providedAddonsSubtotal - existingSelectedAddonsFee
        : 0)
    );
  }, [
    paymentSummary,
    providedActivitiesSubtotal,
    existingSelectedActivitiesFee,
    providedAddonsSubtotal,
    existingSelectedAddonsFee,
  ]);

  return (
    <Fragment>
      <Divider />
      <Row
        columns={['Total Event Fee', <Currency key="amount" value={totalEventFee} />]}
        isHeader
      />
      <Divider />
      <Row
        columns={['Due Today', <Currency key="amount" value={dueTodayAmount} />]}
        tooltipText={tooltipText}
        isHeader
      />
      {payments && <CheckoutPayments payments={payments} />}
      {!hideMakePaymentButton && (
        <div className={styles.buttonWrapper}>
          <Button type="primary" onClick={onMakePayment} loading={isLoading}>
            Make Payment
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default MakePayment;
