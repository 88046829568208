import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  cancelGroupReservation,
  cancelIndividualReservation,
  updateGroupReservation,
} from './actions';

const isCancelingGroupReservation = createReducer(false)
  .handleAction(cancelGroupReservation.request, () => true)
  .handleAction([cancelGroupReservation.success, cancelGroupReservation.failure], () => false);

const cancelIndividualReservationInProgress = createReducer(false)
  .handleAction(cancelIndividualReservation.request, () => true)
  .handleAction(
    [cancelIndividualReservation.success, cancelIndividualReservation.failure],
    () => false,
  );

const isUpdatingGroupReservation = createReducer(false)
  .handleAction(updateGroupReservation.request, () => true)
  .handleAction([updateGroupReservation.success, updateGroupReservation.failure], () => false);

export default combineReducers({
  isCancelingGroupReservation,
  cancelIndividualReservationInProgress,
  isUpdatingGroupReservation,
});
