import React from 'react';

import { Warning, WarningsContainer } from './SessionWarnings.styled';

interface Props {
  isGroupEvent?: boolean;
  showNoCrewCapacityMessage?: boolean;
  showNoPersonCapacityMessage?: boolean;
}

const SessionWarnings: React.FC<Props> = ({
  isGroupEvent,
  showNoCrewCapacityMessage,
  showNoPersonCapacityMessage,
}) => (
  <WarningsContainer>
    {showNoPersonCapacityMessage && (
      <Warning
        errorMessage="The new session you selected doesn't have enough person capacity."
        showWarningText={false}
      />
    )}
    {isGroupEvent && showNoCrewCapacityMessage && (
      <Warning
        errorMessage="The new session you selected doesn't have enough crew capacity."
        showWarningText={false}
      />
    )}
    {isGroupEvent && (
      <Warning
        errorMessage="All attendees present in current rosters will be automatically moved to rosters in the new session."
        showWarningText={false}
      />
    )}
  </WarningsContainer>
);

export default SessionWarnings;
