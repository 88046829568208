export const safeDecodeURIComponent = (text = ''): string => {
  if (!text) {
    return text;
  }
  let result;

  try {
    result = decodeURIComponent(text.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
  } catch (e) {
    result = '';
  }

  return result;
};

export const parseBase64 = <T>(base64Value?: string): T | undefined => {
  if (!base64Value) {
    return undefined;
  }
  let settings;
  try {
    settings = JSON.parse(window.atob(base64Value));
  } catch (e) {
    settings = base64Value;
  }

  return settings;
};
