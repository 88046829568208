import React from 'react';
import { useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { createIsLoadingSel } from '@/modules/data/duck/selectors';
import {
  CardWithWiderHeader,
  TitleWrapper,
} from '@/modules/shared/components/EditableFormSection/EditableFormSection.styled';
import ItemMapper from '@/modules/shared/components/ItemMapper';

type Props = {
  attendeeDetails: AttendeeDetails;
};

const PrimaryRegistrant: React.FC<Props> = ({ attendeeDetails }) => {
  const isLoadingAttendeeDetails = useSelector(createIsLoadingSel('attendeeDetails'));
  return (
    <CardWithWiderHeader
      header={
        <TitleWrapper>
          <span>Primary Registrant</span>
        </TitleWrapper>
      }
      loading={isLoadingAttendeeDetails}
    >
      <ItemMapper
        items={[
          { label: 'Name', value: attendeeDetails.primaryRegistrantName },
          { label: 'Reservation Code', value: attendeeDetails.primaryRegistrantReservationCode },
          { label: 'Attendee Type', value: attendeeDetails.primaryRegistrantTypeName },
        ]}
      />
    </CardWithWiderHeader>
  );
};

export default PrimaryRegistrant;
