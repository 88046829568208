import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { Payment } from '@/modules/data/dataTypes/paymentList';
import { PaymentSummary } from '@/modules/data/dataTypes/paymentSummary';
import { MAKE_REFUND_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import { MakeRefundModalParams } from '@/modules/payments/components/MakeRefundModal/types';
import Desktop from '@/modules/responsiveSizes/Desktop';
import Mobile from '@/modules/responsiveSizes/Mobile';

import PaymentHistoryList from './components/PaymentHistoryList';
import PaymentHistoryTable from './components/PaymentHistoryTable';

type Props = {
  reservationPayments: Payment[];
  formSettings: FormSettings;
  errorMessage?: string;
  paymentSummary: PaymentSummary;
  canPostRefunds: boolean;
  canUpdatePaymentStatus: boolean;
} & (
  | { isGroup: true; groupReservation: GroupReservation }
  | { isGroup: false; attendeeDetails: AttendeeDetails }
);

const PaymentHistoryTab: React.FC<Props> = props => {
  const {
    reservationPayments,
    formSettings,
    errorMessage,
    paymentSummary,
    isGroup,
    canPostRefunds,
    canUpdatePaymentStatus,
  } = props;
  const dispatch = useDispatch();

  const guid = isGroup
    ? props.groupReservation.groupReservationGUID
    : props.attendeeDetails.formRecordGUID;

  const handleMakeRefund = (payment?: Payment) => {
    if (!formSettings) return null;

    const modalParams: MakeRefundModalParams = {
      ...(payment ? { paymentRecord: payment } : { amountPaid: paymentSummary.amountPaid }),
      isGroup,
      guid,
      expeditionNumber: isGroup
        ? props.groupReservation.groupReservationExpeditionNumber
        : undefined,
      formCode: formSettings?.formCode,
    };
    dispatch(openModal(MAKE_REFUND_MODAL, modalParams));
  };

  return (
    <Fragment>
      <Desktop>
        <PaymentHistoryTable
          canPostRefunds={canPostRefunds}
          canUpdatePaymentStatus={canUpdatePaymentStatus}
          reservationPayments={reservationPayments}
          formSettings={formSettings}
          errorMessage={errorMessage}
          isGroup={isGroup}
          guid={guid}
          paymentSummary={paymentSummary}
          onMakeRefund={handleMakeRefund}
        />
      </Desktop>
      <Mobile>
        <PaymentHistoryList
          canUpdatePaymentStatus={canUpdatePaymentStatus}
          canPostRefunds={canPostRefunds}
          reservationPayments={reservationPayments}
          formSettings={formSettings}
          errorMessage={errorMessage}
          onMakeRefund={handleMakeRefund}
        />
      </Mobile>
    </Fragment>
  );
};

export default PaymentHistoryTab;
