import { redirect } from 'redux-first-router';
import { StateObservable } from 'redux-observable';
import { of, concat, Observable } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { RootAction, RootState } from 'typesafe-actions';

import { setFilters } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createFiltersSel } from '@/modules/data/duck/selectors';
import { contextTokenSel, urlFormCodeSel } from '@/modules/location/duck/selectors';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { navigateToEventPage } from '@/pages/event/duck/actions';
import { tabFiltersSel } from '@/pages/eventList/duck/selectors';

import { ROUTE_EVENTS, ROUTE_VALIDATE_COLLABORATOR_INVITE } from './constants';
import { navigateToEvents } from './duck/actions';
import services from './duck/services';

import EventList from '.';

const routes = {
  [ROUTE_EVENTS]: {
    path: '/events',
    component: EventList,
    titleId: 'page.events',
    unAuthenticatedAccess: true,
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      let defaultFilters = createFiltersSel('personFormList')(state$.value);

      if (!Object.keys(defaultFilters).length) {
        defaultFilters = tabFiltersSel(state$.value);
      }

      return concat(
        of(setFilters({ dataType: 'personFormList', filters: defaultFilters })),
        prefetchData$(action$, { dataType: 'personFormList' }),
      );
    },
  },
  [ROUTE_VALIDATE_COLLABORATOR_INVITE]: {
    path: '/events/:formCode/collaborator/:contextToken',
    component: EventList,
    titleId: 'page.events',
    unAuthenticatedAccess: true,
    getObservable: (
      _action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const state = state$.value;
      const formCode = urlFormCodeSel(state);
      const contextToken = contextTokenSel(state);

      return services.validateCollaboratorInvite$(formCode, contextToken).pipe(
        switchMap(() => {
          toastService.success(
            'Congratulations! Your collaborator invite was successfully validated.',
          );
          return of(redirect(navigateToEventPage({ formCode })));
        }),
        catchError((err: ApiError) => {
          if (err.responseCode === '-10') {
            toastService.error(
              'This invitation has expired or already been used. Please check your collaborator access or contact the event owner to resend your invitation.',
            );
            return of(redirect(navigateToEvents()));
          }

          toastService.error('Something went wrong with your collaborator invite.');
          return of(redirect(navigateToEvents()));
        }),
      );
    },
  },
};

export default routes;
