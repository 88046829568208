import { MergeCellsOutlined, SwapOutlined, UploadOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import ModalComponent from '../modals/components/ModalComponent';
import Steps from '../steps';

import { moduleName } from './constants';
import { importAnswersInProgressSel } from './duck/selectors';
import MapQuestions from './steps/MapQuestions';
import { createGetStepObservable as createMapQuestionsGetStepObservable } from './steps/MapQuestions/duck/epics';
import SelectAnswers from './steps/SelectAnswers';
import { createGetStepObservable as createSelectAnswersGetStepObservable } from './steps/SelectAnswers/duck/epics';
import UploadFile from './steps/UploadFile';
import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const UploadQuestionsModal: React.FC<Props> = ({ modalParams: { formCode } }) => {
  const importAnswersInProgress = useSelector(importAnswersInProgressSel);
  const steps = useMemo(
    () => [
      {
        title: 'Upload File',
        icon: <UploadOutlined />,
        Component: UploadFile,
      },
      {
        title: 'Map Questions',
        icon: <SwapOutlined />,
        Component: MapQuestions,
        getObservable: createMapQuestionsGetStepObservable(formCode),
      },
      {
        title: 'Select Answers',
        icon: <MergeCellsOutlined />,
        Component: SelectAnswers,
        getObservable: createSelectAnswersGetStepObservable(formCode),
      },
    ],
    [formCode],
  );

  return (
    <ModalComponent title="Import Answers">
      <Steps steps={steps} moduleName={moduleName} isLoading={importAnswersInProgress} />
    </ModalComponent>
  );
};

export default UploadQuestionsModal;
