import { Row, Button } from 'antd';
import React, { Fragment, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';

import { refreshData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import ActivitiesSelector from '@/modules/entities/Activities/components/ActivitiesSelector';
import { saveActivitiesInProgressSel } from '@/modules/entities/Activities/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { urlGroupReservationGUIDSel, urlFormCodeSel } from '@/modules/location/duck/selectors';
import EventCover from '@/modules/shared/components/EventCover';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { navigateToGroupReservationOverview } from '@/pages/reservation/duck/actions';

import { StyledCard, Title } from './EditGroupReservationActivitiesPage.styled';

const EditActivitiesPage: React.FC = () => {
  const dispatch = useDispatch();
  const activitiesSelectorRef = useRef<{ handleSubmit: () => void }>(null);
  const activities = useSelector(createDataSel('activities'));
  const isLoading = useSelector(saveActivitiesInProgressSel);
  const formCode = useSelector(urlFormCodeSel);
  const groupReservationGUID = useSelector(urlGroupReservationGUIDSel);
  const { sessionName, sessionStartDate, sessionCloseDate, groupReservationExpeditionNumber } =
    useSelector(createDataSel('groupReservation'));
  const groupReservationActivities = useSelector(createDataSel('groupReservationActivities'));

  const handleBack = () => {
    dispatch(redirect(navigateToGroupReservationOverview({ formCode, groupReservationGUID })));
  };

  const handleSubmit = () => {
    activitiesSelectorRef.current?.handleSubmit();
  };

  const canUpdateSelections = useSelector(
    createAbilitiesSelector('attendeeSelections', PermissionAction.Update, null),
  );

  if (!canUpdateSelections) {
    return (
      <StyledCard header="Activities">
        <div>You do not have access</div>
      </StyledCard>
    );
  }

  return (
    <Fragment>
      <EventCover title={sessionName} dates={[sessionStartDate, sessionCloseDate]} />
      <Title>Editing activities for Reservation {groupReservationExpeditionNumber}</Title>
      <ActivitiesSelector
        isGroup
        ref={activitiesSelectorRef}
        groupReservationGUID={groupReservationGUID}
        availableActivities={activities}
        selectedActivities={groupReservationActivities}
        finalActions={[
          refreshData({ dataType: 'groupReservation' }),
          refreshData({ dataType: 'groupReservationActivities' }),
          refreshData({ dataType: 'paymentSummary' }),
          redirect(navigateToGroupReservationOverview({ formCode, groupReservationGUID })),
        ]}
      />
      <Row justify="space-between">
        <Button disabled={isLoading} onClick={handleBack} type="default" id="onBackButton">
          CANCEL
        </Button>
        <Button disabled={isLoading} loading={isLoading} onClick={handleSubmit} type="primary">
          CONFIRM CHANGES
        </Button>
      </Row>
    </Fragment>
  );
};

export default EditActivitiesPage;
