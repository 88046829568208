import React from 'react';

import { Session } from '@/modules/data/dataTypes/sessionList';
import { formattedDatesRange } from '@/modules/utils/dateFormats';

import { Container, Tag, Typography } from './SessionOptionItem.styled';

interface Props {
  session: Session;
  isGroupEvent?: boolean;
  isSelectOpen?: boolean;
}

const SessionOptionItem: React.FC<Props> = ({ session, isGroupEvent, isSelectOpen }) => {
  const {
    attendeeCapacity,
    closeDate,
    isSessionFull,
    sessionName,
    startDate,
    maximumRosterCount = 0,
    registeredPersonCount = 0,
    totalInitialPeopleCount = 0,
    totalInitialRosterCount = 0,
  } = session;

  const sessionDates = formattedDatesRange(startDate, closeDate);
  const sessionNameAndDates = `${sessionName} - ${sessionDates}`;
  const groupSessionLabel = `${sessionNameAndDates} - Persons Booked: ${totalInitialPeopleCount}/${attendeeCapacity} - Crews Booked: ${totalInitialRosterCount}/${maximumRosterCount}`;
  const individualSessionLabel = `${sessionNameAndDates} - Persons Registered: ${registeredPersonCount}/${attendeeCapacity}`;

  return (
    <Container>
      <Typography $isSelectOpen={isSelectOpen}>
        {isGroupEvent ? groupSessionLabel : individualSessionLabel}
      </Typography>
      {isSessionFull && <Tag>SESSION FULL</Tag>}
    </Container>
  );
};

export default SessionOptionItem;
