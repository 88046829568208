export const moduleName = 'eventList';

export const ROUTE_EVENTS = `${moduleName}/ROUTE_EVENTS`;
export const ROUTE_VALIDATE_COLLABORATOR_INVITE = `${moduleName}/ROUTE_VALIDATE_COLLABORATOR_INVITE`;

type Tab = {
  key: string;
  tab: string;
  forCollaboratorOnly: boolean;
  allowRegister: boolean;
  filters?: {
    [key: string]: number;
  };
};

export const tabs: Tab[] = [
  {
    key: 'future',
    tab: 'Discover',
    forCollaboratorOnly: false,
    allowRegister: true,
    filters: {
      isFutureEventsOnly: 1,
      isRegistrationCompleted: 0,
      isPublished: 1,
      isCancelled: 0,
      isActive: 1,
      isHidden: 0,
    },
  },
  {
    key: 'myEvents',
    tab: 'My Events',
    forCollaboratorOnly: false,
    allowRegister: true,
    filters: {
      isRegistered: 1,
      isActive: 1,
      isPublished: 1,
    },
  },
  {
    key: 'past',
    tab: 'Past Events',
    allowRegister: false,
    forCollaboratorOnly: true,
    filters: {
      isClosedEventsOnly: 1,
    },
  },
  {
    key: 'unpublished',
    tab: 'Unpublished Events',
    allowRegister: false,
    forCollaboratorOnly: true,
    filters: {
      isPublished: 0,
      isActive: 1,
    },
  },
  {
    key: 'archived',
    tab: 'Archived Events',
    allowRegister: false,
    forCollaboratorOnly: true,
    filters: {
      isActive: 0,
    },
  },
  {
    key: 'full',
    tab: 'All Events',
    allowRegister: false,
    forCollaboratorOnly: true,
  },
];
