import { Checkbox, Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import type { BasePayloadActivity } from '@/modules/entities/Activities/types';
import toastService from '@/modules/toasts/service';

import { Container, Content, InputNumber, Label, Name } from './Activity.styled';
import Duration from './components/Duration';

type Props = {
  activity: BasePayloadActivity;
  overlapActivitiesList: string;
  canEditDisabledActivities?: boolean;
  onInclude: (activity: string, quantity: number) => void;
};

const Activity: React.FC<Props> = ({
  activity,
  canEditDisabledActivities,
  overlapActivitiesList,
  onInclude,
}) => {
  const { allowGroupRegistration, preventOverlappingActivities } = useSelector(
    createDataSel('form'),
  );
  const currentReservation = useSelector(createDataSel('groupReservation'));
  const {
    quantity,
    isMandatory,
    formRecordActivityCount,
    maximumSelectionCount,
    maximumPeopleCount,
    maximumRosterCount = 0,
    rosterActivityCount = 0,
  } = activity;

  const handleCheck = (e: CheckboxChangeEvent) => {
    onInclude(activity.activitySessionGUID, e.target.checked ? 1 : 0);
  };

  const handleSelectQuantity = (nextQuantity: number | null) => {
    let validatedQuantity = nextQuantity;
    if (validatedQuantity === null) {
      validatedQuantity = 0;
    } else if (validatedQuantity > maximumSelectionCount) {
      toastService.error(`You can't select more than ${maximumSelectionCount} activities`);
      validatedQuantity = maximumSelectionCount;
    }
    onInclude(activity.activitySessionGUID, validatedQuantity);
  };

  const hasSlotsLeft = useMemo(() => {
    if (!allowGroupRegistration) return formRecordActivityCount < maximumPeopleCount;

    if (!currentReservation) return false;

    const { initialPeopleCount, initialRosterCount } = currentReservation;
    const hasCrewSlotsLeft = rosterActivityCount + initialRosterCount <= maximumRosterCount;
    const hasPersonSlotsLeft = formRecordActivityCount + initialPeopleCount <= maximumPeopleCount;

    return hasCrewSlotsLeft && hasPersonSlotsLeft;
  }, [
    allowGroupRegistration,
    currentReservation,
    formRecordActivityCount,
    maximumPeopleCount,
    maximumRosterCount,
    rosterActivityCount,
  ]);

  const areActivitiesOverlapping = preventOverlappingActivities && !!overlapActivitiesList;

  const isDisabled = !canEditDisabledActivities
    ? isMandatory || !hasSlotsLeft || areActivitiesOverlapping
    : false;

  const showCheckbox = maximumSelectionCount === 1;

  return (
    <Container isDisabled={isDisabled}>
      <Duration endDate={activity.finishDate} startDate={activity.startDate} />
      {showCheckbox && (
        <Checkbox checked={activity.quantity > 0} disabled={isDisabled} onChange={handleCheck} />
      )}
      <Content $hasOffset={showCheckbox}>
        <Name>{`${activity.activityName}${isMandatory ? ' (required)' : ''}`}</Name>
        {!showCheckbox && (
          <Row>
            <Col span={12}>
              <Label label="Select desired quantity">
                <InputNumber
                  controls={false}
                  precision={0}
                  onChange={handleSelectQuantity}
                  value={quantity}
                  disabled={isDisabled}
                  min={0}
                />
              </Label>
            </Col>
          </Row>
        )}
        <p>{activity.description}</p>
        {!hasSlotsLeft && <p>No slots left</p>}
        {areActivitiesOverlapping && (
          <div>This activity cannot be selected alongside {overlapActivitiesList}</div>
        )}
      </Content>
    </Container>
  );
};

export default Activity;
