import styled from 'styled-components';

import LoadingContainer from '@/modules/shared/components/LoadingContainer';

export const Container = styled(LoadingContainer)`
  padding: 0 1rem;
  max-height: 800px;
  &&& .react-json-view {
    max-height: 600px;
    overflow: auto;
  }
`;
