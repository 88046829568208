import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeData } from '@/modules/data/duck/actions';
import { createDataSel, createIsLoadingSel } from '@/modules/data/duck/selectors';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import SlidePanel from '@/modules/shared/components/SlidePanel';
import { memberIdSel } from '@/modules/user/duck/selectors';

import { pageDataSel } from '@/pages/reservation/duck/selectors';

import { rosterSel } from '../../duck/selectors';

import { RosterPageCard, RosterName } from './RosterPage.styled';
import BackButton from './components/BackButton';
import CrewAttendees from './components/CrewAttendees';
import RosterDescription from './components/RosterDescription';
import RostersListPage from './components/RostersListPage';
import { ROSTERS_DATA_ID_TRANSFER_ATTENDEES } from './constants';
import { selectedAttendeesByPersonGuidSel, selectedAttendeesIdsSel } from './duck/selectors';

const RosterPage: React.FC = () => {
  const dispatch = useDispatch();
  const {
    data: { form, groupReservation },
  } = useSelector(pageDataSel);

  const roster = useSelector(rosterSel);
  const selectedAttendees = useSelector(selectedAttendeesByPersonGuidSel);
  const selectedAttendeesPersonGuids = useSelector(selectedAttendeesIdsSel) || [];
  const userID = useSelector(memberIdSel);
  const rostersListForTransferAttendees = useSelector(
    createDataSel('rosterList', ROSTERS_DATA_ID_TRANSFER_ATTENDEES),
  );
  const rosterDetailsIsLoading = useSelector(createIsLoadingSel('rosterDetails'));
  const rosterAttendeesIsLoading = useSelector(createIsLoadingSel('rosterAttendees'));
  const rostersListIsLoading = useSelector(
    createIsLoadingSel('rosterList', ROSTERS_DATA_ID_TRANSFER_ATTENDEES),
  );

  const { isCreator, isCollaborator } = form;
  const { isReservationContact, isAdditionalReservationContact } = groupReservation;

  const rostersListForTransferWithoutCurrent = useMemo(
    () =>
      rostersListForTransferAttendees?.filter(
        ({ rosterCode }) => rosterCode !== roster?.rosterCode,
      ),
    [rostersListForTransferAttendees, roster],
  );

  useEffect(() => {
    if (!roster) return;
    const appDiv = document.getElementById('app');
    appDiv?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [roster]);

  const showRostersList =
    (isCreator || isCollaborator) &&
    !!rostersListForTransferWithoutCurrent &&
    !!rostersListForTransferWithoutCurrent.length;

  if (!roster) return null;

  const handleCloseRoster = () => {
    dispatch(removeData({ dataType: 'rosterDetails' }));
    dispatch(removeData({ dataType: 'rosterAttendees' }));
  };

  const handleCloseRostersList = () => {
    dispatch(removeData({ dataType: 'rosterList', dataId: ROSTERS_DATA_ID_TRANSFER_ATTENDEES }));
  };

  const { rosterExpeditionNumber, description, attendeeTypes, leadAdvisorMemberID } = roster;

  const isLeadAdvisor = leadAdvisorMemberID === userID;
  const canEditParticipant =
    isCreator ||
    isCollaborator ||
    isReservationContact ||
    isAdditionalReservationContact ||
    isLeadAdvisor;

  if (!attendeeTypes) return null;

  return (
    <SlidePanel
      collapsed={!showRostersList}
      hideCloseButton
      content={
        <RostersListPage
          onCancel={handleCloseRostersList}
          rosters={rostersListForTransferWithoutCurrent}
        />
      }
    >
      <LoadingContainer
        isLoading={rostersListIsLoading || rosterDetailsIsLoading || rosterAttendeesIsLoading}
      >
        <BackButton onClick={handleCloseRoster}>Back to: Crews List</BackButton>
        <RosterPageCard>
          <RosterName>Crew Number: {rosterExpeditionNumber}</RosterName>
          <RosterDescription description={description} />
          <CrewAttendees
            formSettings={form}
            reservationDetails={groupReservation}
            roster={roster}
            canEditParticipant={canEditParticipant}
            selectedAttendees={selectedAttendees}
            selectedAttendeesPersonGuids={selectedAttendeesPersonGuids}
            isLeadAdvisor={isLeadAdvisor}
          />
        </RosterPageCard>
      </LoadingContainer>
    </SlidePanel>
  );
};

export default RosterPage;
