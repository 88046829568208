import { createAction, createAsyncAction } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { FormattedFormParts } from '@/modules/questions/types';
import { ApiError } from '@/modules/utils/apiService';
import { ValueOf } from '@/modules/utils/typeUtils';

import { modulePath, secondaryRegistrantStepKeys } from '../constants';
const moduleNamespace = modulePath.join('/');

export const openStep = createAction(`${moduleNamespace}/OPEN_STEP`)();
export const openPrevStep = createAction(`${moduleNamespace}/OPEN_PREV_STEP`)();
export const openNextStep = createAction(`${moduleNamespace}/OPEN_NEXT_STEP`)();

export const openStepCompleted = createAction(`${moduleNamespace}/OPEN_STEP_COMPLETED`)();

export const disableStep = createAction(`${moduleNamespace}/DISABLE_STEP`)<{
  stepKey: ValueOf<typeof secondaryRegistrantStepKeys>;
  reason: string;
}>();
export const enableStep = createAction(`${moduleNamespace}/ENABLE_STEP`)<
  ValueOf<typeof secondaryRegistrantStepKeys>
>();

export const collectFormParts = createAction(
  `${moduleNamespace}/COLLECT_FORM_PARTS`,
)<FormattedFormParts>();

export const collectRegistrationData = createAction(`${moduleNamespace}/COLLECT_REGISTRATION_DATA`)<
  Partial<AttendeeDetails>
>();

export const addAdditionalRegistrant = createAsyncAction(
  `${moduleNamespace}/ADD_ADDITIONAL_REGISTRANT_REQUEST`,
  `${moduleNamespace}/ADD_ADDITIONAL_REGISTRANT_SUCCESS`,
  `${moduleNamespace}/ADD_ADDITIONAL_REGISTRANT_FAILURE`,
)<string, string, ApiError>();
