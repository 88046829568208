// TODO: this is a temporary workaround for eslint until bsa-ui is fixed
/* eslint-disable import/named */
import { DatePicker as BsaDatePicker, DatePickerProps, TimePickerProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import cn from 'classnames';
import dayjs from 'dayjs';
import React from 'react';

import styles from './DatePicker.less';
import { DatePickerComponent } from './DatePicker.styled';

import L from 'SHARED/components/L';

type Props = DatePickerProps & {
  fluid?: boolean;
  className?: string;
  labelContent?: React.ReactNode;
  disabled?: boolean;
  showTime?: boolean | TimePickerProps;
  children?: React.ReactNode;
  format?: string | string[];
  disabledDate?: (currentDate: dayjs.Dayjs) => boolean;
};

const DatePicker: React.FC<Props> & {
  RangePicker: typeof BsaDatePicker.RangePicker;
  MonthPicker: typeof BsaDatePicker.MonthPicker;
  WeekPicker: typeof BsaDatePicker.WeekPicker;
} = ({ fluid, className, format, labelContent, disabledDate, ...props }) => {
  const classNames = cn(
    {
      [styles.fluid]: fluid,
    },
    className,
  );

  const handleDisableDates: RangePickerProps['disabledDate'] = current => {
    const minimumDateAllowed = new Date(1700, 0, 1);
    if (current && current < dayjs(minimumDateAllowed)) {
      return true;
    }

    if (disabledDate) {
      return disabledDate(current);
    }

    return false;
  };

  return (
    <div
      className={cn(styles.datePicker, {
        [styles.materialInput]: !!labelContent,
      })}
    >
      {labelContent && <L size="1">{labelContent}</L>}
      <DatePickerComponent
        className={classNames}
        popupClassName={cn({
          [styles.hollowCurrentDay]: !props.value,
        })}
        format={format}
        size="large"
        disabledDate={handleDisableDates}
        {...props}
      />
    </div>
  );
};

DatePicker.RangePicker = BsaDatePicker.RangePicker;
DatePicker.MonthPicker = BsaDatePicker.MonthPicker;
DatePicker.WeekPicker = BsaDatePicker.WeekPicker;

DatePicker.defaultProps = {
  fluid: false,
  format: ['MM / DD / YYYY', 'MM/DD/YYYY', 'MM-DD-YYYY'],
};
DatePicker.RangePicker.defaultProps = {
  ...DatePicker.RangePicker.defaultProps,
  format: 'MM / DD / YYYY',
};
DatePicker.MonthPicker.defaultProps = {
  ...DatePicker.MonthPicker.defaultProps,
  format: 'MM / DD / YYYY',
};
DatePicker.WeekPicker.defaultProps = {
  ...DatePicker.WeekPicker.defaultProps,
  format: 'MM / DD / YYYY',
};

export default DatePicker;
