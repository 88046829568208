import { ToastContainer, TypeOptions } from 'react-toastify';
import styled, { css } from 'styled-components';

import { getColor } from '../utils';

const generateStyles = (type: TypeOptions) => css`
  .Toastify__toast--${type} {
    border-color: ${getColor(type)};

    .Toastify__toast-icon > svg {
      fill: ${getColor(type)};
    }

    .Toastify__progress-bar {
      background: ${getColor(type)};
    }
  }
`;

export default styled(ToastContainer)`
  .Toastify__toast {
    border-width: 1.5px;
    border-style: solid;
    border-radius: 3px;
  }

  ${generateStyles('error')}
  ${generateStyles('success')}
  ${generateStyles('info')}
  ${generateStyles('warning')}
`;
