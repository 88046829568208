import { chunk } from 'lodash';
import React from 'react';

import { Button, ColorsRow, Container } from './ColorPicker.styled';
import ColorIcon from './components/ColorIcon';
import { colors } from './constants';

type Props = {
  activeColor?: string;
  resetColor: string;
  onChange: (color: string) => void;
};

const ColorPicker: React.FC<Props> = ({ activeColor, resetColor, onChange }) => {
  const colorsRows = chunk(colors, 12);

  const createOnChangeHandler = (color: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    onChange(color);
  };

  return (
    <Container>
      {colorsRows.map((row, index) => (
        <ColorsRow key={index}>
          {row.map(color => (
            <Button
              key={color}
              onMouseDown={createOnChangeHandler(color)}
              style={{
                backgroundColor: color,
                color,
              }}
            >
              <ColorIcon color={color} activeColor={activeColor} resetColor={resetColor} />
            </Button>
          ))}
        </ColorsRow>
      ))}
    </Container>
  );
};

export default ColorPicker;
