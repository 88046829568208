import React from 'react';

import { Checkbox } from './Selection.styled';

type Props = {
  selectedIds: Set<string>;
  id: string;
  isSelected: boolean;
  onSelect: (selectedIds: Set<string>) => void;
};

const Selection: React.FC<Props> = ({ id, selectedIds, onSelect, isSelected }) => {
  const handleSelect = () => {
    if (isSelected) {
      onSelect(new Set([...selectedIds].filter(item => item !== id)));
    } else {
      onSelect(new Set([...selectedIds, id]));
    }
  };

  return <Checkbox checked={isSelected} onChange={handleSelect} />;
};

export default Selection;
