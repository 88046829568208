import get from 'lodash/get';
import { createSelector } from 'reselect';
import { RootState, StateType } from 'typesafe-actions';

import { createDataSel } from '@/modules/data/duck/selectors';
import { isEventAdministratorSel, isSystemAdministratorSel } from '@/modules/user/duck/selectors';

import { moduleName, tabs } from '../constants';

import moduleReducers from './reducers';

const moduleSel = (state: RootState): StateType<typeof moduleReducers> => state[moduleName];

export const tabKeySel = createSelector(moduleSel, ({ tabKey }) => tabKey);

export const isCloningEventSel = createSelector(moduleSel, ({ isCloningEvent }) => isCloningEvent);

// COLLABORATOR ON ANY FORM
export const isAnyCollaboratorSel = createSelector([createDataSel('personFormRoles')], formRoles =>
  get(formRoles, 'isCollaborator', false),
);

export const tabFiltersSel = createSelector(
  [tabKeySel, isEventAdministratorSel, isSystemAdministratorSel],
  (tabKey, isAdmin, isSystemAdmin) => {
    const tab = tabs.find(({ key }) => key === tabKey);

    if (!tab) {
      return {};
    }

    const { filters, forCollaboratorOnly } = tab;

    if (isAdmin || isSystemAdmin) return filters;

    return {
      ...filters,
      ...(forCollaboratorOnly ? { isCollaborator: 1 } : {}),
    };
  },
);
