import { differenceBy, differenceWith } from 'lodash';
import { combineEpics, Epic } from 'redux-observable';
import { concat, defer, forkJoin, merge, of, race } from 'rxjs';
import { filter, switchMap, map, catchError, take } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData, removeDataItem } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import {
  deleteAddon,
  deleteAddonOptions,
  updateAddon,
  updateAddonClosed,
  updateAddonObject,
  updateAddonOptions,
} from './actions';
import services from './services';

const updateAddon$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateAddon.request)),
    switchMap(({ payload: addon }) => {
      const { addonCode } = addon;
      const isNewAddon = !addonCode;

      if (isNewAddon) {
        const existingAddons = createDataSel('addons')(state$.value);
        const addonWithSameNameExists = existingAddons.some(
          ({ addonName }) => addon.addonName === addonName,
        );
        if (addonWithSameNameExists) {
          throw new Error('Add-On with this name already exists');
        }
      }

      return concat(
        merge(
          race(
            action$.pipe(
              filter(isActionOf(updateAddonObject.success)),
              take(1),
              switchMap(action => {
                const { payload: nextAddonCode } = action;

                return merge(
                  merge(
                    race(
                      action$.pipe(filter(isActionOf(updateAddonOptions.success)), take(1)),
                      action$.pipe(
                        filter(isActionOf(updateAddonOptions.failure)),
                        take(1),
                        map(({ payload }) => {
                          throw new Error(payload.message);
                        }),
                      ),
                    ),
                    of(updateAddonOptions.request({ ...addon, addonCode: nextAddonCode })),
                  ),
                  merge(
                    race(
                      action$.pipe(filter(isActionOf(deleteAddonOptions.success)), take(1)),
                      action$.pipe(
                        filter(isActionOf(deleteAddonOptions.failure)),
                        take(1),
                        map(({ payload }) => {
                          throw new Error(payload.message);
                        }),
                      ),
                    ),
                    of(deleteAddonOptions.request({ ...addon, addonCode: nextAddonCode })),
                  ),
                );
              }),
            ),
            action$.pipe(
              filter(isActionOf(updateAddonObject.failure)),
              take(1),
              map(({ payload }) => {
                throw new Error(payload.message);
              }),
            ),
          ),
          of(updateAddonObject.request(addon)),
        ),
        defer(() => {
          toastService.success(`Addon was successfully ${isNewAddon ? 'created' : 'updated'}`);
          return of(
            updateAddon.success(),
            updateAddonClosed(),
            refreshData({ dataType: 'addons' }),
          );
        }),
      );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(updateAddon.failure(error)), caught);
    }),
  );

const updateAddonObject$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateAddonObject.request)),
    switchMap(({ payload: { addonOptions, addonCode, ...addon } }) => {
      const state = state$.value;
      const addons = createDataSel('addons')(state);
      const { formCode } = createDataSel('form')(state);

      const oldAddon = addons.find(item => item.addonCode === addonCode);

      const addonNotChanged =
        !!addonCode &&
        !!oldAddon &&
        oldAddon.addonName === addon.addonName &&
        oldAddon.isAppliedToRoster === addon.isAppliedToRoster &&
        oldAddon.description === addon.description &&
        oldAddon.forbiddenStateList === addon.forbiddenStateList &&
        oldAddon.isPaidWithDeposit === addon.isPaidWithDeposit;

      if (addonNotChanged) return of(updateAddonObject.success(addonCode));

      return services.updateAddon$({ ...addon, addonCode, formCode }).pipe(
        map(({ responseValue }) => updateAddonObject.success(responseValue as string)),
        catchError((error: ApiError) => of(updateAddonObject.failure(error))),
      );
    }),
  );

const updateAddonOptions$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateAddonOptions.request)),
    switchMap(({ payload: { addonOptions, addonCode } }) => {
      const state = state$.value;
      const addons = createDataSel('addons')(state);
      const oldAddon = addons.find(item => item.addonCode === addonCode);

      let nextAddonOptions = addonOptions;

      if (oldAddon) {
        nextAddonOptions = differenceWith(
          addonOptions,
          oldAddon.addonOptions,
          (a, b) =>
            a.optionCode === b.optionCode &&
            a.optionName === b.optionName &&
            a.fee === b.fee &&
            a.positionNumber === b.positionNumber,
        );
      }

      if (!nextAddonOptions.length) return of(updateAddonOptions.success(undefined));

      const { formCode } = createDataSel('form')(state);

      return forkJoin(
        nextAddonOptions.map((option, index) =>
          services
            .updateOption$({
              ...option,
              formCode,
              addonCode,
            })
            .pipe(
              map(response => {
                const { responseValue } = response;
                nextAddonOptions[index].optionCode = responseValue as string;
                return response;
              }),
            ),
        ),
      ).pipe(
        map(() => updateAddonOptions.success(nextAddonOptions)),
        catchError((error: ApiError, caught) =>
          merge(of(updateAddonOptions.failure(error)), caught),
        ),
      );
    }),
  );

const deleteAddonOptions$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteAddonOptions.request)),
    switchMap(({ payload: { addonOptions, addonCode } }) => {
      const state = state$.value;
      const addons = createDataSel('addons')(state);
      const { formCode } = createDataSel('form')(state);
      const oldAddon = addons.find(item => item.addonCode === addonCode);

      if (!oldAddon) return of(deleteAddonOptions.success());

      const addonOptionsToDelete = differenceBy(oldAddon.addonOptions, addonOptions, 'optionCode');

      if (!addonOptionsToDelete.length) return of(deleteAddonOptions.success());

      return forkJoin(
        addonOptionsToDelete.map(({ optionCode }) =>
          services.deleteOption$({ formCode, addonCode, optionCode }),
        ),
      ).pipe(
        map(() => deleteAddonOptions.success()),
        catchError((error: ApiError) => of(deleteAddonOptions.failure(error))),
      );
    }),
  );

const deleteAddon$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteAddon.request)),
    switchMap(({ payload: addonCode }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      return services.deleteAddon$(formCode, addonCode).pipe(
        switchMap(() =>
          of(
            deleteAddon.success(),
            removeDataItem({ dataType: 'addons', idField: 'addonCode', dataItemId: addonCode }),
          ),
        ),
        catchError((error: Error) => {
          toastService.error(error.message);
          return of(deleteAddon.failure(error));
        }),
      );
    }),
  );

export default combineEpics(
  updateAddon$,
  updateAddonObject$,
  updateAddonOptions$,
  deleteAddonOptions$,
  deleteAddon$,
);
