import { MenuFoldOutlined } from '@ant-design/icons';
import React from 'react';
import { useSlate } from 'slate-react';

import { removeIndentation } from '../../../../utils';
import ToolbarButton from '../ToolbarButton';

const RemoveIndentationButton: React.FC = () => {
  const editor = useSlate();

  const handleClick = () => {
    removeIndentation(editor);
  };

  return <ToolbarButton Icon={MenuFoldOutlined} onClick={handleClick} />;
};

export default RemoveIndentationButton;
