import { Dropdown, DropdownProps } from 'antd';
import React from 'react';

type Props = { className?: string } & DropdownProps;

const DropdownWithClassName: React.FC<Props> = ({ className, ...rest }) => (
  <Dropdown {...rest} overlayClassName={className} />
);

export default DropdownWithClassName;
