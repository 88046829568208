import { Row, Col, Card, Spin } from 'antd';
import { intersection } from 'lodash';
import React, {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import { PaymentSummary as PaymentSummaryType } from '@/modules/data/dataTypes/paymentSummary';
import { Session } from '@/modules/data/dataTypes/sessionList';
import { createDataSel } from '@/modules/data/duck/selectors';
import { isDeposit } from '@/modules/entities/PaymentCategories/utils';
import PaymentSummary from '@/modules/entities/Registrations/components/PaymentSummary';
import {
  confirmRegistrationErrorSel,
  confirmRegistrationInProgressSel,
} from '@/modules/entities/Registrations/duck/selectors';
import Payment from '@/modules/payments';
import { PaymentMethod } from '@/modules/payments/constants';
import { makePaymentInProgressSel } from '@/modules/payments/duck/selectors';
import useGetPaymentMethods from '@/modules/payments/hooks/useGetPaymentMethods';
import InputMoney from '@/modules/shared/components/InputMoney';
import Label from '@/modules/shared/components/Label';
import toastService from '@/modules/toasts/service';

import { getPaymentAmount, getTotalBalanceDue } from '../../utils';
import MaxAmountInfoBubble from '../MaxAmountInfoBubble';

import { AmountInputContainer } from './CheckoutStep.styled';

type BaseProps = {
  currentSession: Pick<
    Session,
    'sessionName' | 'isFeePerPerson' | 'baseFeePersonCount' | 'additionalPersonFee'
  >;
  hideMakePaymentButton?: true;
  paymentSummary: PaymentSummaryType;
  onPaymentStarted?: () => void;
  onOpenNextStep: (paymentMethod: PaymentMethod | null) => void;
  onRefreshPaymentsData: () => void;
};

type GroupProps = BaseProps & {
  isGroup: true;
  groupReservationDetails: GroupReservation;
};

type IndividualProps = BaseProps & {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};

type Props = GroupProps | IndividualProps;

const CheckoutStep = forwardRef<{ handleSubmit: () => void }, Props>((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  const {
    currentSession,
    isGroup,
    paymentSummary,
    hideMakePaymentButton,
    onPaymentStarted,
    onOpenNextStep,
    onRefreshPaymentsData,
  } = props;
  const formSettings = useSelector(createDataSel('form'));
  const confirmRegistrationError = useSelector(confirmRegistrationErrorSel);
  const isLoadingPayment = useSelector(makePaymentInProgressSel);
  const isLoadingConfirmation = useSelector(confirmRegistrationInProgressSel);
  const paymentCategories = useSelector(createDataSel('paymentCategories'));
  const [triggerPayment, setTriggerPayment] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [paymentComments, setPaymentComments] = useState('');

  const depositPaymentCategory = useMemo(
    () => paymentCategories.find(isDeposit),
    [paymentCategories],
  );

  const { formCode, allowSplitPayments, allowGreaterInitialPayment } = formSettings;
  const { amountPaid = 0, nextPaymentAmountDue, totalFee } = paymentSummary;

  const [customAmount, setCustomAmount] = useState<number>(nextPaymentAmountDue);

  useEffect(() => setCustomAmount(nextPaymentAmountDue), [nextPaymentAmountDue]);

  const enabledPaymentMethods = useGetPaymentMethods({
    form: formSettings,
    availableMethods: [
      PaymentMethod.creditCard,
      PaymentMethod.check,
      PaymentMethod.adjustment,
      PaymentMethod.costCenter,
      PaymentMethod.eCheckOrbital,
    ],
    allowedMethods: depositPaymentCategory?.allowedMethods ?? [],
    ...(isGroup
      ? { groupReservation: props.groupReservationDetails }
      : { attendeeDetails: props.attendeeDetails }),
  });

  const showCustomAmountInput = allowGreaterInitialPayment || allowSplitPayments;

  const showCommentsField = !!intersection(Array.from(enabledPaymentMethods), [
    PaymentMethod.adjustment,
    PaymentMethod.check,
    PaymentMethod.costCenter,
  ]).length;

  const amountToPay = useMemo(
    () =>
      getPaymentAmount(
        customAmount || 0,
        allowGreaterInitialPayment ? getTotalBalanceDue(paymentSummary) : nextPaymentAmountDue,
        paymentMethod,
      ),
    [allowGreaterInitialPayment, paymentMethod, paymentSummary, customAmount, nextPaymentAmountDue],
  );

  useEffect(() => {
    if (triggerPayment) {
      setTriggerPayment(false);
    }
  }, [triggerPayment]);

  const handleSubmit = useCallback(() => {
    const totalAmountDue = totalFee - amountPaid;

    if (amountToPay > totalAmountDue) {
      toastService.error("Payment amount can't be bigger than total amount due");
      return;
    }

    if (amountToPay < nextPaymentAmountDue) {
      if (!allowSplitPayments) {
        toastService.error('Payment amount should be greater than deposit');
        return;
      }
    }

    if (amountToPay > nextPaymentAmountDue) {
      if (!allowGreaterInitialPayment) {
        toastService.error("Payment amount can't be bigger than due today amount");
        return;
      }
    }

    setTriggerPayment(true);
  }, [
    allowGreaterInitialPayment,
    allowSplitPayments,
    amountPaid,
    amountToPay,
    nextPaymentAmountDue,
    totalFee,
  ]);

  const handlePaymentStart = () => {
    onPaymentStarted && onPaymentStarted();
  };

  const handleOnPaymentCompleted = useCallback(() => {
    if (amountToPay < nextPaymentAmountDue) {
      onRefreshPaymentsData();
    } else {
      onOpenNextStep(paymentMethod);
    }

    setPaymentComments('');
  }, [amountToPay, nextPaymentAmountDue, paymentMethod, onOpenNextStep, onRefreshPaymentsData]);

  const handleCustomAmountChange = (amount?: number) => {
    if (!amount) {
      setCustomAmount(0);
      return;
    }
    if (amount < 0) {
      setCustomAmount(nextPaymentAmountDue);
      return;
    }

    const nextAmount = getPaymentAmount(
      amount,
      allowGreaterInitialPayment ? getTotalBalanceDue(paymentSummary) : nextPaymentAmountDue,
      paymentMethod,
    );

    setCustomAmount(nextAmount);
  };

  if (isLoadingConfirmation) {
    return (
      <div className="text-center">
        <Spin tip="Loading Content..." spinning />
      </div>
    );
  }

  if (confirmRegistrationError) {
    return (
      <div className="mt-6 text-center">
        An error occurred while saving your reservation information, please try again later
      </div>
    );
  }

  if (amountPaid === -1) {
    return (
      <div style={{ margin: '0 auto', textAlign: 'center' }}>
        paymentMethod === paymentMethods.adjustment
        <p className="mt-1">An error occurred, please try again later</p>
      </div>
    );
  }

  return (
    <Payment
      {...('groupReservationDetails' in props
        ? { isGroup: true, guid: props.groupReservationDetails.groupReservationGUID }
        : {
            isGroup: false,
            guid: props.attendeeDetails.formRecordGUID,
          })}
      amount={amountToPay}
      formCode={formCode}
      paymentCategoryCode={depositPaymentCategory?.paymentCategoryCode as string}
      triggerPayment={triggerPayment}
      enabledPaymentMethods={enabledPaymentMethods}
      paymentMethod={paymentMethod}
      onPaymentStart={handlePaymentStart}
      onPaymentMethodChange={setPaymentMethod}
      onPaymentCompleted={handleOnPaymentCompleted}
      comments={paymentComments}
    >
      {(paymentMethodPicker, isIframeDisplayed) => (
        <Row gutter={12}>
          <Col sm={16} xs={24}>
            <Card title="Payment Checkout" className="mb-2 mt-2">
              {!isIframeDisplayed && showCustomAmountInput && (
                <AmountInputContainer>
                  <Label
                    label={
                      <Fragment>
                        Amount to Pay <MaxAmountInfoBubble paymentMethod={paymentMethod} />
                      </Fragment>
                    }
                  >
                    <InputMoney
                      onChange={handleCustomAmountChange}
                      placeholder="0.00"
                      value={customAmount}
                      contentAfter="USD"
                    />
                  </Label>
                </AmountInputContainer>
              )}
              {paymentMethodPicker}
            </Card>
          </Col>
          <Col sm={8} xs={24}>
            <PaymentSummary
              {...(isGroup
                ? {
                    isGroup,
                    groupReservation: props.groupReservationDetails,
                  }
                : { isGroup })}
              currentSession={currentSession}
              paymentSummary={paymentSummary}
              onMakePayment={handleSubmit}
              isLoading={isLoadingPayment}
              hideMakePaymentButton={isIframeDisplayed || !!hideMakePaymentButton}
              paymentComments={paymentComments}
              onPaymentCommentsChange={setPaymentComments}
              showComments={showCommentsField}
              commentsFieldRequired={paymentMethod === PaymentMethod.adjustment}
            />
          </Col>
        </Row>
      )}
    </Payment>
  );
});

export default CheckoutStep;
