import apiService from '@/modules/utils/apiService';

const disclaimerAcceptRequest$ = (payload: {
  disclaimers: {
    isAccepted: boolean;
    disclaimerCode: string;
    firstName: string;
    lastName: string;
    relationship: string;
  }[];
  formCode: string;
  personGUID: string;
  formRecordGUID: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateFormPersonDisclaimerList',
    payload,
  );

export default { disclaimerAcceptRequest$ };
