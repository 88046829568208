import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { LedgerAccount } from '@/modules/data/dataTypes/ledgerAccountLIst';

import {
  closeUpdateLedgerAccount,
  openUpdateLedgerAccount,
  deleteLedgerAccount,
  updateLedgerAccount,
} from './actions';

const updatedLedgerAccount = createReducer<Partial<LedgerAccount> | null>(null)
  .handleAction(openUpdateLedgerAccount, (_state, { payload }) => payload)
  .handleAction(closeUpdateLedgerAccount, () => null);

const updateLedgerAccountInProgress = createReducer(false)
  .handleAction(updateLedgerAccount.request, () => true)
  .handleAction([updateLedgerAccount.success, updateLedgerAccount.failure], () => false);

const deleteLedgerAccountInProgress = createReducer(false)
  .handleAction(deleteLedgerAccount.request, () => true)
  .handleAction([deleteLedgerAccount.success, deleteLedgerAccount.failure], () => false);

export default combineReducers({
  updatedLedgerAccount,
  updateLedgerAccountInProgress,
  deleteLedgerAccountInProgress,
});
