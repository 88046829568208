import { DataBlockItem } from '@/modules/data/dataTypes/dataBlockItem';
import { DataBlock } from '@/modules/data/dataTypes/dataBlockList';
import { FormPartsData } from '@/modules/data/dataTypes/formPartsData';
import { OptionSetItem } from '@/modules/data/dataTypes/optionSets';
import apiService from '@/modules/utils/apiService';

import { ConnectDataBlockItemPayload } from '../types';

const updateOrCreateFormPart$ = (
  data: Pick<FormPartsData['formParts'][number], 'formPartName' | 'description'> & {
    formCode: string;
  },
) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateFormPart',
    data,
  );

const updateOrCreateDataBlockItem$ = (payload: DataBlockItem & { formCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventDatablocks&ScriptCode=UpdateDatablockItem',
    payload,
  );

const updateDataBlockItem$ = (payload: DataBlockItem) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventDatablocks&ScriptCode=UpdateDatablockItem',
    payload,
  );

const connectDataBlockItem$ = (data: ConnectDataBlockItemPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=ConnectDatablockItem',
    data,
  );

const updateFormItem$ = (
  data: Pick<
    FormPartsData['formParts'][number]['formItems'][number],
    | 'formItemCode'
    | 'formItemName'
    | 'description'
    | 'isMandatory'
    | 'isVisible'
    | 'optionSetCode'
    | 'placeholderText'
    | 'presentationType'
  > & { formCode: string; formPartCode: string },
) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateFormItem',
    data,
  );

const createOptionSet$ = (data: {
  formCode: string;
  optionSetCode: string;
  optionSetName: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateOptionSet',
    data,
  );

const updateOptionSetData$ = (data: {
  formCode: string;
  optionSetCode: string;
  optionSetName?: string;
  optionSetDescription?: string;
  optionSetItems: OptionSetItem[];
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateOptionSetData',
    data,
  );

const deleteOptionSetItemList$ = (data: {
  formCode: string;
  optionSetCode: string;
  optionSetItems: string[];
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteOptionSetItemList',
    data,
  );

const updateFormItemVisibility$ = (data: {
  formCode: string;
  formPartCode: string;
  formItemCode: string;
  visibilitySettings: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateFormItemVisibilitySettings',
    data,
  );

const updateFormPartVisibility$ = (data: {
  formCode: string;
  formPartCode: string;
  visibilitySettings: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateFormPartVisibilitySettings',
    data,
  );

const updateOrCreateDataBlock$ = (payload: Omit<DataBlock, 'datablockID' | 'numberOfItems'>) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventDatablocks&ScriptCode=UpdateDatablock',
    payload,
  );

const connectDataBlock$ = (data: {
  formCode: string;
  formPartCode: string;
  datablockCode: string;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=ConnectDatablock',
    data,
  );

const disconnectDataBlock$ = (payload: { formCode: string; formPartCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DisconnectDatablock',
    payload,
  );

export default {
  updateOrCreateFormPart$,
  updateOrCreateDataBlockItem$,
  updateDataBlockItem$,
  connectDataBlockItem$,
  updateFormItem$,
  createOptionSet$,
  updateOptionSetData$,
  deleteOptionSetItemList$,
  updateFormPartVisibility$,
  updateFormItemVisibility$,
  updateOrCreateDataBlock$,
  connectDataBlock$,
  disconnectDataBlock$,
};
