import dayjs from 'dayjs';
import React, { forwardRef, Fragment, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Person } from '@/modules/data/dataTypes/person';
import { createDataSel } from '@/modules/data/duck/selectors';
import { dateFromString } from '@/modules/utils/dateFormats';

import PersonData from '../PersonData';

import PersonalDataCard from './components/CardWithPersonFormData';
import ParentData from './components/ParentData';
import UnitAndCouncil from './components/UnitAndCouncil';
import type {
  PersonFormData,
  ParentData as ParentDataType,
  GroupReservationPersonFormData,
  IndividualReservationPersonFormData,
} from './types';

import { ADULT_AGE_LOW } from 'SHARED/constants';

type RefProps = {
  handleSubmit: () => Promise<PersonFormData>;
};

type Props = {
  isGroup: boolean;
  initialUnitValue?: string;
};

const PersonalInformation = forwardRef<RefProps, Props>(({ isGroup, initialUnitValue }, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  const unitRef = useRef<{ handleSubmit: () => Promise<{ unit: string }> }>(null);
  const personRef = useRef<{
    handleSubmit: () => Promise<Partial<Person>>;
  }>(null);
  const parentRef = useRef<{ handleSubmit: () => Promise<ParentDataType> }>(null);

  const person: Person | undefined = useSelector(createDataSel('arnicaPerson'));
  const [dateOfBirth, setDateOfBirth] = useState(() =>
    person ? dateFromString(person.dateOfBirth) : null,
  );

  const isOlder = useMemo(() => {
    if (!dateOfBirth) return true;
    return dayjs().diff(dateOfBirth, 'years') >= ADULT_AGE_LOW;
  }, [dateOfBirth]);

  function handleSubmit() {
    return Promise.all([
      unitRef.current?.handleSubmit(),
      personRef.current?.handleSubmit(),
      parentRef.current?.handleSubmit(),
    ]).then(([unitFormData, personFormData, parentFormData]) => {
      const { positions, ...personData } = personFormData || {};
      const parentFormDataEntries = parentFormData && Object.entries(parentFormData);

      if (parentFormDataEntries?.length && personData) {
        Object.assign(personData, {
          guardianFirstName: parentFormData && parentFormData['parent.firstName'],
          guardianLastName: parentFormData && parentFormData['parent.lastName'],
          guardianEmailAddress: parentFormData && parentFormData['parent.email'],
        });
      }

      if (isGroup) {
        const groupReservationPersonFormData: GroupReservationPersonFormData = {
          personData,
          groupReservationInitialSettings: {
            hostUnit: unitFormData?.unit as string,
            hostCouncil: person?.councilNumber as string,
          },
        };
        return groupReservationPersonFormData;
      }

      const individualReservationPersonFormData: IndividualReservationPersonFormData = {
        personData,
        individualRegistrationInitialSettings: {
          unit: unitFormData?.unit as string,
        },
        ...(isOlder || !parentFormDataEntries
          ? {}
          : {
              formParts: [
                {
                  formPartCode: 'parentdata',
                  formItems: parentFormDataEntries.map(([key, value]) => {
                    const [, newKey] = key.split('.');
                    return {
                      formItemCode: newKey,
                      formItemValue: value,
                    };
                  }),
                },
              ],
            }),
      };
      return individualReservationPersonFormData;
    });
  }

  return (
    <Fragment>
      <PersonalDataCard>
        {!!person && !!person.councilLong && !!person.positions && (
          <UnitAndCouncil ref={unitRef} person={person} initialUnitValue={initialUnitValue} />
        )}
        <PersonData ref={personRef} person={person} onDateOfBirthChange={setDateOfBirth} />
      </PersonalDataCard>
      {!isOlder && <ParentData ref={parentRef} person={person} />}
    </Fragment>
  );
});

export default PersonalInformation;
