import React from 'react';
import { useSlate } from 'slate-react';

import { LeafType } from '../../../../constants';
import { addMark, removeMark, getFormat } from '../../../../utils';
import ColorPicker from '../ColorPicker';

const HighlightColorPicker: React.FC = () => {
  const resetColor = '#FFFFFF';
  const editor = useSlate();
  const activeColor = getFormat(editor, LeafType.backgroundColor) as string;

  const handleColorSelect = (color: string) => {
    if (color === resetColor) {
      removeMark(editor, LeafType.backgroundColor);
    } else {
      addMark(editor, LeafType.backgroundColor, color);
    }
  };

  return (
    <ColorPicker onChange={handleColorSelect} activeColor={activeColor} resetColor={resetColor} />
  );
};

export default HighlightColorPicker;
