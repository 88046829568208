import { combineEpics, Epic, StateObservable } from 'redux-observable';
import { of, concat, Observable, defer, merge, EMPTY, race } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { RegistrantTypes } from '@/modules/data/dataTypes/attendeeTypeList';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { updateFormRecord } from '@/modules/entities/FormRecord/duck/actions';
import { checkoutStepEpic$ } from '@/modules/entities/Payments/components/CheckoutStep/duck/epics';
import { closeModal } from '@/modules/modals/duck/actions';
import { formPartsSel } from '@/modules/questions/duck/selectors';
import toastService from '@/modules/toasts/service';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { ApiError } from '@/modules/utils/apiService';

import { secondaryRegistrantStepKeys, SECONDARY_REGISTRANT_DATA_ID } from '../constants';
import { stepDataParams as checkoutStepDataParams } from '../steps/Checkout/constants';
import { stepDataSel as checkoutStepDataSel } from '../steps/Checkout/duck/selectors';
import findPersonStepEpics$ from '../steps/FindPersonStep/duck/epics';
import { personGuidSel } from '../steps/FindPersonStep/duck/selectors';
import { stepDataParams as questionsStepDataParams } from '../steps/QuestionsStep/constants';
import { stepDataSel as questionsStepDataSel } from '../steps/QuestionsStep/duck/selectors';

import {
  addAdditionalRegistrant,
  disableStep,
  enableStep,
  openNextStep,
  openPrevStep,
  openStep,
  openStepCompleted,
} from './actions';
import {
  secondaryRegistrantFormRecordGuidSel,
  futureStepKeySel,
  sessionSel,
  primaryRegistrantFormRecordGUIDSel,
  collectedFormPartsSel,
  collectedRegistrationDataSel,
  formCodeSel,
} from './selectors';

const DISABLE_STEP_ERROR = 'disableStep';

class SkipStepError extends Error {
  skipStepReason: string;

  constructor(message: string, skipStepReason: string) {
    super(message);
    this.skipStepReason = skipStepReason;
  }
}

const secondaryRegistrantsStepGetObservable: {
  [propName: string]: (
    action: Observable<RootAction>,
    state: StateObservable<RootState>,
    formRecordGUID: string | null,
  ) => Observable<RootAction>;
} = {
  [secondaryRegistrantStepKeys.findPerson]: (action$, state$, formRecordGUID) => {
    const { formCode } = createDataSel('form')(state$.value);
    return concat(
      formRecordGUID
        ? prefetchData$(action$, {
            dataType: 'attendeeDetails',
            dataId: SECONDARY_REGISTRANT_DATA_ID,
            queryObj: { formCode, formRecordGUID },
          })
        : EMPTY,
      defer(() => {
        const personGUID = personGuidSel(state$.value);
        const attendeeDetails: AttendeeDetails | undefined = createDataSel(
          'attendeeDetails',
          SECONDARY_REGISTRANT_DATA_ID,
        )(state$.value);

        if (!personGUID && !attendeeDetails) return EMPTY;

        return prefetchData$(action$, {
          dataType: 'arnicaPerson',
          queryObj: { personGUID: personGUID || attendeeDetails?.personGUID, useAkelaData: true },
        });
      }),
    );
  },
  [secondaryRegistrantStepKeys.attendeeTypes]: (action$, state$, formRecordGUID) => {
    const state = state$.value;
    const { formCode } = createDataSel('form')(state);
    const personGUID = personGuidSel(state);
    const filterByRegistrantType = !createAbilitiesSelector(
      'attendeeDetails',
      'updateAttendeeType',
      null,
    )(state);

    return prefetchData$(
      action$,
      {
        dataType: 'attendeeTypesForPersonList',
        queryObj: {
          formCode,
          personGUID,
          ...(filterByRegistrantType
            ? {
                registrantType: RegistrantTypes.Secondary,
              }
            : {}),
        },
        fetchData: true,
      },
      formRecordGUID
        ? {
            dataType: 'attendeeDetails',
            dataId: SECONDARY_REGISTRANT_DATA_ID,
            queryObj: {
              formCode,
              formRecordGUID,
            },
          }
        : null,
    );
  },
  [secondaryRegistrantStepKeys.addons]: (action$, state$, formRecordGUID) => {
    const state = state$.value;
    const { formCode } = createDataSel('form')(state);
    const personGUID = personGuidSel(state);
    const session = sessionSel(state);

    return concat(
      prefetchData$(
        action$,
        { dataType: 'session', queryObj: { formCode, sessionCode: session?.sessionCode } },
        { dataType: 'arnicaPerson', queryObj: { personGUID } },
        { dataType: 'addons', queryObj: { formCode, sessionCode: session?.sessionCode } },
      ),
      defer(() => {
        const addons = createDataSel('addons')(state$.value);
        const { state: usaState } = createDataSel('arnicaPerson')(state$.value);

        const filteredAddons = addons.filter(
          ({ forbiddenStateList }) => !forbiddenStateList.includes(usaState),
        );

        if (!filteredAddons.length) {
          throw new SkipStepError(DISABLE_STEP_ERROR, 'No Add-Ons available');
        }

        return prefetchData$(
          action$,
          {
            dataType: 'formRecordAddons',
            queryObj: { formCode, formRecordGUID },
            fetchData: true,
          },
          { dataType: 'paymentCategories', queryObj: { formCode } },
        );
      }),
    );
  },
  [secondaryRegistrantStepKeys.activities]: (action$, state$, formRecordGUID) => {
    const state = state$.value;
    const { formCode } = createDataSel('form')(state$.value);
    const session = sessionSel(state);

    return concat(
      prefetchData$(action$, {
        dataType: 'attendeeDetails',
        dataId: SECONDARY_REGISTRANT_DATA_ID,
        queryObj: { formCode, formRecordGUID },
      }),
      defer(() => {
        const { typeCode } = createDataSel(
          'attendeeDetails',
          SECONDARY_REGISTRANT_DATA_ID,
        )(state$.value);
        return concat(
          prefetchData$(action$, {
            dataType: 'activities',
            queryObj: {
              formCode,
              sessionCode: session?.sessionCode,
              typeCode,
              formRecordGUID,
            },
          }),
          defer(() => {
            const activities = createDataSel('activities')(state$.value);
            if (!activities.length) {
              throw new SkipStepError(DISABLE_STEP_ERROR, 'No Activities Available');
            }

            return prefetchData$(
              action$,
              { dataType: 'paymentCategories', queryObj: { formCode } },
              {
                dataType: 'formRecordActivities',
                queryObj: { formCode, formRecordGUID },
                fetchData: true,
              },
            );
          }),
        );
      }),
    );
  },
  [secondaryRegistrantStepKeys.questions]: (action$, state$, formRecordGUID) => {
    const state = state$.value;
    const formCode = formCodeSel(state);
    return concat(
      prefetchData$(
        action$,
        ...questionsStepDataParams.map(params => {
          switch (params.dataType) {
            case 'form':
            case 'visibilitySettings':
              return { ...params, queryObj: { formCode } };
            case 'attendeeDetails':
            case 'optionSets':
              return { ...params, queryObj: { formCode, formRecordGUID } };
          }
        }),
      ),
      defer(() => {
        const {
          data: { attendeeDetails, visibilitySettings },
        } = questionsStepDataSel(state$.value);

        const formParts = formPartsSel(state$.value, {
          attendeeDetails,
          visibilitySettings,
          removeSystemFormParts: true,
          formParts: attendeeDetails.formParts,
        });

        if (!formParts.length) {
          throw new SkipStepError(DISABLE_STEP_ERROR, 'No form required to fill');
        }

        return EMPTY;
      }),
    );
  },
  [secondaryRegistrantStepKeys.checkout]: (action$, state$, formRecordGUID) => {
    const session = sessionSel(state$.value);

    if (!session || !formRecordGUID) return EMPTY;

    const { formCode } = createDataSel('form')(state$.value);
    const { sessionCode } = session;

    return concat(
      prefetchData$(
        action$,
        ...checkoutStepDataParams.map(params => {
          switch (params.dataType) {
            case 'paymentSummary':
            case 'attendeeDetails':
              return { ...params, queryObj: { formCode, formRecordGUID } };
          }
        }),
      ),
      defer(() => {
        const {
          data: { paymentSummary },
        } = checkoutStepDataSel(state$.value);
        return checkoutStepEpic$(
          action$,
          state$,
          { formRecordGUID },
          { finalActions: [openNextStep()], sessionCode, paymentSummary },
        );
      }),
    );
  },
};

const openNextStep$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([openNextStep, openPrevStep, openStep])),
    switchMap(() => {
      const state = state$.value;
      const futureStepKey = futureStepKeySel(state);

      if (!futureStepKey) {
        return of(closeModal());
      }

      const formRecordGUID = secondaryRegistrantFormRecordGuidSel(state);
      const getStepObservable = secondaryRegistrantsStepGetObservable[futureStepKey];
      if (!getStepObservable) return of(openStepCompleted());

      return concat(
        of(enableStep(futureStepKey)),
        getStepObservable(action$, state$, formRecordGUID),
        of(openStepCompleted()),
      );
    }),
    catchError((error: SkipStepError | Error, caught) => {
      if ('skipStepReason' in error) {
        const { skipStepReason } = error;
        const futureStepKey = futureStepKeySel(state$.value);

        return merge(
          of(disableStep({ stepKey: futureStepKey, reason: skipStepReason }), openNextStep()),
          caught,
        );
      }
      toastService.error(error.message);
      return caught;
    }),
  );

const addAdditionalRegistrant$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addAdditionalRegistrant.request)),
    switchMap(({ payload: typeCode }) => {
      const state = state$.value;
      const formParts = collectedFormPartsSel(state);
      const registrationData = collectedRegistrationDataSel(state);
      const personGUID = personGuidSel(state);

      if (!personGUID) return EMPTY;

      const formRecordGUID = secondaryRegistrantFormRecordGuidSel(state);
      const { formCode } = createDataSel('form')(state);
      const primaryRegistrantFormRecordGUID = primaryRegistrantFormRecordGUIDSel(state);
      const session = sessionSel(state);

      return merge(
        race(
          action$.pipe(
            filter(isActionOf(updateFormRecord.success)),
            take(1),
            switchMap(({ payload }) =>
              of(addAdditionalRegistrant.success(payload), openNextStep()),
            ),
          ),
          action$.pipe(
            filter(isActionOf(updateFormRecord.failure)),
            take(1),
            map(({ payload }) => {
              throw new Error(payload.message);
            }),
          ),
        ),
        of(
          updateFormRecord.request({
            ...(formRecordGUID ? { formRecordGUID } : {}),
            ...registrationData,
            ...(formParts
              ? {
                  formParts,
                }
              : {}),
            formCode,
            personGUID,
            primaryRegistrantFormRecordGUID,
            sessionCode: session?.sessionCode,
            typeCode,
          }),
        ),
      );
    }),
    catchError((error: ApiError, caught) =>
      merge(of(addAdditionalRegistrant.failure(error)), caught),
    ),
  );

export default combineEpics(findPersonStepEpics$, openNextStep$, addAdditionalRegistrant$);
