/* eslint-disable react/no-multi-comp */
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { TableProps } from 'antd/lib/table';
import capitalize from 'lodash/capitalize';
import omit from 'lodash/omit';
import React, { useState, useEffect } from 'react';

import { SearchInput, Table, WrapperCard } from './ActionTable.styled';
import ActionsColumn from './components/ActionsColumn';
import EmptyTable from './components/EmptyTable';
import TBody from './components/TBody';
import THeadButton from './components/THeadButton';
import THeadWrapper from './components/THeadWrapper';

import { search } from 'UTILS/searchUtils';

interface Props<T> extends TableProps<T> {
  id?: string;
  subject?: string;
  addText: string;
  onAdd?: () => void;
  isLoading?: boolean;
  onEdit?: (index: number, record: T) => void;
  onRemove?: (index: number, record: T) => void;
  onCollapse: (index: number, record: T) => void;
  onSearch?: (searchStr: string) => void;
  canRemove?: (record: T) => boolean;
  customAction?: {
    size?: SizeType;
    text: string;
    icon: React.ReactNode;
    onClick: (index: number, record: T) => void;
    hide: (record: T) => void;
  };
  cardClass?: string;
  subHeader?: React.ReactNode;
  customActionBarRender?: () => React.ReactElement | null;
  expandedRowRender?: (
    record: T,
    index: number,
    indent: number,
    expanded: boolean,
  ) => React.ReactNode;
  allowSearch: boolean;
  cardTitle?: React.ReactNode;
  expandedRowKeys: string[];
  allowDeepSearch: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  GroupHeaderComponent?: React.FC<any>;
  SubHeaderComponent?: React.ReactElement;
  searchValue?: string;
  searchInKeys?: Array<string>;
  useBackEndSearch: boolean;
  emptyMessage?: string;
  bordered?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ActionTable<T extends Record<string, any>>({
  rowKey,
  cardTitle,
  columns = [],
  addText,
  onAdd,
  onRemove,
  onEdit,
  onExpand,
  onCollapse,
  onSearch,
  isLoading,
  subject,
  customAction,
  canRemove,
  cardClass,
  customActionBarRender,
  expandedRowRender,
  expandedRowKeys,
  dataSource = [],
  allowSearch,
  allowDeepSearch,
  searchValue,
  useBackEndSearch,
  SubHeaderComponent,
  GroupHeaderComponent,
  emptyMessage,
  searchInKeys,
  bordered = true,
  rowSelection,
  ...rest
}: Props<T>): React.ReactElement {
  const [expandedKeys, setExpandedKeys] = useState<{ [propName: string]: boolean }>({});
  const [filteredData, setFilteredData] = useState(dataSource);
  const [localSearchValue, setLocalSearchValue] = useState(searchValue);

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    setLocalSearchValue(searchValue);
  }, [searchValue]);

  const handleSearch = (searchStr: string) => {
    setLocalSearchValue(searchStr);
    if (useBackEndSearch && onSearch) {
      onSearch(searchStr);
    } else {
      const nextFilteredData = search(dataSource, searchStr, allowDeepSearch, searchInKeys);
      setFilteredData(nextFilteredData);
    }
  };

  const cols = [...columns];

  if (onRemove || onEdit || customAction || expandedRowRender || onAdd) {
    cols.push({
      title: onAdd && <THeadButton onClick={onAdd}>{addText}</THeadButton>,
      key: 'actions',
      align: 'right',
      // eslint-disable-next-line react/no-multi-comp
      render: (_, record, index) => {
        const key: string = typeof rowKey === 'string' ? record[rowKey] : '';
        return (
          <ActionsColumn
            index={index}
            record={record}
            customAction={customAction}
            onEdit={onEdit}
            {...(!canRemove || canRemove(record)
              ? {
                  onRemove,
                  removeText: 'Are you sure?',
                }
              : {})}
            {...(expandedRowRender
              ? {
                  expanded: !!expandedKeys[key],
                  onExpand: expandHandler,
                  onCollapse: collapseHandler,
                }
              : {})}
          />
        );
      },
    });
  }

  useEffect(() => {
    if (!expandedRowKeys) return;

    setExpandedKeys(
      expandedRowKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: true,
        }),
        {},
      ),
    );
  }, [expandedRowKeys]);

  const expandHandler = (_index: number, record: T) => {
    if (!expandedRowKeys) {
      const key: string = typeof rowKey === 'string' ? record[rowKey] : '';
      setExpandedKeys({ ...expandedKeys, [key]: true });
    }
  };

  const collapseHandler = (index: number, record: T) => {
    onCollapse(index, record);
    if (!expandedRowKeys) {
      const key: string = typeof rowKey === 'string' ? record[rowKey] : '';
      setExpandedKeys(omit(expandedKeys, [key]));
    }
  };

  const ExpandIcon = () => null;

  return (
    <WrapperCard title={cardTitle} bordered={bordered} className={cardClass}>
      {allowSearch && <SearchInput value={localSearchValue} onChange={handleSearch} />}
      {customActionBarRender && customActionBarRender()}
      <Table
        pagination={{ position: ['bottomCenter'], ...rest.pagination }}
        dataSource={filteredData}
        rowKey={rowKey}
        columns={cols}
        components={{
          header: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            wrapper: (props: any) => (
              <THeadWrapper
                {...props}
                SubHeaderComponent={SubHeaderComponent}
                colSpan={cols.length}
              />
            ),
          },
          body: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            wrapper: (props: any) => (
              <TBody
                {...props}
                colSpan={cols.length}
                GroupHeaderComponent={GroupHeaderComponent}
                hasRowSelection={!!rowSelection}
              />
            ),
          },
        }}
        loading={isLoading}
        locale={{
          emptyText: (
            <EmptyTable
              title={emptyMessage || `You don't have any ${capitalize(subject)} defined yet`}
              subtitle={onAdd && `Start creating/adding a ${subject} by clicking the button above`}
              buttonText={onAdd && `Add ${capitalize(subject)}`}
              onClick={onAdd}
            />
          ),
        }}
        expandIcon={ExpandIcon}
        expandedRowRender={expandedRowRender}
        expandedRowKeys={Object.keys(expandedKeys)}
        rowSelection={rowSelection}
        {...rest}
      />
    </WrapperCard>
  );
}

ActionTable.defaultProps = {
  addText: 'Add',
  columns: [],
  dataSource: [],
  allowSearch: true,
  expandedRowKeys: null,
  allowDeepSearch: true,
  useBackEndSearch: false,
  onExpand: Function.prototype,
  onCollapse: Function.prototype,
};

export default ActionTable;
