import { produce } from 'immer';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { ADD_SECONDARY_REGISTRANT_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import { IndividualReservationPersonFormData } from '@/modules/shared/components/PersonalInfoForm/types';
import { ValueOf } from '@/modules/utils/typeUtils';

import { secondaryRegistrantStepKeys } from '../constants';
import { moduleName as findPersonStepModuleName } from '../steps/FindPersonStep/constants';
import findPersonStepReducers from '../steps/FindPersonStep/duck/reducers';
import { AddSecondaryRegistrantModalParams } from '../types';

import {
  addAdditionalRegistrant,
  collectFormParts,
  collectRegistrationData,
  disableStep,
  enableStep,
  openNextStep,
  openPrevStep,
  openStep,
  openStepCompleted,
} from './actions';

const initialModalData = createReducer<AddSecondaryRegistrantModalParams | null>(null).handleAction(
  openModal,
  (_state, { modalName, params }) =>
    modalName === ADD_SECONDARY_REGISTRANT_MODAL
      ? (params as AddSecondaryRegistrantModalParams)
      : null,
);

const stepIndex = createReducer({ future: 0, current: 0 })
  .handleAction(openNextStep, state =>
    produce(state, draft => {
      draft.future += 1;
    }),
  )
  .handleAction(openPrevStep, state =>
    produce(state, draft => {
      draft.future -= 1;
    }),
  )
  .handleAction(openStepCompleted, state =>
    produce(state, draft => {
      draft.current = draft.future;
    }),
  );

const stepIsLoading = createReducer(false)
  .handleAction([openNextStep, openPrevStep, openStep], () => true)
  .handleAction(openStepCompleted, () => false);

const secondaryRegistrantFormRecordGuid = createReducer<null | string>(null).handleAction(
  addAdditionalRegistrant.success,
  (_, { payload }) => payload,
);

const disabledSteps = createReducer(
  {} as Record<ValueOf<typeof secondaryRegistrantStepKeys>, string>,
)
  .handleAction(disableStep, (state, { payload }) =>
    produce(state, draft => {
      draft[payload.stepKey] = payload.reason;
    }),
  )
  .handleAction(enableStep, (state, { payload }) =>
    produce(state, draft => {
      delete draft[payload];
    }),
  );

const collectedFormParts = createReducer<null | IndividualReservationPersonFormData['formParts']>(
  null,
).handleAction(collectFormParts, (state, { payload }) =>
  produce(state, draft => {
    if (!draft) return payload;
    payload.forEach(fp => {
      const existingFormPart = draft.find(({ formPartCode }) => formPartCode === fp.formPartCode);
      if (existingFormPart) {
        fp.formItems.forEach(fi => {
          const existingFormItemIndex = existingFormPart.formItems.findIndex(
            ({ formItemCode }) => formItemCode === fi.formItemCode,
          );
          if (existingFormItemIndex !== -1) {
            existingFormPart.formItems.splice(existingFormItemIndex, 1);
          }
          existingFormPart.formItems.push(fi);
        });
      } else {
        draft.push(fp);
      }
    });
  }),
);
const collectedRegistrationData = createReducer({} as Partial<AttendeeDetails>).handleAction(
  collectRegistrationData,
  (state, { payload }) =>
    produce(state, draft => {
      Object.assign(draft, payload);
    }),
);

const addAdditionalRegistrantInProgress = createReducer(false)
  .handleAction(addAdditionalRegistrant.request, () => true)
  .handleAction([addAdditionalRegistrant.success, addAdditionalRegistrant.failure], () => false);

export default combineReducers({
  initialModalData,
  stepIndex,
  stepIsLoading,
  secondaryRegistrantFormRecordGuid,
  disabledSteps,
  collectedFormParts,
  collectedRegistrationData,
  addAdditionalRegistrantInProgress,
  [findPersonStepModuleName]: findPersonStepReducers,
});
