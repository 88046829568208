import { Card as AntCard } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Container = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  margin: 12px 0;
  border-radius: 3px;
  overflow: hidden;

  :last-child {
    margin-bottom: 24px;
  }
`;

export const Header = styled.div`
  font-size: 16px;
  font-weight: 600;
  height: 35px;
  background-color: ${colors.scoutingBlue};

  display: flex;
  align-items: center;
  padding: 0 24px;
  color: ${colors.white};
`;

export const Description = styled.div`
  background-color: ${colors.gray_1};
  padding: 10px 24px;
  margin: 0;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Card = styled(AntCard)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;
