import { Button } from 'antd';
import React from 'react';

import { TrimmedVariable } from '../../../../types';

import styles from './Variable.less';

type Props = {
  variable: TrimmedVariable;
  onClick: (variable: TrimmedVariable) => void;
};

const Variable: React.FC<Props> = ({ variable, onClick }) => {
  const { caption, value } = variable;

  const handleClick: React.MouseEventHandler = e => {
    e.preventDefault();
    onClick(variable);
  };

  return (
    <div className={styles.variable}>
      <Button onMouseDown={handleClick} className={styles.caption}>
        {caption}
      </Button>
      &nbsp; - &nbsp;
      {value}
    </div>
  );
};

export default Variable;
