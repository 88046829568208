import { Card } from 'antd';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { Comment } from '@/modules/data/dataTypes/commentList';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { GroupReservation } from '@/modules/data/dataTypes/groupReservation';
import EventLogs from '@/modules/entities/Logs/components/EventLogs';
import { formatLogItem } from '@/modules/entities/Logs/utils';
import { personGuidSel } from '@/modules/user/duck/selectors';

import { TitleAddon } from './ReservationComments.styled';

type GroupProps = {
  isGroup: true;
  groupReservation: GroupReservation;
};

type IndividualProps = {
  isGroup: false;
  attendeeDetails: AttendeeDetails;
};

type Props = {
  form: FormSettings;
  comments: Comment[];
} & (GroupProps | IndividualProps);

const ReservationComments: React.FC<Props> = ({ comments, form, ...props }) => {
  const isLoading = false;
  const currentPersonGuid = useSelector(personGuidSel);
  const logs = comments
    .map(c => formatLogItem('comment', c))
    .map(item => {
      const isSameUser = currentPersonGuid === item.baseData.personGUID;
      return {
        ...item,
        title: (
          <Fragment>
            {item.title}
            {isSameUser && <TitleAddon> (You)</TitleAddon>}
          </Fragment>
        ),
      };
    })
    .sort((a, b) => Number(b.date) - Number(a.date));
  const { formCode } = form;

  return (
    <Card>
      <EventLogs {...props} formCode={formCode} isLoading={isLoading} logs={logs} />
    </Card>
  );
};

export default ReservationComments;
