import React, { useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { GroupReservationsExtraData } from '@/modules/data/dataTypes/groupReservationListReport';
import { createDataSel, createExtraDataSel } from '@/modules/data/duck/selectors';
import { urlGroupReservationGUIDSel } from '@/modules/location/duck/selectors';
import { isLoggedInSel, isOlderAgeSel } from '@/modules/user/duck/selectors';

import FormContent from './components/FormContent';
import FormHeader from './components/FormHeader';
import { stepKeys } from './constants';
import { currentStepKeySel, stepIsLoadingSel } from './duck/selectors';
import ActivitiesStep from './steps/ActivitiesStep';
import AddPerson from './steps/AddPerson';
import Addons from './steps/AddonsStep';
import AttendeeTypesStep from './steps/AttendeeType';
import Checkout from './steps/Checkout';
import Confirmation from './steps/Confirmation';
import FormSubmission from './steps/FormSubmission';
import JobPriorities from './steps/JobPriorities';
import PersonalInformation from './steps/PersonalInformation';
import SessionPriorities from './steps/SessionPriorities';
import WaitlistConfirmation from './steps/WaitlistConfirmation';

const stepComponents = {
  [stepKeys.personalInformation]: PersonalInformation,
  [stepKeys.addons]: Addons,
  [stepKeys.sessionPriorities]: SessionPriorities,
  [stepKeys.activities]: ActivitiesStep,
  [stepKeys.jobPriorities]: JobPriorities,
  [stepKeys.formSubmission]: FormSubmission,
  [stepKeys.addPerson]: AddPerson,
  [stepKeys.checkout]: Checkout,
  [stepKeys.confirmation]: Confirmation,
  [stepKeys.attendeeTypes]: AttendeeTypesStep,
  [stepKeys.waitlistConfirmation]: WaitlistConfirmation,
} as const;

type MapProps = ReturnType<typeof mapState>;

type Props = MapProps;

const CreateReservationPage: React.FC<Props> = ({ isOlder, groupReservationGUID }) => {
  const isLoggedIn = useSelector(isLoggedInSel);
  const stepKey = useSelector(currentStepKeySel);
  const stepIsLoading = useSelector(stepIsLoadingSel);
  const formSettings = useSelector(createDataSel('form'));
  const groupReservationsListExtraData: GroupReservationsExtraData | undefined = useSelector(
    createExtraDataSel('reservationListReport'),
  );

  const { allowGroupRegistration } = formSettings;

  const renderStepComponent = useCallback(() => {
    const StepComponent = stepComponents[stepKey];
    return <StepComponent />;
  }, [stepKey]);

  return (
    <React.Fragment>
      <FormHeader />
      <FormContent
        formSettings={formSettings}
        isOlder={isOlder}
        isLoggedIn={isLoggedIn}
        stepIsLoading={stepIsLoading}
        hasPendingReservations={
          allowGroupRegistration && !!groupReservationsListExtraData?.hasPendingReservation
        }
        groupReservationGUID={groupReservationGUID}
      >
        {renderStepComponent()}
      </FormContent>
    </React.Fragment>
  );
};

const mapState = (
  state: RootState,
): {
  isOlder: boolean;
  groupReservationGUID: string;
} => ({
  isOlder: isOlderAgeSel(state),
  groupReservationGUID: urlGroupReservationGUIDSel(state),
});

export default connect(mapState)(CreateReservationPage);
