import ReactGA from 'react-ga';

// Initialize analytics providers, can be more than one at a time.
export const init = (UA: string) => {
  ReactGA.initialize(UA);
};

// See docs for google analytics set method.
export const set = (fieldsObject = {}) => {
  ReactGA.set(fieldsObject);
};

// See docs for google analytics pageView method.
export const pageView = (path: string) => {
  ReactGA.pageview(path);
};

// Starts API performance measure and returns object to finish and clear performance measures.
export const startPerformanceMeasure = (markName: string) => {
  const timeStamp = Date.now();
  const startMark = `start-${markName}-${timeStamp}`;
  const endMark = `end-${markName}-${timeStamp}`;
  const differenceName = `difference-${markName}-${timeStamp}`;

  performance.mark(startMark);
  return {
    finishPerformanceMeasureAndClear: (prefix: string) => {
      performance.mark(endMark);
      performance.measure(differenceName, startMark, endMark);
      const measure = performance.getEntriesByName(differenceName, 'measure')[0];

      measure &&
        ReactGA.timing({
          category: 'API requests',
          variable: markName,
          value: Math.round(measure.duration),
          label: prefix,
        });

      performance.clearMarks(startMark);
      performance.clearMarks(endMark);
      performance.clearMeasures(differenceName);
    },
    clearFailedPerformanceMeasure: () => {
      performance.clearMarks(startMark);
    },
  };
};
