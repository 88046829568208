import { Input } from 'antd';
import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Configuration } from '@/modules/data/dataTypes/configuration';
import { createDataSel, createIsLoadingSel } from '@/modules/data/duck/selectors';
import { jobStatus, JOB_PERIODS } from '@/modules/entities/Jobs/constants';
import { updateFormJob } from '@/modules/entities/Jobs/duck/actions';
import { updateFormJobInProgressSel } from '@/modules/entities/Jobs/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import { formattedDatesRange } from '@/modules/utils/dateFormats';

import { sessionSel } from '../../duck/selectors';
import { JobActionType } from '../StaffOfferBanner/constants';

import { Body, DeclineReasonCounter } from './AttendeeJobOfferModal.styled';
import { AttendeeJobOfferModalParams } from './types';

import L from 'SHARED/components/L';

const MAX_DECLINE_REASON_LENGTH = 200;

type Props = {
  modalParams: AttendeeJobOfferModalParams;
};

const AttendeeJobOfferModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const [declineReason, setDeclineReason] = useState('');
  const session = useSelector(sessionSel);
  const { formRecordGUID, firstName, lastName } = useSelector(createDataSel('attendeeDetails'));
  const isLoadingJobs = useSelector(createIsLoadingSel('formRecordJobs'));
  const updateFormJobInProgress = useSelector(updateFormJobInProgressSel);
  const configuration: Configuration | undefined = useSelector(createDataSel('configuration'));
  const offerExpirationDays = configuration?.dayCountUntilStaffOfferExpiration || 14;

  const { formCode, type, offeredJob, jobPeriodCode, priority } = modalParams;

  const currentContent = useMemo(() => {
    const { jobName } = offeredJob;
    const sessionDateRange = session
      ? formattedDatesRange(session.startDate, session.closeDate)
      : '';
    const offeredJobPeriod = JOB_PERIODS.find(({ code }) => code === jobPeriodCode);
    const jobPeriodText = offeredJobPeriod ? `${offeredJobPeriod.name}` : 'Unknown period';

    const contentData = {
      [JobActionType.DECLINE]: {
        title: 'Decline Position',
        header: `Are you sure you want to DECLINE ${jobName} position to ${firstName} ${lastName}?`,
        body: `${firstName} ${lastName}’s application will be passed on to the next hiring manager immediately. You will not be able to access ${firstName}’s Application while it is being reviewed by other hiring managers.`,
        sessionText: '',
        jobPeriodText: '',
        buttonText: 'DECLINE POSITION',
        status: jobStatus.job_collaborator_declined.code,
      },
      [JobActionType.APPROVE]: {
        title: 'Make Offer',
        header: `Are you sure you want to OFFER ${jobName} position to ${firstName} ${lastName}?`,
        body: `${firstName} ${lastName} will receive an email offer. If ${firstName} ${lastName} doesn't accept the position within ${offerExpirationDays} days, he/she will forfeit this offer and his/her application will be passed on to the next JST Hiring Manager.`,
        sessionText: `${session?.sessionName} | ${sessionDateRange}`,
        jobPeriodText,
        buttonText: 'MAKE OFFER',
        status: jobStatus.job_offered.code,
      },
      [JobActionType.CANCEL]: {
        title: 'Cancel Position',
        header: `Are you sure you want to CANCEL ${jobName} position for ${firstName} ${lastName}?`,
        body: `${firstName} ${lastName}’s job application will be cancelled and open. You will be able to offer a different position.`,
        sessionText: `${session?.sessionName} | ${sessionDateRange}`,
        jobPeriodText: '',
        buttonText: 'CANCEL OFFER',
        status: jobStatus.job_pending.code,
      },
    };

    return contentData[type];
  }, [type, offeredJob, jobPeriodCode, session, offerExpirationDays, firstName, lastName]);

  function handleOffer() {
    const { jobCode } = offeredJob;
    dispatch(
      updateFormJob.request({
        formCode,
        jobsToUpdate: [
          {
            formRecordGUID,
            jobCode,
            priority: priority >= 0 ? priority : 0,
            status: currentContent.status,
            jobPeriodCode,
          },
        ],
        successMessage: 'Job status updated',
        finalActions: [closeModal()],
      }),
    );
  }

  const handleDeclineReasonText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_DECLINE_REASON_LENGTH) {
      setDeclineReason(value);
    }
  };

  return (
    <ModalComponent
      title={currentContent.title}
      inProgress={updateFormJobInProgress || isLoadingJobs}
      buttons={[
        {
          title: currentContent.buttonText,
          type: 'primary',
          onClick: handleOffer,
        },
      ]}
    >
      <p>
        <b>{currentContent.header}</b>
      </p>
      {type !== JobActionType.DECLINE && (
        <p>
          <b>For Session:</b> {currentContent.sessionText}
        </p>
      )}
      {!!currentContent.jobPeriodText && (
        <p>
          <b>Job Period: </b> {currentContent.jobPeriodText}
        </p>
      )}
      <Body>{currentContent.body}</Body>
      {type === JobActionType.DECLINE && (
        <React.Fragment>
          <L forInput="reason" size="1" colored>
            Reason for Declining
          </L>
          <Input.TextArea
            id="reason"
            onChange={handleDeclineReasonText}
            autoSize={{ minRows: 2, maxRows: 6 }}
            placeholder="Type here..."
            value={declineReason}
          />
          <DeclineReasonCounter>
            {`${declineReason.length}/${MAX_DECLINE_REASON_LENGTH}`}
          </DeclineReasonCounter>
        </React.Fragment>
      )}
    </ModalComponent>
  );
};

export default AttendeeJobOfferModal;
