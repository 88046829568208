import { Card } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Title = styled.h2`
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  color: ${colors.primary_5};
  margin: 0;
`;

export const CardHeader = styled.header`
  padding: 22px;
`;

export const CardNoPadding = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
  .ant-table-title {
    padding: 0;
  }
`;
