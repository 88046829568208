import React from 'react';

import { Payment } from '@/modules/data/dataTypes/paymentList';
import { transactionStatuses } from '@/modules/payments/constants';
import StatusComponent from '@/modules/shared/components/StatusComponent';

type Props = {
  payment: Payment;
};

const PaymentStatus: React.FC<Props> = ({ payment: { statusCode, status } }) => (
  <StatusComponent
    status={statusCode}
    statusLabel={status}
    successStatuses={[transactionStatuses.completed.code]}
    dangerStatuses={[transactionStatuses.failed.code, transactionStatuses.cancelled.code]}
  />
);

export default PaymentStatus;
