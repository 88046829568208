import React, { PropsWithChildren } from 'react';

import { CardContent, CardDescription, StyledCard } from './AddonsWrapper.styled';

type Props = {
  addonsSummary?: string;
};

const AddonsWrapper: React.FC<PropsWithChildren<Props>> = ({ children, addonsSummary }) => (
  <StyledCard header="Add-ons" description={addonsSummary}>
    <CardDescription>Choose Add-ons for your session.</CardDescription>
    <CardContent>{children}</CardContent>
  </StyledCard>
);

export default AddonsWrapper;
