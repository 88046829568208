import { createPageDataParams } from '@/modules/data/utils';

import { moduleName as parentModuleName } from '../../constants';

export const moduleName = 'programs';
export const modulePath = [parentModuleName, moduleName];

export const stepDataParams = createPageDataParams(
  { dataType: 'programs' },
  { dataType: 'ledgerAccounts' },
);
