import { List } from 'antd';
import styled from 'styled-components';

import Input from '@/modules/shared/components/SearchInput';
import colors from '@/modules/styles/colors';

export const StyledList = styled(List)`
  border: 1px solid ${colors.gray};
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
` as typeof List;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Column = styled.div<{ flex?: number | string }>`
  display: flex;
  flex: ${({ flex }) => flex || 1};
  flex-direction: column;
  transition: flex 0.2s;
`;

export const SearchInput = styled(Input)`
  background: white;
  margin: 0;
  padding: 8px 24px;
  border: 1px solid ${colors.lightGrey};

  input {
    border: none;
  }

  .ant-input-group-addon {
    padding-left: 0;
  }
`;
