import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Col = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
`;
