import { Text, View, Link } from '@react-pdf/renderer';
import React, { useCallback } from 'react';
import { Element as SlateElement, CustomElement } from 'slate';

import { ElementType } from '@/modules/richTextEditor/constants';

import Leaf from '../Leaf';

type Props = {
  element: CustomElement;
  index?: number;
};

const Element: React.FC<Props> = ({ element, index: elementIndex }) => {
  const { type, children } = element;

  const renderChildren = useCallback(
    () =>
      children.map((descendant, index) => {
        if (SlateElement.isElement(descendant))
          return (
            <Element
              key={index}
              element={descendant}
              {...(type === ElementType.orderedList ? { index } : {})}
            />
          );
        return <Leaf leaf={descendant} key={index} />;
      }),
    [children, type],
  );

  switch (type) {
    case ElementType.orderedList:
    case ElementType.unorderedList:
      return <View style={{ marginLeft: 20, flexDirection: 'column' }}>{renderChildren()}</View>;
    case ElementType.listItem:
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              marginRight: 5,
            }}
          >
            {typeof elementIndex === 'number' ? `${elementIndex + 1}.` : '•'}
          </Text>
          <Text>{renderChildren()}</Text>
        </View>
      );
    case ElementType.indentation:
      return <View style={{ marginLeft: 30 }}>{renderChildren()}</View>;
    case ElementType.link:
      return <Link src={element.url}>{renderChildren()}</Link>;
    default:
      return <Text style={{ marginBottom: 10 }}>{renderChildren()}</Text>;
  }
};

export default Element;
