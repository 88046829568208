import { Collapse } from 'antd';
import styled from 'styled-components';

import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';
import colors from '@/modules/styles/colors';

const { Panel } = Collapse;

interface CustomPanelProps {
  isBlockSelected: boolean;
  screenSize: ReturnType<typeof useGetScreenSize>;
}

export const CustomPanel = styled(Panel)<CustomPanelProps>`
  &.ant-collapse-item {
    margin: 10px 0;
    border-style: solid;
    border-color: ${props => (props.isBlockSelected ? `${colors.info} !important` : colors.gray_3)};
    border-width: ${props => (props.isBlockSelected ? '2px' : '1px')};
    border-radius: 5px;
    width: 32%;
    display: table;

    width: ${props => (props.screenSize === 'mobile' ? '99%' : '49%')};

    .ant-collapse-content {
      background-color: transparent;
    }

    .ant-collapse-header {
      padding: 1.2rem 2.2rem;
      color: ${colors.primary_5};
      font-weight: bold;
      i {
        width: 10px;
        left: auto !important;
        right: 16px !important;
        height: 38px;
        line-height: 38px !important;
        margin-top: -2px !important;
      }
    }
  }
`;

export const CustomCollapse = styled(Collapse)`
  background-color: ${colors.white};
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
