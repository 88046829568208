import { find, groupBy, sortBy } from 'lodash';

import { Attendee } from '@/modules/data/dataTypes/rosterAttendees';
import { AttendeeType, Roster } from '@/modules/data/dataTypes/rosterList';

import { GroupedAttendees } from './types';

export const EXTRA_ATTENDEE_TYPE = 'extraAttendeeType';

export const groupAttendees = (
  attendees: Attendee[] = [],
  roster: Roster,
  showAddButton = false,
  pageNumber = 0,
): GroupedAttendees => {
  const { attendeeTypes } = roster;
  const sortedAttendeeTypes = sortBy(attendeeTypes, ({ typeCode }) =>
    typeCode.toLowerCase(),
  ).filter(attendeeType => attendeeType.numberOfAttendees > 0);

  const typesWithoutAttendees = sortBy(attendeeTypes, ({ typeCode }) =>
    typeCode.toLowerCase(),
  ).filter(attendeeType => attendeeType.numberOfAttendees === 0);

  const leadAdvisorIndex = attendees.findIndex(
    ({ rosterPositionType }) => rosterPositionType.toLowerCase() === 'lead advisor',
  );
  const { attendeeTypeCode } = attendees[leadAdvisorIndex] || {};

  const leadAdvisorAttendeeType = find(sortedAttendeeTypes, { typeCode: attendeeTypeCode });

  const otherAttendees =
    leadAdvisorIndex !== -1
      ? [...attendees.slice(0, leadAdvisorIndex), ...attendees.slice(leadAdvisorIndex + 1)]
      : [...attendees];

  const groupedByAttendeeTypeCode = groupBy(otherAttendees, 'attendeeTypeCode');

  const leadAdvisorWithGroupHeader: GroupedAttendees =
    leadAdvisorIndex === -1
      ? []
      : [
          {
            isGroupHeader: true,
            showAddButton: false,
            attendeeType: {
              typeName: `Lead Advisor (${leadAdvisorAttendeeType?.typeName})`,
            } as AttendeeType,
          },
          attendees[leadAdvisorIndex],
        ];

  const attendeeTypesEmptyHeader: GroupedAttendees =
    pageNumber === 1
      ? typesWithoutAttendees.map(attendeeType => ({
          isGroupHeader: true,
          showAddButton: showAddButton && attendeeType.numberOfAttendees < attendeeType.maxCapacity,
          attendeeType,
        }))
      : [];

  const groupedAttendees = sortedAttendeeTypes.reduce(
    (acc, attendeeType): GroupedAttendees => {
      const { typeCode, maxCapacity, numberOfAttendees } = attendeeType;

      // don't adding group header of attendee type that doesn't exist in pages others that first one
      if (!groupedByAttendeeTypeCode[typeCode]?.length) return acc;

      const attendeesWithGroupHeader: GroupedAttendees = [
        {
          isGroupHeader: true,
          showAddButton: showAddButton && numberOfAttendees < maxCapacity,
          attendeeType,
        },
        ...(groupedByAttendeeTypeCode[typeCode] || []),
      ];

      const nextGroupedAttendees: GroupedAttendees = [...acc, ...attendeesWithGroupHeader];

      return nextGroupedAttendees;
    },
    [...leadAdvisorWithGroupHeader, ...attendeeTypesEmptyHeader],
  );

  const attendeeTypesGrouped = groupBy(sortedAttendeeTypes, 'typeCode');
  const extraAttendees = attendees.filter(
    attendee => !attendeeTypesGrouped[attendee.attendeeTypeCode],
  );

  if (extraAttendees.length) {
    const extraAttendeesHeader: GroupedAttendees = [
      {
        isGroupHeader: true,
        showAddButton: false,
        attendeeType: {
          isRosterLead: false,
          maxCapacity: 100,
          minCapacity: 0,
          numberOfArchivedAttendees: 0,
          numberOfAttendees: extraAttendees.length,
          restrictions: {
            adultOnly: false,
            gender: [],
            isBSAMember: false,
            maxAge: 0,
            minAge: 100,
            programId: [''],
          },
          typeCode: EXTRA_ATTENDEE_TYPE,
          typeName: 'Other Attendees (From Session Change)',
        },
      },
    ];

    return [...groupedAttendees, ...extraAttendeesHeader, ...extraAttendees];
  }

  return groupedAttendees;
};
