import { FormSettings } from '@/modules/data/dataTypes/form';
import { Person } from '@/modules/data/dataTypes/person';
import { Role } from '@/modules/data/dataTypes/roleList';
import { PersonRoles } from '@/modules/entities/Roles/constants';

export const hasAssignedPermission = (
  assignedRoles: FormSettings['assignedRoles'],
  comparator: (permission: FormSettings['assignedRoles'][number]['permissions'][number]) => boolean,
): boolean => assignedRoles.some(({ permissions }) => permissions.some(comparator));

export const hasAssignedPermissionWithCode = (
  assignedRoles: FormSettings['assignedRoles'],
  permissionCode: string,
): boolean => hasAssignedPermission(assignedRoles, p => p.permissionCode === permissionCode);

export const hasAssignedRoleWithCode = (roles: Role[] = [], roleCode: string): boolean =>
  roles.some(r => r.roleCode === roleCode);

export const getIsEAD = (
  form: FormSettings,
  currentPerson: Person,
  councilNumber?: string | null,
  hostCouncilNumber?: string | null,
): boolean => {
  const { requireCouncilApproval } = form;
  const hasEadRole = hasAssignedRoleWithCode(
    currentPerson.roles,
    PersonRoles.EventApprovalDesignee,
  );

  if (!requireCouncilApproval || !hasEadRole) return false;

  if (councilNumber || hostCouncilNumber) {
    return [hostCouncilNumber, councilNumber].includes(currentPerson.councilNumber);
  }

  return true;
};
