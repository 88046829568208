/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';

import { DataBlockItem } from '@/modules/data/dataTypes/dataBlockItemList';
import { FormPartsData } from '@/modules/data/dataTypes/formPartsData';
import { OptionSetItem } from '@/modules/data/dataTypes/optionSets';
import { VisibilitySettingsParsed } from '@/modules/data/dataTypes/visibilitySettings';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';
import { ConnectDataBlockItem } from '../types';

export const updateFormPart = createAsyncAction(
  `${moduleName}/UPDATE_FORM_PART_REQUEST`,
  `${moduleName}/UPDATE_FORM_PART_RESPONSE`,
  `${moduleName}/UPDATE_FORM_PART_ERROR`,
)<
  Pick<FormPartsData['formParts'][number], 'formPartCode' | 'formPartName' | 'description'>,
  undefined,
  Error
>();

export const updateDatablockItem = createAsyncAction(
  `${moduleName}/UPDATE_DATABLOCK_ITEM_REQUEST`,
  `${moduleName}/UPDATE_DATABLOCK_ITEM_RESPONSE`,
  `${moduleName}/UPDATE_DATABLOCK_ITEM_ERROR`,
)<{ dataBlockItem: DataBlockItem; formPartCode: string }, undefined, Error>();

export const connectDatablockItem = createAsyncAction(
  `${moduleName}/CONNECT_DATABLOCK_ITEM_REQUEST`,
  `${moduleName}/CONNECT_DATABLOCK_ITEM_RESPONSE`,
  `${moduleName}/CONNECT_DATABLOCK_ITEM_ERROR`,
)<ConnectDataBlockItem, undefined, ApiError>();

export const removeDatablockItem = createAction(`${moduleName}/REMOVE_DATABLOCK_ITEM`)<{
  datablockCode: string;
  datablockItemCode: string;
  formPartCode: string;
}>();

export const setEditingFieldId = createAction(`${moduleName}/SET_EDITING_FIELD_ID`)<string>();

export const setEditingSectionCodes = createCustomAction(
  `${moduleName}/SET_EDITING_SECTION_CODES`,
  (formPartCode = '', formItemCode = '') => ({
    payload: {
      formPartCode,
      formItemCode,
    },
  }),
);

export const updateVisibilitySettings = createAsyncAction(
  `${moduleName}/UPDATE_VISIBILITY_SETTINGS_REQUEST`,
  `${moduleName}/UPDATE_VISIBILITY_SETTINGS_SUCCESS`,
  `${moduleName}/UPDATE_VISIBILITY_SETTINGS_FAILURE`,
)<VisibilitySettingsParsed & { successMessage?: string }, undefined, ApiError>();

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const removeDataBlock = createAsyncAction(
  `${moduleName}/REMOVE_DATABLOCK_REQUEST`,
  `${moduleName}/REMOVE_DATABLOCK_SUCCESS`,
  `${moduleName}/REMOVE_DATABLOCK_FAILURE`,
)<string, undefined, ApiError>();

// <UPDATE FORM ITEM SETTINGS>

export const updateFormItemOptions = createAsyncAction(
  `${moduleName}/UPDATE_FORM_ITEM_OPTIONS_REQUEST`,
  `${moduleName}/UPDATE_FORM_ITEM_OPTIONS_SUCCESS`,
  `${moduleName}/UPDATE_FORM_ITEM_OPTIONS_FAILURE`,
)<
  {
    formPart: FormPartsData['formParts'][number];
    formItem: Pick<
      FormPartsData['formParts'][number]['formItems'][number],
      'presentationType' | 'formItemCode' | 'optionSetCode'
    >;
    options?: OptionSetItem[];
  },
  string | undefined,
  ApiError
>();

export const deleteFormItemOptions = createAsyncAction(
  `${moduleName}/DELETE_FORM_ITEM_OPTIONS_REQUEST`,
  `${moduleName}/DELETE_FORM_ITEM_OPTIONS_SUCCESS`,
  `${moduleName}/DELETE_FORM_ITEM_OPTIONS_FAILURE`,
)<
  {
    formPart: FormPartsData['formParts'][number];
    formItem: Pick<
      FormPartsData['formParts'][number]['formItems'][number],
      'presentationType' | 'formItemCode' | 'optionSetCode'
    >;
    options?: OptionSetItem[];
  },
  undefined,
  ApiError
>();

export const updateFormItem = createAsyncAction(
  `${moduleName}/UPDATE_FORM_ITEM_REQUEST`,
  `${moduleName}/UPDATE_FORM_ITEM_SUCCESS`,
  `${moduleName}/UPDATE_FORM_ITEM_FAILURE`,
)<
  {
    formPart: FormPartsData['formParts'][number];
    formItem: Pick<
      FormPartsData['formParts'][number]['formItems'][number],
      | 'formItemCode'
      | 'formItemName'
      | 'description'
      | 'isMandatory'
      | 'isVisible'
      | 'optionSetCode'
      | 'placeholderText'
      | 'presentationType'
      | 'datablockItemCode'
    >;
    options?: OptionSetItem[];
  },
  undefined,
  ApiError
>();

// </UPDATE FORMITEM SETTINGS>

export const addDataBlock = createAsyncAction(
  `${moduleName}/ADD_DATABLOCK_REQUEST`,
  `${moduleName}/ADD_DATABLOCK_SUCCESS`,
  `${moduleName}/ADD_DATABLOCK_FAILURE`,
)<
  Array<{
    formPartName: string;
    datablockCode: string;
    description: string;
    positionNumber: string;
    pageNumber: string;
  }>,
  undefined,
  ApiError
>();
// )<DataBlock[], undefined, ApiError>();

// REORDER FORM PART

export const reorderFormParts = createAsyncAction(
  `${moduleName}/REORDER_FORM_PART_REQUEST`,
  `${moduleName}/REORDER_FORM_PART_SUCCESS`,
  `${moduleName}/REORDER_FORM_PART_FAILURE`,
)<{ currentFormPartCode: string; otherFormPartCode: string }, undefined, ApiError>();

export const reorderFormItems = createAction(`${moduleName}/REORDER_FORM_ITEMS`)<{
  formPartCode: string;
  positionNumbers: Record<string, number>;
}>();

// REORDER FORM PART
