import { produce } from 'immer';
import { set } from 'lodash';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { openNextStep, openPrevStep, openStep, resetModuleState } from './actions';

const stepIndexReducer = createReducer<Record<string, { future: number; current: number }>>({})
  .handleAction(openPrevStep, (state, { payload }) =>
    produce(state, draft => {
      set(draft, [payload, 'future'], (draft[payload]?.future || 0) - 1);
    }),
  )
  .handleAction(openNextStep, (state, { payload }) =>
    produce(state, draft => {
      set(draft, [payload, 'future'], (draft[payload]?.future || 0) + 1);
    }),
  )
  .handleAction(openStep.success, (state, { payload }) =>
    produce(state, draft => {
      set(draft, [payload, 'current'], draft[payload]?.future || 0);
    }),
  )
  .handleAction(resetModuleState, (state, { payload }) =>
    produce(state, draft => {
      delete draft[payload];
    }),
  );

export default combineReducers({
  stepIndex: stepIndexReducer,
});
