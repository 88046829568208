import React from 'react';
import { FormattedMessage } from 'react-intl';

import Settings from '../../Settings';

import { AuthHeaderWrapper, NameWrapper } from './AuthHeader.styled';

import NotificationIcon from 'NOTIFICATIONS/components/NotificationIcon';

type Props = {
  fullName?: string;
  version: string;
};

const AuthHeader: React.FC<Props> = ({ fullName, version = '' }) => (
  <AuthHeaderWrapper>
    <NameWrapper>
      <FormattedMessage id="core.Header.welcome" values={{ user: fullName }} />
    </NameWrapper>
    <div className="mr-1 ml-1">
      <NotificationIcon />
    </div>
    <Settings />
    <span className="ml-1">{version}</span>
  </AuthHeaderWrapper>
);

export default AuthHeader;
