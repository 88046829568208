import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createDataSel, createPageDataSelector } from '@/modules/data/duck/selectors';

import { modulePath, stepDataParams } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulePath);

export const stepDataSel = createPageDataSelector(stepDataParams);

export const editingSectionCodesSel = createSelector(
  moduleSel,
  ({ editingSectionCodes }) => editingSectionCodes,
);

export const editingFormPartSel = createSelector(
  [createDataSel('formPartsData'), editingSectionCodesSel],
  ({ formParts }, { formPartCode }) => formParts.find(fp => fp.formPartCode === formPartCode),
);

export const editingFormItemSel = createSelector(
  [editingFormPartSel, editingSectionCodesSel],
  (formPart, { formItemCode }) => {
    if (formPart && formItemCode) {
      const { formItems } = formPart;
      return formItems.find(fi => fi.formItemCode === formItemCode);
    }
  },
);

export const isLoadingDatablockItemSel = createSelector(
  [moduleSel],
  ({ updateDatablockItemInProgress }) => updateDatablockItemInProgress,
);

export const updateVisibilitySettingsInProgressSel = createSelector(
  moduleSel,
  ({ updateVisibilitySettingsInProgress }) => updateVisibilitySettingsInProgress,
);

export const addDatablockInProgressSel = createSelector(
  moduleSel,
  ({ addDatablockInProgress }) => addDatablockInProgress,
);

export const removeDatablockInProgressSel = createSelector(
  moduleSel,
  ({ removeDatablockInProgress }) => removeDatablockInProgress,
);

export const updateFormItemInProgressSel = createSelector(
  moduleSel,
  ({ updateFormItemInProgress }) => updateFormItemInProgress,
);
