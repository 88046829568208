import { Button } from 'antd';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Attendee } from '@/modules/data/dataTypes/rosterAttendees';
import { sendEmail } from '@/modules/entities/Emails/duck/actions';

import { pageDataSel } from '@/pages/reservation/duck/selectors';

import {
  DisclaimerStatusContainer,
  DisclaimerAcceptedStatusIcon,
  DisclaimerNotAcceptedStatusIcon,
} from './DisclaimerStatus.styled';

type Props = {
  attendeeRecord: Attendee;
};

const DisclaimerStatus: React.FC<Props> = ({ attendeeRecord }) => {
  const dispatch = useDispatch();

  const {
    data: { form },
  } = useSelector(pageDataSel);

  const { formCode } = form;
  const { isAllDisclaimersAccepted, formRecordGUID } = attendeeRecord;

  const onResendDisclaimers = () => {
    dispatch(
      sendEmail.request({
        emailTemplateCode: 'pendingDisclaimerReminder',
        formRecordGUID,
        formCode,
      }),
    );
  };

  return (
    <DisclaimerStatusContainer>
      {isAllDisclaimersAccepted ? (
        <DisclaimerAcceptedStatusIcon />
      ) : (
        <Fragment>
          <DisclaimerNotAcceptedStatusIcon />
          <Button type="link" onClick={onResendDisclaimers}>
            Resend Disclaimer
          </Button>
        </Fragment>
      )}
    </DisclaimerStatusContainer>
  );
};

export default DisclaimerStatus;
