import { AttendeeType } from '@/modules/data/dataTypes/attendeeTypeList';
import apiService from '@/modules/utils/apiService';

import { SessionAddon, UpdateSessionMultiSessionRule, UpdateSessionPayload } from '../types';

const deleteSession$ = (data: { formCode: string; sessionCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteSession',
    data,
  );

const cloneSession$ = (
  formCode: string,
  sourceSessionCode: string,
  sessions: {
    sessionName: string;
    startDate: string;
    closeDate: string;
  }[],
) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=CloneSession',
    {
      formCode,
      sourceSessionCode,
      sessions,
    },
  );

const updateCreateSession$ = (payload: {
  formCode: string;
  sessions: Partial<UpdateSessionPayload>[];
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateSession',
    payload,
  );

const updateAddonSession$ = (payload: {
  formCode: string;
  sessions: {
    sessionCode: string;
    addons: Array<SessionAddon & { isIncluded: boolean }>;
  }[];
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateAddonSession',
    payload,
  );

const updateSessionAttendeeType$ = (payload: {
  formCode: string;
  attendeeTypes: Array<
    Partial<
      Pick<
        AttendeeType,
        | 'typeName'
        | 'isDefault'
        | 'isRosterLead'
        | 'minCapacity'
        | 'maxCapacity'
        | 'fee'
        | 'deposit'
        | 'earlyArrivalDays'
        | 'earlyArrivalFee'
      >
    > & { typeCode: string; sessionCode: string; isGranted: boolean }
  >;
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateSessionAttendeeType',
    payload,
  );

const updateActivitySession$ = (payload: {
  formCode: string;
  sessions: {
    sessionCode: string;
    activities: {
      activityCode: string;
      isIncluded: boolean;
      isMandatory: boolean;
    }[];
  }[];
}) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateActivitySession',
    payload,
  );

const updateSessionMultiSessionRule$ = (data: UpdateSessionMultiSessionRule) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateSessionMultiSessionRuleData',
    data,
  );

export default {
  deleteSession$,
  cloneSession$,
  updateCreateSession$,
  updateAddonSession$,
  updateSessionAttendeeType$,
  updateActivitySession$,
  updateSessionMultiSessionRule$,
};
