import { Popconfirm } from 'antd';
import React, { PropsWithChildren, useState } from 'react';

import { Button } from './ActionButton.styled';

type Props = {
  icon: React.ReactNode;
  confirmText?: string;
  onClick: () => void;
};

const ActionButton: React.FC<PropsWithChildren<Props>> = ({
  confirmText,
  icon,
  onClick,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleConfirm = () => {
    setIsVisible(false);
    onClick();
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleVisibleChange = (visible: boolean) => {
    if (!visible) {
      setIsVisible(visible);
      return;
    }
    if (confirmText) {
      setIsVisible(visible);
    } else {
      handleConfirm();
    }
  };

  return (
    <Popconfirm
      title={confirmText}
      open={isVisible}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      onOpenChange={handleVisibleChange}
    >
      <Button ghost icon={icon} size="small">
        {children}
      </Button>
    </Popconfirm>
  );
};

export default ActionButton;
