import { omit } from 'lodash';

import {
  RESERVATION_LIST_FILTER_CONTENT_EXPRESSIONS,
  RESERVATION_NOT_CANCELLED,
} from './constants';
import type { KeyTypes, FilterContent } from './types';

export const getFilterContentString = (filterContent: FilterContent): string => {
  const objKeys = Object.keys(omit(filterContent, [RESERVATION_NOT_CANCELLED])) as KeyTypes[];

  const filtersArray = objKeys.map((key, i) => ({
    ...RESERVATION_LIST_FILTER_CONTENT_EXPRESSIONS[key],
    ExpressionName: `exp${i}`,
  }));

  let fullFilters = {
    Formula: filtersArray.map(({ ExpressionName }) => `@${ExpressionName}`).join(' OR '),
    Expressions: filtersArray,
  };

  if (RESERVATION_NOT_CANCELLED in filterContent) {
    const expCount = filtersArray.length + 1;

    const newFormula = `(${fullFilters.Formula}) AND @exp${expCount}`;
    const newExpressions = [
      ...fullFilters.Expressions,
      {
        ...RESERVATION_LIST_FILTER_CONTENT_EXPRESSIONS[RESERVATION_NOT_CANCELLED],
        ExpressionName: `exp${expCount}`,
      },
    ];

    fullFilters = {
      Formula: newFormula,
      Expressions: newExpressions,
    };
  }

  return encodeURI(JSON.stringify(fullFilters));
};
