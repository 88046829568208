import { Typography } from 'antd';
import React, { Fragment, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { FormSettings } from '@/modules/data/dataTypes/form';
import { createDataSel } from '@/modules/data/duck/selectors';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';

import AgeRequirement from '@/pages/createReservation/components/AgeRequirementRenderer';
import PendingReservation from '@/pages/createReservation/components/PendingReservation';
import RegisteredRenderer from '@/pages/createReservation/components/RegisteredRenderer';
import SessionTimer from '@/pages/createReservation/components/SessionTimer';
import UnpublishedEvent from '@/pages/createReservation/components/UnpublishedEvent';

import { currentStepIndexSel } from '../../duck/selectors';

import styles from './FormContent.less';

type Props = {
  formSettings: FormSettings;
  isOlder: boolean;
  isLoggedIn: boolean;
  stepIsLoading: boolean;
  hasPendingReservations: boolean;
  groupReservationGUID: string;
};

const FormContent: React.FC<PropsWithChildren<Props>> = ({
  children,
  formSettings,
  isOlder,
  isLoggedIn,
  stepIsLoading,
  hasPendingReservations,
  groupReservationGUID,
}) => {
  const attendeeDetails: AttendeeDetails | undefined = useSelector(
    createDataSel('attendeeDetails'),
  );
  const currentStepIndex = useSelector(currentStepIndexSel);

  if (!formSettings) {
    return (
      <Typography.Title id="noEventMessage" className={styles.centralText} level={3}>
        No such event or event id is invalid.
      </Typography.Title>
    );
  }

  const { formCode, isPublished, allowGroupRegistration } = formSettings;

  if (!isPublished) {
    return <UnpublishedEvent />;
  }

  if (
    currentStepIndex === 0 &&
    !allowGroupRegistration &&
    attendeeDetails &&
    attendeeDetails.isRegistrationCompleted
  ) {
    return (
      <RegisteredRenderer formCode={formCode} formRecordGUID={attendeeDetails.formRecordGUID} />
    );
  }

  if (isLoggedIn && !isOlder && allowGroupRegistration) {
    return <AgeRequirement />;
  }

  if (allowGroupRegistration && hasPendingReservations && !groupReservationGUID) {
    return <PendingReservation formCode={formCode} />;
  }

  return (
    <Fragment>
      <SessionTimer />
      <LoadingContainer isLoading={stepIsLoading}>{children}</LoadingContainer>
    </Fragment>
  );
};

export default FormContent;
