import { get } from 'lodash';
import { redirect } from 'redux-first-router';
import { combineEpics, Epic, StateObservable } from 'redux-observable';
import { of, concat, EMPTY, Observable } from 'rxjs';
import { switchMap, filter, map } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { appInit } from '@/modules/core/duck/actions';
import { currentRouteSel } from '@/modules/location/duck/selectors';
import { navigateToLogin } from '@/modules/user/duck/actions';
import { isLoggedInSel } from '@/modules/user/duck/selectors';

import routes from '../routes';
import { getNeedsAuth } from '../utils';

import { pageLoadCompleted } from './actions';

type GetObservable = (
  action$: Observable<RootAction>,
  state$: StateObservable<RootState>,
) => Observable<RootAction>;

const initialRouteLaunch$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appInit.success)),
    map(() => {
      const currentRoute = currentRouteSel(state$.value);
      return currentRoute;
    }),
  );

const routeEpic$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(({ type }) => !!routes[type]),
    switchMap(({ type }) => {
      const state = state$.value;
      const isLoggedIn = isLoggedInSel(state);

      const needsAuth = getNeedsAuth(type);

      if (needsAuth && !isLoggedIn) {
        return of(redirect(navigateToLogin()));
      }

      const getObservable: undefined | GetObservable = get(routes, [type, 'getObservable']);
      const theObservable = getObservable ? getObservable(action$, state$) : EMPTY;

      return concat(theObservable, of(pageLoadCompleted(type)));
    }),
  );

export default combineEpics(initialRouteLaunch$, routeEpic$);
