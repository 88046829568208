import { Alert as AntAlert } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Alert = styled(AntAlert)`
  &&&.ant-alert {
    background-color: ${colors.white};
    font-size: 18px;
    border-width: 0;
    border-left-width: 8px;
    border-radius: 0;
    margin: 0.5em 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
  .anticon {
    margin-right: 32px;
  }
`;
