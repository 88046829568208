import { createAction, createAsyncAction } from 'typesafe-actions';

import { FoundPerson } from '@/modules/data/dataTypes/foundPersons';
import { Person } from '@/modules/data/dataTypes/person';
import { Roster } from '@/modules/data/dataTypes/rosterList';

import { moduleName } from '../constants';

export const showLeadAdvisorInviteModal = createAction(
  `${moduleName}/SHOW_LEAD_ADVISOR_INVITE_MODAL`,
)();

export const inviteLeadAdvisor = createAsyncAction(
  `${moduleName}/INVITE_LEAD_ADVISOR_REQUEST`,
  `${moduleName}/INVITE_LEAD_ADVISOR_SUCCESS`,
  `${moduleName}/INVITE_LEAD_ADVISOR_FAILURE`,
)<
  {
    memberID: string;
    roster: Roster;
    foundPerson: FoundPerson | Person;
  },
  undefined,
  Error | undefined
>();
