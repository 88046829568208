import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  updateAttendeesAttributes,
  createAndSelectAttendeeAttribute,
} from '@/modules/entities/Attributes/duck/actions';
import {
  createFormAttributeInProgressSel,
  updateAttendeesAttributesInProgressSel,
} from '@/modules/entities/Attributes/duck/selectors';
import TagSelector from '@/modules/shared/components/TagSelector';
import toastService from '@/modules/toasts/service';

import { pageDataSel } from '@/pages/reservation/duck/selectors';
import { tabDataSel } from '@/pages/reservation/tabs/AttendeeDetails/duck/selector';

import LoadingContainer from 'SHARED/components/LoadingContainer';

type Props = {
  canEditAttendeeDetails: boolean;
  canEditAttributes: boolean;
  isEditing?: boolean;
};

const AttributesPicker: React.FC<Props> = ({
  canEditAttributes,
  canEditAttendeeDetails,
  isEditing = false,
}) => {
  const dispatch = useDispatch();
  const {
    data: { attendeeDetails, form },
  } = useSelector(pageDataSel);
  const {
    data: { attributeList, formRecordAttributes },
    isLoading: tabIsLoading,
  } = useSelector(tabDataSel);

  const updateAttributesInProgress = useSelector(updateAttendeesAttributesInProgressSel);
  const createAttributeInProgress = useSelector(createFormAttributeInProgressSel);

  const { formCode } = form;
  const { formRecordGUID } = attendeeDetails;

  const assignedAttributeCodes = useMemo(
    () => formRecordAttributes.map(({ attributeCode }) => attributeCode),
    [formRecordAttributes],
  );

  const handleOnAdd = (_: string, attributeName: string) => {
    dispatch(
      createAndSelectAttendeeAttribute.request({
        formCode,
        attributeName,
        attendeeFormRecordGUID: formRecordGUID,
        assignedAttributes: assignedAttributeCodes,
      }),
    );
  };

  const handleSelect = (attributeCode: string) => {
    if (!canEditAttendeeDetails) {
      return toastService.error("You don't have permissions to edit attendee");
    }

    dispatch(
      updateAttendeesAttributes.request({
        formCode,
        isDelete: false,
        attendees: [formRecordGUID],
        attributes: [...assignedAttributeCodes, attributeCode],
      }),
    );
  };

  const handleDelete = (attributeCode: string) => {
    dispatch(
      updateAttendeesAttributes.request({
        formCode,
        isDelete: true,
        attendees: [formRecordGUID],
        attributes: [attributeCode],
      }),
    );
  };

  return (
    <LoadingContainer isLoading={updateAttributesInProgress || tabIsLoading.formRecordAttributes}>
      <TagSelector
        dataSource={attributeList}
        valueField="attributeCode"
        labelField="attributeName"
        value={assignedAttributeCodes}
        onSelect={handleSelect}
        onDeselect={handleDelete}
        onAdd={handleOnAdd}
        addInProgress={createAttributeInProgress}
        allowEdit={isEditing && canEditAttendeeDetails && canEditAttributes}
      />
    </LoadingContainer>
  );
};

export default AttributesPicker;
