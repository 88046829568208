import cn from 'classnames';
import { capitalize } from 'lodash';
import React from 'react';

import { PAYMENT_SCHEDULE_STATUS_OVERDUE, PAYMENT_SCHEDULE_STATUS_PAID } from '../../constants';

import styles from './StatusColumn.less';

type Props = {
  status?: string;
};

const StatusColumn: React.FC<Props> = ({ status }) => {
  if (!status) return null;
  return (
    <div
      className={cn(styles.statusColumn, {
        [styles.paid]: status === PAYMENT_SCHEDULE_STATUS_PAID,
        [styles.overdue]: status === PAYMENT_SCHEDULE_STATUS_OVERDUE,
      })}
    >
      {capitalize(status)}
    </div>
  );
};

export default StatusColumn;
