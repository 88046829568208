import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { sendCustomEmail, sendEmail, updateEmailTemplate } from './actions';

const sendEmailInProgress = createReducer(false)
  .handleAction(sendEmail.request, () => true)
  .handleAction([sendEmail.success, sendEmail.failure], () => false);

const updateEmailTemplateInProgress = createReducer(false)
  .handleAction(updateEmailTemplate.request, () => true)
  .handleAction([updateEmailTemplate.success, updateEmailTemplate.failure], () => false);

const sendCustomEmailInProgress = createReducer(false)
  .handleAction(sendCustomEmail.request, () => true)
  .handleAction([sendCustomEmail.success, sendCustomEmail.failure], () => false);

export default combineReducers({
  sendCustomEmailInProgress,
  updateEmailTemplateInProgress,
  sendEmailInProgress,
});
