import React from 'react';

import { Roster } from '@/modules/data/dataTypes/rosterList';

import styles from './LeadAdvisor.less';

type Props = {
  roster: Roster;
  onAdd: (roster: Roster) => void;
  canAdd: boolean;
};

const LeadAdvisor: React.FC<Props> = ({ roster, onAdd, canAdd }) => {
  const { leadAdvisorFirstName, leadAdvisorLastName } = roster;
  if (leadAdvisorFirstName || leadAdvisorLastName) {
    return (
      <span>{`${leadAdvisorFirstName || ''}${leadAdvisorFirstName ? ' ' : ''}${
        leadAdvisorLastName || ''
      }`}</span>
    );
  }

  if (!canAdd) return null;

  const handleAdd = () => {
    onAdd(roster);
  };

  return (
    <span className={styles.invite} onClick={handleAdd}>
      Add
    </span>
  );
};

export default LeadAdvisor;
