import React from 'react';
import { useSlate } from 'slate-react';

import { LeafType } from '@/modules/richTextEditor/constants';

import { addMark, removeMark, getFormat } from '../../../../utils';
import ColorPicker from '../ColorPicker';

const FontColorPicker: React.FC = () => {
  const editor = useSlate();
  const resetColor = '#000000';
  const activeColor = getFormat(editor, LeafType.color) as string | undefined;

  const handleColorSelect = (color: string) => {
    if (color === resetColor) {
      removeMark(editor, LeafType.color);
    } else {
      addMark(editor, LeafType.color, color);
    }
  };

  return (
    <ColorPicker onChange={handleColorSelect} activeColor={activeColor} resetColor={resetColor} />
  );
};

export default FontColorPicker;
