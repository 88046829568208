import { Form } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { FormPartsData } from '@/modules/data/dataTypes/formPartsData';

import { AttendeeDetails } from '../data/dataTypes/attendeeDetails';
import { OptionSets } from '../data/dataTypes/optionSets';
import { VisibilitySettingsParsed, VisibilitySettings } from '../data/dataTypes/visibilitySettings';
import LoadingContainer from '../shared/components/LoadingContainer';
import { usePrevious } from '../utils/hooks';
import { parseBase64 } from '../utils/stringFunctions';

import FormPart from './components/FormPart';
import { formPartsSel } from './duck/selectors';
import {
  FormattedFormParts,
  CurrentValuesObj,
  InitialValuesObj,
  TrimmedIncomingFormParts,
} from './types';
import { formatValue, isVisibleByCustomLogic } from './utils';

import CardWithHeader from 'SHARED/components/CardWithHeader';

type BaseProps = {
  isEditing?: boolean;
  isLoading: boolean;
  showEditButton?: boolean;
  editingSectionName?: string | null;
  optionSets: OptionSets;
  visibilitySettings: VisibilitySettings;
  onEdit?: (sectionName: string | null) => void;
  onSubmit?: () => void;
};

type AddAttendeeProps = BaseProps & {
  isEditing: true;
  formPartsData: FormPartsData;
  providedAttendeeType: string;
  checkAttendeeTypeOnly?: true;
};

type UpdateAttendeeProps = BaseProps & {
  attendeeDetails: AttendeeDetails;
  removeSystemFormParts: boolean;
};

type Props = AddAttendeeProps | UpdateAttendeeProps;

const Questions = forwardRef<{ handleSubmit: () => Promise<FormattedFormParts> }, Props>(
  (props, ref) => {
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));
    const {
      isEditing,
      isLoading,
      showEditButton,
      editingSectionName,
      visibilitySettings,
      onEdit,
      onSubmit,
    } = props;
    const [form] = Form.useForm();

    const previousEditingSectionName = usePrevious(editingSectionName);

    useEffect(() => {
      if (previousEditingSectionName) {
        form.resetFields([[previousEditingSectionName]]);
      }
    }, [editingSectionName, previousEditingSectionName, form]);

    const formParts: TrimmedIncomingFormParts =
      'formPartsData' in props ? props.formPartsData.formParts : props.attendeeDetails.formParts;

    const filteredFormParts = useSelector((state: RootState) =>
      formPartsSel(state, { ...props, formParts }),
    );

    const initialValuesObj = useMemo(
      () =>
        formParts.reduce((fpAcc, fp) => {
          const fpVisibilitySettings = visibilitySettings[fp.formPartCode];
          const fpVisibilitySettingsParsed = parseBase64<VisibilitySettingsParsed>(
            fpVisibilitySettings?.visibilitySettings,
          );
          return {
            ...fpAcc,
            [fp.formPartCode]: {
              customVisibilitySettings: fpVisibilitySettingsParsed?.visibilityLogic,
              formItems: fp.formItems.reduce((fiAcc, fi) => {
                const fiVisibilitySettings = fpVisibilitySettings?.['formItems'][fi.formItemCode];
                const fiVisibilitySettingsParsed =
                  parseBase64<VisibilitySettingsParsed>(fiVisibilitySettings);
                return {
                  ...fiAcc,
                  [fi.formItemCode]: {
                    customVisibilitySettings: fiVisibilitySettingsParsed?.visibilityLogic,
                    formItemValue: fi.formItemValue,
                  },
                };
              }, {}),
            },
          };
        }, {} as InitialValuesObj),
      [formParts, visibilitySettings],
    );

    const handleSubmit = () =>
      form
        .validateFields()
        .then((values: CurrentValuesObj) => {
          onEdit && onEdit(null);

          const formattedValues: FormattedFormParts = Object.entries(values).map(
            ([formPartCode, formItems]) => ({
              formPartCode,
              formItems: Object.entries(formItems).map(([formItemCode]) => ({
                formItemCode,
                formItemValue: formatValue(formPartCode, formItemCode, values),
              })),
            }),
          );

          return formattedValues;
        })
        .catch((error: { errorFields: { name: string[] }[] }) => {
          const { errorFields } = error;
          form.scrollToField(errorFields[0].name);
          throw error;
        });

    if (filteredFormParts.length === 0) {
      return <CardWithHeader header="Form">No form was Filled</CardWithHeader>;
    }

    return (
      <LoadingContainer isLoading={isLoading && !editingSectionName}>
        <Form layout="vertical" form={form}>
          {filteredFormParts.map(formPart => {
            const { formPartCode } = formPart;
            const isEditingFormPart = formPartCode === editingSectionName;

            return (
              <Form.Item key={formPartCode} noStyle shouldUpdate>
                {({ getFieldsValue }) => {
                  const currentValues: CurrentValuesObj = getFieldsValue();

                  const isVisible = isVisibleByCustomLogic({
                    isEditingAllFormParts: isEditing,
                    editingFormPartCode: editingSectionName,
                    formPartCode,
                    currentValues,
                    initialValues: initialValuesObj,
                  });

                  if (!isVisible) return null;

                  return (
                    <FormPart
                      {...props}
                      isEditingAllFormParts={isEditing}
                      editingFormPartCode={editingSectionName}
                      initialValues={initialValuesObj}
                      hideButtons={!!isEditing}
                      formPart={formPart}
                      formPartVisibilitySettings={visibilitySettings[formPartCode]}
                      showEditButton={!!showEditButton}
                      isLoading={isEditingFormPart && isLoading}
                      onEditFormPart={onEdit}
                      onSave={onSubmit}
                    />
                  );
                }}
              </Form.Item>
            );
          })}
        </Form>
      </LoadingContainer>
    );
  },
);

export default Questions;
