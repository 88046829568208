import React from 'react';
import { Descendant, Text } from 'slate';

import Element from '../Element';
import Leaf from '../Leaf';

type Props = {
  node: Descendant;
};

const RenderNode: React.FC<Props> = ({ node }) => {
  if (Text.isText(node)) {
    const { text } = node;
    return <Leaf leaf={node}>{text}</Leaf>;
  }

  return (
    <Element element={node} serializing>
      {node.children.map((item, index) => (
        <RenderNode node={item} key={index} />
      ))}
    </Element>
  );
};

export default RenderNode;
