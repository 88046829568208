import React from 'react';

import { dateFromString, usDateTime } from '@/modules/utils/dateFormats';

import { Container } from './Duration.styled';

type Props = {
  endDate: string;
  startDate: string;
};

const Duration: React.FC<Props> = ({ endDate, startDate }) => (
  <Container>{`${usDateTime(dateFromString(startDate))} - ${usDateTime(
    dateFromString(endDate),
  )}`}</Container>
);

export default Duration;
