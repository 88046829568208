import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Select } from 'antd';
import React, { Fragment, forwardRef, useImperativeHandle, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RESERVATION_CODE_COLUMN_NAME } from '../../constants';
import { updateMappedColumns } from '../../duck/actions';
import { columnNamesSel } from '../../duck/selectors';
import { MappedColumn } from '../../types';

import { HeadersRow, Row } from './MapQuestions.styled';
import { initialValuesSel, optionsSel } from './duck/selectors';

type Props = {
  onOpenNextStep: () => void;
};

const MAPPED_COLUMNS_FIELD_NAME = 'MAPPED_COLUMNS_FIELD_NAME';

const IMPORTED_COLUMN_FIELD_NAME: keyof MappedColumn = 'importedColumnName';
const NEXT_COLUMN_FIELD_NAME: keyof MappedColumn = 'nextColumnName';

const MapQuestions = forwardRef<{ handleSubmit: () => void }, Props>(({ onOpenNextStep }, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const dispatch = useDispatch();
  const [form] = Form.useForm<{ [MAPPED_COLUMNS_FIELD_NAME]: MappedColumn[] }>();
  const columnNames = useSelector(columnNamesSel);

  const values = Form.useWatch(MAPPED_COLUMNS_FIELD_NAME, form);
  const options = useSelector(optionsSel);
  const initialValues = useSelector(initialValuesSel);

  function handleSubmit() {
    form
      .validateFields()
      .then(({ [MAPPED_COLUMNS_FIELD_NAME]: mappedColumns }) =>
        mappedColumns.reduce(
          (acc, { importedColumnName, nextColumnName }) => {
            const [formPartCode, formItemCode] = nextColumnName.split('/');
            return {
              ...acc,
              [formPartCode]: {
                ...acc[formPartCode],
                [formItemCode]: importedColumnName,
              },
            };
          },
          {} as Record<string, Record<string, string>>,
        ),
      )
      .then(mappedColumns => {
        dispatch(updateMappedColumns(mappedColumns));
        onOpenNextStep();
      });
  }

  const importedColumnNames = useMemo(
    () => columnNames.filter(cn => cn !== RESERVATION_CODE_COLUMN_NAME),
    [columnNames],
  );

  const selectedNextColumns = useMemo(
    () =>
      new Set(
        (values || [])
          .filter(val => val && val.nextColumnName)
          .map(({ nextColumnName }) => nextColumnName),
      ),
    [values],
  );

  const selectedImportedColumns = useMemo(
    () =>
      new Set(
        (values || [])
          .filter(val => val && val.importedColumnName)
          .map(({ importedColumnName }) => importedColumnName),
      ),
    [values],
  );

  const optionsWithDisabled = useMemo(
    () =>
      options.reduce(
        (acc, option) => {
          const { options: nestedOptions } = option;
          const filteredNestedOptions = nestedOptions.map(nestedOption => ({
            ...nestedOption,
            disabled: selectedNextColumns.has(nestedOption.value),
          }));
          if (!filteredNestedOptions) return acc;
          return [...acc, { ...option, options: filteredNestedOptions }];
        },
        [] as typeof options,
      ),
    [options, selectedNextColumns],
  );

  const removedImportedColumns = useMemo(
    () => importedColumnNames.filter(col => !selectedImportedColumns.has(col)),
    [importedColumnNames, selectedImportedColumns],
  );

  return (
    <Form form={form} initialValues={{ [MAPPED_COLUMNS_FIELD_NAME]: initialValues }}>
      <HeadersRow gutter={10}>
        <Col span={11}>Imported Question Name</Col>
        <Col span={12}>Selected Question Name</Col>
      </HeadersRow>
      <Form.List name={MAPPED_COLUMNS_FIELD_NAME}>
        {(fields, { add, remove }) => (
          <Fragment>
            {fields.map(field => (
              <Row key={field.key} gutter={10}>
                <Col span={11}>
                  <Form.Item
                    name={[field.name, IMPORTED_COLUMN_FIELD_NAME]}
                    rules={[
                      {
                        required: true,
                        message: 'Field is required',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      disabled={!removedImportedColumns.length}
                      options={removedImportedColumns.map(col => ({
                        value: col,
                        label: col,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={[field.name, NEXT_COLUMN_FIELD_NAME]}
                    rules={[
                      {
                        required: true,
                        message: 'Field is required',
                      },
                    ]}
                  >
                    <Select options={optionsWithDisabled} optionFilterProp="label" showSearch />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Button
                    type="text"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                </Col>
              </Row>
            ))}
            <Button
              icon={<PlusOutlined />}
              type="link"
              disabled={!removedImportedColumns.length}
              onClick={() => {
                add();
              }}
            >
              Add
            </Button>
          </Fragment>
        )}
      </Form.List>
    </Form>
  );
});

export default MapQuestions;
