import { moduleName as parentModuleName } from '../../constants';

export const moduleName = 'currentReport';
export const modulePath = [parentModuleName, moduleName];

export const FILTER_KEY_QUERY_FILTER_CODE = 'queryFilterCode';
export const FILTER_KEY_FILTER_CONTENT = 'filterContent';
export const FILTER_KEY_COLUMN_LIST = 'columnList';
export const FILTER_KEY_SORT_ORDER = 'sortOrder';
export const FILTER_KEY_GLOBAL_SEARCH_VALUE = 'globalSearchValue';
