import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { CDN_URL } from '@/modules/config/config';

import { Logo, Title } from './LoginComponent.styled';
import LoginOptions from './components/LoginOptions';

interface Props {
  hideSignInWithCode?: boolean;
}

const LoginComponent: React.FC<Props> = ({ hideSignInWithCode }) => (
  <Fragment>
    <Logo alt="BSA Fleur De Lis" src={`${CDN_URL}FleurDeLis_90px.png`} />
    <Title level={2}>
      <FormattedMessage id="user.LoginPage.bsaEventManagement" />
    </Title>
    <LoginOptions hideSignInWithCode={!!hideSignInWithCode} />
  </Fragment>
);

export default LoginComponent;
