import { createContext, useContext } from 'react';

import { hasAssignedPermissionWithCode } from './utils';

import { PermissionCode } from 'ROLES/constants';
import { formStatus } from 'SHARED/constants';
import Ability from 'UTILS/ability';

// Any of this permissions will grant access to the attendee details page

export const defineAbilities = ({
  isLoggedIn,
  personGuid: loggedPersonGuid,
  formSettings = {},
  isSystemAdministrator = false,
  isEventAdministrator = false,
}) => {
  const { isCreator, isCollaborator, assignedRoles = [] } = formSettings;

  if (!isLoggedIn || !loggedPersonGuid) {
    return new Ability();
  }

  const hasGroupReservationAccess = ({
    isLeadAdvisor,
    isReservationContact,
    isAdditionalReservationContact,
    isAdditionalRC,
  }) =>
    !!isCreator ||
    !!isLeadAdvisor ||
    !!isReservationContact ||
    !!isAdditionalReservationContact ||
    !!isAdditionalRC;

  const hasAttendeeAccess = ({ personGUID, personGuid, isPrimaryRegistrant }) =>
    loggedPersonGuid === personGUID || loggedPersonGuid === personGuid || !!isPrimaryRegistrant;

  const hasPermission = permissionCode =>
    hasAssignedPermissionWithCode(assignedRoles, permissionCode);

  const getIsAllowedToPay = reservationDetails => {
    const isReservationContact = reservationDetails?.isReservationContact;
    const isAdditionalRC = reservationDetails?.isAdditionalReservationContact;
    const samePersonGuid = reservationDetails?.personGUID === loggedPersonGuid;

    return isReservationContact || isAdditionalRC || samePersonGuid;
  };

  const abilities = new Ability();

  abilities.allow({
    actions: 'create',
    targets: 'events',
    condition: (performer, target, params) =>
      params.isCollaborator || isSystemAdministrator || isEventAdministrator,
  });

  abilities.allow({
    actions: 'unrestrictedFindPerson',
    targets: 'event',
    condition: (_performer, _target, { isEventOwner }) =>
      isEventOwner || isCreator || isCollaborator || isEventAdministrator || isSystemAdministrator,
  });

  abilities.allow({
    actions: 'send',
    targets: 'dashboard.attendees.email',
    // allow only Event Approval Designee that also are the creators of the event
    condition: () =>
      isCreator || isSystemAdministrator || hasPermission(PermissionCode.EmailsManageAccess),
  });

  abilities.allow({
    actions: 'create',
    targets: 'reservation',
    condition: () => isCreator || isCollaborator || isSystemAdministrator,
  });

  /* Admin dashboard tabs */

  /* Attendee Details */

  abilities.allow({
    actions: 'edit',
    targets: 'attendee.job.positions',
    condition: (_performer, _target, options) =>
      hasAttendeeAccess(options) ||
      hasGroupReservationAccess(options) ||
      (isCollaborator && hasPermission(PermissionCode.AttendeeEditJobPrioritiesAccess)) ||
      isSystemAdministrator,
  });

  abilities.allow({
    actions: 'edit',
    targets: 'dashboard.attendees.reservation',
    condition: (performer, target, { isCancelled, statusCode, isEADEnabled }) => {
      if (isCancelled) {
        return false;
      }
      return (
        (isCreator || isCollaborator || isSystemAdministrator || isEADEnabled) &&
        [
          formStatus.registration_council_approved.code,
          formStatus.registration_completed.code,
        ].includes(statusCode)
      );
    },
  });

  /* Reports */

  if (
    isCreator ||
    isSystemAdministrator ||
    hasPermission(PermissionCode.QueryFilterExecuteReportsAccess)
  ) {
    abilities.allow({
      actions: 'executePrint',
      targets: 'dashboard.reports',
    });
  }

  abilities.allow({
    actions: 'edit',
    targets: 'attendee.attributes',
    condition: () =>
      isCreator || isSystemAdministrator || hasPermission(PermissionCode.AttributesUpdateAccess),
  });

  /* Reservation page */

  abilities.allow({
    actions: 'view',
    targets: 'reservationPage.eventCostDetails',
    condition: (_performer, _target, { isEADPaymentEnabled, reservationDetails }) => {
      const isAllowedToPay = getIsAllowedToPay(reservationDetails);
      return (
        isCreator ||
        isCollaborator ||
        isEventAdministrator ||
        isSystemAdministrator ||
        isEADPaymentEnabled ||
        isAllowedToPay
      );
    },
  });

  abilities.allow({
    actions: 'view',
    targets: 'reservationPage.rosters',
    condition: (_performer, _target, { isCancelled, ...otherOptions }) =>
      !isCancelled &&
      (hasGroupReservationAccess(otherOptions) ||
        isCollaborator ||
        isEventAdministrator ||
        isSystemAdministrator),
  });

  if (isCreator || isCollaborator || isSystemAdministrator) {
    abilities.allow({
      actions: 'assign',
      targets: 'dashboard.hiringDepartment',
    });
  }

  return abilities;
};

export const useAbilities = () => useContext(AbilityContext);

const AbilityContext = createContext();

export default AbilityContext;
