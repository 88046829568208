import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Session } from '@/modules/data/dataTypes/sessionList';
import { refreshData, scheduleRefresh } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import CheckoutStep from '@/modules/entities/Payments/components/CheckoutStep';
import { confirmRegistration } from '@/modules/entities/Registrations/duck/actions';
import { PaymentMethod } from '@/modules/payments/constants';

import { SECONDARY_REGISTRANT_DATA_ID } from '../../constants';
import { openNextStep } from '../../duck/actions';
import { sessionSel } from '../../duck/selectors';

import { stepDataSel } from './duck/selectors';

const Checkout = forwardRef<{ handleSubmit: () => void }>((_, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const dispatch = useDispatch();
  const checkoutStepRef = useRef<{ handleSubmit: () => void }>(null);
  const {
    data: { attendeeDetails, paymentSummary },
  } = useSelector(stepDataSel);
  const { formCode } = useSelector(createDataSel('form'));
  const session = useSelector(sessionSel) as Session;

  function handleSubmit() {
    checkoutStepRef.current?.handleSubmit();
  }

  const handleOpenNextStep = useCallback(
    (paymentMethod: PaymentMethod | null) => {
      dispatch(openNextStep());
      dispatch(
        scheduleRefresh({ dataType: 'paymentSummary', dataId: SECONDARY_REGISTRANT_DATA_ID }),
      );

      if (
        session &&
        paymentMethod &&
        [PaymentMethod.adjustment, PaymentMethod.costCenter].includes(paymentMethod)
      ) {
        dispatch(
          confirmRegistration.request({
            formCode,
            formRecordGUID: attendeeDetails.formRecordGUID,
            sessionCode: session.sessionCode,
          }),
        );
      }
    },
    [dispatch, formCode, attendeeDetails, session],
  );

  const handleRefreshPaymentsData = useCallback(() => {
    dispatch(refreshData({ dataType: 'paymentSummary', dataId: SECONDARY_REGISTRANT_DATA_ID }));
  }, [dispatch]);

  if (!session) {
    return null;
  }

  return (
    <CheckoutStep
      ref={checkoutStepRef}
      paymentSummary={paymentSummary}
      hideMakePaymentButton
      attendeeDetails={attendeeDetails}
      currentSession={session}
      onOpenNextStep={handleOpenNextStep}
      onRefreshPaymentsData={handleRefreshPaymentsData}
      isGroup={false}
    />
  );
});

export default Checkout;
