import apiService from '@/modules/utils/apiService';

import type { UpdateActivitiesPayload } from '../types';

const updateFormRecordActivity$ = (payload: UpdateActivitiesPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateFormRecordActivity',
    payload,
  );

const updateGroupReservationActivity$ = (payload: UpdateActivitiesPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateGroupReservationActivity',
    payload,
  );

export default {
  updateFormRecordActivity$,
  updateGroupReservationActivity$,
};
