import { createPageDataParams } from '@/modules/data/utils';

export const moduleName = 'eventPage' as const;

export const ROUTE_EVENT_PAGE = `${moduleName}/ROUTE_EVENT_PAGE`;
export const ROUTE_EVENT_PAGE_SESSION_LIST = `${moduleName}/ROUTE_EVENT_PAGE_SESSION_LIST`;

export const pageDataParams = createPageDataParams({ dataType: 'form' });

export enum TabKey {
  OVERVIEW = 'overview',
  RESERVATIONS = 'reservations',
  ATTENDEES = 'attendees',
  SESSIONS = 'sessions',
  ACTIVITIES = 'activities',
  ROSTERS = 'rosters',
  EMAILS = 'emails',
  TRANSACTIONS = 'transactions',
}

export const routeEventPagePathPrefix = '/events/:formCode';

// key should match one of the abilities
export const dashboardTabs = [
  {
    key: TabKey.OVERVIEW,
    label: 'Overview',
  },
  {
    key: TabKey.RESERVATIONS,
    label: 'Reservations',
  },
  {
    key: TabKey.ATTENDEES,
    label: 'Attendees',
  },
  {
    key: TabKey.SESSIONS,
    label: 'Sessions',
  },
  {
    key: TabKey.ACTIVITIES,
    label: 'Activities',
  },
  {
    key: TabKey.ROSTERS,
    label: 'Crews',
  },
  {
    key: TabKey.EMAILS,
    label: 'Emails',
  },
  {
    key: TabKey.TRANSACTIONS,
    label: 'Transactions',
  },
] as const;

export const firstEventPageTabRoute = `${ROUTE_EVENT_PAGE}/${dashboardTabs[0].key}`;
