import { createPageDataParams } from '@/modules/data/utils';

import { SECONDARY_REGISTRANT_DATA_ID } from '../../constants';

export const stepDataParams = createPageDataParams(
  {
    dataType: 'paymentSummary',
    dataId: SECONDARY_REGISTRANT_DATA_ID,
  },
  {
    dataType: 'attendeeDetails',
    dataId: SECONDARY_REGISTRANT_DATA_ID,
  },
);
