import { Col, Form, Row } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateMappedAnswers } from '../../duck/actions';
import { fileDataSel } from '../../duck/selectors';
import { MappedAnswers } from '../../types';

import FormItem from './components/FormItem';
import { importAnswers } from './duck/actions';
import { formItemsWithOptionsSel } from './duck/selectors';

const SelectAnswers = forwardRef<{ handleSubmit: () => void }>((_props, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const dispatch = useDispatch();
  const [form] = Form.useForm<MappedAnswers>();

  const fileData = useSelector(fileDataSel);
  const formItemsWithOptions = useSelector(formItemsWithOptionsSel);

  useEffect(() => {
    if (fileData.length && !formItemsWithOptions.length) {
      dispatch(importAnswers.request());
    }
  }, [dispatch, formItemsWithOptions, fileData]);

  function handleSubmit() {
    form.validateFields().then(values => {
      dispatch(updateMappedAnswers(values));
      dispatch(importAnswers.request());
    });
  }

  return (
    <Form form={form}>
      <Row>
        <Col span={4}>
          <b>Imported Question Name</b>
        </Col>
        <Col span={4}>
          <b>Selected Question Name</b>
        </Col>
        <Col span={8}>
          <b>Imported Value</b>
        </Col>
        <Col span={8}>
          <b>Selected Value</b>
        </Col>
      </Row>
      {formItemsWithOptions.map(fi => (
        <FormItem key={`${fi.formPartCode}/${fi.formItemCode}`} formItem={fi} />
      ))}
    </Form>
  );
});

export default SelectAnswers;
