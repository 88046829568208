import { BellFilled, BellOutlined } from '@ant-design/icons';
import { Popover as AntPopover } from 'antd';
import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

export const Popover = styled(AntPopover)`
  cursor: pointer;

  .ant-badge-count {
    font-size: 10px;
    box-shadow: none;
    line-height: 16px;
    height: 16px;
    min-width: 16px;
  }
`;

const notificationsIconsStyles = css`
  font-size: 1.2em;
  color: ${colors.white};
`;

export const HasNotificationsIcon = styled(BellFilled)`
  ${notificationsIconsStyles}
`;

export const NoNotificationsIcon = styled(BellOutlined)`
  ${notificationsIconsStyles}
`;
