import React, { useMemo } from 'react';

import { Job } from '@/modules/data/dataTypes/jobList';

import { Container, OptionItemLabel, Select, SelectContainer } from './JobPriorities.styled';

import L from 'SHARED/components/L';

const { Option } = Select;

const ORDINAL = ['FIRST', 'SECOND', 'THIRD'];

type Props = {
  value: (string | null)[];
  jobsList: Job[];
  disabledPriorities?: Set<number>;
  onChange: (nextPositions: (string | null)[]) => void;
};

const JobPrioritySelector: React.FC<Props> = ({
  jobsList,
  value,
  disabledPriorities,
  onChange,
}) => {
  const createSelectHandler = (index: number) => (nextValue: string) => {
    const nextPositions = [...value];
    nextPositions[index] = nextValue;
    onChange(nextPositions);
  };

  const sortedJobsList = useMemo(
    () => [...jobsList].sort((a, b) => (a.jobName < b.jobName ? -1 : 1)),
    [jobsList],
  );

  return (
    <Container>
      {value.map((jobCode, index) => {
        const name = ORDINAL[index];
        const id = `${jobCode}${name}staffChoice`;
        return (
          <SelectContainer key={id}>
            <L forInput={jobCode || undefined} size="1" colored inlineBlock>
              {name} Staff Choice
            </L>
            <Select
              id={jobCode || undefined}
              placeholder="Select Position"
              onSelect={createSelectHandler(index)}
              value={jobCode || ''}
              showSearch
              disabled={!!disabledPriorities?.has(index)}
              optionFilterProp="label"
            >
              {sortedJobsList.map(job => (
                <Option key={job.jobCode} label={job.jobName}>
                  <OptionItemLabel>{job.jobName}</OptionItemLabel>
                </Option>
              ))}
            </Select>
          </SelectContainer>
        );
      })}
    </Container>
  );
};

export default JobPrioritySelector;
