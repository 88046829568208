import { Radio } from 'antd';
import React, { forwardRef, useEffect, useMemo } from 'react';

import { CDN_URL } from '@/modules/config/config';
import { PaymentMethod, paymentMethodsData } from '@/modules/payments/constants';

import { AdditionalFieldsData } from '../../types';

import styles from './PaymentMethodPicker.less';
import { Description, Note, OptionWrapper } from './PaymentMethodPicker.styled';
import AdditionalFields from './components/AdditionalFields';

type Props = {
  onPaymentMethodChange: (type: PaymentMethod) => void;
  paymentMethod: PaymentMethod | null;
  allowOfflinePayments?: boolean;
  enabledPaymentMethods: Set<PaymentMethod>;
};

const PaymentTypePicker = forwardRef<{ handleSubmit: () => Promise<AdditionalFieldsData> }, Props>(
  ({ paymentMethod, onPaymentMethodChange, allowOfflinePayments, enabledPaymentMethods }, ref) => {
    const createPaymentTypeHandler = (nextPaymentType: PaymentMethod) => () => {
      onPaymentMethodChange(nextPaymentType);
    };

    const usableMethods = useMemo(
      () =>
        Array.from(paymentMethodsData).filter(([value, { enabled, isOffline }]) => {
          if (!enabled) {
            return false;
          }
          if (enabledPaymentMethods.has(value)) {
            return true;
          }
          if (!allowOfflinePayments) {
            return !isOffline;
          }

          return false;
        }),
      [allowOfflinePayments, enabledPaymentMethods],
    );

    useEffect(() => {
      if (usableMethods.length && !paymentMethod) {
        const method = usableMethods[0];
        onPaymentMethodChange(method[0]);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usableMethods]);

    return (
      <div className={styles.typesWrapper}>
        <h4 className="ant-card-head-title">Select a payment type</h4>
        <div>
          <Radio.Group value={paymentMethod} className={styles.radioGroup}>
            {usableMethods.map(([value, { paymentMethodPickerTitle, description, note }]) => {
              const isActive = value === paymentMethod;

              return (
                <OptionWrapper key={value} onClick={createPaymentTypeHandler(value)}>
                  <Radio value={value} className={styles.radioOption} />
                  <div className={styles.typeOptionDetails}>
                    <h5 className={styles.typeTitle}>
                      {paymentMethodPickerTitle}{' '}
                      {value === PaymentMethod.creditCard && (
                        <img
                          src={`${CDN_URL}4-card-color-horizontal@2x.png`}
                          className={styles.cardsImg}
                        />
                      )}
                    </h5>
                    {description && <Description>{description}</Description>}
                    {note && <Note>{note}</Note>}
                    {isActive && <AdditionalFields paymentMethod={paymentMethod} ref={ref} />}
                  </div>
                </OptionWrapper>
              );
            })}
          </Radio.Group>
        </div>
      </div>
    );
  },
);

export default PaymentTypePicker;
