import { RcFile } from 'antd/es/upload';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container, Dragger, DropZoneText, FileIcon } from './UploadFile.styled';

type Props = {
  onChange: (file: RcFile | null) => void;
  file?: RcFile | null;
  fileStatus?: UploadFileStatus;
};

const UploadFile: React.FC<Props> = ({ onChange, file, fileStatus }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragStart = useCallback(() => {
    setIsDraggingOver(true);
  }, []);
  const handleDragEnd = useCallback(() => {
    setIsDraggingOver(false);
  }, []);

  useEffect(() => {
    const element = ref.current;
    element?.addEventListener('dragover', handleDragStart, true);
    element?.addEventListener('dragleave', handleDragEnd, true);
    element?.addEventListener('drop', handleDragEnd, true);
    return () => {
      element?.removeEventListener('dragover', handleDragStart, true);
      element?.removeEventListener('dragleave', handleDragEnd, true);
      element?.removeEventListener('drop', handleDragEnd, true);
    };
  }, [handleDragStart, handleDragEnd]);

  const handleBeforeUpload = (nextFile: RcFile) => {
    onChange(nextFile);
    return false;
  };

  const handleRemove = () => {
    onChange(null);
  };

  return (
    <Dragger
      beforeUpload={handleBeforeUpload}
      onRemove={handleRemove}
      accept=".csv"
      multiple={false}
      {...(file
        ? {
            fileList: [{ originFileObj: file, status: fileStatus, uid: 'id', name: file.name }],
          }
        : {})}
    >
      <Container ref={ref} $isDraggingOver={isDraggingOver}>
        <FileIcon size={56} />
        <DropZoneText>Click or Drag a CSV file to this area to upload</DropZoneText>
      </Container>
    </Dragger>
  );
};

export default UploadFile;
