import { createAction, createAsyncAction } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';

import { ROUTE_EVENTS, moduleName } from '../constants';

import { CloneEventPayload } from './services';

export const navigateToEvents = createAction(ROUTE_EVENTS)();

export const changeTab = createAction(`${moduleName}/CHANGE_TAB`)<string>();

export const cloneEvent = createAsyncAction(
  `${moduleName}/CLONE_EVENT_REQUEST`,
  `${moduleName}/CLONE_EVENT_SUCCESS`,
  `${moduleName}/CLONE_EVENT_FAILURE`,
)<CloneEventPayload, { formCode: string }, ApiError>();
