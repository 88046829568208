import React, { Fragment, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTotalBalanceDue } from '@/modules/entities/Payments/utils';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { CANCEL_GROUP_RESERVATION_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import { PaymentMethod } from '@/modules/payments/constants';
import useGetPaymentMethods from '@/modules/payments/hooks/useGetPaymentMethods';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import CancelButton from './components/CancelButton';
import CancelledReservationBanner from './components/CancelledReservationBanner';
import ReservationDashboard from './components/ReservationDashboard';
import { TabKey, availablePaymentMethods, tabs } from './constants';
import { currentTabKeySel, pageDataSel } from './duck/selectors';
import AttendeeDetailsTab from './tabs/AttendeeDetails';
import LogsTab from './tabs/Logs';
import OverviewTab from './tabs/OverviewGroup';
import PaymentsTab from './tabs/Payments';

const GroupReservationPage: React.FC = () => {
  const dispatch = useDispatch();

  const {
    data: { form, groupReservation, attendeeDetails, paymentSummary, paymentCategories },
  } = useSelector(pageDataSel);
  const currentTabKey = useSelector(currentTabKeySel);

  const totalAmountDue = getTotalBalanceDue(paymentSummary);

  const currentInstallment = useMemo(() => {
    const record = paymentSummary.records.find(
      r => r.groupReservationGUID === attendeeDetails.groupReservationGUID,
    );

    if (record?.paymentCategoryCode) {
      return paymentCategories.find(p => p.paymentCategoryCode === record.paymentCategoryCode);
    }
  }, [attendeeDetails, paymentCategories, paymentSummary]);

  const enabledPaymentMethods = useGetPaymentMethods({
    form,
    groupReservation,
    availableMethods: [
      PaymentMethod.adjustment,
      ...(totalAmountDue > 0 ? availablePaymentMethods : []),
    ],
    allowedMethods: currentInstallment?.allowedMethods ?? [],
  });

  const canCancelReservation = useSelector(
    createAbilitiesSelector('reservation', 'cancel', { groupReservation }),
  );

  const canAccessAttendeeDetails = useSelector(
    createAbilitiesSelector('attendeeDetails', PermissionAction.Read, { attendeeDetails }),
  );

  const canMakePayments = useSelector(
    createAbilitiesSelector('payment', PermissionAction.Create, { groupReservation }),
  );

  const canViewPaymentsTab = useSelector(
    createAbilitiesSelector('payment', PermissionAction.Read, { groupReservation }),
  );

  const isAllowedToPostComments = useSelector(
    createAbilitiesSelector('comment', PermissionAction.Create),
  );
  const isAllowedToViewLogs = useSelector(createAbilitiesSelector('log', PermissionAction.Read));

  const {
    isCancelled,
    cancellationReason,
    isReservationContact,
    isLeadAdvisor,
    isAdditionalReservationContact,
  } = groupReservation;

  const filteredTabs = useMemo(
    () =>
      tabs.filter(({ key }) => {
        switch (key) {
          case TabKey.AttendeeDetails:
            return (
              canAccessAttendeeDetails ||
              isReservationContact ||
              isLeadAdvisor ||
              isAdditionalReservationContact
            );
          case TabKey.Payment:
            return canViewPaymentsTab || (canMakePayments && enabledPaymentMethods.size);
          case TabKey.Logs:
            return isAllowedToPostComments || isAllowedToViewLogs;
          case TabKey.Disclaimers:
            return false;
          default:
            return true;
        }
      }),
    [
      canAccessAttendeeDetails,
      canMakePayments,
      canViewPaymentsTab,
      enabledPaymentMethods.size,
      isAdditionalReservationContact,
      isAllowedToPostComments,
      isAllowedToViewLogs,
      isLeadAdvisor,
      isReservationContact,
    ],
  );

  const handleCancelReservation = () => {
    dispatch(openModal(CANCEL_GROUP_RESERVATION_MODAL));
  };

  const renderTab = useCallback(() => {
    switch (currentTabKey) {
      case TabKey.Overview:
        return <OverviewTab />;
      case TabKey.AttendeeDetails:
        return <AttendeeDetailsTab />;
      case TabKey.Payment:
        return (
          <PaymentsTab
            form={form}
            isGroup
            groupReservation={groupReservation}
            enabledPaymentMethods={enabledPaymentMethods}
          />
        );
      case TabKey.Disclaimers:
        return null;
      case TabKey.Logs:
        return <LogsTab isGroup groupReservation={groupReservation} />;
    }
  }, [currentTabKey, enabledPaymentMethods, form, groupReservation]);

  return (
    <Fragment>
      {isCancelled && <CancelledReservationBanner cancellationReason={cancellationReason} />}
      <ReservationDashboard tabs={filteredTabs}>{renderTab()}</ReservationDashboard>
      {canCancelReservation && (
        <CancelButton
          isCancelled={groupReservation.isCancelled}
          onCancelReservation={handleCancelReservation}
        />
      )}
    </Fragment>
  );
};

export default GroupReservationPage;
