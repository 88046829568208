import { createAction } from 'typesafe-actions';

import { moduleName } from '../constants';
import { Notice, Notification } from '../types';

export const addNotification = createAction(`${moduleName}/ADD_NOTIFICATION`)<Notification>();
export const markAsReadNotification = createAction(
  `${moduleName}/MARK_AS_READ_NOTIFICATION`,
)<string>();

export const addNotice = createAction(`${moduleName}/ADD_NOTICE`)<Notice>();
export const clearNotice = createAction(`${moduleName}/CLEAR_NOTICE`)();
