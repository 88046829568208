import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  addDataBlock,
  connectDatablockItem,
  removeDataBlock,
  setEditingSectionCodes,
  updateDatablockItem,
  updateFormItem,
  updateVisibilitySettings,
} from './actions';

const updateDatablockItemInProgress = createReducer(false)
  .handleAction([updateDatablockItem.request, connectDatablockItem.request], () => true)
  .handleAction(
    [updateDatablockItem.success, connectDatablockItem.success, connectDatablockItem.failure],
    () => false,
  );

const editingSectionCodes = createReducer({
  formPartCode: '',
  formItemCode: '',
}).handleAction(setEditingSectionCodes, (_, { payload }) => payload);

const updateVisibilitySettingsInProgress = createReducer(false)
  .handleAction(updateVisibilitySettings.request, () => true)
  .handleAction([updateVisibilitySettings.success, updateVisibilitySettings.failure], () => false);

const addDatablockInProgress = createReducer(false)
  .handleAction(addDataBlock.request, () => true)
  .handleAction([addDataBlock.success, addDataBlock.failure], () => false);

const removeDatablockInProgress = createReducer(false)
  .handleAction(removeDataBlock.request, () => true)
  .handleAction([removeDataBlock.success, removeDataBlock.failure], () => false);

const updateFormItemInProgress = createReducer(false)
  .handleAction(updateFormItem.request, () => true)
  .handleAction([updateFormItem.success, updateFormItem.failure], () => false);

export default combineReducers({
  updateDatablockItemInProgress,
  editingSectionCodes,
  updateVisibilitySettingsInProgress,
  addDatablockInProgress,
  removeDatablockInProgress,
  updateFormItemInProgress,
});
