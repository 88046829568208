import { StateObservable } from 'redux-observable';
import { EMPTY, Observable } from 'rxjs';
import { RootAction, RootState } from 'typesafe-actions';

import AddAttendee from './AddAttendee';
import { ROUTE_ADD_ATTENDEE } from './constants';
import { stepGetObservable } from './duck/epics';
import { futureStepKeySel } from './duck/selectors';

export default {
  [ROUTE_ADD_ATTENDEE]: {
    path: '/add-attendee/:formCode',
    component: AddAttendee,
    titleId: 'page.form',
    getObservable: (
      action$: Observable<RootAction>,
      state$: StateObservable<RootState>,
    ): Observable<RootAction> => {
      const state = state$.value;
      const futureStepKey = futureStepKeySel(state);
      const getObservable = stepGetObservable[futureStepKey];
      return getObservable ? getObservable(action$, state$) : EMPTY;
    },
  },
};
