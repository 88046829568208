import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Title = styled.div<{ isGray: boolean }>`
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => (props.isGray ? colors.scoutingPaleGray : colors.scoutingBlue)};
  white-space: normal;
  overflow: hidden;
`;
