import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { inviteLeadAdvisor } from './actions';

const leadAdvisorDesignateInProgress = createReducer(false)
  .handleAction([inviteLeadAdvisor.request], () => true)
  .handleAction([inviteLeadAdvisor.failure, inviteLeadAdvisor.success], () => false);

export default combineReducers({
  leadAdvisorDesignateInProgress,
});
