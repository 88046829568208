import { Button as AntButton } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Button = styled(AntButton)<{ $isActive: boolean }>`
  color: ${colors.text};
  border: none;
  text-align: start;
  min-width: 0;
  display: flex;
  align-items: center;

  span[role='img'] {
    font-size: initial;
    visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  }

  :hover {
    color: ${colors.text};
    background-color: ${colors.scoutingPaleGray};
  }
`;
