import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { updatePerson } from './actions';

const updatePersonInProgress = createReducer(false)
  .handleAction(updatePerson.request, () => true)
  .handleAction([updatePerson.success, updatePerson.failure], () => false);

export default combineReducers({
  updatePersonInProgress,
});
