import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { deleteProgram, updateProgram } from './actions';

const updateProgramInProgress = createReducer(false)
  .handleAction(updateProgram.request, () => true)
  .handleAction([updateProgram.success, updateProgram.failure], () => false);

const deleteProgramInProgress = createReducer(false)
  .handleAction(deleteProgram.request, () => true)
  .handleAction([deleteProgram.success, deleteProgram.failure], () => false);

export default combineReducers({
  deleteProgramInProgress,
  updateProgramInProgress,
});
