import apiService from '@/modules/utils/apiService';

interface UpdateActivityPayload {
  formCode: string;
  activityCode?: string;
  activityName: string;
  description?: string;
  isGroupActivity: boolean;
  fee: number;
  instructor?: string;
  location?: string;
  maximumRosterCount?: number;
  maximumPeopleCount?: number;
  startDate: string;
  finishDate: string;
}

interface UpdateActivityAttendeeTypesPayload {
  formCode: string;
  activityCode: string;
  attendeeTypes: Array<{
    typeCode: string;
    isIncluded: boolean;
  }>;
}

interface DeleteActivityPayload {
  formCode: string;
  activityCode: string;
}

const updateActivity$ = (payload: UpdateActivityPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateActivity',
    payload,
  );

const updateActivityAttendeeTypes$ = (payload: UpdateActivityAttendeeTypesPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateActivityAttendeeType',
    payload,
  );

const deleteActivity$ = (payload: DeleteActivityPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteActivity',
    payload,
  );

export default {
  deleteActivity$,
  updateActivity$,
  updateActivityAttendeeTypes$,
};
