import { combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, filter, catchError } from 'rxjs/operators';
import { isActionOf, RootAction } from 'typesafe-actions';

import { createDataSel } from '@/modules/data/duck/selectors';
import toastService from '@/modules/toasts/service';

import { saveActivities } from './actions';
import services from './services';

const saveActivities$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(saveActivities.request)),
    switchMap(({ payload }) => {
      const { activities = [], finalActions = [], formCode } = payload;
      const state = state$.value;
      const { allowGroupRegistration } = createDataSel('form')(state);

      const service$ = allowGroupRegistration
        ? services.updateGroupReservationActivity$
        : services.updateFormRecordActivity$;

      return service$({
        formCode,
        activities,
        ...(payload.isGroup
          ? { groupReservationGUID: payload.groupReservationGUID }
          : { formRecordGUID: payload.formRecordGUID }),
      }).pipe(
        switchMap(() => {
          toastService.success('Activities saved correctly');
          return of(saveActivities.success(), ...finalActions);
        }),
        catchError(e => {
          toastService.error(e.message);
          return of(saveActivities.failure(e.message));
        }),
      );
    }),
  );

export default combineEpics(saveActivities$);
