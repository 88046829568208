import React from 'react';

import { Title } from './EventCardTitle.styled';

type Props = {
  title: string;
  published?: boolean;
  isActive?: boolean;
};

const EventCardTitle = ({ title, published, isActive }: Props): JSX.Element => {
  function getLabel() {
    if (!isActive) return ' (Archived)';
    if (!published) return ' (Unpublished)';
    return '';
  }

  return <Title isGray={!published || !isActive}>{`${title}${getLabel()}`}</Title>;
};

export default EventCardTitle;
