import React from 'react';

import Divider from '@/modules/shared/components/Divider';

import {
  ListItem as ListItemComponent,
  ListItemMeta,
  Title,
  TitleWrapper,
} from './ListItem.styled';
import HeaderActionButton from './components/HeaderActionButton';
import ListItemContentRow from './components/ListItemContentRow';
import Selection from './components/Selection';
import { HeaderAction } from './types';

const notEmpty = <T,>(row: T | null): row is T => row !== null;

interface BaseProps<T> {
  record: T;
  rowKey: string;
  title: React.ReactNode;
  isHeader?: boolean;
  headerAction?: HeaderAction<T>;
  rows: Array<
    | React.ReactElement
    | {
        title: string;
        content: React.ReactNode;
      }
    | null
  >;
}

interface PropsWithSelection<T> extends BaseProps<T> {
  selectedIds: Set<string>;
  onSelect: (selectedIds: Set<string>) => void;
}

type Props<T> =
  | BaseProps<T>
  | PropsWithSelection<T>
  | { content: React.ReactNode; isHeader?: boolean };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ListItem = <T extends Record<string, any>>(props: Props<T>): JSX.Element => {
  if ('content' in props) {
    return (
      <ListItemComponent isHeader={props.isHeader || false}>{props.content}</ListItemComponent>
    );
  }

  const { record, rowKey, title, rows, headerAction } = props;

  const id = record[rowKey];
  const isSelected = 'selectedIds' in props && props.selectedIds.has(id);

  return (
    <ListItemComponent isSelected={isSelected}>
      <ListItemMeta
        title={
          <TitleWrapper>
            {'selectedIds' in props && (
              <Selection
                selectedIds={props.selectedIds}
                onSelect={props.onSelect}
                isSelected={isSelected}
                id={id}
              />
            )}
            <Title>{title}</Title>
            {headerAction && <HeaderActionButton action={headerAction} record={record} />}
          </TitleWrapper>
        }
      />
      <Divider />
      {rows.filter(notEmpty).map(r => {
        if ('title' in r) {
          const { title: rowTitle, content } = r;

          return (
            <ListItemContentRow key={rowTitle} title={rowTitle}>
              {content}
            </ListItemContentRow>
          );
        }

        return r;
      })}
    </ListItemComponent>
  );
};

export default ListItem;
