import styled from 'styled-components';

import CardWithHeader from '@/modules/shared/components/CardWithHeader';

export const StyledCard = styled(CardWithHeader)`
  & .ant-card-body {
    padding: 0;
  }
`;

export const CardContent = styled.div`
  padding: 1.5em;
`;

export const CardDescription = styled.div`
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  height: 50px;
  padding: 1.5em;
`;
