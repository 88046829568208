import { Row, Col, Checkbox } from 'antd';
import cn from 'classnames';
import DOMPurify from 'dompurify';
import { get } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { MultiSessionCapacityRule } from '@/modules/data/dataTypes/multiSessionCapacityRules';
import { Session } from '@/modules/data/dataTypes/sessionList';
import { dateFromString, formattedDatesRange } from '@/modules/utils/dateFormats';
import { CURRENCY_FORMAT } from '@/modules/utils/numberFormats';
import { safeDecodeURIComponent } from '@/modules/utils/stringFunctions';

import styles from './Priority.less';
import AdditionalPersonFee from './components/AdditionalPersonFee';
import EstimatedFee from './components/EstimatedFee';
import FooterItem from './components/FooterItem';
import PrioritySelector from './components/PrioritySelector';
import WarningMessage from './components/WarningMessage';

interface Fees {
  totalFeePerPerson: number;
  totalFeePerRoster: number;
  totalDepositPerPerson: number;
  totalDepositPerRoster: number;
  totalAdoonFeePerPerson: number;
  totalAddonFeePerRoster: number;
  totalActivityFeePerPerson: number;
  totalActivityFeePerRoster: number;
}

type Props = {
  index: number;
  selectedCount: number;
  session: Session;
  showPrioritySelector: boolean;
  isGroupRegistration: boolean;
  registeredPeople: number;
  fees: Fees;
  isLoadingFees: boolean;
  isWaitlistEnabled: boolean;
  initialRosterCount: number;
  multiSessionRuleList: MultiSessionCapacityRule[];
  selectedAttendeeTypeCode?: string;
  changeEstimatedSessions: (sessionCode: string, value: boolean) => void;
  getGroupTotalFee: (payload: {
    sessionCode: string;
    numberOfPeople: number;
    numberOfCrews: number;
  }) => void;
  onMoveUp: (position: number) => void;
  onMoveDown: (position: number) => void;
  onSelect: (sessionCode: string) => void;
  onDeselect: (sessionCode: string) => void;
  setGroupInitialSettings: (initialRosterCount: number) => void;
};

const Priority: React.FC<Props> = ({
  session,
  index,
  onSelect,
  onDeselect,
  onMoveUp,
  onMoveDown,
  selectedCount,
  showPrioritySelector,
  isGroupRegistration,
  changeEstimatedSessions,
  registeredPeople,
  setGroupInitialSettings,
  getGroupTotalFee,
  fees,
  isLoadingFees,
  initialRosterCount,
  isWaitlistEnabled,
  multiSessionRuleList = [],
  selectedAttendeeTypeCode,
}) => {
  const isSelected = index > -1;
  const showSelector = isSelected && showPrioritySelector;

  const {
    activities,
    addons,
    attendeeCapacity,
    sessionName,
    sessionCode,
    description,
    baseRosterFee,
    deposit,
    startDate,
    closeDate,
    isFeePerPerson,
    baseFeePersonCount,
    maximumPeopleCount,
    minimumPeopleCount,
    programName,
    isPersonInWaitingList,
    remainingRosters,
    waitingListRecordCount,
    multiSessionRuleCode = '',
    totalInitialPeopleCount = 0,
    attendeeTypes = [],
  } = session;

  const remainingPersonSlots = useMemo(() => {
    const multiSessionRule = multiSessionRuleList.find(
      multiSession => multiSession.multiSessionRuleCode === multiSessionRuleCode,
    );

    if (multiSessionRule) {
      const { maxPeopleCount, sharedInitialPeopleCount } = multiSessionRule;
      return maxPeopleCount >= sharedInitialPeopleCount
        ? maxPeopleCount - sharedInitialPeopleCount
        : 0;
    }

    return attendeeCapacity >= totalInitialPeopleCount
      ? attendeeCapacity - totalInitialPeopleCount
      : 0;
  }, [attendeeCapacity, totalInitialPeopleCount, multiSessionRuleCode, multiSessionRuleList]);

  const hasAttendeeTypeSlotsAvailable = useMemo(() => {
    if (!selectedAttendeeTypeCode) return false;

    const sessionAttendeeTypeInfo = attendeeTypes.find(
      attendeeType => attendeeType.typeCode === selectedAttendeeTypeCode,
    );

    if (!sessionAttendeeTypeInfo) return false;

    const { registrationCompletedAttendeeCount = 0, maxCapacity = 0 } = sessionAttendeeTypeInfo;

    return registrationCompletedAttendeeCount < maxCapacity;
  }, [attendeeTypes, selectedAttendeeTypeCode]);

  const minCrews = useMemo(
    () => Math.ceil(registeredPeople / maximumPeopleCount) || 1,
    [registeredPeople, maximumPeopleCount],
  );

  const maxCrews = useMemo(
    () => Math.min(remainingRosters, Math.floor(registeredPeople / minimumPeopleCount)) || 1,
    [registeredPeople, minimumPeopleCount, remainingRosters],
  );

  const maxPeople = useMemo(
    () => Number(remainingRosters) * Number(maximumPeopleCount),
    [remainingRosters, maximumPeopleCount],
  );

  const minPeopleForCrews = useMemo(
    () => Number(minimumPeopleCount) * Number(minCrews),
    [minimumPeopleCount, minCrews],
  );

  const canJoinWaitingList = useMemo(
    () =>
      isWaitlistEnabled &&
      !isPersonInWaitingList &&
      registeredPeople > 0 &&
      (maxPeople <= 0 || waitingListRecordCount > 0),
    [isWaitlistEnabled, registeredPeople, isPersonInWaitingList, maxPeople, waitingListRecordCount],
  );

  const sessionDate = useMemo(() => {
    const from = dateFromString(startDate);
    const to = dateFromString(closeDate);
    const parsedDate = formattedDatesRange(from, to);

    return `(${parsedDate})`;
  }, [closeDate, startDate]);

  const selectHandler = () => {
    if (isSelected) {
      onDeselect(sessionCode);
    } else {
      onSelect(sessionCode);
    }
  };

  const disabledMessage = useMemo(() => {
    if (isPersonInWaitingList) return 'You are already on the waiting list for this session';
    if (!registeredPeople && isGroupRegistration)
      return 'Please specify amount of adults and youth in your group';
    if (maxPeople < registeredPeople || registeredPeople > remainingPersonSlots)
      return 'Not enough spots for your group';
    if (minimumPeopleCount > registeredPeople) return 'You need more people for this session';
    if (isGroupRegistration) {
      if (
        remainingRosters <= 0 ||
        (remainingPersonSlots < registeredPeople && attendeeCapacity > 0)
      ) {
        return 'Session is full';
      }
      if (registeredPeople < minPeopleForCrews)
        return `You need at least ${minPeopleForCrews} people, in ${minCrews} groups`;
    } else {
      if (!selectedAttendeeTypeCode) return 'Please go back and select an attendee type';
      if (!hasAttendeeTypeSlotsAvailable) return 'No slots left for your attendee type';
      if (remainingPersonSlots === 0 && attendeeCapacity > 0) {
        return 'Session is full';
      }
    }

    return null;
  }, [
    isPersonInWaitingList,
    registeredPeople,
    maxPeople,
    minimumPeopleCount,
    remainingRosters,
    attendeeCapacity,
    isGroupRegistration,
    minPeopleForCrews,
    minCrews,
    remainingPersonSlots,
    selectedAttendeeTypeCode,
    hasAttendeeTypeSlotsAvailable,
  ]);

  const parsedDescription = useMemo(() => safeDecodeURIComponent(description), [description]);

  const isDisabled = !!disabledMessage;

  useEffect(() => {
    isDisabled && onDeselect(sessionCode);
  }, [isDisabled, sessionCode, onDeselect]);

  const requiredAddonsFee = useMemo(
    () =>
      addons.reduce((acc, { isMandatory, addonOptions }) => {
        if (!isMandatory) return acc;
        const defaultOption = addonOptions.find(({ isDefaultOption }) => isDefaultOption);
        const defaultOptionFee = get(defaultOption, 'fee', 0);
        return acc + defaultOptionFee;
      }, 0),
    [addons],
  );

  const requiredActivitiesFee = useMemo(
    () =>
      activities.reduce((acc, { isMandatory, fee }) => {
        if (!isMandatory) return acc;
        return acc + fee;
      }, 0),
    [activities],
  );

  return (
    <Row
      align="middle"
      className={cn(styles.container, {
        [styles.disabled]: isDisabled && !canJoinWaitingList,
      })}
      gutter={16}
    >
      <Col>
        <Checkbox
          checked={isSelected}
          onChange={selectHandler}
          disabled={isDisabled && !canJoinWaitingList}
        />
      </Col>
      <Col>
        <Row align="middle" className={styles.headerRow}>
          <span className={styles.name}>
            {programName} - {sessionName}&nbsp;
          </span>
          {sessionDate}
          <WarningMessage>{disabledMessage}</WarningMessage>
        </Row>
        {!!parsedDescription.length && (
          <Row
            className={styles.descriptionRow}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(parsedDescription) }}
          />
        )}
        {isGroupRegistration && (
          <Row className={styles.footerRow}>
            {canJoinWaitingList && isDisabled && (
              <div className={styles.waitlistFull}>
                <span>SESSION FULL</span>
                Waitlist Only
              </div>
            )}

            <FooterItem text="Remaining Crew Slots" value={remainingRosters} />
            <FooterItem text="Remaining Person Slots" value={remainingPersonSlots} />
            <FooterItem
              text="People per Crew"
              value={`${minimumPeopleCount} - ${maximumPeopleCount}`}
            />
            {!!(baseRosterFee || deposit || requiredActivitiesFee || requiredAddonsFee) &&
              !isFeePerPerson && (
                <FooterItem
                  text="Minimum Fee"
                  value={`${CURRENCY_FORMAT(
                    baseRosterFee + deposit + requiredActivitiesFee + requiredAddonsFee,
                  )} for ${baseFeePersonCount} people`}
                />
              )}
            <AdditionalPersonFee session={session} />
          </Row>
        )}
        {isSelected && isGroupRegistration && (
          <EstimatedFee
            session={session}
            changeEstimatedSessions={changeEstimatedSessions}
            registeredPeople={registeredPeople}
            setGroupInitialSettings={setGroupInitialSettings}
            getGroupTotalFee={getGroupTotalFee}
            fees={fees}
            isLoadingFees={isLoadingFees}
            minCrews={minCrews}
            maxCrews={maxCrews}
            initialRosterCount={initialRosterCount}
          />
        )}
        {showSelector && (
          <Row>
            <PrioritySelector
              position={index}
              allowMax={selectedCount > 1 && index > 0}
              allowMin={selectedCount > 1 && index < selectedCount - 1}
              onPlus={onMoveDown}
              onMinus={onMoveUp}
            />
          </Row>
        )}
      </Col>
    </Row>
  );
};

Priority.defaultProps = {
  showPrioritySelector: true,
};

export default Priority;
