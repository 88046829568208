import { combineEpics, Epic } from 'redux-observable';
import { concat, defer, EMPTY, merge, of, race } from 'rxjs';
import { filter, switchMap, map, catchError, take, takeUntil } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { scheduleRefresh } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import { updateFormRecord } from '@/modules/entities/FormRecord/duck/actions';
import { updatePerson } from '@/modules/entities/Person/duck/actions';
import { allowLeave } from '@/modules/routing/duck/actions';
import toastService from '@/modules/toasts/service';
import { login } from '@/modules/user/duck/actions';
import { isLoggedInSel } from '@/modules/user/duck/selectors';

import { openNextStep, setFormRecordGuid } from '@/pages/createReservation/duck/actions';
import { formRecordGuidSel } from '@/pages/createReservation/duck/selectors';
import { navigateToEvents } from '@/pages/eventList/duck/actions';

import { setGroupInitialSettings } from '../../SessionPriorities/duck/actions';

import { updatePersonalInformation } from './actions';
import services from './services';

const updatePersonalInformation$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updatePersonalInformation)),
    switchMap(({ payload }) => {
      const { personData } = payload;

      return merge(
        race(
          action$.pipe(
            filter(isActionOf(updatePerson.failure)),
            take(1),
            map(({ payload: error }) => {
              throw error;
            }),
            switchMap(() => EMPTY),
          ),
          action$.pipe(
            filter(isActionOf(updatePerson.success)),
            take(1),
            switchMap(({ payload: personGUID }) => {
              const isLoggedIn = isLoggedInSel(state$.value);
              const { formCode } = createDataSel('form')(state$.value);

              return concat(
                isLoggedIn
                  ? EMPTY
                  : services.initiateFormRecord$({ formCode, personGUID }).pipe(
                      switchMap(({ responseValue, reservationCode }) =>
                        merge(
                          action$.pipe(
                            filter(isActionOf(login.success)),
                            take(1),
                            takeUntil(action$.pipe(filter(isActionOf(login.failure)))),
                          ),
                          of(
                            setFormRecordGuid(responseValue as string),
                            login.request({
                              reservationCode,
                              lastName: personData.lastName as string,
                              preventPostLoginRedirection: true,
                            }),
                          ),
                        ),
                      ),
                    ),
                defer(() => {
                  if ('groupReservationInitialSettings' in payload) {
                    return of(setGroupInitialSettings(payload.groupReservationInitialSettings));
                  }

                  const formRecordGUID = formRecordGuidSel(state$.value) || undefined;
                  const { individualRegistrationInitialSettings, formParts } = payload;

                  return merge(
                    race(
                      action$.pipe(
                        filter(isActionOf(updateFormRecord.success)),
                        take(1),
                        switchMap(({ payload: nextFormRecordGUID }) =>
                          of(
                            setFormRecordGuid(nextFormRecordGUID),
                            scheduleRefresh({ dataType: 'attendeeDetails' }),
                          ),
                        ),
                      ),
                      action$.pipe(
                        filter(isActionOf(updateFormRecord.failure)),
                        take(1),
                        switchMap(({ payload: { message } }) => {
                          throw new Error(message);
                        }),
                      ),
                    ),
                    of(
                      updateFormRecord.request({
                        formCode,
                        formParts,
                        personGUID,
                        formRecordGUID,
                        unit: individualRegistrationInitialSettings.unit,
                      }),
                    ),
                  );
                }),
                of(openNextStep()),
              );
            }),
          ),
        ),
        of(
          updatePerson.request({
            ...personData,
            isLocal: personData.isLocal || !personData.personGUID,
          }),
          scheduleRefresh({ dataType: 'arnicaPerson' }),
        ),
      );
    }),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return merge(caught, of(allowLeave(), navigateToEvents()));
    }),
  );

export default combineEpics(updatePersonalInformation$);
