import { Row } from 'antd';
import { get } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { jobStatus } from '@/modules/entities/Jobs/constants';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { CHANGE_RESERVATION_SESSION_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import { useGetScreenSize } from '@/modules/responsiveSizes/hooks';
import InfoItem from '@/modules/shared/components/InfoItem';
import ReservationActivities from '@/modules/shared/components/ReservationActivities';
import ReservationAddons from '@/modules/shared/components/ReservationAddons';
import ReservationSession from '@/modules/shared/components/ReservationSession';
import { formStatus } from '@/modules/shared/constants';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { daysBetween, formattedDatesRange, usDate } from '@/modules/utils/dateFormats';

import {
  navigateToEditIndividualActivities,
  navigateToEditIndividualAddons,
} from '@/pages/reservation/duck/actions';
import { attendeeTypeNameSel, pageDataSel } from '@/pages/reservation/duck/selectors';
import { individualReservationTabDataSel } from '@/pages/reservation/tabs/OverviewIndividual/duck/selector';

import { ChangeSessionModalParams } from '../../../ChangeReservationSessionModal/types';
import { Collapse } from '../../Details.styled';

import { sections } from './constans';

type Props = {
  reservationDetails: AttendeeDetails;
};

const IndividualReservationSections: React.FC<Props> = ({ reservationDetails }) => {
  const dispatch = useDispatch();
  const screenSize = useGetScreenSize();
  const attendeeType = useSelector(attendeeTypeNameSel);
  const {
    data: { allDisclaimersAccepted, formRecordAddons, formRecordActivities },
  } = useSelector(individualReservationTabDataSel);
  const {
    data: { sessionList, form },
  } = useSelector(pageDataSel);

  const { formCode } = form;

  const {
    formRecordGUID,
    firstName,
    lastName,
    dateOfBirth,
    status,
    emailAddress,
    sessionName,
    sessionCode,
    activeJobPositionName,
    activeJobPositionStatusCode,
    activeJobPositionStatusName,
  } = reservationDetails;

  const currentSession = useMemo(
    () => (sessionList || []).find(session => session.sessionCode === sessionCode),
    [sessionCode, sessionList],
  );
  const sessionDates = currentSession
    ? formattedDatesRange(currentSession.startDate, currentSession.closeDate)
    : '';
  const sessionLength = currentSession
    ? daysBetween(currentSession.startDate, currentSession.closeDate)
    : 0;

  const sectionKeys = useMemo(() => Object.values(sections).map(({ key }) => key), []);

  const isAllowedToEditAttendee = useSelector(
    createAbilitiesSelector('attendeeDetails', PermissionAction.Update, {
      attendeeDetails: reservationDetails,
    }),
  );

  const isAllowedToEditSelections = useSelector(
    createAbilitiesSelector('attendeeSelections', PermissionAction.Update, reservationDetails),
  );

  const canEditSelections =
    isAllowedToEditSelections || (!!currentSession && isAllowedToEditAttendee);

  const handleClickEditSession = useCallback(() => {
    const reservationOwnerName = `${firstName} ${lastName}`;
    const modalParams: ChangeSessionModalParams = {
      currentSessionCode: sessionCode,
      reservationOwnerName,
    };

    return isAllowedToEditSelections
      ? dispatch(openModal(CHANGE_RESERVATION_SESSION_MODAL, modalParams))
      : undefined;
  }, [isAllowedToEditSelections, firstName, lastName, sessionCode, dispatch]);

  const handleEditActivities = useCallback(() => {
    dispatch(navigateToEditIndividualActivities(formCode, formRecordGUID));
  }, [dispatch, formCode, formRecordGUID]);

  const handleEditAddons = useCallback(() => {
    dispatch(navigateToEditIndividualAddons(formCode, formRecordGUID));
  }, [dispatch, formCode, formRecordGUID]);

  return (
    <Fragment>
      <Row>
        <InfoItem header="Name" info={`${firstName} ${lastName}`} />
        <InfoItem header="Date of Birth" info={usDate(dateOfBirth)} />
        <InfoItem header="Email" info={emailAddress} />
        <InfoItem header="Reservation Status" info={get(formStatus, [status, 'name'])} />
        <InfoItem header="Attendee Type" info={attendeeType} />
        <InfoItem
          header="Disclaimer Status"
          info={`${allDisclaimersAccepted ? '' : 'Not '}Accepted`}
        />
        {activeJobPositionStatusCode && (
          <InfoItem
            header={
              activeJobPositionStatusCode === jobStatus.job_attendee_accepted.code
                ? 'Accepted Job'
                : 'Job status'
            }
            info={
              activeJobPositionStatusCode === jobStatus.job_attendee_accepted.code
                ? activeJobPositionName
                : activeJobPositionStatusName
            }
          />
        )}
      </Row>
      <Collapse
        expandIconPosition="end"
        {...(screenSize === 'desktop' ? { activeKey: sectionKeys } : {})}
      >
        <ReservationSession
          dates={sessionDates}
          key={sections.sessionSelected.key}
          header={sections.sessionSelected.title}
          length={sessionLength}
          name={sessionName}
          onEdit={isAllowedToEditSelections ? handleClickEditSession : undefined}
        />
        <ReservationActivities
          isGroup={false}
          activities={formRecordActivities}
          key={sections.activities.key}
          header={sections.activities.title}
          onEditActivities={canEditSelections ? handleEditActivities : undefined}
        />
        <ReservationAddons
          addons={formRecordAddons}
          key={sections.addOns.key}
          header={sections.addOns.title}
          onEditAddons={canEditSelections ? handleEditAddons : undefined}
        />
      </Collapse>
    </Fragment>
  );
};

export default IndividualReservationSections;
