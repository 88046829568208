import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';

import { updateInstallment, updateInstallmentClosed, updateInstallmentOpened } from './actions';

const currentInstallment = createReducer<PaymentCategory | Record<string, never> | null>(null)
  .handleAction(updateInstallmentOpened, (_state, { payload }) => payload || {})
  .handleAction(updateInstallmentClosed, () => null);

const updateInstallmentInProgress = createReducer(false)
  .handleAction(updateInstallment.request, () => true)
  .handleAction([updateInstallment.success, updateInstallment.failure], () => false);

export default combineReducers({
  currentInstallment,
  updateInstallmentInProgress,
});
