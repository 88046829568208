import React from 'react';
import { LinkElement } from 'slate';

import { ElementProps } from '../../types';

const Link: React.FC<ElementProps> = ({ attributes, children, element }) => {
  const { url } = element as LinkElement;

  return (
    <a {...attributes} href={url}>
      {children}
    </a>
  );
};

export default Link;
