import React from 'react';

import { FoundPerson } from '@/modules/data/dataTypes/foundPersons';
import { ADULT_AGE_HIGH } from '@/modules/shared/constants';

type Props = {
  foundPerson: FoundPerson;
  memberID: string;
  isEligible: boolean;
};

const NoUsernameContent: React.FC<Props> = ({ foundPerson, memberID, isEligible }) => {
  const { firstName, lastName, age } = foundPerson;
  const inviteeFullName = `${firstName} ${lastName}`;

  return (
    <div>
      <b>My.scouting account not found</b>
      <p>
        {inviteeFullName} {isEligible ? 'is eligible to join this crew but ' : ''}does not have a
        my.scouting account associated with Member ID {memberID}.
      </p>
      {age < ADULT_AGE_HIGH ? (
        <p>
          Please ask the guardian of {inviteeFullName} to visit{' '}
          <b>
            <a href="https://my.scouting.org">my.scouting.org</a>
          </b>{' '}
          and create an account. After entering their child's name, date of birth and zip code, and
          indicating that they are the parent of the youth, they should see a prompt to use their
          child's existing member ID {memberID} to set up the my.scouting account.
        </p>
      ) : (
        <p>
          Please ask {inviteeFullName} to visit{' '}
          <b>
            <a href="https://my.scouting.org">my.scouting.org</a>
          </b>{' '}
          and create an account. As part of setting up an account, they should see a prompt to use
          their existing Member ID {memberID} for the new account.
        </p>
      )}
      <p>
        If {inviteeFullName} already has a my.scouting account, they can log in to my.scouting.org
        and select "Manage Member ID" in the top left-hand menu. This will give them the option to
        add their Member ID {memberID} to their existing account.
      </p>
      <p>
        Once one of the above steps have been completed, please try inviting {inviteeFullName}{' '}
        again.
      </p>
    </div>
  );
};

export default NoUsernameContent;
