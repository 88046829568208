import { Button, Typography } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

import { LoginButton } from '../../LoginComponent.styled';

export const Subtitle = styled(Typography.Title)`
  && {
    color: ${colors.darkGrey};
    margin: 0 0 40px 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
  }
`;

export const BSALogo = styled.img`
  fill: white;
  height: 24px;
  margin-right: 16px;
`;

export const MyScoutingButton = styled(LoginButton)`
  margin-bottom: 0;
`;

export const DividerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 40px auto;
  max-width: 300px;

  & hr {
    background-color: ${colors.darkGrey};
  }

  & span {
    color: ${colors.darkGrey};
    font-size: 14px;
    font-weight: bold;
    margin: 0 16px;
  }
`;

export const ReservationCodeButton = styled(Button)<{ $isMobile: boolean }>`
  color: ${colors.scoutingBlue};
  margin: 0 auto 16px auto;
  max-width: ${({ $isMobile }) => ($isMobile ? '280px' : '284px')};
  width: 100%;
`;
