import { RadioChangeEvent } from 'antd/lib/radio';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';

import { rosterSel } from '../../../../duck/selectors';
import { ROSTERS_DATA_ID_TRANSFER_ATTENDEES } from '../../constants';
import { transferAttendees } from '../../duck/actions';
import {
  transferAttendeesInProgressSel,
  selectedAttendeesByPersonGuidSel,
} from '../../duck/selectors';

import { Radio, RadioGroup } from './ConfirmAttendeesTransferModalRC.styled';

const ConfirmAttendeesTransferModalRC: React.FC = () => {
  const dispatch = useDispatch();
  const currentRoster = useSelector(rosterSel);
  const selectedAttendees = useSelector(selectedAttendeesByPersonGuidSel);
  const isLoading = useSelector(transferAttendeesInProgressSel);
  const rosters = useSelector(createDataSel('rosterList', ROSTERS_DATA_ID_TRANSFER_ATTENDEES));

  const rostersWithoutCurrent = useMemo(
    () =>
      (rosters || []).filter(
        ({ rosterCode }) => rosterCode !== get(currentRoster, ['rosterCode'], ''),
      ),
    [rosters, currentRoster],
  );

  const [selectedRosterCode, setSelectedRosterCode] = useState(
    get(rostersWithoutCurrent, [0, 'rosterCode'], ''),
  );

  const attendeesString = useMemo(() => {
    const names = selectedAttendees.map(({ fullName }) => fullName);
    if (names.length === 1) return names[0];
    const firstNames = names.slice(0, -1);
    const lastName = names[names.length - 1];
    return `${firstNames.join(', ')} and ${lastName}`;
  }, [selectedAttendees]);

  const handleRosterSelect = (e: RadioChangeEvent) => {
    setSelectedRosterCode(e.target.value);
  };

  function handleConfirm() {
    const selectedRoster = rostersWithoutCurrent.find(
      ({ rosterCode }) => rosterCode === selectedRosterCode,
    );
    if (selectedRoster) {
      dispatch(transferAttendees.request({ roster: selectedRoster }));
    }
  }

  return (
    <ModalComponent
      inProgress={isLoading}
      title="Confirm Transfer"
      description={`Please select the roster you want to transfer ${attendeesString} to.`}
      buttons={[{ title: 'CONFIRM', onClick: handleConfirm, type: 'primary' }]}
    >
      <RadioGroup value={selectedRosterCode} onChange={handleRosterSelect}>
        {rostersWithoutCurrent.map(({ rosterExpeditionNumber, rosterCode }) => (
          <Radio key={rosterCode} value={rosterCode}>
            {rosterExpeditionNumber}
          </Radio>
        ))}
      </RadioGroup>
    </ModalComponent>
  );
};

export default ConfirmAttendeesTransferModalRC;
