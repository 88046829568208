export type ValueOf<T> = T[keyof T];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TupleSplit<T, N extends number, O extends readonly any[] = readonly []> = O['length'] extends N
  ? [O, T]
  : T extends readonly [infer F, ...infer R]
  ? TupleSplit<readonly [...R], N, readonly [...O, F]>
  : [O, T];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TakeFirst<T extends readonly any[], N extends number> = TupleSplit<T, N>[0];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SkipFirst<T extends readonly any[], N extends number> = TupleSplit<T, N>[1];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TupleSlice<T extends readonly any[], S extends number, E extends number> = SkipFirst<
  TakeFirst<T, E>,
  S
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sliceTuple = <T extends readonly any[], S extends number, E extends number>(
  arr: readonly [...T],
  start: S,
  end: E,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => arr.slice(start, end) as readonly any[] as TupleSlice<T, S, E>;
