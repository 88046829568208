import { Row } from 'antd';
import React from 'react';

import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import Currency from '@/modules/shared/components/Currency';
import { EnhancedPaymentCategory } from '@/modules/shared/components/ReservationPaymentsCard/ReservationPaymentsCard';

import {
  ListItem as ListItemComponent,
  ListItemMeta,
  ContentTitle,
  ContentData,
} from '../../../../../../ReservationPaymentsCard.styled';
import DueDateColumn from '../../../DueDateColumn';
import StatusColumn from '../../../StatusColumn';

import Header from './components/Header';

type Props = {
  paymentCategory: EnhancedPaymentCategory;
  onSelect: (categoryCode: string, isSelected: boolean) => void;
  onUpdatePaymentScheduleDueDate?: (paymentCategory: PaymentCategory) => void;
  isSelected: boolean;
  allowPayments: boolean;
};

const ListItem: React.FC<Props> = ({
  paymentCategory,
  allowPayments,
  isSelected,
  onSelect,
  onUpdatePaymentScheduleDueDate,
}) => {
  const { dueAmount, status } = paymentCategory;

  return (
    <ListItemComponent>
      <ListItemMeta
        title={
          <Header
            paymentCategory={paymentCategory}
            allowPayments={allowPayments}
            isSelected={isSelected}
            onSelect={onSelect}
          />
        }
      />
      <Row>
        <ContentTitle>Due Amount: </ContentTitle>
        <ContentData>
          <Currency value={dueAmount} />
        </ContentData>
      </Row>
      <Row>
        <ContentTitle>Due Date: </ContentTitle>
        <ContentData>
          <DueDateColumn
            record={paymentCategory}
            paymentsAllowed={allowPayments}
            onUpdatePaymentScheduleDueDate={onUpdatePaymentScheduleDueDate}
          />
        </ContentData>
      </Row>
      <Row>
        <ContentTitle>Status: </ContentTitle>
        <ContentData>
          <StatusColumn status={status} />
        </ContentData>
      </Row>
    </ListItemComponent>
  );
};

export default ListItem;
