import { Col, Form, Row } from 'antd';
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FormPartsData } from '@/modules/data/dataTypes/formPartsData';
import InputSelector from '@/modules/entities/FormItems/components/InputSelector/InputSelector';
import { generateFormItemRules } from '@/modules/entities/FormItems/utils';
import Divider from '@/modules/shared/components/Divider';
import { fileDataSel, mappedColumnsSel } from '@/modules/uploadQuestionsModal/duck/selectors';

import { stepDataSel } from '../../duck/selectors';

type Props = {
  formItem: FormPartsData['formParts'][number]['formItems'][number] & { formPartCode: string };
};

const FormItem: React.FC<Props> = ({ formItem }) => {
  const mappedColumns = useSelector(mappedColumnsSel);
  const importedData = useSelector(fileDataSel);
  const {
    data: { form, optionSets },
  } = useSelector(stepDataSel);
  const { formPartCode, formItemCode, formItemName, optionSetCode, presentationType } = formItem;
  const importedColumnName = mappedColumns[formPartCode][formItemCode];

  const importedValues = useMemo(
    () => new Set(importedData.map(record => record[importedColumnName])),
    [importedColumnName, importedData],
  );

  const options = useMemo(
    () =>
      optionSetCode &&
      optionSets[optionSetCode]?.optionItems
        .slice()
        .sort((a, b) => a.positionNumber - b.positionNumber)
        .map(({ itemLabel, itemValue }) => ({ value: itemValue, label: itemLabel })),
    [optionSets, optionSetCode],
  );

  if (!options) return null;

  return (
    <Fragment>
      <Divider />
      <Row>
        <Col span={4}>{importedColumnName}</Col>
        <Col span={4}>{formItemName}</Col>
        <Col span={16}>
          {Array.from(importedValues).map(value => (
            <Fragment key={value}>
              <Row>
                <Col span={12}>{value}</Col>
                <Col span={12}>
                  <Form.Item
                    name={[formPartCode, formItemCode, value]}
                    rules={[
                      {
                        required: true,
                        message: 'Answer is required',
                      },
                      ...generateFormItemRules(formItem, false),
                    ]}
                  >
                    <InputSelector
                      formSettings={form}
                      presentationType={presentationType}
                      formPartCode={formPartCode}
                      formItemCode={formItemCode}
                      options={options}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Fragment>
          ))}
        </Col>
      </Row>
    </Fragment>
  );
};

export default FormItem;
