import { DownloadOutlined } from '@ant-design/icons';
import React, { MouseEventHandler, useCallback, useRef, useState } from 'react';

import { Attendee, Disclaimer } from '../../../../types';

import { Button } from './DownloadButton.styled';
import Link from './components/Link';

type Props = {
  disclaimer: Disclaimer;
  attendeeDetails: Attendee;
};

const DownloadButton: React.FC<Props> = ({ disclaimer, attendeeDetails }) => {
  const [isClicked, setIsClicked] = useState(false);
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const handleClick: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    if (linkRef.current) {
      linkRef.current.click();
    } else {
      setIsClicked(true);
    }
  };

  const onRefChange = useCallback((node: HTMLAnchorElement | null) => {
    if (node) {
      if (!linkRef.current) {
        node.click();
      }
      linkRef.current = node;
    }
  }, []);

  return (
    <Button type="link" icon={<DownloadOutlined />} onClick={handleClick}>
      Download
      {isClicked && (
        <Link ref={onRefChange} disclaimer={disclaimer} attendeeDetails={attendeeDetails} />
      )}
    </Button>
  );
};

export default DownloadButton;
