import { Select as BsaSelect, Form } from 'antd';
import styled from 'styled-components';

export const Select = styled(BsaSelect)`
  &&& .ant-select-selector {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
`;

export const FormItem = styled(Form.Item)`
  width: 100%;
`;
