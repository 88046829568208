import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  margin: 1rem;
`;

export const ContentWrapper = styled.div`
  margin: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 2rem;
`;
