import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import EditableFormSection from '@/modules/shared/components/EditableFormSection';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import toastService from '@/modules/toasts/service';
import { useAbilities } from '@/modules/user/duck/abilities';
import { Abilities } from '@/modules/user/types';

import { assignHiringManager } from '@/pages/reservation/duck/actions';
import { pageDataSel, assignHiringManagerInProgressSel } from '@/pages/reservation/duck/selectors';
import { tabDataSel } from '@/pages/reservation/tabs/AttendeeDetails/duck/selector';

import { useHandleEdit } from '../../hooks';

import { TagSelector } from './AssignHiringManager.styled';
import { moduleName } from './constants';

type Props = {
  onEdit: (sectionName: string | null) => void;
  editingSectionName: string | null;
};

const AssignHiringManager: React.FC<Props> = ({ editingSectionName, onEdit }) => {
  const dispatch = useDispatch();
  const abilities: Abilities = useAbilities();

  const {
    data: { attendeeDetails },
  } = useSelector(pageDataSel);
  const {
    data: { jobDepartmentList },
  } = useSelector(tabDataSel);

  const { handleStartEdit, handleStopEdit, isEditing } = useHandleEdit(
    moduleName,
    editingSectionName,
    onEdit,
  );

  const assignHiringManagerInProgress = useSelector(assignHiringManagerInProgressSel);

  const { personGUID, jobDepartments } = attendeeDetails;

  const isAllowedToAssignHiringDepartment = abilities.can({
    action: 'assign',
    target: 'dashboard.hiringDepartment',
  });

  const createUpdateDepartmentHandler = (isGranted: boolean) => (jobDepartmentCode: string) => {
    if (jobDepartmentCode) {
      dispatch(
        assignHiringManager.request({
          jobDepartmentCode,
          isGranted,
          personGUID,
        }),
      );
    } else {
      toastService.error('You cannot add a new hiring department');
    }
  };

  return (
    <EditableFormSection
      title="Hiring Department"
      hideSaveButton
      canEdit={isAllowedToAssignHiringDepartment}
      onEditClick={handleStartEdit}
      onCancel={handleStopEdit}
      isEditing={isEditing}
    >
      <LoadingContainer isLoading={assignHiringManagerInProgress}>
        <TagSelector
          closeOnSelect
          dataSource={jobDepartmentList}
          valueField="jobDepartmentCode"
          labelField="jobDepartmentName"
          value={jobDepartments.map(({ jobDepartmentCode }) => jobDepartmentCode)}
          allowEdit={isEditing}
          label="Hiring Departments"
          onSelect={createUpdateDepartmentHandler(true)}
          onDeselect={createUpdateDepartmentHandler(false)}
        />
      </LoadingContainer>
    </EditableFormSection>
  );
};

export default AssignHiringManager;
