import { Progress } from 'antd';
import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

export const BorderedContainer = styled.div`
  height: 360px;
  padding: 16px;
  border-radius: 4px;
  background-color: ${colors.white};
  transition: 'border .24s ease-in-out';
  border: solid 1px ${colors.scoutingPaleGray};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
`;

export const GrayIconWrapper = styled.div`
  color: ${colors.gray_6};
`;

const boldTextStyle = css`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
`;

export const IconRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  column-gap: 16px;
  align-items: center;
`;

export const SuccessIcon = styled.div`
  color: ${colors.success};
  align-self: start;
  margin-left: 16px;
`;

export const ErrorIconWrapper = styled.div`
  color: ${colors.error};
  align-self: start;
  margin-left: 16px;
`;

export const FileStatusRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  column-gap: 16px;
`;

export const FilenameText = styled.div`
  ${boldTextStyle}
  color: ${colors.darkGrey};
  margin-bottom: 8px;
  width: 300px;
`;

export const ProgressBar = styled(Progress)`
  margin-top: 4px;
`;

export const ProgressLabel = styled.span`
  text-transform: capitalize;
`;

export const ErrorsTitle = styled.span`
  ${boldTextStyle}
  color: ${colors.error};
`;

export const ErrorsList = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 12px;
  overflow: auto;
  width: 450px;
`;
