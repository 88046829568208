import LoginPage from './components/LoginPage';
import { ROUTE_LOGIN, ROUTE_INVALID_MEMBERSHIP } from './duck/actions';

import InvalidMembership from 'SHARED/components/InvalidMembership';

const routes = {
  [ROUTE_LOGIN]: {
    path: '/login',
    component: LoginPage,
    titleId: 'page.login',
    unAuthenticatedAccess: true,
    isStandAlone: true,
  },
  [ROUTE_INVALID_MEMBERSHIP]: {
    path: '/invalid-membership',
    component: InvalidMembership,
    titleId: 'page.invalidMembership',
  },
};

export default routes;
