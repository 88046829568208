import { cloneDeep, set } from 'lodash';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootAction } from 'typesafe-actions';

import { Addon as AddonType } from '@/modules/data/dataTypes/addons';
import { FormRecordAddon } from '@/modules/data/dataTypes/formRecordAddons';
import { GroupReservationAddon } from '@/modules/data/dataTypes/groupReservationAddons';
import { Addon as SessionAddonType } from '@/modules/data/dataTypes/sessionList';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import Divider from '@/modules/shared/components/Divider';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { saveSelectedAddonOptions } from '../../duck/actions';
import { FullAddon } from '../../types';

import Addon from './components/Addon';

type BaseProps = {
  formCode: string;
  checkMandatoryAddons?: boolean;
  finalActions?: RootAction[];
  onChange?: (addons: FullAddon[]) => void;
  availableAddons: Array<AddonType | SessionAddonType>;
  state?: string;
};

type GroupProps = BaseProps & {
  isGroup: true;
  groupReservationGUID: string;
  selectedAddons: GroupReservationAddon[];
};

type IndividualProps = BaseProps & {
  isGroup: false;
  formRecordGUID: string;
  selectedAddons: FormRecordAddon[];
};

type Props = GroupProps | IndividualProps;

type RefProps = {
  handleSubmit: () => void;
};

const SelectAddons = forwardRef<RefProps, Props>((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const { checkMandatoryAddons, onChange, availableAddons, selectedAddons, state } = props;
  const dispatch = useDispatch();
  const canSkipRequiredAddOns: boolean = useSelector(
    createAbilitiesSelector('attendeeSelections', PermissionAction.Update, null),
  );

  const [addons, setAddons] = useState(() => {
    const selectedAddonsObj = (
      selectedAddons as Array<FormRecordAddon | GroupReservationAddon>
    ).reduce(
      (acc, addon) => ({ ...acc, [addon.addonCode]: addon }),
      {} as Record<string, (typeof selectedAddons)[number]>,
    );
    return availableAddons
      .filter(({ forbiddenStateList }) => !state || !forbiddenStateList.includes(state))
      .map(addon => {
        const selectedAddon = selectedAddonsObj[addon.addonCode];
        const defaultOption = addon.addonOptions.find(({ isDefaultOption }) => isDefaultOption);
        return {
          ...addon,
          ...selectedAddon,
          ...(!selectedAddon && defaultOption
            ? {
                selectedOptionCode: defaultOption.optionCode,
                selectedOptionName: defaultOption.optionName,
                selectedOptionFee: defaultOption.fee,
              }
            : {}),
        };
      });
  });

  useEffect(() => {
    onChange && onChange(addons);
  });

  const createAddonOptionSelectHandler = (index: number) => (optionCode: string) => {
    const nextAddons = cloneDeep(addons);
    const updatedAddon = cloneDeep(addons[index]);
    const option = updatedAddon.addonOptions.find(o => o.optionCode === optionCode);

    updatedAddon.selectedOptionCode = optionCode;
    updatedAddon.selectedOptionFee = option?.fee || 0;
    updatedAddon.selectedOptionName = option?.optionName || '';

    set(nextAddons, [index], updatedAddon);

    setAddons(nextAddons);
  };

  function handleSubmit() {
    if (checkMandatoryAddons && !canSkipRequiredAddOns) {
      const mandatoryAddonsSelected = () =>
        addons.every(addon => !addon.isMandatory || addon.selectedOptionCode);
      if (!mandatoryAddonsSelected()) {
        toast.error('Please select an option for required Add-ons');
        return null;
      }
    }

    dispatch(
      saveSelectedAddonOptions.request({
        addons,
        ...props,
      }),
    );
  }

  return (
    <Fragment>
      {addons.map((addon, index) => {
        const { addonName, addonCode, description, isMandatory, addonOptions, isAppliedToRoster } =
          addon;
        return (
          <Fragment
            key={
              'formRecordAddonGUID' in addon
                ? `${addon.formRecordAddonGUID}_${index}`
                : `${addonCode}_${index}`
            }
          >
            {index > 0 && <Divider />}
            <Addon
              addonName={addonName}
              canSkipRequiredAddOns={canSkipRequiredAddOns}
              description={description}
              addonOptions={addonOptions}
              isMandatory={isMandatory}
              selectedOptionCode={
                'selectedOptionCode' in addon ? addon.selectedOptionCode : undefined
              }
              isAppliedToRoster={isAppliedToRoster}
              onChange={createAddonOptionSelectHandler(index)}
            />
          </Fragment>
        );
      })}
    </Fragment>
  );
});

export default SelectAddons;
