import dayjs from 'dayjs';
import { useMemo } from 'react';

import { Person } from '@/modules/data/dataTypes/person';
import { dateFromString } from '@/modules/utils/dateFormats';

export const useGetFilteredPositions = (person?: Person): Person['positions'] => {
  const organizations = useMemo(() => {
    if (!person?.positions) return [];
    return person.positions.filter(
      ({ positionName, expirationDate }) =>
        positionName !== null &&
        positionName.toLowerCase() !== 'alumni' &&
        dayjs().isSameOrBefore(dateFromString(expirationDate)),
    );
  }, [person]);

  return organizations;
};
