import { EmailTemplate } from '@/modules/data/dataTypes/emailTemplate';
import { QueryFilterVariable } from '@/modules/data/dataTypes/queryFilterVariableList';

export const makeVariableCanBeUsedInTemplate =
  (template: EmailTemplate | Record<string, never>) => (variable: QueryFilterVariable) => {
    const { caption, isAvailableForEmailTemplate, tagData } = variable;

    if (!isAvailableForEmailTemplate || !caption) return false;
    if (!tagData) return true;

    return tagData
      .split('@')
      .slice(1)
      .some(tag => template.tagData && template.tagData.includes(tag));
  };
