import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { FormSettings } from '@/modules/data/dataTypes/form';
import { dateFromString } from '@/modules/utils/dateFormats';

import { moduleName } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => state[moduleName];

export const updatingEventSel = createSelector(moduleSel, ({ updatingEvent }) => updatingEvent);

export const isOnRegistrationWindowSel = createSelector(
  (
    _state: RootState,
    props: Pick<FormSettings, 'eventRegistrationStartDate' | 'eventRegistrationCloseDate'>,
  ) => props,
  ({ eventRegistrationStartDate, eventRegistrationCloseDate }) =>
    dayjs().isBetween(
      dateFromString(eventRegistrationStartDate),
      dateFromString(eventRegistrationCloseDate),
      'day',
      '[]',
    ),
);
