/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const moduleName = 'localization';

export const locale = 'en';

export const locales = {
  en: 'enUS',
  es: 'esMX',
} as const;

export const bsaLocales = {
  en: () => import('antd/lib/locale/en_US'),
  es: () => import('antd/lib/locale/es_ES'),
};
