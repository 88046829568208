import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { Fragment } from 'react';

import Currency from '@/modules/shared/components/Currency';
import { EnhancedPaymentCategory } from '@/modules/shared/components/ReservationPaymentsCard/ReservationPaymentsCard';

import { ROW_KEY } from '../../../../../../constants';

import { Checkbox } from './Header.styled';

type Props = {
  allowPayments: boolean;
  isSelected: boolean;
  paymentCategory: EnhancedPaymentCategory;
  onSelect: (categoryId: string, isSelected: boolean) => void;
};

const Header: React.FC<Props> = ({ paymentCategory, allowPayments, isSelected, onSelect }) => {
  const { paymentCategoryName, paymentCategoryAmount, [ROW_KEY]: categoryCode } = paymentCategory;

  const handleSelect = (e: CheckboxChangeEvent) => {
    onSelect(categoryCode, e.target.checked);
  };

  return (
    <Fragment>
      <Checkbox disabled={!allowPayments} checked={isSelected} onChange={handleSelect} />
      <span>
        {`${paymentCategoryName}:`} <Currency value={paymentCategoryAmount} />
      </span>
    </Fragment>
  );
};

export default Header;
