import styled from 'styled-components';

export const AssignRolesWrapper = styled.div`
  margin: 3em;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .ant-input {
    width: 18em;
    height: 3em;
    border-radius: 4px;
    font-size: 16px;
  }

  > *:nth-child(-n + 2) {
    width: auto;
    margin-bottom: 15px;
  }

  & .ant-select-auto-complete {
    width: 18em;
  }
`;
