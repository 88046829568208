import { Button as BsaButton, ButtonProps } from 'antd';
import cn from 'classnames';
import { isString } from 'lodash';
import React from 'react';

import styles from './Button.less';

const extraColors = ['success', 'warn', 'info', 'error'];

type Props = ButtonProps & {
  id?: string;
  loading?: boolean;
  uppercase?: boolean;
  shadow?: boolean;
  noBorder?: boolean;
  color?: string;
  className?: string;
  children: React.ReactNode;
  icon?: React.ReactElement;
  noPadding?: boolean;
  bold?: boolean;
  disabled?: boolean;
  htmlType?: 'submit';
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const Button: React.FC<Props> = ({
  uppercase,
  shadow,
  noBorder,
  color,
  className,
  children,
  icon,
  noPadding,
  bold,
  ...props
}) => {
  let extraColor = '';
  if (color && extraColors.includes(color)) {
    extraColor = color;
    color = 'scouting-blue';
  }

  const classNames = cn(
    styles.button,
    {
      [styles.uppercase]: uppercase,
      [styles.shadow]: shadow,
      [styles.noBorder]: noBorder,
      [styles.withIcon]: icon,
      [styles.noPadding]: noPadding,
      [styles.bold]: bold,
    },
    styles[extraColor],
    className,
  );

  const styledIcon =
    icon &&
    !isString(icon) &&
    React.cloneElement(icon, {
      className: cn(icon.props.className, styles.icon),
    });

  return (
    <BsaButton className={classNames} color={color} {...props}>
      {styledIcon}
      {children}
    </BsaButton>
  );
};

Button.defaultProps = {
  uppercase: true,
  shadow: false,
  noBorder: false,
  color: 'scouting-blue',
};

export default Button;
