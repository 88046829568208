import apiService from '@/modules/utils/apiService';

import { UpdateAttendeeTypePayload } from './actions';

const updateAttendeeType$ = (payload: UpdateAttendeeTypePayload & { formCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=UpdateAttendeeType',
    payload,
  );

const deleteAttendeeType$ = (payload: { formCode: string; isDefault: boolean; typeCode: string }) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventFormMetadata&ScriptCode=DeleteAttendeeType',
    payload,
  );

export default {
  updateAttendeeType$,
  deleteAttendeeType$,
};
