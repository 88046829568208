import React, { Fragment } from 'react';

import Currency from '@/modules/shared/components/Currency';
import Divider from '@/modules/shared/components/Divider';

import { TotalDueRow } from './Footer.styled';

type Props = {
  totalDueAmount: number;
};

const Footer: React.FC<Props> = ({ totalDueAmount }) => (
  <Fragment>
    <TotalDueRow>
      <span>TOTAL DUE</span>
      <span>
        <Currency value={totalDueAmount} />
      </span>
    </TotalDueRow>
    <Divider />
  </Fragment>
);

export default Footer;
