import React, { Fragment, useEffect, useMemo } from 'react';

import { PaymentCategory } from '@/modules/data/dataTypes/paymentCategoryList';
import Desktop from '@/modules/responsiveSizes/Desktop';
import Mobile from '@/modules/responsiveSizes/Mobile';

import { EnhancedPaymentCategory } from '../../ReservationPaymentsCard';

import PaymentScheduleList from './components/PaymentScheduleList';
import PaymentScheduleTable from './components/PaymentScheduleTable';
import { ROW_KEY } from './constants';

interface Props {
  onSetAmountToPay: (amount: number) => void;
  paymentCategories: EnhancedPaymentCategory[];
  isReservationCancelled: boolean;
  selectedRowKeys: string[];
  isAllowedToPay?: boolean;
  onSetSelectedRowKeys: (keys: string[]) => void;
  onUpdatePaymentScheduleDueDate?: (paymentCategory: PaymentCategory) => void;
}

const PaymentScheduleTab: React.FC<Props> = ({
  paymentCategories,
  onSetAmountToPay,
  isReservationCancelled,
  selectedRowKeys,
  onSetSelectedRowKeys,
  onUpdatePaymentScheduleDueDate,
  isAllowedToPay,
}) => {
  const amountToPay = useMemo(
    () =>
      paymentCategories.reduce((acc, { [ROW_KEY]: categoryCode, dueAmount }) => {
        if (selectedRowKeys.includes(categoryCode)) {
          return acc + dueAmount;
        }
        return acc;
      }, 0),
    [selectedRowKeys, paymentCategories],
  );

  const totalDueAmount = useMemo(
    () => paymentCategories.reduce((acc, { dueAmount }) => acc + dueAmount, 0),
    [paymentCategories],
  );

  useEffect(() => {
    onSetAmountToPay(amountToPay);
  }, [amountToPay, onSetAmountToPay]);

  const allowPayments = useMemo(
    () => (isReservationCancelled ? false : !!isAllowedToPay),
    [isReservationCancelled, isAllowedToPay],
  );

  return (
    <Fragment>
      <Desktop>
        <PaymentScheduleTable
          allowPayments={allowPayments}
          selectedRowKeys={selectedRowKeys}
          onSetSelectedRowKeys={onSetSelectedRowKeys}
          onUpdatePaymentScheduleDueDate={onUpdatePaymentScheduleDueDate}
          paymentCategories={paymentCategories}
          totalDueAmount={totalDueAmount}
        />
      </Desktop>
      <Mobile>
        <PaymentScheduleList
          onSetSelectedRowKeys={onSetSelectedRowKeys}
          onUpdatePaymentScheduleDueDate={onUpdatePaymentScheduleDueDate}
          paymentCategories={paymentCategories}
          selectedRowKeys={selectedRowKeys}
          allowPayments={allowPayments}
          totalDueAmount={totalDueAmount}
        />
      </Mobile>
    </Fragment>
  );
};

export default PaymentScheduleTab;
