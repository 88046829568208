import { InfoCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const ExplanationWrapper = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
`;

export const InfoIcon = styled(InfoCircleFilled)`
  color: ${colors.lightBlue};
  margin-right: 5px;
`;
