import { Avatar, List } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const StyledListItem = styled(List.Item)<{ $isSelected: boolean }>`
  border-bottom-color: ${colors.greyUnderline};
  padding: 1rem;
  cursor: pointer;
  background-color: ${colors.complementColor};

  &:nth-of-type(2n) {
    background-color: ${colors.infoBackground};
  }

  filter: ${({ $isSelected }) => ($isSelected ? 'brightness(0.9);' : 'none')};
  border-left: ${({ $isSelected }) => ($isSelected ? `5px solid ${colors.primaryColor};` : '0')};
`;

export const StyledListItemMeta = styled(List.Item.Meta)`
  &&& {
    margin-bottom: 0;

    .ant-list-item-meta-title {
      margin-bottom: 0;
    }
  }
`;

export const Title = styled.span`
  font-family: Roboto, serif;
  font-weight: bold;
  color: ${colors.primaryColor};
  font-size: 14px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-family: Roboto, serif;
  font-weight: normal;
  color: ${colors.darkGrey};
`;

export const Date = styled.div`
  font-size: 12px;
  font-family: Roboto, serif;
  font-weight: normal;
  color: ${colors.gray};
`;

export const StyledAvatar = styled(Avatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: ${colors.primaryColor};
`;
