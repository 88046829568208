import { Card } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const TheCard = styled(Card)`
  .ant-card-head {
    background: ${colors.white};
  }
`;
