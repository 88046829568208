import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const TableInnerHeader = styled.div`
  display: flex;
  background-color: ${colors.darkGrey};
  padding: 16px;
`;

export const BlueBoldText = styled.b`
  color: ${colors.lightBlue};
`;
