import { Spin } from 'antd';
import React, { Fragment, useRef, useMemo } from 'react';

import { Container, Loader, Spinner } from './LoadingContainer.styled';

type Props = {
  isLoading: boolean;
  children?: React.ReactNode;
  className?: string;
  greyOut?: boolean;
};

const LoadingContainer: React.FC<Props> = ({ isLoading, children, className, greyOut }) => {
  const loaderRef = useRef<HTMLDivElement>(null);

  const containerHeight = useMemo(() => {
    if (!loaderRef.current) return 0;
    return loaderRef.current.getBoundingClientRect().height;
  }, [loaderRef]);

  return (
    <Container ref={loaderRef} className={className}>
      {isLoading && (
        <Fragment>
          <Loader greyOut={greyOut} />
          <Spinner containerHeight={containerHeight}>
            <Spin />
          </Spinner>
        </Fragment>
      )}
      {children}
    </Container>
  );
};

LoadingContainer.defaultProps = {
  isLoading: false,
};

export default LoadingContainer;
