import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { MultiSessionCapacityRule } from '@/modules/data/dataTypes/multiSessionCapacityRules';

import {
  createEditMultiSessionRuleClosed,
  createEditMultiSessionRuleOpened,
  deleteMultiSessionRule,
  updateMultiSessionRule,
} from './actions';

const updatedMultiSessionRule = createReducer(null as Partial<MultiSessionCapacityRule> | null)
  .handleAction([createEditMultiSessionRuleOpened], (_state, action) => action.payload)
  .handleAction([createEditMultiSessionRuleClosed, updateMultiSessionRule.success], () => null);

const updateMultiSessionRuleInProgress = createReducer(false)
  .handleAction(updateMultiSessionRule.request, () => true)
  .handleAction([updateMultiSessionRule.success, updateMultiSessionRule.failure], () => false);

const deleteMultiSessionRuleInProgress = createReducer(false)
  .handleAction(deleteMultiSessionRule.request, () => true)
  .handleAction([deleteMultiSessionRule.success, deleteMultiSessionRule.failure], () => false);

export default combineReducers({
  updatedMultiSessionRule,
  updateMultiSessionRuleInProgress,
  deleteMultiSessionRuleInProgress,
});
