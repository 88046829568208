import { Tag as BSATag, Typography as BSATypography } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Typography = styled(BSATypography)<{ $isSelectOpen?: boolean }>`
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ $isSelectOpen }) => ($isSelectOpen ? 'normal' : 'nowrap')};
`;

export const Tag = styled(BSATag)`
  align-items: center;
  background-color: ${colors.dangerColor};
  border-radius: 4px;
  color: black;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 94px;
`;
