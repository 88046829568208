import React, { PropsWithChildren } from 'react';
import { TypeOptions } from 'react-toastify';

import { Body, Content, Title } from './Toast.styled';

interface Props {
  title?: React.ReactNode | null;
  type?: TypeOptions;
}

const Toast: React.FC<PropsWithChildren<Props>> = ({ title, children, type = 'default' }) => (
  <Content>
    {title && <Title $type={type}>{title}</Title>}
    <Body>{children}</Body>
  </Content>
);

export default Toast;
