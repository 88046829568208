import { combineEpics, Epic } from 'redux-observable';
import { concat, defer, merge, of, race } from 'rxjs';
import { switchMap, filter, take, map, catchError } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData, replaceDataItem, scheduleRefresh } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { AttributeCode } from '@/modules/entities/Attributes/constants';
import {
  createFormAttribute,
  updateAttendeesAttributes,
} from '@/modules/entities/Attributes/duck/actions';
import { CONFIRM_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal, updateModalParams } from '@/modules/modals/duck/actions';
import { cancelAction, confirmAction } from '@/modules/shared/components/ConfirmModal/duck/actions';
import type { ModalParams as ConfirmModalParams } from '@/modules/shared/components/ConfirmModal/types';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import { pageDataSel } from '@/pages/event/duck/selectors';

import { checkIn, councilApproveSelected } from './actions';
import services from './services';

const councilApproveSelected$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(councilApproveSelected)),
    switchMap(({ payload: { formRecordGUIDList, dataId, dataType } }) => {
      const state = state$.value;
      const confirmModalParams: ConfirmModalParams = {
        title: 'Mass Council Approve Confirmation',
        description: 'You are about to approve all the selected attendees.',
      };

      const { formCode } = createDataSel('form')(state);

      const finalActions = [refreshData({ dataType, dataId }), closeModal()];

      return merge(
        race(
          action$.pipe(
            filter(isActionOf(confirmAction)),
            take(1),
            switchMap(() =>
              merge(
                of(updateModalParams({ inProgress: true })),
                services.massApproveFormRecords$({ formCode, formRecordGUIDList }).pipe(
                  switchMap(() => {
                    toastService.success('All selected records were approved');
                    return of(...finalActions);
                  }),
                  catchError((error: ApiError) => {
                    if (error.responseCode === '-4') {
                      toastService.success(
                        'All selected records with pending state where approved',
                      );
                    } else toastService.error(error.message);

                    return of(...finalActions);
                  }),
                ),
              ),
            ),
          ),
          action$.pipe(
            filter(isActionOf(cancelAction)),
            take(1),
            map(() => closeModal()),
          ),
        ),

        of(openModal(CONFIRM_MODAL, confirmModalParams)),
      );
    }),
    catchError((error: ApiError) => {
      toastService.error(error.message);
      return of(closeModal());
    }),
  );

const checkIn$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(checkIn.request)),
    switchMap(({ payload }) => {
      const { attendee, checkedIn } = payload;
      const { formRecordGUID, attributeList } = attendee;
      const {
        data: {
          form: { formCode },
        },
      } = pageDataSel(state$.value);
      return concat(
        prefetchData$(action$, { dataType: 'attributeList', queryObj: { formCode } }),
        defer(() => {
          const attributes = createDataSel('attributeList')(state$.value);
          const checkInAttribute = attributes.find(
            ({ attributeCode }) => attributeCode === AttributeCode.CheckedIn,
          );
          if (checkInAttribute) {
            return concat(
              merge(
                race(
                  action$.pipe(filter(isActionOf(updateAttendeesAttributes.success)), take(1)),
                  action$.pipe(
                    filter(isActionOf(updateAttendeesAttributes.failure)),
                    take(1),
                    map(({ payload: assignAttributeError }) => {
                      throw assignAttributeError;
                    }),
                  ),
                ),
                of(
                  updateAttendeesAttributes.request({
                    formCode,
                    attendees: [formRecordGUID],
                    attributes: [checkInAttribute.attributeCode],
                    isDelete: !checkedIn,
                  }),
                ),
              ),
              of(
                checkIn.success(),
                replaceDataItem({
                  dataType: 'attendeeList',
                  idField: 'formRecordGUID',
                  dataItem: {
                    ...attendee,
                    attributeList: checkedIn
                      ? `${attributeList},${checkInAttribute.attributeCode}`
                      : attributeList
                          .split(',')
                          .filter(attr => attr !== checkInAttribute.attributeCode)
                          .join(','),
                  },
                }),
              ),
            );
          }

          return concat(
            merge(
              race(
                action$.pipe(filter(isActionOf(createFormAttribute.success)), take(1)),
                action$.pipe(
                  filter(isActionOf(createFormAttribute.failure)),
                  take(1),
                  map(({ payload: createAttributeError }) => {
                    throw createAttributeError;
                  }),
                ),
              ),
              of(
                createFormAttribute.request({
                  formCode,
                  attributeCode: AttributeCode.CheckedIn,
                  attributeName: 'Checked In',
                }),
              ),
            ),
            of(scheduleRefresh({ dataType: 'attributeList' }), checkIn.request(payload)),
          );
        }),
      );
    }),
    catchError((error: Error, caught) => {
      toastService.error(error.message);
      return merge(of(checkIn.failure(error)), caught);
    }),
  );

export default combineEpics(councilApproveSelected$, checkIn$);
