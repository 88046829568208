import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'typesafe-actions';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservationListReport';
import { updatePageNumber } from '@/modules/data/duck/actions';
import { isOnRegistrationWindowSel } from '@/modules/entities/Events/duck/selectors';
import Desktop from '@/modules/responsiveSizes/Desktop';
import Mobile from '@/modules/responsiveSizes/Mobile';
import EventCover from '@/modules/shared/components/EventCover';
import GoBackLink from '@/modules/shared/components/GoBackLink';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import NotFoundData from '@/modules/shared/components/NotFoundData';
import RegisterButton from '@/modules/shared/components/RegisterButton';
import Warning from '@/modules/shared/components/Warning';
import { eventDateWithYear } from '@/modules/utils/dateFormats';

import { openForm } from '@/pages/createReservation/duck/actions';

import { navigateToGroupReservationOverview } from '../reservation/duck/actions';

import { ButtonWrapper, Card, Pagination } from './ReservationList.styled';
import ReservationFilterCheckGroup from './components/ReservationFilterCheckGroup';
import ReservationsList from './components/ReservationsList';
import ReservationsTable from './components/ReservationsTable';
import { pageDataSel } from './duck/selectors';

const ReservationListComponent: React.FC = () => {
  const dispatch = useDispatch();
  const {
    data: { form, reservationListReport: reservations },
    extraData: {
      reservationListReport: { pendingReservationList },
    },
    isLoading,
    pagination,
  } = useSelector(pageDataSel);

  const isOnRegistrationWindow = useSelector((state: RootState) =>
    isOnRegistrationWindowSel(state, form),
  );

  if (!form) {
    return <NotFoundData content="Sorry, we have trouble loading this event." />;
  }

  const pendingReservation = pendingReservationList.split(',')[0];

  const { pageSize, pageNumber, recordCount } = pagination.reservationListReport || {};

  const {
    formCode,
    formName,
    eventStartDate,
    eventCloseDate,
    eventRegistrationStartDate,
    eventRegistrationCloseDate,
  } = form;

  const handleFinishReservation = ({ groupReservationGUID }: GroupReservation) => {
    dispatch(openForm({ formCode, groupReservationGUID }));
  };

  const handleOpenReservation = ({ groupReservationGUID }: GroupReservation) => {
    dispatch(
      navigateToGroupReservationOverview({
        formCode,
        groupReservationGUID,
      }),
    );
  };

  const handlePageChange = (number: number) => {
    dispatch(updatePageNumber({ dataType: 'reservationListReport', pageNumber: number }));
  };

  return (
    <Fragment>
      <EventCover title={formName} dates={[eventStartDate, eventCloseDate]} />
      <Card>
        <GoBackLink />
        <ReservationFilterCheckGroup />
        <LoadingContainer isLoading={isLoading.reservationListReport}>
          <Desktop>
            <ReservationsTable
              allowRegistration={isOnRegistrationWindow}
              onFinishReservation={handleFinishReservation}
              onOpenReservation={handleOpenReservation}
            />
          </Desktop>
          <Mobile>
            <ReservationsList
              allowRegistration={isOnRegistrationWindow}
              onFinishReservation={handleFinishReservation}
              onOpenReservation={handleOpenReservation}
            />
          </Mobile>
        </LoadingContainer>
        <Pagination
          pageSize={pageSize}
          current={pageNumber}
          total={recordCount}
          showTotal={(total: number) => <Desktop>{`Total Reservations ${total}`}</Desktop>}
          onChange={handlePageChange}
        />
        <ButtonWrapper>
          {!isOnRegistrationWindow && (
            <Warning
              showWarningText={false}
              errorMessage={`Registration period is closed (${eventDateWithYear(
                eventRegistrationStartDate,
                eventRegistrationCloseDate,
              )})`}
            />
          )}
          {!!pendingReservation && (
            <Warning
              showWarningText={false}
              errorMessage={'There is  at least 1 reservation in progress'}
            />
          )}
          <RegisterButton
            isGroup
            event={form}
            numberOfReservations={reservations.length}
            lastInProgressGroupReservationGUID={pendingReservation}
          />
        </ButtonWrapper>
      </Card>
    </Fragment>
  );
};

export default ReservationListComponent;
