import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Session } from '@/modules/data/dataTypes/sessionList';
import { refreshData, scheduleRefresh } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import CheckoutStep from '@/modules/entities/Payments/components/CheckoutStep';
import { confirmRegistration } from '@/modules/entities/Registrations/duck/actions';
import { createGroupReservationRosters } from '@/modules/entities/Reservations/duck/actions';
import { PaymentMethod } from '@/modules/payments/constants';

import { openNextStep, stopRegistrationTimer } from '../../duck/actions';
import { pageDataSel } from '../../duck/selectors';
import { currentSessionSel } from '../SessionPriorities/duck/selectors';

const Checkout: React.FC = () => {
  const dispatch = useDispatch();
  const {
    data: { form, attendeeDetails, groupReservation },
  } = useSelector(pageDataSel);
  const currentSession = useSelector(currentSessionSel) as Session;
  const paymentSummary = useSelector(createDataSel('paymentSummary'));

  const { formCode, allowGroupRegistration } = form;
  const { sessionCode } = currentSession;

  const handlePaymentStart = () => {
    dispatch(stopRegistrationTimer());
  };

  const handleOpenNextStep = useCallback(
    (paymentMethod: PaymentMethod | null) => {
      dispatch(openNextStep());
      dispatch(scheduleRefresh({ dataType: 'payments' }));
      dispatch(scheduleRefresh({ dataType: 'paymentSummary' }));

      if (
        paymentMethod &&
        [PaymentMethod.adjustment, PaymentMethod.costCenter].includes(paymentMethod)
      ) {
        dispatch(
          allowGroupRegistration
            ? createGroupReservationRosters.request({
                formCode,
                groupReservationGUID: groupReservation.groupReservationGUID,
                sessionCode,
              })
            : confirmRegistration.request({
                formCode,
                formRecordGUID: attendeeDetails.formRecordGUID,
                sessionCode,
              }),
        );
      }
    },
    [dispatch, allowGroupRegistration, attendeeDetails, groupReservation, formCode, sessionCode],
  );

  const handleRefreshPaymentsData = useCallback(() => {
    dispatch(refreshData({ dataType: 'payments' }));
    dispatch(refreshData({ dataType: 'paymentSummary' }));
  }, [dispatch]);

  return (
    <CheckoutStep
      currentSession={currentSession}
      onPaymentStarted={handlePaymentStart}
      onOpenNextStep={handleOpenNextStep}
      onRefreshPaymentsData={handleRefreshPaymentsData}
      paymentSummary={paymentSummary}
      {...(allowGroupRegistration
        ? {
            isGroup: allowGroupRegistration,
            groupReservationDetails: groupReservation,
          }
        : {
            isGroup: allowGroupRegistration,
            attendeeDetails: attendeeDetails,
          })}
    />
  );
};

export default Checkout;
