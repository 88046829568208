import { Button } from 'antd';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Attendee } from '@/modules/data/dataTypes/attendeeList';
import ActionTable from '@/modules/shared/components/ActionTable';
import EventCover from '@/modules/shared/components/EventCover';
import GoBackLink from '@/modules/shared/components/GoBackLink';
import { usDate } from '@/modules/utils/dateFormats';

import { openForm } from '@/pages/createReservation/duck/actions';
import { navigateToIndividualReservation } from '@/pages/reservation/duck/actions';

import { OpenReservationButton, Card, RegisterButton } from './IndividualReservationList.styled';
import { pageDataSel } from './duck/selectors';

const IndividualReservations: React.FC = () => {
  const dispatch = useDispatch();
  const {
    data: { form, attendeeList },
  } = useSelector(pageDataSel);

  const { formCode, formName, eventStartDate, eventCloseDate } = form;

  const createOpenRegistrationHandler = (formRecordGUID: string) => () => {
    dispatch(navigateToIndividualReservation({ formCode, formRecordGUID }));
  };

  const createFinishRegistrationHandler = (formRecordGUID: string) => () => {
    dispatch(openForm({ formCode, formRecordGUID }));
  };

  const columns = [
    {
      title: 'Reservation Code',
      key: 'reservationCode',
      render: ({ reservationCode }: Attendee) => reservationCode,
    },
    {
      title: 'Registrant Name',
      key: 'registrantName',
      render: ({ firstName, lastName }: Attendee) => `${firstName} ${lastName}`,
    },
    {
      title: 'Session Name',
      key: 'sessionName',
      render: ({ sessionName }: Attendee) => sessionName,
    },
    {
      title: 'Session Date',
      key: 'sessionDate',
      render: ({ sessionStartDate }: Attendee) => sessionStartDate && usDate(sessionStartDate),
    },
    {
      key: 'openIndividualReservation',
      // eslint-disable-next-line react/no-multi-comp
      render: ({ isRegistrationCompleted, formRecordGUID }: Attendee) => {
        if (isRegistrationCompleted) {
          return <OpenReservationButton onClick={createOpenRegistrationHandler(formRecordGUID)} />;
        }
        return (
          <Button
            type="link"
            size="small"
            onClick={createFinishRegistrationHandler(formRecordGUID)}
          >
            Finish Registration
          </Button>
        );
      },
    },
  ];

  return (
    <Fragment>
      <EventCover title={formName} dates={[eventStartDate, eventCloseDate]} />
      <Card>
        <GoBackLink />
        <ActionTable
          dataSource={attendeeList}
          columns={columns}
          rowKey="formRecordGUID"
          pagination={false}
        />
        <RegisterButton event={form} isGroup={false} numberOfRegistrations={attendeeList.length} />
      </Card>
    </Fragment>
  );
};

export default IndividualReservations;
