import { Form, Select } from 'antd';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { EmailTemplate } from '@/modules/data/dataTypes/emailTemplate';
import { EmailTemplate as EmailTemplateItem } from '@/modules/data/dataTypes/emailTemplateList';
import { fetchData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';

type Option = {
  value: string;
  label: string;
  isLocal: boolean;
};

type Props = {
  activeTemplate?: EmailTemplate;
  emailTemplates: EmailTemplateItem[];
  onSelect?: (value: string) => void;
};

const TemplatePickerRow: React.FC<Props> = ({ activeTemplate, emailTemplates, onSelect }) => {
  const dispatch = useDispatch();
  const { formCode } = useSelector(createDataSel('form'));
  const [selectedTemplateCode, setSelectedTemplateCode] = useState(
    activeTemplate?.emailTemplateCode,
  );

  const groupedTemplates = useMemo(
    () =>
      emailTemplates
        .slice()
        .sort((a, b) =>
          a.emailTemplateName.toLowerCase().localeCompare(b.emailTemplateName.toLowerCase()),
        )
        .reduce(
          (acc, template) => {
            const { emailTemplateCode, emailTemplateName, isLocal } = template;
            const key: keyof typeof acc = isLocal ? 0 : 1;
            const { options } = acc[key];
            const nextOptions = [
              ...options,
              { value: emailTemplateCode, label: emailTemplateName, isLocal },
            ];
            acc[key] = { ...acc[key], options: nextOptions };

            return acc;
          },
          [
            { options: [] as Option[], label: 'Custom Templates' },
            { options: [] as Option[], label: 'System Templates' },
          ],
        )
        .filter(({ options }) => options.length),
    [emailTemplates],
  );

  const handleFetchTemplate = (emailTemplateCode: string, option: unknown) => {
    if (!emailTemplateCode || !option) return;

    const { isLocal } = option as Option;

    dispatch(
      fetchData.request({
        dataType: 'emailTemplate',
        queryObj: { emailTemplateCode, ...(isLocal ? { formCode } : {}) },
      }),
    );

    setSelectedTemplateCode(emailTemplateCode);
  };

  useEffect(() => {
    if (selectedTemplateCode !== activeTemplate?.emailTemplateCode) {
      setSelectedTemplateCode(activeTemplate?.emailTemplateCode);

      if (onSelect) {
        onSelect('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTemplate]);

  return (
    <Form.Item label="Select a template" labelCol={{ span: 4 }}>
      <Select
        id="selectEmailTemplateInput"
        size="large"
        optionFilterProp="label"
        showSearch
        value={selectedTemplateCode}
        onChange={handleFetchTemplate}
        options={groupedTemplates}
      />
    </Form.Item>
  );
};

export default TemplatePickerRow;
