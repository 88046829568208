import { createAction, createAsyncAction } from 'typesafe-actions';

import { Activity } from '@/modules/data/dataTypes/activities';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';

export interface UpdateActivityPayload {
  activityCode?: string;
  activityName: string;
  description?: string;
  fee: number;
  finishDate: string;
  isGroupActivity: boolean;
  instructor?: string;
  location?: string;
  maximumRosterCount?: number;
  maximumPeopleCount: number;
  startDate: string;
  attendeeTypesList?: string[];
}

export const resetModuleState = createAction(`${moduleName}/RESET_MODULE_STATE`)();

export const updateActivityOpened = createAction(`${moduleName}/UPDATE_ACTIVITY_OPENED`)<
  Activity | Record<string, never> | null
>();
export const updateActivityClosed = createAction(`${moduleName}/UPDATE_ACTIVITY_CLOSED`)();

export const updateActivity = createAsyncAction(
  `${moduleName}/CREATE_EDIT_ACTIVITY_REQUEST`,
  `${moduleName}/CREATE_EDIT_ACTIVITY_SUCCESS`,
  `${moduleName}/CREATE_EDIT_ACTIVITY_FAILURE`,
)<UpdateActivityPayload, undefined, ApiError>();

export const updateActivityAttendeeTypes = createAsyncAction(
  `${moduleName}/UPDATE_ACTIVITY_ATTENDEE_TYPES_REQUEST`,
  `${moduleName}/UPDATE_ACTIVITY_ATTENDEE_TYPES_SUCCESS`,
  `${moduleName}/UPDATE_ACTIVITY_ATTENDEE_TYPES_FAILURE`,
)<
  {
    activityCode: string;
    attendeeTypes: Array<{
      isIncluded: boolean;
      typeCode: string;
    }>;
  },
  undefined,
  ApiError
>();

export const deleteActivity = createAsyncAction(
  `${moduleName}/DELETE_ACTIVITY_REQUEST`,
  `${moduleName}/DELETE_ACTIVITY_SUCCESS`,
  `${moduleName}/DELETE_ACTIVITY_FAILURE`,
)<string, undefined, Error>();
