import { Card } from 'antd';
import styled from 'styled-components';

import Input from 'SHARED/components/SearchInput';

export const SearchInput = styled(Input)`
  display: flex;
  flex: 1;
  background: white;
  margin: 0;
  padding: 8px 24px;
  border-top: 1px solid #e9e9e9;

  input {
    border: none;
  }

  .ant-input-group-addon {
    padding-left: 0;
  }
`;

export const RosterPageCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`;

export const RosterName = styled.div`
  padding: 16px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  color: #003f87;
`;
