import React, { Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateFormRecord } from '@/modules/entities/FormRecord/duck/actions';
import { updateFormRecordInProgressSel } from '@/modules/entities/FormRecord/duck/selectors';
import Questions from '@/modules/questions';
import { FormattedFormParts } from '@/modules/questions/types';

import { openNextStep } from '@/pages/createReservation/duck/actions';

import ButtonsRow from '../../components/ButtonsRow';
import { pageDataSel } from '../../duck/selectors';

import { stepDataSel } from './duck/selectors';

const FormSubmission: React.FC = () => {
  const dispatch = useDispatch();
  const questionsRef = useRef<{ handleSubmit: () => Promise<FormattedFormParts> }>(null);
  const {
    data: { form, attendeeDetails },
  } = useSelector(pageDataSel);
  const {
    data: { optionSets, visibilitySettings },
  } = useSelector(stepDataSel);
  const { formCode } = form;
  const updateFormRecordInProgress = useSelector(updateFormRecordInProgressSel);

  function handleSubmit() {
    questionsRef.current?.handleSubmit().then(formParts => {
      const { sessionCode, formRecordGUID } = attendeeDetails;
      dispatch(
        updateFormRecord.request({
          formCode,
          sessionCode,
          formRecordGUID,
          formParts,
          finalActions: [openNextStep()],
        }),
      );
    });
  }

  return (
    <Fragment>
      <Questions
        ref={questionsRef}
        attendeeDetails={attendeeDetails}
        optionSets={optionSets}
        visibilitySettings={visibilitySettings}
        isLoading={updateFormRecordInProgress}
        isEditing
        removeSystemFormParts
      />
      <ButtonsRow leftButtons={[{}]} rightButtons={[{ onClick: handleSubmit }]} />
    </Fragment>
  );
};

export default FormSubmission;
