import React from 'react';
import { useSlate } from 'slate-react';

import { FontSize, LeafType } from '@/modules/richTextEditor/constants';

import { addMark, removeMark, getFormat } from '../../../../utils';

import styles from './SizePicker.less';
import SizeItem from './components/SizeItem';

const SizePicker: React.FC = () => {
  const editor = useSlate();
  const activeFontSize = getFormat(editor, LeafType.fontSize) as FontSize;

  const sizeChangeHandler = (fontSize: FontSize | null) => {
    if (fontSize) {
      addMark(editor, LeafType.fontSize, fontSize);
    } else {
      removeMark(editor, LeafType.fontSize);
    }
  };

  return (
    <div className={styles.sizesList}>
      <SizeItem
        title="Normal"
        fontSize={null}
        activeFontSize={activeFontSize}
        onClick={sizeChangeHandler}
      />
      <SizeItem
        title="Big"
        fontSize={FontSize.large}
        activeFontSize={activeFontSize}
        onClick={sizeChangeHandler}
      />
      <SizeItem
        title="Very Big"
        fontSize={FontSize.xLarge}
        activeFontSize={activeFontSize}
        onClick={sizeChangeHandler}
      />
    </div>
  );
};

export default SizePicker;
