import { Typography } from 'antd';
import styled from 'styled-components';

export const Title = styled(Typography.Title)`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
`;
