import styled from 'styled-components';

import colors from '@/modules/styles/colors';

import AntCard from 'SHARED/components/Card';

export const BlueBackground = styled.div`
  background-color: ${colors.scoutingBlue};
  color: white;
  height: 275px;
`;

export const Card = styled(AntCard)<{ $isMobile: boolean }>`
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  left: 50%;
  padding: ${({ $isMobile }) => ($isMobile ? '0 6px' : '')};
  position: absolute;
  text-align: center;
  transform: translate(-50%, -30%);
  width: ${({ $isMobile }) => ($isMobile ? '340px' : '460px')};
`;
