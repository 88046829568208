import { Form } from 'antd';
import cn from 'classnames';
import React from 'react';

import styles from './ValueWithLabel.less';
import { Value } from './ValueWithLabel.styled';

type Props = {
  label: string;
  wrapperClassName?: string;
  className?: string;
  value?: string | number | boolean | React.ReactElement;
};

const ValueWithLabel: React.FC<Props> = ({ label, wrapperClassName, className, value = '-' }) => (
  <Form.Item label={label} className={cn(styles.label, wrapperClassName)}>
    <Value className={className}>{value}</Value>
  </Form.Item>
);

export default ValueWithLabel;
