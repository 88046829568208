import { Button } from 'antd';
import React from 'react';

import { ButtonsRow as ButtonsRowComponent } from './ButtonsRow.styled';

type Props = {
  isLoading: boolean;
  onNextClick: () => void;
  backButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
  nextButtonText?: string;
};

const ButtonsRow: React.FC<Props> = ({
  isLoading,
  onNextClick,
  backButtonDisabled = false,
  nextButtonDisabled = false,
  nextButtonText = 'Next',
}) => (
  <ButtonsRowComponent>
    <Button disabled={backButtonDisabled || isLoading}>Back</Button>
    <Button
      onClick={onNextClick}
      loading={isLoading}
      disabled={nextButtonDisabled || isLoading}
      type="primary"
    >
      {nextButtonText}
    </Button>
  </ButtonsRowComponent>
);

export default ButtonsRow;
