import { Row, Button } from 'antd';
import React, { Fragment, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';

import { refreshData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import ActivitiesSelector from '@/modules/entities/Activities/components/ActivitiesSelector';
import { saveActivitiesInProgressSel } from '@/modules/entities/Activities/duck/selectors';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import EventCover from '@/modules/shared/components/EventCover';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';

import { navigateToIndividualReservation } from '@/pages/reservation/duck/actions';

import { StyledCard, Title } from './EditIndividualReservationActivitiesPage.styled';

const EditActivitiesPage: React.FC = () => {
  const dispatch = useDispatch();
  const activitiesSelectorRef = useRef<{ handleSubmit: () => void }>(null);
  const activities = useSelector(createDataSel('formRecordActivities'));
  const activitiesTotal = useSelector(createDataSel('activities'));
  const sessions = useSelector(createDataSel('sessionList'));
  const isLoading = useSelector(saveActivitiesInProgressSel);
  const attendeeDetails = useSelector(createDataSel('attendeeDetails'));
  const { formCode } = useSelector(createDataSel('form'));
  const isAllowedToEditAttendee = useSelector(
    createAbilitiesSelector('attendeeDetails', PermissionAction.Update, {
      attendeeDetails: attendeeDetails,
    }),
  );
  const isAllowedToEditActivities = useSelector(
    createAbilitiesSelector('attendeeSelections', PermissionAction.Update, attendeeDetails),
  );

  const currentSession = useMemo(
    () => (sessions || []).find(session => session.sessionCode === attendeeDetails.sessionCode),
    [attendeeDetails.sessionCode, sessions],
  );

  const { formRecordGUID } = attendeeDetails;

  const canEditActivities =
    !!currentSession && isAllowedToEditAttendee && isAllowedToEditActivities;

  const handleBack = () => {
    dispatch(redirect(navigateToIndividualReservation({ formCode, formRecordGUID })));
  };

  const handleSubmit = () => {
    activitiesSelectorRef.current?.handleSubmit();
  };

  if (!canEditActivities) {
    return (
      <StyledCard header="Activities">
        <div>You do not have access</div>
      </StyledCard>
    );
  }

  return (
    <Fragment>
      {
        <EventCover
          title={currentSession.sessionName}
          dates={[currentSession.startDate, currentSession.closeDate]}
        />
      }
      <Title>Editing activities for Reservation {attendeeDetails.reservationCode}</Title>
      <ActivitiesSelector
        ref={activitiesSelectorRef}
        availableActivities={activitiesTotal}
        selectedActivities={activities}
        formRecordGUID={attendeeDetails.formRecordGUID}
        isGroup={false}
        finalActions={[
          refreshData({ dataType: 'attendeeDetails' }),
          refreshData({ dataType: 'formRecordActivities' }),
          refreshData({ dataType: 'paymentSummary' }),
          redirect(navigateToIndividualReservation({ formCode, formRecordGUID })),
        ]}
      />
      <Row justify="space-between">
        <Button disabled={isLoading} onClick={handleBack} type="default" id="onBackButton">
          CANCEL
        </Button>
        <Button disabled={isLoading} loading={isLoading} onClick={handleSubmit} type="primary">
          CONFIRM CHANGES
        </Button>
      </Row>
    </Fragment>
  );
};

export default EditActivitiesPage;
