import {
  AccountBookOutlined,
  AuditOutlined,
  BuildOutlined,
  CalculatorOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  InfoCircleFilled,
  RocketOutlined,
  SolutionOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import get from 'lodash/get';

import { createPageDataParams } from '@/modules/data/utils';
import { INCLUDE, NOTINCLUDE } from '@/modules/questions/constants';

export const moduleName = 'createEventPage';

export const pageDataParams = createPageDataParams(
  { dataType: 'form' },
  { dataType: 'attendeeTypeList' },
  { dataType: 'eventCategories' },
);

export const nonCollaboratorRole = 'nonCollaborator';

export const ROUTE_CREATE_EVENT_FORM_BUILDER = `${moduleName}/ROUTE_CREATE_EVENT_FORM_BUILDER`;
export const ROUTE_CREATE_EVENT_FORM_PREVIEW = `${moduleName}/ROUTE_CREATE_EVENT_FORM_PREVIEW`;
export const ROUTE_CREATE_EVENT_PUBLISH = `${moduleName}/ROUTE_CREATE_EVENT_PUBLISH`;
export const ROUTE_CREATE_EVENT_SETTINGS_BASIC_INFO = `${moduleName}/ROUTE_CREATE_EVENT_BASIC_INFO`;
export const ROUTE_CREATE_EVENT_SETTINGS_PROGRAMS = `${moduleName}/ROUTE_CREATE_EVENT_SETTINGS_PROGRAMS`;
export const ROUTE_CREATE_EVENT_SETTINGS_ATTENDEE_TYPES = `${moduleName}/ROUTE_CREATE_EVENT_ATTENDEE_TYPES`;
export const ROUTE_CREATE_EVENT_SETTINGS_COLLABORATORS = `${moduleName}/ROUTE_CREATE_EVENT_COLLABORATORS`;
export const ROUTE_CREATE_EVENT_SETTINGS_PAYMENT_SCHEDULE = `${moduleName}/ROUTE_CREATE_EVENT_PAYMENT_SCHEDULE`;
export const ROUTE_CREATE_EVENT_SETTINGS_DISCLAIMERS = `${moduleName}/ROUTE_CREATE_EVENT_DISCLAIMERS`;
export const ROUTE_CREATE_EVENT_SETTINGS_ADDONS = `${moduleName}/ROUTE_CREATE_EVENT_SETTINGS_ADDONS`;
export const ROUTE_CREATE_EVENT_SETTINGS_ACTIVITIES = `${moduleName}/ROUTE_CREATE_EVENT_SETTINGS_ACTIVITIES`;
export const ROUTE_CREATE_EVENT_SETTINGS_MULTI_SESSION_CAPACITY_RULES = `${moduleName}/ROUTE_CREATE_EVENT_SETTINGS_MULTI_SESSION_CAPACITY_RULES`;
export const ROUTE_CREATE_EVENT_SETTINGS_SESSIONS = `${moduleName}/ROUTE_CREATE_EVENT_SESSIONS`;
export const ROUTE_CREATE_EVENT_SETTINGS_JOBS = `${moduleName}/ROUTE_CREATE_EVENT_JOBS`;
export const ROUTE_CREATE_EVENT_SETTINGS_LEDGER_ACCOUNTS = `${moduleName}/ROUTE_CREATE_EVENT_LEDGER_ACCOUNTS`;
export const UNAUTHORIZED = 'UNAUTHORIZED';

export const settingsSubSteps = [
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_BASIC_INFO,
    path: 'event-settings/basic-info',
    title: 'Basic Info',
    Icon: InfoCircleFilled,
  },
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_PROGRAMS,
    path: 'event-settings/programs',
    title: 'Programs',
    Icon: ContainerOutlined,
  },
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_LEDGER_ACCOUNTS,
    path: 'event-settings/ledger-accounts',
    title: 'Ledger Accounts',
    Icon: AccountBookOutlined,
  },
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_ATTENDEE_TYPES,
    path: 'event-settings/attendee-types',
    title: 'Attendee Types',
    Icon: UserOutlined,
  },
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_COLLABORATORS,
    path: 'event-settings/collaborators',
    title: 'Collaborators',
    Icon: UserOutlined,
  },
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_PAYMENT_SCHEDULE,
    path: 'event-settings/payment-schedule',
    title: 'Payments',
    Icon: CreditCardOutlined,
  },
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_DISCLAIMERS,
    path: 'event-settings/disclaimers',
    title: 'Disclaimers',
    Icon: SolutionOutlined,
  },
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_ADDONS,
    path: 'event-settings/add-ons',
    title: 'Add-ons',
    Icon: ToolOutlined,
  },
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_ACTIVITIES,
    path: 'event-settings/activities',
    title: 'Activities',
    Icon: RocketOutlined,
  },
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_MULTI_SESSION_CAPACITY_RULES,
    path: 'event-settings/multi-session-capacity-rules',
    title: 'Multi-Session Capacity Rules',
    Icon: BuildOutlined,
  },
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_SESSIONS,
    path: 'event-settings/sessions',
    title: 'Sessions',
    Icon: CalculatorOutlined,
  },
  {
    key: ROUTE_CREATE_EVENT_SETTINGS_JOBS,
    path: 'event-settings/jobs',
    title: 'Jobs',
    Icon: AuditOutlined,
  },
] as const;

export const steps = [
  {
    title: 'Event Settings',
    path: 'event-settings',
    subSteps: settingsSubSteps,
  },
  {
    key: ROUTE_CREATE_EVENT_FORM_BUILDER,
    path: 'form-builder',
    title: 'Builder',
    Icon: ToolOutlined,
  },
  {
    key: ROUTE_CREATE_EVENT_FORM_PREVIEW,
    path: 'form-preview',
    title: 'Preview',
  },
  {
    key: ROUTE_CREATE_EVENT_PUBLISH,
    path: 'publish',
    title: 'Publish',
  },
];

export const firstStepRoute = get(steps, [0, 'key']) || get(steps, [0, 'subSteps', 0, 'key']);

export const specialVisibilityOptions: Record<string, Array<{ key: string; text: string }>> = {
  country: [
    {
      // Country could be a list of country codes not a single one, thats why its special case
      key: INCLUDE,
      text: 'Is Equal To',
    },
    {
      key: NOTINCLUDE,
      text: 'Is Not Equal To',
    },
  ],
};

// actions

export const logicActions = {
  HIDE: 'Hide',
  SHOW: 'Show',
} as const;
