import { Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Session } from '@/modules/data/dataTypes/sessionList';
import { updatePageSize } from '@/modules/data/duck/actions';
import {
  createDataSel,
  createIsLoadingSel,
  createRecordCountSel,
} from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import SelectInputMaterial from '@/modules/shared/components/SelectInputMaterial';

import {
  updateFormRegistrationSession,
  updateGroupReservationSession,
} from '../../../../duck/actions';
import { updateSelectedSessionInProgressSel } from '../../../../duck/selectors';

import SessionInformation from './components/SessionInformation';
import SessionOptionItem from './components/SessionOptionItem';
import SessionWarnings from './components/SessionWarnings';
import { ChangeSessionModalParams } from './types';

const { Option } = Select;
interface Props {
  modalParams: ChangeSessionModalParams;
}

const ChangeReservationSessionModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const {
    currentSessionCode,
    initialPeopleCount = 1,
    initialRosterCount = 0,
    reservationOwnerName,
  } = modalParams;
  const { allowGroupRegistration } = useSelector(createDataSel('form'));
  const sessions = useSelector(createDataSel('sessionList'));
  const isLoadingSessions = useSelector(createIsLoadingSel('sessionList'));
  const isUpdatingSession = useSelector(updateSelectedSessionInProgressSel);
  const sessionsCount = useSelector(createRecordCountSel('sessionList'));

  const [selectedSession, setSelectedSession] = useState<Session>();
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const isLoading = isLoadingSessions || isUpdatingSession;

  useEffect(() => {
    dispatch(updatePageSize({ dataType: 'sessionList', pageSize: sessionsCount }));
  }, [dispatch, sessionsCount]);

  const filteredSessions = useMemo(
    () => (sessions || []).filter(session => session.sessionCode !== currentSessionCode),
    [sessions, currentSessionCode],
  );

  const handleSelectSession = (value: string) => {
    const foundSession = filteredSessions.find(session => session.sessionCode === value);

    if (foundSession) {
      setSelectedSession(foundSession);
    }
  };

  const handleSubmit = () => {
    if (!selectedSession) return;

    if (allowGroupRegistration) {
      dispatch(
        updateGroupReservationSession.request({ selectedSessionCode: selectedSession.sessionCode }),
      );
    } else {
      dispatch(
        updateFormRegistrationSession.request({ selectedSessionCode: selectedSession.sessionCode }),
      );
    }
  };

  const getIsSessionDisabled = useCallback(() => {
    if (!selectedSession) return true;

    const {
      attendeeCapacity,
      isSessionFull,
      remainingCapacity = 0,
      remainingRosters = 0,
      totalInitialPeopleCount = 0,
    } = selectedSession;

    const remainingPersonSlots = allowGroupRegistration
      ? attendeeCapacity - totalInitialPeopleCount
      : remainingCapacity;
    const hasNoCrewSlotsLeft = allowGroupRegistration
      ? remainingRosters < initialRosterCount
      : false;
    const hasNoPersonSlotsLeft = remainingPersonSlots < initialPeopleCount;

    return hasNoCrewSlotsLeft || hasNoPersonSlotsLeft || isSessionFull;
  }, [allowGroupRegistration, initialPeopleCount, initialRosterCount, selectedSession]);

  const renderWarningsSection = useCallback(() => {
    if (!selectedSession) return null;

    const {
      attendeeCapacity,
      remainingCapacity = 0,
      remainingRosters = 0,
      totalInitialPeopleCount = 0,
    } = selectedSession;

    const remainingPersonSlots = allowGroupRegistration
      ? attendeeCapacity - totalInitialPeopleCount
      : remainingCapacity;

    return (
      <SessionWarnings
        isGroupEvent={allowGroupRegistration}
        showNoCrewCapacityMessage={remainingRosters < initialRosterCount}
        showNoPersonCapacityMessage={remainingPersonSlots < initialPeopleCount}
      />
    );
  }, [allowGroupRegistration, initialPeopleCount, initialRosterCount, selectedSession]);

  return (
    <ModalComponent
      buttons={[
        {
          disabled: !selectedSession || getIsSessionDisabled(),
          title: 'SAVE CHANGES',
          type: 'primary',
          onClick: handleSubmit,
        },
      ]}
      inProgress={isLoading}
      description={`Please choose a new session for ${reservationOwnerName}`}
      title="Edit Session"
    >
      <SelectInputMaterial
        labelContent="Session"
        optionFilterProp="data-sessionname"
        value={selectedSession?.sessionCode}
        showSearch
        onChange={handleSelectSession}
        onDropdownVisibleChange={setIsSelectOpen}
      >
        {filteredSessions.map(session => (
          <Option
            data-sessionname={session.sessionName}
            key={session.sessionCode}
            value={session.sessionCode}
          >
            <SessionOptionItem
              isGroupEvent={allowGroupRegistration}
              isSelectOpen={isSelectOpen}
              session={session}
            />
          </Option>
        ))}
      </SelectInputMaterial>
      {selectedSession && (
        <SessionInformation isGroupEvent={allowGroupRegistration} session={selectedSession} />
      )}
      {renderWarningsSection()}
    </ModalComponent>
  );
};

export default ChangeReservationSessionModal;
