import { createAction, createAsyncAction } from 'typesafe-actions';

import { RosterDetails } from '@/modules/data/dataTypes/rosterDetails';
import { Roster } from '@/modules/data/dataTypes/rosterList';
import { ApiError } from '@/modules/utils/apiService';

import { moduleName } from '../constants';

export const fetchRoster = createAsyncAction(
  `${moduleName}/FETCH_ROSTER_REQUESTED`,
  `${moduleName}/FETCH_ROSTER_SUCCEEDED`,
  `${moduleName}/FETCH_ROSTER_FAILED`,
)<{ rosterCode: string }, RosterDetails, Error>();

export const updateSelections = createAction(`${moduleName}/UPDATE_SELECTIONS`)<Set<string>>();
export const deselectAttendee = createAction(`${moduleName}/DESELECT_ATTENDEE`)<string>();

export const dropSelectedAttendees = createAction(`${moduleName}/DROP_SELECTED_ATTENDEES`)();

export const removeAttendees = createAsyncAction(
  `${moduleName}/REMOVE_ATTENDEES_REQUEST`,
  `${moduleName}/REMOVE_ATTENDEES_SUCCEEDED`,
  `${moduleName}/REMOVE_ATTENDEES_FAILURE`,
)<undefined, undefined, Error | undefined>();

export const initiateAttendeesTransfer = createAction(
  `${moduleName}/INITIATE_ATTENDEES_TRANSFER`,
)();

export const transferAttendees = createAsyncAction(
  `${moduleName}/TRANSFER_ATTENDEES_REQUEST`,
  `${moduleName}/TRANSFER_ATTENDEES_SUCCEEDED`,
  `${moduleName}/TRANSFER_ATTENDEES_FAILURE`,
)<
  { roster: Roster; selectedAttendeeTypes?: { [memberId: string]: string } },
  undefined,
  ApiError
>();
