import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';

import { defaultCustomDataBlock } from '../../constants';
import { addDataBlock } from '../../duck/actions';
import { addDatablockInProgressSel } from '../../duck/selectors';

import DataBlocksSet from './components/DataBlocksSet';
import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const AddDataBlockModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const addDataBlocksInProgress = useSelector(addDatablockInProgressSel);
  const [selectedDataBlocks, setSelectedDataBlocks] = useState<Record<string, boolean>>({});

  const { formPartsData, dataBlocks = [] } = modalParams;

  const { formParts } = formPartsData;

  const filteredDataBlocks = useMemo(() => {
    const existingDataBlocks: Record<string, true> = formParts.reduce(
      (acc, { datablockCode }) => ({ ...acc, [datablockCode]: true }),
      {},
    );

    const blocks = dataBlocks
      .filter(({ datablockCode }) => !existingDataBlocks[datablockCode])
      .sort((a, b) => (a.datablockName > b.datablockName ? 1 : -1));

    return blocks.concat(defaultCustomDataBlock);
  }, [formParts, dataBlocks]);

  function checkAndReturnNewName(name: string): string {
    const _name = name;
    for (let i = 0; i < formParts.length; i++) {
      const { formPartName } = formParts[i];
      if (formPartName === name) {
        const indicator = name.match(/\s\(\d\)$/);
        if (indicator && indicator.length) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const newName = _name.replace(/\s\(\d\)$/, ` (${indicator[0].substr(2, 1) * 1 + 1})`);
          return checkAndReturnNewName(newName);
        } else {
          return checkAndReturnNewName(`${_name} (1)`);
        }
      }
    }
    return _name;
  }

  function handleAddSection(): void {
    const lastPositionNumber = formParts
      .map(fp => Number(fp.positionNumber))
      .sort((a, b) => b - a)[0];

    const blocksArray = filteredDataBlocks
      .filter(b => selectedDataBlocks[b.datablockCode])
      .map((b, index) => ({
        ...b,
        formPartName: checkAndReturnNewName(b.datablockName),
        positionNumber: String(lastPositionNumber + index + 1),
        pageNumber: '1',
      }));

    dispatch(addDataBlock.request(blocksArray));
  }

  function handleCancel() {
    dispatch(closeModal());
  }

  const handleSelectDataBlock = (e: CheckboxChangeEvent, datablockCode: string): void => {
    setSelectedDataBlocks({ ...selectedDataBlocks, [datablockCode]: e.target.checked });
  };

  return (
    <ModalComponent
      title="Add Section"
      inProgress={addDataBlocksInProgress}
      buttons={[
        {
          title: 'Cancel',
          onClick: handleCancel,
        },
        {
          type: 'primary',
          disabled: !Object.keys(selectedDataBlocks).length,
          onClick: handleAddSection,
          title: 'Add Section',
        },
      ]}
    >
      <DataBlocksSet
        dataBlocks={filteredDataBlocks}
        selectedDataBlocks={selectedDataBlocks}
        onSelectDataBlock={handleSelectDataBlock}
      />
    </ModalComponent>
  );
};

export default AddDataBlockModal;
