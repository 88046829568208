import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { closeModal } from '@/modules/modals/duck/actions';
import InfoBubble from '@/modules/shared/components/InfoBubble';
import { currentUserData } from '@/modules/user/constants';

import { inviteLeadAdvisor } from '../RostersList/duck/actions';
import { leadAdvisorDesignateInProgressSel } from '../RostersList/duck/selectors';

import styles from './LeadAdvisorInviteModal.less';
import { ModalParams } from './types';

type Props = {
  modalParams: ModalParams;
};

const InviteLeadAdvisorModal: React.FC<Props> = ({ modalParams }) => {
  const dispatch = useDispatch();
  const [selectedRosterCode, setSelectedRosterCode] = useState(null);
  const currentUser = useSelector(createDataSel('arnicaPerson', currentUserData));
  const inProgress = useSelector(leadAdvisorDesignateInProgressSel);

  const { rosterList } = modalParams;

  function handleSubmit() {
    if (!selectedRosterCode) return;
    const roster = rosterList.find(({ rosterCode }) => rosterCode === selectedRosterCode);
    if (!roster) return;
    dispatch(
      inviteLeadAdvisor.request({
        foundPerson: currentUser,
        memberID: currentUser.memberId,
        roster,
      }),
    );
  }

  function handleCancel() {
    dispatch(closeModal());
  }

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedRosterCode(e.target.value);
  };

  const buttons = [
    {
      title: 'SKIP',
      onClick: handleCancel,
    },
    {
      title: 'Confirm',
      disabled: !selectedRosterCode,
      onClick: handleSubmit,
    },
  ];

  const Info = (
    <InfoBubble noMargin placement="rightBottom">
      Each crew's Lead Advisor will help by inviting other attendees to register for their crew, and
      will serve as a point of contact for the specific crew during their stay on site.
    </InfoBubble>
  );

  return (
    <ModalComponent
      title={'Confirm Participation'}
      description={
        <p>
          Will you be participating as the <span className={styles.underline}>Lead Advisor</span>{' '}
          {Info} with a crew in this reservation? If participating, please select a crew below. If
          not participating, you can invite someone else over age 21 on the next screen.
        </p>
      }
      buttons={buttons}
      inProgress={inProgress}
    >
      <Radio.Group
        className={styles.options}
        onChange={handleRadioChange}
        value={selectedRosterCode}
      >
        {rosterList.map(({ rosterExpeditionNumber, rosterCode }) => (
          <Radio key={rosterCode} value={rosterCode}>
            {`Yes, I will be the Lead advisor for ${rosterExpeditionNumber}`}
          </Radio>
        ))}
        <Radio value={null}>
          No, I am coordinating the reservation but I will not be participating.
        </Radio>
      </Radio.Group>
    </ModalComponent>
  );
};

export default InviteLeadAdvisorModal;
