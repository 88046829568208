import { combineEpics, Epic, StateObservable } from 'redux-observable';
import { concat, defer, EMPTY, interval, merge, Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil, takeWhile } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { RegistrantTypes } from '@/modules/data/dataTypes/attendeeTypeList';
import { removeData, resetPagination, scheduleRefresh } from '@/modules/data/duck/actions';
import { prefetchData$ } from '@/modules/data/duck/epics';
import { createDataSel } from '@/modules/data/duck/selectors';
import { checkoutStepEpic$ } from '@/modules/entities/Payments/components/CheckoutStep/duck/epics';
import { PermissionAction } from '@/modules/entities/Roles/constants';
import { SESSION_SORT_ORDER_FIELD } from '@/modules/entities/Sessions/constants';
import { getSessionSortParams } from '@/modules/entities/Sessions/utils';
import { typeSel } from '@/modules/location/duck/selectors';
import { REGISTRATION_TIME_OUT_MODAL } from '@/modules/modals/constants';
import { openModal } from '@/modules/modals/duck/actions';
import { formPartsSel } from '@/modules/questions/duck/selectors';
import { createAbilitiesSelector } from '@/modules/user/duck/abilitiesSelector';
import { logout } from '@/modules/user/duck/actions';
import { personGuidSel as currentUserPersonGuidSel } from '@/modules/user/duck/selectors';
import { sliceTuple } from '@/modules/utils/typeUtils';

import { pageDataParams, ROUTE_FORMS, stepKeys } from '../constants';
import { stepDataParams as addPersonStepDataParams } from '../steps/AddPerson/constants';
import { stepDataParams as formsStepDataParams } from '../steps/FormSubmission/constants';
import { stepDataSel as formsStepDataSel } from '../steps/FormSubmission/duck/selectors';
import personalInformationEpics$ from '../steps/PersonalInformation/duck/epics';
import sessionPriorityEpics$ from '../steps/SessionPriorities/duck/epics';
import { currentFormSessionCodeSel } from '../steps/SessionPriorities/duck/selectors';

import {
  disableStep,
  enableStep,
  initRegistrationTimer,
  openNextStep,
  openPrevStep,
  openStepByIndex,
  openStepByKey,
  openStepCompleted,
  repeatOpenStep,
  setRegistrationTimeLeft,
  stopRegistrationTimer,
} from './actions';
import {
  disabledStepsSel,
  futureStepKeySel,
  formRecordGuidSel,
  currentGroupReservationGUIDSel,
  stepsSel,
  pageDataSel,
} from './selectors';

class SkipStepError extends Error {
  skipStepError: true;

  constructor(message: string) {
    super(message);
    this.skipStepError = true;
  }
}

const stepsGetObservables: Record<
  number,
  (
    action: Observable<RootAction>,
    state: StateObservable<RootState>,
    guids:
      | {
          formRecordGUID: string;
        }
      | { groupReservationGUID: string },
  ) => Observable<RootAction>
> = {
  [stepKeys.personalInformation]: (action$, state$, guids) => {
    const state = state$.value;
    const personGUID = currentUserPersonGuidSel(state);

    return concat(
      'groupReservationGUID' in guids && guids.groupReservationGUID
        ? EMPTY
        : of(removeData({ dataType: 'groupReservation' })),
      of(removeData({ dataType: 'payments' })),
      of(removeData({ dataType: 'paymentSummary' })),
      prefetchData$(
        action$,
        personGUID
          ? {
              dataType: 'arnicaPerson',
              queryObj: { personGUID },
            }
          : null,
        { dataType: 'countries' },
        { dataType: 'states' },
        { dataType: 'councilList' },
      ),
    );
  },
  [stepKeys.attendeeTypes]: (action$, state$) => {
    const state = state$.value;
    const { formCode } = createDataSel('form')(state);
    const filterByRegistrantType = !createAbilitiesSelector(
      'attendeeDetails',
      'updateAttendeeType',
      null,
    )(state);
    const personGUID = currentUserPersonGuidSel(state);

    return prefetchData$(action$, {
      dataType: 'attendeeTypesForPersonList',
      queryObj: {
        formCode,
        ...(filterByRegistrantType ? { personGUID, registrantType: RegistrantTypes.Primary } : {}),
      },
      fetchData: true,
    });
  },
  [stepKeys.sessionPriorities]: (action$, state$) => {
    const {
      data: { form, attendeeDetails },
    } = pageDataSel(state$.value);
    const {
      formCode,
      allowGroupRegistration,
      sessionsListSortType = '',
      isWaitlistEnabled,
      hidePastSessions,
    } = form;
    const canSeeAllSessions = createAbilitiesSelector(
      'attendeeSelections',
      PermissionAction.Update,
      null,
    )(state$.value);

    return merge(
      of(stopRegistrationTimer()),
      prefetchData$(
        action$,
        { dataType: 'programs', queryObj: { formCode } },
        {
          dataType: 'sessionList',
          queryObj: {
            formCode,
            includeAllFields: false,
            isIncludeAddons: false,
            isIncludeActivities: false,
            hideFullSessions: !isWaitlistEnabled,
            [SESSION_SORT_ORDER_FIELD]: getSessionSortParams(sessionsListSortType),
            hidePastSessions: canSeeAllSessions ? false : hidePastSessions,
            ...(allowGroupRegistration ? {} : { typeCode: attendeeDetails.typeCode }),
            ...(canSeeAllSessions ? {} : { includeHiddenSessions: false }),
          },
        },
      ),
    );
  },
  [stepKeys.addPerson]: (action$, state$, guids) => {
    if (!('formRecordGUID' in guids)) return EMPTY;

    const state = state$.value;
    const { formRecordGUID } = guids;
    const { formCode } = createDataSel('form')(state);
    const sessionCode = currentFormSessionCodeSel(state);
    const attendeeDetails = createDataSel('attendeeDetails')(state);

    return prefetchData$(
      action$,
      ...addPersonStepDataParams.map(params => {
        switch (params.dataType) {
          case 'attendeeList':
            return {
              ...params,
              queryObj: {
                formCode,
                primaryRegistrantFormRecordGUID: formRecordGUID,
                isPrimaryRegistrant: false,
              },
            };
          case 'addons':
            return { ...params, queryObj: { formCode, sessionCode } };
          case 'activities':
            return {
              ...params,
              queryObj: {
                formCode,
                sessionCode,
                typeCode: attendeeDetails.typeCode,
                ...guids,
              },
            };
          case 'countries':
          case 'states':
            return params;
        }
      }),
    );
  },
  [stepKeys.addons]: (action$, state$, guids) => {
    const state = state$.value;
    const { formCode } = createDataSel('form')(state);
    const sessionCode = currentFormSessionCodeSel(state);
    const personGUID = currentUserPersonGuidSel(state);

    return concat(
      prefetchData$(
        action$,
        { dataType: 'addons', queryObj: { formCode, sessionCode } },
        { dataType: 'arnicaPerson', queryObj: { personGUID } },
      ),
      defer(() => {
        const addons = createDataSel('addons')(state$.value);
        const { state: usaState } = createDataSel('arnicaPerson')(state$.value);

        const filteredAddons = addons.filter(
          ({ forbiddenStateList }) => !forbiddenStateList.includes(usaState),
        );

        if (!filteredAddons || !filteredAddons.length) {
          throw new SkipStepError('No Add-Ons available');
        }

        return merge(
          of(enableStep(stepKeys.addons)),
          prefetchData$(
            action$,
            {
              dataType: 'paymentSummary',
              queryObj: {
                formCode,
                ...guids,
              },
              fetchData: true,
            },
            'formRecordGUID' in guids
              ? {
                  dataType: 'formRecordAddons',
                  queryObj: { formCode, formRecordGUID: guids.formRecordGUID },
                  fetchData: true,
                }
              : {
                  dataType: 'groupReservationAddons',
                  queryObj: { formCode, groupReservationGUID: guids.groupReservationGUID },
                  fetchData: true,
                },
            { dataType: 'paymentCategories', queryObj: { formCode } },
          ),
        );
      }),
    );
  },
  [stepKeys.activities]: (action$, state$, guids) => {
    const state = state$.value;
    const { formCode } = createDataSel('form')(state);
    const sessionCode = currentFormSessionCodeSel(state);

    const isIndividualEvent = 'formRecordGUID' in guids;

    const attendeeDetails = isIndividualEvent
      ? createDataSel('attendeeDetails')(state$.value)
      : undefined;

    return concat(
      prefetchData$(action$, {
        dataType: 'activities',
        queryObj: {
          formCode,
          sessionCode,
          typeCode: attendeeDetails?.typeCode,
          ...guids,
        },
      }),
      defer(() => {
        const activities = createDataSel('activities')(state$.value);
        if (!activities.length) {
          throw new SkipStepError('No Activities Available');
        }

        return merge(
          of(enableStep(stepKeys.activities)),
          prefetchData$(
            action$,
            {
              dataType: 'paymentSummary',
              queryObj: { formCode, ...guids },
              fetchData: true,
            },
            { dataType: 'paymentCategories', queryObj: { formCode } },

            'formRecordGUID' in guids
              ? {
                  dataType: 'formRecordActivities',
                  queryObj: { formCode, formRecordGUID: guids.formRecordGUID },
                  fetchData: true,
                }
              : {
                  dataType: 'groupReservationActivities',
                  queryObj: { formCode, groupReservationGUID: guids.groupReservationGUID },
                  fetchData: true,
                },
          ),
        );
      }),
    );
  },
  [stepKeys.jobPriorities]: (action$, state$, guids) => {
    const state = state$.value;
    const { formCode } = createDataSel('form')(state);

    if (!('formRecordGUID' in guids)) return EMPTY;

    const { formRecordGUID } = guids;

    return concat(
      prefetchData$(action$, {
        dataType: 'attendeeTypesForPersonList',
        queryObj: { formCode },
      }),
      defer(() => {
        const { typeCode: selectedAttendeeType } = createDataSel('attendeeDetails')(state$.value);
        const attendeeTypesList = createDataSel('attendeeTypesForPersonList')(state$.value);
        const attendeeType = attendeeTypesList.find(
          ({ typeCode }) => selectedAttendeeType === typeCode,
        );

        if (!attendeeType?.isStaff) {
          throw new SkipStepError('Selected attendee type is not staff');
        }

        return prefetchData$(action$, {
          dataType: 'formRecordJobs',
          queryObj: { formCode, formRecordGUID },
        });
      }),
    );
  },
  [stepKeys.formSubmission]: (action$, state$) => {
    const {
      data: { attendeeDetails },
    } = pageDataSel(state$.value);

    const { formParts, formCode, formRecordGUID } = attendeeDetails;

    const formsStepDataParams1 = sliceTuple(formsStepDataParams, 0, 1);
    const formsStepDataParams2 = sliceTuple(formsStepDataParams, 1, formsStepDataParams.length);

    return concat(
      prefetchData$(
        action$,
        ...formsStepDataParams1.map(params => {
          switch (params.dataType) {
            case 'visibilitySettings':
              return { ...params, queryObj: { formCode } };
          }
        }),
      ),
      defer(() => {
        const {
          data: { visibilitySettings },
        } = formsStepDataSel(state$.value);

        const formPartsToFill = formPartsSel(state$.value, {
          attendeeDetails,
          removeSystemFormParts: true,
          formParts,
          visibilitySettings,
        });

        if (!formPartsToFill.length) {
          throw new SkipStepError('No form required to fill');
        }

        return merge(
          of(enableStep(stepKeys.formSubmission)),
          prefetchData$(
            action$,
            ...formsStepDataParams2.map(params => {
              switch (params.dataType) {
                case 'optionSets':
                  return { ...params, queryObj: { formCode, formRecordGUID } };
              }
            }),
          ),
        );
      }),
    );
  },
  [stepKeys.checkout]: (action$, state$, guids) => {
    const sessionCode = currentFormSessionCodeSel(state$.value);
    const { formCode } = createDataSel('form')(state$.value);

    return concat(
      prefetchData$(action$, {
        dataType: 'paymentSummary',
        queryObj: { formCode, ...guids },
        fetchData: true,
      }),
      defer(() => {
        const paymentSummary = createDataSel('paymentSummary')(state$.value);
        return checkoutStepEpic$(action$, state$, guids, {
          sessionCode,
          finalActions: [openNextStep(), stopRegistrationTimer()],
          paymentSummary,
        });
      }),
    );
  },
  [stepKeys.confirmation]: () =>
    of(
      resetPagination({ dataType: 'sessionList', fetchData: false }),
      scheduleRefresh({ dataType: 'groupReservation' }),
      scheduleRefresh({ dataType: 'groupReservationActivities' }),
      scheduleRefresh({ dataType: 'groupReservationAddons' }),
      scheduleRefresh({ dataType: 'sessionList' }),
      scheduleRefresh({ dataType: 'paymentCategories' }),
    ),
};

const openStep$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([openPrevStep, openNextStep, openStepByIndex, repeatOpenStep])),
    switchMap(action => {
      const state = state$.value;
      const { formCode, allowGroupRegistration } = createDataSel('form')(state);
      const disabledSteps = disabledStepsSel(state);
      const futureStepKey = futureStepKeySel(state);
      const stepIsDisabled = !!disabledSteps[futureStepKey];

      const formRecordGUID = formRecordGuidSel(state);
      const groupReservationGUID = currentGroupReservationGUIDSel(state);

      if (stepIsDisabled) return of(action);

      const getStepObservable = stepsGetObservables[futureStepKey];

      if (!getStepObservable) {
        return of(openStepCompleted());
      }

      return concat(
        prefetchData$(
          action$,
          ...pageDataParams.map(params => {
            switch (params.dataType) {
              case 'form':
              case 'jobList':
                return { ...params, queryObj: { formCode } };
              case 'reservationListReport':
                if (!allowGroupRegistration) return null;
                return { ...params, queryObj: { formCode } };
              case 'attendeeDetails':
                if (!formRecordGUID) return null;
                return { ...params, queryObj: { formCode, formRecordGUID } };
              case 'groupReservation':
                if (!groupReservationGUID) return null;
                return { ...params, queryObj: { formCode, groupReservationGUID } };
            }
          }),
        ),
        defer(() =>
          getStepObservable(
            action$,
            state$,
            allowGroupRegistration
              ? { groupReservationGUID: groupReservationGUID as string }
              : { formRecordGUID: formRecordGUID as string },
          ),
        ),
        of(openStepCompleted()),
      );
    }),
    catchError((error: SkipStepError | Error, caught) => {
      if ('skipStepError' in error) {
        const { message } = error;
        const futureStepKey = futureStepKeySel(state$.value);

        return merge(
          caught,
          of(disableStep({ stepKey: futureStepKey, reason: message }), repeatOpenStep()),
        );
      }
      return caught;
    }),
  );

const openStepByKey$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(openStepByKey)),
    switchMap(({ payload }) => {
      const steps = stepsSel(state$.value);
      const stepIndex = steps.findIndex(({ key }) => key === payload);
      return of(openStepByIndex(stepIndex));
    }),
  );

const initRegistrationTimer$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(initRegistrationTimer)),
    switchMap(() => {
      const state = state$.value;
      const { allowGroupRegistration, registrationCompletionTimeLimitSeconds } =
        createDataSel('form')(state);

      return interval(1000).pipe(
        switchMap(timePassed => {
          const timeLeft = registrationCompletionTimeLimitSeconds - timePassed;
          if (timeLeft > 0) {
            return of(setRegistrationTimeLeft(timeLeft));
          }
          return of(
            openModal(REGISTRATION_TIME_OUT_MODAL),
            stopRegistrationTimer(),
            scheduleRefresh({
              dataType: allowGroupRegistration ? 'groupReservation' : 'attendeeDetails',
            }),
          );
        }),
        takeWhile(() => {
          const currentRoute = typeSel(state$.value);
          return currentRoute === ROUTE_FORMS;
        }),
        takeUntil(
          action$.pipe(filter(isActionOf([stopRegistrationTimer, logout.request])), take(1)),
        ),
      );
    }),
  );

export default combineEpics(
  openStep$,
  openStepByKey$,
  initRegistrationTimer$,
  personalInformationEpics$,
  sessionPriorityEpics$,
);
