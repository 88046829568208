import cn from 'classnames';
import React from 'react';

import styles from '../../Priority.less';

type Props = {
  text: string;
  value: string | number;
  wrapperClassName?: string;
};

const FooterItem: React.FC<Props> = ({ text, value, wrapperClassName }) => (
  <div className={cn(styles.rowItem, wrapperClassName)}>
    <b>{text}:&nbsp;</b>
    <span>{value}</span>
  </div>
);

export default FooterItem;
