import { Badge } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NotificationList from '../NotificationList';

import styles from './NotificationIcon.less';
import { HasNotificationsIcon, NoNotificationsIcon, Popover } from './NotificationIcon.styled';
import NoticePopover from './components/NoticePopover';

import { clearNotice } from 'NOTIFICATIONS/duck/actions';
import { unreadNotificationsCountSel } from 'NOTIFICATIONS/duck/selectors';

const NotificationIcon: React.FC = () => {
  const dispatch = useDispatch();
  const unreadCount = useSelector(unreadNotificationsCountSel);

  const handleNoticeVisible = (isVisible: boolean) => {
    if (isVisible) {
      dispatch(clearNotice());
    }
  };

  return (
    <NoticePopover>
      <Popover
        placement="bottomRight"
        trigger="click"
        content={<NotificationList />}
        overlayClassName={styles.popover}
        onOpenChange={handleNoticeVisible}
        arrow
      >
        <Badge count={unreadCount}>
          {unreadCount > 0 ? <HasNotificationsIcon /> : <NoNotificationsIcon />}
        </Badge>
      </Popover>
    </NoticePopover>
  );
};

export default NotificationIcon;
