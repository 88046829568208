import { toUpper } from 'lodash';

import type { Payment } from '@/modules/data/dataTypes/paymentList';

import { PaymentMethod, paymentMethodsData } from './constants';

// need this function because paymentType can be stored in arbitrary case
export const paymentMethodsIncludes = (
  existingMethods: PaymentMethod[],
  questionableMethod?: string | null,
): boolean => {
  if (!questionableMethod) return false;
  return existingMethods.map(toUpper).includes(questionableMethod.toUpperCase());
};

export const getPaymentMethodLabel = (payment: Pick<Payment, 'paymentType'>): string => {
  const method: PaymentMethod = payment?.paymentType;

  const paymentMethod =
    Object.values(PaymentMethod).find(foundMethod =>
      paymentMethodsIncludes([foundMethod], method),
    ) || method;

  return paymentMethodsData.get(paymentMethod)?.label || '';
};
