import { isNumber } from 'lodash';
import React, { Fragment } from 'react';

import Currency from '@/modules/shared/components/Currency';
import { EnhancedPaymentCategory } from '@/modules/shared/components/ReservationPaymentsCard/ReservationPaymentsCard';

const NameColumn: React.FC<EnhancedPaymentCategory> = ({
  paymentCategoryName,
  paymentCategoryAmount,
}) => (
  <Fragment>
    {paymentCategoryName}
    {isNumber(paymentCategoryAmount) && (
      <Fragment>
        {': '}
        <Currency value={paymentCategoryAmount} />
      </Fragment>
    )}
  </Fragment>
);

export default NameColumn;
