import { CheckOutlined } from '@ant-design/icons';
import React from 'react';

import { FontSize } from '@/modules/richTextEditor/constants';

import { Button } from './SizeItem.styled';

type Props = {
  title: 'Normal' | 'Big' | 'Very Big';
  fontSize: FontSize | null;
  activeFontSize: FontSize;
  onClick: (fontSize: FontSize | null) => void;
};

const SizeItem: React.FC<Props> = ({ title, onClick, activeFontSize, fontSize }) => {
  const isActive = fontSize === activeFontSize;

  const handleClick = () => {
    if (isActive) return null;
    onClick(fontSize);
  };

  return (
    <Button
      $isActive={isActive}
      style={{
        fontSize: fontSize || undefined,
      }}
      onClick={handleClick}
      icon={<CheckOutlined />}
    >
      {title}
    </Button>
  );
};

export default SizeItem;
