import React from 'react';

import styles from './Cover.less';

interface Props {
  image: string;
}

const Cover: React.FC<Props> = ({ image }) => (
  <div className={styles.eventCover}>
    <img alt="example" src={image} />
  </div>
);

export default Cover;
