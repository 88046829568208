import React from 'react';

import { RosterDescriptionContainer } from './RosterDescription.styled';

type Props = {
  description: string;
};

const RosterDescription: React.FC<Props> = ({ description }) => {
  if (!description || description.toLowerCase().includes('automatic')) return null;
  return <RosterDescriptionContainer>{description}</RosterDescriptionContainer>;
};

export default RosterDescription;
