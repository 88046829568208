import React, { useMemo } from 'react';

import { getApiPrefix } from '@/modules/utils/apiService';

type Props = {
  value: string;
};

const FileLink: React.FC<Props> = ({ value }) => {
  const [code, name] = value.split('/');

  const link = useMemo(() => {
    if (!code) return null;
    const mainUrl =
      '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=AWSGetFile';
    const apiPrefix = getApiPrefix(mainUrl);
    return `${apiPrefix}${mainUrl}&itemCode=${code}`;
  }, [code]);

  if (!link) return null;

  const fileName = name || code;

  return (
    <a href={link} download={fileName}>
      {fileName}
    </a>
  );
};

export default FileLink;
