import { InfoCircleFilled } from '@ant-design/icons';
import styled, { css } from 'styled-components';

import colors from '@/modules/styles/colors';

const noMarginRule = css`
  margin: 0;
`;

export const Icon = styled(InfoCircleFilled)<{ $isDanger?: boolean; $noMargin?: boolean }>`
  color: ${props => (props.$isDanger ? colors.redStatus : colors.lightBlue)};
  margin-left: 1rem;
  ${props => props.$noMargin && noMarginRule}
`;
