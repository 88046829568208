import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';

import { FormRecordActivity } from '@/modules/data/dataTypes/formRecordActivities';
import { scheduleRefresh } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import ActivitiesSelector from '@/modules/entities/Activities/components/ActivitiesSelector';

import { SECONDARY_REGISTRANT_DATA_ID } from '../../constants';
import { openNextStep } from '../../duck/actions';
import { secondaryRegistrantFormRecordGuidSel } from '../../duck/selectors';

const ActivitiesStep = forwardRef<{ handleSubmit: () => void }>((_, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const activitiesRef = useRef<{ handleSubmit: () => void }>(null);
  const formRecordGUID = useSelector(secondaryRegistrantFormRecordGuidSel);
  const activities = useSelector(createDataSel('activities'));
  const selectedActivities: FormRecordActivity[] = useSelector(
    createDataSel('formRecordActivities'),
  );

  function handleSubmit() {
    activitiesRef.current?.handleSubmit();
  }

  return (
    <ActivitiesSelector
      ref={activitiesRef}
      finalActions={[
        openNextStep(),
        scheduleRefresh({ dataType: 'paymentSummary', dataId: SECONDARY_REGISTRANT_DATA_ID }),
      ]}
      formRecordGUID={formRecordGUID as string}
      availableActivities={activities}
      selectedActivities={selectedActivities}
      isGroup={false}
    />
  );
});

export default ActivitiesStep;
