import dayjs from 'dayjs';
import { combineEpics, Epic } from 'redux-observable';
import { merge, of, race } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData, removeData, scheduleRefresh } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import { arnicaServiceDefinitions } from '@/modules/data/duck/services';
import { CONFIRM_MODAL } from '@/modules/modals/constants';
import { closeModal, openModal, updateModalParams } from '@/modules/modals/duck/actions';
import { getReportDataId } from '@/modules/reports/utils';
import { cancelAction, confirmAction } from '@/modules/shared/components/ConfirmModal/duck/actions';
import { ModalParams as ConfirmModalParams } from '@/modules/shared/components/ConfirmModal/types';
import toastService from '@/modules/toasts/service';
import { ApiError, createParamsFromObject } from '@/modules/utils/apiService';
import { utcDateToString } from '@/modules/utils/dateFormats';

import { CustomTemplateApiNames } from '@/pages/event/tabs/Emails/components/EmailTemplates/constants';

import { deleteEmailTemplate, sendCustomEmail, sendEmail, updateEmailTemplate } from './actions';
import services from './services';

const sendEmailEpic$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sendEmail.request)),
    switchMap(({ payload }) => {
      const { finalActions = [], ...payloadRest } = payload;

      const query = createParamsFromObject(payloadRest);

      return services.sendEmail$(query).pipe(
        switchMap(() => {
          toastService.success('Email sent');
          return of(sendEmail.success(), ...finalActions);
        }),
        catchError((err: ApiError) => {
          toastService.error('Something went wrong. Please try again');
          return of(sendEmail.failure(err));
        }),
      );
    }),
  );

const sendCustomEmail$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sendCustomEmail.request)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      const { template, mailing, dataType } = payload;
      const apiName = arnicaServiceDefinitions[dataType]?.scriptCode as CustomTemplateApiNames;
      const TheObservable =
        'emailTemplateCode' in template
          ? of(template)
          : services
              .updateEmailTemplate$({ formCode, isAdHoc: true, apiName, ...template })
              .pipe(map(({ responseValue }) => ({ emailTemplateCode: responseValue as string })));
      return TheObservable.pipe(
        switchMap(({ emailTemplateCode }) => {
          const processingStartDate =
            mailing.processingStartDate && utcDateToString(mailing.processingStartDate);

          return services.updateMailing$({
            ...mailing,
            processingStartDate,
            formCode,
            emailTemplateCode,
          });
        }),
        switchMap(({ responseValue }) =>
          services.startMailingProcessing$(formCode, responseValue as string),
        ),
        switchMap(() => {
          const { processingStartDate } = mailing;
          const sendTime =
            processingStartDate && dayjs.duration(processingStartDate.diff(dayjs())).humanize();
          toastService.success(
            `Email successfully saved and will be sent ${
              sendTime ? `in ${sendTime}` : 'within 10 minutes'
            }`,
          );
          return of(
            sendCustomEmail.success(),
            closeModal(),
            scheduleRefresh({ dataType: 'mailingList' }),
          );
        }),
      );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(sendCustomEmail.failure(error)), caught);
    }),
  );

export const updateEmailTemplate$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(updateEmailTemplate.request)),
    switchMap(({ payload }) =>
      services.updateEmailTemplate$(payload).pipe(
        switchMap(() => {
          toastService.success('Template updated correctly.');
          const dataId: ReturnType<typeof getReportDataId> =
            payload.apiName === CustomTemplateApiNames.Attendee ? 'attendees' : 'reservations';
          return of(
            closeModal(),
            updateEmailTemplate.success(),
            refreshData({ dataType: 'emailTemplateList' }),
            scheduleRefresh({
              dataType: 'emailTemplateList',
              dataId,
            }),
          );
        }),
        catchError((err: ApiError) => {
          toastService.error(err.message);
          return of(updateEmailTemplate.failure(err));
        }),
      ),
    ),
  );

const deleteEmailTemplate$: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(deleteEmailTemplate)),
    switchMap(({ payload }) => {
      const { emailTemplateApiName, emailTemplateCode, formCode } = payload;
      const confirmModalParams: ConfirmModalParams = {
        confirmButton: {
          title: 'Yes, delete',
        },
        description: 'Are you sure you want to delete this custom email template?',
        title: 'Delete custom email template?',
      };

      return merge(
        race(
          action$.pipe(
            filter(isActionOf(confirmAction)),
            take(1),
            switchMap(() => {
              const nextConfirmModalParams: ConfirmModalParams = {
                ...confirmModalParams,
                inProgress: true,
              };

              return merge(
                of(updateModalParams(nextConfirmModalParams)),
                services.deleteEmailTemplate({ emailTemplateCode, formCode }).pipe(
                  switchMap(() => {
                    const dataId: ReturnType<typeof getReportDataId> =
                      emailTemplateApiName === CustomTemplateApiNames.Attendee
                        ? 'attendees'
                        : 'reservations';

                    toastService.success('Custom email template deleted successfully');

                    return of(
                      refreshData({ dataType: 'emailTemplateList' }),
                      scheduleRefresh({
                        dataType: 'emailTemplateList',
                        dataId,
                      }),
                      removeData({ dataType: 'emailTemplate' }),
                      closeModal(),
                    );
                  }),
                ),
              );
            }),
          ),
          action$.pipe(
            filter(isActionOf(cancelAction)),
            take(1),
            switchMap(() => of(closeModal())),
          ),
        ),
        of(openModal(CONFIRM_MODAL, confirmModalParams)),
      );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(closeModal()), caught);
    }),
  );

export default combineEpics(
  sendEmailEpic$,
  sendCustomEmail$,
  updateEmailTemplate$,
  deleteEmailTemplate$,
);
