import { combineEpics, Epic } from 'redux-observable';
import { concat, defer, merge, of, race } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';

import { refreshData } from '@/modules/data/duck/actions';
import { createDataSel } from '@/modules/data/duck/selectors';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import {
  deleteActivity,
  updateActivity,
  updateActivityAttendeeTypes,
  updateActivityClosed,
} from './actions';
import services from './services';

const updateActivity$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateActivity.request)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);
      const { attendeeTypesList = [], ...values } = payload;

      return services.updateActivity$({ ...values, formCode }).pipe(
        switchMap(({ responseValue }) => {
          const attendeeTypes = createDataSel('attendeeTypeList')(state);

          const updateAttendeeTypesPayload = {
            activityCode: payload.activityCode || (responseValue as string),
            attendeeTypes: [
              ...attendeeTypes.map(attendeeType => ({
                isIncluded: attendeeTypesList.includes(attendeeType.typeCode),
                typeCode: attendeeType.typeCode,
              })),
            ],
          };

          return concat(
            merge(
              race(
                action$.pipe(filter(isActionOf(updateActivityAttendeeTypes.success)), take(1)),
                action$.pipe(
                  filter(isActionOf(updateActivityAttendeeTypes.failure)),
                  take(1),
                  map(({ payload: { message } }) => {
                    throw new Error(message);
                  }),
                ),
              ),
              of(updateActivityAttendeeTypes.request(updateAttendeeTypesPayload)),
            ),
            defer(() => {
              toastService.success(
                `Activity ${payload.activityCode ? 'updated' : 'created'} correctly`,
              );

              return of(
                refreshData({ dataType: 'activities' }),
                updateActivity.success(),
                updateActivityClosed(),
              );
            }),
          );
        }),
      );
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(updateActivity.failure(error), updateActivityClosed()), caught);
    }),
  );

const updateActivityAttendeeTypes$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateActivityAttendeeTypes.request)),
    switchMap(({ payload }) => {
      const { formCode } = createDataSel('form')(state$.value);
      return services
        .updateActivityAttendeeTypes$({
          ...payload,
          attendeeTypes: payload.attendeeTypes || [],
          formCode,
        })
        .pipe(map(() => updateActivityAttendeeTypes.success()));
    }),
    catchError((error: ApiError, caught) => {
      toastService.error(error.message);
      return merge(of(updateActivity.failure(error)), caught);
    }),
  );

const deleteActivity$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteActivity.request)),
    switchMap(({ payload: activityCode }) => {
      const state = state$.value;
      const { formCode } = createDataSel('form')(state);

      return services.deleteActivity$({ formCode, activityCode }).pipe(
        switchMap(() => {
          toastService.success('Activity deleted successfully');
          return of(deleteActivity.success(), refreshData({ dataType: 'activities' }));
        }),
        catchError((error: ApiError) => {
          toastService.error(error.message);
          return of(deleteActivity.failure(error));
        }),
      );
    }),
  );

export default combineEpics(deleteActivity$, updateActivity$, updateActivityAttendeeTypes$);
