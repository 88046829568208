import { Checkbox, Col, Input, Radio, Row, Select, Switch } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SwitchSize } from 'antd/lib/switch';
import React from 'react';

import { FormSettings } from '@/modules/data/dataTypes/form';
import FileUpload from '@/modules/shared/components/FileUpload';
import InputPhone from '@/modules/shared/components/InputPhone';
import { PresentationType } from '@/modules/shared/constants';

import { DatePicker } from './InputSelector.styled';
import WithOtherOption from './components/WithOtherOption';

type Option = {
  value: string;
  label: string;
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  presentationType: PresentationType;
  disabled?: boolean;
  size?: SizeType;
  options?: Option[];
  placeholder?: string;
  formPartCode: string;
  formItemCode: string;
  formSettings: FormSettings;
  showOtherOption?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
}

const InputSelector: React.FC<Props> = ({
  presentationType,
  formPartCode,
  formItemCode,
  formSettings,
  showOtherOption,
  ...rest
}) => {
  const handleFilterOption = (input: string, option?: Option) =>
    !!(option?.label as string).toLowerCase().includes(input.toLowerCase());

  switch (presentationType) {
    case PresentationType.DROPDOWN:
    case PresentationType.DROPDOWN_BULK:
      return (
        <WithOtherOption {...rest} showOtherOption={!!showOtherOption}>
          {propsWithOther => (
            <Select {...propsWithOther} showSearch filterOption={handleFilterOption} />
          )}
        </WithOtherOption>
      );
    case PresentationType.CHECKBOX:
      return (
        <WithOtherOption {...rest} showOtherOption={!!showOtherOption}>
          {({ options, ...propsWithOther }) => (
            <Checkbox.Group {...propsWithOther}>
              <Row gutter={[16, 16]}>
                {options?.map(({ value, label }) => (
                  <Col key={value}>
                    <Checkbox value={value}>{label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          )}
        </WithOtherOption>
      );
    case PresentationType.SWITCH:
      return <Switch {...rest} size={rest.size as SwitchSize} />;
    case PresentationType.DATE_PICKER:
      return <DatePicker {...rest} />;
    case PresentationType.TEXTAREA:
      return <Input.TextArea {...rest} />;
    case PresentationType.FILE_UPLOAD:
      return (
        <FileUpload
          {...rest}
          formItemCode={formItemCode}
          formPartCode={formPartCode}
          formSettings={formSettings}
        />
      );
    case PresentationType.RADIO:
      return (
        <WithOtherOption {...rest} showOtherOption={!!showOtherOption}>
          {({ options, ...propsWithOther }) => (
            <Radio.Group {...propsWithOther}>
              <Row>
                {options?.map(({ value, label }) => (
                  <Col key={value} xs="4">
                    <Radio value={value}>{label}</Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          )}
        </WithOtherOption>
      );
    case PresentationType.DATE_RANGE_PICKER: {
      const { placeholder, ...rangeProps } = rest;
      return <DatePicker.RangePicker {...rangeProps} />;
    }
    case PresentationType.PHONE:
    case PresentationType.INTERNATIONAL_PHONE:
      return <InputPhone {...rest} />;
    case PresentationType.TEXT:
    case PresentationType.DESCRIPTION:
    case PresentationType.EMAIL:
    default:
      return <Input {...rest} />;
  }
};

export default InputSelector;
