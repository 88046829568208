import React from 'react';

import { Session } from '@/modules/data/dataTypes/sessionList';
import { formattedDatesRange } from '@/modules/utils/dateFormats';

import {
  InfoContainer,
  InfoLabelContainer,
  SessionLabel,
  SessionLabelValue,
} from './SessionInformation.styled';

interface Props {
  session: Session;
  isGroupEvent?: boolean;
}

const SessionInformation: React.FC<Props> = ({ session, isGroupEvent }) => {
  const {
    attendeeCapacity,
    closeDate,
    maximumRosterCount = 0,
    sessionName,
    startDate,
    registeredPersonCount = 0,
    totalInitialPeopleCount = 0,
    totalInitialRosterCount = 0,
  } = session;

  const personsCount = isGroupEvent ? totalInitialPeopleCount : registeredPersonCount;

  return (
    <InfoContainer>
      <InfoLabelContainer>
        <SessionLabel>Session Selected: </SessionLabel>
        <SessionLabelValue>{sessionName}</SessionLabelValue>
      </InfoLabelContainer>
      <InfoLabelContainer>
        <SessionLabel>Session Dates:</SessionLabel>
        <SessionLabelValue>{formattedDatesRange(startDate, closeDate)}</SessionLabelValue>
      </InfoLabelContainer>
      <InfoLabelContainer>
        <SessionLabel>{`Persons ${isGroupEvent ? 'Booked' : 'Registered'}:`}</SessionLabel>
        <SessionLabelValue>{`${personsCount}/${attendeeCapacity}`}</SessionLabelValue>
      </InfoLabelContainer>
      {isGroupEvent && (
        <InfoLabelContainer>
          <SessionLabel>Crews Booked:</SessionLabel>
          <SessionLabelValue>{`${totalInitialRosterCount}/${maximumRosterCount}`}</SessionLabelValue>
        </InfoLabelContainer>
      )}
    </InfoContainer>
  );
};

export default SessionInformation;
