import { Col, Form, FormItemProps } from 'antd';
import React from 'react';

import { Person } from '@/modules/data/dataTypes/person';

interface Props extends FormItemProps {
  name: keyof Person;
}

const PersonDataFormItem: React.FC<Props> = props => (
  <Col xs={24} sm={12} md={8}>
    <Form.Item {...props} />
  </Col>
);

export default PersonDataFormItem;
