import cn from 'classnames';
import React from 'react';

import styles from './L.less';

interface Props extends Omit<React.HTMLProps<HTMLLabelElement>, 'size'> {
  size?: '1' | '2' | '3' | '4' | '5' | '6';
  hr?: boolean;
  semibold?: boolean;
  bold?: boolean;
  colored?: boolean;
  colon?: boolean;
  inline?: boolean;
  inlineBlock?: boolean;
  serif?: boolean;
  ellipsis?: boolean;
  className?: string;
  children: React.ReactNode;
  forInput?: string;
}

const L: React.FC<Props> = ({
  size,
  hr,
  semibold,
  bold,
  colored,
  colon,
  inline,
  inlineBlock,
  serif,
  className,
  children,
  forInput,
  ellipsis,
  ...htmlLabelProps
}) => {
  const sizeClassName = `subtitle-${size}`;
  const classNames = cn(
    sizeClassName,
    styles.block,
    styles.label,
    {
      'text-primary': colored,
      [styles.hr]: hr,
      [styles.semibold]: semibold,
      [styles.bold]: bold,
      [styles.inline]: inline,
      [styles.inlineBlock]: inlineBlock,
      [styles.serif]: serif,
      [styles.ellipsis]: ellipsis,
    },
    className,
  );

  return (
    <label htmlFor={forInput} className={classNames} {...htmlLabelProps}>
      {children}
      {colon && ':'}
    </label>
  );
};

L.defaultProps = {
  hr: false,
  semibold: false,
  bold: false,
  colored: false,
  colon: false,
  inline: false,
  inlineBlock: false,
  serif: false,
  ellipsis: false,
};

export default L;
