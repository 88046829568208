import { Button } from 'antd';
import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const CellContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const PrintButton = styled(Button)`
  &.ant-btn {
    box-shadow: none;
  }

  &&& {
    color: ${colors.lightBlueStatus};
  }
`;
