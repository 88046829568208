import { CloseOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Row, Col, Form, FormListFieldData, Input } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { Dayjs } from 'dayjs';
import { cloneDeep, isEmpty, last } from 'lodash';
import { FieldData } from 'rc-field-form/es/interface';
import React, { useMemo, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createDataSel } from '@/modules/data/duck/selectors';
import ModalComponent from '@/modules/modals/components/ModalComponent';
import { dateFromString, dateToString, usDate } from '@/modules/utils/dateFormats';

import { cloneSession } from '../../duck/actions';
import { cloneSessionInProgressSel } from '../../duck/selectors';

import { Button, DatePicker } from './CloneSessionModal.styled';
import { ModalParams } from './types';

const SESSIONS_FIELD = 'sessions';
const DATE_FIELD = 'startDate';
const NAME_FIELD = 'sessionName';

type SessionsValue = {
  sessions: {
    [NAME_FIELD]: string;
    [DATE_FIELD]: Dayjs;
  }[];
};

type Props = {
  modalParams: ModalParams;
};

const CloneSessionModal: React.FC<Props> = ({ modalParams: { sessionCode } }) => {
  const [form] = Form.useForm<SessionsValue>();

  const dispatch = useDispatch();
  const inProgress = useSelector(cloneSessionInProgressSel);

  const sessionList = useSelector(createDataSel('sessionList'));
  const currentSession = useMemo(
    () => sessionList.find(s => s.sessionCode === sessionCode),
    [sessionCode, sessionList],
  );

  const currentSessionStartDate = dateFromString(currentSession?.startDate);
  const currentSessionCloseDate = dateFromString(currentSession?.closeDate);

  const sessionsStartDates = useMemo(
    () =>
      sessionList.reduce(
        (acc, { startDate, sessionName }) => ({ ...acc, [sessionName]: dateFromString(startDate) }),
        {} as Record<string, Dayjs>,
      ),
    [sessionList],
  );

  function addSuffix(name = '', date: Dayjs) {
    const programPrefix = currentSession?.programPrefix || '';
    const dateStr = date.format('MMDDYY');
    const nameWithoutSuffix = name.replace(/\s?-?\s?[A-Za-z]{2,3}[0-9]{6,8}$/, '');
    return `${nameWithoutSuffix} - ${programPrefix}${dateStr}`;
  }

  const getIsValid = (
    name: string,
    date: Dayjs,
    index: number,
    sessions: Array<Partial<SessionsValue['sessions'][number]> | undefined>,
  ) => {
    const sameNameDate = sessionsStartDates[name];
    if (sameNameDate && sameNameDate.isSame(date, 'day')) {
      return false;
    }

    if (!sessions.length) return true;

    for (let i = 0; i < sessions.length; i += 1) {
      if (i !== index) {
        const foundName = sessions[i]?.[NAME_FIELD];

        if (foundName === name) {
          const foundDate = sessions[i]?.[DATE_FIELD];
          if (foundDate && foundDate.isSame(date, 'day')) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const getFieldInitialValues = (field: FormListFieldData): { name?: string; date?: Dayjs } => {
    const fieldData = form.getFieldValue([SESSIONS_FIELD, field.name]);

    if (!isEmpty(fieldData)) return {};

    const sessions: SessionsValue['sessions'] = form.getFieldValue(SESSIONS_FIELD);

    let prevFieldName = field.name - 1;
    let nextStartDate = null;

    while (!nextStartDate) {
      if (prevFieldName < 0) {
        nextStartDate = currentSessionStartDate;
      } else {
        const prevStartDate: Dayjs | null = form.getFieldValue([
          SESSIONS_FIELD,
          prevFieldName,
          DATE_FIELD,
        ]);

        if (prevStartDate) {
          nextStartDate = prevStartDate;
        } else {
          prevFieldName -= 1;
        }
      }
    }

    let nextSessionName: string;
    do {
      nextStartDate = nextStartDate.add(1, 'day');
      nextSessionName = addSuffix(currentSession?.sessionName, nextStartDate);
    } while (!getIsValid(nextSessionName, nextStartDate, field.name, sessions || []));

    return {
      date: nextStartDate,
      name: nextSessionName,
    };
  };

  function handleSubmit() {
    form.validateFields().then(({ sessions }) => {
      const sessionDuration = currentSessionCloseDate.diff(currentSessionStartDate);
      const updatedSessions = sessions.map(({ sessionName, startDate }) => ({
        sessionName,
        startDate: dateToString(startDate),
        closeDate: dateToString(startDate.add(sessionDuration, 'milliseconds')),
      }));
      dispatch(cloneSession.request({ sessionCode, sessions: updatedSessions }));
    });
  }

  const handleChangeFields = (changedFields: FieldData[]) => {
    if (changedFields.length === 1) {
      const changedRow = changedFields[0];
      if (Array.isArray(changedRow.name) && last(changedRow.name) === DATE_FIELD) {
        const index = changedRow.name[1];
        const values = form.getFieldsValue();
        const nextValues = cloneDeep(values);
        if (changedRow.value) {
          nextValues.sessions[index as number][NAME_FIELD] = addSuffix(
            currentSession?.sessionName,
            changedRow.value,
          );
          form.setFieldsValue(nextValues);
        }
      }
    }
  };

  return (
    <ModalComponent
      title="Replicate Session"
      description={
        <Fragment>
          {`Template Session: ${currentSession?.sessionName} ${usDate(
            currentSessionStartDate,
          )} - ${usDate(currentSessionCloseDate)} (${currentSessionCloseDate.diff(
            currentSessionStartDate,
            'days',
          )} days)`}
          <br />
          Please provide the name and start date for each new offering of this session below
        </Fragment>
      }
      buttons={[
        {
          type: 'primary',
          title: 'Replicate Session',
          onClick: handleSubmit,
        },
      ]}
      inProgress={inProgress}
    >
      <Form layout="vertical" form={form} onFieldsChange={handleChangeFields}>
        <Form.List name="sessions" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <Fragment>
              {fields.map(field => {
                const initialValues = getFieldInitialValues(field);

                return (
                  <Row key={field.key} gutter={10} align="middle">
                    <Col span={8}>
                      <Form.Item
                        name={[field.name, DATE_FIELD]}
                        label="New Session Start Date"
                        initialValue={initialValues.date}
                        rules={[
                          {
                            required: true,
                            message: 'Date is required',
                          },
                        ]}
                      >
                        <DatePicker size="large" />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <Form.Item
                        name={[field.name, NAME_FIELD]}
                        label="New Session Name"
                        initialValue={initialValues.name}
                        rules={[
                          { required: true, message: 'Name is required' },
                          {
                            validator: (_rule: RuleObject, currentName: string) => {
                              const currentDate: Dayjs = form.getFieldValue([
                                SESSIONS_FIELD,
                                field.name,
                                DATE_FIELD,
                              ]);
                              for (let i = field.name - 1; i >= 0; i -= 1) {
                                const existingDate = form.getFieldValue([
                                  SESSIONS_FIELD,
                                  i,
                                  DATE_FIELD,
                                ]);
                                const existingName = form.getFieldValue([
                                  SESSIONS_FIELD,
                                  i,
                                  NAME_FIELD,
                                ]);

                                if (
                                  existingDate &&
                                  existingName &&
                                  currentName === existingName &&
                                  currentDate.isSame(existingDate, 'day')
                                ) {
                                  return Promise.reject(
                                    'Session with this name already exists on this date',
                                  );
                                }
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col xs={2} flex="">
                      {fields.length > 1 && (
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}
              {fields.length < 60 && (
                <Row>
                  <Button
                    onClick={() => {
                      add();
                    }}
                    size="large"
                    icon={<PlusCircleFilled />}
                  >
                    Add
                  </Button>
                </Row>
              )}
            </Fragment>
          )}
        </Form.List>
      </Form>
    </ModalComponent>
  );
};

export default CloneSessionModal;
