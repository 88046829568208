import styled from 'styled-components';

import colors from '@/modules/styles/colors';

export const Container = styled.div`
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.primaryColor};
`;
