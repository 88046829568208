import React from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { useSelector } from 'react-redux';

import { GroupReservation } from '@/modules/data/dataTypes/groupReservationListReport';
import List, { ListItem } from '@/modules/shared/components/List';
import { usDate } from '@/modules/utils/dateFormats';

import { pageDataSel } from '../../duck/selectors';
import PeopleColumn from '../PeopleColumn';
import ReservationStatus from '../ReservationStatus';

type Props = {
  onFinishReservation: (record: GroupReservation) => void;
  onOpenReservation: (record: GroupReservation) => void;
  allowRegistration: boolean;
};

const ReservationsList: React.FC<Props> = ({
  allowRegistration,
  onFinishReservation,
  onOpenReservation,
}) => {
  const {
    data: {
      form: { isCreator, isCollaborator },
      reservationListReport: reservationList,
    },
  } = useSelector(pageDataSel);

  function getHeaderAction(record: GroupReservation) {
    const {
      isCancelled,
      isReservationContact,
      isComplete,
      expeditionNumber,
      isLeadAdvisor,
      isAdditionalReservationContact,
    } = record;
    if (
      !isCancelled &&
      (isReservationContact || isAdditionalReservationContact) &&
      (!isComplete || !expeditionNumber) &&
      allowRegistration
    ) {
      return {
        title: 'Finish Registration',
        onClick: () => {
          onFinishReservation(record);
        },
      };
    }

    if (
      isCreator ||
      isCollaborator ||
      isLeadAdvisor ||
      isReservationContact ||
      isAdditionalReservationContact
    ) {
      return {
        Icon: AiOutlineRight,
        onClick: () => {
          onOpenReservation(record);
        },
      };
    }
  }

  return (
    <List
      dataSource={reservationList}
      renderListItem={record => {
        const {
          expeditionNumber,
          reservationContactFullName,
          startDate,
          initialRosterCount,
          amountDue,
          amountPaid,
        } = record;
        const headerAction = getHeaderAction(record);
        return (
          <ListItem
            record={record}
            title={expeditionNumber}
            headerAction={headerAction}
            rowKey="reservationCode"
            rows={[
              {
                title: 'Reservation Contact',
                content: reservationContactFullName,
              },
              ...((isCreator || isCollaborator) && startDate
                ? [
                    {
                      title: 'Session Date',
                      content: usDate(startDate),
                    },
                  ]
                : []),
              {
                title: 'Crews',
                content: initialRosterCount,
              },
              {
                title: 'People',
                content: <PeopleColumn record={record} />,
              },
              ...(isCreator || isCollaborator
                ? []
                : [
                    {
                      title: 'Total Amount Due',
                      content: `$${amountDue}`,
                    },
                    {
                      title: 'Total Amount Paid',
                      content: `$${amountPaid}`,
                    },
                  ]),
              {
                title: 'Status',
                content: <ReservationStatus reservation={record} isMobile />,
              },
            ]}
          />
        );
      }}
    />
  );
};

export default ReservationsList;
