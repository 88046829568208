import { Form as BsaForm } from 'antd';
import styled from 'styled-components';

export const Form = styled(BsaForm)`
  max-width: 300px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  .ant-form-item-explain-error {
    color: red;
  }
`;
