import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import React, { cloneElement, PropsWithChildren, useCallback, useMemo } from 'react';
import { FiMoreVertical } from 'react-icons/fi';

import { Button, FakeIcon, StyledDropdown } from './Dropdown.styled';

type Props = {
  menuItems: MenuItemType[];
  iconsSize?: number;
  placement?: 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter';
  disabled?: boolean;
  className?: string;
};

const Dropdown: React.FC<PropsWithChildren<Props>> = ({
  menuItems,
  children,
  iconsSize = 18,
  placement = 'bottomRight',
  disabled,
  className,
}) => {
  const iconExist = useMemo(() => menuItems.some(({ icon }) => !!icon), [menuItems]);

  const renderIcon = useCallback(
    (icon?: React.ReactNode) => {
      if (React.isValidElement(icon)) {
        return cloneElement(icon, { size: iconsSize } as Partial<unknown>);
      }
      if (iconExist) {
        return <FakeIcon $size={iconsSize} />;
      }
      return null;
    },
    [iconExist, iconsSize],
  );

  const handleClick: React.MouseEventHandler = e => {
    e.stopPropagation();
  };

  return (
    <StyledDropdown
      className={className}
      disabled={disabled}
      placement={placement}
      trigger={['click']}
      menu={{
        style: { maxHeight: '500px', overflow: 'auto' },
        items: menuItems.map(({ icon, ...item }) => ({
          ...item,
          icon: renderIcon(icon),
        })),
      }}
    >
      {children || (
        <Button type="text" icon={<FiMoreVertical size={iconsSize} />} onClick={handleClick} />
      )}
    </StyledDropdown>
  );
};

export default Dropdown;
