import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Select, Tag as AntTag, Pagination as AntPagination, Input as AntInput } from 'antd';
import styled from 'styled-components';

import DatePicker from '@/modules/shared/components/DatePicker/DatePicker';
import colors from '@/modules/styles/colors';

export const SessionsDatePicker = styled(DatePicker)`
  &.ant-picker {
    height: 48px;
  }
`;

export const ProgramsSelectedTitle = styled.h4`
  color: ${colors.primary_5};
  text-align: left;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ResetButton = styled.span`
  font-weight: 600;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
`;

export const SearchIcon = styled(SearchOutlined)`
  padding-right: 1rem;
  color: ${colors.scoutingPaleGray};
`;

export const ArrowIcon = styled(CaretDownOutlined)`
  margin-top: 10px;
`;

const ProgramSelectWithBuggedGenericProps = styled(Select)`
  width: 100%;

  & .ant-select-selection__rendered > ul > li:not(:last-child) {
    display: none;
  }

  & .ant-select-selector {
    min-height: 48px;
  }

  & .ant-select-arrow {
    height: 4px;
  }
`;
// workaround for styled wrapper doesn't preserve generic props.
// see https://github.com/styled-components/styled-components/issues/1803
export const ProgramSelect = ProgramSelectWithBuggedGenericProps as unknown as typeof Select;

export const ProgramTag = styled(AntTag)`
  border: none;
  border-radius: 18px;
  display: inline-flex;
  align-items: center;
  height: 30px;
  background-color: ${colors.greyText};
  color: white;
  font-size: 14px;
  padding: 8px 16px;
  &.ant-tag .anticon-close {
    color: white;
  }
`;

export const Input = styled(AntInput)`
  height: 48px;

  & input {
    height: 38px;
  }
`;

export const SelectedProgramsRow = styled(Row)`
  margin-top: 16px;
`;

export const Pagination = styled(AntPagination)`
  text-align: center;
`;
