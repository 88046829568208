import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ColumnMetadataItem } from '@/modules/data/dataTypes/queryFilterList';
import { resetOnAction } from '@/modules/utils/resetOnLogoutReducer';

import {
  resetColumns,
  resetCurrentReport,
  resetRowsSelection,
  saveReport,
  selectRows,
  updateColumnMetadata,
  updateQueryFilterName,
} from './actions';

const updatedColumnMetadata = createReducer<ColumnMetadataItem[] | null>(null).handleAction(
  updateColumnMetadata.success,
  (_state, { payload }) => payload,
);

const selectedRows = createReducer<string[]>([]).handleAction(
  selectRows,
  (_state, { payload }) => payload,
);

const updatedQueryFilterName = createReducer<string | null>(null).handleAction(
  updateQueryFilterName,
  (_state, { payload }) => payload,
);

const saveReportInProgress = createReducer(false)
  .handleAction(saveReport.request, () => true)
  .handleAction([saveReport.success, saveReport.failure], () => false);

export default resetOnAction(
  combineReducers({
    saveReportInProgress,
    updatedQueryFilterName,
    updatedColumnMetadata: resetOnAction(updatedColumnMetadata, resetColumns),
    selectedRows: resetOnAction(selectedRows, resetRowsSelection),
  }),
  resetCurrentReport.request,
);
