import { createAction, createAsyncAction } from 'typesafe-actions';

import { ApiError } from '@/modules/utils/apiService';

import { firstEventPageTabRoute, moduleName } from '../constants';

export const deleteMailing = createAsyncAction(
  `${moduleName}/DELETE_MAILING_REQUEST`,
  `${moduleName}/DELETE_MAILING_SUCCESS`,
  `${moduleName}/DELETE_MAILING_FAILURE`,
)<string, undefined, ApiError>();

// TODO: Integrate CSV downloads with dataModule
export const downloadSessionsReport = createAction(`${moduleName}/DOWNLOAD_SESSIONS_REPORT`)<
  string | undefined
>();

export const summaryReport = createAsyncAction(
  `${moduleName}/SUMMARY_REPORT_REQUEST`,
  `${moduleName}/SUMMARY_REPORT_SUCCESS`,
  `${moduleName}/SUMMARY_REPORT_FAILURE`,
)<{ formCode: string; eventName: string; groupBy: string }, void, Error>();

export const adminReportCSV = createAsyncAction(
  `${moduleName}/ADMIN_PAYMENT_CSV_REQUEST`,
  `${moduleName}/ADMIN_PAYMENT_CSV_SUCCESS`,
  `${moduleName}/ADMIN_PAYMENT_CSV_FAILURE`,
)<
  {
    formCode: string;
    eventName: string;
    startDate: string;
    finishDate: string;
  },
  void,
  void
>();

export const navigateToEventPage = createAction(firstEventPageTabRoute)<{ formCode: string }>();
