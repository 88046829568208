import { Addon } from '@/modules/data/dataTypes/addons';
import apiService from '@/modules/utils/apiService';

type UpdateAddonsPayload = {
  formCode: string;
  addons: (Omit<Addon, 'addonOptions'> & {
    isIncluded: boolean;
    groupReservationAddonGUID?: string;
    formRecordAddonGUID?: string;
  })[];
  formRecordGUID?: string;
  groupReservationGUID?: string;
};

const updateFormRecordAddon$ = (payload: UpdateAddonsPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateFormRecordAddon',
    payload,
  );

const updateGroupReservationAddon$ = (payload: UpdateAddonsPayload) =>
  apiService.post$(
    '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=EventData&ScriptCode=UpdateGroupReservationAddon',
    payload,
  );

export default {
  updateFormRecordAddon$,
  updateGroupReservationAddon$,
};
