import {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { get, isBoolean } from 'lodash';
import React, { Fragment } from 'react';

import ActionButton from './components/ActionButton';

interface Props<T> {
  onEdit?: (index: number, record: T) => void;
  onRemove?: (index: number, record: T) => void;
  index: number;
  record: T;
  removeText?: string;
  customAction?: {
    size?: SizeType;
    text: string;
    icon: React.ReactNode;
    onClick: (index: number, record: T) => void;
    hide: (record: T) => void;
  };
  expanded?: boolean;
  onExpand?: (index: number, record: T) => void;
  onCollapse?: (index: number, record: T) => void;
}

function ActionsColumn<T>({
  onEdit,
  onRemove,
  removeText,
  index,
  record,
  customAction,
  expanded,
  onExpand,
  onCollapse,
}: Props<T>): React.ReactElement {
  const createActionClickHandler = (handler: (index: number, record: T) => void) => () => {
    handler(index, record);
  };

  const handleCustomActionClick = () => {
    customAction && customAction.onClick(index, record);
  };

  function showCustomAction(): boolean {
    const hide = get(customAction, 'hide', () => false);
    return !hide(record);
  }

  return (
    <Fragment>
      {onEdit && (
        <ActionButton onClick={createActionClickHandler(onEdit)} icon={<EditOutlined />} />
      )}
      {onRemove && (
        <ActionButton
          icon={<DeleteOutlined />}
          onClick={createActionClickHandler(onRemove)}
          confirmText={removeText}
        />
      )}
      {customAction && showCustomAction() && (
        <ActionButton
          icon={customAction.icon}
          onClick={handleCustomActionClick}
          confirmText={customAction.text}
        />
      )}
      {isBoolean(expanded) &&
        onCollapse &&
        onExpand &&
        (expanded ? (
          <ActionButton onClick={createActionClickHandler(onCollapse)} icon={<CaretUpOutlined />} />
        ) : (
          <ActionButton onClick={createActionClickHandler(onExpand)} icon={<CaretDownOutlined />} />
        ))}
    </Fragment>
  );
}

ActionsColumn.defaultProps = {
  removeText: 'Remove this record?',
};

export default ActionsColumn;
