const APPLEID_AUTH_SDK_URL =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
const APPLEID_AUTH_SDK_ELEMENT_ID = '__appleid_auth_sdk';

class AppleId {
  loadSDK = () =>
    new Promise((resolve, reject) => {
      const existingScript = document.getElementById(APPLEID_AUTH_SDK_ELEMENT_ID);
      if (existingScript) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = APPLEID_AUTH_SDK_URL;
      script.id = APPLEID_AUTH_SDK_ELEMENT_ID;
      document.body.appendChild(script);
      script.onload = () => {
        resolve(this);
      };
      script.onerror = error => {
        reject(error);
      };
    });

  init = (config, onSuccess, onFailure) => {
    this.onSuccess = onSuccess;
    this.onFailure = onFailure;
    if (window.AppleID) {
      window.AppleID.auth.init(config);
      document.addEventListener('AppleIDSignInOnSuccess', this.onSuccess);
      document.addEventListener('AppleIDSignInOnFailure', this.onFailure);
    }
  };

  cleanUp = () => {
    document.removeEventListener('AppleIDSignInOnSuccess', this.onSuccess);
    document.removeEventListener('AppleIDSignInOnFailure', this.onFailure);
  };
}

export default AppleId;
