import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';

const iconStyles = css`
  filter: invert(1);
`;

export const ResetColorIcon = styled(CloseOutlined)`
  ${iconStyles};
`;

export const ActiveColorIcon = styled(CheckOutlined)`
  ${iconStyles};
`;
