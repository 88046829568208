import React, { Fragment, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-named-as-default
import { RootState } from 'typesafe-actions';

import { AttendeeDetails } from '@/modules/data/dataTypes/attendeeDetails';
import { refreshData, scheduleRefresh } from '@/modules/data/duck/actions';
import { createDataSel, createExtraDataSel } from '@/modules/data/duck/selectors';
import { updateFormRecord } from '@/modules/entities/FormRecord/duck/actions';
import { updateFormRecordInProgressSel } from '@/modules/entities/FormRecord/duck/selectors';
import AttendeeTypes from '@/modules/shared/components/AttendeeTypes';
import { hasActiveMembershipSel, isAlumniSel } from '@/modules/user/duck/selectors';

import ButtonsRow from '../../components/ButtonsRow';
import { openNextStep } from '../../duck/actions';
import { formRecordGuidSel } from '../../duck/selectors';

type Props = ReturnType<typeof mapState>;

const AttendeeType: React.FC<Props> = ({ isAlumni, hasActiveMembership }) => {
  const dispatch = useDispatch();
  const ref = useRef<{ handleSubmit: () => Promise<string> | undefined }>(null);
  const { formCode } = useSelector(createDataSel('form'));
  const attendeeDetails: AttendeeDetails | undefined = useSelector(
    createDataSel('attendeeDetails'),
  );
  const formRecordGUID = useSelector(formRecordGuidSel);
  const updateFormRecordInProgress = useSelector(updateFormRecordInProgressSel);
  const attendeeTypes = useSelector(createDataSel('attendeeTypesForPersonList'));
  const { isMembershipEvent } = useSelector(createExtraDataSel('attendeeTypesForPersonList'));

  function handleNext() {
    if (!formRecordGUID) return null;

    ref.current?.handleSubmit()?.then(typeCode => {
      const currentType = attendeeTypes.find(at => at.typeCode === typeCode);
      const isStaff = currentType?.isStaff;

      dispatch(
        updateFormRecord.request({
          formCode,
          formRecordGUID,
          typeCode,
          finalActions: [
            ...(isStaff ? [refreshData({ dataType: 'jobList' })] : []),
            scheduleRefresh({ dataType: 'attendeeDetails' }),
            openNextStep(),
          ],
        }),
      );
    });
  }

  return (
    <Fragment>
      <AttendeeTypes
        ref={ref}
        cardHeader="Attendee Types"
        attendeeTypes={attendeeTypes}
        isMembershipEvent={isMembershipEvent}
        selectedAttendeeType={attendeeDetails?.typeCode}
        isAlumni={isAlumni}
        hasActiveMembership={hasActiveMembership}
      />
      <ButtonsRow
        leftButtons={[{}]}
        rightButtons={[{ onClick: handleNext, loading: updateFormRecordInProgress }]}
      />
    </Fragment>
  );
};

const mapState = (
  state: RootState,
): {
  isAlumni: boolean;
  hasActiveMembership: boolean;
} => ({
  isAlumni: isAlumniSel(state),
  hasActiveMembership: hasActiveMembershipSel(state),
});

export default connect(mapState)(AttendeeType);
