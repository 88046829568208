import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { createPageDataSelector } from '@/modules/data/duck/selectors';

import { modulePath, stepDataParams } from '../constants';

import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> => get(state, modulePath);

export const stepDataSel = createPageDataSelector(stepDataParams);

export const updateDisclaimerInProgressSel = createSelector(
  moduleSel,
  ({ updateDisclaimerInProgress }) => updateDisclaimerInProgress,
);

export const deleteDisclaimerInProgressSel = createSelector(
  moduleSel,
  ({ deleteDisclaimerInProgress }) => deleteDisclaimerInProgress,
);

export const linkEventDisclaimerInProgressSel = createSelector(
  moduleSel,
  ({ linkEventDisclaimerInProgress }) => linkEventDisclaimerInProgress,
);

export const unlinkEventDisclaimerInProgressSel = createSelector(
  moduleSel,
  ({ unlinkEventDisclaimerInProgress }) => unlinkEventDisclaimerInProgress,
);
