import { AttendeeType } from '@/modules/data/dataTypes/attendeeTypeList';
import { FormPartsData } from '@/modules/data/dataTypes/formPartsData';
import { OptionSetItem, OptionSets } from '@/modules/data/dataTypes/optionSets';
import { PresentationType } from '@/modules/shared/constants';

interface FormPartFieldItem {
  formItemCode: string;
  formItemName: string;
  optionSetCode?: string;
  presentationType: PresentationType;
}

interface FormPartField {
  formItems: FormPartFieldItem[];
  formPartCode: string;
  formPartName: string;
}

export const getUpdatedFormParts = (formParts: FormPartsData['formParts']): FormPartField[] => {
  const filteredFormParts = formParts.filter(
    ({ datablockCode }) => !['form', 'parentdata'].includes(datablockCode),
  );

  const attendeeTypesPart: FormPartField = {
    formItems: [
      {
        formItemCode: 'attendeeType',
        formItemName: 'Attendee Type',
        optionSetCode: 'attendeeTypes',
        presentationType: PresentationType.CHECKBOX,
      },
    ],
    formPartCode: 'attendeeType',
    formPartName: 'Attendee Type',
  };

  return [attendeeTypesPart, ...filteredFormParts];
};

export const getUpdatedOptionSets = (
  optionSets: OptionSets,
  attendeeTypeList: AttendeeType[],
): Record<string, { optionItems: Pick<OptionSetItem, 'itemLabel' | 'itemValue'>[] }> => {
  const optionItems = attendeeTypeList.map(attendeeType => ({
    itemLabel: attendeeType.typeName,
    itemValue: attendeeType.typeCode,
  }));

  return { ['attendeeTypes']: { optionItems }, ...optionSets };
};
