import { Card, Button } from 'antd';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'redux-first-router-link';

import { createDataSel } from '@/modules/data/duck/selectors';
import { updatePersonInProgressSel } from '@/modules/entities/Person/duck/selectors';
import EventCover from '@/modules/shared/components/EventCover';
import LoadingContainer from '@/modules/shared/components/LoadingContainer';
import Stepper from '@/modules/shared/components/Stepper';
import { isLoggedInSel } from '@/modules/user/duck/selectors';

import styles from './AddAttendee.less';
import { stepKeys } from './constants';
import { resetAddAttendeeState } from './duck/actions';
import {
  addAttendeeInProgressSel,
  currentStepIndexSel,
  disabledStepsSel,
  stepIsLoadingSel,
  stepsSel,
} from './duck/selectors';
import AddAttendeeFormSubmission from './steps/AddAttendeeFormSubmission';
import PersonalInformation from './steps/PersonalInformation';

const stepComponents = {
  [stepKeys.personalInfo]: PersonalInformation,
  [stepKeys.forms]: AddAttendeeFormSubmission,
};

const StepWrapper: React.FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSel);
  const { formName, eventLocation, eventStartDate, eventCloseDate } = useSelector(
    createDataSel('form'),
  );
  const steps = useSelector(stepsSel);
  const stepIndex = useSelector(currentStepIndexSel);
  const disabledSteps = useSelector(disabledStepsSel);
  const stepIsLoading = useSelector(stepIsLoadingSel);
  const updatePersonInProgress = useSelector(updatePersonInProgressSel);
  const addAttendeeInProgress = useSelector(addAttendeeInProgressSel);

  useEffect(
    () => () => {
      dispatch(resetAddAttendeeState());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const stepKey = steps[stepIndex].key;
  const StepComponent = stepComponents[stepKey];

  return (
    <Fragment>
      <EventCover
        title={formName}
        location={eventLocation}
        dates={[eventStartDate, eventCloseDate]}
      />
      {steps.length > 1 && (
        <Stepper steps={steps} stepIndex={stepIndex} disabledSteps={disabledSteps} />
      )}
      {isLoggedIn ? (
        <LoadingContainer
          isLoading={stepIsLoading || updatePersonInProgress || addAttendeeInProgress}
        >
          <StepComponent />
        </LoadingContainer>
      ) : (
        <Card className={styles.cardMargin}>
          <div className={styles.centerText}>
            <p className={styles.midText}>Login and Accept Invitation.</p>
            <Link to="/login">
              <Button className="mt-2">Login</Button>
            </Link>
          </div>
        </Card>
      )}
    </Fragment>
  );
};

export default StepWrapper;
