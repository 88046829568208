import { RightOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { useSelector } from 'react-redux';

import { Attendee } from '@/modules/data/dataTypes/rosterAttendees';
import { ADDED_WITHOUT_INVITATION } from '@/modules/shared/components/InvitationStatusCell/constants';

import { pageDataSel } from '@/pages/reservation/duck/selectors';
import AttendeeAge from '@/pages/reservation/tabs/OverviewGroup/components/RosterPage/components/AttendeeAge';
import GroupHeader from '@/pages/reservation/tabs/OverviewGroup/components/RosterPage/components/GroupHeader';

import { GroupedAttendees } from '../../../../types';
import DisclaimerStatus from '../../../DisclaimerStatus';

import AttendeeDetailsLink from './components/AttendeeDetailsLink';

import ActionTable from 'SHARED/components/ActionTable';
import InvitationStatusCell from 'SHARED/components/InvitationStatusCell';

type Props = {
  groupedAttendees: GroupedAttendees;
  selectedAttendees: Set<string>;
  eventHasDisclaimers: boolean;
  onSelect: (personsGuids: Set<string>) => void;
};

const AttendeesTable: React.FC<Props> = ({
  groupedAttendees,
  selectedAttendees,
  eventHasDisclaimers,
  onSelect,
}) => {
  const {
    data: { groupReservation },
  } = useSelector(pageDataSel);

  function handleSelect(record: GroupedAttendees[number], selected: boolean) {
    if ('personGUID' in record && !!record.personGUID) {
      const { personGUID } = record;
      const nextSelectedAttendees = new Set([...selectedAttendees]);
      if (selected) {
        nextSelectedAttendees.add(personGUID);
      } else {
        nextSelectedAttendees.delete(personGUID);
      }
      onSelect(nextSelectedAttendees);
    }
  }

  function handleSelectAll() {
    const attendeesPersonGuidArr = groupedAttendees
      .filter((ga): ga is Attendee => 'personGUID' in ga && !!ga.personGUID)
      .map(({ personGUID }) => personGUID);
    if (selectedAttendees.size && selectedAttendees.size === attendeesPersonGuidArr.length) {
      onSelect(new Set());
    } else {
      const selectedAttendeesArr = groupedAttendees
        .filter((ga): ga is Attendee => 'personGUID' in ga && !!ga.personGUID)
        .map(({ personGUID }) => personGUID);
      onSelect(new Set(selectedAttendeesArr));
    }
  }

  const columns: ColumnProps<GroupedAttendees[number]>[] = [
    {
      title: 'Full Name',
      key: 'fullName',
      // eslint-disable-next-line react/no-multi-comp
      render: (record: Attendee) => (
        <AttendeeDetailsLink attendee={record}>{record.fullName}</AttendeeDetailsLink>
      ),
    },
    {
      title: 'Age',
      key: 'age',
      // eslint-disable-next-line react/no-multi-comp
      render: (attendee: Attendee) => (
        <AttendeeAge attendee={attendee} reservationDetails={groupReservation} />
      ),
    },
    {
      title: 'Invitation Status',
      key: 'invitationStatus',
      // eslint-disable-next-line react/no-multi-comp
      render: (attendee: Attendee) => {
        const { invitationStatus, isInvitationSent } = attendee;
        return (
          <InvitationStatusCell
            statusName={isInvitationSent ? invitationStatus : ADDED_WITHOUT_INVITATION}
          />
        );
      },
    },
    ...(eventHasDisclaimers
      ? [
          {
            key: 'status',
            title: 'Disclaimer Status',
            // eslint-disable-next-line react/no-multi-comp
            render: (attendeeRecord: Attendee) => (
              <DisclaimerStatus attendeeRecord={attendeeRecord} />
            ),
          },
        ]
      : []),
    {
      key: 'dashboardLink',
      // eslint-disable-next-line react/no-multi-comp
      render: (record: Attendee) => (
        <AttendeeDetailsLink attendee={record}>
          <RightOutlined />
        </AttendeeDetailsLink>
      ),
    },
    {
      key: 'emptyColumnForAddButton',
    },
  ];

  return (
    <ActionTable
      pagination={false}
      allowSearch={false}
      columns={columns}
      dataSource={groupedAttendees}
      rowKey="personGUID"
      GroupHeaderComponent={GroupHeader}
      bordered={false}
      rowSelection={{
        onSelect: handleSelect,
        onSelectAll: handleSelectAll,
        selectedRowKeys: [...selectedAttendees],
      }}
    />
  );
};

export default AttendeesTable;
