import { ajax } from 'rxjs/ajax';

const getMessages$ = (locale: 'enUS') =>
  ajax<Record<string, string>>({
    method: 'GET',
    url: `/assets/translations/${locale}.json`,
  });

export default {
  getMessages$,
};
