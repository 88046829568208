import { differenceBy, isEqual, omit, trimStart } from 'lodash';
import { Epic, combineEpics } from 'redux-observable';
import { concat, defer, merge, of } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { RootAction, RootState, isActionOf } from 'typesafe-actions';

import {
  removeData,
  removeFilter,
  resetFilters,
  resetPagination,
  setFilter,
} from '@/modules/data/duck/actions';
import { refreshDataAndWait$ } from '@/modules/data/duck/epics';
import { arnicaServiceDefinitions } from '@/modules/data/duck/services';
import { closeModal } from '@/modules/modals/duck/actions';
import { resetReports } from '@/modules/reports/duck/actions';
import { formCodeSel, queryFilterVariablesObjSel } from '@/modules/reports/duck/selectors';
import { encodeFilterContent, getReportDataId } from '@/modules/reports/utils';
import toastService from '@/modules/toasts/service';
import { ApiError } from '@/modules/utils/apiService';

import {
  FILTER_KEY_COLUMN_LIST,
  FILTER_KEY_FILTER_CONTENT,
  FILTER_KEY_QUERY_FILTER_CODE,
  FILTER_KEY_SORT_ORDER,
} from '../constants';

import {
  addColumns,
  deleteColumn,
  moveColumn,
  resetColumns,
  resetCurrentReport,
  resizeColumn,
  saveReport,
  updateColumnMetadata,
  updateFilterContent,
  updateSortOrder,
} from './actions';
import {
  columnMetadataSel,
  createCurrentQueryFilterSel,
  filterContentSel,
  filtersSel,
  queryFilterColumnMetadataSel,
  queryFilterNameSel,
  sortOrderChangedSel,
  sortOrderSel,
  updatedColumnMetadataSel,
} from './selectors';
import services from './services';

const addColumns$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addColumns)),
    switchMap(({ payload: { columns, dataType } }) => {
      const state = state$.value;
      const columnMetadata = columnMetadataSel(state, { dataType });
      const nextColumnMetadata = [...columnMetadata, ...columns];
      return of(updateColumnMetadata.request({ dataType, nextColumnMetadata }), closeModal());
    }),
  );

const deleteColumn$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteColumn)),
    switchMap(({ payload: { dataType, columnName } }) => {
      const state = state$.value;
      const columnMetadata = columnMetadataSel(state, { dataType });
      const nextColumnMetadata = columnMetadata.filter(({ name }) => name !== columnName);
      return of(updateColumnMetadata.request({ dataType, nextColumnMetadata }));
    }),
  );

const moveColumns$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(moveColumn)),
    switchMap(({ payload: { dataType, sourceColumnName, targetColumnName } }) => {
      const state = state$.value;
      const columnMetadata = columnMetadataSel(state, { dataType });
      const nextColumnMetadata = [...columnMetadata];
      const sourceIndex = nextColumnMetadata.findIndex(({ name }) => name === sourceColumnName);
      const targetIndex = nextColumnMetadata.findIndex(({ name }) => name === targetColumnName);
      [nextColumnMetadata[sourceIndex], nextColumnMetadata[targetIndex]] = [
        nextColumnMetadata[targetIndex],
        nextColumnMetadata[sourceIndex],
      ];
      return of(updateColumnMetadata.request({ dataType, nextColumnMetadata }));
    }),
  );

const resizeColumn$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(resizeColumn)),
    switchMap(({ payload: { dataType, columnName, columnWidth } }) => {
      const state = state$.value;
      const columnMetadata = columnMetadataSel(state, { dataType });
      const columnIndex = columnMetadata.findIndex(({ name }) => name === columnName);
      const column = columnMetadata[columnIndex];
      const nextColumn = { ...column, width: columnWidth };
      const nextColumnMetadata = [
        ...columnMetadata.slice(0, columnIndex),
        nextColumn,
        ...columnMetadata.slice(columnIndex + 1),
      ];
      return of(updateColumnMetadata.request({ dataType, nextColumnMetadata }));
    }),
  );

const updateColumnMetadata$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateColumnMetadata.request)),
    switchMap(({ payload: { dataType, nextColumnMetadata } }) => {
      const state = state$.value;
      const dataId = getReportDataId(dataType);
      const queryFilterColumnMetadata = queryFilterColumnMetadataSel(state, { dataType });
      const currentColumnMetadata = columnMetadataSel(state, { dataType });

      const columnsChanged = !isEqual(queryFilterColumnMetadata, nextColumnMetadata);

      return concat(
        of(columnsChanged ? updateColumnMetadata.success(nextColumnMetadata) : resetColumns()),
        defer(() => {
          const newColumnsAdded = !!differenceBy(nextColumnMetadata, currentColumnMetadata, 'name')
            .length;

          const columnList = nextColumnMetadata.map(({ name }) => name).join(',');

          return of(
            setFilter({
              dataType,
              dataId,
              key: FILTER_KEY_COLUMN_LIST,
              value: columnList,
              fetchData: !!newColumnsAdded,
            }),
          );
        }),
      );
    }),
  );

const updateFilterContent$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateFilterContent)),
    switchMap(({ payload: { filterContent, dataType } }) => {
      const state = state$.value;
      const dataId = getReportDataId(dataType);
      const currentQueryFilter = createCurrentQueryFilterSel()(state, { dataType });
      const queryFilterVariablesObj = queryFilterVariablesObjSel(state, { dataType });
      const newFilterContent = encodeFilterContent(filterContent, queryFilterVariablesObj);

      return of(
        currentQueryFilter?.filterContent === newFilterContent
          ? removeFilter({ dataType, key: FILTER_KEY_FILTER_CONTENT, dataId })
          : setFilter({
              dataType,
              key: FILTER_KEY_FILTER_CONTENT,
              value: newFilterContent,
              dataId,
            }),
      );
    }),
  );

const updateSortOrder$: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateSortOrder)),
    switchMap(({ payload: { columnName, order, dataType } }) => {
      const state = state$.value;
      const dataId = getReportDataId(dataType);
      const sortOrderStr = sortOrderSel(state, { dataType });
      const currentQueryFilter = createCurrentQueryFilterSel()(state, { dataType });

      const sortOrderWithoutColumn = sortOrderStr
        .split(',')
        .filter(item => !trimStart(item).startsWith(columnName))
        .join(',');

      const nextSortOrderStr = order
        ? `${columnName} ${order}${sortOrderWithoutColumn ? `,${sortOrderWithoutColumn}` : ''}`
        : sortOrderWithoutColumn;

      return of(
        nextSortOrderStr !== currentQueryFilter?.sortOrder
          ? setFilter({ dataType, dataId, key: FILTER_KEY_SORT_ORDER, value: nextSortOrderStr })
          : removeFilter({ dataType, dataId, key: FILTER_KEY_SORT_ORDER }),
      );
    }),
  );

const saveReport$: Epic<RootAction, RootAction> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(saveReport.request)),
    switchMap(({ payload: { currentQueryFilterCode, isNew, dataType, ...data } }) => {
      const state = state$.value;
      const dataId = getReportDataId(dataType);
      const currentQueryFilter = createCurrentQueryFilterSel(currentQueryFilterCode)(state, {
        dataType,
      });

      if (!currentQueryFilter) {
        return of(saveReport.failure(undefined));
      }

      const formCode = formCodeSel(state);

      const queryFilterName = queryFilterNameSel(state, { dataType }) as string;

      const hasUpdatedQueryFilterName = queryFilterName !== currentQueryFilter.queryFilterName;
      const hasUpdatedColumns = !!updatedColumnMetadataSel(state);
      const hasUpdatedSortOrder = sortOrderChangedSel(state, { dataType });
      const { [FILTER_KEY_FILTER_CONTENT]: updatedFilters } = filtersSel(state, {
        dataType,
      });

      if (
        !hasUpdatedQueryFilterName &&
        !hasUpdatedColumns &&
        !updatedFilters &&
        !hasUpdatedSortOrder &&
        !isNew
      ) {
        return of(saveReport.success());
      }

      const queryFilterCode =
        currentQueryFilter.isDefault || isNew // Default queryFilter should remain as base and not altered in UI.
          ? null
          : currentQueryFilter.queryFilterCode;

      const apiName = arnicaServiceDefinitions[dataType]?.scriptCode as string;

      const baseData = omit(currentQueryFilter, [
        'personGUID',
        'firstName',
        'lastName',
        'creationDate',
        'updateDate',
        'lastRecordCount',
      ]);

      const columnMetadata = columnMetadataSel(state, { dataType });
      const filterContent = filterContentSel(state, { dataType });
      const sortOrder = sortOrderSel(state, { dataType });

      const payload = {
        ...baseData,
        ...data,
        queryFilterCode,
        queryFilterName,
        apiName,
        formCode,
        columnList: columnMetadata.map(({ name }) => name).join(','),
        columnMetadata,
        filterContent,
        sortOrder,
        isDefault: false,
        defaultFilter: false,
      };

      return services.updateQueryFilter$(payload).pipe(
        switchMap(({ responseValue }) => {
          toastService.success(isNew ? 'Created Report' : 'Updated Report');

          return concat(
            refreshDataAndWait$(action$, { dataType: 'queryFilters', dataId }),
            merge(
              action$.pipe(
                filter(isActionOf(resetCurrentReport.success)),
                take(1),
                takeUntil(action$.pipe(filter(isActionOf(resetCurrentReport.failure)), take(1))),
              ),
              of(resetCurrentReport.request(dataType)),
            ),
            of(
              saveReport.success(),
              closeModal(),
              setFilter({
                dataType,
                dataId,
                key: FILTER_KEY_QUERY_FILTER_CODE,
                value: responseValue,
              }),
            ),
          );
        }),
      );
    }),
    catchError((e: ApiError) => {
      toastService.error(e.message);
      return of(saveReport.failure(e));
    }),
  );

const resetCurrentReport$: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf([resetCurrentReport.request, resetReports])),
    switchMap(({ payload: dataType }) => {
      const dataId = getReportDataId(dataType);
      return concat(
        of(
          resetFilters({ dataType, dataId, fetchData: false }),
          resetPagination({ dataType, dataId, fetchData: false }),
          removeData({ dataType: 'emailTemplate' }),
        ),
        of(resetCurrentReport.success()),
      );
    }),
  );

export default combineEpics(
  addColumns$,
  deleteColumn$,
  moveColumns$,
  resizeColumn$,
  updateColumnMetadata$,
  updateFilterContent$,
  updateSortOrder$,
  saveReport$,
  resetCurrentReport$,
);
