import { combineEpics, Epic } from 'redux-observable';
import { catchError } from 'rxjs/operators';
import { RootAction } from 'typesafe-actions';

import broadcastEpics from '@/modules/broadcast/duck/epics';
import coreEpics from '@/modules/core/duck/epics';
import dataEpics from '@/modules/data/duck/epics';
import activitiesEpics from '@/modules/entities/Activities/duck/epics';
import addonsEpics from '@/modules/entities/Addons/duck/epics';
import attributesEpics from '@/modules/entities/Attributes/duck/epics';
import emailEpics from '@/modules/entities/Emails/duck/epics';
import eventEntitiesEpics from '@/modules/entities/Events/duck/epics';
import formRecordEpics from '@/modules/entities/FormRecord/duck/epics';
import jobEpics from '@/modules/entities/Jobs/duck/epics';
import logsEpics from '@/modules/entities/Logs/duck/epics';
import personEntitiesEpics from '@/modules/entities/Person/duck/epics';
import registrationsEpics from '@/modules/entities/Registrations/duck/epics';
import reservationsEpics from '@/modules/entities/Reservations/duck/epics';
import localizationEpics from '@/modules/localization/duck/epics';
import paymentEpics from '@/modules/payments/duck/epics';
import reportsEpics from '@/modules/reports/duck/epics';
import routingEpics from '@/modules/routing/duck/epics';
import sharedEpics from '@/modules/shared/duck/epics';
import stepsEpics from '@/modules/steps/duck/epics';
import uploadQuestionsModalEpics from '@/modules/uploadQuestionsModal/duck/epics';
import userEpics from '@/modules/user/duck/epics';

import addAttendeeEpics$ from '@/pages/addAttendee/duck/epics';
import createEventPageEpics from '@/pages/createEvent/duck/epics';
import formsEpics from '@/pages/createReservation/duck/epics';
import eventPageEpics from '@/pages/event/duck/epics';
import eventsEpics from '@/pages/eventList/duck/epics';
import parentConsentEpics$ from '@/pages/parentConsent/duck/epics';
import reservationPageEpics from '@/pages/reservation/duck/epics';

/*
    This helps debug all epics easily by catching the error
    and also prevents all of them to stop working again by returning source again to stream.
*/
const rootEpic: Epic<RootAction, RootAction> = (action$, store$, dependencies) =>
  combineEpics(
    addonsEpics,
    addAttendeeEpics$,
    broadcastEpics,
    coreEpics,
    dataEpics,
    jobEpics,
    logsEpics,
    uploadQuestionsModalEpics,
    userEpics,
    emailEpics,
    eventEntitiesEpics,
    eventsEpics,
    createEventPageEpics,
    eventPageEpics,
    parentConsentEpics$,
    formsEpics,
    localizationEpics,
    paymentEpics,
    reportsEpics,
    reservationPageEpics,
    routingEpics,
    sharedEpics,
    stepsEpics,
    attributesEpics,
    personEntitiesEpics,
    formRecordEpics,
    registrationsEpics,
    reservationsEpics,
    activitiesEpics,
  )(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return source;
    }),
  );

export default rootEpic;
